import { ensureArray } from 'BMapsSrc/util/js_utils';

/**
 * Update MapCase properties with the associated KLIFS data, if available.
 * @param {import('BMapsModel').MapCase} mapCase
 * @param {function} trigger
 * @returns {{ value: object, error: string }}
 */
export async function updateMapCaseWithKlifs(mapCase, trigger) {
    const { values, error } = await mapCase.fetchPropertyGroup('klifs', async () => {
        const { klifs: results, error: klifsErr } = await klifsLookupByPdbId(mapCase.pdbID);
        // In case of multiple KLIFS entries, merge them into a single object, with values as arrays
        const klifsVals = results?.reduce((acc, next) => {
            for (const [key, val] of Object.entries(next)) {
                if (acc[key] === undefined) {
                    acc[key] = { value: val };
                } else {
                    acc[key].value = [].concat(acc[key].value, val);
                }
            }
            return acc;
        }, {});

        return { values: klifsVals, error: klifsErr };
    }, trigger);
    return { values, error };
}

/**
 * Lookup KLIFS data for a list of PDBs. Return an object mapping from each PDB ID to KLIFS data.
 * @param {string|string[]} pdbIds
 * @returns {{ results: { [pdbId: string]: [] } } | { error: string }}
 */
export async function klifsLookupByPdbIds(pdbIdsIn) {
    const pdbIds = ensureArray(pdbIdsIn);
    if (pdbIds.length === 0 || !pdbIds.every(isValidPdbId)) {
        const empty = pdbIds.length === 0 || pdbIds.every((id) => id === '');
        const invalids = pdbIds.filter((id) => !isValidPdbId(id));
        const msg = empty
            ? 'No PDB ID available for KLIFS lookup'
            : `Invalid PDB ID for KLIFS lookup: ${invalids.join(', ')}`;
        return { error: msg };
    }
    const url = `https://klifs.net/api_v2/structures_pdb_list?pdb-codes=${pdbIds.join(',')}`;

    const requestOptions = {
        method: 'GET', // allow forcing POST with empty string
        headers: { accept: 'application/json' },
    };

    const response = await fetch(url, requestOptions);
    let parseResponse;
    if (response.ok) {
        try {
            parseResponse = await response.json();
        } catch (ex) {
            return { error: `Failed to parse klifs response: ${ex}` };
        }

        const results = {};
        for (const kResult of parseResponse) {
            const key = kResult.pdb.toLowerCase();
            if (!results[key]) results[key] = [];
            results[key].push(kResult);
        }
        return { results };
    } else {
        try {
            parseResponse = await response.json();
            if (parseResponse[1] === 'KLIFS error: An unknown PDB-code was provided') {
                parseResponse = 'No KLIFS entry found';
            }
        } catch (ex) {
            parseResponse = await response.text();
        }
        return { error: parseResponse };
    }
}

/**
 * Lookup KLIFS data for a single PDB
 * @param {string} pdbId
 * @returns {{ error, klifs: [] }}
 */
export async function klifsLookupByPdbId(pdbId) {
    const { results, error } = await klifsLookupByPdbIds(pdbId);
    const key = pdbId.toLowerCase();
    return error ? { error } : { klifs: results[key] || [] };
}

// Copied from PdbImportCase in MapCase.js. Could go into mol_format_utils?
// Number followed by 3 alphanumerics, with at least one letter
function isValidPdbId(id) {
    const pidFormat = /^[0-9][A-Za-z0-9]{3}$/;
    const alphaChar = /[A-Za-z]/;
    return pidFormat.test(id) && alphaChar.test(id);
}
