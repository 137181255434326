import React from 'react';
import TextField from '@mui/material/TextField';
import { withFormik, withTooltip, wrapForDisabledTooltip } from './helpers';

export default function Select({ children, ...rest }) {
    const nativeOverride = rest.SelectProps?.native;
    const nativeDefault = true;
    const isNative = typeof (nativeOverride) === 'boolean' ? nativeOverride : nativeDefault;

    return (
        <TextField
            fullWidth
            select
            SelectProps={{
                native: nativeDefault,
            }}
            {...rest}
        >
            {
                isNative
                    ? children
                    : React.Children.map(children, wrapForDisabledTooltip)
            }
        </TextField>
    );
}

export const SelectWithFormik = withFormik(Select, { hasErrorState: true, defaultOptimize: false });
export const SelectWithInfoTooltip = withTooltip(Select);
export const SelectWithFormikAndTooltip = withTooltip(SelectWithFormik);
export const SelectWithInfoTooltipAndFormik = withFormik(
    SelectWithInfoTooltip,
    { hasErrorState: true, defaultOptimize: false }
);
