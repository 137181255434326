/* React component to display svg files from Indigo
 *
 * This uses Indigo (via bmaps webservice) to create the svg data
 *
 * Props:
 *      paneId: id of the surrounding div for the image
 *      molText: mol text to convert to SVG
 *      smiles:  smiles to convert to SVG
 *
 *  One or both of molText and smiles should be provided.
 *  If there are both mol and smiles available, mol is used.
 */

import React from 'react';
import { svgImgSrc } from './ui/ui_utils';
import { IndigoWs } from './WebServices';

export class IndigoSvg extends React.Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        if (!props.autoUpdate) return;
        this.resizeObserver = new ResizeObserver(() => this.draw());
    }

    componentDidMount() {
        this.draw();
        const { autoUpdate } = this.props;
        if (!autoUpdate) return;
        this.resizeObserver.observe(this.imgRef.current);
    }

    componentDidUpdate() {
        const { autoUpdate } = this.props;
        if (autoUpdate) return;
        this.draw();
    }

    componentWillUnmount() {
        const { autoUpdate } = this.props;
        if (!autoUpdate) return;
        this.resizeObserver.disconnect();
    }

    async draw() {
        const { molText, smiles } = this.props;
        const node = this.imgRef.current;
        if (!node) return;

        let molData;
        let srcFormat;
        if (molText) {
            molData = molText;
            srcFormat = 'mol';
        } else if (smiles) {
            molData = smiles;
            srcFormat = 'smiles';
        } else {
            molData = '';
            srcFormat = 'mol'; // If there is no data, use 'mol' format to render an empty image
        }

        const baseColor = 0x000000;
        let { clientWidth: width, clientHeight: height } = node;

        // Make sure width and height are not too small otherwise the api will reject the request.
        if (height < 26) height = 26;
        if (width < 26) width = 26;

        const svgData = await IndigoWs.mol2svg(
            molData, srcFormat, baseColor, width, height, { dearomatize: true, lineWidth: 2 }
        );
        this.updateImgSrc(node, svgData, 'image/svg');
    }

    updateImgSrc(node, imgData, imgType) {
        const imgSrc = imgType === 'image/svg' ? svgImgSrc(imgData) : imgData;
        node.setAttribute('src', imgSrc);
    }

    render() {
        const { paneId, style, alt } = this.props;
        // Note: Unifying this with SvgViewer will requiring dealing with the ref in the img
        return (
            <div id={paneId} style={{ padding: '5px', ...style }}>
                <img alt={alt || 'Molecule 2D diagram'} draggable={false} ref={this.imgRef} style={{ height: '100%', width: '100%' }} />
            </div>
        );
    }
}
