/* chem_utils.js */
/**
 * @typedef {import('BMapsModel').Atom} Atom
 */
import { getElementWeight } from '../chemistry/ElementInfo';

export function isHydrogen(atom) {
    return getElement(atom) === 'H';
}

export function isNotHydrogen(atom) {
    return !isHydrogen(atom);
}

export function isCarbon(atom) {
    return getElement(atom) === 'C';
}

export function sameElement(atom1, atom2) {
    return getElement(atom1) === getElement(atom2);
}

/**
 *
 * @param {Atom[]} atoms
 * @param {Boolean?} includeHs
 * @returns
 */
export function filterHydrogens(atoms, includeHs) {
    const keep = !!includeHs;
    return atoms.filter((atom) => keep === isHydrogen(atom));
}

export function removeHydrogens(atoms) { return filterHydrogens(atoms, false); }
export function onlyHydrogens(atoms) { return filterHydrogens(atoms, true); }

export function countHeavyAtoms(atoms) { return removeHydrogens(atoms).length; }
export function calculateMolecularWeight(atoms) {
    return atoms.reduce((mw, atom) => mw + getElementWeight(getElement(atom)), 0);
}

export function isPolarHydrogen(atom) {
    if (isHydrogen(atom)) {
        const partner = getPartnerAtoms(atom)[0];
        if (partner) {
            return ['N', 'O', 'S'].includes(getElement(partner));
        }
    }
    return false;
}

export function isNonPolarHydrogen(atom) {
    return isHydrogen(atom) && !isPolarHydrogen(atom);
}

// Internal functions
function getElement(atom) { return atom.elem; }
function getPartnerAtoms(atom) { return [...atom.bondedAtoms]; }
