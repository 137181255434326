/* ApplicationState */
/**
 * @typedef {Atom | AtomGroup | FragSeriesInfo | FragInfo | CaseData | MapCase} item
 * @typedef {{
 *   atomGroup: AtomGroup?, fragInfo: FragInfo?,
 *   caseData: CaseData?, mapCase: MapCase?,
 *   caseDataCollection: CaseDataCollection?,
 *   connector: import('./server/BFDServerInterface').BFDServerInterface?,
 *   dataConnection: DataConnection?,
 *   permissionManager: PermissionManager?
 * }} dataParents
 */
import {
    Hotspot, AtomGroup, Atom, Workspace,
    CaseData, CaseDataCollection, MapCase,
} from 'BMapsModel';
import DataConnection from './DataConnection';
import { FragInfo, FragSeriesInfo } from './FragList';
import { ConnectionManager } from './server/ConnectionManager';

/**
 * This class contains all state associated with the BMaps application:
 * - DataConnections: server / static connections and their associated data and permissions
 * - Workspace
 * - ServerMonitor
 */
export class ApplicationState {
    constructor(websocketStrategy) {
        this.connectionManager = new ConnectionManager(websocketStrategy);
        /** @type {Workspace} */
        this.workspace = new Workspace(this);
    }

    getCaseDataCollections() {
        return this.connectionManager
            .getDataConnections().map((dataConn) => dataConn.getCaseDataCollection());
    }

    getDataConnections() {
        return this.connectionManager.getDataConnections();
    }

    addDataConnection(dataConnection) {
        this.connectionManager.addDataConnection(dataConnection);
    }

    dumpState() {
        let report = '';
        report += this.connectionManager.dumpState();
        return report;
    }

    /**
     * @param { item } item
     * @returns {dataParents}
     *
     */
    getDataParents(item) {
        const ret = {};
        if (item instanceof Atom) {
            ret.atomGroup = item.getAtomGroup();
            ret.caseData = ret.atomGroup.getCaseData();
        } else if (item instanceof AtomGroup) {
            ret.caseData = item.getCaseData();
        } else if (item instanceof Hotspot) {
            ret.caseData = item.getCaseData();
        } else if (item instanceof FragSeriesInfo) {
            ret.fragInfo = item.getFragInfo();
            ret.caseData = ret.fragInfo.getCaseData();
        } else if (item instanceof FragInfo) {
            ret.caseData = item.getCaseData();
        } else if (item instanceof CaseData) {
            ret.caseData = item;
        } else if (item instanceof MapCase) {
            ret.mapCase = item;
            ret.caseDataCollection = item.getCaseDataCollection();
            ret.caseData = ret.caseDataCollection?.getCaseDataByMapCase(item);
        }

        if (ret.caseData) {
            if (!ret.mapCase) {
                ret.mapCase = ret.caseData.mapCase;
            }
            if (!ret.caseDataCollection) {
                ret.caseDataCollection = ret.caseData.getCaseDataCollection();
            }
        }

        if (ret.caseDataCollection) {
            ret.dataConnection = ret.caseDataCollection.getDataConnection();
            ret.connector = ret.dataConnection?.getConnector();
            ret.permissionManager = ret.dataConnection?.getPermissionManager();
        }

        return ret;
    }

    /**
     * Partition a list of items into a map, according to their dataParents.
     * @param { item[] } items
     * @returns {{
     *     dataParentsMap: Map<dataParents, item>, dataParentsList: dataParents[],
     * }} Map from dataParents object to list of items
     */
    partitionByDataParents(items) {
        const map = new Map();

        function matches(item1, item2) {
            return [...Object.keys(item1)].every((key) => item1[key] === item2[key])
                && [...Object.keys(item2)].every((key) => item1[key] === item2[key]);
        }

        for (const item of items) {
            const dataParents = this.getDataParents(item);
            let dataParentsKey = [...map.keys()].find((key) => matches(key, dataParents));
            if (!dataParentsKey) {
                dataParentsKey = dataParents;
                map.set(dataParentsKey, []);
            }
            map.get(dataParentsKey).push(item);
        }

        return {
            dataParentsMap: map,
            dataParentsList: [...map.keys()],
        };
    }
}
