/**
 * ProteinSelect Component
 * This React component manages the selected Protein for various forms.
 * By default, if only one protein is loaded, this component will return null/be invisible.
 * If however, there are multiple proteins, this will allow the user to select the protien for
 * the form to use.
 *
 * This component requires ProteinContext, and thus must be wrapped by ProteinContextProvider (the
 * context provider) at some level.
*/

import { useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectWithFormik } from './Select';
import { ProteinContext } from './ProteinContextProvider';

export default function ProteinSelect({ SelectComponent=Select, onChange = () => {}, ...rest }) {
    const {
        selectedProtein,
        handleContextChange,
        loadedProteins,
        lookupCaseData,
    } = useContext(ProteinContext);

    return (
        <>
            {loadedProteins?.length > 1
                ? (
                    <SelectComponent
                        label="Protein"
                        name="ProteinSelect"
                        SelectProps={{ native: false }}
                        value={selectedProtein?.uri || ''}
                        onChange={(evt) => {
                            const value = evt.target.value;
                            const newProt = loadedProteins.find((p) => p.uri === value);
                            // TODO: consider having handleContextChange return the new caseData?
                            const newCaseData = lookupCaseData(newProt);
                            handleContextChange(newProt);
                            onChange(evt, { protein: newProt, caseData: newCaseData });
                        }}
                        {...rest}
                    >
                        {loadedProteins.map((prot) => (
                            <MenuItem
                                key={prot.uri}
                                value={prot.uri}
                            >
                                {prot.getLongName()}
                            </MenuItem>
                        ))}
                    </SelectComponent>
                ) : (null)}
        </>
    );
}

/**
 * Note: to take advantage of the withFormik error field and validation,
 * set the `name` and `validate` props on ProteinSelectWithFormik, and make sure
 * the name is one of the initial form values.
 * See Sketcher.jsx as an example.
 */
export function ProteinSelectWithFormik({ ...rest }) {
    return <ProteinSelect SelectComponent={SelectWithFormik} {...rest} />;
}
