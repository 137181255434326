// visualizations/ligand_halos.js

/**
 * @fileoverview Functions to display ligand functional group energy halos in the canvas.
 * @typedef {import('BMapsModel').Atom} Atom
 * @typedef {Atom[]} FunctionalGroup
 *
 * Public interface:
 *     displayFunctionalGroupEnergyHalos(show, functionalGroups)
 *     displayFunctionalGroupHighlight(show, functionalGroups)
 *     removeFunctionalGroupHalos(label)
 *     getFunctionalGroupEnergy(functionalGroup)
 *
 * Internal storage:
 *    functionalGroupHalos: Map<string, CanvasShape> - maps from a halo key to canvas sphere
 * The halo key combines the purpose of the halo (energy vs highlight) and an id for the group.
 *
 * This receives a functional group (array of atoms), calculates the energy, and displays a sphere.
 */

import { drawSphere, removeSphere } from 'BMapsSrc/MainCanvas';
import { makeTooltipLegend } from 'BMapsSrc/ui/TooltipLegend';
import { isNotHydrogen } from 'BMapsSrc/util/chem_utils';
import { getColorFromThresholds } from 'BMapsSrc/util/visualization_utils';

const debug = false;

// These are for functional groups, so scale appropriately.
const ddGsCompoundColorParams = {
    moderateColor: 0x0aff0a,
    neutralColor: 0xc3c5c9,
    strongColor: 0xff0a0a,
    strongThreshold: 10,
    moderateThreshold: 3,
    moderateColorCss: '#0aff0a',
    neutralColorCss: '#c3c5c9',
    strongColorCss: '#ff0a0a',
};

/**
 * Keep track of drawn functional group halos.
 * @type {Map<string, CanvasShape>}
 */
const functionalGroupHalos = new Map();

/**
 * Change the display of functional group halos
 * @param {boolean} show
 * @param {FunctionalGroup[]} functionalGroups
 */
export function displayFunctionalGroupEnergyHalos(show, functionalGroups) {
    if (show) {
        for (const group of functionalGroups) {
            const energy = getFunctionalGroupEnergy(group);
            const energyColor = getColorFromThresholds(energy, ddGsCompoundColorParams);
            if (debug) {
                const debugMsg = `FunctionalGroup (${group.map((x) => x.atom).join()}) has solvation ${energy.toFixed(4)} and halo color ${energyColor}.`;
                console.log(debugMsg);
            }
            displayOneFunctionalGroupHalo(group, energyColor, 'energy', energy);
        }
    } else {
        removeFunctionalGroupHalos('energy');
    }
}

export function displayFunctionalGroupHighlight(show, functionalGroups) {
    if (show) {
        for (const group of functionalGroups) {
            const energyColor = 'white';
            displayOneFunctionalGroupHalo(group, energyColor, 'highlight');
        }
    } else {
        removeFunctionalGroupHalos('highlight');
    }
}

export function removeFunctionalGroupHalos(label) {
    functionalGroupHalos.forEach((sphereId, key) => {
        if (key.startsWith(`${label}-`)) {
            removeSphere(sphereId);
            functionalGroupHalos.delete(key);
        }
    });
}

/**
 * Calculate desolvation energy of a functional group (sum up the cost for each atom)
 * @param {Atom[]} functionalGroup
 * @returns {number?}
 */
export function getFunctionalGroupEnergy(functionalGroup) {
    let energy = null;

    for (const atom of functionalGroup) {
        if (atom.dGs != null && atom.dGs_bound != null) {
            energy += (atom.dGs_bound - atom.dGs);
            if (debug) {
                const debugMsg = `Solvation for ${atom.atom}: ${atom.dGs_bound.toFixed(4)} - ${atom.dGs.toFixed(4)} = ${(atom.dGs_bound - atom.dGs).toFixed(4)}. Total: ${energy.toFixed(4)}`;
                console.log(debugMsg);
            }
        }
    }
    return energy;
}

/**
 *
 * @param {FunctionalGroup} functionalGroup
 * @param {string|number} color
 * @param {string} label - used for internal identification
 * @param {number} ddGs desolvation cost for this functional group
 */
function displayOneFunctionalGroupHalo(functionalGroup, color, label, ddGs) {
    const opacity = 0.6;
    const haloKey = `${label}-${functionalGroup[0].uniqueID}`;

    if (!functionalGroupHalos.get(haloKey)) {
        // Set center to the center of non-hydrogens
        const center = { x: 0, y: 0, z: 0 };
        const nonHs = functionalGroup.filter(isNotHydrogen);
        // Use reduce to add the coordinates together for average
        center.x = nonHs.reduce((sum, nextAtom) => sum + nextAtom.getX(), 0) / nonHs.length;
        center.y = nonHs.reduce((sum, nextAtom) => sum + nextAtom.getY(), 0) / nonHs.length;
        center.z = nonHs.reduce((sum, nextAtom) => sum + nextAtom.getZ(), 0) / nonHs.length;

        // Set radius according to number of atoms
        let radius = 1;
        if (functionalGroup.length === 2 || functionalGroup.length === 3
            || (functionalGroup.length === 4 && nonHs.length === 1)) { // CH3 case; it's not so big
            radius = 1.5;
        } else if (functionalGroup.length === 4) {
            radius = 2;
        } else if (functionalGroup.length > 4) {
            radius = 2.5;
        }

        const shapeOptions = {
            opacity,
            description: ddGs !== undefined
                ? ligandSolvationTooltipInfo(ddGs)
                : 'Functional group highlight', //--- someday put the functional group name here.

        };
        const sphere = drawSphere(center, radius, color, shapeOptions);
        functionalGroupHalos.set(haloKey, sphere);
    }
}

function ligandSolvationTooltipInfo(ddGs, params=ddGsCompoundColorParams) {
    const moderateThresh = params.moderateThreshold.toFixed(1);
    const strongThresh = params.strongThreshold.toFixed(1);

    const value = ddGs != null ? `${ddGs.toFixed(2)} kcal/mol` : 'failed for this functional group';
    return {
        type: 'table',
        title: 'Compound Desolvation',
        body: [
            ['ΔΔGₛ desolvation energy', value],
        ],
        extra: makeTooltipLegend({
            label: 'Desolvation color range',
            ranges: [
                {
                    color: params.moderateColorCss, high: moderateThresh, label: 'Favorable',
                }, {
                    color: params.neutralColorCss, low: moderateThresh, high: strongThresh,
                }, {
                    color: params.strongColorCss, low: strongThresh, label: 'Unfavorable',
                }],
        }),
    };
}
