// utils.js
// General utility functions for use in different components

// Chosing a longer distance introduces a lot of visual busy-ness.
// vdw interactions are commonly 1.4(hydrogen)+1.9(Carbon), or 3.3.
// H-bonds are strong <2.5, moderate <3.2.
// Note, the binding site radius can be easily adjusted by the user with
// Ctrl-Mousewheel.
export const DefaultBindingSiteRadius = 5; // 2.5 was too small, 2x vdw is 3-3.8 or so

// Extension for BMaps Session state file downloads
export const stateFileExtension = 'bmaps';

// Stuff for errors in Preview mode
const PreviewModeError = 'Not available in Preview';
export function makePreviewModeError(msg='') {
    return PreviewModeError + (msg ? `: ${msg}` : '');
}
export function hasPreviewModeError(errors) {
    return errors && errors.find(isPreviewModeError);
}
export function isPreviewModeError(error) {
    return error && error.startsWith(PreviewModeError);
}

export function encodeHtmlEntities(str) {
    return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

// getFileExtension(): taken from http://stackoverflow.com/a/12900504/23746
export function getFileExtension(fname) {
    return fname.slice((fname.lastIndexOf('.') - 1 >>> 0) + 2);
}

export function canvasHasFocus() {
    // Make sure we're not responding to keyboard commands
    // if an input control has focus or there's a modal pane open.
    const anyInputWithFocus = $(':focus:not(button)').length > 0;
    // TODO/NOTE: ttdoan  - A more efficient approach would be to add a counter to a global class
    // (ie. App) and have the modal component increment the counter instead of having jquery
    // exhaustively search the DOM for the modal class. This also eliminates the dependency on the
    // modal class, which may change in the future.
    const anyModalPaneOpen = $('.modalViewer:visible').length > 0 || $('.MuiDialog-root:visible').length > 0;
    // Candidate for MapSelector removal
    const mapSelectorOpen = $('#mapSelectorGroup:visible').length > 0;
    return !anyInputWithFocus && !anyModalPaneOpen && !mapSelectorOpen;
}

export function isComputedWaterAtom(atom) {
    return atom && atom.fragment;
}

/** Join compounds together by spec, with an optional join argument */
export function joinCompoundSpecs(compounds, join=', ') {
    return compounds.map((cmpd) => cmpd.resSpec).join(join);
}

const dnaResidues = new Set(['DA5', 'DA', 'DA3', 'DAN', 'DT5', 'DT', 'DT3', 'DTN', 'DG5', 'DG', 'DG3', 'DGN', 'DC5', 'DC', 'DC3', 'DCN']);
const rnaResidues = new Set(['A5', 'A', 'A3', 'AN', 'U5', 'U', 'U3', 'UN', 'G5', 'G', 'G3', 'GN', 'C5', 'C', 'C3', 'CN']);
export function classifyResidue({
    resname, dnaValue, rnaValue, proteinValue,
} = {}) {
    if (dnaResidues.has(resname)) {
        return dnaValue;
    } else if (rnaResidues.has(resname)) {
        return rnaValue;
    } else {
        return proteinValue;
    }
}

export const AtomRoles = {
    unknown: 'unknown',
    terminal: 'terminal',
    linkage: 'linkage',
    junction: 'junction',
};

// Go through a list of atoms and return the functional groups
// This uses getAtomRole() to assign an atom to "terminal" | "linkage" | "junction" and groups all
// connected terminals together. Return a list of the terminal atom lists.

export function getFunctionalGroups(atomList, groupType) {
    const alreadySeen = [];
    const functionalGroups = [];
    // console.log(`Getting functionalGroups for atoms: ${atomList.map(x=>x.atom)}`);

    // Go through the list of atoms, ignoring them if we've already seen them or they
    // aren't terminals
    for (const atom of atomList) {
        if (alreadySeen.includes(atom)) continue;
        alreadySeen.push(atom);
        const atomRole = getAtomRole(atom);
        // console.log(`Outer: role ${atomRole} Looking at ${atom.atom} (neighbors:
        // ${atom.bondedAtoms.map(x=>x.atom)})`);

        // When we find a new terminal, we have a new functional group. Now fill it out.
        // Look through the new terminal's neighbors to see if there are more terminals to add to
        // the group. If so, add them to the group, and look through their neighbors also.
        if (atomRole === AtomRoles.terminal // generally just terminals, but add NH linkage.
              || (atomRole === AtomRoles.linkage && atom.elem === 'N' && atom.bondedAtoms.length === 3)) {
            const currentGroup = [atom];
            const candidates = atom.bondedAtoms;
            // const candStr = candidates.map(x=>x.atom).join();
            // const fgroups = functionalGroups.map(x=>x.map(y=>y.atom).join(',')).join(' ');
            // console.log(`Outer: ${atom.atom} is a terminal. Candidates:
            // ${candStr}\nFunctional Groups: \n\t${fgroups}`);

            for (let i = 0; i < candidates.length; i++) {
                // The list of candidates will grow as we add the neighbors of any terminals we find
                const candidate = candidates[i];
                if (alreadySeen.includes(candidate)) continue;
                alreadySeen.push(candidate);

                // console.log(`Inner: Looking at ${candidate.atom} (neighbors:
                // ${candidate.bondedAtoms.map(x=>x.atom)})`);
                if (getAtomRole(candidate) === AtomRoles.terminal) {
                    currentGroup.push(candidate);
                    for (const cNeighbor of candidate.bondedAtoms) {
                        candidates.push(cNeighbor);
                    }
                    // console.log(`Inner: ${candidate.atom} is a terminal. Current Group:
                    // ${currentGroup.map(x=>x.atom)}\candidates: ${candidates.map(x=>x.atom)
                    // .join()}\nFunctional Groups: \n\t${functionalGroups.map(x=>x.map(y=>y.atom)
                    // .join(',')).join(' ')}`);
                }
            }

            if (currentGroup.length > 1 || currentGroup[0].elem !== 'H') {
                // Don't include single Hs.  (H atomRole is always terminal)
                functionalGroups.push(currentGroup);
            }
        }
    }

    // console.log(`Found functionalGroups: \n\t${functionalGroups.map(x=>x.map(y=>y.atom)
    // .join(',')).join('\n\t')}`);
    return functionalGroups;
}

/* ATOM ROLE LOGIC PORTED FROM assemble.tcl */

export function getAtomRole(atom) {
    // Cases: terminal, linker, junction (3-4 bonds)
    // Terminal - one connected heavy atom except CF3, carboxylate, others?
    // Linkage -- 2 heavy atoms connected
    // Junction -- 3-4
    // > 4, return "unknown"

    const connected = atom.bondedAtoms.filter((x) => x.elem !== 'H');
    const nConnected = connected.length;

    // Number of heavy atoms connected.
    switch (nConnected) {
        case 1:
            // single terminal carbon (acetylene, methylene), nitrogen (nitrile/cyano, Imine),
            // or oxygen (carbonyl/carboxyl)
            return AtomRoles.terminal;
        case 2: {
            const [at1, at2] = connected;
            const [elem1, elem2] = connected.map((x) => x.elem);
            const [nbonds1, nbonds2] = connected.map((x) => x.bonds.length);
            if ((elem1 === 'O' && nbonds1 === 1) || (elem2 === 'O' && nbonds2 === 1) // =N-O, xC-O
                 || (elem1 === 'N' && nbonds1 === 1) || (elem2 === 'N' && nbonds2 === 1) // nitrile C
                 || (elem1 === 'C' && nbonds1 === 2) || (elem2 === 'C' && nbonds2 === 2)) { // acetylene/methylene C
                return AtomRoles.terminal;
            } else {
                return AtomRoles.linkage;
            }
        }
        case 3:
        case 4: {
            // Carboxylates/NO2/sulfoxide: C/N plus 2 single bonded oxygens (terminal)
            // CF2
            // CF3 : C plus 3 fluorine groups (terminal)
            // Phosphate: P + 3 oxygens (terminal)
            // sulfoxide: S plus one oxygen (linkage)
            // sulfone: 2 plus 2 oxygens (linkage)
            let nTerminalOxygens = 0;
            let nFluorines = 0;
            let nTerminals = 0;
            for (const catom of connected) {
                switch (catom.atom[0]) {
                    case 'O':
                        if (isTerminal(catom)) {
                            nTerminalOxygens++;
                            nTerminals++;
                        }
                        break;
                    case 'F':
                        nFluorines++;
                        break;
                    default:
                        nTerminals += isTerminal(catom);
                }
            }
            const element = atom.elem;
            if ((element in ['C', 'N'] && nTerminalOxygens === 2)
                 || (element === 'P' && nTerminalOxygens === 3) // Phosphate
                 || (element === 'C' && (nFluorines === 3 || nFluorines === 2))
                 || (nConnected === 3 && nTerminals === 2)) {
                return AtomRoles.terminal;
            }
            if ((nConnected === 4 && element === 'S' && nTerminalOxygens === 2)
                 || (nConnected === 3 && nTerminals > 0)) {
                return AtomRoles.linkage;
            }
            return AtomRoles.junction;
        }
        default:
            return AtomRoles.unknown;
    }
}

export function isTerminal(atom) {
    if (atom.ringOrdinals && atom.ringOrdinals.length > 0) return false; // The atom is in a ring

    const connected = atom.bondedAtoms.filter((x) => x.elem !== 'H');
    const nConnected = connected.length;

    return nConnected === 1 || isTrifluoro(atom) || isDifluoro(atom)
        || isAcetyl(atom) || isCyano(atom);
}

function isTrifluoro(atom) {
    if (atom.elem !== 'C') { return false; }
    const cAtoms = atom.bondedAtoms.filter((x) => x.elem === 'F');
    return cAtoms.length === 3;
}

function isDifluoro(atom) {
    if (atom.elem !== 'C') { return false; }
    const cAtoms = atom.bondedAtoms.filter((x) => x.elem === 'F');
    return cAtoms.length === 2;
}

function isAcetyl(atom) {
    for (const bond of atom.bondOrder) {
        if (bond === 3) return 1;
    }
    return 0;
}

function isCyano(atom) {
    const bondedAtoms = atom.bondedAtoms;
    for (let i = 0; i < bondedAtoms.length; i++) {
        if (bondedAtoms[i].elem === 'N' && atom.bondOrder[i] === 2) {
            return 1;
        }
    }
    return 0;
}

export function isNitro(atom) {
    if (atom.elem !== 'N') return false;
    const bondedAtoms = atom.bondedAtoms;
    let Ocount = 0;
    for (let i = 0; i < bondedAtoms.length; i++) {
        const oatom = bondedAtoms[i];
        if (oatom.elem === 'O' && oatom.bondedAtoms.length === 1) Ocount++;
    }
    return Ocount === 2;
}

export function isCarboxylate(atom) {
    if (atom.elem !== 'C') return false;
    const bondedAtoms = atom.bondedAtoms;
    let Ocount = 0;
    for (let i = 0; i < bondedAtoms.length; i++) {
        const oatom = bondedAtoms[i];
        if (oatom.elem === 'O' && oatom.bondedAtoms.length === 1) Ocount++;
    }
    return Ocount === 2;
}

/* END OF ATOM ROLE LOGIC FROM assemble.tcl */

export const TerminalGroups = [
    { formula: 'H', name: 'Hydrogen' },
    { formula: 'CH3', name: 'Methyl' },
    { formula: 'NH2', name: 'Amine' },
    { formula: 'NH3', name: 'Prot-Amine' },
    { formula: 'Cl', name: 'Chlorine' },
    { formula: 'Br', name: 'Bromine' },
    { formula: 'F', name: 'Fluorine' },
    { formula: 'I', name: 'Iodine' },
    { formula: 'OH', name: 'Hydroxyl' },
    { formula: 'O-', name: 'Oxylate' },
    { formula: 'S-', name: 'Thiolate' },
    { formula: 'COO-', name: 'Carboxylate' },
    { formula: 'CO-', name: 'Carboxyl' },
    { formula: 'NO-', name: 'Amine-Oxide' },
    { formula: 'NO2', name: 'Nitro' },
    { formula: 'SH', name: 'Thiol' },
    // Can't handle these yet because they are constrained by the configuration of the atom
    // they are connected to and/or will need adjustment of hydrogens (not yet implemented).
    //    {formula: '=O', name: 'Carbonyl'},
    //    {formula: 'C=CH2', name: 'Methylene'},
    //    {formula: 'C=NH', name: 'Imine'},
    //    {formula: 'C≡C-H', name: 'Acetylene'},
    //    {formula: 'C≡N', name: 'Nitrile'},
    // CF2 also requires bond / hydrogen adjustment
    //    {formula: 'CF2', name: 'Difluoromethylene'},
    { formula: 'CF3', name: 'Trifluoromethyl' },
    { formula: 'PO3', name: 'Phosphate' },
    // NYI -- analogous to COO-
    //    {formula: 'CONH2', name: 'Amide'}
    //    {formula: 'CNH2NH2', name: 'Amidine'}

    // Added:
    // Hydrogen Methyl Hydroxyl Amine
    // Carbonyl Carboxylate [Amino]
    // Nitro [Nitrile]
    // Imine Thiol Chloride Bromide Fluoride Iodide
    // Trifluoro Difluoro
    // [Amide] [Phosphate]

// Need to Add:
// Methylene Acetylene
// Prot-Amine Cyano Oxide-Amine
// Oxylate  Carboxyl
// Thiolate
];

const HalogenFormula = ['Cl', 'Br', 'F', 'I', 'CF2', 'CF3'];
export const HalogenGroups = TerminalGroups.filter((x) => HalogenFormula.includes(x.formula));

export function canModify(visibleCompounds, atom) {
    // In principle, we can replace terminal groups, or we can attempt to
    // grow from any atom.
    // We will restrict growing from protein atoms.
    // In addition, for demo, prevent growing from rings or from
    // double-bonded atoms, as these would produce energy issues.
    // This returns a list of canReplace, canGrow, and unavailable.
    let myCompound = null;
    for (const compound of visibleCompounds) {
        if (compound.hasAtom(atom)) {
            myCompound = compound;
            break;
        }
    }
    // Prevent modifying protein atoms
    if (!atom.hetflag || !myCompound || atom.elem === 'P') return [false, false, false, true];

    const myGroup = myCompound.getFunctionalGroupByAtom(atom);
    let canReplace = myGroup || atom.elem === 'H';
    let canGrow = true;
    let canSubstRing = false;
    let unavailable = false;

    // Consider ring atoms.
    if (atom.ringOrdinals) {
        if (atom.ringOrdinals.length > 1) { // if part of multiple rings, can't modify
            canGrow = false;
            unavailable = true;
        } else {
            // Two cases: atom only links to other ring atoms, or it is also bonded to a
            // non-ring atom (hydrogen, terminal, or linker).
            let ringOnlyAtom = true; // only connected to ring atoms
            let hasBondPossibility = false;
            const myRing = atom.ringOrdinals[0];
            for (const neighbor of atom.bondedAtoms) {
                const hasMyRing = neighbor.ringOrdinals
                                  && neighbor.ringOrdinals.includes(myRing);
                if (!hasMyRing) {
                    ringOnlyAtom = false;
                    if (atom.bondTo(neighbor).orderOriginal === 1) {
                        hasBondPossibility = true;
                    }
                }
            }

            if (ringOnlyAtom || !hasBondPossibility) { // e.g. double bonded oxygen
                canGrow = false;
                canSubstRing = true;
            }
            // Otherwise, there is an atom which supports growing.
        }
    } else if (isCarboxylate(atom) || isNitro(atom)) {
        canReplace = true;
        canGrow = true;
    } else if (!atom.bondOrder.includes(1)
               || (atom.bondedAtoms.length === 1 && atom.elem === 'O')) {
        // We need to have at least one single bond to grow.
        // Suppress others for now, until implemented more broadly.  Need to allow
        // carbon linkers with carbonyl through so other bonds can be chosen.
        canReplace = false;
        canGrow = false;
        unavailable = true;
    }

    return [canReplace, canGrow, canSubstRing, unavailable];
}

/**
 * @description Return sets of atoms can be operated on in certain ways.
 * @param {*} compound
 * @param {*} atomFilterFn filter function to limit the pool of atoms
 * @returns { canReplace: [...atoms...], canGrow: [...atoms...], canSubstRing: [...atoms...]}
 */
export function actionableAtoms(compound, atomFilterFn=(x) => true) {
    const ret = {
        canReplace: [],
        canGrow: [],
        canSubstRing: [],
    };
    const atoms = compound.getAtoms().filter(atomFilterFn);
    for (const atom of atoms) {
        const [canReplace, canGrow, canSubstRing] = canModify([compound], atom);
        if (canReplace) ret.canReplace.push(atom);
        if (canGrow) ret.canGrow.push(atom);
        if (canSubstRing) ret.canSubstRing.push(atom);
    }
    return ret;
}

export class AtomVectorList {
    /**
     *
     * @param {*} needle vector
     * @param {*} haystack vector array
     * @returns return of find on the vector array
     */
    static findVector([a1, b1], list) {
        return list.find(([a2, b2]) => a1 === a2 && b1 === b2);
    }

    /**
     *
     * @param {*} vector vector to add
     * @param {*} destArray vector array to receive
     */
    static addVector(vector, destArray) {
        if (!vector || vector.length === 0) {
            return destArray;
        }

        if (!AtomVectorList.findVector(vector, destArray)) {
            destArray.push(vector);
        }
        return destArray;
    }

    /**
     *
     * @param {*} vectors vectors to add
     * @param {*} destArray vector array to receive
     */
    static addVectors(vectors, destArray) {
        return vectors.reduce(
            (acc, vector) => AtomVectorList.addVector(vector, acc),
            destArray,
        );
    }

    /**
     *
     * @param  {...any} vectorListList
     * @returns
     */
    static merge(...vectorListList) {
        return vectorListList.reduce(
            (acc, vectorList) => AtomVectorList.addVectors(vectorList, acc),
            [],
        );
    }
}
/**
 * @description Return all unique bond vectors pairs that could result from a
 * growing from a particular atom, in both directions.
 * @param {*} atom Atom to grow from
 *
 * Implemented by sending all modifier key combinations to findBondVectorPair
 */
export function allBondVectorPairs(atom) {
    const modifierCombinations = [
        // ctrlKey, shiftKey, altKey
        [false, false, false],
        [true, false, false],
        [false, true, false],
        [true, true, false],
        [false, false, true],
        [true, false, true],
        [false, true, true],
        [true, true, true],
    ];
    const vectors = [];
    for (const modifiers of modifierCombinations) {
        const vector = findBondVectorPair(atom, modifiers);
        AtomVectorList.addVector(vector, vectors);
    }
    return vectors;
}

export function findBondVectorPair(atom, modifierKeys=[]) {
    // Find a partner atom to determine a bond vector for growing (fragment
    // searching). Caller needs to handle the return of an empty list when bond vectors
    // can't be found. The modifier keys are used to select from multiple bond
    // possibilities (Alt/Shift) or reverse the direction of the bond vector (Ctrl).

    // 1. If the atom is a hydrogen or halogen, it is the head of the vector, and the atom
    // it is bonded to is the tail.
    // 2. If an atom is bonded to only one non-hydrogen/non-fluorine atom, then it is a
    // terminal and make it the head of the bond vector, and the one heavy atom the tail.
    // Examples: OH, O-, SH, S-, NH2, NH3, CH3, CF3, ...
    // Exception: double bonds such as carbonyl, imine, triple bonds (acetylene, nitrile).
    // The reason these exceptions are difficult is that changing from double to single
    // bonds has propagating bond effects, not compatible with the rest of the molecule.
    // 3. If the atom is in a ring and is bonded to only one atom outside the ring, make
    // it the tail of the vector and that other atom the head. Again, except when they are
    // connected by a double bond.  This works for hydrogens and heavy atoms both.
    // 4. Atom bonding to two other heavy atoms with single bonds (e.g. methylene,
    // difluoromethyl, amine, ether).  Atom becomes the tail, and the head is the other
    // atom with the most number of atoms connected to it.
    // 5. Other cases with > 2 heavy atoms attached: guanidinium, carboxylate/nitro,
    // amine-oxide.  Generally, these can be treated by selecting a different "upstream"
    // atom.  Thus return an empty array for these.

    const [ctrlKey, shiftKey, altKey] = modifierKeys;
    // console.log(`ctrl ${ctrlKey} shift ${shiftKey} alt ${altKey}`);
    const role = getAtomRole(atom);
    const maybeReverse = (pair) => (ctrlKey ? [pair[1], pair[0]] : pair);
    const ringCase = () => {
        const neighborPossibles = [];
        for (const neighbor of atom.bondedAtoms) {
            if (!neighbor.ringOrdinals || neighbor.ringOrdinals.length === 0) {
                // Can't do double bond case.
                if (atom.bondTo(neighbor).orderOriginal === 1) {
                    neighborPossibles.push(neighbor);
                }
            } else {
                const len = neighbor.ringOrdinals
                    .filter((ord) => atom.ringOrdinals.includes(ord))
                    .length;
                // Return immediately, since this is likely the best option.
                if (len === 0) return maybeReverse([atom, neighbor]);
            }
        }
        const neighborHeavies = neighborPossibles.filter((a) => a.elem !== 'H');
        if (neighborHeavies.length > 0) return maybeReverse([atom, neighborHeavies[0]]);
        if (neighborPossibles.length > 0) {
            return [atom, neighborPossibles[0]]; // don't reverse ring hydrogens
        }
        return [];
    };
    const isBondToAtomSingle = (na) => {
        const bond = atom.bondTo(na);
        // Can be delocalized here, so check kekule order also.
        return bond.orderOriginal === 1 || bond.kekuleOrderOriginal === 1;
    };

    /*
    const roleStr
          = role === AtomRoles.terminal ? "terminal" :
              (role === AtomRoles.linkage ? "linkage" :
              (role === AtomRoles.junction ? "junction" : "unknown"));
    console.log(`atom role: ${roleStr}`)
*/
    if (role === AtomRoles.terminal) {
        // Handles cases 1. and 2., and also carboxylate/nitro/phosphate and nitriles.
        for (const neighbor of atom.bondedAtoms) {
            const len = ['H', 'O', 'F'].filter((x) => x === neighbor.elem).length;
            if ((len === 0 && isBondToAtomSingle(neighbor))
                || (neighbor.elem === 'O' && neighbor.bondedAtoms.length === 2)) { // ether link);
                return [neighbor, atom]; // Don't reverse these
            }
        }
        console.log(`${atom.atom} is a terminal but cannot determine neighbor atom.`);
        return []; // huh?
    } else if (role === AtomRoles.linkage) {
        // Can be in a ring, or not.
        if (atom.ringOrdinals) {
            return ringCase();
        } else {
            // Linkage case
            // First collect the heavy atoms.
            // Normally there will be at least 2 heavy atoms, but consider that there can
            // be one or two double bonds, or a single and triple bond. Growing can only
            // be done on single bonds.
            const atomFilter = (na) => na.bondedAtoms.length !== 1 && isBondToAtomSingle(na);
            // Filter performance ok here because arrays are short.
            let connectors = atom.bondedAtoms.filter(atomFilter);
            const clen = connectors.length;
            switch (clen) {
                case 0: {
                    // Try hydrogens, halogens, etc.
                    const oneBondFilter = (na) => na.bondedAtoms.length === 1
                        && atom.bondTo(na).orderOriginal === 1;
                    connectors = atom.bondedAtoms.filter(oneBondFilter);
                    return connectors.length !== 1
                        ? []
                        : [atom, connectors[0]]; // don't reverse hydrogens
                }
                case 1:
                    return maybeReverse([atom, connectors[0]]);
                case 2: {
                    // Now figure out which direction has more atoms.
                    const ca0 = countConnectingAtoms(atom, connectors[0]);
                    const ca1 = countConnectingAtoms(atom, connectors[1]);
                    // More connected atoms likely means the atom is in the direction of the
                    // bulk of the molecule rather than the peripheral.  Prefer the one toward
                    // the perhipheral for growing.
                    const biggerIndex = ca0 > ca1 || isTerminal(connectors[1]) ? 1 : 0;
                    const index = altKey ? biggerIndex^1 : biggerIndex;
                    const otherAtom = connectors[index];
                    return maybeReverse([atom, otherAtom]);
                }
                default:
                    console.warn(`Linkage ${atom.atom} has ${clen} connecting atoms, but not handled.`);
                    return []; // huh? Junction snuck in here
            }
        }
    } else if (AtomRoles.junction) {
        // Junction of some sort or unknown
        if (atom.ringOrdinals) {
            return ringCase();
        } else {
            // It's rather arbitrary which one to pick first.
            const nonringCandidates = [];
            const ringCandidates = [];
            for (const neighbor of atom.bondedAtoms) {
                if (neighbor.elem === 'H' || !isBondToAtomSingle(neighbor)) continue;
                if (!neighbor.ringOrdinals || neighbor.ringOrdinals.length === 0) {
                    nonringCandidates.push(neighbor);
                } else {
                    ringCandidates.push(neighbor);
                }
            }
            // Non-ring preferred, so add it first.
            const candidates = [...nonringCandidates, ...ringCandidates];
            const maxIndex = candidates.length-1;
            const index = altKey
                ? Math.min(maxIndex, shiftKey ? 3 : 2)
                : Math.min(maxIndex, shiftKey ? 1 : 0);
            return maybeReverse([atom, candidates[index]]);
        }
    } else {
        console.log(`Bonding role of atom ${atom.atom} could not be determined.`);
    }
    return [];
}

function countConnectingAtoms(atom, nextAtom) {
    let count = 0;
    const visited = [atom];
    const alreadyVisited = (x) => visited.includes(x);
    const walk = (watom) => {
        visited.push(watom);
        for (const batom of watom.bondedAtoms) {
            if (alreadyVisited(batom)) continue;
            count++;
            if (!batom.bondedAtoms) {
                console.log(`Huh? ${batom.atom} has no bonds?`);
                continue;
            }
            if (batom.bondedAtoms.length > 1) {
                visited.push(batom);
                walk(batom);
            }
        }
    };
    walk(nextAtom);
    return count;
}

// This class represents a grouping of atoms for a particular purpose: eg: selected atoms.
export class AtomSet {
    constructor() {
        this.map = new Set();
    }

    addAtom(atom) {
        this.map.add(atom);
    }

    removeAtom(atom) {
        this.map.delete(atom);
    }

    removeAtoms(atoms) {
        for (const atom of atoms) {
            this.removeAtom(atom);
        }
    }

    addAtoms(atoms) {
        for (const at of atoms) {
            this.addAtom(at);
        }
    }

    hasAtom(atom) {
        return this.map.has(atom);
    }

    /** @returns {import('BMapsModel').Atom[]} */
    getAtoms() {
        return [...this.map];
    }

    clear() {
        this.map.clear();
    }

    atomCount() {
        return this.map.size;
    }

    equals(otherAtomGroup) {
        if (this.atomCount() !== otherAtomGroup.atomCount()) {
            return false;
        }

        for (const atom of this.getAtoms()) {
            if (!otherAtomGroup.hasAtom(atom)) {
                return false;
            }
        }

        return true;
    }
}

/* MoleculeLoadOptions
 *
 * A class to represent an alignment strategy, including the following data:
 *   1) Whether or not to align
 *   2) A spec for a reference compound (to align to)
 *   3) Whether or not to do "pre-alignment"
 *      (using atom map ids to take advantage of previous coordinates)
 *
 * contructor:
 * new MoleculeLoadOptions (
 *      {   refSpec: <ligand spec>,
 *          preAlign: true|false,
 *          gen3d: true|false,
 *          retainCoords: true|false,
 *          keepBonds: true|false,
 *          alignAction: 'align' | 'none'
 *          alreadyMinimized: true | false,
 *      }
 * )
 */

export class MoleculeLoadOptions {
    static get NoAlignment() { return new MoleculeLoadOptions({ retainCoords: true }); }
    static get Align() { return new MoleculeLoadOptions({ alignAction: 'align' }); }

    /**
     * @param {{
     *  alignAction?: 'none' | 'align'
     *  preAlign?: boolean
     *  refSpec?: string
     *  gen3d?: boolean
     *  retainCoords?: boolean
     *  keepBonds?: boolean
     *  alreadyMinimized?: boolean
     * }} options
     */
    constructor(options={}) {
        if (!options.alignAction) options.alignAction = 'none';
        this.options = options;
    }

    addOptions(options) {
        Object.assign(this.options, options);
    }

    isPlaceOutside() {
        return this.options.alignAction === 'none' && !this.options.retainCoords;
    }

    toOldString() {
        const parts = [];
        parts.push(this.options.alignAction);
        if (this.options.refSpec) {
            parts.push(`spec:${this.options.refSpec}`);
        }
        if (this.options.preAlign) {
            parts.push('pre-align');
        }
        if (this.options.gen3d) {
            parts.push('gen3d');
        }
        if (this.options.retainCoords) {
            parts.push('retain-coords');
        }
        if (this.options.keepBonds) {
            parts.push('keep-bonds');
        }
        return parts.join(',');
    }
}

export class MolDataSource {
    static get Types() {
        return {
            Unspecified: 'Unspecified',
            File: 'File',
            Import: 'Import',
            Sketcher: 'Sketcher',
            ProteinCopy: 'ProteinCopy',
            ScoreCompound: 'ScoreCompound',
            // Other types can be used, eg CDDV
        };
    }

    static FromFile(file, molFormat, molData, encoding) {
        return new MolDataSource({
            sourceType: MolDataSource.Types.File,
            sourceId: file,
            molFormat,
            molData,
            encoding,
        });
    }

    static FromImport(compoundName, molFormat, molData) {
        const sourceObj = new MolDataSource({
            sourceType: MolDataSource.Types.Import,
            molFormat,
            molData,
        });
        if (compoundName) {
            sourceObj.compoundName = compoundName;
        }
        return sourceObj;
    }

    static FromSketcher(compoundName, molFormat, molData, modifiedFrom) {
        const sourceObj = new MolDataSource({
            sourceType: MolDataSource.Types.Sketcher,
            molFormat,
            molData,
            modifiedFrom,
        });
        if (compoundName) {
            sourceObj.compoundName = compoundName;
        }
        return sourceObj;
    }

    static FromProteinCopy(compoundName, molFormat, molData, sourceCmpds) {
        const sourceObj = new MolDataSource({
            sourceType: MolDataSource.Types.ProteinCopy,
            molFormat,
            molData,
        });
        if (compoundName) {
            sourceObj.compoundName = compoundName;
        }
        sourceObj.sourceCmpds = sourceCmpds;
        return sourceObj;
    }

    constructor({
        sourceType=MolDataSource.Types.Unspecified,
        sourceId, compoundName, molFormat, molData, encoding, modifiedFrom,
    }) {
        this.sourceType = sourceType;
        this.sourceId = sourceId;
        this.compoundName = compoundName;
        this.molFormat = molFormat.toLowerCase();
        this.molData = molData;
        this.encoding = encoding;
        this.modifiedFrom = modifiedFrom;
        this.sourceCmpds = null; // only used by FromProtein, excluded from forBfdServer
    }

    label() {
        const id = this.sourceId ? this.sourceId : `${this.compoundName}.${this.molFormat}`;
        return `${this.sourceType}:${id}`;
    }

    forBfdServer() {
        const knownKeys = [
            'sourceType', 'sourceId',
            'compoundName', 'molFormat', 'molData',
            'encoding',
            'modifiedFrom',
        ];
        return knownKeys.reduce(
            (acc, nextKey) => ({ ...acc, [nextKey]: this[nextKey] }),
            {}
        );
    }
}

export function showAlert(msg, title) {
    if (typeof (jAlert) !== 'undefined') {
        jAlert(msg, title);
    } else {
        console.warn(`User Alert: ${title} - ${msg}`);
    }
}

export async function userConfirmation(message, title, options={}) {
    return new Promise((resolve) => {
        if (options.forceResult == null) {
            jConfirm(message, title, (result) => resolve(result));
        } else {
            resolve(options.forceResult);
        }
    });
}
