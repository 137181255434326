// Local
import { CDDVExportTab } from '../../../../plugin/CDDV/CDDVTab';
import { useExportDataFetch } from '../exportUtils';

const config = {
    formats: ['mol2', 'smi'],
    allowMultiple: false,
    allowStaticMode: false,
    allowMultipleProteins: false,
};

export default function ExportCDDVaultTab({ selected }) {
    const {
        errors, formattedData,
        context: { desc },
    } = useExportDataFetch(selected, config, 'CDD Vault');
    const mol2Data = formattedData['mol2'];
    const smiData = formattedData['smi'];

    return (
        <CDDVExportTab
            loading={!mol2Data || !smiData}
            exportPane
            exportData={{ name: desc, data: mol2Data, smiles: smiData }}
            error={errors.length ? errors[0] : null}
            title="CDD Vault Molecule Upload"
        />
    );
}
