// CompoundHeritage.js
import { simpleCond } from 'BMapsSrc/util/js_utils';
import { getFullResidueId } from 'BMapsSrc/util/mol_info_utils';
import { MolDataSource } from 'BMapsSrc/utils';
import { App } from '../BMapsApp';

export class CompoundHeritage {
    constructor() {
        this.list = [];
    }

    steps() {
        return [...this.list];
    }

    setHeritage(list) {
        this.list = [...list];
    }

    record(newUnit) {
        if (newUnit.parent) {
            for (const old of newUnit.parent.getHeritage()) {
                this.list.push(old);
            }
        }
        this.list.push(newUnit);
    }

    static addLigand(self) {
        self.recordHeritage(new HeritageUnit(self, null, 'Co-Crystal Ligand'));
    }

    static addTerminalReplacement(parent, children, detailObj) {
        const detail = `${detailObj.atom.atom} -> ${detailObj.replacementGroup}`;
        for (const child of [].concat(children)) {
            child.recordHeritage(new HeritageUnit(child, parent, 'Terminal Replace', detailObj, detail));
        }
    }

    static addDock(parent, children, detailObj) {
        for (const child of [].concat(children)) {
            let detailEntries = {};
            const dockingProgram = detailObj?.dockingProgram;
            switch (dockingProgram) {
                case 'autodock': {
                    const {
                        dockingScore, dockingPoseRank, dockingScoreDelta,
                        // boxParams, rmsdFromBestUB, rmsdFromBestLB, // for commented code below
                    } = detailObj;
                    detailEntries = {
                        'Autodock Score': dockingScore?.toFixed(2),
                        'Pose Rank': dockingPoseRank,
                        'Delta from best score': dockingScoreDelta?.toFixed(2),
                        // Uncomment to display docking target (ligand, hotspot or selected atoms)
                        /* eslint-disable-next-line quote-props */
                        // 'target': boxParams?.refObj?.description;
                        //
                        // These RMSD values have been removed from presentation to the user.
                        // Upper bound is based on a strict 1-1 atom correspondence
                        // Lower bound allows using other atoms of the same type,
                        // which is more meaningful when there is symmetry.
                        // 'RMSD from best pose (upper bound)': rmsdFromBestUB?.toFixed(2),
                        // 'RMSD from best pose (best fit)': rmsdFromBestLB?.toFixed(2),
                    };
                    break;
                }
                case 'diffdock': {
                    const { rank, confidence, confDelta } = detailObj;
                    detailEntries = {
                        'Pose Rank': rank,
                        'DiffDock Confidence': confidence,
                        'Delta from best confidence': confDelta?.toFixed(2),
                    };
                    break;
                }
                default:
                    // detailEntries defaults to empty object above
            }
            const detailText = Object.entries(detailEntries).reduce(
                (acc, [label, value]) => (value != null ? acc.concat(`${label}: ${value}`) : acc),
                []
            ).join(', ') || undefined;
            child.recordHeritage(new HeritageUnit(child, parent, 'Docked', detailObj, detailText));
        }
    }

    static addFragmentGrow(parent, children, detailObj) {
        const selectionID = detailObj.suggestion.selectionIDs[detailObj.suggestion.selectedIndex];
        let detail = `${detailObj.atom.atom} -> ${detailObj.suggestion.name}`;
        if (detailObj.suggestion.frags?.length > 0) {
            const selected = detailObj.suggestion.frags.find((x) => x.poseSerialNo === selectionID);
            const excp = selected.exchemPotential.toFixed(1);
            detail += `, Frag. FE (ExCP): ${excp}`;
        }
        for (const child of [].concat(children)) {
            child.recordHeritage(new HeritageUnit(child, parent, 'Fragment Grow', detailObj, detail));
        }
    }

    static addFindNear(parent, children, detailObj) {
        const selectionID = detailObj.suggestion.selectionIDs[detailObj.suggestion.selectedIndex];
        const selectedFrag = detailObj.suggestion.frags.find((x) => x.poseSerialNo === selectionID);
        const excp = selectedFrag.exchemPotential.toFixed(1);
        const detail = `${getFullResidueId(detailObj.atom)}, ${detailObj.atom.atom}, Frag. FE (ExCP): ${excp}`;
        for (const child of [].concat(children)) {
            child.recordHeritage(new HeritageUnit(child, parent, 'Search Nearby', detailObj, detail));
        }
    }

    static addMinimize(self, detailObj) {
        let detailText = '';
        if (App.getDataParents(self).mapCase) {
            const score = self.energyInfo.getEnergyScore();
            detailText = `Score: ${score.toFixed(1)}`;
        } else {
            // Don't bother displaying the internal energy in the compound heritage
        }

        self.recordHeritage(new HeritageUnit(self, null, 'Energy Minimized', detailObj, detailText));
    }

    static addMolSource(parent, children, molSource) {
        const label = (p) => {
            switch (molSource.sourceType) {
                case MolDataSource.Types.ProteinCopy: return 'Copied to Protein';
                case MolDataSource.Types.ScoreCompound: return 'Copied to Protein by Score Compound';
                default:
                    return `${p ? 'Modified via' : 'Added via'} ${molSource.sourceType}`;
            }
        };
        for (const child of [].concat(children)) {
            child.recordHeritage(
                new HeritageUnit(child, parent, label(parent), molSource, molSource.sourceId)
            );
        }
    }

    static addRename(self, detailObj) {
        self.recordHeritage(new HeritageUnit(self, null, 'Renamed', detailObj));
    }

    static addDuplicate(parent, children) {
        for (const child of [].concat(children)) {
            child.recordHeritage(new HeritageUnit(child, parent, 'Duplicated'));
        }
    }

    static addStarterCompounds(children, isValidPdbCode) {
        const re = /(...)\.(....)/;
        for (const child of [].concat(children)) {
            const match = child.resSpec.match(re);
            if (match && isValidPdbCode(match[2])) {
                child.recordHeritage(new HeritageUnit(child, null, `Co-Crystal Ligand from ${match[2]}`));
            } else {
                child.recordHeritage(new HeritageUnit(child, null, 'Sample compound'));
            }
        }
    }

    /**
     * Capture all compound heritage to save into a session object.
     * There is not a general way to address a specific loaded MapCase, so this uses the index
     * into the list of all captured casedatas.
     * @param {import('BMapsModel').Workspace} workspace
     */
    static captureAllHeritage(workspace) {
        const ret = [];
        // Use same procedure as SavedState.CaptureDataConnections to list caseDataConnections
        const caseDataToIndexMap = new Map();
        for (const { caseDataCollection } of workspace.getDataConnections()) {
            const caseDatas = caseDataCollection.getAllCaseData().filter((cd) => !cd.isEmpty());
            for (const [caseDataI, caseData] of caseDatas.entries()) {
                caseDataToIndexMap.set(caseData, caseDataI);
            }
        }

        function serializeCmpd(cmpd) {
            const caseData = App.getDataParents(cmpd).caseData;
            const index = caseDataToIndexMap.get(caseData);
            return {
                mapCaseIndex: index,
                mapCaseName: caseData.mapCase?.displayName,
                resSpec: cmpd.resSpec,
            };
        }

        for (const cmpd of workspace.getLoadedCompounds()) {
            const heritage = cmpd.getHeritage();
            if (heritage.length === 0) continue;

            const serializedHeritage = heritage.map((unit) => {
                const {
                    self, parent, label, detailText, // omitting detailObject for now
                } = unit.forSerialization();
                const serializedUnit = {};
                if (self) {
                    serializedUnit.self = serializeCmpd(self);
                } else if (unit.missingResSpec) {
                    // The compound for this heritage unit was removed.
                    serializedUnit.self = { resSpec: unit.missingResSpec };
                }
                if (parent) serializedUnit.parent = serializeCmpd(parent);
                if (label) serializedUnit.label = label;
                // Omit detailObject for now, which can't be serializeed if it self-references
                // if (detailObject) serializedUnit.detailObject = detailObject;
                if (detailText) serializedUnit.detailText = detailText;
                return serializedUnit;
            });

            ret.push({
                compound: serializeCmpd(cmpd),
                heritage: serializedHeritage,
            });
        }
        return ret;
    }

    /**
     * @param {{ caseData: import('BMapsModel').CaseData, proteinErrors: Array}[]} restoredData
     * @param {ReturnType<typeof CompoundHeritage.captureAllHeritage>} heritageData
     */
    static restoreAllHeritage(restoredData, heritageData) {
        /**
         * @param {{ mapCaseIndex: number, mapCaseName: string, resSpec: string} param0
         */
        function lookupCompound({ mapCaseIndex, mapCaseName, resSpec }) {
            // mapCaseIndex is the caseData index of loaded cases.
            // mapCaseName is just used for sanity check and error messages
            // Both are null in the case of a missing compound.

            if (mapCaseIndex == null && mapCaseName == null) {
                return null; // missing compound case
            }
            const { caseData } = restoredData[mapCaseIndex] || {};
            // Note: In no protein case mapCase and mapCaseName are undefined and will be ===
            if (!caseData || caseData.mapCase?.displayName !== mapCaseName) {
                throw new Error(`Failed to load compound heritage because of missing ${mapCaseName}`);
            }
            const cmpd = caseData.getCompoundBySpec(resSpec);
            return cmpd;
        }

        for (const { compound, heritage } of heritageData) {
            try {
                const cmpd = lookupCompound(compound);

                const newHeritage = heritage.map((unit) => {
                    const self = unit.self && lookupCompound(unit.self);
                    const parent = unit.parent && lookupCompound(unit.parent);
                    const { label, detailObject, detailText } = unit;
                    const newUnit = new HeritageUnit(self, parent, label, detailObject, detailText);
                    if (!self && unit.self) {
                        newUnit.missingResSpec = unit.self.resSpec;
                    }
                    return newUnit;
                });

                cmpd.setHeritage(newHeritage);
            } catch (e) {
                console.error(`Failed to load compound heritage for ${compound.resSpec}`, e);
            }
        }
    }
}

export class HeritageUnit {
    constructor(self, parent, label, detailObject, detailText) {
        this.self = self;
        this.parent = parent;
        this.label = label;
        this.detailObject = detailObject;
        this.detailText = detailText;
        this.missingResSpec = null;
    }

    get resSpec() {
        return simpleCond([
            [this.self, this.self?.resSpec],
            [this.missingResSpec, this.missingResSpec], // consider a suffix here, like 'missing'
            [true, 'missing compound'],
        ]);
    }

    hasCompound() {
        return !!this.self;
    }

    forSerialization() {
        return { ...this };
    }
}
