// EventBroker
// A class to manage pub-sub of events in the system, eg. zapAll
// The idea is to be a singleton class with static methods.

/**
 * @typedef {{
 * zapAll: 'zapAll',
 * dataLoaded: 'dataLoaded',
 * energyCalc: 'energyCalc',
 * activeCompound: 'activeCompound',
 * compoundChanged: 'compoundChanged',
 * visibilityChanged: 'visibilityChanged',
 * nodeSelectionChanged: 'nodeSelectionChanged',
 * nodeStarredChanged: 'nodeStarredChanged',
 * nodeExpansionChanged: 'nodeExpansionChanged',
 * atomGroupStateChanged: 'atomGroupStateChanged',
 * hotspotsChanged: 'hotspotsChanged',
 * viewStateChanged: 'viewStateChanged',
 * starterAvailablity: 'starterAvailablity',
 * debugInfo: 'debugInfo',
 * resize: 'resize',
 * connectionsStatuschanged: 'connectionStatusChanged'
 * connectionStatusChangedHandler: 'connectionStatusChangedHandler',
 * sessionError: 'sessionError',
 * atomMouse: 'atomMouse',
 * atomMouse2d: 'atomMouse2d',
 * shapeMouse: 'shapeMouse',
 * backgroundMouse: 'backgroundMouse',
 * keyDown: 'keyDown',
 * keyUp: 'keyUp',
 * redisplayRequest: 'redisplayRequest',
 * refreshAtomDisplay: 'refreshAtomDisplay',
 * redrawSceneRequest: 'redrawSceneRequest',
 * modifyingCompound: 'modifyingCompound',
 * redisplayFragments: 'redisplayFragments',
 * mainContextMenuWrapperClose: 'mainContextMenuWrapperClose',
 * setDisplayStyle: 'setDisplayStyle',
 * setPermissions: 'setPermissions',
 * displayLoadingScreen: 'displayLoadingScreen',
 * addAtoms: 'addAtoms',
 * removeAtoms: 'removeAtoms',
 * setAtomSelected: 'setAtomSelected',
 * setAtomColor: 'setAtomColor',
 * pauseRedisplay: 'pauseRedisplay',
 * escapeKey: 'escapeKey',
 * mapSelectorCmd: 'mapSelectorCmd',
 * proteinLoaded: 'proteinLoaded',
 * askSketcher: 'askSketcher',
 * hoverCompound: 'hoverCompound',
 * reconnect: 'reconnect',
 * modalDialogRequest: 'modalDialogRequest',
 * serverQueueUpdate: 'serverQueueUpdate',
 * modalClosed: 'modalClosed',
 * mapSelectorClosed: 'mapSelectorClosed',
 * openProteinImportTab: 'openProteinImportTab',
 * showFragmentPane: 'showFragmentPane',
 * energyCalc: 'energyCalc',
 * displayVondVectors: 'displayBondVectors',
 * }} eventList
 * This is most of the event broker subscriptions, but it might not be all of them.
 * Sometimes eventBroker actions get added programatically and I might not have found
 * them while searching.
 *
 * @typedef {eventList[keyof eventList]} event
 */
export class EventBroker {
    static registerModule(moduleName, functions) {
        const module = EventBroker.ensureModule(moduleName);
        Object.assign(module, functions);
    }

    static getModule(moduleName) {
        return EventBroker.ensureModule(moduleName);
    }

    static ensureModule(moduleName) {
        let module = TheEventBroker.modules.get(moduleName);
        if (!module) {
            module = {};
            TheEventBroker.modules.set(moduleName, module);
        }
        return module;
    }

    /**
     * Subscribe to an event.
     * @param {event} event
     * Please add subscriptions to the eventbroker jsdoc as you create new ones.
     * @param {Function} handler
     * @returns {Function} return the handler, for tracking arrow functions to unsubscribe later.
     */
    static subscribe(event, handler) {
        TheEventBroker.doSubscribe(event, handler);
        return handler;
    }

    /**
     * Unsubscribe from an event.
     * @param {event} event
     * Please add subscriptions to the eventbroker jsdoc as you create new ones.
     * @param {Function} handler
     */
    static unsubscribe(event, handler) {
        TheEventBroker.doUnsubscribe(event, handler);
    }

    /**
     * Publish an event to all subscribers.
     * @param {event} event
     * @param {*} data
     */
    static publish(event, data) {
        TheEventBroker.doPublish(event, data);
    }

    static async withIgnoredEvents(event, promise) {
        try {
            if (EventBroker.LogAll) console.log(`Ignoring events: ${event}`);
            TheEventBroker.ignored.set(event, true);
            return await promise;
        } finally {
            TheEventBroker.ignored.delete(event);
            if (EventBroker.LogAll) console.log(`Resuming events: ${event}`);
        }
    }

    constructor() {
        this.broker = new Map();
        this.modules = new Map();
        this.ignored = new Map();
    }

    doSubscribe(event, handler) {
        if (!this.broker.get(event)) {
            this.broker.set(event, []);
        }
        this.broker.get(event).push(handler);
        if (EventBroker.LogAll) console.log(`Subscribed to ${event}`);
    }

    doUnsubscribe(event, handler) {
        const idx = this.broker.get(event).indexOf(handler);
        if (idx >= 0) {
            this.broker.get(event).splice(idx, 1);
        }
        if (EventBroker.LogAll) console.log(`Unsubscribed from ${event}`);
    }

    doPublish(event, data) {
        if (this.ignored.get(event)) {
            if (EventBroker.LogAll) console.log(`Ignoring published event: ${event}`);
            return;
        }
        if (EventBroker.LogAll) console.log(`Publishing event: ${event}`);
        const handlers = this.broker.get(event);
        if (handlers) {
            for (const handler of handlers) { handler(event, data); }
        }
    }
}
const TheEventBroker = new EventBroker();
EventBroker.LogAll = false;
