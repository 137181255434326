import { useEffect, useState } from 'react';
import styled from 'styled-components';
// Material UI
import Grid from '@mui/material/Grid';
// Local
import { ContrastButton } from '../../../common/Buttons';
import { useExportDataFetch } from '../exportUtils';
import { pubChemLookup } from '../../../../WebServices';
import { ErrorMessage, Notice, CopyToClipboard } from '../Components';
import { WorkingIndicator } from '../../../ui_utils';

const config = {
    formats: ['sdf', 'smiNoName'],
    allowMultiple: false,
    allowStaticMode: true,
    allowMultipleProteins: false,
    disallowAtoms: true,
};

const StyledButton = styled(ContrastButton).attrs(({ $isMobile }) => ({
    variant: 'outlined',
    fullWidth: $isMobile,
}))`
    ${({ $isMobile }) => ($isMobile ? 'margin-bottom: 1em' : 'margin-right: 1em')};
`;

export default function ExportPubChemTab({ selected }) {
    const [title, setTitle] = useState('');
    const [cmpdName, setCmpdName] = useState('');
    const [cid, setCid] = useState(null);
    const { errors, formattedData, context } = useExportDataFetch(selected, config, 'PubChem');
    const { exportTarget, setLoading, isMobile } = context;
    const [sdfData, setSdfData] = useState(null);
    const [pubChemState, setPubChemState] = useState(null); // null, 'fetching', 'fetched'

    const exportSdfData = formattedData['sdf'];
    const exportSmiData = formattedData['smiNoName'];

    async function searchPubChem() {
        if (!exportTarget.compounds?.length > 0) {
            return;
        }

        const compoundSpec = exportTarget.compounds[0].resSpec;
        const match = compoundSpec.match(/^([a-z0-9]*)\.[a-z0-9]*:[a-z0-9]*$/i);
        const ligandName = match ? match[1] : null;
        setSdfData(exportSdfData);
        setPubChemState('fetching');
        setLoading(true, 'Searching PubChem database...');

        const pubchemResults = await pubChemLookup({ sdf: exportSdfData, name: ligandName });
        setTitle(pubchemResults.Title || '');
        setCid(pubchemResults.CID || null);
        setCmpdName(pubchemResults.IUPACName || '');
        setPubChemState('fetched');
        setLoading(false);
    }

    useEffect(() => {
        if (selected && !errors.length) {
            // Only retrieve PubChem data if the SDF data changed.
            if (exportSdfData && exportSdfData !== sdfData) {
                searchPubChem(exportSdfData);
            }
        }
    }, [selected, errors, exportSdfData]);

    let mainContent;
    let smiContent;

    if (errors.length) {
        mainContent = (
            <Notice title="Problem searching PubChem">
                <ErrorMessage>{errors[0]}</ErrorMessage>
            </Notice>
        );
    } else if (pubChemState === 'fetching') {
        mainContent = <Notice title="Searching PubChem..."><WorkingIndicator /></Notice>;
    } else if (pubChemState === 'fetched') {
        if (cid) {
            mainContent = (
                <>
                    <Grid item component="h4">Results from PubChem:</Grid>
                    <Grid item component="a" className="pubchem_link" href={`https://pubchem.ncbi.nlm.nih.gov/compound/${cid}`} target="_blank" rel="noreferrer">
                        <div id="pubchem_cmpd">
                            <img id="pubchem_cmpdImg" alt={`PubChem Compound ${cid}`} src={`https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/cid/${cid}/png`} />
                        </div>
                    </Grid>
                    <Grid>
                        <div id="cmpd_names">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <strong>PubChem CID:</strong>
                                    {' '}
                                    {cid}
                                </div>
                                <CopyToClipboard copyTarget={cid} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <strong>PubChem name:</strong>
                                    {' '}
                                    {title}
                                </div>
                                <CopyToClipboard copyTarget={title} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <strong>IUPAC name:</strong>
                                    {' '}
                                    {cmpdName}
                                </div>
                                <CopyToClipboard copyTarget={cmpdName} />
                            </div>
                        </div>
                    </Grid>
                </>
            );
        } else {
            mainContent = (
                <Notice title="Results from PubChem:">
                    <span style={{ fontStyle: 'italic' }}>No exact match found</span>
                </Notice>
            );
        }

        if (exportSmiData) {
            const query = encodeURIComponent(exportSmiData);
            smiContent = (
                <Grid container item direction="column" spacing={2}>
                    <Grid container item justifyContent="center"><h4>More on PubChem:</h4></Grid>
                    <Grid container item justifyContent="center">
                        {!!cid && <StyledButton $isMobile={isMobile} className="pubchem_link" href={`https://pubchem.ncbi.nlm.nih.gov/compound/${cid}`} target="_blank" rel="noreferrer">View this structure</StyledButton> }
                        <StyledButton $isMobile={isMobile} id="pubchem_simsearch" href={`https://pubchem.ncbi.nlm.nih.gov/#query=${query}&tab=similarity`} target="_blank" rel="noreferrer">Similarity search</StyledButton>
                        <StyledButton $isMobile={isMobile} id="pubchem_subsearch" href={`https://pubchem.ncbi.nlm.nih.gov/#query=${query}&tab=substructure`} target="_blank" rel="noreferrer">Substructure search</StyledButton>
                    </Grid>
                </Grid>
            );
        }
    } else {
        mainContent = (
            <Notice title={
                <ErrorMessage>Unable to prepare compound data for PubChem search</ErrorMessage>
            }
            />
        );
    }

    return (
        <Grid container spacing={2} direction="column" alignItems="center">
            {mainContent}
            {smiContent}
        </Grid>
    );
}
