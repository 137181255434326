// model/SavedState.js

/**
 * @fileoverview A class for managing saving and restoring the state of BMaps
 *
 * @typedef {import('BMapsModel').Workspace} Workspace
 * @typedef {import('BMapsModel').CaseData} CaseData
 * @typedef {import('BMapsSrc/style_manager').AtomGroupStyle} AtomGroupStyle
 * @typedef {import('BMapsSrc/DataConnection').default} DataConnection
 */

import _ from 'lodash';
import { App } from 'BMapsSrc/BMapsApp';
import { StyleManager } from 'BMapsSrc/style_manager';
import { Atom, CompoundHeritage, StarterCompounds } from 'BMapsModel';
import { getColorSchemeInfo, getBindingSiteDistance } from '../redux/prefs/access';
import { getTargetInfoMap } from '../redux/projectState/access';
import { FoldingDataImportCase, MapCase, UserDataImportCase } from './MapCase';

const semver = require('semver');
/**
 * @description SavedState class
 */
export class SavedState {
    static get LatestVersion() { return '2.3.1'; }

    constructor(
        displayState,
        styleState,
        connections,
        heritage,
        version=SavedState.LatestVersion
    ) {
        this.Version = version;
        this.Display = displayState;
        /** @type {AtomGroupStyle[]} */
        this.Style = styleState;
        /** @type {DataConnection[]} */
        this.Connections = connections;
        /** @type {ReturnType <typeof CompoundHeritage.captureAllHeritage>} */
        this.Heritage = heritage;
    }

    versionLessThan(input) {
        return semver.lt(this.Version, input);
    }

    versionAtLeast(input) {
        return semver.gte(this.Version, input);
    }

    supportsFeature(feature) {
        return feature && feature.minVersion
            && this.versionAtLeast(feature.minVersion);
    }

    /** @param {Workspace} workspace */
    static Capture(workspace) {
        const connections = SavedState.CaptureDataConnections(workspace);
        const displayState = {
            Options: {
                BackgroundColor: getColorSchemeInfo().name,
                BindingSiteRadius: getBindingSiteDistance(),
            },
            State: workspace.displayState.saveState(),
            TreeState: workspace.systemTree.saveState(),
        };

        const styleState = StyleManager.getGlobalStyleState();
        const heritage = CompoundHeritage.captureAllHeritage(workspace);

        return new SavedState(displayState, styleState, connections, heritage);
    }

    static Load(objIn) {
        const obj = (typeof objIn === 'string')
            ? JSON.parse(objIn)
            : { ...objIn }; // make sure we have a copy so version update doesn't affect original

        if (!semver.valid(obj.Version)) obj.Version = '0.1.0';
        return this.LatestVersionObjConverter(obj);
    }

    static LatestVersionObjConverter(obj) {
        let working = { ...obj };

        // Bail if attempting to import a more advanced major version.
        if (semver.major(working.Version) > semver.major(SavedState.LatestVersion)) {
            throw new Error('This saved session is from a newer version of BMaps and cannot be loaded.');
        }

        // Transform previous format into the latest format, step by step
        if (semver.lt(working.Version, '2.0.0')) {
            working = {
                Version: '2.0.0',
                Display: _.omit(working.Display, ['AtomGroupState']),
                Style: { ...working.Style },
                Connections: [
                    {
                        Mode: null,
                        LoadedCases: [
                            {
                                Protein: { ...working.Protein },
                                Compounds: [...working.Compounds],
                                AtomGroupState: { ...working.Display.AtomGroupState },
                                Styles: { AtomGroupsColorState: {} },
                            },
                        ],
                    },
                ],
            };
        }

        // Transform 2.0.0 to 2.0.1
        if (semver.lt(working.Version, '2.0.1')) {
            for (const conn of working.Connections) {
                for (const loadedCase of conn.LoadedCases) {
                    loadedCase.Styles.AtomGroupsMolStyleState = {};
                }
            }
        }

        // 2.0.2 introduced loadedCase.Protein.UserData.loadOptions
        // However this is only for user structures and we can't apply a default to old sessions.

        // Transform 2.0.1 to 2.1.0
        if (semver.lt(working.Version, '2.1.0')) {
            for (const conn of working.Connections) {
                for (const loadedCase of conn.LoadedCases) {
                    loadedCase.TargetInfo = { isTarget: true };
                }
            }
        }

        // Transform 2.1.0 to 2.2.0
        if (semver.lt(working.Version, '2.2.0')) {
            for (const conn of working.Connections) {
                for (const loadedCase of conn.LoadedCases) {
                    for (const [key, value] of Object.entries(
                        loadedCase.Styles.AtomGroupsColorState
                    )) {
                        loadedCase.Styles[key] = {
                            ...loadedCase.Styles[key],
                            color: value.color,
                            colorAllAtoms: typeof value.carbonsOnly === 'undefined'
                                ? false
                                : !value.carbonsOnly,
                        };
                    }
                    for (const [key, value] of Object.entries(
                        loadedCase.Styles.AtomGroupsMolStyleState
                    )) {
                        loadedCase.Styles[key] = {
                            ...loadedCase.Styles[key],
                            molStyle: value,
                        };
                    }
                    delete loadedCase.Styles.atomGroupsColorState;
                    delete loadedCase.Styles.atomGroupsMolStyleState;
                }
            }
        }

        if (semver.lt(working.Version, '2.3.0')) {
            working.Heritage = [];
        }

        //
        // Add any additional transforms here, from 2.1.0 to the next...
        //

        // Finished transforming
        // Use the original version in the final result, not the latest.
        // This is so supportsFeature version checks can be used downstream
        return new SavedState(
            working.Display,
            working.Style,
            working.Connections,
            working.Heritage,
            obj.Version
        );
    }

    static CaptureProtein(protein) {
        if (!protein || !(protein instanceof MapCase)) return null;

        const capturedProteinData = {
            CaseUri: protein.uri,
            DisplayName: protein.displayName,
            ScopedProperties: exportMapCaseScopedProperties(protein),
        };

        const sampleCompoundInfo = App.getDataParents(protein).caseData.sampleCompoundInfo;
        const sampleStatesToTrack = [StarterCompounds.Loaded, StarterCompounds.Dismissed];
        if (sampleStatesToTrack.includes(sampleCompoundInfo.availability)) {
            capturedProteinData.SampleCompoundStatus = sampleCompoundInfo.availability;
        }
        if (protein instanceof UserDataImportCase || protein instanceof FoldingDataImportCase) {
            capturedProteinData.UserData = {
                data: protein.data,
                format: protein.data_format,
                name: protein.molecule_name,
                pdbid: protein.pdbID,
                sourceDesc: protein.sourceDesc,
                loadOptions: protein.loadOptions,
            };

            if (protein instanceof FoldingDataImportCase) {
                capturedProteinData.UserData.foldingData = protein.foldingData;
            }
        }

        return capturedProteinData;
    }

    static CaptureCompound(compound, active, visible) {
        const obj = {
            name: compound.resSpec,
        };
        if (!compound.isLigand()) {
            obj.mol = compound.getMol2000();
            obj.energies = exportCompoundEnergies(compound);
            obj.solvation = exportCompoundSolvation(compound);
        }
        if (compound === active) obj.active = true;
        if (visible.includes(compound)) obj.visible = true;
        obj.scopedProperties = exportCompoundScopedProperties(compound);
        return obj;
    }

    /**
     * @param {Workspace} workspace
     * Collect all data connections with their connection type and case data (protein & compounds)
     */
    static CaptureDataConnections(workspace) {
        const Connections = [];
        const dataConnections = workspace.getDataConnections();

        for (const { connector, caseDataCollection } of dataConnections) {
            const caseDatas = caseDataCollection.getAllCaseData();
            const Mode = connector.getModeForSavedState();
            const LoadedCases = caseDatas
                .filter((caseData) => !caseData.isEmpty())
                .map((caseData) => SavedState.CaptureCaseData(caseData, workspace));
            Connections.push({ Mode, LoadedCases });
        }
        return Connections;
    }

    /**
     * Collect data for one caseData: protein, compounds, atomgroup state, and atomgroup style
     * @param {CaseData} caseData
     * @param {Workspace} workspace
     */
    static CaptureCaseData(caseData, workspace) {
        const Protein = this.CaptureProtein(caseData.mapCase);
        const Compounds = [];
        const activeCompound = workspace.getActiveCompound();
        const visibleCompounds = workspace.getVisibleCompounds(false);
        const compounds = caseData.getCompounds();
        const AtomGroupState = workspace.atomGroupState.saveState(caseData);
        const Styles = StyleManager.getAllAtomGroupStyles(caseData.allAtomGroups());

        compounds.forEach((cmpd) => {
            const compoundState = SavedState.CaptureCompound(
                cmpd, activeCompound, visibleCompounds
            );
            Compounds.push(compoundState);
        });
        const targetMap = getTargetInfoMap();

        if (caseData.mapCase) {
            const { mapCase, displayName, ...targetInfo } = targetMap.get(caseData.mapCase);
            return {
                Protein, Compounds, AtomGroupState, Styles, TargetInfo: targetInfo,
            };
        } else { // No protein case
            return {
                Compounds, AtomGroupState, Styles,
            };
        }
    }
}

/**
 * @description Return raw binding energy data for this compound.
 * {
 *      internalEnergies:    [ [summary], [detail] ],
 *      interactionEnergies: [ [summary], [detail] ]
 * }
 * @param {*} compound
 */
export function exportCompoundEnergies(compound) {
    return compound.originalEnergyData;
}

/**
 * @description Return raw solvation data for this compound.
 * @returns {
 *      totals: [ ddG_PL, ddG_LP, ddG_P, ddG_L],
 *      detail: {
 *          compound: [ [atomName, dGs, dGs_bound]... ],
 *          solute:   [ [uniqueID, dGs, dGs_bound]... ]
 *      },
 *      PSA: [ PSA, PSAbound, TSA, TSAbound,
 *              proteinPSA, proteinPSAbound,
 *              proteinTSA, proteinTSAbound ]
 * }
 * @param {*} compound
 */
export function exportCompoundSolvation(compound) {
    const data = compound.originalSolvData;

    if (data) {
        // The original data may have atom objects, so convert them
        // atom name or ids as appropriate.
        for (const e of data.detail.compound) {
            if (e[0] instanceof Atom) { e[0] = e[0].atom; } // atom name
        }

        for (const s of data.detail.solute) {
            if (s[0] instanceof Atom) {
                s[0] = s[0].uniqueID;
            }
        }
    }

    return data;
}

function exportCompoundScopedProperties(compound) {
    return compound?.scopedProperties?.forSerialization();
}

function exportMapCaseScopedProperties(mapCase) {
    return mapCase?.scopedProperties?.forSerialization();
}

SavedState.Features = {
    EnergyDetail1: { minVersion: '1.0.0' },
    TreeStructure1: { minVersion: '1.1.0' },
};
