import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import ChevronRight from '@mui/icons-material/ChevronRight';
import NestedMenuItem from '../../common/AzmenakNestedMenuItem';

export default styled(NestedMenuItem).attrs({
    rightIcon: <Grid container justifyContent="flex-end"><ChevronRight /></Grid>,
})`
    padding-right: 0;

    &:hover {
        background: #AAAAAA;
    },
`;
