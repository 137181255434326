import { Formik, Form } from 'formik';
import styled from 'styled-components';
// Material UI
import Collapse from '@mui/material/Collapse';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import MuiRadioGroup from '@mui/material/RadioGroup';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// Local
import { DockingReference, boxSpecs } from '../../../data_tools';
import DialogContentSection, { DialogContentWithTooltip } from '../../common/DialogContentSection';
import { DialogSubmitButton, DialogCancelButton } from '../../common/DialogActionButtons';
import { withTooltip, withFormik } from '../../common/helpers';
import { FormikIncrementDecrementInput } from '../../common/IncrementDecrementInput';
import { defaultDockSpeed } from './constants';
import { SidePanelSection } from '../../info_display/SidePanel';
import DockingReferencesSelect, { useDockingReferences, selectSomeAtomsMessage } from './DockingReferencesSelect';

const RadioGroup = styled(MuiRadioGroup)`
  width: 100%;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: 0px;
`;

const RadioGroupWithValidation = withTooltip(withFormik(RadioGroup), {
    IconWrapperProps: { style: { paddingTop: '7px', width: 'unset' } },
});
const ReferencesSelectWithValidation = withTooltip(
    DockingReferencesSelect
);
const IncrementDecrementWithValidation = withTooltip(
    withFormik(FormikIncrementDecrementInput)
);

const DockingTips = styled(({ className }) => {
    const tips = [
        'Docking works best when components have 4 or fewer rotatable bonds.',
        "Please click Dock only when you're ready. You will not be able to to work on your compound during the docking process.",
    ];

    return (
        <DialogContentSection title="Tips" disablePadding collapsible defaultExpand>
            <List dense disablePadding className={className}>
                {tips.map((tip) => (
                    <ListItem key={tip}>
                        <ListItemText>
                            {tip}
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </DialogContentSection>
    );
})`
  list-style: disc;
  & .MuiTypography-root {
    display: list-item;
  }
`;

function FormActions({ handleClose, formId }) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <DialogActions>
            <DialogCancelButton
                fullWidth={isMobile}
                onClick={handleClose}
            >
                Cancel
            </DialogCancelButton>
            <DialogSubmitButton fullWidth={isMobile} form={formId}>Dock</DialogSubmitButton>
        </DialogActions>
    );
}

// TODO: for RadioGroup width, prefer to use global theme to set
export default function DockingForm({ handleSubmit, handleClose, requestedCompounds }) {
    const formId = 'docking-form';

    const dockingReferences = useDockingReferences();

    const poseCountMin = 1;
    const poseCountMax = 10;

    const dockingProgramsWithDockingRef = ['autodock'];
    const dockingProgramsWithBoxSize = ['autodock'];
    const dockingProgramsWithSpeedArg = ['autodock'];

    const availableDockingPrograms = [
        {
            id: 'autodock',
            label: 'AutoDock Vina',
            disabled: false,
            title: 'Traditional docking',
        },
        {
            id: 'diffdock',
            label: 'DiffDock',
            disabled: false,
            title: 'A new machine-learning-based docking algorithm',
        },
    ];

    function validateSelectedRefId(id, dockingProgram) {
        if (!dockingProgramsWithDockingRef.includes(dockingProgram)
            || id !== DockingReference.UNSPECIFIED_ID
        ) {
            return undefined;
        }

        return dockingReferences.length > 0
            ? 'Select a reference for the docking box'
            : selectSomeAtomsMessage();
    }

    function validatePoseCount(count) {
        return count < poseCountMin || count > poseCountMax ? `Invalid pose count. Valid range: ${poseCountMin} - ${poseCountMax}.` : undefined;
    }

    function validateBoxSize(size, otherFormValues) {
        const { boxSizeType } = otherFormValues;
        return boxSizeType === 'custom' && (size < boxSpecs.SIZE_MIN || size > boxSpecs.SIZE_MAX) ? `Invalid box size. Valid range: ${boxSpecs.SIZE_MIN} - ${boxSpecs.SIZE_MAX}.` : undefined;
    }

    function formValidate(formValues) {
        const errors = {};
        const {
            dockingProgram,
            // autodock params
            selectedRefId, poseCount, boxSize, boxSizeType,
        } = formValues;
        let val;

        if (!dockingProgram) {
            errors.dockingProgram = 'Please select a docking program!';
        }

        val = validateSelectedRefId(selectedRefId, dockingProgram);
        if (val) {
            errors.selectedRefId = val;
        }

        val = validatePoseCount(poseCount);
        if (val) {
            errors.poseCount = val;
        }

        val = validateBoxSize(boxSize, { boxSizeType });
        if (val) {
            errors.boxSize = val;
        }

        return errors;
    }

    function getStartingDockingProgram() {
        const entry = availableDockingPrograms.find((dp) => !dp.disabled);
        return entry?.id || '';
    }

    return (
        <>
            <Formik
                initialValues={{
                    dockingProgram: getStartingDockingProgram(),
                    poseCount: 6,

                    // autodock params
                    selectedRefId: DockingReference.UNSPECIFIED_ID,
                    dockspeed: defaultDockSpeed,
                    boxSizeType: 'auto',
                    boxSize: 20,
                    // diffdock params
                    keepHs: false,
                    keepSrc3D: false,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
                validate={formValidate}
            >
                {/* Formik Render Function with parameters from the formik "bag" */}
                {({
                    setFieldValue, getFieldProps, values: {
                        dockingProgram,
                        // autodock params
                        boxSizeType,
                        // diffdock params
                        keepHs,
                        keepSrc3D,
                    },
                }) => (
                    <Form id={formId}>
                        <DialogContentWithTooltip
                            title="Docking Program"
                            disablePadding
                            collapsible
                            defaultExpand
                            tooltip="AutoDock Vina is the traditional docking program. DiffDock uses a different algorithm, accelerated by machine learning."
                        >
                            <SidePanelSection>
                                <RadioGroupWithValidation
                                    name="dockingProgram"
                                    label="Docking Program"
                                >
                                    {availableDockingPrograms.map((dp) => (
                                        <FormControlLabel
                                            key={dp.id}
                                            value={dp.id}
                                            label={dp.label}
                                            title={dp.title}
                                            disabled={dp.disabled}
                                            control={<Radio size="small" />}
                                        />
                                    ))}
                                </RadioGroupWithValidation>
                            </SidePanelSection>
                        </DialogContentWithTooltip>
                        { dockingProgramsWithDockingRef.includes(dockingProgram) && (
                            <DialogContentWithTooltip
                                title="Box Center"
                                disablePadding
                                tooltip="Choose a crystal ligand or computed hot spot for the center of the docking box. You can also select atoms or residues in the 3D workspace for a custom docking box center."
                                style={{ width: '100%' }}
                            >
                                <SidePanelSection>
                                    <ReferencesSelectWithValidation
                                        name="selectedRefId"
                                        requestedCompounds={requestedCompounds}
                                    />
                                </SidePanelSection>
                            </DialogContentWithTooltip>
                        )}
                        <DialogContentSection
                            title="Advanced Parameters"
                            disablePadding
                            collapsible
                        >
                            <SidePanelSection>
                                { dockingProgramsWithBoxSize.includes(dockingProgram) && (
                                    <>
                                        <RadioGroupWithValidation
                                            name="boxSizeType"
                                            label="Box edge size:"
                                            tooltip="Box size is automatically calculated for each docked compound, or you can specify a custom size (1-40 Å)."
                                            optimize={false}
                                        >
                                            <FormControlLabel value="auto" control={<Radio size="small" />} label="Auto" />
                                            <FormControlLabel value="custom" control={<Radio size="small" />} label="Specify edge size" />
                                        </RadioGroupWithValidation>
                                        <Collapse in={boxSizeType === 'custom'} style={{ width: '100%' }}>
                                            <IncrementDecrementWithValidation
                                                name="boxSize"
                                                type="number"
                                                min={boxSpecs.SIZE_MIN}
                                                max={boxSpecs.SIZE_MAX}
                                                validate={validateBoxSize}
                                            />
                                        </Collapse>
                                    </>
                                )}
                                { dockingProgramsWithSpeedArg.includes(dockingProgram) && (
                                    <RadioGroupWithValidation
                                        name="dockspeed"
                                        label="Docking speed:"
                                        tooltip="Docking speed sets the exhaustiveness of the simulation, trading off between speed and thoroughness."
                                        optimize={false}
                                    >
                                        <FormControlLabel value="fast" control={<Radio size="small" />} label="Normal" />
                                        <FormControlLabel value="faster" control={<Radio size="small" />} label="Fast" />
                                        <FormControlLabel value="accurate" control={<Radio size="small" />} label="Accurate" />
                                    </RadioGroupWithValidation>
                                )}
                                <IncrementDecrementWithValidation
                                    name="poseCount"
                                    label={`Number of poses (${poseCountMin} - ${poseCountMax})`}
                                    type="number"
                                    min={poseCountMin}
                                    max={poseCountMax}
                                    validate={validatePoseCount}
                                    ContainerComponentProps={{
                                        style: { marginTop: '1ex' },
                                    }}
                                />
                                { dockingProgram === 'diffdock' && (
                                    <>
                                        <FormControlLabel
                                            control={<Checkbox {...getFieldProps('keepHs')} checked={keepHs} size="small" />}
                                            label="Keep Hydrogens"
                                            title="Diffdock docks without hydrogens by default. Check this to include them. Note: at the moment this seems to produce worse poses"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox {...getFieldProps('keepSrc3D')} checked={keepSrc3D} size="small" />}
                                            label="Start docking with provided ligand conformation"
                                            title="Diffdock recalculates the 3D pose by default. Check this to disable that."
                                        />
                                    </>
                                )}
                            </SidePanelSection>
                        </DialogContentSection>
                    </Form>
                )}
            </Formik>
            <DockingTips />
            <FormActions formId={formId} handleClose={handleClose} />
        </>
    );
}
