// visualizations/hotspot_visualizations.js
/**
 * @fileoverview Functions to display hotspots in the canvas.
 * @typedef {import('BMapsModel').Hotspot} Hotspot
 *
 * Public interface:
 *     displayOneHotspot(show, hotspot, color)
 *     removeHotspots() - removes all hotspots
 *     cleanupHotspots(visibleHotspots) - remove any hotspots that aren't in visibleHotspots
 *
 * Internal storage:
 *     hotspotInfo: Map<Hotspot, { surface, label: string, color: string | number }>
 *
 * This calculates the surface from the atoms in the hotspot.
 */

import {
    drawLabel, drawSurface, removeLabel, removeSurface,
} from 'BMapsSrc/MainCanvas';
import { removeHbondsForHotspots, showHBondsForHotspot } from './hbond_visualizations';

/**
 * Keep track of drawn hotspots for each hotspot
 * @type {Map<Hotspot, { surface, label, color: string | number }} */
const hotspotInfo = new Map();

// Internal flag
const showHotspotHBonds = false;

/**
 * Remove all hotspot visualizations (surfaces and labels)
 */
export function removeHotspots() {
    removeHotspotSurfaces();
    removeHbondsForHotspots();
}

/**
 * Display surface, hbonds, and label for a hotspot
 * @param {boolean} show
 * @param {Hotspot} hotspot
 * @param {string|number} [color='white']
 */
export function displayOneHotspot(show, hotspot, color='white') {
    let existing = hotspotInfo.get(hotspot);

    // Remove if necessary
    if (existing) {
        const needToRedraw = existing.color !== color;
        if (!show || needToRedraw) {
            if (showHotspotHBonds) showHBondsForHotspot(false, hotspot);
            removeSurface(existing.surface);
            removeLabel(existing.label);
            hotspotInfo.delete(hotspot);
            existing = null;
        }
    }

    // Draw new hotspot
    if (show && !existing) {
        if (showHotspotHBonds) showHBondsForHotspot(true, hotspot);
        const surface = drawSurface({ atoms: hotspot.getAtoms(), colorFn: () => color });
        const label = drawLabel({
            text: `${hotspot.fragmentGroupNumber}`,
            options: { alignment: 'center' },
            centerAtomGroup: hotspot,
        });
        hotspotInfo.set(hotspot, { surface, label, color });
    }
}

/**
 * Remove any hotspot visualizer entities (surfaces and labels) for HSs that are no longer visible
 * @param {Hotspot[]} visibleHotspots
 */
export function cleanUpHotspots(visibleHotspots) {
    for (const hotspot of hotspotInfo.keys()) {
        if (!visibleHotspots.includes(hotspot)) {
            displayOneHotspot(false, hotspot);
        }
    }
}

function removeHotspotSurfaces() {
    for (const { surface, label } of hotspotInfo.values()) {
        removeSurface(surface);
        removeLabel(label);
    }
    hotspotInfo.clear();
}
