import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {import('BMapsModel').Compound} Compound
 * @typedef {{ id: string, isTarget: boolean, displayName: string }[]} structList
 * @typedef {{
 *    proteinFamily: string,
 *    targetList: structList,
 *    sideTableWidth: number,
 *    scoringTableState: {
 *        columnVisibilityModel: import('@mui/x-data-grid').GridColumnVisibilityModel
 *        scoring: Boolean,
 *    }
 *    compoundTableState: {
 *        columnVisibilityModel: import('@mui/x-data-grid').GridColumnVisibilityModel
 *    }
 * }} initialState
 */

/** @type {initialState} */
export const initialState = {
    proteinFamily: '',
    targetList: [],
    sidePanelWidth: 600,
    scoringTableState: {
        scoring: false,
    },
    compoundTableState: {},
};

const slice = createSlice({
    name: 'projectState',
    initialState,
    reducers: {
        addStructure(state, action) {
            // ensure that target id is unique.
            if (state.targetList.some((value) => value.mapCase === action.payload.mapCase)) {
                return ({ ...state });
            }
            return {
                ...state,
                targetList: [
                    ...state.targetList,
                    action.payload,
                ],
            };
        },
        changeTarget(state, action) {
            const newState = state.targetList.map((value) => {
                if (action.payload.mapCase === value.mapCase) {
                    return ({
                        ...value,
                        isTarget: !value.isTarget,
                    });
                } else {
                    return (value);
                }
            });
            return {
                ...state,
                targetList: [...newState],
            };
        },
        setTargetInfo(state, action) {
            const newState = state.targetList.map((value) => {
                if (action.payload.mapCase === value.mapCase) {
                    return (action.payload);
                } else {
                    return (value);
                }
            });
            return {
                ...state,
                targetList: [...newState],
            };
        },
        removeStructure(state, action) {
            const newState = state.targetList.filter(
                (value) => value.mapCase !== action.payload.mapCase
            );
            return {
                ...state,
                targetList: [...newState],
            };
        },
        changeProteinFamily(state, action) {
            return {
                ...state,
                proteinFamily: action.payload,
            };
        },
        setCompoundTableState(state, action) {
            state.compoundTableState = {
                ...state.compoundTableState,
                ...action.payload,
            };
        },
        setScoringTableState(state, action) {
            state.scoringTableState = {
                ...state.scoringTableState,
                ...action.payload,
            };
        },
        setSidePanelWidth(state, action) {
            state.sidePanelWidth = action.payload;
        },
        removeAllStructures(state) {
            return {
                ...state,
                targetList: [],
            };
        },
        reset() {
            return initialState;
        },
    },
});

export default slice;
