import { useEffect, useCallback, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { App } from 'BMapsSrc/BMapsApp';
import { showDockingBox } from 'BMapsSrc/visualizations';
import { DockingReference, getDockingReferences, calculateDockingBox } from '../../../data_tools';
import useEventBrokerSubscription from '../../common/helpers/useEventBrokerSubscription';
import { useWithSelectedAtoms } from '../../common/helpers/useWithSelectedAtoms';
import { withFormik } from '../../common/helpers';
import { BindingSiteSelector } from '../SimulationPane/Components';

const BindingSiteSelectorWithFormik = withFormik(BindingSiteSelector);

export default function DockingReferencesSelect({ name, requestedCompounds }) {
    const dockingReferences = useDockingReferences();
    const selectedAtoms = useWithSelectedAtoms();

    // Formik fields
    const [{ value: boxSizeValue }] = useField('boxSize');
    const [{ value: boxSizeType }] = useField('boxSizeType');
    const [{ value: refId },, { setValue: setRefId, setError: setRefErr }] = useField('selectedRefId');
    const { setFieldTouched } = useFormikContext();

    const isSelectedAtoms = refId === DockingReference.SELECTED_ATOMS_ID;
    const size = boxSizeType === 'auto' ? 0 : boxSizeValue;

    useEffect(() => {
        // Automatically choose "Selected Atoms" if it's the only option.
        // If the only option is a ligand or hotspot, user has to pick,
        // so they're aware of the option.
        // Ideally, it should remember the center specified for a given
        // structure across docks and select that the next time
        if (!isSelectedAtoms && dockingReferences.length === 1
            && dockingReferences[0].type === DockingReference.Types.selected) {
            setRefId(dockingReferences[0].id);
        }
        // Clear invalid reference selection
        if ((isSelectedAtoms && selectedAtoms.length === 0)
          || dockingReferences.length === 0) {
            setRefId(DockingReference.UNSPECIFIED_ID);
        }

        if (dockingReferences.length === 0) {
            // "Touch" field so the error appears immediately
            setFieldTouched('selectedRefId', true);
            setRefErr(selectSomeAtomsMessage());
        } else if (refId !== DockingReference.UNSPECIFIED_ID) {
            // Clear error if a valid reference is selected
            setRefErr('');
        }
    // eslint will complain about setRefId and setRefErr, but they are stable (from Formik)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dockingReferences, isSelectedAtoms, selectedAtoms]);

    useEffect(() => {
        const ref = dockingReferences.find((r) => r.id === refId);
        displayDockingBox(ref, requestedCompounds, size);
        return () => displayDockingBox(); // clear the box on unmount
    }, [
        boxSizeType, boxSizeValue, selectedAtoms, dockingReferences,
        refId, size, requestedCompounds,
    ]);

    return (
        <BindingSiteSelectorWithFormik
            name={name}
            value={refId || DockingReference.UNSPECIFIED_ID}
            refList={dockingReferences}
            placeholder="Choose docking center..."
            includeCompounds={false}
            onChange={(evt) => setRefId(evt.target.value)}
            shouldUpdate={(nextProps, prevProps) => (
                nextProps.refList.join(';') !== prevProps.refList.join(';')
            )}
        />
    );
}

function displayDockingBox(refObj, compounds, customSize) {
    if (refObj && compounds.length > 0) {
        const type = refObj.type;
        const [c] = compounds;
        const size = customSize;
        const boxParams = { refObj, size };
        const coords = calculateDockingBox(App.Workspace, c, boxParams);
        showDockingBox('dockingpane', true, coords);
    } else {
        showDockingBox('dockingpane', false);
    }
}

export function useDockingReferences() {
    const [dockingReferences, setDockingReferences] = useState(
        () => (App.Ready ? getDockingReferences(App.Workspace) : [])
    );
    const updateReferences = useCallback(() => {
        const refs = getDockingReferences(App.Workspace);
        setDockingReferences(refs);
    }, []);
    useEventBrokerSubscription('selectionDisplay', updateReferences); // in lieu of useSelectedAtoms
    useEventBrokerSubscription('zapAll', updateReferences);
    useEventBrokerSubscription('proteinsChanged', updateReferences);
    return dockingReferences;
}

export function selectSomeAtomsMessage() {
    return 'Select atoms in 3D to use as a docking box center';
}
