/**
 * ScopedPropertyGroup.js
 * @fileoverview
 * This file contains the implementation of "ScopedProperties," having the following features:
 * - flexible structure:
 *   - properties are grouped in "scopes," including nested scopes
 *   - properties have json value type, allowing for flexible data
 * - individual properties and property groups all have the following additional fields:
 *   - errors
 *   - metadata, allowing for labels, type, and source info
 *   - requestInfo, temporary data for tracking a property request, excluded from serialization
 * - json serialization and deserialization
 *
 * Design considerations:
 * - Have a similar data structure in both bfd-server and BMaps
 * - Support the following categories of properties:
 *   - SDF properties
 *   - properties calculated by bfd-server (eg ring annotations, perhaps openbabel molprops)
 *   - properties calculated by BMaps
 *   - properties requested from external services (eg lookup CHEMBL ID)
 *   - property group requested from external services (eg get kinase info from KLIFS)
 * - Support the following clients of scoped properties:
 *   - sample compounds / static data prep (bfd-server)
 *   - BMaps compound property table
 *   - BMaps export to SDF / CSV
 *   - BMaps Save / Restore session
 *
 * Classes:
 * - ScopedPropertyGroup: main class for either root level or child property groups
 * - ScopedProperty: an individual property entry
 * - ScopedPropertyItem: Base class for ScopedProperty and ScopedPropertyGroup
 * - ScopedPropertyContainer: convenience class for a collection of ScopedProperties (std::map)
 *
 * Todo / ideas:
 * - Give properties and child property groups references to their containing scopes
 * - Give attention to the idea of property requests that produce both a result and an error.
 *   Right now these might just be considered failures.
 * - Allow another data structure to attach to and manage an existing property group.
 *   For example, if BMaps energies could be converted to use ScopedProperties for storage.
 *   This would take the place of some but not all the EnergyInfo logic. So EnergyInfo would
 *   still exist, using a reference to a child ScopedPropertyGroup to store energy data and
 *   request info.
 */

import { ensureArray } from 'BMapsSrc/util/js_utils';

export const RequestStatus = Object.freeze({
    UNSPECIFIED: undefined,
    NEVER_REQUESTED: 0,
    WORKING: 1,
    FAILED: 2,
    SUCCEEDED: 3,
});

export function extractPrecisionFromMetadata(metadata, defaultPrecision=2) {
    let { precision, datatype } = metadata;
    if (precision == null) {
        if (datatype === 'float') precision = defaultPrecision;
        else if (datatype === 'int') precision = 0;
    } else { // Precision is defined
        if (datatype == null) datatype = precision === 0 ? 'int' : 'float';
    }
    return { precision, datatype };
}

export function updateRequestStatus(propRef, status, { value, error, trigger }={}) {
    propRef.updateRequestInfo('status', status);
    if (value !== undefined) propRef.setValue(value);
    if (error !== undefined) {
        propRef.addError(error);
    } else {
        propRef.errors.length = 0;
    }
    if (trigger) trigger();
}

export async function fetchProperty(propRef, fn, trigger) {
    updateRequestStatus(propRef, RequestStatus.WORKING, { trigger });
    const { value, error } = await fn();
    const status = error === undefined ? RequestStatus.SUCCEEDED : RequestStatus.FAILED;
    updateRequestStatus(propRef, status, { value, error, trigger });
    return { value, error };
}

export async function fetchPropertyGroup(groupRef, fn, trigger) {
    updateRequestStatus(groupRef, RequestStatus.WORKING, { trigger });
    const { error: groupError, values } = await fn();
    const status = groupError === undefined ? RequestStatus.SUCCEEDED : RequestStatus.FAILED;
    if (values) {
        for (const [propName, { value, error: propError }] of Object.entries(values)) {
            if (value) groupRef.setPropertyValue(propName, value);
            if (propError) groupRef.addPropertyError(propName, propError);
        }
    }
    updateRequestStatus(groupRef, status, { error: groupError, trigger });
    return { error: groupError, values };
}

export class ScopedPropertyItem {
    constructor(name='', metadata={}) {
        this.name = name;
        this.metadata = metadata;
        this.errors = [];
        this.requestInfo = {};
        this.parentScope = null;
    }

    setMetadataItem(itemName, itemValue) {
        this.metadata[itemName] = itemValue;
    }

    getMetadata() {
        return this.metadata;
    }

    /**
     * Lookup a metadata item in this item or in ancestors
     * @param {string} itemName
     * @returns {any|undefined}
     */
    traverseForMetadataItem(itemName) {
        let working = this;
        while (working) {
            const found = working.getMetadata()[itemName];
            if (found !== undefined) return found;
            working = working.parentScope;
        }
        return undefined;
    }

    /**
     * Lookup a request info item in this item or in ancestors
     * @param {string} itemName
     * @returns {any|undefined}
     */
    traverseForRequestInfoItem(itemName) {
        let working = this;
        while (working) {
            const found = working.requestInfo[itemName];
            if (found !== undefined) return found;
            working = working.parentScope;
        }
        return undefined;
    }

    /**
     * Collect all errors from this item and ancestors
     * @returns {any[]}
     */
    traverseForErrors() {
        const errors = [];
        let working = this;
        while (working) {
            errors.push(...working.errors);
            working = working.parentScope;
        }
        return errors;
    }

    addError(error) {
        this.errors.push(error);
    }

    updateRequestInfo(itemName, itemValue) {
        this.requestInfo[itemName] = itemValue;
    }

    getParentScope() {
        return this.parentScope;
    }

    setParentScope(scope) {
        this.parentScope = scope;
    }

    getScopeList({ includeSelf, includeRoot }={}) {
        const scopeList = [];
        let working = includeSelf ? this : this.parentScope;
        while (working) {
            if (!includeRoot && !working.parentScope) break;
            scopeList.unshift(working);
            working = working.parentScope;
        }
        return scopeList;
    }

    getScopeListNames(getScopeListParams) {
        return this.getScopeList(getScopeListParams).map((sp) => sp.name);
    }

    copyFrom(otherSpi, includeName=false) {
        if (includeName) {
            this.name = otherSpi.name;
        }
        if (otherSpi.metadata) {
            for (const [key, value] of Object.entries(otherSpi.metadata)) {
                this.setMetadataItem(key, value);
            }
        }
        if (otherSpi.errors) {
            for (const err of otherSpi.errors) {
                this.addError(err);
            }
        }
        if (otherSpi.requestInfo) {
            for (const [key, value] of Object.entries(otherSpi.requestInfo)) {
                this.updateRequestInfo(key, value);
            }
        }
    }

    forSerialization() {
        const obj = {};
        if (Object.keys(this.metadata).length > 0) obj.metadata = { ...this.metadata };
        if (this.errors.length > 0) obj.errors = [...this.errors];
        return obj;
    }

    static fromDeserialization(deserialized, target=new ScopedPropertyItem()) {
        if (deserialized.name) target.name = deserialized.name;
        if (deserialized.metadata) target.metadata = deserialized.metadata;
        if (deserialized.errors) target.errors = deserialized.errors;
        if (deserialized.requestInfo) target.requestInfo = deserialized.requestInfo;
        return target;
    }
}

/**
 * This class is to manage default metadata for properties.
 * TODO: Defaults should somehow be related to the class of the owning business object.
 * eg. Compound, MapCase, Fragment might all have properties with the same names,
 * but we want different labels or other metadata.
 */
export class ScopedPropertyMetadata {
    static Init() {
        if (!ScopedPropertyMetadata.DefaultMetadata) ScopedPropertyMetadata.Reset();
        return ScopedPropertyMetadata.DefaultMetadata;
    }

    static Reset() {
        ScopedPropertyMetadata.DefaultMetadata = new ScopedPropertyGroup();
    }

    /**
     * Add to default metadata for a scope or property.
     * This is implemented using the ScopedPropertyGroup design, and receives a
     * serialized nested scoped property group, extracting the metadata entries for defaults.
     * Top level childGroups key is optional (normally present for serialized scoped properties).
     * @param {*} input
     */
    static SetDefaultMetadata(inputIn) {
        const metadataMgr = ScopedPropertyMetadata.Init();
        const input = inputIn.childGroups ? inputIn : { childGroups: inputIn };
        const defaultsAsPropertyGroup = ScopedPropertyGroup.fromDeserialization(input);
        if (defaultsAsPropertyGroup.name) {
            metadataMgr.copyToFrom(defaultsAsPropertyGroup.name, defaultsAsPropertyGroup);
        } else {
            metadataMgr.copyFrom(defaultsAsPropertyGroup);
        }
    }

    /**
     * Lookup the default metadata for a scope or property.
     * @param {string|ScopedPropertyGroup|string[]|ScopedPropertyGroup[]} scope
     * @param {string} [prop]
     * @returns {object?}
     */
    static GetDefaultMetadata(scope, prop) {
        const metadataMgr = ScopedPropertyMetadata.Init();
        const scopeArr = ensureArray(scope);
        const scopeNames = scopeArr.map((s) => (typeof s === 'string' ? s : s.name));
        if (prop) {
            const info = metadataMgr.getPropertyInfo(scopeNames, prop);
            return info?.metadata;
        } else {
            const info = metadataMgr.getScope(scopeNames);
            return info?.metadata;
        }
    }
}

export class ScopedProperty extends ScopedPropertyItem {
    constructor(name='', value, metadata) {
        // Constructor can take individual base attributes or another class instance
        if (typeof name === 'string') {
            super(name, metadata);
            this.value = value;
        } else {
            super();
            const otherSp = name;
            this.copyFrom(otherSp, true);
        }
    }

    getMetadata() {
        return {
            ...ScopedPropertyMetadata.GetDefaultMetadata(
                this.getScopeList({ includeRoot: true }), this.name
            ),
            ...this.metadata,
        };
    }

    copyFrom(otherSp, includeName) {
        super.copyFrom(otherSp, includeName);
        this.value = otherSp.value;
    }

    setValue(value) { this.value = value; }
    clearValue() { this.value = undefined; }
    getValue() { return this.value; }

    forSerialization() {
        const obj = super.forSerialization();
        if (this.value !== undefined) obj.value = this.value;
        return obj;
    }

    static fromDeserialization(deserialized, target=new ScopedProperty()) {
        ScopedPropertyItem.fromDeserialization(deserialized, target);
        if (deserialized.value !== undefined) target.value = deserialized.value;
        return target;
    }
}

export class ScopedPropertyGroup extends ScopedPropertyItem {
    constructor(name='', metadata={}) {
        // Constructor can take individual base attributes or another class instance
        if ((typeof name) === 'string') {
            super(name, metadata);
            this.init();
        } else if (typeof name === 'object') {
            super();
            this.init();
            const otherSpg = name;
            this.copyFrom(otherSpg, true);
        } else {
            super();
            this.init();
        }
    }

    init() {
        this.properties = {};
        this.childGroups = {};
    }

    getMetadata() {
        return {
            ...ScopedPropertyMetadata.GetDefaultMetadata(
                this.getScopeList({ includeSelf: true, includeRoot: true })
            ),
            ...this.metadata,
        };
    }

    copyFrom(otherSpg, includeName, filterObj) {
        super.copyFrom(otherSpg, includeName);
        this.copyChildrenFrom(otherSpg, filterObj);
    }

    copyChildrenFrom(otherSpg, { propertyFilter, groupFilter, scope }={}) {
        if (otherSpg.properties) {
            for (const [propName, propInfo] of Object.entries(otherSpg.properties)) {
                if (!propertyFilter || propertyFilter(propInfo, this, scope)) {
                    this.addProperty(propName, propInfo);
                }
            }
        }
        if (otherSpg.childGroups) {
            for (const [groupName, childGroup] of Object.entries(otherSpg.childGroups)) {
                if (!groupFilter || groupFilter(childGroup, scope)) {
                    const workingGroup = this.ensureScope(groupName);
                    workingGroup.copyFrom(childGroup, false, {
                        scope: scope ? [...scope, groupName] : [groupName],
                        groupFilter,
                        propertyFilter,
                    });
                }
            }
        }
    }

    copyToFrom(destScope, sourceGroup) {
        const spg = this.ensureScope(destScope);
        spg.copyFrom(sourceGroup);
    }

    hasScope(scope) {
        return !!this.getScope(scope);
    }

    getScope(scope) {
        if (typeof scope === 'string') {
            return this.childGroups[scope];
        }

        let working = this;
        for (const next of scope) {
            working = working.getScope(next);
            if (!working) return null;
        }
        return working;
    }

    addScope(scope) {
        if (typeof scope === 'string') {
            const created = new ScopedPropertyGroup(scope);
            created.setParentScope(this);
            this.childGroups[scope] = created;
            return created;
        }

        let working = this;
        for (const next of scope) {
            working = working.ensureScope(next);
        }
        return working;
    }

    ensureScope(scope) {
        const ret = this.getScope(scope) || this.addScope(scope);
        return ret;
    }

    addChildGroup(groupName, groupInfo) {
        const created = new ScopedPropertyGroup(groupInfo);
        created.setParentScope(this);
        this.childGroups[groupName] = created;
    }

    addProperty(propName, propInfo) {
        const created = new ScopedProperty(propInfo);
        created.setParentScope(this);
        this.properties[propName] = created;
    }

    /**
     * Ensures the existence of a property in this group, or in a child group if scope is provided.
     * Returns the extant or created Scoped Property object.
     * @param {string | string[]} scopeOrPropName string|string[]
     * @param {string} [propName] string?
     * @returns {ScopedProperty} The existing ScopedProperty object or a new one
     */
    ensureProperty(...args) {
        const { targetGroup, propName } = this.getArgs(args, ['propName']);

        if (targetGroup) {
            return targetGroup.ensureProperty(propName);
        }

        if (this.properties[propName] !== undefined) {
            return this.properties[propName];
        }
        const sp = new ScopedProperty(propName);
        sp.setParentScope(this);
        this.properties[propName] = sp;
        return sp;
    }

    /**
     * Adds an error to this group, or to a child group if scope is provided.
     * @param {string | string[]} scopeOrError string | string[]
     * @param {string} [error] string?
     */
    addError(...args) {
        const { targetGroup, error } = this.getArgs(args, ['error']);
        if (targetGroup) {
            targetGroup.addError(error);
            return;
        }

        super.addError(error);
    }

    /**
     * Updates the metadata for this group, or for a child group if scope is provided.
     * @param {string | string[]} scopeOrMetadata string | string[]
     * @param {object} [metadata] object
     */
    setMetadata(...args) {
        const { targetGroup, metadata } = this.getArgs(args, ['metadata']);
        if (targetGroup) {
            targetGroup.setMetadata(metadata);
            return;
        }

        if (metadata == null) {
            this.metadata = null;
        } else {
            for (const [key, value] of Object.entries(metadata)) {
                super.setMetadataItem(key, value);
            }
        }
    }

    /**
     * Updates the request info for this group, or for a child group if scope is provided.
     * @param {string | string[]} scopeOrInfoName string | string[]
     * @param {string} infoName string
     * @param {*} [value] any
     */
    updateRequestInfo(...args) {
        const { targetGroup, infoName, value } = this.getArgs(args, ['infoName', 'value']);
        if (targetGroup) {
            targetGroup.updateRequestInfo(infoName, value);
            return;
        }

        super.updateRequestInfo(infoName, value);
    }

    /**
     * Sets the value of a property in this group, or in a child group if scope is provided.
     * @param {string | string[]} scopeOrPropName string|string[]
     * @param {string} propName string
     * @param {*} [value] any
     */
    setPropertyValue(...args) {
        const { targetGroup, propName, value } = this.getArgs(args, ['propName', 'value']);

        if (targetGroup) {
            targetGroup.setPropertyValue(propName, value);
            return;
        }

        const sp = this.ensureProperty(propName);
        sp.setValue(value);
    }

    /**
     * Sets the values of multiple properties in this group, or a child group if scope is provided.
     * @param {string | string[]} scopeOrPropertyMap string | string[]
     * @param {object} [propertyMap] object
     */
    setPropertyValues(...args) {
        const { targetGroup, propertyMap } = this.getArgs(args, ['propertyMap']);
        if (targetGroup) {
            targetGroup.setPropertyValues(propertyMap);
            return;
        }

        for (const [name, value] of Object.entries(propertyMap)) {
            const sp = this.ensureProperty(name);
            sp.setValue(value);
        }
    }

    /**
     * Adds an error to a property in this group, or in a child group if scope is provided.
     * @param {string | string[]} scopeOrPropName string | string[]
     * @param {string} propName string
     * @param {string} [error] string
     */
    addPropertyError(...args) {
        const { targetGroup, propName, error } = this.getArgs(args, ['propName', 'error']);
        if (targetGroup) {
            targetGroup.addPropertyError(propName, error);
            return;
        }

        const sp = this.ensureProperty(propName);
        sp.addError(error);
    }

    /**
     * Updates the metadata for a property in this group, or in a child group if scope is provided.
     * @param {string | string[]} scopeOrPerPropMetadata string | string[]
     * @param {object} [perPropMetadata] object
     */
    setPropertyMetadata(...args) {
        const { targetGroup, perPropMetadata } = this.getArgs(args, ['perPropMetadata']);

        if (targetGroup) {
            targetGroup.setPropertyMetadata(perPropMetadata);
            return;
        }

        for (const [propName, metadata] of Object.entries(perPropMetadata)) {
            const sp = this.ensureProperty(propName);
            for (const [key, value] of Object.entries(metadata)) {
                sp.setMetadataItem(key, value);
            }
        }
    }

    /**
     * Updates request info of a property in this group, or in a child group if scope is provided.
     * @param {string | string[]} scopeOrPropName string | string[]
     * @param {string} propName string
     * @param {string} itemName string
     * @param {*} [itemValue] any
     */
    updatePropertyRequestInfo(...args) {
        const {
            targetGroup, propName, itemName, itemValue,
        } = this.getArgs(args, ['propName', 'itemName', 'itemValue']);

        if (targetGroup) {
            targetGroup.updatePropertyRequestInfo(propName, itemName, itemValue);
            return;
        }

        const sp = this.ensureProperty(propName);
        sp.updateRequestInfo(itemName, itemValue);
    }

    /**
     * Return an array of all the properties in this group, optionally including props in children.
     * @param {string|string[]|boolean} scopeOrIncludeChildren
     * @param {boolean} [includeChildren]
     * @returns {ScopedProperty[]}
     */
    listProperties({ scope, includeChildren }={}) {
        let spg = this;
        // Look up the scope if necessary, but don't ensure it exists
        if (scope) {
            spg = this.getScope(scope);
            if (!spg) {
                return [];
            }
        }
        const result = [...Object.values(spg.properties)];
        if (includeChildren) {
            for (const childGroup of Object.values(spg.childGroups)) {
                result.push(...childGroup.listProperties({ includeChildren }));
            }
        }
        return result;
    }

    getPropertyInfo(scope, propName) {
        const propEntry = this.getPropertyRef(scope, propName);
        // Create a new object to protect from accidental modification.
        return propEntry && new ScopedProperty(propEntry);
    }

    getPropertyValue(scope, propName) {
        const propInfo = this.getPropertyInfo(scope, propName);
        return propInfo?.getValue();
    }

    getPropertyRef(scope, propName) {
        if (propName == null) {
            // scope is the propName
            return this.properties[scope];
        }
        const spg = this.getScope(scope);
        return spg?.getPropertyRef(propName);
    }

    /**
     * This is a helper function for the various functions that have an optional scope.
     * If there is no scope, the function would operate directly on this ScopedPropertyGroup.
     * If there is a scope, then it looks up the target SPG with the scope and act on that.
     * This function will do the lookup, and returns an object with the desired field names
     * and the targetGroup to receive the action.
     *
     * @param {any[]} args The actual arguments provided to the function
     * @param {string[]} argNames The names of the fields to extract from the args, less 'scope'
     * @returns {{ targetGroup: ScopedPropertyGroup }} object with desired fields and targetGroup
     */
    getArgs(args, argNames) {
        const argNamesToUse = ['scope', ...argNames];
        const argsToUse = args.length < argNamesToUse.length ? [undefined, ...args] : args;
        const ret = argNamesToUse.reduce(
            (acc, nextName, i) => ({ ...acc, [nextName]: argsToUse[i] }),
            {}
        );

        const scope = argsToUse[0];
        if (scope) {
            ret.targetGroup = this.ensureScope(scope);
        }
        return ret;
    }

    forSerialization() {
        const obj = super.forSerialization();
        if (this.name) {
            obj.name = this.name;
        }
        if (Object.keys(this.properties).length > 0) {
            obj.properties = {};
            for (const [name, propInfo] of Object.entries(this.properties)) {
                obj.properties[name] = propInfo.forSerialization();
            }
        }
        if (Object.keys(this.childGroups).length > 0) {
            obj.childGroups = {};
            for (const [name, group] of Object.entries(this.childGroups)) {
                obj.childGroups[name] = group.forSerialization();
            }
        }
        return obj;
    }

    static fromDeserialization(deserialized, target=new ScopedPropertyGroup()) {
        ScopedPropertyItem.fromDeserialization(deserialized, target);
        if (deserialized.properties) {
            for (const [propName, propInfo] of Object.entries(deserialized.properties)) {
                propInfo.name = propName;
                target.addProperty(propName, propInfo);
            }
        }
        if (deserialized.childGroups) {
            for (const [grpName, childGroup] of Object.entries(deserialized.childGroups)) {
                target.addChildGroup(grpName, ScopedPropertyGroup.fromDeserialization(childGroup));
            }
        }
        return target;
    }

    static fromJson(json) {
        const parsed = JSON.parse(json);
        return ScopedPropertyGroup.fromDeserialization(parsed);
    }
}
