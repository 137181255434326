// TopBar.jsx
import { useState } from 'react';
import { EventBroker } from 'BMapsSrc/eventbroker';

import { Loader } from 'BMapsSrc/Loader';
import { App } from '../BMapsApp';
import { SaveStatePanel } from './SaveStatePanel';
import { TheManual } from './manual/TheManual';
import useEventBrokerSubscription from './common/helpers/useEventBrokerSubscription';
import { loginUrlForMapCase } from '../connection_utils';
import VoiceCommandListener from '../VoiceCommandListener';
import { toggleHelpMenu } from './HelpPanel';

export default function TopBar() {
    const [loginUrl, setLoginUrl] = useState('/login');
    const [showLogin, setShowLogin] = useState(false);

    useEventBrokerSubscription('proteinLoaded', (_, { mapCase }) => {
        setLoginUrl(loginUrlForMapCase(mapCase));
    });
    useEventBrokerSubscription('connectionStatusChanged', (_, { connector, mode }) => {
        if (connector === App.PrimaryDataConnection.getConnector()) {
            if (mode === 'static') {
                setShowLogin(true);
            } else {
                setShowLogin(false);
            }
        }
    });
    return (
        <>
            {/* We want the TopBar to close any modals when clicked on (emulating a clickaway),
                but don't necessarily want it to be a button, since modals have their own
                close and cancel buttons. So disable the eslint a11y errors.
            */}
            {/* eslint-disable jsx-a11y/click-events-have-key-events */}
            {/* eslint-disable jsx-a11y/no-static-element-interactions */}
            <div
                id="topbar"
                className="topbar"
                onClick={() => EventBroker.publish('escapeKey')}
            >
                {/* eslint-enable jsx-a11y/click-events-have-key-events */}
                {/* eslint-enable jsx-a11y/no-static-element-interactions */}
                <div id="topbar-left-side">
                    <div id="topbar-logo-container" style={{ pointerEvents: 'none' }}>
                        <span id="topbar-logo-anchor">
                            <img src="images/BLogo.png" alt="BMaps Logo" />
                        </span>
                    </div>
                    <div id="topbar-title-container" style={{ pointerEvents: 'none', color: 'var(--topbar-title-color)' }}>
                        Boltzmann Maps
                    </div>
                </div>
                <div id="topbar-right-side">
                    {showLogin && (
                        <a href={loginUrl}>
                            <button
                                type="button"
                                className="topbar-button topbar-button-with-label"
                                title="Log in or Sign up"
                            >
                                <i className="fa fa-sign-in" />
                                <div className="topbar-button-label">login</div>
                            </button>
                        </a>
                    )}
                    { !!Loader.AllowLabFeatures && <VoiceCommandListener />}
                    <button
                        id="topbar-chat-help-button"
                        type="button"
                        className="topbar-button topbar-button-with-label"
                        title="Documentation, support, and &quot;Gibbs&quot; AI Chatbot"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleHelpMenu();
                        }}
                    >
                        <i className="fa fa-question" />
                        <div className="topbar-button-label">Help</div>
                    </button>
                    <button
                        id="topbar-help-button"
                        type="button"
                        className="topbar-button topbar-button-with-label"
                        title="View cheatsheet"
                        onClick={(e) => {
                            e.stopPropagation();
                            TheManual.Toggle();
                        }}
                    >
                        <i className="fa fa-map" />
                        <div className="topbar-button-label">guide</div>
                    </button>
                    <button
                        type="button"
                        className="topbar-button topbar-button-with-label"
                        title="Session storage"
                        onClick={(e) => {
                            e.stopPropagation();
                            SaveStatePanel.Open();
                        }}
                    >
                        <i className="fa fa-download" />
                        <div className="topbar-button-label">save</div>
                    </button>
                    <button
                        id="topbar-menu-button"
                        type="button"
                        className="topbar-button"
                        title="Main menu"
                        onClick={(e) => {
                            e.stopPropagation();
                            // Menu is actually opened by another event listener
                            // added in menu_cmds.js
                        }}
                    >
                        <i className="fa fa-bars" />
                    </button>
                </div>
            </div>
            <ul id="topbar-menu-list" />
        </>
    );
}
