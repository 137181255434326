/* compound.js */

import { isCarbon } from 'BMapsSrc/util/chem_utils';
import {
    ScopedPropertyGroup, fetchProperty, fetchPropertyGroup, CompoundHeritage,
    extractPrecisionFromMetadata,
} from 'BMapsModel';
import { pointDistanceSquared } from 'BMapsSrc/util/atom_distance_utils';
import { getFunctionalGroups, AtomSet } from '../utils';
import { getFullResidueId } from '../util/mol_info_utils';
import { EventBroker } from '../eventbroker';
import { EnergyInfo } from './energyinfo';
import { WebServices } from '../WebServices';
import { AtomGroup, AtomGroupTypes } from './atomgroups';
import { App } from '../BMapsApp';
import { getColorSchemeInfo } from '../redux/prefs/access';
import { Loader } from '../Loader';
import {
    makeNamedSmiles, makeOneSDF, removeSmilesMolName, replaceNameInMolText,
    removeAtomMappingFromMolText,
} from '../util/mol_format_utils';
import { ensureArray } from '../util/js_utils';

export class Compound extends AtomGroup {
    /**
     * Lookup a compound by spec from a list of compounds
     * @param {string} spec
     * @param {Compound[]} cmpdList
     * @return {Compound?}
     */
    static findBySpec(spec, cmpdList) {
        return cmpdList?.find(({ resSpec }) => resSpec === spec);
    }

    /**
     * Compare two compounds based on atom elements and coordinates.
     * The atoms do not have to be in the same order.
     * @param {Compound} cmpd1
     * @param {Compound} cmpd2
     * @param {number} [tolerance] Tolerance for distance *squared* atom position comparison
     * @returns {boolean}
     */
    static matches3d(cmpd1, cmpd2, tolerance=0.0001) {
        const atoms1 = cmpd1.getAtoms();
        const atoms2 = cmpd2.getAtoms();

        if (atoms1.length !== atoms2.length) return false;

        // Check that each atom in cmpd1 has a match in cmpd2
        for (const atom1 of atoms1) {
            const atom2Ix = atoms2.findIndex((a2) => (
                a2.elem === atom1.elem && pointDistanceSquared(a2, atom1) < tolerance
            ));
            if (atom2Ix < 0) return false;
            atoms2.splice(atom2Ix, 1);
        }
        return true;
    }

    constructor(atoms, type=AtomGroupTypes.Compound) {
        super(atoms, type);
        this.atomSet = new AtomSet();
        for (const newAtom of this.atoms) {
            this.addAtom(newAtom);
        }
        this.bindingSite = null;
        this.functionalGroups = getFunctionalGroups(this.getAtoms());
        this.is_ligand = false;
        this.canDock = true;

        // molType and molName are used by other AtomGroups but not by Compounds/Ligands
        this.molType = null;
        this.molName = null;

        // The following data may show up in property tables, Export, and Save/Restore.
        /** @type {string} */
        this.smiles = null;
        this.mol2000 = null;
        this.energyInfo = new EnergyInfo(this);
        this.molProps = {};
        this.heritage = new CompoundHeritage();
        this.scopedProperties = new ScopedPropertyGroup('compound');
    }

    /**
     * Lipinski's Rule of 5:
     *   - No more than 5 hydrogen bond donors (HBD <= 5)
     *   - No more than 10 hydrogen bond acceptors (HBA <= 10)
     *   - A molecular mass less than 500 daltons (MW < 500)
     *   - LogP that does not exceed 5 (LogP <= 5)
     * @returns {number} The number of Lipinski Rule violations for this compound (0-4)
     */
    getLipinskiViolationCount() {
        let counter = 0;
        if (this.getMolProp(MolProps.HBondDonors) > 5) counter++;
        if (this.getMolProp(MolProps.HBondAcceptors) > 10) counter++;
        if (this.getMolProp(MolProps.MolWeight) >= 500) counter++;
        if (this.getMolProp(MolProps.LogP) > 5) counter++;
        return counter;
    }

    addAtom(atom) {
        this.atomSet.addAtom(atom);
    }

    hasAtom(atom) {
        return this.atomSet.hasAtom(atom);
    }

    getAtomByName(name) {
        for (const atom of this.getAtoms()) {
            // console.log(`looking for ${name}, looking at ${atom.atom}`)
            if (atom.atom === name) return atom;
        }
        return null;
    }

    atomCount() {
        return this.atomSet.atomCount();
    }

    getAtoms() {
        return this.atomSet.getAtoms();
    }

    getEnergyInfo() {
        return this.energyInfo;
    }

    energyTypeAvailable(typesIn) {
        const types = ensureArray(typesIn);
        return this.energyInfo.energiesAvailable(types);
    }

    getFunctionalGroups() {
        return this.functionalGroups;
    }

    getFunctionalGroupByAtom(atom) {
        return this.functionalGroups.find((fg) => fg.includes(atom));
    }

    recordHeritage(unit) { this.heritage.record(unit); }
    getHeritage() { return this.heritage.steps(); }
    setHeritage(heritage) { this.heritage.setHeritage(heritage); }

    setLigand(ligand) { this.is_ligand = ligand; }
    isLigand() { return this.is_ligand; }

    setMol2000(mol2000) { this.mol2000 = mol2000; }

    /**
     * Return a mol block for the compound.
     * @param {CaseData} refCaseData If supplied, use transformed molfile coordinates for dest case
     * @returns {string}
     */
    getMol2000(refCaseData) {
        if (!refCaseData || refCaseData === this.caseData) {
            return this.mol2000;
        } else {
            return refCaseData.transformMolDataFrom(this.mol2000, 'mol', this.caseData);
        }
    }

    setSmiles(smiles) { this.smiles = smiles; }
    getSmiles() { return this.smiles; }
    getUnnamedSmiles() { return removeSmilesMolName(this.smiles); }
    get molUnbound() { return this.getProperty('unbound_conformation', 'mol_unbound'); }
    set molUnbound(value) { this.setProperty('unbound_conformation', 'mol_unbound', value); }

    setSvg(svg) { this.svg = svg; }
    getSvg() { return this.svg; }

    isCovalentlyBound() {
        return this.hasForeignBonds();
    }

    getUniqueStructId() {
        const inchi = this.getProperty('structure2d', 'inchi');
        if (inchi) return inchi;

        const smiles = this.getUnnamedSmiles();
        if (smiles) return smiles;

        console.error(`Unable to find structure identifier for ${this.displayName}`);
        return this.displayName || '';
    }

    rename(newName) {
        this.resSpec = newName;
        this.resname = newName;
        this.key = newName;
        this.residue.parentName = newName;
        this.getAtoms().forEach((at) => { at.resname = newName; });
        const newMolString = replaceNameInMolText(this.mol2000, newName);
        this.setMol2000(newMolString);
        const newSmiles = makeNamedSmiles(this.smiles, newName);
        this.setSmiles(newSmiles);
    }

    getProperty(scope, prop) { return this.scopedProperties.getPropertyValue(scope, prop); }
    getPropertyInfo(scope, prop) { return this.scopedProperties.getPropertyInfo(scope, prop); }
    setProperty(scope, prop, value) { this.scopedProperties.setPropertyValue(scope, prop, value); }
    listProperties(listPropertiesParams) {
        return this.scopedProperties.listProperties(listPropertiesParams);
    }

    async fetchProperty(scope, prop, fn, trigger) {
        const propRef = this.scopedProperties.ensureProperty(scope, prop);
        return fetchProperty(propRef, fn, trigger);
    }

    async fetchPropertyGroup(scope, fn, trigger) {
        const groupRef = this.scopedProperties.ensureScope(scope);
        return fetchPropertyGroup(groupRef, fn, trigger);
    }

    async updateSvg(format='mol', { colorSchemeInfo=getColorSchemeInfo(), renderIndices=false }={}) {
        let svg;
        // TODO: implement this with svgForMol
        //       Two challenges, to handle:
        //           1. Specific rdkit paramaters and calling get_svg_with_highlights
        //           2. Different post-generation color replacements for both rdkit and indigo
        // uncomment this to enable local, RDKit based functionality
        // svg = await Compound.getCmpdSvgRDKit(this, format, { colorSchemeInfo, renderIndices });
        // if (svg === 'failed')
        // eslint-disable-next-line prefer-const
        svg = await Compound.getCmpdSvgIndigo(this, format, { colorSchemeInfo, renderIndices });
        this.setSvg(svg);
        this.fireCompoundChanged();
    }

    static async getCmpdSvgRDKit(cmpd, format = 'mol', { colorSchemeInfo=getColorSchemeInfo(), renderIndices=false }={}) {
        if (!Loader.RDKitExport) {
            return 'failed';
        }

        let molString = cmpd.getMol2000();
        if (!renderIndices) molString = removeAtomMappingFromMolText(molString);
        const data = format === 'mol' ? molString : cmpd.getSmiles();
        const mdetails = {
            clearBackground: false,
            rotate: 0,
            // addStereoAnnotation: true,
            // prepareMolsBeforeDrawing: true,
        };
        const jsMol = Loader.RDKitExport.getCleanMol(data);
        mdetails.rotate = Loader.RDKitExport.molSpin(jsMol.get_molblock());
        let svg = jsMol.get_svg_with_highlights(JSON.stringify(mdetails), 500, 300);
        jsMol.delete();

        // This changes black objects in the svg to the theme's text color
        svg = svg.replace(/#000000/g, colorSchemeInfo.textCss);

        // This changes blue objects to a lighter blue that shows up better on black
        svg = svg.replace(/#0000FF/g, '#1919FF');
        return svg;
    }

    static async getCmpdSvgIndigo(cmpd, format = 'mol', { colorSchemeInfo=getColorSchemeInfo(), renderIndices=false }) {
        let molString = cmpd.getMol2000();
        if (!renderIndices) molString = removeAtomMappingFromMolText(molString);
        const data = format === 'mol' ? molString : cmpd.getSmiles();
        let svg;

        try {
            svg = await WebServices.mol2svg(data, format);
        } catch (err) {
            try {
                await WebServices.refreshCookie();
                svg = await WebServices.mol2svg(data, format);
            } catch {
                svg = 'failed';
            }
        }

        // Indigo occasionally sends explicitly black bonds or labels that don't match
        // the neighbors and don't update with the background theme color.
        // To handle that case, we recolor the SVG objects to have the same color as the
        // other text / bonds, allowing them to be seen when the background is black.
        // See 4Y2X for an example
        // TODO make the webservice not draw black objects for black theme (address root cause)
        svg = svg.replace(/rgb\(0%, ?0%, ?0%\)/g, colorSchemeInfo.textCss);
        return svg;
    }

    async updateMolProps() {
        const spec = this.resSpec;

        try {
            const { connector } = App.getDataParents(this);
            const incomingProps = await connector.cmdGetMoleculeProperties(spec);

            for (const [indigoKey, indigoValue] of Object.entries(incomingProps)) {
                // Convert Indigo keys to our own if possible
                const localKey = IndigoMolPropMap[indigoKey] || indigoKey;
                this.setMolProp(localKey, indigoValue);
            }
            const rotBondCount = this.getRotatableBonds().length;
            this.setMolProp(MolProps.RotatableBonds, rotBondCount, true);
            this.setMolProp(MolProps.LipinskiViolationCount, this.getLipinskiViolationCount());
            this.setMolProp(MolProps.Fsp3, this.calcFsp3());
            this.fireCompoundChanged();

            this.updateEnergyEfficiency();
        } catch (err) {
            console.warn(`Failed to get mol props for ${this.resSpec}`);
        }
    }

    getRotatableBonds({ includeAmideBonds=false }={}) {
        const allBonds = this.getAtoms()
            .map((x) => x.heavyBonds)
            .reduce((acc, cur) => acc.concat(cur), []); //--- Is this redundant?
        const rotBonds = [];
        for (const bond of allBonds) {
            if (bond.isRotatable({ includeAmideBonds })) {
                rotBonds.push(bond);
            }
        }
        const uniqueRotBonds = [];
        for (const bond of rotBonds) {
            let alreadyInSet = false;
            for (const other of uniqueRotBonds) {
                if (bond.equals(other)) {
                    alreadyInSet = true;
                    break;
                }
            }
            if (!alreadyInSet) {
                uniqueRotBonds.push(bond);
            }
        }

        const debugRotatableBonds = false;
        if (debugRotatableBonds) {
            console.log('ROTATABLE BONDS:');
            for (const bond of uniqueRotBonds) {
                const atom1 = bond.atom1;
                const atom2 = bond.atom2;
                console.log(`${atom1.atom} (${atom1.amber}) - ${atom2.atom} (${atom2.amber})`);
            }
        }
        return uniqueRotBonds;
    }

    getMolProps() { return { ...this.molProps }; }
    getMolProp(prop) { return this.molProps[prop]; }

    setMolProp(prop, value, overwrite=false) {
        const prevVal = this.molProps[prop];
        if (prevVal) {
            if (overwrite) {
                console.log(`Overwriting mol prop ${prop}: ${prevVal} -> ${value}`);
            } else {
                console.log(`Not overwriting mol prop ${prop}: ${prevVal}. Ignoring new value: ${value}`);
                return;
            }
        }

        this.molProps[prop] = value;
    }

    // Energy efficiency is -interaction energy / heavy atom count
    // We get the energy from two different server packets (energiesForLig + solvationForLig)
    // We get the heavy atom count from the web service (via OpenBabel), although
    // we could really calculate that ourselves.
    // This function is called whenever these data sources are received, and when they are all
    // available, the efficiency will be added.
    //
    // If we don't have heavy atoms, we haven't received any molprops, so don't do anything.
    //      (If we were to add an efficiency prop, it would be the only one)
    // If we have heavy atoms, but are missing any component energies, energy efficiency is "N/A"
    updateEnergyEfficiency() {
        let energy = null;

        const heavyAtoms= this.molProps[MolProps.HeavyAtoms];

        // These energy components have to be available, but they aren't
        // necessarily part of the energy score calculation.
        const energyComponents = [
            EnergyInfo.Types.vdW,
            EnergyInfo.Types.ddGs,
            EnergyInfo.Types.electrostatics,
            EnergyInfo.Types.hbonds,
        ];
        if (this.energyInfo.energiesAvailable(energyComponents)) {
            energy = this.energyInfo.getEnergyScore();
        }

        if (heavyAtoms != null) {
            const efficiency = energy != null
                ? this.calcEnergyEfficiency(energy, heavyAtoms)
                : 'N/A';
            this.setMolProp(MolProps.EnergyEfficiency, efficiency, true);
            this.fireCompoundChanged();
        }
    }

    // Definition of energy efficiency:
    //      -(interaction energy) / (# heavy atoms)
    // If result < 0, just return 0.
    // Return null if missing energy or atom count
    calcEnergyEfficiency(energy, heavyAtoms) {
        if (energy != null && heavyAtoms) {
            const efficiency = -1 * (energy / heavyAtoms);
            return efficiency > 0 ? efficiency : 0;
        } else {
            return null;
        }
    }

    calcFsp3() {
        const carbons = this.atoms.filter((atom) => isCarbon(atom));

        if (carbons.length === 0) {
            return null;
        }

        const sp3Carbons = carbons.filter((atom) => atom.isSp3Carbon());
        return sp3Carbons.length / carbons.length;
    }

    fireCompoundChanged() {
        EventBroker.publish('compoundChanged', { compound: this });
    }

    toString() {
        return this.resSpec;
    }

    get selectQuery() {
        const atom = this.atoms[0];
        // To get around issues of weird characters in compound names, use the
        // full technical spec, ie UNL.<num>:Z for user compounds.

        // At one time, we thought we needed to specifiy ligand or compound.
        // const type = this.isLigand() ? 'ligand' : 'compound';
        const spec = getFullResidueId(atom, true);
        return spec;
    }

    isMinimizing() {
        return this.energyInfo.minimizationStatus === EnergyInfo.States.working;
    }

    anyEnergiesWorking() {
        return this.energyInfo.anyEnergiesWorking();
    }

    /**
     * Produce SDF content for a number of compounds
     * @param {Compound[]} compounds
     * @returns {string}
     */
    static makeSDF(compounds, sdfOptions) {
        return compounds.map((c) => c.getSDF(sdfOptions)).join('');
    }

    /**
     * Produce SDF content for this compound
     * @returns {string}
     */
    getSDF({ refCaseData, propInclusion }={}) {
        const molBlock = this.getMol2000(refCaseData).trimEnd();
        const sdfProps = this.getSDFPropsObj(propInclusion);
        return makeOneSDF(molBlock, sdfProps);
    }

    /**
     * Gather properties of interest for SDF export into an object
     * @returns {object}
     */
    getSDFPropsObj(propInclusion='all') {
        if (propInclusion === 'none') return {};

        // mapCase is the protein the compound is associated with, if any
        const { mapCase } = App.getDataParents(this);
        return {
            ...this.compoundInfoForExport(),
            ...this.heritageForExport(),
            ...this.proteinDataForExport(mapCase),
            ...this.energiesForExport(mapCase),
            ...this.molpropsForExport(),
            ...this.scopedPropertiesForExport(),
        };
    }

    compoundInfoForExport() {
        const infoProps = {
            Smiles: this.getUnnamedSmiles(),
        };

        const metaToAdd = [
            { scope: 'structure2d', key: 'inchikey', label: 'InChI Key' },
            { scope: 'structure2d', key: 'inchi', label: 'InChI' },
        ];
        for (const { scope, key, label } of metaToAdd) {
            if (this.getProperty(scope, key)) {
                infoProps[label] = this.getProperty(scope, key);
            }
        }
        return infoProps;
    }

    scopedPropertiesForExport() {
        const infoProps = {};
        for (const propInfo of this.listProperties({ includeChildren: true })) {
            const propMeta = propInfo.getMetadata();
            if (propInfo.traverseForMetadataItem('excludeFromSDF')) {
                continue;
            }
            // Use the original property names for the actual SDF props,
            // but include the scope for others.
            let label = propMeta.label;
            if (!label) {
                label = propInfo.traverseForMetadataItem('omitScopeNameForExport')
                    ? propInfo.name
                    : propInfo.getScopeListNames({ includeSelf: true }).join('/');
            }
            const { precision } = extractPrecisionFromMetadata(propMeta);

            // Convert property values to strings, skipping undefined.
            // (If the intention is to emit an empty SDF property, set the value to '' instead.)
            let value = propInfo.value;
            if (value === undefined) {
                continue;
            } else if (typeof value === 'number' && precision != null) {
                value = value.toFixed(precision);
            } else if (typeof value === 'object' && !Array.isArray(value)) {
                // This clause handles the null case as well
                value = JSON.stringify(value);
            }
            // strings, numbers and arrays can just be passed through
            infoProps[label] = value;
        }
        return infoProps;
    }

    /**
     * Copy over scoped properties from another compound, with filtering, depending on the
     * reason for the new compound.
     *
     * structure2d and model3d are always excluded, expecting that they have been added via
     * integrateCompounds.
     *
     * Known use cases:
     * After Duplicate Compound - copy everything except 2d/3d structure
     * After Energy Minimization - skip perPose properties
     * After Docking - skip perPose properties
     * After "Copy to Protein" - skip perPose and perCompoundTarget properties
     *
     * Filter options:
     * - skipPerPose: skip properties dependent on the 3D pose (eg GiFE score)
     * - skipPerCompoundTarget: skip properties dependent on the compound-target pair (eg IC50)
     * - copyAfterOperations can be used when a property that would normally be skipped is desired,
     * eg: after a compound minimization, the Autodock Docking Score (a perPose property)
     * should be preserved.
     *
     * @param {Compound} otherCompound
     * @param {{
     *      skipPerPose,
     *      skipPerCompoundTarget,
     *      operation
     * }} filterOptions
     */
    copyScopedPropertiesFrom(otherCompound, filterOptions={}) {
        const { skipPerPose, skipPerCompoundTarget, operation } = filterOptions;
        const excludedScopes = ['structure2d', 'model3d'];
        const groupFilter = (groupInfo) => !excludedScopes.includes(groupInfo.name);
        const propertyFilter = (propInfo) => {
            const { propApplicability, copyAfterOperations=[] } = propInfo.getMetadata();
            if (copyAfterOperations.includes(operation)) return true;
            const perPose = propApplicability === 'perPose';
            const perCmpdTarget = propApplicability === 'perCompoundTarget';
            const skippingPerPose = skipPerPose && perPose;
            const skippingPerCompoundTarget = skipPerCompoundTarget && (perCmpdTarget || perPose);
            if (skippingPerPose || skippingPerCompoundTarget) return false;
            return true;
        };
        this.scopedProperties.copyFrom(otherCompound.scopedProperties, false, {
            groupFilter, propertyFilter,
        });
    }

    proteinDataForExport() {
        // NYI
        // Need to think through privacy. Does a user necessarily want the protein name included?
        return {};
    }

    /**
     * Prepare SDF property with compound heritage.
     * This lists a source row.
     * Then "Modifications", followed by lines for modifications that changes the chemistry.
     * Docking / Minimization are appended to the previous line.
     * @returns { object }
     */
    heritageForExport() {
        const heritageLines = [];
        for (const heritageUnit of this.heritage.steps()) {
            const { resSpec, label } = heritageUnit;
            const detailText = getSDFDetailForHeritageUnit(heritageUnit);
            const detail = detailText ? `: ${detailText}` : '';
            if (heritageLines.length === 0) {
                heritageLines.push(`Source: ${resSpec} (${label}${detail})`);
            } else {
                const recentLineIdx = heritageLines.length - 1;
                switch (label) {
                    // Since the Docking entry has a lot of information, use the default case
                    // to put it on its own line, instead of appending to previous line
                    /*
                    case 'Docked': {
                        const dockDetail = detailText ? ` (${detailText})` : '';
                        heritageLines[recentLineIdx] += `; Docked${dockDetail}`;
                        break;
                    }
                    */
                    case 'Energy Minimized': {
                        const minDetail = detailText ? ` (${detailText})` : '';
                        heritageLines[recentLineIdx] += `; Minimized${minDetail}`;
                        break;
                    }
                    default: {
                        if (recentLineIdx === 0) heritageLines.push('Modifications:');
                        const modCount = heritageLines.length - 1;
                        heritageLines.push(`${modCount}. ${label}${detail}`);
                    }
                }
            }
        }

        return {
            'BMaps Compound Provenance': heritageLines.join('\n'),
        };
    }

    /**
     * @param {import('./MapCase').MapCase} mapCase
     */
    energiesForExport(mapCase) {
        // Energy props available when the compound is in the context of a protein
        const interactionEnergyPropsRules = [
            {
                label: 'BMaps Energy Score',
                // This definition of availability requires knowing that the score = vdW + hbonds
                available: () => this.energyTypeAvailable([
                    EnergyInfo.Types.vdW, EnergyInfo.Types.hbonds,
                ]),
                value: () => this.energyInfo.getEnergyScore().toFixed(2),
            },
            {
                label: 'van der Waals',
                available: () => this.energyTypeAvailable([EnergyInfo.Types.vdW]),
                value: () => this.energyInfo.getEnergyValueByType(EnergyInfo.Types.vdW).toFixed(2),
            },
            {
                label: 'BMaps ddGs',
                available: () => this.energyTypeAvailable([EnergyInfo.Types.ddGs]),
                value: () => this.energyInfo.getEnergyValueByType(EnergyInfo.Types.ddGs).toFixed(2),
            },
            {
                label: 'Hydrogen Bond Energy',
                available: () => this.energyTypeAvailable([EnergyInfo.Types.hbonds]),
                value: () => (
                    this.energyInfo.getEnergyValueByType(EnergyInfo.Types.hbonds).toFixed(2)
                ),
            },
            {
                label: 'Other Electostatics',
                available: () => this.energyTypeAvailable([EnergyInfo.Types.electrostatics]),
                value: () => (
                    this.energyInfo.getEnergyValueByType(EnergyInfo.Types.electrostatics).toFixed(2)
                ),
            },
            {
                label: 'Stress Delta',
                available: () => this.energyTypeAvailable([EnergyInfo.Types.stress]),
                value: () => (
                    this.energyInfo.getEnergyValueByType(EnergyInfo.Types.stress).toFixed(2)
                ),
            },
            {
                label: 'BMaps Energy Efficiency',
                available: () => this.energyTypeAvailable([
                    EnergyInfo.Types.vdW, EnergyInfo.Types.hbonds,
                ]),
                value: () => {
                    const val = this.molProps[MolProps.EnergyEfficiency];
                    // Could be N/A
                    return typeof val === 'number' ? val.toFixed(2) : val;
                },
            },
        ];

        // Energy props available in the no-protein case
        const internalOnlyEnergyPropsRules = [
            {
                label: 'BMaps Internal Energy',
                available: () => this.energyTypeAvailable([EnergyInfo.Types.stress]),
                value: () => this.energyInfo.getInternalEnergy().toFixed(2),
            },
        ];

        // Energy props available regardless of context
        const independentEnergyPropsRules = [];

        const energyPropsRules = [];
        if (mapCase) {
            energyPropsRules.push(...interactionEnergyPropsRules);
        } else {
            energyPropsRules.push(...internalOnlyEnergyPropsRules);
        }
        energyPropsRules.push(...independentEnergyPropsRules);

        const result = {};
        for (const { label, available, value } of energyPropsRules) {
            if (available()) {
                result[label] = value();
            }
        }
        return result;
    }

    /**
     * Returns an object mapping from SDF property name to value, in order of appearance.
     */
    molpropsForExport() {
        const sp3 = this.molProps[MolProps.Fsp3] != null
            ? this.molProps[MolProps.Fsp3].toFixed(2)
            : 'N/A';
        return {
            'BMaps Mol. Wt.': this.molProps[MolProps.MolWeight].toFixed(2),
            'BMaps Polar Surface Area': this.molProps[MolProps.PolarSurfaceArea].toFixed(2),
            'BMaps LogP (OpenBabel XLogP)': this.molProps[MolProps.LogP].toFixed(2),
            'BMaps HBond Donors': this.molProps[MolProps.HBondDonors],
            'BMaps HBond Acceptors': this.molProps[MolProps.HBondAcceptors],
            'BMaps Rotatable Bonds': this.molProps[MolProps.RotatableBonds],
            'BMaps Heavy Atoms': this.molProps[MolProps.HeavyAtoms],
            'BMaps Charge': this.molProps[MolProps.Charge],
            'BMaps Lipinski Violations': this.molProps[MolProps.LipinskiViolationCount],
            'BMaps Fraction Sp3': sp3,
            // Note: Energy efficiency is added in energiesForExport (only available with protein)
        };
    }
}

function getSDFDetailForHeritageUnit({ label, detailObject, detailText }) {
    switch (label) {
        // These cases remove the fragment binding score from the heritage detail
        // This is actually important information, so put it back until we can clarify the details.
        // case 'Fragment Grow':
        //     return `${detailObject.atom.atom} -> ${detailObject.suggestion.name}`;
        // case 'Search Nearby':
        //     return `${getFullResidueId(detailObject.atom)}, ${detailObject.atom.atom}`;
        default:
            return detailText;
    }
}

export class Ligand extends Compound {
    constructor(atoms) {
        super(atoms);
        this.setLigand(true);
        CompoundHeritage.addLigand(this);
    }
}

// Constants for MolProps. Also used for labels.
/**
 * @typedef {{
 *  HeavyAtoms: '# Heavy Atoms',
 *  PolarSurfaceArea: 'PSA',
 *  LogP: 'LogP',
 *  HBondAcceptors: 'HB Acceptors',
 *  HBondDonors: 'HB Donors',
 *  Charge: 'Charge',
 *  RotatableBonds: '# Rot. Bonds',
 *  MolWeight: 'Mol. Weight',
 *  EnergyEfficiency: 'Energy Efficiency',
 *  LipinskiViolationCount: '# Lipinski Violations',
 *  Fsp3: 'Fraction Sp3',
 * }} MolProps
 *
 * @type MolProps
 */
export const MolProps = {
    HeavyAtoms: '# Heavy Atoms',
    PolarSurfaceArea: 'PSA',
    LogP: 'LogP',
    HBondAcceptors: 'HB Acceptors',
    HBondDonors: 'HB Donors',
    Charge: 'Charge',
    RotatableBonds: '# Rot. Bonds',
    MolWeight: 'Mol. Weight',
    EnergyEfficiency: 'Energy Efficiency',
    LipinskiViolationCount: '# Lipinski Violations',
    Fsp3: 'Fraction Sp3',
};

// Map from Indigo molprop codes to ours
const IndigoMolPropMap = {
    HAC: MolProps.HeavyAtoms,
    TPSA: MolProps.PolarSurfaceArea,
    LogP: MolProps.LogP,
    HBA: MolProps.HBondAcceptors,
    HBD: MolProps.HBondDonors,
    charge: MolProps.Charge,
    RBC: MolProps.RotatableBonds,
    MW: MolProps.MolWeight,
};
