import slice from './slice';

export const {
    addStructure, changeProteinFamily,
    changeTarget, removeStructure,
    setCompoundTableState, setScoringTableState,
    setSidePanelWidth,
    removeAllStructures, reset,
    setTargetInfo,
} = slice.actions;

export default slice.reducer;
