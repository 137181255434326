/**
 * Element information copied from makeamber.cpp
 * Ref: http://www.iupac.org/publications/pac/2003/pdf/7508x1107.pdf
 *
 * Map from element symbol to [atomic number, element name, atomic weight]
 *
 * This is just a local object, but could be converted into a class if more operations were desired.
 */
export const ElementInfo = {
    H: [1, 'Hydrogen', 1.00794],
    He: [2, 'Helium', 4.002602],
    Li: [3, 'Lithium', 6.941],
    Be: [4, 'Beryllium', 9.012182],
    B: [5, 'Boron', 10.811],
    C: [6, 'Carbon', 12.0107],
    N: [7, 'Nitrogen', 14.0067],
    O: [8, 'Oxygen', 15.9994],
    F: [9, 'Fluorine', 18.9984032],
    Ne: [10, 'Neon', 20.1797],
    Na: [11, 'Sodium', 22.989770],
    Mg: [12, 'Magnesium', 24.3050],
    Al: [13, 'Aluminum', 26.981538],
    Si: [14, 'Silicon', 28.0855],
    P: [15, 'Phosphorus', 30.973761],
    S: [16, 'Sulfur', 32.065],
    Cl: [17, 'Chlorine', 35.453],
    Ar: [18, 'Argon', 39.948],
    K: [19, 'Potassium', 39.0983],
    Ca: [20, 'Calcium', 40.078],
    Sc: [21, 'Scandium', 44.955910],
    Ti: [22, 'Titanium', 47.867],
    V: [23, 'Vanadium', 50.9415],
    Cr: [24, 'Chromium', 51.9961],
    Mn: [25, 'Manganese', 54.938049],
    Fe: [26, 'Iron', 55.845],
    Co: [27, 'Cobalt', 58.933200],
    Ni: [28, 'Nickel', 58.6934],
    Cu: [29, 'Copper', 63.546],
    Zn: [30, 'Zinc', 65.409],
    Ga: [31, 'Gallium', 69.723],
    Ge: [32, 'Germanium', 72.64],
    As: [33, 'Arsenic', 74.92160],
    Se: [34, 'Selenium', 78.96],
    Br: [35, 'Bromine', 79.904],
    Kr: [36, 'Krypton', 83.798],
    Rb: [37, 'Rubidium', 85.4678],
    Sr: [38, 'Strontium', 87.62],
    Y: [39, 'Yttrium', 88.90585],
    Zr: [40, 'Zirconium', 91.224],
    Nb: [41, 'Niobium', 92.90638],
    Mo: [42, 'Molybdenum', 95.94],
    Ru: [44, 'Ruthenium', 101.07],
    Rh: [45, 'Rhodium', 102.90550],
    Pd: [46, 'Palladium', 106.42],
    Ag: [47, 'Silver', 107.8682],
    Cd: [48, 'Cadmium', 112.411],
    In: [49, 'Indium', 114.818],
    Sn: [50, 'Tin', 118.710],
    Sb: [51, 'Antimony', 121.760],
    Te: [52, 'Tellurium', 127.60],
    I: [53, 'Iodine', 126.90447],
    Xe: [54, 'Xenon', 131.293],
    Cs: [55, 'Cesium', 132.90545],
    Ba: [56, 'Barium', 137.327],
    La: [57, 'Lanthanum', 138.9055],
    Ce: [58, 'Cerium', 140.116],
    Pr: [59, 'Praseodymium', 140.90765],
    Nd: [60, 'Neodymium', 144.24],
    Sm: [62, 'Samarium', 150.36],
    Eu: [63, 'Europium', 151.964],
    Gd: [64, 'Gadolinium', 157.25],
    Tb: [65, 'Terbium', 158.92534],
    Dy: [66, 'Dysprosium', 162.500],
    Ho: [67, 'Holmium', 164.93032],
    Er: [68, 'Erbium', 167.259],
    Tm: [69, 'Thulium', 168.93421],
    Yb: [70, 'Ytterbium', 173.04],
    Lu: [71, 'Lutetium', 174.967],
    Hf: [72, 'Hafnium', 178.49],
    Ta: [73, 'Tantalum', 180.9479],
    W: [74, 'Tungsten', 183.84],
    Re: [75, 'Rhenium', 186.207],
    Os: [76, 'Osmium', 190.23],
    Ir: [77, 'Iridium', 192.217],
    Pt: [78, 'Platinum', 195.078],
    Au: [79, 'Gold', 196.96655],
    Hg: [80, 'Mercury', 200.59],
    Tl: [81, 'Thallium', 204.3833],
    Pb: [82, 'Lead', 207.2],
    Bi: [83, 'Bismuth', 208.98038],
};

/**
 * Return the atomic info for an element symbol
 * @param {string} symbol
 * @returns {{ atomicNumber: number, elementName: string, atomicWeight: number }}
 */
export function getElementInfo(symbol) {
    const info = ElementInfo[symbol];
    if (!info) return info;

    const [atomicNumber, elementName, atomicWeight] = info;
    return { atomicNumber, elementName, atomicWeight };
}

/**
 * Return the atomic weight for an element symbol
 * @param {string} symbol
 * @returns {number}
 */
export function getElementWeight(symbol) {
    const [, , atomicWeight] = ElementInfo[symbol] || [];
    return atomicWeight;
}

/**
 * Return the element name for an element symbol
 * @param {string} symbol
 * @returns {string}
 */
export function getElementName(symbol) {
    const [, elementName] = ElementInfo[symbol] || [];
    return elementName;
}

/**
 * Return the atomic number for an element symbol
 * @param {string} symbol
 * @returns {number}
 */
export function getElementNumber(symbol) {
    const [atomicNumber] = ElementInfo[symbol] || [];
    return atomicNumber;
}
