// visualizations/protein_surface_highlights.js
/**
 * @fileoverview utility functions for protein surface highlights
 *
 * Public interface:
 *    soluteSolvationSurfaceColor(atom)
 *    hydrophobicitySurfaceColor(atom)
 *
 * This doesn't actually draw the surfaces, but it calculates the surface color for each atom.
 */

import { App } from 'BMapsSrc/BMapsApp';
import { getFullResidueId } from 'BMapsUtil/mol_info_utils';
import { getColorFromThresholds } from 'BMapsUtil/visualization_utils';

const hydrophobicityColorParams = {
    moderateColor: 0x0aff0a,
    neutralColor: 0xc3c5c9,
    strongColor: 0xff0a0a,
    strongThreshold: -0.2,
    moderateThreshold: 1,
};

// These are per residue.
const ddGsProteinColorParams = {
    moderateColor: 0x0aff0a,
    neutralColor: 0xc3c5c9,
    strongColor: 0xff0a0a,
    strongThreshold: 10,
    moderateThreshold: 3,
};

export function soluteSolvationSurfaceColor(atom) {
    const ddgs = soluteSolvationEnergy(atom);
    const color = getColorFromThresholds(ddgs, ddGsProteinColorParams);
    // console.log(`protein ddgs ${ddgs.toFixed(3)}, color ${color.toString(16)}`);
    return color;
}

export function hydrophobicitySurfaceColor(atom) {
    const energy = hydrophobicityEnergy(atom);
    return getColorFromThresholds(energy, hydrophobicityColorParams);
}

// Get the solvation energy for a protein atom's residue.
// This is called atom-by-atom to create the highlighting surface.
// The interface could be improved:
// Passing in compound instead of accessing getActiveCompound.
function soluteSolvationEnergy(atom) {
    // What to do if the calculation fails? 0 ends up with a green highlight
    let solvEnergy = null;

    const cmpd = App.Workspace.getActiveCompound();
    const activeCompoundSpec = cmpd && cmpd.resSpec;
    if (!(activeCompoundSpec && atom.residue)) return solvEnergy;
    const solvEntry = atom.residue.solvInfoMap && atom.residue.solvInfoMap.get(activeCompoundSpec);
    if (solvEntry) {
        solvEnergy = solvEntry.ddGs; // assumes entry is defined
    } else {
        console.warn(`Missing ddGs for ${cmpd.resSpec} <-> ${getFullResidueId(atom)}`);
    }
    return solvEnergy;
}

/// Hydrophobic Interaction ///
//  This scale is from https://en.wikipedia.org/wiki/Hydrophobicity_scales
//  Origin: http://blanco.biomol.uci.edu/hydrophobicity_scales.html
const HydrophobicityScale = {
    ALA: ['ALA', 0.17, 0.50, 0.33],
    ARG: ['ARG', 0.81, 1.81, 1.00],
    ASN: ['ASN', 0.42, 0.85, 0.43],
    ASP: ['ASP', 1.23, 3.64, 2.41],
    ASP0: ['ASP', -0.07, 0.43, 0.50],
    CYS: ['CYS', -0.24, -0.02, 0.22],
    GLN: ['GLN', 0.58, 0.77, 0.19],
    GLU: ['GLU', 2.02, 3.63, 1.61],
    GLU0: ['GLU', -0.01, 0.11, 0.12],
    GLY: ['GLY', 0.01, 1.15, 1.14],
    HIS: ['HIS', 0.96, 2.33, 1.37],
    HIS0: ['HIS', 0.17, 0.11, -0.06],
    ILE: ['ILE', -0.31, -1.12, -0.81],
    LEU: ['LEU', -0.56, -1.25, -0.69],
    LYS: ['LYS', 0.99, 2.80, 1.81],
    MET: ['MET', -0.23, -0.67, -0.44],
    PHE: ['PHE', -1.13, -1.71, -0.58],
    PRO: ['PRO', 0.45, 0.14, -0.31],
    SER: ['SER', 0.13, 0.46, 0.33],
    THR: ['THR', 0.14, 0.25, 0.11],
    TRP: ['TRP', -1.85, -2.09, -0.24],
    TYR: ['TYR', -0.94, -0.71, 0.23],
    VAL: ['VAL', 0.07, -0.46, -0.53],
};

function getResidueHydrophobicity(resn) {
    const hpData = HydrophobicityScale[resn];
    return hpData ? hpData[3] : null;
}

function getAtomHydrophobicity(atom) {
    return getResidueHydrophobicity(atom.resn);
}

function hydrophobicityEnergy(atom) {
    return getAtomHydrophobicity(atom);
}
