export class BondVectorDisplay {
    constructor(atomPair, {
        color, description, onClick, object,
    }={}) {
        this.atomPair = atomPair;
        this.options = {
            color, description, onClick, object,
        };
    }
}
