/**
 * MolVisualizer Component
 * Creates a canvas and draws compound
 * Retains EventBroker mouse events like main canvas
 *
 * Some methods should be rewritten as they contain references to the viewer.
 * These methods are labelled with the comment: CONTAINS VIEWER METHODS.
 * Ideally, the Visualizer3Dmol should have all necessary methods so that referencing the viewer
 * is not necessary.
 *
 * @typedef {import('BMapsModel').Atom} Atom
 */
import React from 'react';
import { App } from '../BMapsApp';
import { Visualizer3Dmol } from '../Visualizer3Dmol';
import { EventBroker } from '../eventbroker';
import { hydrogenCheck } from '../util/display_utils';
import { pointDistance } from '../util/atom_distance_utils';
import { RDKitExport } from '../plugin/RDKitExport';
import { MolAtomLineRegex } from '../util/mol_format_utils';
import { sameElement } from '../util/chem_utils';

const compoundViewMap = new Map();

export class MolVisualizer extends React.Component {
    constructor(props) {
        super(props);
        this.className = this.getClass(props.viewType);
        this.canvasRef = React.createRef();
        this.initViewer = this.initViewer.bind(this);
        this.setViewerCompound = this.setViewerCompound.bind(this);
        this.setViewerView = this.setViewerView.bind(this);
        this.myMouseHandler = this.myMouseHandler.bind(this);
    }

    componentDidMount() {
        this.resizeListener = () => this.resize();
        window.addEventListener('resize', this.resizeListener);
        this.selectionListener = EventBroker.subscribe('setAtomSelected', (_, args) => this.updateSelected(args));
        this.initViewer();
    }

    componentDidUpdate(prevProps) {
        const { backgroundColorInfo, compound } = this.props;
        // Background Color
        if (prevProps.backgroundColorInfo.css !== backgroundColorInfo.css) {
            this.SubVisualizer.SetBackgroundColor(backgroundColorInfo.css);
        }

        // Compound
        if (prevProps.compound.smiles !== compound.smiles) {
            this.setViewerCompound({ compound });
        }
    }

    // ## CONTAINS VIEWER METHODS ##
    componentWillUnmount() {
        const { compound } = this.props;
        window.removeEventListener('resize', this.resizeListener);
        EventBroker.unsubscribe('setAtomSelected', this.selectionListener);
        // Saves view on unmount to be restored on next mount
        compoundViewMap.set(compound, this.SubVisualizer.viewer.getView());
    }

    getClass(viewKeyword) {
        switch (viewKeyword) {
            case '2D':
                return 'molvisualizer2d';
            case '3D':
                return 'molvisualizer2d';
            default:
                // Unexpected
                return '';
        }
    }

    // Called on init and when active compound changes
    // ## CONTAINS VIEWER METHODS ##;
    setViewerCompound({ compound }) {
        const { viewType } = this.props;
        this.SubVisualizer.Reset();
        // TODO: Is Reset sufficient? (only removes currentModel)
        this.SubVisualizer.viewer.removeAllModels();

        if (viewType === '2D') {
            // Uses AtomCorrespondence to link subviewer atoms to main viewer atoms
            let atomCorrespondence;
            try {
                atomCorrespondence = new AtomCorrespondence(compound);
            } catch (ex) {
                console.error(`2D Visualizer failed: ${ex}`);
                this.SubVisualizer.Redisplay(); // clear the display
                return;
            }
            const mol2dCoords = atomCorrespondence.get2dMol();
            // TODO add methods to Visualizer to add mol data and retrieve the list of atoms
            this.SubVisualizer.state.currentModel = this.SubVisualizer.viewer.addModel(mol2dCoords, 'sdf');
            const atoms2d = this.SubVisualizer.viewer.selectedAtoms({}); // ie get viewer atoms
            // Go through new viewer atoms, mark with uniqueID and map to Compound atoms
            for (const newAtom of atoms2d) {
                const { bmapsAtom } = atomCorrespondence.lookup2dAtom(newAtom);
                newAtom.appAtom = bmapsAtom;
                this.SubVisualizer.MapAtoms(bmapsAtom, newAtom);
            }
            // Adds click and hover events for atoms in the viewer
            // Necessary because the atoms are added via SDF instead of via Visualizer3Dmol.MakeAtom
            this.SubVisualizer.viewer.setClickable({}, true, this.SubVisualizer.MouseCallback);
            this.SubVisualizer.viewer.setHoverable(
                {}, true, this.SubVisualizer.HoverCallback, this.SubVisualizer.UnHoverCallback
            );
            this.SubVisualizer.viewer.enableContextMenu({}, true);
        } else if (viewType === '3D') {
            this.SubVisualizer.AddAtoms(compound.atoms);
        }
        // TODO Switching between 2D and 3D ends up with wonky coordinates
        // Could be because the view is stored on the compound.

        // Set 2d Style
        const hState = App.Workspace.displayState.hydrogens;

        for (const atom of compound.getAtoms()) {
            // Because we did the map atoms and set the uniqueID, we can operate
            // directly on the compound atoms, and the Visualizer will do the right thing.
            // This apparently redundant call to setAtomSelected() will recolor the atoms
            this.SubVisualizer.setAtomSelected(atom, App.Workspace.isSelectedAtom(atom));
            const style = hydrogenCheck(atom, hState) ? '2dview' : 'hidden';
            this.SubVisualizer.StyleAtom(atom, style);
        }

        this.SubVisualizer.ZoomToAtoms();
        this.SubVisualizer.Redisplay();
    }

    // Sets default view or previous view upon remount
    // ## CONTAINS VIEWER METHODS ##
    setViewerView({ compound }) {
        const view = compoundViewMap.get(compound);
        if (view !== undefined) {
            this.SubVisualizer.viewer.setView(view);
        } else {
            this.SubVisualizer.ZoomToAtoms();
            this.SubVisualizer.viewer.zoom(1.5);
        }
    }

    /**
     * Update an atom's selection status in response to a setAtomSelected event
     * @param {{atom: Atom, selected: boolean}} param0 Eventarg for the atom selection event
     */
    updateSelected({ atom, selected }) {
        this.SubVisualizer.setAtomSelected(atom, selected);
        // TODO too many redisplays
        this.SubVisualizer.Redisplay();
    }

    // Called once upon mount
    initViewer() {
        const { compound, canvasId, backgroundColorInfo } = this.props;
        this.SubVisualizer = new Visualizer3Dmol({
            parentEltId: canvasId,
            backgroundColor: backgroundColorInfo.css,
        });
        this.SubVisualizer.RegisterMouseHandler(this.myMouseHandler);

        // Add compound to viewer
        this.setViewerCompound({ compound });

        // Set View
        this.setViewerView({ compound });
    }

    // copied from current MainCanvas.js
    myMouseHandler(targetObject, mouseEvent) {
        const { viewType } = this.props;
        if (targetObject.background) {
            EventBroker.publish('backgroundMouse', mouseEvent);
        } else if (targetObject.atom) {
            const atom = targetObject.atom;
            if (viewType === '2D') {
                const bmapsAtom = this.SubVisualizer.AppAtom(atom);
                if (bmapsAtom) {
                    EventBroker.publish('atomMouse2d', { atomName: bmapsAtom.atom, mouseEvent });
                } else {
                    console.log(`MolVisualizer 2d mouse handling: Couldn't find atom for ${atom.atom}:${atom.uniqueID}`);
                }
            } else if (viewType === '3D') {
                EventBroker.publish('atomMouse2d', { atomName: atom.atom, mouseEvent });
            }
        } else {
            console.warn(`Unknown mouse target for ${mouseEvent.type}: ${JSON.stringify(targetObject)}`);
        }
    }

    // Force canvas resize and redraw
    resize() {
        this.forceUpdate();
    }

    render() {
        const { canvasId } = this.props;
        return (
            <div className={this.className} id={canvasId} ref={this.canvasRef} />
        );
    }
}

/**
 * ATOM CORRESPONDENCE CLASS
 *
 * Create a linkage between 2d and 3d atoms.
 * This is implemented as a list of atom objects of type: {
 *     x -- atom x coordinate in 2D
 *     y -- atom y coordinate in 2D
 *     z -- atom z coordinate in 2D
 *     elem -- the atom element name (eg C)
 *     index - atom index in both 2D and 3D mol strings
 *     atomName -- the atom name, used by the mouse handler
 *     pos3d: { x, y, z} -- coordinates of the atom in 3D (stored for reference, but not used)
 *     uniqueID -- the atom uniqueID in the server (not currently used)
 * }
 *
 * This list is created by combining the compound atom objects with the mol data atom rows
 * in both 3d and 2d:
 *   * Atom 3d coordinates are used to find the atom index in the 3d mol data rows
 *   * The atom index is used to find the 2d coordinates in the 2d mol data rows.
 * When an atom is clicked on in 2D, it looks up the atom data in this list using 2d coordinates,
 * and finds the necessary identifier data (currently atom name).
 *
 * Note: that it would make sense to store the original 3d atom in that atom info object.
 * I was so focused on just getting the atomName (currently needed by mouse handler) that I
 * didn't think of that.
 *
 * Usage:
 *      const atomCorrespondence = new AtomCorrespondence(compound);
 *      const mol2D = atomCorrespondence.get2dMol();
 *      // update visualizer with mol2D
 *      ...
 *      // in mouse handler
 *      const atomInfo = atomCorrespondence.lookup2dAtom(clickedOn2dAtom);
 *      // Publish mouse event with atomInfo.atomName
 */
class AtomCorrespondence {
    constructor(compound) {
        this.compound = compound;
        this.mol2d = AtomCorrespondence.make2d(compound);

        if (!this.mol2d) {
            throw new Error(`AtomCorrespondence: Unable set up correspondence for ${compound.resSpec} due to missing 2d coordinates`);
        }
        const mol3d = compound.getMol2000();
        // Extract atom coordinates, element, and index from mol data
        const atoms3d = mol3d.split('\n')
            .map(AtomCorrespondence.extractMolAtom).filter((x) => x);
        const atoms2d = this.mol2d.split('\n')
            .map(AtomCorrespondence.extractMolAtom).filter((x) => x);
        this.correspondenceList = AtomCorrespondence.makeCorrespondenceList(
            compound, atoms3d, atoms2d
        );
    }

    lookup2dAtom(atom2d) {
        return AtomCorrespondence.findAtomByCoords(atom2d, this.correspondenceList);
    }

    get2dMol() {
        return this.mol2d;
    }

    /**
     *
     * @param {Compound} compound
     * @param {Array} atoms3d array of extracted atoms from 3d mol data: { x, y, z, elem, index}
     * @param {Array} atoms2d array of extracted atoms from 2d mol data: { x, y, z, elem, index}
     * @returns {Array} lookup list of the form {
     *     x -- atom x coordinate in 2D
     *     y -- atom y coordinate in 2D
     *     z -- atom z coordinate in 2D
     *     elem -- the atom element name (eg C)
     *     index - atom index in both 2D and 3D mol strings
     *     atomName -- the atom name, used by the mouse handler
     *     pos3d: { x, y, z} -- coordinates of the atom in 3D (stored for reference, but not used)
     *     uniqueID -- the atom uniqueID in the server (not currently used)
     * }
     */
    static makeCorrespondenceList(compound, atoms3d, atoms2d) {
        const compoundAtoms = compound.getAtoms();
        return compoundAtoms.map((compoundAtom) => {
            const {
                x, y, z, elem, atom: atomName, uniqueID,
            } = compoundAtom;
            const atomObj = {
                elem,
                atomName,
                pos3d: { x, y, z },
                uniqueID,
                bmapsAtom: compoundAtom,
            };

            // Find the atom in the 3D mol string atoms, using 3D coordinates
            const found3d = AtomCorrespondence.findAtomByCoords(compoundAtom, atoms3d);
            if (found3d) {
                // Find the atom in the 2D mol string atoms, using index
                const index = found3d.index;
                const found2d = AtomCorrespondence.findAtomByIndex({ elem, index }, atoms2d);
                if (found2d) {
                    atomObj.index = index;
                    // Store 2D position, so we can do reverse lookup later
                    atomObj.x = found2d.x;
                    atomObj.y = found2d.y;
                    atomObj.z = found2d.z;
                } else {
                    console.error(`Didn't find atom ${atomName} (index ${index}) in 2D mol string`);
                }
            } else {
                console.error(`Didn't find atom ${atomName} (${x}, ${y}, ${z}) in 3D mol string`);
            }
            return atomObj;
        });
    }

    static make2d(compound) {
        return AtomCorrespondence.make2dRDkit(compound);
    }

    static make2dRDkit(compound) {
        const mol3d = compound.getMol2000();
        const molObj = RDKitExport.getMol(mol3d);
        const mol2d = molObj.get_new_coords(true);
        molObj.delete();
        return mol2d;
    }

    /**
     * Extract mol atom data from mol text. Very naive: can only use ONE molecule!
     * This could maybe be refactored to use matchAll (in Node 12) on the whole mol string,
     * instead of splitting and iterating over lines in the calling function.
    */
    static extractMolAtom(line) {
        const match = line.match(MolAtomLineRegex);
        if (match) {
            const {
                x, y, z, element, mapindex,
            } = match.groups;
            return {
                x: Number(x.trim()),
                y: Number(y.trim()),
                z: Number(z.trim()),
                elem: element.trim(),
                index: Number(mapindex.trim()),
            };
        }
        return null;
    }

    /** Find an atom in an atom list, matching the element and very close coordinates */
    static findAtomByCoords(atom1, atomList) {
        const tolerance = 0.01;
        return atomList.find(
            (atom2) => sameElement(atom1, atom2) && pointDistance(atom1, atom2) < tolerance
        );
    }

    /** Find an atom in an atom list, matching the element and atom index */
    static findAtomByIndex(atom1, atomList) {
        return atomList.find((atom2) => atom1.index === atom2.index && sameElement(atom1, atom2));
    }
}
