import { App } from 'BMapsSrc/BMapsApp';
import { EventBroker } from 'BMapsSrc/eventbroker';

export const SessionErrorType = Object.freeze({
    CantStart_NotLoggedIn: 'CantStart_NotLoggedIn',
    CantStart_SessionLimitReached: 'CantStart_SessionLimitReached',
    CantStart_ServerUnavailable: 'CantStart_ServerUnavailable',
    CantStart_NoInternet: 'CantStart_NoInternet',
    CantStart_Unknown: 'CantStart_Unknown',
    CantStart_Other: 'CantStart_Other',
    Lost_SessionLost: 'Lost_SessionLost',
    Lost_Reconnecting: 'Lost_Reconnecting',
    Lost_InactivityTimeout: 'Lost_InactivityTimeout',
    UnavailableInStaticMode: 'UnavailableInStaticMode',
    Unspecified: 'Unspecified',
});

/**
 * Return true if the provded server connection is "Disconnected"
 * @param {import('BMapsSrc/server/BFDServerInterface').BFDServerInterface} connector
 */
export function isDisconnectedSession(connector=App.PrimaryDataConnection?.connector) {
    return connector.getMode() === 'static' && connector.getModeForSavedState() === 'server';
}

/**
 * Return true if the provded server connection is Preview/Static mode
 * @param {import('BMapsSrc/server/BFDServerInterface').BFDServerInterface} connector
 */
export function isPreviewModeSession(connector=App.PrimaryDataConnection?.connector) {
    return connector.getMode() === 'static' && connector.getModeForSavedState() === 'static';
}

/**
 * If the session is "Disconnected", show Call to Action and return true.
 * @param {string} detail The attempted action, used in the Call to Action description
 * @param {import('BMapsSrc/server/BFDServerInterface').BFDServerInterface} connector
 * @returns {boolean} - true if the action was disallowed
 */
export function disallowIfDisconnected(detail, connector=App.PrimaryDataConnection.connector) {
    if (isDisconnectedSession(connector)) {
        callToAction(connector, detail);
        return true;
    }
    return false;
}

/**
 * @description Tell the user to sign up!
 * @param {import('BMapsSrc/server/BFDServerInterface').BFDServerInterface} connector
 * @param {string} detail A description of the attempted action
 */
export function callToAction(connector, detailIn) {
    const detail = (detailIn) ? `(${detailIn}) `: '';

    let errTitle = 'Log In or Sign Up to continue';
    let errType = SessionErrorType.UnavailableInStaticMode;
    let errMsg = `This action ${detail}requires a Boltzmann Maps account.<br>
    <br>Please login or create a free account to continue.<br>
    <br>Click <a href="/plans" target="_blank">here</a> to learn more about subscription options.`;

    if (isDisconnectedSession(connector)) {
        errTitle = 'Reconnect to continue';
        errType = SessionErrorType.Lost_SessionLost;
        errMsg = `This action ${detail}requires a connected BMaps session.<br>
        <br>Reload your session to access computation resources.`;
    }

    EventBroker.publish('sessionError', { errTitle, errMsg, errType });
}
