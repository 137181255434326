/**
 * ModalManager.jsx
 * ModalManager is a helper to display Modal dialogs.
 * It has a mount point in the DOM via UIManager and will render dialogs there.
 * Usage:
 *      ModalManager.Show({
 *          title: "My Title"
 *          content: <MyReactComponent ... />
 *      });
 *
 * This will display a <ModalPane /> with the title, a close button,
 * and MyReactComponent inside.
 *
 * ModalManager.Hide() will hide all modals managed by the ModalManager.
 *
 * This class will only handle one modal.  Below it is another version
 * called
 */

import React from 'react';
import ModalPane from './ModalPane';
import { EventBroker } from '../eventbroker';

export class ModalManager extends React.PureComponent {
    static Show(modalObj) {
        EventBroker.publish('modalDialogRequest', { op: 'show', obj: modalObj });
    }

    static Toggle(modalObj) {
        EventBroker.publish('modalDialogRequest', { op: 'toggle', obj: modalObj });
    }

    static Hide() {
        EventBroker.publish('modalDialogRequest', { op: 'hide' });
    }

    constructor(props) {
        super(props);
        this.state = {
            visibleModal: null,
        };
        this.close = this.close.bind(this);
        this.handleModalRequest = this.handleModalRequest.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('modalDialogRequest', this.handleModalRequest);
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('modalDialogRequest', this.handleModalRequest);
    }

    handleModalRequest(_, { op, obj }) {
        const { visibleModal } = this.state;
        switch (op) {
            case 'show':
                this.displayModal(obj);
                break;
            case 'toggle':
                if (visibleModal?.title === obj.title) {
                    this.close();
                } else {
                    this.displayModal(obj);
                }
                break;
            case 'hide':
                this.close();
                break;
            default:
                console.warn(`ModalManager: unhandled operation: ${op}`);
        }
    }

    displayModal(modalObj) {
        if (!(modalObj.options && modalObj.options.alreadyOpen)) {
            EventBroker.publish('escapeKey'); // Close other modals before opening
        }
        this.setState({ visibleModal: modalObj });
    }

    close() {
        const { visibleModal } = this.state;
        this.setState({ visibleModal: null });
        EventBroker.publish('modalClosed', { modal: visibleModal });
    }

    content() {
        const { visibleModal: modal } = this.state;
        const { ModalPaneProps } = this.props;
        return modal && (
        <ModalPane
            visible
            title={modal.title}
            eltId={modal.eltId}
            close={this.close}
            titleAdditions={modal.titleAdditions}
            style={modal.style}
            {...ModalPaneProps}
        >
            {modal.content}
        </ModalPane>
        );
    }

    render() {
        const content = this.content();
        return (
            <div id="modal_mgr">
                {' '}
                { content }
                {' '}
            </div>
        );
    }
}

// The code below expects that ModalPane.jsx has a "hidden" prop.
// The hidden prop has been removed, so that will need to be dealt with
// if attempting to revive the LongLivedModalManager.

// Effort to create a ModalManager that manages longlived modals
// This might be necessary if attempting to migrate Import / Export etc
// into this framework.
// export class LongLivedModalManager extends React.PureComponent {
//     static CreateSavedModal(modalObj) {
//         EventBroker.publish('modalDialogRequest', { op: 'create', obj: modalObj });
//     }

//     static Show(modalObj) {
//         EventBroker.publish('modalDialogRequest', { op: 'show', obj: modalObj });
//     }

//     static Hide() {
//         EventBroker.publish('modalDialogRequest', { op: 'hide' });
//     }

//     constructor(props) {
//         super(props);
//         this.state = {
//             visible: false,
//             saved: {},
//             visibleModal: null,
//         };
//         this.close = this.close.bind(this);
//         this.handleModalRequest = this.handleModalRequest.bind(this);
//     }

//     componentDidMount() {
//         EventBroker.subscribe('modalDialogRequest', this.handleModalRequest);
//     }

//     componentWillUnmount() {
//         EventBroker.unsubscribe('modalDialogRequest', this.handleModalRequest);
//     }

//     handleModalRequest(_, { op, obj }) {
//         switch (op) {
//             case 'create':
//                 this.createSavedModal(obj);
//                 break;
//             case 'show':
//                 this.displayModal(obj);
//                 break;
//             case 'hide':
//                 this.close();
//                 break;
//             default:
//                 console.warn(`LongLivedModalManager: unhandled operation: ${op}`);
//         }
//     }

//     createSavedModal(modalObj) {
//         this.setState((oldState) => {
//             const saved = oldState.saved;
//             if (!saved[modalObj.saveAs]) {
//                 saved[modalObj.saveAs] = modalObj;
//             }
//             return { saved };
//         });
//         this.forceUpdate();
//     }

//     displayModal(modalObj) {
//         this.setState((oldState) => {
//             if (modalObj.saveAs) { // Display a saved modal
//                 const newState = { visible: true };
//                 const saved = oldState.saved;
//                 if (!saved[modalObj.saveAs]) {
//                     saved[modalObj.saveAs] = modalObj;
//                     newState.saved = saved;
//                 }
//                 newState.visibleModal = saved[modalObj.saveAs];
//                 return newState;
//             } else { // Display a one time modal
//                 return { visible: true, visibleModal: modalObj };
//             }
//         });
//     }

//     close() {
//         this.setState({ visible: false });
//     }

//     content() {
//         const modals = [].concat(Object.values(this.state.saved));
//         const visibleModal = this.state.visibleModal;
//         if (visibleModal && !visibleModal.saveAs) {
//             modals.push(visibleModal);
//         }

//         return modals.map((m) => {
//             const show = this.state.visible && m === visibleModal;
//             const visible = m.saveAs || show;

//             return (
//                 <ModalPane
//                     key={m.saveAs || m.title}
//                     visible={visible}
//                     hidden={!show}
//                     title={m.title}
//                     content={m.content}
//                     eltId={m.eltId}
//                     childId={m.childId}
//                     close={this.close}
//                 />
//             );
//         });
//     }

//     render() {
//         const content = this.content();
//         return (
//             <div id="modal_mgr">
//                 {' '}
//                 { content }
//                 {' '}
//             </div>
//         );
//     }
// }
