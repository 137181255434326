// display_utils.js

import { EventBroker } from '../eventbroker';
import { isNonPolarHydrogen, isNotHydrogen } from './chem_utils';

export const HydrogenDisplayOptions = Object.freeze({
    none: 'none',
    polar: 'polar',
    all: 'all',
});

/**
 * Instructs 3D visualizer to avoid redisplay during the execution of a promise.
 * Eg. We don't want atoms to turn pink if they are temporarily selected for a background task.
 * @param {Promise} promise
 * @returns {*} The result of the promise
 */
export async function withPausedRedisplay(promise) {
    EventBroker.publish('pauseRedisplay', true);
    try {
        return await promise;
    } finally {
        EventBroker.publish('pauseRedisplay', false);
    }
}

/**
 * Should this atom be visible, based on hydrogen display settings (eg all, polar, none)?
 * @param {Atom} atom Atom to check
 * @param {string} policy Hydrogen policy
 * @returns {Boolean} true if atom should be visible.
 */
export function hydrogenCheck(atom, policy=HydrogenDisplayOptions.polar) {
    switch (policy) {
        case HydrogenDisplayOptions.all:
            return true;
        case HydrogenDisplayOptions.polar:
            return !isNonPolarHydrogen(atom);
        case HydrogenDisplayOptions.none:
            return isNotHydrogen(atom);
        default:
            // unexpected
            return true;
    }
}
