import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { UserActions } from 'BMapsCmds';
import { hasLogin } from 'BMapsSrc/server/session_request';
import { Link } from '@mui/material';
import { SidePanelContainer } from './info_display/SidePanel';
import { ChatBot } from './ChatBot';
import ExternalLink from './common/ExternalLink';
import { MainTour } from './Tour';
import { TheManual } from './manual/TheManual';
import DialogContentSection from './common/DialogContentSection';

export function HelpPanel({
    handleClose, open, longLived, ChatBotProps,
}) {
    const [loggedIn, setLoggedIn] = useState(false);
    useEffect(() => {
        hasLogin().then((newLoggedIn) => setLoggedIn(newLoggedIn));
    }, []);
    return (
        <SidePanelContainer
            open={open}
            handleClose={handleClose}
            longLived={longLived}
            title="Help / Support"
            width="20rem"
            containerStyle={{
                background: '#DCF0E6',
                // set z index to 0 to override default
                // right side panel needs to be under the bfm_viewer so that tooltips are
                // displayed on top of it. this is not true for left side panel
                // which is on top of the bfm_viewer
                zIndex: 0,
            }}
        >
            <Typography component="div">
                <DialogContentSection
                    titleVariant="subtitle1"
                    collapsible
                    defaultExpand
                    disablePaddingTop
                    title="Help Information"
                    TitleProps={{ style: { padding: '0 .5rem' } }}
                >
                    <HelpSection title="In-App Help:" style={{ paddingTop: 0 }}>
                        <HelpButton onClick={() => MainTour.start()}>Launch Tour</HelpButton>
                        <HelpButton onClick={() => TheManual.ShowPage(TheManual.Pages.Main)}>
                            Open Manual
                        </HelpButton>
                        <HelpButton onClick={() => TheManual.ShowPage(TheManual.Pages.Cheatsheet)}>
                            Cheatsheet
                        </HelpButton>
                    </HelpSection>
                    <HelpSection title="Documentation:">
                        <HelpLink link="https://www.youtube.com/channel/UCoFsnMxpCs1NnTB-9JAb1fw?&ab_channel=ConiferPoint">Tutorial Videos</HelpLink>
                        <HelpLink link="https://www.boltzmannmaps.com/faqs">Frequently Asked Questions</HelpLink>
                        <HelpLink link="https://www.boltzmannmaps.com/documentation">Documentation Pages</HelpLink>
                    </HelpSection>
                    <HelpSection title="Contact Us:">
                        <HelpLink link="mailto:support@coniferpoint.com">support@coniferpoint.com</HelpLink>
                    </HelpSection>
                    { !!loggedIn && (
                        <HelpSection title="Connect:">
                            <HelpLink link="https://join.slack.com/t/bmaps-community/shared_invite/zt-2kuban85x-hbntjszQ7lUfTn2pj0j0Rg">
                                Join our Slack community
                            </HelpLink>
                        </HelpSection>
                    )}
                </DialogContentSection>
            </Typography>
            <ChatBot
                loggedIn={loggedIn}
                {...ChatBotProps}
            />
        </SidePanelContainer>
    );
}

function HelpLink({ link, children }) {
    return (
        <Box>
            <ExternalLink
                link={link}
                style={{ color: 'var(--marketing-blue)' }}
                skipIcon
            >
                {children}
            </ExternalLink>
        </Box>
    );
}

function HelpSection({ title, style, children }) {
    const styleToUse = { paddingTop: '0.5rem', ...style };
    return (
        <Box style={styleToUse}>
            {title}
            {children}
        </Box>
    );
}

const HelpButton = ({ onClick, children }) => (
    <Box>
        <Link /* eslint-disable-line jsx-a11y/anchor-is-valid */
            component="button"
            onClick={onClick}
            sx={{ color: 'var(--marketing-blue)' }}
        >
            {children}
        </Link>
    </Box>
);

/**
 * @param {{ ChatBotProps?: { initalMessages: [string]} }} props
 */
export function openHelpMenu(props) {
    UserActions.OpenSidePanel('right', { pageId: 'help', props });
}

export function toggleHelpMenu() {
    UserActions.OpenSidePanel('right', { pageId: 'help', toggle: true });
}
