// page_nav.js

// Page navigation for when a user leaves the app
import { App } from './BMapsApp';
import { getPreferences } from './redux/prefs/access';
import { endAllSlotSessions } from './server/session_request';

function isStaticMode() {
    return App.App && App.ServerConnection && App.ServerConnection.staticMode;
}

function isConnected() {
    return App.App && App.ServerConnection
        && App.ServerConnection.isConnected && App.ServerConnection.isConnected();
}

function promptBeforeExit() {
    const { DontPromptBeforeExit } = getPreferences();
    return (isConnected() && !DontPromptBeforeExit
        && (App.Workspace.isProteinLoaded() || App.Workspace.getLoadedCompounds().length > 0));
}

function beforeUnloadHandler(event) {
    if (promptBeforeExit()) {
        const msg = 'You have data that may be lost. Are you sure you want to leave?';
        // For cross-compatability, these 3 lines are 3 ways to get browsers to prompt before exit.
        // Almost no browsers will actually display the msg.
        event.preventDefault();
        event.returnValue = msg;
        return msg;
    }
    return undefined;
}

function unloadHandler(event) {
    if (!isStaticMode()) {
        endAllSlotSessions(App.DataConnections);
    }
}

export function initNavigationHandlers() {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    window.addEventListener('unload', unloadHandler);
}
