import React from 'react';
import { EventBroker } from './eventbroker';
import { SmilesDrawerReact } from './SmilesDrawerReact';
import { withColorSchemeInfo } from './redux/prefs/access';
import { Loader } from './Loader';
import { InteractiveSvgViewer } from './ui/InteractiveSvgViewer';
import { SvgViewer } from './ui/UIComponents';

/* ActiveCompound2dViewer is a React component to display the
 * 2d structure of the active compound in BMaps.  It also can
 * temporarily display the structure of a moused-over compound.
 *
 * ActiveCompound2dViewer doesn't take any props.  It subscribes
 * to compound events on the EventBroker.
 */
class ActiveCompound2dViewerBase extends React.Component {
    static get DefaultViewer() { return 'SvgViewer'; }

    constructor(props) {
        super(props);
        this.state = {
            compound: props.compound,
            tmpCompound: null,
            viewer: ActiveCompound2dViewerBase.DefaultViewer,
            allowViewPicker: false,
        };
        this.onActiveCompound = this.onActiveCompound.bind(this);
        this.onZapAll = this.onZapAll.bind(this);
        this.onHoverCompound = this.onHoverCompound.bind(this);
        this.onCompoundChanged = this.onCompoundChanged.bind(this);

        // Enable viewerPickers
        if (Loader.AllowLabFeatures) {
            const params = new URLSearchParams(window.location.search);
            const viewer = params.get('2dviewer');
            if (viewer != null) {
                // Show the 2D viewer picker if the 2dviewer param is in the query string,
                // but allow it to be empty (defaults to the Indigo SVG viewer)
                this.state.allowViewPicker = true;
                if (viewer) {
                    this.state.viewer = viewer;
                }
            }
        }
    }

    componentDidMount() {
        EventBroker.subscribe('activeCompound', this.onActiveCompound);
        EventBroker.subscribe('compoundChanged', this.onCompoundChanged);
        EventBroker.subscribe('hoverCompound', this.onHoverCompound);
        EventBroker.subscribe('zapAll', this.onZapAll);
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('activeCompound', this.onActiveCompound);
        EventBroker.unsubscribe('compoundChanged', this.onCompoundChanged);
        EventBroker.unsubscribe('hoverCompound', this.onHoverCompound);
        EventBroker.unsubscribe('zapAll', this.onZapAll);
    }

    onActiveCompound(ignoreEventName, { compound }) {
        this.setState({ compound });
    }

    onCompoundChanged(ignoreEventName, { compound }) {
        const { compound: currentCmpd, tmpCompound } = this.state;
        if (compound === currentCmpd || compound === tmpCompound) {
            this.forceUpdate();
        }
    }

    onHoverCompound(ignoreEventName, { compound, hover }) {
        this.setState(({ compound: currentCmpd }) => {
            const applyTmp = hover && compound !== currentCmpd;
            return {
                tmpCompound: applyTmp ? compound : null,
            };
        });
    }

    onZapAll() {
        this.onActiveCompound('zapAll', { compound: null });
    }

    // Used only by commented out spans which let you change the viewer
    updateViewer(newViewer) {
        this.setState({ viewer: newViewer });
    }

    ViewerPicker({ viewerId }) {
        return (
            <button
                type="button"
                key={viewerId}
                style={{
                    color: '#aeaeffa0', fontFamily: 'sans-serif', textDecoration: 'underline', border: '0.5px solid #aeaeffa0', marginRight: '2px',
                }}
                onClick={() => this.updateViewer(viewerId)}
            >
                {viewerId}
            </button>
        );
    }

    render() {
        const {
            compound, tmpCompound, viewer, allowViewPicker,
        } = this.state;
        const { colorSchemeInfo } = this.props;
        let className = 'active2dViewer';
        let compoundToShow = compound;

        if (tmpCompound) {
            className += ' hoverpeek';
            compoundToShow = tmpCompound;
        }
        const viewPickers = [
            'SvgViewer', 'SmilesDrawer', '3DCanvasViewer-2D', '3DCanvasViewer-3D', 'InteractiveSvgViewer',
        ];
        return !!compound
            && (
                <div className={className}>
                    {!!allowViewPicker
                    && (
                    <div style={{ position: 'absolute', bottom: '0px' }}>
                        { viewPickers.map((viewerId) => this.ViewerPicker({ viewerId })) }
                    </div>
                    )}
                    <Viewer2d compound={compoundToShow} eltId="activeCompound2d" eltClassName="viewer2d" viewer={viewer} backgroundColorInfo={colorSchemeInfo} />
                </div>
            );
    }
}

export const ActiveCompound2dViewer = withColorSchemeInfo(ActiveCompound2dViewerBase);

/* Viewer2d is a React component to display a compound's 2d structure.
 * Currently it displays compound's previously calculated svg (via indigo).
 * It can also use SmilesDrawer.
 *
 * It takes props:
 *  - compound: a BMaps compound object that may or may not have a smiles string defined
 *  - eltId: the id for the child visualizer node that will be created
 *  - eltClassName: className for the child visualizer node that will be created
 *
 */
export class Viewer2d extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            smiles: null,
            mol2000: null,
            svg: null,
        };
    }

    // We need to extract smiles from props.compound and in state.smiles.
    // When the compound first arrives, it does not have a smiles string.
    // We need to detect when the compound's smiles changes, even if the compound
    // itself hasn't changed, so we'll store the smiles in state.
    //
    // We can't do this with shouldComponentUpdate().  If the compound's smiles changes,
    // this.props.compound.smiles and nextProps.compound.smiles will return the same.
    static getDerivedStateFromProps(nextProps, prevState) {
        const compound = nextProps.compound;
        const newSmiles = compound && compound.getSmiles();
        const newMol2000 = compound && compound.getMol2000();
        const newSvg = compound && compound.getSvg();

        if (newSvg === 'refresh') {
            compound.updateSvg();
            return null;
        }

        const updatedState = {};
        let needToUpdate = false;
        if (newSmiles !== prevState.smiles) {
            updatedState.smiles = newSmiles;
            needToUpdate = true;
        }
        if (newMol2000 !== prevState.mol2000) {
            updatedState.mol2000 = newMol2000;
            needToUpdate = true;
        }
        if (newSvg !== prevState.svg) {
            updatedState.svg = newSvg;
            needToUpdate = true;
        }

        return needToUpdate ? updatedState : null;
    }

    render() {
        const {
            compound, eltId, eltClassName, viewer, backgroundColorInfo,
        } = this.props;
        const { svg, smiles } = this.state;
        switch (viewer) {
            case 'SmilesDrawer':
                if (smiles) {
                    return (
                        <SmilesDrawerReact
                            canvasId={eltId}
                            canvasClassName={eltClassName}
                            smiles={smiles}
                            smilesDrawerOptions={{ bondThickness: 1.0, compactDrawing: false }}
                            backgroundColorInfo={backgroundColorInfo}
                        />
                    );
                }
                break;
            case 'SvgViewer':
                // Note: if svg is undefined, SvgViewer will render a small, empty box.
                // This only happens when a new compound has arrived, but is still waiting for svg.
                // We don't want to show the SmilesDrawer fallback below for that case,
                // (only want it for failed), so we need to return here, to prevent an ugly flash.
                // There is stil a minor flash where the 2D shrinks and expands again.
                // Would be better to prevent it altogether.
                if (svg !== 'failed') {
                    return (
                        <SvgViewer
                            id={eltId}
                            className="SvgViewer"
                            svg={svg}
                            alt={compound.resSpec}
                        />
                    );
                }
                break;
            case 'InteractiveSvgViewer':
                if (Loader.RDKitExport) { // RDKit is required for interactive SVG
                    return (
                        <InteractiveSvgViewer
                            paneId={eltId}
                            compound={compound}
                        />
                    );
                }
                break;
            case '3DCanvasViewer-2D':
            case '3DCanvasViewer-3D':
                if (Loader.MolVisualizer && Loader.RDKitExport) { // RDKit is required for 2d layout
                    return (
                        /**
                        * The viewtype prop can be either '2D' or '3D'. Both use a 3D mol canvas;
                        * the 2D keyword flattens the molecule on a single plane.
                        * */
                        <Loader.MolVisualizer
                            canvasId={eltId}
                            canvasClassName={eltClassName}
                            compound={compound}
                            viewType={viewer === '3DCanvasViewer-2D' ? '2D' : '3D'}
                            backgroundColorInfo={backgroundColorInfo}
                        />
                    );
                }
                break;
            default:
                console.warn(`Unrecognized 2d viewer: ${viewer}`);
        }

        // If we've gotten here, nothing worked. Try to fallback on SmilesDrawer
        if (smiles) {
            return (
                <SmilesDrawerWithNote
                    canvasId={eltId}
                    canvasClassName={eltClassName}
                    smiles={smiles}
                    smilesDrawerOptions={{ bondThickness: 1.0, compactDrawing: false }}
                    backgroundColorInfo={backgroundColorInfo}
                    note="Preferred 2D layout is unavailable, using SmilesDrawer"
                />
            );
        } else {
            console.warn(`2d visualizer ${viewer} is not available and no smiles to fallback on`);
            return false;
        }
    }
}

export function SmilesDrawerWithNote({ note, ...props }) {
    return (
        <span>
            <SmilesDrawerReact {...props} />
            <span style={{ fontStyle: 'italic', color: 'lightsteelblue', paddingLeft: '5px' }}>
                {note}
            </span>
        </span>
    );
}
