const listeners = {};

export function addListener(actionType, handler) {
    const list = listeners[actionType] ?? [];
    list.push(handler);

    listeners[actionType] = list;
}

const middleware = (store) => (next) => (action) => {
    const subs = listeners[action.type] ?? [];
    subs.forEach((handler) => handler(action.payload));

    return next(action);
};

export default middleware;
