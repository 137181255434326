import { createSlice } from '@reduxjs/toolkit';
import { preferences } from './constants';

const initialState = preferences.reduce((prev, curr) => {
    const { id, default: defaultVal } = curr;
    prev[id] = defaultVal;
    return prev;
}, {});

const slice = createSlice({
    name: 'prefs',
    initialState,
    reducers: {
        setPreference: {
            prepare: (pref, value) => ({ payload: { pref, value } }),
            reducer: (state, action) => {
                const { pref, value } = action.payload;
                state[pref] = value;
            },
        },
        setPreferences(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },

    },
});

export default slice;
