import { createSelector } from '@reduxjs/toolkit';

const registry = {};

export function register(key, selectorFn) {
    console.log(`Registering state subscriber ${key}`);
    if (registry[key]) {
        throw new Error(`State subscriber '${key}' already exists!`);
    }

    registry[key] = {
        selector: createSelector(
            selectorFn,
            (updatedSlice) => registry[key].callbacks.forEach((cb) => cb(updatedSlice))
        ),
        callbacks: [],
    };
}

export function subscribe(key, cb) {
    if (!registry[key]) {
        console.error(`A state subscriber for ${key} does not exist. Please add a "register" call in store.js!`);
        return;
    }

    registry[key].callbacks.push(cb);
}

export function initStateChangeSubscriber({ getState }) {
    return () => Object.values(registry).forEach(({ selector }) => selector(getState()));
}
