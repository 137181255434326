import { useState } from 'react';
import { CmdInterpreter } from 'BMapsCmds';
import { App } from 'BMapsSrc/BMapsApp';
import { captureAndTranscribe, wordsToCommand } from './util/speech_utils';

export default function VoiceCommandListener() {
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recording, setRecording] = useState(false);

    async function captureAndSend() {
        setRecording(true);
        const recorder = await captureAndTranscribe({
            setMediaRecorder,
            handleTranscription: async (words) => {
                if (words != null && words.length > 0) {
                    const context = {
                        focusCompound: App.Workspace.getActiveCompound()?.selectQuery,
                    };
                    const commandArr = await wordsToCommand(words, context);
                    CmdInterpreter.interpretCommand(commandArr);
                }
            },
            autoTranscribe: true,
        });

        // first time using recorder
        if (!mediaRecorder) {
            SayGreeting();
        }

        setMediaRecorder(recorder);
    }

    async function stopRecording() {
        setRecording(false);
        mediaRecorder.stop();
    }

    async function SayGreeting() {
        const greeting = `
        Greetings! Welcome to B Maps.
        While this microphone is active, you can speak to me to perform commands.
        Everything I hear will be sent to the voice to text provider, but nothing is saved.
        Click the microphone again, and I'll stop listening.
        `;
        await sayMessage(greeting);
    }

    return (
        <div>
            {recording ? (
                <button
                    type="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        stopRecording();
                    }}
                    id="speech_button_on"
                    className="topbar-button"
                    style={{ color: 'green' }}
                    title="Disable voice control"
                >
                    <i className="fa fa-microphone" />
                </button>
            ) : (
                <button
                    type="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        captureAndSend();
                    }}
                    id="speech_button_off"
                    className="topbar-button"
                    title="Enable voice control"
                >
                    <i className="fa fa-microphone-slash" />
                </button>
            )}
        </div>
    );
}

/**
 * @param {string} message
 */
export async function sayMessage(message) {
    return new Promise((resolveGreeting) => {
        const getVoices = () => new Promise((resolveVoices) => {
            let voices = speechSynthesis.getVoices();
            if (voices.length) {
                resolveVoices(voices);
                return;
            }
            speechSynthesis.onvoiceschanged = () => {
                voices = speechSynthesis.getVoices();
                speechSynthesis.onvoiceschanged = null;
                resolveVoices(voices);
            };
        });

        getVoices().then((voices) => {
            const greeting = new SpeechSynthesisUtterance(message);
            greeting.volume = 1;
            let foundVoice = false;
            for (const voice of voices) {
                if (!foundVoice && (voice.name === 'Fiona' || voice.name === 'Google UK English Female')) {
                    greeting.voice = voice;
                    foundVoice = true;
                }
            }
            greeting.onend = () => {
                resolveGreeting();
            };
            speechSynthesis.speak(greeting);
        });
    });
}
