/**
 * ServerInfo is a React component to display information about a server connection,
 * primarily:
 *      Connection status:
 *          connected / disconnected
 *          server / slot id
 *      Working item
 *          Which command is running
 *
 * The indicator is colored:
 *      grey   - not connected
 *      yellow - waiting for a command response from the server
 *      green  - connected and idle
 */
import React from 'react';
import _ from 'lodash';
import LinkLikeButton from 'BMapsUI/common/LinkLikeButton';
import { EventBroker } from '../eventbroker';
import { App } from '../BMapsApp';
import { loginUrlForMapCase } from '../connection_utils';
import { reconnectAndRestoreSession } from '../server/connection_utils';
import { isDisconnectedSession, isPreviewModeSession } from '../server/session_utils';
import { simpleCond } from '../util/js_utils';
import BMapsTooltip from './BMapsTooltip';

export default class ServerInfo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.updateCommandStatus = this.updateCommandStatus.bind(this);
        this.updateConnectionStatus = this.updateConnectionStatus.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('serverQueueUpdate', this.updateCommandStatus);
        EventBroker.subscribe('connectionStatusChanged', this.updateConnectionStatus);
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('serverQueueUpdate', this.updateCommandStatus);
        EventBroker.unsubscribe('connectionStatusChanged', this.updateConnectionStatus);
    }

    /**
     * @todo Ideally, this should return a list of info for all connections.
     */
    getConnectionInfo() {
        if (!App.Ready) return null;
        const conns = App.DataConnections
            .map((dc) => dc.getConnector())
            .map(this.getOneConnectionInfo);
        return conns.length > 0 ? conns : null;
    }

    getOneConnectionInfo(connector) {
        const connectionInfo = connector.connectionInfo;
        const connected = connector.isConnected();
        const previewMode = isPreviewModeSession(connector);
        const disconnectedSession = isDisconnectedSession(connector);
        const mode = connector.getMode();
        const agent = connectionInfo.agent;
        const port = connectionInfo.port;
        let serverDesc;
        switch (true) {
            case !!(connected && agent && port): serverDesc = `${agent}.${port}`; break;
            case connected: serverDesc = 'server'; break;
            case previewMode: serverDesc = 'Preview session'; break;
            case disconnectedSession: serverDesc = 'Disconnected session'; break;
            default: serverDesc = 'Disconnected';
        }

        return {
            connected,
            server: serverDesc,
            mode,
            currentCmd: connector.workingCommand,
            connector,
        };
    }

    updateConnectionStatus() {
        if (!App.Ready) return;
        this.forceUpdate();
    }

    updateCommandStatus() {
        if (!App.Ready) return;
        // Delay updaing the UI, so that simple clicks don't flash the indicator
        setTimeout(() => {
            this.forceUpdate();
        }, 300);
    }

    previewMode() {
        const myStructure = App.Workspace && App.Workspace.getLoadedProtein();
        const tooltip = 'Molecule import, docking, and new simulations are not available in Preview.';
        return (
            <BMapsTooltip title={tooltip} useColorScheme placement="bottom">
                <div className="server-info preview-mode-notice">
                    <div className="preview-mode-title">BMaps Preview</div>
                    <div className="preview-mode-desc">
                        <a href={loginUrlForMapCase(myStructure)}>Log in</a>
                        {' '}
                        for free access
                        {' '}
                        <br />
                        to the full set of features.
                    </div>
                </div>
            </BMapsTooltip>
        );
    }

    disconnectedMode() {
        const tooltip = 'Molecule import, docking, and new simulations are not available when disconnected.';
        return (
            <BMapsTooltip title={tooltip} useColorScheme placement="bottom">
                <div className="server-info preview-mode-notice">
                    <div className="preview-mode-title">Disconnected BMaps - View Only</div>
                    <div className="preview-mode-desc">
                        <LinkLikeButton
                            style={{
                                fontSize: 'inherit',
                                color: 'var(--marketing-blue)',
                                textAlign: 'center',
                                width: '100%',
                            }}
                            onClick={() => {
                                reconnectAndRestoreSession();
                            }}
                        >
                            Reload Session
                        </LinkLikeButton>
                    </div>
                </div>
            </BMapsTooltip>
        );
    }

    content(connectionInfo) {
        return (
            <div>
                {connectionInfo.map((info, ix) => this.contentForOne(info, ix))}
            </div>
        );
    }

    contentForOne({ currentCmd, connected, server }, index) {
        const sessionLabel = index > 0 ? `Session ${index + 1}` : 'Session';
        const serverText = `${sessionLabel}: ${connected ? `Connected to ${server}` : server}`;
        const cmdName = currentCmd ? currentCmd.name : 'Idle';
        const workingText = connected ? `Working on: ${cmdName}` : 'No computation available';
        return (
            <div key={sessionLabel}>
                <div>{serverText}</div>
                <div>{workingText}</div>
            </div>
        );
    }

    render() {
        const connectionInfo = this.getConnectionInfo();
        if (!connectionInfo || connectionInfo.length === 0) return false;

        if (connectionInfo.every(({ connector }) => isPreviewModeSession(connector))) {
            return this.previewMode();
        } else if (connectionInfo.every(({ connector }) => isDisconnectedSession(connector))) {
            return this.disconnectedMode();
        }

        const connected = connectionInfo.some((connInfo) => connInfo.connected);
        const cmdInProgress = connectionInfo.some(({ currentCmd }) => currentCmd);
        const workingColor = 'orange';
        const connectedColor = '#89bd45'; // same as 'marketing-green' from css
        const disconnectedColor = 'lightgrey';
        const className0 = `fa fa-cog${(cmdInProgress ? ' fa-spin' : '')}`;
        const className1 = `fa fa-cog${(cmdInProgress ? ' spin-reverse' : '')}`;

        const color = simpleCond([
            [connected && cmdInProgress, workingColor],
            [connected, connectedColor], // connected but not working on a command
            [true, disconnectedColor],
        ]);
        const styleCog0 = {
            color, fontSize: '22px', position: 'relative', top: '5px',
        };
        const styleCog1 = {
            color, fontSize: '18px', position: 'relative', top: '-5px',
        };
        const styleCog2 = {
            color, fontSize: '14px', position: 'relative', top: '12px', left: '-16px',
        };
        const content = this.content(connectionInfo);
        return (
            <BMapsTooltip title={content} useColorScheme placement="bottom">
                <div className="server-info">
                    <i className={className0} style={styleCog0} />
                    <i className={className1} style={styleCog1} />
                    <i className={className1} style={styleCog2} />
                </div>
            </BMapsTooltip>
        );
    }
}
