import _ from 'lodash';
import { sleep } from './util/js_utils';
import { BackgroundColors } from './themes';
import { svgForMol } from './util/svg_utils';

export class FragList {
    constructor(initialFragments) {
        this.list = null;
        this.promise = new Promise(() => {}); // will not resolve
        if (initialFragments) {
            this.setAvailableFragments(initialFragments);
        }
    }

    /**
     * @description Add info for one fragment series to the provided list,
     * either by creating a new FragInfo object or adding the series info
     * to the existing FragInfo for that fragment.
     * @param rawFragObj - A JS object with series data, parsed from json
     * @param list - The destination array
     */
    static addOneRawFragmentInfo(rawFragObj, list) {
        const existing = list.find((x) => x.name === rawFragObj.name); // same as findByName
        if (!existing) {
            list.push(new FragInfo(rawFragObj));
        } else {
            existing.addSeries(rawFragObj);
        }
    }

    /**
     * @description Add a list of fragment series info
     * @param newFragments Array of frag series data objects, parsed from JSON
     */
    setAvailableFragments(newFragments) {
        if (!this.isInitialized()) {
            const list = [];
            for (const fragInfo of newFragments) {
                FragList.addOneRawFragmentInfo(fragInfo, list);
            }
            this.list = list;
        } else {
            this.updateAvailableFragments(newFragments);
        }
        this.promise = Promise.resolve(true);
    }

    /**
     * @description Recreate the FragInfo list with the new fragment data,
     * but reuse any existing FragInfo objects (with FragInfo.replace())
     * This keeps existing object references for other parts of the system.
     *
     * @param {*} newFragments Array of frag series data objects, parsed from JSON
     */
    updateAvailableFragments(newFragments) {
        const result = [];
        const newMap = new Map();
        for (const f of newFragments) {
            if (newMap.has(f.name)) {
                newMap.get(f.name).push(f);
            } else {
                newMap.set(f.name, [f]);
            }
        }

        // Build the new FragInfo list in "result"
        newMap.forEach((fragList, name) => {
            const oldEntry = this.findByName(name);
            // For existing FragInfos, replace the data, but keep the object
            if (oldEntry) {
                const merging = fragList.shift();
                oldEntry.replace(merging);
                result.push(oldEntry);
            }
            for (const f of fragList) {
                FragList.addOneRawFragmentInfo(f, result);
            }
        });

        this.list = result;
    }

    isInitialized() {
        return !!this.list;
    }

    items() {
        return this.list ? [...this.list] : [];
    }

    anyAvailable() {
        return this.list ? this.list.length > 0 : false;
    }

    allSeries() {
        return this.list.reduce(
            (list, frag) => list.concat(frag.series_list),
            [],
        );
    }

    findByName(name) {
        if (!this.list) {
            console.warn('Fragment list has not been initialized yet');
            return null;
        }
        return this.list.find((x) => x.name === name);
    }

    async waitForInitialization() {
        return this.promise;
    }

    async loadFragImages() {
        if (!this.list) {
            console.warn('Fragment list has not been initialized yet');
            return Promise.resolve(null);
        }

        return Promise.all(
            this.list.map(async (frag) => {
                // This sleep (setTimeout) gives UI a chance to respond to user events in between
                // Interestingly, sleep has to come before updateSvg, otherwise UI hangs 🤔
                await sleep(0);
                await (frag.getSvg() || frag.updateSvg());
            })
        );
    }

    async getFragImage(name) {
        const frag = this.findByName(name);
        if (!frag) {
            if (name === 'water') {
                return FragInfo.generateSvg('O', 'smi');
            } else {
                return '';
            }
        }

        return frag.getImage();
    }

    hasFragInfo(fragInfo) {
        return this.list.indexOf(fragInfo) > -1;
    }

    get hasBuiltinSeries() {
        return this.items().some((fragInfo) => fragInfo.hasBuiltinSeries);
    }

    get hasUserSeries() {
        return this.items().some((fragInfo) => fragInfo.hasUserSeries);
    }
}

export class FragInfo {
    static async generateSvg(molData, format) {
        const params = { baseBondColor: 0x000000 };
        return svgForMol({
            source: 'indigo', molData, format, params,
        });
    }

    constructor(fragObj) {
        this.name = fragObj.name;
        this.smiles = fragObj.SMILES;
        this.mol = fragObj.MOL;
        this.series_list = [];
        this.fragmentObjects = [];
        this.minBValue = Number.MAX_SAFE_INTEGER;
        this.maxBValue = Number.MIN_SAFE_INTEGER;
        this.hasUserSeries = false;
        this.addSeries(fragObj);

        // Required for saving display state with atom groups
        this.type = 'FragInfo';
        this.key = this.name;

        // Used for application plumbing
        this.caseData = null;
    }

    getCaseData() { return this.caseData; }
    setCaseData(caseData) { this.caseData = caseData; }

    addSeries(fragObj) {
        const fsi = new FragSeriesInfo(fragObj, this);
        this.series_list.push(fsi);
        this.minBValue = Math.min(this.minBValue, fsi.minBValue);
        this.maxBValue = Math.max(this.maxBValue, fsi.maxBValue);
        if (fsi.isUserSeries) { this.hasUserSeries = true; }
    }

    // Replaces internal data, but preserves the containing object reference
    replace(rawFragObj) {
        Object.assign(this, new FragInfo(rawFragObj));
    }

    async getImage() {
        if (!this.getSvg()) {
            await this.updateSvg();
        }
        return this.getSvg();
    }

    setSvg(svg) {
        this.svg = svg;
    }

    getSvg() {
        return this.svg;
    }

    /**
     * Update the svg data for this fragment
     */
    async updateSvg(format = 'mol') {
        const molData = format === 'mol' ? this.mol : this.smiles;
        const svg = await FragInfo.generateSvg(molData, format);
        this.setSvg(svg);
    }

    updateFrags(fragmentObjects) {
        this.fragmentObjects.push(...fragmentObjects);
        for (const fragmentObj of fragmentObjects) {
            fragmentObj.fragmentInfo = this;
        }
    }

    get hasBuiltinSeries() {
        return this.series_list.find((series) => series.isBuiltinSeries);
    }
}

/**
 * @description Fragment information specific to a series.
 */
export class FragSeriesInfo {
    constructor(fragObj, fragInfo) {
        this.mount_index = fragObj.mount_index;
        this.series = fragObj.series;
        this.display_name = fragObj.display_name;
        this.nsnaps = fragObj.nsnaps;
        this.bValues = fragObj['B-values'];

        // Used by application plumbing
        this.fragInfo = fragInfo;
    }

    get minBValue() {
        return Math.min(...this.bValues);
    }

    get maxBValue() {
        return Math.max(...this.bValues);
    }

    get isUserSeries() { return this.mount_index !== 0; }

    get isBuiltinSeries() { return this.mount_index === 0; }

    getSummaryRequest() {
        return [this.mount_index, this.series];
    }

    getLoadRequest() {
        return { mount_index: this.mount_index, series: this.series };
    }

    getFragInfo() { return this.fragInfo; }
}
