/**
 * atomsnames.js
 * @fileoverview Contains atom information for residues, ligands, and compounds.
 */

// Associate atom names with residues, ions, ligands, etc.

// Since some keys have single quotes in them, all delimiters are double-quotes. Disable quote check
/* eslint-disable quotes */

//--- Package these into a single object?

/** Atom names by protein resiude
 * @type {Map<string, string[]>}
*/
export const residueAtomNamesMap = new Map([
    ["ALA", ["N", "H", "CA", "HA", "CB", "HB1", "HB2", "HB3", "C", "O"]],
    ["GLY", ["N", "H", "CA", "HA2", "HA3", "C", "O"]],
    ["SER", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "OG", "HG", "C", "O"]],
    ["THR", ["N", "H", "CA", "HA", "CB", "HB", "CG2", "HG21", "HG22", "HG23", "OG1", "HG1", "C", "O"]],
    ["LEU", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG", "CD1", "HD11", "HD12", "HD13", "CD2", "HD21", "HD22", "HD23", "C", "O"]],
    ["ILE", ["N", "H", "CA", "HA", "CB", "HB", "CG2", "HG21", "HG22", "HG23", "CG1", "HG12", "HG13", "CD1", "HD11", "HD12", "HD13", "C", "O"]],
    ["VAL", ["N", "H", "CA", "HA", "CB", "HB", "CG1", "HG11", "HG12", "HG13", "CG2", "HG21", "HG22", "HG23", "C", "O"]],
    ["ASN", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "ND2", "HD21", "HD22", "C", "O"]],
    ["GLN", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "NE2", "HE21", "HE22", "C", "O"]],
    ["ARG", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "HD2", "HD3", "NE", "HE", "CZ", "NH1", "HH11", "HH12", "NH2", "HH21", "HH22", "C", "O"]],
    ["HID", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "HD1", "CE1", "HE1", "NE2", "CD2", "HD2", "C", "O"]],
    ["HIE", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "CE1", "HE1", "NE2", "HE2", "CD2", "HD2", "C", "O"]],
    ["HIP", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "HD1", "CE1", "HE1", "NE2", "HE2", "CD2", "HD2", "C", "O"]],
    ["TRP", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "NE1", "HE1", "CE2", "CZ2", "HZ2", "CH2", "HH2", "CZ3", "HZ3", "CE3", "HE3", "CD2", "C", "O"]],
    ["PHE", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "CE1", "HE1", "CZ", "HZ", "CE2", "HE2", "CD2", "HD2", "C", "O"]],
    ["TYR", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "CE1", "HE1", "CZ", "OH", "HH", "CE2", "HE2", "CD2", "HD2", "C", "O"]],
    ["GLU", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "OE2", "C", "O"]],
    ["ASP", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "OD2", "C", "O"]],
    ["LYS", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "HD2", "HD3", "CE", "HE2", "HE3", "NZ", "HZ1", "HZ2", "HZ3", "C", "O"]],
    ["LYN", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "HD2", "HD3", "CE", "HE2", "HE3", "NZ", "HZ2", "HZ3", "C", "O"]],
    ["PRO", ["N", "CD", "HD2", "HD3", "CG", "HG2", "HG3", "CB", "HB2", "HB3", "CA", "HA", "C", "O"]],
    ["CYS", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "SG", "HG", "C", "O"]],
    ["CYX", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "SG", "C", "O"]],
    ["CYM", ["N", "H", "CA", "HA", "CB", "HB3", "HB2", "SG", "C", "O"]],
    ["MET", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "SD", "CE", "HE1", "HE2", "HE3", "C", "O"]],
    ["ACE", ["HH31", "CH3", "HH32", "HH33", "C", "O"]],
    ["NME", ["N", "H", "CH3", "HH31", "HH32", "HH33"]],
    //              ["NHE", [ "N", "HN1", "HN2" ]],
    ["NH2", ["N", "HN1", "HN2"]],
    ["ASH", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "OD2", "HD2", "C", "O"]],
    ["GLH", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "OE2", "HE2", "C", "O"]],
    ["ALA_CT", ["N", "H", "CA", "HA", "CB", "HB1", "HB2", "HB3", "C", "O", "OXT"]],
    ["GLY_CT", ["N", "H", "CA", "HA2", "HA3", "C", "O", "OXT"]],
    ["SER_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "OG", "HG", "C", "O", "OXT"]],
    ["THR_CT", ["N", "H", "CA", "HA", "CB", "HB", "CG2", "HG21", "HG22", "HG23", "OG1", "HG1", "C", "O", "OXT"]],
    ["LEU_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG", "CD1", "HD11", "HD12", "HD13", "CD2", "HD21", "HD22", "HD23", "C", "O", "OXT"]],
    ["ILE_CT", ["N", "H", "CA", "HA", "CB", "HB", "CG2", "HG21", "HG22", "HG23", "CG1", "HG12", "HG13", "CD1", "HD11", "HD12", "HD13", "C", "O", "OXT"]],
    ["VAL_CT", ["N", "H", "CA", "HA", "CB", "HB", "CG1", "HG11", "HG12", "HG13", "CG2", "HG21", "HG22", "HG23", "C", "O", "OXT"]],
    ["ASN_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "ND2", "HD21", "HD22", "C", "O", "OXT"]],
    ["GLN_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "NE2", "HE21", "HE22", "C", "O", "OXT"]],
    ["ARG_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "HD2", "HD3", "NE", "HE", "CZ", "NH1", "HH11", "HH12", "NH2", "HH21", "HH22", "C", "O", "OXT"]],
    ["HID_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "HD1", "CE1", "HE1", "NE2", "CD2", "HD2", "C", "O", "OXT"]],
    ["HIE_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "CE1", "HE1", "NE2", "HE2", "CD2", "HD2", "C", "O", "OXT"]],
    ["HIP_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "HD1", "CE1", "HE1", "NE2", "HE2", "CD2", "HD2", "C", "O", "OXT"]],
    ["TRP_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "NE1", "HE1", "CE2", "CZ2", "HZ2", "CH2", "HH2", "CZ3", "HZ3", "CE3", "HE3", "CD2", "C", "O", "OXT"]],
    ["PHE_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "CE1", "HE1", "CZ", "HZ", "CE2", "HE2", "CD2", "HD2", "C", "O", "OXT"]],
    ["TYR_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "CE1", "HE1", "CZ", "OH", "HH", "CE2", "HE2", "CD2", "HD2", "C", "O", "OXT"]],
    ["GLU_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "OE2", "C", "O", "OXT"]],
    ["GLH_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "OE2", "HE2", "C", "O", "OXT"]],
    ["ASP_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "OD2", "C", "O", "OXT"]],
    ["ASH_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "OD2", "HD2", "C", "O", "OXT"]],
    ["LYS_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "HD2", "HD3", "CE", "HE2", "HE3", "NZ", "HZ1", "HZ2", "HZ3", "C", "O", "OXT"]],
    ["PRO_CT", ["N", "CD", "HD2", "HD3", "CG", "HG2", "HG3", "CB", "HB2", "HB3", "CA", "HA", "C", "O", "OXT"]],
    ["CYS_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "SG", "HSG", "C", "O", "OXT"]],
    ["CYX_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "SG", "C", "O", "OXT"]],
    ["CYM_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "SG", "C", "O", "OXT"]],
    ["MET_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "SD", "CE", "HE1", "HE2", "HE3", "C", "O", "OXT"]],
    ["MSE_CT", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "SE", "CE", "HE1", "HE2", "HE3", "C", "O", "OXT"]],
    ["ALA_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB1", "HB2", "HB3", "C", "O"]],
    ["GLY_NT", ["N", "H1", "H2", "H3", "CA", "HA2", "HA3", "C", "O"]],
    ["SER_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "OG", "HG", "C", "O"]],
    ["THR_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB", "CG2", "HG21", "HG22", "HG23", "OG1", "HG1", "C", "O"]],
    ["LEU_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG", "CD1", "HD11", "HD12", "HD13", "CD2", "HD21", "HD22", "HD23", "C", "O"]],
    ["ILE_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB", "CG2", "HG21", "HG22", "HG23", "CG1", "HG12", "HG13", "CD1", "HD11", "HD12", "HD13", "C", "O"]],
    ["VAL_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB", "CG1", "HG11", "HG12", "HG13", "CG2", "HG21", "HG22", "HG23", "C", "O"]],
    ["ASN_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "ND2", "HD21", "HD22", "C", "O"]],
    ["ASH_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "OD2", "HD2", "C", "O"]],
    ["GLN_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "NE2", "HE21", "HE22", "C", "O"]],
    ["GLH_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "OE2", "HE2", "C", "O"]],
    ["ARG_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "HD2", "HD3", "NE", "HE", "CZ", "NH1", "HH11", "HH12", "NH2", "HH21", "HH22", "C", "O"]],
    ["HID_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "HD1", "CE1", "HE1", "NE2", "CD2", "HD2", "C", "O"]],
    ["HIE_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "CE1", "HE1", "NE2", "HE2", "CD2", "HD2", "C", "O"]],
    ["HIP_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "ND1", "HD1", "CE1", "HE1", "NE2", "HE2", "CD2", "HD2", "C", "O"]],
    ["TRP_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "NE1", "HE1", "CE2", "CZ2", "HZ2", "CH2", "HH2", "CZ3", "HZ3", "CE3", "HE3", "CD2", "C", "O"]],
    ["PHE_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "CE1", "HE1", "CZ", "HZ", "CE2", "HE2", "CD2", "HD2", "C", "O"]],
    ["TYR_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "CE1", "HE1", "CZ", "OH", "HH", "CE2", "HE2", "CD2", "HD2", "C", "O"]],
    ["GLU_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "OE1", "OE2", "C", "O"]],
    ["ASP_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "OD1", "OD2", "C", "O"]],
    ["LYS_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "CD", "HD2", "HD3", "CE", "HE2", "HE3", "NZ", "HZ1", "HZ2", "HZ3", "C", "O"]],
    ["PRO_NT", ["N", "H2", "H3", "CD", "HD2", "HD3", "CG", "HG2", "HG3", "CB", "HB2", "HB3", "CA", "HA", "C", "O"]],
    ["CYS_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "SG", "HSG", "C", "O"]],
    ["CYX_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "SG", "C", "O"]],
    ["CYM_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "SG", "C", "O"]],
    ["MET_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "SD", "CE", "HE1", "HE2", "HE3", "C", "O"]],
    ["MSE_NT", ["N", "H1", "H2", "H3", "CA", "HA", "CB", "HB2", "HB3", "CG", "HG2", "HG3", "SE", "CE", "HE1", "HE2", "HE3", "C", "O"]],
    ["DA5", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "H61", "H62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'"]],
    ["DA", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "H61", "H62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'"]],
    ["DA3", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "H61", "H62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'", "HO3'"]],
    ["DAN", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "H61", "H62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'", "HO3'"]],
    ["A5", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "H61", "H62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'"]],
    ["A", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "H61", "H62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'"]],
    ["A3", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "H61", "H62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'", "HO3'"]],
    ["AN", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "H61", "H62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'", "HO3'"]],
    ["DT5", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "C7", "H71", "H72", "H73", "C4", "O4", "N3", "H3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'"]],
    ["DT", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "C7", "H71", "H72", "H73", "C4", "O4", "N3", "H3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'"]],
    ["DT3", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "C7", "H71", "H72", "H73", "C4", "O4", "N3", "H3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'", "HO3'"]],
    ["DTN", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "C7", "H71", "H72", "H73", "C4", "O4", "N3", "H3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'", "HO3'"]],
    ["U5", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "O4", "N3", "H3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'"]],
    ["U", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "O4", "N3", "H3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'"]],
    ["U3", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "O4", "N3", "H3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'", "HO3'"]],
    ["UN", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "O4", "N3", "H3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'", "HO3'"]],
    ["DG5", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "H1", "C2", "N2", "H21", "H22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'"]],
    ["DG", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "H1", "C2", "N2", "H21", "H22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'"]],
    ["DG3", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "H1", "C2", "N2", "H21", "H22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'", "HO3'"]],
    ["DGN", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "H1", "C2", "N2", "H21", "H22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'", "HO3'"]],
    ["G5", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "H1", "C2", "N2", "H21", "H22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'"]],
    ["G", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "H1", "C2", "N2", "H21", "H22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'"]],
    ["G3", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "H1", "C2", "N2", "H21", "H22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'", "HO3'"]],
    ["GN", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "H1", "C2", "N2", "H21", "H22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'", "HO3'"]],
    ["DC5", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "N4", "H41", "H42", "N3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'"]],
    ["DC", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "N4", "H41", "H42", "N3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'"]],
    ["DC3", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "N4", "H41", "H42", "N3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'", "HO3'"]],
    ["DCN", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "N4", "H41", "H42", "N3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "H2''", "O3'", "HO3'"]],
    ["C5", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "N4", "H41", "H42", "N3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'"]],
    ["C", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "N4", "H41", "H42", "N3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'"]],
    ["C3", ["P", "OP1", "OP2", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "N4", "H41", "H42", "N3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'", "HO3'"]],
    ["CN", ["HO5'", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N1", "C6", "H6", "C5", "H5", "C4", "N4", "H41", "H42", "N3", "C2", "O2", "C3'", "H3'", "C2'", "H2'", "O2'", "HO'2", "O3'", "HO3'"]],
    ["MSE", ["N", "H", "CA", "HA", "CB", "HB1", "HB2", "CG", "HG2", "HG3", "SE", "CE", "HE1", "HE2", "HE3", "C", "O"]],
    ["PTN", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "CE1", "HE1", "CZ", "OH", "CE2", "HE2", "CD2", "HD2", "C", "O", "P", "OP1", "OP2", "HOP2", "O3P", "HO3P"]],
    ["PTR", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "CG", "CD1", "HD1", "CE1", "HE1", "CZ", "OH", "CE2", "HE2", "CD2", "HD2", "C", "O", "P", "OP1", "OP2", "O3P"]],
    ["HYP", ["N", "CD", "HD2", "HD3", "CG", "HG", "CB", "HB2", "HB3", "CA", "HA", "C", "O", "OD", "HOD"]],
    ["SEP", ["N", "H", "CA", "HA", "CB", "HB2", "HB3", "OG", "P", "C", "O", "OP1", "OP2", "HOP2", "O3P", "HO3P"]],
    ["ALS", ["N", "H", "CA", "HA", "CB", "HB", "OG", "HOG", "OS1", "S", "OS2", "OS3", "OS4", "C", "O"]],
    // saccharides.fdb
    ["NAG_OT", ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8", "N2", "O3", "O4", "O5", "O6", "O7", "H2", "H3", "H4", "H5", "H11", "H12", "H61", "H62", "H81", "H82", "H83", "HN2", "HO3", "HO6"]],
    ["NAG_CT", ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8", "N2", "O3", "O4", "O5", "O6", "O7", "H1", "H2", "H3", "H4", "H5", "H61", "H62", "H81", "H82", "H83", "HN2", "HO3", "HO4", "HO6"]],
    ["NAG", ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8", "N2", "O3", "O4", "O5", "O6", "O7", "H1", "H2", "H3", "H4", "H5", "H61", "H62", "H81", "H82", "H83", "HN2", "HO3", "HO6"]],
    ["BMA_CT", ["C1", "C2", "C3", "C4", "C5", "C6", "O2", "O3", "O4", "O5", "O6", "H1", "H2", "H3", "H4", "H5", "H61", "H62", "HO2", "HO3", "HO4", "HO6"]],
    ["BMA", ["C1", "C2", "C3", "C4", "C5", "C6", "O2", "O3", "O4", "O5", "O6", "H1", "H2", "H3", "H4", "H5", "H61", "H62", "HO2", "HO4", "HO6"]],
    ["MAN", ["C1", "C2", "C3", "C4", "C5", "C6", "O2", "O3", "O4", "O5", "O6", "H1", "H2", "H3", "H4", "H5", "H61", "H62", "HO3", "HO4", "HO6"]],
    ["MAN_CT", ["C1", "C2", "C3", "C4", "C5", "C6", "O2", "O3", "O4", "O5", "O6", "H1", "H2", "H3", "H4", "H5", "H61", "H62", "HO2", "HO3", "HO4", "HO6"]],
    // FUC and FUL are different conformations of the same sugar L-fucopyranose
    ["FUC", ["C1", "C2", "C3", "C4", "C5", "C6", "O1", "O2", "O3", "O4", "O5", "H1", "H2", "H3", "H4", "H5", "H61", "H62", "H63", "HO1", "HO2", "HO3", "HO4"]],
    ["FUL", ["C1", "C2", "C3", "C4", "C5", "C6", "O1", "O2", "O3", "O4", "O5", "H1", "H2", "H3", "H4", "H5", "H61", "H62", "H63", "HO1", "HO2", "HO3", "HO4"]],
]);

/**  Atom names for ions, Derived from ions.fdb */
export const ionAtomNamesMap = new Map([
    ["AG", ["AG"]],
    ["AL", ["AL"]],
    ["AM", ["AM"]],
    ["AR", ["AR"]],
    ["ARS", ["AS"]],
    ["AU", ["AU"]],
    ["AU3", ["AU"]],
    ["BA", ["BA"]],
    ["0BE", ["BE"]],
    ["BR", ["BR"]],
    ["BS3", ["BI"]],
    ["CA", ["CA"]],
    ["CD", ["CD"]],
    ["CE", ["CE"]],
    ["CF", ["CF"]],
    ["CL", ["CL"]],
    ["CIM", ["CL"]],
    ["ZCM", ["CM"]],
    ["CO", ["CO"]],
    ["3CO", ["CO"]],
    ["CR", ["CR"]],
    ["CS", ["CS"]],
    ["CU", ["CU"]],
    ["CU1", ["CU"]],
    ["CU3", ["CU"]],
    ["D8U", ["D"]],
    ["DY", ["DY"]],
    ["ER3", ["ER"]],
    ["EU", ["EU"]],
    ["EU3", ["EU"]],
    ["F", ["F"]],
    ["FE", ["FE"]],
    ["FE2", ["FE"]],
    ["GA", ["GA"]],
    ["GD", ["GD"]],
    ["GD3", ["GD"]],
    ["H", ["H"]],
    ["HG", ["HG"]],
    ["HO", ["HO"]],
    ["HO3", ["HO"]],
    ["IN", ["IN"]],
    ["IOD", ["I"]],
    ["IR", ["IR"]],
    ["IR3", ["IR"]],
    ["K", ["K"]],
    ["KR", ["KR"]],
    ["LA", ["LA"]],
    ["LI", ["LI"]],
    ["LU", ["LU"]],
    ["MG", ["MG"]],
    ["MGM", ["MG", "MG1", "MG2", "MG3", "MG4", "MG5", "MG6"]],
    ["MN", ["MN"]],
    ["MN3", ["MN"]],
    ["MO", ["MO"]],
    ["4MO", ["MO"]],
    ["6MO", ["MO"]],
    ["NA", ["NA"]],
    ["CIP", ["NA"]],
    ["NI", ["NI"]],
    ["3NI", ["NI"]],
    ["OS", ["OS"]],
    ["OS4", ["OS"]],
    ["PB", ["PB"]],
    ["PD", ["PD"]],
    ["PR", ["PR"]],
    ["PT", ["PT"]],
    ["PT4", ["PT"]],
    ["4PU", ["PU"]],
    ["RB", ["RB"]],
    ["RE", ["RE"]],
    ["RH", ["RH"]],
    ["RH3", ["RH"]],
    ["RU", ["RU"]],
    ["SM", ["SM"]],
    ["SB", ["SB"]],
    ["SE", ["SE"]],
    ["SR", ["SR"]],
    ["TA0", ["TA"]],
    ["TB", ["TB"]],
    ["TE", ["TE"]],
    ["TL", ["TL"]],
    ["TH", ["TH"]],
    ["4TI", ["TI"]],
    ["U1", ["U"]],
    ["V", ["V"]],
    ["W", ["W"]],
    ["XE", ["XE"]],
    ["Y1", ["Y"]],
    ["YT3", ["Y"]],
    ["YB", ["YB"]],
    ["YB2", ["YB"]],
    ["ZN", ["ZN"]],
    ["ZR", ["ZR"]],
    ["OH", ["O", "HO"]],
    ["OH3", ["O", "H1", "H2", "H3"]],
    ["NH4", ["N", "HN1", "HN2", "HN3", "HN4"]],
]);

/** Atom names for known ligands */
export const knownLigandAtomNamesMap = new Map([
    // Waters
    ["HOH", ["O", "H1", "H2"]], // PDB waters
    ["DOD", ["O", "D1", "D2"]], // deuterium
    ["H2O", ["O1", "H2", "H3"]], // Schrodinger waters
    ["NH3", ["N", "HN1", "HN2", "HN3"]], // co-crystal ammonia
    ["CH3", ["C", "HC1", "HC2", "HC3", "HC4"]],
    // adenosine.fdb
    ["ADP", ["O1B", "PB", "O2B", "O3B", "O3A", "PA", "O1A", "O2A", "O5'", "C5'", "H5'1", "H5'2", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "HN61", "HN62", "N1", "C2", "H2", "N3", "C4", "C3'", "O3'", "HO3'", "H3'", "C2'", "H2'", "O2'", "HO2'"]],
    ["ATP", ["O1G", "PG", "O2G", "O3G", "O3B", "PB", "O1B", "O2B", "O3A", "PA", "O1A", "O2A", "O5'", "C5'", "H5'1", "H5'2", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "N6", "HN61", "HN62", "N1", "C2", "H2", "N3", "C4", "C3'", "H3'", "O3'", "HO3'", "C2'", "H2'", "O2'", "HO2'"]],
    // guanosine.fdb
    ["GDP", ["O1B", "PB", "O2B", "O3B", "O3A", "PA", "O1A", "O2A", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "HN1", "C2", "N2", "HN21", "HN22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO2'", "O3'", "HO3'"]],
    ["GTP", ["O1G", "PG", "O2G", "O3G", "O3B", "PB", "O1B", "O2B", "O3A", "PA", "O1A", "O2A", "O5'", "C5'", "H5'", "H5''", "C4'", "H4'", "O4'", "C1'", "H1'", "N9", "C8", "H8", "N7", "C5", "C6", "O6", "N1", "HN1", "C2", "N2", "HN21", "HN22", "N3", "C4", "C3'", "H3'", "C2'", "H2'", "O2'", "HO2'", "O3'", "HO3'"]],
    // Heme.fdb
    ["HEM", ["FE", "NA", "C1A", "C2A", "CAA", "HAA1", "HAA2", "CBA", "HBA1", "HBA2", "CGA", "O1A", "O2A", "C3A", "CMA", "HMA1", "HMA2", "HMA3", "C4A", "CHB", "HHB", "C1B", "NB", "C2B", "CMB", "HMB1", "HMB2", "HMB3", "C3B", "CAB", "HAB", "CBB", "HBB1", "HBB2", "C4B", "CHC", "HHC", "C1C", "NC", "C2C", "CMC", "HMC1", "HMC2", "HMC3", "C3C", "CAC", "HAC", "CBC", "HBC1", "HBC2", "C4C", "CHD", "HHD", "C1D", "ND", "C2D", "CMD", "HMD1", "HMD2", "HMD3", "C3D", "C4D", "CHA", "HHA", "CAD", "HAD1", "HAD2", "CBD", "HBD1", "HBD2", "CGD", "O1D", "O2D"]],
    // NAD.fdb
    ["NAD", ["C6N", "H6N", "C5N", "H5N", "C4N", "H4N", "C3N", "C7N", "O7N", "N7N", "H7N1", "H7N2", "C2N", "H2N", "N1N", "C1D", "H1D", "C2D", "H2D", "O2D", "HO2D", "C3D", "H3D", "O3D", "HO3D", "C4D", "H4D", "O4D", "C5D", "H5D1", "H5D2", "O5D", "PN", "O1N", "O2N", "O3", "PA", "O1A", "O2A", "O5B", "C5B", "H5B1", "H5B2", "C4B", "H4B", "O4B", "C3B", "H3B", "O3B", "HO3B", "C2B", "H2B", "O2B", "HO2B", "C1B", "H1B", "N9A", "C8A", "H8A", "N7A", "C5A", "C6A", "N6A", "H6A1", "H6A2", "N1A", "C2A", "H2A", "N3A", "C4A"]],
    ["NDP", ["C6N", "H6N", "C5N", "H5N", "C4N", "H42N", "H41N", "C3N", "C7N", "O7N", "N7N", "H72N", "H71N", "C2N", "H2N", "N1N", "C1D", "H1D", "C2D", "H2D", "O2D", "HO2N", "C3D", "H3D", "O3D", "HO3N", "C4D", "H4D", "O4D", "C5D", "H52N", "H51N", "O5D", "PN", "O1N", "O2N", "O3", "PA", "O1A", "O2A", "O5B", "C5B", "H52A", "H51A", "C4B", "H4B", "O4B", "C3B", "H3B", "O3B", "HO3A", "C2B", "H2B", "O2B", "P2B", "O1X", "O2X", "O3X", "C1B", "H1B", "N9A", "C8A", "H8A", "N7A", "C5A", "C6A", "N6A", "H62A", "H61A", "N1A", "C2A", "H2A", "N3A", "C4A"]],
    ["NP2", ["C6N", "H6N", "C5N", "H5N", "C4N", "H4N", "C3N", "C7N", "O7N", "N7N", "H72N", "H71N", "C2N", "H2N", "N1N", "C1D", "H1D", "C2D", "H2D", "O2D", "HO2N", "C3D", "H3D", "O3D", "HO3N", "C4D", "H4D", "O4D", "C5D", "H52N", "H51N", "O5D", "PN", "O1N", "O2N", "O3", "PA", "O1A", "O2A", "O5B", "C5B", "H52A", "H51A", "C4B", "H4B", "O4B", "C3B", "H3B", "O3B", "HO3A", "C2B", "H2B", "O2B", "P2B", "O1X", "H2X", "O2X", "O3X", "C1B", "H1B", "N9A", "C8A", "H8A", "N7A", "C5A", "C6A", "N6A", "H62A", "H61A", "N1A", "C2A", "H2A", "N3A", "C4A"]],
    ["NP3", ["C6N", "H6N", "C5N", "H5N", "C4N", "H42N", "H41N", "C3N", "C7N", "O7N", "N7N", "H72N", "H71N", "C2N", "H2N", "N1N", "C1D", "H1D", "C2D", "H2D", "O2D", "HO2N", "C3D", "H3D", "O3D", "HO3N", "C4D", "H4D", "O4D", "C5D", "H52N", "H51N", "O5D", "PN", "O1N", "O2N", "O3", "PA", "O1A", "O2A", "O5B", "C5B", "H52A", "H51A", "C4B", "H4B", "O4B", "C3B", "H3B", "O3B", "HO3A", "C2B", "H2B", "O2B", "P2B", "O1X", "HO1", "O2X", "O3X", "C1B", "H1B", "N9A", "C8A", "H8A", "N7A", "C5A", "C6A", "N6A", "H62A", "H61A", "N1A", "C2A", "H2A", "N3A", "C4A"]],
    ["NAP", ["C6N", "H6N", "C5N", "H5N", "C4N", "H4N", "C3N", "C7N", "O7N", "N7N", "H72N", "H71N", "C2N", "H2N", "N1N", "C1D", "H1D", "C2D", "H2D", "O2D", "HO2N", "C3D", "H3D", "O3D", "HO3N", "C4D", "H4D", "O4D", "C5D", "H52N", "H51N", "O5D", "PN", "O1N", "O2N", "O3", "PA", "O1A", "O2A", "O5B", "C5B", "H52A", "H51A", "C4B", "H4B", "O4B", "C3B", "H3B", "O3B", "HO3A", "C2B", "H2B", "O2B", "P2B", "O1X", "O2X", "O3X", "C1B", "H1B", "N9A", "C8A", "H8A", "N7A", "C5A", "C6A", "N6A", "H62A", "H61A", "N1A", "C2A", "H2A", "N3A", "C4A"]],
    // saccharides.fdb
    ["MAN_NP", ["C1", "C2", "C3", "C4", "C5", "C6", "O1", "O2", "O3", "O4", "O5", "O6", "H2", "H3", "H4", "H5", "H11", "H12", "H61", "H62", "HO2", "HO3", "HO4", "HO6"]],
    // FUC and FUL are different conformations of the same sugar L-fucopyranose
    ["FUC_NP", ["C1", "C2", "C3", "C4", "C5", "C6", "O2", "O3", "O4", "O5", "H2", "H3", "H4", "H5", "H11", "H12", "H61", "H62", "H63", "HO2", "HO3", "HO4"]],
    ["FUL_NP", ["C1", "C2", "C3", "C4", "C5", "C6", "O2", "O3", "O4", "O5", "H2", "H3", "H4", "H5", "H11", "H12", "H61", "H62", "H63", "HO2", "HO3", "HO4"]],
]);

/** Amber names for protein residues */
export const residueAmberNamesMap = new Map([
    ["ALA", ["N", "H", "CX", "H1", "CT", "HC", "HC", "HC", "C", "O"]],
    ["LEU", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O"]],
    ["SER", ["N", "H", "CX", "H1", "CT", "H1", "H1", "OH", "HO", "C", "O"]],
    ["GLY", ["N", "H", "CX", "H1", "H1", "C", "O"]],
    ["ASN", ["N", "H", "CX", "H1", "CT", "HC", "HC", "C", "O", "N", "H", "H", "C", "O"]],
    ["THR", ["N", "H", "CX", "H1", "CT", "H1", "CT", "HC", "HC", "HC", "OH", "HO", "C", "O"]],
    ["ASP", ["N", "H", "CX", "H1", "CT", "HC", "HC", "C", "O2", "O2", "C", "O"]],
    ["ARG", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "H1", "H1", "N2", "H", "CA", "N2", "H", "H", "N2", "H", "H", "C", "O"]],
    ["ILE", ["N", "H", "CX", "H1", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O"]],
    ["VAL", ["N", "H", "CX", "H1", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O"]],
    ["GLN", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O", "N", "H", "H", "C", "O"]],
    ["HID", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CC", "NA", "H", "CR", "H5", "NB", "CV", "H4", "C", "O"]],
    ["HIE", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CC", "NB", "CR", "H5", "NA", "H", "CW", "H4", "C", "O"]],
    ["HIP", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CC", "NA", "H", "CR", "H5", "NA", "H", "CW", "H4", "C", "O"]],
    ["MET", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "H1", "H1", "S", "CT", "H1", "H1", "H1", "C", "O"]],
    ["TRP", ["N", "H", "CX", "H1", "CT", "HC", "HC", "C*", "CW", "H4", "NA", "H", "CN", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "CB", "C", "O"]],
    ["PHE", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CA", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "C", "O"]],
    ["TYR", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CA", "CA", "HA", "CA", "HA", "C", "OH", "HO", "CA", "HA", "CA", "HA", "C", "O"]],
    ["GLU", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O2", "O2", "C", "O"]],
    ["LYS", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HP", "HP", "N3", "H", "H", "H", "C", "O"]],
    ["NHC", ["N", "H", "H"]],
    ["LYN", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HP", "HP", "N3", "H", "H", "C", "O"]],
    ["PRO", ["N", "CX", "H1", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "H1", "C", "O"]],
    ["CYS", ["N", "H", "CX", "H1", "CT", "H1", "H1", "SH", "HS", "C", "O"]],
    ["CYX", ["N", "H", "CX", "H1", "CT", "H1", "H1", "S", "C", "O"]],
    ["CYM", ["N", "H", "CX", "H1", "CT", "H1", "H1", "SH", "C", "O"]],
    ["ACE", ["HC", "CT", "HC", "HC", "C", "O"]],
    ["NME", ["N", "H", "CT", "H1", "H1", "H1"]],
    //              ["NHE", [ "N", "H", "H" ]],
    ["NH2", ["N", "H", "H"]],
    ["ASH", ["N", "H", "CX", "H1", "CT", "HC", "HC", "C", "O", "OH", "HO", "C", "O"]],
    ["GLH", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O", "OH", "HO", "C", "O"]],
    ["ALA_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "HC", "C", "O"]],
    ["LEU_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O"]],
    ["SER_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "H1", "H1", "OH", "HO", "C", "O"]],
    ["GLY_NT", ["N3", "H", "H", "H", "CX", "HP", "HP", "C", "O"]],
    ["ASN_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "C", "O", "N", "H", "H", "C", "O"]],
    ["THR_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "H1", "CT", "HC", "HC", "HC", "OH", "HO", "C", "O"]],
    ["ASP_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "C", "O2", "O2", "C", "O"]],
    ["ARG_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "H1", "H1", "N2", "H", "CA", "N2", "H", "H", "N2", "H", "H", "C", "O"]],
    ["ILE_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O"]],
    ["VAL_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O"]],
    ["GLN_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O", "N", "H", "H", "C", "O"]],
    ["HID_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CC", "NA", "H", "CR", "H5", "NB", "CV", "H4", "C", "O"]],
    ["HIE_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CC", "NB", "CR", "H5", "NA", "H", "CW", "H4", "C", "O"]],
    ["HIP_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CC", "NA", "H", "CR", "H5", "NA", "H", "CW", "H4", "C", "O"]],
    ["MET_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CT", "H1", "H1", "S", "CT", "H1", "H1", "H1", "C", "O"]],
    ["MSE_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CT", "H1", "H1", "Se", "CT", "H1", "H1", "H1", "C", "O"]],
    ["TRP_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "C*", "CW", "H4", "NA", "H", "CN", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "CB", "C", "O"]],
    ["PHE_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CA", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "C", "O"]],
    ["TYR_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CA", "CA", "HA", "CA", "HA", "C", "OH", "HO", "CA", "HA", "CA", "HA", "C", "O"]],
    ["GLU_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O2", "O2", "C", "O"]],
    ["LYS_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HP", "HP", "N3", "H", "H", "H", "C", "O"]],
    ["PRO_NT", ["N3", "H", "H", "CX", "HP", "HP", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HP", "C", "O"]],
    ["CYS_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "H1", "H1", "SH", "HS", "C", "O"]],
    ["CYX_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "H1", "H1", "S", "C", "O"]],
    ["CYM_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "H1", "H1", "SH", "C", "O"]],
    ["ASH_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "C", "O2", "O2", "HO", "C", "O"]],
    ["GLH_NT", ["N3", "H", "H", "H", "CX", "HP", "CT", "HC", "HC", "C", "HC", "HC", "C", "O2", "O2", "HO", "C", "O"]],
    ["ALA_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "HC", "C", "O2", "O2"]],
    ["LEU_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O2", "O2"]],
    ["SER_CT", ["N", "H", "CX", "H1", "CT", "H1", "H1", "OH", "HO", "C", "O2", "O2"]],
    ["GLY_CT", ["N", "H", "CX", "H1", "H1", "C", "O2", "O2"]],
    ["ASN_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "C", "O", "N", "H", "H", "C", "O2", "O2"]],
    ["THR_CT", ["N", "H", "CX", "H1", "CT", "H1", "CT", "HC", "HC", "HC", "OH", "HO", "C", "O2", "O2"]],
    ["ASP_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "C", "O2", "O2", "C", "O2", "O2"]],
    ["ASH_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "C", "O2", "OH", "HO", "C", "O2", "O2"]],
    ["ARG_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "H1", "H1", "N2", "H", "CA", "N2", "H", "H", "N2", "H", "H", "C", "O2", "O2"]],
    ["ILE_CT", ["N", "H", "CX", "H1", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O2", "O2"]],
    ["VAL_CT", ["N", "H", "CX", "H1", "CT", "HC", "CT", "HC", "HC", "HC", "CT", "HC", "HC", "HC", "C", "O2", "O2"]],
    ["GLN_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O", "N", "H", "H", "C", "O2", "O2"]],
    ["HID_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CC", "NA", "H", "CR", "H5", "NB", "CV", "H4", "C", "O2", "O2"]],
    ["HIE_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CC", "NB", "CR", "H5", "NA", "H", "CW", "H4", "C", "O2", "O2"]],
    ["HIP_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CC", "NA", "H", "CR", "H5", "NA", "H", "CW", "H4", "C", "O2", "O2"]],
    ["MET_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "H1", "H1", "S", "CT", "H1", "H1", "H1", "C", "O2", "O2"]],
    ["MSE_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "H1", "H1", "Se", "CT", "H1", "H1", "H1", "C", "O2", "O2"]],
    ["TRP_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "C*", "CW", "H4", "NA", "H", "CN", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "CB", "C", "O2", "O2"]],
    ["PHE_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CA", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "CA", "HA", "C", "O2", "O2"]],
    ["TYR_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CA", "CA", "HA", "CA", "HA", "C", "OH", "HO", "CA", "HA", "CA", "HA", "C", "O2", "O2"]],
    ["GLU_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O2", "O2", "C", "O2", "O2"]],
    ["GLH_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O2", "OH", "HO", "C", "O2", "O2"]],
    ["LYS_CT", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "HP", "HP", "N3", "H", "H", "H", "C", "O2", "O2"]],
    ["PRO_CT", ["N", "CX", "H1", "H1", "CT", "HC", "HC", "CT", "HC", "HC", "CT", "H1", "C", "O2", "O2"]],
    ["CYS_CT", ["N", "H", "CX", "H1", "CT", "H1", "H1", "SH", "HS", "C", "O2", "O2"]],
    ["CYX_CT", ["N", "H", "CX", "H1", "CT", "H1", "H1", "S", "C", "O2", "O2"]],
    ["CYM_CT", ["N", "H", "CX", "H1", "CT", "H1", "H1", "SH", "C", "O2", "O2"]],

    ["MSE", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CT", "H1", "H1", "Se", "CT", "H1", "H1", "H1", "C", "O"]],
    ["PTN", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CA", "CA", "HA", "CA", "HA", "C", "OH", "CA", "HA", "CA", "HA", "C", "O", "P", "O", "O", "O", "O", "O"]],
    ["PTR", ["N", "H", "CX", "H1", "CT", "HC", "HC", "CA", "CA", "HA", "CA", "HA", "CA", "OS", "CA", "HA", "CA", "HA", "C", "O", "P", "O2", "O2", "O2"]],
    ["HYP", ["N", "CT", "H1", "H1", "CT", "HC", "CT", "HC", "HC", "CX", "H1", "C", "O", "O", "H"]],
    ["SEP", ["N", "H", "CX", "H1", "CT", "H1", "H1", "OH", "P", "C", "O", "O", "O", "H", "O", "H"]],
    ["ALS", ["N", "H", "CX", "H1", "CT", "H1", "OH", "HO", "O2", "S", "O2", "O2", "O2", "C", "O"]],

    ["DA", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB", "CT", "H1", "CT", "HC", "HC", "OS"]],
    ["AN", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB", "CT", "H1", "CT", "H1", "OH", "HO", "OH", "HO"]],
    ["U", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "C", "O", "NA", "H", "C", "O", "CT", "H1", "CT", "H1", "OH", "HO", "OS"]],
    ["DAN", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB", "CT", "H1", "CT", "HC", "HC", "OH", "HO"]],
    ["DG", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "C", "O", "NA", "H", "CA", "N2", "H", "H", "NC", "CB", "CT", "H1", "CT", "HC", "HC", "OS"]],
    ["A", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB", "CT", "H1", "CT", "H1", "OH", "HO", "OS"]],
    ["DT", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "CT", "HC", "HC", "HC", "C", "O", "NA", "H", "C", "O", "CT", "H1", "CT", "HC", "HC", "OS"]],
    ["DTN", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "CT", "HC", "HC", "HC", "C", "O", "NA", "H", "C", "O", "CT", "H1", "CT", "HC", "HC", "OH", "HO"]],
    ["UN", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "C", "O", "NA", "H", "C", "O", "CT", "H1", "CT", "H1", "OH", "HO", "OH", "HO"]],
    ["G", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "C", "O", "NA", "H", "CA", "N2", "H", "H", "NC", "CB", "CT", "H1", "CT", "H1", "OH", "HO", "OS"]],
    ["DGN", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "C", "O", "NA", "H", "CA", "N2", "H", "H", "NC", "CB", "CT", "H1", "CT", "HC", "HC", "OH", "HO"]],
    ["GN", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "C", "O", "NA", "H", "CA", "N2", "H", "H", "NC", "CB", "CT", "H1", "CT", "H1", "OH", "HO", "OH", "HO"]],
    ["DC", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "CA", "N2", "H", "H", "NC", "C", "O", "CT", "H1", "CT", "HC", "HC", "OS"]],
    ["DCN", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "CA", "N2", "H", "H", "NC", "C", "O", "CT", "H1", "CT", "HC", "HC", "OH", "HO"]],
    ["C", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "CA", "N2", "H", "H", "NC", "C", "O", "CT", "H1", "CT", "H1", "OH", "HO", "OS"]],
    ["CN", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "CA", "N2", "H", "H", "NC", "C", "O", "CT", "H1", "CT", "H1", "OH", "HO", "OH", "HO"]],
    ["U3", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "C", "O", "NA", "H", "C", "O", "CT", "H1", "CT", "H1", "OH", "HO", "OH", "HO"]],
    ["DA5", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB", "CT", "H1", "CT", "HC", "HC", "OS"]],
    ["U5", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "C", "O", "NA", "H", "C", "O", "CT", "H1", "CT", "H1", "OH", "HO", "OS"]],
    ["DA3", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB", "CT", "H1", "CT", "HC", "HC", "OH", "HO"]],
    ["DG5", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "C", "O", "NA", "H", "CA", "N2", "H", "H", "NC", "CB", "CT", "H1", "CT", "HC", "HC", "OS"]],
    ["A5", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB", "CT", "H1", "CT", "H1", "OH", "HO", "OS"]],
    ["A3", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB", "CT", "H1", "CT", "H1", "OH", "HO", "OH", "HO"]],
    ["DT5", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "CT", "HC", "HC", "HC", "C", "O", "NA", "H", "C", "O", "CT", "H1", "CT", "HC", "HC", "OS"]],
    ["DT3", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "CT", "HC", "HC", "HC", "C", "O", "NA", "H", "C", "O", "CT", "H1", "CT", "HC", "HC", "OH", "HO"]],
    ["DG3", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "C", "O", "NA", "H", "CA", "N2", "H", "H", "NC", "CB", "CT", "H1", "CT", "HC", "HC", "OH", "HO"]],
    ["DC3", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "CA", "N2", "H", "H", "NC", "C", "O", "CT", "H1", "CT", "HC", "HC", "OH", "HO"]],
    ["G5", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "C", "O", "NA", "H", "CA", "N2", "H", "H", "NC", "CB", "CT", "H1", "CT", "H1", "OH", "HO", "OS"]],
    ["DC5", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "CA", "N2", "H", "H", "NC", "C", "O", "CT", "H1", "CT", "HC", "HC", "OS"]],
    ["G3", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "C", "O", "NA", "H", "CA", "N2", "H", "H", "NC", "CB", "CT", "H1", "CT", "H1", "OH", "HO", "OH", "HO"]],
    ["C5", ["HO", "OH", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "CA", "N2", "H", "H", "NC", "C", "O", "CT", "H1", "CT", "H1", "OH", "HO", "OS"]],
    ["C3", ["P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H2", "N*", "CM", "H4", "CM", "HA", "CA", "N2", "H", "H", "NC", "C", "O", "CT", "H1", "CT", "H1", "OH", "HO", "OH", "HO"]],
    // saccharides.fdb
    ["NAG_OT", ["c3", "c3", "c3", "c3", "c3", "c3", "c", "c3", "n", "oh", "oh", "os", "oh", "o", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "hc", "hc", "hc", "hn", "ho", "ho"]],
    ["NAG_CT", ["c3", "c3", "c3", "c3", "c3", "c3", "c", "c3", "n", "oh", "oh", "os", "oh", "o", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "hc", "hc", "hc", "hn", "ho", "ho", "ho"]],
    ["NAG", ["c3", "c3", "c3", "c3", "c3", "c3", "c", "c3", "n", "oh", "oh", "os", "oh", "o", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "hc", "hc", "hc", "hn", "ho", "ho"]],
    ["BMA_CT", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "os", "oh", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "ho", "ho", "ho", "ho"]],
    ["BMA", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "os", "oh", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "ho", "ho", "ho"]],
    ["MAN", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "os", "oh", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "ho", "ho", "ho"]],
    ["MAN_CT", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "os", "oh", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "ho", "ho", "ho", "ho"]],
    // FUC and FUL are different conformations of the same sugar L-fucopyranose
    ["FUC", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "oh", "os", "h2", "h1", "h1", "h1", "h1", "hc", "hc", "hc", "ho", "ho", "ho", "ho"]],
    ["FUL", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "oh", "os", "h2", "h1", "h1", "h1", "h1", "hc", "hc", "hc", "ho", "ho", "ho", "ho"]],
]);

/** Amber names for ions. Derived from ions.fdb */
export const ionAmberNamesMap = new Map([
    ["AG", ["Ag"]],
    ["AL", ["Al"]],
    ["AM", ["Am"]],
    ["AR", ["Ar"]],
    ["ARS", ["As"]],
    ["AU", ["Au"]],
    ["AU3", ["Au"]],
    ["BA", ["Ba"]],
    ["0BE", ["Be"]],
    ["BR", ["br"]],
    ["BS3", ["Bi"]],
    ["CA", ["Ca"]],
    ["CD", ["Cd"]],
    ["CE", ["Ce"]],
    ["CF", ["Cf"]],
    ["CL", ["IM"]],
    ["CIM", ["IM"]],
    ["ZCM", ["Cm"]],
    ["CO", ["Co"]],
    ["3CO", ["Co"]],
    ["CR", ["Cr"]],
    ["CS", ["Cs"]],
    ["CU", ["Cu"]],
    ["CU1", ["Cu"]],
    ["CU3", ["Cu"]],
    ["D8U", ["D"]],
    ["DY", ["Dy"]],
    ["ER3", ["Er"]],
    ["EU", ["Eu"]],
    ["EU3", ["Eu"]],
    ["F", ["f"]],
    ["FE", ["Fe"]],
    ["FE2", ["Fe"]],
    ["GA", ["Ga"]],
    ["GD", ["Gd"]],
    ["GD3", ["Gd"]],
    ["H", ["H"]],
    ["HG", ["Hg"]],
    ["HO", ["Ho"]],
    ["HO3", ["Ho"]],
    ["IN", ["In"]],
    ["IOD", ["i"]],
    ["IR", ["Ir"]],
    ["IR3", ["Ir"]],
    ["K", ["K"]],
    ["KR", ["Kr"]],
    ["LA", ["La"]],
    ["LI", ["Li"]],
    ["LU", ["Lu"]],
    ["MG", ["Mg"]],
    ["MGM", ["G0", "Gx", "Gx", "Gy", "Gy", "Gz", "Gz"]],
    ["MN", ["Mn"]],
    ["MN3", ["Mn"]],
    ["MO", ["Mo"]],
    ["4MO", ["Mo"]],
    ["6MO", ["Mo"]],
    ["NA", ["IP"]],
    ["CIP", ["IP"]],
    ["NI", ["Ni"]],
    ["3NI", ["Ni"]],
    ["OS", ["Os"]],
    ["OS4", ["Os"]],
    ["PB", ["Pb"]],
    ["PD", ["Pd"]],
    ["PR", ["Pr"]],
    ["PT", ["Pt"]],
    ["PT4", ["Pt"]],
    ["4PU", ["Pu"]],
    ["RB", ["Rb"]],
    ["RE", ["Re"]],
    ["RH", ["Rh"]],
    ["RH3", ["Rh"]],
    ["RU", ["Ru"]],
    ["SM", ["Sm"]],
    ["SB", ["Sb"]],
    ["SE", ["Se"]],
    ["SR", ["Sr"]],
    ["TA0", ["Ta"]],
    ["TB", ["Tb"]],
    ["TE", ["Te"]],
    ["TL", ["Tl"]],
    ["TH", ["Th"]],
    ["4TI", ["Ti"]],
    ["U1", ["U"]],
    ["V", ["V"]],
    ["W", ["W"]],
    ["XE", ["Xe"]],
    ["Y1", ["Y"]],
    ["YT3", ["Y"]],
    ["YB", ["Yb"]],
    ["YB2", ["Yb"]],
    ["ZN", ["Zn"]],
    ["ZNM", ["Z0", "Zc", "Zc", "Zc", "Zc"]],
    ["ZR", ["Zr"]],
    ["OH", ["o", "ho"]],
    ["OH3", ["ow", "hw", "hw", "hw"]],
    ["NH4", ["n4", "hn", "hn", "hn", "hn"]],
]);

/** Amber names for known ligands */
export const knownLigandAmberNamesMap = new Map([
    // Waters
    ["HOH", ["ow", "hw", "hw"]], // PDB, TIP3P
    ["DOD", ["ow", "hw", "hw"]], // deuterium
    ["H2O", ["ow", "hw", "hw"]], // Schrodinger
    ["NH3", ["n3", "hn", "hn", "hn"]], // co-crystal ammonia
    ["CH3", ["c3", "hc", "hc", "hc", "hc"]],
    // adenosine.fdb
    ["ADP", ["o", "p5", "o", "o", "os", "p5", "o", "o", "os", "c3", "h1", "h1", "c3", "h1", "os", "c3", "h2", "na", "cc", "h5", "nc", "ca", "ca", "nh", "hn", "hn", "nb", "ca", "h5", "nb", "ca", "c3", "oh", "ho", "h1", "c3", "h1", "oh", "ho"]],
    ["ATP", ["o", "p5", "o", "o", "os", "p5", "o", "o", "os", "p5", "o", "o", "os", "c3", "h1", "h1", "CT", "H1", "os", "c3", "h2", "na", "cc", "h5", "nc", "ca", "ca", "nh", "hn", "hn", "nb", "ca", "h5", "nb", "ca", "c3", "h1", "oh", "ho", "c3", "h1", "oh", "ho"]],
    // guanosine.fdb
    ["GDP", ["o", "p5", "o", "o", "os", "p5", "o", "o", "OS", "c3", "h1", "h1", "c3", "h1", "os", "c3", "h2", "na", "cc", "h5", "nc", "cc", "c", "o", "n", "hn", "c2", "na", "hn", "hn", "ne", "cc", "c3", "h1", "c3", "h1", "oh", "ho", "oh", "ho"]],
    ["GTP", ["o", "p5", "o", "o", "os", "p5", "o", "o", "os", "p5", "o", "o", "os", "c3", "h1", "h1", "c3", "h1", "os", "c3", "h2", "na", "cc", "h5", "nc", "cc", "c", "o", "n", "hn", "c2", "na", "hn", "hn", "ne", "cc", "c3", "h1", "c3", "h1", "oh", "ho", "oh", "ho"]],
    // Heme.fdb
    ["HEM", ["FE", "NP", "CC", "CB", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O2", "O2", "CB", "CT", "HC", "HC", "HC", "CC", "CD", "HC", "CC", "no", "CB", "CT", "HC", "HC", "HC", "CB", "CY", "HC", "cx", "HC", "HC", "CC", "CD", "HC", "CC", "NP", "CB", "CT", "HC", "HC", "HC", "CB", "CY", "HC", "cx", "HC", "HC", "CC", "CD", "HC", "CC", "no", "CB", "CT", "HC", "HC", "HC", "CB", "CC", "CD", "HC", "CT", "HC", "HC", "CT", "HC", "HC", "C", "O2", "O2"]],
    // NAD.fdb
    ["NAD", ["CA", "H4", "CA", "HA", "CA", "HA", "CA", "C", "O", "N", "H", "H", "CA", "H4", "N*", "CT", "H2", "CT", "H1", "OH", "HO", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "CT", "H1", "H1", "OS", "P", "O2", "O2", "OS", "P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H1", "OH", "HO", "CT", "H1", "OH", "HO", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB"]],
    ["NDP", ["CA", "H4", "CA", "HA", "CT", "HC", "HC", "CA", "C", "O", "N", "H", "H", "CA", "H4", "N*", "CT", "H2", "CT", "H1", "OH", "HO", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "CT", "H1", "H1", "OS", "P", "O2", "O2", "OS", "P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "P", "O2", "O2", "O2", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB"]],
    ["NP2", ["CA", "H4", "CA", "HA", "CA", "HA", "CA", "C", "O", "N", "H", "H", "CA", "H4", "N*", "CT", "H2", "CT", "H1", "OH", "HO", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "CT", "H1", "H1", "OS", "P", "O2", "O2", "OS", "P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "P", "OH", "HO", "O2", "O2", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB"]],
    ["NP3", ["CA", "H4", "CA", "HA", "CT", "HC", "HC", "CA", "C", "O", "N", "H", "H", "CA", "H4", "N*", "CT", "H2", "CT", "H1", "OH", "HO", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "CT", "H1", "H1", "OS", "P", "O2", "O2", "OS", "P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "P", "OH", "HO", "O2", "O2", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB"]],
    ["NAP", ["CA", "H4", "CA", "HA", "CA", "HA", "CA", "C", "O", "N", "H", "H", "CA", "H4", "N*", "CT", "H2", "CT", "H1", "OH", "HO", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "CT", "H1", "H1", "OS", "P", "O2", "O2", "OS", "P", "O2", "O2", "OS", "CT", "H1", "H1", "CT", "H1", "OS", "CT", "H1", "OH", "HO", "CT", "H1", "OS", "P", "O2", "O2", "O2", "CT", "H2", "N*", "CK", "H5", "NB", "CB", "CA", "N2", "H", "H", "NC", "CQ", "H5", "NC", "CB"]],
    // saccharides.fdb
    ["MAN_NP", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "os", "oh", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "h1", "ho", "ho", "ho", "ho"]],
    // FUC and FUL are different conformations of the same sugar L-fucopyranose
    ["FUC_NP", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "os", "h1", "h1", "h1", "h1", "h1", "h1", "hc", "hc", "hc", "ho", "ho", "ho"]],
    ["FUL_NP", ["c3", "c3", "c3", "c3", "c3", "c3", "oh", "oh", "oh", "os", "h1", "h1", "h1", "h1", "h1", "h1", "hc", "hc", "hc", "ho", "ho", "ho"]],
]);

/** Charges for protein residues */
export const residueChargesMap = new Map([
    ["ALA", [-0.41570, 0.27190, 0.03370, 0.08230, -0.18250, 0.06030, 0.06030, 0.06030, 0.59730, -0.56790]],
    ["GLY", [-0.41570, 0.27190, -0.02520, 0.06980, 0.06980, 0.59730, -0.56790]],
    ["SER", [-0.41570, 0.27190, -0.02490, 0.08430, 0.21170, 0.03520, 0.03520, -0.65460, 0.42750, 0.59730, -0.56790]],
    ["THR", [-0.41570, 0.27190, -0.03890, 0.10070, 0.36540, 0.00430, -0.24380, 0.06420, 0.06420, 0.06420, -0.67610, 0.41020, 0.59730, -0.56790]],
    ["LEU", [-0.41570, 0.27190, -0.05180, 0.09220, -0.11020, 0.04570, 0.04570, 0.35310, -0.03610, -0.41210, 0.10000, 0.10000, 0.10000, -0.41210, 0.10000, 0.10000, 0.10000, 0.59730, -0.56790]],
    ["ILE", [-0.41570, 0.27190, -0.05970, 0.08690, 0.13030, 0.01870, -0.32040, 0.08820, 0.08820, 0.08820, -0.04300, 0.02360, 0.02360, -0.06600, 0.01860, 0.01860, 0.01860, 0.59730, -0.56790]],
    ["VAL", [-0.41570, 0.27190, -0.08750, 0.09690, 0.29850, -0.02970, -0.31920, 0.07910, 0.07910, 0.07910, -0.31920, 0.07910, 0.07910, 0.07910, 0.59730, -0.56790]],
    ["ASN", [-0.41570, 0.27190, 0.01430, 0.10480, -0.20410, 0.07970, 0.07970, 0.71300, -0.59310, -0.91910, 0.41960, 0.41960, 0.59730, -0.56790]],
    ["GLN", [-0.41570, 0.27190, -0.00310, 0.08500, -0.00360, 0.01710, 0.01710, -0.06450, 0.03520, 0.03520, 0.69510, -0.60860, -0.94070, 0.42510, 0.42510, 0.59730, -0.56790]],
    ["ARG", [-0.34790, 0.27470, -0.26370, 0.15600, -0.00070, 0.03270, 0.03270, 0.03900, 0.02850, 0.02850, 0.04860, 0.06870, 0.06870, -0.52950, 0.34560, 0.80760, -0.86270, 0.44780, 0.44780, -0.86270, 0.44780, 0.44780, 0.73410, -0.58940]],
    ["HID", [-0.41570, 0.27190, 0.01880, 0.08810, -0.04620, 0.04020, 0.04020, -0.02660, -0.38110, 0.36490, 0.20570, 0.13920, -0.57270, 0.12920, 0.11470, 0.59730, -0.56790]],
    ["HIE", [-0.41570, 0.27190, -0.05810, 0.13600, -0.00740, 0.03670, 0.03670, 0.18680, -0.54320, 0.16350, 0.14350, -0.27950, 0.33390, -0.22070, 0.18620, 0.59730, -0.56790]],
    ["HIP", [-0.34790, 0.27470, -0.13540, 0.12120, -0.04140, 0.08100, 0.08100, -0.00120, -0.15130, 0.38660, -0.01700, 0.26810, -0.17180, 0.39110, -0.11410, 0.23170, 0.73410, -0.58940]],
    ["TRP", [-0.41570, 0.27190, -0.02750, 0.11230, -0.00500, 0.03390, 0.03390, -0.14150, -0.16380, 0.20620, -0.34180, 0.34120, 0.13800, -0.26010, 0.15720, -0.11340, 0.14170, -0.19720, 0.14470, -0.23870, 0.17000, 0.12430, 0.59730, -0.56790]],
    ["PHE", [-0.41570, 0.27190, -0.00240, 0.09780, -0.03430, 0.02950, 0.02950, 0.01180, -0.12560, 0.13300, -0.17040, 0.14300, -0.10720, 0.12970, -0.17040, 0.14300, -0.12560, 0.13300, 0.59730, -0.56790]],
    ["TYR", [-0.41570, 0.27190, -0.00140, 0.08760, -0.01520, 0.02950, 0.02950, -0.00110, -0.19060, 0.16990, -0.23410, 0.16560, 0.32260, -0.55790, 0.39920, -0.23410, 0.16560, -0.19060, 0.16990, 0.59730, -0.56790]],
    ["GLU", [-0.51630, 0.29360, 0.03970, 0.11050, 0.05600, -0.01730, -0.01730, 0.01360, -0.04250, -0.04250, 0.80540, -0.81880, -0.81880, 0.53660, -0.58190]],
    ["ASP", [-0.51630, 0.29360, 0.03810, 0.08800, -0.03030, -0.01220, -0.01220, 0.79940, -0.80140, -0.80140, 0.53660, -0.58190]],
    ["LYS", [-0.34790, 0.27470, -0.24000, 0.14260, -0.00940, 0.03620, 0.03620, 0.01870, 0.01030, 0.01030, -0.04790, 0.06210, 0.06210, -0.01430, 0.11350, 0.11350, -0.38540, 0.34000, 0.34000, 0.34000, 0.73410, -0.58940]],
    ["LYN", [-0.41570, 0.27190, -0.07206, 0.09940, -0.04845, 0.03400, 0.03400, 0.06612, 0.01041, 0.01041, -0.03768, 0.01155, 0.01155, 0.32604, -0.03358, -0.03358, -1.03581, 0.38604, 0.38604, 0.59730, -0.56790]],
    ["PRO", [-0.25480, 0.01920, 0.03910, 0.03910, 0.01890, 0.02130, 0.02130, -0.00700, 0.02530, 0.02530, -0.02660, 0.06410, 0.58960, -0.57480]],
    ["CYS", [-0.41570, 0.27190, 0.02130, 0.11240, -0.12310, 0.11120, 0.11120, -0.31190, 0.19330, 0.59730, -0.56790]],
    ["CYX", [-0.41570, 0.27190, 0.04290, 0.07660, -0.07900, 0.09100, 0.09100, -0.10810, 0.59730, -0.56790]],
    ["CYM", [-0.46300, 0.25200, 0.03500, 0.04800, -0.73600, 0.24400, 0.24400, -0.73600, 0.61600, -0.50400]],
    ["MET", [-0.41570, 0.27190, -0.02370, 0.08800, 0.03420, 0.02410, 0.02410, 0.00180, 0.04400, 0.04400, -0.27370, -0.05360, 0.06840, 0.06840, 0.06840, 0.59730, -0.56790]],
    ["ACE", [0.11230, -0.33690, 0.11230, 0.11230, 0.56790, -0.56790]],
    ["NME", [-0.41570, 0.27190, -0.14900, 0.09760, 0.09760, 0.09760]],
    //              ["NHE", [0.14140, 0.19970, 0.19970]],
    ["NH2", [-0.62000, 0.32000, 0.30000]],
    ["ASH", [-0.41570, 0.27190, 0.03410, 0.08640, -0.03160, 0.04880, 0.04880, 0.64620, -0.55540, -0.63760, 0.47470, 0.59730, -0.56790]],
    ["GLH", [-0.41570, 0.27190, 0.01450, 0.07790, -0.00710, 0.02560, 0.02560, -0.01740, 0.04300, 0.04300, 0.68010, -0.58380, -0.65110, 0.46410, 0.59730, -0.56790]],
    ["ALA_CT", [-0.38210, 0.26810, -0.17470, 0.10670, -0.20930, 0.07640, 0.07640, 0.07640, 0.77310, -0.80550, -0.80550]],
    ["GLY_CT", [-0.38210, 0.26810, -0.24930, 0.10560, 0.10560, 0.72310, -0.78550, -0.78550]],
    ["SER_CT", [-0.38210, 0.26810, -0.27220, 0.13040, 0.11230, 0.08130, 0.08130, -0.65140, 0.44740, 0.81130, -0.81320, -0.81320]],
    ["THR_CT", [-0.38210, 0.26810, -0.24200, 0.12070, 0.30250, 0.00780, -0.18530, 0.05860, 0.05860, 0.05860, -0.64960, 0.41190, 0.78100, -0.80440, -0.80440]],
    ["LEU_CT", [-0.38210, 0.26810, -0.28470, 0.13460, -0.24690, 0.09740, 0.09740, 0.37060, -0.03740, -0.41630, 0.10380, 0.10380, 0.10380, -0.41630, 0.10380, 0.10380, 0.10380, 0.83260, -0.81990, -0.81990]],
    ["ILE_CT", [-0.38210, 0.26810, -0.31000, 0.13750, 0.03630, 0.07660, -0.34980, 0.10210, 0.10210, 0.10210, -0.03230, 0.03210, 0.03210, -0.06990, 0.01960, 0.01960, 0.01960, 0.83430, -0.81900, -0.81900]],
    ["VAL_CT", [-0.38210, 0.26810, -0.34380, 0.14380, 0.19400, 0.03080, -0.30640, 0.08360, 0.08360, 0.08360, -0.30640, 0.08360, 0.08360, 0.08360, 0.83500, -0.81730, -0.81730]],
    ["ASN_CT", [-0.38210, 0.26810, -0.20800, 0.13580, -0.22990, 0.10230, 0.10230, 0.71530, -0.60100, -0.90840, 0.41500, 0.41500, 0.80500, -0.81470, -0.81470]],
    ["GLN_CT", [-0.38210, 0.26810, -0.22480, 0.12320, -0.06640, 0.04520, 0.04520, -0.02100, 0.02030, 0.02030, 0.70930, -0.60980, -0.95740, 0.43040, 0.43040, 0.77750, -0.80420, -0.80420]],
    ["ARG_CT", [-0.34810, 0.27640, -0.30680, 0.14470, -0.03740, 0.03710, 0.03710, 0.07440, 0.01850, 0.01850, 0.11140, 0.04680, 0.04680, -0.55640, 0.34790, 0.83680, -0.87370, 0.44930, 0.44930, -0.87370, 0.44930, 0.44930, 0.85570, -0.82660, -0.82660]],
    ["HID_CT", [-0.38210, 0.26810, -0.17390, 0.11000, -0.10460, 0.05650, 0.05650, 0.02930, -0.38920, 0.37550, 0.19250, 0.14180, -0.56290, 0.10010, 0.12410, 0.76150, -0.80160, -0.80160]],
    ["HIE_CT", [-0.38210, 0.26810, -0.26990, 0.16500, -0.10680, 0.06200, 0.06200, 0.27240, -0.55170, 0.15580, 0.14480, -0.26700, 0.33190, -0.25880, 0.19570, 0.79160, -0.80650, -0.80650]],
    ["HIP_CT", [-0.34810, 0.27640, -0.14450, 0.11150, -0.08000, 0.08680, 0.08680, 0.02980, -0.15010, 0.38830, -0.02510, 0.26940, -0.16830, 0.39130, -0.12560, 0.23360, 0.80320, -0.81770, -0.81770]],
    ["TRP_CT", [-0.38210, 0.26810, -0.20840, 0.12720, -0.07420, 0.04970, 0.04970, -0.07960, -0.18080, 0.20430, -0.33160, 0.34130, 0.12220, -0.25940, 0.15670, -0.10200, 0.14010, -0.22870, 0.15070, -0.18370, 0.14910, 0.10780, 0.76580, -0.80110, -0.80110]],
    ["PHE_CT", [-0.38210, 0.26810, -0.18250, 0.10980, -0.09590, 0.04430, 0.04430, 0.05520, -0.13000, 0.14080, -0.18470, 0.14610, -0.09440, 0.12800, -0.18470, 0.14610, -0.13000, 0.14080, 0.76600, -0.80260, -0.80260]],
    ["TYR_CT", [-0.38210, 0.26810, -0.20150, 0.10920, -0.07520, 0.04900, 0.04900, 0.02430, -0.19220, 0.17800, -0.24580, 0.16730, 0.33950, -0.56430, 0.40170, -0.24580, 0.16730, -0.19220, 0.17800, 0.78170, -0.80700, -0.80700]],
    ["GLU_CT", [-0.51920, 0.30550, -0.20590, 0.13990, 0.00710, -0.00780, -0.00780, 0.06750, -0.05480, -0.05480, 0.81830, -0.82200, -0.82200, 0.74200, -0.79300, -0.79300]],
    ["GLH_CT", [-0.415700, 0.271900, 0.014500, 0.077900, -0.007100, 0.025600, 0.025600, -0.017400, 0.043000, 0.043000, 0.680100, -0.583800, -0.651100, 0.464100, 0.390300, -0.567900, -0.793000]],
    ["ASP_CT", [-0.51920, 0.30550, -0.18170, 0.10460, -0.06770, -0.02120, -0.02120, 0.88510, -0.81620, -0.81620, 0.72560, -0.78870, -0.78870]],
    ["ASH_CT", [-0.415700, 0.271900, 0.034100, 0.086400, -0.031600, 0.048800, 0.048800, 0.646200, -0.555400, -0.637600, 0.474700, 0.606800, -0.788700, -0.788700]],
    ["LYS_CT", [-0.34810, 0.27640, -0.29030, 0.14380, -0.05380, 0.04820, 0.04820, 0.02270, 0.01340, 0.01340, -0.03920, 0.06110, 0.06110, -0.01760, 0.11210, 0.11210, -0.37410, 0.33740, 0.33740, 0.33740, 0.84880, -0.82520, -0.82520]],
    ["PRO_CT", [-0.28020, 0.04340, 0.03310, 0.03310, 0.04660, 0.01720, 0.01720, -0.05430, 0.03810, 0.03810, -0.13360, 0.07760, 0.66310, -0.76970, -0.76970]],
    ["CYS_CT", [-0.38210, 0.26810, -0.16350, 0.13960, -0.19960, 0.14370, 0.14370, -0.31020, 0.20680, 0.74970, -0.79810, -0.79810]],
    ["CYX_CT", [-0.38210, 0.26810, -0.13180, 0.09380, -0.19430, 0.12280, 0.12280, -0.05290, 0.76180, -0.80410, -0.80410]],
    ["CYM_CT", [-0.801000, 0.419800, 0.132500, 0.016700, 0.139900, -0.017800, -0.017800, -1.042300, 0.885600, -0.857780, -0.857780]],
    ["MET_CT", [-0.38210, 0.26810, -0.25970, 0.12770, -0.02360, 0.04800, 0.04800, 0.04920, 0.03170, 0.03170, -0.26920, -0.03760, 0.06250, 0.06250, 0.06250, 0.80130, -0.81050, -0.81050]],
    ["MSE_CT", [-0.38210, 0.26810, -0.25970, 0.12770, -0.02360, 0.04800, 0.04800, 0.04920, 0.03170, 0.03170, -0.26920, -0.03760, 0.06250, 0.06250, 0.06250, 0.80130, -0.81050, -0.81050]],
    ["ALA_NT", [0.14140, 0.19970, 0.19970, 0.19970, 0.09620, 0.08890, -0.05970, 0.03000, 0.03000, 0.03000, 0.61630, -0.57220]],
    ["GLY_NT", [0.29430, 0.16420, 0.16420, 0.16420, -0.01000, 0.08950, 0.08950, 0.61630, -0.57220]],
    ["SER_NT", [0.18490, 0.18980, 0.18980, 0.18980, 0.05670, 0.07820, 0.25960, 0.02730, 0.02730, -0.67140, 0.42390, 0.61630, -0.57220]],
    ["THR_NT", [0.18120, 0.19340, 0.19340, 0.19340, 0.00340, 0.10870, 0.45140, -0.03230, -0.25540, 0.06270, 0.06270, 0.06270, -0.67640, 0.40700, 0.61630, -0.57220]],
    ["LEU_NT", [0.10100, 0.21480, 0.21480, 0.21480, 0.01040, 0.10530, -0.02440, 0.02560, 0.02560, 0.34210, -0.03800, -0.41060, 0.09800, 0.09800, 0.09800, -0.41040, 0.09800, 0.09800, 0.09800, 0.61230, -0.57130]],
    ["ILE_NT", [0.03110, 0.23290, 0.23290, 0.23290, 0.02570, 0.10310, 0.18850, 0.02130, -0.37200, 0.09470, 0.09470, 0.09470, -0.03870, 0.02010, 0.02010, -0.09080, 0.02260, 0.02260, 0.02260, 0.61230, -0.57130]],
    ["VAL_NT", [0.05770, 0.22720, 0.22720, 0.22720, -0.00540, 0.10930, 0.31960, -0.02210, -0.31290, 0.07350, 0.07350, 0.07350, -0.31290, 0.07350, 0.07350, 0.07350, 0.61630, -0.57220]],
    ["ASN_NT", [0.18010, 0.19210, 0.19210, 0.19210, 0.03680, 0.12310, -0.02830, 0.05150, 0.05150, 0.58330, -0.57440, -0.86340, 0.40970, 0.40970, 0.61630, -0.57220]],
    ["ASH_NT", [0.07820, 0.22000, 0.22000, 0.22000, 0.02920, 0.11410, -0.02350, -0.01690, -0.01690, 0.81940, -0.80840, -0.80840, 0.47470, 0.56210, -0.58890]],
    ["GLN_NT", [0.14930, 0.19960, 0.19960, 0.19960, 0.05360, 0.10150, 0.06510, 0.00500, 0.00500, -0.09030, 0.03310, 0.03310, 0.73540, -0.61330, -1.00310, 0.44290, 0.44290, 0.61230, -0.57130]],
    ["GLH_NT", [0.00170, 0.23910, 0.23910, 0.23910, 0.05880, 0.12020, 0.09090, -0.02320, -0.02320, -0.02360, -0.03150, -0.03150, 0.80870, -0.81890, -0.81890, 0.46410, 0.56210, -0.58890]],
    ["ARG_NT", [0.13050, 0.20830, 0.20830, 0.20830, -0.02230, 0.12420, 0.01180, 0.02260, 0.02260, 0.02360, 0.03090, 0.03090, 0.09350, 0.05270, 0.05270, -0.56500, 0.35920, 0.82810, -0.86930, 0.44940, 0.44940, -0.86930, 0.44940, 0.44940, 0.72140, -0.60130]],
    ["HID_NT", [0.15420, 0.19630, 0.19630, 0.19630, 0.09640, 0.09580, 0.02590, 0.02090, 0.02090, -0.03990, -0.38190, 0.36320, 0.21270, 0.13850, -0.57110, 0.10460, 0.12990, 0.61230, -0.57130]],
    ["HIE_NT", [0.14720, 0.20160, 0.20160, 0.20160, 0.02360, 0.13800, 0.04890, 0.02230, 0.02230, 0.17400, -0.55790, 0.18040, 0.13970, -0.27810, 0.33240, -0.23490, 0.19630, 0.61230, -0.57130]],
    ["HIP_NT", [0.25600, 0.17040, 0.17040, 0.17040, 0.05810, 0.10470, 0.04840, 0.05310, 0.05310, -0.02360, -0.15100, 0.38210, -0.00110, 0.26450, -0.17390, 0.39210, -0.14330, 0.24950, 0.72140, -0.60130]],
    ["TRP_NT", [0.19130, 0.18880, 0.18880, 0.18880, 0.04210, 0.11620, 0.05430, 0.02220, 0.02220, -0.16540, -0.17880, 0.21950, -0.34440, 0.34120, 0.15750, -0.27100, 0.15890, -0.10800, 0.14110, -0.20340, 0.14580, -0.22650, 0.16460, 0.11320, 0.61230, -0.57130]],
    ["PHE_NT", [0.17370, 0.19210, 0.19210, 0.19210, 0.07330, 0.10410, 0.03300, 0.01040, 0.01040, 0.00310, -0.13920, 0.13740, -0.16020, 0.14330, -0.12080, 0.13290, -0.16030, 0.14330, -0.13910, 0.13740, 0.61230, -0.57130]],
    ["TYR_NT", [0.19400, 0.18730, 0.18730, 0.18730, 0.05700, 0.09830, 0.06590, 0.01020, 0.01020, -0.02050, -0.20020, 0.17200, -0.22390, 0.16500, 0.31390, -0.55780, 0.40010, -0.22390, 0.16500, -0.20020, 0.17200, 0.61230, -0.57130]],
    ["GLU_NT", [0.00170, 0.23910, 0.23910, 0.23910, 0.05880, 0.12020, 0.09090, -0.02320, -0.02320, -0.02360, -0.03150, -0.03150, 0.80870, -0.81890, -0.81890, 0.56210, -0.58890]],
    ["ASP_NT", [0.07820, 0.22000, 0.22000, 0.22000, 0.02920, 0.11410, -0.02350, -0.01690, -0.01690, 0.81940, -0.80840, -0.80840, 0.56210, -0.58890]],
    ["LYS_NT", [0.09660, 0.21650, 0.21650, 0.21650, -0.00150, 0.11800, 0.02120, 0.02830, 0.02830, -0.00480, 0.01210, 0.01210, -0.06080, 0.06330, 0.06330, -0.01810, 0.11710, 0.11710, -0.37640, 0.33820, 0.33820, 0.33820, 0.72140, -0.60130]],
    ["PRO_NT", [-0.20200, 0.31200, 0.31200, -0.01200, 0.10000, 0.10000, -0.12100, 0.10000, 0.10000, -0.11500, 0.10000, 0.10000, 0.10000, 0.10000, 0.52600, -0.50000]],
    ["CYS_NT", [0.13250, 0.20230, 0.20230, 0.20230, 0.09270, 0.14110, -0.11950, 0.11880, 0.11880, -0.32980, 0.19750, 0.61230, -0.57130]],
    ["CYX_NT", [0.20690, 0.18150, 0.18150, 0.18150, 0.10550, 0.09220, -0.02770, 0.06800, 0.06800, -0.09840, 0.61230, -0.57130]],
    ["CYM_NT", [-0.841600, 0.400467, 0.400467, 0.400467, -0.083400, 0.076700, 0.091900, -0.011300, -0.011300, -0.932230, 0.274800, -0.764900]],
    ["MET_NT", [0.15920, 0.19840, 0.19840, 0.19840, 0.02210, 0.11160, 0.08650, 0.01250, 0.01250, 0.03340, 0.02920, 0.02920, -0.27740, -0.03410, 0.05970, 0.05970, 0.05970, 0.61230, -0.57130]],
    ["MSE_NT", [0.15920, 0.19840, 0.19840, 0.19840, 0.02210, 0.11160, 0.08650, 0.01250, 0.01250, 0.03340, 0.02920, 0.02920, -0.27740, -0.03410, 0.05970, 0.05970, 0.05970, 0.61230, -0.57130]],
    ["DA5", [0.4422, -0.6318, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0431, 0.1838, -0.0268, 0.1607, 0.1877, -0.6175, 0.0725, 0.6897, -0.9123, 0.4167, 0.4167, -0.7624, 0.5716, 0.0598, -0.7417, 0.3800, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.5232]],
    ["DA", [1.1659, -0.7761, -0.7761, -0.4954, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0431, 0.1838, -0.0268, 0.1607, 0.1877, -0.6175, 0.0725, 0.6897, -0.9123, 0.4167, 0.4167, -0.7624, 0.5716, 0.0598, -0.7417, 0.3800, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.5232]],
    ["DA3", [1.1659, -0.7761, -0.7761, -0.4954, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0431, 0.1838, -0.0268, 0.1607, 0.1877, -0.6175, 0.0725, 0.6897, -0.9123, 0.4167, 0.4167, -0.7624, 0.5716, 0.0598, -0.7417, 0.3800, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.6549, 0.4396]],
    ["DAN", [0.4422, -0.6318, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0431, 0.1838, -0.0268, 0.1607, 0.1877, -0.6175, 0.0725, 0.6897, -0.9123, 0.4167, 0.4167, -0.7624, 0.5716, 0.0598, -0.7417, 0.3800, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.6549, 0.4396]],
    ["A5", [0.4295, -0.6223, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0394, 0.2007, -0.0251, 0.2006, 0.1553, -0.6073, 0.0515, 0.7009, -0.9019, 0.4115, 0.4115, -0.7615, 0.5875, 0.0473, -0.6997, 0.3053, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.5246]],
    ["A", [1.1662, -0.7760, -0.7760, -0.4989, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0394, 0.2007, -0.0251, 0.2006, 0.1553, -0.6073, 0.0515, 0.7009, -0.9019, 0.4115, 0.4115, -0.7615, 0.5875, 0.0473, -0.6997, 0.3053, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.5246]],
    ["A3", [1.1662, -0.7760, -0.7760, -0.4989, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0394, 0.2007, -0.0251, 0.2006, 0.1553, -0.6073, 0.0515, 0.7009, -0.9019, 0.4115, 0.4115, -0.7615, 0.5875, 0.0473, -0.6997, 0.3053, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.6541, 0.4376]],
    ["AN", [0.4295, -0.6223, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0394, 0.2007, -0.0251, 0.2006, 0.1553, -0.6073, 0.0515, 0.7009, -0.9019, 0.4115, 0.4115, -0.7615, 0.5875, 0.0473, -0.6997, 0.3053, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.6541, 0.4376]],
    ["DT5", [0.4422, -0.6318, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0680, 0.1804, -0.0239, -0.2209, 0.2607, 0.0025, -0.2269, 0.0770, 0.0770, 0.0770, 0.5194, -0.5563, -0.4340, 0.3420, 0.5677, -0.5881, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.5232]],
    ["DT", [1.1659, -0.7761, -0.7761, -0.4954, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0680, 0.1804, -0.0239, -0.2209, 0.2607, 0.0025, -0.2269, 0.0770, 0.0770, 0.0770, 0.5194, -0.5563, -0.4340, 0.3420, 0.5677, -0.5881, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.5232]],
    ["DT3", [1.1659, -0.7761, -0.7761, -0.4954, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0680, 0.1804, -0.0239, -0.2209, 0.2607, 0.0025, -0.2269, 0.0770, 0.0770, 0.0770, 0.5194, -0.5563, -0.4340, 0.3420, 0.5677, -0.5881, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.6549, 0.4396]],
    ["DTN", [0.4422, -0.6318, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0680, 0.1804, -0.0239, -0.2209, 0.2607, 0.0025, -0.2269, 0.0770, 0.0770, 0.0770, 0.5194, -0.5563, -0.4340, 0.3420, 0.5677, -0.5881, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.6549, 0.4396]],
    ["U5", [0.4295, -0.6223, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0674, 0.1824, 0.0418, -0.1126, 0.2188, -0.3635, 0.1811, 0.5952, -0.5761, -0.3549, 0.3154, 0.4687, -0.5477, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.5246]],
    ["U", [1.1662, -0.7760, -0.7760, -0.4989, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0674, 0.1824, 0.0418, -0.1126, 0.2188, -0.3635, 0.1811, 0.5952, -0.5761, -0.3549, 0.3154, 0.4687, -0.5477, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.5246]],
    ["U3", [1.1662, -0.7760, -0.7760, -0.4989, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0674, 0.1824, 0.0418, -0.1126, 0.2188, -0.3635, 0.1811, 0.5952, -0.5761, -0.3549, 0.3154, 0.4687, -0.5477, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.6541, 0.4376]],
    ["UN", [0.4295, -0.6223, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0674, 0.1824, 0.0418, -0.1126, 0.2188, -0.3635, 0.1811, 0.5952, -0.5761, -0.3549, 0.3154, 0.4687, -0.5477, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.6541, 0.4376]],
    ["DG5", [0.4422, -0.6318, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0358, 0.1746, 0.0577, 0.0736, 0.1997, -0.5725, 0.1991, 0.4918, -0.5699, -0.5053, 0.3520, 0.7432, -0.9230, 0.4235, 0.4235, -0.6636, 0.1814, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.5232]],
    ["DG", [1.1659, -0.7761, -0.7761, -0.4954, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0358, 0.1746, 0.0577, 0.0736, 0.1997, -0.5725, 0.1991, 0.4918, -0.5699, -0.5053, 0.3520, 0.7432, -0.9230, 0.4235, 0.4235, -0.6636, 0.1814, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.5232]],
    ["DG3", [1.1659, -0.7761, -0.7761, -0.4954, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0358, 0.1746, 0.0577, 0.0736, 0.1997, -0.5725, 0.1991, 0.4918, -0.5699, -0.5053, 0.3520, 0.7432, -0.9230, 0.4235, 0.4235, -0.6636, 0.1814, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.6549, 0.4396]],
    ["DGN", [0.4422, -0.6318, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, 0.0358, 0.1746, 0.0577, 0.0736, 0.1997, -0.5725, 0.1991, 0.4918, -0.5699, -0.5053, 0.3520, 0.7432, -0.9230, 0.4235, 0.4235, -0.6636, 0.1814, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.6549, 0.4396]],
    ["G5", [0.4295, -0.6223, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0191, 0.2006, 0.0492, 0.1374, 0.1640, -0.5709, 0.1744, 0.4770, -0.5597, -0.4787, 0.3424, 0.7657, -0.9672, 0.4364, 0.4364, -0.6323, 0.1222, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.5246]],
    ["G", [1.1662, -0.7760, -0.7760, -0.4989, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0191, 0.2006, 0.0492, 0.1374, 0.1640, -0.5709, 0.1744, 0.4770, -0.5597, -0.4787, 0.3424, 0.7657, -0.9672, 0.4364, 0.4364, -0.6323, 0.1222, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.5246]],
    ["G3", [1.1662, -0.7760, -0.7760, -0.4989, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0191, 0.2006, 0.0492, 0.1374, 0.1640, -0.5709, 0.1744, 0.4770, -0.5597, -0.4787, 0.3424, 0.7657, -0.9672, 0.4364, 0.4364, -0.6323, 0.1222, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.6541, 0.4376]],
    ["GN", [0.4295, -0.6223, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0191, 0.2006, 0.0492, 0.1374, 0.1640, -0.5709, 0.1744, 0.4770, -0.5597, -0.4787, 0.3424, 0.7657, -0.9672, 0.4364, 0.4364, -0.6323, 0.1222, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.6541, 0.4376]],
    ["DC5", [0.4422, -0.6318, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, -0.0116, 0.1963, -0.0339, -0.0183, 0.2293, -0.5222, 0.1863, 0.8439, -0.9773, 0.4314, 0.4314, -0.7748, 0.7959, -0.6548, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.5232]],
    ["DC", [1.1659, -0.7761, -0.7761, -0.4954, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, -0.0116, 0.1963, -0.0339, -0.0183, 0.2293, -0.5222, 0.1863, 0.8439, -0.9773, 0.4314, 0.4314, -0.7748, 0.7959, -0.6548, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.5232]],
    ["DC3", [1.1659, -0.7761, -0.7761, -0.4954, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, -0.0116, 0.1963, -0.0339, -0.0183, 0.2293, -0.5222, 0.1863, 0.8439, -0.9773, 0.4314, 0.4314, -0.7748, 0.7959, -0.6548, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.6549, 0.4396]],
    ["DCN", [0.4422, -0.6318, -0.0069, 0.0754, 0.0754, 0.1629, 0.1176, -0.3691, -0.0116, 0.1963, -0.0339, -0.0183, 0.2293, -0.5222, 0.1863, 0.8439, -0.9773, 0.4314, 0.4314, -0.7748, 0.7959, -0.6548, 0.0713, 0.0985, -0.0854, 0.0718, 0.0718, -0.6549, 0.4396]],
    ["C5", [0.4295, -0.6223, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0066, 0.2029, -0.0484, 0.0053, 0.1958, -0.5215, 0.1928, 0.8185, -0.9530, 0.4234, 0.4234, -0.7584, 0.7538, -0.6252, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.5246]],
    ["C", [1.1662, -0.7760, -0.7760, -0.4989, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0066, 0.2029, -0.0484, 0.0053, 0.1958, -0.5215, 0.1928, 0.8185, -0.9530, 0.4234, 0.4234, -0.7584, 0.7538, -0.6252, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.5246]],
    ["C3", [1.1662, -0.7760, -0.7760, -0.4989, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0066, 0.2029, -0.0484, 0.0053, 0.1958, -0.5215, 0.1928, 0.8185, -0.9530, 0.4234, 0.4234, -0.7584, 0.7538, -0.6252, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.6541, 0.4376]],
    ["CN", [0.4295, -0.6223, 0.0558, 0.0679, 0.0679, 0.1065, 0.1174, -0.3548, 0.0066, 0.2029, -0.0484, 0.0053, 0.1958, -0.5215, 0.1928, 0.8185, -0.9530, 0.4234, 0.4234, -0.7584, 0.7538, -0.6252, 0.2022, 0.0615, 0.0670, 0.0972, -0.6139, 0.4186, -0.6541, 0.4376]],
    ["MSE", [-0.41570, 0.27190, -0.02370, 0.08800, 0.03420, 0.02410, 0.02410, 0.00180, 0.04400, 0.04400, -0.27370, -0.05360, 0.06840, 0.06840, 0.06840, 0.59730, -0.56790]],
    ["PTN", [-0.65099, 0.35150, 0.28461, -0.04163, -0.11483, 0.08653, 0.01018, 0.13445, -0.23169, 0.16166, -0.19008, 0.23488, 0.29081, -0.33589, -0.18051, 0.13766, -0.14901, 0.12973, 0.41810, -0.39964, 1.23120, -0.75542, -0.59143, 0.43469, -0.74349, 0.47861]],
    ["PTR", [-0.49370, 0.30180, -0.00100, 0.05560, 0.02110, 0.02250, 0.02250, -0.00170, -0.18490, 0.11950, -0.26800, 0.21000, -0.33800, -0.28000, -0.26800, 0.21000, -0.18490, 0.11950, 0.67310, -0.58540, 1.40000, -0.85000, -0.85000, -0.85000]],
    ["HYP", [-0.27919, -0.11720, 0.10910, 0.08111, 0.33571, -0.02470, -0.08896, 0.07269, 0.04762, -0.00942, 0.07977, 0.42603, -0.40981, -0.61833, 0.39557]],
    ["SEP", [-0.58385, 0.35638, 0.04998, 0.08528, 0.53833, -0.06452, -0.08426, -0.43050, 1.06054, 0.34411, -0.31741, -0.67587, -0.61968, 0.46714, -0.56227, 0.43657]],
    ["ALS", [-0.415700, 0.271900, -0.038900, 0.100700, 0.365400, 0.004300, -0.676100, 0.410200, -0.243800, 1.349034, -0.899504, -0.899504, -0.899504, 0.597300, -0.567900]],
    // saccharides.fdb
    ["NAG_OT", [0.111400, 0.059700, 0.113100, 0.145100, 0.083100, 0.149400, 0.662100, -0.193100, -0.584900, -0.593800, -0.161800, -0.395600, -0.577800, -0.598100, 0.059700, 0.064700, 0.083700, 0.024700, 0.058700, 0.058700, 0.050700, 0.050700, 0.065367, 0.065367, 0.065367, 0.329500, 0.408000, 0.397000]],
    ["NAG_CT", [0.204100, 0.102700, 0.115100, 0.097100, 0.082100, 0.113400, 0.665100, -0.173100, -0.569900, -0.606800, -0.594800, -0.422600, -0.593800, -0.619100, 0.080700, 0.066700, 0.037700, 0.080700, 0.084700, 0.054700, 0.054700, 0.059033, 0.059033, 0.059033, 0.327500, 0.418000, 0.413000, 0.403000]],
    ["NAG", [0.199600, 0.038700, 0.160100, 0.140100, 0.086100, 0.109400, 0.678100, -0.167100, -0.571900, -0.623800, -0.171800, -0.421600, -0.589800, -0.615100, 0.060200, 0.073700, 0.052700, 0.053700, 0.059700, 0.055200, 0.055200, 0.063033, 0.063033, 0.063033, 0.298500, 0.449000, 0.404000]],
    ["BMA_CT", [0.200100, 0.126100, 0.121100, 0.083100, 0.125100, 0.151400, -0.601800, -0.612800, -0.612800, -0.402600, -0.593800, 0.082700, 0.042700, 0.042700, 0.056700, 0.056700, 0.024200, 0.024200, 0.427000, 0.434000, 0.417000, 0.407000]],
    ["BMA", [0.172100, 0.125100, 0.153100, 0.104100, 0.140100, 0.141400, -0.581800, -0.201800, -0.606800, -0.430600, -0.621800, 0.054700, 0.089700, 0.060700, 0.038700, 0.052700, 0.031200, 0.031200, 0.406000, 0.422000, 0.416000]],
    ["MAN", [0.163100, 0.172100, 0.165100, 0.090100, 0.117100, 0.133400, -0.196800, -0.613800, -0.588800, -0.431600, -0.614800, 0.055700, 0.051700, 0.042700, 0.047700, 0.058700, 0.033700, 0.033700, 0.441000, 0.413000, 0.427000]],
    ["MAN_CT", [0.147600, 0.106100, 0.072100, 0.084100, 0.193100, 0.193400, -0.616800, -0.612800, -0.602800, -0.438600, -0.601800, 0.075200, 0.100700, 0.099700, 0.037700, 0.054700, 0.008700, 0.008700, 0.416000, 0.425000, 0.432000, 0.418000]],
    // FUC and FUL are different conformations of the same sugar L-fucopyranose
    ["FUC", [0.335900, 0.091100, 0.089100, 0.132100, 0.164100, -0.083100, -0.607800, -0.587800, -0.606800, -0.595800, -0.445600, 0.074700, 0.058700, 0.062700, 0.055700, 0.055700, 0.050033, 0.050033, 0.050033, 0.413000, 0.405000, 0.422000, 0.419000]],
    // These charges were just copied, and should be recomputed someday.
    ["FUL", [0.335900, 0.091100, 0.089100, 0.132100, 0.164100, -0.083100, -0.607800, -0.587800, -0.606800, -0.595800, -0.445600, 0.074700, 0.058700, 0.062700, 0.055700, 0.055700, 0.050033, 0.050033, 0.050033, 0.413000, 0.405000, 0.422000, 0.419000]],
]);

/** Charges for Ions. Derived from ions.fdb */
export const ionChargesMap = new Map([
    ["AG", [1.000000]],
    ["AL", [3.000000]],
    ["AM", [3.000000]],
    ["AR", [0.000000]],
    ["ARS", [0.000000]],
    ["AU", [1.000000]],
    ["AU3", [3.000000]],
    ["BA", [2.000000]],
    ["0BE", [2.000000]],
    ["BR", [1.000000]],
    ["BS3", [3.000000]],
    ["CA", [2.000000]],
    ["CD", [1.000000]],
    ["CE", [3.000000]],
    ["CF", [3.000000]],
    ["CL", [-1.000000]],
    ["CIM", [-1.000000]],
    ["ZCM", [3.000000]],
    ["CO", [1.000000]],
    ["3CO", [3.000000]],
    ["CR", [3.000000]],
    ["CS", [1.000000]],
    ["CU", [1.000000]],
    ["CU1", [1.000000]],
    ["CU3", [3.000000]],
    ["D8U", [1.000000]],
    ["DY", [3.000000]],
    ["ER3", [3.000000]],
    ["EU", [2.000000]],
    ["EU3", [3.000000]],
    ["F", [1.000000]],
    ["FE", [2.000000]],
    ["FE2", [2.000000]],
    ["GA", [3.000000]],
    ["GD", [3.000000]],
    ["GD3", [3.000000]],
    ["H", [1.000000]],
    ["HG", [2.000000]],
    ["HO", [3.000000]],
    ["HO3", [3.000000]],
    ["IN", [3.000000]],
    ["IOD", [1.000000]],
    ["IR", [4.000000]],
    ["IR3", [3.000000]],
    ["K", [1.000000]],
    ["KR", [0.000000]],
    ["LA", [3.000000]],
    ["LI", [1.000000]],
    ["LU", [3.000000]],
    ["MG", [2.000000]],
    ["MGM", [-1.000000, 0.500000, 0.500000, 0.500000, 0.500000, 0.500000, 0.500000]],
    ["MN", [2.000000]],
    ["MN3", [3.000000]],
    ["MO", [4.000000]],
    ["4MO", [4.000000]],
    ["6MO", [6.000000]],
    ["NA", [1.000000]],
    ["CIP", [1.000000]],
    ["NI", [2.000000]],
    ["3NI", [3.000000]],
    ["OS", [2.000000]],
    ["OS4", [4.000000]],
    ["PB", [2.000000]],
    ["PD", [2.000000]],
    ["PR", [3.000000]],
    ["PT", [2.000000]],
    ["PT4", [4.000000]],
    ["4PU", [4.000000]],
    ["RB", [1.000000]],
    ["RE", [4.000000]],
    ["RH", [1.000000]],
    ["RH3", [3.000000]],
    ["RU", [3.000000]],
    ["SM", [3.000000]],
    ["SB", [3.000000]],
    ["SE", [1.000000]],
    ["SR", [2.000000]],
    ["TA0", [5.000000]],
    ["TB", [3.000000]],
    ["TE", [2.000000]],
    ["TL", [1.000000]],
    ["TH", [4.000000]],
    ["4TI", [4.000000]],
    ["U1", [6.000000]],
    ["V", [3.000000]],
    ["W", [6.000000]],
    ["XE", [0.000000]],
    ["Y1", [2.000000]],
    ["YT3", [3.000000]],
    ["YB", [1.000000]],
    ["YB2", [2.000000]],
    ["ZN", [2.000000]],
    ["ZNM", [0.000000, 0.500000, 0.500000, 0.500000, 0.500000]],
    ["ZR", [4.000000]],
    ["OH", [-1.417000, 0.417000]],
    ["OH3", [-1.000001, 0.666667, 0.666667, 0.666667]],
    ["NH4", [-0.385400, 0.34635, 0.34635, 0.34635, 0.34635]],
]);

/** Charges for known ligands */
export const knownLigandChargesMap = new Map([
    // Waters
    ["HOH", [-0.834000, 0.417000, 0.417000]], // PDB, TIP3P
    ["DOD", [-0.834000, 0.417000, 0.417000]], // deuterium
    ["H2O", [-1.112800, 0.556400, 0.556400]], // Schrodinger
    ["NH3", [-1.106507, 0.336700, 0.336700, 0.336700]], // co-crystal ammonia
    ["CH3", [-0.450815, 0.112704, 0.112704, 0.112704, 0.112704]],
    // adenosine.fdb",
    ["ADP", [-0.955200, 1.367200, -0.955200, -0.955200, -0.634600, 1.492900, -0.947400, -0.947400, -0.657900, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.039400, 0.200700, -0.025100, 0.200600, 0.155300, -0.607300, 0.051500, 0.700900, -0.901900, 0.411500, 0.411500, -0.761500, 0.587500, 0.047300, -0.699700, 0.305300, 0.202200, -0.654100, 0.061500, 0.437600, 0.067000, 0.097200, -0.613900, 0.418600]],
    ["ATP", [-0.952600, 1.265000, -0.952600, -0.952600, -0.532200, 1.385200, -0.889400, -0.889400, -0.568900, 1.253200, -0.879900, -0.879900, -0.598700, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.039400, 0.200700, -0.025100, 0.200600, 0.155300, -0.607300, 0.051500, 0.700900, -0.901900, 0.411500, 0.411500, -0.761500, 0.587500, 0.047300, -0.699700, 0.305300, 0.202200, 0.061500, -0.654100, 0.437600, 0.067000, 0.097200, -0.613900, 0.418600]],
    // guanosine.fdb",
    ["GDP", [-0.955200, 1.367200, -0.955200, -0.955200, -0.634600, 1.492900, -0.947400, -0.947400, -0.657900, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.019100, 0.200600, 0.049200, 0.137400, 0.164000, -0.570900, 0.174400, 0.477000, -0.559700, -0.478700, 0.342400, 0.765700, -0.967200, 0.436400, 0.436400, -0.632300, 0.122200, 0.202200, 0.061500, 0.067000, 0.097200, -0.613900, 0.418600, -0.654100, 0.437600]],
    ["GTP", [-0.952600, 1.265000, -0.952600, -0.952600, -0.532200, 1.385200, -0.889400, -0.889400, -0.568900, 1.253200, -0.879900, -0.879900, -0.598700, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.019100, 0.200600, 0.049200, 0.137400, 0.164000, -0.570900, 0.174400, 0.477000, -0.559700, -0.478700, 0.342400, 0.765700, -0.967200, 0.436400, 0.436400, -0.632300, 0.122200, 0.202200, 0.061500, 0.067000, 0.097200, -0.613900, 0.418600, -0.654100, 0.437600]],
    // Heme.fdb
    ["HEM", [0.250000, -0.180000, 0.030000, -0.020000, -0.160000, 0.100000, 0.100000, -0.300000, 0.100000, 0.100000, 0.300000, -0.500000, -0.500000, 0.020000, -0.265000, 0.075000, 0.075000, 0.075000, 0.020000, -0.110000, 0.150000, 0.030000, -0.180000, 0.020000, -0.265000, 0.075000, 0.075000, 0.075000, -0.050000, -0.130000, 0.150000, -0.300000, 0.100000, 0.100000, 0.020000, -0.110000, 0.150000, 0.030000, -0.180000, 0.020000, -0.265000, 0.075000, 0.075000, 0.075000, -0.050000, -0.120000, 0.150000, -0.300000, 0.100000, 0.100000, 0.020000, -0.110000, 0.150000, 0.030000, -0.180000, 0.020000, -0.265000, 0.075000, 0.075000, 0.075000, -0.020000, 0.020000, -0.110000, 0.150000, -0.160000, 0.100000, 0.100000, -0.300000, 0.100000, 0.100000, 0.300000, -0.500000, -0.500000]],
    // NAD.fdb
    ["NAD", [0.055100, 0.206200, -0.264100, 0.210200, 0.138800, 0.158000, -0.234500, 0.821700, -0.542800, -0.952000, 0.422700, 0.422700, 0.036500, 0.154900, 0.083100, 0.321900, 0.077600, 0.067000, 0.097200, -0.613900, 0.418600, 0.202200, 0.061500, -0.654100, 0.437600, 0.106500, 0.117400, -0.354800, 0.055800, 0.067900, 0.067900, -0.566100, 1.372900, -0.855800, -0.855800, -0.576000, 1.372900, -0.855800, -0.855800, -0.566100, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.202200, 0.061500, -0.654100, 0.437600, 0.067000, 0.097200, -0.613900, 0.418600, 0.039400, 0.200700, -0.025100, 0.200600, 0.155300, -0.607300, 0.051500, 0.700900, -0.901900, 0.411500, 0.411500, -0.761500, 0.587500, 0.047300, -0.699700, 0.305300]],
    ["NDP", [-0.355200, 0.221900, -0.173000, 0.125700, 0.134800, 0.019600, 0.019600, -0.222700, 0.828500, -0.609900, -0.993500, 0.404700, 0.404700, -0.184000, 0.142200, 0.229300, -0.038400, 0.161700, 0.067000, 0.097200, -0.613900, 0.418600, 0.202200, 0.061500, -0.654100, 0.437600, 0.106500, 0.117400, -0.354800, 0.055800, 0.067900, 0.067900, -0.566100, 1.372900, -0.855800, -0.855800, -0.576000, 1.372900, -0.855800, -0.855800, -0.566100, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.202200, 0.061500, -0.654100, 0.437600, -0.282900, 0.232600, -0.582800, 1.297200, -0.898400, -0.898400, -0.898400, 0.039400, 0.200700, -0.025100, 0.200600, 0.155300, -0.607300, 0.051500, 0.700900, -0.901900, 0.411500, 0.411500, -0.761500, 0.587500, 0.047300, -0.699700, 0.305300]],
    ["NP2", [0.055100, 0.206200, -0.264100, 0.210200, 0.138800, 0.158000, -0.234500, 0.821700, -0.542800, -0.952000, 0.422700, 0.422700, 0.036500, 0.154900, 0.083100, 0.321900, 0.077600, 0.067000, 0.097200, -0.613900, 0.418600, 0.202200, 0.061500, -0.654100, 0.437600, 0.106500, 0.117400, -0.354800, 0.055800, 0.067900, 0.067900, -0.566100, 1.372900, -0.855800, -0.855800, -0.576000, 1.372900, -0.855800, -0.855800, -0.566100, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.202200, 0.061500, -0.654100, 0.437600, -0.087600, 0.157200, -0.529700, 1.320200, -0.718400, 0.432200, -0.802500, -0.802500, 0.039400, 0.200700, -0.025100, 0.200600, 0.155300, -0.607300, 0.051500, 0.700900, -0.901900, 0.411500, 0.411500, -0.761500, 0.587500, 0.047300, -0.699700, 0.305300]],
    ["NP3", [-0.355200, 0.221900, -0.173000, 0.125700, 0.134800, 0.019600, 0.019600, -0.222700, 0.828500, -0.609900, -0.993500, 0.404700, 0.404700, -0.184000, 0.142200, 0.229300, -0.038400, 0.161700, 0.067000, 0.097200, -0.613900, 0.418600, 0.202200, 0.061500, -0.654100, 0.437600, 0.106500, 0.117400, -0.354800, 0.055800, 0.067900, 0.067900, -0.566100, 1.372900, -0.855800, -0.855800, -0.576000, 1.372900, -0.855800, -0.855800, -0.566100, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.202200, 0.061500, -0.654100, 0.437600, -0.087600, 0.157200, -0.529700, 1.320200, -0.718400, 0.432200, -0.802500, -0.802500, 0.039400, 0.200700, -0.025100, 0.200600, 0.155300, -0.607300, 0.051500, 0.700900, -0.901900, 0.411500, 0.411500, -0.761500, 0.587500, 0.047300, -0.699700, 0.305300]],
    ["NDP", [0.055100, 0.206200, -0.264100, 0.210200, 0.138800, 0.158000, -0.234500, 0.821700, -0.542800, -0.952000, 0.422700, 0.422700, 0.036500, 0.154900, 0.083100, 0.321900, 0.077600, 0.067000, 0.097200, -0.613900, 0.418600, 0.202200, 0.061500, -0.654100, 0.437600, 0.106500, 0.117400, -0.354800, 0.055800, 0.067900, 0.067900, -0.566100, 1.372900, -0.855800, -0.855800, -0.576000, 1.372900, -0.855800, -0.855800, -0.566100, 0.055800, 0.067900, 0.067900, 0.106500, 0.117400, -0.354800, 0.202200, 0.061500, -0.654100, 0.437600, -0.282900, 0.232600, -0.582800, 1.297200, -0.898400, -0.898400, -0.898400, 0.039400, 0.200700, -0.025100, 0.200600, 0.155300, -0.607300, 0.051500, 0.700900, -0.901900, 0.411500, 0.411500, -0.761500, 0.587500, 0.047300, -0.699700, 0.305300]],
    // saccharides.fdb
    ["MAN_NP", [0.141400, 0.133100, 0.131100, 0.086100, 0.124100, 0.116400, -0.601800, -0.604800, -0.611800, -0.398600, -0.602800, 0.029700, 0.045700, 0.066700, 0.081700, 0.072200, 0.072200, 0.019200, 0.019200, 0.430000, 0.431000, 0.414000, 0.408000]],
    // FUC and FUL are different conformations of the same sugar L-fucopyranose
    ["FUC_NP", [0.144400, 0.096100, 0.120100, 0.087100, 0.114100, -0.093100, -0.601800, -0.598800, -0.601800, -0.393600, 0.047700, 0.086700, 0.038700, 0.045700, 0.056200, 0.056200, 0.046033, 0.046033, 0.046033, 0.424000, 0.419000, 0.414000]],
    ["FUL_NP", [0.136400, 0.129100, 0.078100, 0.088100, 0.109100, -0.085100, -0.600800, -0.595800, -0.582800, -0.409600, 0.044700, 0.042700, 0.075700, 0.045700, 0.061200, 0.061200, 0.048700, 0.048700, 0.048700, 0.429000, 0.414000, 0.413000]],
]);

/**
 * Known residue names for buffers derived from SolventLigands in residue-classes.tcl
 * These are so-called buffer compounds used in crystallography.
 */
export const bufferResidueNames = ['SO4', 'SO3', 'SO2', 'PO4', 'PO3', 'PO2', 'GOL',
    'ONL', 'EPE', 'MLT', 'UMP', 'SGM', 'DTV',
    'RHD', 'EDO', 'ACT', 'ACY', 'IMD', 'FMT',
    'XCP', 'XPC', 'B3E', 'BNG', 'DMS', 'IPA', 'MES', 'BME',
    'PGE', 'OHX', 'ISU', 'CAD', 'PEG', 'CIT', 'NO3',
    'MOH', 'AKR', 'MPD', 'TRS', 'PA0', 'BU4', 'BEN', 'AF3'];

/**
 * Known residue names for cofactors derived from StandardCofactors and OtherCofactors
 * in residue-classes.tcl
 * A compounds are Adenosine, N compounds are nicotinamide adenine dinucleotide
 * phosphates in various charge states, HEM is the iron-containing Heme co-factor.
 */
export const cofactorResidueNames = [
    'ADP', 'ATP', 'GDP', 'GTP',
    'NAD', 'NDP', 'NP2', 'NP3', 'NAP',
    'HEM', // heme
];

/**
 * Known residue names for saccharides
 */
export const saccharideResidueNames = [
    'NAG', 'FBP', 'BMA', 'FUC', 'MAN',
    'MMA', 'TOA', 'TOB', 'TOC', 'BDR',
    'IDG', 'BDG', 'NEB', 'A2G', 'GAL',
    'MDA', 'DDA', 'DDL', 'BGC', 'BDP',
    'CEG', 'XYP', 'GXL', 'NDG', 'MRP',
    'DSR', 'DAG', 'EMP', 'NGA', 'GL0',
    'GLC', 'GCU', 'MXY', 'GC4', 'RAM',
    'FUL', 'ERI', 'ARI', 'CDR', '1GL',
];

/**
 * These are names used for crystallographyic waters (HOH is for PDB waters, SOL used by
 * Schrodinger, UNX is for electron densities for unknown atoms, likely to be waters.
 */
export const waterResidueNames = ['HOH', 'H2O', 'DOD', 'SOL', 'UNX'];

/** This is produced by the -amber-to-element bmoc command. */
export const amberToElementMap = new Map([
    ["C", "C"],
    ["CA", "C"],
    ["CB", "C"],
    ["CC", "C"],
    ["CD", "C"],
    ["CI", "C"],
    ["CK", "C"],
    ["CP", "C"],
    ["CM", "C"],
    ["CS", "C"],
    ["CN", "C"],
    ["CQ", "C"],
    ["CR", "C"],
    ["CT", "C"],
    ["CV", "C"],
    ["CW", "C"],
    ["C*", "C"],
    ["CX", "C"],
    ["CY", "C"],
    ["CZ", "C"],
    ["C5", "C"],
    ["C4", "C"],
    ["C0", "Ca"],
    ["H", "H"],
    ["HC", "H"],
    ["H1", "H"],
    ["H2", "H"],
    ["H3", "H"],
    ["HA", "H"],
    ["H4", "H"],
    ["H5", "H"],
    ["HO", "H"],
    ["HS", "H"],
    ["HW", "H"],
    ["HP", "H"],
    ["HZ", "H"],
    ["F", "F"],
    ["Cl", "Cl"],
    ["Br", "Br"],
    ["I", "I"],
    ["IM", "Cl"],
    ["IB", "Na"],
    ["MG", "Mg"],
    ["N", "N"],
    ["NA", "N"],
    ["NB", "N"],
    ["NC", "N"],
    ["N2", "N"],
    ["N3", "N"],
    ["NO", "No"],
    ["NP", "N"],
    ["NT", "N"],
    ["N*", "N"],
    ["NY", "N"],
    ["O", "O"],
    ["O2", "O"],
    ["OW", "O"],
    ["OH", "O"],
    ["OS", "O"],
    ["OP", "O"],
    ["P", "P"],
    ["S", "S"],
    ["SH", "S"],
    ["CU", "Cu"],
    ["FE", "Fe"],
    ["Zn", "Zn"],
    ["EP", "UNK"],
    ["Li", "Li"],
    ["IP", "Na"],
    ["Na", "Na"],
    ["K", "K"],
    ["Rb", "Rb"],
    ["Cs", "Cs"],
    ["LP", "UNK"],
    ["c", "C"],
    ["c1", "C"],
    ["c2", "C"],
    ["c3", "C"],
    ["ca", "C"],
    ["cp", "C"],
    ["cq", "C"],
    ["cc", "C"],
    ["cd", "C"],
    ["ce", "C"],
    ["cf", "C"],
    ["cg", "C"],
    ["ch", "C"],
    ["cx", "C"],
    ["cy", "C"],
    ["cu", "C"],
    ["cv", "C"],
    ["cz", "C"],
    ["h1", "H"],
    ["h2", "H"],
    ["h3", "H"],
    ["h4", "H"],
    ["h5", "H"],
    ["ha", "H"],
    ["hc", "H"],
    ["hn", "H"],
    ["ho", "H"],
    ["hp", "H"],
    ["hs", "H"],
    ["hw", "H"],
    ["hx", "H"],
    ["f", "F"],
    ["cl", "Cl"],
    ["br", "Br"],
    ["i", "I"],
    ["n", "N"],
    ["n1", "N"],
    ["n2", "N"],
    ["n3", "N"],
    ["n4", "N"],
    ["na", "N"],
    ["nb", "N"],
    ["nc", "N"],
    ["nd", "N"],
    ["ne", "N"],
    ["nf", "N"],
    ["nh", "N"],
    ["no", "N"],
    ["ni", "Ni"],
    ["nj", "N"],
    ["nk", "N"],
    ["nl", "N"],
    ["nm", "N"],
    ["nn", "N"],
    ["np", "Np"],
    ["nq", "N"],
    ["o", "O"],
    ["oh", "O"],
    ["os", "O"],
    ["op", "O"],
    ["oq", "O"],
    ["ow", "O"],
    ["p2", "P"],
    ["p3", "P"],
    ["p4", "P"],
    ["p5", "P"],
    ["pb", "P"],
    ["pc", "P"],
    ["pd", "P"],
    ["pe", "P"],
    ["pf", "P"],
    ["px", "P"],
    ["py", "P"],
    ["s", "S"],
    ["s2", "S"],
    ["s4", "S"],
    ["s6", "S"],
    ["sh", "S"],
    ["ss", "S"],
    ["sp", "S"],
    ["sq", "S"],
    ["sx", "S"],
    ["sy", "S"],
    ["C1", "C"],
    ["N1", "N"],
    ["Ag", "Ag"],
    ["Am", "Am"],
    ["Ar", "Ar"],
    ["As", "As"],
    ["Au", "Au"],
    ["B", "B"],
    ["Ba", "Ba"],
    ["Be", "Be"],
    ["Bi", "Bi"],
    ["Ca", "Ca"],
    ["Cd", "Cd"],
    ["Ce", "Ce"],
    ["Cf", "Cf"],
    ["Cm", "Cm"],
    ["Co", "Co"],
    ["Cr", "Cr"],
    ["Cu", "Cu"],
    ["D", "H"],
    ["Dy", "Dy"],
    ["Er", "Er"],
    ["Eu", "Eu"],
    ["Fe", "Fe"],
    ["Ga", "Ga"],
    ["Gd", "Gd"],
    ["Hg", "Hg"],
    ["Ho", "Ho"],
    ["In", "In"],
    ["Ir", "Ir"],
    ["Kr", "Kr"],
    ["La", "La"],
    ["Lu", "Lu"],
    ["Mg", "Mg"],
    ["G0", "Mg"],
    ["Gx", "UNK"],
    ["Gy", "UNK"],
    ["Gz", "UNK"],
    ["Mn", "Mn"],
    ["Mo", "Mo"],
    ["Ni", "Ni"],
    ["Os", "Os"],
    ["Pb", "Pb"],
    ["Pd", "Pd"],
    ["Pr", "Pr"],
    ["Pt", "Pt"],
    ["Pu", "Pu"],
    ["Re", "Re"],
    ["Rh", "Rh"],
    ["Ru", "Ru"],
    ["Sb", "Sb"],
    ["Sc", "Sc"],
    ["Se", "Se"],
    ["Sm", "Sm"],
    ["Sr", "Sr"],
    ["Ta", "Ta"],
    ["Tb", "Tb"],
    ["Te", "Te"],
    ["Th", "Th"],
    ["Ti", "Ti"],
    ["Tl", "Tl"],
    ["U", "U"],
    ["V", "V"],
    ["W", "W"],
    ["Xe", "Xe"],
    ["Y", "Y"],
    ["Yb", "Yb"],
    ["Z0", "Zn"],
    ["Zr", "Zr"],
    ["oW", "O"],
    ["hW", "H"],
    ["WC", "W"],
    ["O3", "O"],
    ["Si", "Si"],
    ["Al", "Al"],
]);

export const aminoRings = Object.freeze({
    PHE: { 1: ['CG', 'CD1', 'CD2', 'CE1', 'CE2', 'CZ', 'HD1', 'HD2', 'HE1', 'HE2', 'HZ'] },
    HIS: { 1: ['CG', 'ND1', 'CD2', 'CE1', 'NE2', 'HD2', 'HE1', 'HE2'] },
    TYR: { 1: ['CG', 'CD1', 'CD2', 'CE1', 'CE2', 'CZ', 'HD1', 'HD2', 'HE1', 'HE2'] },
    TRP: {
        1: ['CG', 'CD1', 'CD2', 'NE1', 'CE2', 'HD1', 'HE1'],
        2: ['CD2', 'CE2', 'CE3', 'CZ2', 'CZ3', 'CH2', 'HE3', 'HZ2', 'HZ3', 'HH2'],
    },
    PRO: { 1: ['CA', 'CB', 'N', 'CD', 'CG', 'HA', 'HB2', 'HB3', 'HD2', 'HD3', 'HG2', 'HG3'] },
});

/**
 * Return built-in atom info for known protein / NA residues
 * @param {string} resName
 * @returns {{ atomNames: string[], amberNames: string[], charges: number[]}?}
 */
export function getKnownResidueAtomInfo(resName) {
    const atomNames = residueAtomNamesMap.get(resName);
    if (!atomNames) return null;

    return {
        atomNames,
        amberNames: residueAmberNamesMap.get(resName),
        charges: residueChargesMap.get(resName),
    };
}

/**
 * Return built-in atom info for known ligands (cofactors and HOH, not ions)
 * @param {string} resName
 * @returns {{ atomNames: string[], amberNames: string[], charges: number[]}?}
 */
export function getKnownLigandAtomInfo(resName) {
    const atomNames = knownLigandAtomNamesMap.get(resName);
    if (!atomNames) return null;

    return {
        atomNames,
        amberNames: knownLigandAmberNamesMap.get(resName),
        charges: knownLigandChargesMap.get(resName),
    };
}

/**
 * Return built-in atom info for known ions
 * @param {string} resName
 * @returns {{ atomNames: string[], amberNames: string[], charges: number[]}?}
 */
export function getKnownIonAtomInfo(resName) {
    const atomNames = ionAtomNamesMap.get(resName);
    if (!atomNames) return null;

    return {
        atomNames,
        amberNames: ionAmberNamesMap.get(resName),
        charges: ionChargesMap.get(resName),
    };
}

/**
 * Searches known protein / NA residues, ligands (cofactors & HOH), and ions for specified resnames.
 * This accepts a single search name or a list.
 * It searches all search names for each category in order of probability:
 * - First Protein / NA
 * - Then cofactor / HOH
 * - Then Ions
 * @param {string | string[]} searchNamesIn
 * @returns {{ atomNames: string[], amberNames: string[], charges: number[]}?}

 */
export function searchKnownAtomInfo(searchNamesIn) {
    const searchNames = [].concat(searchNamesIn);
    const fns = [getKnownResidueAtomInfo, getKnownLigandAtomInfo, getKnownIonAtomInfo];
    for (const func of fns) {
        for (const searchName of searchNames) {
            const result = func(searchName);
            if (result) return result;
        }
    }
    return null;
}

/* eslint-enable quotes */
