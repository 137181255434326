import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// Material UI
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
// Local
import { setPreferences } from '../../redux/prefs';
import { preferences, PREFS_TYPE } from '../../redux/prefs/constants';
import { addMenuItem } from '../../menu_cmds';
import { EventBroker } from '../../eventbroker';
import ModalPane from '../ModalPane';
import Select from '../common/Select';
import { MainButton } from '../common/Buttons';
import { Loader } from '../../Loader';
import { useEventBrokerStateHandling } from '../common/helpers/useEventBrokerSubscription';

function formValidate(values) {
    const errors = {};

    const { prefName, prefValue } = values;

    if (prefName === '') {
        errors.prefName = 'This field is required!';
    }

    if (prefValue === '') {
        errors.prefValue = 'This field is required!';
    }

    return errors;
}

export default function PreferencesPane({ ModalPaneProps }) {
    const prefs = useSelector((state) => state.prefs);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (Loader.AllowLabFeatures) {
            addMenuItem('PreferencesPane', 'Preferences', () => { EventBroker.publish('showPreferencesPane', true); });
        }
    }, []);

    useEventBrokerStateHandling('showPreferencesPane', open, setOpen);

    function handleClose() {
        setOpen(false);
    }

    function handleSubmit(values) {
        dispatch(setPreferences(values));
    }

    return (
        <ModalPane
            visible={open}
            title="Preferences"
            close={handleClose}
            closeOnEscape={handleClose}
            {...ModalPaneProps}
        >
            <Formik
                initialValues={prefs}
                validate={formValidate}
                onSubmit={handleSubmit}
            >
                {({ values: formikValues }) => (
                    <Form>
                        {preferences.map((pref) => {
                            const {
                                id, label, type, values,
                            } = pref;

                            switch (type) {
                                case PREFS_TYPE.boolean:
                                    return (
                                        <Grid container key={id} alignItems="center" justifyContent="space-between" style={{ marginBottom: '0.5rem' }}>
                                            <InputLabel htmlFor={id}>{`${label}:`}</InputLabel>
                                            <Field
                                                as={Switch}
                                                name={id}
                                                checked={formikValues[id]}
                                            />
                                        </Grid>
                                    );

                                case PREFS_TYPE.enum:
                                    return (
                                        <Grid container key={id} style={{ marginBottom: '0.5rem' }}>
                                            <Field
                                                as={Select}
                                                name={id}
                                                id={id}
                                                label={label}
                                                margin="dense"
                                                SelectProps={{
                                                    native: false,
                                                }}
                                            >
                                                {values.map(
                                                    (opt) => (
                                                        <MenuItem
                                                            key={opt}
                                                            value={opt}
                                                        >
                                                            {opt}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Field>
                                        </Grid>
                                    );

                                case PREFS_TYPE.int:
                                    return (
                                        <Grid container key={id} style={{ marginBottom: '0.5rem' }}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                type="number"
                                                name={id}
                                                label={label}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    );
                                default:
                                    console.error(`Invalid preference type: ${type}`);
                                    return null;
                            }
                        })}
                        <MainButton fullWidth type="submit" onClick={handleClose}>Save</MainButton>
                    </Form>
                )}
            </Formik>
        </ModalPane>
    );
}
