import React from 'react';

import { imageSrc } from '../ui_utils';

export { MainPage } from './pages/MainPage';
export { Cheatsheet } from './pages/Cheatsheet';

export function ChooseProteinPage({ title }) {
    return (
        <div className="man-page">
            <Title>{toTitleCase(title)}</Title>
            <br />
            <p>
                Select
                {' '}
                <span className="bmaps-control">
                    <i className="fa fa-folder-open" />
                    Choose Protein
                </span>
                {' '}
                to view the protein list. This list contains hundreds of prepared structures with
                precomputed water and fragment data.
            </p>
            <p>
                If we don&apos;t have your target, you can import it from the PDB or load your own
                pdb file.
            </p>
            <p>To load by PDB id:</p>
            <ul>
                <li>Enter the pdb id of your target protein into the protein list.</li>
                <li>
                    Click the full protein name to load a prepared structure, including water and
                    fragment data.
                </li>
                <li>
                    Click &quot;Import
                    {' '}
                    <i>pdb id</i>
                    {' '}
                    from the protein data bank&quot; to load the structure only; water and fragment
                    data will not be available.
                </li>
            </ul>
            <p>
                <span className="embedded-content">
                    <Image src="screencast-load-pdb.gif" />
                </span>
            </p>
            <p>
                To load your own protein, click
                {' '}
                <span className="bmaps-control">
                    <i className="fa fa-plus" />
                    {' '}
                    Upload my structure
                </span>
                {' '}
                from the Welcome Screen, or click
                {' '}
                <span className="bmaps-control">
                    <i className="fa fa-folder-open" />
                    {' '}
                    Choose Protein
                </span>
                {' '}
                and then the top-right Upload button.
            </p>
            <p>
                <span className="embedded-content">
                    <Image src="screencast-upload.gif" />
                </span>
            </p>
            <p><strong>Advanced features: Preserve hydrogens</strong></p>
            <p>
                By default, hydrogens are recomputed for imported user structures.
                This ensures that missing hydrogens are added and that all hydrogens have Amber
                compatible names, but it may result in changed hydrogen positions.
                <br />
                If you need to keep the exact hydrogens in your structure, check the &quot;Preserve
                hydrogens&quot; box.
                <br />
                Just make sure there aren&apos;t any missing hydrogens and that they all have Amber
                compatible names.
            </p>
            <p><strong>Advanced features: Name mappings</strong></p>
            <p dangerouslySetInnerHTML={{ __html: nameMappingDocumentation() }} />
        </div>
    );
}

export function AddCompoundPage({ title }) {
    return (
        <div className="man-page">
            <Title>{toTitleCase(title)}</Title>
            <br />
            <p>There are several ways to add compounds:</p>
            <p><strong>Add a file or compound data</strong></p>
            <ul>
                <li>Drag and drop a file into the workspace</li>
                <li>Paste compound data into the workspace</li>
                <li>
                    Open the
                    {' '}
                    <span className="bmaps-control">
                        <i className="fa fa-plus" />
                        {' '}
                        Add Compound
                    </span>
                    {' '}
                    tool and use the
                    {' '}
                    <span className="bmaps-control">Enter Mol Data</span>
                    {' '}
                    tab to enter compound data or upload files
                </li>
                <li>Supported formats: cdx, sdf, smi, mol (v2000), mol2, pdb, cif, cdxml, cml</li>
            </ul>
            <p><strong>Use the 2D sketcher</strong></p>
            <p>
                Choose
                {' '}
                <span className="bmaps-control">Draw New Cmpd</span>
                {' '}
                from the
                {' '}
                <span className="bmaps-control">Edit</span>
                {' '}
                menu and draw a new compound.
            </p>
            <p><strong>Import from CDD Vault</strong></p>
            <ol>
                <li>
                    Open
                    {' '}
                    <span className="bmaps-control">
                        <i className="fa fa-plus" />
                        {' '}
                        Add Compound
                    </span>
                    {' '}
                    tool and use the
                    {' '}
                    <span className="bmaps-control">CDD Vault</span>
                    {' '}
                    tab to add your API key and connect to your Vault.
                </li>
                <li>Select your vault and project to view your stored compounds.</li>
                <li>
                    Mouseover your desired compound and click
                    {' '}
                    <span className="bmaps-control bmaps-button">Import &amp; Align</span>
                    {' '}
                    or
                    {' '}
                    <span className="bmaps-control bmaps-button">Import Only</span>
                    {' '}
                    if you want to preserve coordinates.
                </li>
            </ol>
            <p><strong>Docking new compounds</strong></p>
            <p>
                When you load a compound, the default behavior is to align the compound to a
                crystal ligand. If there is no crystal ligand, or if you want a better pose, dock
                the compound before doing any design.
            </p>
            <p>
                The
                {' '}
                <span className="bmaps-control">Dock</span>
                {' '}
                control is in the
                {' '}
                <span className="bmaps-control">Modeling Tasks</span>
                {' '}
                menu.
            </p>
        </div>
    );
}

export function ModifyCompoundPage({ title }) {
    return (
        <div className="man-page">
            <Title>{toTitleCase(title)}</Title>
            <br />
            <p>
                Now that your compound is in position and you have baseline energies, it&apos;s
                time to make some modifications and see if they improve the compound&apos;s
                chances.
            </p>
            <p>There are several ways to modify compounds:</p>
            <ul>
                <li>
                    <strong>2D Sketcher:</strong>
                    <br />
                    Choose
                    {' '}
                    <span className="bmaps-control">Edit Active Cmpd.</span>
                    {' '}
                    from the
                    {' '}
                    <span className="bmaps-control bmaps-menu">Edit</span>
                    {' '}
                    menu.
                </li>
                <li>
                    <strong>Terminal replacement:</strong>
                    <br />
                    Right-click an atom in a terminal group and choose a replacement from the
                    {' '}
                    <span className="bmaps-control">Replace terminal</span>
                    {' '}
                    section.
                </li>
                <li>
                    <strong>Growing to fragments:</strong>
                    <br />
                    Right-click an atom and click
                    {' '}
                    <span className="bmaps-control">Bond link</span>
                    {' '}
                    from the
                    {' '}
                    <span className="bmaps-control">Grow</span>
                    {' '}
                    section. If the structure came with fragment data, then nearby fragments will
                    appear as candidates to replace a section of your compound.
                </li>
            </ul>
            <p>
                <span className="embedded-content">
                    <Image src="screencast-modify.gif" />
                </span>
            </p>
            <p>
                After making a few changes, click the
                {' '}
                <i className="fa fa-eye" />
                s on a few compounds in the left-hand compound list. Then you can compare the
                energies and properties in the tables below.
            </p>
            <p>
                <span className="embedded-content">
                    <Image src="screencast-energies.gif" />
                </span>
            </p>
        </div>
    );
}

export function ExportPage({ title }) {
    return (
        <div className="man-page">
            <Title>{toTitleCase(title)}</Title>
            <br />
            <p>
                When you are ready to move your compound designs along the pipeline, use the
                {' '}
                <span className="bmaps-control bmaps-menu">Export</span>
                {' '}
                tool to:
            </p>
            <ul>
                <li>Download your data in a number of common formats</li>
                <li>Search PubChem for similar compounds</li>
                <li>Upload your compound to CDD Vault, if you have an account.</li>
            </ul>
            <p>
                In order to save everything and pick up where you left off later, click the
                {' '}
                <span className="bmaps-control bmaps-button">Save</span>
                {' '}
                button in the top right to download a session file with your protein and all your
                compounds. Your compounds aren&apos;t stored in our server.
            </p>
        </div>
    );
}

export function FragmentDataPage({ title }) {
    return (
        <div className="man-page">
            <Title>{toTitleCase(title)}</Title>
            <br />
            <p>
                Eventually, you may wish to compute water or fragment maps on your own structure
                and compounds.
            </p>
            <p>
                Open the
                {' '}
                <span className="bmaps-control">Modeling Tasks</span>
                {' '}
                menu and choose
                {' '}
                <span className="bmaps-control">Run Water Sim.</span>
                {' '}
                or
                {' '}
                <span className="bmaps-control">Run Frag. Sim.</span>
                .
            </p>
            <p>
                Click
                {' '}
                <span className="bmaps-control">Prepare system</span>
                , then
                {' '}
                <span className="bmaps-control">Send to simulator</span>
                .
            </p>
            <p>
                <span className="embedded-content">
                    <Image src="screencast-fragstart.gif" />
                </span>
            </p>
            <p><strong>Advanced: binding site specification</strong></p>
            <p dangerouslySetInnerHTML={{ __html: bindingSiteSpecDocumentation() }} />
        </div>
    );
}

export function EvaluatePage({ title }) {
    return (
        <div className="man-page">
            <Title>{toTitleCase(title)}</Title>
            <br />
            <p>
                To view important chemical properties like interaction energies and logP, click the
                {' '}
                <span className="bmaps-control">Energies</span>
                {' '}
                and
                {' '}
                <span className="bmaps-control">Properties</span>
                {' '}
                tabs in the bottom left.
            </p>
            <p>
                To visualize energies semi-quantitatively, especially the cost of desolvation,
                see the
                {' '}
                <span className="bmaps-control">Highlights</span>
                {' '}
                menu.
            </p>
            <p>
                To view crystal waters or highlighted water maps, open the
                {' '}
                <span className="bmaps-control">Waters</span>
                . View hotspot clusters from the
                {' '}
                <span className="bmaps-control">Highlights</span>
                {' '}
                menu.
            </p>
            <p>
                To view fragment map summaries, visit the
                {' '}
                <span className="bmaps-control">Fragments</span>
                {' '}
                tab of the left side selector. Click a fragment to view the summary. A slider will
                appear, allowing you to filter out fragments that don&apos;t meet the energy
                threshold.
            </p>
            <p>
                <span className="embedded-content">
                    <Image src="screencast-highlights.gif" />
                </span>
            </p>
        </div>
    );
}

export function DockCompoundPage({ title }) {
    return (
        <div className="man-page">
            <Title>{toTitleCase(title)}</Title>
            <br />
            <div className="embedded-content" id="dockImgDiv">
                <Image src="docking-box.png" />
            </div>
            <p>
                Docking is a tool for finding where a compound is likely to bind to a target
                protein. BMaps uses the AutoDock Vina program which samples many locations and
                orientations of a compound (binding poses) within a designated region of the
                protein. These poses are scored and the best one is returned. The docking result
                is a starting point for structure-based design of compound modifications.
            </p>
            <p>
                To dock a compound, first select a compound by pressing the arrow (
                <i className="fa fa-arrow-right" />
                ) next to the compound name in the panel on the left side of the screen.
                Next, press the
                {' '}
                <span className="bmaps-control">Modeling Tasks</span>
                {' '}
                button and select
                {' '}
                <span className="bmaps-control">Dock</span>
                {' '}
                from the list of available modeling tasks.
                A dialog will be shown confirming the name of the compound to be docked and
                docking parameters that describe the docking speed and the region around the
                protein where the docking should be focused (the bounding box). The default is to
                dock in a region near the co-crystal ligand, if available. The docking speed Normal
                samples comprehensively, while the Fast setting is for getting a quick look.
            </p>
            <p>
                Studies indicate that docking poses agree with X-ray co-crystal experiments about
                87% of the time. Small molecules (&lt;&nbsp;300&nbsp;Da, 2-3 rotatable bonds) have
                a higher success rate, and larger molecules (&gt;&nbsp;500&nbsp;Da, 8-10 rotatable
                bonds), including peptides, have less success. If the pose does not meet your
                expectations or seem plausible, contact us and we can offer consulting services
                with more elaborate modeling. Very tight binding pockets, such as that seen on P38
                (1KV1 PDB structure), often require special techniques to reproduce known binding
                poses.
            </p>
        </div>
    );
}

function Title({ children }) {
    return (<h3 style={{ textAlign: 'center' }}>{children}</h3>);
}

function Image({ src }) {
    const fullSrc = imageSrc(src);
    return (<img src={fullSrc} alt="" />);
}

// https://stackoverflow.com/a/4878800
function toTitleCase(str) {
    return str.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

function nameMappingDocumentation() {
    const doc = `The Name mappings text box allows the user to change the name of an atom, residue, ligand, or chain. This may be needed to achieve compatibility with the Amber forcefield definitions used by energy calculations or other reasons. Multiple name mappings can be included, separated by semi-colons. Note, since a common use case is importing files from Schrodinger's Maestro package, these files are automatically recognized and appropriate name mappings employed, saving you the trouble.

For residues and ligands, there are two types of mappings called PDB and AMBER. PDB refers to a 3-letter residue/ligand ID used for display or when exporting files. AMBER refers to the name of an Amber forcefield definition. The PDB name implies the AMBER name, but there are occassions where these want to be different. An example is cysteine where the (default) PDB name is CYS and the Amber name is one of CYS (HS), CYM (S-anion), and CYX (disulfide bridge).
The syntax for the mappings are:
/!!PDB:<residue spec>=<new PDB name>;!!/
/!!AMBER:<residue spec>=<new Amber name>;!!/
where /!!<residue spec>!!/ is /!!<3-letter id>.<sequence number><insertion code>:<chainID>!!/ as in CYS.301A:A.
For example if CYS 301 on chain B has a disulfide link to another cysteine, the mapping /!!AMBER:CYS.301:B=CYX!!/ is used.

Another common use of mappings is to specify histidine types, mapping HIS to Amber types HID, HIE, HIP. If the right side of the equals sign (=) is blank, this means the residue should be removed.
Another use of residue mappings is when you happen to have a file where two different kinds of molecules are given the same name, say MOL, and they need to be distinguished for forcefield assignment.
For example, if MOL.201 and MOL.301 are different kinds of molecules, you might do:
/!!PDB:MOL.201=IN1;PDB:MOL.301=IN2!!/

Protein residues or compounds named UNK cannot be used in energy calculations or simulations because, when files are loaded from the PDB, most such molecules are incomplete. If you are sure the molecule is complete, rename it (say, UNK residue number 201) to MYC (or whatever 3-letter code you want) using:
/!!PDB:UNK.201=MYC!!/

Atom naming mappings are primarily used for hydrogens. Typically, PDB and Amber hydrogen naming is compatible and also consistent with hydrogens added by the commonly-used Reduce program. Other software tools may use different naming conventions. The format of atom name specifications is:
/!!<residue spec or *>|<from atom1 name>=<to atom1 name>,<from atom2 name>=<to atom2 name>, ...;!!/
where * means apply to any residue.
For example, /!!*|HXT=!!/ would remove any HXT C-terminal atoms.
Other examples,
/!!ASN|HD2=HD22;!!/ (different naming convention)
/!!SER|H1=H,H2=;!!/ (Change an NH2 terminal to NH)
/!!GDP|H5'1=H5',H5'2=H5'',H2_1=HN21,H2_2=HN22;!!/
Note, mappings that just apply to N-terminals or C-Terminals would be specified with a residue spec such as ASN_NT or ASN_CT.

Some other specifications for loading structure files can be provided:
/!!RESET;!!/     Clear out any of the default mappings (used if they get in your way).
/!!NOPDB;!!/     Don't lookup ligands in the PDB het dictionary for bond definitions (default is to do lookup).
/!!CHAIN:A=Z;!!/ Change the chain ID A to Z. A model number may be added to the chain ID as in A1.
/!!MODEL:3;!!/   Keep only model 3 in an NMR file (-1 means ignore model records, useful when only one model specified).
/!!ALTLOC:*!!/;  Keep the alternate location with the best B value.
Also: /!!ALTLOC:<residue spec>=<alt loc>!!/, where /!!<alt loc>!!/ is A, B, C, etc.
/!!INFER: false;!!/ Infer bonds (false means don't, default is true)`;
    return prepDoc(doc);
}

function bindingSiteSpecDocumentation() {
    const doc = `You may specify a binding site in the "Advanced Parameters" section of the simulation setup. The binding site is defined as a radius from a center.
You can provide the center directly or provide a set of atoms to calculate a centroid.
We typically recommend 15 for the radius (Ångstrom).

Format: /!! <radius> center <x> <y> <z>!!/ OR /!!<radius> <residue 1> ...!!/

Examples:
/!! 15 center 2.3 1.7 -8.2 !!/
/!! 15 GLY.23:A !!/
/!! 18.5 GLY.23:A SER.32:A !!/
/!! 12.5 GLU.18:B ALA.40:B TYR.60:B !!/

You can optionally provide atom names to be specific about which atoms to include in the centroid calculation:
/!!<radius> <residue 1> <atom name 1> ... !!/
This is recommended if the selected amino acid residues have different sizes.

Examples:
/!! 18.5 GLY.23:A CA SER.32:A N !!/
/!! 12.5 GLU.18:B CA ALA.40:B CA TYR.60:B OH !!/

`;
    return prepDoc(doc);
}

function prepDoc(docIn) {
    const doc = docIn.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n\n/g, '</p><p>').replace(/\n/g, '<br>')
        .replace(/\/!!/g, '<code>')
        .replace(/!!\//g, '</code>');
    return `<p>${doc}</p>`;
}
