import CoralMap from '@Conifer-Point/interactive-kinase-map';
import { useState } from 'react';
import { EnergySlider } from 'BMapsUI/UIComponents';
import BMapsTooltip from 'BMapsSrc/ui/BMapsTooltip';

export function CoralComponent({ coralMapInfoEntries }) {
    const activityName = 'Binding score';
    const defaultThreshold = -9;
    const [activityThreshold, setActivityThreshold] = useState(defaultThreshold);
    const styles = {};
    let lowestScore = Number.MAX_SAFE_INTEGER;
    let highestScore = Number.MIN_SAFE_INTEGER;
    for (const entry of coralMapInfoEntries) {
        const {
            targetInfo, mapCase, kinaseId, bindingScore, fragInfo,
        } = entry;
        const color = targetInfo.isTarget ? 'blue' : 'red';
        const targetLabel = targetInfo.isTarget ? 'target' : 'off-target';
        const structureLabel = `${targetLabel} ${kinaseId} (${mapCase.case})`;
        const isActive = bindingScore != null && bindingScore < activityThreshold;
        let tooltip = `${fragInfo.name} against ${structureLabel}: `;
        if (bindingScore != null) {
            tooltip += `${isActive ? 'Active' : 'Non-active'} (${bindingScore.toFixed(2)} < ${activityThreshold})`;
            lowestScore = Math.min(lowestScore, bindingScore);
            highestScore = Math.max(highestScore, bindingScore);
        } else {
            tooltip += 'No fragments found';
        }

        styles[kinaseId] = {
            branchColor: isActive ? color : 'gray',
            nodeColor: isActive ? color : 'gray',
            textColor: 'white',
            nodeSize: 20,
            textSize: '12px',
            tooltip,
        };
    }

    // Min is the lowest integer below the lowest score, making it possible to "deactivate" all
    const sliderMin = Math.min(Math.floor(lowestScore-0.1), defaultThreshold);

    return (
        <BMapsTooltip
            noMaxWidth
            title={(
                <div style={{ background: 'white', padding: '1rem', color: 'black' }}>
                    <div style={{ height: '30rem', width: '30rem' }}>
                        <CoralMap
                            TooltipComponent={CoralTooltip}
                            styles={{ ...styles, branchColor: 'lightgray', label: { textColor: 'black' } }}
                        />
                    </div>
                    <p>
                        {activityName}
                        {' range: '}
                        {highestScore.toFixed(1)}
                        {' '}
                        &mdash;
                        {' '}
                        {lowestScore.toFixed(1)}
                    </p>
                    <p>
                        {activityName}
                        {' threshold: '}
                        {activityThreshold.toFixed(1)}
                    </p>
                    <EnergySlider
                        onChange={setActivityThreshold}
                        value={activityThreshold}
                        min={sliderMin}
                        max={0}
                        step={0.5}
                    />
                    <CoralLegend />
                </div>
            )}
            arrow
            placement="right"
        >
            <div style={{ height: '5rem', width: '5rem' }}>
                <CoralMap staticMap styles={{ ...styles, branchColor: 'lightgray' }} />
            </div>
        </BMapsTooltip>
    );
}

const CoralTooltip = (props) => (
    <BMapsTooltip placement="top" followCursor {...props} />
);

const CoralLegend = () => (
    <div style={{ display: 'flex', paddingTop: '0.5em' }}>
        {/* 0.6875rem is the height used for mui tooltip text. */}
        {/* Target active */}
        <span style={{
            width: '0.6875rem',
            height: '0.6875rem',
            background: 'blue',
            borderRadius: '.5rem',
            marginRight: '.5rem',
            marginLeft: '1rem',
        }}
        />
        <p>On-target</p>
        {/* Off-target active */}
        <span style={{
            width: '0.6875rem',
            height: '0.6875rem',
            background: 'red',
            borderRadius: '.5rem',
            marginRight: '.5rem',
            marginLeft: '1rem',
        }}
        />
        <p>Off-target</p>
        {/* Inactive */}
        <span style={{
            width: '0.6875rem',
            height: '0.6875rem',
            background: 'gray',
            borderRadius: '.5rem',
            marginRight: '.5rem',
            marginLeft: '1rem',
        }}
        />
        <p>Weak score or no data</p>
    </div>
);
