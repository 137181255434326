// visualizations/pipi_visualizations.js
/**
 * @fileoverview Functions to display pi-pi interactions in the canvas.
 * @typedef {import('BMapsModel').BindingSite} BindingSite
 * @typedef {import('BMapsSrc/interactions/pipi').PiPiStack} PiPiStack
 * @typedef {import('BMapsSrc/interactions/pipi').PiPiParams} PiPiParams
 * @typedef {{
*     bindingSite: BindingSite, soluteAtoms: Atom[], compoundAtoms: Atom[], waterAtoms: Atom[],
* }} BindingSiteContext
*
* Public interface:
*     displayPiPiStacking(show, bindingSiteContext, params)
*
* Internal storage:
*     piPiStackingLines: Line[]
*
* This function takes a binding site context, calculates pi-pi interactions, and displays them.
*/

import { findPiPiStacks, DefaultPiPiParams } from 'BMapsSrc/interactions';
import { drawCylinder, redisplay, removeShape } from 'BMapsSrc/MainCanvas';
import { UserActions } from 'BMapsSrc/cmds/UserCmd';
import { Loader } from 'BMapsSrc/Loader';

/**
 * Keep track of pi-pi interaction lines drawn on the canvas
 */
const piPiStackingLines = [];

/**
 * Calculate pi-pi interactions and display their visualizations
 * @param {boolean} show
 * @param {BindingSiteContext} bindingSiteContext
 * @param {PiPiParams} params
 */
export function displayPiPiStacking(show, bindingSiteContext, params=DefaultPiPiParams) {
    removePiPiStacking();
    if (show && bindingSiteContext) {
        const { compounds, residues } = bindingSiteContext;
        const stacks = findPiPiStacks({ atomGroups1: compounds, atomGroups2: residues, params });
        console.log(`There are ${stacks.length} pi-pi interactions.`);

        // Pi-Pi interactions are drawn twice, since the energies are received asynchronously.
        drawPiPiStacking(stacks);
        if (Loader.AllowLabFeatures) {
            redrawPiPiStacksWithEnergies(stacks);
        }
    }
}

/**
 * Add visualizations for pi-pi interactions to the 3D canvas
 * @param {PiPiStack[]} piPiStacks
 */
function drawPiPiStacking(piPiStacks) {
    for (const piPiStack of piPiStacks) {
        const { ring1, ring2 } = piPiStack;
        const [x1, y1, z1] = ring1.centroid;
        const [x2, y2, z2] = ring2.centroid;

        const cyl = drawCylinder(
            { x: x1, y: y1, z: z1 },
            { x: x2, y: y2, z: z2 },
            0.15,
            'violet',
            true, // dashed
            { description: getPiPiTooltipInfo(piPiStack) }
        );
        piPiStackingLines.push(cyl);
    }
}

/**
 * @param {PiPiStack[]} stacks
 */
function redrawPiPiStacksWithEnergies(stacks) {
    const gifeRequests = stacks.map(async (stack) => {
        try {
            const request = await UserActions.GetGifeEnergies({
                atoms: [...stack.ring1.atoms, ...stack.ring2.atoms],
            });
            if (request.error) throw new Error(request.error);
            if (typeof request.results[0].value !== 'number') {
                throw new Error('Invalid GIFE energies');
            }
            return ({ stack, GiFEenergy: request.results[0].value });
        } catch (error) {
            console.warn(`Error getting GIFE energies for PiPiStack, using default PiPiStack instead. ${error}`);
            return ({ stack, error });
        }
    });

    Promise.all(gifeRequests).then((resolvedRequests) => {
        const newStacks = resolvedRequests
            .map((request) => ({
                ...request.stack,
                GiFEenergy: request.GiFEenergy,
            }));
        if (newStacks.every((stack) => stack.GiFEenergy === undefined)) {
            console.warn('Failed to get GIFE energies for pi-pi interactions.');
            return;
        }
        removePiPiStacking();
        drawPiPiStacking(newStacks);
        redisplay();
    });
}

/**
 * Remove all pi-pi interactions from the 3D canvas
 */
function removePiPiStacking() {
    for (const line of piPiStackingLines) {
        removeShape(line);
    }
    piPiStackingLines.length = 0;
}

/**
 * Prepare table for pi-pi interaction tooltip
 * @param {PiPiStack} pipiStack
 * @returns
 */
function getPiPiTooltipInfo(pipiStack) {
    const {
        ring1, ring2, distance, planeAngle, GiFEenergy,
    } = pipiStack;
    const tooltipInfo = {
        type: 'table',
        title: 'Pi-Pi Stack',
        body: [
            ['Residues', `${ring1.resSpec} - ${ring2.resSpec}`],
            ['Distance', `${distance.toFixed(2)}Å`],
            ['Plane Angle', `${planeAngle.toFixed(2)}°`],
        ],
    };
    if (GiFEenergy && typeof GiFEenergy === 'number') {
        tooltipInfo.body.push(['Energy (GiFE)', `${GiFEenergy.toFixed(2)} kcal/mol`]);
    }
    return tooltipInfo;
}
