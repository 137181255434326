import { Loader } from '../Loader';
import { WebServices } from '../WebServices';
import { removeAtomMappingFromMolText } from './mol_format_utils';

/**
 * Calculate an svg for a molecule.
 * Default to RDKit if available, fallover to Indigo webservice.
 *
 * @param {{ source: string?, molData: string, format: string, params: object }}
 * @returns {Promise<string>} Valid svg data or "failed"
 */
export async function svgForMol({ source='rdkit', ...args }) {
    let svg = 'failed';
    switch (source) {
        case 'rdkit':
            svg = await svgForMolRDKit(args);
            break;
        default:
            svg = await svgForMolIndigoWS(args);
    }

    // Fallback to indigo webservice
    if (svg === 'failed') {
        svg = await svgForMolIndigoWS(args);
    }
    return svg;
}

// RDKit functionality
async function svgForMolRDKit({ molData: molDataIn, format, params }) {
    const molData = prepareMolData(molDataIn, format);
    let svg = 'failed';

    if (Loader.RDKitExport) {
        const jsMol = Loader.RDKitExport.getCleanMol(molData);
        // Check smiles to ensure RDKit was able to load the molecule
        if (jsMol.get_smiles()) {
            svg = jsMol.get_svg();
        }
        jsMol.delete();
    }

    return svg;
}

// WebService functionality
async function svgForMolIndigoWS({ molData: molDataIn, format, params }) {
    const {
        baseBondColor, height, width, ...rest
    } = params || {};
    const molData = prepareMolData(molDataIn, format);
    let svg;
    try {
        svg = await WebServices.mol2svg(molData, format, baseBondColor, width, height, rest);
    } catch {
        try {
            await WebServices.refreshCookie();
            svg = await WebServices.mol2svg(molData, format, baseBondColor, width, height, rest);
        } catch {
            svg = 'failed';
        }
    }
    return svg;
}

function prepareMolData(molData, format, { removeMappings=true }={}) {
    if (removeMappings && ['mol', 'sdf'].includes(format)) {
        return removeAtomMappingFromMolText(molData);
    }

    return molData;
}
