import {
    Avg3, Centroid, Cross3, Dot3, Normalize3, Sub3_3, Mul3_1,
} from '../math';

/**
 *
 * @param {Atom|[number, number, number]} pt1 atom or [x, y, z] position array
 * @param {Atom|[number, number, number]} pt2 atom or [x, y, z] position array
 * @returns {number}
 */
export function pointDistance(pt1, pt2) {
    return Math.sqrt(pointDistanceSquared(pt1, pt2));
}

/**
 *
 * @param {Atom|[number, number, number]} pt1 atom or [x, y, z] position array
 * @param {Atom|[number, number, number]} pt2 atom or [x, y, z] position array
 * @returns {number}
 */
export function pointDistanceSquared(pt1, pt2) {
    const [x1, y1, z1] = getPositionArray(pt1);
    const [x2, y2, z2] = getPositionArray(pt2);
    const dx = x2 - x1;
    const dy = y2 - y1;
    const dz = z2 - z1;
    return dx*dx + dy*dy + dz*dz;
}

/**
 * @param {[Atom|[number, number, number]]} points array of atoms or [x, y, z] position arrays
 * @returns {[number, number, number]} centroid of the points
 */
export function pointsCentroid(points) {
    return Centroid(points.map(getPositionArray));
}

/**
 * Calculate a normal vector for a ring.
 * Choose three points, and get the cross product of the vectors between them.
 * @param {[number, number, number][]} ringPoints
 * @returns {[number, number, number]} Normal vector for the ring
 */
export function ringNormalVector(ringPoints) {
    let pointsToUse;
    switch (ringPoints.length) {
        case 3:
            pointsToUse = [ringPoints[0], ringPoints[1], ringPoints[2]];
            break;
        case 4:
            pointsToUse = [ringPoints[0], ringPoints[2], ringPoints[3]];
            break;
        default:
            pointsToUse = [ringPoints[0], ringPoints[2], ringPoints[4]];
    }
    const [p1, p2, p3] = pointsToUse.map(getPositionArray);
    const v1 = Sub3_3(p2, p1);
    const v2 = Sub3_3(p3, p2);
    const normal = Cross3(v1, v2);
    return Normalize3(normal);
}

/**
 * Determines if the provided atoms lie in a plane.
 * @param {Array<Atom|[number, number, number]>} points - Array of atoms or [x,y,z] position arrays
 * @param {{ tolerance?: number }} params - Optional parameters
 * @returns {boolean} - True if atoms lie in a plane, false otherwise.
 * Thank you Copilot.
 */
export function isPlanar(points, params={}) {
    if (points.length < 4) return true; // Three points always define a plane
    const { tolerance=0.05, debug=false } = params;

    // Calculate centroid of the atoms
    const centroid = pointsCentroid(points);

    // Calculate normal vector using the first three atoms
    const normal = ringNormalVector(points.slice(0, 3));

    // For each additional atom, calculate the dot product for the vector pointing to the centroid
    for (let i = 3; i < points.length; i++) {
        const atomPos = getPositionArray(points[i]);
        const vectorToCentroid = Sub3_3(atomPos, centroid);
        const dotProduct = Dot3(normal, vectorToCentroid);

        // Dot product should be close to 0 (perpendicular)
        if (Math.abs(dotProduct) > tolerance) {
            if (debug) console.log(`isPlanar: atom ${i} does not lie in the plane: ${dotProduct.toFixed(4)}`);
            return false;
        }
    }

    return true; // All atoms lie in the plane
}

/**
 * A ring normal vector could stick out of either side of the ring.
 * This function returns the normal vector that is on the side of the point of interest.
 * @param {[number, number, number]} centroid
 * @param {[number, number, number]} normal
 * @param {Atom|[number, number, number]} pointOfInterest
 * @returns {[number, number, number]} Normal vector on the side of the point of interest
 */
export function ensureNormalToward(centroid, normal, pointOfInterest) {
    const poiPos = getPositionArray(pointOfInterest);
    const poiVec = Sub3_3(poiPos, centroid);
    const dot = Dot3(normal, poiVec);
    // Positive dot product means the angle between the vectors is acute,
    // so the normal is on the correct side.
    return (dot >= 0) ? normal : Normalize3(Mul3_1(normal, -1));
}

// Calculate atoms within a distance from a set of reference atoms, with options:
// Options:
//      roundup: if true, rounds up by residue
//      filter:  a filter function (atom=>true/false) to reduce results.
export function getAtomsNearAtoms(refs, candidateAtoms, distance, options) {
    options.boundingBox = getBoundingBox(refs, distance);
    return atomsInRange(refs, candidateAtoms, distance, options);
}

export function isPositionArray(arr) {
    return Array.isArray(arr) && arr.length === 3
        && typeof arr[0] === 'number' && typeof arr[1] === 'number' && typeof arr[2] === 'number';
}

export function likePositionObject(obj) {
    return obj && obj.x != null && obj.y != null && obj.z != null;
}

/**
 * Return [x,y,z] array from from either an Atom's position or another position array.
 * @param {Atom|[number, number, number]} atomOrArray atom or [x, y, z] position array
 */
export function getPositionArray(atomOrArray, atomPositionOptions) {
    if (isPositionArray(atomOrArray)) return atomOrArray;
    if (atomOrArray.getPosition != null) return atomOrArray.getPosition(atomPositionOptions);
    if (likePositionObject(atomOrArray)) {
        return [atomOrArray.x, atomOrArray.y, atomOrArray.z];
    }
    throw new Error(`getPositionArray: can't get position from ${atomOrArray}`);
}

/**
 * Calculate a simple bounding box around reference atoms. Hopefully this reduces computation
 * somewhat.
 * @param {Array<Atom|[number, number, number]>} refItems
 * @param {number} distance
 * @param {*} atomPositionOptions
 * @returns {
 *     { lowX:number, highX:number, lowY:number, highY:number, lowZ:number, highZ:number }
 * } Bounding box
 */
export function getBoundingBox(refItems, distance=0, atomPositionOptions) {
    let highX = Number.MIN_SAFE_INTEGER;
    let highY = Number.MIN_SAFE_INTEGER;
    let highZ = Number.MIN_SAFE_INTEGER;
    let lowX = Number.MAX_SAFE_INTEGER;
    let lowY = Number.MAX_SAFE_INTEGER;
    let lowZ= Number.MAX_SAFE_INTEGER;

    for (const ref of refItems) {
        const [x, y, z] = getPositionArray(ref, atomPositionOptions);
        if (x > highX) highX = x;
        if (x < lowX) lowX = x;
        if (y > highY) highY = y;
        if (y < lowY) lowY = y;
        if (z > highZ) highZ = z;
        if (z < lowZ) lowZ = z;
    }

    return {
        highX: highX + distance,
        highY: highY + distance,
        highZ: highZ + distance,
        lowX: lowX - distance,
        lowY: lowY - distance,
        lowZ: lowZ - distance,
    };
}

export function getGyrationRadius(atcoords) {
    if (atcoords.length < 2) return 0;
    const geoctr = Avg3(atcoords);
    let sumsq = 0;
    for (const pos of atcoords) {
        const dr = Sub3_3(pos, geoctr); // this can be avoided by factoring the expression
        const rsq = Dot3(dr, dr);
        sumsq += rsq;
    }
    return Math.sqrt(sumsq/atcoords.length);
}

/**
 * Return a filter function that is used by atomsInRange and anyAtomsInRange
 * @param {Array<Atom|[number, number, number]>} refs atoms or [x,y,z] position arrays
 * @param {number} distance
 * @param {{
 *     filter: function(Atom): boolean,
 *     boundingBox: {lowX:number,highX:number,lowY:number,highY:number,lowZ:number,highZ:number},
 *     roundup: boolean,
 * }} options
 * @returns {function(Atom): boolean} A filter function
 */
function getNearbyFilterFn(refs, distance, options) {
    const distanceSquared = distance*distance;

    const nearAnAtom = function nearAnAtom(atom) {
        // Note: this is not using some because displayComponents in display_mgr is passing a Set
        // Be sure to address that before converting this to Array.some.
        for (const ref of refs) {
            if (pointDistanceSquared(atom, ref) < distanceSquared) {
                return true;
            }
        }
        return false;
    };

    return (atom) => (
        // Do these in order of cost.
        (options.filter ? options.filter(atom) : true) // an atom type lookup or element test?
        && (options.boundingBox ? pointInBox(atom, options.boundingBox) : true)
        && nearAnAtom(atom)
    );
}

/**
 * Filter a list of candidate atoms by whether they are within a specified distance of the refAtoms.
 * To further restrict output or to avoid distance calculation, two filtering options are allowed:
 *   - filter: an extra filter function to run on the input (eg element name)
 *   - boundingBox: an object with the bounds of the acceptable range
 * A successful check requires passing all 3 of the filter, boundingBox check, and distance check.
 *
 * An additional option allows specifying whether to round up by residue after the filtering.
 * @param {Array<Atom|[number, number, number]>} refs atoms or [x,y,z] position arrays
 * @param {Atom[]} candidateAtoms
 * @param {number} distance
 * @param {{
 *     filter: function(Atom): boolean,
 *     boundingBox: {lowX:number,highX:number,lowY:number,highY:number,lowZ:number,highZ:number},
 *     roundup: boolean,
 * }} options
 * @returns {Atom[]} Array of atoms that passed the filter
 */
export function atomsInRange(refs, candidateAtoms, distance, options) {
    let nearbyAtoms = candidateAtoms.filter(getNearbyFilterFn(refs, distance, options));

    if (options.roundup) nearbyAtoms = roundupAtoms(nearbyAtoms);

    return nearbyAtoms;
}

/**
 * Return whether any of the candidate atoms are within the specified distance of the refAtoms.
 * To further restrict output or to avoid distance calculation, two filtering options are allowed:
 *   - filter: an extra filter function to run on the input (eg element name)
 *   - boundingBox: an object with the bounds of the acceptable range
 * A successful check requires passing all 3 of the filter, boundingBox check, and distance check.
 * @param {Array<Atom|[number, number, number]>} refs atoms or [x,y,z] position arrays
 * @param {Atom[]} candidateAtoms
 * @param {number} distance
 * @param {{
 *     filter: function(Atom): boolean,
 *     roundup: boolean,
 *     boundingBox: {lowX:number,highX:number,lowY:number,highY:number,lowZ:number,highZ:number}
 * }} options
 * @returns {boolean}
 */
export function anyAtomInRange(refs, candidateAtoms, distance, options) {
    return candidateAtoms.some(getNearbyFilterFn(refs, distance, options));
}

/**
 *
 * @param {Atom|[number, number, number]} point atom or [x,y,z] position array
 * @param {*} boundingBox
 * @returns
 */
export function pointInBox(point, boundingBox) {
    const [x, y, z] = getPositionArray(point);
    return (x >= boundingBox.lowX && x <= boundingBox.highX
            && y >= boundingBox.lowY && y <= boundingBox.highY
            && z >= boundingBox.lowZ && z <= boundingBox.highZ);
}

export function roundupAtoms(originalAtoms) {
    const expandedAtoms = new Set(originalAtoms);

    // Make sure each atom's cohort (either fragment or residue) is included
    for (const atom of originalAtoms) {
        let cohortAtoms = [];
        if (atom.residue) cohortAtoms = atom.residue.atoms;
        else if (atom.fragment) cohortAtoms = atom.fragment.atoms;

        for (const catom of cohortAtoms) {
            expandedAtoms.add(catom);
        }
    }

    return [...expandedAtoms];
}

/**
 * Filter a list of atoms according to a bounding box, optionally rounding up by residue.
 * @param {Atom[]} atoms The list of atoms to filter
 * @param {{
 *     lowX: number, highX: number, lowY: number, highY: number, lowZ: number, highZ: number
 * }} boundingBox
 * @param {boolean} roundup Whether or not to round up the results
 * @returns {Atom[]} An array of filtered atoms
 */
export function atomsInBox(atoms, boundingBox, roundup=false) {
    const filtered = atoms.filter((atom) => pointInBox(atom, boundingBox));
    const result = roundup ? roundupAtoms(filtered) : filtered;
    return result;
}

/**
 * Return a boolean of whether any of the points are in the bounding box
 * @param {Array<Atom|[number, number, number]>} points atoms or [x,y,z] position arrays
 * @param {{
 *     lowX: number, highX: number, lowY: number, highY: number, lowZ: number, highZ: number
 * }} boundingBox
 * @returns {boolean} Whether any of the points / atoms are in the bounding box
 */
export function anyPointInBox(points, boundingBox) {
    const ret = points.some((point) => pointInBox(point, boundingBox));
    return ret;
}
