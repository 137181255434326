import { makeCsvString } from 'BMapsUtil/mol_format_utils';
import { AnnotatedSearchResult } from './AnnotatedSearchResult';
import { getColumnInfo, getValue } from './column_definitions';

/**
 * @param {AnnotatedSearchResult[]} sortedSuggestions
 * @param {string[]} columns
 * @returns {string}
 */
export function getCsvData(sortedSuggestions, columns) {
    const suggestionsData = sortedSuggestions.map((s) => getSuggestionRowData(s, columns));
    const suggestionsHeader = getSuggestionsHeader(columns);
    const dataRows = suggestionsData.map((obj) => Object.values(obj));
    return makeCsvString(dataRows, { header: suggestionsHeader });
}

/**
 *
 * @param {AnnotatedSearchResult} suggestion
 * @param {string[]} columns
 */
function getSuggestionRowData(suggestion, columns) {
    const suggestionRowData = {};

    for (const col of columns) {
        suggestionRowData[col] = getCsvValue(suggestion, col);
    }

    return suggestionRowData;
}

/**
 * @param {string[]} columns
 */
function getSuggestionsHeader(columns) {
    const suggestionsHeader = columns.map(getHeader);
    return suggestionsHeader;
}

// Helpers

function getHeader(col) {
    const colInfo = getColumnInfo(col);
    if (!colInfo) return '';
    const { column, projectCase, properties: { csv } } = colInfo;

    let header = csv?.header || '';
    if (projectCase) {
        header += ` (${projectCase})`;
    }
    return header;
}

function getCsvValue(suggestion, col) {
    const val = getValue(suggestion, col);
    switch (true) {
        case val == null:
            return '';
        case (typeof val === 'object'):
            return JSON.stringify(val);
        default:
            return val;
    }
}
