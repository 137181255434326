// visualizations/hbond_visualizations.js
/**
 * @fileoverview Functions to display hydrogen bonds in the canvas.
 * @typedef {import('BMapsModel').BindingSite} BindingSite
 * @typedef {import('BMapsModel').Hotspot} Hotspot
 * @typedef {{
 *     bindingSite: BindingSite, soluteAtoms: Atom[], compoundAtoms: Atom[], waterAtoms: Atom[],
 * }} BindingSiteContext
 *
 * Public interface:
 *     displayHBondsForBindingSite(show, includeWaters, includeWeak, bindingSiteContext, params)
 *     showHBondsForHotspot(show, hotspot)
 *     removeHBondsForBindingSite()
 *     removeHbondsForHotspots()
 *
 * Internal storage:
 *     hbondLines: { bindingSite: Array of hbonds, hotspots: Map<Hotspot, Array of hotspot hbonds> }
 *
 * This differentiates between hbonds in a binding site and hbonds for specific hotspots (disabled).
 *
 * This functions receive a context (binding site or hotspot),
 * determines the hbonds for the context,
 * and displays them.
 */
import { findHbonds, defaultHBondParams } from 'BMapsSrc/interactions';
import { drawCylinder, removeCylinder, atomIsVisible } from 'BMapsSrc/MainCanvas';
import { makeTooltipLegend } from 'BMapsSrc/ui/TooltipLegend';
import { pointDistance } from 'BMapsSrc/util/atom_distance_utils';
import { simpleCond } from 'BMapsSrc/util/js_utils';
import { getFullResidueId } from 'BMapsSrc/util/mol_info_utils';

/**
 * Keep track of drawn HBonds, either for the binding site or for specific hotspots.
 * @type {{ bindingSite: Array, hotspots: Map<Hotspot, Array> }}
 */
const hbondLines = { bindingSite: [], hotspots: new Map() };

/**
 * Display, hide, or redisplay hydrogen bonds in a binding site,
 * either for a compound or computed waters.
 *
 * @param {boolean} show
 * @param {boolean} includeWaters
 * @param {boolean} includeWeak
 * @param {BindingSiteContext} bindingSiteContext
 * @param {HBondParams} params
 */
export function displayHBondsForBindingSite(
    show, includeWaters, includeWeak, bindingSiteContext, params=defaultHBondParams
) {
    // remove existing ones first
    removeHBonds(hbondLines.bindingSite);

    if (show && bindingSiteContext) {
        const {
            bindingSite, soluteAtoms, compoundAtoms, waterAtoms,
        } = bindingSiteContext;

        // Display waters or compound, but not both (gets too busy).
        const targetAtoms = includeWaters ? waterAtoms : compoundAtoms;
        const otherAtoms = soluteAtoms;
        const hbonds = findHbonds(targetAtoms, otherAtoms, params);
        console.log(`There are ${hbonds.length} ${includeWaters ? 'water' : 'compound'} atoms with ${hbonds.map((x) => x[1].length)} hbonds for radius ${bindingSite.radius.toFixed(2)}.`);
        drawHBonds(hbonds, hbondLines.bindingSite, includeWeak);
    }
}

export function showHBondsForHotspot(show, hotspot) {
    let list = hbondLines.hotspots.get(hotspot);

    if (show) {
        const hbonds = findHbonds(hotspot.atoms, hotspot.solute);
        if (!list) {
            list = [];
            hbondLines.hotspots.set(hotspot, list);
            drawHBonds(hbonds, list);
        }
    } else {
        if (list) {
            removeHBonds(list);
        }
    }
}

export function removeHBondsForBindingSite() {
    removeHBonds(hbondLines.bindingSite);
}

export function removeHbondsForHotspots() {
    hbondLines.hotspots.forEach((list) => removeHBonds(list));
}

// Internal functions

function drawHBonds(hbonds, list, includeWeak) {
    for (const hb of hbonds) {
        const [atom, partners] = hb;
        if (!atomIsVisible(atom)) {
            // console.log(`atom ${atom.atom} has no style.`);
            continue; // don't display if hidden
        }
        for (const [partner, energy] of partners) {
            // Only draw a highlight if the partner atom is visible.
            if (atomIsVisible(partner) // not hidden
                  && ((includeWeak && energy < defaultHBondParams.threshold) // cutoff at 2kT?
                   || energy < defaultHBondParams.moderateThreshold)) {
                list.push(drawHBond(atom, partner, energy));
            }
        }
    }
}

function drawHBond(atom1, atom2, energy) {
    // There is some debate as to whether it is more intuitive for users to draw a
    // highlight from the donor or donor hydrogen to the acceptor.  It seems that the
    // latter is preferred, although the former gives more information about the relative
    // orientation of the dipoles (the ideal from a physics perspective is to highlight
    // the mid-point of each dipole).
    const donorHydrogen = atom1.elem === 'H' ? atom1 : atom2;
    const acceptor = donorHydrogen === atom1 ? atom2 : atom1;
    const donor = donorHydrogen.bondedAtoms[0];
    const dist = pointDistance(donorHydrogen, acceptor); // dipole mid-points would be better...
    const p = defaultHBondParams;
    const color = simpleCond([
        [energy <= p.strongThreshold, p.strongColor],
        [energy <= p.moderateThreshold, p.moderateColor],
        [true, p.weakColor],
    ]);
    const cyl = drawCylinder(
        donorHydrogen.getPosition({ as: 'object' }),
        acceptor.getPosition({ as: 'object' }),
        0.15, color,
        true, // dashed
        { description: hbondTooltipInfo(donor, acceptor, dist, energy, p) },
    );
    return cyl;
}

function hbondTooltipInfo(donor, acceptor, dist, energy, params) {
    const {
        strongThreshold, strongColor,
        moderateThreshold, moderateColor,
        threshold, weakColor,
    } = params;
    return {
        type: 'table',
        title: 'Hydrogen Bond',
        body: [
            ['Atoms', `${getFullResidueId(donor)} ${donor.atom} - ${getFullResidueId(acceptor)} ${acceptor.atom}`],
            ['Distance', `${dist.toFixed(2)}Å`],
            ['Energy', `${energy.toFixed(2)} kcal/mol`],
        ],
        extra: makeTooltipLegend({
            label: 'H-bond color range (press \'W\' to see weak h-bonds)',
            ranges: [{
                color: strongColor, high: strongThreshold, label: 'Strong',
            }, {
                color: moderateColor, low: strongThreshold, high: moderateThreshold,
            }, {
                color: weakColor, low: moderateThreshold, high: threshold, label: 'Weak',
            }],
        }),
    };
}

function removeHBonds(lines) {
    for (const line of lines) {
        removeCylinder(line);
    }
    lines.splice(0, lines.length);
}
