/* visualizations/index.js
 *
 * This file exports functions for the canvas display of HBonds, water halos, ddGs halos,
 * hydrophobic interaction surfaces, etc.
 *
 * Each visualization is different in how it determines what to display.
 * Some are told exactly what to do (eg docking box) whereas others have to figure it out (hbonds).
 *
 * This also subscribes to events to handle display updates.
 */

import { EventBroker } from 'BMapsSrc/eventbroker';
import {
    removeHBondsForBindingSite,
} from './hbond_visualizations';
import { drawBondVectors, hideAllBondVectors } from './vector_visualizations';
import { removeFunctionalGroupHalos } from './ligand_halos';
import { removeWaterHalos } from './water_visualizations';
import { removeHotspots } from './hotspot_visualizations';
import { removeAllAtomGroupLabels } from './atom_group_labels';

export { displayHBondsForBindingSite } from './hbond_visualizations';
export { displayOneHotspot, cleanUpHotspots } from './hotspot_visualizations';
export { displayFunctionalGroupEnergyHalos, displayFunctionalGroupHighlight } from './ligand_halos';
export { displayWaterHalo, displayOneWaterHalo, waterChemPotential } from './water_visualizations';
export { hydrophobicitySurfaceColor, soluteSolvationSurfaceColor } from './protein_surface_highlights';
export { showDockingBox } from './docking_box';
export { drawAtomGroupLabel } from './atom_group_labels';

// Subscribe to events to implement display updates.
EventBroker.subscribe('zapAll', onZapAll);
EventBroker.subscribe('displayBondVectors', ondisplayBondVectors);

/// Event Handlers ///

function onZapAll() {
    removeHBondsForBindingSite();
    removeWaterHalos();
    removeFunctionalGroupHalos();
    removeHotspots();
    removeAllAtomGroupLabels();
}

function ondisplayBondVectors(_eventName, { vectors, options, hideAll }={}) {
    if (hideAll) {
        hideAllBondVectors();
    } else {
        drawBondVectors(vectors, options);
    }
    EventBroker.publish('redisplayRequest');
}
