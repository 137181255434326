import { App } from 'BMapsSrc/BMapsApp';
import Select from 'BMapsSrc/ui/common/Select';

export default function SelectActiveSoluteTab() {
    if (!App.Ready || !App.PrimaryDataConnection) {
        // For some reason, reloading a session in disconnected mode crashes here because
        // of undefined PrimaryDataConnection.
        console.warn('Can\'t render SelectActiveSoluteTab because no PrimaryDataConnection');
        return false;
    }

    const soluteNames = App.PrimaryDataConnection
        .getCaseDataCollection()
        .getAllCaseData()
        .map((caseData) => caseData.soluteName);

    const handlechange = (evt) => {
        App.PrimaryDataConnection.connector.cmdSetActiveSolute(evt.target.value);
    };

    return (
        <>
            <Select onChange={handlechange}>
                {
                    soluteNames.map((name) => (
                        <option
                            key={name}
                            value={name}
                        >
                            {name}
                        </option>
                    ))
                }
            </Select>
        </>
    );
}
