/**
 * Tour.js
 * This implements a tour for first time users.
 * It used jquery to give access to the whole DOM,
 * whether the items have been created with html, jquery,
 * or React.
 *
 * It has dependency on EventBroker; certain events are
 * handled for the tour to change the shape of a highlight.
 * It would be nice to factor out the dependency so the tour
 * could be totally independent.
 *
 * Additional stuff that could be cleaned up:
 * - The items in the TourSteps could be refactored into a class.
 * - The functions that handle the position of the tourBox deal with
 * a bunch of coordinates for different elements.  It would be nice to
 * clean that up, maybe deal with a consistent object for each element,
 * instead of a long list of variables.
 * - There could be generalized methods to handle actions when starting
 * and stopping a particular step.
 * Right now, at step start, the following occurs: (showTourItem)
 * 1) Event subscription
 * 2) actionOnFocus
 * 3) setup
 * On Step stop (finishItem)
 * 1) Event unsubscription
 * 2) actionOnBlur
 */
import { EventBroker } from '../eventbroker';
import { canvasHasFocus } from '../utils';
import { getRandomInt } from '../math';
import { simpleCond } from '../util/js_utils';
import { CookieManager } from '../CookieManager';

/**
 * @description class to maintain data for a tour
 */
export class Tour {
    constructor(steps) {
        this.steps = steps;
        this.tourStep = 0;
        // Handlers for BMaps EventBroker events, saved so we can unsubscribe
        this.bmapsHandlers = {};
        // Special arguments for the tour, passed into getHtml functions
        this.tourArgs = null;
    }

    static Init() {
        // <div id="tourBoxWrapper"
        // style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; z-index: 3999;"
        // ></div>

        $(document).on('keyup', (evt) => Tour.CurrentTour?.keypressHandler(evt));
        $(window).on('resize', () => Tour.CurrentTour?.refresh());
    }

    static Hide() {
        $('#tourBox').hide();
        $('#tourHighlight').hide();
    }

    start(args) {
        if (Tour.CurrentTour) Tour.CurrentTour.stop();
        Tour.CurrentTour = this;

        this.tourStep = 0;
        this.tourArgs = args;
        this.showStep(this.tourStep);
    }

    step(step=1) {
        this.finishStep(this.tourStep);
        this.tourStep += step;
        this.showStep(this.tourStep);
    }

    stop() {
        this.finishStep(this.tourStep);
        this.tourStep = 0;
        Tour.CurrentTour = null;
        Tour.Hide();
    }

    // Unsubscribe for items which have subscribed to events
    finishItem(item) {
        if (item) {
            if (item.events) {
                for (const evtName of Object.keys(item.events)) {
                    EventBroker.unsubscribe(evtName, this.bmapsHandlers[evtName]);
                }
            }
            if (item.actionOnBlur) {
                const selector = item.getEltSelector ? item.getEltSelector() : item.elt_selector;
                const elt = $(selector);
                item.actionOnBlur(elt);
            }
        }
    }

    // Account for going above or below the bounds of the tour array
    getItem(indexIn) {
        const steps = this.steps;
        const index = indexIn % steps.length;
        const item = steps[index >= 0 ? index : index+steps.length];
        return item;
    }

    finishStep(index) {
        const item = this.getItem(index);
        return this.finishItem(item);
    }

    /**
     * @description Display a step of the tour.
     * 1. Update test
     * 2. Update position of tour highlight and text box
     *    a. Update horizontal and vertical position
     *    b. Account for going off screen or overlapping the focused element
     * 3. Subscribe to bmaps events for that step if necessary
     */
    showItem(item) {
        const tourBox = $('#tourBox');
        tourBox.show();
        const windowWidth = $(window).width();

        // Update text
        $('#tourText').html(item.getHtml ? item.getHtml(this.tourArgs) : item.html);

        // Update control buttons
        $('#tourPrev').val('Back').show();
        $('#tourCancel').val('Skip Tour').show();
        $('#tourNext').val('Next').show();
        if (item.buttons) {
            const updateBtn = (setting, elt) => {
                if (setting != null) elt.val(setting);
                else if (setting === null) elt.hide();
                if (setting === null || setting === '') {
                    elt.attr('aria-hidden', true);
                }
            };
            updateBtn(item.buttons.prev, $('#tourPrev'));
            updateBtn(item.buttons.cancel, $('#tourCancel'));
            updateBtn(item.buttons.next, $('#tourNext'));
        }

        // Update position
        let focusedOnElt = false;
        const selector = item.getEltSelector ? item.getEltSelector() : item.elt_selector;

        if (selector) {
            const focusElt = $(selector);
            const overlapOk = item.overlapOk || (item.getOverlapOk && item.getOverlapOk());
            const preferredPosition = item.getPreferredPosition && item.getPreferredPosition();

            // Position tour box for the focus element, making adjustments if necessary.
            // ANNOYING WORKAROUND: call positioning function repeatedly until it doesn't change.
            // This is because for the RHS menu items, the tourbox width isn't correctly calculated
            // until the very end, causing the position calculation to be off.
            // If you want to look into this, logging indicates the width changes after this line:
            //     `tourBox.css('top', boxTop).css('left', boxLeft);` (in positionTourBoxForElt)
            let checkLeft;
            let checkTop;
            do {
                checkLeft = tourBox.css('left');
                checkTop = tourBox.css('top');
                focusedOnElt = positionTourBoxForElt(focusElt, overlapOk, preferredPosition);
            } while (checkLeft !== tourBox.css('left') || checkTop !== tourBox.css('top'));

            if (item.actionOnFocus) {
                item.actionOnFocus(focusElt);
            }
        }
        if (!focusedOnElt) { // General tour item without an element
            updateHandIndicators();
            positionHighlight(null);
            tourBox.css('left', item.left || (windowWidth / 2 - tourBox.outerWidth() / 2));
            tourBox.css('top', item.top || '10%');
            if (item.height) tourBox.height(item.height);
            if (item.width) tourBox.width(item.width);
        }

        // Subscribe to events.  Selector and Inspector will change size when
        // switching between tabs so we'll need to adjust highlight box.
        if (item.events) {
            for (const [evtName, handler] of Object.entries(item.events)) {
                this.bmapsHandlers[evtName] = (_, ...args) => handler(this, ...args);
                EventBroker.subscribe(evtName, this.bmapsHandlers[evtName]);
            }
        }

        if (item.setup) item.setup(this);

        // if (item.nonInteractive)
        //     $('#tourBoxWrapper').css('pointer-events', 'all');
        // else
        //     $('#tourBoxWrapper').css('pointer-events', 'none');
    }

    showStep(index) {
        const item = this.getItem(index);
        this.showItem(item);
    }

    refresh() {
        this.finishStep(this.tourStep);
        this.showStep(this.tourStep);
    }

    keypressHandler(evt) {
        if (!this.okToHandleKeys()) return;

        switch (evt.key) {
            case 'ArrowRight':
                this.step(1);
                break;
            case 'ArrowLeft':
                this.step(-1);
                break;
            case 'Escape':
                this.skipTour();
                break;
            // no default; ignore other keystrokes
        }
    }

    // Go to last step or close
    skipTour() {
        const last = this.steps.length-1;
        const current = this.getItem(this.tourStep);
        if (current !== this.steps[last]) {
            this.finishItem(current);
            this.tourStep = last;
            this.showStep(this.tourStep);
        } else {
            this.stop();
        }
    }

    // This should be passed in, not hard-coded
    okToHandleKeys() {
        // modalView = Guide, Import/Export, Sketcher, Dock, FragSim, SaveState
        const modalViewerOpen = $('.modalViewer:visible').length > 0;
        // popup = ligand_mod, context menu, but except atom tooltip
        const popupOpen = $('.canvasPopup:visible:not(.canvasTooltip)').length > 0;
        const mapSelectorOpen = isMapSelectorVisible(); // Candidate for MapSelector removal

        return !(modalViewerOpen || popupOpen || mapSelectorOpen);
    }
}

export function showTourOnStartup() {
    const cookieName = 'skiptour';
    return !CookieManager.exists(cookieName)
        || !CookieManager.get(cookieName);
}

/* RunTourWhenDialogsClose()
 * Open the Tour when all dialogs have closed.
 * The idea is that if the Guide/Welcome page or the Map Selector is visible at
 * startup, we want to show the tour once all the dialogs have closed, whether
 * or not the user chose a protein.  But we don't want to show the tour if they
 * switch from one dialog to another.
 *
 * Subscribe to relevant bmaps events.
 * When they occur, check to see if all dialogs are closed.
 *   If so, start the tour and unsubscribe
 *   If not, keep waiting until the user closes all dialogs.
 */
export function RunTourWhenDialogsClose(tour, args) {
    const evts = ['modalClosed', 'mapSelectorClosed', 'proteinLoaded'];
    // fn is the BMaps EventBroker event handler and will unsubscribe itself
    let fn;

    fn = (evtName) => { // eslint-disable-line prefer-const
        // setTimeout allows the DOM to update before the focus check
        setTimeout(() => {
            if (canvasHasFocus()) {
                tour.start(args);
                evts.forEach((e) => EventBroker.unsubscribe(e, fn));
            }
        }, 0);
    };

    evts.forEach((e) => EventBroker.subscribe(e, fn));
}

function storePreference(evt) {
    const skipTourCookieName = 'skiptour';
    const skipTour = evt.target.checked;
    if (skipTour) {
        CookieManager.set(skipTourCookieName, 'set', -1);
    } else {
        CookieManager.clear(skipTourCookieName);
    }
}

/**
 * @description Change the hand indicator to basic usage:
 * 1) No hand indicators (not focused on an element)
 * 2) Show a vertically-centered hand indicator to the left OR right
 * updateHandIndicators() can made additional changes, such as
 * pointing up or down, or changing the vertical alignment.
 * @param {*} eltOnLeft
 * @returns jQuery object for the active hand indicator DOM elt
 */
function resetHandIndicators(eltOnLeft) {
    // Reset to base
    $('.tourIndicatorLeft, .tourIndicatorRight').css('display', 'none').css('align-self', 'center');
    $('.tourIndicatorLeft i, .tourIndicatorRight i').removeAttr('class');
    $('.tourIndicatorLeft i').addClass('fa fa-hand-o-left');
    $('.tourIndicatorRight i').addClass('fa fa-hand-o-right');

    if (eltOnLeft == null) return null;

    // Change visibility only
    if (eltOnLeft) {
        $('.tourIndicatorLeft').css('display', 'unset');
        $('.tourIndicatorRight').css('display', 'none');
        return $('.tourIndicatorLeft');
    } else {
        $('.tourIndicatorLeft').css('display', 'none');
        $('.tourIndicatorRight').css('display', 'unset');
        return $('.tourIndicatorRight');
    }
}

/**
 * @description Update the display of the hand indicators, including adjusting
 * the alignment or direction of the hand, depending on how the tour box has
 * been adjusted.
 * Adjustments:
 * 1) uncoveringDown: the tourBox had been covering the focusElt and we moved
 * the tourBox down.  This means we need to point the hand UP.
 * 2) uncoveringUp: the tourBox had been covering the focusElt and we moved
 * the tourBox up.  This means we need to point the hand DOWN.
 * 3) shiftedUp|Down: the tourBox had gone off the bottom or top of the screen and
 * we moved it back onscreen, but enough that the hand is no longer pointing to the focus elt.
 * We need to change the alignment of the hand, but stay in the same direction.
 * @param {*} eltOnLeft
 * @param {*} adjustments
 */
function updateHandIndicators(eltOnLeft, preferredPosition, adjustments) {
    const activeIndicator = resetHandIndicators(eltOnLeft);
    if (!activeIndicator || !adjustments) return;

    if (adjustments.uncoveringDown || preferredPosition === 'below') {
        activeIndicator.children('i').removeAttr('class').addClass('fa fa-hand-o-up');
        activeIndicator.css('align-self', 'flex-start');
    } else if (adjustments.uncoveringUp || preferredPosition === 'above') {
        activeIndicator.children('i').removeAttr('class').addClass('fa fa-hand-o-down');
        activeIndicator.css('align-self', 'flex-end');
    } else if (adjustments.shiftedUp) {
        activeIndicator.css('align-self', 'flex-end');
    } else if (adjustments.shiftedDown) {
        activeIndicator.css('align-self', 'flex-start');
    }
}

/**
 * @description Position the tourbox relative to a focus element.
 * Steps:
 * 1) Collect necessary heights and widths
 * 2) Horizontal position (dealing with left or right offscreen)
 * 3) Vertical position (dealing with top or bottom offscreen)
 * 4) Reposition if overlapping the focus element
 * 5) Update the position!
 * @param {*} focusElt jquery object for the element that we are focused on
 * @param {bool} overlapEltOk Skip repositioning when overlapping the focusElt
 */
function positionTourBoxForElt(focusElt, overlapEltOk, preferredPositionIn) {
    if (!focusElt) return false;

    const tourBox = $('#tourBox');
    const windowWidth = $(window).width();
    const windowHeight = $(window).height();
    tourBox.height('unset');
    tourBox.width('unset');

    /** *** Collect necessary information **** */
    const {
        left: eltLeft, top: eltTop, width: eltWidth, height: eltHeight,
        right: eltRight, bottom: eltBottom,
    } = getFocusBox(focusElt, true);

    // eltOnLeft: is the focusElt to the left of the tourBox
    // Determined by: is the elt closer to the left of the window than the right side
    const eltOnLeft = eltLeft <= windowWidth - eltRight;
    const preferredPosition = preferredPositionIn || (eltOnLeft ? 'right' : 'left');
    // Calling resetIndicators() ensures the hand icon is in place and accounted
    // for in the size of the tourBox.
    resetHandIndicators(eltOnLeft);
    const boxWidth = tourBox.outerWidth();
    const boxHeight = tourBox.outerHeight();

    // Ok we're ready to do some calculation.  The goal is to get values
    // for boxLeft and boxTop, to position to the tourBox
    let boxLeft;
    let boxTop;
    // let boxAdjustments = {};
    let left;
    let top;
    let adjustments = {}; // Track adjustments to position of tourBox

    // Parentheses below allow destructuring assignment without the let declaration
    ({ left, top } = getPreferredPosition(
        {
            eltLeft, eltTop, eltRight, eltBottom, eltWidth, eltHeight,
        },
        boxWidth, boxHeight, eltOnLeft, preferredPosition,
    )
    );

    ({ left, top, adjustments } = ensureOnScreen(
        left, top, boxWidth, boxHeight, windowWidth, windowHeight, eltHeight,
    ));

    // boxAdjustments = { ...adjustments };

    // If the tourbox is overlapping the focus element,
    // move it above or below
    if (!overlapEltOk) {
        ({ left, top, adjustments } = ensureNotOverlapping(eltLeft, eltTop, eltRight, eltBottom,
            left, top, boxWidth, boxHeight, windowWidth, windowHeight, preferredPosition)
        );
    }
    // boxAdjustments = { ...adjustments };
    boxLeft = left; // eslint-disable-line prefer-const
    boxTop = top; // eslint-disable-line prefer-const

    // Ok, time to apply the new values
    tourBox.css('top', boxTop).css('left', boxLeft);
    positionHighlight(focusElt);
    updateHandIndicators(eltOnLeft, preferredPosition, adjustments);
    return true;
}

function getPreferredPosition({
    eltLeft, eltTop, eltRight, eltBottom, eltWidth, eltHeight,
},
boxWidth, boxHeight, eltOnLeft, preferredPosition='right') {
    let boxLeft;
    let boxTop;
    const defaultDistanceFromElt = 20;
    const handIndicatorWidth = eltOnLeft
        ? $('.tourIndicatorLeft').width()
        : $('.tourIndicatorRight').width();

    // We are trying to get the tourBox hand to point to the midpoint of the
    // near side of the focusElt
    if (preferredPosition === 'left' || preferredPosition === 'right') {
        // Horizontal position for left/right
        if (eltOnLeft) {
            boxLeft = eltRight + defaultDistanceFromElt;
        } else {
            boxLeft = eltLeft - boxWidth - defaultDistanceFromElt;
        }
        // Vertical position for left/right
        const eltMidH = eltTop + (eltHeight / 2);
        boxTop = eltMidH - (boxHeight / 2);
    } else if (preferredPosition === 'above' || preferredPosition === 'below') {
        // Horizontal position for above/below
        const eltMidW = eltLeft + (eltWidth / 2);
        if (eltOnLeft) {
            boxLeft = eltMidW - handIndicatorWidth;
        } else {
            boxLeft = eltMidW - boxWidth + handIndicatorWidth;
        }
        // Vertical position for above/below
        if (preferredPosition === 'above') {
            boxTop = eltTop - boxHeight - defaultDistanceFromElt;
        } else {
            boxTop = eltBottom + defaultDistanceFromElt;
        }
    }

    return { left: boxLeft, top: boxTop };
}

function ensureOnScreen(
    boxLeft, boxTopIn, boxWidth, boxHeight, windowWidth, windowHeight, eltHeight
) {
    const adjustments = {};
    let boxTop = boxTopIn;
    let left=boxLeft;
    let top=boxTop;
    // Reposition if offscreen
    if (boxLeft < 0) {
        left = 0;
        adjustments.shiftedRight = true;
    } else if (boxLeft + boxWidth > windowWidth) {
        left = windowWidth - boxWidth;
        adjustments.shiftedLeft = true;
    }

    // We may need to change the alignment of the hand indicator if after
    // repositioning it's no longer pointing to the focus elt.
    // This is the threshold for deciding if we've moved too far away.
    const toleranceForRepositioningHand = eltHeight / 2;

    // Handle if we've gone above the screen.
    if (boxTop < 0) {
        if (boxTop < -1 * toleranceForRepositioningHand) { adjustments.shiftedDown = true; }
        top = 0;
    }
    // Handle if we've gone below the screen
    if (boxTop + boxHeight > windowHeight) {
        if (boxTop + boxHeight - windowHeight
            > toleranceForRepositioningHand) {
            adjustments.shiftedUp = true;
        }
        boxTop = windowHeight - boxHeight;
    }
    return { left, top, adjustments };
}

function ensureNotOverlapping(eltLeft, eltTop, eltRight, eltBottom,
    boxLeft, boxTop, boxWidth, boxHeight, windowWidth, windowHeight, preferredPosition) {
    const left = boxLeft;
    let top = boxTop;
    let adjustments = {}; // eslint-disable-line prefer-const
    const boxRight = boxLeft + boxWidth;
    const boxBottom = boxTop + boxHeight;

    if (preferredPosition === 'left' || preferredPosition === 'right') {
        if ((eltLeft > boxLeft && eltLeft < boxRight)
             || (eltRight > boxLeft && eltRight < boxRight)) {
            const availableTop = eltTop - boxHeight;
            const availableBottom = windowHeight - (eltBottom + boxHeight);
            const useTop = (availableTop > 0 && availableBottom > 0)
                || availableBottom < 0;
            if (useTop) {
                adjustments.uncoveringUp = true;
                top = eltTop - boxHeight;
            } else {
                adjustments.uncoveringDown = true;
                top = eltBottom;
            }
        }
    } else {
        // NYI
    }

    return { left, top, adjustments };
}

/**
 * @description Return left,top,right,bottom,width,height of the focus area
 * @param {*} focusElt
 * @param {*} includeMargin
 */
function getFocusBox(focusElt, includeMargin=false) {
    // These will be changed as we iterate
    // eq() is jquery for "get the first jquery elt" (as opposed to DOM node)
    let offset = focusElt.eq(0).offset();
    let width = focusElt.eq(0).outerWidth(includeMargin);
    let height = focusElt.eq(0).outerHeight(includeMargin);

    // These are collecting the results
    let left = offset.left;
    let top = offset.top;
    let right = left + width;
    let bottom = top + height;

    for (let i = 1; i < focusElt.length; i++) {
        offset = focusElt.eq(i).offset();
        width = focusElt.eq(i).outerWidth(includeMargin);
        height = focusElt.eq(i).outerHeight(includeMargin);

        left = Math.min(left, offset.left);
        top = Math.min(top, offset.top);
        right = Math.max(right, offset.left + width);
        bottom = Math.max(bottom, offset.top + height);
    }

    return {
        left, top, right, bottom, width: right-left, height: bottom - top,
    };
}

/**
 * @description Position the highlight frame around a focus element
 * @param {*} focusElt
 */
function positionHighlight(focusElt) {
    const tourHighlight = $('#tourHighlight');

    if (focusElt) {
        const {
            left, top, width, height,
        } = getFocusBox(focusElt);
        tourHighlight.css('left', left);
        tourHighlight.css('top', top);
        tourHighlight.outerWidth(width);
        tourHighlight.outerHeight(height);
        tourHighlight.show();
    } else {
        tourHighlight.hide();
    }
}

export function getGreeting(greetings=['Hi', 'Hello'], includeTime=true) {
    if (includeTime) {
        // Add a time-based greeting
        const hours = new Date().getHours();

        const morningStart = 2;
        const afternoonStart = 12;
        const eveningStart = 18;

        const timeGreeting = simpleCond([
            [hours >= morningStart && hours < afternoonStart, 'Good morning'],
            [hours >= afternoonStart && hours < eveningStart, 'Good afternoon'],
            [true, 'Good evening'],
        ]);
        greetings.push(timeGreeting);
    }

    return greetings[getRandomInt(0, greetings.length)];
}

/* Candidate for MapSelector removal */
function getMapSelectorSel() { return '#mapSelectorGroup,.mapSelector_dropdown'; }

/* Candidate for MapSelector removal */
function isMapSelectorVisible() {
    const mapSelector = $(getMapSelectorSel());
    return mapSelector.is(':visible');
}

function isProteinSelected() {
    const proteinName = $('.proteinOpener .proteinName').text();
    return proteinName;
}

const TourSteps = [
    {
        name: 'welcome',
        elt_selector: null,
        getHtml: (args) => {
            let greeting = `${getGreeting()}, welcome to Boltzmann&nbsp;Maps! <br><br>`;
            if (args && args.dontGreet) {
                greeting = '';
            }

            const suggestTour = "Let's do a one-minute tour and show you around.";
            const staticWarning = (args && args.staticMode)
                ? '<br><br><span style="font-style: italic;">Note: You are currently able to view data for hundreds of structures, but you\'ll need to log in before you can add new molecules or perform new simulations.</span>' : '';
            return `${greeting}${suggestTour}${staticWarning}`;
        },
        setup: () => {
            if (isMapSelectorVisible()) {
                $('#tourBox').css('top', 12);
            }
        },
        events: {
            mapSelectorCmd(tour) {
                setTimeout(() => tour.refresh(), 0);
            },
            mapSelectorClosed(tour) {
                setTimeout(() => tour.refresh(), 0);
            },
        },
    },
    {
        name: 'protein',
        getEltSelector: () => (
            /* Candidate for MapSelector removal */
            isMapSelectorVisible() ? getMapSelectorSel() : '.proteinOpener .infoLabel'
        ),
        getHtml: () => {
            const intro = isProteinSelected()
                ? 'Click here to select a different protein.'
                : 'First, select a protein.';
            return `${intro}<br>Choose from among 500+ structures that have been prepared with fragment maps, water maps, and hotspot analysis.<br>
        You can also pull a protein structure from the PDB or upload your own.`;
        },
        // getOverlapOk: () => {
        //     return isMapSelectorVisible();
        // },
        events: {
            mapSelectorCmd(tour) {
                setTimeout(() => tour.refresh(), 0);
            },
            mapSelectorClosed(tour) {
                setTimeout(() => tour.refresh(), 0);
            },
        },
        getPreferredPosition: () => isMapSelectorVisible() && 'above',
    },
    {
        name: 'compounds',
        elt_selector: '.compoundControl',
        html: `Add compounds by pasting molecule descriptions or uploading files.
        You can also drag-and-drop compound files into the workspace any time, or use the 2D sketcher to draw compounds.`,
    },
    {
        name: 'selector',
        elt_selector: '.systemSelector',
        html: `Use the the Selector to view fragment maps, compounds, and structural components.<br>
        You can use the arrow keys on the keyboard to switch between compounds.`,
        events: {
            selectorTabChanged(tour) {
                setTimeout(() => tour.refresh(), 0);
            },
        },
        overlapOk: true,
    },
    {
        name: 'inspector',
        elt_selector: '#inspector',
        html: 'The structure of the active compound is shown here.<br><br>Use the energies and properties tables to spot opportunities for chemical improvement or to track the results of compound changes.',
        events: {
            viewStateChanged(tour) {
                setTimeout(() => tour.refresh(), 0);
            },
        },
        getPreferredPosition: () => 'above',
    },
    {
        name: 'views',
        elt_selector: '#views_style_group',
        html: 'Switch between zoomed-out protein and zoomed-in ligand views.',
        getPreferredPosition: () => 'below',
        actionOnFocus: (jq) => jq.trigger('click'),
        actionOnBlur: (jq) => jq.parents('.buttonDrawer').trigger('mouseleave'),
    },
    {
        name: 'compute',
        elt_selector: '#compute_style_group',
        html: 'Dock your compound, calculate energies, or simulate new fragment maps.',
        getPreferredPosition: () => 'above',
        actionOnFocus: (jq) => jq.trigger('click'),
        actionOnBlur: (jq) => jq.parents('.buttonDrawer').trigger('mouseleave'),
    },
    {
        name: 'editing',
        elt_selector: '#ligandediting_style_group',
        html: `Use the sketcher to draw or edit compounds. <br>
        You can also modify compounds from the 3D view by using terminal replacement or fragment growing.`,
        getPreferredPosition: () => 'above',
        actionOnFocus: (jq) => jq.trigger('click'),
        actionOnBlur: (jq) => jq.parents('.buttonDrawer').trigger('mouseleave'),
    },
    // {
    //     elt_selector: "#export_style",
    //     html: "Export your designs in a variety for formats or send to PubChem search."
    // },
    {
        name: 'guide',
        elt_selector: '#topbar-help-button',
        html: 'Check out the Guide for more information and a cheatsheet. You can also start this tour again from the Welcome page of the Guide.',
        getPreferredPosition: () => 'below',
    },
    {
        name: 'endtour',
        elt_selector: null,
        html: `The tour is finished!  Good luck with your designs!<br><br>
        <label><input type='checkbox'> Skip the tour next time</label><br><br>
        You can find the tour again on the Welcome page of the Guide.`,
        setup: () => {
            $('#tourText input[type=checkbox]').click(storePreference).prop('checked', !showTourOnStartup());
        },
        buttons: {
            cancel: 'Close',
            next: 'Replay Tour',
        },
    },
];

export const MainTour = new Tour(TourSteps);
