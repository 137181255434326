import styled from 'styled-components';

/**
 * Component for a one-line color legend that can be included in a tooltip.
 */
export const TooltipLegend = ({ label, ranges }) => (
    <div>
        <strong>{label}</strong>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            { ranges.map((range) => (
                <LegendPart key={range.color} {...range} />
            ))}
        </div>
    </div>
);

const LegendSpan = styled.span`
    text-align: center;
    flex: 1;
    font-weight: bold;
    padding: 0.2em;
`;

function LegendPart({
    color, label: labelIn, low, high,
}) {
    let label = labelIn || '';
    let range;
    switch (true) {
        case low != null && high != null:
            range = `${low} – ${high}`;
            break;
        case low != null:
            range = `> ${low}`;
            break;
        case high != null:
            range = `< ${high}`;
            break;
        default:
            range = '';
    }
    if (range && label) {
        label = ` (${label})`;
    }
    return (
        <LegendSpan style={{ backgroundColor: color }}>
            {range}
            {label}
        </LegendSpan>
    );
}

/**
 * Create a tooltip legend component, callable from regular JS code.
 */
export function makeTooltipLegend(props) {
    return <TooltipLegend {...props} />;
}
