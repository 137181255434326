import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useContext } from 'react';
import { Loader } from 'BMapsSrc/Loader';
import { ProteinContext } from '../../../../common/ProteinContextProvider';

/**
 * @typedef {"individual" | "selectivity" | "multiProtein"} selectivity
 */

/**
 * @param {{
 *      selectivity: selectivity
 *      target: 'target' | 'offtarget'
 *      setSelectivity: import('react').Dispatch<import('react').SetStateAction<selectivity>>
 *      viewOnly: boolean
 *      setTarget: import('react').Dispatch<import('react').SetStateAction<'target' | 'offtarget'>>
 * }} param0
 * @returns {import('react').ReactElement}
 */
export default function SelectivityControl({
    selectivity, setSelectivity, viewOnly,
    connectionWarning, target, setTarget,
}) {
    const { loadedProteins } = useContext(ProteinContext);
    if (loadedProteins.length === 0) return (false);

    const loadOptions = [
        {
            value: 'individual', label: 'Clear and load new structure', title: 'Clear the workspace and load a new structure', available: true,
        },
        {
            value: 'selectivity', label: 'Add structure for selectivity', title: 'Add an additional structure with resources for selectivity analysis', available: !viewOnly,
        },
        {
            value: 'viewonly', label: 'Add view-only structure', title: 'Add an additional structure for visualization only', available: viewOnly,
        },
        {
            value: 'multiProtein',
            label: 'Add structure using multiProtein support',
            title: 'Add an additional structure using single connection',
            available: Loader.AllowDevFeatures,
        },
    ];
    const targetOptions = [
        {
            value: 'target', label: 'Target', title: 'Load as a target protein', available: true,
        },
        {
            value: 'offtarget', label: 'Off-Target', title: 'Load as an off-target protein', available: true,
        },
    ];

    return (
        <div style={{ marginBottom: '1rem' }}>
            { connectionWarning && (
                <span style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>{connectionWarning}</span>
            )}
            <MuiRadioGroup
                onChange={(evt) => setSelectivity(evt.target.value)}
                sx={{ display: 'flex', flexDirection: 'row' }}
            >
                {loadOptions
                    .filter((option) => option.available)
                    .map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            checked={selectivity===option.value}
                            label={option.label}
                            title={option.title}
                            control={<Radio size="small" />}
                        />
                    ))}
            </MuiRadioGroup>
            {selectivity !== 'individual' && (
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span>This protein is a</span>
                    <MuiRadioGroup
                        onChange={(evt) => setTarget(evt.target.value)}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 0,
                            marginLeft: '0.75rem',
                        }}
                    >
                        {targetOptions
                            .filter((option) => option.available)
                            .map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    checked={target===option.value}
                                    label={option.label}
                                    title={option.title}
                                    control={<Radio size="small" />}
                                />
                            ))}
                    </MuiRadioGroup>
                </span>
            )}
        </div>
    );
}
