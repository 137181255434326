/* TestCase.js
 * A class for managing testcases in the TestConsole ui
 */
import { UserActions } from 'BMapsCmds';
import { App as TheApp } from '../../BMapsApp';
import { ServerCmds } from '../../server_cmds';
import { ResponseIds } from '../../server/server_common';
import { findBondVectorPair } from '../../utils';
import * as Data from '../../project_data';
import * as TestData from '../test_data';
import { SimSpec } from '../../model/SimSpec';

// Environment for test cases
// This lets us refer to them in the TestConsole
let Server;
let User;
let Log;
let Result;
let App;
let Workspace;

export class TestCase {
    constructor(name, arg='') {
        this.name = name;
        this.updateCode(arg);
    }

    updateCode(arg='') {
        if (typeof (arg) === 'string') {
            this.fn = null;
            this.code = arg;
        } else if (typeof (arg) === 'function') {
            this.fn = arg;
            const code = arg.toString();
            this.code = code.slice(code.indexOf('{')+1, code.lastIndexOf('}')).trim();
        }
    }

    static async ExecuteCode(code) {
        const output = [];

        // Available within the eval: Server, User, Log, Result
        Server = ServerConnection();
        User = UserActions;
        App = TheApp;
        Workspace = App.Workspace;
        Log = (message) => { console.log(message); output.push(message); };
        Result = null;
        // This is not available in production
        // eslint-disable-next-line no-eval
        await eval(`(async function (){${code}})();`); // wrap code in an async function so we can use await inside
        return { result: Result, output };
    }

    async Execute() {
        await UserActions.ZapAll();
        return TestCase.ExecuteCode(this.code);
    }
}

export function GetSampleTestCases() {
    const cases = [];

    for (const [tc, value] of Object.entries(sampleTestCases)) {
        cases.push(new TestCase(tc, value));
    }

    return cases;
}

const sampleTestCases = {
    HugeFragSearchRadius,
    PrepareAndRun4YZU,
    PrepareAndRunWithCompound4YZU,
    Load1ULA,
    Load4YZU,
    LoadA2ar,
    ReportEnergy4YZU,
    Load4YZUAndSelectAtom,
    Load4YZUAndReplaceGroup,
    Load4YZUAndGrowFromAtom,
    Load4YZUAndSearchNear,
    LoadJAK3Ligand,
    Load1XJ,
    LoadInvalidSmiles,
    LoadNoHydrogens,
    Translate,
    Export4YZU,
    LoadBenzeneSmiles,
    CompareXylo,
    DockNothing,
    DockNull,
    DockWithoutProtein,
    DockTwo,
    DockParker,
    LoadAll,
    ReportEnergyAll,
};

function ServerConnection() {
    const conn = TheApp.ServerConnection;
    const serverCmds = { };
    for (const [cmdName, cmdFn] of Object.entries(ServerCmds)) {
        serverCmds[cmdName] = (...args) => conn.execCmd(cmdName, cmdFn, ...args);
    }

    return serverCmds;
}

async function prepareAndRun(mapCase, includeCompound=false, fragments='') {
    const { mapCase: loadedProtein } = await User.ChooseProtein(mapCase);
    const caseData = Workspace.lookupCaseData(loadedProtein);
    const simSpec = SimSpec.GetFromWorkspace(Workspace, caseData, includeCompound, fragments);
    let prep = await User.PrepareProjectCase({ simSpec });
    if (prep.case_suggestion) {
        simSpec.case = prep.case_suggestion;
        prep = await User.PrepareProjectCase({ simSpec });
    }
    if (prep.errors.length > 0) {
        Log(prep.errors.join('\n\t'));
        return;
    }
    Log(prep.status);
    const { errors: runErrors } = await User.RunProjectCase(simSpec);
    Log(runErrors.join('\n\t'));
}

async function HugeFragSearchRadius() {
    Server.FragSearchRadius(99);
}

async function PrepareAndRun4YZU() {
    prepareAndRun(TestData.Case4YZU.uri);
}

async function PrepareAndRunWithCompound4YZU() {
    const includeCompound = true;
    prepareAndRun(TestData.Case4YZU.uri, includeCompound);
}

async function Load4YZU() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
}

async function Load1ULA() {
    await User.ChooseProtein('pdb:1ULA');
}

async function LoadA2ar() {
    await User.ChooseProtein(TestData.CaseA2ar.uri);
}

async function LoadAll() {
    const responseData = await Server.ListMaps();
    const allMapData = responseData.byId(ResponseIds.MapList);
    const maps = allMapData.reduce((prev, curr) => prev.concat(curr), []);
    for (const map of maps) {
        await User.ChooseProtein(map);
    }
}

async function ReportEnergy4YZU() {
    const uri = TestData.Case4YZU.uri;
    const responseData = await User.ChooseProtein(uri);
    reportEnergies(uri, responseData.energies);
}

async function ReportEnergyAll() {
    const responseData = await Server.ListMaps();
    const allMapData = responseData.byId(ResponseIds.MapList);
    const maps = allMapData.reduce((prev, curr) => prev.concat(curr), []);
    for (const map of maps) {
        const data = await User.ChooseProtein(map);
        reportEnergies(map.uri, data.energies);
    }
}

function reportEnergies(label, energies, errors=[]) {
    Log(`Energies for ${label}:\n`);
    for (const en of energies) {
        for (const entry of Object.keys(en)) {
            if (entry === 'cmpdSpec' || entry === 'total') continue;
            Log(`${en.cmpdSpec} ${entry}: ${(en[entry]).toFixed(1)}\n`);
        }
    }

    if (errors.length > 0) {
        Log(`Energy errors for ${label}:\n\t`);
        Log(errors.join('\n\t'));
    }
}

async function minimizeAndReportEnergyForCompounds(compounds) {
    const {
        compounds: minimizedCompounds,
        energies,
        errors: minimizationErrors,
    } = await User.EnergyMinimize(compounds);
    for (const compound of compounds) {
        reportEnergies(compound.resSpec, energies, minimizationErrors);
    }
}

async function Load4YZUAndSelectAtom() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
    const atom = App.Workspace.findAtomByUid(3681);
    await User.SelectAtom(atom);
}

async function Load4YZUAndReplaceGroup() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
    const atom = App.Workspace.findAtomByUid(3664);
    const { compounds } = await User.ReplaceGroup(atom, 'Amine');
    await minimizeAndReportEnergyForCompounds(compounds);
}
async function Load4YZUAndGrowFromAtom() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
    const atom = App.Workspace.findAtomByUid(3654);
    const bondVectorPair = findBondVectorPair(atom, [false, false, false]);
    const { suggestions } = await User.GrowFromAtom(bondVectorPair, 'Bond');
    const { compounds } = await User.ConfirmModification(suggestions[0], atom);
    await minimizeAndReportEnergyForCompounds(compounds);
}
async function Load4YZUAndSearchNear() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
    const atom = App.Workspace.findAtomByUid(3654);
    const { suggestions } = await User.SearchNear(atom);
    const { compounds } = await User.ConfirmModification(suggestions[0], atom);
    await minimizeAndReportEnergyForCompounds(compounds);
}
async function LoadJAK3Ligand() {
    await User.LoadMolecule('4ST', 'smi', TestData.JAK3Ligand.smi, 'none');
}
async function Load1XJ() {
    await User.LoadMolecule('1XJ', 'smi', TestData.OneXJ.smi, 'none');
}
async function LoadInvalidSmiles() {
    const { compounds: loadedCompounds, errors: loadErrors } = await User.LoadMolecule('invalid', 'smi', 'c1cccc1r', 'none');
    Log(`LoadInvalidSmiles: ${JSON.stringify(loadedCompounds)}, ${JSON.stringify(loadErrors)}`);
}
async function LoadNoHydrogens() {
    const { compounds: loadedCompounds, errors: loadErrors } = await User.LoadMolecule('no_h_test', 'mol', TestData.FourK6_no_H.mol, 'none');
}
async function Translate() {
    const format = 'mol';
    const result = await Server.TranslateMolecule('taco', 'smi', 'cccnc', 'mol');
    const mol = result.content;
    Log(mol);
    await User.LoadMolecule('test', format, mol, 'none,gen3d');
}
async function Export4YZU() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
    await Server.Select('4K6');
    const result = await Server.ExportSelection('mol2');
    Log(result);
}

async function LoadBenzeneSmiles() {
    await User.LoadMolecule('benzene', 'smi', TestData.Benzene.smi, 'none,gen3d');
}

async function DockParker() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
    const { compounds: loadedCompounds, errors: loadErrors } = await User.LoadMolecule('parker', 'mol2', TestData.Parker.mol2, 'align');
    let [newCompounds, errors] = await dockCompounds(loadedCompounds, 'faster');
    await minimizeAndReportEnergyForCompounds(newCompounds);
    [newCompounds, errors] = await dockCompounds(loadedCompounds, 'fast');
    await minimizeAndReportEnergyForCompounds(newCompounds);
}

async function dockCompounds(compounds, speed, labelIn) {
    let label = labelIn;
    const dockingParams = {
        speed,
        poseCount: Math.floor(Math.random() * Math.floor(10)) + 1, // Value: 1 - 10
        boxParams: null,
    };
    const { compounds: newCompounds, errors } = await User.SubmitDocking(compounds, dockingParams);
    if (!label && compounds) {
        label = `Docking ${JSON.stringify(compounds.map((c) => c.resSpec))}`;
    }
    Log(`${label}: Docked compounds: ${newCompounds}\nErrors: ${errors}`);
    return [newCompounds, errors];
}

async function DockNothing() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
    const [newCompounds, errors] = await dockCompounds([], 'fast');
}

async function DockNull() {
    await User.ChooseProtein(TestData.Case4YZU.uri);
    try {
        const [newCompounds, errors] = await dockCompounds(null, 'fast');
    } catch (ex) {
        const errMsg = `Exception in DockNull ${ex}!`;
        Log(`ERROR: ${errMsg}`);
        console.error(errMsg);
    }
}

async function DockWithoutProtein() {
    const { compounds: loadedCompounds, errors: loadErrors } = await User.LoadMolecule('parker', 'mol2', TestData.Parker.mol2, 'none');
    const [newCompounds, dockErrors] = await dockCompounds(loadedCompounds, 'fast', 'DockWithoutProtein');
}

async function DockTwo() {
    await User.ChooseProtein(TestData.CaseEBV.uri);
    const { compounds: compounds1, errors: loadErrors1 } = await User.LoadMolecule('benzene', 'smi', TestData.Benzene.smi, 'none');
    const { compounds: compounds2, errors: loadErrors2 } = await User.LoadMolecule('acetamide', 'pdb', TestData.Acetamide.pdb, 'none');
    const [newCompounds, errors] = await dockCompounds(compounds1.concat(compounds2), 'faster');
    await minimizeAndReportEnergyForCompounds(newCompounds);
}

async function CompareXylo() {
    const replaceRegex = {
        pdb: /(REMARK|COMPND|AUTHOR|MASTER|TER).*\r?\n/gm,
        mol2: /(#).*\r?\n/gm,
    };

    for (const format of ['smi']) {
        const loaded = await User.LoadMolecule('xylo', 'sdf', TestData.Xylo.sdf, 'none');
        await Server.Select('xylo');
        let exportResult = await Server.ExportSelection(format);
        exportResult = exportResult.trim();
        if (replaceRegex[format]) exportResult = exportResult.replace(replaceRegex[format], '');

        let translateResult = await Server.TranslateMolecule('xylo', 'sdf', TestData.Xylo.sdf, format);
        translateResult = translateResult.content.trim();
        if (replaceRegex[format]) translateResult = translateResult.replace(replaceRegex[format], '');

        console.log(`Export ${format}: \n${exportResult} \nTranslate Format: \n${translateResult}`);
        Log(`Xylo ${format} from export: ${exportResult}`);
        Log(`Xylo ${format} from translate: ${translateResult}`);
        Log('Xylo smi from Maestro: O[C@H]1OC[C@@H](O)[C@H](O)[C@H]1O');
    }
}
