/**
 * @file BMapsPage.jsx
 * Note about layout of items and components in BMaps
 * There are a number of potentially overlapping items in the UI.
 * This is an attempt to rationalize its structure.
 *
 * Primary app layout:
 * - TopBar
 * - Main Content Area
 *   - Left side panel
 *   - Main workspace
 *     - 3D Canvas with HUD items on top of it (heads up display)
 *     - Right Control Panel
 *   - Right side panel
 *
 * Intended order of BMaps items from lowest to hightest:
 *  - 3D Canvas
 *  - UI Elements displayed with, but on top of the canvas
 *    - InfoDisplay
 *      - Protein Name
 *      - Add Protein and Compound buttons
 *      - Selector
 *      - Inspector
 *    - LigandMod (fragment search results)
 *    - ServerInfo
 *    - Background color buttons
 *    - Status Bar (# of selected atoms)
 *    - Select Box
 *  - Right side panel
 *  - Left side panel
 *  - Top Bar
 *  - RH Control Buttons
 *  - Tour box
 *  - Canvas tooltips
 *  - Canvas context menu
 *  - RH Control Drawers (open drawers)
 *  - TopBar menus (hamburger menu)
 *  - Dialogs (export pane, simulation pane, protein and compound import etc.)
 *  - ConnectionError
 *  - Loading Screen
 *
 * Problematic:
 * - Background color buttons on top of Inspector in narrow widths
 * - LigandMod z-index the same as CanvasTooltip (.canvasPopup)
 *   - LigandMod will end up on top of RH Control Buttons and Right Side Panel
 * - Elements layered between the RH Control buttons and their open drawers (looks weird):
     RH Control buttons can be covered, that is fine. But what if a covered button gets opened?
     I prefer the user to be able to see into the drawer they just opened.
     The approach for now is to put the open drawer on top, creating a weird looking sandwich.
     Alternatively, we could change the z-index of the control button when the drawer is open.
     Known cases:
 *   - TourBox can cover RH Control Buttons (by design), but user might want to open such a drawer.
 *   - CanvasTooltip can occasionally open unexpectedly if the mouse leaves the canvas while
 *     hovering an atom. This was significantly reduced by canceling the 3Dmol hover timer
 *     when leaving the canvas, but it can still happen, and has resisted an effort to address.
 *     The current workaround is to position the tooltip between Control buttons and drawers.
 *     An alternative is to make sure the tooltips close when the drawer opens (in SpeedDial).
 * - Left Side Panel covers Tour
 *
 * Bfm_viewer is distinguished from other components due to its need to be "resized"
 * placing items in the bfm_viewer should be avoided because of this causing positional changes,
 * (but they still need to be positioned correctly).
 *
 * Some candidates for removal from bfm_viewer are:
 * <TourComponent/>
 * <LigandMod/>
 * <InfoDisplay />
 * <BackgroundButtons />
 * <ImportCompoundPane />
 * <ImportProteinPane />
 * <ExportPane />
 * <SimulationPane />
 * <PreferencesPane />
 * <StatusBar />
 * <SelectBox />
 *
 * Background color buttons are the highest priority for removing from the bfm_viewer
 * due to visual glitches
 * but as a principle we should develop a better way of managing these components
 * that isn't in bfm_viewer
 *
 * <MapSelector /> Isn't used anymore and will be removed.
 *
 * Listing of z-index usage:
 * - SidePanel: SidePanelContainer has z-index: 1
 *   - HelpPanel: zIndex: 0, to be under the bfm_viewer so tooltips are displayed on top of it.
 * - tour.css:
 *   - #tourHighlight: z-index: 3
 *   - #tourBox: z-index: 4
 * - .canvasPopup (CanvasTooltip, LigandMod) z-index: 5
 * - RightControlPanel.jsx: Overrides ActionsDrawer z-index to 6.
 *   (see "Elements layered between the RH Control buttons and their open drawers" above)
 * - TopBar menu: #topbar-menu-list z-index: 5000
 * - Overlays:
 *   .modalViewer (loading screen, connection err) z-index: 1500
 *   .cxn_err_pane: z-index: 3000, .cxn_err_pane .close: 3001
 * Localized usage in specific components:
 * - ImportPane: PlaceHolder (watermark text with upload link) has z-index: 1
 * - ExportPane: Backdrop has zIndex: 2000 (only used during export fetch / calculation?)
 * - CDDVTab: PageControlsContainer: z-index: 1. Keeps paging controls on top when scrolling.
 *   (necessary?)
 * - LigandMod (style.css): sticky header of search results: z-index: 1
 * - FragmentManager: .filtermenu has z-index: 1
 */

import { useEffect, useRef } from 'react';
import LigandMod from 'BMapsDialog/FragmentSearch/LigandMod';
import { useColorSchemeInfo } from 'BMapsSrc/redux/prefs/access';
import PageWrapper from './PageWrapper';
import UIManager from './UIManager';
import BMapsTheme from '../../styles/theme';
import main from '../main';
import { ModalManager } from './ModalManager';
import DockingPane from './dialog/DockingPane';
import ImportCompoundPane from './dialog/ImportPane/ImportCompoundPane';
import ImportProteinPane from './dialog/ImportPane/ImportProteinPane';
import ExportPane from './dialog/ExportPane';
import SimulationPane from './dialog/SimulationPane/SimulationPane';
import PreferencesPane from './dialog/PreferencesPane';
import { LoadingScreen } from '../LoadingScreen';
import { ConnectionErrorPane } from '../ConnectionErrorPane';
import MapSelector from './MapSelector';
import InfoDisplay from './info_display/InfoDisplay';
import ServerInfo from './ServerInfo';
import TourComponent from './TourComponent';
import RightControlPanel from './RightControlPanel';
import BackgroundButtons from './BackgroundButtons';
import SelectBox from './SelectBox';
import StatusBar from './StatusBar';
import TopBar from './TopBar';
import SidePanel from './info_display/SidePanel';
import { HelpPanel } from './HelpPanel';

export default function BMapsPage({ PageWrapperProps }) {
    useEffect(() => {
        main();
    }, []);

    return (
        <PageWrapper theme={BMapsTheme} PageWrapperProps={PageWrapperProps}>
            <TopBar />
            <LoadingPane />
            <ErrorPane />
            <AppDefinition />
            <MainApp />
        </PageWrapper>
    );
}

/**
 * @todo Consider unifying with LoadingScreen
 */
function LoadingPane() {
    return (
        <div id="loading_pane" className="modalViewer">
            Loading Boltzmann Maps ...
            {' '}
            <i className="fa fa-cog fa-spin" />
        </div>
    );
}

function ErrorPane() {
    return (
        <div id="connection_err_pane" className="modalViewer" hidden>
            <i className="fa fa-close connection_err_close" />
            <i className="fa fa-warning" />
&nbsp;
            <span id="connection_err_msg">Sorry...a connection to Boltzmann Maps could not be established.</span>
            <br />
            Please
            {' '}
            <a href="bmaps-debug.html">try again</a>
        </div>
    );
}

function AppDefinition() {
    return (
        <div id="boltzmannDef" hidden>
            Distributions of binding configurations of chemical fragments or water molecules
            <br />
            on the surface of macromolecules, conforming to Boltzmann energy statistics
        </div>
    );
}

function MainApp() {
    const { css } = useColorSchemeInfo();
    const viewerRef = useRef(null);

    const ModalPaneProps = {
        DialogProps: {
            sx: {
                // Positioning tweaks because ModalPane is relative to the canvas, not the viewport:
                // Cover Right Ctrl Buttons
                right: 'calc((var(--mol-button-size) + 1vh + (var(--mol-button-margin) * 2)) * -1)',
                // Reposition vertically for better aesthetics
                '& .MuiDialog-paper': {
                    transform: 'translateY(calc(var(--topbar-logo-size) * -1))',
                },
            },
        },
        modalRef: viewerRef,
    };

    return (
        <div id="app_wrapper" style={{ backgroundColor: css }}>
            <div id="loading_pane_wrapper"><LoadingScreen /></div>
            <div id="cxn_err_pane_wrapper"><ConnectionErrorPane /></div>
            <LeftDisplay />
            <div id="bfm_viewer" ref={viewerRef}>
                <TourComponent />
                <LigandMod />
                <CanvasWrapper />
                {/* Candidate for MapSelector removal */}
                <MapSelector />
                <InfoDisplay />
                <BackgroundButtons />
                {/* ModalManager: Sketcher, SaveState, FragmentManager, Manual */}
                <ModalManager ModalPaneProps={ModalPaneProps} />
                <ImportCompoundPane ModalPaneProps={ModalPaneProps} />
                <ImportProteinPane ModalPaneProps={ModalPaneProps} />
                <ExportPane ModalPaneProps={ModalPaneProps} />
                <SimulationPane ModalPaneProps={ModalPaneProps} />
                <PreferencesPane ModalPaneProps={ModalPaneProps} />
                <StatusBar />
                <SelectBox />
            </div>
            <RightDisplay />
        </div>
    );
}

function RightDisplay() {
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            height: '100%',
            pointerEvents: 'none',
        }}
        >
            <div style={{ height: 'fit-content', pointerEvents: 'all' }}>
                <ServerInfo />
            </div>
            {/* Items next to bfm_viewer */}
            <div id="rightsidepanel" style={{ display: 'flex', pointerEvents: 'all' }}>
                <RightControlPanel />
                <SidePanel side="right">
                    <HelpPanel pageId="help" keepOpenOnZap />
                </SidePanel>
            </div>
        </div>
    );
}

function LeftDisplay() {
    return (
        <div id="leftsidepanel">
            <SidePanel side="left">
                <DockingPane pageId="docking" />
            </SidePanel>
        </div>
    );
}

function CanvasWrapper() {
    function canvasPreventDefault(evt) {
        evt.preventDefault();
    }

    return (
        <div id="canvas_wrapper" visualizer="3dmol" onContextMenu={canvasPreventDefault}>
            <UIManager />
        </div>
    );
}
