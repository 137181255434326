const INFO = 'INFO';
const WARN = 'WARN';
const ERROR = 'ERROR';

export class Log {
    static info(msg) { Log.log(INFO, msg); }
    static warn(msg) { Log.log(WARN, msg); }
    static error(msg) { Log.log(ERROR, msg); }

    static timestamp() {
        const d = new Date();
        d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
        // ISO Format = 2018-12-13T14:05:17.345Z
        // substr(11) strips off the date part
        return d.toISOString().substr(11).replace(/:|Z/g, '');
    }

    static log(priority, msg) {
        const priorityText = priority === ERROR ? priority : `${priority} `;
        const log = `${Log.timestamp()} ${priorityText} ${msg}`;
        switch (priority) {
            case WARN: console.warn(log);
                break;
            case ERROR: console.error(log);
                break;
            default: console.log(log);
        }
    }
}
