import { useEffect, useState } from 'react';

// Material UI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
// Local
import { UserActions } from 'BMapsCmds';
import { App } from '../../../BMapsApp';
import { EventBroker } from '../../../eventbroker';
import { upgradeUrl } from '../../../connection_utils';
import DialogContent from '../../common/DialogContent';
import DialogContentSection, { DialogContentWithTooltip } from '../../common/DialogContentSection';
import DockingForm from './DockingForm';
import { SidePanelSection } from '../../info_display/SidePanel';
import { useDockingReferences } from './DockingReferencesSelect';

function DockingDescription({ compounds }) {
    return (
        <DialogContentWithTooltip
            title="Compound(s)"
            disablePadding
            collapsible
            defaultExpand
            tooltip={'These compounds will be submitted for docking. To modify this list, select the desired compounds in the Compounds Selector and choose "Dock" again.'}
            style={{ width: '100%' }}
        >
            <SidePanelSection>
                <List dense disablePadding aria-label="requested compounds" style={{ overflow: 'auto', maxHeight: '10em' }}>
                    {compounds.map((cmpd) => (
                        <ListItem key={cmpd}>
                            <ListItemText>
                                {cmpd.resSpec}
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </SidePanelSection>
        </DialogContentWithTooltip>
    );
}

function Footer({ counts }) {
    return (
        <DialogContentSection>
            {!!counts.limit && (
                <>
                    {`Docks left this month: ${counts.remaining} / ${counts.limit}`}
                </>
            )}
        </DialogContentSection>
    );
}

// Currently unused because we have both Autodock Vina and DiffDock.
function DockingCredit() {
    const dockingProgram = 'AutoDock Vina';
    const dockingWebsite = 'http://vina.scripps.edu/';

    return (
        <DialogContentText align="center">
            <i>
                Boltzmann Maps docking is powered by
                {' '}
                <a href={dockingWebsite} rel="noreferrer" target="_blank">{dockingProgram}</a>
                .
            </i>
        </DialogContentText>
    );
}

export default function QueryContent({
    handleClose, requestedCompounds, preDock, postDock,
}) {
    const [counts, setCounts] = useState({ limit: null });
    const dockingReferences = useDockingReferences();
    const permissionManager = getPermissionManagerForCompounds(requestedCompounds);

    // Note: permissionManager is undefined for the first DockingPane render, because there are no
    // compounds on the first render, and we look up the permission manager from the compounds.

    const noDockMsg = counts.limit && counts.remaining === 0 ? (
        <>
            <Typography align="center">
                You have used up all of your docks for the month!
            </Typography>
            <br />
            <Typography>
                You&apos;ll get new docks on
                {' '}
                {counts.nextPeriodStart.toLocaleDateString('en-US', { month: 'long' })}
                {' '}
                {counts.nextPeriodStart.getDate()}
                , or you can
                {' '}
                <a href={upgradeUrl()} target="_blank" rel="noreferrer">upgrade your account</a>
                {' '}
                to dock now.
            </Typography>
        </>
    ) : null;

    function updateCounts() {
        if (App.Ready && permissionManager) {
            setCounts(permissionManager.getCounts('dock'));
        }
    }

    async function handleSubmit(values) {
        const {
            dockingProgram,
            poseCount,
            // autodock params
            selectedRefId, dockspeed, boxSizeType, boxSize,
            // diffdock params
            keepHs, keepSrc3D,
        } = values;
        const refObj = dockingReferences.find((r) => r.id === selectedRefId);
        const boxParams = {
            refObj,
            size: boxSizeType === 'auto' ? 0 : boxSize,
        };
        const dockingParams = {
            dockingProgram,
            poseCount,
            // autodock params
            speedArg: dockspeed,
            boxParams,
            // diffdock params
            keepHs,
            keepSrc3D,
        };

        preDock();

        try {
            const dockResult = await UserActions.SubmitDocking(requestedCompounds, dockingParams);
            if (App.Ready && permissionManager) {
                await permissionManager.refresh();
            }
            postDock(dockResult);
        } catch (error) {
            console.error('Exception during docking:', error);
            postDock({ compounds: [], errors: ['There was a problem processing the docking results.'] });
        }
    }

    useEffect(() => {
        updateCounts();
        const subscription = EventBroker.subscribe('setPermissions', updateCounts);

        return () => {
            EventBroker.unsubscribe('setPermissions', subscription);
        };
    }, []);

    if (noDockMsg) {
        return (
            <DialogContent>
                {noDockMsg}
            </DialogContent>
        );
    }

    return (
        <DialogContent>
            <DockingDescription compounds={requestedCompounds} />
            <DockingForm
                handleClose={handleClose}
                requestedCompounds={requestedCompounds}
                handleSubmit={handleSubmit}
            />
            <Footer counts={counts} />
        </DialogContent>
    );
}

/**
 * Look up PermissionManager on the dataConnection for the compounds.
 * This can't currently handle compounds from more than one connection.
 * @param {Compound[]} compounds
 * @returns {PermissionManager?}
 */
function getPermissionManagerForCompounds(compounds) {
    const { dataParentsList } = App.partitionByDataParents(compounds);

    if (dataParentsList.length === 0) {
        return null;
    }

    if (dataParentsList.length > 1) {
        console.warn(`getPermissionManagerForCompounds: More than one permission manager for compounds: ${compounds.map((c) => c.resSpec).join(', ')}`);
    }
    return dataParentsList[0].permissionManager;
}
