import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';

export default styled(MenuItem)`
    transition: none;

    &:hover {
        background: #AAAAAA;
    },
`;
