import _ from 'lodash';
import { SimSpec } from '../model/SimSpec';

/** AlphaFold "Very High" Confidence X > 90 */
export const VERY_HIGH = 90;
/** AlphaFold "Confident" Confidence: 90 > X > 70 */
export const CONFIDENT = 70;
/** AlphaFold "Low" Confidence: 70 > X > 50 */
export const LOW = 50;
/** AlphaFold "Very Low" Confidence: 50 > X > 0 */
export const VERY_LOW = 0;

export const defaultFilterParams = {
    SMALL_LOOP_CUTOFF: 12,
    INCLUDE_SS_ENDS: false,
    MIN_CONFIDENT_LOOP_SIZE: 2,
};

/**
 * Functions which determine how to get residue properties.
 * Another implementation can be passed into filterByConfidence for testing.
 */
const defaultResidueLogic = {
    getConfidence(residue) { return residueConfidence(residue); },
    getStructureInfo(residue) { return residue.getSecondaryStructureInfo(); },
};

/** Get 3D confidence colors per AlphaFold site */
export function getAlphafoldColor(bfactor) {
    switch (true) {
        case bfactor > VERY_HIGH: return 0x0053d6;
        case bfactor > CONFIDENT: return 0x65cbf3;
        case bfactor >= LOW: return 0xffdb13;
        case bfactor < LOW: default: return 0xff7d45;
    }
}

export function atomConfidence(atom) {
    return atom.Bfactor;
}

export function residueConfidence(residue) {
    return atomConfidence(residue.getAtoms()[0]);
}

/**
 * Transform a SimSpec, filtering out AlphaFold residues that don't meet the confidence level
 * @param {SimSpec} simspec
 * @returns
 */
export function alphaFoldSimSpecFilter(simspec) {
    // Filter chains by Alphafold confidence level, get the residues to EXCLUDE
    const filteredResidues = [];
    for (const chain of simspec.proteinChains) {
        const { rejectedResidues } = filterByConfidence([...chain.residues]);
        filteredResidues.push(...rejectedResidues);
    }
    return new SimSpec({
        ...simspec,
        ignoredObjects: filteredResidues,
    });
}

/**
 * Separate a group of residues according to AlphaFold confidence.
 *
 * Definitions:
 * - helices and sheets are residues with secondary structure information
 * - loops are residues without secondary structure information
 * - high-confidence (HC) in this context means "sufficient confidence" to pass the filter
 * - low-confidence (LC) in this context means "insufficient confidence" to pass the filter
 * - string: a group of residues with the same confidence level and secondary structure info
 * - external: LC residues that are at the ends of the chains, beyond the last HC residues
 * - internal: LC residues that come in the middle, with HC residues existing on either side
 * - HC structures / LC structures: shorthand for high-low confidence secondary structures
 * Logic for inclusion:
 * - All high-confidence helices and sheets are always included
 * - Internal low-confidence helices and sheets are always included
 * - External low-confidence helices and sheets are included if INCLUDE_SS_ENDS is specified
 * - External low-confidence loops are always rejected
 * - Internal low-confidence loops are included if the string has length <= SMALL_LOOP_CUTOFF
 * - Internal high-confidence loops are included if:
 *   - If the string has length >= MIN_CONFIDENT_LOOP_SIZE
 *   - OR they are immediately next to an included structure
 *   - OR they are a part of a longer LC loop that gets included
 * @param {Residue|Residue[]} residueListIn
 * @param {number} confidence
 * @param {{
 *      SMALL_LOOP_CUTOFF: number, INCLUDE_SS_ENDS: boolean, MIN_CONFIDENT_LOOP_SIZE: number
 * }} filterParams Parameters for the filtering
 * @param {{
 *      getConfidence: function, getStructureInfo: function,
 * }} logic How to extract necessary info from residues (overridden for unit test)
 * @returns
 */
export function filterByConfidence(residueListIn,
    confidence=CONFIDENT,
    filterParams=defaultFilterParams,
    logic=defaultResidueLogic) {
    const residueList = Array.isArray(residueListIn) ? [...residueListIn] : [residueListIn];
    residueList.sort((residue1, residue2) => residue1.resi - residue2.resi);

    const residueStrings = groupResiduesIntoStrings(residueList, confidence, filterParams, logic);
    return processResidueStrings(residueStrings, filterParams);
}

/**
 * Process an array of residues that have been grouped into "strings" according to
 * confidence and structure. The residue "strings" have also already been annotated with
 * whether they can be definitely included or rejected, based on basic properties.
 * @param {{
 *      residues: Residue[], meetsConfidence: boolean, hasStructure: boolean,
 *      definitelyInclude: boolean, definitelyReject: boolean,
 * }[]} resStrings
 * @param {*} filterParams
 */
function processResidueStrings(resStrings, filterParams=defaultFilterParams) {
    const filteredResidues = []; // Result array for included residues
    const rejectedResidues = []; // Result array for rejected residues
    let tempResidues = []; // Array for loops that we aren't sure about yet
    const include = (including) => { filteredResidues.push(...including); };
    const reject = (rejecting) => { rejectedResidues.push(...rejecting); };
    function handleTemp(allow) {
        if (tempResidues.length > 0) {
            if (allow && tempResidues.length <= filterParams.SMALL_LOOP_CUTOFF) {
                logRes('Including temp', tempResidues);
                include(tempResidues);
            } else {
                logRes('Rejecting temp', tempResidues);
                reject(tempResidues);
            }
            tempResidues = [];
        }
    }
    function includeGroup(residues, label) {
        handleTemp(true);
        logRes(label, residues);
        include(residues);
    }
    function rejectGroup(residues, label) {
        handleTemp(false);
        logRes(label, residues);
        reject(residues);
    }
    function isAdjacentHCLoop(item, index) {
        return isHCLoop(item)
            && (resStrings[index-1]?.definitelyInclude || resStrings[index+1]?.definitelyInclude);
    }

    const firstDefInclude = _.findIndex(resStrings, ({ definitelyInclude }) => definitelyInclude);
    const lastDefInclude = _.findLastIndex(resStrings,
        ({ definitelyInclude }) => definitelyInclude);

    for (const [index, resInfo] of resStrings.entries()) {
        const { residues, hasStructure } = resInfo;

        // Easy cases first
        if (resInfo.definitelyInclude) {
            // Definitely included residues include HC structures and HC Loops of sufficient length
            includeGroup(residues, 'Adding definite residues');
        } else if (resInfo.definitelyReject) {
            // Definitely rejected residues are LC Loops of sufficient length
            rejectGroup(residues, 'Rejecting definite residues');
        } else if (isAdjacentHCLoop(resInfo, index)) {
            // We always want to include HC Loops immediately adjacent to included structures
            // Note: this doesn't handle single HC Loops immediately adjacent to LC structures
            // So if an interior sequence was ...<Long LC Loop><1 HC Loop Res><LC Helix>...,
            // the HC loop would be dropped with the Long LC loop, instead of being included with
            // the LC Helix.
            includeGroup(residues, 'Adding vulnerable HC Loop');
        } else if (index < firstDefInclude || index > lastDefInclude) {
            // Reject "external" residues, beyond the first and last definitely added regions
            rejectGroup(residues, `Rejecting external residues ${firstDefInclude} < ${index} > ${lastDefInclude}`);
        } else if (hasStructure) {
            // External case has already been checked, so can add any internal LC structures
            includeGroup(residues, 'Adding internal LC structures', residues);
        } else {
            // Defer handling of interior loops of questionable confidence
            tempResidues.push(...residues);
        }
    }

    return { filteredResidues, rejectedResidues };
}

/**
 * Group array of residues according to structure type and whether they meet the confidence level.
 * Annotate the entries with whether they are "definitely included" or "definitely rejected."
 * Definitely included:
 *   - high confidence secondary structures
 *   - high confidence loops of sufficient length (>= MIN_CONFIDENT_LOOP_SIZE)
 *   - low confidence secondary structures, provided filter params allow INCLUDE_SS_ENDS
 * Definitely rejected:
 *   - low confidence loops of sufficient length (> SMALL_LOOP_CUTOFF)
 * @returns {{
 *      residues: Residue[], meetsConfidence: boolean, hasStructure: boolean,
 *      definitelyInclude: boolean, definitelyReject: boolean,
 * }[]}
 */
function groupResiduesIntoStrings(residues,
    confidence=CONFIDENT,
    filterParams=defaultFilterParams,
    logic=defaultResidueLogic) {
    const allResidueStrings = [];

    let currentResType = null;
    let currentString = [];

    function matches(a, b) {
        return a.meetsConfidence === b.meetsConfidence && a.hasStructure === b.hasStructure;
    }

    function processCurrentString() {
        const { meetsConfidence, hasStructure } = currentResType;
        // Definitely include HC secondary structures or long enough HC loops
        const passingHighConf = isHCStructure(currentResType) || (isHCLoop(currentResType)
            && currentString.length >= filterParams.MIN_CONFIDENT_LOOP_SIZE);
        // Definitely include LC secondary structures if including external LC structures
        const passingLowConf = isLCStructure(currentResType) && filterParams.INCLUDE_SS_ENDS;
        const failingLowConf = isLCLoop(currentResType)
            && currentString.length > filterParams.SMALL_LOOP_CUTOFF;

        allResidueStrings.push({
            residues: [...currentString],
            meetsConfidence,
            hasStructure,
            definitelyInclude: passingHighConf || passingLowConf,
            definitelyReject: failingLowConf,
        });
    }

    for (const residue of residues) {
        const resType = {
            meetsConfidence: logic.getConfidence(residue) > confidence,
            hasStructure: !!logic.getStructureInfo(residue),
        };

        if (!currentResType) currentResType = resType;

        if (!matches(resType, currentResType)) {
            processCurrentString();
            currentResType = resType;
            currentString = [];
        }

        currentString.push(residue);
    }

    processCurrentString();

    return allResidueStrings;
}

function logRes(label, residues) {
    let desc = '<empty>';
    if (residues.length > 0) {
        desc = `${residues.length} residues - ${residues[0].resSpec}-${residues[residues.length-1].resSpec}`;
    }
    console.log(`${label} ${desc}`);
}

// Functions to help interpret residue strings
function isLoop({ hasStructure }) { return !hasStructure; }
function isStructure({ hasStructure }) { return !!hasStructure; }
function isHC({ meetsConfidence }) { return !!meetsConfidence; }
function isLC({ meetsConfidence }) { return !meetsConfidence; }
function isLCLoop(item) { return isLC(item) && isLoop(item); }
function isHCLoop(item) { return isHC(item) && isLoop(item); }
function isLCStructure(item) { return isLC(item) && isStructure(item); }
function isHCStructure(item) { return isHC(item) && isStructure(item); }
