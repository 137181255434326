/**
 * Inspired by _.cond, this takes an array of entries of the form [condition, result]
 * It returns the result for the first truthy condition.
 *
 * This is called simpleCond because _.cond works with predicate and result functions,
 * but this just works on values. Note: this means that the results passed in will be
 * evaluated first.
 * @param {Array<Array<*, *>>} conditions
 *
 * @example ```
 *      function getWaterState(temp) {
 *          return simpleCond([
 *              [temp < 32, "Ice"],
 *              [temp >= 212, "Steam"],
 *              [true, "Water"]
 *          ]);
 *      }
 * ```
 * @returns
 */
export function simpleCond(conditions) {
    for (const entry of conditions) {
        if (!Array.isArray(entry)) throw new Error(`simpleCond: unexpected non-array entry: ${entry}`);
        const [condition, result] = entry;
        if (condition) return result;
    }
    return undefined;
}

export function ensureArray(arg) {
    return Array.isArray(arg) ? arg : [arg];
}

// Sleep from: https://stackoverflow.com/a/39914235
export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
