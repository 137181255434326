import styled from 'styled-components';
import DisabledMenuItem from './DisabledMenuItem';

// TODO: investigate why DisabledMenuItem disableRipple doesn't transfer over...
export default styled(DisabledMenuItem).attrs({
    disableRipple: true,
    tabIndex: null,
})`
    font-weight: bold;
`;
