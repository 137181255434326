import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
// Material UI
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
// Local
import Select from '../../../common/Select';
import { TextArea } from '../../../common/TextFields';
import { Loader } from '../../../../Loader';
import { App } from '../../../../BMapsApp';
import { isProteinExportFormat, getProteinExportFormats } from '../../../../util/mol_format_utils';
import { textToBlobURL, freeBlobURL } from '../../../ui_utils';
import { ExportPaneContext } from '../ExportPane';
import { exportFilenameForTarget, fetchExportData } from '../exportUtils';
import CopyToClipboardButton from '../../../common/Buttons/CopyToClipboardButton';

// These height rules are to override MUI automatic sizing of the text area.
// There was an issue with 0 height if it wasn't initially focused.
const DataTextArea = styled(TextArea)`
    & .MuiInputBase-root {
        height: 100%;
    }

    & textarea {
        height: 100% !important;
        ${({ error }) => (error ? 'color: red;': '')}
    }
`;

export default function ExportMolDataTab({ selected }) {
    const context = useContext(ExportPaneContext);
    const {
        exportTarget, exportFormatData, cacheFormatData, setLoading,
    } = context;

    const [format, setFormat] = useState('sdf');
    const [downloadLinkProps, setDownloadLinkProps] = useState({});
    const [blobURL, setBlobURL] = useState(null);
    const [alert, setAlert] = useState(null);
    const [optionsAttributes, setOptionsAttributes] = useState({});
    const [error, setError] = useState(null);

    // u2013 below are for endash
    const options = {
        sdf: {
            label: 'SDF \u2013 MDL Structure Data File',
        },
        smi: {
            label: 'SMI with Name\u2013 SMILES string (e.g. for Excel)',
        },
        smiNoName: {
            label: 'SMI without Name\u2013 SMILES string (e.g. for Excel)',
        },
        mol: {
            label: 'MOL \u2013 MDL MOL File',
        },
        cdxml: {
            label: 'CDXML \u2013 ChemDraw XML',
        },
        cml: {
            label: 'CML \u2013 Chemical Markup Language',
        },
        mol2: {
            label: 'MOL2 \u2013 Tripos Sybyl Molecule',
        },
        pdb: {
            label: 'PDB \u2013 Protein Data Bank',
        },
        xyz: {
            label: 'XYZ \u2013 XYZ File (for QM)',
        },
        inchi: {
            label: 'InChI \u2013 InChI structure definition string',
        },
        inchikey: {
            label: 'InChI Key\u2013 InChI Key',
        },
        csv: {
            label: 'CSV \u2013 CSV with SMILES & energies',
        },
    };
    if (Loader.AllowDevFeatures) {
        options['bmapscmpds'] = {
            label: 'BMaps JSON with smiles, molfile, mol. props, and full energy data (dev only)',
        };
        options['mol_unbound'] = {
            label: 'Unbound conformation (dev only)',
        };
        options['raw'] = {
            label: 'Mol. data on compound object (dev only)',
        };
    }

    async function update(myFormat) {
        if (exportFormatData[myFormat]) {
            const filename = exportFilenameForTarget(exportTarget, myFormat);
            displayMolDataDownload(filename, myFormat, exportFormatData[myFormat]);
        } else {
            setLoading(true, `Retrieving data for ${myFormat.toUpperCase()} format...`);
            const res = await fetchExportData(exportTarget, myFormat);
            displayContent(res, myFormat);
        }
    }

    function handleChange(e) {
        setFormat(e.target.value);
        update(e.target.value);
    }

    function displayContent({ error: myError, filename, data }, myFormat) {
        if (myError) {
            displayError(myError);
            return;
        }

        if (!data) {
            displayError(`No data was produced for ${myFormat}`);
            return;
        }

        cacheFormatData(myFormat, data);
        displayMolDataDownload(filename, myFormat, data);
    }

    function displayError(messageIn) {
        // Naively reduce the error message to what might be user appropriate.
        // (but not actually displayed)
        let message = messageIn;
        const sep = message.indexOf(':');
        if (sep > -1) message = message.substr(sep+1);

        setError(messageIn);
        setLoading(false);
    }

    function updateFormatOptions(target = {}) {
        const { atoms } = target;
        if (!atoms) {
            return null;
        }

        const defaultProteinExportFormat = 'pdb';
        const haveProteinAtoms = atoms.find((atom) => App.Workspace.isProteinAtom(atom));
        const isFormatAvailable = (frmt) => !haveProteinAtoms || isProteinExportFormat(frmt);
        const atts = {};

        for (const frmt of Object.keys(options)) {
            atts[frmt] = {};
            if (isFormatAvailable(frmt)) {
                atts[frmt]['disabled'] = false;
            } else {
                atts[frmt]['disabled'] = true;
                const title = 'Selected protein atoms can only be exported in '
                + `the following formats: ${getProteinExportFormats().join(', ')}`;
                atts[frmt]['title'] = title;
            }
        }

        setOptionsAttributes(atts);
        if (haveProteinAtoms) {
            setFormat(defaultProteinExportFormat);
            return defaultProteinExportFormat;
        }

        return null;
    }

    function displayMolDataDownload(filename, myFormat, data) {
        const title = `Download ${myFormat.toUpperCase()} (${filename})`;
        if (blobURL) {
            freeBlobURL(blobURL);
        }
        const blobURLNext = textToBlobURL(data);
        setBlobURL(blobURLNext);
        setDownloadLinkProps({
            href: blobURLNext,
            download: filename,
            title,
        });
        setError(null);
        setLoading(false);
    }

    useEffect(() => {
        if (selected) {
            const preSelectFormat = updateFormatOptions(exportTarget);
            update(preSelectFormat || format);
        }
    }, [selected, exportTarget]);

    const clipboardData = exportFormatData[format] || '';

    return (
        <>
            <Grid container wrap="nowrap" style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                <Select value={format} onChange={handleChange} label="Choose Format" id="exportFormat">
                    {Object.keys(options).map((frmt) => {
                        const { label } = options[frmt];

                        return (
                            <option
                                key={frmt}
                                value={frmt}
                                {...optionsAttributes[frmt]}
                            >
                                {label}
                            </option>
                        );
                    })}
                </Select>
                <IconButton disabled={!clipboardData || error} {...downloadLinkProps} size="large">
                    <GetAppIcon />
                </IconButton>
                <CopyToClipboardButton
                    copyTarget={clipboardData}
                    tooltopMessage={<span style={{ fontSize: '1em' }}>{`${format.toUpperCase()} data copied to clipboard`}</span>}
                    IconButtonProps={{ size: 'large' }}
                />
            </Grid>
            <DataTextArea
                minRows={20}
                // Note: maxRows here doesn't affect height but avoids an MUI scrolling issue.
                // Without this, MUI will add overflow=hidden to the textarea style when there is
                // long content in the textarea
                maxRows={20}
                value={error || clipboardData}
                readOnly
                error={!!error}
            />
        </>
    );
}
