// CmdInterpreter.js
/**
 * @fileoverview CmdInterpreter validates and interprets commands returned by agent API.
 */
import { UserActions } from 'BMapsCmds';
import { BackgroundColors } from 'BMapsSrc/themes';

// Validation information
const knownCmdValidations = {
    SetView: {
        argInfo: [{
            options: ['protein', 'ligand', 'publication'],
        }],
    },
    ResetView: {
        argInfo: [],
    },
    SetDisplayStyle: {
        argInfo: [{
            options: ['default', 'wireframe', 'sticks', 'ballandstick', 'spacefill', 'cartoon', 'surface'],
        }],
    },
    SetHotspots: {
        argInfo: [{
            type: 'boolean',
        }],
    },
    SetColorScheme: {
        argInfo: [{
            options: Object.keys(BackgroundColors),
        }],
    },
    Select: {
        argInfo: [{
            type: 'string',
            // Ideally this should validate the select argument semantics:
            // either a selection keyword or an existing resSpec or compound name
        }],
    },
    OpenDock: {
        argInfo: [],
    },
    RequestMinimize: {
        argInfo: [],
    },
    ActivateNextCompound: {
        argInfo: [{
            type: 'number',
        }],
    },
};

export default class CmdInterpreter {
    /**
     * Apply a command array returned by agent API.
     * This validates and executes each command representation.
     * @param {Array<[string, ...]>} cmdArray
     */
    static async interpretCommand(cmdArray) {
        if (!cmdArray || cmdArray.length === 0) {
            console.log('Could not interpret command from words');
            return;
        }
        console.log(`Applying interpreted command from words: ${cmdArray}`);

        for (const cmdUnit of cmdArray) {
            const { action, args, error } = CmdInterpreter.validateCommand(cmdUnit);
            if (error) {
                console.log(`Command validation error: ${error}`);
                continue;
            }
            await UserActions[action](...args);
        }
    }

    /**
     * Validates a command action and arguments, returning them if valid.
     * @param {[string, ...]} cmdUnit
     * @returns {{ action: string, args: [] } | { error: string }}
     */
    static validateCommand(cmdUnit) {
        if (!Array.isArray(cmdUnit) || cmdUnit.length === 0) {
            return { error: 'Invalid command unit' };
        }
        const [action, ...args] = cmdUnit;
        const { argInfo } = knownCmdValidations[action] || {};
        if (!argInfo) {
            return { error: `Invalid action ${action}` };
        }

        if (args.length !== argInfo.length) {
            return { error: `Invalid number of arguments for ${action}` };
        }

        for (let i = 0; i < args.length; i++) {
            const error = CmdInterpreter.validateArg(args[i], argInfo[i]);
            if (error) return { error: `Invalid argument ${i} for ${action}: ${error}` };
        }
        return { action, args };
    }

    /**
     * Validate a single command argument
     * @param {any} arg
     * @param {{ type: string?, options: []?}} argInfo
     * @returns {string?} error string or null if successful
     */
    static validateArg(arg, argInfo) {
        const { type, options } = argInfo;
        if (type) {
            const provided = typeof arg;
            switch (type) {
                case 'string':
                    // Note: eslint enforces always comparing typeof results to string literals
                    if (provided !== 'string') return `${type} required, ${provided} provided`;
                    break;
                case 'boolean':
                    if (provided !== 'boolean') return `${type} required, ${provided} provided`;
                    break;
                // no default
            }
        }
        if (options) {
            if (!options.includes(arg)) return 'invalid option';
        }
        return null;
    }
}
