/** @module CDDVData.js handles cddvault data and connection
 *
 */

// API fields are snake_cased so variable names and functions follow suit. Disable camelcase.
/* eslint-disable camelcase */

import { UserActions } from 'BMapsCmds';
import { MolDataSource, MoleculeLoadOptions } from 'BMapsSrc/utils';
import CDDVProtocol from './CDDVProtocol';
import { molfileNeeds3D } from '../../util/mol_format_utils';

const PAGE_SIZE = 20;

/**
 * The primary driver class for CDD vault, handling requests and stored data
 */
export class CDDVIntegration {
    /**
     * constructor with extra steps
     * @returns a CDDVIntegration instance
     */
    static getInstance() {
        if (CDDVIntegration.Instance == null) {
            CDDVIntegration.Instance = new CDDVIntegration();
        }
        return CDDVIntegration.Instance;
    }

    /**
     * build the instance with empty properties
     */
    constructor() {
        this.connected = false;
        this.vaults = [];
    }

    /**
     * get a specific CDD vault
     * @param {*} id the id of the requested vault
     * @returns the requested vault
     */
    getVault(id) {
        return this.vaults.find((vault) => vault.id === id);
    }

    // **** METHODS THAT SEND TO CDDV PROTOCOL ****

    // Setup / login methods

    /**
     * Calls the server function to see if CDDV is logged in, and, if it is
     * handle setting up
     * @param {function} setVaultsLoaded state mutator so react knows to rerender
     */
    async check_setup(setVaultsLoaded) {
        const setupResponse = await CDDVProtocol.check_setup();
        if (setupResponse.success) {
            this.connected = true;
            this.processVaults(setupResponse.data, setVaultsLoaded);
        } else {
            this.connected = false;
        }
        return this.connected;
    }

    /**
     * Calls the server function to log in to CDD, followed by processing the most
     * basic part of setup, enumerating and processing the vaults
     * @param {string} token CDD authentication token
     * @param {function} setVaultsLoaded state mutator so react knows to rerender
     * @returns new connected state of CDDVIntegration
     */
    async setup(token, setVaultsLoaded) {
        const setupResponse = await CDDVProtocol.setup(token);
        let errMsg = null;
        if (setupResponse.success) {
            this.connected = true;
            this.processVaults(setupResponse.data, setVaultsLoaded);
        } else {
            this.connected = false;
            const prob = setupResponse.data ? JSON.stringify(setupResponse.data) : 'Unknown error';
            errMsg = `CDD Vault API key registration failed: ${prob}`;
        }

        return { connected: this.connected, errMsg };
    }

    /**
     * Calls the server to erase the auth token and clears out all our data
     */
    async clear_setup() {
        const setupResponse = await CDDVProtocol.clear_setup();
        if (setupResponse.success) {
            this.connected = false;
            this.vaults = [];
        }
    }

    // Methods dealing with projects

    /**
     * fetches and processes the projects in a given vault
     * @param {Number} vaultId id of the vault to be accessed
     */
    async list_projects(vaultId) {
        const { data, request, success } = await CDDVProtocol.list_projects(vaultId);
        if (success) {
            this.processProjects(request.vaultId, data);
        } else {
            console.warn('list_projects failed');
        }
    }

    /**
     * fetches the molecules from a given CDDV project
     * @param {Number} vaultId id of the relevant vault
     * @param {Number} projectId id of the target project
     * @param {Number} page_size how many CDDV will return, maximally. API is 5-1000
     * @param {Number} offset where in the list of projects to start
     * @returns an array of CDDV's molecule objects
     */
    fetch_molecules(vaultId, projectId, page_size=PAGE_SIZE, offset=0) {
        return CDDVProtocol.fetch_molecules(vaultId, projectId, page_size, offset);
    }

    // Methods dealing with saved searches

    /**
     * fetches and processes the saved searches in a given vault
     * @param {Number} vaultId the id of the target vault
     */
    async list_searches(vaultId) {
        const { data, request, success } = await CDDVProtocol.list_searches(vaultId);
        if (success) {
            this.processSearches(request.vaultId, data);
        } else {
            console.warn('list_searches failed');
        }
    }

    /**
     * Starts an async search on CDDV from saved searches
     * @param {Number} vaultId the relevant vault
     * @param {Number} searchId the id of the saved search
     * @returns a Promise that resolves to CDDV's response to the start search query
     * It contains response, including datetime information and an id for the search job.
     */
    start_search(vaultId, searchId) {
        return CDDVProtocol.start_search(vaultId, searchId);
    }

    /**
     * checks on the progress of an async search on CDDV
     * CDD recommends running this every 5-10 seconds after starting a search
     * @param {Number} vaultId the relevant vault
     * @param {Number} jobId the id of the active search job
     * @returns a Promise that resolves to CDDV's response to the check search query
     * It contains datetime information and the search job's status
     */
    check_search(vaultId, jobId) {
        return CDDVProtocol.check_search(vaultId, jobId);
    }

    /**
     * fetches the results of an async search on CDDV
     * @param {Number} vaultId id of the relevant vault
     * @param {Number} jobId the id of the active search job
     * @returns a Promise that resolves to a response packet that contains
     * an array of the molecules that match the search criteria
     */
    fetch_search(vaultId, jobId) {
        return CDDVProtocol.fetch_search(vaultId, jobId);
    }

    // Method to upload molecule to CDDV

    /**
     * Upload a molecule to CDDV
     * @param {Number} vaultId target vault's id
     * @param {Number} projectId target project's id
     * @param {String} molName name of uploaded molecule
     * @param {String} smiles smiles format data of molecule
     * @param {String} molData mol2 format data of molecule
     * @returns a promise that resolves to CDDV's response packet,
     * including the molecule's new CDDV id
     */
    upload_molecule(vaultId, projectId, molName, smiles, molData) {
        const query = CDDVProtocol.uploadArgs(projectId, molName, smiles, molData);
        return CDDVProtocol.upload_molecule(vaultId, JSON.stringify(query));
    }

    // **** METHODS PROCESSING INBOUND CDDV DATA ****

    /**
     * fetches the projects and searches associated with each vault
     * @param {array} vaultData array containing the vaults supplied by CDDV's login response
     * @param {function} setVaultsLoaded react state mutator so react knows it can render the vaults
     */
    async processVaults(vaultData, setVaultsLoaded) {
        const promiseArray = [];
        for (const vault of vaultData) {
            if (!this.getVault(vault.id)) {
                this.vaults.push(new CDDVVault(vault.id, vault.name));
                promiseArray.push(this.list_projects(vault.id));
                promiseArray.push(this.list_searches(vault.id));
            }
        }
        await Promise.all(promiseArray);
        setVaultsLoaded(this.vaults.length > 0);
    }

    /**
     * processes projects for a given vault
     * @param {Number} vaultId id of relevant vault
     * @param {array} projectData array of projects from CDDV
     */
    processProjects(vaultId, projectData) {
        const vault = this.getVault(vaultId);
        if (vault) {
            for (const project of projectData) {
                vault.addProject(new CDDVProject(
                    project.id, project.name, vaultId, CDDVIntegration.getInstance()
                ));
            }
        } else {
            console.warn(`processProjects: No vault found with id ${vaultId}`);
        }
    }

    /**
     * processes saved searches for a given vault
     * @param {Number} vaultId id of relevant vault
     * @param {array} searchData array of saved searches from CDDV
     */
    processSearches(vaultId, searchData) {
        const vault = this.getVault(vaultId);
        if (vault) {
            for (const search of searchData) {
                vault.addSearch(new CDDVSearch(
                    search.id, search.name, vaultId, CDDVIntegration.getInstance()
                ));
            }
        } else {
            console.warn(`processSearches: No vault found with id ${vaultId}`);
        }
    }

    // **** METHODS DEALING WITH DATA BOUND FOR BFD-SERVER ****

    /**
     * Return a MolSource for CDDV molecule data
     * @param {Number} molId the molecule's unique id on CDDV
     * @param {String} molName the molecule's name
     * @param {String} molFormat the molecule's data format
     * @param {String} molData the molecule's structural data
     * @returns {MolDataSource}
     */

    molSourceForCDDV(molId, molName, molFormat, molData) {
        return new MolDataSource({
            sourceType: 'CDDV', sourceId: molId, compoundName: molName, molFormat, molData,
        });
    }

    /**
     * Loads a molecule from CDDV into BMaps
     * @param {Number} molId the molecule's unique id on CDDV
     * @param {String} molName the molecule's name
     * @param {String} molFormat the molecule's data format
     * @param {String} molData the molecule's structural data
     * @param {Boolean} doAlign should bmaps align the molecule while importing?
     */
    loadMolecule(molId, molName, molFormat, molData, doAlign) {
        let alignAction;
        if (doAlign) {
            alignAction = MoleculeLoadOptions.Align;
            if (molfileNeeds3D(molData)) alignAction.addOptions({ gen3d: true });
        } else {
            alignAction = MoleculeLoadOptions.NoAlignment;
        }
        const molSource = this.molSourceForCDDV(molId, molName, molFormat, molData);
        UserActions.LoadMolData(molSource, alignAction);
    }
}

/**
 * a container class to hold information and data associated with a given vault
 */
export class CDDVVault {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        this.projects = [];
        this.searches = [];
    }

    addProject(project) {
        if (this.getProject(project.id)) return;
        this.projects.push(project);
    }

    getProject(id) {
        return this.projects.find((project) => project.id === id);
    }

    addSearch(search) {
        if (this.getSearch(search.id)) return;
        this.searches.push(search);
    }

    getSearch(id) {
        return this.searches.find((search) => search.id === id);
    }
}

/**
 * parent class for searches and groups
 * don't try to instantiate this, it won't work very well
 */
class CDDVGroup {
    constructor(id, name, vaultId, instance) {
        this.CDDVInstance = instance;
        this.id = id;
        this.name = name;
        this.vaultId = vaultId;
        this.molPages = [];
        this.startedFetch = [];
        this.count = -1;
    }

    /**
     * Boolean function to check the readiness of a given page of molecules
     * @param {Number} offset which molecules do we care about relative to the start of the list
     * @returns true if the molecules are ready to display or there aren't any at all
     * false otherwise
     */
    molsReady(offset) {
        if (this.count === 0) return true;
        if (this.molPages[offset/PAGE_SIZE]) return true;
        return false;
    }

    /**
     * adds molecules to a group object. They are organized by page, analagously to the UI
     * @param {Array} mols array of molecules to go in this page
     * @param {Number} offset where are they from in the list, so we know what page to use
     */
    addMols(mols, offset) {
        const molPage = [];
        mols.forEach((mol) => {
            const vaultId = this.vaultId;
            let format = 'smi';
            let molData = mol.smiles;
            if (mol.inchi) {
                format = 'inchi';
                molData = mol.inchi;
            }
            // Windows version can't do INChI so do molfile last
            if (mol.molfile) {
                format = 'mol';
                molData = mol.molfile;
            }

            // Get 3D structure from custom field
            // CDD Vault molecules are general and don't have 3D coordinates,
            // since any given molecule could be in any experiment.
            // Batches are particular "instantiations" of molecules in an experiment,
            // so that is where we store the custom field for our 3D coordinates.
            // Here we just take the most recent version. In principle, we could let the user pick.
            for (let i = mol.batches.length-1; i >= 0; i--) {
                const batch = mol.batches[i];
                if (batch.batch_fields && batch.batch_fields.structure_bmaps) {
                    molData = batch.batch_fields.structure_bmaps;
                    format = 'mol2';
                    break;
                }
            }
            molPage.push({
                ...mol, vaultId, format, molData,
            });
        });
        this.molPages[offset/PAGE_SIZE] = molPage;
    }
}

/**
 * class for CDDV projects
 * contains the functions that differ between searches and projects
 */
export class CDDVProject extends CDDVGroup {
    /**
     * getter to retrieve some molecules from the CDDV project
     * @param {function} finishCallback callback to let the caller know when it's ready
     * @param {Number} offset which molecules
     * @returns an array of molecules if they are downloaded, null if they are not
     */
    getMols(finishCallback, offset=0) {
        if (this.molPages[offset/PAGE_SIZE]) return this.molPages[offset/PAGE_SIZE];

        if (!this.startedFetch[offset/PAGE_SIZE]) {
            this.fetchMolecules(finishCallback, offset);
            this.startedFetch[offset/PAGE_SIZE] = true;
        }
        return null;
    }

    /**
     * requests the molecules from CDDV, loads them into the project object, then pings react
     * @param {function} finishCallback callback to let the caller know it's done
     * @param {Number} offset which molecules
     */
    async fetchMolecules(finishCallback, offset=0) {
        const { success, data } = await this.CDDVInstance.fetch_molecules(
            this.vaultId, this.id, PAGE_SIZE, offset
        );
        if (success) {
            const allMols = data.objects;
            super.addMols(allMols.filter((o) => o.smiles), offset); // Only want mols with structure
            this.count = data.count;
            finishCallback();
        } else {
            console.warn('CDDVProject.fetchMolecules query failed');
            finishCallback(false);
            this.startedFetch[offset/PAGE_SIZE] = false;
        }
    }
}

/**
 * class for CDDV saved searches
 * contains the functions that differ between searches and projects
 */
export class CDDVSearch extends CDDVGroup {
    /**
     * getter to retrieve some molecules from the CDDV search
     * @param {function} finishCallback callback to let the caller know when it's ready
     * @param {Number} offset which molecules, currently unused
     * @returns an array of molecules if they are downloaded, null if they are not
     */
    getMols(finishCallback, offset=0) {
        if (this.molPages[offset/PAGE_SIZE]) return this.molPages[offset/PAGE_SIZE];

        if (!this.startedFetch[0]) {
            this.startSearch(finishCallback);
            this.startedFetch[0] = true;
        }
        return null;
    }

    /**
     * sends a request to CDDV to start the saved search, then begins checking on it
     * @param {function} finishCallback
     */
    async startSearch(finishCallback) {
        const { success, data } = await this.CDDVInstance.start_search(this.vaultId, this.id);
        if (success) {
            const jobId = data.id;
            this.checkSearch(jobId, finishCallback);
        } else {
            console.warn('CDDV search error in startSearch');
            finishCallback(false);
            this.startedFetch[0] = false;
        }
    }

    /**
     * check the status of the search, repeatedly, then download when it's finished
     * @param {Number} jobId the search job's id
     * @param {function} finishCallback
     */
    async checkSearch(jobId, finishCallback) {
        const { success, data } = await this.CDDVInstance.check_search(this.vaultId, jobId);
        if (success) {
            const { status } = data;

            switch (status) {
                case 'new':
                case 'started':
                    setTimeout(() => this.checkSearch(jobId, finishCallback), 5000);
                    break;
                case 'finished':
                case 'downloaded':
                    this.fetchSearch(jobId, finishCallback);
                    break;
                default:
                    console.warn(`Unexpected status for successful checkSearch ${status}`);
            }
        } else {
            console.warn('check_search request to CDDV failed');
        }
    }

    /**
     * retrieve the molecules from a finished search
     * currently only fetches the first 50
     * @param {Number} jobId the search job's id
     * @param {function} finishCallback E.T. phone home
     */
    async fetchSearch(jobId, finishCallback) {
        const { success, data } = await this.CDDVInstance.fetch_search(this.vaultId, jobId);
        if (success) {
            const allMols = data.objects;
            const someMols = allMols.filter((o) => o.smiles); // Only want mols with structure
            this.count = someMols.length;
            for (let offset = 0; offset < this.count; offset += PAGE_SIZE) {
                super.addMols(someMols.slice(offset, offset + PAGE_SIZE), offset);
            }
            finishCallback();
        } else {
            console.warn('fetch_search request to CDDV failed');
        }
    }
}
