import { Decoder } from '../decode';
import { EventBroker } from '../eventbroker';
import { ServerCmd, ServerCmds } from '../server_cmds';
import { ServerCmdProcessingQueue } from '../server_responders';

export class BFDServerInterface {
    constructor() {
        /** @type {ServerCmdProcessingQueue} */
        this.cmdProcessingQueue = new ServerCmdProcessingQueue(() => EventBroker.publish('serverQueueUpdate'));
        /** @type {Decoder}  */
        this.decoder = new Decoder(); // Where should this really live?
        /** @type {DataConnection} */
        this.dataConnection = null;
        // Add functions to call each ServerCmd
        for (const [cmdName, serverCmd] of Object.entries(ServerCmds)) {
            this[`cmd${cmdName}`] = (...args) => this.execCmd(cmdName, serverCmd, ...args);
        }

        EventBroker.subscribe('zapAll', () => {
            this.cmdProcessingQueue.reset();
            this.decoder.resetStorage();
        });
    }

    // Abstract methods, must be overridden
    execCmd(cmdName, serverCmd, ...args) {
        abstractImplWarning(cmdName);
        return null;
    }

    /**
     * Directly send "zap-all" to the server interface, don't set up any response handling.
     */
    zapNoWait() {
        abstractImplWarning('zapNoWait');
        return null;
    }

    isConnected() {
        abstractImplWarning('isConnected');
        return null;
    }

    get connectionInfo() {
        abstractImplWarning('connectionInfo');
        return null;
    }

    /** @returns {'server' | 'static' | null} */
    getMode() {
        abstractImplWarning('getMode');
        return null;
    }

    // Can be overridden
    monitorConnection() { }

    getLabel() {
        return JSON.stringify(this.connectionInfo);
    }

    /** @returns {'server' | 'static' | null} */
    getModeForSavedState() {
        return this.getMode();
    }

    // "Public"
    setDecoderStorage(decoderStorage) {
        this.decoder.setStorage(decoderStorage);
    }

    getDataConnection() { return this.dataConnection; }
    setDataConnection(dataConnection) { this.dataConnection = dataConnection; }

    // "Protected" methods
    /**
     *
     * @param {ServerCmd} serverCmd
     * @param {string} requestId
     * @param {*} args
     * @returns
     */
    setupServerResponder(serverCmd, requestId, args) {
        const serverKey = this.connectionInfo.key;
        const cmdInfo = { name: serverCmd.cmdName, args, serverKey };
        return this.cmdProcessingQueue.processCmdResponses(
            requestId, serverCmd.scalarResponse, cmdInfo, this.decoder,
        );
    }

    get workingCommand() {
        const key = this.connectionInfo.key;
        return this.cmdProcessingQueue.currentCmd(key);
    }

    get commandQueue() {
        const key = this.connectionInfo.key;
        return [...this.cmdProcessingQueue.queue(key)];
    }
}

function abstractImplWarning(msg) {
    console.error(`Attempting to run ${msg} on abstract BFDServerInterface`);
}
