// main.js

import { UserActions } from 'BMapsCmds';
import { makeBFMViewer } from './bfm_viewer';
import { initMenus } from './menu_cmds';
import { Loader } from './Loader';
import { BrowserApp } from './BrowserApp';
import { LoadingScreen } from './LoadingScreen';
import { EventBroker } from './eventbroker';
import { initNavigationHandlers } from './page_nav';
import { BrowserWebSocketConn } from './server/BrowserWebSocketConn';
import { BMapsExecStrategy, DevServerSandboxStrategy, LocalModeStrategy } from './server/WebSocketStrategy';
import { initInfoDisplay } from './ui/init_info_display';

async function startApp(staticMode=false) {
    const websocketStrategy = determineWebsocketStrategy();
    await BrowserApp.Init({ staticMode, websocketStrategy });
    initNavigationHandlers();
}

function isStaticMode() {
    const params = new URLSearchParams(window.location.search);
    const staticParam = params.get('static');
    const previewParam = params.get('preview');
    return (staticParam && staticParam !== 'false') || previewParam != null;
}

function determineWebsocketStrategy() {
    const wsClass = BrowserWebSocketConn;
    if (LocalModeStrategy.needThisStrategy()) {
        return new LocalModeStrategy({ wsClass });
    } else if (DevServerSandboxStrategy.needThisStrategy()) {
        return new DevServerSandboxStrategy({ wsClass });
    }
    return new BMapsExecStrategy({ wsClass });
}

/**
 * Parse viewer options from the query string to pass into viewer setup.
 * The 3D viewer is actually created before the BMapsApp (created at connection time)
 * so the query string has to be parsed here and passed into makeBFMViewer().
 *
 * @todo Would be nice to have a single point of query string parsing used for
 * viewerOptions here, static mode above, options in BrowserApp.js, and maybe
 * other Preferences like EnergyEngine.
 *
 */
function parseViewerOptions() {
    const viewerOptions = {};
    const params = new URLSearchParams(window.location.search);

    let cartoonQuality = params.get('cartoonQuality');
    cartoonQuality = cartoonQuality != null ? Number(cartoonQuality) : NaN;
    if (!Number.isNaN(cartoonQuality)) {
        viewerOptions.cartoonQuality = cartoonQuality;
    }
    return viewerOptions;
}

/**
 * @description Start the app in static mode
 */
function startStaticMode() {
    startApp(true);
}

/**
 * @description Get a server connection and start the server-mode app
 */
function startServerMode() {
    LoadingScreen.withLoadingScreen(startApp(), 'Establishing connection...');
}

/**
 * Add an event so select2 text boxes will receive focus on open, because
 * they're bugged as of jQuery 3.6.0
 * @see {@link https://github.com/select2/select2/issues/5993 issue and workaround}
 */
function jQueryFocusWorkaround() {
    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });
}

export default async function main() {
    require('../lib/js/jquery.alerts'); // eslint-disable-line global-require
    require('../lib/js/jquery-ui'); // eslint-disable-line global-require
    require('../lib/js/select2.full.min'); // eslint-disable-line global-require
    require('tooltipster'); // eslint-disable-line global-require

    console.log('calling main');
    const viewerOptions = parseViewerOptions();
    // Replace loading_pane (from html payload) with javascript-requested LoadingScreen
    $('#loading_pane').hide();
    EventBroker.publish('displayLoadingScreen', { on: true, msg: 'Loading Boltzmann Maps...' });
    Loader.Load();
    initMenus();
    makeBFMViewer(viewerOptions);
    initInfoDisplay();

    UserActions.SetColorScheme();
    $('#topbar-menu-button').tooltipster({ side: 'left' });
    $('.topbar-button').tooltipster();
    jQueryFocusWorkaround();
    if (isStaticMode()) {
        startStaticMode();
    } else {
        // Start the app after getting a server session
        startServerMode();
    }
}
