import { hbondExists } from 'BMapsSrc/interactions';
import { pointDistanceSquared } from './atom_distance_utils';
import AmberData from '../chemistry/AmberData';
import { isHydrogen } from './chem_utils';
import { getFullResidueId } from './mol_info_utils';
import { fixFloat } from '../math';

/** @type number */
export const defaultClashingTolerance = 0.21;

/**
 * Return atom's radius based on Amber data.
 * @param {Atom} atom
 * @returns {?number}
 */
export function getAtomRadius(atom) {
    const radiusData = AmberData.getRadiusData(atom.amber);
    return radiusData != null ? radiusData.radius : null;
}

export function getClashingDistance(atom1, atom2, tolerance=defaultClashingTolerance) {
    const [rad1, rad2] = [atom1, atom2].map((atom) => getAtomRadius(atom));
    if (!rad1 || !rad2) {
        // No clash if radius is 0 or null
        return null;
    }
    const clashingDistance = (rad1 + rad2) * AmberData.vdWFactor - tolerance;
    return clashingDistance;
}

/**
 * @description Detects if atoms in atom1 are clashing with atoms in atom2
 * @param {Atom|Atom[]} targetAtomOrAtoms The primary atoms we're checking for a clash on
 * @param {Atom|Atom[]} refAtomOrAtoms The reference atoms we're checking against
 * @param {{ includeH: Boolean?, reportAll: Boolean?}}
 *     includeH - if true, also check hydrogens for clashes
 *     reportAll - if true, return all clashing atoms; if false, just the first detected clash
 * @returns { {atom1: Atom, atom2: Atom}[]? }
 */
export function atomsClashing(
    targetAtomOrAtoms, refAtomOrAtoms,
    { includeH, reportAll=true, tolerance }={}
) {
    const atoms1 = Array.isArray(targetAtomOrAtoms) ? targetAtomOrAtoms : [targetAtomOrAtoms];
    const atoms2 = Array.isArray(refAtomOrAtoms) ? refAtomOrAtoms : [refAtomOrAtoms];

    const included1 = atoms1.filter((a) => AmberData.includeForRadius(a.amber, a.elem, includeH));
    const included2 = atoms2.filter((a) => AmberData.includeForRadius(a.amber, a.elem, includeH));
    const clashingAtoms = [];

    for (const atom1 of included1) {
        for (const atom2 of included2) {
            if (atomsClashingInternal(atom1, atom2, tolerance)) {
                clashingAtoms.push({ atom1, atom2 });
                if (!reportAll) {
                    return clashingAtoms;
                }
            }
        }
    }

    return clashingAtoms.length > 0 ? clashingAtoms : null;
}

/**
 * Detect a steric clash between two atoms.
 *
 * Atoms are clashing if atom distance is less than the vdW distance,
 * where the vdW distance is the sum of the vdW radii defined in amber, times the vdWFactor:
 *     vdWDist = vdWFactor * (radius1 + radius2)
 * We need to subtract a tolerance term from the clashing distance,
 * otherwise some crystal ligands would report a clash with the protein.
 *
 * As normal when comparing distances, use distances squared to avoid calculating the sqrt.
 *
 * If one of the radii is null or 0, the atoms do not clash (per jlkjr)
 *
 * @param {Atom} atom1
 * @param {Atom} atom2
 * @returns {boolean} are the atoms clashing
 */
function atomsClashingInternal(atom1, atom2, tolerance) {
    const clashingDistance = getClashingDistance(atom1, atom2, tolerance);
    if (clashingDistance === null) {
        // No clash if failed to get clashDistance
        return false;
    }
    const distanceSquared = pointDistanceSquared(atom1, atom2);
    const clashingDistanceSquared = clashingDistance ** 2;
    let isClashing = fixFloat(distanceSquared - clashingDistanceSquared) < 0;
    if (isClashing && ignoredCertainBondClashes(atom1, atom2)) {
        isClashing = false;
    }
    return isClashing;
}

/**
 * Check for hydrogen bonds and covalent bonds.
 */
function ignoredCertainBondClashes(atom1, atom2) {
    const group1 = [atom1, ...atom1.bondedAtoms];
    const group2 = [atom2, ...atom2.bondedAtoms];
    const collectIgnoredBonds = (atom, otherAtoms) => {
        const ret = [];
        for (const otherAtom of otherAtoms) {
            // Check hbonds (only check non-H to H)
            if (!isHydrogen(atom) && isHydrogen(otherAtom) && hbondExists(atom, otherAtom)) {
                ret.push({ atom, otherAtom, kind: 'hbond' });
            }
            // Check covalent bonds
            const differentResidue = getFullResidueId(atom) !== getFullResidueId(otherAtom);
            if (atom.bondTo(otherAtom) && differentResidue) {
                // We may report covalent bonds in both directions.
                // I'd rather not do the work to detect that just to avoid duplicates in the logs.
                ret.push({ atom, otherAtom, kind: 'covalent bond' });
            }
        }
        return ret;
    };
    const ignoredBonds = [
        ...collectIgnoredBonds(atom1, group2),
        ...collectIgnoredBonds(atom2, group1),
    ];
    if (ignoredBonds.length > 0) {
        const atomDesc = (atom) => `${getFullResidueId(atom)}-${atom.atom}`;
        const clashDesc = ({ atom, otherAtom, kind }) => `${kind}: ${atomDesc(atom)} ${atomDesc(otherAtom)}`;
        console.log(`Ignoring clash between ${atomDesc(atom1)} and ${atomDesc(atom2)} because: ${ignoredBonds.map(clashDesc).join('; ')}`);
        return true;
    }
    return false;
}
