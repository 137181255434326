/* binding_site.js */

/**
 * @fileoverview This is a class about BindingSites.
 *
 * A binding site is a region that we might want to zoom in on.
 * It is defined by a group of reference atoms and a radius.
 *
 * Separation of concerns:
 * When zooming into a binding site, we need to zoom on atoms.
 * How do we get those atoms? A few components need to work together:
 * 1) The Workspace knows the available CaseData and also knows component visibility
 * 2) The CaseData knows what components and atoms it has
 * 3) The BindingSite knows how to filter components and atoms according to their location
 *
 * Additional complications:
 * 1) When hot spots are visible, protein residues around the hot spots should also be visible.
 *    This is currently handled as a special case within display_mgr.
 * 2) Chain atoms exist in both Protein / Polymer and Residue MolAtomGroups.
 *    When doing getAtomsInRange, roundup is on by default, rounding up to the residue,
 *    but getComponentsInRange / isComponentInRange require care.
 *    Eg. display_mgr displayProteinSurface converts to residues before calling getComponentsInRange
 *
 * Asking about specific atoms:
 * - getAtomsInRange(atoms) - filter atoms according to binding site, rounding up by default
 * - anyAtomInRange(atoms) - return whether any atom is in range
 * Asking about specific components (see complication #2 above):
 * - getComponentsInRange(components) - filter components according to binding site
 * - isComponentInRange(component) - return whether of any of the component's atoms are in range
 * Asking about all loaded data:
 * - getBindingSiteAtomsForTypes(workspace)
 * - getAllBindingSiteAtoms(workspace)
 * - getSoluteAtoms(workspace)
 * - getComputedWaterAtoms(workspace)
 * - getVisibleCompoundAtoms(workspace)
 *
 */
import { DefaultBindingSiteRadius } from '../utils';
import {
    roundupAtoms, getBoundingBox, atomsInRange, anyAtomInRange, getPositionArray,
} from '../util/atom_distance_utils';
import { AtomGroup, Hotspot } from './atomgroups';
import { Atom } from './atoms';
import { ensureArray } from '../util/js_utils';
import { getBindingSiteDistance } from '../redux/prefs/access';

export class BindingSite {
    constructor({ refObject, refAtoms }={}, radius=DefaultBindingSiteRadius) {
        let atoms;
        // Note refObject isn't currently used
        if (refObject) {
            atoms = refObject.getAtoms();
        } else if (refAtoms) {
            atoms = refAtoms;
        } else {
            throw new Error('Binding site needs either refAtoms or refObject');
        }

        this.refObject = refObject;
        this.setDefinition(atoms, radius);
    }

    setDefinition(atoms, radius) {
        this.atoms = atoms;
        this.radius = radius;
    }

    getRefAtoms() {
        return [...this.atoms];
    }

    getRefAtomsCount() {
        return this.atoms.length;
    }

    getBoundingBox() {
        return getBoundingBox(this.getRefAtoms(), this.radius);
    }

    dumpState() {
        const {
            lowX, lowY, lowZ, highX, highY, highZ,
        } = this.getBoundingBox();
        const boxDesc = `Box (loX/hiX, loY/hiY, loZ/hiZ) = (${lowX.toFixed(2)}/${highX.toFixed(2)}, ${lowY.toFixed(2)}/${highY.toFixed(2)}, ${lowZ.toFixed(2)}/${highZ.toFixed(2)})`;
        return `Binding site around ${this.getRefAtomsCount()} atoms. ${boxDesc}`;
    }

    anyAtomInRange(atoms) {
        return anyAtomInRange(
            this.atoms.map(getPositionArray),
            atoms,
            this.radius,
            { boundingBox: this.getBoundingBox() }
        );
    }

    getAtomsInRange(atoms, roundup=true) {
        return atomsInRange(
            this.atoms.map(getPositionArray),
            atoms,
            this.radius,
            { roundup, boundingBox: this.getBoundingBox() },
        );
    }

    getComponentsInRange(components) {
        const result = components.filter((comp) => this.isComponentInRange(comp));
        return result;
    }

    // Where is isAtomInRange() ?
    // It seems desirable to have a function asking if a particular atom is within the binding site.
    // However, because we round up residues, asking about a specific atom isn't what we want;
    // rather we'd want to ask if any of the atoms in that atom's residue are in range.
    // The implementation would look like: this.anyAtomInRange(roundupAtoms([atom]))
    // This hasn't not been implemented because when approaching an array of atoms, it would be
    // convenient but inefficient (but maybe still fast enough), to filter on isAtomInRange()

    // Where is getComponentAtomsInRange() ?
    // It seems desirable to have a function which filters atomgroups
    // and then returns all their atoms.
    // This is tricky because of the chain implementation. Both chains and residues are AtomGroups,
    // so if you passed in a protein chain to such a function, it would return all its atoms,
    // even those far from the binding site.
    // Addressing that case needs to be considered before adding such a function.

    isComponentInRange(component) {
        return this.anyAtomInRange(component.getAtoms());
    }

    matchesRefAtoms(otherAtoms, radius) {
        if (this.radius !== radius) {
            return false;
        }

        for (const mine of this.atoms) {
            if (!otherAtoms.includes(mine)) {
                return false;
            }
        }
        for (const theirs of otherAtoms) {
            if (!this.atoms.includes(theirs)) {
                return false;
            }
        }
        return true;
    }

    getBindingSiteAtomsForTypes(workspace, types) {
        const result = this.getAtomsInRange(
            AtomGroup.atomsInAtomGroups(workspace.atomGroupsByTypes(types))
        );
        return result;
    }

    getAllBindingSiteAtoms(workspace) {
        const result = this.getAtomsInRange(
            AtomGroup.atomsInAtomGroups(workspace.allAtomGroups())
        );
        return result;
    }

    getSoluteAtoms(workspace) {
        return this.getAtomsInRange(
            workspace.collectFromCaseData((caseData) => caseData.getSoluteAtoms())
        );
    }

    getComputedWaterAtoms(workspace) {
        return this.getAtomsInRange(
            workspace.collectFromCaseData((caseData) => caseData.getComputedWaterAtoms())
        );
    }

    getVisibleCompoundAtoms(workspace) {
        return this.getAtomsInRange(workspace.getVisibleCompoundAtoms());
    }

    static calculate(workspace, refObjIn) {
        const isProteinLoaded = workspace.isProteinLoaded();
        const activeCompound = workspace.getActiveCompound();
        const selectedAtoms = workspace.getSelectedAtoms();
        const bindingSiteDistance = getBindingSiteDistance();
        let workingBindingSite = workspace.workingBindingSite;
        let refObj = refObjIn;

        if (!isProteinLoaded) {
            return null; // We only have a binding site if there is a protein to bind in.
        }

        // Apply default reference atoms if necessary: selected atoms or active compound
        if (refObj == null) {
            if (selectedAtoms.length > 0) {
                // Roundup atoms will turn selection into entire compound/residue
                refObj = { atoms: roundupAtoms(selectedAtoms) };
            } else if (activeCompound != null) {
                refObj = { compound: activeCompound };
            }
        }

        if (refObj && (refObj.atoms || refObj.compound)) {
            const radius = refObj.radius != null ? refObj.radius : bindingSiteDistance;
            const refAtoms = refObj.atoms || refObj.compound.getAtoms();
            // Use the existing binding site if it matches the atoms
            if (workingBindingSite?.matchesRefAtoms(refAtoms, this.radius)) {
                return workingBindingSite;
            }
            // Note: this doesn't currently set the refObject in the BindingSite constructor
            workingBindingSite = new BindingSite({ refAtoms }, radius);
        } else {
            workingBindingSite = null;
        }

        return workingBindingSite;
    }

    duplicate() {
        return new BindingSite({ refAtoms: this.getRefAtoms() }, this.radius);
    }

    extend(atomsOrComponentsIn) {
        const atomsOrComponents = ensureArray(atomsOrComponentsIn);
        const newRefAtoms = new Set(this.atoms);
        for (const item of atomsOrComponents) {
            if (item instanceof Atom) {
                newRefAtoms.add(item);
            } else if (item instanceof AtomGroup || item instanceof Hotspot) {
                item.getAtoms().forEach((refAtom) => newRefAtoms.add(refAtom));
            }
        }
        this.setDefinition([...newRefAtoms], this.radius);
    }

    makeExtendedBindingSite(extendedItems) {
        const newBindingSite = this.duplicate();
        newBindingSite.extend(extendedItems);
        return newBindingSite;
    }

    updateRadius(newRadius=getBindingSiteDistance()) {
        this.setDefinition(this.atoms, newRadius);
    }
}
