import React from 'react';
import { removeSmilesMolName } from './util/mol_format_utils';

const SmilesDrawer = require('smiles-drawer');

/* SmilesDrawerReact
 * A React component to display a smiles string with SmilesDrawer.
 * Props:
 *     - canvasId : the id of the canvas element that it will create
 *     - canvasClassName : className passthrough to the canvas element
 *     - smiles : the smiles string to render
 *     - smilesDrawerOptions : SmilesDrawer options defined in the documentation
 *
 * You may want to wrap this in a div and pass the canvas a class with {height: 100%; width: 100%}
 * The canvas has to be visible in order to draw into it.
 * TODO: Figure this out before releasing to open source?
 *
 * Example:
 *     <SmilesDrawerReact
 *         canvasId="mySmilesDrawer"
 *         canvasClassName="mySmilesStyle"
 *         smiles="C1ccNcC1"
 *         smilesDrawerOptions={ {bondThickness: 10.0} }
 *     />
 */
export class SmilesDrawerReact extends React.PureComponent {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef(); // We need this to draw on the canvas during updates
    }

    componentDidMount() {
        this.resizeListener = () => this.resize();
        window.addEventListener('resize', this.resizeListener);
        this.draw2d();
    }

    componentDidUpdate() {
        this.draw2d();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener);
    }

    getStyle(backgroundColorInfo) {
        return (backgroundColorInfo && backgroundColorInfo.style) || 'light';
    }

    draw2d() {
        const { smiles: smilesOrig, smilesDrawerOptions, backgroundColorInfo } = this.props;
        const node = this.canvasRef.current;
        let smiles = removeSmilesMolName(smilesOrig);
        // SmilesDrawer adds implicit hydrogens for nitrogens in aromatic rings (it shouldn't).
        // So replace all n with [n] but don't replace existing [n] or [nH].
        // Couldn't figure out how to do both cases reliably in a single regex (the next
        // clever programmer can figure it out).
        // You would think /g would avoid the need for a loop, but it doesn't.
        while (true) {
            const rsmiles = smiles.replace(/([^[n]{1})n/g, '$1[n]');
            if (rsmiles === smiles) break;
            smiles = rsmiles;
        }
        smiles = smiles.replace(/^n/, '[n]');
        // console.log(`new smiles ${smiles}`);
        if (smiles && node && node.clientHeight > 0 && node.clientWidth > 0) {
            if (this.drawer == null) {
                const sdOptions = smilesDrawerOptions || { };
                sdOptions.height = node.clientHeight;
                sdOptions.width = node.clientWidth;
                this.drawer = new SmilesDrawer.Drawer(sdOptions);
            }

            SmilesDrawer.parse(smiles, (tree) => {
                this.drawer.draw(tree, node.id, this.getStyle(backgroundColorInfo), false);
            }, (err) => { console.error(err); });
        }
    }

    // Force canvas resize and redraw
    resize() {
        if (this.canvasRef.current) {
            // SmilesDrawer updated the style according to the original size of the canvas.
            // We need to clear it here to be able to receive the new size.
            this.canvasRef.current.removeAttribute('style');
        }
        this.drawer = null;
        this.forceUpdate();
    }

    render() {
        const { canvasId, canvasClassName } = this.props;
        return (
            <canvas
                id={canvasId}
                className={canvasClassName}
                ref={this.canvasRef}
            />
        );
    }
}
