/**
 *
 * Loads all commands and exports the UserActions used by the rest of the system.
 *
 */
import {
    AddUserActions, UserCmd, UserActions as cmdResigistry,
    CustomActions, UserAction, AddCustomAction,
} from './UserCmd';
import { ConnectedDataCmds } from './ConnectedDataCmds';
import { DisplayCmds } from './DisplayCmds';
import { SaveRestoreCmds } from './SaveRestoreCmds';
import { ComputationCmds } from './ComputationCmds';
import { FragmentsCmds } from './FragmentsCmds';
import { ModificationCmds } from './ModificationCmds';
import { UICmds } from './UICmds';
import { DesignCmds } from './DesignCmds';
import { ScoringCmds } from './ScoringCmds';

const actions = {
    ...ConnectedDataCmds,
    ...DisplayCmds,
    ...SaveRestoreCmds,
    ...ComputationCmds,
    ...FragmentsCmds,
    ...ModificationCmds,
    ...UICmds,
    ...DesignCmds,
    ...ScoringCmds,
};

AddUserActions(actions);

/** @type {actions} UserActions */
export const UserActions = cmdResigistry;

export {
    UserCmd,
    AddUserActions,
    CustomActions,
    UserAction,
    AddCustomAction,
};

export { default as CmdInterpreter } from './CmdInterpreter';
