import { useState, useEffect, useRef } from 'react';
import { clearDropListener } from 'BMapsSrc/paste_drag';

export function withFileDragAndDrop(Component, dropTargetProps={}) {
    // outer dropTargetProps is specifed when defining a component with this Higher Order Component
    // inner DropTargetProps is passed into the actual instance of the component in JSX
    return ({ onDrop, DropTargetProps, ...props }) => (
        <FileDragAndDrop
            onDrop={onDrop}
            DropTargetProps={{
                ...dropTargetProps,
                ...DropTargetProps,
            }}
        >
            <Component {...props} />
        </FileDragAndDrop>
    );
}

export default function FileDragAndDrop({ children, onDrop, DropTargetProps={} }) {
    const { style, ...restDropTargetProps } = DropTargetProps;
    const visibleBorder = {
        borderWidth: 8,
        borderStyle: 'dashed',
        borderColor: 'rgba(50,205,50,1)',
        marginLeft: -8,
        marginRight: -8,
    };
    const invisibleBorder = { ...visibleBorder, borderColor: 'rgba(50,205,50,0)' };

    const dropRef = useRef(null);
    const [dropIndicatorStyle, setDropIndicatorStyle] = useState(invisibleBorder);

    function handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
        clearDropListener();
        setDropIndicatorStyle(visibleBorder);
    }
    function handleDragOut(e) {
    /**
     * Reset the drop indicator style to invisible when the drag is leaving the target.
     * @param {DragEvent} e - the drag event
     */
        e.preventDefault();
        e.stopPropagation();
        setDropIndicatorStyle(invisibleBorder);
    }
    function handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        clearDropListener();
        setDropIndicatorStyle(invisibleBorder);
        onDrop(e.dataTransfer.files);
    }

    useEffect(() => {
        const div = dropRef.current;
        div.addEventListener('dragleave', handleDragOut);
        div.addEventListener('dragover', handleDrag);
        div.addEventListener('drop', handleDrop);

        return () => {
            div.removeEventListener('dragleave', handleDragOut);
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
        };
    });

    return (
        <div style={{ ...style, ...dropIndicatorStyle }} ref={dropRef} {...restDropTargetProps}>
            {children}
        </div>
    );
}
