/* Ketcher.jsx
 *
 * A react component for Ketcher
 */

import React from 'react';
import { IndigoWs } from './WebServices';
import { Loader } from './Loader';

export class Ketcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setup = this.setup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.iframeRef = React.createRef();
        this.noteRef = React.createRef();
    }

    componentWillUnmount() {
        if (this.ketcher) {
            this.ketcher.editor.off('change', this.handleChange);
        }
    }

    async handleChange() {
        const { onMolChange } = this.props;

        if (this.ketcher) {
            let data = this.ketcher.getMolfile();
            try {
                data = await this.serverViewWS(data);
                // // Rdkit implementation was removing chiral information so disable for now
                // const rdkitData = await this.serverViewRDKit(data);
                // if (rdkitData) data = rdkitData;
                // else data = await this.serverViewWS(data);
            } catch (err) {
                // Ignore error (will send coords without Hs to server)
            }
            onMolChange(data);
        }
    }

    async setup() {
        const ketcher = this.iframeRef.current.contentWindow.ketcher;
        const { startingMolText } = this.props;
        this.updateOptions(ketcher);
        this.ketcher = ketcher;

        if (startingMolText) {
            let molText = startingMolText;
            try {
                molText = await this.layout(startingMolText);
                this.updateNote('');
            } catch (err) {
                console.error(`Exception in layout: ${err}`);
                this.updateNote('2D layout is unavailable, using flattened 3D view instead');
            }

            ketcher.setMolecule(molText);
        }
        ketcher.editor.on('change', this.handleChange);
    }

    // I was not able to figure out how to specify inital settings for Ketcher,
    // so we need to update them after it is created.  Unfortunately, this does
    // not update the settings UI.
    updateOptions(ketcher) {
        const options = ketcher.editor.options();
        options.hideChiralFlag = true;
        ketcher.editor.options(options);
    }

    async serverViewWS(data) {
        return IndigoWs.toServerView(data);
    }

    async serverViewRDKit(data) {
        if (!Loader.RDKitExport) return '';
        const molA = await Loader.RDKitExport.getMol(data);
        const molB = await Loader.RDKitExport.getMol(molA.add_hs());
        const s = molB.get_new_coords(true);
        molA.delete();
        molB.delete();
        return s;
    }

    async layout(molText) {
        return this.layoutWS(molText);
        // // Rdkit implementation was removing chiral information so disable for now
        // let s = await this.layoutRDKit(molText);
        // if (!s) {
        //     s = await this.layoutWS(molText);
        // }
        // return s;
    }

    async layoutWS(molText) {
        return IndigoWs.toSketcherView(molText);
    }

    async layoutRDKit(molText) {
        if (!Loader.RDKitExport) return '';
        const jsMol = Loader.RDKitExport.getCleanMol(molText);
        const s = jsMol.get_molblock();
        jsMol.delete();

        return s;
    }

    updateNote(text) {
        this.noteRef.current.innerText = text;
    }

    render() {
        const { iframeId } = this.props;
        const iframeStyle = { height: '99%', width: '100%', border: '1px solid darkgray' };

        return (
            <span>
                <span
                    ref={this.noteRef}
                    style={{
                        position: 'absolute', right: '10px', top: '65px', color: 'lightbluesteel', fontStyle: 'italic',
                    }}
                />
                <iframe
                    id={iframeId}
                    title="Ketcher, a 2D Sketcher"
                    // See setup() / updateOptions() for Ketcher settings
                    src="ketcher/ketcher.html?api_path=/services/indigo/&disabledTools=process,rgroup,rgroup-data,sgroup,sgroup-data,reaction,chiral-flag"
                    style={iframeStyle}
                    ref={this.iframeRef}
                    onLoad={this.setup}
                />
            </span>
        );
    }
}
