import React from 'react';

export function Cheatsheet(props) {
    const helpID = 'helpPane';

    return (
        <div id={helpID} style={{ height: '100%', overflow: 'auto' }}>
            <div id={`${helpID}_wrapper`}>
                <div id={`${helpID}_grid`}>
                    <div id="help_col1">
                        <div id="projectInfoHelp" className="help-item">
                            <div className="help-title">
                                <i className="fa fa-arrow-up rotate-45-left" />
                                Protein Info
                            </div>
                            <div>
                                Click on
                                {' '}
                                <i className="fa fa-folder-open" />
                                <u>Protein</u>
                                {' '}
                                to choose a protein structure.
                            </div>
                            <div>
                                Click on PDB
                                <i className="fa fa-external-link" />
                                , when shown, to view the structure on the PDB web site.
                            </div>
                            <div>
                                Click on
                                {' '}
                                <i className="fa fa-plus" />
                                <u>Add Compound</u>
                                {' '}
                                to load additional compounds.
                            </div>
                        </div>
                        <div id="compoundSelectorHelp" className="help-item">
                            <div className="help-title">
                                <i className="fa fa-arrow-left" />
                                Compound Selector
                            </div>
                            <div>
                                Click
                                {' '}
                                <i className="fa fa-arrow-right" />
                                {' '}
                                to view a compound.
                            </div>
                            <div>
                                Click
                                {' '}
                                <i className="fa fa-thumb-tack" />
                                {' '}
                                to view more than one compound and to include in the energy table.
                            </div>
                            <div>Click or shift-click to select compounds for export.</div>
                        </div>
                        <div id="mouseGesturesHelp" className="help-item">
                            <div className="help-title">Mouse Gestures</div>
                            <ul>
                                <li>
                                    <span>
                                        <b>Rotate</b>
                                        {' '}
                                        display:
                                    </span>
                                    <span>Left-click &amp; drag</span>
                                </li>
                                <li>
                                    <span>
                                        <b>Zoom</b>
                                        {' '}
                                        display:
                                    </span>
                                    <span>Shift-left-click &amp; drag or Mousewheel</span>
                                </li>
                                <li>
                                    <span>
                                        <b>Pan</b>
                                        {' '}
                                        display:
                                    </span>
                                    <span>Ctrl-left-click &amp; drag</span>
                                </li>
                                <li>
                                    <span>
                                        <b>Change</b>
                                        {' '}
                                        binding site radius:
                                    </span>
                                    <span>Ctrl-Mousewheel</span>
                                </li>
                                <li>
                                    <span>
                                        <b>Select</b>
                                        {' '}
                                        atoms:
                                    </span>
                                    <span>Left-click atoms</span>
                                </li>
                                <li>
                                    <span>
                                        <b>Expand</b>
                                        {' '}
                                        selection:
                                    </span>
                                    <span>Left-click selected atoms</span>
                                </li>
                                <li>
                                    <span>
                                        <b>Modify</b>
                                        {' '}
                                        terminals:
                                    </span>
                                    <span>Right-click atoms</span>
                                </li>
                                <li>
                                    <span>
                                        <b>Search</b>
                                        {' '}
                                        fragment maps for substitutions:
                                    </span>
                                    <span>Right-click atoms (Ctrl reverses dir)</span>
                                </li>
                            </ul>
                        </div>

                        <span id="view2dHelp" className="help-item">
                            <div className="help-title">
                                <i className="fa fa-arrow-left rotate-45-left" />
                                {' '}
                                2D Structure / Energies / Properties
                            </div>
                            <div>
                                Click
                                {' '}
                                <i className="fa fa-thumb-tack" />
                                {' '}
                                in the compound list to add compounds to the property tables.
                            </div>
                            <div>
                                Click on the
                                {' '}
                                <b>Calculate Energies</b>
                                {' '}
                                button on the Energy tab
                            </div>
                            <div>&ensp;to minimize and view binding energy components.</div>
                            <div>
                                <b>
                                    Desolvation (&Delta;&Delta;G
                                    <sub>s</sub>
                                    )
                                </b>
                                {' '}
                                is the energy of desolvating the ligand, protein.
                            </div>
                            <div>
                                <b>Stress</b>
                                {' '}
                                is the change in bond stretching, bending,
                            </div>
                            <div>
                                &ensp;&ensp;and twisting (torsional) energy between the unbound
                            </div>
                            <div>&ensp;&ensp;and bound ligand conformations.</div>
                        </span>
                    </div>
                    <div id="help_col2">
                        <div id="displayControlButtonHelp" className="help-item">
                            <div className="help-title">
                                Display Control Buttons
                                <i className="fa fa-arrow-right" />
                            </div>
                            <div>
                                <b>Views:</b>
                                &ensp;Zoom to
                                {' '}
                                <b>Protein</b>
                                ,
                                {' '}
                                <b>Ligand</b>
                                , or
                                {' '}
                                <b>Publication</b>
                                {' '}
                                view.
                            </div>
                            <div>
                                <b>Molecule style:</b>
                                &ensp;Change protein or selected atoms to the
                            </div>
                            <div>
                                &ensp;style depicted on the button (spacefill, surface, etc.).
                            </div>
                            <div>
                                <b>Hydrogen display:</b>
                                &ensp;Select one of
                                {' '}
                                <b>Polar</b>
                                ,
                                {' '}
                                <b>None</b>
                                , or
                                {' '}
                                <b>All</b>
                                .
                            </div>
                            <div>
                                <b>Highlights display:</b>
                                &ensp;Toggle various energy, solvation highlights.
                            </div>
                            <div>
                                <b>Water / Fragment Maps:</b>
                                &ensp;Display Crystal or Computed waters.
                            </div>
                        </div>

                        <div id="keyboardHelp" className="help-item">
                            <div className="help-title">Keyboard Commands</div>
                            <ul>
                                <li>
                                    <span>
                                        <b>H:</b>
                                        &ensp;
                                    </span>
                                    <span>Display all compound hydrogens momentarily</span>
                                </li>
                                <li>
                                    <span>
                                        <b>L:</b>
                                        &ensp;
                                    </span>
                                    <span>Zoom to Ligand view or view the next compound</span>
                                </li>
                                <li>
                                    <span>
                                        <b>R:</b>
                                        &ensp;
                                    </span>
                                    <span>Highlight functional groups</span>
                                </li>
                                <li>
                                    <span>
                                        <b>X:</b>
                                        &ensp;
                                    </span>
                                    <span>Hide the active compound momentarily</span>
                                </li>
                                <li>
                                    <span>
                                        <b>W:</b>
                                        &ensp;
                                    </span>
                                    <span>Show weak hydrogen bonds momentarily</span>
                                </li>
                                <li>
                                    <span>
                                        <b>D:</b>
                                        &ensp;
                                    </span>
                                    <span>Display default docking box</span>
                                </li>
                                <li>
                                    <span>
                                        <b>&larr;,&rarr;:</b>
                                        &ensp;
                                    </span>
                                    <span>View previous, next compound</span>
                                </li>
                            </ul>
                        </div>
                        <div id="compoundHelp" className="help-item">
                            <div className="help-title">
                                Compound Actions
                                <i className="fa fa-arrow-right" />
                            </div>
                            <div>
                                <b>Modeling Tasks:</b>
                                &ensp;Energy minimize or submit for docking, fragment simulation
                            </div>
                            <div>
                                <b>Draw / Edit:</b>
                                &ensp;2D or 3D compound editing.
                            </div>
                            <div>
                                <b>Export:</b>
                                &ensp;Export selected compounds or atoms to a file or web service.
                                Also download the 2D compound image or the 3D image of your
                                structure
                            </div>
                        </div>
                        <span id="selectHelp" className="help-item">
                            <div className="help-title">
                                Atom Selector
                                <i className="fa fa-arrow-down rotate-45-left" />
                            </div>
                            <div>Select atoms by description.  For example,</div>
                            <div>
                                &ensp;&quot;ligand&quot; ; &quot;protein&quot; ; &quot;4K6&quot; ;
                                &quot;GLU.217:A&quot;, etc.
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
