/**
 * @fileoverview This file contains utility functions specifically for visualizations
 * (eg, hbonds, pi-pi interactions, etc.)
 */

import { simpleCond } from './js_utils';

/**
 * Compare the value against the param thresholds and return the corresponding color.
 * If the strongThreshold < moderateThreshold then more negative is stronger.
 * If the strongThreshold > moderateThreshold then less negative is stronger.
 * If the value is null, then something went wrong.  Return a transparent white color
 * to avoid misleading the user with wrong colors.
 * @param {number} value
 * @param {{
 *     strongThreshold: number, moderateThreshold: number,
 *     strongColor: number, neutralColor: number, moderateColor: number,
 * }} params - The thresholds and hex colors to use
 */
export function getColorFromThresholds(value, params) {
    if (value === null) return 0xffffffff;

    if (params.strongThreshold < params.moderateThreshold) { // more negative is stronger
        return simpleCond([
            [value < params.strongThreshold, params.strongColor],
            [value < params.moderateThreshold, params.neutralColor],
            [true, params.moderateColor],
        ]);
    } else { // more postive is stronger
        return simpleCond([
            [value < params.moderateThreshold, params.moderateColor],
            [value < params.strongThreshold, params.neutralColor],
            [true, params.strongColor],
        ]);
    }
}
