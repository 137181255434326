import { Formik, Form } from 'formik';

import { AlphaFoldImportCase } from '../../../../../model/MapCase';
import { DialogSubmitButton } from '../../../../common/DialogActionButtons';
import TextField from '../../../../common/TextFields';
import ExternalLink from '../../../../common/ExternalLink';
import { ImportErrors } from '../../import_components';

function validate(values) {
    const errors = {};
    if (!values.alphaFoldId) {
        errors.alphaFoldId = 'Please specify a UniProt id';
    } else if (!AlphaFoldImportCase.isValidId(values.alphaFoldId)) {
        errors.alphaFoldId = 'Malformed UniProt id!';
    }

    return errors;
}

export default function ImportPdbTab({
    errors, selectProtein,
}) {
    const initialValues = {
        alphaFoldId: '',
    };

    async function handleSubmit(values) {
        const { alphaFoldId } = values;
        const newMapCase = new AlphaFoldImportCase(alphaFoldId);
        selectProtein(newMapCase);
    }

    return (
        <>
            <div style={{ paddingBottom: '1em' }}>
                Import a computer-generated structure from
                {' '}
                <ExternalLink link="https://alphafold.ebi.ac.uk/">AlphaFold</ExternalLink>
                {' '}
                by entering the UniProt id (eg. &quot;Q5VSL9&quot;).
                <br />
                AlphaFold structures have been predicted with an AI technology developed at
                {' '}
                <ExternalLink link="https://www.deepmind.com/">DeepMind</ExternalLink>
                {'. '}
                The residues are colored by the confidence level;
                dark blue residues have the highest level of confidence.
                <br />
                Raw AlphaFold structures do not contain any fragment data,
                such as water maps or hot spots.
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validate={validate}
                validateOnChange={false}
                validateOnBlur={false}
            >
                <Form>
                    <TextField fullWidth name="alphaFoldId" label="UniProt ID" />
                    <DialogSubmitButton fullWidth>Import from AlphaFold</DialogSubmitButton>
                </Form>
            </Formik>
            {errors && <ImportErrors errors={errors} /> }
        </>
    );
}
