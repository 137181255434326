import { useState, createContext } from 'react';
import { CDDVIntegration } from './CDDVData';

export const CDDVContext = createContext({
    vaultId: -1,
    projectId: -1,
    includeSearches: false,
    vaultsLoaded: false,
    connected: false,
    setVaultsLoaded: () => {},
    setProjectId: () => {},
    setVaultId: () => {},
    setConnected: () => {},
});

export default function CDDVContextProvider({ includeSearches=false, children }) {
    const CDDV = CDDVIntegration.getInstance();
    const connected = CDDV.connected;
    const vaultsLoaded = connected; // default state
    let vaultId = -1;
    let projectId = -1;

    if (vaultsLoaded) {
        vaultId = CDDV.vaults[0].id;
        projectId = defaultProjectId(vaultId);
    }

    function defaultProjectId(vid) {
        let ret = -1;
        const vault = CDDV.getVault(vid);
        if (vault) {
            if (vault.projects.length > 0) {
                ret = vault.projects[0].id;
            } else if (vault.searches.length > 0 && includeSearches) {
                ret = vault.searches[0].id;
            }
        }
        return ret;
    }

    const [contextValue, setContextValue] = useState({
        vaultId,
        projectId,
        includeSearches,
        vaultsLoaded,
        connected,
    });

    function setConnected(bool) {
        let vid = -1;
        if (bool) {
            if (CDDV.vaults.length > 0) {
                vid = CDDV.vaults[0].id;
            }
            setContextValue({
                ...contextValue,
                connected: bool,
                vaultId: vid,
            });
        } else {
            setContextValue({
                ...contextValue,
                connected: false,
                vaultsLoaded: false,
                vaultId: -1,
                projectId: -1,
            });
        }
    }

    function setVaultsLoaded(bool) {
        let vid;
        let pid;

        if (bool) {
            vid = CDDV.vaults[0].id;
            pid = defaultProjectId(vid);
        } else {
            vid = -1;
            pid = -1;
        }

        setContextValue({
            ...contextValue,
            connected: CDDV.connected,
            vaultsLoaded: bool,
            vaultId: vid,
            projectId: pid,
        });
    }

    function setVaultId(id) {
        const pid = defaultProjectId(id);
        setContextValue({
            ...contextValue,
            vaultId: id,
            projectId: pid,
        });
    }

    function setProjectId(id) {
        setContextValue({
            ...contextValue,
            projectId: id,
        });
    }

    return (
        <CDDVContext.Provider value={{
            ...contextValue, setVaultsLoaded, setProjectId, setVaultId, setConnected,
        }}
        >
            {children}
        </CDDVContext.Provider>
    );
}
