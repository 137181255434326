import React, { useState } from 'react';

import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import styled from 'styled-components';

import { wrapForDisabledTooltip } from './helpers';

const MenuStyle = styled(MenuItem)`
    .MuiButton-root {
        width: 100%
    }`;

/**
 * This component produces a drop-down button, similar to github's squash & merge button on PRs.
 * To use, simply make the buttons that would be
 * choices children of the DialogDropDownButton component
 * @param {Object} style default={} pass css object style to style buttons
 * @returns react component
 */
export default function DialogSplitButton({
    children, style={}, PopperProps, ...otherProps
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const childArray = React.Children.toArray(children);

    if (childArray.length < 1) return children;

    function arrowClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <>
            <ButtonGroup style={style} {...otherProps}>
                <Button style={{ width: '100%' }} color="neutral" {...childArray[0].props} />
                {childArray.length > 1 && (
                    <Button onClick={arrowClick} color="neutral" size="small">
                        <ArrowDropDown />
                    </Button>
                )}
            </ButtonGroup>
            <Popper
                anchorEl={anchorEl}
                open={open}
                placement="bottom-end"
                role={undefined}
                disablePortal
                {...PopperProps}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            {childArray.slice(1).map(
                                (child) => (
                                    <MenuStyle
                                        key={child.props.children}
                                        disableGutters
                                        style={{ padding: 0 }}
                                    >
                                        {wrapForDisabledTooltip(
                                            injectClose(child, handleClose)
                                        )}
                                    </MenuStyle>
                                )
                            )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
}

/**
 * Higher-order component that modifies a MenuItem's onClick ensure the menu is closed.
 */
export function injectClose(component, onClose) {
    const { props } = component;
    const { onClick } = props;
    return React.cloneElement(component, {
        ...props,
        onClick(e) {
            if (onClick) onClick(e);
            onClose();
        },
    });
}
