import styled from 'styled-components';
// Material UI
import Grid from '@mui/material/Grid';
// Local
import { UserActions } from 'BMapsCmds';
import { WaterStates } from '../../model/DisplayState';
import {
    validateRightControlPanelAction, doRightControlPanelAction,
    imageSrcForStyle,
} from './utils';
import { Loader } from '../../Loader';

import ControlButton from './base/ControlButton';
import ControlDrawer from './ControlDrawer';
import ActionsDrawer from './ActionsDrawer';
import TogglesDrawer from './TogglesDrawer';

const Img = styled.img`
    height: 100%;
    width: 100%;
    border-radius: calc(var(--mol-button-size)/6);
`;

const Container = styled(Grid)`
    position: relative;
    height: calc(100vh - var(--topbar-height)); 
    margin: 0;
    top: 0;
    right: 0;
    background-color: var(--topbar-bg-color);
    border-left: .5vh solid black;

    & { width: unset }
`;

const displayControlButtons = {
    // key: {desc: tooltip for the main button,
    //      style: [drawer | cycle (showing next action) | toggle (showing current state)
    //          | drawer_toggles | drawer_actions]
    //      options: [{id: mdstyle (match previous usage for img and setDisplayStyle),
    //                 desc: tooltip for specific option,
    //                 options: {id: mdstyle }
    //                 }
    //                ... ]
    // }
    views: {
        desc: 'Switch between protein and binding site (ligand) views',
        label: 'View',
        style: 'drawer_actions',
        ControlProps: { id: 'views_style_group' },
        options: [
            { id: 'views-protein', desc: 'Zoom out to whole protein view', label: 'Protein View' },
            { id: 'views-ligand', desc: 'Zoom in to binding site view for the focus compound or selected atoms', label: 'Ligand View' },
            { id: 'views-publication', desc: 'Publication view (under development)', label: 'Pub. View' },
            { id: 'views-recenter', desc: 'Recenter view on visible atoms', label: 'Recenter' },
            { id: 'views-reset', desc: 'Reset to default view', label: 'Reset' },
        ],
    },
    atomstyle: {
        desc: 'Change display style of protein atoms or selected atoms.',
        style: 'drawer',
        ControlProps: { id: 'atomstyle_style_group' },
        start: 6,
        options: [
            { id: 'hidden', desc: 'Hide atoms', label: 'Hide Atoms' },
            { id: 'wireframe', desc: 'Display bonds as thin lines.' },
            { id: 'sticks', desc: 'Display bond sticks between atoms.' },
            { id: 'ballandstick', desc: 'Display atoms as small spheres with bond sticks between them.' },
            { id: 'spacefill', desc: 'Display atoms as van der Waals spheres.' },
            { id: 'surface', desc: 'Display the protein Connolly surface.' },
            { id: 'cartoon', desc: 'Display the protein with helix and sheet cartoons.' },
        ],
    },
    hydrogens: {
        desc: 'Display no hydrogen atoms or only polar hydrogen atoms.',
        style: 'drawer',
        ControlProps: { id: 'hydrogen_style_group' },
        start: 0,
        options: [
            { id: 'polarhydrogens', desc: 'Only show polar hydrogens.' },
            { id: 'nohydrogens', desc: 'Hide all hydrogens.' },
            { id: 'allhydrogens', desc: 'Show all hydrogens.' },
        ],
    },
    energyhilite: {
        desc: 'Toggle highlighting of interactions',
        label: <span>Interaction High&shy;lights</span>,
        style: 'drawer_toggles',
        ControlProps: { id: 'highlights_style_group' },
        options: [
            {
                id: 'hbonds',
                desc: 'Toggle hydrogen bonds',
                label: 'HBonds',
            },
            {
                id: 'pipistacking',
                desc: 'Toggle pi-pi stacking visualization',
                label: 'Pi-Pi Stacking',
            },
            {
                id: 'ligandsolvation',
                desc: 'Toggle ligand functional group desolvation highlighting',
                label: 'Ligand Desolv',
            },
            {
                id: 'protsolvation',
                desc: 'Toggle protein desolvation surface display',
                label: 'Protein Desolv',
            },
            {
                id: 'hydrophobic',
                desc: 'Toggle protein hydrophobicity surface display',
                label: 'Protein Hydro- phobic',
            },
            // // BFactor only controls alphafold colors, which only have a single chain.
            // // So disable user control of the highlighting for now
            // {
            //     id: 'bfactor',
            //     desc: 'Toggle B-factor coloring',
            //     label: 'B factor',
            // },
            {
                id: 'hotspots',
                desc: 'Toggle hotspot highlighting',
                label: 'Hot Spots',
            },
        ],
    },
    waters: {
        desc: 'Switch between crystalographic and computed waters.',
        style: 'drawer_actions',
        label: 'Water / Fragment Maps',
        ControlProps: { id: 'water_style_group' },
        start: 0,
        options: [
            { id: WaterStates.none, desc: 'Hide all waters', label: 'Hide All Waters' },
            { id: WaterStates.crystal, desc: 'Show crystallographic waters', label: 'Show Crystal Waters' },
            { id: WaterStates.computed, desc: 'Show simulated water map', label: 'Show BMaps Waters' },
            { id: WaterStates.all, desc: 'Show both computed and crystallographic waters', label: 'Show All Waters' },
            { id: 'SearchFragmentMaps', desc: 'View information about searching fragment maps', label: 'Search Fragment Maps' },
        ],
    },
    compute: {
        label: 'Modeling Tasks',
        desc: 'Run docking and fragment / water maps',
        style: 'drawer_actions',
        ControlProps: { id: 'compute_style_group' },
        options: [
            { id: 'energyminimize', desc: 'Perform energy minimization', label: 'Energy Minimize' },
            { id: 'dockcompound', desc: 'Submit the visible compound for docking', label: 'Dock' },
            { id: 'watermap', desc: 'Run a water map', label: 'Run Water Sim.' },
            { id: 'fragmap', desc: 'Run a fragment map', label: 'Run Frag. Sim.' },
            // \u00A0 is a unicode character representing a non-breaking space (nbsp)
            { id: 'clustermap', desc: 'Run fragment simulations to predict hot spots', label: 'Run Hot\u00A0Spot Sim.' },
        ],
    },
    ligandediting: {
        desc: 'Draw new compounds or edit compounds in 2D or 3D.',
        label: 'Draw / Edit',
        style: 'drawer_actions',
        ControlProps: { id: 'ligandediting_style_group' },
        options: [
            { id: 'newligand', desc: 'Draw a new compound with the 2D sketcher', label: 'Draw New Cmpd' },
            { id: 'editligand', desc: 'Edit the visible compound with the 2D sketcher', label: 'Edit Active Cmpd' },
            { id: 'terminalReplacement', desc: 'Replace a terminal functional group', label: 'Replace Terminal' },
            { id: 'fragmentGrow', desc: 'Replace a section of a compound with a nearby fragment from simulation', label: 'Fragmt. Growing' },
        ],
    },
    //    dockcompound:  "Submit the visible compound for docking",
    export: {
        desc: 'Export to a molecule file, picture, or web service',
        label: 'Export',
        ControlProps: {
            onClick() {
                if (validateRightControlPanelAction('export')) {
                    UserActions.OpenExport();
                }
            },
        },
    },
    selection: {
        desc: 'Select atoms with a query',
        label: <Img
            alt="selection"
            src={imageSrcForStyle('selection')}
        />,
        ControlProps: {
            onClick() {
                if (validateRightControlPanelAction('selection')) {
                    UserActions.OpenSelectBox('toggle');
                }
            },
        },
    },

};

const createControlButton = (infoType, info) => {
    let defaultLabel;
    let ret;
    const {
        desc, label,
        style: controlButtonStyle,
        start, options,
        ControlProps,
    } = info;

    const defaultHandleClick = (styleId) => {
        if (validateRightControlPanelAction(styleId)) {
            doRightControlPanelAction(styleId);
        }
    };

    // This z-index ensures the open drawer will be on top of the canvas.
    // This is a different z-index than the control button in the Right Control Panel.
    // This means that a canvas tooltip can be on top of the control button, but under
    // the open drawer.
    const drawerBackgroundProps = { style: { zIndex: 6 } };

    switch (controlButtonStyle) {
        case 'drawer':
            if (options[start].label) {
                defaultLabel = options[start].label;
            } else {
                defaultLabel = <Img alt={`${options[start].id}`} src={imageSrcForStyle(options[start].id)} />;
            }

            ret = (
                <ControlDrawer
                    key={infoType}
                    defaultLabel={defaultLabel}
                    title={desc}
                    ControlProps={ControlProps}
                    ActionsBackgroundProps={drawerBackgroundProps}
                    actions={options.map((opt) => {
                        const actionLabel = opt.label ? opt.label : <Img alt={`${opt.id}`} src={imageSrcForStyle(opt.id)} />;

                        return {
                            ...opt,
                            label: actionLabel,
                            onClick(setControlLabel) {
                                if (validateRightControlPanelAction(opt.id)) {
                                    doRightControlPanelAction(opt.id);
                                    setControlLabel(actionLabel);
                                }
                            },
                        };
                    })}
                />
            );
            break;

        case 'drawer_toggles':
            ret = (
                <TogglesDrawer
                    key={infoType}
                    title={desc}
                    label={label}
                    toggles={options}
                    ActionsBackgroundProps={drawerBackgroundProps}
                />
            );
            break;

        case 'drawer_actions':
            ret = (
                <ActionsDrawer
                    key={infoType}
                    title={desc}
                    label={label}
                    ControlProps={ControlProps}
                    ActionsBackgroundProps={drawerBackgroundProps}
                    actions={options.map((opt) => {
                        const handleClick = opt.onClick ?? (() => defaultHandleClick(opt.id));

                        return {
                            ...opt,
                            onClick: handleClick,
                        };
                    })}
                />
            );
            break;

        default: ret = (
            <ControlButton
                key={infoType}
                title={desc}
                {...ControlProps}
            >
                {label}
            </ControlButton>
        );
    }

    return ret;
};

/**
 * Right-hand control buttons with drawers.
 *
 * Note: z-index for this is tricky, with the following needs:
 * - Drawers should open on top of the canvas and on top of ServerInfo.
 * - Canvas Tooltips should be on top of the control buttons.
 *
 * To achieve this, we set z-index of drawer backgrounds to 5,
 * which allows canvas tooltips to be on top of the control buttons, but under open drawers.
 */
export default function RightControlPanel() {
    if (Loader.AllowLabFeatures) {
        // Add "Get Energies" button which is only available in dev/lab for now.
        // There may be an issue in bfd-server where get-energies is unable to failover to
        // bfd energies if mm-server is requested but unavailable.
        if (!displayControlButtons.compute.options.find(({ id }) => id === 'calculateEnergies')) {
            displayControlButtons.compute.options.unshift( // push onto the beginning
                { id: 'calculateEnergies', desc: 'Calculate energies', label: 'Get Energies' }
            );
        }
    }

    const items = Object.entries(displayControlButtons).map(
        ([infoType, info]) => createControlButton(infoType, info)
    );

    return (
        <Container
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
        >
            {items}
        </Container>
    );
}
