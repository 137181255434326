import { ScopedPropertyGroup } from 'BMapsModel';
import { ResponseIds } from './server_common';

// ToBMaps
export class CompoundMetadataPacket {
    static get MsgType() { return ResponseIds.CompoundMetadata; } // compound-info
    /**
     * @param {{ compoundSpec: string, compound_2d: string, fields: object }} obj
     */
    constructor(obj={}) {
        this.compoundSpec = obj.compoundSpec;
        this.compound_2d = obj.compound_2d;
        this.scopedProperties = ScopedPropertyGroup.fromDeserialization(obj.scopedProperties || {});
    }
}

export class UnboundConformationsPacket {
    static get MsgType() { return ResponseIds.UnboundConformations; } // unbound-conformations

    /**
     * @param {{[spec:string]: { value: string, error: string }}} obj
     */
    constructor(obj={}) {
        for (const [spec, molUnbound] of Object.entries(obj)) {
            this[spec] = molUnbound;
        }
    }
}
