import _ from 'lodash';
import ControlButton from './base/ControlButton';
import DrawerButton from './base/DrawerButton';
import SpeedDial from './base/SpeedDial';

/**
 * Create a drawer using our custom SpeedDial.
 * ActionsBackgroundProps is used to specify the z-index of the open drawer.
 * @param {{
 *     actions: Array<{id: string, desc: string, label: string, onClick: function}>,
 *     title: string,
 *     control: React.Component,
 *     label: string,
 *     children: React.Component,
 *     ControlProps: Object,
 *     ActionsBackgroundProps: Object,
 *     ...props
 * }} param0
 * @returns {JSX.Element}
 */
export default function ActionsDrawer({
    actions, title, control, label, children, ControlProps,
    ActionsBackgroundProps,
    ...props
}) {
    const controlComp = control || (
        <ControlButton title={title} {...ControlProps}>{label}</ControlButton>
    );

    const backgroundMargin = '2.5em';
    const actionsBackgroundPropsToUse = _.merge({
        style: {
            top: `-${backgroundMargin}`, // 0 is top of control button; need to extend up
            paddingTop: backgroundMargin,
            paddingBottom: backgroundMargin,
            paddingLeft: backgroundMargin,
        },
    }, ActionsBackgroundProps);
    // Note: RightControlPanel overrides zIndex via ActionsBackgroundProps.

    return (
        <SpeedDial
            control={controlComp}
            ActionsBackgroundProps={actionsBackgroundPropsToUse}
            ActionsContainerProps={{ marginRight: 'calc(3 * var(--mol-button-margin))' }}
            {...props}
        >
            {children || actions?.map((action) => {
                const {
                    id, desc, label: actionLabel, onClick,
                } = action;

                return (
                    <DrawerButton
                        key={id}
                        title={desc}
                        id={id}
                        onClick={onClick}
                    >
                        {actionLabel}
                    </DrawerButton>
                );
            })}
        </SpeedDial>
    );
}
