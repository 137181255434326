/**
 * @module CDDVProtocol this module handles CDD Vault server requests
 */

// API fields are snake case
/* eslint-disable camelcase */

import { App } from '../../BMapsApp';
import { ResponseIds } from '../../server/server_common';

const CDDVServiceCode = 'CDDV';

export default class CDDVProtocol {
    static check_setup() {
        return this.sendServerRequest({ operation: 'check_setup' });
    }

    static setup(token) {
        return this.sendServerRequest({ operation: 'setup', token });
    }

    static clear_setup() {
        return this.sendServerRequest({ operation: 'clear_setup' });
    }

    static list_projects(vaultId) {
        return this.sendServerRequest({ operation: 'list_projects', vaultId });
    }

    static fetch_molecules(vaultId, projectId, page_size=10, offset=0) {
        const requestObj = {
            operation: 'fetch_molecules',
            vaultId,
            query: { projectId, page_size, offset },
        };
        return this.sendServerRequest(requestObj);
    }

    static list_searches(vaultId) {
        return this.sendServerRequest({ operation: 'list_searches', vaultId });
    }

    static start_search(vaultId, searchId) {
        return this.sendServerRequest({ operation: 'start_search', vaultId, searchId });
    }

    static check_search(vaultId, jobId) {
        return this.sendServerRequest({ operation: 'check_search', vaultId, jobId });
    }

    static fetch_search(vaultId, jobId) {
        return this.sendServerRequest({ operation: 'fetch_search', vaultId, jobId });
    }

    static upload_molecule(vaultId, args) {
        const requestObj = {
            operation: 'upload_molecule',
            vaultId,
            upload_args: args,
        };
        return this.sendServerRequest(requestObj);
    }

    /**
     * There's a server issue that mangles json payloads with the word "error"
     * This workaround scans the raw text of the mangled payload for a few specific error messages.
     * @param {string} error
     * @returns {string}
     */
    static extractErrorWorkaround(error) {
        const translations = [
            ['Validation failed: Synonym has already been taken', { error: 'This name is already assigned as the synonym of another molecule.' }],
            ['Token has insufficient access to modify data', { error: 'Your CDD Vault API key does not have permission to add or modify data.' }],
            ['Not available in Preview: CDD Vault integration', { data: 'CDD Vault integration is not available in Preview.' }],
        ];

        for (const [search, interpretation] of translations) {
            if (error.indexOf(search) > -1) {
                return interpretation;
            }
        }

        return {};
    }

    static async sendServerRequest(requestObj) {
        const serviceRequest = App.ServerConnection.cmdMoleculeServiceRequest;
        const responseData = await serviceRequest(CDDVServiceCode, JSON.stringify(requestObj));
        if (responseData.hasError()) {
            const [fullError] = responseData.errors;
            const { error, data } = this.extractErrorWorkaround(fullError);

            return {
                success: false,
                error,
                data,
                request: requestObj,
            };
        }
        const [packet] = responseData.byId(ResponseIds.MolServiceRequestResult);
        return packet || {}; // Ensure we have an object to query `success`
    }

    static uploadArgs(projectId, molName, smiles, molDataOrig) {
        // I'll keep this comment, this is from cddv.js and it may be something to explore later
        // const collections = [ {"name": collectionName} ];
        const molData = molDataOrig.replace(/\r/g, '');
        const molecule = { smiles, synonyms: [molName] };
        const projects = [{ id: projectId }];
        const batchFields = { structure_bmaps: molData };
        const query = { projects, molecule, batch_fields: batchFields };
        return query;
    }
}
