import styled from 'styled-components';
import Button from '@mui/material/Button';

export default styled(Button)`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.contrastText};
    border: solid 1px ${theme.palette.primary.main};

    &:hover {
      background-color: ${theme.palette.primary.contrastText};
    }
  `}
`;
