import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { App } from 'BMapsSrc/BMapsApp';
import { Slider } from 'BMapsUI/UIComponents';
import { BestPoseBindingInfo } from 'BMapsModel/FragmentSearchResult';
import { CoralComponent } from './CoralComponent';
import LinkLikeButton from '../../common/LinkLikeButton';
import { AnnotatedSearchResult } from './AnnotatedSearchResult';
import { svgImgSrc, updateTooltipster } from '../../ui_utils';
import { renderDebug, getMapCaseAndTargetInfo, getKinaseIdForMapCase } from './utils';
import {
    getColumnInfo, getValue,
    ActiveTargetColor, ActiveOffTargetColor,
} from './column_definitions';

/**
 * Component to display a fragment search result table with header and data rows.
 */
export function SuggestionTable({
    uiInfo, sortedSuggestions, columns,
    sortCol, sortAscending, handleSort,
    showImages, toggleImages,
    suggestionAccept, suggestionEnter, suggestionExit,
    cycleSuggestions,
    isPinned, togglePin,
    activityInfo,
}) {
    const projectCases = AnnotatedSearchResult.getProjectCasesForSuggestions(sortedSuggestions);

    return (
        <table>
            <thead>
                <SuggestionsHeader
                    columns={columns}
                    sortCol={sortCol}
                    sortAscending={sortAscending}
                    handleSort={handleSort}
                    showImages={showImages}
                    toggleImages={toggleImages}
                />
            </thead>
            <tbody>
                { sortedSuggestions.map((d) => (
                    <SuggestionRow
                        key={d.index}
                        sugg={d}
                        uiInfo={uiInfo}
                        columns={columns}
                        showImg={showImages}
                        suggestionAccept={suggestionAccept}
                        suggestionEnter={suggestionEnter}
                        suggestionExit={suggestionExit}
                        onChangePose={cycleSuggestions}
                        pinned={isPinned(d)}
                        togglePin={togglePin}
                        projectCases={projectCases}
                        activityInfo={activityInfo}
                    />
                ))}
            </tbody>
        </table>
    );
}
/**
 * Component to display one row of a fragment search result table.
 * @description props = {
 *      sugg: suggestion object,
 *      showImg: whether to show images instead of fragment names,
 *      uiInfo: labels and presence of certain controls, based on command
 *      suggestionAccept, suggestionEnter, suggestionAccept: callbacks
 * }
 */
class SuggestionRow extends React.PureComponent {
    constructor(props) {
        super(props);
        const { sugg } = props;
        this.state = {
            sliderExpanded: false,
            sliderValue: sugg.selectedIndex,
        };

        this.toggle = this.toggle.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate() {
        updateTooltipster({
            [`#${this.getEltId()} .toggle`]: {
                side: 'right',
            },
        });
    }

    onChange(valueIn) {
        const { sugg, onChangePose } = this.props;
        const value = Number(valueIn);
        this.setState({
            sliderValue: value,
        });
        onChangePose(sugg, value);
    }

    getEltId() {
        const { sugg } = this.props;
        return `suggestion-${sugg.index}`;
    }

    getSliderId() {
        const { sugg } = this.props;
        return `suggestion-${sugg.index}-slider`;
    }

    getSliderTooltip(sugg, fragIndex) {
        const currentPose = sugg && sugg.frags && sugg.frags[fragIndex];
        if (!currentPose) return '';

        const entries = [
            [BestPoseBindingInfo.getBindingScore(currentPose.exchemPotential), 'binding score', 1],
            [currentPose.enSolute, 'interaction energy', 1],
        ];

        const info = entries.reduce((acc, [nextVal, nextLabel, nextPrecision]) => {
            if (nextVal == null) return acc;
            return acc.concat(`${nextLabel}: ${nextVal.toFixed(nextPrecision)}`);
        }, []);
        return info.length > 0 ? `Highlighted pose ${info.join('; ')}` : '';
    }

    /**
     * Return extra info to append to suggestion row tooltip. Currently just min b-values.
     * @returns {string} string to append.  Embedded html is ok.
     */
    getTooltipExtras() {
        // Note: this projectCases includes projectCases from all suggestions,
        // not just this one. It can't necessarily be pulled from the suggestion unless
        // we guarantee that all suggestions always have all projectCases.
        const { sugg, projectCases } = this.props;

        const fragInfos = projectCases.map((projectCase) => {
            const fragInfo = App.Workspace.getInfoForFragment(sugg.name, projectCase);
            return {
                projectCase,
                fragInfo,
                minBValue: fragInfo != null ? fragInfo.minBValue : 'N/A',
            };
        });

        const ret = [];
        if (fragInfos.length === 1) {
            ret.push(`Min B-Value: ${fragInfos[0].minBValue}`);
        } else if (fragInfos.length > 1) {
            ret.push('Min B-Values:');
            for (const { projectCase, minBValue } of fragInfos) {
                ret.push(`${projectCase}: ${minBValue}`);
            }
        }
        return ret;
    }

    toggle() {
        this.setState((old) => ({ sliderExpanded: !old.sliderExpanded }));
    }

    render() {
        if (renderDebug) console.log(`Rendering SuggestionRow ${this.getEltId()}`);
        const {
            sugg, showImg, uiInfo, columns,
            suggestionAccept, suggestionEnter, suggestionExit,
            pinned, togglePin,
            activityInfo,
        } = this.props;
        const { sliderExpanded, sliderValue } = this.state;
        const sliderTooltip = this.getSliderTooltip(sugg, sliderValue);

        const eltId = this.getEltId();
        const altText = `2D diagram of ${sugg.name}`;
        const imgTooltip = (<img alt={altText} className="suggestion-imgTooltip" src={svgImgSrc(sugg.img)} />);
        const tooltip = showImg ? sugg.name : imgTooltip;
        const tooltipExtras = this.getTooltipExtras();

        const addOnRowClick = !uiInfo.usingSlider;
        const accept = () => suggestionAccept(sugg);
        const addButton = (
            <LinkLikeButton
                style={{ color: 'var(--marketing-blue)' }}
                title={uiInfo.addButtonTitle}
                onClick={(event) => { event.stopPropagation(); accept(); }}
            >
                <i className="fa fa-plus-square-o" />
            </LinkLikeButton>
        );
        const pin = (
            <LinkLikeButton
                onClick={(event) => { event.stopPropagation(); togglePin(sugg); }}
                title={`Keep viewing ${sugg.frags.length > 1 ? 'these poses' : 'this pose'} in the workspace`}
                style={{ color: pinned ? 'var(--marketing-blue)' : 'lightgrey' }}
            >
                <i className="fa fa-thumb-tack" />
            </LinkLikeButton>
        );
        const toggleButton = (
            <LinkLikeButton
                onClick={this.toggle}
                className="toggle"
                style={{ color: 'var(--marketing-blue)' }}
                title={`${sliderExpanded ? 'Hide' : 'Show'} pose selector (${sugg.frags.length} poses available)`}
            >
                {sliderExpanded
                    ? <i className="fa fa-caret-down" />
                    : <i className="fa fa-caret-left" /> }
            </LinkLikeButton>
        );

        return (
            <>
                <Tooltip
                    arrow
                    title={(
                        <div>
                            {tooltip}
                            {!!tooltipExtras && (
                                tooltipExtras.map((tt, ti) => <div key={`${tt}${ti.toString()}`}>{tt}</div>)
                            )}
                        </div>
                    )}
                >
                    <tr
                        key={eltId}
                        className="suggestionRow"
                        id={eltId}
                        data-selectionid={sugg.selectionID}
                        onMouseEnter={() => suggestionEnter(sugg)}
                        onMouseLeave={() => suggestionExit(sugg)}
                        onClick={() => addOnRowClick && accept()}
                    >
                        { columns.map((col, i) => (
                            <SuggestionRowCell
                                key={`${eltId}+${col}`}
                                suggestion={sugg}
                                col={col}
                                index={i}
                                activityInfo={activityInfo}
                            />
                        ))}
                        <td>
                            {pin}
                            {addButton}
                            {uiInfo.usingSlider && toggleButton}
                        </td>
                    </tr>
                </Tooltip>
                {uiInfo.usingSlider && (
                <tr
                    key={`slider-${sugg.index}`}
                    onMouseEnter={() => suggestionEnter(sugg)}
                    onMouseLeave={() => suggestionExit(sugg)}
                >
                    <td colSpan="100">
                        {sliderExpanded && (
                        <Slider
                            eltId={this.getSliderId()}
                            value={sliderValue}
                            max={sugg.frags.length-1}
                            onChange={this.onChange}
                            tooltip={sliderTooltip}
                            // \xa0  is non-breaking space
                            label={`Pose\xa0${1 + sliderValue}\xa0/\xa0${sugg.frags.length}`}
                        />
                        )}
                    </td>
                </tr>
                )}
            </>
        );
    }
}

/**
 * Component to display the header of a fragment search result table.
 */
function SuggestionsHeader({
    columns,
    sortCol, sortAscending, handleSort,
    toggleImages,
}) {
    return (
        <>
            {/* grouphead displays protein labels in multi-protein case */}
            <SuggestionGroupHeadRow columns={columns} />
            {/* colhead displays the sortable column headers */}
            <tr key="header-row" className="ligandmod-colhead">
                {/* Frag/Name toggle column */}
                <th
                    style={{ width: '1px' }}
                    onClick={() => { toggleImages(); }}
                    title="Switch between fragment images and names"
                >
                    <i id="image-name-toggle" className="fa fa-random" />
                </th>
                {/* Columns to display */}
                { columns.map((col) => (
                    <SuggestionColumnHead
                        key={col}
                        col={col}
                        sortCol={sortCol}
                        sortAscending={sortAscending}
                        handleSort={handleSort}
                    />
                ))}
                {/* Controls column */}
                <th aria-label="Controls"> </th>
            </tr>
        </>
    );
}

/**
 * @param {AnnotatedSearchResult} sugg
 */
function getInfoForCoralMap(sugg) {
    return Object.values(sugg.perCaseInfo).map((projectCaseEntry) => ({
        kinaseId: getKinaseIdForMapCase(projectCaseEntry.mapCase),
        projectCase: projectCaseEntry.projectCase,
        mapCase: projectCaseEntry.mapCase,
        targetInfo: projectCaseEntry.targetInfo,
        fragInfo: sugg.fragInfo,
        bindingScore: projectCaseEntry.bestPoseInfo.bindingScore,
        ligandEfficiency: projectCaseEntry.bestPoseInfo.ligandEfficiency,
        enSolute: projectCaseEntry.bestPoseInfo.enSolute,
        ligandEfficiencyEn: projectCaseEntry.bestPoseInfo.ligandEfficiencyEn,
    }));
}

function SuggestionColumnHead({
    col, handleSort, sortCol, sortAscending,
}) {
    const { properties: { noSort, sortKey, table } } = getColumnInfo(col) || {};
    const { header, title, suffix } = table || {};

    const sortIcon = (c) => {
        if (c === sortCol) {
            const className = sortAscending ? 'fa fa-caret-up' : 'fa fa-caret-down';
            return <i className={className} style={{ display: 'inline' }} />;
        } else {
            return '';
        }
    };

    return (
        <th
            onClick={!noSort ? () => handleSort(sortKey || col) : undefined}
            title={title}
        >
            {header}
            {!noSort && (
                <>
                    &nbsp;
                    {sortIcon(col)}
                </>
            )}
            {!!suffix && suffix}
        </th>
    );
}

/**
 * Display the GroupHead header row for the SuggestionTable.
 * In the multi-protein case, display the project case names with tooltips with target info.
 */
function SuggestionGroupHeadRow({ columns }) {
    // Organize columns by groups according to their associated project case, so it can
    // create one <th> with a colSpan for the whole group.
    // <th>s for groups associated with a projectCase will have tooltips with target info.
    // This is perhaps unnecessarily complex because it avoids making assumptions about
    // column structure. This means it could handle something like:
    //     Col1, Col2, Col3_pcA, Col3_pcB, Col4, Col5_pcA, Col6_pcA, Col5_pcB, Col6_pcB
    // Resulting groups:
    //     [Col1, Col2], [Col3_A], {Col3_B], [Col4], [Col5_A, Col6_A], [Col5_B, Col6_B]

    const getColumnGroupEntry = (projectCase, cols) => {
        const { mapCase, targetInfo } = getMapCaseAndTargetInfo(projectCase);
        const target = !(targetInfo?.isTarget) ? 'Off-target' : 'Target';
        return {
            shortName: mapCase?.getShortName(),
            title: targetInfo ? `${target}: ${mapCase?.getLongName()}` : undefined,
            cols: [...cols], // Make a copy
        };
    };

    const columnGroups = [];
    const allProjectCases = new Set();
    let workingProjectCase;
    let workingColumnGroup = [];

    for (const col of columns) {
        const { projectCase } = getColumnInfo(col);
        if (projectCase) allProjectCases.add(projectCase);
        if (projectCase !== workingProjectCase) {
            columnGroups.push(getColumnGroupEntry(workingProjectCase, workingColumnGroup));
            workingColumnGroup = [];
            workingProjectCase = projectCase;
        }
        workingColumnGroup.push(col);
    }

    columnGroups.push(getColumnGroupEntry(workingProjectCase, workingColumnGroup));

    return allProjectCases.size > 1 && (
        <tr className="ligandmod-grouphead">
            {/* Frag/Name */}
            <th colSpan="1"> </th>
            {/* Columns */}
            {columnGroups.map(({
                cols, shortName, title,
            }, i) => (
                <th
                    key={`groupheadCol_${i.toString()}`}
                    colSpan={cols.length}
                    title={title}
                >
                    {shortName || ' '}
                </th>
            ))}
            {/* Controls */}
            <th> </th>
        </tr>
    );
}

function SuggestionRowCell({
    suggestion, col, index, activityInfo,
}) {
    const colInfo = getColumnInfo(col);
    if (!colInfo) return null;
    const { projectCase } = colInfo;
    const { highlightActiveFrags, activityThresholdInfo: threshInfo } = activityInfo;

    const isFirstCol = index === 0;
    const colSpan = isFirstCol ? 2 : undefined;
    const defaultStyle = { textAlign: 'right', paddingRight: '20px' };
    const style = { ...defaultStyle };
    if (projectCase && highlightActiveFrags) {
        const perCaseInfo = suggestion.perCaseInfo[projectCase];
        if (perCaseInfo.isActiveOffTarget(threshInfo)) style.backgroundColor = ActiveOffTargetColor;
        if (perCaseInfo.isActiveTarget(threshInfo)) style.backgroundColor = ActiveTargetColor;
    }

    return (
        <td
            style={style}
            colSpan={colSpan}
        >
            { getTableValue(suggestion, col) }
        </td>
    );
}

function getTableValue(suggestion, col) {
    const val = getValue(suggestion, col);
    const { column, properties: { table } } = getColumnInfo(col);
    const { precision, getValue: getMyValue } = table || {};
    switch (true) {
        case precision != null:
            return val?.toFixed(precision) ?? '';
        case getMyValue != null:
            return getMyValue(val);
        case column === 'coralMap':
            return <CoralComponent coralMapInfoEntries={getInfoForCoralMap(suggestion)} />;
        case column === 'svg': {
            const altText = `2D diagram of ${suggestion.name}`;
            return <img alt={altText} width="70px" src={svgImgSrc(suggestion.img)} />;
        }
        default:
            return val;
    }
}
