/* UIManager.jsx */

import React from 'react';
import ReactDOM from 'react-dom';
import Typography from '@mui/material/Typography';
// Local
import { Loader } from 'BMapsSrc/Loader';
import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { getFullResidueId } from '../util/mol_info_utils';
import { pointDistance, pointsCentroid } from '../util/atom_distance_utils';
import { getMouseGesture } from './ui_utils';
import CanvasTooltip from '../CanvasTooltip';
import CanvasContextMenu from './CanvasContextMenu';
import { buildContextMenuForAtom, getVectorMenu } from '../display_mgr';
import { createContextMenuComponents } from './CanvasContextMenu/utils';
import { BondVectorDisplay } from '../model/display_models';
import { AlphaFoldImportCase } from '../model/MapCase';
import { AtomGroupTypes } from '../model/atomgroups';

/* renderIntoElt()
 * Renders a react component into an element, optionally creating a wrapper for the component.
 *
 * parentEltId: the id of the parent element in the DOM
 * childComponent: the React component to be added
 * wrapperType: the type of node to wrap the child in (eg 'div')
 * wrapperAttrs: an object containing attributes for the wrapper (eg 'id')
 *
 * This function appears to be unused after moving InfoDisplay into BMapsPage.
 */
export function renderIntoElt(parentEltId, childComponent, wrapperType=null, wrapperAttrs={}) {
    const parent = document.getElementById(parentEltId);
    let target = parent;
    if (wrapperType) {
        const wrapper = document.createElement(wrapperType);
        for (const [attrName, attrData] of Object.entries(wrapperAttrs)) {
            wrapper.setAttribute(attrName, attrData);
        }
        parent.appendChild(wrapper);
        target = wrapper;
    }

    ReactDOM.render(childComponent, target);
}

export default class UIManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredObject: null,
            hoveredLabel: '',
            lastMouseEvent: null,
            menu: null,
        };
        this.closeMenu = this.closeMenu.bind(this);
        this.handleAtomMouse = this.handleAtomMouse.bind(this);
        this.handleAtomMouse2d = this.handleAtomMouse2d.bind(this);
        this.handleShapeMouse = this.handleShapeMouse.bind(this);
        this.handleBackgroundMouse = this.handleBackgroundMouse.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('atomMouse', this.handleAtomMouse);
        EventBroker.subscribe('atomMouse2d', this.handleAtomMouse2d);
        EventBroker.subscribe('shapeMouse', this.handleShapeMouse);
        EventBroker.subscribe('backgroundMouse', this.handleBackgroundMouse);
        EventBroker.subscribe('zapAll', this.handleZapAll);
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('atomMouse', this.handleAtomMouse);
        EventBroker.unsubscribe('atomMouse2d', this.handleAtomMouse2d);
        EventBroker.unsubscribe('shapeMouse', this.handleShapeMouse);
        EventBroker.unsubscribe('backgroundMouse', this.handleBackgroundMouse);
        EventBroker.unsubscribe('zapAll', this.handleZapAll);
    }

    handleAtomMouse(_, eventData) {
        const atom = eventData.atom;
        const label = atom && this.buildAtomSpyContent(atom);
        // If atom and label are null it will be treated like a background click
        const event = eventData.mouseEvent;
        this.handleMouseEvent(event, atom, label);
    }

    handleAtomMouse2d(_, eventData) {
        const atomName = eventData.atomName;
        for (const atom of App.Workspace.getActiveCompoundAtoms()) {
            if (atom.atom === atomName) {
                this.handleAtomMouse(_, {
                    atom,
                    mouseEvent: eventData.mouseEvent,
                });
                break;
            }
        }
    }

    handleShapeMouse(_, data) {
        const sel = data.selected;
        if (sel && sel.description) {
            this.handleMouseEvent(data.mouseEvent, sel, sel.description);
        }
    }

    handleBackgroundMouse(_, data) {
        // data is actually the mouseEvent
        this.handleMouseEvent(data, null, null);
    }

    handleZapAll() {
        // If this component continues to move towards being exclusively for 3D canvas interaction,
        // this zapAll => escapeKey subscription should be moved somewhere else.
        EventBroker.publish('escapeKey');
    }

    handleContextMenu(object, evt) {
        /** @type {import('BMapsUI/CanvasContextMenu/utils').menuItem[]} */
        let menuItems;
        let menuHandler;

        if (this.isAtom(object)) {
            ({ menuItems, menuHandler } = buildContextMenuForAtom(object, evt, this.closeMenu));
        } else if (this.isBondVector(object)) {
            const { atomPair } = object.object;
            ({ menuItems, menuHandler } = getVectorMenu(atomPair));
        }

        if (menuItems) {
            const menu = createContextMenuComponents(menuItems, this.closeMenu, menuHandler);
            this.openMenu(menu, evt);
        }
    }

    handleMouseEvent(event, object, label) {
        let updateTooltip = false;
        let clearTooltip = false;

        const gesture = getMouseGesture(event);
        switch (gesture) {
            case 'click': {
                updateTooltip = true;
                break;
            }
            case 'context-menu':
                this.handleContextMenu(object, event);
                clearTooltip = true;
                updateTooltip = true;
                break;
            case 'enter':
                if (this.tooltipOk(object, event)) {
                    updateTooltip = true;
                }
                break;
            case 'move':
                updateTooltip = true;
                break;
            case 'leave':
                this.modModeMouseEvent(object, event);
                updateTooltip = true;
                break;
            // no default; ignore other mouseEvent types
        }

        if (updateTooltip) {
            this.setState({
                hoveredObject: object,
                hoveredLabel: label,
                lastMouseEvent: clearTooltip ? null : event,
            });
        }
    }

    /* eslint-disable-next-line react/sort-comp */
    tooltipOk(atom, event) {
        return !this.modModeMouseEvent(atom, event) && !this.menuIsVisible();
    }

    menuIsVisible() {
        const { menu } = this.state;
        return !!menu;
    }

    modModeMouseEvent(atom, event) {
        return false;
    }

    isAtom(object) {
        return object && object.uniqueID != null;
    }

    isBondVector(object) {
        return object && object.object instanceof BondVectorDisplay;
    }

    getMousePos(mouseEvent) {
        const x = (mouseEvent.clientX !== undefined
            ? mouseEvent.clientX // click events
            : mouseEvent.changedTouches[0].clientX) // touch events
            || 0;
        const y = (mouseEvent.clientY !== undefined
            ? mouseEvent.clientY // click events
            : mouseEvent.changedTouches[0].clientY) // touch events
            || 0;
        // -2 fudge factor guarantees the menu will be under the mouse, so it will catch a "leave"
        return { x: x - 2, y: y - 2 };
    }

    openMenu(items, mouseEvent) {
        const position = this.getMousePos(mouseEvent);
        this.setState({
            menu: { items, position },
        });
    }

    closeMenu() {
        this.setState({ menu: null });
        EventBroker.publish('mainContextMenuWrapperClose');
    }

    render() {
        const {
            menu, hoveredObject, hoveredLabel, lastMouseEvent,
        } = this.state;
        return (
            <>
                <CanvasTooltip
                    object={hoveredObject}
                    label={hoveredLabel}
                    mouseEvent={lastMouseEvent}
                />
                {!!menu
                && (
                    <CanvasContextMenu
                        mousePos={menu.position}
                        closeMenu={this.closeMenu}
                    >
                        {menu.items}
                    </CanvasContextMenu>
                )}
            </>
        );
    }

    /**
     * Data for an Atom canvas tooltip
     * @param {import('BMapsModel').Atom} atom
     */
    buildAtomSpyContent(atom) {
        if (atom.fragment && atom.fragment.isHotspot) {
            return this.hotspotDescription(atom);
        }

        const atomGroup = atom.getAtomGroup();
        let groupTypeLabel;
        switch (atomGroup?.type) {
            case AtomGroupTypes.Residue:
            case AtomGroupTypes.Polymer:
            case AtomGroupTypes.Protein:
            case AtomGroupTypes.PeptideLigand:
                groupTypeLabel = 'Residue';
                break;
            case AtomGroupTypes.Ligand:
                groupTypeLabel = 'Ligand';
                break;
            case AtomGroupTypes.Compound:
                groupTypeLabel = 'Compound';
                break;
            case AtomGroupTypes.Cofactor:
                groupTypeLabel = 'Cofactor';
                break;
            case AtomGroupTypes.Ion:
                groupTypeLabel = 'Ion';
                break;
            case AtomGroupTypes.Fragment:
                groupTypeLabel = 'Fragment';
                break;
            case AtomGroupTypes.ComputedWater:
            case AtomGroupTypes.CrystalWater:
                groupTypeLabel = 'Water';
                break;
            case undefined:
                // This is known to happen for atoms in suggestion fragments after fragment search.
                console.warn(`Atom group is missing for atom ${atom}`);
                groupTypeLabel = '';
                break;
            default:
                groupTypeLabel = 'Atom Group';
        }
        const descriptionLines = [
            ['Atom Name', atom.atom],
            [`${groupTypeLabel || 'Parent'} Id`, getFullResidueId(atom)],
        ];
        if (Loader.AllowDevFeatures) {
            descriptionLines.splice(1, 0, ['Atom uniqueID (dev only)', atom.uniqueID], ['Amber Type (dev only)', atom.amber]);
        }
        const caseLabel = App.Workspace.getCaseLabel(atom);
        if (caseLabel) descriptionLines.push(['Target', caseLabel]);

        const frag = atom.fragment;

        if (frag && frag.exchemPotential && frag.exchemPotential !== 0) {
            const waterOffset = frag.baseFrag.name === 'water' ? -5.5 : 0; // -5.5 is because excess cp is most usefully referenced to bulk water.
            descriptionLines.push(['Ex. Chem. Potential', `${(frag.exchemPotential-waterOffset).toFixed(2)} kcal/mol`]);
        }
        if (atom.charge) {
            descriptionLines.push(['Charge (Q)', atom.charge.toFixed(2)]);
        }
        const moreInfo = this.getDistanceAndAngleInfo(atom);
        if (moreInfo.length > 0) {
            descriptionLines.push(...moreInfo);
        }
        if (atom.Bfactor != null) {
            let label = 'B-factor';
            const { mapCase } = App.getDataParents(atom);
            if (mapCase instanceof AlphaFoldImportCase) {
                label = 'AlphaFold Confidence Score';
            }
            descriptionLines.push([label, atom.Bfactor.toFixed(2)]);
        }
        return {
            type: 'table',
            title: groupTypeLabel ? `${groupTypeLabel} Atom` : 'Atom',
            body: descriptionLines,
        };
    }

    hotspotDescription(atom) {
        const hotspotNum = atom.fragment.fragmentGroup;
        let title;
        let header;
        let body;
        let extra;
        const { caseData } = App.getDataParents(atom);
        const hotspot = caseData.getHotspots().find(
            (h) => h.fragmentGroupNumber === hotspotNum,
        );

        if (hotspot) {
            const fragmentLines = [];
            const fragments = hotspot.getFragments();
            fragments.sort((a, b) => {
                if (a.exchemPotential === b.exchemPotential) {
                    return 0;
                } else if (a.exchemPotential == null || b.exchemPotential == null) {
                    return (a.exchemPotential == null) ? 1 : -1;
                } else {
                    return (a.exchemPotential < b.exchemPotential) ? -1 : 1;
                }
            });

            const allAtoms = [];
            for (const f of fragments) {
                let exchempDisplay = '';
                if (f.exchemPotential != null) {
                    const cp = f.exchemPotential.toFixed(2);
                    exchempDisplay = `${cp} kcal/mol`;
                }
                fragmentLines.push([f.parentName, exchempDisplay]);
                allAtoms.push(...f.atoms);
            }
            title = hotspot.displayName;
            const centroid = pointsCentroid(allAtoms);
            const caseLabel = App.Workspace.getCaseLabel(atom);
            if (caseLabel) title = `${caseLabel} ${title}`;
            header = ['ExChemP. (Avg)', `${hotspot.exchemPotentialAvg.toFixed(2)} kcal/mol`];
            body = fragmentLines;
            extra = (
                <Typography component="div" style={{ fontSize: 'smaller' }}>
                    <div style={{ fontWeight: 'bold' }}>Other info:</div>
                    <div>
                        Centroid:
                        {' '}
                        {centroid.map((n) => n.toFixed(1)).join(', ')}
                    </div>
                </Typography>
            );
        }
        const sendBack = {
            type: 'table',
            title,
            header,
            body,
            extra,
        };
        return sendBack;
    }

    getDistanceAndAngleInfo(atom) {
        const distanceEntries = [];
        const selectedAtoms = App.Workspace.getSelectedAtoms();

        const display3Dval = (val) => val.map((coord) => coord.toFixed(2)).join(', ');

        distanceEntries.push(['Position', `${display3Dval(atom.getPosition())}`]);
        if (selectedAtoms.length === 1) { // calculate distance
            const distance = pointDistance(atom, selectedAtoms[0]);
            distanceEntries.push(['Distance from selected atom', `${distance.toFixed(2)} Å`]);
        } else if (selectedAtoms.includes(atom) && selectedAtoms.length > 1) {
            const centroid = pointsCentroid(selectedAtoms);
            distanceEntries.push(['Selection Centroid', `${display3Dval(centroid)}`]);
        }
        // TODO: add cases for angle and torsion
        // These are dependent on the order in which atoms were selected.
        return distanceEntries;
    }
}

class DebugDisplay extends React.Component {
    render() {
        const { html } = this.props;
        return ReactDOM.createPortal(
            <div dangerouslySetInnerHTML={{ __html: html }} />,
            document.getElementById('debug_display'),
        );
    }
}
