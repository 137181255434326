import {
    useEffect, useLayoutEffect, useRef, useState,
} from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import CloseIcon from '@mui/icons-material/Close';

import { UserActions } from 'BMapsCmds';
import { AtomGroup, AtomGroupTypes, Polymer } from 'BMapsSrc/model';
import { MolStyles, StyleManager } from 'BMapsSrc/style_manager';
import { ColorChain, DefaultColorCode } from 'BMapsSrc/themes';
import LinkLikeButton from 'BMapsSrc/ui/common/LinkLikeButton';
import { IncrementDecrementInput } from 'BMapsSrc/ui/common/IncrementDecrementInput';

/**
 *
 * @param {{
 * atomGroup: import('BMapsSrc/model').AtomGroupLike
 * displayState: any
 * }} param0
 */

export default function StyleMenuControl({ atomGroup, displayState }) {
    const isIon = atomGroup.type === AtomGroupTypes.Ion;

    const styles = StyleManager.getCustomAtomGroupStyle(atomGroup);
    const [style, setStyle] = useState({
        color: styles?.color || '',
        colorAllAtoms: !!(isIon || styles?.colorAllAtoms),
        molStyle: styles.molStyle || '',
        showLabel: !!styles.showLabel,
        type: atomGroup.type,
        atomGroup,
    });

    const [menuHeight, setMenuHeight] = useState(0);
    const menuRef = useRef();
    const [anchorElement, setAnchorElement] = useState();
    const [selectorOpen, setSelectorOpen] = useState(false);

    useEffect(() => {
        setAnchorElement(menuRef.current);
    }, [menuRef]);

    useLayoutEffect(() => {
        setMenuHeight(menuRef.current.offsetHeight);
    }, [menuRef]);

    const colorMenuItems = () => Object.entries(ColorChain)
        .map(([name, color]) => (
            <MenuItem value={color} key={color}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {color !== DefaultColorCode && (
                        <div
                            style={{
                                width: '1em',
                                height: '1em',
                                borderRadius: '20%',
                                marginRight: '0.5em',
                                backgroundColor: `#${color.toString(16).padStart(6, '0')}`,
                            }}
                        />
                    )}
                    {name}
                </div>
            </MenuItem>
        ));

    const supportedTypes = [
        AtomGroupTypes.Protein, AtomGroupTypes.Polymer,
        AtomGroupTypes.Residue,
        AtomGroupTypes.Compound, AtomGroupTypes.Ligand,
        AtomGroupTypes.Cofactor, AtomGroupTypes.Ion,
        AtomGroupTypes.Fragment, AtomGroupTypes.PeptideLigand,
        // displayWaters logic (in display_mgr) currently bypasses the style manager
    ];
    const isSupported = supportedTypes.includes(atomGroup.type);

    // Mol style menu
    const molStylesToAllow = {
        [MolStyles.default]: 'Default Style',
        [MolStyles.hidden]: 'Hide',
        [MolStyles.invisible]: 'Invisible (mouse-interactive)',
        [MolStyles.wireframe]: 'Wireframe',
        [MolStyles.sticks]: 'Sticks',
        [MolStyles.ballandstick]: 'Ball and stick',
        [MolStyles.spacefill]: 'Spacefill',
        [MolStyles.cartoon]: 'Ribbons',
        [MolStyles.tribbons]: 'Transparent Ribbons',
    };

    const polymerOnly = [MolStyles.cartoon, MolStyles.tribbons];
    const notForIons = [MolStyles.wireframe, MolStyles.sticks];
    const molStyleMenuItems = () => Object.entries(molStylesToAllow)
        .filter(([newStyle, _]) => {
            if (polymerOnly.includes(newStyle) && !(atomGroup instanceof Polymer)) {
                return false;
            }
            if (isIon && notForIons.includes(newStyle)) {
                return false;
            }
            return true;
        })
        .map(([newStyle, label]) => (
            <MenuItem value={newStyle} key={newStyle}>
                {label}
            </MenuItem>
        ));
    const offerLabel = displayState.bindingsite && !AtomGroup.ChainTypes.includes(atomGroup.type);

    return ( // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            style={{
                display: 'flex',
                padding: '0 0 0 24px',
            }}
            // Stop propagation on this parent div allows keyboard navigation within the submenu.
            // This is a different solution from FragDataMenuControl,
            // which has stopPropagation on individual input elements.
            onKeyDown={(evt) => evt.stopPropagation()}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                ref={menuRef}
            >
                { isSupported ? (
                    <>
                        <Typography style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1rem' }}>
                            {`${style.type} Style`}
                        </Typography>
                        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                            <InputLabel id={`${style.type}-molStyle-label`}>Molecular Style</InputLabel>
                            <Select
                                labelId={`${style.type}-molStyle-label`}
                                id={`${style.type}-molStyle`}
                                value={style.molStyle}
                                label="Molecular Style"
                                onChange={(event) => {
                                    UserActions.SetCustomAtomGroupStyle(style.atomGroup, {
                                        molStyle: event.target.value,
                                    });
                                    setStyle({ ...style, molStyle: event.target.value });
                                    UserActions.RedrawScene();
                                }}
                                onOpen={() => setSelectorOpen(true)}
                                onClose={() => setSelectorOpen(false)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            boxShadow: 'none',
                                            height: menuHeight,
                                        },
                                    },
                                    anchorEl: anchorElement,
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    },
                                }}
                            >
                                {molStyleMenuItems()}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id={`${style.type}-color-label`}>Color</InputLabel>
                            <Select
                                labelId={`${style.type}-color-label`}
                                id={`${style.type}-color`}
                                value={style.color}
                                label="Color"
                                onChange={(event) => {
                                    const newStyle = { color: event.target.value };
                                    if (style.colorAllAtoms) {
                                        // In the case that style.colorAllAtoms is true by default
                                        // (eg Ions), make sure we call it along.
                                        newStyle.colorAllAtoms = true;
                                    }
                                    UserActions.SetCustomAtomGroupStyle(style.atomGroup, newStyle);
                                    setStyle({ ...style, ...newStyle });
                                    UserActions.RedrawScene();
                                }}
                                onOpen={() => setSelectorOpen(true)}
                                onClose={() => setSelectorOpen(false)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            boxShadow: 'none',
                                            height: menuHeight,
                                        },
                                    },
                                    anchorEl: anchorElement,
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    },
                                }}
                            >
                                {colorMenuItems()}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            style={{
                                marginTop: '.5rem',
                                visibility: isIon ? 'hidden' : 'visible',
                            }}
                            control={(
                                <Checkbox
                                    checked={style.colorAllAtoms}
                                    onChange={(event) => {
                                        UserActions.SetCustomAtomGroupStyle(style.atomGroup, {
                                            colorAllAtoms: event.target.checked,
                                        });
                                        setStyle({ ...style, colorAllAtoms: event.target.checked });
                                        UserActions.RedrawScene();
                                    }}
                                />
                        )}
                            label="Color all atoms, not just carbons"
                        />
                        {!!offerLabel && <LabelSection style={style} setStyle={setStyle} />}
                    </>
                ) : (
                    <Typography>Not yet supported</Typography>
                )}
            </Box>
            <CloseIcon
                style={{
                    visibility: selectorOpen ? 'visible': 'hidden',
                    color: 'gray',
                    height: '24px',
                    margin: '0 10px',
                }}
            />
        </div>
    );
}

function LabelSection({ style, setStyle }) {
    const [showOptions, setShowOptions] = useState(false);

    return (
        <>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={style.showLabel}
                            onChange={(event) => {
                                const labelUpdate = {
                                    showLabel: event.target.checked,
                                };
                                UserActions.SetCustomAtomGroupStyle(style.atomGroup, labelUpdate);
                                setStyle({ ...style, ...labelUpdate });
                                UserActions.RedrawScene();
                            }}
                        />
                    )}
                    label="Show label"
                />
                <LinkLikeButton
                    style={{ color: 'var(--marketing-blue)', fontSize: 'inherit' }}
                    onClick={() => setShowOptions((oldShowOptions) => !oldShowOptions)}
                >
                    <i className="fa fa-cog" />
                    {' '}
                    Label options
                </LinkLikeButton>
            </Box>
            {showOptions && <LabelOptions />}
        </>
    );
}

function LabelOptions() {
    const [defaults, setDefaults] = useState({ ...StyleManager.getGlobalStyleState().LabelStyle });

    const updateGlobalStyle = (incomingStyle) => {
        const newDefaults = { ...defaults, ...incomingStyle };
        UserActions.SetGlobalStyleState({ LabelStyle: newDefaults });
        setDefaults(newDefaults);
        UserActions.RedrawScene();
    };

    return (
        <Box display="flex" flexDirection="column">
            <Typography component="div" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                Global Label Style
            </Typography>
            <InputLabel htmlFor="labelmenu-default-font-size">Font size</InputLabel>
            <Box style={{ marginRight: '1rem', marginBottom: '1rem' }}>
                <IncrementDecrementInput
                    name="Font size"
                    min={6}
                    max={48}
                    value={defaults.fontSize}
                    setValue={(value) => updateGlobalStyle({ fontSize: value })}
                    incrementContainerProps={{ style: { marginLeft: 'unset' } }}
                    incrementInputProps={{ id: 'labelmenu-default-font-size' }}
                />
            </Box>
            <FormControlLabel
                control={(
                    <Checkbox
                        checked={defaults.type === 'print'}
                        onChange={(event) => {
                            const newLabelType = event.target.checked ? 'print': 'background';
                            updateGlobalStyle({ type: newLabelType });
                        }}
                    />
                )}
                label="Don't use label backgrounds"
            />
        </Box>
    );
}
