import { useState, useEffect } from 'react';
import styled from 'styled-components';
// Material UI
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
// Local
import ProteinContextProvider from '../../../common/ProteinContextProvider';
import TabController from '../../../common/TabController';
import ModalPane from '../../../ModalPane';
import { EventBroker } from '../../../../eventbroker';
import { Loader } from '../../../../Loader';
import tabs from './Tabs';

const SketcherBtn = styled(Button)`
    text-transform: none;
    font-size: small;
    font-weight: normal;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: rgba(255, 255, 255, 0.8);
    color: rgb(0, 0, 255);
    border-radius: 0.5em;
    &:hover {
        background-color: rgba(255, 255, 255, 0.8);
    }
`;

export default function ImportCompoundPane({ ModalPaneProps }) {
    const [open, setOpen] = useState(false);
    const [tabBarValue, setTabBarValue] = useState(null);
    const [initData, setInitData] = useState({});

    const openImportPane = (tabId, data) => {
        const defaultTabId = 'import_dragpaste_tab';
        setTabBarValue(tabId || defaultTabId);
        if (data) {
            setInitData(data);
        }
        setOpen(true);
    };
    const closeImportPane = () => {
        setOpen(false);
        setInitData({});
    };

    useEffect(() => {
        const openImportTabSubscription = EventBroker.subscribe(
            'openImportTab',
            (name, args={ tabId: null, args: null }) => openImportPane(args.tabId, args.args)
        );
        return () => {
            EventBroker.unsubscribe('openImportTab', openImportTabSubscription);
        };
    });

    return (
        <ModalPane
            visible={open}
            style={{ width: '100%' }}
            title="Compound Import"
            closeOnEscape
            close={closeImportPane}
            titleAdditions={<Use2DSketcher closeImportPane={closeImportPane} />}
            {...ModalPaneProps}
        >
            <ProteinContextProvider defaultToFirstProtein>
                <TabController
                    tabs={tabs.map((tab) => tab.forTabController(
                        { closeImportPane, initData }
                    ))}
                    selected={tabBarValue}
                    setSelected={setTabBarValue}
                />
            </ProteinContextProvider>
        </ModalPane>
    );
}

function Use2DSketcher({ closeImportPane }) {
    function handleClick() {
        Loader.Sketcher.Display();
        closeImportPane();
    }
    return (
        <SketcherBtn onClick={handleClick}>
            <EditIcon fontSize="small" />
            Use 2d sketcher instead
        </SketcherBtn>
    );
}
