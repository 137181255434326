// visualizations/water_visualizations.js
/**
 * @fileoverview Functions to display water spheres in the canvas:
 * semi-quantitative exchemp halos for computed waters and red spheres for crystal waters.
 *
 * @typedef {object} CanvasShape
 *
 * Public interface:
 *    displayWaterHalo(show, atoms, displayParams) - used for computed waters
 *    displayOneWaterHalo(atom, displayParams) - used for both crystal and computed waters
 *    removeWaterHalos()
 *    waterChemPotential(atom) - used to determine visibility of some waters
 *
 * Internal storage:
 *   waterHalos: Map<number, CanvasShape> - maps from atom id to canvas sphere
 *
 * This is given visible waters to display halos for.
 * For computed waters, it skips halo if it doesn't have a meaninful exchemp value.
 *
 */

import { App } from 'BMapsSrc/BMapsApp';
import { isComputedWaterAtom } from 'BMapsSrc/utils';
import { drawSphere, removeSphere } from 'BMapsSrc/MainCanvas';
import { getColorFromThresholds } from 'BMapsSrc/util/visualization_utils';
import { getFullResidueId } from 'BMapsSrc/util/mol_info_utils';
import { makeTooltipLegend } from 'BMapsSrc/ui/TooltipLegend';

// Water halo thresholds are after the 5.5 kcal/mol bulk water value is subtracted.
// The moderateThreshold is thus -3 kcal/mol (or, roughly, 2.5x thermal noise) and the
// strongThreshold is -6 kcal/mol. [Might need further tuning.]
const waterHaloParams = {
    moderateColor: 0x0aff0a, // green
    neutralColor: 0xc3c5c9, // gray
    strongColor: 0xcc00cc, // magenta (red would be confused with crystal waters oxygen red)
    strongThreshold: -6,
    moderateThreshold: -3,
    moderateColorCss: '#0aff0a',
    neutralColorCss: '#c3c5c9',
    strongColorCss: '#cc00cc',
};

/// Water Halos (colored by excess chemical potential) ///
/**
 * Keep track of halos drawn around water atoms.
 * @type {Map<number, CanvasShape>}
 */
const waterHalos = new Map();

export function displayWaterHalo(show, atoms, displayParams) {
    if (show) {
        for (const atom of atoms) {
            if (isComputedWaterAtom(atom)) {
                displayOneWaterHalo(atom, displayParams);
            }
        }
    } else {
        removeWaterHalos();
    }
}

export function displayOneWaterHalo(atom, displayParams, colorIn) {
    const isComputedWater = isComputedWaterAtom(atom);

    // Draw only one sphere per water molecule, around atom id "atomSerialNumberBase"
    // Display water fragment halo's or crystal water halo's.
    if (isComputedWater
          && (!atom.fragment.exchemPotential
            || atom.uniqueID !== atom.fragment.atomSerialNumberBase)) {
        return;
    }

    const wcp = isComputedWater ? waterChemPotential(atom) : 0;
    if (isComputedWater && wcp >= 0) return; // don't display transient waters
    let color;
    if (isComputedWater) {
        color = getColorFromThresholds(wcp, waterHaloParams);
    } else {
        color = colorIn && colorIn !== 'default' ? colorIn : 0xF00000; // red
    }

    // Only show red waters in Protein View
    if (isComputedWater && needToHideHalo(color, waterHaloParams, displayParams)) {
        return;
    }

    const opacity = isComputedWater ? 0.6 : 0.7;
    const radius = isComputedWater ? 1 : 1.2;
    if (!waterHalos.get(atom.uniqueID)) {
        const caseLabel = App.Workspace.getCaseLabel(atom);
        const shapeOptions = {
            opacity,
            description: isComputedWater
                ? computedWaterHaloToolipInfo(atom, caseLabel)
                : crystalWaterTooltipInfo(atom, caseLabel),
        };
        const sphere = drawSphere(atom.getPosition({ as: 'object' }), radius, color, shapeOptions);
        waterHalos.set(atom.uniqueID, sphere);
    } else {
        console.log(`already water halo for ${atom.uniqueID}`);
    }
}

export function removeWaterHalos() {
    waterHalos.forEach((sphere) => removeSphere(sphere));
    waterHalos.clear();
}

export function waterChemPotential(atom) {
    if (!atom || !atom.fragment) return 0;
    const excessCP = atom.fragment.exchemPotential || -5.5;
    return excessCP - (-5.5); // -5.5 is the CP of bulk water
}

function computedWaterHaloToolipInfo(atom, caseLabel) {
    const wcp = waterChemPotential(atom);
    const {
        strongColorCss, neutralColorCss, moderateColorCss,
        strongThreshold, moderateThreshold,
    } = waterHaloParams;
    const ret = {
        type: 'table',
        title: 'Computed Water',
        body: [
            ['Water Id', `${getFullResidueId(atom)}`],
            ['Ex. Chem. Potential', `${wcp.toFixed(2)} kcal/mol`],
        ],
        extra: makeTooltipLegend({
            label: 'Water ex. chem. potential color range',
            ranges: [{
                color: strongColorCss, high: strongThreshold, label: 'Bound water',
            }, {
                color: neutralColorCss, low: strongThreshold, high: moderateThreshold,
            }, {
                color: moderateColorCss, low: moderateThreshold, label: 'Bulk water',
            }],

        }),
    };

    if (caseLabel) {
        ret.body.splice(1, 0, ['Target', caseLabel]);
    }
    return ret;
}

function crystalWaterTooltipInfo(atom, caseLabel) {
    const ret = {
        type: 'table',
        title: 'Crystal Water',
        body: [
            ['Water Id', `${getFullResidueId(atom)}`],
        ],
    };

    if (caseLabel) {
        ret.body.splice(1, 0, ['Target', caseLabel]);
    }
    return ret;
}

// needToHideHalo()
// We want only the red waters to be visible in protein view mode.
// haloColor : color of the halo we might hide
// haloParams : halo threshold object and colors (we look at "strongColor")
// displayParams: we look at"onlyShowStrongWaterHalos"
function needToHideHalo(haloColor, haloParams, displayParams) {
    return displayParams && displayParams.onlyShowStrongWaterHalos
        && haloColor !== haloParams.strongColor;
}
