import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';

import { UserActions } from 'BMapsCmds';
import { downloadData } from 'BMapsSrc/ui/ui_utils';
import BMapsTooltip from 'BMapsSrc/ui/BMapsTooltip';
import { changeTarget, changeProteinFamily } from '../../../../../redux/projectState';

export default function ProjectTargetsTab() {
    const { proteinFamily, targetList } = useSelector((state) => state.projectState);
    const dispatch = useDispatch();

    const targetHasDownload = (target) => !!target.mapCase.myExternalLinkInfo()?.download;
    const hasDownload = targetList.some(targetHasDownload);

    return (
        <>
            <TextField
                size="small"
                value={proteinFamily}
                label="Protein Family"
                sx={{ paddingBottom: '1rem' }}
                onChange={(evt) => {
                    dispatch(
                        changeProteinFamily(evt.target.value)
                    );
                }}
            />
            <Box
                sx={{
                    display: 'grid',
                    justifyContent: 'space-between',
                    gap: '1rem',
                }}
            >
                <Typography sx={{ gridArea: '1 / 1' }}>Structure</Typography>
                <Typography sx={{ gridArea: '1 / 2' }}>Target</Typography>
                <Typography sx={{ gridArea: '1 / 3' }}>Off Target</Typography>
                <Typography sx={{ gridArea: '1 / 4' }}>Remove</Typography>
                { hasDownload && (
                    <Typography sx={{ gridArea: '1 / 5' }}>Download</Typography>
                )}
                {targetList.map((target, index) => (
                    <React.Fragment key={target.mapCase.uri}>
                        <div style={{ gridArea: `${index + 2} / 1`, display: 'flex', alignItems: 'center' }}>
                            {target.displayName}
                        </div>
                        <div style={{ gridArea: ` ${index + 2} / 2` }}>
                            <Radio
                                checked={target.isTarget}
                                onClick={() => {
                                    dispatch(
                                        changeTarget({ mapCase: target.mapCase })
                                    );
                                }}
                            />
                        </div>
                        <div style={{ gridArea: ` ${index + 2} / 3` }}>
                            <Radio
                                checked={!target.isTarget}
                                onClick={() => {
                                    dispatch(
                                        changeTarget({ mapCase: target.mapCase })
                                    );
                                }}
                            />
                        </div>
                        <div style={{ gridArea: `${index +2} / 4` }}>
                            <IconButton
                                aria-label="delete"
                                onClick={
                                    () => {
                                        const collection = target.mapCase.getCaseDataCollection();
                                        const caseData = collection
                                            .getCaseDataByMapCase(target.mapCase);
                                        UserActions.RemoveCase(caseData);
                                    }
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        { hasDownload && (
                        <div style={{ gridArea: `${index +2} / 5` }}>
                            <DownloadTargetButton
                                target={target}
                            />
                        </div>
                        )}
                    </React.Fragment>
                ))}
            </Box>
        </>
    );
}

function DownloadTargetButton({ target }) {
    const linkInfo = target.mapCase.myExternalLinkInfo();
    const downloadInfo = linkInfo?.download;

    return (
        <BMapsTooltip
            title={downloadInfo ? linkInfo.title : 'Only folding results can be downloaded here. Use select and export to download other target data'}
        >
            <IconButton aria-label="download" disabled={!downloadInfo}>
                <GetAppIcon onClick={
                    () => {
                        if (downloadInfo) {
                            const { filename, data } = downloadInfo;
                            downloadData(filename, data);
                        }
                    }
                }
                />
            </IconButton>
        </BMapsTooltip>
    );
}
