/** hbonds.js
 *
 * Logic for calculating hydrogen bond energies.
 * See https://dev-server.boltzmannmaps.com/bmaps-wiki/index.php/Hydrogen_Bonds
 * Check out http://proteopedia.org/wiki/index.php/Hydrogen_bonds#Distances_and_Energies
 *
 * @typedef {import('BMapsModel').Atom} Atom
 * @typedef {import('BMapsModel').Compound} Compound
 * @typedef {[Atom, Atom[]]} OneHBondUnit
 * @typedef {OneHBondUnit[] } HBondResults
 * @typedef {{
 *     strongThreshold: number, moderateThreshold: number, threshold: number,
 *     maxDistance: number, minDistance: number,
 *     strongColor: string, moderateColor: string, weakColor: string,
 * }} HBondParams
 */

import {
    Length3, Add3_3, Sub3_3, Div3_1,
} from 'BMapsSrc/math';
import { simpleCond } from 'BMapsUtil/js_utils';
import { getAtomsNearAtoms, pointDistanceSquared } from 'BMapsUtil/atom_distance_utils';
import { getFullResidueId } from 'BMapsUtil/mol_info_utils';

// From the wiki page:
// Strong (quasi-covalent): 2.2-2.5A (40-14 kcal/mol)
// Moderate (electrostatic): 2.5-3.2A (14-4 kcal/mol)
// Weak (electrostatic): 3.2-4A (<4 kcal/mol -- 0.6 (1kT) is noise).
//
// Yet another reconsideration of h-bonds.  Think of them as dipole-dipole interactions,
// where the donor x-H is one dipole and the acceptor dipole x-O, x-O-x (ether), or x-N-x
// (imine), where x is a heavy atom. Donor can be OH, OH2 (water), NH, NH2, NH3 (always a
// hydrogen). Note, donors can also be acceptors, but only OHn is considered, as the NHn
// lone pair acceptors are too weak.  More specifically, the dipole charges are from the H
// and an opposite charge is at the position of the connecting x atom, and for acceptors,
// the charge is from the O/N and the opposite charge is at the bisector of the two
// connecting x atoms.  Then Coulomb's Law between these charges gives an energy (simple
// sum of 4 terms), with no distance or angle considerations necessary!  Then use the 14
// to 4 kcal/mol as the threshold points for strong, moderate, weak. When using OH has an
// acceptor, subtract the hydrogen dipole charge (i.e. it is weaker).  One consideration
// is that the charges needed to define the dipoles aren't always available, in which
// case, use average charges for these atom types (after all, we are only looking for
// semi-quantitative ranges for h-bond energies).

const hbondDonorHydrogens = new Set(['H', 'HS', 'HO', 'ho', 'HW', 'hw', 'hW', 'hn', 'hp', 'hs', 'H5']);
const hbondAcceptors = new Set(['S', 'SH', 'OH', 'oh', 'O', 'o', 'O2', 'OS', 'os', 'OW', 'ow', 'oW',
    'nb', 'NB', 'nc', 'NC', 'nd', 'ND', 'N3', 'n3', 'NY', 'F', 'f']);

const hbondAllTypes = new Set([...hbondDonorHydrogens, ...hbondAcceptors]);

export const defaultHBondParams = {
    strongThreshold: -12, // -14 is overly strong, consider making this even lower
    moderateThreshold: -4,
    threshold: -1.2,
    maxDistance: 4.0,
    minDistance: 2.0, // get steric clashes (strong vdW) below this
    strongColor: 'white',
    moderateColor: 'cyan',
    weakColor: 'lime',
/*  no longer used:
    minDonorAngle: 135,
    maxLonePairErrorAngle: 15,
    acceptorAngleHardCutoff: 90,
    showFilteredOutResults: 0,
    showLonePairVectors: 0
*/
};

/**
 * Return potential hydrogen bonds for the given compound atoms in a list of the form:
 *     [ [compoundAtom1, [1's partners...] ] , [ compoundAtom2, [2's partners...] ] ]
 * @param {Atom[]} targetAtomsIn
 * @param {Atom[]} soluteAtoms
 * @param {HBondParams} params
 */
export function findHbonds(targetAtomsIn, soluteAtoms, params=defaultHBondParams) {
    // First the target atom list is filtered by those that have an Amber type which can
    // contribute to an h-bond.
    const targetAtoms = targetAtomsIn.filter((a) => hbondAllTypes.has(a.amber));

    const results = [];
    // Restrict the atoms we are searching through based on the distance from the
    // compound and having an Amber type that can be involved with h-bonds.
    const solutePool = getAtomsNearAtoms(targetAtoms, soluteAtoms, params.maxDistance,
        { filter: (a) => hbondAllTypes.has(a.amber) });

    if (targetAtoms.length > 0 && solutePool.length > 0) {
        let partners = [];
        for (const atom of targetAtoms) {
            partners = getHBondPartners(atom, solutePool, params);
            if (partners.length > 0) results.push([atom, partners]);
        }
    } else {
        console.log(`Not enough atoms for hbonds: ${targetAtoms.length} target atoms, ${solutePool.length} solute atoms`);
    }
    return results;
}

/**
 * Is there an hbond between these two atoms?
 * @param {Atom} atom
 * @param {Atom} other
 * @param {HBondParams} params
 * @returns {boolean}
 */
export function hbondExists(atom, other, params=defaultHBondParams) {
    return getHBondPartners(atom, [other], params).length > 0;
}

// Internal functions

function getHBondPartners(atom, partnerPool, params) {
    // Actually, the terminology here is a bit ambiguous. The donor search is for
    // hydrogens connected to the donor atom.
    const atype = atom.amber;
    const isDonorHydrogen = hbondDonorHydrogens.has(atype);
    // Amber types for the potential partner atom.
    const amberTypes = simpleCond([
        [isDonorHydrogen, hbondAcceptors],
        [hbondAcceptors.has(atype), hbondDonorHydrogens],
    ]);
    if (!amberTypes) return [];
    const atomCaseData = atom.getCaseData();

    // Filter candidate atoms based on donor/acceptor distance.
    const baseAtom = isDonorHydrogen ? atom.bondedAtoms[0] : atom;
    if (!baseAtom) {
        console.warn(`hbonds with missing bonds: no baseAtom for ${getFullResidueId(atom)}-${atom.atom}`);
        return [];
    }
    const baseAtomPos = baseAtom.getPosition();
    const maxDistSq = params.maxDistance*params.maxDistance;
    const minDistSq = params.minDistance*params.minDistance;
    const hbfilter = (ppatom) => {
        if (!amberTypes.has(ppatom.amber)) return false;
        if (ppatom.bondedAtoms.length === 0) {
            console.warn(`hbonds with missing bonds: hbFilter found atom with no bonds: ${getFullResidueId(ppatom)}-${ppatom.atom}`);
            return false;
        }
        if (atomCaseData !== ppatom.getCaseData()) return false;

        const ppBaseAtom = !isDonorHydrogen ? ppatom.bondedAtoms[0] : ppatom;
        const atDistSq = pointDistanceSquared(baseAtomPos, ppBaseAtom);
        const res = atDistSq <= maxDistSq && atDistSq >= minDistSq; // waters can be overlapping.
        // if (amberTypes.has(ppatom.amber))
        /*
            console.log(`
                ${atom.atom} - ${ppatom.atom} dist ${Math.sqrt(atDistSq).toFixed(2)} res ${res}
            `);
        */
        return res;
    };
    const partners = partnerPool.filter(hbfilter);

    const partnersPlusEnergy = [];
    for (const patom of partners) {
        const en = getHBondEnergy(atom, patom, isDonorHydrogen);
        // Filter by energy only (accounts for both distance and angle).
        // console.log(`${atom.atom} - ${patom.atom} en ${en.toFixed(2)}`);
        if (en < params.threshold) partnersPlusEnergy.push([patom, en]);
    }
    return partnersPlusEnergy;
}

function getHBondEnergy(atom, other, isDonorHydrogen) {
    const donorHydrogenAtom = isDonorHydrogen ? atom : other;
    const donorBaseAtom = donorHydrogenAtom.bondedAtoms[0]; // hydrogen only has one bond
    const donorHydrogenPos = donorHydrogenAtom.getPosition();
    const donorBasePos = donorBaseAtom.getPosition();
    const dchg = donorHydrogenAtom.charge || 0; // might be undefined?

    const acceptorAtom = isDonorHydrogen ? other : atom;
    const acceptorBondedPartners = acceptorAtom.bondedAtoms;
    const acceptorPartners = acceptorBondedPartners.filter((a) => a.elem !== 'H');
    const acceptorPos = acceptorAtom.getPosition();
    let achg = acceptorAtom.charge || 0;

    //---- Could lookup standard charges if charges are zero, but conformations may not
    // be realistic.
    if (achg === 0 || dchg === 0) return 0;

    let acceptorBasePos;
    // acceptorPartners is only the heavy atoms here.
    if (acceptorPartners.length < 1) {
        // Note: Waters are handled below, because of LP atom
        console.log(`Ignoring atom ${acceptorAtom.atom}-${acceptorAtom.uniqueID} (single heavy atom) for hbond calculation.`);
        return 0; //---- Should we return 0 here? or update acceptorBasePos with our acceptorAtom
    } else if (acceptorPartners.length === 1) {
        // Cases like carbonyl oxygen, fluorine, carboxyl, etc.
        const apatom = acceptorPartners[0]; // only one heavy atom bonded
        acceptorBasePos = apatom.getPosition();
    } else {
        if (acceptorPartners.length > 2) {
            console.log(`H-bond acceptor ${acceptorAtom.atom} with > 2 heavy atom bonds.`);
        }
        // Here, could be water or acceptor with 2 heavy atom bonds, so derive the
        // acceptor partner position from the mid-point.
        const ap1 = acceptorBondedPartners[0];
        const ap2 = acceptorBondedPartners[1];
        const ap1pos = ap1.getPosition();
        const ap2pos = ap2.getPosition();
        // console.log(`ap1pos ${JSON.stringify(ap1pos)} ap2pos ${JSON.stringify(ap2pos)}`);
        acceptorBasePos = Div3_1(Add3_3(ap1pos, ap2pos), 2);
    }

    const acceptorHydrogens = acceptorBondedPartners.filter((a) => a.elem === 'H'); // for hydroxyl, etc.
    // Hydrogen charges plus acceptor charge for dipole.
    for (const hatom of acceptorHydrogens) achg += hatom.charge || 0;

    // Coulomb energy between the dipole charge points. The 331 factor makes the units of
    // charge be electron charge units and the distances in Angstroms, producing energy
    // in kcal/mol.
    const dist = (pos1, pos2) => Length3(Sub3_3(pos1, pos2));
    const d1 = donorHydrogenPos;
    const d2 = donorBasePos;
    const a1 = acceptorPos;
    const a2 = acceptorBasePos;
    const en = 331.84162*dchg*achg*(
        1/dist(d1, a1) + -1/dist(d1, a2) + -1/dist(d2, a1) + 1/dist(d2, a2)
    );
    return en;
}

/* Hydrogen Bond Parameters from bfd monitors.tcl:
#
# maxDistance: maximum distance (in A) for discovered h bonds, from donor to acceptor.
# minDonorAngle:
#   http://journals.iucr.org/services/cif/hbonds.html - modified per
#   2011-12-08 email conversation with JLK
#   minimum angle ABC for discovered h bonds where A is the position
#   of the atom bonded to H, B is the position of H, and C is the
#   position of the H bond acceptor atom
# maxLonePairErrorAngle:
#   maximum allowable deviation in degrees between the vectors AH
#   and AL (where A is the position of the h-bond acceptor atom, H is the
#   position of the hydrogen atom, L is the position of the lone
#   pair on the h-bond acceptor atom)
# acceptorAngleHardCutoff:
#   don't accept any h bonds where the angle between the H atom,
#   acceptor atom and single atom that's bonded to the acceptor
#   atom is less than this cutoff
*/
/*
    // geometry restrictions:
    // (1) donor - H ... acceptor angle > [hbondparams-minDonorAngle $params]
    let filtered = filterByDonorHAcceptorAngle(partners, atom, params);

    //(2a) "cone" test (less than hard cutoff angle; C=O case):
    filtered = filterByAcceptorConeTest(filtered, atom, params);
    //(2b) lone pair directional vector test:
    filtered = filterByAcceptorLonePairAngle(filtered, atom, params);
    return filtered.map(a => [a, 0]);
*/
/*

export function getBondedToHAtom(hAtom) {
    const bondedAtoms = hAtom.bondedAtoms;
    if (bondedAtoms.length > 0 && bondedAtoms[0]) {
        return bondedAtoms[0];
    } else {
        return null;
    }
}

let TrigonalAngleDeg = 120;
let halfTetrahedralAngle = Math.acos(-1.0/3) * 0.5;
let cosHalfTetrahedralAngle = Math.cos(halfTetrahedralAngle);
let sinHalfTetrahedralAngle = Math.sin(halfTetrahedralAngle);

function filterByDonorHAcceptorAngle ( candidateAtoms, atom, params) {
    let hBondable = [];
    const angleLimit = params.minDonorAngle;
    if (atom.elem === 'H') {
        const hAtom = atom;
        const bondedToH = getBondedToHAtom(hAtom);
        if (bondedToH) {
            for (let otherAtom of candidateAtoms) {
                if (isAngleSmallerThan(bondedToH, hAtom, otherAtom, angleLimit)) {
                    hBondable.push(otherAtom);
                    console.log(`
                        Passed DonorHAcceptorAngle
                        ${[bondedToH, hAtom, otherAtom].map(x=>x.elem+x.uniqueID)}
                    `);
                }
            }
        }
    } else {
        const otherAtom = atom;
        for (let hAtom of candidateAtoms) {
            const bondedToH = getBondedToHAtom(hAtom);
            if (bondedToH) {
                if (isAngleSmallerThan(bondedToH, hAtom, otherAtom, angleLimit)) {
                    hBondable.push(hAtom);
                    console.log(`
                        Passed DonorHAcceptorAngle
                        ${[bondedToH, hAtom, otherAtom].map(x=>x.elem+x.uniqueID)}
                    `);
                }
            }
        }
    }

    return hBondable;
}

function filterByAcceptorConeTest ( candidateAtoms, atom, params) {
    const hbondable = [];
    let donor, acceptor;
    if (atom.elem === 'H') {
        donor = atom;
    } else {
        acceptor = atom;
    }

    for (let candidate of candidateAtoms) {
        if (atom.elem === 'H') {
            acceptor = candidate;
        } else {
            donor = candidate;
        }
        if (lonePairConeTest(acceptor, donor, params))
            hbondable.push(candidate);
    }

    return hbondable;
}

function filterByAcceptorLonePairAngle ( candidateAtoms, atom, params) {
    const maxLonePairErrorAngleDeg = params.maxLonePairErrorAngle;
    const atomPos = [atom.x, atom.y, atom.z];
    const toRemove = [];
    if (atom.elem === 'H') {
        // atom is the donor
        for (let c of candidateAtoms) {
            // If not simple angle text, try filtering by angle test
            // with respect to found lone pair directional vectors
            // c is the potential h bond acceptor
            const directionals = getLonePairDirectionals(c, params);
            if (directionals.length > 0) {
                let ok = 0;
                const cPos = [c.x, c.y, c.z];
                for (let directional of directionals) {
                    const directionalPos = Add3_3(cPos, directional);
                    const angleDeg = DegreesFromRadians(
                        Math.abs(AngleBetweenPoints(directionalPos, cPos, atomPos))
                    );
                    if (angleDeg <= maxLonePairErrorAngleDeg) {
                        ok = 1;
                    }
                }

                if (!ok) {
                    console.log(`
                        filterByAcceptorLonePairAngle: filtering candidate atom
                        ${c.atom}-${c.uniqueID} for ${atom.atom}-${atom.uniqueID}
                    `);
                    toRemove.push(c);
                }
            }
        }
    } else { // atom is not Hydrogen
        // atom is the acceptor
        // Try to filter by lone pair directional
        const directionals = getLonePairDirectionals(atom, params);
        if (directionals.length > 0) {
            const directionalPositions = directionals.map( d => Add3_3(atomPos, d));
            // here candidateAtoms are the H atoms
            for (let c of candidateAtoms) {
                let ok = 0;
                const cPos = [c.x, c.y, c.z];
                for (let directionalPos of directionalPositions) {
                    const angleDeg = DegreesFromRadians(
                        Math.abs(AngleBetweenPoints(directionalPos, cPos, atomPos))
                    );
                    if (angleDeg <= maxLonePairErrorAngleDeg) {
                        ok = 1;
                    }
                }

                if (!ok) {
                    console.log(`
                        filterByAcceptorLonePairAngle: filtering candidate atom
                        ${c.atom}-${c.uniqueID} for ${atom.atom}-${atom.uniqueID}
                    `);
                    toRemove.push(c);
                }
            }
        }
    }

    return candidateAtoms.filter(x => !toRemove.includes(x));
}

function getLonePairDirectionals(atom, params) {
    let directionals = [];
    const bonds = atom.bondedAtoms;
    const numBonds = bonds.length;
    if (numBonds === 0) {
        return directionals;
    }

    const firstBondType = atom.bondOrder[0];
    // Current possibilities for hydrogen bond acceptors are N, O, F, S
    const elem = atom.elem;

    switch (elem) {
        case 'N':
            // valid for numBonds = 1,2,3
            if (numBonds === 3) {
                // If 3 single bonds, there's 1 lone pair and the N can
                // accept an H bond in a tetrahedral shape.  If there
                // are any double/delocalized/indeterminate bonds of
                // the 3, then likely there's no lone pair.
                if (firstBondType === 1 &&
                    atom.bondOrder[1] === 1 &&
                    atom.bondOrder[2] === 1) {
                        // sp3 - tetrahedral
                        directionals = getDirectionalAwayFromAverage(atom, params);
                    }
            } else if (numBonds === 2) {
                // TODO: maybe should ensure that one bond is
                // double/aromatic-double and the other is single/aromatic-single
                // sp2 trigonal planar
                directionals = getDirectionalAwayFromAverage(atom, params);
            } else if (numBonds === 1 && firstBondType === 3) {
                // sp linear
                directionals = getDirectionalAwayFromAverage(atom, params);
            }
            break;
        case 'O':
            // IsSulfonylOxygen(atom, numBonds); // NYI, just ported from tcl
            if (numBonds === 1) {
                if (firstBondType === 1) {
                    // 1 single bond on an O (is it O- ?) - sp3
                } else if (firstBondType === 2) {
                    // this (sp2 trigonal) case is handled by LonePairConeTest
                    // since we want to look for anything in a 120
                    // degree cone, not just specific vectors along
                    // that cone's surface
                // } else if (firstBondType === 1.5 || firstBondType > 3) {
                    // sulfonyl-type case - sp linear:
                    //--- TODO delocalized cases?  Right now delocalized bonds are overridden by
                    // 3dmol_interface:makeBond()
                    // directionals = getDirectionalAwayFromAverage(atom, params);
                // } else if (firstBondType === 'indeterminate') {
                    //--- TODO implement indeterminate case
//                }
                }
            } else if (numBonds === 2) {
                // sp3 tetrahedral
                directionals = get2DirectionalsForSp3(atom, params);
            } else if (numBonds === 3) {
                // One of these is probably amber-type WC (water charge) -
                // Ignore it for the purposes of finding the lone pair directional vectors
                if (atom.amber.toUpperCase() === 'OW') {
                    const bondsSubset = [];
                    for (let b of atom.bondedAtoms) {
                        const amberType = b.amber;
                        if (amberType.toUpperCase() !== 'WC') {
                            bondsSubset.push(b);
                        }
                    }
                    if (bondsSubset.length === 2) {
                        // sp3 tetrahedral
                        directionals = get2DirectionalsForSp3(atom, params);
                    }
                }
            }
            break;
        case 'F':
            // sp linear
            directionals = getDirectionalAwayFromAverage(atom, params);
            break;
        case 'S':
            // TODO where are the lone pairs on a S atom
            break;
    }

    return directionals;
}

function getDirectionalAwayFromAverage(atom, params) {
    // no argument checking yet
    const atomPos = [atom.x, atom.y, atom.z];
    // need to be normalized since they may not all be the same length
    const bondedVNorm = getBondedNormVectors(atom);
    const directional = Normalize3( Mul3_1( Avg3(bondedVNorm), -1));

    // TOOD: show lone pair vectors for debugging
    return [directional];
}

// Find lone pair directional vectors for an atom in sp3 orbital with
// 2 single bonds and 2 lone pairs (e.g. O)
function get2DirectionalsForSp3(atom, params) {
    // no argument checking yet

    // normalized vectors Vn1 Vn2 in the direction of the existing bonds
    const [Vn1, Vn2] = getBondedNormVectors(atom);
    // normalized bisector Bn of Vn1 and Vn2; reverse its direction
    const bisectorNormNeg = Mul3_1(Normalize3(Add3_3(Vn1, Vn2)), -1);
    const crossNorm = Normalize3(Cross3(Vn1, Vn2));
    // then the 2 lone pair directional (already normalized) are
    // found along the unit circle by:
    //  L1= -cos(theta)Bn + sin(theta)Cn
    //  L2= -cos(theta)Bn - sin(theta)Cn
    const term1 = Mul3_1(bisectorNormNeg, cosHalfTetrahedralAngle);
    const term2 = Mul3_1(crossNorm, sinHalfTetrahedralAngle);
    const L1 = Add3_3(term1, term2);
    const L2 = Sub3_3(term1, term2);

    // debugging code
    return [L1, L2];
}

// get the list of normalized vectors pointing away from $atom in the
// direction of each of its $bonds
function getBondedNormVectors(atom) {
    const atomPos = [atom.x, atom.y, atom.z];
    return atom.bondedAtoms.map( b =>
        Normalize3(Sub3_3([b.x, b.y, b.z], atomPos))
    );
}

// returns 2-tuple of binary values:
//  first value=1 if simple angle test applies to this acceptorAtom, 0
//  if not applicable
//
//  second value=1 if donorAtom within valid range for h bond, 0 if not
//  valid (second value is meaningless when first value === 0)

function lonePairConeTest(acceptorAtom, donorAtom, params) {
    const bonds = acceptorAtom.bondedAtoms;
    const bondOrder = acceptorAtom.bondOrder;
    const numBonds = bonds.length;

    if (acceptorAtom.elem === 'O' && numBonds === 1) {
        if (bondOrder[0] === 2) { // If first bond is a double-bond
            const maxLonePairErrorAngle = params.maxLonePairErrorAngle;
            const otherAtom = bonds[0];
            const angleDeg = getDonorAcceptorOtherAngleDeg(donorAtom, acceptorAtom, otherAtom);
            if (
                (TrigonalAngleDeg - maxLonePairErrorAngle) > angleDeg
                || angleDeg > (TrigonalAngleDeg + maxLonePairErrorAngle)
            ) {
                // Filter it out
                console.log(`
                    lonePairConeTest filtering2 ${donorAtom.atom}-${donorAtom.uniqueID}
                    ${acceptorAtom.atom}-${acceptorAtom.uniqueID}
                    ${otherAtom.atom}-${otherAtom.uniqueID}
                `);
                return 0;
            }
        }
    }

    if (numBonds === 1) {
        const hardCutoffAngle = params.acceptorAngleHardCutoff;
        // don't accept anything that's more acute than the hard cutoff
        const otherAtom = bonds[0];
        const angleDeg = getDonorAcceptorOtherAngleDeg(donorAtom, acceptorAtom, otherAtom);
        if (angleDeg < hardCutoffAngle) {
            console.log(`
                lonePairConeTest filtering2 ${donorAtom.atom}-${donorAtom.uniqueID}
                ${acceptorAtom.atom}-${acceptorAtom.uniqueID}
                ${otherAtom.atom}-${otherAtom.uniqueID}`);
            return 0;
        }
    }

    return 1;
}

function getDonorAcceptorOtherAngleDeg(donorAtom, acceptorAtom, otherAtom) {
    const posD = ['x','y','z'].map(v=> donorAtom[v]);
    const posA = ['x','y','z'].map(v=> acceptorAtom[v]);
    const posO = ['x','y','z'].map(v=> otherAtom[v]);
    let angle = DegreesFromRadians(Math.abs(AngleBetweenPoints(posD, posA, posO)));
    return angle;
}

function isAngleSmallerThan(atom1, atom2, atom3, angleLimit) {
    const p1 = ['x','y','z'].map(v=> atom1[v]);
    const p2 = ['x','y','z'].map(v=> atom2[v]);
    const p3 = ['x','y','z'].map(v=> atom3[v]);

    const angleDeg = DegreesFromRadians(Math.abs(AngleBetweenPoints(p1, p2, p3)));
    return angleLimit <= angleDeg;
}
*/

/*
    Hydrogen Bond result filtering from bfd monitors.tcl:

    set atomANumber [get atomic-number of atom $atom]

    # geometry restrictions:
    # (1) donor - H ... acceptor angle > [hbondparams-minDonorAngle $params]
    set hBondable [FilterByDonorHAcceptorAngle $withinRange $atom $atomANumber $params]

    # (2) H ... acceptor - lone pair angle:
    #   Hydrogen bonds are generally formed along the direction of the free
    #   electron pair of the acceptor atom. For example, the preferred
    #   angle C=O...H has its peak at 120 deg, corresponding to the
    #   lone pair direction of the carbonyl oxygen.

    #(2a) "cone" test (less than hard cutoff angle; C=O case):
    set hBondable [FilterByAcceptorConeTest $hBondable $atom $atomANumber $params]

    #(2b) lone pair directional vector test:
    set hBondable [FilterByAcceptorLonePairAngle $hBondable $atom $atomANumber $params]
*/
