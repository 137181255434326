/**
 * Due to the fact that when an MUI element is disabled the MUI tooltip is also disabled,
 * we wrap disabled components with a div to hold the title.
 * This is only necessary in the non-"native" case
 * @param {*} component
 * @returns <div>Component</div>
 */
export default function wrapForDisabledTooltip(component, { WrapperProps }={}) {
    const { key } = component;
    const { title, disabled } = component.props;
    if (!disabled) return component;
    return <div key={key} title={title} {...WrapperProps}>{component}</div>;
}
