import { MapCase, PdbImportCase, AlphaFoldImportCase } from '../model/MapCase';
import { hasPreviewModeError } from '../utils';

/**
 * Consolidate raw load errors from server into a user-facing message.
 * @param {MapCase} caseInfo MapCase we had attempted to load
 * @param {string[]} errors Raw errors returned by the server
 * @param {*} anythingLoaded Whether or not any data was loaded (CaseData but treated as a boolean)
 * @returns {string?} Error message to display to the user
 */
export function getStructureErrMsg(caseInfo, errors, anythingLoaded) {
    if (errors.length === 0 && !hasPreviewModeError(errors)) {
        return null;
    }

    const [error] = errors;
    let errorMsg = `${anythingLoaded ? 'There were errors loading' : 'Failed to load'} ${caseInfo.displayName}.`;
    if (caseInfo instanceof PdbImportCase) {
        const nonExistentPdbErrTexts = [
            'Nothing found for ID',
            'Did not find', // Did not find PDB entry...
        ];
        if (nonExistentPdbErrTexts.some((text) => error.includes(text))) {
            errorMsg += ' Unknown PDB id.';
        } else {
            errorMsg += ' This PDB structure is not yet supported by BMaps.';
        }
    } else if (caseInfo instanceof AlphaFoldImportCase) {
        const nonExistentAlphaFoldErrTexts = [
            'Nothing found for ID',
            'Did not find', // Did not find 'numFound' ... (workaround server bug)
        ];
        if (nonExistentAlphaFoldErrTexts.some((text) => error.includes(text))) {
            errorMsg += ' Unknown AlphaFold id.';
        } else {
            errorMsg += ' This AlphaFold structure is not yet supported by BMaps.';
        }
    }
    return errorMsg;
}
