import { useEffect } from 'react';
import { Tour } from './Tour';

export default function TourComponent() {
    useEffect(() => {
        Tour.Init(); // Subscribe to DOM events: window resize / document keyup
    }, []);

    function onMove(step) {
        if (Tour.CurrentTour) Tour.CurrentTour.step(step);
    }
    function onCancel() {
        if (Tour.CurrentTour) Tour.CurrentTour.skipTour();
    }

    return (
        <>
            <div id="tourHighlight" style={{ display: 'none' }} />
            <div id="tourBox" style={{ display: 'none' }}>
                <div className="tourIndicatorLeft"><i className="fa fa-hand-o-left" /></div>
                <div className="tourContent">
                    <div id="tourText" />
                    <div className="tourControls">
                        <input type="button" id="tourPrev" onClick={() => { onMove(-1); }} />
                        <input type="button" id="tourCancel" onClick={() => { onCancel(); }} />
                        <input type="button" id="tourNext" onClick={() => { onMove(1); }} />
                    </div>
                </div>
                <div className="tourIndicatorRight"><i className="fa fa-hand-o-right" /></div>
            </div>
        </>
    );
}
