import React, {
    useState, useRef, useCallback,
} from 'react';
import styled from 'styled-components';
// Material UI
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import ControlButton from './ControlButton';
import { useJQueryHandlers } from '../../ui_utils';

const ActionsBackground = styled(Grid)`
  position: absolute;
  overflow: hidden;
  height: auto;
  width: auto;
`;

/**
 * @todo Remove dependency on var(--mol-button-margin)
 */
export default function SpeedDial({
    control, ActionsBackgroundProps, ActionsContainerProps, children,
}) {
    const [open, setOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const controlRef = useRef(null);
    const containerRef = useRef(null);

    const handleControlClick = useCallback(() => {
        setOpen((o) => !o);
        if (!open) {
            setTooltipOpen(false);
        }
    }, [open]);

    const handleMouseLeave = useCallback(() => {
        setOpen(false);
    }, []);

    useJQueryHandlers(containerRef.current, { mouseleave: handleMouseLeave });

    // TODO: Consider investigating a way of supplying a custom control component without cloning.
    // The cloning allows you to add the necessary props: ref, onClick, TooltipProps.
    const controlComp = control ? React.cloneElement(control, {
        ref: controlRef,
        onClick: handleControlClick,
        TooltipProps: {
            open: tooltipOpen,
            onClose: () => setTooltipOpen(false),
            onOpen: () => setTooltipOpen(!open),
        },
    }) : (
        <ControlButton
            ref={controlRef}
            onClick={handleControlClick}
            TooltipProps={{
                open: tooltipOpen,
                onClose: () => setTooltipOpen(false),
                onOpen: () => setTooltipOpen(!open),
            }}
        />
    );

    const rightOffset = controlRef.current?.offsetWidth ?? undefined;

    return (
        <Grid
            container
            item
            justifyContent="center"
            className="buttonDrawer"
            onMouseLeave={handleMouseLeave}
            position="relative"
            ref={containerRef}
        >
            {controlComp}
            { !!open
            && (
                // TODO: Support slider animation on close (currently it just disappears)
                // Probably requires reorganzing the children so <Slider> is on the outside,
                // which probably requires specifying the offset (currently in ActionContainerProps)
                <ActionsBackground
                    right={`${rightOffset}px`}
                    {...ActionsBackgroundProps}
                    container
                >
                    <Grid
                        container
                        overflow="hidden"
                        {...ActionsContainerProps}
                    >
                        <Slide in={open} direction="left">
                            <Grid
                                container
                                width="auto"
                                wrap="nowrap"
                            >
                                {children}
                            </Grid>
                        </Slide>

                    </Grid>
                </ActionsBackground>
            )}
        </Grid>
    );
}
