/**
 * @description Logic and display information for fragment search result columns.
 * There are four groups of columns: per-fragment, per-protein, link, and summary.
 * These are used in LigandMod.getColumns, FragmentSearchCsv.js and SuggestionTable.jsx.
 * The general idea is that this is a single place to define which columns are available
 * to be displayed and how they would appear in the search results table and CSV.
 *
 * Column groups:
 * - perFragColumns: properties associated with the fragment, independent of protein context
 * - perProteinColumns: properties of fragment binding from a particular simulation
 * - linkColumns: properties of the link type, when fragment growing
 * - summaryColumns: cross-protein properties related to selectivity (summary is not a great name)
 *
 * Column properties:
 * - skip: don't include this column
 * - sortKey: use another key when sorting by this column
 * - noSort: don't allow sorting by this column
 * - csv: display properties for csv
 *   - header: text to put in the header row for the column
 * - table: display properties for the GUI table
 *   - header: content for the column header <th>
 *   - title: tooltip text for the column header
 *   - precision: precision to be applied to Numbers for display in the table (passed into toFixed)
 */
export const FragmentSearchResultColumns = Object.freeze({
    // perFragColumns
    perFragColumns: {
        name: {
            csv: { header: 'Name' },
            table: {
                label: 'Name',
                header: 'Name',
                suffix: <>&nbsp;&nbsp;</>,
            },
        },
        svg: {
            sortKey: 'name',
            table: {
                label: '2D Structure',
                header: 'Frag',
                suffix: <>&nbsp;&nbsp;</>,
            },
        },
        smiles: {
            csv: { header: 'SMILES' },
        },
        mwt: {
            csv: { header: 'Molecular Weight' },
            table: {
                header: (
                    <>
                        Mol.
                        <br />
                        Wt
                    </>
                ),
                precision: 0,
            },
        },
        charge: {
            csv: { header: 'Charge' },
        },
        HAC: {
            csv: { header: 'Heavy Atom Count' },
        },
        LogP: {
            csv: { header: 'LogP' },
        },
        TPSA: {
            csv: { header: 'Total Polar Surface Area' },
        },
        HBA: {
            csv: { header: 'Hydrogen Bond Acceptors' },
        },
        HBD: {
            csv: { header: 'Hydrogen Bond Donors' },
        },
        RBC: {
            csv: { header: 'Rotatable Bond Count' },
        },
        conflictingProps: {
            csv: { header: 'Properties with Multiple Values' },
        },
    },
    // perProteinColumns
    perProteinColumns: {
        bindingScore: {
            csv: {
                header: 'Binding Score',
            },
            table: {
                title: 'Binding score is based on the free energy of simulation when the pose was found (B-value)',
                header: (
                    <>
                        Binding
                        <br />
                        Score
                    </>
                ),
                precision: 1,
            },
        },
        ligandEfficiency: {
            skip: true,
            csv: {
                header: 'Binding Score Efficiency',
            },
            table: {
                title: 'Ligand efficiency = binding score / # heavy atoms',
                header: (
                    <>
                        Ligand
                        <br />
                        Effcy.
                    </>
                ),
                precision: 1,
            },
        },
        bindingScoreRatio: {
            csv: {
                header: 'Binding Score / Primary Target Binding Score',
            },
            table: {
                title: 'Binding score ratio = binding score for this protein / binding score for primary target',
                header: (
                    <>
                        Score
                        <br />
                        Ratio
                    </>
                ),
                precision: 2,
            },
        },
        enSolute: {
            skip: true,
            csv: {
                header: 'Best Pose Energy',
            },
            table: {
                title: 'Interaction energy between the best fragment pose and the protein (kcal/mol)',
                header: (
                    <>
                        Interaction
                        <br />
                        Energy
                    </>
                ),
                precision: 1,
            },
        },
        ligandEfficiencyEn: {
            skip: true,
            csv: {
                header: 'Energy Efficiency',
            },
            table: {
                title: 'Energy efficiency = interaction energy / # heavy atoms',
                header: (
                    <>
                        Energy
                        <br />
                        Effcy.
                    </>
                ),
                precision: 1,
            },
        },
        // Pose statistics
        ...getPoseStatisticsColumns(),
        // Pose buckets
        /* eslint-disable camelcase */
        poseBucket0_1: { skip: true, csv: { header: 'Bucket 0 pose count: bindingScore 0 - -1' } },
        poseBucket1_2: { skip: true, csv: { header: 'Bucket 1 pose count: bindingScore -1 - -2' } },
        poseBucket2_3: { skip: true, csv: { header: 'Bucket 2 pose count: bindingScore -2 - -3' } },
        poseBucket3_4: { skip: true, csv: { header: 'Bucket 3 pose count: bindingScore -3 - -4' } },
        poseBucket4_5: { skip: true, csv: { header: 'Bucket 4 pose count: bindingScore -4 - -5' } },
        poseBucket5_6: { skip: true, csv: { header: 'Bucket 5 pose count: bindingScore -5 - -6' } },
        poseBucket6_7: { skip: true, csv: { header: 'Bucket 6 pose count: bindingScore -6 - -7' } },
        poseBucket7_8: { skip: true, csv: { header: 'Bucket 7 pose count: bindingScore -7 - -8' } },
        poseBucket8_9: { skip: true, csv: { header: 'Bucket 8 pose count: bindingScore -8 - -9' } },
        poseBucket9_10: { skip: true, csv: { header: 'Bucket 9 pose count: bindingScore -9 - -10' } },
        poseBucket10_11: { skip: true, csv: { header: 'Bucket 10 pose count: bindingScore -10 - -11' } },
        poseBucket11_12: { skip: true, csv: { header: 'Bucket 11 pose count: bindingScore -11 - -12' } },
        poseBucket12_13: { skip: true, csv: { header: 'Bucket 12 pose count: bindingScore -12 - -13' } },
        poseBucket13_14: { skip: true, csv: { header: 'Bucket 13 pose count: bindingScore -13 - -14' } },
        poseBucket14_15: { skip: true, csv: { header: 'Bucket 14 pose count: bindingScore -14 - -15' } },
        poseBucket15_16: { skip: true, csv: { header: 'Bucket 15 pose count: bindingScore -15 - -16' } },
        poseBucket16_17: { skip: true, csv: { header: 'Bucket 16 pose count: bindingScore -16 - -17' } },
        poseBucket17_18: { skip: true, csv: { header: 'Bucket 17 pose count: bindingScore -17 - -18' } },
        poseBucket18_19: { skip: true, csv: { header: 'Bucket 18 pose count: bindingScore -18 - -19' } },
        poseBucket19_20: { skip: true, csv: { header: 'Bucket 19 pose count: bindingScore -19 - -20' } },
        poseBucket20_X: { skip: true, csv: { header: 'Bucket pose count: bindingScore < -20' } },
        poseBucketX_0: { skip: true, csv: { header: 'Bucket pose count: unexpected bindingScore >= 0' } },
        /* eslint-enable camelcase */
    },
    // linkColumns
    linkColumns: {
        modType: {
            csv: { header: 'Modification Type' },
            table: {
                title: 'Link types are simple bonds, carbon links, or acetylene links.',
                header: (
                    <>
                        Link
                        <br />
                        Type
                    </>
                ),
                getValue(val) {
                    /* eslint-disable react/jsx-one-expression-per-line */
                    switch (val) {
                        case 'Bond': return <>&mdash;&ensp;&ensp;</>;
                        case 'CXC': return <>&ndash;C&ndash;</>;
                        case 'methylene': return <>&ndash;C&ndash;&ensp;&thinsp;</>;
                        case 'ethane': return <>&ndash;C&ndash;C&ndash;</>;
                        case 'ethylene_cis': return <>&ndash;C=C&ndash;</>;
                        case 'ethylene_trans': return <>&ndash;C<b>=</b>C&ndash;</>;
                        case 'acetylene': case 'Acetylene': return <>&ndash;C&equiv;C&ndash;</>;
                        default:
                            // Unrecognized
                            return '';
                    }
                    /* eslint-enable react/jsx-one-expression-per-line */
                },
            },
        },
        fragAtomName: {
            skip: true,
            csv: {
                header: 'Fragment Attachment Atom',
            },
            table: {
                title: 'Atom name of the fragment attachment point',
                header: (
                    <>
                        Attach
                        <br />
                        Atom
                    </>
                ),
            },
        },
    },
    // summaryColumns
    summaryColumns: {
        activeTargetCount: {
            skip: true,
            csv: {
                header: 'Active Target Count',
            },
            table: {
                title: 'Active Target Count',
                header: (
                    <>
                        # Active
                        <br />
                        Targets
                    </>
                ),
            },
        },
        activeOffTargetCount: {
            skip: true,
            csv: {
                header: 'Active Off-Target Count',
            },
            table: {
                title: 'Active Off-Target Count',
                header: (
                    <>
                        # Active
                        <br />
                        Off-Targets
                    </>
                ),
            },
        },
        selectivityRatio: {
            skip: true,
            csv: {
                header: 'Selectivity Ratio',
            },
            table: {
                title: 'Selectivity Ratio = (active target count + inactive off-target count) / total protein count',
                header: (
                    <>
                        Selectivity
                        <br />
                        Ratio
                    </>
                ),
                precision: 2,
            },
        },
        selectivityScore: {
            csv: {
                header: 'Selectivity Score',
            },
            table: {
                title: 'Selectivity Score = active target count + inactive off-target count - active off-target count',
                header: (
                    <>
                        Selectivity
                        <br />
                        Score
                    </>
                ),
            },
        },
        coralMap: {
            noSort: true,
            table: {
                header: 'Coral Map',
            },
        },
    },
});

export const ActiveTargetColor = 'rgba(0,255,0,0.2)';
export const ActiveOffTargetColor = 'rgba(255,0,0,0.2)';

export function getColumnInfo(columnIn) {
    const [column, projectCase] = columnIn.split('+');
    for (const [group, groupCols] of Object.entries(FragmentSearchResultColumns)) {
        if (Object.keys(groupCols).includes(column)) {
            return {
                group, column, projectCase, properties: groupCols[column],
            };
        }
    }
    return null;
}

export function makePerProteinCol(column, projectCase) {
    return `${column}+${projectCase}`;
}

export function getValue(suggestion, col) {
    const colInfo = getColumnInfo(col);
    if (!colInfo) return null;
    const {
        group, column, projectCase, properties,
    } = colInfo;

    if (properties.getValue) return properties.getValue(suggestion, column, projectCase);

    switch (group) {
        case 'perFragColumns':
            return suggestion.fragInfo[column];
        case 'perProteinColumns': {
            const bestPoseInfo = suggestion.perCaseInfo[projectCase] || {};
            return bestPoseInfo[column];
        }
        case 'linkColumns':
            return suggestion[column];
        case 'summaryColumns':
            return suggestion.summaryInfo[column];
        default:
            console.warn(`Unrecognized fragment search column group: ${group}, for ${col}`);
            return null;
    }
}

// Pose statistics
// These columns are populated by FragmentSearchResult statsForOneSuggestionRow
function getPoseStatisticsColumns() {
    return {
        enSoluteAvg: {
            skip: true,
            csv: {
                header: 'Average Pose Interaction Energy',
            },
            table: {
                title: 'Average interaction energy between the fragment poses and the protein (kcal/mol)',
                header: (
                    <>
                        Avg. Pose
                        <br />
                        Energy
                    </>
                ),
                precision: 1,
            },
            getValue: getFragStatFn('enSolute', 'avg'),
        },
        enSoluteMax: {
            csv: {
                header: 'Max Pose Interaction Energy',
            },
            getValue: getFragStatFn('enSolute', 'max'),
        },
        enSoluteMin: {
            csv: {
                header: 'Min Pose Interaction Energy',
            },
            getValue: getFragStatFn('enSolute', 'min'),
        },
        poseCount: {
            csv: {
                header: 'Pose count',
            },
            getValue: getFragStatFn('poseCount'),
        },
    };
}

function getFragStatFn(statField, type) {
    return (suggestion, column, projectCase) => {
        const bestPoseInfo = suggestion.perCaseInfo[projectCase] || {};
        if (type) {
            return bestPoseInfo.fragStatistics?.[statField]?.[type];
        } else {
            return bestPoseInfo.fragStatistics?.[statField];
        }
    };
}
