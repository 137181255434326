import { getFullResidueId } from '../util/mol_info_utils';
import AtomInfoMap from './AtomInfoMap';

/**
 * The ResidueAtomInfoManager class contains important atom info necessary for decoding binary data.
 *
 * When decoding protein and compound data, there is a constraint that certain atom details
 * must be already in place. These data include amber names and charges, and others.
 * For most protein residues, this is preloaded in atomnames.js, but for user compounds,
 * the AtomInfo packet has to be processed before the compound data itself can be.
 *
 * This class maintains those data associations.
 */
export default class AtomInfoManager {
    constructor() {
        this.reset();
    }

    reset() {
        /** @type {AtomInfoMap} */
        this.ligandAtomInfo = new AtomInfoMap();
        /** @type {Map<string, AtomInfoMap>} */
        this.customResidueAtomInfoMaps = new Map();
        /** @type {Map<number, Atom>} */
        this.atomSerialNumberMap = new Map();
    }

    lookupAtom(uniqueID) {
        return this.atomSerialNumberMap.get(uniqueID);
    }

    storeAtom(uniqueID, atom) {
        const existing = this.atomSerialNumberMap.get(uniqueID);
        if (existing && getFullResidueId(atom) !== getFullResidueId(existing)) {
            console.warn(`Adding ${getFullResidueId(atom)}, there was a conflict with atom ${getFullResidueId(existing)}, uniqueID ${uniqueID}.`);
        }
        this.atomSerialNumberMap.set(uniqueID, atom);
    }

    setLigandAtomInfo(resName, atomInfo) { this.ligandAtomInfo.setAtomInfo(resName, atomInfo); }
    getLigandAtomInfo(resName) { return this.ligandAtomInfo.getAtomInfo(resName); }

    setCustomResidueAtomInfo(molType, resName, atomInfo) {
        const atomInfoMap = this.getCustomResidueMap(molType);
        atomInfoMap.setAtomInfo(resName, atomInfo);
    }

    getCustomResidueAtomInfo(molType, resName) {
        return this.getCustomResidueMap(molType).getAtomInfo(resName);
    }

    getCustomResidueMap(molType) {
        let info = this.customResidueAtomInfoMaps.get(molType);
        if (!info) {
            info = new AtomInfoMap();
            this.customResidueAtomInfoMaps.set(molType, info);
        }
        return info;
    }

    removeAtomFromMap(atom) {
        this.atomSerialNumberMap.delete(atom.uniqueID);
    }

    atomMapStats() {
        return {
            atomSerialNumberMapLength: this.atomSerialNumberMap.size,
            ligandInfoCount: this.ligandAtomInfo.size,
            totalCustomResidueInfoCount: [...this.customResidueAtomInfoMaps.values()].reduce(
                (acc, nextResInfo) => acc + nextResInfo.size, 0
            ),
        };
    }

    /**
     * Update compounds with the data stored in the maps for ambernames, charges, & ring ordinals.
     * @param {Compound|Array<Compound>} compoundOrCompounds
     * @returns {Array<Compound>} updated compounds
     */
    syncCompoundsWithAtomInfo(compoundOrCompounds) {
        const compounds = [].concat(compoundOrCompounds); // force array
        const changedCompounds = [];
        const changesToLog = [];

        // Update a particular field for a particular atom if it has a new value stored in the maps
        const syncOneField = function syncOneField(
            atom, compound, atomIndex, fieldName, valueList, contrastFn
        ) {
            if (valueList) {
                const oldValue = atom[fieldName];
                const newValue = valueList[atomIndex];

                // contrastFn is notEqual for amber and charge
                //               ordinalsNotEqual for ringOrdinals
                if (contrastFn(oldValue, newValue)) {
                    atom[fieldName] = newValue;
                    if (!changedCompounds.includes(compound)) changedCompounds.push(compound);
                    changesToLog.push(`${compound.resname}.${atom.atom}(${atomIndex}).${fieldName} ${JSON.stringify(oldValue)}->${JSON.stringify(newValue)}`);
                }
            }
        };

        // Simple equality is good to compare charges and amber types
        const notEqual = (a, b) => a !== b;
        // ringOrdinals are objects (arrays), so can't use simple equality
        const ordinalsNotEqual = (a, b) => {
            const aEmpty = !a || a.length === 0;
            const bEmpty = !b || b.length === 0;
            if (aEmpty && bEmpty) return false;
            if (aEmpty !== bEmpty) return true;
            if (a.length !== b.length) return true;

            for (const i of a.keys()) {
                if (a[i] !== b[i]) return true;
            }

            return false;
        };

        // Get various ligand info for each compound,
        // use the atomname to match the indices into the other info lists
        // and call syncOneField to update each atom with each info type
        for (const compound of compounds) {
            const compoundKey = compound.resname;
            const atomInfo = this.ligandAtomInfo.getAtomInfo(compoundKey);
            if (atomInfo) {
                const {
                    atomNames, amberNames, charges, ringOrdinals,
                } = atomInfo;
                for (const atom of compound.getAtoms()) {
                    const index = atomNames.indexOf(atom.atom);
                    syncOneField(atom, compound, index, 'amber', amberNames, notEqual);
                    syncOneField(atom, compound, index, 'charge', charges, notEqual);
                    syncOneField(atom, compound, index, 'ringOrdinals', ringOrdinals, ordinalsNotEqual);
                }
            } else {
                console.warn(`SyncCompoundsWithAtomInfo: Failed to find atom info for compound ${compoundKey}`);
            }
        }
        // console.log(`SyncCompoundsWithLigandSpec: ${changesToLog.join('; ')}`);
        return changedCompounds;
    }
}
