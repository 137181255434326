// visualizaitons/atom_group_labels.js
/**
 * @fileoverview Display labels in the canvas
 *
 * @typedef {import('BMapsSrc/model').AtomGroup} AtomGroup
 * @typedef {import('BMapsSrc/themes').ColorChain} ColorChain
 * @typedef {import('BMapsSrc/MainCanvas').labelOptions} labelOptions
 * @typedef {import('3dmol-bmaps/src').Label} Label
 *
 */

import { drawLabel, removeLabel } from 'BMapsSrc/MainCanvas';

/**
 * Keep track of drawn labels for each atomGroup
 * @type {Map<AtomGroup,Label>}
 */
const atomGroupLabelInfo = new Map();

/**
 * @param {boolean} show
 * @param {AtomGroup} atomGroup
 * @param {labelOptions} [newStyle]
 */
export function drawAtomGroupLabel(show, atomGroup, newStyle={}) {
    if (!atomGroup) return;
    const existing = atomGroupLabelInfo.get(atomGroup);

    if (existing) {
        removeLabel(existing);
    }

    if (!newStyle.text) return;

    if (show) {
        const label = drawLabel({
            text: newStyle.text,
            options: newStyle.options || {},
            centerAtomGroup: atomGroup,
        });
        atomGroupLabelInfo.set(atomGroup, label);
    }
}

/**
 * Remove all AtomGroup labels
 */
export function removeAllAtomGroupLabels() {
    atomGroupLabelInfo.forEach((label) => {
        removeLabel(label);
    });
    atomGroupLabelInfo.clear();
}
