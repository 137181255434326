/**
 * SimulationForm.jsx
 * A React Component to manage staging a water or fragment run for simulation.
 *
 */

/* eslint camelcase: ["error", { allow: ["case_suggestion", "sim_spec"]}] */

import React, { useEffect, useState, useContext } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

// Local
import { UserActions } from 'BMapsCmds';
import { SimSpec } from '../../../model/SimSpec';
import { App } from '../../../BMapsApp';
import { molTypeToString } from '../../ui_utils';
import DialogContent from '../../common/DialogContent';
import DialogContentSection from '../../common/DialogContentSection';
import Select from '../../common/Select';
import { DialogSubmitButton } from '../../common/DialogActionButtons';
import { classifyResidue } from '../../../utils';
import ProteinSelect from '../../common/ProteinSelect';
import { ProteinContext } from '../../common/ProteinContextProvider';
import { Loader } from '../../../Loader';
import { alphaFoldSimSpecFilter } from '../../../util/alphafold_utils';
import { MapCase } from '../../../model/MapCase';
import ExternalLink from '../../common/ExternalLink';
import { BfdMolTypeCodes } from '../../../molecule_types';
import { TextInputWithTooltip, BindingSiteSpecs } from './Components';

function ProteinSetupBox({
    loadedProteins, formOptions, formValues, updateSimInputs,
    isWaterSim, disabled,
    allowDefaultConfig, useDefaultConfig, onUseDefaultChange,
}) {
    const usingDefault = allowDefaultConfig && useDefaultConfig;

    function getPolymerLabel(i) {
        // Avoid adding "Reference" moltype detail in polymer check labels (internal language only)
        const chainPart = `Chain ${i.chain}`;
        const excludedMolTypes = [
            BfdMolTypeCodes.ref_protein,
            BfdMolTypeCodes.ref_dna,
            BfdMolTypeCodes.ref_rna,
        ];
        const useMolType = !excludedMolTypes.includes(i.molType);
        const molTypePart = useMolType ? ` (${molTypeToString(i.molType)})` : '';
        return `${chainPart}${molTypePart}`;
    }

    return (
        <Paper
            style={{
                padding: '10px',
                height: '100%',
                minHeight: '80px',
                margin: '0 0 20px',
            }}
            variant="outlined"
        >
            <FormControl style={{ width: '100%' }}>
                <ProteinSelect
                    style={{ margin: '2px 0 14px 6px', paddingRight: '12px' }}
                    id="simulation-form_protein-select"
                    required
                />
                {loadedProteins?.length === 1 && (
                    <Typography
                        variant="h6"
                        style={{ padding: '6px 0 14px 0px' }}
                    >
                        {formOptions.proteinType}
                        :
                        {' '}
                        {formOptions.proteinName}
                    </Typography>
                )}
                { !!allowDefaultConfig && (
                <FormControl>
                    <RadioGroup onChange={onUseDefaultChange}>
                        <FormControlLabel
                            control={<Radio checked={!!useDefaultConfig} />}
                            label={`Keep using the current simulation setup for ${formOptions.proteinName}`}
                            value
                        />
                        <FormControlLabel
                            control={<Radio checked={!useDefaultConfig} />}
                            label="Specify a new simulation setup"
                            value={false}
                        />
                    </RadioGroup>
                </FormControl>
                )}
                {(isWaterSim && formOptions.protein) && (
                    <CompoundSelect
                        compounds={formOptions.compounds}
                        selected={formValues.compound}
                        onChange={(cmp) => updateSimInputs({ compoundInput: cmp })}
                        disabled={disabled}
                        useDefaultConfig={usingDefault}
                    />
                )}
                {(formOptions.proteinChains?.length > 0
                    && formValues.proteinChains?.length === 0
                    && !disabled)
                    && (
                        <Typography color="error" style={{ paddingBottom: '12px' }}>
                            Please select a
                            {' '}
                            {formOptions.proteinType}
                            {' '}
                            chain for the simulation
                        </Typography>
                    )}
                <Grid container spacing={4} style={{ width: '100%' }}>
                    {formOptions.proteinChains?.length > 0
                        && (
                            <Grid item>
                                <CheckboxList
                                    label="Target Chains"
                                    items={formOptions.proteinChains}
                                    selected={formValues.proteinChains}
                                    getName={(i) => `Chain ${i.chain}`}
                                    onChange={(pc) => updateSimInputs({ proteinChain: pc })}
                                    disabled={disabled}
                                    useDefaultConfig={usingDefault}
                                />
                            </Grid>
                        )}
                    {formOptions.cofactors?.length > 0
                        && (
                            <Grid item>
                                <CheckboxList
                                    label="Cofactors"
                                    items={formOptions.cofactors}
                                    selected={formValues.cofactors}
                                    getName={(i) => `${i.resSpec}`}
                                    onChange={(cf) => updateSimInputs({ cofactor: cf })}
                                    disabled={disabled}
                                    useDefaultConfig={usingDefault}
                                />
                            </Grid>
                        )}
                    {formOptions.polymers?.length > 0
                        && (
                            <Grid item>
                                <CheckboxList
                                    label="Others"
                                    items={formOptions.polymers}
                                    selected={formValues.polymers}
                                    getName={(i) => getPolymerLabel(i)}
                                    onChange={(p) => updateSimInputs({ polymer: p })}
                                    disabled={disabled}
                                    useDefaultConfig={usingDefault}
                                />
                            </Grid>
                        )}
                    {formOptions.ions?.length > 0
                        && (
                            <Grid item>
                                <CheckboxList
                                    label="Ions"
                                    items={formOptions.ions}
                                    selected={formValues.ions}
                                    getName={(i) => `${i.resSpec}`}
                                    onChange={(i) => updateSimInputs({ ion: i })}
                                    disabled={disabled}
                                    useDefaultConfig={usingDefault}
                                />
                            </Grid>
                        )}
                </Grid>
            </FormControl>
        </Paper>
    );
}

export default function SimulationForm({ formType, open, close }) {
    const initialState = {
        open: false,
        formValues: {
            proteinName: '',
            project: '',
            case: '',
            proteinChains: [],
            ions: [],
            cofactors: [],
            polymers: [],
            compounds: [],
            bindingSiteSpecs: '',
        },
        allowDefaultConfig: {
            water: false,
            fragment: false,
        },
    };
    const simFormTypes = {
        water: {
            type: 'water',
            isWater: true,
            isFragment: false,
        },
        fragment: {
            type: 'fragment',
            isWater: false,
            isFragment: true,
        },
        clustering: {
            type: 'clustering',
            isWater: false,
            isFragment: false,
            isClustering: true,
        },
    };

    const formId = 'simulation-form';
    const permissionWarning = "You don't have permission for this feature. Please contact support.";

    const [simulationType, setSimulationType] = useState(simFormTypes[formType.type]);
    const [permissions, setPermissions] = useState({});
    const [formOptions, setFormOptions] = useState({});
    const [defaultConfig, setDefaultConfig] = useState(initialState.formValues);
    const [allowDefaultConfig, setAllowDefaultConfig] = useState(initialState.allowDefaultConfig);
    const [useDefaultConfig, setUseDefaultConfig] = useState(false);
    const [useSelection, setUseSelection] = useState(false);
    const [workspaceSelection, setWorkspaceSelection] = useState([]);
    const [prepared, setPrepared] = useState(false);
    const [keepSelection, setKeepSelection] = useState(false);
    const [filterAlphaFold, setFilterAlphaFold] = useState(false);
    const [formValues, setFormValues] = useState(initialState.formValues);
    const [warning, setWarning] = useState(false);
    const [fragservData, setFragservData] = useState(null);
    const [formDisabled, setFormDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [enableSolvation, setEnableSolvation] = useState(false);
    const [minimizeBox, setMinimizeBox] = useState(false);
    const [gen2Annealing, setGen2Annealing] = useState(false);
    const { loadedProteins, selectedProtein, selectedCaseData } = useContext(ProteinContext);

    useEffect(() => {
        if (open) {
            openSimPane(formType);
        } else {
            clearForm();
        }
    }, [open]);

    useEffect(() => {
        if (selectedProtein) {
            deriveState(App.Workspace, simulationType.type, selectedCaseData);
        }
    }, [selectedProtein, selectedCaseData]);

    function submitClose() {
        close();
        clearForm();
    }

    function clearForm() {
        setSimulationType({});
        setFormOptions({});
        setDefaultConfig(initialState.formValues);
        setAllowDefaultConfig(initialState.allowDefaultConfig);
        setUseDefaultConfig(false);
        setUseSelection(false);
        setWorkspaceSelection([]);
        setPrepared(false);
        setFormValues(initialState.formValues);
        setWarning(false);
        setFragservData(null);
        setFormDisabled(true);
        setLoading(false);
    }

    function handleSimTypeChange(evt) {
        const simType = evt.target.value;
        if (allowDefaultConfig[simType]) {
            defaultConfig.fragments = fragmentsToSend(simType);
        }
        // Consider keeping user's choice if they move away from default option
        const useSolvation = simType === 'clustering';
        setEnableSolvation(useSolvation);
        setSimulationType(simFormTypes[simType]);
        const usingDefault = allowDefaultConfig[simType] && useDefaultConfig;
        setFragservData(usingDefault ? defaultConfig : null);
        setFormDisabled(usingDefault);
        setPrepared(usingDefault);
        handleWarnings(simType);
        setUseSelection(false);
    }

    function openSimPane(data) {
        const { type } = data;
        // Remove any existing fragserv data
        setFragservData(null);
        setWarning(false);
        // Set things that cannot be changed by the form
        const waterPermission = App.Ready && App.AllPermissions.isAllowed('water_run');
        const fragmentPermission = App.Ready && App.AllPermissions.isAllowed('frag_run');
        setPermissions({
            water: waterPermission,
            fragment: fragmentPermission,
            clustering: waterPermission && fragmentPermission,
        });
        if ((type === 'water' && !waterPermission) || (type === 'fragment' && !fragmentPermission)) {
            setWarning({ warning: permissionWarning });
        }
    }

    function reorderByChain(list) {
        return list.sort((a, b) => {
            if (a.chain < b.chain) return -1;
            if (a.chain > b.chain) return 1;
            else return 0;
        });
    }

    function handleWarnings(type) {
        if (!permissions[type]) {
            setWarning({ warning: permissionWarning });
        } else if (warning) {
            setWarning(false);
        }
    }

    /**
     * Right now this only includes water for water runs.
     * Theoretically it could check other values, like a fragment picker?
     */
    function fragmentsToSend(simType) {
        switch (simType) {
            case 'water':
                return 'water';
            case 'clustering': {
                const clusteringFrags = App.Workspace.fragmentData.getClusteringFragset()?.items;
                if (!clusteringFrags || clusteringFrags.length === 0) {
                    return '';
                }
                const fragNames = clusteringFrags.map(({ name }) => name);
                return fragNames.join('\n');
            }
            default:
                return '';
        }
    }

    function deriveState(newWorkspace, simType, newCaseData) {
        // Acquire the sim spec, set the main protein for the form
        const includeActiveCompound = (simType === 'water') && newWorkspace.getActiveCompound();
        const fragments = fragmentsToSend(simType);
        const initial_sim_spec = SimSpec.GetFromWorkspace(
            newWorkspace, newCaseData, includeActiveCompound, fragments
        );
        const protein = newCaseData.mapCase;
        const isAlphaFoldStructure = protein.sourceDesc === MapCase.Sources.AlphaFold;

        // Determine if protein has a default spec
        const protSourceDesc = protein.sourceDesc;
        const shouldUseDefaultSpec = {
            water: protSourceDesc === MapCase.Sources.Fragserv,
            fragment: [MapCase.Sources.Public, MapCase.Sources.Fragserv].includes(protSourceDesc),
            clustering: protSourceDesc === MapCase.Sources.Fragserv,
        };
        // Consider keeping user's choice if they move away from default option
        const useSolvation = simType === 'clustering';
        setEnableSolvation(useSolvation);
        const useMinimizeBox = protSourceDesc === MapCase.Sources.Public; // This may change later
        setMinimizeBox(useMinimizeBox);
        setAllowDefaultConfig(shouldUseDefaultSpec);
        // Generate config data, regardless of if it is used on this SimType
        setDefaultConfig(SimSpec.fragservArgsFromProtein(protein, fragments));
        // In the event of selecting different protein
        setUseSelection(false);
        // Only allow Use Selection if atoms come from the selected protein
        const selectedAtoms = newWorkspace.getSelectedAtoms();
        const { dataParentsList } = App.partitionByDataParents(selectedAtoms);
        if (dataParentsList.every(({ caseData }) => caseData === newCaseData)) {
            setWorkspaceSelection(selectedAtoms);
        } else {
            setWorkspaceSelection([]);
        }
        // Set up state for either sim type
        setSimulationType(simFormTypes[simType]);

        const defaultConfigExists = [MapCase.Sources.Public, MapCase.Sources.Fragserv]
            .includes(protSourceDesc);
        const defaultUseDefault = defaultConfigExists;
        setUseDefaultConfig(defaultUseDefault);
        const allowingDefault = shouldUseDefaultSpec[simType];
        const usingDefault = allowingDefault && defaultUseDefault;

        setFormDisabled(usingDefault);
        setPrepared(usingDefault);
        setFragservData(allowingDefault && SimSpec.fragservArgsFromProtein(protein, fragments));

        setFormOptions({
            proteinName: initial_sim_spec.protein.getLongName(),
            proteinChains: reorderByChain(newCaseData.getProteinChains()),
            proteinType: classifyResidue({
                resname: newCaseData.getProteinChains()[0].residue.name,
                rnaValue: 'RNA',
                dnaValue: 'DNA',
                proteinValue: 'Protein',
            }),
            protein: initial_sim_spec.protein,
            ions: reorderByChain(newCaseData.getIons()),
            cofactors: reorderByChain(newCaseData.getCofactors()),
            polymers: reorderByChain(newCaseData.getPolymers()),
            compounds: newCaseData.getCompounds(),
            project: initial_sim_spec.project,
            case: SimSpec.defaultCaseName({
                protein: initial_sim_spec.protein, compound: null,
            }),
            isAlphaFoldStructure,
        });
        // const chainNames = initial_sim_spec.proteinChains.map((i) => i.chain);
        setFormValues({
            project: initial_sim_spec.project,
            case: SimSpec.defaultCaseName({
                protein: initial_sim_spec.protein, compound: null,
            }),
            proteinName: initial_sim_spec.protein.getLongName(),
            compound: null,
            proteinChains: initial_sim_spec.proteinChains,
            ions: initial_sim_spec.ions,
            cofactors: initial_sim_spec.cofactors,
            polymers: initial_sim_spec.polymers,
        });
    }

    function handleUseSelectionToggle(checked) {
        setUseSelection(checked);
        if (checked) {
            setFragservData(null);
            setFormDisabled(true);
            // Currently making a simplifying assumption that only one of
            // "Filter AlphaFold" and "Use Selection" can be checked
            // This is also reflected in the "disabled" prop on the Checkboxes
            setFilterAlphaFold(false);
            setFormValues({
                ...formValues,
                project: formOptions.project,
                case: `${formOptions.case}_selection`,
            });
        } else {
            if (allowDefaultConfig[simulationType.type] && useDefaultConfig) {
                setFragservData(defaultConfig);
                setFormDisabled(true);
            } else {
                setFragservData(null);
                setFormDisabled(false);
                updateSimInputs({});
            }
        }
    }

    function handleFilterAlphaFoldToggle(checked) {
        if (checked) {
            setFilterAlphaFold(true);
            // Currently making a simplifying assumption that only one of
            // "Filter AlphaFold" and "Use Selection" can be checked.
            // This is also reflected in the "disabled" prop on the Checkboxes
            handleUseSelectionToggle(false);
        } else {
            setFilterAlphaFold(false);
        }
    }

    function handleDefaultConfigToggle(evt) {
        const useDefault = evt.target.value === 'true';
        setUseDefaultConfig(useDefault);
        if (useDefault) {
            setFragservData(defaultConfig);
            setFormDisabled(true);
            setPrepared(true);
        } else {
            setFragservData(null);
            setFormDisabled(false);
            setPrepared(false);
        }
        setWarning(false);
    }

    function updateCaseOrProject(type, newName) {
        if (type === 'case') {
            setFormValues({
                ...formValues,
                case: newName,
            });
        } else if (type === 'project') {
            setFormValues({
                ...formValues,
                project: newName,
            });
        }
    }

    function getCaseNameTag(newVals, allVals) {
        /** Return a modification tag for ion/cofactor, based on the expected selection */
        function getModTag(selected, all, selectedChains, allChains, name) {
            // Expect ions and cofactors whose chains are selected or who are not a protein chain
            const expected = all.filter(
                ({ chain }) => selectedChains.includes(chain) || !allChains.includes(chain)
            );

            if (selected.length === 0) {
                return expected.length > 0 ? `-no-${name}` : '';
            }

            const missing = expected.filter((f) => !selected.includes(f));
            const added = selected.filter((f) => !expected.includes(f));

            if (missing.length > 0 && added.length > 0) {
                return `-custom-${name}`;
            } else if (missing.length > 0) {
                return `-less-${name}`;
            } else if (added.length > 0) {
                return `-plus-${name}`;
            }
            return '';
        }

        // Detect if we need to add to the default case name in various ways
        // Protein Chains: changed if any polymer is added or if the number of chains does not
        // match that of the original simspec
        const chains = newVals.polymers.concat(newVals.proteinChains)
            .map((p) => p.chain).sort();
        const allChains = allVals.polymers.concat(allVals.proteinChains)
            .map((p) => p.chain);
        const chainsChanged = newVals.polymers.length > 0
            || newVals.proteinChains.length < allVals.proteinChains.length;

        let caseTag = '';
        // Add names of included chains if not the default chains
        if (chainsChanged) {
            if (chains.length > 4) {
                caseTag += `_with-${chains.length}-chains`;
            } else if (chains.length > 0) {
                const base = chains.length === 1 ? '_chain-' : '_chains-';
                const chainStr = chains.join('-');
                caseTag += `${base}${chainStr}`;
            }
        }
        caseTag += getModTag(newVals.cofactors, allVals.cofactors, chains, allChains, 'cofactor');
        caseTag += getModTag(newVals.ions, allVals.ions, chains, allChains, 'ion');
        return caseTag;
    }

    function updateSimInputs({
        compoundInput, proteinChain, ion, cofactor, polymer,
    }) {
        // Used to toggle a value, item, in a list of form values
        const toggle = (list, item) => {
            if (list.indexOf(item) >= 0) {
                return list.filter((i) => i !== item);
            } else {
                list.push(item);
                return list;
            }
        };
        // If toggling a value, item, that is connected to other form values, otherItems,
        // this will toggle those as well
        const toggleSecondaries = (list, item, otherList, otherItems) => {
            const chainItems = otherItems.filter((i) => i.chain === item.chain);
            let newOtherList = otherList;

            if (list.indexOf(item) >= 0) {
                for (const chainItem of chainItems) {
                    if (newOtherList.indexOf(chainItem) >= 0) {
                        newOtherList = toggle(newOtherList, chainItem);
                    }
                }
            } else {
                for (const chainItem of chainItems) {
                    if (newOtherList.indexOf(chainItem) === -1) {
                        newOtherList = toggle(newOtherList, chainItem);
                    }
                }
            }
            return newOtherList;
        };

        let {
            compound, proteinChains, ions, cofactors, polymers,
        } = formValues;

        // Depeneding on which input is being changed, adjust the associated list
        // ProteinChains and Polymers affect ions/cofactors, they are toggled in toggleSecondaries
        if (compoundInput) compound = (compoundInput === 'noCompound') ? null : compoundInput;
        if (proteinChain) {
            ions = toggleSecondaries(proteinChains, proteinChain, ions, formOptions.ions);
            cofactors = toggleSecondaries(
                proteinChains, proteinChain, cofactors, formOptions.cofactors
            );
            proteinChains = toggle(proteinChains, proteinChain);
        }
        if (polymer) {
            ions = toggleSecondaries(polymers, polymer, ions, formOptions.ions);
            cofactors = toggleSecondaries(polymers, polymer, cofactors, formOptions.cofactors);
            polymers = toggle(polymers, polymer);
        }
        if (ion) {
            ions = toggle(ions, ion);
        }
        if (cofactor) {
            cofactors = toggle(cofactors, cofactor);
        }

        let caseName = SimSpec.defaultCaseName({
            protein: formOptions.protein, compound,
        });
        // Don't update the case name if it is blank
        if (caseName) {
            caseName += getCaseNameTag({
                compound, proteinChains, ions, cofactors, polymers,
            }, formOptions);
        }

        handleWarnings(simulationType.type);
        setFormValues({
            ...formValues,
            case: caseName,
            compound,
            proteinChains,
            ions,
            cofactors,
            polymers,
        });
    }

    async function handlePrepSubmit(evt) {
        evt.preventDefault();
        setLoading(true);
        // In the case of useSelection, SimSpec only needs protein, project, and case
        let newSimSpec;
        if (useSelection) {
            newSimSpec = new SimSpec({
                protein: selectedProtein,
                project: formValues.project,
                case: formValues.case,
                bindingSiteSpecs: formValues.bindingSiteSpecs,
                fragments: fragmentsToSend(simulationType.type),
            });
        } else {
            newSimSpec = new SimSpec({
                protein: selectedProtein,
                proteinChains: formValues.proteinChains,
                compound: formValues.compound,
                ions: formValues.ions,
                cofactors: formValues.cofactors,
                polymers: formValues.polymers,
                project: formValues.project,
                case: formValues.case,
                bindingSiteSpecs: formValues.bindingSiteSpecs,
                fragments: fragmentsToSend(simulationType.type),
            });
        }

        if (filterAlphaFold) {
            newSimSpec = alphaFoldSimSpecFilter(newSimSpec);
        }

        const {
            status, case_suggestion, errorInfo,
        } = await UserActions.PrepareProjectCase({
            simSpec: newSimSpec,
            useSelection,
            dontDeselect: keepSelection,
        });

        switch (status) {
            case SimSpec.Status.Ready: {
                const fragservArgs = newSimSpec.forFragserv;
                setWarning(false);
                setFragservData(fragservArgs);
                setFormDisabled(true);
                setPrepared(true);
                // If not using selection, PrepareProjectCase will remove selection
                if (!useSelection) setWorkspaceSelection([]);
                break;
            }
            case SimSpec.Status.Conflict: {
                const usingSuggestion = case_suggestion && case_suggestion !== formValues.case;
                let msg = `The structure name you provided (${formValues.case}) was already used for a different complex. `;
                if (usingSuggestion) {
                    msg += 'Use the suggested alternative or choose a different structure name.';
                } else {
                    msg += 'Try a different structure name.';
                }
                setWarning({ warning: msg });
                if (usingSuggestion) {
                    updateCaseOrProject('case', case_suggestion);
                }
                break;
            }
            case SimSpec.Status.StorageUpdatedError:
                setWarning({ warning: 'Your account storage was recently updated and needs to be refreshed; please try signing out and in again.' });
                break;
            case SimSpec.Status.PersonalStorageError:
                setWarning({ warning: 'Your account storage is still getting set up; please try signing out and in again.' });
                break;
            case SimSpec.Status.FdbFileError:
            case SimSpec.Status.PartialChargesError:
                setWarning({ warning: `This complex contains one or more residues that are not currently supported for simulations - ${errorInfo.failedResidues.join(', ')}. You may be able to deselect the problematic chain(s) to continue.` });
                break;
            case SimSpec.Status.NoHydrogensError:
                setWarning({ warning: 'Cannot run fragment simulation on this structure. No hydrogens found. (Note: BMaps currently does not support computing hydrogens for certain large structures from the PDB)' });
                break;
            case SimSpec.Status.UnknownError:
                setWarning({ warning: 'There was an error trying to prepare the simulation files.' });
                break;
            case SimSpec.Status.TooManyChainsError:
                setWarning({ warning: 'This system has more than 500 residues and is too large for simulation. Try removing some chains.' });
                break;
            case SimSpec.Status.BindingSiteSpecError:
                setWarning({ warning: `Binding site could not be applied: ${errorInfo.bindingSiteSpecErr}` });
                break;
            default:
                // This shouldn't happen
                setWarning({ warning: 'There was a problem trying to prepare the simulation files.' });
        }
        setLoading(false);
    }

    const useDefault = allowDefaultConfig[simulationType.type] && useDefaultConfig;
    const fragservDataToUse = fragservData && {
        ...fragservData,
        // Stringify extraSimSettings because it going in form data
        extraSimSettings: JSON.stringify({
            isClustering: simulationType.isClustering,
            enableSolvation,
            minimizeBox,
            gen2Annealing,
        }),
    };
    return (
        <DialogContent>
            <form onSubmit={handlePrepSubmit} id={formId}>
                <DialogContentSection title="Simulation Type" disablePaddingTop disablePaddingBottom>
                    <FormControl required>
                        <RadioGroup
                            name="simType"
                            onChange={handleSimTypeChange}
                            style={{ width: '100%', flexDirection: 'row' }}
                        >
                            <FormControlLabel
                                control={<Radio checked={simulationType.isWater} />}
                                label="Water"
                                value="water"
                            />
                            <FormControlLabel
                                control={<Radio checked={simulationType.isFragment} />}
                                label="Fragment"
                                value="fragment"
                            />
                            <FormControlLabel
                                control={<Radio checked={simulationType.isClustering} />}
                                label="Hot Spots"
                                value="clustering"
                            />
                        </RadioGroup>
                    </FormControl>
                    <SimTypeDescription simType={simulationType.type} />
                </DialogContentSection>
                <DialogContentSection title="Simulation System">
                    <ProteinSetupBox
                        loadedProteins={loadedProteins}
                        updateSimInputs={updateSimInputs}
                        formOptions={formOptions}
                        formValues={formValues}
                        isWaterSim={simulationType.isWater}
                        disabled={prepared || useSelection}
                        allowDefaultConfig={allowDefaultConfig[simulationType.type]}
                        useDefaultConfig={useDefaultConfig}
                        onUseDefaultChange={handleDefaultConfigToggle}
                    />
                </DialogContentSection>
                <DialogContentSection
                    title="Advanced Parameters"
                    disablePaddingTop
                    disablePaddingRight
                    disablePaddingBottom
                    collapsible
                >
                    <BindingSiteSpecs
                        setValue={(bindingSiteSpecs) => setFormValues({
                            ...formValues,
                            bindingSiteSpecs,
                        })}
                        disabled={prepared}
                        selectionAlreadyUsed={useSelection}
                    />
                    <br />
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={enableSolvation}
                                onChange={(evt) => setEnableSolvation(evt.target.checked)}
                            />
                        )}
                        label="Enable Solvation Model"
                    />
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={minimizeBox}
                                onChange={(evt) => setMinimizeBox(evt.target.checked)}
                            />
                        )}
                        label="Minimize Box"
                    />
                    {!simulationType.isWater && !!Loader.AllowLabFeatures && (
                    <>
                        <br />
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={gen2Annealing}
                                    onChange={(evt) => setGen2Annealing(evt.target.checked)}
                                />
                            )}
                            label="Use 2nd generation annealing"
                        />
                    </>
                    )}
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={filterAlphaFold}
                                onChange={(evt) => handleFilterAlphaFoldToggle(evt.target.checked)}
                                disabled={prepared
                                    || !formOptions.isAlphaFoldStructure || useSelection}
                            />
                        )}
                        label="Filter out low confidence AlphaFold residues"
                    />
                    <br />
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={useSelection && !prepared}
                                onChange={(evt) => handleUseSelectionToggle(evt.target.checked)}
                                disabled={workspaceSelection.length === 0 || prepared
                                    || !selectedProtein || filterAlphaFold}
                            />
                        )}
                        label={`Use Selection (${workspaceSelection.length} atoms selected)`}
                    />
                    { !!Loader.AllowLabFeatures && (
                    <>
                        <br />
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={keepSelection}
                                    onChange={(evt) => setKeepSelection(evt.target.checked)}
                                    disabled={prepared || !selectedProtein}
                                />
                            )}
                            label="Keep selection after structure preparation (dev / lab only)"
                        />
                    </>
                    )}
                </DialogContentSection>
                <DialogContentSection title="System Name">
                    <TextInputWithTooltip
                        id="targetProteinName"
                        disabled={formDisabled && prepared}
                        labelText="Target Protein Name"
                        tooltip="Target protein name groups all structures and simulation data for
                                a certain target (eg. 'factorIXa')"
                        value={!useDefault ? formValues.project : ' '}
                        title={useDefault ? `Using existing configuration: ${defaultConfig.project}/${defaultConfig.case}`: ''}
                        onChange={(evt) => {
                            updateCaseOrProject('project', evt.target.value);
                        }}
                    />
                    <TextInputWithTooltip
                        id="structureName"
                        disabled={formDisabled && prepared}
                        labelText="Structure Name"
                        tooltip="Structure name groups fragment data for one complex around one
                                structure (eg. '5EGM', '4YZU+4K6')"
                        value={!useDefault ? formValues.case : ' '}
                        title={useDefault ? `Using existing configuration: ${defaultConfig.project}/${defaultConfig.case}`: ''}
                        onChange={(evt) => updateCaseOrProject('case', evt.target.value)}
                    />
                </DialogContentSection>
            </form>
            {warning
                && (
                    <Typography style={{ paddingBottom: '10px' }} color="error">
                        Error:
                        {' '}
                        {warning.warning}
                    </Typography>
                )}
            <Box style={{ margin: '0 0 10px', maxHeight: '60px' }}>
                {fragservDataToUse
                    ? (
                        <form id="submitSim" action="/services/shopping/loadCart" method="POST" target="_blank" onSubmit={() => setTimeout(submitClose, 1000)}>
                            {
                                Object.keys(fragservDataToUse).map((k) => (
                                    <React.Fragment key={k}>
                                        <input
                                            key={k}
                                            type="hidden"
                                            name={k}
                                            title={k}
                                            value={fragservDataToUse[k]}
                                            readOnly
                                        />
                                    </React.Fragment>
                                ))
                            }
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                {!!fragservDataToUse
                                    && <Typography color="primary">This system is ready for simulation</Typography>}
                                <DialogSubmitButton form="submitSim" disabled={!permissions[simulationType.type]}>
                                    Continue to Simulator
                                </DialogSubmitButton>
                            </Box>
                        </form>
                    ) : (
                        <>
                            {loading
                                ? (
                                    <LinearProgress style={{ margin: '14px 0 30px' }} />
                                ) : (
                                    <DialogSubmitButton
                                        form={formId}
                                        disabled={!permissions[simulationType.type]}
                                    >
                                        Prepare System
                                    </DialogSubmitButton>
                                )}
                        </>
                    )}
            </Box>
            <DialogContentText>
                Note: Simulation runs are fulfilled by the simulation shopping cart, which
                opens in a separate page.
            </DialogContentText>
            <ExternalLink link="/services/runs/runs">
                View simulation status
            </ExternalLink>
        </DialogContent>
    );
}

function CompoundSelect({
    compounds, selected, onChange, disabled,
}) {
    if (compounds && compounds.length > 0) {
        return (
            <FormControl
                style={{ width: '250px', margin: '10px 0 24px 6px' }}
            >
                <Select
                    value={selected || 'noCompound'}
                    disabled={disabled}
                    label="Compound"
                    onChange={(evt) => {
                        onChange(compounds.find((c) => c.resSpec === evt.target.value) || 'noCompound');
                    }}
                >
                    <option value="noCompound">No compound</option>
                    {compounds.map((cmp) => (
                        <option value={cmp.resSpec} key={cmp.resSpec}>
                            {cmp.resSpec}
                        </option>
                    ))}
                </Select>
            </FormControl>
        );
    } else {
        return (
            <FormControl
                style={{ width: '250px', margin: '0 0 24px 6px' }}
            >
                <Select
                    value="noCompound"
                    label="Compound"
                    onChange={onChange}
                    style={{ width: '100%' }}
                    disabled
                >
                    <option value="noCompound">No compounds available</option>
                </Select>
            </FormControl>
        );
    }
}

/**
 * Note: useDefaultConfig disables and clears all the checkboxes.
 * This is because "default config" means "use whatever is in the server checkpoint file."
 * It's not possible for BMaps to know how to assign the checkboxes. This approach clears them
 * all so that by being hopefully obviously wrong, it is hopefully less confusing.
 */
function CheckboxList({
    label, items, selected, getName, onChange, disabled, useDefaultConfig,
}) {
    return (
        <FormGroup>
            <FormLabel>{`${label}:`}</FormLabel>
            {items && items.map((i) => (
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={!useDefaultConfig && selected?.includes(i)}
                            onChange={() => onChange(i)}
                            name={getName(i)}
                        />
                    )}
                    label={getName(i)}
                    key={getName(i)}
                    disabled={useDefaultConfig || disabled}
                />
            ))}
        </FormGroup>
    );
}

function SimTypeDescription({ simType }) {
    let content = '';
    switch (simType) {
        case 'water':
            content = '';
            break;
        case 'fragment':
            content = '';
            break;
        case 'clustering':
            content = 'Hot spot runs include 16 diverse clustering fragments.';
            break;
        // no default
    }
    return !!content && <div>{content}</div>;
}
