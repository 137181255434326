/**
 * @typedef {import('BMapsModel').Compound} Compound
 * @typedef {import('BMapsModel').MapCase} MapCase
 * @typedef {import('@mui/x-data-grid').GridColumnGroupingModel} GridColumnGroupingModel
 * @typedef {import('@mui/x-data-grid').GridColumnsPanelProps} GridColumnsPanelProps
 * @typedef {import('@mui/x-data-grid').ColumnsPanelPropsOverrides} ColumnsPanelPropsOverrides
 * @typedef {import ('@mui/x-data-grid').GridColDef} GridColDef
 */

import { useState, useEffect } from 'react';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';

import {
    gridColumnVisibilityModelSelector,
    useGridApiContext, useGridRootProps, useGridSelector,
} from '@mui/x-data-grid';

import { EventBroker } from 'BMapsSrc/eventbroker';
import { App } from 'BMapsSrc/BMapsApp';
import {
    HoverableCompoundStructureCell,
    ProteinHeader, ProteinCell, CompoundNameCell,
} from './TableCells';
import { BMapsTable } from './BMapsTable';
import BMapsTooltip from '../BMapsTooltip';

/**
 * @param {{
*     setColumnVisibilityModel?: (columnVisibilityModel: GridColumnVisibilityModel) => any,
*     columnVisibilityModel?: GridColumnVisibilityModel,
*     itemSelector?: (item: item) => any,
*     disableTable?: boolean,
*     exportFileName?: string,
*     useUniqueCompounds?: boolean,
*     useSdfProperties?: boolean,
*     checkboxSelection?: boolean,
*     setCheckedCompounds?: (compound: Compound) => any,
*     useProteinProperties?: boolean,
*}} props
*/
export function CompoundTable({
    useUniqueCompounds, useSdfProperties, useProteinProperties,
    columnVisibilityModel, setCheckedCompounds, ...props
}) {
    const [compounds, setCompounds] = useState(getCompounds(useUniqueCompounds));

    useEffect(() => {
        function updateCompounds() {
            setCompounds(getCompounds(useUniqueCompounds));
        }
        updateCompounds();
        EventBroker.subscribe('energyCalc', updateCompounds);
        EventBroker.subscribe('compoundsUpdated', updateCompounds);
        EventBroker.subscribe('compoundChanged', updateCompounds);
        EventBroker.subscribe('deleteCompound', updateCompounds);
        return () => {
            EventBroker.unsubscribe('compoundsUpdated', updateCompounds);
            EventBroker.unsubscribe('energyCalc', updateCompounds);
            EventBroker.unsubscribe('compoundChanged', updateCompounds);
            EventBroker.unsubscribe('deleteCompound', updateCompounds);
        };
    }, []);

    const perCompoundColumnTitles = ['Compound Name', 'Compound Structure', 'Mol. Weight', 'LogP', 'PSA', 'Fraction Sp3', '# Heavy Atoms', 'Charge', 'HB Donors', 'HB Acceptors', '# Rot. Bonds', '# Lipinski Violations'];
    const perPoseColumnTitles = ['Interaction Score', 'Energy Efficiency', 'Van der Waals', 'Electrostatic Energy', 'ddGs'];

    const tableData = createCompoundTableData({
        compounds,
        useUniqueCompounds,
        useSdfProperties,
        useProteinProperties,
        perCompoundColumnTitles,
        perPoseColumnTitles,
    });

    return (
        <BMapsTable
            rows={tableData.rows}
            columns={tableData.columns}
            columnGroupingModel={tableData.columnGroupingModel}
            rowItems={tableData.rowItems}
            columnVisibilityModel={columnVisibilityModel
                || getDefaultVisibleColumns(tableData.columns)}
            setCheckedRowItems={setCheckedCompounds}
            customPanel={CustomColumnsPanel}
            duplicateColumnKeys={perPoseColumnTitles}
            {...props}
        />
    );
}

/**
 * @param {Compound} compound
 * @param {MapCase} mapCase
 * @returns
 */
function getBestPose(mapCase, compound, scoreFn=defaultScore) {
    const compoundKey = compound.getUniqueStructId();
    const poses = App.Workspace.getLoadedCompounds().filter((pose) => {
        const poseKey = pose.getUniqueStructId();
        return (poseKey === compoundKey && pose.caseData.mapCase === mapCase);
    });
    poses.sort((a, b) => sortPoses(a, b, scoreFn));
    return poses[0];
}

/**
 * @param {Compound} compound
 * @returns
 */
function defaultScore(compound) {
    return compound?.getEnergyInfo()?.getEnergyScore();
}

/**
 * @param {Compound} cmpdA
 * @param {Compound} cmpdB
 * @param {(Compound) => Number?} [scoreFn]
 * @returns
 */
function sortPoses(cmpdA, cmpdB, scoreFn=defaultScore) {
    const scoreA = scoreFn(cmpdA);
    const scoreB = scoreFn(cmpdB);
    switch (true) {
        case scoreA == null && scoreB == null: return 0;
        case scoreA == null: return 1;
        case scoreB == null: return -1;
        default: return scoreA - scoreB;
    }
}

/**
     * @param {Compound} compound
     * @param {string} property
     * @returns {Number | null}
     */
const getMolPropOrEnergyValue = (compound, property) => {
    if (property === 'Energy Efficiency') return compound.getMolProp('Energy Efficiency');
    if (property === 'Interaction Score') {
        const enInfo = compound.getEnergyInfo();
        // For some reason, getEnergyScore() returns 0 when there is no energy.
        // Until this is fixed, check availablily first.
        return enInfo.energyScoreAvailable() ? enInfo.getEnergyScore() : '';
    }
    return compound.getEnergyInfo()?.getEnergyValueByType(property);
};

/**
 * @param {Compound} compound
 * @param {*} property
 * @param {MapCase} mapCase
 * @returns
 */
const getPerPoseValue = (compound, property, mapCase) => {
    if (!compound) return '';
    if (compound.caseData.mapCase !== mapCase) return '';

    let propertyName = property;
    if (property === 'Best Pose Name') return compound.displayName;
    if (property === 'Van der Waals') propertyName = 'vdW';
    if (property === 'Hydrogen Bond Energy') propertyName = 'hbonds';
    if (property === 'Electrostatic Energy') propertyName = 'electrostatics';
    if (property === 'Stress') propertyName = 'stress';
    if (property === 'Docking Score') propertyName = 'dockingScore';

    const propertyValue = getMolPropOrEnergyValue(compound, propertyName);
    if (typeof propertyValue !== 'number') return '';
    return propertyValue;
};

const getPerCompoundValue = (compound, property) => {
    const propertyName = property;
    const value = compound?.getMolProp(propertyName);
    if (typeof value !== 'number') return '';
    return value;
};

function filterByPropApplicability(propList, propApplicability) {
    return propList.filter((sp) => {
        if (!propApplicability) return true;
        const { propApplicability: myPropApplicability } = sp.getMetadata();
        return (
            !myPropApplicability && propApplicability === 'perCompound'
        ) || (
            typeof propApplicability === 'string' && propApplicability === myPropApplicability
        ) || (
            Array.isArray(propApplicability) && propApplicability.includes(myPropApplicability)
        );
    });
}

function listPerCompoundProps(compound) {
    const allProps = compound.listProperties({ includeChildren: true });
    return filterByPropApplicability(allProps, 'perCompound');
}

function listProteinProps(mapCase) {
    return mapCase?.listProperties({ includeChildren: true }) || [];
}

function listPerProteinProps(compound) {
    const { mapCase } = App.getDataParents(compound);
    const proteinProps = listProteinProps(mapCase);
    const allCmpdProps = compound.listProperties({ includeChildren: true });
    const perProteinCmpdProps = filterByPropApplicability(allCmpdProps, ['perPose', 'perCompoundTarget']);
    return [...proteinProps, ...perProteinCmpdProps];
}

function labelOrName(prop) {
    return prop.getMetadata().label || prop.name;
}

function makePropKey(propInfo) {
    const fullPath = propInfo.getScopeListNames({ includeSelf: true, includeRoot: true });
    const key = fullPath.join('__');
    return key;
}

function makePerProtColKey(prop, mapCase, index) {
    if (!mapCase) {
        return prop;
    }
    return `${prop} ${mapCase.displayName} (Protein ${index + 1})`;
}

function makeColPickerTitle(propInfo) {
    const name = labelOrName(propInfo);
    const scopeNameList = propInfo.getScopeListNames({ includeRoot: true });
    if (scopeNameList[scopeNameList.length-1] === 'sdfProps') {
        return `SDF: ${name}`;
    }
    if (scopeNameList[0] === 'target') {
        return `Target ${name}`;
    } else if (scopeNameList[0] === 'compound') {
        const { propApplicability } = propInfo.getMetadata();
        if (['perPose', 'perCompoundTarget'].includes(propApplicability)) {
            return `Pose ${name}`;
        } else {
            return `Cmpd ${name}`;
        }
    } else {
        return propInfo.name;
    }
}

/**
 * @param {{
 *     compounds: Compound[],
 *     useUniqueCompounds?: boolean,
 *     useSdfProperties?: boolean,
 *     useProteinProperties?: boolean,
 * }} tableProperties
 * @returns
 */
function createCompoundTableData({
    compounds,
    perCompoundColumnTitles,
    perPoseColumnTitles,
    useProteinProperties=false,
    useSdfProperties=false,
    useUniqueCompounds=false,
}) {
    const loadedProteins = useProteinProperties ? App.Workspace.getLoadedProteins() : [];

    if (useUniqueCompounds) {
        perPoseColumnTitles.push('Best Pose Name');
    }

    /** @type {string[]} */
    const defaultPerCompoundCols = perCompoundColumnTitles.map(
        (title) => ({ key: title, title, colPickerTitle: title })
    );
    const defaultPerPoseCols = perPoseColumnTitles.map(
        (title) => ({ key: title, title, colPickerTitle: title })
    );
    let otherCmpdPropCols = [];
    let otherProteinPropCols = [];
    if (useSdfProperties) {
        otherCmpdPropCols = _.uniqBy(
            compounds.flatMap(listPerCompoundProps).map((propInfo) => ({
                key: makePropKey(propInfo),
                title: labelOrName(propInfo),
                colPickerTitle: makeColPickerTitle(propInfo),
            })),
            (entry) => entry.key
        );
        otherProteinPropCols = _.uniqBy(
            compounds.flatMap(listPerProteinProps).map((propInfo) => ({
                key: makePropKey(propInfo),
                title: labelOrName(propInfo),
                colPickerTitle: makeColPickerTitle(propInfo),
            })),
            (entry) => entry.key
        );
    }

    const allPerCompoundCols = [...defaultPerCompoundCols, ...otherCmpdPropCols];
    const allPerPoseCols = [...defaultPerPoseCols, ...otherProteinPropCols];

    /**
     * @type {GridColumnGroupingModel}
     */
    const columnGroupingModel = [
        {
            groupId: 'perCompound',
            headerClassName: 'none-header',
            headerName: ' ',
            children: allPerCompoundCols.map(({ key }) => ({ field: key })),
        },
        ...loadedProteins.map((mapCase, index) => ({
            groupId: `${mapCase.displayName} (Protein ${index + 1})`,
            headerName: mapCase.getLongName(),
            headerAlign: 'center',
            headerClassName: 'protein-header',
            children: allPerPoseCols.map(
                ({ key }) => ({ field: makePerProtColKey(key, mapCase, index) })
            ),
        })),
    ];

    /**
     * @param {string | number} value
     * @returns
     */
    function getFormattedValue(value) {
        let formattedValue = value;
        if (typeof formattedValue !== 'number') {
            if (!Number.isNaN(parseFloat(formattedValue)) && Number.isFinite(formattedValue)) {
                formattedValue = Number(value);
            } else {
                return value;
            }
        }
        if (Number.isInteger(value)) return value;
        return value.toFixed(2);
    }

    /**
     * @type {GridColDef[]}
     * Definitions:
     * - field is the programmatic key
     * - headerName is what shows up in the CSV
     * - title is what shows up in the column picker widget
     * - renderHeader() is what shows up in the table header
     */
    const columns = [
        ...allPerCompoundCols.map(({ key, title, colPickerTitle }) => {
            /**
             * @type {GridColDef}
             */
            const columnInfo = {
                field: key,
                headerName: title,
                title: colPickerTitle,
                type: 'number',
                flex: 1,
                minWidth: 80,
                renderCell: ({ value }) => (
                    <BMapsTooltip title={value} placement="bottom-start">
                        <span>{getFormattedValue(value)}</span>
                    </BMapsTooltip>
                ),
                renderHeader: () => (
                    <BMapsTooltip title={title}>
                        <span>{ title }</span>
                    </BMapsTooltip>
                ),
            };

            if (title === 'Compound Structure') {
                columnInfo.renderCell = ({ value, row }) => (
                    <HoverableCompoundStructureCell smiles={value} row={row} />
                );
                columnInfo.sortable = false;
                columnInfo.filterable = false;
                columnInfo.type = 'string';
                columnInfo.renderHeader = () => (
                    <BMapsTooltip title="Structure">
                        <span>Structure</span>
                    </BMapsTooltip>
                );
                columnInfo.headerName = 'Structure';
            }

            if (title === 'Compound Name') {
                columnInfo.type = 'string';
                columnInfo.renderHeader = () => (
                    <BMapsTooltip title="Compound">
                        <span>Compound</span>
                    </BMapsTooltip>
                );
                columnInfo.headerName = 'Compound';
                columnInfo.renderCell = ({ value, row }) => (
                    <CompoundNameCell row={row} value={value} />
                );
            }

            if (title === 'Fraction Sp3') {
                columnInfo.renderHeader = () => (
                    <BMapsTooltip title={'Fraction sp\u2083'}>
                        <span>{'Fraction sp\u2083'}</span>
                    </BMapsTooltip>
                );
            }
            return columnInfo;
        }),
        ...loadedProteins.flatMap((mapCase, index) => (
            allPerPoseCols.map(({ key, title, colPickerTitle }) => ({
                field: makePerProtColKey(key, mapCase, index),
                headerName: mapCase ? `${title} ${mapCase.displayName}` : title,
                title: colPickerTitle,
                type: 'number',
                flex: 1,
                minWidth: 80,
                renderHeader: () => <ProteinHeader title={title} />,
                renderCell: ({ value, row }) => (
                    <ProteinCell
                        value={value}
                        mapCase={mapCase}
                        row={row}
                        index={index}
                        useUniqueCompounds={useUniqueCompounds}
                    />
                ),
            }))
        )),
    ];

    const rows = compounds.map((compound, id) => {
        /**
         * @type {GridValidRowModel}
         */
        const properties = { id };
        defaultPerCompoundCols.forEach(({ key, title }) => {
            if (title === 'Compound Structure') {
                properties[key] = compound.getUnnamedSmiles();
            } else if (title === 'Compound Name') {
                properties[key] = compound.displayName;
            } else {
                properties[key] = getPerCompoundValue(compound, key);
            }
        });
        const otherProps = listPerCompoundProps(compound);
        if (otherProps) {
            for (const propInfo of otherProps) {
                properties[makePropKey(propInfo)] = propInfo.value;
            }
        }
        properties.resSpec = compound.resSpec;
        for (const [index, mapCase] of loadedProteins.entries()) {
            const relevantCompound = useUniqueCompounds ? getBestPose(mapCase, compound) : compound;
            if (!relevantCompound) {
                continue;
            }
            for (const { key } of defaultPerPoseCols) {
                const colKey = makePerProtColKey(key, mapCase, index);
                properties[colKey] = getPerPoseValue(relevantCompound, key, mapCase);
            }
            for (const propInfo of listPerProteinProps(relevantCompound)) {
                const { value } = propInfo;
                const colKey = makePerProtColKey(makePropKey(propInfo), mapCase, index);
                properties[colKey] = relevantCompound.caseData.mapCase === mapCase ? value : '';
            }
        }
        return properties;
    });

    return {
        columnGroupingModel,
        columns,
        rows,
        rowItems: compounds,
    };
}

/**
 * @param {boolean} useUniqueCompounds
 * @returns {Compound[]}
 */
function getCompounds(useUniqueCompounds) {
    if (!useUniqueCompounds) return App.Workspace.getLoadedCompounds();
    const uniqueCompounds = _.uniqBy(
        App.Workspace.getLoadedCompounds(),
        (compound) => compound.getUniqueStructId()
    );
    return uniqueCompounds;
}

function getDefaultVisibleColumns(columns) {
    const visibilityModel = {
        'Compound Name': true,
        'Compound Structure': true,
    };

    columns.forEach((column) => {
        if (column.field.includes('Interaction Score')) visibilityModel[column.field] = true;
    });

    return (visibilityModel);
}

function CustomColumnsPanel() {
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const columnVisibilityModel = useGridSelector(
        apiRef,
        gridColumnVisibilityModelSelector,
    );
    const [columns, setColumns] = useState(rootProps.columns);

    const toggleAbleColumns = _.uniqBy(columns, (column) => column.title);
    const [visibleColumns, setVisibleColumns] = useState(columnVisibilityModel);

    const toggleColumn = (child, checked) => {
        const visibilitySetColumns = columns.filter(
            (column) => column.title === child.title
        );

        const visibilityChanges = {};
        visibilitySetColumns.forEach((column) => {
            visibilityChanges[column.field] = checked;
        });

        setVisibleColumns({
            ...visibleColumns,
            ...visibilityChanges,
        });
    };
    const updateModel = () => {
        apiRef.current.setColumnVisibilityModel(visibleColumns);
    };
    const setAllColumnVisibility = (checked) => {
        const visibilityChanges = {};
        columns.forEach((column) => { visibilityChanges[column.field] = checked; });
        setVisibleColumns({
            ...visibleColumns,
            ...visibilityChanges,
        });
    };

    const filterColumns = (searchTerm) => {
        const filteredColumns = rootProps.columns.filter(
            (column) => column.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setColumns(filteredColumns);
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
                <TextField
                    id="search-bar"
                    variant="outlined"
                    placeholder="Search"
                    size="small"
                    onInput={(e) => {
                        filterColumns(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box sx={{
                overflow: 'auto', width: '100%', padding: '1rem', borderBottom: '1px solid lightgrey',
            }}
            >
                {toggleAbleColumns.map((column) => (
                    <Stack direction="row" key={column.field}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={visibleColumns[column.field] !== false}
                                    sx={{ p: 1 }}
                                />
                            )}
                            label={column.title}
                            onChange={(val, newValue) => toggleColumn(column, newValue)}
                        />
                    </Stack>
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '.2rem' }}>
                <Checkbox
                    checked={
                        Object.values(visibleColumns).every(
                            (value) => value === true,
                        )
                    }
                    indeterminate={
                        Object.values(visibleColumns).some(
                            (value) => value === true,
                        ) && Object.values(visibleColumns).some(
                            (value) => value === false,
                        )
                    }
                    onChange={(val, newValue) => setAllColumnVisibility(newValue)}
                />
                <Button
                    onClick={
                        () => setVisibleColumns(columnVisibilityModel)
                    }
                    disabled={_.isEqual(columnVisibilityModel, visibleColumns)}
                >
                    Cancel
                </Button>
                <Button
                    onClick={
                        () => updateModel()
                    }
                    variant="contained"
                    disabled={_.isEqual(columnVisibilityModel, visibleColumns)}
                >
                    Save Changes
                </Button>
            </Box>
        </Box>
    );
}
