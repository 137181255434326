import TabInfo from '../../../../common/TabController/TabInfo';
import { CDDVImportTab } from '../../../../../plugin/CDDV/CDDVTab';
import EnterMolDataTab from './EnterMolDataTab';

const tabs = [
    new TabInfo(
        'import_dragpaste_tab',
        'Enter Mol. Data',
        EnterMolDataTab,
    ),
    new TabInfo(
        'import_cddv_tab',
        'CDD Vault',
        CDDVImportTab,
    ),
];

export default tabs;
