/* Utility functions related to connections */

import { App } from './BMapsApp';

export function loginUrl() {
    return '/login';
}

export function logoutUrl() {
    return './../mr/auth/logout';
}

export function accountUrl() {
    return '../mr/account';
}

export function upgradeUrl() {
    return '../mr/account/products';
}

export function loginUrlForMapCase(mapCase,
    caseDataCollection=App.getDataParents(mapCase).caseDataCollection) {
    if (!mapCase) return loginUrl();
    const structure = minimalStructureTerm(mapCase, caseDataCollection);
    return `/structure/${structure}`;
}

/**
 * @description Get the minimal search term that will
 * uniquely identify this structure.  Either:
 *     pdb | project/pdb | project/case
 * If none of those return a single result, use either
 * pdb or project.
 * @param {*} mapCase
 */
export function minimalStructureTerm(mapCase,
    caseDataCollection=App.getDataParents(mapCase).caseDataCollection) {
    const project = mapCase.project;
    const caseName = mapCase.caseName;
    const pdbID = mapCase.pdbID;

    const tries = [
        pdbID,
        `${project}/${pdbID}`,
        `${project}/${caseName}`,
    ];
    let found;
    for (const term of tries) {
        found = caseDataCollection.findMapsByProjectPDBOrCase(term);
        if (found.length === 1) return term;
    }
    // If none of those worked, try to return pdbID as it's the most meaningful to user
    return pdbID || project;
}
