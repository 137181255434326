import React from 'react';
import { svgImgSrc } from '../ui/ui_utils';

export function FragmentNodeTooltip({ fragment }) {
    return (
        !!fragment.getSvg() && (
        <div>
            <div>
                <img
                    alt={`2D Diagram of ${fragment.name}`}
                    style={{ backgroundColor: 'white' }}
                    width="120px"
                    src={svgImgSrc(fragment.getSvg())}
                />
            </div>
        </div>
        )
    );
}
