/**
 * A Class to organize all fragment data.
 * There are two main sources of data about fragments:
 * 1) bfd-server, providing:
 *    a) list of available fragments, with series names
 *    b) Actual fragment objects for displaying fragment map summaries
 * 1) fragserv API, providing:
 *    a) list of fragment libraries (BMaps, minifrags, RID, custom, etc)
 *    b) list of fragment sets, including builtins (definition of standard fragments)
 *    c) Fragment info for fragments in fragment sets
 *    d) Fragment info for arbitrary fragment names (used to get info for
 *       bfd-server available fragments that aren't in fragment sets)
 *
 * data from bfd-server is stored in a FragList, and is refreshed by sending
 * UserActions.RefreshAvailableFragments()
 *
 * data from fragserv is stored in a FragservData and is automatically refreshed
 * after the bfd-server data is updated in response to RefreshAvailableFragments.
 *
 */
import { FragservWs } from '../WebServices';

export class FragmentData {
    constructor() {
        this.fragservData = new FragservData();
    }

    dumpState() {
        return this.fragservData.dumpState();
    }

    async updateGeneralFragservInfo() {
        const data = await FragservWs.listFragsetInfo();
        if (data) {
            this.fragservData.applyDataFromFragserv(data);
        } else {
            console.warn('Unable to update fragment service data.');
        }
    }

    async getFragservDataForAvailableFragments(availableFragmentInfo) {
        const fragmentNames = availableFragmentInfo.items().map((f) => f.name);
        const data = await FragservWs.fragLookupByName(fragmentNames);
        if (data) {
            this.fragservData.applyFragmentInfo(data.results);
        } else {
            console.warn(`Unable to update data for ${fragmentNames.length} fragments.`);
        }
    }

    availableFragmentsInFragset(availableFragmentInfo, fragset) {
        if (!availableFragmentInfo || !fragset) return [];

        const fragsetNames = fragset.items.map((f) => f.name);
        return availableFragmentInfo.items().filter((f) => fragsetNames.includes(f.name));
    }

    getAvailableClusteringFragments(availFragInfo) {
        return this.availableFragmentsInFragset(availFragInfo, this.fragservData.clusteringFragset);
    }

    // Passthrough to fragservData
    getFragsets() {
        return this.fragservData.getFragsets();
    }

    fragsetById(id) {
        return this.fragservData.fragsetById(id);
    }

    getClusteringFragset() {
        return this.fragservData.getClusteringFragset();
    }
}

export class FragDataSource {
    constructor({
        shortName, longName, description, link,
    }) {
        this.shortName = shortName;
        this.longName = longName;
        this.description = description;
        this.link = link;
    }
}

export class FragDataGroup {
    constructor({ name, items=[] }) {
        this.name = name;
        this.items = items;
    }
}

export class FragDataFrag {
    constructor({ name, molProps, fragSource }) {
        this.name = name;
        this.molProps = molProps;
        this.fragSource = fragSource;
    }
}

export class FragservData {
    constructor(obj) {
        this.libraries = new Map();
        this.fragsets = [];
        this.fragsetFrags = [];
        this.otherFrags = [];
        this.standardFragset = null;
        this.clusteringFragset = null;
        if (obj) {
            this.reset(obj);
        }
    }

    reset(obj) {
        this.libraries = obj.libraries || new Map();
        this.fragsets = obj.fragsets || [];
        this.fragsetFrags = obj.fragsetFrags || [];
        this.otherFrags = obj.otherFrags || [];
        this.standardFragset = this.fragsets.find(
            (fs) => fs.fragsetType === FragservFragset.TypeStandard
        );
        this.clusteringFragset = this.fragsets.find(
            (fs) => fs.fragsetType === FragservFragset.TypeClustering
        );
    }

    dumpState() {
        return `${this.libraries.size} Frag Libraries; ${this.fragsets.length} Fragsets; ${this.fragsetFrags.length} Fragset fragments; ${this.otherFrags.length} fragments not in a fragset`;
    }

    static ParseFragservInfo({ libraries, fragsets, fragments }) {
        const libraryMap = new Map();
        const fragmentMap = new Map();
        const fragmentSetList = [];
        Object.values(libraries).forEach((l) => {
            libraryMap.set(l.id, new FragservFragLibrary(l));
        });
        Object.values(fragments).forEach((f) => {
            const newFF = new FragservFragment({ ...f, library: libraryMap.get(f.libraryId) });
            fragmentMap.set(f.id, newFF);
        });
        Object.values(fragsets).forEach((fs) => {
            const newFs = new FragservFragset({
                ...fs,
                items: fs.fragIds.map((fid) => fragmentMap.get(fid)),
            });
            fragmentSetList.push(newFs);
        });
        return new FragservData({
            libraries: libraryMap,
            fragsets: fragmentSetList,
            fragsetFrags: [...fragmentMap.values()],
        });
    }

    applyDataFromFragserv(info) {
        this.reset(FragservData.ParseFragservInfo(info));
    }

    applyFragmentInfo(fragments) {
        if (this.libraries.size > 0) {
            this.otherFrags = fragments.map(
                (f) => new FragservFragment({ ...f, library: this.libraries.get(f.libraryId) })
            );
        } else {
            console.warn('Fragment Library info is not available yet');
        }
    }

    getStandardFragset() {
        return this.standardFragset;
    }

    getClusteringFragset() {
        return this.clusteringFragset;
    }

    isStandardFrag(name) {
        if (this.standardFragset) {
            return this.standardFragset.items.find((x) => x.name === name);
        } else {
            console.warn('Standard fragment set is not available yet.');
            return undefined;
        }
    }

    fragInfoByFragName(name) {
        const result = [];
        let found;
        for (const fs of this.fragsets) {
            found = fs.items.filter((f) => f.name === name);
            for (const f of found) {
                result.push({ fragset: fs, fragment: f });
            }
        }

        for (const frag of this.otherFrags.filter((f) => f.name === name)) {
            result.push({ fragset: null, fragment: frag });
        }
        return result;
    }

    fragsetByName(name) {
        return this.fragsets.find((fs) => fs.name === name);
    }

    /**
     * Find a fragment set by numeric id.
     *
     * We currently need special handling for fragserv's built-in fragment sets,
     * since these have string ids, whereas all user fragment sets have ints.
     * (I'm not sure why the builtin ids are of different type)
     *
     * Special-casing the builtin fragset string ids is necessary because menu options currently
     * use the dataset in the menu node.
     *
     * @param {Number} id Fragment set id
     * @returns {?FragservFragset} The corresponding fragment set or undefined
     *
     */
    fragsetById(id) {
        let idToUse = Number(id);
        // builtinIds: "Starter frags", "Clustering frags", "Standard frags", "Astex Minifrags"
        const builtinIds = [-1, -2, -3, -4];
        if (builtinIds.includes(idToUse)) {
            idToUse = String(idToUse);
        }
        return this.fragsets.find((fs) => fs.fragsetId === idToUse);
    }

    getLibraries() {
        return [...this.libraries.values()];
    }

    getFragsets() {
        return [...this.fragsets];
    }
}

FragservData.MolPropNames = ['MW', 'HAC', 'LogP', 'HBD', 'HBA', 'TPSA', 'RBC', 'charge'];

export class FragservFragLibrary extends FragDataSource {
    constructor({
        id, shortName, longName, description, link,
    }) {
        super({
            shortName, longName, description, link,
        });
        this.libraryId = id;
    }
}

/**
 * @todo Deal with fragserv built in fragment sets having string ids
 */
export class FragservFragset extends FragDataGroup {
    constructor({
        id, name, fsType, items=[],
    }) {
        super({ name, items });
        // NOTE: the builtin fragment sets have string ids of negative numbers, not sure why.
        // Defined in services/services/fragsets/models.py.  Any change to this needs to
        // account for both BMaps and Fragment service usage of the fragment sets.
        this.fragsetId = id;
        this.fragsetType = fsType;
    }
}

FragservFragset.TypeUser = 'user';
FragservFragset.TypeStandard = 'standard';
FragservFragset.TypeClustering = 'clustering';
FragservFragset.TypeBuiltin = 'builtin';

export class FragservFragment extends FragDataFrag {
    constructor({
        id, name, molProps, library,
    }) {
        super({ name, molProps });
        this.canonicalName = id;
        this.library = null;

        if (library) {
            this.addToLibrary(library);
        }
    }

    addToLibrary(library) {
        this.library = library;
    }
}
