/**
 * @typedef {import('BMapsModel').MapCase} MapCase
 */
import { App } from 'BMapsSrc/BMapsApp';
import { getTargetInfoMap } from 'BMapsRedux/projectState/access';
import { removeSmilesMolName } from 'BMapsUtil/mol_format_utils';
import { floatEquals } from 'BMapsSrc/math';
import { getColumnInfo } from './column_definitions';

export const perProteinColumns = ['bindingScore', 'ligandEfficiency', 'enSolute', 'ligandEfficiencyEn'];
export const linkColumns = ['modType']; // 'fragAtomName'
export const summaryColumns = ['activeTargetCount', 'activeOffTargetCount', 'selectivityScore'];
export const renderDebug = false;

/**
 * Manages information about LigandMod display properties.
 * Currently, these only change when there is a new search.
 * Other state that can change in the context of a single batch of search results
 * (eg search column and which boxes are checked), is in the LigandMod state.
 * This might be a good place to manage which columns are displayed.
 *
 * This class may not be needed anymore.
 */
export class LigandModUIInfo {
    constructor(cmd, hasMultipleFrags=false) {
        const isGrow = cmd === 'grow';

        this.cmd = cmd;
        this.caption = isGrow ? 'candidates for fragment growing' : 'nearby fragments';
        this.displayLinkType = isGrow;
        this.usingSlider = hasMultipleFrags;
        this.addButtonTitle = '';

        if (isGrow) {
            this.addButtonTitle = 'Grow with this fragment';
        } else if (hasMultipleFrags) {
            this.addButtonTitle = 'Add this fragment in the highlighted pose';
        } else {
            this.addButtonTitle = 'Add this fragment';
        }
    }
}

/**
 * Return a sort function for sorting fragment search suggestions.
 * @param {string} sortColIn
 * @param {boolean} sortAscending
 * @returns {function<AnnotatedSearchResult, AnnotatedSearchResult>}
 */
export function sortSearchResults({ sortCol: sortColIn, sortAscending }) {
    const mtypes = ['bond', 'methylene', 'ethane', 'acetylene'];

    return function (aRow, bRow) {
        const defaultSort = 'bindingScore';
        let sortCol = sortColIn;
        if (sortCol === 'default') sortCol = defaultSort;

        const [firstCaseA, firstCaseEntryA] = Object.entries(aRow.perCaseInfo)[0];
        const [firstCaseB, firstCaseEntryB] = Object.entries(bRow.perCaseInfo)[0];
        if (firstCaseA !== firstCaseB) {
            console.warn(`LigandMod sort: first projectCases don't match: ${firstCaseA} !== ${firstCaseB}`);
        }

        // Default comparison is just from the search result
        let compA = aRow[sortCol];
        let compB = bRow[sortCol];

        // Special handling for per-protein columns
        const {
            column: col, group, projectCase, properties,
        } = getColumnInfo(sortCol);
        if (group === 'perProteinColumns') {
            const isMissing = !projectCase || !aRow.perCaseInfo[projectCase];
            if (isMissing) {
                if (projectCase) {
                    console.log(`LigandMod sort can't find entry for ${projectCase} for ${col} (${sortCol}), using first case: ${firstCaseA}`);
                }
                compA = firstCaseEntryA?.[col] || 0;
                compB = firstCaseEntryB?.[col] || 0;
            } else {
                if (properties.getValue) {
                    compA = properties.getValue(aRow, col, projectCase) || 0;
                    compB = properties.getValue(bRow, col, projectCase) || 0;
                } else {
                    compA = aRow.perCaseInfo[projectCase]?.[col] || 0;
                    compB = bRow.perCaseInfo[projectCase]?.[col] || 0;
                }
            }
        }

        // Special handling for summary columns
        if (summaryColumns.includes(sortCol)) {
            compA = aRow.summaryInfo[sortCol];
            compB = bRow.summaryInfo[sortCol];

            // secondary sort for summary columns,
            // Switch a and b to compensate for the fact that these columns are positive,
            // but energies are negative.
            if (compA === compB && compA != null) {
                const perCaseA = Object.values(aRow.perCaseInfo);
                const perCaseB = Object.values(bRow.perCaseInfo);
                if (['selectivityScore', 'activeTargetCount'].includes(sortCol)) {
                    const firstTargetA = perCaseA.find(({ targetInfo }) => targetInfo.isTarget);
                    const firstTargetB = perCaseB.find(({ targetInfo }) => targetInfo.isTarget);
                    compA = firstTargetA?.[defaultSort];
                    compB = firstTargetB?.[defaultSort];
                    // Switch a and b because of opposite sort order
                    if (compA != null && compB != null) [compA, compB] = [compB, compA];
                } else { // activeOffTargetCount
                    const firstOffA = perCaseA.find(({ targetInfo }) => !targetInfo.isTarget);
                    const firstOffB = perCaseB.find(({ targetInfo }) => !targetInfo.isTarget);
                    compA = firstOffA?.[defaultSort];
                    compB = firstOffB?.[defaultSort];
                    // Switch a and b because of opposite sort order
                    if (compA != null && compB != null) [compA, compB] = [compB, compA];
                }
            }
        }

        // Handle null cases first (shouldn't happen).
        if (compA != null && compB == null) return sortAscending ? 1 : -1;
        else if (compA == null && compB != null) return sortAscending ? -1 : 1;
        else if (compA == null && compB == null) return 0;

        // Special handling for other columns
        if (sortCol === 'modType') {
            // modTypes are sorted by index (not by string value) to preserve order
            compA = mtypes.indexOf(compA);
            compB = mtypes.indexOf(compB);
            // Secondary sort for modType: bindingScore
            if (compA === compB) {
                compA = firstCaseEntryA['bindingScore'];
                compB = firstCaseEntryB['bindingScore'];
            }
        } else if (sortCol === 'name') {
            compA = compA.toLowerCase();
            compB = compB.toLowerCase();
            // Secondary sort for name: modType (link type)
            if (compA === compB) {
                // modTypes are sorted by index (not by string value) to preserve order
                compA = mtypes.indexOf(aRow['modType']);
                compB = mtypes.indexOf(bRow['modType']);
            }
        }

        if (compA > compB) return sortAscending ? 1 : -1;
        else if (compA < compB) return sortAscending ? -1 : 1;
        else if (compA === compB) return 0;
        else return 0; // This would be unexpected
    };
}

/**
 * Return an object with the properties of the fragment associated with a fragment search result.
 *
 * @param {AnnotatedSearchResult} sugg
 * @param {string[]} projectCases
 * @returns {{
 *      name: string, smiles: string, mwt: number,
 *      charge: number, HAC: number, LogP: number, TPSA: number,
 *      HBA: number, HBD: number, RBC: number,
 *      conflictingProps: object?
 * }}
 */
export function getFragmentPropsForSuggestion(sugg, projectCases) {
    const fragProps = {
        name: sugg.name,
        smiles: null, // filled out below
        mwt: sugg.mwt,
    };

    let conflictingProps = null;

    // Add properties by name.
    // It is possible for the values to be different for the same property name,
    // so collect alternate values in conflictingProps.
    // TODO: investigate and better handle multiple definitions,
    // especially those with same names in basis and minifrags:
    //     pyrazole, imidazole, pyridine, morpholine_Prot
    function addProp(propName, value) {
        if (!value && value !== 0) return;
        const existing = fragProps[propName];
        if (existing == null) {
            fragProps[propName] = value;
        } else if (valueChanged(existing, value)) {
            if (!conflictingProps) conflictingProps = {};
            if (!conflictingProps[propName]) conflictingProps[propName] = [existing];
            if (!conflictingProps[propName].includes(value)) {
                conflictingProps[propName].push(value);
            }
        } // otherwise they are equal, so keep the existing value
    }

    function valueChanged(existing, incoming) {
        if (typeof existing !== typeof incoming) {
            return true;
        } else if (typeof existing === 'number') {
            return !floatEquals(existing, incoming);
        } else {
            return existing !== incoming;
        }
    }

    // Smiles is taken fom the available fragments reported by bfd-server.
    // Because each projectCase reports its own available fragments,
    // there is a chance that the smiles could be different.
    for (const pc of projectCases) {
        const fragInfo = App.Workspace.getInfoForFragment(sugg.name, pc);
        if (fragInfo) {
            const smiles = removeSmilesMolName(fragInfo.smiles);
            addProp('smiles', smiles);
        }
    }

    // All other props are taken from those reported by the Fragment Service fetch
    const fragServFragInfo = App.Workspace.fragmentData.fragservData.fragInfoByFragName(sugg.name);
    const fragPropCols = ['charge', 'HAC', 'LogP', 'TPSA', 'HBA', 'HBD', 'RBC'];
    for (const col of fragPropCols) {
        for (const { fragment: fragmentInfoItem } of fragServFragInfo) {
            // BUG: additional proteins are clearing the molprops from previous proteins.
            // So if frag A is in Protein 1, but not Protein 2, it will be missing props.
            const value = fragmentInfoItem.molProps[col];
            addProp(col, value);
        }
    }
    fragProps.conflictingProps = conflictingProps;
    return fragProps;
}

/**
 * Grab the map case and targetinfo for a projectcase
 * @param {string} projectCase
 * @returns {{mapCase: MapCase, targetInfo: TargetInfo}}
 */
export function getMapCaseAndTargetInfo(projectCase) {
    const targetMap = getTargetInfoMap();
    for (const [mapCase, targetInfo] of targetMap.entries()) {
        if (mapCase.projectCase === projectCase) {
            return { mapCase, targetInfo };
        }
    }
    return {};
}

/**
 *
 * @param {string[]} projectCases
 * @returns {boolean} whether any of the project cases have a kinaseId
 */
export function isKinaseInProjectCases(projectCases) {
    for (const pc of projectCases) {
        const { mapCase } = getMapCaseAndTargetInfo(pc);
        const hasKinase = !!getKinaseIdForMapCase(mapCase);
        if (hasKinase) {
            return true;
        }
    }
    return false;
}

/**
 * Allow pulling kinase id from "other properties" (defined in map-list.json)
 * or from the klifs properties fetched from the klifs API (via integrations inspector tab).
 * @param {import('BMapsModel').MapCase} mapCase
 * @returns {string}
 */
export function getKinaseIdForMapCase(mapCase) {
    if (mapCase.otherProperties?.kinaseId) return mapCase.otherProperties.kinaseId;
    let kinaseId = mapCase.getProperty('klifs', 'kinase');
    if (Array.isArray(kinaseId)) kinaseId = kinaseId[0];
    return kinaseId || '';
}
