import { useState, useCallback } from 'react';
import { App } from '../../../BMapsApp';
import useEventBrokerSubscription from './useEventBrokerSubscription';

/**
 * React hook returning the selected atoms from the workspace.
 * This triggers rerenders when the selection changes.
 * @returns {Array<Atom>} the selected atoms
 */
export function useWithSelectedAtoms() {
    const initialState = App.Ready ? App.Workspace.getSelectedAtoms() : [];
    const [selectedAtoms, setSelectedAtoms] = useState(initialState);
    const handleSelection = useCallback((_, newSelected) => setSelectedAtoms(newSelected), []);
    useEventBrokerSubscription('selectionDisplay', handleSelection);
    return selectedAtoms;
}
