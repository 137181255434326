import {
    RotateThenTranslate, InvertRTTransform, MultiplyMatrices,
} from 'BMapsSrc/math';
import { MolAtomLineRegex, formMolAtomLine } from 'BMapsUtil/mol_format_utils';

/**
 * @typedef {{
 *     translation: [number, number, number],
 *     rotation: [number, number, number][]
 * }} Transform
 */

/**
 * Apply transformation provided by USalign output
 * @param {[number, number, number]} pos
 * @param {{
*      translation: [number, number, number],
*      rotation: [number, number, number][]
* }} usalignMatrix
* @returns {[number, number, number]}
*/
export function usalignTransform(pos, usalignMatrix) {
    const { translation, rotation } = usalignMatrix;
    const result = RotateThenTranslate(pos, rotation, translation);
    return result;
}

/**
 * Reverse transformation provided by USalign output
 * @param {[number, number, number]} pos
 * @param {{
*      translation: [number, number, number],
*      rotation: [number, number, number][]
* }} usalignMatrix
* @returns {[number, number, number]}
*/
export function undoUsalignTransform(pos, usalignMatrix) {
    const { translation, rotation } = InvertRTTransform(usalignMatrix);
    const result = RotateThenTranslate(pos, rotation, translation);
    return result;
}

/* FUNCTIONS TO TRANSFORM MOL DATA */
export function transformMolString(molText, transform) {
    const lines = molText.split('\n');
    const resultLines = [];
    const atomLineRe = new RegExp(MolAtomLineRegex);
    for (const line of lines) {
        const match = line.match(atomLineRe);
        if (!match) {
            resultLines.push(line);
            continue;
        }

        const {
            x, y, z, element, numbers,
        } = match.groups;
        const pos = [Number(x), Number(y), Number(z)];
        const pos2 = usalignTransform(pos, transform);
        const [newX, newY, newZ] = pos2;
        const newAtomLine = formMolAtomLine({
            x: newX, y: newY, z: newZ, element, numbers,
        });
        resultLines.push(newAtomLine);
    }
    const newMol = resultLines.join('\n');
    return newMol;
}

/**
 * Combine two transformations.
 * This produces a single transformation that is the same as if you applied the two transforms
 * in order.
 * @param {Transform} transform1 - The 1st transformation
 * @param {Transform} transform2 - The 2nd transformation
 * @returns {Transform} - Single transformation that is the result of applying both transformations
 */
export function combineTransforms(transform1, transform2) {
    // Create 4x4 transformation matrices for easy matrix multiplication.
    // The translation vector is the last column of the matrix, to the right of the rotation.
    const matrix1 = createTransformationMatrix(transform1);
    const matrix2 = createTransformationMatrix(transform2);

    const combinedMatrix = MultiplyMatrices(matrix2, matrix1);

    // Translation is the last column
    const combinedTranslation = [combinedMatrix[0][3], combinedMatrix[1][3], combinedMatrix[2][3]];

    // Rotation is the top-left 3x3 submatrix
    const combinedRotation = [
        [combinedMatrix[0][0], combinedMatrix[0][1], combinedMatrix[0][2]],
        [combinedMatrix[1][0], combinedMatrix[1][1], combinedMatrix[1][2]],
        [combinedMatrix[2][0], combinedMatrix[2][1], combinedMatrix[2][2]],
    ];
    return {
        translation: combinedTranslation,
        rotation: combinedRotation,
    };
}

/**
 * Take a translation and rotation and create a 4x4 transformation matrix.
 * The translation is the last column of the matrix.
 * @param {Transform} param0
 * @returns {number[][]} - 4x4 transformation matrix
 */
function createTransformationMatrix({ translation, rotation }) {
    const transformationMatrix = [
        [...rotation[0], translation[0]],
        [...rotation[1], translation[1]],
        [...rotation[2], translation[2]],
        [0, 0, 0, 1], // Homogeneous coordinate
    ];
    return transformationMatrix;
}
