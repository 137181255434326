import { useState } from 'react';
import Popover from '@mui/material/Popover';
import { IndigoSvg } from 'BMapsSrc/IndigoSvg';
import BMapsTooltip from '../BMapsTooltip';

/**
 * @typedef {import('BMapsModel').Compound} Compound
 * @typedef {import('BMapsModel').MapCase} MapCase
 * @typedef {import('@mui/x-data-grid').GridValidRowModel} GridValidRowModel
 */

/**
 * @param {{
 *    smiles: Compound['smiles'],
 *    row: GridValidRowModel,
 * }} props
 * @returns
 */
export function HoverableCompoundStructureCell({ smiles, row }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setTimer(setTimeout(handleClose, 2000));
    };

    const handleClose = () => {
        clearTimeout(timer);
        setAnchorEl(null);
    };

    const [timer, setTimer] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? `${smiles}-${row.id}-popoup` : undefined;

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
            }}
        >
            <div
                aria-describedby={id}
                onFocus={handleOpen}
                onMouseOver={handleOpen}
                onBlur={handleClose}
                onMouseLeave={handleClose}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <IndigoSvg
                    smiles={smiles}
                    style={{
                        padding: '0',
                        height: '100%',
                        width: '100%',
                    }}
                    autoUpdate
                    alt={`2D structure of ${row['Compound Name']}`}
                />
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{ pointerEvents: 'none' }}
            >
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                    <IndigoSvg
                        smiles={smiles}
                        autoUpdate
                        style={{
                            padding: '0',
                            height: '6rem',
                            overflow: 'hidden',
                        }}
                        alt=""
                    />
                </div>
            </Popover>
        </div>
    );
}

/**
 * @param {{
 *    value: number,
 *    mapCase: MapCase,
 *    row: GridValidRowModel,
 *    index: number
 *    useUniqueCompounds?: boolean
 * }} props
 * @returns
 */
export function ProteinCell({
    value, mapCase, row, index, useUniqueCompounds=false,
}) {
    let tooltip = getFormattedValue(value);

    if (useUniqueCompounds) {
        const bestPoseKey = `Best Pose Name ${mapCase.displayName} (Protein ${index + 1})`;
        const bestPoseName = row[bestPoseKey];
        // Option to activate the best pose
        // const { caseData } = App.getDataParents(mapCase);
        // const bestPose = caseData.getCompoundBySpec(bestPoseName);
        // const bestPoseActivate = (
        //     <LinkLikeButton
        //         onClick={() => UserActions.ActivateCompound(bestPose)}
        //         title={`Focus on ${bestPoseName}`}
        //     >
        //         {bestPoseName}
        //     </LinkLikeButton>
        // );

        tooltip = (
            <>
                {getFormattedValue(value)}
                <br />
                <br />
                Top scoring pose for
                {' '}
                {mapCase.getLongName()}
                {' is '}
                {bestPoseName}
            </>
        );
    }

    return (
        <BMapsTooltip title={tooltip} placement="bottom-start">
            <span>{ getFormattedValue(value) }</span>
        </BMapsTooltip>
    );
}

/**
 * @param {{
 *    title: string,
 * }} props
 * @returns
 */
export function ProteinHeader({ title }) {
    let formattedTitle = title;
    if (title === 'ddGs') {
        formattedTitle = '\u0394\u0394Gs';
    }
    return (
        <BMapsTooltip title={formattedTitle} placement="bottom-start">
            <span>{ formattedTitle }</span>
        </BMapsTooltip>
    );
}

export function CompoundNameCell({ row, value }) {
    const resSpec = row.resSpec;
    return (
        <BMapsTooltip title={resSpec}>
            <span>{ value }</span>
        </BMapsTooltip>
    );
}

/**
 * @param {string | number} value
 * @returns
 */
export function DefaultCell(value) {
    return getFormattedValue(value);
}

/**
 * @param {string | number} value
 * @returns
 */
function getFormattedValue(value) {
    if (typeof value !== 'number') return value;
    if (Number.isInteger(value)) return value;
    return value.toFixed(2);
}
