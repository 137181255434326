/**
 * @typedef {import('BMapsModel').Compound} Compound
 * @typedef {import('BMapsModel').MapCase} MapCase
 * @typedef {import('BMapsModel').ParentCompound} ParentCompound
 * @typedef {import('@mui/x-data-grid').GridColumnVisibilityModel} GridColumnVisibilityModel
 * @typedef {import('@mui/x-data-grid').GridRowsProp} GridRowsProp
 * @typedef {import('@mui/x-data-grid').GridColDef} GridColDef
 * @typedef {import('@mui/x-data-grid').GridColumnGroupingModel} GridColumnGroupingModel
 * @typedef {import('@mui/x-data-grid').GridToolbarProps} GridToolbarProps
 * @typedef {import('@mui/x-data-grid').ToolbarPropsOverrides} ToolbarPropsOverrides
 * @typedef {any} item
 * @typedef {import('@mui/x-data-grid').GridSlotProps['filterPanel']} FilterPanelProps
 */

import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import createSvgIcon from '@mui/material/utils/createSvgIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    DataGrid, GridColumnHeaderSortIcon, GridColumnsPanel, gridFilteredSortedRowIdsSelector,
    gridSortedRowIdsSelector, GridToolbarColumnsButton, GridFilterPanel,
    GridToolbarContainer, GridToolbarDensitySelector,
    useGridApiContext, useGridApiRef,
} from '@mui/x-data-grid';

/**
 * @param {{
 *     rows: GridRowsProp,
 *     columns: GridColDef[],
 *     columnGroupingModel?: GridColumnGroupingModel,
 *     setColumnVisibilityModel?: (columnVisibilityModel: GridColumnVisibilityModel) => any,
 *     columnVisibilityModel?: GridColumnVisibilityModel,
 *     setCheckedRowItems?: (item: item) => any,
 *     rowItems?: item[],
 *     disableTable?: boolean
 *     exportFileName?: string
 *     checkboxSelection?: boolean
 *     toolBarId? string,
 *     customPanel?: (props: GridColumnsPanelProps) => any
 *     visibilityDefault?: boolean
 *     duplicateColumnKeys?: string[]
 *}} props
 * @returns
 */
export function BMapsTable({
    rows, columns, columnGroupingModel,
    setColumnVisibilityModel, columnVisibilityModel,
    setCheckedRowItems, rowItems, disableTable, exportFileName,
    checkboxSelection, toolBarId, customPanel = null, visibilityDefault = false,
    duplicateColumnKeys = [],
}) {
    const apiRef = useGridApiRef();

    const managedVisibilityModel = manageColumnVisibility(
        columnVisibilityModel, columns, duplicateColumnKeys, visibilityDefault
    );

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            columnGroupingModel={columnGroupingModel}
            checkboxSelection={checkboxSelection}
            disableRowSelectionOnClick
            slots={{
                toolbar: (toolBarProps) => (
                    <CustomToolbar
                        {...toolBarProps}
                        exportFileName={exportFileName}
                        toolBarId={toolBarId}
                    />
                ),
                filterPanel: () => (
                    <CustomFilterPanel />
                ),
                filterPanelDeleteIcon: () => (
                    <DeleteIcon />
                ),
                columnsPanel: customPanel? (props) => customPanel(props)
                    : (props) => <GridColumnsPanel {...props} />,
                columnHeaderSortIcon: (sortIconProps) => (
                    <GridColumnHeaderSortIcon {...sortIconProps} title={`Sort by ${sortIconProps.field}`} />
                ),
            }}
            slotProps={{
                columnsManagement: {
                    getTogglableColumns: (cols) => cols.filter(({ field }) => {
                        // '__check__' is the default name for the checkboxes given by
                        // mui data grid. This will prevent users from removing the
                        // checkbox column
                        if (field === '__check__') return false;
                        return true;
                    }).map((col) => col.field),
                },
            }}
            hideFooter
            apiRef={apiRef}
            loading={disableTable}
            onRowSelectionModelChange={(gridRows) => {
                const newCheckedItems = [];
                gridRows.forEach((index) => {
                    if (rowItems[index]) {
                        newCheckedItems.push(rowItems[index]);
                    }
                });
                setCheckedRowItems(newCheckedItems);
            }}
            onColumnVisibilityModelChange={(event) => {
                setColumnVisibilityModel(event);
            }}
            columnVisibilityModel={managedVisibilityModel}
            sx={{
                '& .protein-header': {
                    backgroundColor: 'lightGray',
                    borderRight: '1px solid white',
                    borderLeft: '1px solid white',
                },
                '& .MuiDataGrid-columnHeader--emptyGroup': {
                    borderBottom: '1px solid lightgray',
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                },
                '& .none-header': {
                    padding: 0,
                },
                // on some resolutions this separator causes the top check box to become
                // miss-aligned.
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
            }}
        />
    );
}

/**
 * @param {{exportFileName?: string} & GridToolbarProps & ToolbarPropsOverrides} props
 * @returns
 */
function CustomToolbar({ exportFileName, toolBarId, ...toolBarProps }) {
    const exportAll = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);
    const exportVisible = ({ apiRef }) => gridFilteredSortedRowIdsSelector(apiRef);

    const ref = useGridApiContext();

    const handleExport = (options) => {
        handleClose();
        return (
            ref.current.exportDataAsCsv(options)
        );
    };

    const fileName = exportFileName || `BMaps Table ${new Date().toLocaleDateString('en-US')}`;

    /** @type {import('@mui/x-data-grid').GridCsvExportOptions} */
    const allExportOptions = {
        allColumns: true,
        getRowsToExport: exportAll,
        fileName,
        includeColumnGroupsHeaders: false,
    };

    const visibleExportOptions = {
        getRowsToExport: exportVisible,
        fileName,
        includeColumnGroupsHeaders: false,
    };

    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        'SaveAlt',
    );

    const buttonBaseProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ExportIcon />,
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const idString = toolBarId || `BMaps Table ${new Date().toLocaleDateString('en-US')}`;
    const id = open ? idString : undefined;

    return (
        <GridToolbarContainer {...toolBarProps}>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <Button
                {...buttonBaseProps}
                aria-describedby={toolBarId}
                onClick={handleClick}
            >
                Export
            </Button>
            <Menu
                id={toolBarId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleExport(allExportOptions)}>
                    Export All to CSV
                </MenuItem>
                <MenuItem onClick={() => handleExport(visibleExportOptions)}>
                    Export Visible to CSV
                </MenuItem>
            </Menu>
        </GridToolbarContainer>
    );
}

function CustomFilterPanel() {
    const filterPanelProps = {
        // Display columns by ascending alphabetical order
        columnsSort: 'asc',
        filterFormProps: {
            // Customize inputs by passing props
            logicOperatorInputProps: {
                variant: 'outlined',
            },
            columnInputProps: {
                variant: 'outlined',
                sx: { mt: 'auto' },
            },
            operatorInputProps: {
                variant: 'outlined',
                sx: { mt: 'auto' },
            },
            valueInputProps: {
                InputComponentProps: {
                    variant: 'outlined',
                    sx: { mt: 'auto' },
                },
            },
            deleteIconProps: {
                sx: {
                    margin: '.5rem',
                },
            },
        },
        sx: {
            // Customize inputs using css selectors
            '& .MuiDataGrid-filterForm': { p: 2 },
            '& .MuiDataGrid-filterForm:nth-child(even)': {
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'),
            },
            '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
            '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
            '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
            '& .MuiDataGrid-filterFormValueInput': { width: 200 },
            '& .MuiIconButton-root': {
                margin: 'auto',
            },
        },
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <GridFilterPanel {...filterPanelProps} />
        </Box>
    );
}

// Provides additional functionality to column visibility
// columns can be hidden by default if visibilityDefault is false
// column visibility can be linked to other columns by strings that are common in multiple columns
function manageColumnVisibility(
    compoundVisibilityModel, allColumns, duplicateColumnKeys, visibilityDefault
) {
    const managedVisibilityModel = { ...compoundVisibilityModel };
    const refrenceVisibility = {};

    Object.keys(managedVisibilityModel).forEach((key) => {
        const refrenceTitle = duplicateColumnKeys.find((title) => key.includes(title));
        if (refrenceTitle) {
            refrenceVisibility[refrenceTitle] = managedVisibilityModel[key];
        }
    });

    allColumns.forEach((column) => {
        Object.keys(refrenceVisibility).forEach((key) => {
            if (column.field.includes(key)) {
                managedVisibilityModel[column.field] = refrenceVisibility[key];
            }
        });
    });

    if (!visibilityDefault) {
        allColumns.forEach((column) => {
            if (!managedVisibilityModel[column.field]) {
                managedVisibilityModel[column.field] = false;
            }
        });
    }

    return managedVisibilityModel;
}
