import { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
// Material UI
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
// Local
import DialogContentSection from 'BMapsSrc/ui/common/DialogContentSection';
import { withTooltip, withFormik } from '../../../../common/helpers';
import TextField, { TextArea } from '../../../../common/TextFields';
import { SelectWithFormik } from '../../../../common/Select';
import { loadProteinFiles, loadSessionFiles } from '../../../../../paste_drag';
import { MapCase, UserDataImportCase } from '../../../../../model/MapCase';
import { fileFilterAcceptedTypes } from '../../tab_utils';
import { getSupportedProteinTypes, guessFormat } from '../../../../../util/mol_format_utils';
import { WorkingIndicator } from '../../../../ui_utils';
import { DialogSubmitButton } from '../../../../common/DialogActionButtons';
import FormControlContainer from '../../base/FormControlContainer';
import FileDroppableTextAreaWithFormik from '../../base/TextAreaWithFormik';
import {
    InputBox, PlaceHolder, InputFile, UploadButton, FormControlLabelStyled, ImportErrors,
} from '../../import_components';

function PreserveHydrogensCheckbox({ ...rest }) {
    return (
        <FormControlLabelStyled
            control={(
                <Checkbox
                    color="primary"
                    {...rest}
                />
                )}
            label="Preserve Hydrogens"
        />
    );
}

const PreserveHydrogensWithTooltip = withTooltip(PreserveHydrogensCheckbox);

export default function ImportProteinTab({
    selectProtein,
    closeImportPane,
    initData,
    errors,
}) {
    const uploadBtnRef = useRef(null);
    const [loading, setLoading] = useState(false);

    function validateProteinDataText(text) {
        return text === '' ? 'This field is required!' : undefined;
    }

    function validateDataFormat(format) {
        return format === '' ? 'This field is required!' : undefined;
    }

    function formValidate(formValues) {
        const formErrs = {};
        let val;

        val = validateProteinDataText(formValues.proteinDataText);
        if (val) {
            formErrs.proteinDataText = val;
        }

        val = validateDataFormat(formValues.dataFormat);
        if (val) {
            formErrs.dataFormat = val;
        }

        return formErrs;
    }

    function getMoltypeSelectComponents() {
        const moltypes = getSupportedProteinTypes();
        return (
            moltypes.map((moltype) => (
                <MenuItem
                    key={moltype}
                    value={moltype}
                >
                    {moltype.toUpperCase()}
                </MenuItem>
            ))
        );
    }

    function handleUploadBtnClicked() {
        uploadBtnRef.current.children[0].click();
    }

    function handleSubmit(formValues) {
        const {
            proteinName: name, PDBid: pdbid, dataFormat: format,
            proteinDataText: data, preserveHydrogens, nameMappings,
            smilesText,
        } = formValues;
        const ligandSmiles = smilesText.split('\n');
        const loadOptions = { preserveHydrogens, nameMappings, ligandSmiles };
        const newMapCase = new UserDataImportCase({
            data, name, pdbid, format, loadOptions,
        });
        selectProtein(newMapCase, loadOptions);
        return false;
    }

    // Actually render the form
    return (
        <Formik
            initialValues={{
                proteinDataText: initData?.proteinData || '',
                dataFormat: initData?.dataFormat || '',
                PDBid: '',
                proteinName: '',
                preserveHydrogens: false,
                nameMappings: '',
                smilesText: '',
            }}
            onSubmit={handleSubmit}
            validate={formValidate}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {/* Formik Render Function with parameters from the formik "bag" */}
            {({
                values, handleChange, setFieldValue, getFieldProps,
            }) => {
                const { proteinDataText, preserveHydrogens } = values;

                function handleTextFieldChange(e) {
                    handleChange(e);
                    updateFormat(e.target.value);
                }

                async function stageProteinFile(file) {
                    setLoading(true);
                    if (await loadSessionFiles([file])) {
                        closeImportPane();
                        return;
                    }
                    const proteinSources = await loadProteinFiles([file]);
                    const proteinText = proteinSources[0]?.proteinData;

                    setLoading(false);
                    if (proteinText != null) {
                        setFieldValue('proteinDataText', proteinText);
                        updateFormat(proteinText);
                    }
                }

                function handleImportFileChange(e) {
                    stageProteinFile(e.target.files[0]);
                }

                function updateFormat(data) {
                    const format = guessFormat(data);
                    setFieldValue('dataFormat', format);
                }

                function updateLigandSmiles(e) {
                    const smiles = e.target.value;
                    setFieldValue('smilesText', smiles);
                }

                return (
                    <Form id="importForm">
                        {errors && <ImportErrors errors={errors} /> }
                        <PlaceHolder show={proteinDataText === ''}>
                            { loading
                                ? <WorkingIndicator />
                                : (
                                    <>
                                        Drag & Drop or
                                        {' '}
                                        <UploadButton
                                            disableRipple
                                            onClick={handleUploadBtnClicked}
                                        >
                                            upload
                                        </UploadButton>
                                        {' '}
                                        a pdb, supported protein file, or BMaps session file,
                                        {' '}
                                        <br />
                                        Or paste protein data and click &quot;Import Protein&quot;
                                    </>
                                )}
                        </PlaceHolder>
                        <FileDroppableTextAreaWithFormik
                            name="proteinDataText"
                            id="importMolText"
                            rows={1}
                            autoFocus
                            validate={validateProteinDataText}
                            onChange={handleTextFieldChange}
                            onDrop={(files) => stageProteinFile(files[0])}
                            sx={{
                                '& textarea': {
                                    resize: 'vertical',
                                    minHeight: '7rem',
                                },
                            }}
                        />
                        <InputBox>
                            <SelectWithFormik
                                name="dataFormat"
                                id="importMolFormat"
                                label="Data Format"
                                validate={validateDataFormat}
                                SelectProps={{
                                    native: false,
                                }}
                            >
                                <MenuItem value=""><em>Data Format</em></MenuItem>
                                {getMoltypeSelectComponents()}
                            </SelectWithFormik>
                            <TextField
                                fullWidth
                                name="PDBid"
                                id="importPDBid"
                                label="PDB ID (optional)"
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                name="proteinName"
                                id="importProteinName"
                                label="Protein Name (optional)"
                                variant="outlined"
                            />
                            <FormControlContainer>
                                <InputFile
                                    ref={uploadBtnRef}
                                    id="importFileSelect"
                                    type="file"
                                    onChange={handleImportFileChange}
                                    inputProps={{
                                        accept: fileFilterAcceptedTypes(getSupportedProteinTypes()),
                                    }}
                                />
                            </FormControlContainer>
                        </InputBox>
                        <DialogContentSection
                            title="Advanced parameters"
                            collapsible
                            disablePaddingTop
                            disablePaddingBottom
                            collapsedMarginBottom="0.5em"
                        >
                            <PreserveHydrogensWithTooltip
                                {...getFieldProps('preserveHydrogens')}
                                checked={preserveHydrogens}
                                tooltip="Keep all hydrogen atoms as defined and do not add missing hydrogens.
                    All hydrogen atoms must have Amber compatible names.
                    Recommended for advanced users or NMR structures."
                            />
                            <TextAreaWithTooltip
                                id="importNameMappings"
                                label="Name Mappings (optional)"
                                fullWidth
                                minRows={1}
                                variant="outlined"
                                style={{ flexGrow: 1, height: 'unset', marginBottom: '0.5ex' }}
                                {...getFieldProps('nameMappings')}
                                tooltip='Specify name mappings for atoms, residues, or chains.
                        See the "Viewing Proteins" section of the Guide for more information'
                            />
                            <TextAreaWithTooltip
                                fullWidth
                                minRows={1}
                                name="ligandSmiles"
                                id="importLigandSmiles"
                                label="Ligand SMILES (optional)"
                                variant="outlined"
                                onChange={updateLigandSmiles}
                                style={{ flexGrow: 1, height: 'unset' }}
                                tooltip="SMILES strings for ligands in this data, used to help calculate hydrogens.
                                Enter SMILES one per line, in order, for each ligand."
                            />
                        </DialogContentSection>
                        <DialogSubmitButton style={{ width: '100%' }}>Import Protein</DialogSubmitButton>
                    </Form>
                );
            }}
        </Formik>
    );
}

const TextAreaWithFormik = withFormik(TextArea, { hasErrorState: false });
const TextAreaWithTooltip = withTooltip(TextAreaWithFormik, {
    ContainerProps: {
        style: { paddingTop: '0.5ex' },
    },
});
