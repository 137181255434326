import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { UserActions } from 'BMapsCmds';
import { App } from '../../BMapsApp';
import TextField from '../../ui/common/TextFields';
import { CDDVIntegration } from './CDDVData';
import { DialogSubmitButton } from '../../ui/common/DialogActionButtons';
import Select from '../../ui/common/Select';
import { svgImgSrc, WorkingIndicator } from '../../ui/ui_utils';
import DialogSplitButton from '../../ui/common/DialogSplitButton';
import ConfirmationDialog from '../../ui/common/ConfirmationDialog';
import { svgForMol } from '../../util/svg_utils';
import { molfileNeeds3D, is2dFormat } from '../../util/mol_format_utils';
import CDDVContextProvider, { CDDVContext } from './CDDVContextProvider';
import CommonExternalLink from '../../ui/common/ExternalLink';

const controlSpacing = '.5em';

/**
 * Use for CDD Vault import tab; pass a closeImportPane property to close after import
 */
export function CDDVImportTab({ closeImportPane }) {
    return (
        <CDDVContextProvider includeSearches>
            <CDDVTab>
                <CDDVContentParent title="Import Molecule from CDD Vault">
                    <MoleculesDisplay closeImportPane={closeImportPane} />
                </CDDVContentParent>
            </CDDVTab>
        </CDDVContextProvider>
    );
}

/**
 * Use for CDD Vault export tab. Pass property loading if data is being prepared. Pass property
 * error as a string if there is an error with preparing data. Once data is ready, pass property
 * exportData, an object of the form {
 *  @property {string} name: a string containing a default name for the export
 *  @property {string} smiles: a SMILES string describing the export
 *  @property {string} data: a mol2 string describing the export
 *
 * }
 */
export function CDDVExportTab({ loading, exportData, error }) {
    return (
        <CDDVContextProvider>
            <CDDVTab error={error}>
                <CDDVContentParent title="CDD Vault Molecule Upload">
                    <ExportPane loading={loading} exportData={exportData} />
                </CDDVContentParent>
            </CDDVTab>
        </CDDVContextProvider>
    );
}

/**
 * This is the wrapper component for any implementation of CDDV ui integration.
 *
 * When using for import, it needs the prop closeImportPane, which should be a function
 * that will close the main import panel following a successful import.
 *
 * When using for export, pass the prop exportPane. If data is not ready, pass loading
 *   to display a loading screen.
 * If data is ready, it should be passed as exportData, an object of the form {
 *  @property {string} name: a string containing a default name for the export
 *  @property {string} smiles: a SMILES string describing the export
 *  @property {string} data: a mol2 string describing the export
 *
 * }
 */
function CDDVTab({ error='', children }) {
    const CDDV = CDDVIntegration.getInstance();
    const [setupStateKnown, setSetupStateKnown] = useState(false);
    const {
        setVaultsLoaded, connected, setConnected,
    } = useContext(CDDVContext);
    if (error) {
        return <WarningText style={{ fontWeight: 'bold' }} text={error} />;
    }

    if (!connected && !setupStateKnown) {
        checkSetup();
    }
    async function checkSetup() {
        const response = await CDDV.check_setup(setVaultsLoaded);
        setConnected(response);
        setSetupStateKnown(true);
    }

    async function setup(token) {
        const response = await CDDV.setup(token, setVaultsLoaded);
        setConnected(response.connected);
        setSetupStateKnown(true);
        return response;
    }

    return (
        <div style={{ minHeight: '60vh', maxHeight: '75vh' }}>
            { !connected && (
                setupStateKnown
                    ? <CDDVLogin setup={setup} />
                    : <WorkingText text="Checking CDD Vault registration..." />
            )}
            { !!connected && children }
        </div>
    );
}

function ExternalLink({ text, style: styleIn={}, ...rest }) {
    const style = { color: 'var(--marketing-blue)', ...styleIn };
    return (
        <CommonExternalLink style={style} {...rest}>
            {text}
        </CommonExternalLink>
    );
}

function CDDVLink({ text='CDD Vault' }) {
    return (
        <ExternalLink link="https://collaborativedrug.com" text={text} />
    );
}

function WorkingText({ text, style }) {
    return (
        <div style={style}>
            { !!text && text}
            <span style={{ marginLeft: '.25em' }}><WorkingIndicator /></span>
        </div>
    );
}

function WarningText({ text, style }) {
    return (
        <div style={style}>
            <span style={{ marginRight: '.25em' }}><i className="fa fa-warning" /></span>
            { text }
        </div>
    );
}

function CDDVLogin({ setup }) {
    const [setupMessage, setSetupMessage] = useState('');
    function handleSubmit(formValues) {
        setSetupMessage('Connecting...');
        setup(formValues.token).then(({ connected, errMsg }) => {
            if (!connected) {
                setSetupMessage(<WarningText text={errMsg} />);
            }
        });
    }

    function handleValidateToken(token) {
        if (token === '') return 'Please enter your vault key';
        return undefined;
    }

    function handleValidate(formValues) {
        const errors = {};
        const val = handleValidateToken(formValues.token);
        if (val) errors.token = val;
        return errors;
    }

    return (
        <Formik
            initialValues={{ token: '' }}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validate={handleValidate}
        >
            <Form>
                <div>
                    <span style={{ fontSize: '150%', fontWeight: 'bold' }}>Connect to CDD Vault</span>
                </div>
                <div style={{ marginTop: '1em' }}>
                    <CDDVLink text="Learn more about CDD Vault and Collaborative Drug Discovery" />
                </div>
                <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                    If you have a CDD Vault account, you can:
                    <ul style={{ paddingLeft: '1em' }}>
                        <li><ExternalLink link="https://app.collaborativedrug.com/user/api_keys" text="Create an API key" /></li>
                        <li><ExternalLink link="https://support.collaborativedrug.com" text="Check out the knowledge base for more support" /></li>
                    </ul>
                </div>
                <TextField name="token" autoComplete="off" variant="outlined" label="CDD Vault API Key" validate={handleValidateToken} />
                <DialogSubmitButton>Log In</DialogSubmitButton>
                {!!setupMessage && <div style={{ marginTop: controlSpacing }}>{setupMessage}</div>}
            </Form>
        </Formik>
    );
}

const MarginedSelect = styled(Select)`
margin: ${controlSpacing} 0px;`;

function CDDVContentParent({ title='', children }) {
    const { vaultId, setVaultId } = useContext(CDDVContext);
    const CDDV = CDDVIntegration.getInstance();
    function handleVaultChange(event) {
        setVaultId(Number.parseInt(event.target.value, 10));
    }

    return (
        <div>
            <div>
                <span style={{ fontSize: '125%', fontWeight: 'bold' }}>{title}</span>
                <DisconnectButton />
            </div>
            <MarginedSelect label="Vault" onChange={handleVaultChange}>
                {CDDV.vaults.map(({ name, id }) => (
                    <option value={id} key={id}>{name}</option>
                ))}
            </MarginedSelect>
            {!!CDDV.getVault(vaultId)
                && (
                    <>
                        <ProjectDropdown />
                        {children}
                    </>
                )}
        </div>
    );
}

const DisconnectWarningDialog = styled(ConfirmationDialog)`
    .confirmation-dialog-yes-button {
        background-color: red;
        color: white;
    }`;

function DisconnectButton() {
    const CDDV = CDDVIntegration.getInstance();
    const { setConnected } = useContext(CDDVContext);
    const [dialogOpen, setDialog] = useState(false);
    const title = 'Delete CDD Vault API Key?';
    const content = (
        <>
            <p>
                Are you sure you want to delete your CDD Vault API Key?
                <br />
                You will not be able to browse or upload to CDD Vault.
            </p>
            <p style={{ marginTop: '1em' }}>
                This cannot be undone; please be sure you have access to another copy of your key.
            </p>
        </>
    );
    function clearSetup() {
        CDDV.clear_setup();
        setConnected(false);
    }
    function handleNo() {
        setDialog(false);
    }
    function handleYes() {
        clearSetup();
        setDialog(false);
    }
    function onClick() {
        setDialog(true);
    }
    return (
        <>
            <Button
                onClick={onClick}
                variant="outlined"
                size="small"
                style={{
                    float: 'right', color: 'red',
                }}
            >
                Disconnect from CDD Vault
            </Button>
            <DisconnectWarningDialog
                handleNo={handleNo}
                handleYes={handleYes}
                open={dialogOpen}
                title={title}
                content={content}
            />
        </>
    );
}

function ProjectDropdown() {
    const CDDV = CDDVIntegration.getInstance();
    const {
        vaultId, setProjectId, vaultsLoaded, includeSearches,
    } = useContext(CDDVContext);
    const vault = CDDV.getVault(vaultId);
    const labelString = `Project${(includeSearches) ? ' / Saved Search' : ''}`;
    function onChange(event) {
        setProjectId(Number.parseInt(event.target.value, 10));
    }
    if (!vaultsLoaded) {
        return (
            <>
                <MarginedSelect label={labelString} disabled onChange={onChange}>
                    <option value={0} key={0}>
                        Loading...
                    </option>
                </MarginedSelect>
                <br />
                <WorkingText text="Loading vaults..." />
            </>
        );
    } else if (vault.projects.length && !includeSearches) {
        return (
            <>
                <MarginedSelect label={labelString} onChange={onChange}>
                    {
                        vault.projects.map(
                            ({ name, id }) => (<option value={id} key={id}>{name}</option>)
                        )
                    }
                </MarginedSelect>
            </>
        );
    } else if (vault.projects.length || (vault.searches.length && includeSearches)) {
        return (
            <>
                <MarginedSelect label={labelString} onChange={onChange}>
                    {
                        vault.projects.map(
                            ({ name, id }) => (<option value={id} key={id}>{name}</option>)
                        )
                    }
                    {
                        vault.searches.map(
                            ({ name, id }) => (<option value={id} key={id}>{name}</option>)
                        )
                    }
                </MarginedSelect>
            </>
        );
    } else {
        return (
            <>
                <MarginedSelect label={labelString} disabled onChange={onChange}>
                    <option value={0} key={0}>No projects or searches loaded</option>
                </MarginedSelect>
            </>
        );
    }
}

// top is -24px here because parent element has 24px of padding; this lines up better
const PageControlsContainer = styled(Paper)`
position: sticky;
top: -24px;
width: 100%;
z-index: 1;
background: #F0F0F0;
padding-left: 0.8em;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
align-items: center;`;

function MoleculesDisplay({ closeImportPane }) {
    const CDDV = CDDVIntegration.getInstance();
    const [offset, setOffset] = useState(0);
    const [molsReady, updateMolsReady] = useState(false);
    const [downloadProblem, updateDownloadProblem] = useState(false);
    const { vaultId, projectId, vaultsLoaded } = useContext(CDDVContext);
    const project = CDDV.getVault(vaultId).getProject(projectId)
        || CDDV.getVault(vaultId).getSearch(projectId);
    useEffect(
        () => {
            setOffset(0);
        },
        [vaultId, vaultsLoaded, projectId]
    );
    if (!vaultsLoaded || !project) {
        return (
            <></>
        );
    }
    const finishCallback = (success = true) => {
        if (success) updateMolsReady(true);
        else updateDownloadProblem(true);
    };
    const countButtonStyle = (enabled) => (enabled ? { color: 'blue', fontWeight: 'bold' } : {});
    if (project.molsReady(offset) !== molsReady) updateMolsReady(project.molsReady(offset));
    const mols = (!downloadProblem && project.getMols(finishCallback, offset)) || [];
    const count = Math.max(project.count, 0);
    const prevEnabled = offset > 0;
    const nextEnabled = offset + 20 < count;
    const pageControls = (
        <PageControlsContainer elevation={0}>
            <div style={{ display: 'inline' }}>
                {`Molecules ${(count > 0) ? offset+1 : 0} to ${Math.min(offset+20, count)} of ${count}`}
            </div>
            <div style={{ display: 'inline' }}>
                <Button variant="text" onClick={() => setOffset(offset - 20)} style={countButtonStyle(prevEnabled)} disabled={!prevEnabled}>Previous</Button>
                <Button variant="text" onClick={() => setOffset(offset + 20)} style={countButtonStyle(nextEnabled)} disabled={!nextEnabled}>Next</Button>
            </div>
        </PageControlsContainer>
    );

    return (
        <div>
            {pageControls}
            {(!!project.molsReady(offset) && (mols.map((mol) => (
                <SingleMoleculeDisplay
                    mol={mol}
                    key={mol.name}
                    closeImportPane={closeImportPane}
                />
            )))) || (
                <>
                    {!downloadProblem && (
                        <WorkingText text="Fetching molecules..." />
                    )}
                    {!!downloadProblem && (
                        <>
                            <div>There was a problem fetching molecules from CDD vault.</div>
                            <Button variant="text" onClick={() => updateDownloadProblem(false)} style={countButtonStyle(true)}>Retry</Button>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

const EllipsisTitle = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    width: inherit;
    overflow: hidden;`;

function SingleMoleculeDisplay({ mol, closeImportPane }) {
    const CDDV = CDDVIntegration.getInstance();
    const [svgSrc, updateSvgSrc] = useState('');
    let name = mol.name;
    const haveBmapsData = mol.format === 'mol2';
    const have3d = (data, format) => {
        const isMol = format === 'mol' || format === 'sdf';
        return !is2dFormat(format) && !(isMol && molfileNeeds3D(data));
    };
    const have3dData = mol.format !== 'smi' && have3d(mol.molData, mol.format);
    const multiProtein = App.Workspace.getLoadedProteins().length > 1;

    if (mol.synonyms.length > 1) {
        name = mol.synonyms[0];
    }
    if (!svgSrc && !mol.svg) {
        const svgReq = {
            molData: mol.molfile ? mol.molfile : mol.smiles,
            format: mol.molfile ? 'mol' : 'smi',
            params: { baseBondColor: 0 }, // black
        };
        svgForMol(svgReq).then((svg) => {
            mol.svg = svg;
            updateSvgSrc(svgImgSrc(svg));
        });
    } else if (!svgSrc) {
        updateSvgSrc(svgImgSrc(mol.svg));
    }

    const loadMolecule = (align) => {
        let molData;
        let format;

        // If aligning to ligand, use 2D coordinates, which will do 3Dgen beforehand.
        // We could also use the BMaps 3D pose if available; however, that would start with a pose
        // specific to a given site.
        if (align) {
            if (mol.molfile) {
                molData = mol.molfile;
                format = 'mol';
            } else {
                // Note, if for some reason mol isn't available, BMaps may not be able to load
                // these smiles strings from CDD Vault. Many of the initial sample molecules in our
                // vault fail to load via smiles.
                molData = mol.smiles;
                format = 'smi';
            }
        } else {
            // If not aligning to ligand, use best available data, including BMaps 3D pose.
            molData = mol.molData;
            format = mol.format;
        }

        closeImportPane();
        CDDV.loadMolecule(
            mol.id,
            name,
            format,
            molData,
            align,
        );
    };

    const stageMolecule = (format) => {
        let data;
        let molFormat;
        if (format === mol.format) {
            data = mol.molData;
            molFormat = mol.format;
        } else {
            switch (format) {
                case 'smi': data = mol.smiles; break;
                case 'mol': data = mol.molfile; break;
                case 'inchi': data = mol.inchi; break;
                default: data = `Invalid CDD Vault format ${format}`;
            }
            molFormat = format;
        }
        const using3D = have3d(data, molFormat);

        const stageArgs = {
            placement: using3D ? 'Retain' : undefined,
            molSource: CDDV.molSourceForCDDV(mol.id, name, molFormat, data),
        };
        closeImportPane();
        UserActions.StageMoleculeImport(stageArgs);
    };

    return (
        <div className="cddv_molecule">
            <EllipsisTitle className="cddv_mol_title" title={name}>
                <ExternalLink link={`https://app.collaborativedrug.com/vaults/${mol.vaultId}/molecules/${mol.id}`} text={name} />
            </EllipsisTitle>
            <div className="cddv_mol_img">
                <img src={svgSrc} alt={mol.name} />
            </div>
            <div>
                <DialogSplitButton style={{ width: '100%' }}>
                    {!!haveBmapsData && <Button type="button" color="neutral" onClick={() => stageMolecule('mol2')} title="Stage molecule import based on uploaded coordinates, specifying position">Import BMaps Pose</Button>}
                    {!!mol.molfile && (
                        <Button type="button" color="neutral" onClick={() => stageMolecule('mol')} title="Stage import as mol data, specifying position">Import (mol)</Button>
                    )}
                    <Button type="button" color="neutral" onClick={() => stageMolecule('smi')} title="Stage import as smiles, specifying position">Import (smi)</Button>
                    {!!mol.inchi && (
                        <Button type="button" color="neutral" onClick={() => stageMolecule('inchi')} title="Stage import as InChI string, specifying position">Import (InChI)</Button>
                    )}
                    {!multiProtein && <Button type="button" color="neutral" onClick={() => loadMolecule(true)} title="Import directly, aligning to ligand">Import &amp; Align</Button>}
                    {!multiProtein && <Button type="button" color="neutral" onClick={() => loadMolecule(false)} disabled={!have3dData} title="Import directly, with existing 3D coordinates">Import 3D Coords.</Button>}
                </DialogSplitButton>
            </div>
        </div>
    );
}

function ExportPane({
    exportData, loading,
}) {
    const CDDV = CDDVIntegration.getInstance();
    const { vaultId, projectId } = useContext(CDDVContext);
    const [uploadStatus, setUploadStatus] = useState('');
    function handleSubmit(formValues) {
        setUploadStatus(<WorkingText text="Uploading to CDD Vault..." />);
        CDDV.upload_molecule(
            vaultId, projectId, formValues.name, exportData.smiles, exportData.data
        ).then(
            ({
                success, error, data, request,
            }) => {
                if (success) {
                    const molUrl = `https://app.collaborativedrug.com/vaults/${request.vaultId}/molecules/${data.molecule.id}`;
                    setUploadStatus(
                        <ExternalLink link={molUrl} text="Molecule successfully exported" />
                    );
                } else {
                    const errMsg = error || 'CDD Vault upload failed';
                    setUploadStatus(
                        <WarningText style={{ fontWeight: 'bold' }} text={errMsg} />
                    );
                }
            }
        );
    }

    function handleValidateName(value) {
        if (value === '') return 'Please enter a molecule name';
        else return undefined;
    }
    if (loading) {
        if (uploadStatus) setUploadStatus('');
        return (<WorkingText text="Preparing data..." />);
    }

    function handleValidate(formValues) {
        const errors = {};
        const val = handleValidateName(formValues.token);
        if (val) errors.name = val;
        return errors;
    }
    return (
        <>
            <Formik
                initialValues={{ name: exportData.name }}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                validate={handleValidate}
            >
                <Form>
                    <TextField
                        name="name"
                        autoComplete="off"
                        variant="outlined"
                        label="Molecule Name"
                        validate={handleValidateName}
                        style={{ marginTop: controlSpacing }}
                    />
                    <DialogSubmitButton>Upload Molecule to CDD Vault</DialogSubmitButton>
                </Form>
            </Formik>
            {!!uploadStatus && <div style={{ marginTop: controlSpacing }}>{uploadStatus}</div>}
        </>
    );
}
