import Menu from '@mui/material/Menu';

export default function CanvasContextMenu({
    mousePos, closeMenu, ...rest
}) {
    return (
        <Menu
            keepMounted
            open={mousePos.y !== null}
            onClose={closeMenu}
            disableAutoFocusItem
            anchorReference="anchorPosition"
            anchorPosition={
                mousePos.y !== null && mousePos.x !== null
                    ? { top: mousePos.y, left: mousePos.x }
                    : undefined
            }
            PaperProps={{
                onMouseLeave: closeMenu,
                style: {
                    background: 'unset',
                    padding: '20px',
                    boxShadow: 'none',
                },
            }}
            MenuListProps={{
                onMouseLeave: closeMenu,
                sx: {
                    // sx prop uses # as index into theme props
                    background: 'white',
                    borderRadius: 1, // = 4px
                    boxShadow: 8, // this is equivlent to ${theme.shadows[8]}
                },
            }}
            {...rest}
        />
    );
}
