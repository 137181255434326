import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

// Material UI
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';

// Local
import { createBlobURL } from '../../../ui_utils';
import { App } from '../../../../BMapsApp';
import { useColorSchemeInfo } from '../../../../redux/prefs/access';
import { ExportPaneContext } from '../ExportPane';
import { setBackgroundColor } from '../../../../MainCanvas';

export const IMAGE_TYPE = {
    '3D': '3D',
    '2D': '2D',
};

const ToggleButton = styled(Button).attrs((props) => ({
    variant: props.selected ? 'contained' : 'outlined',
}))`
    border: solid ${({ theme }) => theme.palette.primary.main } 1px;
    background-color: ${({ selected, theme }) => (selected ? theme.palette.primary.main : theme.palette.primary.contrastText)};
    color: ${({ selected, theme }) => (selected ? theme.palette.primary.contrastText : theme.palette.primary.main) };

    &:hover {
        background-color: ${({ selected, theme }) => (selected ? theme.palette.primary.main : theme.palette.primary.contrastText)};
    }
`;

export default function DownloadImageTab({ selected }) {
    const { isMobile } = useContext(ExportPaneContext);
    const [imageType, setImageType] = useState(IMAGE_TYPE['3D']);
    const [link3d, setLink3d] = useState(null);
    const [link2d, setLink2d] = useState(null);
    const colorSchemeInfo = useColorSchemeInfo();

    function handleDownload() {
        const link = document.querySelector('#export-pane_download-image-link');
        link.click();
    }

    async function canvasToBlobURL(canvas) {
        const blob = await new Promise((resolve) => {
            // defaults to an image/png type blob
            canvas.toBlob((blobData) => resolve(blobData));
        });
        return createBlobURL(blob);
    }

    function svgToBlobURL(svg) {
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        return createBlobURL(blob);
    }

    async function getDownload3dImageLink() {
        const downloadName = 'BMaps-Canvas.png';
        const mainCanvas = document.querySelector('#canvas_wrapper > canvas');
        if (!mainCanvas) {
            throw new Error("Couldn't find main canvas element! Check if id name has changed...");
        }

        const data = await withBackgroundColorTweak(
            () => canvasToBlobURL(mainCanvas), colorSchemeInfo
        );
        const imgTag = <img alt="3D Structure" style={{ maxWidth: '100%', height: isMobile ? '100%' : 'unset' }} src={`${data}`} />;
        const link = (
            <a id="export-pane_download-image-link" href={`${data}`} download={`${downloadName}`} target="_blank" rel="noreferrer">
                {imgTag}
            </a>
        );
        return link;
    }

    async function getDownload2dImageLink() {
        let downloadName = '';
        let imgData = '';
        const compound = App.Workspace.getActiveCompound();

        if (!compound) {
            return null;
        }

        const svgData = compound.getSvg();
        if (!svgData || svgData === 'failed') {
            downloadName = 'BMaps-2dCompound.png';
            // Unfortunate inconsistency here:
            // SmilesDrawer has a canvas with an id of activeCompoundId
            // 3DCanvasViewer has a canvas that is a child of #activeCompoundId
            const canvas = document.querySelector('canvas#activeCompound2d') || document.querySelector('#activeCompound2d canvas');
            imgData = canvas ? (await canvasToBlobURL(canvas)) : '';
        } else {
            downloadName = 'BMaps-2dCompound.svg';
            imgData = svgToBlobURL(svgData);
        }

        if (!imgData) {
            return null;
        }

        const imgTag = (
            <img
                alt="2D Structure"
                style={{
                    backgroundColor: `${ colorSchemeInfo.css }`,
                    maxWidth: '100%',
                    maxHeight: '98%',
                }}
                src={imgData}
            />
        );
        const link = (
            <a id="export-pane_download-image-link" href={`${imgData}`} download={`${downloadName}`} target="_blank" rel="noreferrer">
                {imgTag}
            </a>
        );
        return link;
    }

    useEffect(() => {
        if (selected) {
            (async () => {
                setLink3d(await getDownload3dImageLink());
                setLink2d(await getDownload2dImageLink());
            })();
        }
    }, [selected]);

    let imageLink = null;

    if (imageType === IMAGE_TYPE['3D'] && link3d !== null) {
        imageLink = link3d;
    } else if (link2d !== null) {
        imageLink = link2d;
    }

    return (
        <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid container item wrap="nowrap" justifyContent="center" alignItems="center">
                <ButtonGroup fullWidth={isMobile} style={{ marginRight: '1em' }}>
                    <ToggleButton selected={imageType === IMAGE_TYPE['3D']} onClick={() => setImageType(IMAGE_TYPE['3D'])}>
                        {isMobile ? '3D' : '3D Workspace Image' }
                    </ToggleButton>
                    <ToggleButton selected={imageType === IMAGE_TYPE['2D']} onClick={() => setImageType(IMAGE_TYPE['2D'])}>
                        {isMobile ? '2D' : '2D Workspace Image' }
                    </ToggleButton>
                </ButtonGroup>
                <IconButton disabled={!imageLink} onClick={handleDownload} size="large">
                    <GetAppIcon />
                </IconButton>
            </Grid>
            {imageLink ? (
                <Grid
                    item
                >
                    {imageLink}
                </Grid>
            ) : 'No image to display...'}
        </Grid>
    );
}

/**
 * Temporarily change the color of the 3D canvas before doing something.
 * Note: as written, this only works for the 3D image.
 * The 2D images are produced differently.
 * In normal operation, the 2D image is cached as an svg on the compound.
 * If that fails, the image is pulled from the 2D canvas, for which there isn't
 * an easy path to temporarily change the background color.
 * @param {*} doFn A function to do with the temporary background color. The return is passed along.
 * @param {object} originalColorSchemeInfo The original colorSchemeInfo which is used to determine
 * if a tweak is needed and how to restore the original color.
 */
function withBackgroundColorTweak(doFn, originalColorSchemeInfo) {
    // map color scheme names to the "tweaked" background color hex values
    const backgroundColorTweaks = { white: 0xffffff };
    const backgroundColorTweak = backgroundColorTweaks[originalColorSchemeInfo.name];
    if (backgroundColorTweak) {
        setBackgroundColor(backgroundColorTweak);
    }
    try {
        return doFn();
    } finally {
        if (backgroundColorTweak) {
            setBackgroundColor(originalColorSchemeInfo);
        }
    }
}
