import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog({
    handleYes, handleNo, open, title, content, noText='Cancel', yesText='Confirm', ...props
}) {
    return (
        <Dialog onClose={handleNo} open={open} {...props}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText component="div">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" className="confirmation-dialog-no-button" onClick={handleNo} autoFocus>{noText}</Button>
                <Button variant="contained" className="confirmation-dialog-yes-button" onClick={handleYes}>{yesText}</Button>
            </DialogActions>
        </Dialog>
    );
}
