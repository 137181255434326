/* eslint-disable camelcase */ // chembl fields have underscores in them

import { WebServices } from 'BMapsSrc/WebServices';

/**
 * Add chembl properties to a compound
 * @param {import('BMapsModel').Compound} compound
 * @param {*} trigger
 * @returns {{ value: string[], error: string }}
 */
export async function updateCompoundWithChemblId(compound, trigger, { allowTautomers }={}) {
    let propName = 'chembl_ids';
    let inchikey = compound.getProperty('structure2d', 'inchikey');
    if (inchikey && allowTautomers) {
        inchikey = inchikey.slice(0, 14); // Use first inchikey block
        propName = 'tautomer_chembl_ids';
    }

    const { value, error } = await compound.fetchProperty('chembl', propName, async () => {
        const { molecules, error: chemblErr } = inchikey
            ? await chemblLookupCompoundByInchikey(inchikey)
            : await chemblLookupCompoundBySmiles(compound.getUnnamedSmiles());
        return {
            value: molecules,
            error: chemblErr,
        };
    }, trigger);
    return { value, error };
}

/**
 * Add chembl properties to a mapCase
 * @param {import('BMapsModel').MapCase} mapCase
 * @param {*} trigger
 * @returns {{ value: string[], error: string }}
 */
export async function updateMapCaseWithChemblId(mapCase, trigger) {
    const { value, error } = await mapCase.fetchProperty('chembl', 'chembl_ids', async () => {
        const uniprotIds = mapCase.getProperty('uniprot', 'uniprot_ids');
        if (!uniprotIds) {
            return { error: 'No known UniProt IDs, needed for ChEMBL lookup' };
        }
        const { targets, error: chemblErr } = await chemblLookupByUniprotId(uniprotIds);
        const chemblIds = targets?.filter(({ target_components }) => (
            uniprotIds.every((uid) => target_components.some(({ accession }) => accession === uid))
            && target_components.every(({ accession }) => uniprotIds.includes(accession))
        )).map(({ target_chembl_id }) => target_chembl_id);
        return {
            value: chemblIds,
            error: chemblErr,
        };
    }, trigger);
    return { value, error };
}

/**
 * Lookup a compound in ChEMBL by inchikey or First Inchikey HashBlock (first 14 characters)
 * @param {string} inchikey
 * @returns { { moleculeIds: string[], error: string } }
 */
export async function chemblLookupCompoundByInchikey(inchikey) {
    return chemblLookupCompoundBy(inchikey, 'standard_inchi_key');
}

/**
 * Lookup a compound in ChEMBL by smiles (using chembl's flexmatch)
 * @param {string} smiles
 * @returns { { moleculeIds: string[], error: string } }
 */
export async function chemblLookupCompoundBySmiles(smiles) {
    return chemblLookupCompoundBy(smiles, 'canonical_smiles');
}

/**
 * Lookup a compound in ChEMBL by id and type
 * If smiles, use flexmatch query.
 * If first inchikey hashblock, use startswith query.
 * @param {string} id
 * @param {string} type
 * @returns { { moleculeIds: string[], error: string } }
 */
export async function chemblLookupCompoundBy(id, type) {
    let relation = 'exact';
    if (type === 'canonical_smiles') relation = 'flexmatch';
    if (type === 'standard_inchi_key' && id.length === 14) {
        relation = 'startswith'; // Use first inchikey block: FPKSFXFWECAIBR-UHFFFAOYSA-N
    }
    const url = `https://www.ebi.ac.uk/chembl/api/data/molecule?format=json&molecule_structures__${type}__${relation}=${encodeURIComponent(id)}`;
    let responseStr;
    let resp;
    try {
        responseStr = await WebServices.startWsRequest(url);
        resp = JSON.parse(responseStr);
    } catch (ex) {
        console.error(`Exception looking up compound ${id} in chembl: ${ex}`);
        return { error: `Failed to get chembl response for compound: ${id}` };
    }

    if (!resp.molecules) return { error: `No molecules field in ${responseStr}` };
    return { molecules: resp.molecules.map((m) => m.molecule_chembl_id) };
}

/**
 * Lookup Targets in ChEMBL by uniprotId
 * @param {string} uniprotId
 * @returns {{ targets: {
 *     pref_name: string,
 *     target_chembl_id: string,
 *     target_components: {
 *         accession: string,
 *         component_description: string,
 *         component_type: string,
 *         relationship: string
 *     }[]
 * }[], error: string }}
 */
export async function chemblLookupByUniprotId(uniprotId) {
    const url = `https://www.ebi.ac.uk/chembl/api/data/target?format=json&target_components__accession__in=${uniprotId}`;
    let responseStr;
    let resp;
    try {
        responseStr = await WebServices.startWsRequest(url);
        resp = JSON.parse(responseStr);
    } catch (ex) {
        console.error(`Exception looking up target ${uniprotId} in chembl: ${ex}`);
        return { error: `Failed to get chembl response for target: ${uniprotId}` };
    }

    if (!resp.targets) return { error: `No targets field in ${responseStr}` };
    return {
        targets: resp.targets.map(({ pref_name, target_chembl_id, target_components }) => ({
            pref_name,
            target_chembl_id,
            target_components: target_components.map(({
                accession, component_description, component_type, relationship,
            }) => ({
                accession, component_description, component_type, relationship,
            })),
        })),
    };
}

export async function chemblLookupActivity(targetChemblId, moleculeChemblId) {
    const url = `https://www.ebi.ac.uk/chembl/api/data/activity?format=json&target_chembl_id=${targetChemblId}&molecule_chembl_id=${moleculeChemblId}`;
    let responseStr;
    let resp;
    try {
        responseStr = await WebServices.startWsRequest(url);
        resp = JSON.parse(responseStr);
    } catch (ex) {
        console.error(`Exception looking up activity for ${targetChemblId}-${moleculeChemblId} in chembl: ${ex}`);
        return { error: `Failed to get chembl response for activity for ${targetChemblId} + ${moleculeChemblId}` };
    }

    if (!resp.activities) return { error: `No activities field in ${responseStr}` };
    const activityByAssay = {};
    for (const activity of resp.activities) {
        const {
            assay_chembl_id, type, relation, value, units,
        } = activity;
        if (!activityByAssay[assay_chembl_id]) activityByAssay[assay_chembl_id] = [];
        activityByAssay[assay_chembl_id].push(`${type} ${relation || ''} ${value || ''} ${units || ''}`);
    }
    return { activities: activityByAssay };
}
