import { AtomGroupTypes } from './model/atomgroups';

/**
 * residue molType codes in BSF data from bfd-server
 */
export const BfdMolTypeCodes = Object.freeze({
    small_molecule: 'small_molecule',
    ion: 'ion',
    cofactor: 'cofactor',
    macrocycle: 'macrocycle',
    peptide: 'peptide',
    modified_peptide: 'modified_peptide',
    ref_protein: 'ref_protein',
    ref_dna: 'ref_dna',
    ref_rna: 'ref_rna',
    micro_dna: 'micro_dna',
    micro_rna: 'micro_rna',
    antibody: 'antibody',
    polyketide: 'polyketide',
    lipid: 'lipid',
    saccharide: 'saccharide',
    carbohydrate: 'carbohydrate',
});

// List of molecule types along with user-readable name and AtomGroupType
// This is primarily used by AtomGroupFactory.CreatePolymers
export const MoleculeTypes = [
    {
        molType: BfdMolTypeCodes.small_molecule,
        name: 'Small Molecule',
        atomGroupType: AtomGroupTypes.Compound,
    },
    {
        molType: BfdMolTypeCodes.ion,
        name: 'Ion',
        atomGroupType: AtomGroupTypes.Ion,
    },
    {
        molType: BfdMolTypeCodes.cofactor,
        name: 'Cofactor',
        atomGroupType: AtomGroupTypes.Cofactor,
    },
    {
        molType: BfdMolTypeCodes.macrocycle,
        name: 'Macrocycle',
        atomGroupType: AtomGroupTypes.Compound,
    },
    {
        molType: BfdMolTypeCodes.peptide,
        name: 'Peptide',
        atomGroupType: AtomGroupTypes.PeptideLigand,
    },
    {
        molType: BfdMolTypeCodes.modified_peptide,
        name: 'Modified Peptide',
        atomGroupType: AtomGroupTypes.PeptideLigand,
    },
    {
        molType: BfdMolTypeCodes.ref_protein,
        name: 'Reference Protein',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.ref_dna,
        name: 'Reference DNA',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.ref_rna,
        name: 'Reference RNA',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.micro_dna,
        name: 'Micro DNA',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.micro_rna,
        name: 'Micro RNA',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.antibody,
        name: 'Antibody',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.polyketide,
        name: 'Polyketide',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.lipid,
        name: 'Lipid',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.saccharide,
        name: 'Saccharide',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: BfdMolTypeCodes.carbohydrate,
        name: 'Carbohydrate',
        atomGroupType: AtomGroupTypes.Polymer,
    },
    {
        molType: undefined,
        name: 'Protein',
        atomGroupType: AtomGroupTypes.Protein,
    },
    {
        molType: '',
        name: 'Protein',
        atomGroupType: AtomGroupTypes.Protein,
    },
];

const unknownMolType = {
    molType: 'unknown',
    name: 'Molecule',
    atomGroupType: AtomGroupTypes.Unknown,
};
MoleculeTypes.getTypeInfo = (molType) => (
    MoleculeTypes.find((x) => x.molType === molType) || unknownMolType
);
MoleculeTypes.stringify = (molType) => MoleculeTypes.getTypeInfo(molType).name;
