import {
    useState, useRef, useEffect, useMemo,
} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Mic from '@mui/icons-material/Mic';
import InfoIcon from '@mui/icons-material/Info';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';

import { App } from 'BMapsSrc/BMapsApp';
import { captureAndTranscribe } from 'BMapsSrc/util/speech_utils';
import { DialogSubmitButton, DialogCancelButton } from './common/DialogActionButtons';
import { WebServices } from '../WebServices';
import { WorkingIndicator } from './ui_utils';
import { loginUrlForMapCase } from '../connection_utils';
import BMapsTooltip from './BMapsTooltip';

const BotName = 'Gibbs';

export function ChatBot({ loggedIn, initialMessages: initialMsgs=[] }) {
    const initialMessages = useMemo(
        () => initialMsgs.map((msg, i) => ({ ...msg, id: i })),
        [initialMsgs]
    );
    const [input, setInput] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const [messages, setMessages] = useState(initialMessages);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recording, setRecording] = useState(false);

    useEffect(() => {
        if (messagesEndRef?.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [messages]);

    useEffect(() => {
        if (initialMessages.length > 0) {
            (async () => {
                try {
                    setIsLoading(true);
                    await submitMessages(initialMessages);
                } catch (sendError) {
                    console.error('Error making chat agent request:', sendError);
                    setError(sendError);
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [initialMessages]); // eslint-disable-line react-hooks/exhaustive-deps

    const myStructure = App.Workspace && App.Workspace.getLoadedProtein();
    const loginUrl = loginUrlForMapCase(myStructure);

    const addBotMessage = (content, idIn) => {
        setMessages((oldMessages) => {
            const id = idIn ?? oldMessages.length;
            const newMessage = { role: 'assistant', content, id };
            return [...oldMessages, newMessage];
        });
    };

    const handleSend = async (message=input) => {
        try {
            if (message.trim()) {
                setIsLoading(true);
                const userMessage = { role: 'user', content: message, id: messages.length };
                const updatedMessages = [...messages, userMessage];
                setMessages(updatedMessages);
                setInput('');
                await submitMessages(updatedMessages);
            }
        } catch (sendError) {
            console.error('Error making chat agent request:', sendError);
            setError(sendError);
        } finally {
            setIsLoading(false);
        }
    };

    const submitMessages = async (sendingMessages) => {
        const url = '/services/agents/chat/';
        const data = await WebServices.startWsRequestJson(
            url, { messages: sendingMessages }
        );
        const responseData = JSON.parse(data);
        if (responseData.error === '') {
            setError(null);
            addBotMessage(responseData.response, sendingMessages.length);
        } else {
            console.error(`ChatBot error: ${responseData.error}`);
            setError(responseData.error);
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleEnterSubmit = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleClearChat = () => {
        setError(null);
        setMessages([]);
    };

    const handleRegenerate = () => {
        const lastMessage = messages[messages.length - 1];
        const userText = lastMessage.content;
        setInput(userText);
        setError(null);
    };

    function displayChatMessage(inputText) {
        const renderer = new marked.Renderer();
        function convertLink(href, title, text) {
            const link = marked.Renderer.prototype.link.call(this, href, title, text);
            // Our ExternalLink also includes a rel="noreferrer" attribute, which excludes
            // the referrer (BMaps) from the request headers.
            // I chose not to add that here. If we decide to add it, we'll need to pass
            // an allowedAttributes option to sanitizeHtml, so rel isn't filtered out.
            return link.replace('<a ', '<a target="_blank" ');
        }
        renderer.link = convertLink;
        marked.setOptions({ renderer });
        return (
            <div
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(marked.parse(inputText)) }}
            />
        );
    }

    async function captureAndSend() {
        setRecording(true);
        const recorder = await captureAndTranscribe({
            setMediaRecorder,
            handleTranscription: async (words) => {
                if (words != null && words.length > 0) {
                    setInput(words);
                    handleSend(words);
                } else {
                    addBotMessage("If you just said something, I couldn't make it out. Please try again.");
                }
            },
        });
        recorder.onstop = () => setRecording(false);
        setMediaRecorder(recorder);
    }

    async function stopRecording() {
        mediaRecorder.stop();
    }

    return (
        <div style={{
            padding: '1rem',
            paddingTop: '0.5rem',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            minHeight: '300px',
            flexGrow: 1,
            borderTop: '2px solid lightgray',
        }}
        >
            <Typography style={{ fontWeight: 'bold', fontSize: 'smaller' }}>
                Chat with
                {' '}
                {BotName}
                , our AI assistant:
            </Typography>
            <Typography style={{ fontSize: 'small', marginBottom: '0.5rem' }}>
                Please be aware responses from
                {' '}
                {BotName}
                {' '}
                may not always be accurate.
                Use discretion when interpreting results.
            </Typography>
            <div style={{
                flexGrow: '1',
                overflow: 'auto',
                padding: '0.5rem',
                borderTop: '1px solid lightgray',
                borderLeft: '1px solid lightgray',
                borderRight: '1px solid lightgray',
                borderBottom: 'none',
                borderRadius: '4px 4px 0 0',
                background: 'whitesmoke',
            }}
            >
                {messages.map((message, messageI) => (
                    <Box
                        key={`message_${messageI.toString()}`}
                        ref={message.id === messages.length - 1 ? messagesEndRef : null}
                        style={{
                            marginBottom: '2px',
                            padding: '2px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: message.role === 'user' ? 'flex-end' : 'flex-start',
                        }}
                    >
                        <strong>{message.role === 'user' ? 'You: ' : `${BotName}: `}</strong>
                        <Box sx={{
                            border: '1px solid lightgray',
                            borderRadius: '.3rem',
                            maxWidth: '90%',
                            wordWrap: 'break-word',
                            background: message.role === 'user' ? 'white' : 'mintcream',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                            '& li': {
                                marginLeft: '1rem',
                            },
                            '& div': {
                                padding: '.3rem',
                            },
                            '& pre': {
                                whiteSpace: 'pre-wrap',
                            },
                        }}
                        >
                            {displayChatMessage(message.content)}
                        </Box>
                    </Box>
                ))}
                <Box>
                    {isLoading && (
                        <Box>
                            <WorkingIndicator />
                        </Box>
                    )}
                </Box>
                {error && <ChatBotErrorDisplay error={error} onTryAgain={handleRegenerate} />}
                <Box>
                    {!loggedIn && (
                        <Typography style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: 'smaller' }}>
                            Please
                            {' '}
                            <a href={loginUrl}>Log in</a>
                            {' '}
                            to interact with
                            {' '}
                            {BotName}
                            .
                        </Typography>
                    )}
                </Box>
            </div>
            <TextField
                style={{
                    marginBottom: '0.5rem',
                    width: '100%',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    borderLeft: '1px solid lightgray',
                    borderRight: '1px solid lightgray',
                    borderRadius: '0 0 4px 4px',
                    background: 'whitesmoke',
                }}
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleEnterSubmit}
                placeholder="Enter message..."
                multiline
                maxRows={6}
                size="small"
                disabled={!loggedIn}
                InputProps={{
                    sx: {
                        background: 'white',
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={recording ? stopRecording : captureAndSend}
                                style={{
                                    color: recording ? 'green' : undefined,
                                    padding: '2px 4px',
                                    height: 'fit-content',
                                    width: 'fit-content',
                                    display: 'flex',
                                }}
                                title={recording ? 'Disable voice control' : 'Enable voice control'}
                                disabled={!loggedIn}
                            >
                                <Mic />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogSubmitButton
                    onClick={() => handleSend()}
                    disabled={!loggedIn}
                >
                    Send
                </DialogSubmitButton>
                <DialogCancelButton
                    onClick={handleClearChat}
                    disabled={!loggedIn}
                >
                    Clear chat
                </DialogCancelButton>
            </div>
        </div>
    );
}

function ChatBotErrorDisplay({ error, onTryAgain }) {
    const errorRef = useRef(null);
    useEffect(() => {
        if (errorRef?.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [error]);

    return (
        <Box ref={errorRef}>
            <strong>
                {`${BotName}: `}
            </strong>
            <BMapsTooltip title={`Error detail: ${error}`}>
                <div style={{ fontStyle: 'italic', color: 'red' }}>
                    Something went wrong...
                    {' '}
                    <InfoIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                </div>
            </BMapsTooltip>
            <DialogCancelButton style={{ paddingTop: 0, paddingBottom: 0 }} fontSize="smaller" type="button" onClick={onTryAgain}>Try again</DialogCancelButton>
        </Box>
    );
}
