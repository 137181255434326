import React, { useState, useEffect, useRef } from 'react';
import { ListTable } from '@Conifer-Point/px-components';
import { UserActions } from 'BMapsCmds';
import { App } from '../../BMapsApp';
import { EventBroker } from '../../eventbroker';
import { ModalManager } from '../ModalManager';
import { FragservData } from '../../model/FragmentData';
import { ClearableTextInput, EnergySlider } from '../UIComponents';

EventBroker.subscribe('openFragmentManager', (_, args) => FragmentManager.Show(args));

export class FragmentManager extends React.PureComponent {
    static Show({ initialMode }) {
        ModalManager.Show({
            title: 'Fragment Manager',
            content: <FragmentManager
                workspace={App.Workspace}
                initialMode={initialMode}
            />,
            style: { width: '90vw', maxWidth: 'unset', height: '80%' }, // Copied from TheManual
        });
    }

    static get DefaultSortCol() { return 'name'; }
    static get DefaultSortAscending() { return true; }
    static get DefaultVisibleColumns() {
        return [
            'fragset', 'library', 'minBValue', 'MW', 'HAC', 'HBD', 'HBA',
        ];
    }

    static QuickSelect({ workspace, onChange, style={} }) {
        const fragmentData = workspace.fragmentData;
        const fragmentSets = fragmentData.fragservData.getFragsets();
        const libraries = fragmentData.fragservData.getLibraries();
        const placeholder = 'Select by fragment set or library...';
        return (
            <div className="fragmentmanager_quickselect" style={style}>
                <label htmlFor="fragmentmanager-quickselect__select">
                    <select
                        id="fragmentmanager-quickselect__select"
                        value={placeholder}
                        onChange={(e) => {
                            const selected = e.target.value;
                            const parsed = selected.match(/^([a-zA-Z]+)-(.+)$/);
                            const [_, column, name] = parsed || [];
                            console.log(`Selecting all from ${column} ${name}`);
                            if (name && Object.keys(FragmentManager.Columns).includes(column)) {
                                onChange(column, { selected: [name] });
                            }
                        }}
                    >
                        <option value="" style={{ display: 'none' }}>{placeholder}</option>
                        {
                            fragmentSets.map((x) => {
                                const key = `${FragmentManager.Columns.fragset.id}-${x.name}`;
                                return (
                                    <option key={key} value={key}>
                                        Frag. Set:
                                        {x.name}
                                    </option>
                                );
                            })
                        }
                        {
                            libraries.map((x) => {
                                const key = `${FragmentManager.Columns.library.id}-${x.shortName}`;
                                return (
                                    <option key={key} value={key}>
                                        Frag. Library:
                                        {x.shortName}
                                    </option>
                                );
                            })
                        }
                    </select>
                </label>
            </div>
        );
    }

    static Subsection({ title, indent, children }) {
        // Default to
        let finalIndent = indent;
        if (indent == null) finalIndent = !!title;

        const subsectionStyle = {
            marginTop: '.5em',
            marginBottom: '.5em',
        };
        const titleStyle = {
            fontSize: 'smaller',
            fontVariantCaps: 'all-small-caps',
            color: 'var(--marketing-grey)',
        };
        const parentStyle = finalIndent
            ? { paddingLeft: '1em', paddingRight: '1em' }
            : {};

        return (
            <div style={subsectionStyle}>
                {!!title && <div style={titleStyle}>{title}</div>}
                <div style={parentStyle}>
                    {children}
                </div>
            </div>
        );
    }

    static fragmentCaseDatas() {
        return App.Workspace.allCaseData()
            .filter((cd) => cd.getAvailableFragmentInfo().items().length > 0);
    }

    constructor(props) {
        super(props);
        this.state = {
            sortCol: FragmentManager.DefaultSortCol,
            sortAscending: FragmentManager.DefaultSortAscending,
            mode: props.initialMode || FragmentManager.Modes.all,
            searchFilter: '',
            items: [],
            filters: {},
            editingFilterColumn: null,
            visibleColumns: FragmentManager.DefaultVisibleColumns,
            editingColumns: false,
            selected: [],
            caseData: FragmentManager.fragmentCaseDatas()[0],
        };
        this.state.items = this.items();

        this.renderColumn = this.renderColumn.bind(this);
        this.changeSort = this.changeSort.bind(this);
        this.toggleSearchable = this.toggleSearchable.bind(this);
        this.toggleClustering = this.toggleClustering.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.clickFilter = this.clickFilter.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.clearAllFilters = this.clearAllFilters.bind(this);
        this.clickChooseColumns = this.clickChooseColumns.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.setMode = this.setMode.bind(this);
        this.filterName = this.filterName.bind(this);
        this.rowClassName = this.rowClassName.bind(this);
        this.massUpdate = this.massUpdate.bind(this);

        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('hotspotsChanged', this.refresh);
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('hotspotsChanged', this.refresh);
    }

    setMode(modeOrig) {
        let mode = modeOrig;
        if (mode.target) {
            mode = mode.target.dataset['mode'];
        }
        if (Object.keys(FragmentManager.Modes).includes(mode)) {
            this.setState({
                mode,
                items: this.items({ mode }),
            });
        } else {
            console.warn(`FragmentManager: unknown mode: ${mode}`);
        }
    }

    refresh() {
        this.forceUpdate();
    }

    changeSort(evt) {
        const column = evt.currentTarget.dataset.column;
        this.setState((oldState) => {
            const sortCol = column;
            const sortAscending = oldState.sortCol === column
                ? !oldState.sortAscending
                : oldState.sortAscending;
            return {
                sortCol,
                sortAscending,
                items: this.items({ sortCol, sortAscending }),
            };
        });
    }

    toggleSearchable(evt) {
        evt.currentTarget.blur();
        // Index from the table includes the header row,
        // so need to subtract 1 to get index into items
        const { items } = this.state;
        const index = evt.currentTarget.dataset.index - 1;
        const fragInfo = items[index].fragInfo;
        UserActions.ToggleActiveFragment(fragInfo);
        this.forceUpdate();
    }

    toggleClustering(evt) {
        evt.currentTarget.blur();
        // Index from the table includes the header row,
        // so need to subtract 1 to get index into items
        const { items } = this.state;
        const index = evt.currentTarget.dataset.index - 1;
        const fragInfo = items[index].fragInfo;
        UserActions.ToggleClusteringFragment(fragInfo);
        this.forceUpdate();
    }

    toggleSelected(evt) {
        evt.currentTarget.blur();
        // Index from the table includes the header row,
        // so need to subtract 1 to get index into items.
        const { items } = this.state;
        const index = evt.currentTarget.dataset.index - 1;
        const fragInfo = items[index].fragInfo;
        this.setState((oldState) => {
            const selectedArray = oldState.selected;
            const selectedIndex = selectedArray.indexOf(fragInfo);
            if (selectedIndex > -1) {
                selectedArray.splice(selectedIndex, 1);
            } else {
                selectedArray.push(fragInfo);
            }

            return {
                selected: [...selectedArray],
            };
        });
    }

    selectAll() {
        this.setState((oldState) => {
            if (oldState.selected.length !== oldState.items.length) {
                return { selected: oldState.items.map((x) => x.fragInfo) };
            } else {
                return { selected: [] };
            }
        });
    }

    massUpdate(evt) {
        const data = evt.target.selectedOptions[0].dataset;
        const { selected, items } = this.state;
        const { column, group, value: rawValue } = data;
        const value = rawValue === 'true';
        let collection = [];
        switch (group) {
            case 'selected': collection = selected; break;
            case 'visible': collection = items.map((x) => x.fragInfo); break;
            case 'selected+visible': collection = this.selectedAndVisibleItems(); break;
            default:
                console.warn(`FragmentManager mass update: unknown target group: ${group}`);
        }

        if (column === 'searchable') {
            UserActions.ToggleActiveFragment(collection, value);
        } else if (column === 'usedForHotspots') {
            UserActions.ToggleClusteringFragment(collection, value);
        } else {
            console.warn(`FragmentManager massUpdate: Unknown column ${column}`);
        }
        this.forceUpdate();
    }

    updateSearch(searchFilter) {
        this.setState({
            searchFilter,
            items: this.items({ searchFilter }),
        });
    }

    clickFilter(evt) {
        const column = evt.currentTarget.dataset.column;
        this.setState((oldState) => {
            const editing = oldState.editingFilterColumn === column ? true : column;
            return { editingFilterColumn: editing };
        });
    }

    applyFilter(column, state) {
        if (column) {
            this.setState((oldState) => {
                const filters = { ...oldState.filters };
                if (state && Object.values(state).find((x) => x)) {
                    filters[column] = {};
                    for (const [k, value] of Object.entries(state)) {
                        if (value) filters[column][k] = value;
                    }
                } else if (filters[column]) {
                    delete filters[column];
                }
                return {
                    filters: { ...filters },
                    items: this.items({ filters }),
                    editingFilterColumn: null,
                };
            });
        } else {
            this.setState({ editingFilterColumn: null });
        }
    }

    clearAllFilters() {
        const filters = {};
        const searchFilter = '';
        this.setState({
            searchFilter,
            filters,
            items: this.items({ searchFilter, filters }),
        });
    }

    clickChooseColumns() {
        this.setState((oldState) => ({ editingColumns: !oldState.editingColumns }));
    }

    updateColumns(visibleColumns) {
        this.setState({ visibleColumns, editingColumns: false });
    }

    items({
        /* eslint-disable react/destructuring-assignment */
        sortCol=this.state.sortCol,
        sortAscending=this.state.sortAscending,
        searchFilter=this.state.searchFilter,
        filters=this.state.filters,
        mode=this.state.mode,
        caseData=this.state.caseData,
        /* eslint-enable react/destructuring-assignment */
    }={}) {
        const { workspace } = this.props;
        const fragservData = workspace.fragmentData.fragservData;

        if (!caseData) {
            return [];
        }

        const availableFragmentInfo = caseData.getAvailableFragmentInfo();
        const getItem = (fragInfo) => ({
            name: fragInfo.name,
            fragInfo,
            otherInfo: fragservData.fragInfoByFragName(fragInfo.name),
        });

        let items = availableFragmentInfo.items().map((x) => getItem(x));

        if (mode === FragmentManager.Modes.search) {
            items = items.filter((x) => workspace.isActive(x.fragInfo));
        } else if (mode === FragmentManager.Modes.hotspot) {
            items = items.filter((x) => workspace.getAtomGroupState(x.fragInfo, 'usedForHotspots'));
        }

        if (searchFilter) {
            const search = searchFilter.toUpperCase();

            items = items.filter((x) => {
                for (const col of Object.keys(FragmentManager.Columns)) {
                    if (FragmentManager.Columns[col].textSearchable) {
                        const display = this.columnDisplay(x, col);
                        if (display.toString().toUpperCase().indexOf(search) > -1) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }

        items = items.filter((item) => {
            for (const filterCol of Object.keys(filters)) {
                const {
                    filter, min, max, selected, excludeOn, excludeOff,
                } = filters[filterCol];
                const colValue = this.columnValue(item, filterCol);
                const display = this.columnDisplay(item, filterCol);
                switch (FragmentManager.Columns[filterCol].type) {
                    case 'string':
                        if (filter && display.toUpperCase().indexOf(filter.toUpperCase()) === -1) {
                            return false;
                        }
                        break;
                    case 'stringarray':
                        if (selected && selected.length > 0
                            && colValue.filter((x) => selected.includes(x)).length === 0) {
                            return false;
                        }
                        break;
                    case 'int': {
                        const minValue = min && Number(min);
                        const maxValue = max && Number(max);
                        if (minValue != null && minValue > colValue) {
                            return false;
                        }
                        if (maxValue != null && maxValue < colValue) {
                            return false;
                        }
                        break;
                    }
                    case 'float': {
                        const minValue = min && Number(min);
                        const maxValue = max && Number(max);
                        if (minValue != null && minValue > colValue) {
                            return false;
                        }
                        if (maxValue != null && maxValue < colValue) {
                            return false;
                        }
                        break;
                    }
                    case 'bool':
                        if (excludeOn && colValue) {
                            return false;
                        }
                        if (excludeOff && !colValue) {
                            return false;
                        }
                        break;
                    default:
                        console.warn(`Fragment manager: unknown column filter type: ${FragmentManager.Columns[filterCol].type}`);
                }
            }
            return true;
        });

        const direction = sortAscending ? 1 : -1;
        items.sort((a, b) => {
            const aSorting = this.columnValue(a, sortCol);
            const bSorting = this.columnValue(b, sortCol);
            switch (sortCol) {
                default:
                    if (aSorting === bSorting) {
                        return 0;
                    }
                    return (aSorting < bSorting ? -1 : 1) * direction;
            }
        });
        return items;
    }

    sectionInfo(mode) {
        switch (mode) {
            case FragmentManager.Modes.all:
                return false;
            case FragmentManager.Modes.search:
                return false;
            case FragmentManager.Modes.hotspot: {
                return this.hotspotDisplay();
            }
            default:
                return false;
        }
    }

    hotspotDisplay() {
        const { workspace } = this.props;
        const { caseData } = this.state;
        const { hotspotThreshold, fragmentData } = workspace;
        const fragList = caseData.getAvailableFragmentInfo();
        const hotspots = caseData.getHotspots();
        const haveHotspots = hotspots.length > 0;
        const defaultHotspotFragments = fragmentData.getAvailableClusteringFragments(fragList);
        const offerDefaultHotspots = !haveHotspots && defaultHotspotFragments.length > 0;
        const { min, max, included } = hotspots.reduce((acc, next) => ({
            min: Math.min(acc.min, next.exchemPotentialAvg),
            max: Math.max(acc.max, next.exchemPotentialAvg),
            included: next.exchemPotentialAvg < hotspotThreshold
                ? acc.included.concat(next)
                : acc.included,
        }), {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            included: [],
        });

        if (haveHotspots) {
            return (
                <div>
                    {/* <FragmentManager.Subsection title="About">
                        Hotspots are sites on a structure where the clustering of
                        certain simulated fragments suggests favorable bioactivity.<br/>
                        The "Hotspot fragments" are the fragments used for the cluster analysis.
                    </FragmentManager.Subsection> */}

                    <FragmentManager.Subsection>
                        {hotspots.length}
                        {' '}
                        total hotspots identified by cluster
                        analysis on the Hotspot Fragments below.
                        <div>
                            {' '}
                            {included.length}
                            {' '}
                            {included.length === 1 ? 'hotspot meets ' : 'hotspots meet '}
                            the average energy threshold for inclusion in the workspace.
                        </div>
                    </FragmentManager.Subsection>

                    <FragmentManager.Subsection>
                        <div style={{ display: 'flex' }}>
                            <span>Energy threshold: </span>
                            <span style={{ marginLeft: '.25em' }}>
                                {' '}
                                {hotspotThreshold.toFixed(1)}
                                {' '}
                                kcal/mol
                            </span>
                            <EnergySlider
                                style={{ flex: '1 1 auto', maxWidth: '20em' }}
                                max={0}
                                min={-12}
                                step={0.5}
                                value={hotspotThreshold}
                                onChange={(val) => UserActions.ChangeHotspotThreshold(val)}
                            />
                        </div>
                    </FragmentManager.Subsection>
                </div>
            );
        } else {
            return (
                <div>
                    <div>There are no hotspots</div>
                    {(offerDefaultHotspots
                        && (
                        <div>
                            <input
                                className="blueTextButton"
                                type="button"
                                value="Default hotspot analysis"
                                title="View all fragments"
                                onClick={
                                    () => UserActions.ToggleClusteringFragment(
                                        defaultHotspotFragments, true
                                    )
                                }
                            />
                        </div>
                        )
                    )}
                </div>
            );
        }
    }

    activeFilters() {
        const { filters, searchFilter } = this.state;
        const ret = Object.keys(filters);
        if (searchFilter) ret.push('quick_search');
        return ret.map(this.filterName);
    }

    selectedAndVisibleItems() {
        const { items, selected } = this.state;
        return items.map((x) => x.fragInfo).filter((y) => selected.includes(y));
    }

    columnName(col) {
        const selectedIcon = 'fa fa-square-o';
        switch (col) {
            case 'selected': return <i className={selectedIcon} />;
            case 'name': return 'Fragment Name';
            case 'fragset': return 'Fragment Set';
            case 'library': return 'Fragment Library';
            case 'searchable': return 'Searchable';
            case 'usedForHotspots': return 'In Hotspots';
            case 'minBValue': return 'Min. B-Value';
            case 'charge': return 'Charge';
            case 'TPSA': return 'PSA';
            default: return col;
        }
    }

    rowClassName(item) {
        const { selected } = this.state;
        if (selected.includes(item.fragInfo)) {
            return 'fragmanager_row selected';
        } else {
            return 'fragmanager_row';
        }
    }

    filterName(filter) {
        if (filter === 'quick_search') return 'Quick Search';
        return this.columnName(filter);
    }

    modeName(mode) {
        switch (mode) {
            case 'all': return 'Simulated';
            case 'search': return 'Search';
            case 'hotspot': return 'Hotspot';
            default: return '';
        }
    }

    columnTooltip(col) {
        switch (col) {
            case 'name': return 'Fragment Name';
            case 'fragset': return 'Fragment Set';
            case 'library': return 'Fragment Library';
            case 'searchable': return 'Is the fragment ready for searching and growing?';
            case 'minBValue': return 'Minimum B-Value from simulation';
            case 'charge': return 'Charge';
            case 'MW': return 'Molecular Weight';
            case 'HAC': return 'Heavy Atom Count';
            case 'LogP': return 'XLogP (OpenBabel calculation)';
            case 'TPSA': return 'Topological polar surface area';
            case 'HBA': return 'Hydrogen bond acceptors';
            case 'HBD': return 'Hydrogen bond donors';
            case 'RBC': return 'Rotatable bond count';
            default: return col;
        }
    }

    columnValue({ fragInfo, otherInfo, name }, col) {
        const { selected } = this.state;
        const { workspace } = this.props;
        const mainInfo = otherInfo[0];

        switch (col) {
            case 'name':
                return name;
            case 'library': {
                const set = new Set();
                for (const frag of otherInfo) {
                    set.add(frag.fragment.library.shortName);
                }
                return [...set.values()];
            }
            case 'fragset': {
                const set = new Set();
                for (const frag of otherInfo) {
                    if (frag.fragset) {
                        set.add(frag.fragset.name);
                    }
                }
                return [...set.values()];
            }
            case 'searchable':
                return workspace.isActive(fragInfo);
            case 'usedForHotspots':
                return workspace.getAtomGroupState(fragInfo, 'usedForHotspots');
            case 'minBValue':
                return fragInfo.minBValue;
            case 'selected':
                return selected.includes(fragInfo);
            default:
                if (FragservData.MolPropNames.includes(col)) {
                    return mainInfo ? mainInfo.fragment.molProps[col] : 'N/A';
                } else {
                    return `${name} ${col}`;
                }
        }
    }

    updateCaseData(caseData) {
        this.setState({
            caseData,
            items: this.items({ caseData }),
        });
    }

    caseDataPicker(selectedCaseData) {
        const cases = FragmentManager.fragmentCaseDatas();
        const value = selectedCaseData ? cases.indexOf(selectedCaseData) : 0;
        const getLabel = (caseData) => {
            const label = caseData.getShortName();
            const sameNames = cases.filter((cd) => cd.getShortName() === label);
            if (sameNames.length === 1) return label;
            const mySuffix = sameNames.indexOf(caseData) + 1;
            return `${label} (${mySuffix})`;
        };
        return (
            <div>
                Case:
                {' '}
                <select
                    value={value}
                    onChange={(elt) => this.updateCaseData(cases[elt.target.value])}
                >
                    { cases.map((cd, index) => (
                        <option
                            value={index}
                            key={`allcases_${index.toString()}`}
                        >
                            {getLabel(cd)}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

    columnDisplay(item, col) {
        const value = this.columnValue(item, col);
        switch (col) {
            case 'searchable':
            case 'usedForHotspots': {
                const icon = value ? 'fa fa-check-square-o' : 'fa fa-check-square-o';
                const style = value ? { color: 'steelblue' } : { color: 'lightgrey' };
                return <i className={icon} style={style} />;
            }
            case 'selected': {
                const icon = value ? 'fa fa-check-square-o' : 'fa fa-square-o';
                return <i className={icon} />;
            }
            default:
                if (FragmentManager.Columns[col].type === 'float' && value !== 'N/A') {
                    return value.toFixed(4);
                } else if (FragmentManager.Columns[col].type === 'stringarray') {
                    const main = value[0];
                    const more = value.length - 1;
                    return main ? `${main}${more > 0 ? ` + ${more}` : ''}` : `Not in ${this.columnName(col)}`;
                }
                return value;
        }
    }

    renderColumnChooser() {
        const { visibleColumns } = this.state;
        const submit = (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            const checkboxes = [...evt.target];
            const newVisible = checkboxes.reduce((collectingNames, nextCheckbox) => {
                if (nextCheckbox.checked) {
                    collectingNames.push(nextCheckbox.name);
                }
                return collectingNames;
            }, []);
            this.updateColumns(newVisible);
        };
        return (
            <div>
                <form className="filtermenu" onSubmit={submit}>
                    <div style={{ paddingBottom: '.4em' }}>Choose columns to display</div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {Object.values(
                            FragmentManager.Columns
                        ).filter((x) => !x.required).map((col) => {
                            const colId = col.id;
                            const columnName = this.columnName(colId);
                            const inputId = `filtermenu__${columnName}-checkbox`;
                            return (
                                <label
                                    htmlFor={inputId}
                                    key={col.id}
                                    title={this.columnTooltip(colId)}
                                >
                                    <input
                                        id={inputId}
                                        type="checkbox"
                                        name={colId}
                                        defaultChecked={visibleColumns.includes(colId)}
                                        style={{ marginRight: '1em' }}
                                    />
                                    {columnName}
                                </label>
                            );
                        })}
                    </div>
                    <div className="filtermenu_buttons" style={{ justifyContent: 'space-evenly', height: '4em' }}>
                        <input type="submit" value="Apply" />
                        <input type="button" value="Cancel" onClick={this.clickChooseColumns} />
                    </div>
                </form>
            </div>
        );
    }

    renderColumn(item, index, column) {
        if (item.header) {
            return this.renderHeaderColumn(index, column);
        } else {
            switch (column) {
                case 'searchable': {
                    return (
                        <ListTable.Column
                            key={column}
                            className={`fragmanager_${column} fragmanager_data`}
                            data-index={index}
                        >
                            <button type="button" className="checkboxButton" onClick={this.toggleSearchable} data-index={index}>{this.columnDisplay(item, column)}</button>
                        </ListTable.Column>
                    );
                }
                case 'usedForHotspots': {
                    return (
                        <ListTable.Column
                            key={column}
                            className={`fragmanager_${column} fragmanager_data`}
                            data-index={index}
                        >
                            <button type="button" className="checkboxButton" onClick={this.toggleClustering} data-index={index}>{this.columnDisplay(item, column)}</button>
                        </ListTable.Column>
                    );
                }
                case 'selected': {
                    return (
                        <ListTable.Column
                            key={column}
                            className={`fragmanager_${column} fragmanager_data`}
                            data-index={index}
                        >
                            <button type="button" className="checkboxButton" onClick={this.toggleSelected} data-index={index}>{this.columnDisplay(item, column)}</button>
                        </ListTable.Column>
                    );
                }
                default:
                    return (
                        <ListTable.Column
                            key={column}
                            className={`fragmanager_${column} fragmanager_data`}
                            data-index={index}
                            onClick={this.toggleSelected}
                        >
                            <span>{this.columnDisplay(item, column)}</span>
                        </ListTable.Column>
                    );
            }
        }
    }

    renderHeaderColumn(index, column) {
        const {
            sortCol, sortAscending, filters, editingFilterColumn, caseData,
        } = this.state;
        const { workspace } = this.props;
        const columnName = this.columnName(column);
        let sortIcon = false;
        if (sortCol === column) {
            const sortIconClass = (sortAscending)
                ? 'fa fa-caret-up'
                : 'fa fa-caret-down';
            sortIcon = <i className={sortIconClass} />;
        }

        const filterActive = filters[column];
        const filterClass = filterActive ? 'active' : 'inactive';
        const filterIcon = (
            <button
                type="button"
                className={`fragmanager_filterbtn ${filterClass}`}
                data-column={column}
                onClick={this.clickFilter}
                title={`Filter by ${columnName}`}
            >
                <i className="fa fa-filter" />
            </button>
        );

        const editingFilter = editingFilterColumn === column;
        const availableFragmentInfo = caseData.getAvailableFragmentInfo().items();
        const fragservData = workspace.fragmentData.fragservData;
        const otherInfo = availableFragmentInfo.map((x) => fragservData.fragInfoByFragName(x.name));
        let filterData = null;
        if (column === 'library') {
            const set = new Set();
            for (const entries of otherInfo) {
                for (const { fragment } of entries) {
                    set.add(fragment.library.shortName);
                }
            }
            filterData = [...set.values()];
        } else if (column === 'fragset') {
            const set = new Set();
            for (const entries of otherInfo) {
                for (const { fragset } of entries) {
                    if (fragset) {
                        set.add(fragset.name);
                    }
                }
            }
            filterData = [...set.values()];
        }
        return (
            <React.Fragment key={column}>
                <ListTable.Column
                    key={column}
                    className={`fragmanager_${column} fragmanager_data fragmanager_header`}
                    data-index={index}
                    data-column={column}
                    onClick={this.changeSort}
                >
                    <span title={this.columnTooltip(column)}>
                        {columnName}
                        &nbsp;
                        {sortIcon}
                    </span>
                    { editingFilter
                        && (
                        <FilterMenu
                            column={column}
                            data={filterData}
                            initialState={filters[column]}
                            onChange={this.applyFilter}
                        />
                        )}
                </ListTable.Column>
                {filterIcon}
            </React.Fragment>
        );
    }

    render() {
        const {
            items, mode, searchFilter, editingColumns, visibleColumns, caseData,
        } = this.state;
        const { workspace } = this.props;
        const visibleCount = items.length;
        const selectedAndVisibleCount = this.selectedAndVisibleItems().length;
        const allSelected = visibleCount === selectedAndVisibleCount;
        const massUpdateLabel = `Update ${selectedAndVisibleCount} selected fragments...`;
        const sectionInfo = this.sectionInfo(mode);

        if (!caseData) {
            return (
                <div id="fragmentmanager">No fragments are available for any loaded structure</div>
            );
        }
        return (
            <div id="fragmentmanager">
                <div>
                    {this.caseDataPicker(caseData)}
                    <div className="fragmentmanager_nav">
                        <input className="blueTextButton" style={{ marginLeft: '2em' }} type="button" value="All Simulated Fragments" title="View all simulated fragments" data-mode={FragmentManager.Modes.all} disabled={mode===FragmentManager.Modes.all} onClick={this.setMode} />
                        <input className="blueTextButton" style={{ marginLeft: '2em' }} type="button" value="Fragment Search" title="View fragments ready for searching and growing" data-mode={FragmentManager.Modes.search} disabled={mode===FragmentManager.Modes.search} onClick={this.setMode} />
                        <input className="blueTextButton" style={{ marginLeft: '2em' }} type="button" value="Hotspots" title="Manage hotspots and hotspot fragments" data-mode={FragmentManager.Modes.hotspot} disabled={mode===FragmentManager.Modes.hotspot} onClick={this.setMode} />
                    </div>
                    {
                    !!sectionInfo && (
                        <div className="fragmentmanager_sectioninfo">
                            {sectionInfo}
                        </div>
                    )
                }
                    <div className="fragmentmanager_summary">
                        Showing
                        {' '}
                        {visibleCount}
                        {' '}
                        {this.modeName(mode)}
                        {' '}
                        fragments
                        { this.activeFilters().length > 0
                        && (
                        <>
                            <span title={`Filters: ${this.activeFilters().join(', ')}`}>
                                {' '}
                                (filtered)
                                <input className="blueTextButton" style={{ marginLeft: '2em' }} type="button" value="Clear all filters" onClick={this.clearAllFilters} />
                            </span>
                        </>
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '1 1 auto' }}>
                            <ClearableTextInput
                                label="Quick filter: "
                                placeholder="enter filter text"
                                value={searchFilter}
                                onChange={this.updateSearch}
                                style={{ display: 'inline-block', marginRight: '.5em' }}
                            />
                            <FragmentManager.QuickSelect
                                workspace={workspace}
                                onChange={(column, value) => {
                                    this.clearAllFilters();
                                    this.applyFilter(column, value);
                                    this.selectAll();
                                }}
                                style={{ display: 'inline-block' }}
                            />
                        </div>
                        <div className="fragmanager_rightcontrols">
                            <input className="blueTextButton" style={{ marginLeft: '1em' }} type="button" value={allSelected ? 'Deselect all' : 'Select all'} onClick={this.selectAll} />
                            <select
                                onChange={this.massUpdate}
                                value={massUpdateLabel}
                                disabled={selectedAndVisibleCount === 0}
                            >
                                <option value="" style={{ display: 'none' }}>{massUpdateLabel}</option>
                                <option data-group="selected+visible" data-value data-column="searchable">Add Searchable</option>
                                <option data-group="selected+visible" data-value={false} data-column="searchable">Remove Searchable</option>
                                <option data-group="selected+visible" data-value data-column="usedForHotspots">Add to Hotspots</option>
                                <option data-group="selected+visible" data-value={false} data-column="usedForHotspots">Remove from Hotspots</option>
                            </select>
                            <input className="blueTextButton" style={{ marginLeft: '1em' }} type="button" value="Choose columns" onClick={this.clickChooseColumns} />
                            {editingColumns && this.renderColumnChooser()}
                        </div>
                    </div>
                    <ListTable
                        rowClassName={this.rowClassName}
                        columnKeys={
                        // Column ids for displayed columns
                        Object.values(FragmentManager.Columns)
                            .filter((c) => c.required || visibleColumns.includes(c.id))
                            .map((c) => c.id)
                    }
                        items={[{ header: true }, ...items]}
                        renderColumn={this.renderColumn}
                    />
                </div>
            </div>
        );
    }
}

FragmentManager.Columns = {
    selected: {
        id: 'selected', type: 'bool', textSearchable: false, required: true,
    },
    name: {
        id: 'name', type: 'string', textSearchable: true, required: true,
    },
    fragset: { id: 'fragset', type: 'stringarray', textSearchable: true },
    library: { id: 'library', type: 'stringarray', textSearchable: true },
    minBValue: { id: 'minBValue', type: 'int', textSearchable: true },
    MW: { id: 'MW', type: 'float', textSearchable: true },
    HAC: { id: 'HAC', type: 'int', textSearchable: true },
    LogP: { id: 'LogP', type: 'float', textSearchable: true },
    HBD: { id: 'HBD', type: 'int', textSearchable: true },
    HBA: { id: 'HBA', type: 'int', textSearchable: true },
    TPSA: { id: 'TPSA', type: 'float', textSearchable: true },
    RBC: { id: 'RBC', type: 'int', textSearchable: true },
    charge: { id: 'charge', type: 'int', textSearchable: true },
    searchable: {
        id: 'searchable', type: 'bool', textSearchable: false, required: true,
    },
    usedForHotspots: {
        id: 'usedForHotspots', type: 'bool', textSearchable: false, required: true,
    },
};

FragmentManager.Modes = {
    all: 'all',
    search: 'search',
    hotspot: 'hotspot',
};

function FilterMenu({
    column, data, initialState={}, onChange,
}) {
    const type = FragmentManager.Columns[column].type;
    const [state, setState] = useState(initialState);
    const firstElt = useRef(null);

    useEffect(() => {
        if (firstElt && firstElt.current) firstElt.current.focus();
    });

    const min = (input) => {
        const ret = {};
        for (const key of Object.keys(input)) {
            if (input[key] || input[key] === 0) ret[key] = input[key];
        }
        return ret;
    };
    let elt;
    switch (type) {
        case 'string':
            elt = (
                <ClearableTextInput
                    label="Filter text"
                    value={state.filter || ''}
                    onChange={(value) => setState(min({ filter: value }))}
                    focus
                />
            );
            break;
        case 'int':
            elt = (
                <>
                    <label htmlFor="filtermenu__int-min-input">
                        Min
                        <input
                            id="filtermenu__int-min-input"
                            ref={firstElt}
                            type="number"
                            value={state.min || ''}
                            onChange={(e) => setState(
                                min({ min: e.target.value, max: state.max }),
                            )}
                        />
                    </label>
                    <label htmlFor="filtermenu__int-max-input">
                        Max
                        <input
                            id="filtermenu__int-max-input"
                            type="number"
                            value={state.max || ''}
                            onChange={(e) => setState(
                                min({ max: e.target.value, min: state.min }),
                            )}
                        />
                    </label>
                </>
            );
            break;
        case 'float':
            elt = (
                <>
                    <label htmlFor="filtermenu__float-min-input">
                        Min
                        <input
                            id="filtermenu__float-min-input"
                            ref={firstElt}
                            type="number"
                            step=".1"
                            value={state.min || ''}
                            onChange={(e) => setState(
                                min({ min: e.target.value, max: state.max }),
                            )}
                        />
                    </label>
                    <label htmlFor="filtermenu__float-max-input">
                        Max
                        <input
                            id="filtermenu__float-max-input"
                            type="number"
                            step=".1"
                            value={state.max || ''}
                            onChange={(e) => setState(
                                min({ max: e.target.value, min: state.min }),
                            )}
                        />
                    </label>
                </>
            );
            break;
        case 'stringarray': {
            elt = (
                <label htmlFor="filtermenu__stringarray-select">
                    Select filter items
                    <select
                        id="filtermenu__stringarray-select"
                        ref={firstElt}
                        multiple
                        value={state.selected}
                        style={{ height: `${data.length * 1.3}em` }}
                        onChange={(e) => setState(
                            min({ selected: [...e.target.selectedOptions].map((x) => x.value) }),
                        )}
                    >
                        {
                            data.map((x) => <option key={x}>{x}</option>)
                        }
                    </select>
                </label>
            );
            break;
        }
        case 'bool':
            elt = (
                <>
                    <label htmlFor="filtermenu__bool-true-input" className="filtermenu_bool">
                        Include True
                        <input
                            id="filtermenu__bool-true-input"
                            type="checkbox"
                            checked={!state.excludeOn}
                            onChange={
                                (e) => setState({
                                    excludeOn: !e.target.checked,
                                    excludeOff: state.excludeOff,
                                })
                            }
                        />
                    </label>
                    <label htmlFor="filtermenu__bool-false-input" className="filtermenu_bool">
                        Include False
                        <input
                            id="filtermenu__bool-false-input"
                            type="checkbox"
                            checked={!state.excludeOff}
                            onChange={
                                (e) => setState({
                                    excludeOff: !e.target.checked,
                                    excludeOn: state.excludeOn,
                                })
                            }
                        />
                    </label>
                </>
            );
            break;
        default:
            console.warn(`I don't know how to create a form for ${type}`);
    }
    return (!!column
        && (
        <div className="filtermenu">
            <form onSubmit={() => onChange(column, state)}>
                <div>{elt}</div>
                <div className="filtermenu_buttons">
                    <input type="submit" value="Apply Filter" />
                    <input type="button" value="Clear Filter" onClick={() => onChange(column, null)} />
                    <input type="button" value="Cancel" onClick={() => onChange()} />
                </div>
            </form>
        </div>
        )
    );
}
