import { addMenuItem } from './menu_cmds';
import { EventBroker } from './eventbroker';
import { App } from './BMapsApp';

export class FragSearchButton {
    static Init() {
        // console.log('Frag search button loaded');
        // addToTitleBar();
        addToMenu();
        EventBroker.subscribe('setPermissions', () => {
            if (!App.AllPermissions.isAllowed('frag_search')) {
                disable();
            }
        });
    }
}

function addToMenu() {
    addMenuItem('FragmentSearch', 'Fragment Search', () => window.open('/services/search'));
}

function disable() {
    $('#FragmentSearchMenuItem').addClass('disabledButton').off('click');
}

function addToTitleBar() {
    $('#topbar-right-side').prepend(
        `
        <a href="/services/search" target='_blank'> 
        <button id='frag_search' class="topbar-button">
             <i class="fa fa-search"></i>
             <div class='topbar-button-label'>frags</div>
        </button>
        </a>
        `,
    );
}
