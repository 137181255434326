/**
 *  @typedef {import('BMapsModel').MapCase} MapCase
 *  @typedef {import('BMapsModel').Compound} Compound
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';

import {
    ActionMenu, CompoundTree,
    TabNav, TreeUtils,
} from '@Conifer-Point/px-components';
import { CustomActions, UserActions } from 'BMapsCmds';
import { isPreviewModeSession } from 'BMapsSrc/server/session_utils';
import { EventBroker } from '../../eventbroker';
import { Loader } from '../../Loader';
import { App } from '../../BMapsApp';

import { showAlert, userConfirmation } from '../../utils';
import { ClearableTextInput, TreeEnergySlider } from '../UIComponents';
import { StarterCompounds } from '../../model/CaseData';
import { ScoringTableSidePanel } from '../tables/ScoringTableSidePanel';
import { CompoundTableSidePanel } from '../tables/CompoundTableSidePanel';
import BMapsTooltip from '../BMapsTooltip';

const EnergyFilterLimits = {
    min: -10,
    max: 0,
};
const renderDebug = false;

export default class SystemSelector extends React.PureComponent {
    update() {
        EventBroker.publish('selectorTabChanged');
    }

    /* Note: to prevent re-rendering child components, try the following:
        - pass the same objects into as props to the children (see note on InfoDisplay.render)
        - Don't pass in anonymous arrow functions as props
        - CompoundList needs to be React.PureComponent or implement shouldComponentUpdate
     */
    render() {
        if (renderDebug) console.log('Rendering SystemSelector');
        const {
            backgroundStyle, compoundItems, proteinItems, fragmentListInfo, hotspotInfo,
            tab, onSelect, sampleCompoundInfo,
        } = this.props;

        const treeName = 'Compounds';
        return (
            <TabNav
                className="systemSelector"
                style={backgroundStyle}
                tab={tab}
                onSelect={onSelect}
                didUpdate={() => this.update()}
            >
                <TabNav.Page tabId="compounds" title="Compounds">
                    <AddStarterCompounds sampleCompoundInfo={sampleCompoundInfo} />
                    <TreeWithFilter
                        items={compoundItems}
                        onToggleVisible={
                            ({ compound, treeItem }) => UserActions.ToggleVisibility(
                                compound || treeItem
                            )
                        }
                        onToggleStarred={({ compound, isStarred }) => {
                            UserActions.ToggleStarred(compound, !isStarred);
                        }}
                        displayStarFilter
                        onToggleActive={({ compound, isActive }) => {
                            if (isActive) {
                                if (App.Workspace.displayState.bindingsite) {
                                    UserActions.SetView('protein');
                                } else {
                                    // Activating the already active cmpd ensures the workspace
                                    // binding site is for the compound (not selected atoms).
                                    // Just setting to ligand view might zoom to selected atoms
                                    // somewhere else.
                                    // This works because onActiveCompound recalculates and saves
                                    // the binding site in display_mgr.
                                    UserActions.ActivateCompound(compound);
                                    UserActions.SetView('ligand');
                                }
                            } else {
                                UserActions.ActivateCompound(compound);
                            }
                        }}
                        onToggleSelected={({ treeItem, isSelected }) => {
                            UserActions.ToggleSelection(treeItem, !isSelected);
                        }}
                        onToggleExpanded={({ treeItem }) => UserActions.ToggleExpansion(treeItem)}
                        onDoubleClick={({ compound }) => { UserActions.ActivateCompound(compound); UserActions.SetView('ligand'); }}
                        validateRename={validateRename}
                        onRename={doRename}
                        onMoveWithFiltered={(filteredItems, ...onMoveArgs) => {
                            // Unlike combine, onMove is always called with paths instead of items.
                            // When the filter is active, these paths will not match the real tree,
                            // so we'll need to translate the paths to reference items.
                            // Important to note that the toIndexPath assumes the moving item
                            // has already been removed.
                            // There are three cases:
                            // 1. If the filtered tree matches the main tree, can send toIndexPath.
                            // 2. If the filtered tree has an item at the provided indexPath,
                            //    use the item as the a reference for the exact path (addAtItem).
                            // 3. If the filtered tree does not have an item at the indexPath,
                            //    work backwards up the tree until we find an item, then use it as
                            //    a reference, but addAfterItem.
                            const [{ treeItem }, fromIndexPath, toIndexPath] = onMoveArgs;
                            let pathOptions;
                            if (compoundItems.length === filteredItems.length) {
                                // Case 1: Trees match, can use toIndexPath
                                pathOptions = { toIndexPath };
                            } else {
                                // Filter has modified the tree, so must find reference item.
                                // toIndexPath assumes the item has already been removed, so
                                // build a copy of the tree structure without the moving item.
                                const searchTree = cloneTree(filteredItems);
                                TreeUtils.remove(searchTree, fromIndexPath);
                                let { node } = TreeUtils.subscript(searchTree, toIndexPath);

                                if (node) {
                                    // Case 2: Filtered tree has item at toIndexPath, use addAtItem
                                    pathOptions = { addAtItem: node.treeItem };
                                } else {
                                    // Case 3: Filtered tree does not have item at toIndexPath,
                                    // look backwards for reference node and use addAfterItem
                                    const dstIndexPath = [...toIndexPath];
                                    while (!node && dstIndexPath.length > 0) {
                                        if (dstIndexPath[dstIndexPath.length - 1] > 0) {
                                            TreeUtils.decrement(dstIndexPath);
                                        } else {
                                            TreeUtils.ascend(dstIndexPath);
                                        }
                                        ({ node } = TreeUtils.subscript(searchTree, dstIndexPath));
                                    }
                                    if (node) {
                                        pathOptions = { addAfterItem: node.treeItem };
                                    }
                                }
                            }
                            if (pathOptions) {
                                UserActions.MoveTreeItem(treeName, treeItem, pathOptions);
                            }
                        }}
                        onCombine={(
                            { treeItem: movingTreeItem },
                            localFromIndexPath, { treeItem: targetTreeItem }, localToIndexPath
                        ) => {
                            const pathOptions = { };
                            if (targetTreeItem.type === 'group') {
                                pathOptions.addInItem = targetTreeItem;
                            } else {
                                pathOptions.addAfterItem = targetTreeItem;
                            }
                            UserActions.MoveTreeItem(treeName, movingTreeItem, pathOptions);
                        }}
                        style={backgroundStyle}
                        actionsWithFilter={(filterItems) => compoundActions(filterItems, treeName)}
                        filterLabel="Compounds"
                        viewActions={compoundViewSortActions(compoundItems, treeName)}
                        getIcon={(column, item) => {
                            if (column === 'isActive') {
                                if (item.isActive) {
                                    return App.Workspace.DisplayState.bindingsite
                                        ? 'fa fa-search-minus'
                                        : 'fa fa-search-plus';
                                } else {
                                    return 'fa fa-arrow-right';
                                }
                            } else if (column === 'score') {
                                if (item.compound && item.compound.anyEnergiesWorking()) {
                                    return 'fa fa-circle-o-notch fa-spin';
                                }
                            }
                            return '';
                        }}
                        getTooltip={(column, item) => {
                            if (column === 'isVisible') {
                                const verb = item.isVisible ? "Don't keep": 'Keep';
                                const object = item.type === 'group' ? "this group's compounds" : 'this compound';
                                return `${verb} ${object} in the 3D workspace and energy table`;
                            } else if (column === 'isActive') {
                                if (item.isActive) {
                                    return App.Workspace.DisplayState.bindingsite
                                        ? 'Zoom out to Protein View'
                                        : `Zoom in to Ligand View for ${item.name}`;
                                } else {
                                    return `Focus on ${item.name}`;
                                }
                            } else if (column === 'isStarred') {
                                const verb = item.isStarred ? 'Unstar': 'Star';
                                const object = item.type === 'group' ? 'compounds' : 'compound';
                                return `${verb} ${object}`;
                            } else if (column === 'name') {
                                // Only show name tooltip if the full name has been truncated
                                if (item.rawName && item.rawName !== item.name) {
                                    return item.rawName;
                                } else {
                                    return undefined;
                                }
                            } else if (column === 'score') {
                                if (typeof item.score.content === 'string') {
                                    // Tooltip for score value
                                    return 'Interaction score (kcal/mol)';
                                } else if (item.compound && item.compound.anyEnergiesWorking()) {
                                    // Tooltip for working indicator
                                    return 'Calculation in progress...';
                                } else {
                                    // Tooltip for minimize button
                                    return 'Minimize and calculate interaction score.';
                                }
                            } else if (column === 'protein') {
                                /** @type {Compound} */
                                const compound = item.compound;
                                const caseData = compound.caseData;
                                return caseData.mapCase.getLongName();
                            } else {
                                return undefined;
                            }
                        }}
                    />
                </TabNav.Page>
                <TabNav.Page tabId="fragments" title="Fragments">
                    { !!(proteinItems?.pxNodes.length > 0)
                        && (
                            <FragmentSelectorList
                                fragmentListInfo={fragmentListInfo}
                                style={backgroundStyle}
                            />
                        )}
                </TabNav.Page>
                <TabNav.Page tabId="protein" title="Protein">
                    {!!proteinItems
                    && (
                    <TreeWithFilter
                        items={proteinItems.pxNodes}
                        onToggleVisible={proteinItems.onToggleVisible}
                        onToggleExpanded={proteinItems.onToggleExpansion}
                        style={backgroundStyle}
                        actions={proteinItems.pxItemActions}
                        filterLabel="Components"
                        viewActions={proteinItems.viewSortActions}
                        getIcon={proteinItems.getIcon}
                        getTooltip={proteinItems.getTooltip}
                    />
                    )}
                    { (hotspotInfo.hotspots.length > 0)
                        && (
                        <TreeEnergySlider
                            title="Only include hotspots with average free energy below this threshold."
                            label="Hotspot average energy filter:"
                            onManage={() => UserActions.OpenFragmentManager('hotspot')}
                            manageTitle="Manage hotspots"
                            sliderProps={{
                                value: hotspotInfo.threshold,
                                step: 0.5,
                                max: 0,
                                min: -12,
                                onChange: UserActions.ChangeHotspotThreshold,
                            }}
                        />
                        )}
                </TabNav.Page>
            </TabNav>
        );
    }
}

function SelectorHeader({
    filterLabel='', filter, onFilterChange, viewActions, displayStarFilter,
    starFilterActive, setStarFilterActive,
}) {
    const treeKey = filterLabel;
    // filterLabel will be used for the filter placeholder text, but it happens to identify
    // which tree we're on, so use it for the treeKey.
    // Maybe filterLabel should be converted to treeKey and placeholder text looked up via i18n.

    return (
        <div className="selectorHeader">
            <div style={{ padding: '0 .3em' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    { displayStarFilter && (
                        <StarFilter
                            starFilterActive={starFilterActive}
                            setStarFilterActive={setStarFilterActive}
                        />
                    )}
                    <ClearableTextInput
                        placeholder={` Filter ${filterLabel}`}
                        value={filter}
                        onChange={onFilterChange}
                    />
                </div>
            </div>
            <ViewSortMenu viewActions={viewActions} treeKey={treeKey} />
        </div>
    );
}

function StarFilter({ starFilterActive, setStarFilterActive }) {
    return (
        <BMapsTooltip
            title={starFilterActive ? 'Display all items' : 'Display only starred items'}
            placement="top"
            useColorScheme
        >
            <IconButton
                aria-label="Star filter"
                onClick={() => setStarFilterActive(!starFilterActive)}
                sx={{ color: 'inherit' }}
            >
                <i
                    className="fa fa-star"
                    style={{ opacity: starFilterActive ? 1.0 : 0.3 }}
                />
            </IconButton>
        </BMapsTooltip>
    );
}

function ViewSortMenu({ viewActions, treeKey }) {
    const anyIcons = viewActions.find((x) => x.icon);
    return (
        <div className="viewSortMenu">
            {/* View / Sort added by ::before css rule in InfoDisplay.css */}
            <ActionMenu key="actions" className="actions" style={{ display: 'inline' }}>
                {viewActions.map((action, index) => (
                    <ActionMenu.Item
                        key={`${treeKey} ${action.title || `menuitem#${index.toString()}`}`}
                        noIcons={!anyIcons}
                        {...action}
                    />
                ))}
            </ActionMenu>
        </div>
    );
}

function NothingToShowTree({ message, spinning, more }) {
    return (
        <div className="nothing-to-show-tree">
            <div className="nothing-to-show-tree-message">
                {message}
                {!!spinning && (
                <span>
                    {' '}
                    <i className="fa fa-circle-o-notch fa-spin" />
                </span>
                )}
            </div>
            {!!more && more}
        </div>
    );
}

function collectTreeItems(nodes, queryFn=() => true, includeChildren=true) {
    const result = { leaves: [], groups: [] };
    const addLeaf = (leaf) => {
        if (!result.leaves.includes(leaf)) result.leaves.push(leaf);
    };
    const addGroup = (groupObj) => {
        if (!result.groups.find((x) => x.treeItem === groupObj.treeItem)) {
            result.groups.push(groupObj);
        }
    };
    TreeUtils.traverse(nodes, (node, indexPath) => {
        if (queryFn(node)) {
            if (node.type === 'leaf') {
                addLeaf(node);
            } else {
                const groupObj = { ...node, indexPath };
                addGroup(groupObj);
                const groupsRes = collectTreeItems(node.children,
                    includeChildren ? () => true : queryFn,
                    includeChildren);
                for (const child of groupsRes.leaves) {
                    addLeaf(child);
                }
                for (const child of groupsRes.groups) {
                    addGroup(child);
                }
            }
        }
    });
    return result;
}

/**
 * @description Return a list of action objects to be passed into Tree control
 * @param {*} actions List of custom action objects: {
 *      title: string label for the menu item
 *      icon: string css class for the menu item icon (font awesome)
 *      isVisible: optional function taking an item of interest
 *          and returning if it should be visible.
 *          ex: restrict action availability to compounds which are ligands
 *      action: function operating on the item of interest to perform the action
 * }
 * @param {*} getItem Function to extract item of interest from tree item (eg compound)
 * @param {*} isVisible Function taking a tree item and returning if the action should be visible
 * @returns A list of action objects ready for tree control: {
 *      title: string label for the menu item
 *      icon: string css class for the menu item icon (font awesome)
 *      isHidden: function taking a tree item and returning if the menu item should be hidden
 *      onClick: function taking tree item to perform the action
 * }
 */
function addCustomActions(actions, getItem=() => undefined, isVisible) {
    const result = [];
    if (actions.length > 0) {
        result.push({ divider: true });
        for (const action of actions) {
            result.push({
                title: action.title,
                icon: action.icon || '',
                isHidden: (item) => !(
                    (!action.isVisible || action.isVisible(getItem(item)))
                    && (!isVisible || isVisible(item))
                ),
                onClick: (item) => action.action(getItem(item)),
            });
        }
    }
    return result;
}

function compoundActions(items, treeName) {
    const isBusyCompound = (item) => isCompound(item) && item.compound.anyEnergiesWorking();
    const isNotBusyCompound = (item) => isCompound(item) && !item.compound.anyEnergiesWorking();
    const promptForMinimizationsAt = 4;
    const promptForGifeAt = 4;

    /** ******** Populate variables to specify logic ********** */
    const proteinCount = App.Ready ? App.Workspace.getLoadedProteins().length : 0;
    const multiProtein = proteinCount > 1;
    const selectedItems = collectTreeItems(items, (x) => x.isSelected);
    const selectedCompounds = selectedItems.leaves.map((x) => x.compound);
    const anySelectedItems = selectedCompounds.length > 0 || selectedItems.groups.length > 0;
    const haveSelectedCompounds = selectedCompounds.length > 0
        && !selectedCompounds.find((x) => x.anyEnergiesWorking());
    const haveSelectedBusyCompounds = !!selectedCompounds.find((x) => x.anyEnergiesWorking());
    const groupHasCompounds = (group) => (
        TreeUtils.some(group.children, (node) => isCompound(node))
        && !TreeUtils.some(group.children, (node) => isBusyCompound(node))
    );
    const groupHasBusyCompounds = (group) => (
        TreeUtils.some(group.children, (node) => isBusyCompound(node))
    );
    const compoundsInGroupItem = (item) => {
        /** @type {Compound[]} */
        const compounds = [];
        TreeUtils.traverse(item.children, (node) => {
            if (isCompound(node)) {
                compounds.push(node.compound);
            }
        });
        return compounds;
    };
    // Plumbing to act on single selected items as if just acting on the item
    const numberOfSelectedNodes = Object.values(selectedItems)
        .flat() // both groups and leaves
        .filter((x) => x.isSelected) // exclude unselected leaves in selected groups
        .length;
    const isMultiItem = (item) => (numberOfSelectedNodes > 1 && item.isSelected);

    /** ********* Define non-trival action functions ************ */
    const doDeleteCompound = async (item) => {
        if (isNotBusyCompound(item) && !item.compound.isLigand()) {
            const text = `Are you sure you want to delete compound ${item.name}?`;
            if (await userConfirmation(text, 'Delete Compound?')) {
                await UserActions.RemoveCompound(item.compound);
            }
        }
    };

    const doDeleteGroup = async (item) => {
        if (isGroup(item)) {
            const compounds = compoundsInGroupItem(item).filter((x) => !x.isLigand());
            const prompt = compounds.length > 0;
            const cmpdLabel = compounds.length === 1
                ? `compound ${compounds[0].resSpec}?`
                : `all ${compounds.length} compounds?`;
            const text = `Are you sure you want to delete group ${item.name} and ${cmpdLabel}`;
            if (!prompt || await userConfirmation(text, 'Delete Compound?')) {
                const needToRemoveGroup = allChildrenVisible(item);
                // Remove compounds, then groups which are now empty as a result
                await Promise.all(compounds.map((cmpd) => UserActions.RemoveCompound(cmpd)));
                if (needToRemoveGroup) {
                    // Pass treeItem to GroupDelete, in case indexPath is for the filtered tree.
                    UserActions.GroupDelete({ treeItem: item.treeItem }, treeName);
                }
            }
        }
    };

    const doDeleteSelected = async () => {
        const compoundsToRemove = selectedCompounds.filter((x) => !x.isLigand());
        const groupsToRemove = selectedItems.groups.filter(allChildrenVisible);
        const text = `Are you sure you want to delete the selected items? (including ${compoundsToRemove.length} compounds)`;
        if (await userConfirmation(text, 'Delete Selected?')) {
            // Remove compounds, then groups which are now empty as a result
            await Promise.all(compoundsToRemove.map((cmpd) => UserActions.RemoveCompound(cmpd)));
            // Pass treeItem to GroupDelete, in case indexPath is for the filtered tree.
            const selectedGroupObjs = groupsToRemove.map(({ treeItem }) => ({ treeItem }));
            UserActions.GroupDelete(selectedGroupObjs, treeName);
        }
    };

    const doMinimize = async (item, minimize=true) => {
        if (isNotBusyCompound(item)) {
            if (minimize) UserActions.EnergyMinimize(item.compound);
            else UserActions.GetEnergies(item.compound);
        }
        if (isGroup(item)) {
            const compounds = compoundsInGroupItem(item);
            const prompt = compounds.length >= promptForMinimizationsAt;
            const verb = minimize ? 'Minimize' : 'Get energies for';
            const text = `${verb} all ${compounds.length} compounds in this group (including subgroups)?`;
            const label = `${verb} Group?`;
            if (!prompt || await userConfirmation(text, label)) {
                if (minimize) UserActions.EnergyMinimize(compounds);
                else UserActions.GetEnergies(compounds);
            }
        }
    };

    const doMinimizeSelected = async (ignoredTreeItems, minimize=true) => {
        const prompt = selectedCompounds.length >= promptForMinimizationsAt;
        const verb = minimize ? 'Minimize' : 'Get energies for';
        const text = `${verb} ${selectedCompounds.length} selected compounds?`;
        const label = `${verb} Selected?`;
        if (!prompt || await userConfirmation(text, label)) {
            if (minimize) UserActions.EnergyMinimize(selectedCompounds);
            else UserActions.GetEnergies(selectedCompounds);
        }
    };

    const doCalculateGife = async (item) => {
        if (isNotBusyCompound(item)) {
            UserActions.UpdateGifeEnergies(item.compound);
        }
        if (isGroup(item)) {
            const compounds = compoundsInGroupItem(item);
            const prompt = compounds.length >= promptForMinimizationsAt;
            const text = `Calculate GiFE for all ${compounds.length} compounds in this group?`;
            const label = 'Calculate GiFE?';
            if (!prompt || await userConfirmation(text, label)) {
                UserActions.UpdateGifeEnergies(compounds);
            }
        }
    };

    const doCalculateGifeSelected = async () => {
        const prompt = selectedCompounds.length >= promptForGifeAt;
        const text = `Calculate GiFE for ${selectedCompounds.length} selected compounds?`;
        const label = 'Calculate GiFE?';
        if (!prompt || await userConfirmation(text, label)) {
            UserActions.UpdateGifeEnergies(selectedCompounds);
        }
    };

    const doSortGroup = (item, sortType) => {
        const groupObj = item.treeItem;
        UserActions.GroupSort(groupObj, { sortType });
    };

    /** ********************* ACTIONS  *************** */

    const showCompoundAction = (item) => isNotBusyCompound(item) && !isMultiItem(item);
    // Actions to show in the menu for a compound when there is no selection
    const singleCompoundActions = [
        // Actions on compounds
        {
            title: 'Working...',
            icon: 'fa fa-circle-o-notch fa-spin',
            isHidden: (item) => !(isBusyCompound(item) && !isMultiItem(item)),
        },
        {
            title: 'Edit',
            icon: 'fa fa-edit',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => {
                if (isCompound(item)) UserActions.OpenSketcher(item.compound);
            },
        },
        {
            title: 'Minimize',
            icon: 'fa fa-cogs',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => doMinimize(item),
        },
        {
            title: 'Get Energies (without minimization)',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showCompoundAction(item)),
            onClick: (item) => doMinimize(item, false),
        },
        {
            title: 'Get GiFE Energies',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showCompoundAction(item)),
            onClick: (item) => doCalculateGife(item),
        },
        {
            title: 'Dock',
            icon: 'fa fa-bullseye',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => UserActions.OpenDock(item.compound, 'fast'),
        },
        {
            title: 'Export',
            icon: 'fa fa-share',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => {
                UserActions.OpenExport({ compounds: [item.compound] }, { tabId: 'export_moldata_tab' });
            },
        },
        {
            title: 'PubChem Search',
            icon: 'fa fa-search',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => {
                UserActions.OpenExport({ compounds: [item.compound] }, { tabId: 'export_pubchem_tab' });
            },
        },
        { divider: true },
        {
            title: 'Duplicate',
            icon: 'fa fa-copy',
            isHidden: (item) => !showCompoundAction(item),
            onClick: (item) => UserActions.CopyCompound(item.compound),
        },
        {
            title: 'Copy to Protein',
            icon: 'fa fa-copy',
            isHidden: (item) => !showCompoundAction(item) || !multiProtein,
            onClick: (item) => {
                UserActions.StageMoleculeImport({
                    compounds: [item.compound],
                    placement: 'Retain',
                });
            },
        },
        {
            title: 'Rename',
            icon: 'fa fa-i-cursor',
            isHidden: (item) => !(showCompoundAction(item) && !item.compound.isLigand()),
            role: 'rename',
        },
        {
            title: 'Delete',
            icon: 'fa fa-times',
            isHidden: (item) => !(showCompoundAction(item) && !item.compound.isLigand()),
            onClick: (item) => doDeleteCompound(item),
        },
        ...addCustomActions(CustomActions.Compound, (item) => item.compound, showCompoundAction),
    ];

    const showGroupAction = (item) => (
        isGroup(item) && groupHasCompounds(item) && !isMultiItem(item)
    );
    // Actions to show in the menu for a group when there is no selection
    const compoundGroupActions = [
        {
            title: 'Working on compounds in group...',
            icon: 'fa fa-circle-o-notch fa-spin',
            isHidden:
                (item) => !(isGroup(item) && groupHasBusyCompounds(item) && !isMultiItem(item)),
        },
        {
            title: 'Minimize all in Group',
            icon: 'fa fa-cogs',
            isHidden: (item) => !showGroupAction(item),
            onClick: (item) => doMinimize(item),
        },
        {
            title: 'Get energies for all in Group',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showGroupAction(item)),
            onClick: (item) => doMinimize(item, false),
        },
        {
            title: 'Get GiFE energies for all in Group',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showGroupAction(item)),
            onClick: (item) => doCalculateGife(item),
        },
        {
            title: 'Dock all in Group',
            icon: 'fa fa-bullseye',
            isHidden: (item) => !showGroupAction(item),
            onClick: (item) => {
                const compounds = compoundsInGroupItem(item);
                UserActions.OpenDock(compounds, 'fast');
            },
        },
        {
            title: 'Export all in Group',
            icon: 'fa fa-share',
            isHidden: (item) => !showGroupAction(item),
            onClick: (item) => {
                const compounds = compoundsInGroupItem(item);
                UserActions.OpenExport({ compounds }, { tabId: 'export_moldata_tab' });
            },
        },
        { divider: true },
        // {
        //     title: "Duplicate Group",
        //     icon: "fa fa-copy",
        //     isHidden: item => !isGroup(item),
        //     onClick: (item) => doCopy(item),
        // },
        {
            title: 'Copy Group to Protein',
            icon: 'fa fa-copy',
            isHidden: (item) => !(isGroup(item) && !isMultiItem(item)) || !multiProtein,
            onClick: (item) => {
                const groupCompounds = compoundsInGroupItem(item);
                UserActions.StageMoleculeImport({ compounds: groupCompounds, placement: 'Retain' });
            },
        },
        {
            title: 'Rename Group',
            icon: 'fa fa-i-cursor',
            isHidden: (item) => !(isGroup(item) && !isMultiItem(item)),
            role: 'rename',
        },
        {
            title: 'Delete Group',
            icon: 'fa fa-times',
            isHidden: (item) => !(isGroup(item) && !isMultiItem(item)),
            onClick: (item) => doDeleteGroup(item),
        },
        { divider: true },
        {
            title: 'Sort this group by Interaction Score',
            icon: 'fa fa-sort-numeric-asc',
            isHidden: (item) => !(
                // Only offer score sorting if group directly contains compounds (no grandchildren)
                isGroup(item) && !isMultiItem(item) && item.children.some(isCompound)
            ),
            onClick: (item) => doSortGroup(item, 'EnergyScore'),
        },
        {
            title: 'Sort this group alphabetically',
            icon: 'fa fa-sort-alpha-asc',
            isHidden: (item) => !(isGroup(item) && !isMultiItem),
            onClick: (item) => doSortGroup(item, 'Alphabetical'),
        },
    ];

    const showSelectedAction = (item) => isMultiItem(item) && haveSelectedCompounds;
    // Actions to show when there is a selection
    const selectedActions = [
        {
            title: 'Working on selected compounds...',
            icon: 'fa fa-circle-o-notch fa-spin',
            isHidden: (item) => !(isMultiItem(item) && haveSelectedBusyCompounds),
        },
        {
            title: 'Minimize Selected',
            icon: 'fa fa-cogs',
            isHidden: (item) => !showSelectedAction(item),
            onClick: () => doMinimizeSelected(items),
        },
        {
            title: 'Get energies for Selected',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showSelectedAction(item)),
            onClick: () => doMinimizeSelected(items, false),
        },
        {
            title: 'Get GiFE energies for Selected',
            icon: 'fa fa-cogs',
            isHidden: (item) => !(Loader.AllowLabFeatures && showSelectedAction(item)),
            onClick: () => doCalculateGifeSelected(),
        },
        {
            title: 'Dock Selected',
            icon: 'fa fa-bullseye',
            isHidden: (item) => !showSelectedAction(item),
            onClick: () => UserActions.OpenDock(selectedCompounds, 'fast'),
        },
        {
            title: 'Export Selected',
            icon: 'fa fa-share',
            isHidden: (item) => !showSelectedAction(item),
            onClick: () => {
                UserActions.OpenExport({ compounds: selectedCompounds }, { tabId: 'export_moldata_tab' });
            },
        },
        { divider: true },
        {
            title: 'Duplicate Selected',
            icon: 'fa fa-copy',
            isHidden: (item) => !showSelectedAction(item),
            onClick: () => {
                for (const cmpd of selectedCompounds) {
                    UserActions.CopyCompound(cmpd);
                }
            },
        },
        {
            title: 'Copy Selected to Protein',
            icon: 'fa fa-copy',
            isHidden: (item) => !showSelectedAction(item) || !multiProtein,
            onClick: () => {
                UserActions.StageMoleculeImport({ compounds: selectedCompounds, placement: 'Retain' });
            },
        },
        {
            title: 'Delete Selected',
            icon: 'fa fa-times',
            isHidden:
                (item) => !(anySelectedItems && !haveSelectedBusyCompounds && isMultiItem(item)),
            onClick: () => doDeleteSelected(),
        },
    ];

    const otherActions = [
        ...compoundTableActions(),
    ];

    const actions = [
        ...singleCompoundActions,
        ...compoundGroupActions,
        ...selectedActions,
        { divider: true },
        ...groupActions(items, treeName),
        { divider: true, isHidden: () => otherActions.every((x) => x.isHidden && x.isHidden()) },
        ...otherActions,
    ];

    return actions;
}

function compoundTableActions(skipIcons) {
    return [
        {
            title: 'Open Scoring Table',
            icon: !skipIcons ? 'fa fa-cog' : undefined,
            isHidden: () => {
                const proteinCount = App.Ready ? App.Workspace.getLoadedProteins().length : 0;
                const haveProtein = proteinCount > 0;
                return !haveProtein;
            },
            onClick: openScoringTableSidePanel,
        },
        {
            title: 'Open Compound Table',
            icon: !skipIcons ? 'fa fa-cog' : undefined,
            onClick: openCompoundTableSidePanel,
        },
    ];
}

function groupActions(items, treeName) {
    const isNonEmptyGroup = (item) => isGroup(item) && item.children.length > 0;

    const doCreateGroup = (item, withSelected) => {
        if (withSelected) {
            const selectedItems = collectTreeItems(items, (x) => x.isSelected);
            const selectedTreeItems = [
                ...selectedItems.groups.map((x) => x.treeItem),
                ...selectedItems.leaves.map((x) => x.treeItem),
            ];
            UserActions.GroupItems(selectedTreeItems, treeName);
        } else {
            UserActions.GroupCreate('New Group', treeName, { toItem: item.treeItem });
        }
    };

    const doUngroup = (item) => {
        UserActions.GroupUngroup(
            { treeItem: item.treeItem },
            treeName,
        );
    };

    const actions = [
        {
            title: 'Group Selected Items',
            icon: 'fa fa-object-group',
            isHidden: (item) => !(item.isSelected),
            onClick: (item) => doCreateGroup(item, true),
        },
        {
            title: 'Create New Group',
            icon: 'fa fa-folder',
            isHidden: () => false,
            onClick: (item) => doCreateGroup(item),
        },
        {
            title: 'Ungroup',
            icon: 'fa fa-object-ungroup',
            isHidden: (item) => !(isNonEmptyGroup(item)),
            onClick: doUngroup,
        },
    ];

    return actions;
}

function expandCollapseViewSortActions(items) {
    const expandAll = () => {
        const updating = [];
        TreeUtils.traverse(items, (i) => {
            if (i.type === 'group') {
                updating.push(i.treeItem);
            }
        });
        UserActions.ToggleExpansion(updating, false);
    };
    const collapseAll = () => {
        const updating = [];
        TreeUtils.traverse(items, (i) => {
            if (i.type === 'group') {
                updating.push(i.treeItem);
            }
        });
        UserActions.ToggleExpansion(updating, true);
    };
    return [
        {
            title: 'Expand all groups',
            onClick: expandAll,
        }, {
            title: 'Collapse all groups',
            onClick: collapseAll,
        },
    ];
}

function workspaceViewSortActions() {
    return [
        {
            title: 'Add another protein (Selectivity)',
            onClick: async () => {
                UserActions.OpenMapSelector('show', { selectivity: isPreviewModeSession()? 'viewonly' : 'selectivity' });
            },
            isHidden: () => {
                const proteinCount = App.Ready ? App.Workspace.getLoadedProteins().length : 0;
                const haveProtein = proteinCount > 0;
                return !haveProtein;
            },
        },
        {
            title: 'Clear workspace',
            onClick: () => { UserActions.ZapAll(); },
        },
    ];
}

function compoundViewSortActions(items) {
    return [
        ...expandCollapseViewSortActions(items),
        { divider: true },
        ...compoundTableActions(true).filter((x) => !(x.isHidden && x.isHidden())),
        { divider: true },
        ...workspaceViewSortActions().filter((x) => !(x.isHidden && x.isHidden())),
    ];
}

function TreeWithFilter(props) {
    const [filter, setFilter] = useState({ input: '', upperCase: '' });
    const [starFilterActive, setStarFilterActive] = useState(false);
    const {
        filterLabel, viewActions, items, displayStarFilter=false,
        onMoveWithFiltered, actionsWithFilter,
        ...forTree
    } = props;
    const matches = (item) => item.name.toUpperCase().indexOf(filter.upperCase) > -1;
    // Show items whose names match the query. Include parent nodes.
    const filterItems = (itemsToFilter) => {
        let starFilteredItems = [];
        if (starFilterActive && displayStarFilter) {
            for (const i of itemsToFilter) {
                if (i.type === 'leaf') {
                    if (i.isStarred) {
                        starFilteredItems.push(i);
                    }
                } else if (i.type === 'group') {
                    const descendants = filterItems(i.children);
                    if (i.isStarred|| descendants.length > 0) {
                        starFilteredItems.push({ ...i, children: descendants });
                    }
                }
            }
        } else {
            starFilteredItems = itemsToFilter;
        }
        let inputFilteredItems = [];
        if (filter.input) {
            for (const i of starFilteredItems) {
                if (i.type === 'leaf') {
                    if (matches(i)) {
                        inputFilteredItems.push(i);
                    }
                } else if (i.type === 'group') {
                    const descendants = filterItems(i.children);
                    if (matches(i) || descendants.length > 0) {
                        inputFilteredItems.push({ ...i, children: descendants });
                    }
                }
            }
        } else {
            inputFilteredItems = starFilteredItems;
        }
        return inputFilteredItems;
    };
    const filteredItems = filterItems(items);
    let onMove;
    if (onMoveWithFiltered) {
        onMove = (...onMoveArgs) => onMoveWithFiltered(filteredItems, ...onMoveArgs);
    }
    let actions;
    if (actionsWithFilter) {
        actions = actionsWithFilter(filteredItems);
    }
    return (
        <>
            { items.length > 0
                && (
                <SelectorHeader
                    filterLabel={filterLabel}
                    viewActions={viewActions}
                    displayStarFilter={displayStarFilter}
                    filter={filter.input}
                    starFilterActive={starFilterActive}
                    setStarFilterActive={setStarFilterActive}
                    onFilterChange={(input) => setFilter({
                        input,
                        upperCase: input.toUpperCase(),
                    })}
                />
                )}
            { items.length > 0 && filteredItems.length === 0
                && <NothingToShowTree message="Nothing matches the specified filter" />}
            {filteredItems.length > 0
                && (
                    <CompoundTree
                        items={filterItems(items)}
                        displayStars={displayStarFilter}
                        onMove={onMove}
                        actions={actions}
                        {...forTree}
                    />
                )}
        </>
    );
}

// used to clone the filtered tree so that it can be used for moving items.
function cloneTree(oldTree, indexPathIn=[]) {
    if (oldTree.length === 0) return [];
    return oldTree.map(({
        type, treeItem, children,
    }, i) => {
        const indexPath = [...indexPathIn, i];
        if (type === 'leaf') {
            return { type, treeItem, indexPath };
        } else {
            return {
                type,
                treeItem,
                indexPath,
                children: cloneTree(children, indexPath),
            };
        }
    });
}

/* See comment on SystemSelector.render() about preventing re-renders */
function FragmentSelectorList({ fragmentListInfo, style }) {
    const { FragmentLoading } = useSelector((state) => state.prefs);

    if (renderDebug) console.log('Rendering FragmentSelectorList');

    if (!fragmentListInfo) {
        if (FragmentLoading === 'lazy') {
            UserActions.RefreshAllFragments();
        }
        return (
            <NothingToShowTree message="Loading fragments for this structure..." spinning />
        );
    } else if (fragmentListInfo.pxNodes.length === 0) {
        return (
            <NothingToShowTree
                message="No fragments available for this structure"
                more={(
                    <div style={{ textAlign: 'center' }}>
                        <button
                            type="button"
                            style={{ font: 'inherit', padding: '.2em' }}
                            onClick={() => UserActions.ShowFragmentPane('fragment')}
                        >
                            Add fragments
                        </button>
                    </div>
                  )}
            />
        );
    } else {
        const {
            pxNodes, pxItemActions, viewSortActions, extra, onToggleVisible,
            onToggleSelected, onToggleExpansion, getIcon, getTooltip, onToggleStarred,
        } = fragmentListInfo;

        const { fragmentInfo: currentFragment, filterValue } = extra.energyFilterInfo;

        return (
            <>
                <TreeWithFilter
                    style={style}
                    filterLabel="Fragments"
                    items={pxNodes}
                    actions={pxItemActions}
                    viewActions={viewSortActions}
                    onToggleVisible={onToggleVisible}
                    onToggleStarred={onToggleStarred}
                    displayStarFilter
                    onToggleSelected={onToggleSelected}
                    onToggleExpanded={onToggleExpansion}
                    getIcon={getIcon}
                    getTooltip={getTooltip}
                    // Disable renaming on fragments for now
                    // validateRename={validateRename}
                    // onRename={doRename}
                />
                <TreeEnergySlider
                    title="Only show fragments with free energy below this threshold."
                    label={`${currentFragment?.name || 'Fragment'} energy filter:`}
                    enabled={!!currentFragment}
                    onManage={() => UserActions.OpenFragmentManager()}
                    manageTitle="Manage fragments"
                    notEnabledText="Select a fragment to adjust its energy filter"
                    sliderProps={{
                        value: filterValue != null ? filterValue : EnergyFilterLimits.max,
                        step: 0.5,
                        min: EnergyFilterLimits.min,
                        max: EnergyFilterLimits.max,
                        onChange: (val) => (
                            UserActions.SetFragmentEnergyFilter(currentFragment, val)
                        ),
                    }}
                />
            </>
        );
    }
}

function AddStarterCompounds({ sampleCompoundInfo: allSampleCompoundInfo }) {
    const tooltipContent = (
        <span>
            Example inhibitor compounds docked into a druggable site, many available commercially.
            <br />
            These small molecules have good docking scores
            {' '}
            and are suitable starting points for developing drug leads.
            <br />
            Some sample compounds are ligands from other pdb entries for the same target.
            <br />
            These ligands from other structures look like
            {' '}
            <code>&lt;3-letter ligand code&gt;.&lt;4-letter pdb code&gt;</code>
            , eg:
            {' '}
            <code>PRD.2AMQ</code>
        </span>
    );
    return (
        <BMapsTooltip title={tooltipContent} placement="right" noMaxWidth useColorScheme>
            <div>
                {allSampleCompoundInfo
                    .map(({ caseData }, i) => (
                        <OneAddStarterCompounds
                            key={`starterCmpds_${caseData.getShortName()}_${i.toString()}`}
                            extraLabel={allSampleCompoundInfo.length > 1 ? caseData.getShortName() : ''}
                            sampleCompoundInfo={caseData.getSampleCompoundInfo()}
                            caseData={caseData}
                        />
                    ))}
            </div>
        </BMapsTooltip>
    );
}

function OneAddStarterCompounds({ sampleCompoundInfo, extraLabel, caseData }) {
    const { availability: status } = sampleCompoundInfo;
    const visible = status === StarterCompounds.Available || status === StarterCompounds.Loading;
    const onClick = status === StarterCompounds.Available
        ? () => {
            sampleCompoundInfo.setLoading();
            UserActions.LoadStarterCompounds(caseData);
        }
        : undefined;
    const loading = status === StarterCompounds.Loading
        ? <i className="fa fa-circle-o-notch fa-spin" />
        : false;

    let label = 'Add sample compounds';
    if (extraLabel) label += ` for ${extraLabel}`;

    return (
        !!visible
        && (
        <div className="starterCompounds">
            <div>
                <button type="button" className="generalButton starterAdd" onClick={onClick}>
                    <i className="fa fa-plus" />
                    <span>{label}</span>
                    {' '}
                    {loading}
                </button>
                <button
                    type="button"
                    className="generalButton starterClose"
                    onClick={() => sampleCompoundInfo.setDismissed()}
                >
                    <i className="fa fa-times" />
                    <span>Dismiss</span>
                </button>
            </div>
        </div>
        )
    );
}

function openScoringTableSidePanel() {
    UserActions.OpenSidePanel('left', {
        pageId: 'scoringTable',
        component: <ScoringTableSidePanel />,
        longLived: false,
    });
}

function openCompoundTableSidePanel() {
    UserActions.OpenSidePanel('left', {
        pageId: 'compoundTable',
        component: <CompoundTableSidePanel />,
        longLived: false,
    });
}

function isCompound(item) {
    return item.type === 'leaf' && item.compound;
}

function isGroup(item) {
    return item.type === 'group';
}

function allChildrenVisible(item) {
    const visibleChildCount = item.children.length;
    const actualChildCount = item.treeItem.children.length;
    if (visibleChildCount !== actualChildCount) return false;
    const groupChildren = item.children.filter(isGroup);
    return groupChildren.every(allChildrenVisible);
}

// Rename functions are returned with the actions and added as
// separate props to the CompoundTree
/**
     * @param {string} newName
     * @returns {string | undefined}
    */
const validateRename = (newName, item) => {
    // group names can be changed to anything
    if (!isCompound(item)) return undefined;

    // Compound name rules
    if (newName.length > 128) return 'Max 128 characters!';
    if (newName === '') return 'Enter a new name';
    if (/[\s]+/.test(newName)) return 'Compound names cannot contain spaces';
    if (newName !== item.compound.resSpec) {
        const { caseData } = App.getDataParents(item.compound);
        if (caseData.getCompoundBySpec(newName)) return 'Another compound already has this name';
    }

    // Not doing other character validation, until analysis of cmpd (re)naming failure modes.
    // Note that BMaps currently behaves differently for EnterMolData tab, Sketcher, and Rename.

    // no errors
    return undefined;
};

/** @param {string} newName */
const doRename = (newName, item) => {
    const trimmedName = newName.trim();
    // Errors supposed to have been caught earlier, but validate again before actually sending.
    const error = validateRename(trimmedName, item);
    if (error) {
        showAlert(`Rename failed: ${error}`);
        return;
    }
    if (isCompound(item) && trimmedName !== item.compound.resSpec) {
        UserActions.RenameCompound(item.compound, trimmedName);
    }
    if (isGroup(item) && trimmedName !== item.displayName) {
        UserActions.GroupRename(item.treeItem, trimmedName);
    }
};
