/* CollapsibleTable.jsx
 * ReactComponent for a collapsible table.
 * Clicking the header row will show / hide the rest of the rows.
 * It preserves the width of the header row when the detail is hidden.
 * Mouse events will pass through when the rows are hidden.
 *
 * props:
 *   data: an array of arrays containing the data to display:
 *      [ [header_col0, header_col1, header_col2],
 *        [row0_col0, row0_col1, row0_col2],
 *        [row1_col0, row1_col1, row1_col2]
 *      ]
 * recommended:
 *   rowMetadata: an array of metadata for the data rows:
 *      [ ... {
 *          key: the key added to the child data rows. defaults to index.
 *          title: the title attribue to be added to <tr>s.
 *      } ... ]
 *      Specifying keys other than indices may help the table track dynamic changes.
 * optional args:
 *   onUpdated: callback for parent to do something when the table updates.
 *              Callback signature: function(expanded) { } ...
 *              This was added for changes that need to happen on expansion.
 *   rememberBy: the table will remember the state of expansion with this object
 *   eltId: the id for the table element that will be created
 *   expanded: should the table be initally expanded. Default: false
 *   tableClassName:  a class to be applied to the table
 *   headerClassName: a class to be applied to the header row
 *   dataClassName:   a class to be applied to the data rows
 *   collapsedRowSlice: numberOrArray
 *      This lets you specify a slice() operation on the list of rows when collapsed.
 *      This is really only useful to have the visible collapsed row be something other
 *      than the first row in the expanded row.
 *      This does not let you choose the number of visible rows.
 *   collapsedColSlice: numberOrArray. When collapsed, default is to show all columns.
 *      This lets you specify slice() params to the column list,
 *      limiting visible columns when collapsed.
 *   dangerouslySetInnerHtml:
 *      This will cause React to dangerouslySetInnerHtml for ALL cells.
 *      Use with caution.
 *   useDummy: whether or not to create a dummy row to preserve spacing when collapsed.
 */

import React from 'react';

export class CollapsibleTable extends React.Component {
    static remember(obj, expanded) {
        if (!obj) return;
        CollapsibleTable.memory.set(obj, !!expanded); // force to boolean
    }

    constructor(props) {
        if (!CollapsibleTable.memory) CollapsibleTable.memory = new Map();
        super(props);
        this.state = {
            expanded: this.initalExpansion(props),
        };
        this.onToggle = this.onToggle.bind(this);
    }

    componentDidUpdate() {
        const { onUpdated } = this.props;
        const { expanded } = this.state;
        if (onUpdated) {
            onUpdated(expanded);
        }
    }

    onToggle() {
        this.setState(
            (prevState) => {
                const newExpansion = !prevState.expanded;
                const { rememberBy } = this.props;
                CollapsibleTable.remember(rememberBy, newExpansion);
                return { expanded: newExpansion };
            },
        );
    }

    initalExpansion(props) {
        const { expanded, rememberBy } = props;
        const remembered = rememberBy && CollapsibleTable.memory.get(rememberBy);
        if (remembered === true || remembered === false) { // don't override for null or undefined
            return remembered;
        }
        return expanded;
    }

    rowKey(index) {
        const metadata = this.rowMetaData();
        const rowMeta = metadata && metadata[index];
        return (rowMeta && rowMeta.key) || index;
    }

    rowTitle(index) {
        const metadata = this.rowMetaData();
        const rowMeta = metadata && metadata[index];
        return rowMeta && rowMeta.title;
    }

    rowClasses(index) {
        const metadata = this.rowMetaData();
        const rowMeta = metadata && metadata[index];
        return (rowMeta && rowMeta.classes) || {};
    }

    data() {
        const { data } = this.props;
        return this.sliceData(data, true);
    }

    rowMetaData() {
        const { rowMetadata } = this.props;
        return this.sliceData(rowMetadata, false);
    }

    // Perform user-defined slice on data before creating the collapsed table
    sliceData(data, includeCols=false) {
        const { expanded } = this.state;
        if (expanded) {
            return data;
        } else {
            const { collapsedRowSlice: rowSlice, collapsedColSlice: colSlice } = this.props;

            // Returns either [start] or [start, end]
            const startAndEnd = (numberOrArray) => [].concat(numberOrArray);
            const [rowStart, rowEnd] = startAndEnd(rowSlice);
            const [colStart, colEnd] = startAndEnd(colSlice);
            const visibleRows = data.slice(rowStart, rowEnd);

            if (includeCols) {
                return visibleRows.map((d) => d.slice(colStart, colEnd));
            } else {
                return visibleRows;
            }
        }
    }

    // renderRows()
    // There are three possible scenarios:
    // 1) Table is empty: no data rows
    // 2) Table is collapsed: add a dummy data row
    // 3) Table is expanded: add all data rows
    renderRows(haveData) {
        // No data, render nothing
        if (!haveData) {
            return false;
        }

        const { expanded } = this.state;
        const {
            useDummy, data, dataClassName, dangerouslySetInnerHTML,
        } = this.props;

        // Collapsed, add dummy row
        if (!expanded) {
            if (useDummy) {
                return this.renderDummyRow();
            } else {
                return false;
            }
        }

        // Expanded, add child data rows
        return data.map((row, index) => index > 0 // ignore header row
            && (
            <CollapsibleTableDataRow
                key={this.rowKey(index)}
                className={dataClassName}
                title={this.rowTitle(index)}
                classes={this.rowClasses(index)}
                data={row}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            />
            ));
    }

    // renderDummyRow()
    // The dummy row is a single, invisible data row added to the collapsed table.
    // It contains the longest data for each column, to maintain the positioning of
    // header row columns.
    // In order to maintain column positions, the dummy row needs to be laid out
    // (ie not absolute positioning), which means that it will take up vertical space.
    // To compensate for this, the collapsed class adjusts the table's bottom-margin.
    renderDummyRow() {
        const data = this.data();
        const { dataClassName, dangerouslySetInnerHTML } = this.props;

        // Collect the longest values for each column into the dummy row
        const headerCols = data[0];
        const rows = data.slice(1);
        const dummyRow = headerCols.map((h) => '');
        for (const col of Object.keys(headerCols)) {
            for (const row of rows) {
                if (row[col] && row[col].toString().length > dummyRow[col].length) {
                    dummyRow[col] = row[col].toString();
                }
            }
        }

        return (
            <CollapsibleTableDataRow
                key="CollapsibleTableDummyRow"
                className={`${dataClassName} collapsible-table-dummy-row`}
                data={dummyRow}
                dummy // Include the wider expanded toggle switch
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            />
        );
    }

    render() {
        const data = this.data();
        const haveData = data.length > 1;
        const { expanded } = this.state;
        const {
            headerClassName, dangerouslySetInnerHTML, tableClassName, useDummy, eltId,
        } = this.props;

        let className = 'collapsible-table';
        if (tableClassName) {
            className += ` ${tableClassName}`;
        }
        if (!expanded && haveData && useDummy) {
            // Collapsed table style hides the dummy row and adjusts
            // margin-bottom - only do this if there's data
            className += ' collapsible-table-collapsed-with-dummy';
        }
        return (
            <table id={eltId} className={className}>
                <tbody>
                    <CollapsibleTableHeader
                        key={this.rowKey(0)}
                        className={headerClassName}
                        data={data[0]}
                        title={this.rowTitle(0)}
                        expanded={expanded}
                        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                        onToggle={haveData ? this.onToggle : null}
                    />
                    {
                        this.renderRows(haveData)
                    }
                </tbody>
            </table>
        );
    }
}

function CollapsibleTableHeader({
    onToggle, className, title, expanded, data, dangerouslySetInnerHTML,
}) {
    return (
        <tr onClick={onToggle} className={className} title={title}>
            <th key="-1">
                {
                    onToggle
                        && <CollapsibleTableToggle expanded={expanded} onClick={onToggle} />
                }
            </th>
            {
                data.map(
                    (item, index) => (
                        <th key={`Header_${index.toString()}`}>
                            <CollapsibleTableCell
                                data={item}
                                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                            />
                        </th>
                    ),
                )
            }
        </tr>
    );
}

function CollapsibleTableDataRow(props) {
    const {
        data, classes: classesIn={}, className, title, dummy, dangerouslySetInnerHTML,
    } = props;
    const classes = {
        tr: `${className} ${classesIn.tr || ''}`,
        th: classesIn.th || undefined,
        td: classesIn.td || undefined,
    };
    return (
        <tr className={classes.tr} title={title}>
            <th key="-1">
                {
                    // To maximize width, dummy data row needs expanded toggle
                    dummy && <CollapsibleTableToggle expanded />
                }
            </th>
            {
                data.map((item, index) => (index === 0
                    ? (
                        <th key={`Column_${index.toString()}`} className={classes.th}>
                            <CollapsibleTableCell
                                data={item}
                                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                            />
                        </th>
                    )
                    : (
                        <td key={`Column_${index.toString()}`} className={classes.td}>
                            <CollapsibleTableCell
                                data={item}
                                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                            />
                        </td>
                    )))
            }
        </tr>
    );
}

function CollapsibleTableCell({ data, dangerouslySetInnerHTML }) {
    if (React.isValidElement(data)) {
        return data;
    } else if (dangerouslySetInnerHTML) {
        return (<span dangerouslySetInnerHTML={{ __html: data }} />);
    } else {
        return (<span>{data}</span>);
    }
}

function CollapsibleTableToggle({ expanded }) {
    const toggleClass = expanded ? 'fa fa-caret-down' : 'fa fa-caret-right';
    return <i className={`collapsible-table-toggle ${toggleClass}`} />;
}
