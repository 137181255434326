/* Sketcher.jsx */

import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
// Material UI
import DialogActions from '@mui/material/DialogActions';
// Local
import { UserActions } from 'BMapsCmds';
import { MolDataSource, MoleculeLoadOptions } from 'BMapsSrc/utils';
import TextField from './ui/common/TextFields';
import { DialogSubmitButton, DialogCancelButton } from './ui/common/DialogActionButtons';
import { Ketcher } from './Ketcher';
import { EventBroker } from './eventbroker';
import { ModalManager } from './ui/ModalManager';
import LinkLikeButton from './ui/common/LinkLikeButton';
import { App } from './BMapsApp';
import { ProteinSelectWithFormik } from './ui/common/ProteinSelect';
import ProteinContextProvider, { ProteinContext } from './ui/common/ProteinContextProvider';
import { replaceNameInMolText } from './util/mol_format_utils';

const safeNameRegex = /[^A-Za-z0-9_.-]/g; // Invalid chars: Run on input to remove bad chars
const sketchNameAllowedCharDesc = 'alphanumeric or _ - .'; // User friendly error msg for invalid input
const sketchNameMaxLength = 128;

EventBroker.subscribe('askSketcher', (_, { compound }) => Sketcher.Display(compound));

function safeName(name='') {
    if (!name) return '';
    return name
        .substr(0, sketchNameMaxLength)
        .replace(safeNameRegex, '_');
}

function loadSketch(caseData, compoundNameIn, molTextIn, initialCmpd) {
    const compoundName = safeName(compoundNameIn);
    const molText = replaceNameInMolText(molTextIn, compoundName);
    const modifiedFrom = initialCmpd && initialCmpd.resSpec;
    const molSource = MolDataSource.FromSketcher(compoundName, 'mol', molText, modifiedFrom);
    const molLoadOptions = new MoleculeLoadOptions({ alignAction: 'align', gen3d: true });
    if (initialCmpd) {
        molLoadOptions.addOptions({ refSpec: initialCmpd.resSpec, preAlign: true });
    }
    UserActions.LoadMolData(molSource, molLoadOptions, caseData);
}

/* Sketcher
 * A React component to wrap a sketcher.
 * ProteinContextProvider is wrapped around the whole Sketcher component to provide
 * the protein context outside of the render method.
 * Does not require a specific sketcher implementation.  This uses Marvin.
 * Props:
 *     onSubmit : function to be called when the submit compound button is clicked
 *          function signature: onSubmit(compoundName, molText, startingCompound)
 */
export class Sketcher extends React.Component {
    static Display(compound) {
        const protein = compound ? App.getDataParents(compound).mapCase : null;
        ModalManager.Show({
            title: 'Compound Editor',
            content: (
                <ProteinContextProvider initialProtein={protein}>
                    <Sketcher
                        onSubmit={loadSketch}
                        startingCompound={compound}
                    />
                </ProteinContextProvider>
            ),
            titleAdditions: <UploadInsteadButton />,
            style: { height: '80%', width: '90vw' },
        });
    }

    static Hide() {
        ModalManager.Hide();
    }

    constructor(props) {
        super(props);
        const { startingCompound } = this.props;
        this.state = {
            startingCompound: startingCompound || null,
            currentMol: null,
        };
        this.handleMolChange = this.handleMolChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.close = this.close.bind(this);
        this.formValidate = this.formValidate.bind(this);
        this.validateProtein = this.validateProtein.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { startingCompound } = this.state;
        return startingCompound !== nextState.startingCompound;
    }

    handleMolChange(mol) {
        this.setState({ currentMol: mol });
    }

    handleSubmit(formValues) {
        const { compoundName } = formValues;
        const { onSubmit } = this.props;
        const {
            currentMol, startingCompound: startCmpd,
        } = this.state;
        const { selectedCaseData } = this.context; // ProteinContext is used here
        const caseData = startCmpd ? App.getDataParents(startCmpd).caseData : selectedCaseData;
        onSubmit(caseData, compoundName, currentMol, startCmpd);
        this.close();
    }

    close() {
        Sketcher.Hide();
    }

    validateCompoundName(name) {
        if (name.length > sketchNameMaxLength) {
            return `Max ${sketchNameMaxLength} characters!`;
        }

        return name !== '' && !/^[a-z0-9_\-.]+$/i.test(name) ? `Invalid characters. Allowed: ${sketchNameAllowedCharDesc}` : undefined;
    }

    validateProtein() {
        // Make sure there's a selected caseData
        const { selectedCaseData } = this.context;
        return !selectedCaseData ? 'Choose a protein to add the compound to' : '';
    }

    formValidate(formValues) {
        const errors = {};
        let val = this.validateCompoundName(formValues.compoundName);
        if (val) {
            errors.compoundName = val;
        }

        val = this.validateProtein();
        if (val) errors.selectedProtein = val;

        return errors;
    }

    render() {
        const { startingCompound } = this.state;
        const { selectedProtein } = this.context;
        const startingMolText = startingCompound?.getMol2000();
        return (
            <div id="sketcher_wrapper">
                <div id="sketcher_content">
                    <Ketcher iframeId="sketcherFrame" startingMolText={startingMolText} onMolChange={this.handleMolChange} />
                </div>
                <Formik
                    initialValues={{
                        compoundName: '',
                        selectedProtein: selectedProtein?.uri || '',
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validate={this.formValidate}
                    onSubmit={this.handleSubmit}
                >
                    <Form id="newCompoundInfo">
                        {
                            !startingCompound
                            && (
                                <ProteinSelectWithFormik
                                    name="selectedProtein"
                                    validate={this.validateProtein}
                                />
                            )
                        }
                        <TextField
                            fullWidth
                            name="compoundName"
                            type="text"
                            label="Compound Name (optional)"
                            inputProps={{
                                maxLength: sketchNameMaxLength,
                                title: `Max ${sketchNameMaxLength} chars, ${sketchNameAllowedCharDesc}`,
                            }}
                            variant="outlined"
                            validate={this.validateCompoundName}
                        />
                        <DialogActions>
                            <DialogCancelButton onClick={this.close}>Cancel</DialogCancelButton>
                            <DialogSubmitButton>Submit</DialogSubmitButton>
                        </DialogActions>
                    </Form>
                </Formik>
            </div>
        );
    }
}

/*
    TODO: find another way of accessing the context or refactor Sketcher to a functional component
        to avoid using `Sketcher.contextType = ProteinContext;`
*/
Sketcher.contextType = ProteinContext;

/**
 * A button to switch from Sketcher to Import tab
 */
const UploadInsteadButton = styled(({ className }) => (
    <LinkLikeButton
        className={className}
        onClick={() => { Sketcher.Hide(); UserActions.OpenImport(); }}
    >
        <i className="fa fa-upload" />
        {' '}
        Upload or enter data instead
    </LinkLikeButton>
))`
font-size: small;
font-weight: normal;
background-color: rgba(255, 255, 255, 0.8);
border-radius: 0.5em;
`;
