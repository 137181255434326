// server_cmds.js

// Send commands to the server.
// ...
/**
 * @typedef {import('BMapsSrc/utils').MoleculeLoadOptions} MoleculeLoadOptions
 * @typedef {import('BMapsSrc/utils').MolDataSource} MolDataSource
 */
import { App } from './BMapsApp';
import { ResponseIds } from './server/server_common';

// Poor man's enum.
const msgIDs = Object.freeze({ load_molecule_msg: 14, translate_molecule_msg: 15 });

export class ServerCmd {
    static get ScalarResponse() { return true; }
    static get ArrayResponse() { return false; }

    constructor(cmdName, cmdFn, scalarResponse=ServerCmd.ArrayResponse, handlerNames) {
        this.cmdName = cmdName;
        this.cmdFn = cmdFn;
        this.scalarResponse = scalarResponse;
        this.handlerNames = handlerNames;
    }

    // Returns command data with arguments and adds requestId
    cmdData(...args) {
        let data = this.cmdFn(...args);
        data = ServerCmd.withRequestId(this.cmdName, data);
        return data;
    }

    static GetRequestId(cmdName) {
        if (!ServerCmd.requestCounts) ServerCmd.requestCounts = {};
        const commandCount = ServerCmd.requestCounts[cmdName] || 0;
        const requestId = `#${cmdName}${commandCount}`;
        ServerCmd.requestCounts[cmdName] = commandCount+1;
        return requestId;
    }

    static withRequestId(cmdName, cmdDataIn) {
        const requestId = ServerCmd.GetRequestId(cmdName);
        let cmdData = cmdDataIn;

        // Add request id before sending
        if (cmdData instanceof ArrayBuffer) { // binary command
            cmdData = this.addRequestIdToBinaryCmd(requestId, cmdData);
        } else {
            cmdData = `${requestId} ${cmdData}`;
        }

        return [requestId, cmdData];
    }

    static addRequestIdToBinaryCmd(requestId, data) {
        const newSize = requestId.length + 1 + data.byteLength;
        const payload = new Uint8Array(newSize);
        const sendit = (x) => {
            for (const i of Object.keys(x)) { payload[index++] = x[i]; }
        };
        const sendstr = (s) => {
            for (const i of Object.keys(s)) { payload[index++] = s[i].charCodeAt(0) & 255; }
            payload[index++] = 0;
        };
        let index = 0;
        sendstr(requestId);
        sendit(new Uint8Array(data));
        return payload.buffer;
    }

    label() {
        return this.cmdName.split('-').map(
            (x) => `${x.slice(0, 1).toUpperCase()}${x.slice(1)}`,
        ).join(' ');
    }
}

export const ServerCmds = {
    Terminate: new ServerCmd(
        'terminate',
        cmdTextTerminate,
        ServerCmd.ArrayResponse,
        [],
    ),
    ListMaps: new ServerCmd(
        'list-maps',
        cmdTextListMaps,
        ServerCmd.ArrayResponse,
        [ResponseIds.MapList],
    ),
    GetPermissions: new ServerCmd(
        'get-permissions',
        cmdTextGetPermissions,
        ServerCmd.ScalarResponse,
        [ResponseIds.SetPermissions],
    ),
    ZapAll: new ServerCmd(
        'zap-all',
        cmdTextZapAll,
        ServerCmd.ScalarResponse,
        [ResponseIds.Zapped],
    ),
    GetCaseFiles: new ServerCmd(
        'get-case-files',
        cmdTextGetCaseFiles,
        ServerCmd.ArrayResponse,
        [
            ResponseIds.Solute,
            ResponseIds.Fragments,
            ResponseIds.AtomGroupInfo,
            ResponseIds.Compound2D,
            ResponseIds.EnergiesForLigand,
            ResponseIds.SolvationForLigand,
        ],
    ),
    ValidateProjectCase: new ServerCmd(
        'validate-project-case',
        cmdTextValidateProjectCase,
        ServerCmd.ArrayResponse,
        [ResponseIds.ProjectCaseStatus],
    ),
    WriteProjectCase: new ServerCmd(
        'write-project-case',
        cmdTextWriteProjectCase,
        ServerCmd.ArrayResponse,
        [ResponseIds.ProjectCaseStatus],
    ),
    RunProjectCase: new ServerCmd(
        'run-project-case',
        cmdTextRunProjectCase,
        ServerCmd.ArrayResponse,
        [],
    ),
    RefreshFragments: new ServerCmd(
        'refresh-fragments',
        cmdTextRefreshFragments,
        ServerCmd.ScalarResponse,
        [],
    ),
    GetAvailableFragments: new ServerCmd(
        'get-available-fragments',
        cmdTextGetAvailableFragments,
        ServerCmd.ArrayResponse,
        [ResponseIds.AvailableFragments],
    ),
    LoadFragments: new ServerCmd(
        'load-fragments',
        cmdTextLoadFragments,
        ServerCmd.ScalarResponse,
        [],
    ),
    GetSnapshot: new ServerCmd(
        'get-fragment-snapshot',
        cmdTextGetSnapshot,
        ServerCmd.ArrayResponse,
        [],
    ),
    GetFragmentMap: new ServerCmd(
        'get-fragment-map',
        cmdTextGetFragmentMap,
        ServerCmd.ArrayResponse,
        [],
    ),
    TranslateMolecule: new ServerCmd(
        'translate-molecule',
        cmdTextTranslateMolecule,
        ServerCmd.ScalarResponse,
        [ResponseIds.TranslateMolecule],
    ),
    LoadMolData: new ServerCmd(
        'load-mol-data',
        cmdTextLoadMolData,
        ServerCmd.ArrayResponse,
        [
            ResponseIds.AtomGroupInfo,
            ResponseIds.Compound,
            ResponseIds.Compound2D,
        ],
    ),
    DockCompound: new ServerCmd(
        'dock-compound',
        cmdTextDockCompound,
        ServerCmd.ArrayResponse,
        [],
    ),
    GetSolvationForLigand: new ServerCmd(
        'get-solvation-for-ligand',
        cmdTextGetSolvationForLigand,
        ServerCmd.ArrayResponse,
        [],
    ),
    GetForcefieldParamsForLigand: new ServerCmd(
        'get-forcefield-params',
        cmdTextGetForcefieldParamsForLigand,
        ServerCmd.ArrayResponse,
        [],
    ),
    GetEnergiesForLigand: new ServerCmd(
        'get-energies-for-ligand',
        cmdTextGetEnergiesForLigand,
        ServerCmd.ArrayResponse,
        [],
    ),
    Select: new ServerCmd(
        'select',
        cmdTextSelect,
        ServerCmd.ScalarResponse,
        [],
    ),
    SelectAtom: new ServerCmd(
        'select-atom',
        cmdTextSelectAtom,
        ServerCmd.ScalarResponse,
        [ResponseIds.Selection],
    ),
    ReplaceGroup: new ServerCmd(
        'replace-group',
        cmdTextReplaceGroup,
        ServerCmd.ArrayResponse,
        [],
    ),
    FindModifications: new ServerCmd(
        'find-modifications',
        cmdTextFindModifications,
        ServerCmd.ArrayResponse,
        [],
    ),
    EnergyMinimize: new ServerCmd(
        'energy-minimize',
        cmdTextEnergyMinimize,
        ServerCmd.ArrayResponse,
        [],
    ),
    SelectModification: new ServerCmd(
        'select-modification',
        cmdTextSelectModification,
        ServerCmd.ArrayResponse,
        [],
    ),
    ExportSelection: new ServerCmd(
        'export-selection',
        cmdTextExportSelection,
        ServerCmd.ScalarResponse,
        [],
    ),
    MoleculeServiceRequest: new ServerCmd(
        'mol-service-request',
        cmdTextMoleculeServiceRequest,
        ServerCmd.ArrayResponse,
        [],
    ),
    LoadPdbId: new ServerCmd(
        'load-pdb-id',
        cmdTextLoadPdbId,
        ServerCmd.ArrayResponse,
        [
            ResponseIds.Solute,
            ResponseIds.Fragments,
            ResponseIds.AtomGroupInfo,
            ResponseIds.Compound2D,
            ResponseIds.EnergiesForLigand,
            ResponseIds.SolvationForLigand,
        ],
    ),
    GetMoleculeProperties: new ServerCmd(
        'get-molecule-properties',
        cmdTextGetMoleculeProperties,
        ServerCmd.ScalarResponse,
        [ResponseIds.GetMoleculeProperties],
    ),
    FragSearchRadius: new ServerCmd(
        'frag-search-radius',
        cmdTextFragSearchRadius,
        ServerCmd.ScalarResponse,
        [],
    ),
    LoadPdbString: new ServerCmd(
        'load-pdb-string',
        cmdTextLoadPdbString,
        ServerCmd.ArrayResponse,
        [
            ResponseIds.Solute,
            ResponseIds.Fragments,
            ResponseIds.AtomGroupInfo,
            ResponseIds.Compound2D,
            ResponseIds.EnergiesForLigand,
            ResponseIds.SolvationForLigand,
        ],
    ),
    LoadStarterCompounds: new ServerCmd(
        'load-starter-compounds',
        cmdTextLoadStarterCompounds,
        ServerCmd.ArrayResponse,
        [ResponseIds.Compound],
    ),
    RenameCompound: new ServerCmd(
        'rename-compound',
        cmdTextRenameCompound,
        ServerCmd.ArrayResponse,
        [ResponseIds.Compound],
    ),
    CopyCompound: new ServerCmd(
        'copy-compound',
        cmdTextCopyCompound,
        ServerCmd.ArrayResponse,
        [ResponseIds.Compound],
    ),
    MakeClusterMap: new ServerCmd(
        'make-clustermap',
        cmdTextMakeClusterMap,
        ServerCmd.ArrayResponse,
        [ResponseIds.ClusterMap],
    ),
    RemoveCompound: new ServerCmd(
        'remove-compound',
        (compound) => `remove-compound ${compound.resSpec}`,
        ServerCmd.ArrayResponse,
        [],
    ),
    FragDataQuery: new ServerCmd(
        'frag-data-query',
        (queryObj) => `frag-data-query ${JSON.stringify(queryObj)}`,
        ServerCmd.ArrayResponse,
        [],
    ),
    AssembleCompound: new ServerCmd(
        'assemble-compound',
        (argObj) => `assemble-compound ${JSON.stringify(argObj)}`,
        ServerCmd.ArrayResponse,
        [],
    ),
    AlignProtein: new ServerCmd(
        'align-protein',
        (argObj) => `align-protein ${JSON.stringify(argObj)}`,
        ServerCmd.ArrayResponse,
        [],
    ),
    ApplyTransform: new ServerCmd(
        'apply-transform',
        (argObj) => `apply-transform ${JSON.stringify(argObj)}`,
        ServerCmd.ArrayResponse,
        [],
    ),
    ReplaceWithFragset: new ServerCmd(
        'replace-with-fragment-set',
        (argObj) => `replace-with-fragment-set ${JSON.stringify(argObj)}`,
        ServerCmd.ArrayResponse,
        [],
    ),
    SetActiveSolute: new ServerCmd(
        'set-active-solute',
        (requestString) => `set-active-solute ${requestString}`,
        ServerCmd.ArrayResponse,
        [],
    ),
    GetUnboundConformations: new ServerCmd(
        'get-unbound-conformations',
        (values) => `get-unbound-conformations ${JSON.stringify(values)}`,
        ServerCmd.ScalarResponse,
        [],
    ),
    FoldProtein: new ServerCmd(
        'fold-protein',
        (values) => `fold-protein ${JSON.stringify(values)}`,
        ServerCmd.ArrayResponse,
        [],
    ),
};
// Type definition for commands used by DataConnection.js
/**
 * @typedef {Object} DataConnectionCmds
 * @property {ServerCmd} cmdTerminate
 * @property {ServerCmd} cmdListMaps
 * @property {ServerCmd} cmdGetPermissions
 * @property {ServerCmd} cmdZapAll
 * @property {ServerCmd} cmdGetCaseFiles
 * @property {ServerCmd} cmdValidateProjectCase
 * @property {ServerCmd} cmdWriteProjectCase
 * @property {ServerCmd} cmdRunProjectCase
 * @property {ServerCmd} cmdRefreshFragments
 * @property {ServerCmd} cmdGetAvailableFragments
 * @property {ServerCmd} cmdLoadFragments
 * @property {ServerCmd} cmdGetSnapshot
 * @property {ServerCmd} cmdGetFragmentMap
 * @property {ServerCmd} cmdTranslateMolecule
 * @property {ServerCmd} cmdLoadMolData
 * @property {ServerCmd} cmdDockCompound
 * @property {ServerCmd} cmdGetSolvationForLigand
 * @property {ServerCmd} cmdGetForcefieldParamsForLigand
 * @property {ServerCmd} cmdGetEnergiesForLigand
 * @property {ServerCmd} cmdSelect
 * @property {ServerCmd} cmdSelectAtom
 * @property {ServerCmd} cmdReplaceGroup
 * @property {ServerCmd} cmdFindModifications
 * @property {ServerCmd} cmdEnergyMinimize
 * @property {ServerCmd} cmdSelectModification
 * @property {ServerCmd} cmdExportSelection
 * @property {ServerCmd} cmdMoleculeServiceRequest
 * @property {ServerCmd} cmdLoadPdbId
 * @property {ServerCmd} cmdGetMoleculeProperties
 * @property {ServerCmd} cmdFragSearchRadius
 * @property {ServerCmd} cmdLoadPdbString
 * @property {ServerCmd} cmdLoadStarterCompounds
 * @property {ServerCmd} cmdRenameCompound
 * @property {ServerCmd} cmdCopyCompound
 * @property {ServerCmd} cmdMakeClusterMap
 * @property {ServerCmd} cmdRemoveCompound
 * @property {ServerCmd} cmdFragDataQuery
 * @property {ServerCmd} cmdAssembleCompound
 * @property {ServerCmd} cmdAlignProtein
 * @property {ServerCmd} cmdApplyTransform
 * @property {ServerCmd} cmdReplaceWithFragset
 * @property {ServerCmd} cmdSetActiveSolute
 */

/* Functions that produce the text of commands */

function cmdTextTerminate() {
    return 'terminate';
}

function cmdTextListMaps() {
    return 'list-maps';
}

function cmdTextGetPermissions() {
    return 'get-permissions';
}

function cmdTextZapAll() {
    return 'zap-all';
}

function cmdTextGetCaseFiles(spec, options={}) {
    // spec is <solute>/<case>, e.g. pcsk9/3gcw-HGS225opt+loop13_all_frags
    const [projCaseSpec, mountSpec] = spec.split(' ');
    const [projName, caseName] = projCaseSpec.split('/'); // solute or project name
    const getCaseArgs = { project: projName, case: caseName };
    if (mountSpec) getCaseArgs.mount_index = Number(mountSpec.substr(5));
    if (options.fragmentLoading) getCaseArgs.fragmentLoading = options.fragmentLoading;
    return `get-case-files ${JSON.stringify(getCaseArgs)}`;
}

function cmdTextValidateProjectCase(argsObj) {
    return `validate-project-case ${JSON.stringify(argsObj)}`;
}

function cmdTextWriteProjectCase(argsObj) {
    return `write-project-case ${JSON.stringify(argsObj)}`;
}

function cmdTextRunProjectCase(argsObj) {
    return `run-project-case ${JSON.stringify(argsObj)}`;
}

function cmdTextRefreshFragments() {
    // No arguments yet.
    return 'refresh-fragments';
}

function cmdTextGetAvailableFragments(mapCase) {
    console.log(`Requesting available fragments for ${mapCase.projectCase} (needs to be implemented in the server)`);
    return 'get-available-fragments';
}

function cmdTextLoadFragments(argsObj) {
    return `load-fragments ${JSON.stringify(argsObj)}`;
}

function cmdTextGetFragmentMap(argsObj) {
    // argsObj = {'name': fragName};
    return `get-fragment-map ${JSON.stringify(argsObj)}`;
}

function cmdTextGetSnapshot(argsObj) {
    // argsObj = {'fragment-series': fragSeries, 'B-value': B, 'snapshot-number': snapnum};
    return `get-fragment-snapshot ${JSON.stringify(argsObj)}`;
}

function cmdTextTranslateMolecule(molID, inType, moldata, outType) {
    // Send output type in the align action string.
    console.log(`Preparing translate-molecule command for ${molID} input type ${inType} output type ${outType}`);
    return cmdMoleculeCommon(molID, inType, moldata, outType, msgIDs.translate_molecule_msg);
}

/**
 * @param {MolDataSource} molSource
 * @param {MoleculeLoadOptions} loadOptions
 * @returns {string}
 */
function cmdTextLoadMolData(molSource, loadOptions) {
    const cmdObj = {
        molSource: molSource.forBfdServer(),
        alignAction: loadOptions.toOldString(),
        loadOptions: loadOptions.options,
    };
    console.log(`Preparing load-mol-data command for ${molSource.label()} with options ${JSON.stringify(loadOptions.options)}`);
    return `load-mol-data ${JSON.stringify(cmdObj)}`;
}

function cmdMoleculeCommon(molID, moltype, moldataIn, alignAction, cmd) {
    const encoder = new TextEncoder();
    let moldata = moldataIn;
    if (typeof (moldata) === 'string') moldata = encoder.encode(moldata);
    const encodedMolId = encoder.encode(molID.replace(/\s+/g, '_'));

    const size = 2 + (encodedMolId.length + 1)
        + (moltype.length + 1) + (alignAction.length + 1) + moldata.length;
    const payload = new Uint8Array(size);
    let index = 0;
    const sendit = (x) => {
        for (const i of Object.keys(x)) { payload[index++] = x[i]; }
    };
    const sendencodedstr = (x) => {
        for (const i of Object.keys(x)) { payload[index++] = x[i]; }
        payload[index++] = 0;
    };
    const sendstr = (s) => {
        for (const i of Object.keys(s)) { payload[index++] = s[i].charCodeAt(0) & 255; }
        payload[index++] = 0;
    };

    // two bytes for command id
    payload[index++] = cmd;
    payload[index++] = 0;
    sendencodedstr(encodedMolId);
    sendstr(moltype);
    sendstr(alignAction);
    sendit(moldata);
    return payload.buffer;
}

function cmdTextDockCompound(requestObj) {
    return `dock-compound ${JSON.stringify(requestObj)}`;
}

// Deprecated (soon).
function cmdTextGetSolvationForLigand(requestObj) {
    return `get-solvation-for-ligand ${JSON.stringify(requestObj)}`;
}

// Returns partial charges and amber atom types.
function cmdTextGetForcefieldParamsForLigand(compound) {
    return `get-forcefield-params ${compound.resSpec}`;
}

// Args can request only specific energies and provide parameters for the energy calc
// [document].
function cmdTextGetEnergiesForLigand(requestObj) {
    // return `get-energies-for-ligand ${ligandID} ${args.join(' ')}`;
    return `get-energies-for-ligand ${JSON.stringify(requestObj)}`;
}

function cmdTextSelect(query) {
    // Add "select" command name if necessary
    const select = 'select ';
    return query.startsWith(select) ? query : `${select}${query}`;
}

function cmdTextSelectAtom(atom, buttons={}) {
    const atomSerialNum = atom ? atom.uniqueID : -1;
    let buttonStr = '';
    if (atomSerialNum !== -1 && buttons) {
        if (buttons.ctrlKey) { buttonStr = 'ctrl'; }
        if (buttons.shiftKey) { buttonStr = (buttonStr.length > 0) ? `${buttonStr}+shift` : 'shift'; }
        if (buttons.altKey) { buttonStr = (buttonStr.length > 0) ? `${buttonStr}+alt` : 'alt'; }
    }
    return `select-atom ${atomSerialNum} ${buttonStr}`;
}

function cmdTextReplaceGroup(replacementName, atomUid) {
    return `replace-group ${replacementName} ${atomUid}`;
}

// find-modifications: wiki: BMaps_-_bfd-server_protocols#find-modifications
function cmdTextFindModifications({ findType, scope, searchParams={} }) {
    const requestObj = {
        findType,
        scope,
        searchParams, // { baseAtomUid, ?terminalAtomUid, ?searchRadius }
    };
    return `find-modifications ${JSON.stringify(requestObj)}`;
}

function cmdTextEnergyMinimize(requestObj) {
    // requestObj = {
    //    cmpdSpec,         ligand spec (i.e. ANP.301:A) or compound name
    //    minType,          Rigid | Flexible
    //    minEngine,        BFD | MM
    //    energyEnging,     BFD | MM
    // }
    return `energy-minimize ${JSON.stringify(requestObj)}`;
}

function cmdTextSelectModification(modType, suggestionID) {
    return `select-modification ${modType} ${suggestionID}`;
}

function cmdTextExportSelection(fileType, ...args) {
    // Requests text of a file of type fileType from the current selection.  Note,
    // anything, such as a filename to be passed along to the export-contents message
    // responder, can be supplied as extra arguments, and is not used by the server.
    return `export-selection ${fileType} ${args}`;
}

function cmdTextMoleculeServiceRequest(service, request) {
    return `mol-service-request ${service} ${request}`;
}

function cmdTextLoadPdbId({ pdbId, loadOptions={} }) {
    const cmdObj = {
        pdbId,
        loadOptions,
        postprocessing: loadOptions.postprocessing, // deprecated
    };
    console.log(`Preparing load-pdb-id command for ${pdbId}`);
    return `load-pdb-id ${JSON.stringify(cmdObj)}`;
}

function cmdTextGetMoleculeProperties(spec) {
    return `get-molecule-properties ${spec}`;
}

function cmdTextFragSearchRadius(radius) {
    return `frag-search-radius ${radius}`;
}

function cmdTextLoadPdbString({
    data, pdbId, moleculeName, fileType, loadOptions={},
}) {
    const requestObj = {
        data,
        pdbId: moleculeName, // This difference will be corrected
        moleculeName,
        fileType,
        loadOptions,
        postprocessing: loadOptions.postprocessing, // deprecated

    };
    return `load-pdb-string ${JSON.stringify(requestObj)}`;
}

function cmdTextLoadStarterCompounds() {
    return 'load-starter-compounds';
}

function cmdTextRenameCompound(compound, newName) {
    return `rename-compound ${compound.resSpec} ${newName}`;
}

function cmdTextCopyCompound(compound) {
    return `copy-compound ${compound.resSpec}`;
}

function cmdTextMakeClusterMap(requestObj) {
    return `make-clustermap ${JSON.stringify(requestObj)}`;
}

/* Wrappers that actually send commands */

function sendBFMCmd(cmd) {
    App.ServerConnection.sendCmd(cmd);
}

export function cmdListMaps() {
    cmdTextListMaps();
}
export function cmdZapAll() {
    sendBFMCmd(cmdTextZapAll());
}
export function cmdGetCaseFiles(spec) {
    sendBFMCmd(cmdTextGetCaseFiles(spec));
}
export function cmdGetSnapshot(uri, B, fragment, snapnum) {
    sendBFMCmd(cmdTextGetSnapshot(uri, B, fragment, snapnum));
}
export function cmdTranslateMolecule(molID, inType, moldata, outType) {
    sendBFMCmd(cmdTextTranslateMolecule(molID, inType, moldata, outType));
}
export function cmdDockCompound(compoundSpec, ...args) {
    sendBFMCmd(cmdTextDockCompound(compoundSpec, ...args));
}
export function cmdGetSolvationForLigand(ligandID) {
    sendBFMCmd(cmdTextGetSolvationForLigand(ligandID));
}
export function cmdGetForcefieldParamsForLigand(compound) {
    sendBFMCmd(cmdTextGetForcefieldParamsForLigand(compound));
}
export function cmdGetEnergiesForLigand(requestObj) {
    sendBFMCmd(cmdTextGetEnergiesForLigand(requestObj));
}
export function cmdSelect(query) {
    sendBFMCmd(cmdTextSelect(query));
}
export function cmdSelectAtom(atom, buttons) {
    sendBFMCmd(cmdTextSelectAtom(atom, buttons));
}
export function cmdReplaceGroup(replacementName, atomUid) {
    sendBFMCmd(cmdTextReplaceGroup(replacementName, atomUid));
}
export function cmdFindModifications(modType, atomUidList) {
    sendBFMCmd(cmdTextFindModifications(modType, atomUidList));
}
export function cmdEnergyMinimize(requestObj) {
    sendBFMCmd(cmdTextEnergyMinimize(requestObj));
}
export function cmdSelectModification(modID) {
    sendBFMCmd(cmdTextSelectModification(modID));
}
export function cmdExportSelection(fileType, ...args) {
    sendBFMCmd(cmdTextExportSelection(fileType, ...args));
}
export function cmdMoleculeServiceRequest(service, request) {
    sendBFMCmd(cmdTextMoleculeServiceRequest(service, request));
}
export function cmdLoadPdbId(pdbId) {
    sendBFMCmd(cmdTextLoadPdbId(pdbId));
}
export function cmdFragSearchRadius(radius) {
    sendBFMCmd(cmdTextFragSearchRadius(radius));
}
export function cmdLoadStarterCompounds() {
    sendBFMCmd(cmdTextLoadStarterCompounds());
}
