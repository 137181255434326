/**
 * @fileoverview Manage bfd-server connections
 */

// Callers of httpGet and httpPost expect rejects to have an object with the request status code.
// This functionality should probably be migrated to fetch; not worth fixing now.
/* eslint-disable prefer-promise-reject-errors */

import { WebServices } from '../WebServices';
import { SessionErrorType } from './session_utils';

// adapted from WebServices.js
function httpGet(url) {
    return new Promise(
        (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('load', function onLoad() {
                if (this.status >= 200 && this.status < 300) {
                    resolve(this.responseText);
                } else {
                    reject({ status: this.status, statusText: this.statusText });
                }
            });
            xhr.addEventListener('error', function onError() {
                reject({ status: this.status, statusText: this.statusText });
            });
            xhr.open('GET', url);
            xhr.send();
        },
    );
}

function httpPost(url, postData, contentType='application/x-www-form-urlencoded') {
    return new Promise(
        (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('load', function onLoad() {
                if (this.status >= 200 && this.status < 300) {
                    resolve(this.responseText);
                } else {
                    reject({ status: this.status, statusText: this.statusText });
                }
            });
            xhr.addEventListener('error', function onError() {
                reject({ status: this.status, statusText: this.statusText });
            });
            xhr.open('POST', url);
            xhr.setRequestHeader('Content-Type', contentType);
            xhr.send(postData);
        },
    );
}

export class SessionErrorInfo {
    constructor(msg, title, type) {
        this.errType = type;
        this.errTitle = title;
        this.errMsg = msg;
    }

    toString() {
        return `${this.errTitle}: ${this.errMsg}`;
    }
}

// adapted from portal/js/landing.js
function getErrorMessage(errCode) {
    const tryAgain = "<div>Please try again later or let us know at <a href='mailto:support@coniferpoint.com'>support@coniferpoint.com</a></div>";

    switch (errCode) {
        case 200: // 200 is not an error code
            return new SessionErrorInfo('', '');
        case 400:
        case 401:
        case 409:
            return new SessionErrorInfo(
                "Log in to use Boltzmann Maps.<br><br>If you are unable to log in, please contact <a href='mailto:support@coniferpoint.com'>support@coniferpoint.com</a>",
                'Login Needed',
                SessionErrorType.CantStart_NotLoggedIn,
            );
        case 499:
            return new SessionErrorInfo(
                'You are using all your allowed Boltzmann Maps sessions. Please close one of your tabs and try again or upgrade your subscription to get more sessions.',
                'Session Limit Reached',
                SessionErrorType.CantStart_SessionLimitReached,
            );
        case 500:
            return new SessionErrorInfo(
                `There was an internal server error. ${tryAgain}`,
                'Server Unavailable',
                SessionErrorType.CantStart_ServerUnavailable,
            );
        case 502:
            return new SessionErrorInfo(
                `The Boltzmann Maps server is down. ${tryAgain}`,
                'Server Unavailable',
                SessionErrorType.CantStart_ServerUnavailable,
            );
        case 503:
            return new SessionErrorInfo(
                `The server is at maximum capacity. ${tryAgain}`,
                'Server Unavailable',
                SessionErrorType.CantStart_ServerUnavailable,
            );
        case 0:
            return new SessionErrorInfo(
                'Could not contact Boltzmann Maps server. Please check your network connection.',
                'Internet Disconnected',
                SessionErrorType.CantStart_NoInternet,
            );
        default:
            return new SessionErrorInfo(
                `Something went wrong while trying to contact the Boltzmann Maps server. ${tryAgain}`,
                'Server Unavailable',
                SessionErrorType.CantStart_Unknown,
            );
    }
}

/**
 * Are we in a deployment type that bypasses the slot mechanism to get bfd-server sessions?
 * Three connection scenarios don't use slots:
 * 1. Local development (addressed by 'localhost' clause below)
 * 2. Automated Mocha tests that spin up their own server processes (also addressed by 'localhost')
 * 3. dev-server sandbox mode (only jlkjr) (currently addressed by bmaps-debug clause)
 *
 * @returns {boolean}
 *
 * @todo Move this into WebSocketStrategy somehow
 * @todo Get rid of need to look for bmaps-debug.html
 */
function slotsNotApplicable() {
    return typeof window === 'undefined'
        || window.location.hostname === 'localhost'
        || window.location.pathname.indexOf('bmaps-debug.html') > -1;
}

/**
 * Request a bfd-server slot from exec
 * @param {boolean} replaceSession Should we terminate a recent session to make room for this one?
 * @returns { { status: number, success: boolean, errorInfo: SessionErrorInfo, data: object}}
 */
export async function startSlotSession(replaceSession) {
    // Try to ensure login cookie is up-to-date
    try {
        await WebServices.refreshCookie();
    } catch (ex) {
        console.warn(ex);
    }

    const query = replaceSession ? '?replaceSession' : '';
    const requestUrl = `../portal/startSession.php${query}`;

    let response;
    try {
        response = await httpGet(requestUrl);
    } catch (ex) {
        console.warn(`Error in GET request to ${requestUrl}: ${ex.statusText}`);
        const errCode = ex.status;
        return { status: errCode, success: false, errorInfo: getErrorMessage(errCode) };
    }

    const { status, data } = JSON.parse(response);
    if (status === 200) {
        // bmapsUserName should maybe also be moved out of session storage
        if (data.bmapsUserName) sessionStorage.setItem('bmapsUserName', data.bmapsUserName);
        return { status, success: true, data };
    } else {
        return { status, success: false, errorInfo: getErrorMessage(status) };
    }
}

/**
 * Confirm that a bfd-server session key is recognized by exec for an active slot
 * @param {*} sessionKey
 * @returns {Promise<boolean>} true if the bfd-server slot is still recognized
 * @todo This should only be called for sessions requested from exec (ie slotsNotApplicable===false)
 */
export async function hasSlot(sessionKey) {
    if (slotsNotApplicable()) return true;

    const result = await checkSession(sessionKey);

    return (result === 'Ok');
}

/**
 * @returns {Promise<boolean>} true if there is a login cookie
 */
export async function hasLogin() {
    if (slotsNotApplicable()) return true;

    // bmapsLogin cookie is not available to JavaScript, so ask the portal with checkSession.php.
    // "Not available" means there is a login but no slot matching the session key.
    // For this purpose, we just want to know if a valid login exists.

    const result = await checkSession();
    return result === 'Not available';
}

/**
 * checkSession.php returns:
 * - 'Ok' if the slot is active
 * - 'Not available' if there is a login but a slot cannot be found
 * - 'Unauthorized' if there is no login
 * @param {string} sessionKey a bfd-server session key to check for an active slot
 * @returns {'Ok'|'Not available'|'Unauthorized'}
 */
async function checkSession(sessionKey) {
    const requestUrl = '../portal/checkSession.php';

    let response;
    try {
        const body = sessionKey ? `bfdkey=${sessionKey}` : '';
        response = await httpPost(requestUrl, body);
    } catch (ex) {
        console.warn(`Error in POST request to ${requestUrl}: ${ex.statusText}`);
        return undefined;
    }

    const { result } = JSON.parse(response);
    return result;
}

/**
 * Release all server slots when closing tab or browser.
 *
 * @param {import('BMapsSrc/DataConnection').default[]} dataConnections
 * The original approach was to clear slots by hitting endSession.php via sendBeacon.
 * This resulted in unreleased slots when closing the entire browser, as opposed to just a tab.
 * The new approach is to send terminate commands to the server as well as use the sendBeacon.
 * The bagent-exec partership will release the slots when the bfd-server process exit is detected.
 *
 * The reason why both sendBeacon and server commands have to be used is because the
 * server command doesn't terminate the server session when the user refreshes the page, but the
 * sendBeacon does.
 */
export function endAllSlotSessions(dataConnections) {
    if (slotsNotApplicable()) return;

    for (const dataConnection of dataConnections) {
        const connector = dataConnection.getConnector();
        if (connector.getMode() === 'server') {
            connector.cmdTerminate();
            const { key } = connector.connectionInfo;
            const formData = new FormData();
            formData.set('bfdkey', key);
            // This is only called in a window unload handler, so navigator should be defined.
            // Don't protect against undefined since there is no other execution path;
            // better to throw exception.
            navigator.sendBeacon('../portal/endSession.php', formData);
        }
    }
}

// endSessionNow provides a promise that can be awaited to ensure the request
// to endSession.php finishes before the continuing
// However, in window.unload, the request may not be sent before the page unloads
export async function endSlotSessionNow(sessionKey) {
    if (slotsNotApplicable()) return;

    const body = `bfdkey=${sessionKey}`;
    const requestUrl = '../portal/endSession.php';
    try {
        await httpPost(requestUrl, body);
    } catch (ex) {
        console.warn(`Error in POST request to ${requestUrl}: ${ex.statusText}`);
    }
}
