/**
 * @fileoverview A class to present protein business objects in a tree
 */
import _ from 'lodash';
import { UserActions } from 'BMapsCmds';

/**
 * A class for presenting business objects (eg atom groups) or container as one or more tree nodes.
 *
 * getPxNode() returns a description that can be used by the px-components
 * tree control.
 */
export default class TreeNode {
    static get DefaultDisplayState() {
        return {
            isVisible: true,
            isExpanded: true,
            isSelected: false,
            isActive: false,
            isStarred: false,
            itemState: {},
            treeNodeState: {},
        };
    }

    /**
     * @param {{
     *   label: string,
     *   info: string,
     *   actions: [],
     *   item: *,
     *   children: TreeNode[]
     * }}
     */
    constructor({
        label, info, actions, item, children,
    }={}) {
        /** @type {string} */
        this.label = label;
        /**
         * This will get displayed in the info tooltip
         * @type {string}
         */
        this.info = info;
        /** @type {{label: string, onClick: function({treeItem: TreeNode}):void}[]} */
        this.actions = actions;
        /** @type {*} */
        this.item = item;
        /** @type {TreeNode[]} */
        this.children = children;
        /** @type {TreeRoot} */
        this.treeRoot = undefined; // Will be set by TreeRoot.setChildTreeRoots
    }

    // Potential overrides

    /**
     * Return menu items for this node
     */
    getActions({
        itemState, treeNodeState, isVisible, isSelected, isActive, isExpanded, isStarred,
    }) {
        return this.actions;
    }

    /**
     * Return an icon string for tree control column for this node
     * @param {string} column Column name (isVisible, name, info, isActive)
     * @param {*} param1
     * @returns {string} css class (Font Awesome)
     */
    getIcon(column, {
        itemState, treeNodeState, isVisible, isSelected, isActive, isExpanded, isStarred,
    }) {
        switch (column) {
            case 'isVisible':
                return 'fa fa-eye';
            default:
                return '';
        }
    }

    /**
     * Return tooltip for a certain column in the tree control for this node
     * @param {string} column Column name (isVisible, name, info, isActive)
     * @param {*} param1
     * @returns { string | *}
     */
    getTooltip(column, {
        itemState, treeNodeState, isVisible, isSelected, isActive, isExpanded, isStarred,
    }) {
        switch (column) {
            case 'isVisible':
                return isVisible
                    ? 'Hide'
                    : 'Show';
            default:
                return undefined;
        }
    }

    // Bookkeeping functions

    /** @param {TreeRoot} treeRoot */
    setTreeRoot(treeRoot) { this.treeRoot = treeRoot; }

    /**
     * Look up node state (selected, visible, active, expanded) with TreeRoot.getNodeState
     * @returns {{isVisible: boolean, isSelected: boolean, isActive: boolean, isExpanded: boolean,
     * isStarred: boolean,
     * itemState: object, treeNodeState: object}}
     */
    getNodeState() {
        return this.treeRoot.getNodeState(this);
    }

    static hasItem(node) { return !!node.item; }
    static hasChildren(node) { return !!node.children; }

    /**
     * Recursively check this and child nodes for a condition, returning a list of passing nodes.
     * If there is no condition, return this and all descendents.
     * @param {function} condition
     * @param {string} pick Return this field from the resulting nodes, instead of nodes themselves
     */
    static selectNodes(node, condition=_.identity, pick=null) {
        const gatherChilden = (nodes) => nodes.reduce(
            (acc, child) => acc.concat(TreeNode.selectNodes(child, condition, pick)),
            []
        );

        // Special handling for array case & return early
        if (Array.isArray(node)) {
            const selected = gatherChilden(node);
            return selected;
        }

        const ret = [];
        if (condition(node)) {
            ret.push(pick ? node[pick] : node);
        }
        if (node.children) {
            const fromChildren = gatherChilden(node.children);
            ret.push(...fromChildren);
        }
        return ret;
    }

    // Convenience functions for menu and view/sort menu

    /**
     * Convenience method for group menu items for show / hide all
     * @returns {{title: string, onClick: function({treeItem: TreeNode}): void}[]}
     */
    static groupHideShowActions() {
        return [
            {
                title: 'Hide all in this group',
                onClick: ({ treeItem }) => {
                    const items = TreeNode.selectNodes(treeItem, TreeNode.hasItem, 'item');
                    UserActions.ToggleVisibility(items, false);
                },
            },
            {
                title: 'Show all in this group',
                onClick: ({ treeItem }) => {
                    const items = TreeNode.selectNodes(treeItem, TreeNode.hasItem, 'item');
                    UserActions.ToggleVisibility(items, true);
                },
            },
        ];
    }

    // Specific for px-components CompoundTree control

    /**
     * Produce a node description for use by px-components CompoundTree control.
     * This uses the provided stateFn to get the node's display state, which may also
     * be used to tailor the actions.
     *
     * @param {*} stateFn
     * @returns {*} px-component node description
     */
    getPxNode() {
        const state = this.getNodeState();
        return {
            treeItem: this,
            name: this.label,
            info: this.info,
            type: this.children ? 'group' : 'leaf',
            item: this.item,
            children: this.children?.map((c) => c.getPxNode()),
            actions: this.getActions(state),
            ...state,
        };
    }
}
