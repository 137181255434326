import React from 'react';
import { reconnectAndRestoreSession } from 'BMapsSrc/server/connection_utils';
import { EventBroker } from './eventbroker';
import { SessionErrorType } from './server/session_utils';
import { App } from './BMapsApp';
import { loginUrl, loginUrlForMapCase, upgradeUrl } from './connection_utils';
import { DownloadSessionLink } from './ui/SaveStatePanel';

export class ConnectionErrorPane extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            msg: <br />,
            title: '',
            sessionError: null,
        };
        this.sessionErrorHandler = this.sessionErrorHandler.bind(this);
        this.connectionStatusChangedHandler = this.connectionStatusChangedHandler.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('sessionError', this.sessionErrorHandler);
        EventBroker.subscribe('connectionStatusChanged', this.connectionStatusChangedHandler);
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('sessionError', this.sessionErrorHandler);
        EventBroker.unsubscribe('connectionStatusChanged', this.connectionStatusChangedHandler);
    }

    sessionErrorHandler(evtName, data) {
        const config = {
            title: data.errTitle,
            msg: (<span dangerouslySetInnerHTML={{ __html: data.errMsg }} />),
            sessionError: data.errType,
        };
        this.show(config);
    }

    connectionStatusChangedHandler(evtName, { connected, connector }) {
        if (!connected) {
            if (connector.closed) return;
            const config = {
                title: 'Boltzmann Maps reconnecting...',
                msg: (
                    <span>
                        Please check your internet connection.
                        <br />
                        You should be automatically reconnected, or
                        {' '}
                        <button
                            type="button"
                            className="link-looking-btn"
                            style={{ color: 'blue', textDecoration: 'underline', font: 'unset' }}
                            onClick={() => { EventBroker.publish('reconnect', { connector }); }}
                        >
                            try now
                        </button>
                        .
                        <br />
                        <br />
                        If you are online and unable to connect, click below to reload your session.
                        <br />
                        (This will reload all compounds)
                    </span>
                ),
                sessionError: null,
            };
            this.show(config);
        } else if (connector.getMode() === connector.getModeForSavedState()) {
            this.hide();
        } else {
            // We're in a disconnected session. Don't hide the screen.
        }
    }

    show(otherState) {
        this.setState({ visible: true, ...otherState });
    }

    hide() {
        this.setState({
            visible: false, msg: <br />, title: '', sessionError: null,
        });
    }

    async reSess(kickOutOld) {
        this.hide();
        reconnectAndRestoreSession(kickOutOld);
    }

    upgrade() {
        window.location.href=upgradeUrl();
    }

    login(loadStructure) {
        const protein = App.Workspace && App.Workspace.getLoadedProtein();
        if (loadStructure && protein) {
            window.location.href = loginUrlForMapCase(protein);
        } else {
            window.location.href = loginUrl();
        }
    }

    render() {
        const {
            visible, sessionError, title, msg,
        } = this.state;
        const noMoreSession = [SessionErrorType.Lost_SessionLost,
            SessionErrorType.Lost_InactivityTimeout].includes(sessionError);
        const loginFail = sessionError === SessionErrorType.CantStart_NotLoggedIn;
        const limitReached = sessionError === SessionErrorType.CantStart_SessionLimitReached;
        const unavailableInStaticMode = sessionError === SessionErrorType.UnavailableInStaticMode;
        const showClose = !sessionError || unavailableInStaticMode;
        const showTryAgain = !loginFail && !unavailableInStaticMode;
        const showCancel = unavailableInStaticMode;
        return visible && (
        <div id="cxn_err_pane">
            {showClose
                    && (
                    <button type="button" className="close" onClick={() => this.hide()}>
                        <i className="fa fa-close" />
                    </button>
                    )}
            <div id="cxn_err_content">
                <h2>{title}</h2>
                <div>{msg}</div>
                <div id="cxn_btn_group">
                    {showTryAgain && (
                    <button type="button" className="bmaps-btn" onClick={() => this.reSess(false)}>
                        {' '}
                        {noMoreSession ? 'Reload Session' : 'Try Again'}
                        {' '}
                    </button>
                    )}
                    {noMoreSession && (
                    <button
                        type="button"
                        className="bmaps-btn"
                        onClick={() => {
                            this.hide();
                        }}
                    >
                        Continue without computation
                    </button>
                    )}
                    {noMoreSession && (
                        <DownloadSessionLink />
                    )}
                    {loginFail
                        && <button type="button" className="bmaps-btn" onClick={() => this.login()}> Login </button>}
                    {limitReached
                        && <button type="button" className="bmaps-btn" onClick={() => this.reSess(true)}> Close Old Session </button>}
                    {limitReached
                        && <button type="button" className="bmaps-btn" onClick={() => this.upgrade()}> Upgrade </button>}
                    {unavailableInStaticMode
                        && <button type="button" className="bmaps-btn" onClick={() => this.login(true)}> Login / Sign Up </button>}
                    {showCancel
                        && (
                        <button type="button" className="bmaps-btn" onClick={() => this.hide()}>
                            { unavailableInStaticMode ? 'Not right now' : 'Cancel'}
                        </button>
                        )}
                </div>
            </div>
        </div>
        );
    }
}
