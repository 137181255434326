import { MapCase } from './MapCase';

/* eslint camelcase: [ "error", { properties: "never", allow: ["mount_index"]}] */

/** Maplist for a bfd-server data source */
export default class MapListData {
    /**
     * Note: this expects the MapCases in the mapList have already been associated with dataSource
     * @param {{dataSource: DataSource, mapList: MapCase[]}}
     */
    constructor({ dataSource, mapList }) {
        this.dataSource = dataSource;
        this.mapList = mapList;
    }

    /**
     * Create a MapListData from a bfd-server JSON obj
     * @param {{datasource: object, maplist: object[] }} json
     * @returns {MapListData}
     */
    static fromBfdServer({ datasource, maplist }) {
        const dataSource = DataSource.fromBfdServer(datasource);
        const mapList = maplist.map((entry) => MapCase.fromBfdServer(entry, dataSource))
            .filter((x) => x);
        return new MapListData({ dataSource, mapList });
    }
}

/** Data source for bfd-server simulations */
export class DataSource {
    constructor({
        type, id, name, mountIndex,
    }) {
        this.type = type;
        this.id = id;
        this.name = name;
        this.mountIndex = mountIndex;
    }

    /**
     * Create a DataSource from a bfd-server JSON obj
     * @param {{ type: string, id: string, name: string, mount_index: number }} json
     */
    static fromBfdServer({
        type, id, name, mount_index,
    }) {
        return new DataSource({
            type, id, name, mountIndex: mount_index,
        });
    }
}
