/* EnergyDisplay.jsx
 *
 */

import React from 'react';
import { UserActions } from 'BMapsCmds';
import { RequestStatus } from 'BMapsSrc/model';
import { EnergyInfo } from '../../model/energyinfo';
import { CollapsibleTable } from '../CollapsibleTable';
import { workingIndicator, updateTooltipster, WorkingIndicator } from '../ui_utils';
import { encodeHtmlEntities } from '../../utils';
import { simpleCond } from '../../util/js_utils';
import { EventBroker } from '../../eventbroker';

export function EnergyDisplay({ activeCompound, otherCompounds, proteinInfo }) {
    const visible = !!(activeCompound && activeCompound.energyInfo); // force boolean
    return visible && (
        <div id="energy_display_wrapper">
            <EnergyTable
                activeCompound={activeCompound}
                otherCompounds={otherCompounds}
                proteinInfo={proteinInfo}
            />
        </div>
    );
}

class EnergyTable extends React.Component {
    static get ComparisonTitle() { return 'Energies by compound'; }
    static get EnergyScoreTitle() { return 'Interaction Score'; }
    static get InternalEnergyTitle() { return 'Internal Energy'; }

    constructor(props) {
        super(props);
        this.handleEnergyCalc = this.handleEnergyCalc.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('energyCalc', this.handleEnergyCalc);
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('energyCalc', this.handleEnergyCalc);
    }

    handleEnergyCalc() {
        this.forceUpdate();
    }

    /**
     * Return a compound display name for the Compound Selector, prepending the name
     * with the caseData short name if there are multiple proteins.
     * @param {Compound} cmpd
     * @param {MapCase[]} allProteins
     * @returns {string}
     */
    cmpdDisplayName(cmpd, allProteins) {
        const prefix = allProteins.length < 2
            ? ''
            : `${cmpd.getCaseData().getShortName()}: `;
        return `${prefix}${cmpd.resname}`;
    }

    // Compounds is the active compound plus all other
    // pinned compounds.
    energyTableData(compounds) {
        // Data format for collapsible table is array of arrays
        // [ ... [energyType, energyValue] ... ]
        // Metadata format is [ ... {key, title} ...]
        const data = [];
        const metadata = [];
        const { proteinInfo } = this.props;
        const { mainProtein, allProteins } = proteinInfo;

        // Header row with compound names
        data.push([
            EnergyTable.ComparisonTitle,
            ...compounds.map((cmpd) => encodeHtmlEntities(this.cmpdDisplayName(cmpd, allProteins))),
        ]);
        metadata.push({ key: 'CompoundNames', title: 'Pin compounds to view energies side-by-side' });

        // Prepare to add data rows for the compound energy info
        let scoreRowId;
        let energyGroups = [];
        const energyInfos = compounds.map((c) => c.energyInfo);
        if (mainProtein) {
            // Have protein case
            // Score row = interaction score
            scoreRowId = EnergyTable.EnergyScoreTitle;
            // Collect individual energy rows as groups for display
            energyGroups = [
                // Group 1: components of energy score
                [EnergyInfo.Types.vdW, EnergyInfo.Types.hbonds],
                // Group 2: other energies calculated by server
                [EnergyInfo.Types.ddGs, EnergyInfo.Types.stress, EnergyInfo.Types.electrostatics],
            ];
        } else {
            // No protein case
            // Score row = internal energy
            scoreRowId = EnergyTable.InternalEnergyTitle;
            // In the no protein case, there is just one energy term (stress),
            // so just show the "Internal Energy" summary row for now.
            // If we need to break it down, add additional data rows as above.
            energyGroups = [];
        }

        // Add "Extra" energies determined in other ways (eg docking score, GiFE)
        // Only include "Extra" energies if any of the energy table compounds have them
        energyGroups.push(
            Object.values(EnergyInfo.ExtraTypes).filter(
                (extraEnType) => compounds.some(
                    (c) => c.getPropertyInfo('extra_energies', extraEnType) != null
                )
            )
        );

        // Actually add the data rows, first the Score row
        data.push(this.EnergyScoreRow(scoreRowId, compounds));
        metadata.push(energyRowMetadata(scoreRowId));

        // Add the other data rows
        let groupsAdded = 0;
        for (const energyTypes of energyGroups) {
            if (energyTypes.length === 0) continue;
            // Separator between groups if necessary
            if (groupsAdded++ > 0) {
                data.push(['', ...compounds.map(() => '')]); // add empty columns
                metadata.push({
                    key: `separator${groupsAdded}`,
                    title: 'Hover over an energy for its description',
                    classes: { tr: 'separator' },
                });
            }
            // Add individual energy rows for the group
            for (const type of energyTypes) {
                if (EnergyInfo.Types[type]) {
                    const rowEntries = energyInfos.map((ei) => ei.getEnergyEntryByType(type));
                    data.push(EnergyRowData(type, rowEntries));
                    metadata.push(energyRowMetadata(type));
                } else {
                    const rowEntries = compounds.map((c) => energyEntryForExtraEnergy(c, type));
                    // TODO: energy row label and metadata should pull from property metadata
                    data.push(EnergyRowData(type, rowEntries));
                    metadata.push(energyRowMetadata(type));
                }
            }
        }

        // That's the table!
        return [data, metadata];
    }

    EnergyScoreRow(scoreType, compounds) {
        const row = [energyRowLabel(scoreType)]; // Row starts with the title

        for (const compound of compounds) {
            const energyInfo = compound.energyInfo;

            // Embed calculate button in table if not requested.
            // This probably violates accessibility guidelines...
            if (energyInfo.notRequested()) {
                row.push(
                    <button
                        type="button"
                        style={{
                            font: 'inherit', border: '1px', paddingLeft: '2px', paddingRight: '2px',
                        }}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            UserActions.EnergyMinimize(compound);
                        }}
                    >
                        Calculate
                    </button>,
                );
                continue;
            }

            const isWorking = energyInfo.status === EnergyInfo.States.working;
            const haveError = energyInfo.errors.length > 0;
            const noCompletedEnergies = !energyInfo.getAllEnergies().find(
                (entry) => entry.status !== EnergyInfo.States.working
            );

            // If we have data, show what we have. Working / error indicators will follow
            let scoreText = formatEnergyScore(scoreType, energyInfo);

            // Custom message for when all energies are in a working state,
            // or there's an error message before energies have been requested
            if (noCompletedEnergies) {
                const text = simpleCond([
                    [isWorking, 'Calculating...'],
                    [energyInfo.isClashing(), 'Steric Clash'],
                    [true, 'Error'],
                ]);

                scoreText = (
                    <span>
                        <i>{text}</i>
                        {' '}
                    </span>
                );
            }

            row.push(
                <>
                    {scoreText}
                    {' '}
                    { isWorking && <WorkingIndicator /> }
                    { !isWorking && haveError && (
                    <>
                        <ErrorIndicator errors={energyInfo.errors} />
                        {' '}
                        <RefreshButton
                            title={noCompletedEnergies ? 'Calculate energies' : 'Retry calculating energies'}
                            onClick={() => UserActions.EnergyMinimize(compound)}
                        />
                    </>
                    )}
                </>
            );
        }
        return row;
    }

    render() {
        const props = this.props;
        const activeCompound = props.activeCompound;
        const otherCompounds = props.otherCompounds || [];
        const compounds = [activeCompound].concat(otherCompounds);
        const multiCompound = compounds.length > 1;

        const [data, metadata] = this.energyTableData(compounds);

        return (
            <CollapsibleTable
                eltId="energy_table"
                tableClassName="info-display-table"
                data={data}
                rowMetadata={metadata}
                dangerouslySetInnerHTML
                rememberBy={EnergyInfo}
                headerClassName="energy_row"
                dataClassName="energy_row"
                onUpdated={updateEnergyTooltips}
                expanded
                // When collapsed, only show energy score for active compound
                // So we need to hide the header row with the compound names,
                // as well as the other columns.
                // These slice params achieve this.
                collapsedRowSlice={1}
                collapsedColSlice={multiCompound ? [0, 2] : undefined}
            />
        );
    }
}

export function updateEnergyTooltips() {
    const tooltipInfo = {
        energy_row: { side: 'right' },
        energyError: { side: 'top' },
        '.energy_row .generalButton': { side: 'top' },
    };
    updateTooltipster(tooltipInfo);
}

// Display energy row for one energy type
function EnergyRowData(type, entries) {
    const row = [energyRowLabel(type)];

    for (const entry of entries) {
        if (!entry) {
            row.push('');
            continue;
        }

        let display;

        if (entry.status === EnergyInfo.States.success) {
            display = entry.energy?.toFixed(1) || '';
        } else if (entry.status === EnergyInfo.States.working) {
            display = workingIndicator();
        } else if (entry.status === EnergyInfo.States.error) {
            display = errorIndicator([entry.error]);
        }
        row.push(display);
    }
    return row;
}

/**
 * Convert an "extra energy," stored in ScopedProperties, into an
 * shape like those in EnergyInfo: { type, status, error, energy }
 */
function energyEntryForExtraEnergy(compound, extraType) {
    /** @type {import('BMapsModel').ScopedProperty} */
    const propInfo = compound.getPropertyInfo('extra_energies', extraType);
    if (!propInfo) return null;
    // match EnergyInfo entries: { type, status, error, energy }
    const ret = { type: extraType, status: EnergyInfo.States.success, energy: propInfo.value };

    if (propInfo.traverseForRequestInfoItem('status') === RequestStatus.WORKING) {
        ret.status = EnergyInfo.States.working;
    }
    const errors = propInfo.traverseForErrors().filter((e) => e);
    if (ret.status === EnergyInfo.States.success && ret.energy == null) {
        errors.push('No value');
    }
    if (errors.length > 0) {
        ret.status = EnergyInfo.States.error;
        ret.error = errors[0];
    }
    return ret;
}

export function errorIndicator(errors) {
    return ` <i class='fa fa-warning energyError' title="${errors.join('\n').replace(/"/g, "''")}"> </i>`;
}

function ErrorIndicator({ errors }) {
    return (
        <i className="fa fa-warning energyError" title={errors.join('\n').replace(/"/g, "''")}>
            {' '}
        </i>
    );
}

export function RefreshButton({ working, onClick, title }) {
    return (
        <button
            type="button"
            className="generalButton"
            onClick={onClick}
            title={title}
        >
            <i
                className={`fa fa-refresh ${working ? 'fa-spin' : ''}`}
            />
        </button>
    );
}

function energyRowMetadata(type) {
    const title = energyDisplayInfo[type] ? energyDisplayInfo[type].title : type;
    return { key: type, title };
}

function energyRowLabel(type) {
    const label = energyDisplayInfo[type] ? energyDisplayInfo[type].label : type;
    return label;
}

function formatEnergyScore(scoreType, energyInfo) {
    let text = '';
    if (scoreType === EnergyTable.EnergyScoreTitle) {
        text = energyInfo.getEnergyScore().toFixed(1);
    } else if (scoreType === EnergyTable.InternalEnergyTitle) {
        text = energyInfo.getInternalEnergy().toFixed(1);
    }

    if (energyDisplayInfo[scoreType].unit) {
        text += ` ${energyDisplayInfo[scoreType].unit}`;
    }
    return text;
}

const energyDisplayInfo = {
    [EnergyTable.EnergyScoreTitle]: { label: 'Interaction Score', title: "Interaction Score is the sum of 'van der Waals' and 'Hydrogen bonds' energies" },
    [EnergyTable.InternalEnergyTitle]: {
        label: 'Internal Energy',
        title: 'Internal Energy',
        unit: 'kcal/mol',
    },
    [EnergyInfo.Types.vdW]: { label: '&emsp;van der Waals', title: 'London dispersion forces' },
    [EnergyInfo.Types.ddGs]: { label: 'Desolvation (&Delta;&Delta;G<sub>s</sub>)', title: 'Energy cost of desolvating both ligand and protein' },
    [EnergyInfo.Types.hbonds]: { label: '&emsp;Hydrogen bonds', title: 'Hydrogen bond energy' },
    [EnergyInfo.Types.electrostatics]: { label: 'Other electrostatics', title: 'Electrostatic energy besides hydrogen bonds' },
    [EnergyInfo.Types.stress]: { label: 'Stress Delta', title: 'Stress Delta' },
    // TODO: pull info for "extra types" from property metadata
    [EnergyInfo.ExtraTypes.dockingScore]: { label: 'Autodock Score', title: 'Pose score calculated by Autodock Vina' },
    [EnergyInfo.ExtraTypes.GiFE]: { label: 'GiFE Beta (kcal/mol)', title: 'ML-accelerated Gibbs free energy of formation (in development)', unit: 'kcal/mol' },
};
