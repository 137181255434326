import { Formik, Form } from 'formik';
// Local
import { PdbImportCase } from '../../../../../model/MapCase';
import { DialogSubmitButton } from '../../../../common/DialogActionButtons';
import TextField from '../../../../common/TextFields';
import ExternalLink from '../../../../common/ExternalLink';
import { ImportErrors } from '../../import_components';

function validate(values) {
    const errors = {};
    if (!values.pdbId) {
        errors.pdbId = 'Please specify a PDB id';
    } else if (!PdbImportCase.isValidId(values.pdbId)) {
        errors.pdbId = 'Malformed PDB id (should be a number followed by three alphanumeric characters, eg "4rmg")';
    }

    return errors;
}

export default function ImportPdbTab({
    selectProtein, errors,
}) {
    const initialValues = {
        pdbId: '',
    };

    async function handleSubmit(values) {
        const { pdbId } = values;
        const newMapCase = new PdbImportCase(pdbId);
        selectProtein(newMapCase);
    }

    return (
        <>
            <div style={{ paddingBottom: '1em' }}>
                Import a raw structure from the
                {' '}
                <ExternalLink link="https://www.rcsb.org/">Protein Data Bank</ExternalLink>
                {' '}
                by entering the PDB id (eg &quot;4rmg&quot;).
                <br />
                Raw PDB structures do not contain any fragment data,
                such as water maps or hot spots.
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validate={validate}
                validateOnChange={false}
                validateOnBlur={false}
            >
                <Form>
                    <TextField fullWidth name="pdbId" label="PDB ID" />
                    <DialogSubmitButton fullWidth>Import from PDB</DialogSubmitButton>
                </Form>
            </Formik>
            {errors && <ImportErrors errors={errors} /> }
        </>
    );
}
