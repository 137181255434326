// paste_drag.js
// Utilities for receiving molecule data via Paste and Drag-N-Drop operations

import { UserActions } from 'BMapsCmds';
import {
    getFileExtension, MolDataSource, stateFileExtension,
    userConfirmation, showAlert,
} from './utils';
import { fromByteArray } from '../lib/js/base64'; // for png workaround>
import {
    isSupportedMoltype, getSupportedMoltypes, isSupportedProteinType,
    getSupportedProteinTypes, isBinaryFormat, dataLooksLikeProtein,
} from './util/mol_format_utils';
import { EventBroker } from './eventbroker';

export function initDropHandling() {
    $('#canvas_wrapper > canvas').addClass('receive-drop');
    addFileDropHandler('#bfm_viewer', '.receive-drop', canvasHandleDropFiles);
    initPasteHandling();
    listenForDrops();
}

function initPasteHandling() {
    $(document).on('paste', (event) => {
        let target = $(':hover').filter('.receive-paste');
        const focused = $(':focus').length > 0;
        if (!focused && target.length > 0) {
            target = target.get(0);
            const items = event.originalEvent.clipboardData.items;
            pasteMolData(items);
            event.preventDefault();
        }
    });
    $('#bfm_viewer').addClass('receive-paste');
}

// Receive a list of items from a paste or Drop operation
function pasteMolData(items) {
    $.each(items, (index, item) => {
        // TODO: protein interpretation
        if (item.kind === 'string') {
            item.getAsString((strData) => {
                UserActions.StageMoleculeImport({ molData: strData });
            });
        } else {
            const file = item.getAsFile();
            const reader = new FileReader();

            reader.onload = function onLoad(e) {
                const text = e.target.result;
                UserActions.StageMoleculeImport({ molData: text, filename: file.name });
            };
            reader.readAsText(file);
        }
    });
}

// Add "droppable" functionality to an element
// Rather than importing jquery-ui, which has droppable() and drop() methods,
// this just uses the jquery on() method to capture the events
//
// selector: a jQuery selector for the element to listen for the drag/drop events
// readyIndicatorSelector: a jQuery selector for an element to indicate ready for drop
//     (via 'ready_to_drop' class)
// dropFn: a function taking a jQuery drop event and handling the drop
//
// This works by having the document itself listen for the drop events.
// When handling them, it works up the DOM tree from the target element, looking for
// a selector that has been registered as a handler.
//
// Historical TODO: investigate filtering by file extension before the actual drop.
//       Originally a droppableFn was being used in the dragenter and dragover handlers,
//       so that only valid files caused the highlighting.  However on moving from local to demo
//       environment, the filename ceased to be visible for events except the drop itself.
//       We chose to remove the checks, but this could be explored some more.

/**
 * @description Registry of drop handlers: { <selector>: {readyIndicatorSelector, dropFn}}
 */
const dropListeners = { };

/**
 * @description Register a drop handler for the given targets
 * @param {*} selector - selector for the element to listen for the drag/drop events
 * @param {*} readyIndicatorSelector - selector for an element to indicate ready for
 * drop (via 'ready_to_drop' class)
 * @param {*} dropFn  - a handler function receiving the **jQuery** drop event
 */
export function addDropHandler(selector, readyIndicatorSelector, dropFn) {
    dropListeners[selector] = { readyIndicatorSelector, dropFn };
}

/**
 * @description Register a drop handler for the given target, specifically for files.
 * This will extract the files from the jQuery event.
 * @param {*} selector - selector for the element to listen for the drag/drop events
 * @param {*} readyIndicatorSelector - selector for an element to indicate ready for
 * drop (via 'ready_to_drop' class)
 * @param {*} dropFn - a handler function receiving the **list of files** from the drop event
 */
export function addFileDropHandler(selector, readyIndicatorSelector, dropFn) {
    const handler = (jqEvent) => dropFn(jqEvent.originalEvent.dataTransfer.files);
    addDropHandler(selector, readyIndicatorSelector, handler);
}

export function clearDropListener() {
    $('.ready_to_drop').removeClass('ready_to_drop');
}

export function listenForDrops() {
    $(document).on('drop', (jqEvent) => {
        jqEvent.preventDefault(); // don't want the dragged doc to take over the page
        const { dropFn } = findDropListener(jqEvent);
        if (dropFn) {
            dropFn(jqEvent);
            $('.ready_to_drop').removeClass('ready_to_drop');
        }
    });

    // dragstart isn't currently being used.
    // This is not invoked for drag and drop files from the file system, but for
    // UI elements being dragged around the screen.
    /*     $(document).on('dragstart', function (jqEvent) {
        jqEvent.preventDefault();
        const {readyIndicatorSelector} = findDropListener(jqEvent);
        if (readyIndicatorSelector) {
            $(readyIndicatorSelector).addClass('ready_to_drop');
        } else {
            jqEvent.originalEvent.dataTransfer.dropEffect = "none";
        }
    });
 */
    // The actual drop is not seen unless we prevent default for dragover
    $(document).on('dragover', (jqEvent) => {
        jqEvent.preventDefault();
        const { readyIndicatorSelector } = findDropListener(jqEvent);
        if (readyIndicatorSelector) {
            $(readyIndicatorSelector).addClass('ready_to_drop');
        } else {
            jqEvent.originalEvent.dataTransfer.dropEffect = 'none';
        }
    });

    // listen for dragenter and dragleave to indicate when a droppable object is ready to be dropped
    $(document).on('dragenter', (jqEvent) => {
        jqEvent.preventDefault();
        const { readyIndicatorSelector } = findDropListener(jqEvent);
        if (readyIndicatorSelector) {
            $(readyIndicatorSelector).addClass('ready_to_drop');
        } else {
            jqEvent.originalEvent.dataTransfer.dropEffect = 'none';
        }
    });

    $(document).on('dragleave', (jqEvent) => {
        const { readyIndicatorSelector } = findDropListener(jqEvent);
        if (readyIndicatorSelector) {
            $(readyIndicatorSelector).removeClass('ready_to_drop');
        }
    });
}

/**
 * @description Finds the closest DOM ancestor that is a registered drop handler
 * and returns the handler info.
 * @param {*} jqEvent - a drag/drop event
 * @returns Registered drop handler info: {readyIndicatorSelector, dropFn}
 * or empty object {} if not found.
 */
function findDropListener(jqEvent) {
    const node = jqEvent.target;
    let listener = null;
    const listenerSelectors = Object.keys(dropListeners);

    // First find the closest ancestor that meets ANY listener selector
    // Then find which listener selector that ancestor meets.
    // If an ancestor matches more than one, it returns the first.
    const found = node.closest(listenerSelectors.join(','));
    if (found) {
        for (const sel of listenerSelectors) {
            if (found.matches(sel)) {
                listener = dropListeners[sel];
            }
        }
    }

    return listener || {};
}

// drop function implemention for supported files
function isSupportedFile(event) {
    const data = event.originalEvent.dataTransfer;
    let ret = false;
    if (data.files.length > 0) {
        const format = getFileExtension(data.files[0].name);
        if (isSupportedMoltype(format) || isStateExtension(format)) {
            // we'll just go with file extension for now
            ret = true;
        }
    }
    return ret;
}

/**
 * File drop handler, passed into addFileDropHandler.
 * Call loadFiles to convert files to MolDataSources, then stage in Import pane.
 * @param FileList files - the files to load, from drag-drop event
 */
async function canvasHandleDropFiles(files) {
    // Close modals when drag-dropping a file to the canvas
    EventBroker.publish('escapeKey');

    const [sessionFiles, moleculeFiles, proteinFiles] = [[], [], [], []];
    for (const file of files) {
        const fileInfo = new FileInfo(file);
        if (fileInfo.kind === 'ambiguous') {
            await fileInfo.recategorize();
        }
        switch (fileInfo.kind) {
            case 'session': sessionFiles.push(fileInfo); break;
            case 'compound': moleculeFiles.push(fileInfo); break;
            case 'protein': proteinFiles.push(fileInfo); break;
            case 'ambiguous':
                console.warn(`Failed to disambiguate file ${fileInfo.name}`);
                break;
            default: // unsupported
                console.warn(`Can't load supported ${fileInfo.kind} file ${fileInfo.name}`);
        }
    }

    // Scenarios:
    // * One session file: load the session
    // * One protein file: stage protein
    // * One compound file: stage compound
    // * Multiple compounds: stage all as compound
    // * Multiple sessions: ask if ok to just load the first (handled in loadSessionFiles)
    // * Session + compound(s): Load session and stage compound(s)
    // * Session + protein: Load session and stage protein
    // * Protein + compound or another protein: alert user that proteins must be loaded one by one

    const loadedSession = await loadSessionFiles(sessionFiles);

    if (proteinFiles.length > 0) {
        if (proteinFiles.length > 1 || moleculeFiles.length > 0) {
            await showAlert('Protein files must be loaded one at a time', 'Load Molecule');
            return;
        }

        const [proteinSource] = await loadProteinFiles(proteinFiles);
        // If we just drop a protein file, don't default to selectivity.
        // But if we load a session and a protein, then default to selectivity.
        // If we ever support multiple protein files, selectivity would have to be
        // applied to the additional proteins.
        const needSelectivity = loadedSession;
        if (needSelectivity) {
            if (!proteinSource.options) proteinSource.options = {};
            proteinSource.options.selectivity = 'selectivity';
        }
        UserActions.StageProteinImport(proteinSource);
    }

    if (moleculeFiles.length > 0) {
        const molSources = await loadMolFiles(moleculeFiles);
        UserActions.StageMoleculeImport({ molSources });
    }
}

/**
 * @param {Iterable} files
 * @returns {boolean} Whether we attempted to load
 */
export async function loadSessionFiles(files) {
    const fileList = FileInfo.getFileList(files);
    // multiple session files of molecule files currently not supported.
    const sessionFiles = fileList.filter((fileInfo) => fileInfo.kind === 'session');
    let addSessionFile = sessionFiles.length > 0;
    if (sessionFiles.length > 1) {
        const ok = await userConfirmation(
            `BMaps can't load more than one session file at a time.\n    Would you like to load ${sessionFiles[0].name}?`,
            'Load Session file?',
        );
        addSessionFile = ok;
    }
    if (addSessionFile && sessionFiles[0]) {
        // Await because session loading waits for the server for protein & cmpds
        try {
            await loadSessionFile(sessionFiles[0]);
        } catch (ex) {
            console.error(`Failed to load session file: ${sessionFiles[0].name}`, ex);
        }
    }
    return addSessionFile;
}

/**
 * @description Load state or compound files from the file system.
 * This is not a very clean function.  First, it **always loads
 * state files** (.bmaps).  Then, it **may or may not load compound files**.
 * @param {FileList|FileInfo[]} files - the files to load, from drag-drop event or file input
 * @returns {Promise<MolDataSource[]}>} MolDataSources loaded from files
 */
export async function loadMolFiles(files) {
    const fileList = FileInfo.getFileList(files);
    const loadedMolData = [];
    const unsupported = [];
    for (const fileInfo of fileList) {
        const filename = fileInfo.name;
        const format = fileInfo.format;
        if (isSupportedMoltype(format)) {
            console.log(`Loading ${filename}`);
            if (fileInfo.size >= 32*1024*1024) {
                await showAlert(
                    `This file is > 32MB and too large to load:\n    ${filename}.\nPlease break it into multiple smaller files.`,
                    'File too large',
                );
                continue;
            }
            if (fileInfo.size > 1024*1024) {
                const ok = await userConfirmation(
                    `This file is large and may take a long time to load:\n    ${filename}. \nDo you want to load it anyway?`,
                    'Load large file?',
                );
                if (!ok) continue;
            }
            const molData = await getMolSourceForFile(fileInfo);
            loadedMolData.push(molData);
        } else if (isStateExtension(format)) {
            // Ignore, handled above
        } else {
            unsupported.push(filename);
        }
    }

    if (unsupported.length > 0) {
        const msg = `The following file(s) are unsupported: \n * ${unsupported.join('\n * ')}

            Please try one of the following file types: ${getSupportedMoltypes().join(', ')}`;
        await showAlert(msg, 'Unsupported File Type');
    }

    return loadedMolData;
}

/**
 * @description Load state or protein files from the file system.
 * @param {FileList|FileInfo[]} files - the files to load, from drag-drop event or file input
 * @returns { Promise<{
 *  proteinData: string,
 *  dataFormat: string,
 * }[]>} ProteinDataSources loaded from files
 */
export async function loadProteinFiles(files) {
    const fileList = FileInfo.getFileList(files);
    const loadedProteinData = [];
    const tooBig = [];
    const unsupported = [];
    for (const fileInfo of fileList) {
        const format = fileInfo.format;
        if (isSupportedProteinType(format)) {
            if (fileInfo.size >= 32*1024*1024) {
                tooBig.push(fileInfo.name);
                continue;
            }
            const proteinData = await fileInfo.getData();
            loadedProteinData.push({ proteinData, dataFormat: format });
        } else {
            unsupported.push(fileInfo.name);
        }
    }

    if (unsupported.length > 0 || tooBig.length > 0) {
        const msg = [];
        if (tooBig.length > 0) {
            msg.push(`The following file(s) are too large to load into BMaps (> 32MB): \n * ${tooBig.join('\n * ')}

            Please try removing some chains or models. If it is from the PDB, you can import by PDB ID instead of uploading the file.`);
        }

        if (unsupported.length > 0) {
            msg.push(`The following file(s) are unsupported: \n * ${unsupported.join('\n * ')}

            Please try one of the following protein file types: ${getSupportedProteinTypes().join(', ')}`);
        }
        await showAlert(msg.join('\n\n'), 'Unsupported Protein Files');
    }

    return loadedProteinData;
}

/**
 * @description Create a MolSource object from a file.
 * Binary data will be encoded as base64.
 * @param {FileInfo} fileInfo
 * @returns {Promise<MolDataSource>} - molecule source object extracted from the file
 */
async function getMolSourceForFile(fileInfo) {
    const data = await fileInfo.getData();
    const encoding = fileInfo.isBinary ? 'base64' : undefined;
    const molSource = MolDataSource.FromFile(fileInfo.name, fileInfo.format, data, encoding);
    return molSource;
}

function isStateExtension(extension) {
    return extension === stateFileExtension;
}

// Load a single session file
// Async in order to wait for server operations (loading protein / molecules),
// before moving on
async function loadSessionFile(fileInfo) {
    const filename = fileInfo.name;
    const fileData = await fileInfo.getData();
    try {
        console.log(`Loading state from ${filename}`);
        const { errors } = await UserActions.RestoreState(fileData, false);
        if (errors.length === 0) {
            return;
        } else {
            jAlert(`Failed to restore session file ${filename}.\n\nProblems:\n* ${errors.join('\n* ')}`);
            throw new Error(errors);
        }
    } catch (ex) {
        console.error(`Error loading ${filename}`, ex);
        jAlert(`Failed to load ${filename}.`);
        throw ex;
    }
}

export async function loadFiles(files) {
    const fileList = FileInfo.getFileList(files);
    const loadedFileData = [];
    const tooBig = [];
    for (const fileInfo of fileList) {
        if (fileInfo.size >= 32*1024*1024) {
            tooBig.push(fileInfo.name);
            continue;
        }
        const fileData = await fileInfo.getData();
        loadedFileData.push(fileData);
    }

    if (tooBig.length > 0) {
        const msg = [];
        if (tooBig.length > 0) {
            msg.push(`The following file(s) are too large to load into BMaps (> 32MB): \n * ${tooBig.join('\n * ')}`);
        }
        await showAlert(msg.join('\n\n'), 'Unsupported Files');
    }

    return loadedFileData;
}

class FileInfo {
    static getFileInfo(file) {
        return file instanceof FileInfo ? file : new FileInfo(file);
    }

    static getFileList(files) {
        const fileList = [];
        for (const file of files) {
            fileList.push(FileInfo.getFileInfo(file));
        }
        return fileList;
    }

    constructor(file) {
        this.file = file;
        this.name = file.name;
        this.size = file.size;
        this.format = getFileExtension(file.name);
        this.isBinary = isBinaryFormat(this.format);
        this.kind = this.getCategory();
        this.data = null;
    }

    getCategory() {
        const format = this.format;
        if (isStateExtension(format)) {
            return 'session';
        }
        const canBeProtein = isSupportedProteinType(format);
        const canBeCmpd = isSupportedMoltype(format);
        switch (true) {
            case canBeProtein && canBeCmpd: {
                if (!this.data) {
                    return 'ambiguous';
                } else {
                    return dataLooksLikeProtein(this.data, format) ? 'protein' : 'compound';
                }
            }
            case canBeProtein && !canBeCmpd: return 'protein';
            case !canBeProtein && canBeCmpd: return 'compound';
            case !canBeProtein && !canBeCmpd: return 'unsupported';
            default: return null; // Shouldn't happen
        }
    }

    async recategorize() {
        await this.getData();
        this.kind = this.getCategory();
    }

    async loadFile() {
        this.data = await readFile(this.file, this.isBinary);
    }

    async getData() {
        if (this.data) {
            return this.data;
        } else {
            await this.loadFile();
            return this.data;
        }
    }
}

function readFile(file, isBinary) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            let data = e.target.result;
            if (isBinary) {
                const dataArr = new Uint8Array(data);
                data = fromByteArray(dataArr);
            }
            resolve(data);
        };
        if (isBinary) {
            reader.readAsArrayBuffer(file);
        } else {
            reader.readAsText(file);
        }
    });
}
