import { useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

/**
 * Display a clipboard icon that will copy provided text when clicked.
 * Also displays "Copied" tooltip.
 * @param {{
 *   copyTarget: string,
 *   tooltipMessage: string,
 *   TooltipProps: object,
 *   IconButtonProps: object,
 * }} param0
 * copyTarget: the text to copy
 * tooltipMessage: the tooltip text to display on copy
 * ToolTipProps: props passed into MUI Tooltip wrapper component
 * IconButtonProps: props passed into the IconButton component
 */
export default function CopyToClipboardButton({
    copyTarget,
    tooltipMessage='Copied',
    TooltipProps,
    IconButtonProps,
}) {
    const [tooltipTimeout, setTooltipTimeout] = useState(null);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);

        if (tooltipTimeout) {
            clearTimeout(tooltipTimeout);
            setTooltipTimeout(null);
        } else {
            const id = setTimeout(() => setTooltipTimeout(null), 1000);
            setTooltipTimeout(id);
        }
    };

    return (
        <Tooltip open={!!tooltipTimeout} title={tooltipMessage} {...TooltipProps}>
            <IconButton
                disabled={!copyTarget}
                onClick={() => handleCopy(copyTarget)}
                {...IconButtonProps}
            >
                <FileCopyIcon />
            </IconButton>
        </Tooltip>
    );
}
