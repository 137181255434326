/**
 * @typedef {import('BMapsModel/atoms').Atom} Atom
 * @typedef {{
 *     ringOrdinal: number, atoms: Atom[], resSpec: string, centroid: [number, number, number]
 * }} Ring
 * @typedef {{
 *     ring1: Ring, ring2: Ring,
 *     distance: number, planeAngle: number, pipiType: string,
 *     normal1, normal2, normalAngle1, normalAngle2, GiFEenergy?: number,
 * }} PiPiStack
 * @typedef {{
 *     minDistance: number, maxDistanceFaceFace: number, maxDistanceEdgeFace: number,
 *     maxAngleFaceFace: number, minAngleEdgeFace: number,
 *     planarDotTolerance: number,
 *     debug: boolean,
 * }} PiPiParams
 */

import {
    ensureNormalToward, isPlanar, pointsCentroid, ringNormalVector,
} from 'BMapsSrc/util/atom_distance_utils';
import {
    Add3_3, AngleBetweenPoints, DegreesFromRadians, Distance, Dot3, Length3,
} from 'BMapsSrc/math';
import { getFullResidueId } from 'BMapsSrc/util/mol_info_utils';
import { AtomGroup, Atom } from 'BMapsSrc/model';
import AmberData from 'BMapsSrc/chemistry/AmberData';

export const DefaultPiPiParams = {
    minDistance: 3.5,
    maxDistanceFaceFace: 4.5,
    maxDistanceEdgeFace: 5.5,
    maxAngleFaceFace: 30,
    minAngleEdgeFace: 60,
    planarDotTolerance: 0.1,
    debug: false,
    // pi-cation: max distance: 6.6, max angle 30 (vector toward cation, off the ring normal vector)
};

/**
 * Return pi-pi interactions found between two lists of atoms
 * @param {{ atoms1: Atom[], atoms2: Atom[], params: PiPiParams }} param0
 * @returns {PiPiStack[]}
 */
export function findPiPiStacksForAtoms({ atoms1, atoms2, params=DefaultPiPiParams }={}) {
    const atomGroups1 = AtomGroup.atomGroupsFromAtoms(atoms1);
    const atomGroups2 = AtomGroup.atomGroupsFromAtoms(atoms2);
    return findPiPiStacks({ atomGroups1, atomGroups2, params });
}

/**
 * Return pi-pi interactions found between two lists of AtomGroups
 * @param {{ atomGroups1: AtomGroup[], atomGroups2: AtomGroup[], params: PiPiParams }} params
 * @returns {PiPiStack[]}
 */
export function findPiPiStacks({ atomGroups1, atomGroups2, params=DefaultPiPiParams }={}) {
    function pipiRingsForAtomGroup(ag) {
        let rings = Atom.getRingsForAtoms(ag.getAtoms());
        rings = annotateRings(rings);
        if (params.debug) console.log(`Rings for ${ag.resSpec}, annotated`, rings);
        return rings;
    }
    let rings1 = atomGroups1.map(pipiRingsForAtomGroup).flat();
    rings1 = filterPiPiRings(rings1, params);
    let rings2 = atomGroups2.map(pipiRingsForAtomGroup).flat();
    rings2 = filterPiPiRings(rings2, params);
    const pipiStacks = [];
    for (const ring1 of rings1) {
        for (const ring2 of rings2) {
            const onePipi = annotatePiPi(ring1, ring2, params);
            if (!onePipi) continue; // doesn't meet the specs

            pipiStacks.push(onePipi);
        }
    }
    if (params.debug) console.log('pipi', pipiStacks);
    return pipiStacks;
}

/**
 * @param {{ atomsForRingOrdinal: Atom[]}} rings
 * @returns {Ring[]}
 */
function annotateRings(rings) {
    return Object.entries(rings).map(
        ([ringOrdinal, atoms]) => annotateRing(ringOrdinal, atoms)
    );
}

/**
 * @param {number} ringOrdinal
 * @param {Atom[]} atoms
 * @returns {Ring}
 */
function annotateRing(ringOrdinal, atoms) {
    return {
        ringOrdinal: Number(ringOrdinal),
        atoms,
        resSpec: getFullResidueId(atoms[0]),
        centroid: pointsCentroid(atoms),
        normal: ringNormalVector(atoms),
    };
}

/**
 * @param {Ring} ring1
 * @param {Ring} ring2
 * @param {PiPiParams} params
 * @returns {PiPiStack?}
 */
function annotatePiPi(ring1, ring2, params=DefaultPiPiParams) {
    const distance = ringDistance(ring1, ring2);
    const planeAngle = getRingsPlaneAngle(ring1, ring2);
    const pipiType = categorizePiPi(ring1, ring2, distance, planeAngle, params);

    if (!pipiType) return null; // doesn't meet specs

    const ringNormal1 = ensureNormalToward(ring1.centroid, ring1.normal, ring2.centroid);
    const ringNormal2 = ensureNormalToward(ring2.centroid, ring2.normal, ring1.centroid);
    const normalEnd1 = Add3_3(ring1.centroid, ringNormal1);
    const normalEnd2 = Add3_3(ring2.centroid, ringNormal2);
    const normalAngle1 = AngleBetweenPoints(normalEnd1, ring1.centroid, ring2.centroid);
    const normalAngle2 = AngleBetweenPoints(normalEnd2, ring2.centroid, ring1.centroid);
    return {
        // Essential properties
        ring1,
        ring2,
        distance,
        planeAngle,
        pipiType,
        // Extra properties about ring normals
        ringNormal1,
        ringNormal2,
        normalAngle1: DegreesFromRadians(normalAngle1),
        normalAngle2: DegreesFromRadians(normalAngle2),
    };
}

/**
 * @param {Ring} ring1
 * @param {Ring} ring2
 * @returns {number}
 */
function ringDistance(ring1, ring2) {
    return Distance(ring1.centroid, ring2.centroid);
}

/**
 * @param {Ring[]} inputRings
 * @param {PiPiParams} params
 * @returns {Ring[]}
 */
function filterPiPiRings(inputRings, params=DefaultPiPiParams) {
    return inputRings.filter((ring) => {
        if (!isAromaticRing(ring.atoms, params)) {
            if (params.debug) console.log(`Rejecting non-planar ring ${ring.resSpec}`);
            return false;
        }
        return true;
    });
}

/**
 * @param {Atom[]} atoms
 * @param {PiPiParams} params
 */
function isAromaticRing(atoms, params) {
    if (atoms[0].resn === 'PRO') {
        if (params.debug) console.log('isAromaticRing - rejecting Proline');
        return false;
    }
    const gaffAromaticTypes = AmberData.GaffAromaticTypes;
    if (atoms[0].hetflag && !atoms.some((at) => gaffAromaticTypes.includes(at.amber))) {
        if (params.debug) console.log(`iSAromaticRing - rejecting ${getFullResidueId(atoms[0])} because no aromatic amber types.`);
        return false;
    }
    if (!isPlanar(atoms, { tolerance: params.planarDotTolerance })) {
        if (params.debug) console.log(`isAromaticRing - rejecting ${getFullResidueId(atoms[0])} because not planar (tolerance ${params.planarDotTolerance})`);
        return false;
    }
    return true;
}

/**
 * Return pi-pi interaction type for two rings: face-to-face, edge-to-face, or null if out of spec
 * @param {Ring} ring1
 * @param {Ring} ring2
 * @param {number} distance
 * @param {number} planeAngle
 * @param {PiPiParams} params
 * @returns {string?} "Face-to-Face", "Edge-to-Face", or null
 */
function categorizePiPi(ring1, ring2, distance, planeAngle, params) {
    function reject(reason) {
        if (params.debug) {
            console.log(`Rejected pi-pi interaction between ${ring1.resSpec} and ${ring2.resSpec}: ${reason}`);
        }
        return null;
    }

    if (distance < params.minDistance) {
        return reject(`distance ${distance} < minDistance ${params.minDistance}`);
    }
    const faceFace = planeAngle <= params.maxAngleFaceFace;
    const edgeFace = planeAngle >= params.minAngleEdgeFace;
    if (faceFace) {
        if (distance > params.maxDistanceFaceFace) {
            return reject(`distance > maxDistanceFaceFace ${params.maxDistanceFaceFace}`);
        }
        return 'Face-to-Face';
    } else if (edgeFace) {
        if (distance > params.maxDistanceEdgeFace) {
            return reject(`distance > maxDistanceEdgeFace ${params.maxDistanceEdgeFace}`);
        }
        return 'Edge-to-Face';
    } else {
        return reject(`plane angle ${planeAngle} does't meet criteria for face-face (< ${params.maxAngleFaceFace}) or edge-face (> ${params.minAngleEdgeFace})`);
    }
}

/**
 * Return the angle for the intersection of the planes of two rings.
 * @param {Ring} ring1
 * @param {Ring} ring2
 * @returns {number}
 */
function getRingsPlaneAngle(ring1, ring2) {
    const { normal: normal1 } = ring1;
    const { normal: normal2 } = ring2;
    const dot = Dot3(normal1, normal2);
    const normalLength1 = Length3(normal1);
    const normalLength2 = Length3(normal2);
    let angle = Math.acos(dot / (normalLength1 * normalLength2));
    angle = DegreesFromRadians(angle);
    if (angle > 90) angle = 180 - angle;
    return angle;
}
