import { useState, useEffect, useCallback } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { RequestStatus } from 'BMapsModel';
import { App } from 'BMapsSrc/BMapsApp';
import InspectorMessage from 'BMapsSrc/ui/info_display/InspectorMessage';
import { WorkingIndicator } from 'BMapsSrc/ui/ui_utils';
import { useColorSchemeInfo } from 'BMapsSrc/redux/prefs/access';
import { updateCompoundWithChemblId, updateMapCaseWithChemblId } from './chembl';
import { updateMapCaseWithUniprotId } from './uniprot';
import { updateMapCaseWithKlifs } from './klifs';

export function IntegrationsInspectorTab({ activeCompound }) {
    let mapCase;
    if (activeCompound) {
        mapCase = App.getDataParents(activeCompound).mapCase;
    } else {
        mapCase = App.Workspace.getLoadedProtein();
    }

    if (!activeCompound && !mapCase) {
        return (
            <InspectorMessage>
                No compound or protein available for integrations.
            </InspectorMessage>
        );
    }

    return (
        <div>
            <strong>Properties via 3rd Party Services</strong>
            {!!activeCompound && <CompoundIntegrations compound={activeCompound} />}
            {!!mapCase && <TargetIntegrations mapCase={mapCase} />}
        </div>
    );
}

const compoundIntegrations = {
    chembl: {
        chembl_ids: {
            label: 'Exact\xa0ChEMBL\xa0IDs', // non-breaking space
            async calculate(compound, trigger) {
                updateCompoundWithChemblId(compound, trigger);
            },
            tableData(value) {
                if (!value || value.length === 0) {
                    return 'No exact ChEMBL match';
                }
                return value.join(', ');
            },
        },
        tautomer_chembl_ids: {
            label: 'Tautomer\xa0ChEMBL\xa0IDs', // non-breaking space
            async calculate(compound, trigger) {
                updateCompoundWithChemblId(compound, trigger, { allowTautomers: true });
            },
            tableData(value) {
                if (!value || value.length === 0) {
                    return 'No tautomer ChEMBL match';
                }
                return value.join(', ');
            },
        },

    },
};

const targetIntegrations = {
    uniprot: {
        uniprot_ids: {
            label: 'UniProt\xa0IDs', // non-breaking space
            async calculate(mapCase, trigger) {
                updateMapCaseWithUniprotId(mapCase, trigger);
            },
            tableData(value) {
                if (!value || value.length === 0) {
                    return 'No UniProt match found';
                }
                return value.join(', ');
            },
        },
    },
    chembl: {
        chembl_ids: {
            label: 'ChEMBL\xa0IDs', // non-breaking space
            async calculate(mapCase, trigger) {
                updateMapCaseWithChemblId(mapCase, trigger);
            },
            tableData(value) {
                if (!value || value.length === 0) {
                    return 'No ChEMBL match found';
                }
                return value.join(', ');
            },
        },
    },
    klifs: {
        structure_ID: {
            label: 'KLIFS\xa0ID', // non-breaking space
            async calculate(mapCase, trigger) {
                await updateMapCaseWithKlifs(mapCase, trigger);
            },
            tableData(value) {
                if (!value || value.length === 0) {
                    return 'No KLIFS match found';
                }
                return Array.isArray(value) ? value.join(', ') : value;
            },
        },
        kinase: { label: 'Kinase' },
        DFG: { label: 'DFG' },
        aC_helix: { label: 'aC\xa0Helix' }, // non-breaking space
        // The following two are for testing "falsy" data that should appear in the table
        // back: {},
        // missing_residues: {},
    },
};

function flattenIntegrations(integrationObj) {
    const arr = [];
    for (const [scope, props] of Object.entries(integrationObj)) {
        for (const [prop, { label=prop, calculate, tableData }] of Object.entries(props)) {
            arr.push({
                scope, prop, label, calculate, tableData,
            });
        }
    }
    return arr;
}

function CompoundIntegrations({ compound }) {
    const [statusMap, updateStatusMap] = usePropertyStatusMap(compoundIntegrations, compound);
    const integrationList = flattenIntegrations(compoundIntegrations).map(({
        scope, prop, label, calculate: calculateOrig, tableData,
    }) => {
        const key = `${scope}-${prop}`;
        const { value, status, error } = statusMap[key];
        const calculate = calculateOrig && (() => calculateOrig(compound, updateStatusMap));
        return {
            key, scope, prop, label, value, status, error, calculate, tableData,
        };
    });
    return (<PropertyTable title={compound.displayName} integrationList={integrationList} />);
}

function TargetIntegrations({ mapCase }) {
    const [statusMap, updateStatusMap] = usePropertyStatusMap(targetIntegrations, mapCase);
    const integrationList = flattenIntegrations(targetIntegrations).map(({
        scope, prop, label, calculate: calculateOrig, tableData,
    }) => {
        const key = `${scope}-${prop}`;
        const { value, status, error } = statusMap[key];
        const calculate = calculateOrig && (() => calculateOrig(mapCase, updateStatusMap));
        return {
            key, scope, prop, label, value, status, error, calculate, tableData,
        };
    });
    return (<PropertyTable title={mapCase.displayName} integrationList={integrationList} />);
}

/**
 * React hook to provide property status and an update function to update state and trigger rerender
 * @param {*} integrations
 * @param {*} reference
 * @returns {[statusMap, updateStatusMap]}
 */
function usePropertyStatusMap(integrations, reference) {
    const getStatusMap = useCallback(() => flattenIntegrations(integrations).reduce(
        (acc, { scope, prop }) => {
            /** @type {import('BMapsModel').ScopedProperty} */
            const propInfo = reference.getPropertyInfo(scope, prop);
            // If the properties were requested as a group (eg KLIFS) but failed,
            // the individual properties won't exist, so pull from the parent scope.
            const groupInfo = reference.scopedProperties.getScope(scope);
            const status = propInfo?.traverseForRequestInfoItem('status')
                || groupInfo?.traverseForRequestInfoItem('status');
            const errors = propInfo?.traverseForErrors() || groupInfo?.traverseForErrors() || [];
            const infoToSend = { value: propInfo?.value, error: errors[0], status };
            return { ...acc, [`${scope}-${prop}`]: infoToSend };
        },
        {}
    ), [reference]);

    const [statusMap, setStatusMap] = useState(getStatusMap());
    const updateStatusMap = useCallback(() => setStatusMap(getStatusMap()), [getStatusMap]);
    useEffect(updateStatusMap, [reference]);
    return [statusMap, updateStatusMap];
}

function defaultDisplay(value) {
    if (Array.isArray(value)) {
        return value.join(', ');
    } else {
        return value;
    }
}

function PropertyTable({ title, integrationList }) {
    const { textCss } = useColorSchemeInfo();
    const noData = (x) => !x && !['number', 'boolean'].includes(typeof x);
    return (
        <div>
            <h3>{title}</h3>
            <Table>
                <TableBody>
                    {integrationList.map(({
                        key, label, status, value, error, calculate, tableData,
                    }) => {
                        let content;
                        switch (true) {
                            case status === RequestStatus.WORKING:
                                content = <WorkingIndicator />;
                                break;
                            case status === RequestStatus.FAILED:
                                content = (
                                    <span style={{ fontStyle: 'italic' }}>
                                        {error || 'Unknown error'}
                                    </span>
                                );
                                break;
                            default:
                                if (value != null || status === RequestStatus.SUCCEEDED) {
                                    content = tableData ? tableData(value) : defaultDisplay(value);
                                    if (noData(content)) {
                                        content = '(No data)';
                                    }
                                    if (typeof content === 'boolean'
                                        || (typeof content === 'object' && !Array.isArray(content))) {
                                        content = JSON.stringify(content);
                                    }
                                } else {
                                    // No value, and not working, failed, or succeeded
                                    content = '';
                                }
                        }

                        // Example: within KLIFS, only structure_ID has a calculate function, but it
                        // updates other fields (like DFG), which do not have a calculate function.
                        // This clause hides DFG, etc, until structure_ID has been calculated.
                        if (noData(value) && !calculate) {
                            return false;
                        }

                        return (
                            <TableRow key={key}>
                                <TableCell style={{ color: textCss }}>{label}</TableCell>
                                <TableCell style={{ color: textCss }}>{content}</TableCell>
                                <TableCell style={{ color: textCss, float: 'right' }}>
                                    {!!calculate && (
                                    <button type="button" onClick={() => calculate()}>
                                        Update
                                    </button>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}
