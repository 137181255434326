import { configureStore } from '@reduxjs/toolkit';
import listenerMiddleware from './middlewares/listenerMiddleware';
import prefsReducer from './prefs';
import { initStateChangeSubscriber, register } from './subscribers/stateChangeSubscriber';
import projectStateReducer from './projectState';

const reducer = {
    prefs: prefsReducer,
    projectState: projectStateReducer,
};

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // removes error message for non-serializable data in redux
            ignoredActionPaths: ['payload.mapCase', 'payload.compounds'],
            ignoredPaths: ['projectState.targetList', 'projectState.scoringTable.rows'],
        },
    }).concat(listenerMiddleware),
});

// Add store subscribers here
store.subscribe(initStateChangeSubscriber(store));

register('prefs.ColorScheme', (state) => state.prefs.ColorScheme);
register('prefs.BindingSiteDistance', (state) => state.prefs.BindingSiteDistance);

export default store;
