import styled from 'styled-components';
import MenuItem from './MenuItem';

export default styled(MenuItem).attrs({
    disableRipple: true,
})`
    cursor: default;

    &:hover {
        background: #FFFFFF;
    },
`;
