// FragmentSearchResult.js
/**
 * @fileoverview Class to unify fragment search results from bfd-server and dataservice.
 */

import { calculateMolecularWeight, countHeavyAtoms } from '../util/chem_utils';

export class FragmentSearchResult {
    static calculatePoseStatistics(searchResults) {
        for (const searchResult of searchResults) {
            const statsByProjectCase = statsForOneSuggestionRow(searchResult);
            for (const [projectCase, stats] of Object.entries(statsByProjectCase)) {
                const bestPoseInfo = searchResult.projectCaseDetails[projectCase];
                bestPoseInfo.fragStatistics = stats;
            }
        }
    }

    constructor(obj={}) {
        this.origin = obj.origin; // bfd-server | dataservice
        this.modType = obj.modType; // Near | Grow | etc
        this.fragAtomName = obj.fragAtomName;
        this.frags = obj.frags || [];
        this.selectedIndex = obj.selectedIndex || 0;
        this.name = obj.name;
        this.mwt = obj.mwt;
        this.projectCaseDetails = obj.projectCaseDetails || {};
    }

    addFrag(frag) {
        this.frags.push(frag);

        // Update project case details
        if (frag.projectCase) {
            const existingBindingInfo = this.projectCaseDetails[frag.projectCase];
            const newBindingInfo = new BestPoseBindingInfo(frag);
            if (!existingBindingInfo || newBindingInfo.isBetterThan(existingBindingInfo)) {
                this.projectCaseDetails[frag.projectCase] = newBindingInfo;
            }
        }
    }

    recalculateBestPoses(compareField) {
        for (const projectCase of Object.keys(this.projectCaseDetails)) {
            const bestBindingInfo = this.bestPoseForCase(projectCase, compareField);
            this.projectCaseDetails[projectCase] = bestBindingInfo;
        }
    }

    bestPoseForCase(projectCase, compareField) {
        let bestBindingInfo;
        for (const frag of this.frags) {
            if (frag.projectCase !== projectCase) continue;
            const newBindingInfo = new BestPoseBindingInfo(frag);
            if (!bestBindingInfo || newBindingInfo.isBetterThan(bestBindingInfo, compareField)) {
                bestBindingInfo = newBindingInfo;
            }
        }
        return bestBindingInfo;
    }
}

export class BfdServerFragmentSearchResult extends FragmentSearchResult {
    static syncResultFragments(searchResults, fragments, caseData) {
        const fragMap = new Map();
        for (const frag of fragments) {
            frag.setCaseData(caseData);
            frag.projectCase = caseData?.mapCase?.projectCase;
            if (frag.poseSerialNo != null) fragMap.set(frag.poseSerialNo, frag);
        }

        for (const searchResult of searchResults) {
            for (const poseId of searchResult.selectionIDs) {
                const frag = fragMap.get(poseId);
                if (frag) {
                    searchResult.addFrag(frag);
                } else {
                    console.warn(`Unknown fragment selectionID for ${searchResult.name}: ${poseId}`);
                }
            }
        }
    }

    /**
     * @param {object} result One result from the modification-selections packet from bfd-server
     */
    constructor(result) {
        super({
            origin: 'bfd-server',
            modType: result.modType,
            fragAtomName: result.fragAtomName,
            name: result.name,
            mwt: result.molecularWeight,
        });
        this.selectionIDs = [result.serialNumber];
        this.providedSolvation = result.solvationEnergy;
        this.providedExchemP = result.excessChemicalPotential;
    }

    /**
     *
     * @param {object} result One result from the modification-selections packet from bfd-server
     */
    addPoseId(result) {
        this.selectionIDs.push(result.serialNumber);
    }
}

export class DataServiceFragmentSearchResult extends FragmentSearchResult {
    /**
     * @param {*} frag
     * @param {*} mapCasesInQuery
     */
    constructor(frag, mapCasesInQuery=[], obj={}) {
        super({
            origin: 'dataservice',
            modType: obj.modType || 'Near',
            fragAtomName: obj.fragAtomName,
            name: frag.baseFrag.name,
            ddGs: frag.enSolv,
            mwt: calculateMolecularWeight(frag.getAtoms()),
            // Most will get filled out by call to addFrag
        });

        // Initialize project case details for the multi-protein search case.
        // This is so LigandMod knows to display multi-protein results, even if
        // there aren't actually any search results found.
        // It would probably be better to track this information separately.
        for (const { projectCase } of mapCasesInQuery) {
            this.projectCaseDetails[projectCase] = new BestPoseBindingInfo();
        }

        this.addFrag(frag);
    }
}

/**
 * Collect information about a best pose from fragment search results.
 * This will be displayed and sortable in columns.
 */
export class BestPoseBindingInfo {
    // Since we're using scores rather than actual energies, Ian suggests
    // dividing by 1.36 which makes FE into log(IC50), closer to values they
    // might expect.
    static getBindingScore(bindingFE) {
        return bindingFE != null ? (bindingFE / 1.36) : null;
    }

    static getLigandEfficiency(bindingFE, nheavy) {
        return bindingFE != null && nheavy ? (-bindingFE/nheavy) : null;
    }

    static isBetter(aIn, bIn, compareField, higherIsBetter=false) {
        const a = aIn?.[compareField];
        const b = bIn?.[compareField];
        if (a == null) return false;
        if (b == null) return true;
        return higherIsBetter ? a > b : a < b;
    }

    constructor(frag) {
        this.bestPose = frag;
        if (frag) {
            const exchemPotential = frag.exchemPotential;
            const nheavy = countHeavyAtoms(frag.getAtoms());
            this.nheavy = nheavy;
            this.exchemPotential = exchemPotential;
            this.bindingFE = exchemPotential;
            this.enSolute = frag.enSolute;
            this.enSolv = frag.enSolv;
            this.fragStatistics = {};
        }
    }

    get bindingScore() {
        return BestPoseBindingInfo.getBindingScore(this.bindingFE);
    }

    get ligandEfficiency() {
        return BestPoseBindingInfo.getLigandEfficiency(this.bindingFE, this.nheavy);
    }

    get ligandEfficiencyEn() {
        return BestPoseBindingInfo.getLigandEfficiency(this.enSolute, this.nheavy);
    }

    isBetterThan(other, compareField='bindingScore', higherIsBetter=false) {
        return BestPoseBindingInfo.isBetter(this, other, compareField, higherIsBetter);
    }

    meetsThreshold(threshold, compareField='bindingScore', higherIsBetter=false) {
        return higherIsBetter ? this[compareField] >= threshold : this[compareField] <= threshold;
    }
}

// Stuff for collecting statistics about the poses for a fragment distribution.
// This is used by column_definitions: getPoseStatisticsColumns and getFragStatFn.

/**
 * Collect values from a single fragment that will be used to calculate statistics
 */
function onePoseStatValues(frag) {
    return {
        enSolute: frag.enSolute,
        exchemPotential: frag.exchemPotential,
    };
}

/**
 * Collect values from all poses (categorized by case) that will be used to calculate statistics
 */
function collectPoseStatValues(searchResult) {
    const values = {};
    for (const frag of searchResult.frags) {
        const fragValues = onePoseStatValues(frag);

        const projectCase = frag.projectCase;
        if (!values[projectCase]) values[projectCase] = {};
        const projectCaseValues = values[projectCase];

        for (const [name, value] of Object.entries(fragValues)) {
            if (value == null) continue;
            if (projectCaseValues[name]) {
                projectCaseValues[name].push(value);
            } else {
                projectCaseValues[name] = [value];
            }
        }
    }
    return values;
}

/**
 * Collect values and calculate statistics for poses in the search result (separating by protein)
 */
function statsForOneSuggestionRow(searchResult) {
    const stats = {};
    const values = collectPoseStatValues(searchResult);

    for (const [projectCase, projectCaseValues] of Object.entries(values)) {
        const perCaseStats = {};
        for (const [name, vals] of Object.entries(projectCaseValues)) {
            const avg = vals.reduce((a, b) => a + b, 0) / vals.length;
            const max = Math.max(...vals);
            const min = Math.min(...vals);
            perCaseStats[name] = { avg, max, min };
        }
        perCaseStats['poseCount'] = Object.values(projectCaseValues)
            .reduce((acc, nextVals) => Math.max(acc, nextVals.length), 0);
        stats[projectCase] = perCaseStats;
    }

    return stats;
}
