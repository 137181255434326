import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { SvgViewer } from 'BMapsSrc/ui/UIComponents';
import { useExportDataFetch } from '../exportUtils';
import { WorkingIndicator } from '../../../ui_utils';
import { App } from '../../../../BMapsApp';
import { ResponseIds } from '../../../../server/server_common';
import { DialogSubmitButton } from '../../../common/DialogActionButtons';
import { ErrorMessage, Notice, CopyToClipboard } from '../Components';
import { svgForMol } from '../../../../util/svg_utils';

const MculeSvcCode = 'Mcule';

const config = {
    formats: ['smiNoName'],
    allowMultiple: false, // In principle can support by making multiple calls in client
    allowStaticMode: false, // Need server connection to place http fetch (to get around CORS)
    allowMultipleProteins: true, // If we support multiple cmpds, it won't matter which protein
    disallowAtoms: true,
};

export default function ExportMculeTab({ selected }) {
    const { errors, formattedData, context } = useExportDataFetch(selected, config, 'Mcule');
    const [load, setLoad] = useState(false);
    const { exportTarget, setLoading, isMobile } = context;
    const exportSmiData = formattedData['smiNoName'];
    const [error, setError] = useState(null);
    const [mculeData, setMculeData] = useState(null);
    const requestObj = {
        url: `https://mcule.com/api/v1/search/lookup/?query=${encodeURIComponent(exportSmiData)}`,
        method: 'GET',
        headers: {},
        body: null,
    };

    async function sendServerRequest(requestObject) {
        try {
            setLoad(true);
            const serviceRequest = App.ServerConnection.cmdMoleculeServiceRequest;
            const responseData = await serviceRequest(MculeSvcCode, JSON.stringify(requestObject));
            const [packet] = responseData.byId(ResponseIds.MolServiceRequestResult);

            if (responseData.hasError()) {
                throw new Error(responseData.errors);
            }

            if (packet.success === false) {
                throw new Error(packet.data);
            }

            console.log('Mcule API Response:', packet);
            if (!packet.data?.results) {
                throw new Error('Unrecognized response from Mcule API');
            }

            const responseObjects = packet.data.results.map((result) => ({
                smiles: result.smiles,
                url: result.url,
                id: result.mcule_id,
                svgData: null,
            }));
            const svgPromises = responseObjects.map(async (responseObj) => {
                if (responseObj.smiles) {
                    try {
                        const params = { baseBondColor: 0x000000, dearomatize: true };
                        const format = 'smi';
                        const svg = await svgForMol({
                            source: 'rdkit', molData: responseObj.smiles, format, params,
                        });
                        responseObj.svgData = svg;
                    } catch (ex) {
                        console.error(`Error loading SVG for ${responseObj.id}: ${ex}`);
                    }
                }
            });
            await Promise.all(svgPromises);
            setMculeData(responseObjects);
        } catch (errorData) {
            console.error('Error making Mcule API request:', errorData.message);
            setError(errorData.message);
        } finally {
            setLoad(false);
        }
    }

    useEffect(() => {
        const ready = selected && exportSmiData && errors.length === 0;
        if (ready) {
            sendServerRequest(requestObj);
        }
    }, [exportSmiData, selected, errors]);

    if (load === true) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20vh' }}>
                <h4>Searching Mcule...</h4>
                <WorkingIndicator />
            </div>
        );
    }

    let sm;

    if (errors.length) {
        sm = (
            <Grid container spacing={2} direction="column" alignItems="center">
                <Notice title="Problem searching Mcule">
                    <ErrorMessage>{errors[0]}</ErrorMessage>
                </Notice>
            </Grid>
        );
    } else {
        sm = (
            <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item component="h4">Results from Mcule:</Grid>
                <Grid item>
                    {error ? (
                        <ErrorMessage>{error}</ErrorMessage>
                    ) : (
                        <div>
                            {mculeData && mculeData.length > 0 ? (
                                mculeData.map((result) => (
                                    <div key={result.id}>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.5em' }}>
                                            <a href={result.url} target="_blank" rel="noreferrer" style={{ border: '1px solid #0071bc', padding: '.5em' }}>
                                                <SvgViewer svg={result.svgData} alt={`2D structure of ${result.id}`} style={{ maxHeight: '20em', maxWidth: '20em' }} />
                                            </a>
                                        </div>
                                        <p>
                                            <strong>Mcule ID:</strong>
                                            {' '}
                                            {result.id}
                                            <CopyToClipboard copyTarget={result.id} />
                                        </p>
                                        <p>
                                            <strong>SMILES in Mcule:</strong>
                                            {' '}
                                            {result.smiles}
                                            <CopyToClipboard copyTarget={result.smiles} />
                                        </p>
                                        <p>
                                            <DialogSubmitButton $isMobile={isMobile} className="mcule_link" href={result.url} target="_blank" rel="noreferrer">View at Mcule</DialogSubmitButton>
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p>No results found</p>
                            )}
                        </div>
                    )}
                </Grid>
            </Grid>
        );
    }

    return sm;
}
