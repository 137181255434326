/*
 */
import _ from 'lodash';
import { StyleManager, MolStyles } from '../style_manager';
import { Log } from '../Log';
import { App } from '../BMapsApp';
import { HydrogenDisplayOptions } from '../util/display_utils';

// The definitive list of DisplayState parameters
export const DisplayStates = {
    bindingsite: 'bindingsite',
    hydrogens: 'hydrogens',
    waters: 'waters',
    hbonds: 'hbonds',
    pipistacking: 'pipistacking',
    bindingSiteSurfaceState: 'bindingSiteSurfaceState',
    functionalGroupHighlight: 'functionalGroupHighlight',
    hotspots: 'hotspots',
    activeCompoundStyle: 'activeCompoundStyle',
    inspectorTab: 'inspectorTab',
    sorting: 'sorting',
    showBfactor: 'showBfactor',
};

// Constants for various values
export const WaterStates = {
    none: 'none',
    crystal: 'crystal',
    computed: 'computed',
    all: 'all',
};

export const BindingSiteSurfaceStates = {
    useSelected: 'useSelected',
    off: 'off',
    ddgs: 'ddgs',
    hydrophobicity: 'hydrophobicity',
};

export const HBondStates = {
    none: 'none',
    normal: 'normal',
    includeWeak: 'includeWeak',
};

export const InspectorTabs = {
    structure: 'structure',
    energies: 'energies',
    properties: 'properties',
    interactionDiagram: 'interactionDiagram',
    GiFE: 'GiFE',
    Integrations: 'Integrations',
};

export const SortTypes = {
    ProteinTree: {
        chain: 'chain',
        type: 'type',
        residue: 'residue',
    },
    FragmentsTree: {
        default: 'default',
        fragset: 'fragset',
        library: 'library',
    },
};

// Validation of DisplayState values
export const DisplayStateValidation = {
    [DisplayStates.bindingsite]: { true: true, false: false },
    [DisplayStates.hydrogens]: HydrogenDisplayOptions,
    [DisplayStates.waters]: WaterStates,
    [DisplayStates.hbonds]: HBondStates,
    [DisplayStates.pipistacking]: { true: true, false: false },
    [DisplayStates.bindingSiteSurfaceState]: BindingSiteSurfaceStates,
    [DisplayStates.functionalGroupHighlight]: { true: true, false: false },
    [DisplayStates.hotspots]: { true: true, false: false },
    [DisplayStates.showBfactor]: { true: true, false: false },
    // Need to consolidate with style manager
    [DisplayStates.activeCompoundStyle]: MolStyles,
    [DisplayStates.inspectorTab]: InspectorTabs,
    [DisplayStates.sorting]: (allSortingInfo) => {
        for (const [which, value] of Object.entries(allSortingInfo)) {
            const valid = SortTypes[which] && Object.values(SortTypes[which]);
            if (valid && !valid.includes(value)) {
                return false;
            }
        }
        return true;
    },
};

function validateDisplayState(category, value) {
    const validation = DisplayStateValidation[category];
    if (typeof (validation) === 'undefined') {
        return true;
    } else if (typeof (validation) === 'function') {
        return validation(value);
    } else if (validation[value] !== undefined) {
        return true;
    } else {
        Log.warn(`Attempting to assign invalid value to ${category}: ${value}`);
        return false;
    }
}

/**
 * @description Contains display configuration state, including:
 * - bindingsite - Boolean, are we in Ligand View
 * - hydrogens - hydrogen display type; all, none, or polar
 * - hbonds - hydrogen bond display type: none, normal, includeWeak
 * - pipistacking - Boolean, are we showing pi-pi stacking?
 * - bindingSiteSurfaceState - off, ddgs, hydrophobicity, useselected
 * - functionalGroupHighlight - Boolean, are we showing transparent spheres for functional groups
 * - hotspots - Boolean, are we showing hotspot clouds?
 * - activeCompoundStyle - MolStyle for the active compound
 * - inspectorTab - which bottom-left inspector tab is active
 * - sorting - sort types for the different tabs in the left hand selector
 */
export class DisplayState {
    static equals(a, b) {
        for (const i of Object.keys(DisplayStates)) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    static get InitialDisplayState() {
        return {
            [DisplayStates.bindingsite]: false, // Might be good to track focus cmpd w/ bindingsite
            [DisplayStates.hydrogens]: HydrogenDisplayOptions.polar,
            [DisplayStates.waters]: WaterStates.none,
            [DisplayStates.hbonds]: HBondStates.normal,
            [DisplayStates.pipistacking]: false,
            [DisplayStates.bindingSiteSurfaceState]: BindingSiteSurfaceStates.off,
            [DisplayStates.functionalGroupHighlight]: false,
            [DisplayStates.hotspots]: false,
            [DisplayStates.showBfactor]: true,
            [DisplayStates.activeCompoundStyle]: MolStyles.ballandstick,
            [DisplayStates.inspectorTab]: InspectorTabs.structure,
            [DisplayStates.sorting]: {
                ProteinTree: SortTypes.ProteinTree.chain,
                FragmentsTree: SortTypes.FragmentsTree.default,
            },
        };
    }

    // These are unused for now.
    // static ShowingHBonds(state) {
    //     return state.hbonds === HBondStates.normal || state.hbonds === HBondStates.includeWeak;
    // }

    // static ShowingCrystalWaters(state) {
    //     return state.waters === WaterStates.crystal || state.waters === WaterStates.all;
    // }

    // Getters return the "view state" which applies any temp styles
    get bindingsite() { return this.viewState[DisplayStates.bindingsite]; }
    get hydrogens() { return this.viewState[DisplayStates.hydrogens]; }
    get waters() { return this.viewState[DisplayStates.waters]; }
    get hbonds() { return this.viewState[DisplayStates.hbonds]; }
    get pipistacking() { return this.viewState[DisplayStates.pipistacking]; }
    get bindingSiteSurfaceState() { return this.viewState[DisplayStates.bindingSiteSurfaceState]; }
    get functionalGroupHighlight() {
        return this.viewState[DisplayStates.functionalGroupHighlight];
    }

    get hotspots() { return this.viewState[DisplayStates.hotspots]; }
    get showBfactor() { return this.viewState[DisplayStates.showBfactor]; }
    get activeCompoundStyle() { return this.viewState[DisplayStates.activeCompoundStyle]; }
    get inspectorTab() { return this.viewState[DisplayStates.inspectorTab]; }
    get sorting() { return this.viewState[DisplayStates.sorting]; }

    constructor(onChange) {
        this.state = {};
        this.temp_state = {};
        this.onChange = onChange;
        this.reset(false);
    }

    reset(triggerChange=true) {
        this.update(DisplayState.InitialDisplayState, triggerChange);
    }

    // Change the view state
    update(newState, triggerChange=true) {
        DisplayState.copy(newState, this.state);
        if (triggerChange && this.onChange) {
            this.onChange();
        }
    }

    // Change the view state "temporarily"
    // The idea of temp state is that the change doesn't enter the action
    // queue as an undoable action.  This provides an easy mechanism to
    // set and restore from a temp veiw state.
    // Examples: displaying weak hbonds | hiding active compound
    updateTemp(newState, triggerChange=true) {
        DisplayState.copy(newState, this.temp_state, 'deleteUndefined');
        if (triggerChange && this.onChange) {
            this.onChange();
        }
    }

    // Return the "live" state, including state and applying tempState
    get viewState() {
        const ret = this.saveState();
        return DisplayState.copy(this.temp_state, ret);
    }

    // Return a copy of the long term state
    saveState() {
        return DisplayState.copy(this.state);
    }

    // Reload a previously saved state
    restoreState(state) {
        this.update(state);
    }

    // Various states are all shallow objects.  This method copies values
    // when updating, saving, etc.
    static copy(src, destIn, undefinedBehavior='ignoreUndefined') {
        const dest = destIn || {};
        for (const [key, val] of Object.entries(src)) {
            if (DisplayStates[key] === undefined) continue;
            if (val !== undefined || undefinedBehavior === 'copyUndefined') {
                if (validateDisplayState(key, val)) {
                    dest[key] = val;
                }
            } else if (undefinedBehavior === 'deleteUndefined') {
                delete (dest[key]);
            } else {
                // do nothing for 'ignoreUndefined'
            }
        }
        return dest;
    }

    // Convenience helpers
    setBindingSite(value) {
        this.update({ bindingsite: value });
    }

    setHBonds(style) {
        this.update({ hbonds: style });
    }

    setPiPiStacking(show) {
        this.update({ pipistacking: show });
    }

    setWaters(style) {
        this.update({ waters: style });
    }

    setHydrogens(style) {
        this.update({ hydrogens: style });
    }

    setBindingSiteSurface(stateId) {
        this.update({ bindingSiteSurfaceState: stateId });
    }

    setFunctionalGroupHighlight(show) {
        this.update({ functionalGroupHighlight: show });
    }

    setHotspots(show) {
        this.update({ hotspots: show });
    }

    setBfactor(show) {
        this.update({ showBfactor: show });
    }

    setInspectorTab(tab) {
        this.update({ [DisplayStates.inspectorTab]: tab });
    }

    setSorting(sorting) {
        const newValue = {
            ...this.state[DisplayStates.sorting],
            ...sorting,
        };
        this.update({ [DisplayStates.sorting]: newValue });
    }
}

// Business level functions that call into DisplayState
export class DisplayStateController {
    constructor(displayState) {
        this.displayState = displayState;
        this.savedStateForPubMode = null;
    }

    reset(triggerChange) {
        StyleManager.reset();
        this.displayState.reset(triggerChange);
    }

    // Methods specific to publication view.
    // This could be removed if pub view changes.
    // Logic: When going into pub view, it saves the current view state,
    // It restores the saved state when going back to ligand or protein view.
    // If any of the settings affected by pub mode are changed while you are in pub mode,
    // The corresponding changes are made in the saved state so that when you leave pub mode,
    // Those changes will be preserved.  The whole concept of pub mode needs to be rethought.
    saveForPubMode() {
        this.savedStateForPubMode = this.displayState.saveState();
    }

    updateSavedForPubMode(newState) {
        if (this.savedStateForPubMode) {
            DisplayState.copy(newState, this.savedStateForPubMode);
        }
    }

    restoredFromPubMode(newStateIn) {
        const newState = DisplayState.copy(newStateIn, this.savedStateForPubMode);
        this.savedStateForPubMode = null;
        return newState;
    }

    // Public methods for updating view state
    setView(view) {
        switch (view) {
            case 'ligand':
            case 'protein': {
                let newState = { bindingsite: view === 'ligand' };
                if (this.savedStateForPubMode) {
                    // Restore state if switching back after Pub mode.
                    newState = this.restoredFromPubMode(newState);
                }
                this.displayState.update(newState);
                break;
            }
            case 'publication':
                // Save state when switching to pub mode, which turns on hotspots.
                // They will be automatically turned off if you switch back.
                this.saveForPubMode();
                this.displayState.update({
                    bindingsite: true,
                    hbonds: HBondStates.normal,
                    hotspots: true,
                    hydrogens: HydrogenDisplayOptions.polar,
                });
                break;
            default:
                Log.warn(`Attempting to display unknown view state: ${view}`);
                break;
        }
    }

    setWaters(waters) {
        this.displayState.setWaters(waters);
    }

    setHydrogens(hydrogens) {
        this.updateSavedForPubMode({ hydrogens });
        this.displayState.setHydrogens(hydrogens);
    }

    setHBonds(hbonds) {
        this.updateSavedForPubMode({ hbonds });
        this.displayState.setHBonds(hbonds);
    }

    setPiPiStacking(show) {
        this.displayState.setPiPiStacking(show);
    }

    setBindingSiteSurface(bindingSiteSurfaceState) {
        this.displayState.setBindingSiteSurface(bindingSiteSurfaceState);
    }

    setLigandSolvation(functionalGroupHighlight) {
        this.displayState.setFunctionalGroupHighlight(functionalGroupHighlight);
    }

    setHotspots(hotspots) {
        this.updateSavedForPubMode({ hotspots });
        this.displayState.setHotspots(hotspots);
    }

    setBfactor(showBfactor) {
        this.updateSavedForPubMode({ showBfactor });
        this.displayState.setBfactor(showBfactor);
    }

    weakHBondsTemp(include) {
        this.displayState.updateTemp({
            [DisplayStates.hbonds]: include ? HBondStates.includeWeak : undefined,
        });
    }

    hideActiveTemp(hide) {
        this.displayState.updateTemp({
            [DisplayStates.activeCompoundStyle]: hide ? MolStyles.hidden : undefined,
        });
    }

    setInspectorTab(tab) {
        this.displayState.setInspectorTab(tab);
    }

    setSorting(sorting) {
        this.displayState.setSorting(sorting);
    }
}

/** MolAtomGroupState
 * @classdesc This class contains general system properties about AtomGroups that
 * should not be stored in the MolAtomGroups themselves.
 * For example, have they been "pinned"
 * or selected in the Selector.
  */
export class MolAtomGroupState {
    constructor() {
        this.map = new Map();
    }

    dumpState() {
        return `${this.map.size} atom group state entries: ${[...this.map.keys()].map((x) => x.key).join(', ')}`;
    }

    set(atomGroup, state) {
        this.map.set(atomGroup, state);
    }

    /** setState()
     * @description Set the AtomGroup state to an exact property list object
     * @param atomGroup an AtomGroup to set the state of
     * @param props the exact properties to assign
    */
    setState(atomGroup, props) {
        this.set(atomGroup, { ...props });
    }

    /**
     * @description remove a AtomGroup from state tracking
     * @param {*} atomGroups the atomGroup(s) to remove
     */
    remove(atomGroups) {
        for (const atomGroup of _.castArray(atomGroups)) {
            this.map.delete(atomGroup);
        }
    }

    removeProp(atomGroup, prop) {
        const state = this.getState(atomGroup);
        let changed = false;
        if (state[prop] !== undefined) {
            delete (state[prop]);
            changed = true;
            this.set(atomGroup, state);
        }
        return changed;
    }

    /** update()
     * @description Update AtomGroup state with items from a property list object
     * @param atomGroup an AtomGroup to update the state of
     * @param props properties to merge in
     * @returns Boolean whether or not the update resulted in a data change
     */
    update(atomGroup, props) {
        const state = this.getState(atomGroup);

        let changed = false;
        for (const [propName, propVal] of Object.entries(props)) {
            if (state[propName] !== propVal) {
                changed = true;
                break;
            }
        }

        Object.assign(state, props);
        this.set(atomGroup, state);
        return changed;
    }

    /**
     * @description Toggle a molatomgroup property between true and deleted
     * from the state object.
     * @param {*} atomGroup
     * @param {*} prop
     */
    toggle(atomGroup, prop, forceValue) {
        const state = this.getState(atomGroup);
        const newValue = forceValue !== undefined ? forceValue : !state[prop];
        if (newValue) {
            state[prop] = true;
        } else {
            delete (state[prop]);
        }

        this.setState(atomGroup, state);
    }

    /**
     * @description returns the state of an molatomgroup
     * @param {*} ag
     * @returns a copy of the state saved for this molatomgroup
     */
    getState(ag) {
        const state = this.map.get(ag) || {};
        return { ...state };
    }

    /**
     *
     * @param {*} queryProps an object containing required properties
     * @param {*} list optional list of atomgroups to search
     * @example query({visible: true})
     * @example query({selected: 'falsy'}, listOfCompounds)
     * @example query({visible: true, notEqual: activeCompound}, listOfCompounds)
     * @returns a list of atomgroups which match the queryProps. An
     * empty query object returns everything.
     */
    query(queryProps={}, list) {
        const result = [];

        if (list) {
            list.forEach((atomGroup) => {
                const atomGroupProps = this.getState(atomGroup);
                if (this.match(atomGroup, atomGroupProps, queryProps)) {
                    result.push(atomGroup);
                }
            });
        } else {
            this.map.forEach((atomGroupProps, atomGroup) => {
                if (this.match(atomGroup, atomGroupProps, queryProps)) {
                    result.push(atomGroup);
                }
            });
        }
        return result;
    }

    /* internal function for testing the props against an object */
    match(atomGroup, atomGroupProps, queryProps) {
        let result = true;
        for (const [queryPropName, queryPropVal] of Object.entries(queryProps)) {
            switch (queryPropName) {
                case 'notEqual':
                    if (queryPropVal === atomGroup) {
                        result = false;
                    }
                    break;
                case 'sameClass':
                    if (queryPropVal.constructor.name !== atomGroup.constructor.name) {
                        result = false;
                    }
                    break;
                case 'type':
                    if (queryPropVal !== atomGroup.type) {
                        result = false;
                    }
                    break;
                default: {
                    const queryProp = queryPropVal;
                    const atomGroupProp = atomGroupProps[queryPropName];
                    if ((queryProp === MolAtomGroupState.Falsy && atomGroupProp)
                        || (queryProp !== MolAtomGroupState.Falsy && queryProp !== atomGroupProp)) {
                        result = false;
                        break;
                    }
                }
            }
            if (!result) {
                break;
            }
        }

        return result;
    }

    saveState(savingCaseData) {
        const saving = {};
        for (const ag of this.map.keys()) {
            // TODO: we're not supposed to import App in model classes...find a better way
            const { caseData } = App.getDataParents(ag);
            if (savingCaseData !== caseData) continue;
            const { type, key } = ag;
            if (!type || !key) {
                console.warn(`Unable to save mol group state.Type: ${type}, key: ${key}`);
                continue;
            }
            const state = this.getState(ag);
            if (Object.keys(state).length > 0) {
                if (!saving[type]) saving[type] = [];
                saving[type].push({ key, state });
            }
        }
        return saving;
    }
}

MolAtomGroupState.Visible = 'visible';
MolAtomGroupState.Selected = 'selected';
MolAtomGroupState.Active = 'active';
MolAtomGroupState.Collapsed = 'collapsed';
MolAtomGroupState.Falsy = 'falsy';
MolAtomGroupState.Starred = 'starred';
