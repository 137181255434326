// visualizations/vector_visualizations.js
/**
 * @fileoverview Functions to display bond vectors in the canvas.
 * @typedef {import('BMapsModel/display_models').BondVectorDisplay} BondVectorDisplay
 * @typedef {object} CanvasShape
 *
 * Public interface:
 *    hideAllBondVectors()
 *    drawBondVectors(bondVectors, options)
 *
 * Internal storage:
 *   visibleBondVectorShapes: CanvasShape[]
 *
 * This draws each bond vector that it is given. It tracks only the vectors that are on the screen.
 */

const { removeShape, drawArrow } = require('BMapsSrc/MainCanvas');
const {
    Normalize3, Sub3_3, Cross3, Add3_3, Mul3_1,
} = require('BMapsSrc/math');
const { isHydrogen } = require('BMapsSrc/util/chem_utils');
const { hydrogenCheck } = require('BMapsSrc/util/display_utils');

/**
 * Keep track of drawn bond vectors
 * @type {CanvasShape[]}
 */
let visibleBondVectorShapes = [];

export function hideAllBondVectors() {
    if (visibleBondVectorShapes.length > 0) {
        for (const shape of visibleBondVectorShapes) {
            removeShape(shape);
        }
        visibleBondVectorShapes = [];
    }
}

/**
 * Display bond vectors in the canvas, first removing other vectors.
 * @param {BondVectorDisplay[]} bondVectors
 * @param {{ displayHydrogens: boolean }} options
 */
export function drawBondVectors(bondVectors, { displayHydrogens }) {
    // Remove existing bond vector highlights before showing any.
    // This behavior could be changed if we need to show / hide at a more granular level
    hideAllBondVectors();

    /** Is an atom currently hidden because of Hydrogen display settings? */
    const isHiddenHydrogen = (atom) => isHydrogen(atom) && !hydrogenCheck(atom, displayHydrogens);

    for (const { atomPair, options } of bondVectors) {
        const [tailAtom, headAtom] = atomPair;
        if (headAtom === undefined || headAtom === tailAtom) return;

        const visibleHead = !isHiddenHydrogen(headAtom);

        const color = options.color || 'cyan';

        const posToObj = (vec) => ({ x: vec[0], y: vec[1], z: vec[2] });
        const unitVec = (vec1, vec2) => Normalize3(Sub3_3(vec1, vec2));

        // Bond sticks are, by default, 0.25, so make the cylinder smaller
        const radius = 0.15;
        const offset = 0.75;
        const headDiamMult = 1.6;
        const opacity = 1.0; // from monitors.tcl
        const headPos = headAtom.getPosition();
        const tailPos = tailAtom.getPosition();

        // Next, find a plane made by vectors to adjacent atoms, and use the normal to the
        // plane to provide offsets for the arrow vector.  But consider colinear
        // configurations.
        let vertexAtom = headAtom.bondedAtoms.length === 1 ? tailAtom : headAtom;
        let connected = vertexAtom.bondedAtoms.filter((x) => x.bondedAtoms.length > 1);
        if (connected.length === 1) {
            // Might be methyl, amine, etc. terminal atom
            vertexAtom = connected[0]; // generally the tail
            connected = vertexAtom.bondedAtoms;
        }
        let normVec = [0, 0, 0]; // default to no offset
        const vertexPos = vertexAtom.getPosition();
        if (connected.length >= 2) {
            const vec1 = unitVec(connected[0].getPosition(), vertexPos);
            const vec2 = unitVec(connected[1].getPosition(), vertexPos);
            normVec = Cross3(vec1, vec2);
        } else {
            const newz = Math.sqrt(vertexPos[0]**2 + vertexPos[1]**2);
            normVec = unitVec([-vertexPos[0], -vertexPos[1], newz]);
        }

        // If the bond vector is toward a visible atom, then display two bond vector
        // arrows, offset to each side, so they don't conflict with the atoms or bond.
        // If the bond vector is toward an invisible atom, then display a
        // single bond vector arrow, offset in the direction of the arrow,
        // so the arrow's tail isn't hidden in the tail atom's sphere.
        let arrow1HeadPos;
        let arrow1TailPos;
        let arrow2HeadPos;
        let arrow2TailPos;
        if (visibleHead) {
            arrow1HeadPos = Add3_3(headPos, Mul3_1(normVec, offset));
            arrow1TailPos = Add3_3(tailPos, Mul3_1(normVec, offset));
            arrow2HeadPos = Add3_3(headPos, Mul3_1(normVec, -offset));
            arrow2TailPos = Add3_3(tailPos, Mul3_1(normVec, -offset));
        } else {
            const unitOffset = 0.5;
            const unitVector = unitVec(headPos, tailPos);
            arrow1HeadPos = Add3_3(headPos, Mul3_1(unitVector, unitOffset));
            arrow1TailPos = Add3_3(tailPos, Mul3_1(unitVector, unitOffset));
        }

        const shapeOptions = {
            opacity,
            description: options.description || '',
            onClick: options.onClick || null,
            object: options.object || null,
        };
        const arrow1 = drawArrow(
            posToObj(arrow1TailPos), posToObj(arrow1HeadPos),
            radius, color, headDiamMult, shapeOptions
        );
        visibleBondVectorShapes.push(arrow1);
        if (visibleHead) {
            const arrow2 = drawArrow(
                posToObj(arrow2TailPos), posToObj(arrow2HeadPos),
                radius, color, headDiamMult, shapeOptions
            );
            visibleBondVectorShapes.push(arrow2);
        }
    }
}
