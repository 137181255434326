import React from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { CacheProvider } from '@emotion/react';
import store from '../redux/store';
import createEmotionCache from './createEmotionCache';

export default function PageWrapper({ theme, children, PageWrapperProps }) {
    const emotionCache = PageWrapperProps?.emotionCache ?? createEmotionCache();
    return (
        <ErrorBoundary>
            {/* CacheProvider injects the emotion styles in lieu of StyledEngineProvider */}
            <CacheProvider value={emotionCache}>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <Provider store={store}>
                            {children}
                        </Provider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </CacheProvider>
        </ErrorBoundary>
    );
}

class ErrorBoundary extends React.Component {
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error('error: ', error);
        console.error('errorInfo: ', errorInfo);
    }

    render() {
        const { children } = this.props;
        return children;
    }
}
