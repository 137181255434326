import { createTheme } from '@mui/material/styles';

const main = '#006428';

export default createTheme({
    palette: {
        primary: {
            main,
        },
        neutral: {
            main: 'rgba(0, 0, 0, 0.87)',
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paperWidthMd: {
                    maxWidth: '55em',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
    },
});
