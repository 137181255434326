import { UserActions } from 'BMapsCmds';
import { Loader } from 'BMapsSrc/Loader';
import { openHelpMenu } from 'BMapsSrc/ui/HelpPanel';
import { EventBroker } from '../../../eventbroker';
import { loadProteinByUri } from '../../../project_data';
import { imageSrc } from '../../ui_utils';
import { SessionManager } from '../../../SessionManager';

export function MainPage({ manual, args }) {
    const TheManual = manual;
    const proteinInfo = TheManual.Pages.ChooseProtein;
    const addCmpdInfo = TheManual.Pages.AddCompound;
    const modifyInfo = TheManual.Pages.ModifyCompound;
    const dockInfo = TheManual.Pages.DockCompound;
    const evaluateInfo = TheManual.Pages.Evaluate;
    const fragDataInfo = TheManual.Pages.FragmentData;
    const savedSessions = SessionManager.listSavesFromLocalStorage();
    const recentProteins = SessionManager.listRecentProteins();
    const queryStringInstructions = processArgs(args);
    const useNormalLoadFunctions = !queryStringInstructions;
    return (
        <div id="mainPage">
            {/* This extra div is so #mainPage justify-content affects the content as a whole. */}
            <div>
                <h3 style={{ textAlign: 'center' }}>Welcome to BMaps!</h3>
                <br />
                { useNormalLoadFunctions
                    ? (
                        <NormalLoadFunctions
                            recentProteins={recentProteins}
                            savedSessions={savedSessions}
                        />
                    )
                    : (
                        <RequestedLoadFunctions
                            manual={TheManual}
                            queryStringInstructions={queryStringInstructions}
                        />
                    )}
                <h4>Learn about...</h4>
                <div className="mainPage_links">
                    <PageLink manual={TheManual} page={proteinInfo}>
                        <img src={imageSrc('protein-ribbons.png')} alt="" />
                        <p className="a-btn">{proteinInfo.title}</p>
                    </PageLink>
                    <PageLink manual={TheManual} page={addCmpdInfo}>
                        <img src={imageSrc('ligand.png')} alt="" />
                        <p className="a-btn">{addCmpdInfo.title}</p>
                    </PageLink>
                    <PageLink manual={TheManual} page={dockInfo}>
                        <img src={imageSrc('surface-ligand.png')} alt="" />
                        <p className="a-btn">{dockInfo.title}</p>
                    </PageLink>
                    <PageLink manual={TheManual} page={modifyInfo}>
                        <img src={imageSrc('grow.png')} alt="" />
                        <p className="a-btn">{modifyInfo.title}</p>
                    </PageLink>
                    <PageLink manual={TheManual} page={evaluateInfo}>
                        <img src={imageSrc('protein-desolvation.png')} alt="" />
                        <p className="a-btn">{evaluateInfo.title}</p>
                    </PageLink>
                    <PageLink manual={TheManual} page={fragDataInfo}>
                        <img src={imageSrc('water-sim.png')} alt="" />
                        <p className="a-btn">{fragDataInfo.title}</p>
                    </PageLink>
                </div>
            </div>
        </div>
    );
}

function PageLink({
    page, manual, children, style,
}) {
    const TheManual = manual;
    return (
        <button type="button" onClick={() => TheManual.ShowPage(page)} style={style}>
            {children}
        </button>
    );
}

function ProteinLink({
    uri, search, data, children,
}) {
    const loader = () => {
        EventBroker.publish('escapeKey');
        if (uri) {
            loadProteinByUri(uri);
        } else if (search) {
            UserActions.OpenMapSelector('show', { searchSeed: search });
        } else {
            UserActions.RestoreProtein(data);
        }
    };

    return (
        <button type="button" className="a-btn" onClick={loader}>
            <div>
                <i className={search ? 'fa fa-search' : 'fa fa-share'} />
                &nbsp;
                {children}
            </div>
        </button>
    );
}

function StateLoadLink({ stateName, children }) {
    return (
        <button
            className="a-btn"
            type="button"
            onClick={() => { EventBroker.publish('escapeKey'); SessionManager.loadFromLocalStorage(stateName); }}
            style={{ alignItems: 'center' }}
        >
            <i className="fa fa-database" />
            BMaps&nbsp;Session:
            {' '}
            {children}
        </button>
    );
}

/**
 * @description Buttons shown during a "normal" Guide view;
 * that is, we haven't specified a structure or search via URL.
 * Note: the special view is only shown after the initial page load.
 * If the manual is requested later, then the normal view is used.
 * @param {*} object of the form: {recentProteins, savedSessions}
 * TODO: display an item with the url request in the normal view too
 */
function NormalLoadFunctions({ recentProteins, savedSessions }) {
    return (
        <>
            <h4>Quick start:</h4>
            <div className="mainPage_links_long">
                <button className="a-btn" type="button" onClick={() => { EventBroker.publish('escapeKey'); UserActions.OpenMapSelector('show'); }}>
                    <div>
                        <i className="fa fa-search" />
                        {' '}
                        View structure by PDB id
                    </div>
                </button>
                <button className="a-btn" type="button" onClick={() => { EventBroker.publish('escapeKey'); UserActions.OpenProteinImport('import_protein_tab'); }}>
                    <div>
                        <i className="fa fa-plus" />
                        {' '}
                        Upload my structure
                    </div>
                </button>
                <button className="a-btn" type="button" onClick={() => { EventBroker.publish('escapeKey'); UserActions.OpenProteinImport('protein_folding_tab'); }}>
                    <div>
                        <i className="fa fa-cog" />
                        {' '}
                        Predict target folding
                    </div>
                </button>
                <button className="a-btn" type="button" onClick={() => { EventBroker.publish('escapeKey'); openHelpMenu(); }}>
                    <div>
                        <i className="fa fa-comments" />
                        {' '}
                        Chat with Gibbs
                    </div>
                </button>
            </div>
            {(recentProteins.length > 0 || savedSessions.length > 0) && (
                // TODO: reorganize the layout so the <br> doesn't have to be mentioned here
                <>
                    <br />
                    <h4>Recent structures:</h4>
                </>
            )}
            <div className="mainPage_links">
                {recentProteins
                    .slice(0, 2)
                    .map((protein) => (
                        <ProteinLink
                            // If user data we need to do a state restore; if not, can load uri
                            uri={protein.UserData ? null : protein.CaseUri}
                            data={protein}
                            key={protein.CaseUri}
                        >
                            {protein.DisplayName}
                        </ProteinLink>
                    ))}
                {savedSessions.map(
                    (name) => <StateLoadLink stateName={name} key={name}>{name}</StateLoadLink>
                )}
            </div>
            <div>
                <label htmlFor="rememberProteinCheckbox">
                    <input
                        id="rememberProteinCheckbox"
                        type="checkbox"
                        style={{ marginRight: '10px', verticalAlign: 'middle' }}
                        defaultChecked={SessionManager.rememberRecentProteins()}
                        onChange={
                            (evt) => SessionManager.setRememberRecentProteins(evt.target.checked)
                        }
                    />
                    <i>Remember recently loaded proteins</i>
                </label>
            </div>
            <br />
            <h4>Featured structures:</h4>
            <div className="mainPage_links">
                <ProteinLink uri="sirt2/4RMG_pr_op_min_frags">4RMG: Sirt2</ProteinLink>
                <ProteinLink uri="factorIXa/4YZU_frags">4YZU: FactorIXa</ProteinLink>
                <ProteinLink uri="nCov_Spike/6M0J_pr_op_min_frags">6M0J: SARS-CoV-2 Spike</ProteinLink>
                <ProteinLink uri="nCov_Protease/5R82_pr_op_min_frags">5R82: SARS-CoV-2 Protease</ProteinLink>
                { /* <ProteinLink uri="IL1R1/1G0Y_pr_op_min_frags">1G0Y: IL1R1</ProteinLink>
                <ProteinLink uri="PCSK9/3gcw_pr_op_min_nosolv_frags">3GCW: PCSK9</ProteinLink> */}
                <ProteinLink search="COVID-19">COVID-19 Structures</ProteinLink>
            </div>
            <br />
        </>
    );
}

/**
 * @description Use a reduced set of load options on the Welcome page when
 * we have requested special structure or search term.
 * @param {*} object with {manual, queryStringInstructions}
 */
function RequestedLoadFunctions({ manual, queryStringInstructions }) {
    const map = queryStringInstructions.map;
    const searchTerm = queryStringInstructions.search;

    return (
        <div>
            <div className="section">
                Boltzmann Maps helps you develop and improve chemistry designs for drug targets,
                <br />
                using computational modeling features such as energy calculation, docking, and
                fragment maps.
            </div>
            <div className="section">
                <div className="mainPage_links_long" style={{ justifyContent: 'center' }}>
                    { map
                        ? (
                            <ProteinLink uri={map.uri}>
                                <br />
                                Proceed to
                                {' '}
                                {map.pdbID}
                                {' '}
                                structure
                            </ProteinLink>
                        )
                        : (
                            <ProteinLink search={searchTerm}>
                                <br />
                                Proceed to
                                {' '}
                                {searchTerm}
                                {' '}
                                structures
                            </ProteinLink>
                        )}
                    <PageLink manual={manual} page={manual.Pages.Main} style={{ display: 'inline' }}>
                        <button type="button" className="a-btn">
                            <i className="fa fa-search" />
                            <br />
                            View other structures
                        </button>
                    </PageLink>
                </div>
            </div>
        </div>
    );
}

/**
 * @description process arguments for the manual page,
 * specifically extracting query string parameters.
 * Returns an object with special instructions or null
 * to load normally without special handling.
 * @param {*} an object with queryStringParams, or null
 * @returns an object with special instructions or null
 * to load normally without special handling.
 */
function processArgs(args) {
    const params = args && args.queryStringParams;
    if (params && params.query) {
        if (params.maps.length === 1) {
            return {
                map: params.maps[0],
                search: params.query,
            };
        } else {
            return {
                search: params.query,
            };
        }
    }

    return null;
}
