// visualizations/docking_box.js
/**
 * @fileoverview Display a docking box in the canvas.
 *
 * This can support displaying multiple docking boxes, differentiated by the requesterId.
 * Multiple docking boxes can happen if the user presses the D key in the canvas (showing default
 * docking box) while there's a docking center chosen in the docking screen.
 *
 * Public interface:
 *     showDockingBox(requesterId, show, coordinates)
 *
 * Internal storage:
 *     drawnLines: { [requesterId]: array of canvas lines for the requester }
 *
 * This function doesn't do any calculation of the docking box (determined in data_tools),
 * it just displays a box according to the provided coordinates.
 */

import { App } from 'BMapsSrc/BMapsApp';
import { EventBroker } from 'BMapsSrc/eventbroker';
import { drawCylinder, removeCylinder } from 'BMapsSrc/MainCanvas';
import { showAlert } from 'BMapsSrc/utils';

/**
 * @type {Object<requesterId: string, canvaslines: Array>}
 */
const drawnLines = {};

/**
 * Show or hide a box in the canvas for the specified label and bounding box.
 * @param {string} requesterId Component requesting the docking box: default (D key) | dockingpane
 * @param {boolean} show Whether to show or hide the box for the requester
 * @param {[
 *     xmin: number, xmax: number, ymin: number, ymax: number, zmin: number, zmax: number
 * ]} coords
 */
export function showDockingBox(requesterId, show, coords) {
    if (show) {
        // boundingBox = xmin xmax ymin ymax zmin zmax
        const isProteinLoaded = App.Workspace.isProteinLoaded();
        if (coords && coords.length >= 6 && isProteinLoaded) {
            clearLines(requesterId); // clear first
            const [xmin, xmax, ymin, ymax, zmin, zmax] = coords;
            const drawLine = function drawDockingLine(startx, starty, startz, endx, endy, endz) {
                drawnLines[requesterId].push(
                    drawCylinder(
                        { x: startx, y: starty, z: startz },
                        { x: endx, y: endy, z: endz },
                        0.085, 'lime',
                    ),
                );
            };

            // draw two faces and connect them
            // zmin face
            drawLine(xmin, ymin, zmin, xmax, ymin, zmin);
            drawLine(xmax, ymin, zmin, xmax, ymax, zmin);
            drawLine(xmax, ymax, zmin, xmin, ymax, zmin);
            drawLine(xmin, ymax, zmin, xmin, ymin, zmin);

            // zmax face
            drawLine(xmin, ymin, zmax, xmax, ymin, zmax);
            drawLine(xmax, ymin, zmax, xmax, ymax, zmax);
            drawLine(xmax, ymax, zmax, xmin, ymax, zmax);
            drawLine(xmin, ymax, zmax, xmin, ymin, zmax);

            // connect z faces
            drawLine(xmin, ymin, zmin, xmin, ymin, zmax);
            drawLine(xmax, ymin, zmin, xmax, ymin, zmax);
            drawLine(xmin, ymax, zmin, xmin, ymax, zmax);
            drawLine(xmax, ymax, zmin, xmax, ymax, zmax);
        } else {
            let message = '';
            if (!isProteinLoaded) message += 'No protein is loaded. Please choose a protein in order to dock or to view the docking box.';
            else if (!App.Workspace.getActiveCompound()) message += 'No compound available for docking. Please import a compound in order to dock or to view the docking box.';
            else {
                message += 'This protein has no crystal ligands or hotspots to use for a docking reference.\n'
                + 'Please select an atom or residue to be the center of the bounding box before docking.';
            }
            showAlert(message, 'Display Docking Bounding Box');
        }
    } else {
        clearLines(requesterId);
    }
    EventBroker.publish('redisplayRequest');
}

function clearLines(requester) {
    if (drawnLines[requester]) {
        drawnLines[requester].forEach((line) => removeCylinder(line));
    }
    drawnLines[requester] = [];
}
