// Material UI
import MuiTextField from '@mui/material/TextField';
// Local
import { withFormik, withTooltip } from '../helpers';

export default withFormik(MuiTextField, { hasErrorState: true });
export const TextFieldWithInfoTooltip = withTooltip(MuiTextField);
export const TextFieldWithInfoTooltipAndFormik = withFormik(
    withTooltip(MuiTextField),
    { hasErrorState: true }
);
