import Link from '@mui/material/Link';
import styled from 'styled-components';

/**
 * Component to display a button styled like a link.
 * props: { onClick, children, style }
 *
 * This can be styled with either a styled prop or by wrapping with `styled`
 */
const LinkLikeButton = styled(
    ({
        children, ...props
    }) => (
        <Link /* eslint-disable-line jsx-a11y/anchor-is-valid */
            component="button"
            type="button"
            underline="none"
            {...props}
        >
            {children}
        </Link>
    )
)`
color: blue;
padding: 0.5em;

&:hover {
    opacity: 0.8;
}
`;

export default LinkLikeButton;
