/**
 * ProteinContextProvider Component
 * This React component creates and provides the protein context for various forms.
 * This component must wrap the form containing ProteinSelect, a parent of ProteinSelect, or the
 * ProteinSelect component itself.
 *
 * In conjunction with ProteinSelect, the selected protein can be changed. If the intertwined form
 * is also subscribed to ProteinContext, it will be updated on any change.
*/

import React, { useState } from 'react';
import { App } from '../../BMapsApp';

export const ProteinContext = React.createContext({
    selectedProtein: null,
    selectedCaseData: null,
    loadedProteins: [],
    handleContextChange: () => {},
});

export default function ProteinContextProvider({
    initialProtein: initialProteinIn, defaultToFirstProtein, children,
}) {
    const loadedProteins = App.Workspace.getLoadedProteins();
    let selectedProtein = null;
    let selectedCaseData = null;
    let initialProtein;
    if (initialProteinIn) {
        initialProtein = loadedProteins.find((p) => p === initialProteinIn);
        if (!initialProtein) {
            console.error(`ProteinContextProvider: attempted to initialize with unknown protein ${initialProteinIn}`);
        }
    } else if (defaultToFirstProtein) {
        initialProtein = loadedProteins[0];
    }
    if (loadedProteins.length === 0) {
        selectedCaseData = App.Workspace.firstCaseData(); // No protein case
    } else if (initialProtein || loadedProteins.length === 1) {
        selectedProtein = initialProtein || loadedProteins[0];
        selectedCaseData = App.Workspace.lookupCaseData(selectedProtein);
    }

    const [contextValue, setContextValue] = useState({
        selectedProtein,
        selectedCaseData,
        loadedProteins,
        handleContextChange,
        lookupCaseData,
    });

    /** @param {MapCase} protein */
    function lookupCaseData(protein) {
        return App.Workspace.lookupCaseData(protein);
    }

    function handleContextChange(p) {
        const caseData = App.Workspace.lookupCaseData(p);
        setContextValue({
            ...contextValue,
            selectedProtein: p,
            selectedCaseData: caseData,
        });
    }

    return (
        <ProteinContext.Provider value={contextValue}>
            {children}
        </ProteinContext.Provider>
    );
}
