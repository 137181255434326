import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import html2canvas from 'html2canvas';

// Material UI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

import { UserActions } from 'BMapsCmds';
import { useExportDataFetch } from '../exportUtils';
import { fetchLID } from '../../../ProLIFApi';
import { App } from '../../../../BMapsApp';
import { WorkingIndicator } from '../../../ui_utils';
import { ProLIFInfoIcon } from '../../../info_display/DisplayLID';
import { ErrorMessage, Notice } from '../Components';

const ToggleButton = styled(Button).attrs((props) => ({
    variant: props.selected ? 'contained' : 'outlined',
}))`
    border: solid ${({ theme }) => theme.palette.primary.main } 1px;
    background-color: ${({ selected, theme }) => (selected ? theme.palette.primary.main : theme.palette.primary.contrastText)};
    color: ${({ selected, theme }) => (selected ? theme.palette.primary.contrastText : theme.palette.primary.main) };

    &:hover {
        background-color: ${({ selected, theme }) => (selected ? theme.palette.primary.main : theme.palette.primary.contrastText)};
    }
`;

const config = {
    formats: ['sdf'],
    allowMultiple: false,
    allowStaticMode: false,
    disallowAtoms: true,
};

export default function ExportProLIFTab({ selected }) {
    const { errors, context } = useExportDataFetch(selected, config);
    const { exportTarget } = context;

    const [LID, setLID] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const iframeRef = useRef(null);
    const containerRef = useRef(null);

    const noPdbMsg = 'Load a protein to see a ligand interaction diagram.';

    const ready = selected && errors.length === 0;

    async function fetchDiagramData() {
        setLoading(true);
        setLID('');
        setErrorMessage('');

        const [compound] = exportTarget.compounds;
        const { caseData } = App.getDataParents(compound);
        const {
            data: pdbData, error: pdbError,
        } = await UserActions.ExportQueryToFormat('protein', 'pdb', caseData);
        const sdfData = compound.getSDF({ propInclusion: 'none' });
        const noPdb = (!pdbError && !pdbData) || pdbData?.indexOf('The export-selection command found nothing selected.') > 0;
        const haveError = pdbError || pdbData?.startsWith('Caught error processing text command request export-selection');

        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;

        const params = {
            width: `${Math.round(0.75 * containerWidth)}px`,
            height: `${Math.round(0.75 * containerHeight)}px`,
        };

        if (noPdb) {
            setErrorMessage(noPdbMsg);
        } else if (haveError) {
            setErrorMessage('This system failed to be prepared for a ligand interaction diagram.');
            console.warn(`ProLIF LID PDB data error: ${pdbError || pdbData}`);
        } else {
            try {
                const result = await fetchLID(pdbData, sdfData, params);
                if (result.html) {
                    setLID(result.html);
                } else {
                    console.warn(`Error from LID service: ${result.error}`);
                    setErrorMessage('The Ligand Interaction Diagram service failed to prepare the diagram for this structure.');
                }
            } catch (errorObj) {
                console.warn(`Error communicating with LID service: ${errorObj.message}`);
                setErrorMessage('There was a problem communicating with the Ligand Interaction Diagram service.');
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (ready) {
            fetchDiagramData();
        }
    }, [ready]);

    const handleDownload = () => {
        const iframe = iframeRef.current;
        const iframeDocument = iframe.contentDocument;
        const diagramElement = iframeDocument.documentElement;
        const networkElement = iframeDocument.getElementById('mynetwork');
        const canvasElement = networkElement.querySelector('canvas');

        html2canvas(diagramElement).then((canvas) => {
            const imgLink = canvas.toDataURL('image/png');
            const anchor = document.createElement('a');
            anchor.setAttribute('href', imgLink);
            anchor.setAttribute('download', 'lid.png');
            anchor.click();
            anchor.remove();
        });
    };

    const handleRefresh = () => {
        fetchDiagramData();
    };

    // This info button style is borrowed from the MUI style for the <IconButton>
    // TODO: figure out if there is a class that we could apply to the info button
    // instead of hardcoding the values here. Especially the color should not be hardcoded.
    const infoButtonStyle = {
        padding: '12px',
        display: 'inline-flex',
        color: 'rgba(0, 0, 0, 0.54)',
    };

    const exportError = errors.length > 0 ? errors[0] : '';
    const lidError = errorMessage;
    const errorToShow = lidError || exportError;
    const showErrorRefresh = lidError && lidError !== noPdbMsg;
    return (
        <div
            style={{
                flex: '1 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
            ref={containerRef}
        >
            {!!LID && (
                <Grid container direction="column" alignItems="center" spacing={3} height="100%">
                    <Grid container item wrap="nowrap" justifyContent="center" alignItems="center">
                        <ToggleButton>
                            {LID ? 'ProLIF Ligand Interaction Diagram' : '3D Workspace Image' }
                        </ToggleButton>
                        <span style={infoButtonStyle}>
                            <ProLIFInfoIcon />
                        </span>
                        <IconButton
                            type="button"
                            onClick={handleDownload}
                            size="large"
                            title="Download image"
                        >
                            <GetAppIcon />
                        </IconButton>
                        <IconButton
                            type="button"
                            onClick={handleRefresh}
                            size="large"
                            title="Reload diagram"
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Grid>
                    <ProlifDiagram html={LID} frame={iframeRef} />
                </Grid>
            )}
            {!!errorToShow && (
                <Notice title="Problem with ProLIF Interaction Diagram">
                    <ErrorMessage>
                        {errorToShow}
                        {' '}
                        {!!showErrorRefresh && (
                            <IconButton
                                type="button"
                                onClick={handleRefresh}
                                size="large"
                                title="Try diagram again"
                                style={{ color: 'black' }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        )}
                    </ErrorMessage>
                </Notice>
            )}
            {(!errorToShow && loading) && (
                <Notice>
                    <span style={{ fontStyle: 'italic' }}>
                        Loading ligand interaction diagram...
                        {' '}
                        <WorkingIndicator />
                    </span>
                </Notice>
            )}
        </div>
    );
}

function ProlifDiagram({ html, frame }) {
    return (
        <div style={{
            flex: '1 0 auto', display: 'flex', flexDirection: 'column', width: '100%',
        }}
        >
            <iframe
                title="Ligand Interaction Diagram"
                ref={frame}
                srcDoc={html}
                width="100%"
                style={{
                    display: 'flex',
                    flex: '1 1 auto',
                    border: '0px',
                }}
            />
        </div>
    );
}
