/* project_data.js
 * Protein and compound data loaded into BMaps
 *
 * A note about atom IDs:
 * 3dmol assigns two different atom ids: index and serial ( see 3dmol GLModel.addAtoms() )
 *   index is the index into all the atoms loaded into 3dmol.
 *      It is updated every time atoms are loaded or removed.
 *   serial is conceptualized as the index into the atoms with which it was loaded.
 *      For example, in a single parsed file.
 *      If we provide a value, it will use that
 *      If we don't, it will base it on the atom's position in list passed into addAtoms.
 * In addition: we track our own id when we decode from bfd
 *   uniqueID

 *
 */
import { UserActions } from 'BMapsCmds';
import {
    showAlert, MoleculeLoadOptions, userConfirmation,
    isPreviewModeError,
    MolDataSource,
}
    from 'BMapsSrc/utils';
import { EventBroker } from './eventbroker';
import { EnergyInfo } from './model/energyinfo';
import { App } from './BMapsApp';
import {
    PdbImportCase, AlphaFoldImportCase, UserDataImportCase, FoldingDataImportCase,
} from './model/MapCase';
import { LoadingScreen } from './LoadingScreen';
import { needs3D } from './util/mol_format_utils';
import { SessionErrorType } from './server/session_utils';

function TheWorkspace() {
    return App.Workspace;
}

EventBroker.registerModule('project_data.js', {
    loadCompound,
    getCompoundBySpec,
    getSelectedAtoms,
    getVisibleCompounds,
    getActiveCompound,
    getLigands,
});

/* In order to separate UI from project data, the following functions
 * were changed from being exported functions to published events.
 * This sort of resolves the problem, but it should be thought through
 * some more
 */
function setAtomSelected(atom, selected) {
    EventBroker.publish('setAtomSelected', { atom, selected });
}

/* End of events converted from exported functions */

// Workspace passthroughs to preserve interface
export function isSelectedAtom(atom) {
    return TheWorkspace().isSelectedAtom(atom);
}

export function isCompoundAtom(atom) {
    return TheWorkspace().isCompoundAtom(atom);
}

export function isProteinAtom(atom) {
    return TheWorkspace().isProteinAtom(atom);
}

export function isActiveCompoundAtom(atom) {
    return TheWorkspace().isActiveCompoundAtom(atom);
}

export function isVisibleCompoundAtom(atom) {
    return TheWorkspace().isVisibleCompoundAtom(atom);
}

export function getSelectedAtoms() {
    return TheWorkspace().getSelectedAtoms();
}

export function getActiveCompound() {
    return TheWorkspace().getActiveCompound();
}

export function getVisibleCompounds() {
    return TheWorkspace().getVisibleCompounds();
}

export function getActiveCompoundAtoms() {
    return TheWorkspace().getActiveCompoundAtoms();
}

export function getVisibleCompoundAtoms() {
    return TheWorkspace().getVisibleCompoundAtoms();
}

export function getLigands() {
    return TheWorkspace().getLigands();
}

export function getAllCompoundAtoms() {
    return TheWorkspace().getAllCompoundAtoms();
}

function addMoleculeEntry(entry) {
    TheWorkspace().addMoleculeEntry(entry);
}

/** getPermissionToLoadProtein()
 * User selects a protein from the map selector, which we must request from bfd-server.
 * We need to zap if there is already a protein loaded.
 *
 * Prompt if the user has anything other than what is available in the Structure picker:
 * - any added or modified compounds
 * - any targets that are uploaded, folded, or pdb/alphafold imports
 * - multiple proteins loaded (selectivity)
 */
async function getPermissionToLoadProtein() {
    const loadedProteins = App.Workspace.getLoadedProteins();
    const loadedCompounds = App.Workspace.getLoadedCompounds();
    const nonLigands = loadedCompounds.filter((c) => !c.isLigand());
    const userProteinTypes = [
        UserDataImportCase, FoldingDataImportCase, PdbImportCase, AlphaFoldImportCase,
    ];
    const userProteins = loadedProteins.filter((p) => userProteinTypes.some((t) => p instanceof t));

    const prompt = nonLigands.length > 0 || userProteins.length > 0 || loadedProteins.length > 1;
    if (prompt) {
        return userConfirmation(`Changing proteins will clear the workspace, including all added or modified compounds.

        Do you want to clear everything and load a new protein?`, 'Load new protein?');
    } else {
        return true;
    }
}

async function loadProtein({
    loadMsg, displayName, uri, mapCase, loadOptions={},
    dataConnection=App.PrimaryDataConnection,
}) {
    const permission = loadOptions.keepExisting || await getPermissionToLoadProtein();
    if (!permission) return null;

    if (mapCase || uri) {
        return LoadingScreen.withLoadingScreen(
            UserActions.ChooseProtein(mapCase || uri, loadOptions, dataConnection),
            loadMsg
        );
    } else {
        showAlert(`Failed to load ${displayName}: Nothing to load`, 'Load Protein');
    }

    return null;
}

export function loadProteinByUri(uri, loadOptions, dataConnection=App.PrimaryDataConnection) {
    const { displayName, loadMsg } = msgInfoForMapCase(uri, dataConnection);
    return loadProtein({
        uri, displayName, loadMsg, loadOptions, dataConnection,
    });
}

export function loadProteinByMapCase(
    mapCase, loadOptions, dataConnection=App.PrimaryDataConnection
) {
    const { displayName, loadMsg } = msgInfoForMapCase(mapCase, dataConnection);
    return loadProtein({
        mapCase, displayName, loadMsg, loadOptions, dataConnection,
    });
}

function msgInfoForMapCase(input, dataConnection) {
    let mapCase = input;
    if (typeof input === 'string') {
        switch (true) {
            case PdbImportCase.isUri(input): {
                const { id } = PdbImportCase.parseUri(input);
                mapCase = new PdbImportCase(id);
                break;
            }
            case AlphaFoldImportCase.isUri(input): {
                const { id } = AlphaFoldImportCase.parseUri(input);
                mapCase = new AlphaFoldImportCase(id);
                break;
            }
            default:
                mapCase = dataConnection.getCaseDataCollection().findMapByUri(input);
        }
    }

    return {
        displayName: mapCase.displayName,
        loadMsg: mapCase.loadingMessage,
    };
}

//---- Consider moving some of this logic into UserActions.LoadMolecule
export async function loadCompound(molSource, loadOptions=MoleculeLoadOptions.Align,
    caseData=App.Workspace.firstCaseData()) {
    const addedMoleculeFiles = App.Workspace.addedMoleculeFiles;

    const molEntry = molSource.label();
    console.log(`Sending ${molEntry}`);
    const notDuplicate = (molSource.sourceId == null || !addedMoleculeFiles.includes(molEntry));
    const okToAdd = notDuplicate
        || await userConfirmation(`You already loaded ${molSource.sourceId}.\n\nDo you want to add it again?`,
            'Load (possible) duplicate data?');

    // Check if we need to add gen3d for 2D-only mol or sdf data
    if (needs3D(molSource)) {
        loadOptions.addOptions({ gen3d: true });
        console.log('Overriding gen3d to true in loadCompound');
    }

    if (okToAdd) {
        const { compounds } = await UserActions.LoadMolData(molSource, loadOptions, caseData);
        if (compounds.length > 0) {
            if (notDuplicate) {
                addMoleculeEntry(molEntry);
            }

            // Group compounds from files
            if (compounds.length > 1) {
                if (molSource.sourceType === MolDataSource.Types.File) {
                    const groupName = `From ${molSource.sourceId}`;
                    const treeItems = App.Workspace.getTreeItemsForItems('Compounds', compounds);
                    UserActions.GroupItems(treeItems, 'Compounds', undefined, { groupName });
                }
            }
        }
    }
}

function reportModificationError(cmd, args, messageIn) {
    let message = messageIn.replace('..', '.');
    if (message.indexOf('does not have a formal charge yet') > -1) {
        message = 'Please calculate energies before attempting to modify this compound.';
    }
    showAlert(message, 'Modify Compound');
}

export function doReceiveWarning(cmd, args, messageIn) {
    if (isPreviewModeError(messageIn)) return;

    const message = messageIn.trim(); // remove extra spaces and CRLF.
    switch (cmd) {
        case 'get-case-files':
            showAlert(message, 'Get Protein Case Files');
            break;
        case 'load-molecule': case 'binary-command-14': case 'load-mol-data':
            showAlert(message, 'Compound Import');
            break;
        default:
            console.warn(`Server reported warning: [${cmd} ${args}]: ${message}`);
            break;
    }
}

export function doReceiveStatus(cmd, args, messageIn) {
    const message = messageIn.trim(); // remove extra spaces and CRLF.
    console.log(`Server reported status: [${cmd} ${args}]: ${message}`);
}

export function doReceiveError(cmd, args, messageIn) {
    if (isPreviewModeError(messageIn)) return;

    const message = messageIn.trim(); // remove extra spaces and CRLF.
    switch (cmd) {
        case 'list-maps':
            reportListMapsError(cmd, args, message);
            break;
        case 'get-solvation-for-ligand':
            reportEnergyError(cmd, args, message);
            break;
        case 'get-energies-for-ligand':
            reportEnergyError(cmd, args, message);
            break;
        case 'select':
            reportSelectError(cmd, args, message);
            break;
        case 'select-modification':
        case 'replace-group':
            reportModificationError(cmd, args, message);
            break;
        case 'load-molecule': case 'binary-command-14': case 'load-mol-data':
            reportLoadMoleculeError(cmd, args, message);
            break;
        case 'dock-compound':
        case 'export-selection':
        case 'get-forcefield-params':
        case 'energy-minimize':
            // Handled elsewhere, but included in the switch to suppress the default warning
            break;
        case 'Idle':
            setTimeout(() => EventBroker.publish('sessionError', {
                errTitle: 'Max Idle Time Exceeded',
                errMsg: 'This session was inactive for more than 12 hours.',
                errType: SessionErrorType.Lost_InactivityTimeout,
            }), 5000);
            break;
        default:
            console.warn(`Server reported error: [${cmd} ${args}]: ${message}`);
    }
}

function reportListMapsError(/* cmd, args, message */) {
    const msg = 'An error occurred while fetching Boltzmann Maps data.  Some data may not be available.  Please <a href="mailto:support@coniferpoint.com">contact us</a> if this problem persists.';
    showAlert(msg, 'Load BMaps Data');
}

function reportLoadMoleculeError(/* cmd, args, message */) {
    showAlert('Failed to load molecule.  (See JS console for details)', 'Load Molecule');
}

export function doReceiveSelectResults(data) {
    // A string from the selection command for reporting errors
    console.log(`Selection results: ${data}`);
    // Would be good to display number of selected atoms under select button?
}

export function doReceiveSelection(data, decoder) {
    for (const a of getSelectedAtoms()) {
        setAtomSelected(a, false);
    }
    TheWorkspace().clearSelection();
    decoder.decodeSelection(data, setAtomSelectedMiddleman);
}

export function setAtomSelectedMiddleman(atom, selected) {
    const selectedBefore = !!isSelectedAtom(atom);
    const selectedNow = !!selected;

    if (selectedBefore !== selectedNow) {
        setAtomSelected(atom, selected);
    }

    if (selected) {
        TheWorkspace().addSelectedAtom(atom);
    } else {
        TheWorkspace().removeSelectedAtom(atom);
    }
}

function getCompoundBySpec(ligandSpec) {
    return TheWorkspace().getCompoundBySpec(ligandSpec);
}

// These server commands (get-solvation | get-energies) are not being used currently
// Will also receive msg parameter
function reportEnergyError(cmd, args/* , msg */) {
    const ligandSpec = args;
    const compound = getCompoundBySpec(ligandSpec);
    if (compound) {
        if (cmd === 'get-solvation-for-ligand') {
            compound.getEnergyInfo().energyError(EnergyInfo.Types.ddGs, 'Solvation request failed at server.');
        } else if (cmd === 'get-energies-for-ligand') {
            compound.getEnergyInfo().energyError(EnergyInfo.Types.stress, 'Stress request failed at server.');
            compound.getEnergyInfo().energyError(EnergyInfo.Types.vdW, 'vdW request failed at server.');
            compound.getEnergyInfo().energyError(EnergyInfo.Types.electrostatics, 'Electrostatics request failed at server.');
        }
    }
}

function reportSelectError(cmd, args, message) {
    showAlert(message, `Select ${args}`);
}
