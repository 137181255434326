/**
 * @fileoverview Exports an AmberData singleton containing Amber data from amber .dat files
 * specifically radius and epsilon data.
 */

// Import is used for checking if we have radius data for defined amber names
import {
    residueAmberNamesMap,
    ionAmberNamesMap,
    knownLigandAmberNamesMap,
    amberToElementMap,
} from '../atomnames';

/**
 * Contains amber data parsed from amber .dat files, specifically radius and epsilon.
 * Also provides some helper methods that make use of the data.
 * @method getRadiusData Return {radius, epsilon} for the provided amber name
 * @method includeForRadius Return Boolean for whether or not the element should be included
 * in a radius calculation.
 */
class AmberInfo {
    constructor(amberData) {
        const { radiusData, aliases } = amberData;
        // Add entries for all the aliases so we don't need to look it up
        Object.keys(aliases).forEach((alias) => {
            const targetAmberName = aliases[alias];
            const radius = radiusData[targetAmberName];
            radiusData[alias] = radius;
        });
        this.radiusData = radiusData;
        this.aliases = aliases;

        this.vdWFactor = 0.6;
        this.ignoredAmber = ['LP', 'EP', 'Zc', 'Gx', 'Gy', 'Gz', 'IB'];

        // Uncomment this if you want to check Amber aliases on startup
        // this.checkAmberData(aliases);
    }

    /**
     *
     * @param {String} amberName The amber name for which to look up radius data
     * @returns {?{radius: number, epsilon: number}} The radius data for the amber name
     * - radius The vdW radius
     * - epsilon
     */
    getRadiusData(amberName) {
        return this.radiusData[amberName];
    }

    /**
     *
     * @param {String} element
     * @param {boolean} keepHydrogen whether to include Hs in the radius calculation
     * @returns {Boolean} whether or not to include this element in a radius calculation
     */
    includeForRadius(amber, element, keepHydrogen=false) {
        return !this.ignoredAmber.includes(amber)
            && (keepHydrogen || element !== 'H');
    }

    get GaffAromaticTypes() {
        return [
            'ca', 'cc', 'cd', 'cp', 'cq',
            'nb', 'nc', 'cd',
            'pb', 'pc', 'pd',
            'os', 'ss',
        ];
    }

    // Internal only
    checkAmberData(aliases) {
        // Check residue maps
        const nameSet = new Set();
        const amberSet = new Set();
        const checkAmberNamesMap = (map, label) => {
            map.forEach((amberNames, res) => {
                const missing = amberNames.filter((amber) => this.radiusData[amber] == null);
                missing.forEach((amber) => {
                    console.warn(`${label} missing amber radius in residue ${res}: ${amber}`);
                    nameSet.add(res);
                    amberSet.add(amber);
                });
            });
        };
        checkAmberNamesMap(residueAmberNamesMap, 'residueAmberNamesMap');
        checkAmberNamesMap(ionAmberNamesMap, 'ionAmberNamesMap');
        checkAmberNamesMap(knownLigandAmberNamesMap, 'knownLigandAmberNamesMap');

        if (amberSet.size > 0) {
            const amberNames = [...amberSet.values()].sort();
            const names = [...nameSet.values()].sort();
            console.log(`${amberSet.size} amber names missing radius data in ${nameSet.size} residues:\nAmber: ${amberNames.join(' ')}\nResidues: ${names.join(' ')}`);
        } else {
            console.log('All residue amber names have radius data');
        }

        // Check elements map
        nameSet.clear();
        amberSet.clear();
        amberToElementMap.forEach((element, amber) => {
            if (this.radiusData[amber] == null) {
                nameSet.add(element);
                amberSet.add(amber);
            }
        });
        if (amberSet.size > 0) {
            const amberNames = [...amberSet.values()].sort();
            const names = [...nameSet.values()].sort();
            console.log(`${amberSet.size} amber names missing radius data in amberToElementMap, mapping to ${nameSet.size} elements:\nAmber: ${amberNames.join(' ')}\nElements: ${names.join(' ')}`);
        } else {
            console.log('All amber elements have radius data.');
        }

        Object.keys(aliases).forEach((alias) => {
            if (this.radiusData[alias] == null) {
                console.error(`Amber alias: ${alias} -> ${aliases[alias]} missing radius`);
            }
        });
    }
}

// Amber data below is json generated from another program, so disable eslint.
/* eslint-disable */
/** Generated automatically from bmaps/tools/amber/parse-amber-dat.js */
const amberRadiusData = {
    "radiusData": {
        "H": {"radius":0.6,"epsilon":0.0157},
        "HO": {"radius":0,"epsilon":0},
        "HS": {"radius":0.6,"epsilon":0.0157},
        "HC": {"radius":1.487,"epsilon":0.0157},
        "H1": {"radius":1.387,"epsilon":0.0157},
        "H2": {"radius":1.287,"epsilon":0.0157},
        "H3": {"radius":1.187,"epsilon":0.0157},
        "HP": {"radius":1.1,"epsilon":0.0157},
        "HA": {"radius":1.459,"epsilon":0.015},
        "H4": {"radius":1.409,"epsilon":0.015},
        "H5": {"radius":1.359,"epsilon":0.015},
        "HW": {"radius":0,"epsilon":0},
        "HZ": {"radius":1.459,"epsilon":0.015},
        "NO": {"radius":1.824,"epsilon":0.17},
        "NP": {"radius":1.824,"epsilon":0.17},
        "O": {"radius":1.6612,"epsilon":0.21},
        "O2": {"radius":1.6612,"epsilon":0.21},
        "OW": {"radius":1.7683,"epsilon":0.152},
        "OH": {"radius":1.721,"epsilon":0.2104},
        "OS": {"radius":1.6837,"epsilon":0.17},
        "OP": {"radius":1.85,"epsilon":0.17},
        "C*": {"radius":1.908,"epsilon":0.086},
        "CI": {"radius":1.908,"epsilon":0.1094},
        "C5": {"radius":1.908,"epsilon":0.086},
        "C4": {"radius":1.908,"epsilon":0.086},
        "CT": {"radius":1.908,"epsilon":0.1094},
        "CX": {"radius":1.908,"epsilon":0.1094},
        "C": {"radius":1.908,"epsilon":0.086},
        "N": {"radius":1.824,"epsilon":0.17},
        "N3": {"radius":1.824,"epsilon":0.17},
        "S": {"radius":2,"epsilon":0.25},
        "SH": {"radius":2,"epsilon":0.25},
        "P": {"radius":2.1,"epsilon":0.2},
        "IM": {"radius":2.47,"epsilon":0.1},
        "Li": {"radius":1.137,"epsilon":0.0183},
        "IP": {"radius":1.868,"epsilon":0.00277},
        "Na": {"radius":1.868,"epsilon":0.00277},
        "K": {"radius":2.658,"epsilon":0.000328},
        "Rb": {"radius":2.956,"epsilon":0.00017},
        "Cs": {"radius":3.395,"epsilon":0.0000806},
        "MG": {"radius":0.7926,"epsilon":0.8947},
        "C0": {"radius":1.7131,"epsilon":0.459789},
        "Zn": {"radius":1.1,"epsilon":0.0125},
        "F": {"radius":1.75,"epsilon":0.061},
        "Cl": {"radius":1.948,"epsilon":0.265},
        "Br": {"radius":2.22,"epsilon":0.32},
        "I": {"radius":2.35,"epsilon":0.4},
        "IB": {"radius":5,"epsilon":0.1},
        "LP": {"radius":0,"epsilon":0},
        "EP": {"radius":0,"epsilon":0},
        "h1": {"radius":1.387,"epsilon":0.0157},
        "h2": {"radius":1.287,"epsilon":0.0157},
        "h3": {"radius":1.187,"epsilon":0.0157},
        "h4": {"radius":1.409,"epsilon":0.015},
        "h5": {"radius":1.359,"epsilon":0.015},
        "ha": {"radius":1.459,"epsilon":0.015},
        "hc": {"radius":1.487,"epsilon":0.0157},
        "hn": {"radius":0.6,"epsilon":0.0157},
        "ho": {"radius":0,"epsilon":0},
        "hp": {"radius":0.6,"epsilon":0.0157},
        "hs": {"radius":0.6,"epsilon":0.0157},
        "hw": {"radius":0,"epsilon":0},
        "hx": {"radius":1.1,"epsilon":0.0157},
        "o": {"radius":1.6612,"epsilon":0.21},
        "oh": {"radius":1.721,"epsilon":0.2104},
        "os": {"radius":1.6837,"epsilon":0.17},
        "op": {"radius":1.6837,"epsilon":0.17},
        "oq": {"radius":1.6837,"epsilon":0.17},
        "ow": {"radius":1.7683,"epsilon":0.152},
        "c": {"radius":1.908,"epsilon":0.086},
        "c1": {"radius":1.908,"epsilon":0.21},
        "c2": {"radius":1.908,"epsilon":0.086},
        "c3": {"radius":1.908,"epsilon":0.1094},
        "ca": {"radius":1.908,"epsilon":0.086},
        "cc": {"radius":1.908,"epsilon":0.086},
        "cd": {"radius":1.908,"epsilon":0.086},
        "ce": {"radius":1.908,"epsilon":0.086},
        "cf": {"radius":1.908,"epsilon":0.086},
        "cg": {"radius":1.908,"epsilon":0.21},
        "ch": {"radius":1.908,"epsilon":0.21},
        "cp": {"radius":1.908,"epsilon":0.086},
        "cq": {"radius":1.908,"epsilon":0.086},
        "cu": {"radius":1.908,"epsilon":0.086},
        "cv": {"radius":1.908,"epsilon":0.086},
        "cx": {"radius":1.908,"epsilon":0.086},
        "cy": {"radius":1.908,"epsilon":0.086},
        "cz": {"radius":1.908,"epsilon":0.086},
        "n": {"radius":1.824,"epsilon":0.17},
        "ni": {"radius":1.824,"epsilon":0.17},
        "nj": {"radius":1.824,"epsilon":0.17},
        "n1": {"radius":1.824,"epsilon":0.17},
        "n2": {"radius":1.824,"epsilon":0.17},
        "n3": {"radius":1.824,"epsilon":0.17},
        "np": {"radius":1.824,"epsilon":0.17},
        "nq": {"radius":1.824,"epsilon":0.17},
        "n4": {"radius":1.824,"epsilon":0.17},
        "nk": {"radius":1.824,"epsilon":0.17},
        "nl": {"radius":1.824,"epsilon":0.17},
        "na": {"radius":1.824,"epsilon":0.17},
        "nb": {"radius":1.824,"epsilon":0.17},
        "nc": {"radius":1.824,"epsilon":0.17},
        "nd": {"radius":1.824,"epsilon":0.17},
        "ne": {"radius":1.824,"epsilon":0.17},
        "nf": {"radius":1.824,"epsilon":0.17},
        "nh": {"radius":1.824,"epsilon":0.17},
        "nm": {"radius":1.824,"epsilon":0.17},
        "nn": {"radius":1.824,"epsilon":0.17},
        "no": {"radius":1.824,"epsilon":0.17},
        "s": {"radius":2,"epsilon":0.25},
        "s2": {"radius":2,"epsilon":0.25},
        "s4": {"radius":2,"epsilon":0.25},
        "s6": {"radius":2,"epsilon":0.25},
        "sx": {"radius":2,"epsilon":0.25},
        "sy": {"radius":2,"epsilon":0.25},
        "sh": {"radius":2,"epsilon":0.25},
        "ss": {"radius":2,"epsilon":0.25},
        "sp": {"radius":2,"epsilon":0.25},
        "sq": {"radius":2,"epsilon":0.25},
        "p2": {"radius":2.1,"epsilon":0.2},
        "p3": {"radius":2.1,"epsilon":0.2},
        "p4": {"radius":2.1,"epsilon":0.2},
        "p5": {"radius":2.1,"epsilon":0.2},
        "pb": {"radius":2.1,"epsilon":0.2},
        "pc": {"radius":2.1,"epsilon":0.2},
        "pd": {"radius":2.1,"epsilon":0.2},
        "pe": {"radius":2.1,"epsilon":0.2},
        "pf": {"radius":2.1,"epsilon":0.2},
        "px": {"radius":2.1,"epsilon":0.2},
        "py": {"radius":2.1,"epsilon":0.2},
        "f": {"radius":1.75,"epsilon":0.061},
        "cl": {"radius":1.948,"epsilon":0.265},
        "br": {"radius":2.02,"epsilon":0.42},
        "i": {"radius":2.15,"epsilon":0.5},
        "FE": {"radius":0.9,"epsilon":0.3},
        "oW": {"radius":1.77,"epsilon":0.155},
        "hW": {"radius":0.6,"epsilon":0.0157},
        "WC": {"radius":0.6,"epsilon":0.0157},
        "Z0": {"radius":3.1,"epsilon":0.000001},
        "Zc": {"radius":0,"epsilon":0},
        "Cd": {"radius":1.58,"epsilon":0.3},
        "Pt": {"radius":1.74,"epsilon":0.3},
        "B": {"radius":2.01,"epsilon":0.49},
        "Co": {"radius":1.52,"epsilon":0.3},
        "Yb": {"radius":2.22,"epsilon":0.3},
        "Os": {"radius":1.35,"epsilon":0.3},
        "G0": {"radius":0.67,"epsilon":3.46},
        "Gx": {"radius":0,"epsilon":0},
        "Gy": {"radius":0,"epsilon":0},
        "Gz": {"radius":0,"epsilon":0}
    },
    "aliases": {
        "NA": "N",
        "N2": "N",
        "N*": "N",
        "NC": "N",
        "NB": "N",
        "NT": "N",
        "NY": "N",
        "CA": "C*",
        "CB": "C*",
        "CC": "C*",
        "CD": "C*",
        "CK": "C*",
        "CM": "C*",
        "CN": "C*",
        "CQ": "C*",
        "CR": "C*",
        "CV": "C*",
        "CW": "C*",
        "CY": "C*",
        "CZ": "C*",
        "CP": "C*",
        "CS": "C*",
        "C1": "C*",
        "O3": "O2",
        "N1": "N",
        "Se": "S",
        "Fe": "FE",
        "Ni": "FE",
        "Mn": "FE",
        "Ru": "FE",
        "Os": "FE",
        "Mg": "MG",
        "Ba": "MG",
        "Pb": "MG",
        "Ca": "C0",
        "CU": "Zn",
        "Cu": "Zn",
        "Cr": "Zn"
    }
}
/* eslint-enable */

/** @type {AmberInfo} */
const AmberData = new AmberInfo(amberRadiusData);
export default AmberData;
