import { TextArea } from '../../../common/TextFields';
import { withFormik } from '../../../common/helpers';
import { withFileDragAndDrop } from '../FileDragAndDrop';

export default withFormik(
    // flexGrow here may be specific for use in the Import screens
    withFileDragAndDrop(TextArea, { style: { flexGrow: 1 } }),
    { hasErrorState: true }
);

export const DroppableTextAreaWithFormikNoError = withFormik(
    withFileDragAndDrop(TextArea, { style: { flexGrow: 1 } }),
    { hasErrorState: false, errorFieldRenderType: 'NEVER' }
);
