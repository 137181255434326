import { MapCase, UserDataImportCase } from './MapCase';
import AtomInfoManager from './AtomInfoManager';
import CaseData from './CaseData';
import MapListData from './MapListData';

/**
 * This class encapsulates all molecular data associated with a particular connection,
 * including the list of available maps, loaded case data (structure and compounds),
 * and the per-atom info used by the decoder.
 */
export default class CaseDataCollection {
    constructor() {
        /** @type {MapCase[]} */
        this.mapList = [];
        /** @type {AtomInfoManager} */
        this.atomInfoMaps = new AtomInfoManager();
        /** @type {Map<MapCase,CaseData} */
        this.caseDataMap = new Map();
        this.addCaseData(new CaseData()); // No protein case
        /** @type {DataConnection} */
        this.dataConnection = null;
    }

    reset() {
        this.atomInfoMaps.reset();
        this.caseDataMap = new Map();
        this.addCaseData(new CaseData()); // No protein case
    }

    // Data Connection
    getDataConnection() { return this.dataConnection; }
    setDataConnection(dataConnection) { this.dataConnection = dataConnection; }

    // Case Data Methods
    getCaseDataCount() { return this.caseDataMap.size; }

    /** Return true if the case data collection has no loaded data */
    isEmpty() {
        const count = this.getCaseDataCount();
        return count === 0 // Unexpected; we always expect there to be a "no protein" case
            || (count === 1 && [...this.caseDataMap.values()][0].isEmpty());
    }

    /** @param {CaseData} caseData */
    addCaseData(caseData) {
        this.caseDataMap.set(caseData.mapCase, caseData);
        caseData.setCaseDataCollection(this);
    }

    /** @param {MapCase} mapCase */
    getCaseDataByMapCase(mapCase) {
        return this.caseDataMap.get(mapCase);
    }

    /** @param {MapCase | string} caseArgs */
    lookupCaseData(caseArgs) {
        const mapCase = caseArgs instanceof MapCase ? caseArgs : this.findMapByUri(caseArgs);
        const caseData = this.getCaseDataByMapCase(mapCase);
        return caseData;
    }

    getNoProteinCaseData() {
        return this.caseDataMap.get(undefined);
    }

    /** @returns {CaseData[]} */
    getAllCaseData() {
        return [...this.caseDataMap.values()];
    }

    getLoadedMapCases() {
        return [...this.caseDataMap.keys()];
    }

    /** @param {CaseData} caseData */
    removeCaseData(caseData) {
        this.caseDataMap.delete(caseData.mapCase);
    }

    // Atom Methods
    /** @param {number} uid */
    findAtomByUid(uid) {
        return this.atomInfoMaps.lookupAtom(uid);
    }

    /** @param {Atom} atom */
    removeAtomFromMap(atom) {
        this.atomInfoMaps.removeAtomFromMap(atom);
    }

    // MapCase Methods
    getMapList() {
        return [...this.mapList];
    }

    /** @param {MapListData} mapData */
    loadMapData(mapData) {
        mapData.mapList.forEach((mapCase) => this.addMapCase(mapCase));
        return this.getMapList();
    }

    addMapCase(mapCase) {
        this.mapList.push(mapCase);
        mapCase.setCaseDataCollection(this);
    }

    /** @param {string} uri, possibly ending with a mount index */
    findMapByUri(uri) {
        // mountIndex is added by MapCase.fromBfdServer
        const { projectCase, mountIndex } = MapCase.splitUri(uri);
        return this.mapList.find((m) => {
            const { projectCase: mProjectCase, mountIndex: mMountIndex } = MapCase.splitUri(m.uri);
            return projectCase && projectCase === mProjectCase
                && (mountIndex == null || mountIndex === mMountIndex);
        });
    }

    findMapByCase(mapCase) {
        if (mapCase instanceof UserDataImportCase) {
            return this.mapList.find((m) => (
                m.pdbID === mapCase.pdbID
                && m.molecule_name === mapCase.molecule_name
                && m.data === mapCase.data
            ));
        } else {
            return this.findMapByUri(mapCase.uri);
        }
    }

    /** @param {string} query */
    findMapsByProjectPDBOrCase(query) {
        const queryUpper = query && query.toUpperCase();
        return this.mapList.filter((m) => {
            const guesses = [
                m.pdbID && m.pdbID.toUpperCase(),
                m.project && m.project.toUpperCase(),
                m.case && m.case.toUpperCase(),
                `${m.project}/${m.case}`.toUpperCase(),
                `${m.project}/${m.pdbID}`.toUpperCase(),
            ];
            return guesses.includes(queryUpper);
        });
    }

    // Admin Methods
    dumpState() {
        let report = `CaseDataCollection: ${this.mapList.length} available maps. Loaded case data:\n`;
        this.caseDataMap.forEach((caseData) => { report += `${caseData.dumpState()}\n`; });
        const atomMapStats = this.atomInfoMaps.atomMapStats();
        report += 'CaseDataCollection atomMapStats:\n';
        for (const [name, value] of Object.entries(atomMapStats)) {
            report += `    ${name}: ${value}`;
        }
        return report;
    }
}
