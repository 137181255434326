/**
 * @typedef {import('BMapsSrc/model').Compound} Compound
 */
import * as targetActions from '.';
import store from '../store';

export function addStructureToProjectTargets(mapCase) {
    store.dispatch(targetActions.addStructure(getTargetInfoForMapCase(mapCase)));
}

export function removeStructureByMapCase(mapCase) {
    if (mapCase) {
        store.dispatch(targetActions.removeStructure({ mapCase }));
        return;
    }
    console.log('Error: No mapCase provided to Redux');
}

export function removeAllStructures() {
    store.dispatch(targetActions.removeAllStructures());
}

// use setProteinTarget in connectedDataCmds to also rebuild the protein tree
/**
 * @param {import('BMapsSrc/model').MapCase} mapCase
 * @param {{isTarget?: boolean}} targetInfo
 */
export function setTargetInfo(mapCase, targetInfo) {
    store.dispatch(targetActions.setTargetInfo(getTargetInfoForMapCase(mapCase, targetInfo)));
}

export function getTargetInfoMap() {
    const map = new Map();
    const list = store.getState().projectState.targetList;
    list.forEach((structure) => {
        map.set(structure.mapCase, structure);
    });
    return (map);
}

function getTargetInfoForMapCase(mapCase, targetInfo={}) {
    const targetInfoToAdd = {
        isTarget: targetInfo.isTarget ?? true,
        // other props would be added here, with defaults
    };
    return {
        mapCase,
        displayName: mapCase.displayName,
        ...targetInfoToAdd,
    };
}

export function setCompoundTableState(compoundTableState) {
    store.dispatch(targetActions.setCompoundTableState(compoundTableState));
}

export function setScoringTableState(scoringTableState) {
    store.dispatch(targetActions.setScoringTableState(scoringTableState));
}

export function setSidePanelWidth(sidePanelWidth) {
    store.dispatch(targetActions.setSidePanelWidth(sidePanelWidth));
}
