import { UserActions } from 'BMapsCmds';
import { BackgroundColors } from '../themes';
import { useColorSchemeInfo } from '../redux/prefs/access';
import BMapsTooltip from './BMapsTooltip';

export default function BackgroundButtons() {
    const curColorInfo = useColorSchemeInfo();
    const tooltipMessage = 'Set background color';
    return (
        <BMapsTooltip placement="top" useColorScheme title={tooltipMessage}>
            <div id="backgroundColorButtons">
                {Object.keys(BackgroundColors).map((color) => (
                    <BackgroundButton
                        color={color}
                        curColor={curColorInfo.name}
                        key={color}
                    />
                ))}
            </div>
        </BMapsTooltip>
    );
}

function BackgroundButton({ color, curColor }) {
    function handleClick() {
        UserActions.SetColorScheme(color);
    }

    return (
        <button
            name={color}
            className={`backgroundColorButton ${color === curColor ? 'selected' : ''}`}
            type="button"
            style={{
                background: BackgroundColors[color].css,
            }}
            onClick={handleClick}
        >
            &nbsp;
        </button>
    );
}
