// style_manager.js

/**
 * @fileoverview A class to manage styling of atoms, compounds, and proteins.
 *
 * @typedef {import('BMapsModel').AtomGroup} AtomGroup
 * @typedef {import('BMapsSrc/themes').ColorChain} ColorChain
 * @typedef {import('BMapsModel').Atom} Atom
  * @typedef {import('BMapsSrc/Visualizer3Dmol').LabelSpec} labelSpec
 *
 * @typedef {{
 *  color?: ColorChain[keyof ColorChain],
 *  colorAllAtoms?: boolean,
 *  molStyle?: MolStyles[keyof MolStyles],
 *  showLabel?: boolean,
 *  label?: string,
 *  labelSpec?: labelSpec,
 *  ribbonColor?: ColorChain[keyof ColorChain],
 * }} AtomGroupStyle
 *
 * @typedef {ColorChain[keyof ColorChain]} color
 *
 * @typedef MolStyles
 * @property {'default'} default
 * @property {'hidden'} hidden
 * @property {'wireframe'} wireframe
 * @property {'sticks'} sticks
 * @property {'ballandstick'} ballandstick
 * @property {'spacefill'} spacefill
 * @property {'ribbons'} ribbons
 * @property {'backbone'} backbone
 * @property {'cartoon'} cartoon
 * @property {'surface'} surface
 * @property {'tribbons'} tribbons
 * @property {'ghost'} ghost
 * @property {'invisible'} invisible
 */

export const DefaultMolStyleCode = 'default';
/** @type MolStyles */
export const MolStyles = {
    default: DefaultMolStyleCode,
    hidden: 'hidden',
    wireframe: 'wireframe',
    sticks: 'sticks',
    ballandstick: 'ballandstick',
    spacefill: 'spacefill',
    ribbons: 'ribbons',
    backbone: 'backbone',
    cartoon: 'cartoon',
    surface: 'surface',
    tribbons: 'tribbons',
    ghost: 'ghost',
    invisible: 'invisible',
};

export class StyleManager {
    static get DefaultProteinViewProteinStyle() { return MolStyles.cartoon; }
    static get DefaultLigandViewProteinStyle() { return MolStyles.wireframe; }
    static get DefaultLabelStyle() {
        return {
            fontSize: 18,
            type: 'background',
        };
    }

    constructor() {
        this.resetInternal();
    }

    resetInternal() {
        /** @type {MolStyles[keyof MolStyles]} */
        this.ProteinViewProtein = StyleManager.DefaultProteinViewProteinStyle;
        /** @type {MolStyles[keyof MolStyles]} */
        this.LigandViewProtein = StyleManager.DefaultLigandViewProteinStyle;
        /** @type {{type: 'print' | 'background', fontSize: number}} */
        this.Label = StyleManager.DefaultLabelStyle;
        /** @type {Map<Atom['uniqueID'], MolStyles[keyof MolStyles]>} */
        this.AtomStyleMap = new Map();
        /** @type {Map<AtomGroup, AtomGroupStyle>} */
        this.AtomGroupStyleMap = new Map();
    }

    updateAtomGroupInternal(atomGroup, newStyle) {
        if (!newStyle || Object.values(newStyle).length === 0) {
            this.AtomGroupStyleMap.delete(atomGroup);
        } else {
            this.AtomGroupStyleMap.set(atomGroup, newStyle);
        }
    }

    static reset() {
        TheStyleManager.resetInternal();
    }

    static get ProteinViewProteinStyle() {
        return TheStyleManager.ProteinViewProtein;
    }

    static set ProteinViewProteinStyle(style) {
        TheStyleManager.ProteinViewProtein = style;
    }

    static get LigandViewProteinStyle() {
        return TheStyleManager.LigandViewProtein;
    }

    static set LigandViewProteinStyle(style) {
        TheStyleManager.LigandViewProtein = style;
    }

    static get LabelStyle() {
        return TheStyleManager.Label;
    }

    static set LabelStyle(style) {
        TheStyleManager.Label = style;
    }

    /**
     * @param {Atom[]} atoms
     * @param {MolStyles[keyof MolStyles]} style
     *
     * This function is primarily used by UserActions.SetCustomAtomsStyle() in DisplayCmds.js
     * consider using that one instead.
     */
    static setCustomAtomsStyle(atoms, style) {
        for (const atom of atoms) {
            TheStyleManager.AtomStyleMap.set(atom.uniqueID, style);
        }
    }

    /**
     * @param {Atom[]} atoms
     *
     * This function is primarily used by UserActions.RemoveCustomAtomsStyle() in DisplayCmds.js
     * consider using that one instead.
     */
    static removeCustomAtomsStyle(atoms) {
        for (const atom of atoms) {
            TheStyleManager.AtomStyleMap.delete(atom.uniqueID);
        }
    }

    /** @param {Atom} atom */
    static getCustomAtomStyle(atom) {
        return TheStyleManager.AtomStyleMap.get(atom.uniqueID);
    }

    /**
     * @param {AtomGroup} atomGroup
     * @param {AtomGroupStyle} style
     *
     * This function is primarily used by UserActions.SetCustomAtomGroupStyle() in DisplayCmds.js
     * consider using that one instead.
     */
    static setCustomAtomGroupStyle(atomGroup, style) {
        const newStyleObj = TheStyleManager.AtomGroupStyleMap.get(atomGroup) || {};

        for (const [styleType, value] of Object.entries(style)) {
            if (value === 'default') { // DefaultColorCode or DefaultMolStyleCode
                delete newStyleObj[styleType];
            } else {
                newStyleObj[styleType] = value;
            }
        }
        TheStyleManager.updateAtomGroupInternal(atomGroup, newStyleObj);
    }

    /**
     * @param {AtomGroup} atomGroup
     * @param {keyof AtomGroupStyle | 'all'} [styleType]
     * styleType is an optional argument. If you want to delete all styles just pass in atomGroup.
     *
     * This function is primarily used by UserActions.RemoveCustomAtomGroupStyle() in DisplayCmds.js
     * consider using that one instead.
     */
    static removeCustomAtomGroupStyle(atomGroup, styleType='all') {
        let newStyleObj = {}; // will be deleted from map if empty
        if (styleType !== 'all') {
            newStyleObj = TheStyleManager.AtomGroupStyleMap.get(atomGroup) || {};
            delete newStyleObj[styleType];
        }
        TheStyleManager.updateAtomGroupInternal(atomGroup, newStyleObj);
    }

    /**
     * @param {AtomGroup} atomGroup
     * @returns {AtomGroupStyle}
     */
    static getCustomAtomGroupStyle(atomGroup) {
        return TheStyleManager.AtomGroupStyleMap.get(atomGroup) || {};
    }

    /**
     * @param {AtomGroup[]} atomGroups
     * @returns {Object.<string, AtomGroupStyle>}
     */
    static getAllAtomGroupStyles(atomGroups) {
        const AtomGroupsStyle = {};
        atomGroups
            .filter((atomGroup) => !!TheStyleManager.AtomGroupStyleMap.get(atomGroup))
            .forEach((atomGroup) => {
                AtomGroupsStyle[atomGroup.key] = TheStyleManager.AtomGroupStyleMap.get(atomGroup);
            });
        return AtomGroupsStyle;
    }

    static getGlobalStyleState() {
        return {
            LigandViewProteinStyle: StyleManager.LigandViewProteinStyle,
            ProteinViewProteinStyle: StyleManager.ProteinViewProteinStyle,
            LabelStyle: StyleManager.LabelStyle,
            // Can't easily include Custom Atom Map because it is based on
            // atomUniqueIds, which cannot be guaranteed.
        };
    }

    /**
     * @param {{
     *  ProteinViewProteinStyle?: MolStyles[keyof MolStyles]
     *  LigandViewProteinStyle?: MolStyles[keyof MolStyles]
     *  LabelStyle?: {fontSize: number, type: 'background' | 'print'}
     * }} obj
     *
     * This function is primarily used by UserActions.SetGlobalStyleState() in DisplayCmds.js
     * consider using that one instead.
     */
    static setGlobalStyleState(obj) {
        const { ProteinViewProteinStyle: proteinView, LigandViewProteinStyle: ligandView } = obj;
        if (proteinView) {
            StyleManager.ProteinViewProteinStyle = proteinView !== 'default' ? proteinView
                : StyleManager.DefaultProteinViewProteinStyle;
        }

        if (ligandView) {
            StyleManager.LigandViewProteinStyle = ligandView !== 'default' ? ligandView
                : StyleManager.DefaultLigandViewProteinStyle;
        }

        if (obj.LabelStyle) {
            StyleManager.LabelStyle = obj.LabelStyle;
        }
    }
}

const TheStyleManager = new StyleManager();
