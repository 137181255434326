import { useState } from 'react';
import { UserActions } from 'BMapsCmds';
import { useWithSelectedAtoms } from '../common/helpers/useWithSelectedAtoms';
import { WorkingIndicator } from '../ui_utils';
import InspectorMessage from './InspectorMessage';
import BMapsTooltip from '../BMapsTooltip';

export function GiFEInspector() {
    const [result, setResult] = useState(null);
    const [load, setLoad] = useState(false);
    const [errorFromGiFE, setErrorFromGiFE] = useState('');
    const atoms = useWithSelectedAtoms();
    const handleCalculate = async () => {
        setLoad(true);
        const { results, error } = await UserActions.GetGifeEnergies({ atoms });
        const haveResults = results?.length > 0;
        setResult(haveResults ? results : null);
        setErrorFromGiFE(error || (!haveResults ? 'GiFE failed to produce a result' : ''));
        setLoad(false);
    };
    if (atoms.length === 0) {
        return (
            <InspectorMessage>Please select atoms to calculate GiFE.</InspectorMessage>
        );
    } else if (load === true) {
        return (
            <InspectorMessage style={{ textAlign: 'center' }}>
                <WorkingIndicator />
            </InspectorMessage>
        );
    } else {
        return (
            <>
                {!!errorFromGiFE && (
                <InspectorMessage>
                    <p>Error: </p>
                    <p>{errorFromGiFE}</p>
                </InspectorMessage>
                )}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {!!result && (
                        <table>
                            <thead>
                                <GiFEHeaderRow results={result} />
                            </thead>
                            <tbody>
                                {result.map((item) => (
                                    <GiFEDataRow item={item} key={`${item.protein}-${item.compound}`} />
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        type="button"
                        onClick={handleCalculate}
                    >
                        Calculate
                    </button>
                </div>
            </>
        );
    }
}

function GiFEHeaderRow({ results }) {
    const item = results[0];
    const isSingleItem = !(item.protein && item.compound);
    return isSingleItem ? (
        <tr>
            <th>Structure</th>
            <th>Energy</th>
        </tr>
    ) : (
        <tr>
            <th>Compound</th>
            <th>Protein</th>
            <th>Interaction</th>
        </tr>
    );
}

function GiFEDataRow({ item }) {
    const isSingleItem = !(item.protein && item.compound);
    return isSingleItem ? (
        <tr>
            <td>{item.compound || item.protein}</td>
            <td><GiFEValue item={item} /></td>
        </tr>
    ) : (
        <tr>
            <td>{item.compound}</td>
            <td>{item.protein}</td>
            <td><GiFEValue item={item} /></td>
        </tr>
    );
}

function GiFEValue({ item }) {
    if (item.error) {
        return item.error;
    }

    const valueStr = `${item.value.toFixed(2)} kcal/mol`;

    return !item.warning
        ? valueStr
        : (
            <BMapsTooltip placement="top" title={item.warning}>
                <div>{valueStr}</div>
            </BMapsTooltip>
        );
}
