import { useEffect } from 'react';
// Material UI
import MuiDialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// Local
import { EventBroker } from '../../eventbroker';

// Base on the different usage cases shown in the Material UI Dialog examples webpage.
// Depending on the variant, different default configs are set.
export const dialogVariants = {
    SIMPLE: 'dialog/SIMPLE',
    RESPONSIVE_FULLSCREEN: 'dialog/RESPONSIVE_FULLSCREEN',
};

export default function Dialog({
    closeOnEscape, onEscape, open, children, variant = dialogVariants.SIMPLE, ...rest
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let defaultProps = {};
    switch (variant) {
        case dialogVariants.RESPONSIVE_FULLSCREEN:
            defaultProps = {
                maxWidth: isMobile ? false : 'md',
                fullScreen: isMobile,
                TransitionComponent: isMobile ? Slide : Fade,
                TransitionProps: isMobile ? { direction: 'left' } : null,
            };
            break;

        default:
            defaultProps = {
                maxWidth: 'md',
            };
    }

    // NOTE: We're replacing the default Material UI escape listener with
    // the eventbroker because other components may have the need to fire
    // this event.
    useEffect(() => {
        if (open && closeOnEscape) {
            EventBroker.subscribe('escapeKey', onEscape);
        }

        return () => {
            if (open && closeOnEscape) {
                EventBroker.unsubscribe('escapeKey', onEscape);
            }
        };
    }, [open, closeOnEscape, onEscape]);

    // NOTE: MUI applies aria-hidden to background elements to prevent screen readers
    // from reading the content not in the dialog, but when it closes it puts focus back on
    // the element that opened it without removing aria-hidden first leading to the error...
    //
    // Error:
    // Blocked aria-hidden on a <button> element
    //
    // Until mui fixes the issue we could add an aria-hidden="false" to the elements, but
    // this would cause a screen reader to read the content not in the dialog.
    //
    // On 9/10/2024 we elected to keep this error as it is for the time being.
    // Look at this thread for more details: https://github.com/mui/material-ui/issues/19450
    //
    // useEffect(() => {
    //     if (open) {
    //         let root = document.querySelectorAll('[aria-hidden="true"]');
    //         root?.forEach((el) => {
    //             el.setAttribute("aria-hidden", "false");
    //         })
    //     }
    // }, [open]);

    return (
        <MuiDialog
            open={open}
            {...defaultProps}
            {...rest}
            disableEscapeKeyDown
        >
            {children}
        </MuiDialog>
    );
}

Dialog.defaultProps = {
    closeOnEscape: true,
    variant: dialogVariants.SIMPLE,
};
