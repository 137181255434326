// decode.js

// Decode server payloads (solute, snapshots, etc.)

// Todo:
//   Can we have a while-decoding construct to avoid code duplication?
//   unit test for decoders
//   Finish decodeSnapshot
//   Are atom altloc's handled?

// Extend base eslint camelcase allowances
/* eslint camelcase: ["error", { allow: [
    "^[a-zA-Z0-9]+_[A-Z0-9][a-zA-Z0-9]*$",
    "^dd?Gs?_(bound|LP?|PL?)$",
    "^decoded_",
    "^orientation_to_matrix$"
]}] */

import {
    ionAtomNamesMap, knownLigandAtomNamesMap, knownLigandChargesMap,
    amberToElementMap, waterResidueNames, bufferResidueNames,
    searchKnownAtomInfo,
} from './atomnames';
import { Atom, Bond } from './model/atoms';
import { DecodedResidue, DecodedFragment } from './model/atomgroups';
import { getFullResidueId } from './util/mol_info_utils';
import AtomInfoManager from './model/AtomInfoManager';

const ProtocolFeatures = {
    BFactor: { id: 'BFactor', minVersion: 1 },
    SecondaryStructureDetail: { id: 'SecondaryStructureDetail', minVersion: 100 }, // for extra features in .cif files NYI
    AltLocationEncoding: { id: 'AltLocationEncoding', minVersion: 2 },
    FragFloatCharges: { id: 'FragFloatCharges', minVersion: 3 },
    LongPDBIDs: { id: 'LongPDBIDs', minVersion: 4 },
};

// Numeric constants used for decoding
const INT8_MIN = -128;
const UINT16_MAX = (1<<16)-1;
// const UINT10_MAX = (1<<10)-1;

/**
 * This class performs the binary decoding.
 */
export class MsgDecoder {
    constructor(data) {
        this.dvLen = data.byteLength-2;
        this.dv = new DataView(data, 2, this.dvLen);
        this.index = 0;
        this.stringDecoder = new TextDecoder();

        const protocolView = new DataView(data, 0, 2);
        const cmdId = protocolView.getUint8(0);
        this.protocolVersion = protocolView.getUint8(1);
    }

    hasMore() { return this.index < this.dvLen; }
    getLength() { return this.dvLen; }
    getIndex() { return this.index; }
    getByte(index) { return (new Uint8Array(this.dv.buffer))[index]; }
    // The 'true' arg means little-endian.
    getUint8() { const n = this.dv.getUint8(this.index, true); this.index += 1; return n; }
    getUint16() { const n = this.dv.getUint16(this.index, true); this.index += 2; return n; }
    getUint32() { const n = this.dv.getUint32(this.index, true); this.index += 4; return n; }
    getInt8() { const n = this.dv.getInt8(this.index, true); this.index += 1; return n; }
    getInt16() { const n = this.dv.getInt16(this.index, true); this.index += 2; return n; }
    getInt32() { const n = this.dv.getInt32(this.index, true); this.index += 4; return n; }
    getFloat32() { const f = this.dv.getFloat32(this.index, true); this.index += 4; return f; }
    getRest() {
        return this.stringDecoder.decode(this.dv.buffer.slice(this.dv.byteOffset + this.index));
    }

    getProtocolVersion() { return this.protocolVersion; }
    supportsFeature({ minVersion }) {
        return this.protocolVersion >= minVersion;
    }

    getRestRaw() {
        return this.dv.buffer.slice(this.dv.byteOffset + this.index);
    }

    Do3(f) { for (let i=0; i<3; i++) f(i); }

    peek(peekCount=1) {
        const arr = [];
        for (let i = 0; i < peekCount; i++) {
            arr.push(this.dv.getUint8(this.index + i, true));
        }
        return arr;
    }

    getString(fixedLengthIn, truncate=true) {
        let fixedLength = fixedLengthIn;
        const lenDefined = fixedLength !== undefined;
        let c;
        let name = '';
        let finished = false;
        while (this.hasMore()) {
            c = this.getUint8();
            if (c === 0 && truncate) break;
            if (c === 0) finished = true;
            if (!finished) name += String.fromCharCode(c); // assume normal chars
            if (lenDefined && --fixedLength === 0) break;
        }
        return name;
    }

    getEncodedString() {
        const chars = [];
        let c;
        while (this.hasMore()) {
            c = this.getUint8();
            if (c === 0) break;
            chars.push(c);
        }
        const buf = new Uint8Array(chars);
        return this.stringDecoder.decode(buf);
    }

    getStringPadded() {
        let c;
        let name = '';
        while (this.hasMore()) {
            c = this.getUint8();
            if (c === 0) break;
            name += String.fromCharCode(c); // assume normal chars
        }
        const residual = (name.length+1) % 4;
        if (residual > 0) this.index += 4 - residual; // skip padding
        return name;
    }

    getEncodedStringPadded() {
        const chars = [];
        let c;
        while (this.hasMore()) {
            c = this.getUint8();
            if (c === 0) break;
            chars.push(c);
        }
        const residual = (chars.length+1) % 4;
        if (residual > 0) this.index += 4 - residual;
        const buf = new Uint8Array(chars);
        return this.stringDecoder.decode(buf);
    }

    decodeSeqNum(resName) {
        // resName provided for backwards compatibility with alt loc suffix (e.g. _A).
        let seqNum;
        let inscodeStr;
        let altLocStr;
        if (this.supportsFeature(ProtocolFeatures.AltLocationEncoding)) {
            seqNum = this.getInt32();
            const inscode = this.getUint8();
            const altLoc = this.getUint8();
            inscodeStr = inscode !== 0 ? String.fromCharCode(inscode) : '';
            altLocStr = altLoc !== 0 ? String.fromCharCode(altLoc) : '';
        } else {
            const seqNumField = this.getUint16();
            inscodeStr = '';
            if (seqNumField === 0xffff) {
                seqNum = this.getInt32();
                const inscode = this.getUint8();
                if (inscode !== 0) inscodeStr = String.fromCharCode(inscode);
            } else {
                seqNum = seqNumField & 0x1fff; // mask the inscode bits, 13-bit seqNum
                let inscode = (seqNumField>>13) & 0x7; // 3-bits, 7 codes plus zero (blank code)
                if (inscode === 7) { // for backwards compatibility
                    seqNum = -seqNum;
                } else if (inscode !== 0) {
                    inscode += 'A'.charCodeAt(0) - 1; // inscode 1 is A
                    inscodeStr = String.fromCharCode(inscode);
                }
            }
            altLocStr = '';
            if (resName) {
                // Deprecated <name>_A etc. for alt locs (but many .bsf files still have this).
                const matchResults = resName.match(/^\w+_([A-Z]{1})$/);
                if (matchResults) altLocStr = matchResults[1];
            }
        }
        // 3dmol uses the rescode with ^, we use it for atom list lookups.
        let rescode = seqNum.toString();
        if (inscodeStr.length > 0) rescode += (`^${inscodeStr}`); // combo
        if (altLocStr.length > 0) {
            if (inscodeStr.length === 0) rescode += '^_';
            rescode += altLocStr;
        }

        return [seqNum, inscodeStr, altLocStr, rescode];
    }

    decodeChainAndModel() {
        let chainIDraw = this.getUint8();
        let modelNumber = 0;
        if (chainIDraw === 0xff) {
            modelNumber = this.getUint8();
            chainIDraw = this.getUint8();
        }
        const chainID = chainIDraw === 0xfe
            ? this.getStringPadded()
            : String.fromCharCode(chainIDraw);
        return [chainID, modelNumber];
    }
}

let fragDataCount = 1000000;

export class Decoder {
    constructor(storage=new AtomInfoManager()) {
        this.setStorage(storage);
    }

    setStorage(storage) {
        /** @type {AtomInfoManager} */
        this.storage = storage;
    }

    // Passthrough storage functions
    resetStorage() { this.storage.reset(); }
    lookupAtom(uniqueID) { return this.storage.lookupAtom(uniqueID); }
    storeAtom(uniqueID, atom) { return this.storage.storeAtom(uniqueID, atom); }
    removeAtomFromMap(atom) { return this.storage.removeAtomFromMap(atom); }
    atomMapStats() { return this.storage.atomMapStats(); }

    get ligandAtomInfo() { return this.storage.ligandAtomInfo; }
    setLigandAtomInfo(resName, atomInfo) { this.ligandAtomInfo.setAtomInfo(resName, atomInfo); }
    getLigandAtomInfo(resName) { return this.ligandAtomInfo.getAtomInfo(resName); }

    setCustomResidueInfo(molType, resName, atomInfo) {
        this.storage.setCustomResidueAtomInfo(molType, resName, atomInfo);
    }

    getCustomResidueInfo(molType, resName) {
        return this.storage.getCustomResidueAtomInfo(molType, resName);
    }

    // Decoding functions
    decodeAndStoreAtomGroupInfo(data) {
        const d = new MsgDecoder(data);
        let ngroups = d.getUint8();
        console.log(`Atom group info ngroups ${ngroups}`);
        while (ngroups-- > 0 && d.hasMore()) {
            const resName = d.getEncodedString();
            const atomGroupTypeName = d.getEncodedString();
            const formalCharge = d.getInt8();
            let natoms = d.getUint8();
            if (natoms === 0xff) natoms = d.getUint16();
            console.log(`Atom group info for ${resName}: group type ${atomGroupTypeName}, fc ${formalCharge}, natoms ${natoms}`);
            const atomNames = [];
            const amberNames = [];
            const charges = [];
            const ringOrdinals = [];
            // Note, charges list must have the full number of entries,
            // even if some are zero, so just track that at least some charges are non-zero.
            let foundNonzeroCharge = false;
            while (natoms-- > 0 && d.hasMore()) {
                const atomName = d.getString(4);
                const amberType = d.getString(2, false);
                const charge = d.getFloat32();
                // console.log(`${resName} ${atomName} ${amberType}`);
                atomNames.push(atomName);
                amberNames.push(amberType);
                if (charge !== 0.0) foundNonzeroCharge = true;
                charges.push(charge);
                const ringOrds = [];
                let ringOrd;
                while ((ringOrd = d.getUint8()) !== 0xff) ringOrds.push(ringOrd);
                ringOrdinals.push(ringOrds);
            }
            // Assign residue to residue lists if it is a modified amino or nucleotide,
            // otherwise it is a ligand (amber-nonpolymer).
            const atomInfoEntry = {
                atomNames,
                amberNames,
                charges: foundNonzeroCharge ? charges : null,
                ringOrdinals,
                formalCharge: formalCharge !== INT8_MIN ? formalCharge : null,
            };

            if (atomGroupTypeName !== 'amber-nonpolymer' || resName.match(/^.{4}_[A-Z]{2,3}\d{1,4}_[A-Z]{1}$/)) {
                //--- these really should be per-file also, but will rarely conflict between
                // files because of the naming, e.g. 1XMS_ILE155_B (typically qm customized
                // charges).
                console.log(`Installing residue atom group info for ${resName}, found charges ${foundNonzeroCharge}`);
                this.setCustomResidueInfo(atomGroupTypeName, resName, atomInfoEntry);
            } else {
                console.log(`Installing ligand atom group info for ${resName} found charges ${foundNonzeroCharge}`);
                this.setLigandAtomInfo(resName, atomInfoEntry);
            }
        }
    }

    /**
     * Categorize the atom with id uuid as a compound or solute atom,
     * and add an entry to the appropriate list in details.
     * Adding the atom is a side-effect on the details object.
     * @param {number} uuid atom id
     * @param {number} dGs
     * @param {number} dGs_bound
     * @param {{
     *     compound: Array<Atom, Number, Number>,
     *     solute: Array<Atom, Number, Number>
     * }} details per-atom solvation info
     */
    addAtomToDetail(uuid, dGs, dGs_bound, details) {
        const atom = this.lookupAtom(uuid);
        if (!atom) {
            console.warn(`Atom serial number ${uuid} not found for ligand solvation.`);
        } else {
            // Need to store the solvation values dGs and dGs_bound.
            // For compound atoms: store in the atom object.
            // For solute (protein and ions, and eventually cofactor), store in a map in
            // the residue. The reason is that there are a different set of dGs values
            // for each ligand.
            const isSoluteAtom = (atom.hetflag === false
                                    || ionAtomNamesMap.has(atom.resname)); //--- Co-factor atoms?
            if (!isSoluteAtom) {
                details.compound.push([atom, dGs, dGs_bound]);
            } else {
                details.solute.push([atom, dGs, dGs_bound]);
            }
        }
        return details;
    }

    decodeSolvationForLigand(data) {
        let obj = data;
        // First check if text/json format
        if (typeof (data) === 'string') {
            obj = JSON.parse(data);
        }
        if (typeof (obj) === 'object' && !(obj instanceof ArrayBuffer)) {
            const {
                cmpdSpec, totals, detail, PSA,
            } = obj;
            if (detail.unsorted) {
                detail.compound = [];
                detail.solute = [];

                for (const [uuid, dGs, dGs_bound] of detail.unsorted) {
                    this.addAtomToDetail(uuid, dGs, dGs_bound, detail);
                }
            }
            // Match results of binary decoding
            return [cmpdSpec, { totals, detail, PSA }];
        }

        // Then deal with binary case
        const d = new MsgDecoder(data);
        // Like NAD.201:A or
        // <pdb code>[_<alt location code>].<seqence number>[<insertion code>]:<chainID>
        // Spec should uniquely identify the ligand.
        const ligandSpec = d.getEncodedString();
        // ddG(P|L) ddG(L|P) dG(L)
        const ddG_PL = d.getFloat32();
        const ddG_LP = d.getFloat32();
        const dG_P = d.getFloat32();
        const dG_L = d.getFloat32();
        // console.log(`ddG_PL ${ddG_PL} ddG_LP ${ddG_LP} dG_P ${dG_P} dG_L ${dG_L}`);

        const atomDetail = { compound: [], solute: [] };
        while (d.hasMore()) {
            const uuid = d.getInt32(); //--- why not uint?
            if (uuid === -1) break;
            const dGs = d.getFloat32();
            const dGs_bound = d.getFloat32();
            this.addAtomToDetail(uuid, dGs, dGs_bound, atomDetail);
        }

        const totals = [ddG_PL, ddG_LP, dG_P, dG_L];
        const PSA = [];
        let psaCount = 0;
        // ligand PSA unbound, bound, involved protein residues PSA unbound, bound.
        while (d.hasMore() && psaCount < 8) { PSA.push(d.getFloat32()); psaCount++; }
        return [ligandSpec, { totals, detail: atomDetail, PSA }];
    }

    decodeForcefieldParamsForLigand(data) {
        const d = new MsgDecoder(data);
        const ligandSpec = d.getEncodedString();
        const charges = [];
        const amberTypes = [];
        while (d.hasMore()) {
            const uuid = d.getInt32();
            if (uuid === -1) break;
            const atomName = d.getString(4);
            const amberType = d.getString(2, false);
            const charge = d.getFloat32();
            charges.push(charge);
            amberTypes.push(amberType);
            // console.log(`${atomName} amber ${amberType} chg ${charge}`);
            const atom = this.lookupAtom(uuid);
            if (atom) {
                atom.amber = amberType;
                atom.charge = charge;
            } else {
                console.warn(`Atom ${atomName} ${amberType} ${charge.toFixed(2)} serial number ${uuid} not found for forcefield parameters.`);
            }
        }

        // Update the per-file parameters for the sake of subsequent reloads of the compound,
        // say, after minimization.
        // Assume it's a ligand if it has a period in the spec, otherwise a compound.
        if (charges.length === 0 && amberTypes.length === 0) return ligandSpec;

        let resName = ligandSpec;
        const matchResults = ligandSpec.match(/(\w+)\./);
        if (matchResults) resName = matchResults[1];
        console.log(`Setting charges for ${resName}`);
        const updatedInfo = {
            charges: charges.length > 0 ? charges : null,
            amberNames: amberTypes.length > 0 ? amberTypes : null,
        };
        this.ligandAtomInfo.updateAtomInfo(resName, updatedInfo);

        return ligandSpec;
    }

    // This is called for both binary and text energies messages:
    //   ResponseIds.EnergiesForLigand
    //   ResponseIds.EnergiesForLigandText
    decodeEnergiesForLigand(data) {
        let obj = data;
        // First check if text/json format
        if (typeof (data) === 'string') {
            obj = JSON.parse(data);
        }
        if (typeof (obj) === 'object' && !(obj instanceof ArrayBuffer)) {
            return [obj.cmpdSpec, obj.internalEnergies, obj.interactionEnergies, obj.raw];
        }

        // Deal with binary case
        const d = new MsgDecoder(data);
        const ligandSpec = d.getEncodedString();

        const decodeEnergies = (nonBondedOnly) => {
            // Summary energies: bond length, bond angle, torsion, improper, vdw, coulomb.
            const summary = [];
            const nenergies = nonBondedOnly ? 2 : 6;
            for (let i=0; i<nenergies && d.hasMore(); i++) {
                const term = d.getFloat32();
                if (term === 1e6) return [];
                summary.push(term);
            }
            // console.log(`summary ${nonBondedOnly} ${summary.join(',')}`);

            const details = [];
            const decodeEnergy = (nAtoms) => {
                const thisEnergyList = [];
                while (d.hasMore()) {
                    const thisEnergy = [];
                    let uid;
                    for (let i=0; i<nAtoms && d.hasMore(); i++) {
                        uid = d.getInt32();
                        if (uid === -1) break;
                        thisEnergy.push(uid);
                    }
                    if (uid === -1 || !d.hasMore()) break;
                    thisEnergy.push(d.getFloat32()); // energy value
                    thisEnergyList.push(thisEnergy);
                }
                details.push(thisEnergyList);
            };
            if (!nonBondedOnly) {
                decodeEnergy(2); // length
                decodeEnergy(3); // angle
                decodeEnergy(4); // dihedral
                decodeEnergy(4); // improper dihedral
            }
            decodeEnergy(2); // vdw
            decodeEnergy(2); // coulomb

            return [summary, details];
        };

        const internalEnergies = decodeEnergies(false);
        const interactionEnergies = decodeEnergies(true);
        return [ligandSpec, internalEnergies, interactionEnergies];
    }

    decodeSelection(data, updateAtomSelected) {
        const d = new MsgDecoder(data);
        let bitPos = 0;
        let countSelected = 0;
        let selectedNotSent = 0;

        const bskind = d.getUint8(); // should be bs_atom
        // Stream has zero-count followed by non-zero count and non-zero bytes, alternating.
        // Need to unselect as well as select.
        const dobits = (which) => {
            let byteCount = d.getUint8();
            let bitCount;
            let bitMask;
            let nzbyte;
            let selected;
            let atom;
            while (byteCount-- > 0 && d.hasMore()) {
                if (which) nzbyte = d.getUint8();
                bitCount = 8;
                bitMask = 1;
                while (bitCount-- > 0) {
                    selected = which ? bitMask & nzbyte : false;
                    bitMask <<= 1;
                    atom = this.lookupAtom(bitPos++);
                    if (!atom) { // Ok, since not all atoms are sent
                        if (selected) selectedNotSent++;
                    } else {
                        updateAtomSelected(atom, selected);
                        if (selected) countSelected++;
                    }
                }
            }
        };

        while (d.hasMore()) {
            dobits(false); // zero run
            if (d.hasMore()) dobits(true); // non-zero run
        }

        console.log(`${countSelected} atoms selected.`);

        if (selectedNotSent > 0) {
            console.log(`${selectedNotSent} atoms selected but not sent.`);
        }
    }

    /**
     * Decode results from bmaps data service.
     * This is identical to decodeFragments except for bond decoding
     */
    decodeFragDataResults(data) {
        const d = new MsgDecoder(data);

        // Return values
        const frags = [];
        const baseFrags = [];
        const atoms = [];
        const bonds = [];

        // Constants and functions
        const chgQuantum = 2.0/UINT16_MAX;
        const DataChunk_End = 0;
        const DataChunk_BaseFragmentDefinitions = 1;
        const DataChunk_FragmentPostures = 2;
        const DataChunk_Metadata = 3;

        const add3_3 = (v1, v2) => [v1[0]+v2[0], v1[1]+v2[1], v1[2]+v2[2]];
        const mul3x3_3 = (m, v) => [
            m[0][0]*v[0]+m[0][1]*v[1]+m[0][2]*v[2],
            m[1][0]*v[0]+m[1][1]*v[1]+m[1][2]*v[2],
            m[2][0]*v[0]+m[2][1]*v[1]+m[2][2]*v[2],
        ];

        // Control loop: process each chunk one at a time (processing functions defined below)
        let chunkCode;
        while ((chunkCode = d.getUint8()) !== DataChunk_End) {
            switch (chunkCode) {
                case DataChunk_BaseFragmentDefinitions:
                    processBaseFragments();
                    break;
                case DataChunk_FragmentPostures:
                    processFragmentPostures();
                    break;
                case DataChunk_Metadata:
                    processMetadata();
                    break;
                default:
                    console.warn(`decodeFragDataResults: Unrecognized data chunk code: ${chunkCode}`);
            }
        }

        return [atoms, bonds, frags];

        // CHUNK PROCESSING FUNCTIONS

        function processBaseFragments() {
            // Decode base fragments
            let nBaseFrags = d.getUint8();
            if (nBaseFrags === 0xff) nBaseFrags = d.getUint16();

            // Will be used to decode bonds in the loop body below
            function decodeBondType(bondType) {
                // b1 b2 b3, 0-indexed so need to add 1
                if (bondType <= 2) return { bondType: bondType + 1, kekuleType: 0 };
                // ba1 ba2 bd1 bd2
                switch (bondType) {
                    case 3: return { bondType: 8, kekuleType: 1 };
                    case 4: return { bondType: 9, kekuleType: 2 };
                    case 5: return { bondType: 7, kekuleType: 1 };
                    case 6: return { bondType: 7, kekuleType: 2 };
                    default:
                        console.warn(`Unexpected bond order: ${bondType}`);
                        return { bondType: 1, kekuleType: 0 };
                }
            }

            // console.log(`${nBaseFrags} base fragments`);
            while (nBaseFrags-- > 0 && d.hasMore()) {
                const [fragName, IUPACname, alias] = d.getEncodedString().split(';');
                const boxmin = [d.getFloat32(), d.getFloat32(), d.getFloat32()]; // frag boundingbox
                const quantum = [d.getFloat32(), d.getFloat32(), d.getFloat32()];
                const formalCharge = d.getInt8();
                const orientationOffset = d.getUint8();
                const baseAtoms = [];
                let nAtoms = d.getUint8();
                let index = 0;
                while (nAtoms-- > 0 && d.hasMore()) {
                    const atomName = d.getStringPadded();
                    const amberType = d.getString(2, false);
                    const charge = false // d.supportsFeature(ProtocolFeatures.FragFloatCharges)TODO
                        ? d.getFloat32()
                        : (d.getUint16()*chgQuantum) - 1.0;
                    const pos = [d.getUint16(), d.getUint16(), d.getUint16()];
                    d.Do3((i) => { pos[i] = boxmin[i] + (quantum[i]*pos[i]); });

                    const baseAtomBonds = [];
                    // Hs don't have encoded bonds in data service results
                    if (amberToElementMap.get(amberType) !== 'H') {
                        let bondByte;

                        const controlBondType = 0x7;
                        const controlAtomIndex = 0x1f;
                        while ((bondByte = d.getUint8()) !== 0xff && d.hasMore()) {
                            let bondType = (bondByte>>5) & 0x7;
                            let atom2Index = bondByte & 0x1f;

                            let kekuleType = 0;
                            if (bondType === controlBondType) {
                                // End of bonds is already handled by the 0xFF check in while loop.
                                // Any other control items could be placed here
                            } else {
                                ({ bondType, kekuleType } = decodeBondType(bondType));

                                if (atom2Index === controlAtomIndex) {
                                    atom2Index = d.getUint8();
                                }
                            }

                            // console.log(`a ${atom2Index} type ${bondType} kekule ${kekuleType}`);
                            baseAtomBonds.push({
                                type: bondType, index: atom2Index, kekuletype: kekuleType,
                            });
                        }
                    }
                    baseAtoms.push({
                        name: atomName,
                        amber: amberType,
                        charge,
                        pos,
                        bonds: baseAtomBonds,
                        index: index++,
                    });
                }
                /*
                    console.log(`
                          base ${fragName} fc ${formalCharge} offset ${orientationOffset} natoms
                          ${baseAtoms.length}
                      `);
                */
                baseFrags.push({
                    name: fragName,
                    IUPAC: IUPACname,
                    chemName: alias,
                    formalCharge,
                    orientationOffset,
                    atoms: baseAtoms,
                });
            }
        }

        function readFragProjectCase() {
            const code = d.getUint8();
            switch (code) {
                case 0x01: {
                    const fullSourceId = d.getString();
                    // Format: <dataSourceId>!<project>/<case>
                    const [dataSourceId, projectCase] = fullSourceId.split('!'); // eslint-disable-line
                    return projectCase;
                }
                default:
                    console.warn('Unrecognized code for fragment posture info');
                    return 'error';
            }
        }

        function processFragmentPostures() {
            // Scaling parameters
            const boxmin = [d.getFloat32(), d.getFloat32(), d.getFloat32()];
            const quantum = [d.getFloat32(), d.getFloat32(), d.getFloat32()];

            const fragProjectCase = readFragProjectCase();

            // Decode fragments
            let nfrags = d.getUint16(); // decremented below
            if (nfrags === UINT16_MAX) nfrags = d.getUint32();

            let fragOrd = 0;
            while (nfrags-- > 0 && d.hasMore()) {
                // Decode the base fragment index.
                fragOrd++;
                let baseIndex = d.getUint8();
                const hasSolvEnergy = baseIndex & (1<<7);
                baseIndex &= 0x7f;
                if (baseIndex === 0x7f) baseIndex = d.getUint16();
                const fragmentGroup = d.getUint8();
                // Include the base serial number (individual atoms have serial
                // numbers that are increments from this).
                const serialNumberBase = d.getUint32();
                // Decode spatial configuration.
                const trans = [d.getUint16(), d.getUint16(), d.getUint16()];
                const orient = [d.getFloat32(), d.getFloat32(), d.getFloat32()];
                d.Do3((i) => {
                    trans[i] = boxmin[i] + (quantum[i]*trans[i]);
                });

                // Decode energies.
                const enSolute = d.getFloat32();
                const enFragments = d.getFloat32();
                const enSolv = hasSolvEnergy ? d.getFloat32() : 0;
                const isWater = false; // TODO
                // NOTE: bfd-server sends excess chemical potential in two different ways.
                // For waters, exchemP is sent in the enSolv field
                // For fragments, exchemP is sent in the enFragments field.
                // Any changes to this have to be coordinated with bfd-server
                const exchemP = isWater ? enSolv : enFragments;

                const poseSerialNo = d.getUint32().toString();

                if (!baseFrags[baseIndex]) {
                    console.warn(`Failed to find baseFrag ${baseIndex} for fragment.
    Ord: ${fragOrd}, Group: ${fragmentGroup},
    SerialBase: ${serialNumberBase}, Pose: ${poseSerialNo}
    Skipping...`);
                    continue;
                }
                const frag = new DecodedFragment({
                    baseFrag: baseFrags[baseIndex],
                    ordinal: fragOrd,
                    fragmentGroup,
                    atomSerialNumberBase: serialNumberBase,
                    translation: trans,
                    orientation: orient,
                    exchemPotential: exchemP,
                    enSolute,
                    enFragments,
                    enSolv,
                    poseSerialNo,
                });
                frag.setProjectCase(fragProjectCase);
                frags.push(frag);
            }

            for (const frag of frags) {
                const t = frag.translation;
                const o = frag.orientation;
                const m = orientation_to_matrix(o, frag.baseFrag.orientationOffset);
                // serialBase here ensures unique atom ids for data service atoms. Not stored.
                let serialBase = fragDataCount++;
                const bfatoms = frag.baseFrag.atoms;
                const fatoms = [];
                const isWater = frag.baseFrag.name === 'water';
                for (const bfatom of bfatoms) {
                    const pos = add3_3(mul3x3_3(m, bfatom.pos), t);
                    const atomElement = amberToElementMap.get(bfatom.amber);
                    const fatom = new Atom(
                        frag,
                        serialBase,
                        bfatom.name,
                        pos,
                        serialBase,
                        bfatom.amber,
                        atomElement
                    );
                    fatom.charge = isWater && bfatom.name === 'O1' ? knownLigandChargesMap.get('HOH')[0] : bfatom.charge;
                    // in decodeFragments, the atom is saved to the registry here
                    fatoms.push(fatom);
                    atoms.push(fatom);
                    serialBase++;
                    fragDataCount++; // Keep this up to date for next fragment
                }
                frag.atoms = fatoms;
                // Second pass for bonds, once all the atoms have been made.
                for (const bfatom of bfatoms) { // iterate over base frag atoms
                    for (const bfbond of bfatom.bonds) {
                        const fatom1 = fatoms[bfatom.index];
                        const fatom2 = fatoms[bfbond.index];
                        bonds.push(new Bond(fatom1, fatom2, bfbond.type, bfbond.kekuletype));
                    }
                }
            }
        }

        function processMetadata() {
            let byteCount = d.getUint32();
            let skipped = 0;
            while (byteCount-- > 0) {
                d.getUint8();
                skipped++;
            }
        }
    }

    decodeFragments(data, fragmentCollectionType) {
        const d = new MsgDecoder(data);
        const frags = [];
        const baseFrags = [];
        const chgQuantum = 2.0/UINT16_MAX;

        // Decode base fragments
        let nBaseFrags = d.getUint8();
        if (nBaseFrags === 0xff) nBaseFrags = d.getUint16();
        // console.log(`${nBaseFrags} base fragments`);
        while (nBaseFrags-- > 0 && d.hasMore()) {
            const [fragName, IUPACname, alias] = d.getEncodedString().split(';');
            const boxmin = [d.getFloat32(), d.getFloat32(), d.getFloat32()]; // fragment boundingbox
            const quantum = [d.getFloat32(), d.getFloat32(), d.getFloat32()];
            const formalCharge = d.getInt8();
            const orientationOffset = d.getUint8();
            const atoms = [];
            let nAtoms = d.getUint8();
            let index = 0;
            while (nAtoms-- > 0 && d.hasMore()) {
                const atomName = d.getStringPadded();
                const amberType = d.getString(2, false);
                const charge = d.supportsFeature(ProtocolFeatures.FragFloatCharges)
                    ? d.getFloat32()
                    : (d.getUint16()*chgQuantum) - 1.0;
                const pos = [d.getUint16(), d.getUint16(), d.getUint16()];
                d.Do3((i) => { pos[i] = boxmin[i] + (quantum[i]*pos[i]); });
                const bonds = [];
                let bondByte;
                while ((bondByte = d.getUint8()) !== 0xff && d.hasMore()) {
                    let bondType = (bondByte>>5) & 0x7;
                    let atom2Index = bondByte & 0x1f;
                    let kekuleType = 0;
                    if (bondType === 0x7) {
                        bondType = (bondByte & 0xf);
                        if (bondType === 0xe) {
                            const bondPair = d.getUint8();
                            bondType = (bondPair & 0xf);
                            kekuleType = (bondPair>>4);
                        }
                        atom2Index = d.getUint8();
                    }
                    if (bondType > 3) bondType += 2;

                    // console.log(`fragindex ${atom2Index} type ${bondType} kekule ${kekuleType}`);
                    bonds.push({ type: bondType, index: atom2Index, kekuletype: kekuleType });
                }
                atoms.push({
                    name: atomName,
                    amber: amberType,
                    charge,
                    pos,
                    bonds,
                    index: index++,
                });
            }
            /*
                console.log(`
                    base ${fragName} fc ${formalCharge} offset ${orientationOffset} natoms
                    ${atoms.length}
                `);
            */
            baseFrags.push({
                name: fragName,
                IUPAC: IUPACname,
                chemName: alias,
                formalCharge,
                orientationOffset,
                atoms,
            });
        }

        // Scaling parameters
        const boxmin = [d.getFloat32(), d.getFloat32(), d.getFloat32()];
        const quantum = [d.getFloat32(), d.getFloat32(), d.getFloat32()];

        // Decode fragments
        let nfrags = d.getUint16(); // decremented below
        if (nfrags === UINT16_MAX) nfrags = d.getUint32();

        let fragOrd = 0;
        while (nfrags-- > 0 && d.hasMore()) {
            // Decode the base fragment index.
            fragOrd++;
            let baseIndex = d.getUint8();
            const hasSolvEnergy = baseIndex & (1<<7);
            baseIndex &= 0x7f;
            if (baseIndex === 0x7f) baseIndex = d.getUint16();
            const fragmentGroup = d.getUint8();
            // Include the base serial number (individual atoms have serial
            // numbers that are increments from this).
            const serialNumberBase = d.getUint32();
            // Decode spatial configuration.
            const trans = [d.getUint16(), d.getUint16(), d.getUint16()];
            const orient = [d.getFloat32(), d.getFloat32(), d.getFloat32()];
            d.Do3((i) => {
                trans[i] = boxmin[i] + (quantum[i]*trans[i]);
            });

            // Decode energies.
            const enSolute = d.getFloat32();
            const enFragments = d.getFloat32();
            const enSolv = hasSolvEnergy ? d.getFloat32() : 0;
            let exchemP = 0;

            // NOTE: bfd-server sends excess chemical potential in two different ways.
            // For waters, exchemP is sent in the enSolv field
            // For fragments, exchemP is sent in the enFragments field.
            // Any changes to this have to be coordinated with bfd-server
            switch (fragmentCollectionType) {
                case 'watermap': // called for ResponseIds.WaterMap
                    exchemP = enSolv;
                    break;
                case 'clustermap': // called for ResponseIds.ClusterMap
                case 'fragmentmap': // called for ResponseIds.FragmentMap
                case 'fragments': // called for ResponseIds.Fragments (Not currently used)
                case 'find-fragments': // called for ResponseIds.FindFragments
                    exchemP = enFragments;
                    break;
                // no default: already initialized to 0
            }

            const poseSerialNo = (fragmentCollectionType === 'find-fragments')
                ? d.getUint32().toString()
                : null;
            /*
                if (fragmentCollectionType === 'clustermap') {
                    console.log(`frag group ${fragmentGroup} B ${exchemP}`);
                }
            */

            if (!baseFrags[baseIndex]) {
                console.warn(`Failed to find baseFrag ${baseIndex} for fragment.
    Ord: ${fragOrd}, Group: ${fragmentGroup},
    SerialBase: ${serialNumberBase}, Pose: ${poseSerialNo}
    Skipping...`);
                continue;
            }
            const frag = new DecodedFragment({
                baseFrag: baseFrags[baseIndex],
                ordinal: fragOrd,
                fragmentGroup,
                atomSerialNumberBase: serialNumberBase,
                translation: trans,
                orientation: orient,
                exchemPotential: exchemP,
                enSolute,
                enFragments,
                enSolv,
                poseSerialNo,
            });
            frags.push(frag);
        }

        const atoms = [];
        const bonds = [];
        const add3_3 = (v1, v2) => [v1[0]+v2[0], v1[1]+v2[1], v1[2]+v2[2]];
        const mul3x3_3 = (m, v) => [
            m[0][0]*v[0]+m[0][1]*v[1]+m[0][2]*v[2],
            m[1][0]*v[0]+m[1][1]*v[1]+m[1][2]*v[2],
            m[2][0]*v[0]+m[2][1]*v[1]+m[2][2]*v[2],
        ];

        for (const frag of frags) {
            const t = frag.translation;
            const o = frag.orientation;
            const m = orientation_to_matrix(o, frag.baseFrag.orientationOffset);
            let serialBase = frag.atomSerialNumberBase;
            const bfatoms = frag.baseFrag.atoms;
            const fatoms = [];
            const isWater = frag.baseFrag.name === 'water';
            for (const bfatom of bfatoms) {
                const pos = add3_3(mul3x3_3(m, bfatom.pos), t);
                const atomElement = amberToElementMap.get(bfatom.amber);
                const fatom = new Atom(
                    frag,
                    serialBase,
                    bfatom.name,
                    pos,
                    serialBase,
                    bfatom.amber,
                    atomElement
                );
                fatom.charge = isWater && bfatom.name === 'O1' ? knownLigandChargesMap.get('HOH')[0] : bfatom.charge;
                this.storeAtom(serialBase, fatom); // for selection lookup
                fatoms.push(fatom);
                atoms.push(fatom);
                serialBase++;
            }
            frag.atoms = fatoms;
            // Second pass for bonds, once all the atoms have been made.
            for (const bfatom of bfatoms) { // iterate over base frag atoms
                for (const bfbond of bfatom.bonds) {
                    const fatom1 = fatoms[bfatom.index];
                    const fatom2 = fatoms[bfbond.index];
                    bonds.push(new Bond(fatom1, fatom2, bfbond.type, bfbond.kekuletype));
                }
            }
        }
        const caseArgs = d.getEncodedString();
        return [atoms, bonds, frags, caseArgs];
    }

    decodeSolute(data) {
        const d = new MsgDecoder(data);
        const atoms = [];
        const bonds = [];
        const bondSpecs = [];
        const residues = [];
        /** @type {Map<string, Map<string, Atom>>} maps res code (seq+inscode) to name->Atom map */
        const residueAtomsMap = new Map();
        /** @type {Map<string, string[]>} maps res code (seq+inscode) to atom name list */
        const residueAtomNameListMap = new Map();

        let nresidues = d.getUint16();
        if (nresidues === 0xffff) nresidues = d.getUint32();
        const boxmin = [];
        const boxmax = [];
        d.Do3(() => { boxmin.push(d.getFloat32()); });
        d.Do3(() => { boxmax.push(d.getFloat32()); });

        const boxWidths = [];
        const quantum = [];
        d.Do3((i) => { boxWidths.push(boxmax[i] - boxmin[i]); });
        d.Do3((i) => { quantum.push(Math.max(1, boxWidths[i]) / UINT16_MAX); });

        //--- where to put this? and box params -- atoms properties?
        const soluteName = d.getStringPadded();
        // Loop over residues
        let atomSequenceNumber = 1;
        while (nresidues-- > 0 && d.hasMore()) {
            const fullNameClause = d.getStringPadded();
            //--- use atomGroupTypeName to distinguish residues from ligands with the same name.
            // Making eslint happy is not worth the trouble here.
            // eslint-disable-next-line prefer-const
            let [resname, nameClause, atomGroupTypeName] = fullNameClause.split(';');
            let [molType, molName] = nameClause ? nameClause.split(':') : [];
            if (resname) resname = resname.trim();
            if (molType) molType = molType.trim();
            if (molName) molName = molName.trim();
            if (atomGroupTypeName) atomGroupTypeName = atomGroupTypeName.trim();

            if (!molType && atomGroupTypeName?.includes('saccharide')) {
                molType = 'saccharide';
            }

            // resnames string can be resname;moltype:display name (mainly for peptides)
            // resname can be ASP_CT (C-terminal residues) or ASP_NT (N-terminal residues)
            // or residues with custom partial charges with names like 1XMS_ILE155_B or
            // <PDB file code>_<pdb 3-letter code><sequence number>_<chain>.
            let searchName = resname; // for looking up atom lists
            const matchResults = resname.match(/^(\w+)_[A-Z]{1}$/);
            if (matchResults && resname.length < 9) {
                searchName = matchResults[1];
            }

            const pdbID = d.supportsFeature(ProtocolFeatures.LongPDBIDs)
                ? d.getStringPadded()
                : d.getString(3, false);
            const [chainID, modelNumber] = d.decodeChainAndModel();
            const [seqNum, inscodeStr, altLocStr, rescode] = d.decodeSeqNum(resname);
            // Detect that modified residues with names like 1XMS_ILE155_B and pdbID ILE are not
            // mistaken for ligands.  HIS is a special case, where pdb's are HIE, HID, or HIP,
            // and only HIS for modified residues.  Modified residues (e.g.  phosphorolated) are
            // also do not get the het flag (should be only non-peptide small molecules or
            // really non-standard peptides such as L-peptides).
            const isIon = ionAtomNamesMap.has(resname);
            const isWater = waterResidueNames.includes(pdbID);
            const hetflag = this.ligandAtomInfo.has(searchName)
                  || knownLigandAtomNamesMap.has(searchName)
                  || isIon;
            const isCompound = hetflag && pdbID !== resname
                  && !isIon && !isWater
                  && (!molType || molType === 'small_molecule'); // not cofactor or saccharide
            const residue = new DecodedResidue({
                name: resname,
                pdbCode: pdbID,
                sequenceNumber: seqNum,
                insertionCode: inscodeStr,
                altLocation: altLocStr,
                rescode,
                chainID,
                modelNumber,
                hetflag,
                isIon,
                molType,
                molName,
                isCompound,
            });

            // Log "special" residues, especially those that might have special mol. types.
            if (atomGroupTypeName !== 'amber-amino' && atomGroupTypeName !== 'amber-nucleotide'
                && !knownLigandAtomNamesMap.has(resname)
                && !ionAtomNamesMap.has(resname)
                && !bufferResidueNames.includes(resname)) {
                const spec = `${pdbID}.${seqNum}:${chainID}`;
                const molTypePart = molType ? ` => ${molType}` : '';
                console.log(`Decoded special residue: ${spec}: ${fullNameClause}${molTypePart}`);
            }
            residues.push(residue);

            // Note:
            // A name can sometimes exist on more than one list when a name can refer to different
            // molecules with Amber versus PDB names. An example is NHE, NME, NHC which can be amino
            // acid terminals (Amber) or unrelated ligands (PDB). In general it shouldnt be the case
            // that both uses occur in the same solute (although it theoretically could), so search
            // the per-file ligand names first. Eventually, the atom group type should be sent with
            // the residue to disambiguate this.
            // Search in the order most likely to occur, except do per-file search first.

            const atomInfo = this.getLigandAtomInfo(searchName)
                || this.getCustomResidueInfo(atomGroupTypeName, searchName)
                || searchKnownAtomInfo(searchName);

            if (!atomInfo) {
                console.warn(`Cannot find atom info for residue '${resname}'.`);
                console.warn(`  Search name '${searchName}', type ${atomGroupTypeName}.`);
                console.warn('Cannot decode solute without atom name list.');
                return [];
            }

            const {
                atomNames: atomNameList,
                amberNames: amberNameList,
                charges: chargesList,
                ringOrdinals: ringOrdinalsList,
                formalCharge: fc,
            } = atomInfo;

            // For ligands, Amber type, charges, may not be received yet.
            if (!hetflag && !amberNameList) console.warn(`Cannot find amber name list for residue ${resname}.`);
            if (!hetflag && !chargesList) console.warn(`Cannot find charges list for residue ${resname}.`);

            // Apply the formal charge if it came in with the ligandAtomNames.
            if (hetflag) {
                if (fc != null) residue.formalCharge = fc;
            }

            const resAtoms = [];
            const lastResidue = nresidues === 0;
            let atomNumber = 0;
            /** @type { Map<string, Atom> } */
            const atomNameMap = new Map();
            // Loop over atoms
            const numAtoms = d.getUint16();
            /*
                console.log(`
                    resname ${resname} rescode ${rescode} chainID ${chainID} natoms ${numAtoms}
                `);
            */
            let natoms = numAtoms;
            let hydrogenOrd = 1;
            if (natoms === 0) console.log(`Decoding zero atoms for ${residue.getSpec()}`);
            while (natoms-- > 0 && d.hasMore()) {
                const atomPackage = [];
                d.Do3(() => { atomPackage.push(d.getUint16()); }); // x, y, z
                const pos = [];
                d.Do3((i) => { pos.push(quantum[i]*atomPackage[i]+boxmin[i]); });
                const serialNumPacked = d.getUint32();
                let nameOrdinal = (serialNumPacked>>24) & 0xff;
                let serialNum = (0xffffff & serialNumPacked);
                if (serialNum === 0xffffff) serialNum = d.getUint32();
                if (nameOrdinal === 0xff) nameOrdinal = d.getUint16();
                let Bfactor = 0;
                if (d.supportsFeature(ProtocolFeatures.BFactor)) {
                    Bfactor = d.getFloat32();
                }
                // ff ordinal means name could not be found in the residue atom group.
                // Assume the missing atom is an N-terminal hydrogen (common case).
                const atomName = nameOrdinal < atomNameList.length ? atomNameList[nameOrdinal] || `H${hydrogenOrd++}` : '??';
                if (!atomName) {
                    console.log(`Can't find name for ${resname}.${seqNum}:${chainID} ord ${nameOrdinal}. Skipping atom.`);
                    // Is it better to make this robust, or force dealing with errors?
                    continue;
                }
                const amberName = nameOrdinal < amberNameList.length ? amberNameList[nameOrdinal] || 'h1' : '??';
                const atomElement = amberToElementMap.get(amberName) || '??';
                const atom = new Atom(residue, serialNum, atomName, pos, atomSequenceNumber,
                    amberName, atomElement, atomNumber, (natoms === 0 && lastResidue));
                atom.residue = residue;
                if (Bfactor !== 0) {
                    atom.Bfactor = Bfactor;
                }
                const charge = chargesList && nameOrdinal < chargesList.length
                    ? chargesList[nameOrdinal] || 0.0
                    : 0.0;
                if (charge !== 0.0) atom.charge = charge; // leave undefined if no charge specified
                /*
                    console.log(`
                        atom name ${atomName} pos ${pos} amberName ${amberName} charge ${charge}
                    `);
                */

                const ringOrdinals = ringOrdinalsList && nameOrdinal < ringOrdinalsList.length
                    ? ringOrdinalsList[nameOrdinal]
                    : undefined;
                if (ringOrdinals && ringOrdinals.length > 0) {
                    atom.ringOrdinals = ringOrdinals;
                    // console.log(`${residue.name}-${atomName} rings ${ringOrdinals.join(', ')}`);
                }
                this.storeAtom(serialNum, atom); // for selection lookup
                resAtoms.push(atom);
                atomNameMap.set(atomName, atom);
                atomSequenceNumber++;
                atomNumber++;

                // Decode bonds of atom
                while (d.hasMore()) {
                    //--- do we need serial numbers for bonds for selection?
                    const bondSpec = d.getUint8();
                    if (bondSpec === 0xff) break;
                    // Bond can be within the residue or to another residue.
                    let orescode = rescode;
                    let oresname = resname; // default to within the residue
                    let ochainID = chainID;
                    let omodelNumber = modelNumber;
                    let decoded_bondType = (bondSpec>>5) & 0x7;
                    let decoded_nameOrd = bondSpec & 0x1f;
                    let decoded_kekuleType = 0;
                    /*
                        console.log(`
                            atom ${atom.atom} bond type ${decoded_bondType} ord ${decoded_nameOrd}
                        `);
                    */
                    if (decoded_bondType === 0x7) {
                        // Extended format for special bond types, larger residues, or
                        // interResidue bonds.
                        decoded_bondType = (bondSpec & 0xf); // 4-bits
                        // console.log(`  decoded bond type ${decoded_bondType}`);
                        if (decoded_bondType === 0xe) {
                            const bondPair = d.getUint8();
                            decoded_bondType = (bondPair & 0xf);
                            decoded_kekuleType = (bondPair>>4) & 0x3; // high 2 bits are bond class
                            /*
                                console.log(`
                                    bond type ${decoded_bondType} kekule ${decoded_kekuleType}
                                `);
                            */
                        }
                        decoded_nameOrd = d.getUint8(); // up to 256 residue atoms
                        if (decoded_nameOrd === 0xff) decoded_nameOrd = d.getUint16();
                        // console.log(`extended ord ${decoded_nameOrd}`);
                        if (bondSpec & (1<<4)) {
                            // InterResidue bond.
                            oresname = d.getStringPadded();
                            // Decode 16-bit encoded seq num.
                            [,,, orescode] = d.decodeSeqNum(oresname);
                            [ochainID, omodelNumber] = d.decodeChainAndModel();
                        }
                    }

                    // Bond types are 1,2,3 for single, double, triple, > 3 for delocalized,
                    // conjugated and aromatic types. Indeterminate is 0, so make it single.
                    // Need to add 2 to the special bonds.
                    const decoded_bondOrder = decoded_bondType > 3
                        ? decoded_bondType+2
                        : (decoded_bondType || 1);
                    const omodel = omodelNumber !== 0 ? omodelNumber.toString() : '';
                    const ospec = `${oresname}.${orescode}:${ochainID}${omodel}`;
                    const newBondSpec = [
                        atom, ospec, decoded_nameOrd, decoded_bondOrder, decoded_kekuleType,
                    ];
                    bondSpecs.push(newBondSpec);
                }
            }
            residue.atoms = resAtoms;
            Array.prototype.push.apply(atoms, resAtoms);
            const model = modelNumber !== 0 ? modelNumber.toString() : '';
            const rescodeAndChainID = `${resname}.${rescode}:${chainID}${model}`;
            residueAtomsMap.set(rescodeAndChainID, atomNameMap);
            residueAtomNameListMap.set(rescodeAndChainID, atomNameList);
        }

        // Now create the bonds, once all the atoms have been created, residues indexed.
        for (const bondSpec of bondSpecs) {
            const [atom, rescodeAndChainID, nameOrd, bondOrder, kekuleOrder] = bondSpec;
            const atomMap = residueAtomsMap.get(rescodeAndChainID);
            const atomNameList = residueAtomNameListMap.get(rescodeAndChainID);
            if (!atomNameList) { console.log(`No atom name list found for ${rescodeAndChainID}`); }
            //--- do this more efficiently
            const oatomName = atomNameList === undefined ? undefined : atomNameList[nameOrd];
            const oatom = oatomName === undefined || atomMap === undefined
                ? undefined
                : atomMap.get(oatomName);
            if (oatom === undefined) {
                console.log(`Atom ${oatomName} undefined in ${rescodeAndChainID}`);
            } else {
                const bond = new Bond(atom, oatom, bondOrder, kekuleOrder);
                if (bond) bonds.push(bond); // 3dmol puts bonds on atoms.
            }
        }

        // console.log("decoding helix specs.");
        // Get helix, sheet specs.
        const decodeChainAndModel = () => {
            let bchainID = d.getUint8();
            if (bchainID === 0xff) return [0xff, 0];
            let modelNum = 0;
            if (bchainID === 0xfe) {
                modelNum = d.getUint8();
                bchainID = d.getUint8();
            }
            const chainID = bchainID === 0xfd ? d.getStringPadded() : String.fromCharCode(bchainID);
            return [chainID, modelNum];
        };

        const helices = [];
        while (d.hasMore()) {
            const [chainID, modelNum] = decodeChainAndModel();
            if (chainID === 0xff) break;
            const [seqnumStart, inscodeStart] = d.decodeSeqNum();
            const [seqnumEnd, inscodeEnd] = d.decodeSeqNum();
            helices.push([modelNum, chainID, seqnumStart, inscodeStart, seqnumEnd, inscodeEnd]);
        }

        // console.log("decoding sheet specs.");
        const sheets = [];
        while (d.hasMore()) {
            const [chainID, modelNum] = decodeChainAndModel();
            if (chainID === 0xff) break;
            const [seqnumStart, inscodeStart] = d.decodeSeqNum();
            const [seqnumEnd, inscodeEnd] = d.decodeSeqNum();
            sheets.push([modelNum, chainID, seqnumStart, inscodeStart, seqnumEnd, inscodeEnd]);
        }

        const caseID = d.getEncodedString().split(' ').join('/');
        return [caseID, residues, atoms, bonds, helices, sheets, soluteName];
    }

    decodeCompound(data) {
        //--- share with decodeSolute?
        const d = new MsgDecoder(data);
        const atoms = [];
        const bonds = [];
        const bondSpecs = [];

        const cmpdSpec = d.getEncodedString(); // a ligand spec or compound name
        const updateReason = d.getString();

        const boxmin = [];
        const boxmax = [];
        d.Do3(() => { boxmin.push(d.getFloat32()); });
        d.Do3(() => { boxmax.push(d.getFloat32()); });

        const boxWidths = [];
        const quantum = [];
        d.Do3((i) => { boxWidths.push(boxmax[i] - boxmin[i]); });
        d.Do3((i) => { quantum.push(Math.max(1, boxWidths[i]) / UINT16_MAX); });

        const [cmpdname, nameClause] = d.getEncodedStringPadded().split(';'); // encoded??
        const [molType, molName] = nameClause ? nameClause.split(':') : [];
        const pdbID = d.supportsFeature(ProtocolFeatures.LongPDBIDs)
            ? d.getStringPadded()
            : d.getString(3, false);
        const [chainID, modelNumber] = d.decodeChainAndModel();
        // Assume compounds' don't have alt locs so suffix isn't misinterpreted, thus no
        // arg to decodeSeqNum.
        const [seqNum, inscodeStr, altLocStr, rescode] = d.decodeSeqNum();
        const model = modelNumber !== 0 ? modelNumber.toString() : '';
        /*
            console.log(`
                Decoding ${cmpdname},
                pdb ${pdbID},
                seqNum ${seqNum} inscode ${inscodeStr} chain ${chainID},
                model ${model} rescode ${rescode}
            `);
        */
        const compound = new DecodedResidue({
            name: cmpdname,
            pdbCode: pdbID,
            sequenceNumber: seqNum,
            insertionCode: inscodeStr,
            altLocation: altLocStr,
            rescode,
            chainID,
            modelNumber,
            updateStatus: updateReason,
            hetflag: true,
            isIon: false,
            molType,
            molName,
            isCompound: cmpdSpec === cmpdname,
        });
        const cmpdBondSpec = `${cmpdname}.${rescode}:${chainID}${model}`; // for bonds below

        const atomInfo = this.getLigandAtomInfo(cmpdname) || this.getLigandAtomInfo(cmpdSpec);

        if (!atomInfo) {
            console.warn(`Cannot find atom info for compound ${cmpdname} spec ${cmpdSpec}.`);
            return [];
        }
        const {
            atomNames: atomNameList,
            amberNames: amberNameList,
            charges: chargesList,
            ringOrdinals: ringOrdinalsList,
            formalCharge: fc,
        } = atomInfo;
        if (fc != null) compound.formalCharge = fc;

        //--- This code should be shared with decodeSolute, but it's complicated to factor it.
        const cmpdAtoms = [];
        const atomNameMap = new Map();
        let atomNumber = 0;

        // Loop over atoms
        let natoms = d.getUint16();
        let atomSequenceNumber = 1;
        if (natoms === 0) console.log('Decoding zero atoms.');
        while (natoms-- > 0 && d.hasMore()) {
            const atomPackage = [];
            d.Do3(() => { atomPackage.push(d.getUint16()); }); // x, y, z
            const pos = [];
            d.Do3((i) => { pos.push(quantum[i]*atomPackage[i]+boxmin[i]); });
            let serialNum = d.getUint32();
            let nameOrdinal = (serialNum>>24) & 0xff;
            serialNum &= 0xffffff;
            if (serialNum === 0xffffff) serialNum = d.getUint32();
            if (nameOrdinal === 0xff) nameOrdinal = d.getUint16();
            let Bfactor = 0;
            if (d.supportsFeature(ProtocolFeatures.BFactor)) {
                Bfactor = d.getFloat32();
            }
            // ff ordinal means name could not be found in the residue atom group.
            const atomName = nameOrdinal < atomNameList.length ? atomNameList[nameOrdinal] : '??';
            const spec = `${cmpdname}.${seqNum}:${chainID}${model}`;
            if (!atomName) {
                console.warn(`Can't find name for ${spec} ord ${nameOrdinal}. Skipping atom.`);
                // Is it better to make this robust, or force dealing with errors?
                continue;
            }
            const amberName = nameOrdinal < amberNameList.length ? amberNameList[nameOrdinal] : '??';
            const atomElement = amberToElementMap.get(amberName) || '??';
            const atom = new Atom(compound, serialNum, atomName, pos, atomSequenceNumber, amberName,
                atomElement, atomNumber, false);
            atom.residue = compound;
            if (Bfactor !== 0) {
                atom.Bfactor = Bfactor;
            }
            const charge = chargesList && nameOrdinal < chargesList.length
                ? chargesList[nameOrdinal] || 0.0
                : 0.0;
            if (charge !== 0.0) atom.charge = charge; // leave undefined if no charge specified
            const ringOrdinals = ringOrdinalsList && nameOrdinal < ringOrdinalsList.length
                ? ringOrdinalsList[nameOrdinal]
                : undefined;
            if (ringOrdinals && ringOrdinals.length > 0) {
                atom.ringOrdinals = ringOrdinals;
            }
            this.storeAtom(serialNum, atom); // for selection lookup
            cmpdAtoms.push(atom);
            atomNameMap.set(atomName, atom);
            atomSequenceNumber++;
            atomNumber++;

            // Decode bonds of atom
            while (d.hasMore()) {
                //--- do we need serial numbers for bonds for selection?
                const bondSpec = d.getUint8();
                if (bondSpec === 0xff) break;
                // Bond can be within the compound or covalently bound to something else.
                let orescode = rescode;
                let oresname = cmpdname; // default to within the residue
                let ochainID = chainID;
                let omodelNumber = modelNumber;
                let decoded_bondType = (bondSpec>>5) & 0x7;
                let decoded_nameOrd = bondSpec & 0x1f;
                let decoded_kekuleType = 0;
                /*
                    console.log(`
                        atom ${atom.atom} bond type ${decoded_bondType} ord ${decoded_nameOrd}
                    `);
                */
                if (decoded_bondType === 0x7) {
                    // Extended format for special bond types, larger compounds, or
                    // covalent bonds to other things.
                    decoded_bondType = bondSpec & 0xf; // 4-bits
                    if (decoded_bondType === 0xe) {
                        const bondPair = d.getUint8();
                        decoded_bondType = (bondPair & 0xf);
                        decoded_kekuleType = (bondPair>>4);
                        // console.log(`bondtype ${decoded_bondType} kekule ${decoded_kekuleType}`);
                    }
                    decoded_nameOrd = d.getUint8(); // up to 256 residue atoms
                    if (decoded_nameOrd === 0xff) decoded_nameOrd = d.getUint16();

                    if (bondSpec & (1<<4)) {
                        // InterResidue bond.
                        oresname = d.getStringPadded();
                        // Decode encoded seq num, insertion code, and alt location.
                        // Could be an interresidue bond to a residue with altloc, so
                        // provide residue name.
                        [,,, orescode] = d.decodeSeqNum(oresname);
                        [ochainID, omodelNumber] = d.decodeChainAndModel();
                    }
                }

                // Bond types are 1,2,3 for single, double, triple, > 3 for delocalized,
                // conjugated and aromatic types. Indeterminate is 0, so make it single.
                // Need to add 2 to the special bonds.
                const decoded_bondOrder = decoded_bondType > 3
                    ? decoded_bondType+2
                    : (decoded_bondType || 1);
                const omodel = omodelNumber !== 0 ? omodelNumber.toString() : '';
                const ospec = `${oresname}.${orescode}:${ochainID}${omodel}`;
                const newBondSpec = [
                    atom, oresname, ospec, decoded_nameOrd, decoded_bondOrder, decoded_kekuleType,
                ];
                bondSpecs.push(newBondSpec);
            }
        }
        compound.atoms = cmpdAtoms;
        Array.prototype.push.apply(atoms, cmpdAtoms);
        // Now create the bonds, once all the atoms have been created, residues indexed.
        for (const bondSpec of bondSpecs) {
            const [atom, oresname, ospec, onameOrd, bondOrder, kekuleOrder] = bondSpec;
            let oatom;
            let oatomName = 'unknown';
            if (ospec === cmpdBondSpec) {
                // Internal bond.
                oatomName = atomNameList[onameOrd];
                oatom = oatomName === undefined ? undefined : atomNameMap.get(oatomName);
            } else {
                // Covalent bond to something else.
                // Find the atom from the spec and name ordinal.

                const oatomInfo = this.getLigandAtomInfo(oresname) || this.getLigandAtomInfo(ospec)
                    // TODO add custom residues
                    || searchKnownAtomInfo([oresname, ospec]);

                if (!oatomInfo) {
                    console.log(`Can't find atom info for ${oresname} / ${ospec} in covalent bond to compound ${cmpdname}.`);
                    continue;
                } else {
                    const { atomNames: oatomNameList } = oatomInfo;
                    oatomName = oatomNameList[onameOrd];
                    oatom = this.getAtomByNameAndResidue(oatomName, ospec); // should work for res.
                    if (!oatom) {
                        // Try just the name (might be a compound).
                        oatom = this.getAtomByNameAndResidue(oatomName, oresname);
                        if (!oatom) {
                            console.log(`Can't find atom ${oatomName} in ${ospec} for covalent bond.`);
                            continue;
                        }
                    }
                    // console.log(`o ${oatom.atom} ${oatom.resi} ${oatom.icode} ${oatom.resname}`);
                }
            }
            if (oatom === undefined) {
                console.log(`Bond atom ${oatomName} undefined.`);
            } else {
                const bond = new Bond(atom, oatom, bondOrder, kekuleOrder);
                /*
                    console.log(`
                        Bond ${bond} ${atom.atom} ${atom.resi} - ${oatom.atom} ${oatom.resi},
                        type ${bondOrder} kekule ${kekuleOrder}
                    `);
                */
                if (bond) bonds.push(bond); // 3dmol puts bonds on atoms.
            }
        }
        console.log(`${atoms.length} atoms ${bonds.length} bonds.`);
        return [compound, atoms, bonds];
    }

    getAtomByNameAndResidue(atomName, resSpec) {
        const allAtoms = Array.from(this.storage.atomSerialNumberMap.values());
        const searchSpec = resSpec.replace('^', ''); // convert rescode to just spec
        return allAtoms.find((x) => x.atom === atomName && getFullResidueId(x) === searchSpec);
    }

    decodeTranslateMessage(data) {
        const d = new MsgDecoder(data);
        const molId = d.getEncodedString();
        const format = d.getString();
        const content = d.getRest();
        const contentRaw = d.getRestRaw();
        return {
            molId, format, content, contentRaw,
        };
    }
}

// Note, orientation vectors are stored as floats, so use float resolution.
const FLT_EPSILON = 1.19209290e-07;
const zeroThreshold = 2*FLT_EPSILON;

function decomposeOrientation(o, offset) {
    const vecmag = Math.sqrt(o[0]*o[0] + o[1]*o[1] + o[2]*o[2]);
    let angle;
    let u;
    if (vecmag <= zeroThreshold) {
        angle = 0;
        u = [0, 0, 0];
    } else {
        angle = vecmag-offset;
        u = [o[0]/vecmag, o[1]/vecmag, o[2]/vecmag];
    }
    return [u, angle];
}

function orientation_to_matrix(ovec, offset) {
    // From bmoc/bgeom.elp
    const [unitv, rawAngle] = decomposeOrientation(ovec, offset);
    if (rawAngle === 0) { // exact compare ok here
        return [[1, 0, 0], [0, 1, 0], [0, 0, 1]];
    }

    // Components of a unit direction vector
    const [x, y, z] = unitv;

    // normalize angle
    const angle = rawAngle % (Math.PI*2);

    let c = Math.cos(angle);
    let s = Math.sqrt(1 - c*c); // see below for sign
    const t = 1 - c;

    // Enforce sign consistency.
    if (Math.abs(angle - Math.PI) <= zeroThreshold) {
        c = -1;
        s = 0;
    } else if (angle > Math.PI) s = -s;

    return [[t*x*x+c, t*x*y-s*z, t*x*z+s*y],
        [t*x*y+s*z, t*y*y+c, t*y*z-s*x],
        [t*x*z-s*y, t*y*z+s*x, t*z*z+c]];
}

export function parseInfoMsg(dataIn) {
    const data = dataIn.trim();
    // Format is "<cmd>[ <args>], <msg>"
    //--- This is naive parsing.

    // This regex emits captured groups: 1) cmd, 2) msg if no args, 3) args+msg.
    // Only one of (2) or (3) should be populated. (?: ... ) indicates a non-capturing group.
    // We don't have enough information at this time to parse the args from the message,
    // because special treatment is necessary if the args are json.
    // Groups with their regex:
    //   cmd             ([\w-]+)        - cmd contains word characters or dashes
    //   argsAndMsg      (?: ([.\S\s]*)) - everything following a space, including newlines
    //   msgWithoutArgs  (?:, (.*))      - everything
    const regex = /([\w-]+)(?:(?: ([\S\s]*))|(?:, ([\S\s]*)))?/m;
    const [ignoreWhole, cmd, argsAndMsg='', msgWithoutArgs=''] = data.match(regex);
    let [args, message] = ['', ''];

    // Easy case, no args to parse
    if (msgWithoutArgs) {
        message = msgWithoutArgs;
    } else if (argsAndMsg) {
        // Check for JSON args
        const extractedJson = extractInitialJson(argsAndMsg);
        if (extractedJson) {
            [args, message] = extractedJson;
        } else {
            // If we don't have json we split at the first comma
            // Example: cmd-name args args, error message
            const separatorIdx = argsAndMsg.indexOf(',');
            if (separatorIdx > -1) {
                args = argsAndMsg.substr(0, separatorIdx).trim();
                message = argsAndMsg.substr(separatorIdx+1).trim();
            } else {
                // If no comma (this shouldn't happen), just treat them all as args
                // Example: cmd-name words words words
                args = argsAndMsg;
            }
        }
    }

    return [cmd, args, message];
}

/** @function extractInitialJson
 * @param text A string which begins with valid JSON but might contain more text after.
 * @description Extact a json string embedded at the start of a larger string..
 * @todo This currently requires that the text start with JSON. It could be generalized.
 */
function extractInitialJson(text) {
    let bracketCount = 0;
    let quoteCount = 0;
    let jsonEnd = 0;
    let result = null;

    // Quit right away if it doesn't start with a curly
    if (text[0] !== '{') {
        return result;
    }

    for (let i = 0; i < text.length; i++) {
        const c = text[i];
        const evenQuote = quoteCount % 2 === 0;

        switch (c) {
            case '{':
                if (evenQuote) {
                    bracketCount++;
                }
                break;
            case '}':
                if (evenQuote) {
                    bracketCount--;
                }
                break;
            case '"':
                if (evenQuote) {
                    quoteCount++;
                } else if (!evenQuote && text[i-1] !== '\\') {
                    quoteCount++;
                } else {
                    // Do nothing if the quot is preceded by backslash
                }
                break;
            default:
                // do nothing
        }

        // The first first bracket will increment, so it is safe to check for 0 here.
        if (bracketCount === 0) {
            // Finished!
            jsonEnd = i;
            break;
        }
    }

    if (jsonEnd > 0) {
        const argsJson = text.substring(0, jsonEnd+1);
        const msg = text.substring(jsonEnd+2).trim();
        try {
            JSON.parse(argsJson); // Make sure it is valid JSON
            result = [argsJson, msg];
        } catch (ex) {
            // Don't have to do anything here, it will return null below
        }
    }

    return result;
}
