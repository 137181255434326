/**
 * @typedef {import('BMapsModel').CaseData} CaseData
 * @typedef {import('BMapsSrc/utils').MoleculeLoadOptions} MoleculeLoadOptions
 */

import {
    AtomGroupFactory, Fragment, DecodedFragment, Compound, CompoundHeritage,
} from 'BMapsModel';
import { disallowIfDisconnected } from 'BMapsSrc/server/session_utils';
import { setTargetInfo } from 'BMapsSrc/redux/projectState/access';
import { floatEquals } from 'BMapsSrc/math';
import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { ResponseIds } from '../server/server_common';
import { UserCmd, UserActions } from './UserCmd';
import {
    MapCase, PdbImportCase, AlphaFoldImportCase, UserDataImportCase, FoldingDataImportCase,
} from '../model/MapCase';
import { MolDataSource, showAlert } from '../utils';
import { getFullResidueId } from '../util/mol_info_utils';
import {
    integrateCompounds, integrateEnergies, integrateFragments, setSoluteForComponent,
} from './cmds_common';
import { pointDistance } from '../util/atom_distance_utils';
import { defaultClashingTolerance, getClashingDistance } from '../util/clash_detection';
import { getPreferences } from '../redux/prefs/access';
import { ensureArray } from '../util/js_utils';
import { withPausedRedisplay } from '../util/display_utils';

/**
 * @typedef ConnectedDataCmds
 * @type {object}
 * @property {zapAll} ZapAll
 * @property {zapConnection} ZapConnection
 * @property {fetchMaps} FetchMaps
 * @property {fetchConnectionMaps} FetchConnectionMaps
 * @property {chooseProtein} ChooseProtein
 * @property {loadMolecule} LoadMolecule
 * @property {loadMolData} LoadMolData
 * @property {selectCmd} Select
 * @property {selectAtom} SelectAtom
 * @property {exportToFormat} ExportToFormat
 * @property {exportQueryToFormat} ExportQueryToFormat
 * @property {exportCompoundsToFormat} ExportCompoundsToFormat
 * @property {exportSelectionToFormat} ExportSelectionToFormat
 * @property {loadStarterCompounds} LoadStarterCompounds
 * @property {removeCompound} RemoveCompound
 * @property {renameCompound} RenameCompound
 * @property {copyCompound} CopyCompound
 * @property {removeConnection} RemoveConnection
 * @property {alignProtein} AlignProtein
 * @property {setProteinTarget} SetProteinTarget
 * @property {callProteinFolding} CallProteinFolding
 */

/** @type {ConnectedDataCmds} */
export const ConnectedDataCmds = {
    ZapAll: new UserCmd('ZapAll', zapAll),
    ZapConnection: new UserCmd('ZapConnection', zapConnection),
    FetchMaps: new UserCmd('FetchMaps', fetchMaps),
    FetchConnectionMaps: new UserCmd('FetchConnectionMaps', fetchConnectionMaps),
    ChooseProtein: new UserCmd('ChooseProtein', chooseProtein),
    LoadMolecule: new UserCmd('LoadMolecule', loadMolecule),
    LoadMolData: new UserCmd('LoadMolData', loadMolData),
    AssembleCompound: new UserCmd('AssembleCompound', assembleCompound),
    Select: new UserCmd('Select', selectCmd),
    SelectAtom: new UserCmd('SelectAtom', selectAtom),
    ExportToFormat: new UserCmd('ExportToFormat', exportToFormat),
    ExportQueryToFormat: new UserCmd('ExportQueryToFormat', exportQueryToFormat),
    ExportCompoundsToFormat: new UserCmd('ExportCompoundsToFormat', exportCompoundsToFormat),
    ExportSelectionToFormat: new UserCmd('ExportSelectionToFormat', exportSelectionToFormat),
    LoadStarterCompounds: new UserCmd('LoadStarterCompounds', loadStarterCompounds),
    RemoveCompound: new UserCmd('RemoveCompound', removeCompound),
    RenameCompound: new UserCmd('RenameCompound', renameCompound),
    CopyCompound: new UserCmd('CopyCompound', copyCompound),
    RemoveConnection: new UserCmd('RemoveConnection', removeConnection),
    AlignProtein: new UserCmd('AlignProtein', alignProtein),
    SetProteinTarget: new UserCmd('SetProteinTarget', setProteinTarget),
    CallProteinFolding: new UserCmd('CallProteinFolding', callProteinFolding),
};

/**
 * @param {MapCase} mapCase
 * @param {{ isTarget: boolean }} targetInfo
 */
function setProteinTarget(mapCase, targetInfo={}) {
    setTargetInfo(mapCase, targetInfo);
    App.Workspace.rebuildProteinTree(true);
}

async function zapAll(usePromise=true) {
    const [primary, ...otherDataConnections] = App.DataConnections;
    await Promise.all([
        zapConnection(primary, usePromise),
        ...(otherDataConnections.map((dc) => App.ConnectionManager.removeDataConnection(dc))),
    ]);
    App.Workspace.zap();
}

function zapConnection(dataConnection, usePromise=true) {
    return dataConnection.zap(usePromise);
}

async function fetchMaps() {
    const mapLists = await Promise.all(
        App.DataConnections.map((dc) => fetchConnectionMaps(dc))
    );
    return mapLists.reduce((acc, next) => acc.concat(next), []);
}

async function fetchConnectionMaps(dataConnection) {
    const { connector, caseDataCollection } = dataConnection.get();
    const responseData = await connector.cmdListMaps();
    const allMapData = responseData.byId(ResponseIds.MapList);
    for (const mapData of allMapData) {
        caseDataCollection.loadMapData(mapData);
    }
    return caseDataCollection.getMapList();
}

async function callProteinFolding(foldingData, handlers, dataConnection=App.PrimaryDataConnection) {
    const { foldingProgramId, foldingArgs } = foldingData;
    const { connector } = dataConnection.get();
    const responder = connector.cmdFoldProtein({
        foldingProgram: foldingProgramId,
        foldingArgs,
    });
    const responseData = await responder.handleResponses(handlers);
    const errors = responseData.errors;
    const results = responseData.byId(ResponseIds.FoldResults);
    // results is the list of all FoldResults packets received, each packet a list of fold results
    const firstPacket = results[0];
    const firstFoldResult = firstPacket?.[0];

    if (!firstFoldResult && errors.length === 0) {
        errors.push('No data returned by the folding service, which usually indicates a failure to load the molecules. Please double-check your inputs (especially ligands) and try again.');
    }

    if (results.length > 1 || firstPacket?.length > 1) {
        console.warn("I don't yet know how to deal with multiple fold results at once. Just using the first one.");
    }

    return {
        data: firstFoldResult?.data,
        format: firstFoldResult?.format,
        errors,
    };
}

/**
 * @param {string | MapCase} caseInfoIn
 * @param {{
 *      keepExisting: boolean
 * }} loadOptions
 * @param {*} dataConnection
 * @returns {Promise<{
 *      errors: string[]
 *      warnings?: string[]
 *      mapCase?: MapCase
 *      caseData?: import('BMapsModel').CaseData
 *      energies?: energies
 * }>}
 */
async function chooseProtein(caseInfoIn, loadOptions={}, dataConnection=App.PrimaryDataConnection) {
    if (disallowIfDisconnected('Switching proteins', dataConnection.connector)) {
        return { errors: ['Action not allowed in disconnected session'] };
    }

    if (!loadOptions.keepExisting) {
        await zapAll();
    }

    /**
     * @type {import('BMapsModel').DataConnection}
     */
    const { connector, caseDataCollection } = dataConnection.get();
    let caseInfo = caseInfoIn;

    if (typeof (caseInfoIn) === 'string') {
        const uri = caseInfoIn;
        caseInfo = caseDataCollection.findMapByUri(uri);

        if (!caseInfo) {
            if (PdbImportCase.isUri(uri)) {
                caseInfo = new PdbImportCase(PdbImportCase.parseUri(uri).id);
            } else if (AlphaFoldImportCase.isUri(uri)) {
                caseInfo = new AlphaFoldImportCase(AlphaFoldImportCase.parseUri(uri).id);
            }
        }
    }

    if (caseInfo == null) {
        const msg = 'Map case not found';
        return { errors: [msg] };
    }

    const uri = caseInfo.uri;
    const loadOptionsForServer = serverLoadOptions(caseInfo, loadOptions);
    let caseHasFrags = false;
    let responseData;
    if (caseInfo instanceof PdbImportCase || caseInfo instanceof AlphaFoldImportCase) {
        responseData = await withPausedRedisplay(
            connector.cmdLoadPdbId({
                pdbId: caseInfo.pdbID,
                loadOptions: loadOptionsForServer,
            }),
        );
    } else if (caseInfo instanceof UserDataImportCase) {
        responseData = await withPausedRedisplay(connector.cmdLoadPdbString({
            data: caseInfo.data,
            pdbId: caseInfo.pdbID,
            moleculeName: caseInfo.molecule_name,
            fileType: caseInfo.data_format,
            loadOptions: loadOptionsForServer,
        }));
    } else if (caseInfo instanceof FoldingDataImportCase) {
        responseData = await withPausedRedisplay(connector.cmdLoadPdbString({
            data: caseInfo.data,
            fileType: caseInfo.data_format,
            moleculeName: caseInfo.molecule_name,
            loadOptions: loadOptionsForServer,
        }));
    } else {
        caseHasFrags = true;
        responseData = await withPausedRedisplay(
            connector.cmdGetCaseFiles(uri, loadOptionsForServer)
        );
    }

    // Ensure that the mapCase object has a reference to the caseDataCollection.
    if (!caseInfo.getCaseDataCollection()) {
        caseInfo.setCaseDataCollection(caseDataCollection);
    }

    const result = handleProteinResponses(caseInfo, responseData);
    const { errors, caseData: loadedCaseData } = result;

    if (errors.length > 0) {
        console.log(`Errors loading protein ${caseInfo}: ${errors}`);
    }

    // Need to add the case to the map selector if we don't already have it
    if (errors.length === 0 && !caseDataCollection.findMapByCase(caseInfo)) {
        caseDataCollection.addMapCase(caseInfo);
    }

    App.Workspace.rebuildProteinTree(false);
    EventBroker.publish('dataLoaded');
    EventBroker.publish('proteinLoaded', { mapCase: caseInfo, caseData: loadedCaseData });
    UserActions.RedrawScene({ forceProteinView: true, recenter: true });

    if (loadedCaseData) {
        if (caseHasFrags) {
            const needToLoadFrags = loadOptionsForServer.fragmentLoading === 'greedy'
                  && !connector.staticMode;

            if (loadOptions.waitForFragments) {
                await UserActions.RefreshFragmentInfo(caseInfo, needToLoadFrags);
            } else {
                UserActions.RefreshFragmentInfo(caseInfo, needToLoadFrags);
            }
        } else {
            await App.Workspace.refreshFragservInfo();
            App.Workspace.setAvailableFragments(caseInfo, []);
        }
    }

    const canAlignInPreviewMode = false;
    const canAlign = dataConnection.getMode() === 'server' || canAlignInPreviewMode;
    if (loadOptions.keepExisting && canAlign && loadedCaseData) {
        const refCaseData = App.Workspace.defaultAlignmentReferenceCaseData();
        await alignProtein({ refCaseData, alignCaseData: loadedCaseData });
        UserActions.RedrawScene({ forceProteinView: true, recenter: true });
        // Why reset display twice?
        // The reset above will show the new protein in its original coordinate system.
        // This one here will switched to the aligned coordinates.
        // For better or worse, this indicates visually that an alignment occurred.
    }
    return result;
}

/**
 * Align one protein to another. This does two things:
 * 1. Notes the reference protein on the aligned protein.
 * 2. Calculates the transform and assigns the "whole protein transform."
 *
 * Note: binding site transforms are handled separately. They make use of the reference protein
 * but calculate a local transform just for the binding site.
 * @param {CaseData} inboundCaseData
 */
async function alignProtein(options) {
    if (options.alignCaseData === options.refCaseData) return;

    const alignMatrix = await withPausedRedisplay(getAlignmentMatrix(options));
    if (alignMatrix) {
        const alignmentFailed = alignMatrix.rotation.flat().every((x) => floatEquals(x, 0));
        if (alignmentFailed) {
            const alignName = options?.alignCaseData.getName();
            const refName = options?.refCaseData.getName();
            console.warn(`Protein alignment failed for aligning ${alignName} to ${refName}`);
        } else {
            const { alignCaseData, refCaseData }= options;
            alignCaseData.setReferenceCaseData(refCaseData);
            alignCaseData.setWholeProteinTransform(alignMatrix);
            UserActions.RedrawScene({ recenter: true });
        }
    } else {
        console.error('No matrix reported after align-protein cmd');
    }
}

/**
 * Calculate an alignment transformation between two proteins.
 * @param {{
*      refCaseData: CaseData,
*      alignCaseData: CaseData,
*      refSpec: string,
*      alignSpec: string,
* }} param0
* @returns {Promise<{ translation: [number,number,number], rotation: [number,number,number][]}>}
*/
async function getAlignmentMatrix({
    refCaseData, alignCaseData, refSpec, alignSpec,
}) {
    const refSelection = refSpec || defaultAlignmentSelection(refCaseData);
    const alignSelection = alignSpec || defaultAlignmentSelection(alignCaseData);

    const { connector: refConnector } = App.getDataParents(refCaseData);
    const { data: pdbRef } = await UserActions.ExportQueryToFormat(refSelection, 'pdb', refCaseData);
    const { data: pdbAlign } = await UserActions.ExportQueryToFormat(alignSelection, 'pdb', alignCaseData);
    const reqObj = { pdbRef, pdbAlign };
    const responseData = await refConnector.cmdAlignProtein(reqObj);
    const [alignMatrix] = responseData.byId(ResponseIds.ProteinRotationMatrix);
    return alignMatrix?.result;
}

/**
* For default alignment, select the first chain.
*/
function defaultAlignmentSelection(caseData) {
    const firstChainAtomGroup = caseData.getProteinChains()[0];
    const chain = firstChainAtomGroup.chain;
    return `(protein or dna or rna) and chain ${chain}`;
}

/**
 * @description Convert client-side load options to server-side
 * @param string uri
 * @param {*} loadOptions
 */
function serverLoadOptions(caseInfo, loadOptions) {
    const args = {};
    const { FragmentLoading } = getPreferences();

    if (caseInfo instanceof PdbImportCase
        || caseInfo instanceof AlphaFoldImportCase
        || caseInfo instanceof UserDataImportCase
        || caseInfo instanceof FoldingDataImportCase) {
        // postprocessing: "just_load" | "just_hydrogens" | "load_and_type" | "all" | undefined
        // nameMappings, ligandSmiles: pass through as a string
        args.postprocessing = loadOptions.preserveHydrogens ? 'load_and_type' : 'all';
        args.nameMappings = loadOptions.nameMappings;
        args.ligandSmiles = loadOptions.ligandSmiles;
    }

    args.fragmentLoading = loadOptions.fragmentLoading || FragmentLoading;
    return args;
}

/**
 * Process various packets in the responseData in response to get-case-files or load-pdb-id
 * @param {MapCase} mapCase
 * @param {import('BMapsSrc/server_responders').ResponseData} responseData
 */
function handleProteinResponses(mapCase, responseData) {
    const [solute] = responseData.byId(ResponseIds.Solute);
    /** @type {string[]} */
    const errors = responseData.byId(ResponseIds.Error);
    /** @type {string[]} */
    const warnings = responseData.byId(ResponseIds.Warning);
    const energies = [];
    let caseData;

    if (solute) {
        const { caseDataCollection } = App.getDataParents(mapCase);
        // First handle protein, compounds, energies, pdb info
        const {
            atoms, bonds, helices, sheets,
        } = solute.atomData;
        caseData = App.Workspace.addProtein(mapCase, solute.allAtomGroups, solute.soluteName);
        caseDataCollection.addCaseData(caseData);
        integrateCompounds(responseData, caseData);
        AtomGroupFactory.PrintSummary(caseData.atomGroups);
        if (solute.compounds) {
            App.Workspace.activateFirstOf(solute.compounds);
            const MAX_COMPOUNDS_FOR_CLASH_DETECTION = 100;
            if (solute.compounds.length <= MAX_COMPOUNDS_FOR_CLASH_DETECTION) {
                reportClashingCompounds(solute.compounds, { alertParams: { dontAlert: true } });
            } else {
                console.log(`Skipping clash detection since ${solute.compounds.length} ligands > ${MAX_COMPOUNDS_FOR_CLASH_DETECTION}`);
            }
        }

        energies.push(...integrateEnergies(responseData, caseData));

        const [pdbInfo] = responseData.byId(ResponseIds.PdbInfo);
        if (pdbInfo) {
            applyPdbInfo(mapCase, pdbInfo);
        }

        integrateFragments(responseData, caseData);

        // Sample compounds
        const starterAvailability = responseData.byId(ResponseIds.StarterAvailability);
        if (starterAvailability.length > 0) {
            const availability = starterAvailability[0];
            if (availability.available) {
                caseData.getSampleCompoundInfo().setAvailable();
            }
        }
    } else {
        errors.push(`No solute data reported for protein case ${mapCase.projectCase || mapCase.displayName}`);
    }

    return {
        errors, warnings, energies, mapCase, caseData,
    };
}

function applyPdbInfo(caseInfo, pdbInfo) {
    // Update molecule name if it's empty or default.
    if (!caseInfo.molecule_name || caseInfo.hasDefaultMoleculeName()) {
        const pdbMolName = pdbInfo.molecule_name || pdbInfo.gene_name;
        // If the pdb data didn't have a name, use the default
        caseInfo.molecule_name = pdbMolName || caseInfo.molecule_name
            || UserDataImportCase.DefaultRootMolName;
    }
    if (!caseInfo.gene_name) caseInfo.gene_name = pdbInfo.gene_name;
    if (!caseInfo.description) caseInfo.description = pdbInfo.title;
}

// UserActions.LoadMolecule
/**
 * @param {string} molId
 * @param {string} molFormat
 * @param {string} molData
 * @param {MoleculeLoadOptions} moleculeLoadOptions
 * @param {CaseData} caseData
 */
async function loadMolecule(molId, molFormat, molData, moleculeLoadOptions,
    caseData=App.Workspace.firstCaseData()) {
    const source = new MolDataSource({ molFormat, molData });
    if (molId) source.compoundName = molId;
    return loadMolData(source, moleculeLoadOptions, caseData);
}

/**
 * @param {MolDataSource} molSource
 * @param {MoleculeLoadOptions} moleculeLoadOptions
 * @param {CaseData} caseData
 * @returns
 */
async function loadMolData(molSource, moleculeLoadOptions, caseData=App.Workspace.firstCaseData()) {
    const { MaxMolecules } = getPreferences();
    const { connector } = await setSoluteForComponent(caseData);
    molSource.maxMolecules = MaxMolecules || 100;
    const responseData = await connector.cmdLoadMolData(molSource, moleculeLoadOptions);
    /** @type {Compound[]} */
    const loadedCompounds = responseData.byId(ResponseIds.Compound);
    /** @type {string[]} */
    const loadErrors = responseData.byId(ResponseIds.Error);
    /** @type {string[]} */
    const loadWarnings = responseData.byId(ResponseIds.Warning);
    App.Workspace.addReplaceCompounds(loadedCompounds, caseData);
    integrateCompounds(responseData, caseData);
    if (!moleculeLoadOptions.isPlaceOutside()) {
        handleClashingCompounds(loadedCompounds);
    }
    App.Workspace.activateFirstOf(loadedCompounds);
    let heritageParent = null;
    if (molSource.sourceCmpds) {
        // Copy To Protein
        const sourceCmpds = [...molSource.sourceCmpds];
        for (const cmpd of loadedCompounds) {
            const sourceIndex = sourceCmpds.findIndex((srcC) => Compound.matches3d(srcC, cmpd));
            if (sourceIndex < 0) {
                console.warn(`Did not find a match for loaded cmpd ${cmpd.resSpec}`);
                continue;
            }
            const [sourceCmpd] = sourceCmpds.splice(sourceIndex, 1);
            heritageParent = sourceCmpd;
            cmpd.copyScopedPropertiesFrom(sourceCmpd, {
                skipPerCompoundTarget: cmpd.caseData !== sourceCmpd.caseData,
                operation: cmpd.caseData !== sourceCmpd.caseData ? 'copy to protein' : 'duplicate',
            });
        }
    } else if (molSource.modifiedFrom) {
        heritageParent = caseData.getCompoundBySpec(molSource.modifiedFrom);
    }
    CompoundHeritage.addMolSource(heritageParent, loadedCompounds, molSource);
    return { compounds: loadedCompounds, errors: loadErrors, warnings: loadWarnings };
}

/**
 * Invoke assemble-compound bfd-server API
 */
async function assembleCompound(modificationsIn, caseData) {
    const modifications = Array.isArray(modificationsIn) ? modificationsIn : [modificationsIn];
    const { connector } = App.getDataParents(caseData);
    const assembleSpec = getAssembleCompoundSpec(modifications);
    const responseData = await connector.cmdAssembleCompound(assembleSpec);
    const loadedCompounds = responseData.byId(ResponseIds.Compound);
    const loadErrors = responseData.byId(ResponseIds.Error);
    const loadWarnings = responseData.byId(ResponseIds.Warning);
    App.Workspace.addReplaceCompounds(loadedCompounds, caseData);
    integrateCompounds(responseData, caseData);
    handleClashingCompounds(loadedCompounds);
    App.Workspace.activateFirstOf(loadedCompounds);
    // TODO: add mol heritage
    return { compounds: loadedCompounds, errors: loadErrors, warnings: loadWarnings };
}

/**
 * Return json spec for a simple molecule description.
 */
function getAssembleCompoundMolDesc(mol) {
    let name = mol.resSpec;
    if (mol instanceof DecodedFragment) name = mol.baseFrag.name;
    else if (mol instanceof Fragment) name = mol.fragmentName;

    const ret = {
        name,
        atoms: mol.getAtoms().map((atom) => ({
            atom: atom.atom,
            // We need to send coordinates in the same reference as the source.
            // "use original" to undo any transformation resulting from an aligned protein
            pos: atom.getPosition({ useOriginal: true }),
        })),
        bonds: mol.getBonds().map(({ atom1, atom2, orderOriginal }) => ({
            fromAtom: atom1.atom, toAtom: atom2.atom, order: orderOriginal,
        })),
    };
    return ret;
}

/**
 * Return a modification spec for AssembleCompound.
 * modMol is the fragment
 * srcMol is the compound to merge the fragment into
 * modInfo contains type, and atom names:
 *   srcAttachAtom
 *   srcDirectionAtom
 *   modAttachAtom
 *   modDirectionAtom
 */
function getAssembleCompoundSpec(modifications) {
    const assemblies = modifications.map(({ srcMol, modMol, modInfo }) => ({
        srcMolecule: srcMol ? getAssembleCompoundMolDesc(srcMol) : null,
        modification: {
            modMolecule: getAssembleCompoundMolDesc(modMol),
            ...modInfo, // type, srcAttachAtom,srcDirectionAtom, modAttachAtom, modDirectionAtom
        },
    }));
    return { assemblies };
}

function handleClashingCompounds(compounds) {
    return reportClashingCompounds(compounds, {
        alertParams: {
            singularNoun: 'imported compound',
            pluralNoun: 'imported compounds',
            some: 'Some',
        },
    });
}
/**
 * @description Check a compound or an array of compounds for steric clashes, and
 * show an alert and log the clashes.
 * Updates energy info for the clashing compounds
 * @param {Compound|Compound[]} compounds
 * @param { {
 *   clashOptions: {
 *     tolerance: number, includeH: boolean, reportAll: boolean
 *   },
 *   alertParams: {
 *     singularNoun: string, pluralNoun: string, some: string, alertTitle: string
 *   },
 * }} options Configuration for the clash detection and the alert text
 * @return { Compound[] } Array of the clashing compounds
 */
export function reportClashingCompounds(compounds, { clashOptions: options, alertParams }={}) {
    const { compoundMap, clashOptions } = App.Workspace.detectClashes(compounds, options);
    const clashingCmpds = [...compoundMap.keys()];
    if (clashingCmpds.length === 0) {
        return clashingCmpds;
    }

    clashAlert(clashingCmpds, compounds, alertParams);
    console.log(getExtraInfoOnClashing(compoundMap, clashOptions));

    // Set warning in Energy Table
    clashingCmpds.forEach((c) => c.energyInfo.setClashing());
    EventBroker.publish('energyCalc');
    return clashingCmpds;
}

/**
 * @description Display clash alert to user
 * @param {Compound[]} clashingCmpds the compounds that are clashing with the protein
 * @param {Compound[]} allCmpds all compounds that were checked for clashes
 * @param { {
 *   singularNoun: string, pluralNoun: string, some: string, alertTitle: string
 *   }} alertParams Configuration for the alert text
 */
function clashAlert(clashingCmpds, allCmpds, alertParams={}) {
    const {
        singularNoun='compound',
        pluralNoun='compounds',
        some='Some of the',
        alertTitle='Steric Clash',
        dontAlert=false,
    } = alertParams;
    if (dontAlert) return;
    const clashingNames = clashingCmpds.map((cmpd) => cmpd.resSpec);
    let msgAlert;
    if (clashingCmpds.length > 1) {
        msgAlert = `${some} ${pluralNoun} are clashing with the protein. You may need to dock them to get favorable energy scores.\n\nClashing compounds: ${clashingNames.join(', ')}`;
    } else if (allCmpds.length === 1) {
        msgAlert = `The ${singularNoun} is clashing with the protein. You may need to dock it to get a favorable energy score.\n\nClashing compound: ${clashingNames.join(', ')}`;
    } else {
        msgAlert = `One of the ${pluralNoun} is clashing with the protein. You may need to dock it to get a favorable energy score.\n\nClashing compound: ${clashingNames.join(', ')}`;
    }
    showAlert(msgAlert, alertTitle);
}

/**
 * Takes array of clashingCmpds and returns a string of the name of the compound(s) and
 * first atoms that are clashing for each compound
 * @param {Compound[]} clashingCmpds
 * @returns String
 */
function getExtraInfoOnClashing(clashReport, { tolerance=defaultClashingTolerance }={}) {
    const clashingExtraInfo = [];
    for (const [cmpd, clashingAtoms] of clashReport) {
        const atomResMaps = new Map(); // Map<atom1, Map<resSpec:string, atomNames:string[]>>
        for (const { atom1, atom2 } of clashingAtoms) {
            const resSpec = getFullResidueId(atom2);
            if (!atomResMaps.get(atom1)) atomResMaps.set(atom1, new Map()); // ensure entry exists
            const resMap = atomResMaps.get(atom1);
            if (!resMap.get(resSpec)) resMap.set(resSpec, []); // ensure entry exists
            const atomNames = resMap.get(resSpec);
            const distance = pointDistance(atom1, atom2);
            const clashDistance = getClashingDistance(atom1, atom2, tolerance);
            atomNames.push(`${atom2.atom} (${atom2.amber}) ${distance.toFixed(5)} < ${clashDistance.toFixed(5)}`);
        }
        let clashingAtomMsg = '';
        for (const [atom1, resMap] of atomResMaps.entries()) {
            for (const [resSpec, atomNames] of resMap.entries()) {
                clashingAtomMsg += `    ${atom1.atom} (${atom1.amber}) with ${resSpec}: ${atomNames.join('; ')}\n`;
            }
        }
        clashingExtraInfo.push(`Clashing Compound: ${cmpd.resSpec}, Clashing Atoms (clash distances include tolerance of ${tolerance}:\n${clashingAtomMsg}`);
    }
    return `${clashingExtraInfo.join('')}`;
}

async function doSelectAtom(atom, args) {
    if (atom) {
        const { connector } = await setSoluteForComponent(atom);
        await connector.cmdSelectAtom(atom, args);
        return;
    }

    // Null atom = background click. Deselect for all connections / caseDatas
    await Promise.all(
        App.Workspace.allCaseData().map(async (caseData) => {
            const { connector } = await setSoluteForComponent(caseData);
            await connector.cmdSelectAtom();
        })
    );
}

async function selectAtom(atomIn, args) {
    const [first, ...rest] = ensureArray(atomIn);
    await doSelectAtom(first, args);

    // Server API doesn't support selecting multiple atoms, so we achieve this by setting the
    // control key for every atom after the first. But need to make sure they aren't already
    // selected, since the ctrl key will make the attempt to select them actually deselect!
    if (rest.length > 0) {
        const restArgs = args ? { ...args } : {};
        restArgs.ctrlKey = true;

        await withPausedRedisplay((async () => {
            // The shift key may cause other atoms to become selected, so check each atom's
            // selection state before sending the select cmd, avoiding accidental deselection.
            for (const atom of rest) {
                if (!App.Workspace.isSelectedAtom(atom)) {
                    await doSelectAtom(atom, restArgs);
                }
            }
        })()); // IIFE
    }
    postSelection();
}

/**
 * @param {string} query
 * @param {CaseData} [caseData]
 */
async function selectCmd(query, caseData=null) {
    const { connector } = await setSoluteForComponent(caseData);
    await connector.cmdSelect(query);
    postSelection();
}

function postSelection() {
    EventBroker.publish('selectionDisplay', App.Workspace.getSelectedAtoms());
    EventBroker.publish('refreshAtomDisplay');
    EventBroker.publish('redisplayRequest');
}

async function exportCompoundsToFormat(compoundsIn, format) {
    const compounds = [].concat(compoundsIn); // force array
    const result = { data: '', error: '' };
    function addError(error) { result.error += `${result.error ? '; ' : ''}${error}`; }

    switch (format) {
        case 'mol':
            result.data = compounds[0] ? compounds[0].getMol2000() : '';
            return result;
        case 'sdf':
            result.data = Compound.makeSDF(compounds);
            return result;
        case 'smi':
            result.data = compounds.map((c) => c.getSmiles()).join('\n');
            return result;
        case 'smiNoName':
            result.data = compounds.map((c) => c.getUnnamedSmiles()).join('\n');
            return result;
        case 'inchikey':
        case 'inchi':
            if (compounds.every((c) => c.getProperty('structure2d', format))) {
                result.data = compounds.map((c) => c.getProperty('structure2d', format)).join('\n');
                return result;
            }
            // fallthrough to call server if inchikey isn't already available locally.
        default:
            // passthrough
    }

    const allowedMultiProteinFormats = ['sdf', 'smi', 'csv', 'mol2', 'xyz'];

    const { dataParentsMap, dataParentsList } = App.partitionByDataParents(compounds);
    if (dataParentsList.length > 1 && !allowedMultiProteinFormats.includes(format)) {
        addError(`Compounds from multiple proteins cannot be exported to format ${format}.`);
        return result;
    }

    for (const [{ caseData }, cmpds] of dataParentsMap.entries()) {
        const query = cmpds.map((c) => c.selectQuery).join(' or ');
        const { data, error } = await exportQueryToFormat(query, format, caseData);
        if (data) result.data += data;
        if (error) addError(error);
    }

    return result;
}

async function exportSelectionToFormat(formatIn, caseData) {
    const { connector } = await setSoluteForComponent(caseData);
    const smiNoNameSupported = connector.getMode() === 'static';
    let format = formatIn;
    if (format === 'smiNoName' && !smiNoNameSupported) format = 'smi';

    const result = { data: null, error: null };

    try {
        result.data = await connector.cmdExportSelection(format);
    } catch (err) {
        result.error = err;
    }

    return result;
}

/**
 * @param {string} query
 * @param {'mol'|'smi'|'smiNoName'|'sdf'|'xyz'|'csv'} format
 * @param {CaseData} [caseData]
 * @returns {Promise<{data: string, error: string?}>}
 */
async function exportQueryToFormat(query, format, caseData) {
    let result = { data: null, error: null };
    const needToSelect = (query && query !== 'selection');
    try {
        if (needToSelect) {
            await UserActions.Select(query, caseData);
        }

        result = await exportSelectionToFormat(format, caseData);
    } catch (err) {
        result.error = err;
    }

    if (needToSelect) {
        await UserActions.Select('none', caseData);
    }
    return result;
}

/**
 * Export atoms to format.  The atoms have to already have been selected.
 */
async function exportSelectedAtomsToFormat(atoms, format) {
    if (atoms.length === 0) {
        return { error: 'No atoms available for export' };
    }

    const { caseData } = App.getDataParents(atoms[0]);
    if (!atoms.every((atom) => App.getDataParents(atom).caseData === caseData)) {
        return { error: 'Selected atoms from only one protein can be exported. Try selecting only atoms on one protein.' };
    }

    return exportSelectionToFormat(format, caseData);
}

/**
 * Export the given spec (compounds, atoms, or query string) to the given format
 * @param {{ compounds: Compound[]?, atoms: Atom[]?, query: string? }} exportSpec
 * @param {string} format
 * @param {CaseData} [caseData]
 */
async function exportToFormat(exportSpec, format, caseData) {
    const { compounds=[], atoms=[], query } = exportSpec || {};
    if (compounds.length > 0) {
        return exportCompoundsToFormat(compounds, format);
    } else if (atoms.length > 0) {
        return exportSelectedAtomsToFormat(atoms, format);
    } else if (query) {
        return exportQueryToFormat(query, format, caseData);
    } else {
        return { error: 'Nothing specified for export' };
    }
}

async function loadStarterCompounds(caseData) {
    const { connector } = await setSoluteForComponent(caseData);
    const responseData = await connector.cmdLoadStarterCompounds();
    const loadedCompounds = responseData.byId(ResponseIds.Compound);
    const loadErrors = responseData.byId(ResponseIds.Error);
    App.Workspace.addReplaceCompounds(loadedCompounds, caseData);
    integrateCompounds(responseData, caseData);
    integrateEnergies(responseData, caseData);
    handleClashingCompounds(loadedCompounds);
    App.Workspace.activateFirstOf(loadedCompounds);
    caseData.getSampleCompoundInfo().setLoaded();
    CompoundHeritage.addStarterCompounds(loadedCompounds, PdbImportCase.isValidId);
    return { compounds: loadedCompounds, errors: loadErrors };
}

async function removeCompound(compound) {
    const { connector } = await setSoluteForComponent(compound);
    App.Workspace.removeCompound(compound);
    await connector.cmdRemoveCompound(compound);
}

async function renameCompound(compound, newName) {
    const { connector } = await setSoluteForComponent(compound);
    const responseData = await connector.cmdRenameCompound(compound, newName);
    if (responseData.hasError()) {
        console.log(`Failed to rename compound: ${responseData.errors.join('; ')}`);
        const error = responseData.errors.join(';');
        if (error.includes('rename-compound command attempted to rename to an existing compound')) {
            showAlert(`Failed to rename compound: ${newName} is already the name of another compound`);
        } else {
            showAlert('There was a problem renaming the compound');
        }
    } else {
        App.Workspace.renameCompound(compound, newName);
    }
}

async function copyCompound(compound) {
    const { connector, caseData } = await setSoluteForComponent(compound);
    const responseData = await connector.cmdCopyCompound(compound);
    if (responseData.hasError()) {
        console.log(`Failed to copy compound: ${responseData.errors.join('; ')}`);
    } else {
        const compounds = responseData.byId(ResponseIds.Compound);
        App.Workspace.addReplaceCompounds(compounds, caseData);
        integrateCompounds(responseData, caseData);
        for (const c of compounds) {
            console.log(`Copied compound: ${compound.resSpec} -> ${c.resSpec}`);
            c.copyScopedPropertiesFrom(compound, { operation: 'duplicate' });
        }
        App.Workspace.activateFirstOf(compounds);
        CompoundHeritage.addDuplicate(compound, compounds);
    }
}

async function removeConnection(connector) {
    if (App.DataConnections.length === 1) {
        // Primary data connection should be zapped instead of removed
        await zapAll();
        return;
    }
    await App.ConnectionManager.removeConnector(connector);
}
