import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setCompoundTableState, setSidePanelWidth } from 'BMapsSrc/redux/projectState/access';
import { useColorSchemeInfo } from 'BMapsSrc/redux/prefs/access';
import { EventBroker } from 'BMapsSrc/eventbroker';
import { CompoundTable } from './CompoundTable';
import { SidePanelContainer } from '../info_display/SidePanel';

export function CompoundTableSidePanel({ open, handleClose }) {
    const { columnVisibilityModel } = useSelector((state) => state.projectState.compoundTableState);
    const sidePanelWidth = useSelector((state) => state.projectState.sidePanelWidth);
    const { textCss } = useColorSchemeInfo();

    const [width, setWidth] = useState(sidePanelWidth);
    const [resizing, setResizing] = useState(false);

    function onMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();
        setResizing(true);
    }

    function onMouseMove(e) {
        if (!resizing) return;
        e.preventDefault();
        e.stopPropagation();
        const newWidth = e.clientX - 10;
        if (newWidth > 20) {
            setWidth(newWidth);
        }
    }

    useEffect(() => {
        function onMouseUp() {
            setResizing(false);
            EventBroker.publish('resize');
        }
        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, []);

    useEffect(() => {
        if (resizing) return;
        setSidePanelWidth(width);
        EventBroker.publish('resize');
    }, [resizing]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <SidePanelContainer
                open={open}
                handleClose={handleClose}
                title="Compound Table"
                width={width}
                contentStyle={{
                    padding: '0.5rem',
                }}
            >
                <CompoundTable
                    useProteinProperties
                    useSdfProperties
                    toolBarId="CompoundSidePanelTable"
                    setColumnVisibilityModel={(visibilityModel) => {
                        setCompoundTableState({ columnVisibilityModel: visibilityModel });
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                />
            </SidePanelContainer>
            <div
                role="toolbar"
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseMove}
                style={{
                    cursor: 'ew-resize',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginRight: '.5rem',
                }}
            >
                <i className="fa fa-arrows-h" style={{ color: textCss }} />
            </div>
        </div>
    );
}
