import { EventBroker } from 'BMapsSrc/eventbroker';
import { UserActions } from 'BMapsCmds';
import { App } from '../BMapsApp';
import DataConnection from '../DataConnection';
import { ServerConnection } from './ServerConnection';
import { StaticDataConnection } from './StaticDataConnection';
import { isDisconnectedSession, SessionErrorType } from './session_utils';
import { LoadingScreen } from '../LoadingScreen';

export async function getSelectivityDataConnection() {
    const unused = App.ConnectionManager.getUnusedDataConnection(ServerConnection);
    if (unused) return { dataConnection: unused };

    if (App.PrimaryDataConnection.getMode() === 'static') {
        const { dataConnection } = await App.ConnectionManager.newStaticConnection();
        return { dataConnection };
    }

    const { dataConnection, errorInfo } = await LoadingScreen.withLoadingScreen(
        App.ConnectionManager.newServerConnection(), 'Establishing a Selectivity Session...'
    );

    if (dataConnection) {
        return { dataConnection };
    }

    // Couldn't get server connection so maybe get static connection
    const { errType } = errorInfo;
    let alert = '';
    switch (errType) {
        case SessionErrorType.CantStart_SessionLimitReached:
            alert = 'You have no compute resources available for selectivity';
            break;
        case SessionErrorType.CantStart_ServerUnavailable:
            alert = 'There are no compute resources available for selectivity';
            break;
        case SessionErrorType.CantStart_NotLoggedIn:
            // User must be in static / Preview mode. No need to alert.
            alert = null;
            break;
        default:
            alert = 'There was a problem establishing a selectivity session';
    }
    if (alert) {
        alert += ', but an additional structure can be loaded for visualization only.';
    }

    return { failureReason: alert };
}

export async function getNewStaticDataConnection() {
    return (await App.ConnectionManager.newStaticConnection()).dataConnection;
}

export async function releaseUnusedConnections() {
    let unused = App.ConnectionManager.getUnusedDataConnection(ServerConnection);
    if (unused && unused !== App.PrimaryDataConnection) {
        await App.ConnectionManager.removeDataConnection(unused);
    }
    unused = App.ConnectionManager.getUnusedDataConnection(StaticDataConnection);
    if (unused && unused !== App.PrimaryDataConnection) {
        await App.ConnectionManager.removeDataConnection(unused);
    }
}

/**
 * Save state, zap all connections, create new connection, and restore state.
 * @param {bool} kickOutOldSession - If true, close an existing session to make room for the new one
 */
export async function reconnectAndRestoreSession(kickOutOldSession) {
    const msg = 'Reestablishing Session...';

    let state;
    try {
        state = UserActions.CaptureState();
    } catch (ex) {
        console.error(`Failed to capture state when retrying session: ${ex}`);
    }

    // Remove all connections
    await LoadingScreen.withLoadingScreen(App.ConnectionManager.removeAllConnections(), msg);
    App.Workspace.zap();

    // Start a new connection
    const { errorInfo } = await LoadingScreen.withLoadingScreen(
        App.ConnectionManager.newServerConnection(kickOutOldSession, false), msg
    );
    if (errorInfo) {
        EventBroker.publish('sessionError', errorInfo);
    } else if (state) {
        await UserActions.RestoreState(state);
    }
}
