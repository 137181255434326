import { useCallback, useEffect, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { DockingReference, getDockingReferences } from '../../../data_tools';
import Select from '../../common/Select';
import { IncrementDecrementInput } from '../../common/IncrementDecrementInput';
import { pointsCentroid } from '../../../util/atom_distance_utils';
import { withTooltip } from '../../common/helpers';
import { App } from '../../../BMapsApp';
import { showAlert } from '../../../utils';
import TooltipInfo from '../../common/TooltipInfo';

export function BasicTextField({
    labelText, id, value, onChange, disabled, required=true, title,
}) {
    return (
        <TextField
            fullWidth
            variant="outlined"
            label={labelText}
            id={id}
            style={{ margin: '10px 0 0 0' }}
            value={value}
            onChange={(evt) => onChange(evt)}
            disabled={disabled}
            required={required}
            title={title}
        />
    );
}

export const TextInputWithTooltip = withTooltip(BasicTextField);

/**
 * BindingSiteSpecs - A component to specify binding site specs for bmoc simulation.
 * This  a picker for to calculate the centroid of a reference object
 * or a text input for directly specifying.
 */
export function BindingSiteSpecs({
    setValue,
    selectionAlreadyUsed=false,
    disabled=false,
}) {
    const refList = getDockingReferences(App.Workspace, { includeCompounds: true });
    const [open, setOpen] = useState(false);
    const [bindingSiteRadius, setBindingSiteRadius] = useState(15);
    const [inputType, setInputType] = useState('picker');
    const [inputSpecs, setInputSpecs] = useState('');
    const [selectedRef, setSelectedRef] = useState(DockingReference.UNSPECIFIED_ID);

    const myRef = refList.find((r) => r.id === selectedRef);

    useEffect(() => {
        if (selectionAlreadyUsed && myRef?.type === DockingReference.Types.selected) {
            setInputSpecs('');
            setBindingSiteRadius(15);
            setSelectedRef(DockingReference.UNSPECIFIED_ID);
            setValue('');
            showAlert('Warning: binding site specs have been removed because the selected atoms are now being used to define the simulation protein.');
        }
    }, [selectionAlreadyUsed, setValue, selectedRef]);

    const updateText = useCallback((reference, radius) => {
        const centroid = pointsCentroid(reference.atoms);
        const radiusAsNumber = Number(radius);
        // Tracking inputSpecs and value separately allow us to preserve full precision for value
        setInputSpecs(`${radiusAsNumber} center ${centroid.map((value) => value.toFixed(2)).join(' ')}`);
        setValue(`${radiusAsNumber} center ${centroid.join(' ')}`);
    }, [setValue]);

    const handleFormchange = (action, payload={}) => {
        switch (action) {
            case 'close':
                setOpen(false);
                setValue('');
                break;
            case 'useTextinput':
                setInputType('textinput');
                setValue(inputSpecs);
                break;
            case 'usePicker': {
                setInputType('picker');
                if (selectedRef === DockingReference.UNSPECIFIED_ID) return;
                updateText(myRef, bindingSiteRadius);
                break;
            }
            case 'textInputChanged':
                setInputSpecs(payload.inputSpecs);
                setValue(payload.inputSpecs);
                setSelectedRef(DockingReference.UNSPECIFIED_ID);
                break;
            case 'radiusChanged': {
                setBindingSiteRadius(payload.radius);
                if (selectedRef === DockingReference.UNSPECIFIED_ID) return;
                updateText(myRef, payload.radius);
                break;
            }
            case 'referenceChanged': {
                updateText(payload.reference, bindingSiteRadius);
                break;
            }
            default:
                break;
        }
    };

    return (
        <>
            <FormControlLabel
                control={(
                    <Checkbox
                        checked={open && !disabled}
                        disabled={disabled}
                        onChange={() => {
                            if (open) {
                                handleFormchange('close');
                                return;
                            }
                            setOpen(true);
                            handleFormchange(inputType);
                        }}
                    />
                )}
                label="Add Binding Site Specification"
            />
            <TooltipInfo title="Apply location bias to the simulation based on a binding site" />
            { (open && !disabled) && (
                <Box sx={{ margin: '0 1rem' }}>
                    <RadioGroup
                        onChange={(evt) => {
                            handleFormchange(evt.target.value);
                        }}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel
                            control={<Radio checked={inputType==='picker'} />}
                            label="Use Reference Object"
                            value="usePicker"
                        />
                        <FormControlLabel
                            control={<Radio checked={inputType==='textinput'} />}
                            label="Use Text Input"
                            value="useTextinput"
                        />
                    </RadioGroup>
                    { inputType === 'picker' ? (
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                            alignItems: 'center',
                        }}
                        >
                            <BindingSiteSelector
                                refList={refList}
                                value={selectedRef}
                                onChange={(evt) => {
                                    setSelectedRef(evt.target.value);
                                    const newRef = refList.find((r) => r.id === evt.target.value);
                                    handleFormchange('referenceChanged', { reference: newRef });
                                }}
                                optionDisabledReasonFn={(ref) => (
                                    (ref.type === DockingReference.Types.selected
                                        && selectionAlreadyUsed)
                                        ? 'Selected atoms are already being used to define the simulation protein'
                                        : null)}
                                label="Binding Site Reference"
                                style={{ flex: '1 1 50%', marginRight: '2em' }}
                            />
                            <Box style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center' }}>
                                <Box>
                                    <InputLabel htmlFor="bindingSiteSpecsRadius">Radius (Å)</InputLabel>
                                </Box>
                                <Box>
                                    <IncrementDecrementInput
                                        name="Radius"
                                        min={1}
                                        value={bindingSiteRadius}
                                        setValue={(value) => handleFormchange('radiusChanged', { radius: value })}
                                        incrementInputProps={{ id: 'bindingSiteSpecsRadius' }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <TextInputWithTooltip
                                id="bindingSiteSpec"
                                labelText="Binding Site Specification"
                                tooltip={(
                                    <div>
                                        <p>Format:</p>
                                        <code>
                                            {'<radius> <res 1> <res 2> ...'}
                                        </code>
                                        <p>Example:</p>
                                        <code>15 GLU.23:A SER.30:A</code>
                                        <p>See the Guide for more information.</p>
                                    </div>
                                )}
                                required={false}
                                value={inputSpecs}
                                onChange={(evt) => {
                                    handleFormchange('textInputChanged', { inputSpecs: evt.target.value });
                                }}
                            />
                        </>
                    )}
                </Box>
            )}
        </>
    );
}

/**
 * BindingSiteSelector - A component to choose a binding site reference from
 * compounds, hotspots, or selected atoms.
 * This is from DockingForm DockingReferenceSelect and could be refactored into a common component.
 */
export function BindingSiteSelector({
    refList,
    optionDisabledReasonFn,
    placeholder='Choose binding site reference',
    includeCompounds=true,
    ...rest
}) {
    const options = [
        <option
            key={DockingReference.UNSPECIFIED_ID}
            value={DockingReference.UNSPECIFIED_ID}
            title=""
            disabled
        >
            {placeholder}
        </option>,
    ];
    refList.forEach((r) => {
        let title = r.type === DockingReference.Types.hotspot
            ? 'Energy is the averge excess chemical potential of the hotspot fragments'
            : '';

        const disabledReason = optionDisabledReasonFn && optionDisabledReasonFn(r);
        if (disabledReason) {
            title = disabledReason;
        }
        options.push(
            <option
                key={r.description}
                value={r.id}
                title={title}
                disabled={!!disabledReason}
            >
                {r.description}
            </option>
        );
    });

    // When a type of docking reference isn't available, dummy options in
    // the selector give an affordance that the possibility is there.
    const dummyInfo = [
        {
            type: DockingReference.Types.selected,
            text: 'Selected atoms',
            title: 'Click on atoms in the 3D workspace to define a center from selected atoms.',
        }, {
            type: DockingReference.Types.compound,
            text: includeCompounds ? 'Ligand / compound' : 'Crystal ligand',
            title: `This protein structure has no crystal ligand to use as a reference.${includeCompounds ? '  Add and/or dock a compound to use it as a binding site reference.' : ''}`,
        }, {
            type: DockingReference.Types.hotspot,
            text: 'Hotspot',
            title: 'There are no hotspots to use as references. Run a clustering simulation to generate hotspots.',
        },
    ];
    // Add the dummy options if neccessary
    dummyInfo.forEach(({ type, text, title }) => {
        const found = refList.find((r) => r.type === type);
        if (!found) {
            options.push(
                <option key={`DummyOption:${text}`} disabled title={title}>{text}</option>,
            );
        }
    });

    return (
        <Select {...rest}>
            {options}
        </Select>
    );
}
