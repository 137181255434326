// server_responders.js

import { ResponseIds, GetResponseLabel } from './server/server_common';
import {
    doReceiveSelection,
    doReceiveError,
    doReceiveWarning, doReceiveStatus,
    doReceiveSelectResults,
}
    from './project_data';
import { parseInfoMsg } from './decode';
import MapListData from './model/MapListData';
import { AtomGroupTypes, DecodedFragment } from './model/atomgroups';
import { CompoundMetadataPacket, UnboundConformationsPacket } from './server/bmaps-protocol';
import { AtomGroupFactory } from './model/AtomGroupFactory';

import { Log } from './Log';
import { BfdServerFragmentSearchResult } from './model/FragmentSearchResult';

/**
 * @description A class to maintain the server responses for a single command.
 * This is returned by commands to the server and acts like a promise, so await can be used.
 */
export class ServerResponder {
    constructor(requestId, scalarResponse, cmdInfo, decoder) {
        this.requestId = requestId;
        this.asyncRequestId = null;
        this.responseHandlers = {};
        this.resolve = null;
        this.reject = null;
        this.responseData = new ResponseData();
        this.scalarResponse = scalarResponse;
        this.cmdInfo = cmdInfo;
        this.decoder = decoder;
    }

    handleResponses(handlers) {
        if (handlers) {
            this.responseHandlers = handlers;
        }

        const wrapper = (resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        };
        return new Promise(wrapper);
    }

    then(resolve, reject) {
        const wrapper = (res, rej) => {
            this.resolve = resolve;
            this.reject = reject;
        };
        return new Promise(wrapper);
    }

    catch(reject) {
        const wrapper = (res, rej) => {
            this.reject = reject;
        };
        return new Promise(wrapper);
    }

    handleResponse(responseId, data) {
        let result = null;
        if (responseId === ResponseIds.Completed) {
            this.finish(this.responseData);
        } else {
            let handler = this.responseHandlers[responseId];
            if (!handler) handler = defaultHandlers[responseId];
            if (handler) {
                result = handler(data, this.decoder);
                if (result) {
                    this.responseData.addResponse(responseId, result);
                }
            } else {
                Log.warn(`Received server response with no handler: ${responseId}`);
            }
        }
        return result;
    }

    finish(dataIn) {
        let data = dataIn;
        const failure = this.scalarResponse && this.responseData[ResponseIds.Error];
        const finishFn = failure ? this.reject : this.resolve;

        // For convenience, certain operations (eg export) are defined to return a
        // single "scalar" value.  The caller can know to assign the result data
        // directly, instead of looking for the right index and key in the array.
        if (this.scalarResponse) {
            data = this.responseData.scalarResponse;
        }
        if (finishFn) {
            finishFn(data);
        }
    }
}

/**
 * @description A class to maintain outstanding server requests.
 * Implemented as a Map of requestId to ServerReponder object.
 *
 * @method add - Add a server responder to the queue
 * @method processResponse - Handle a response object from the server.
 *         Note: this will remove the responder from the queue when the COMPLETE
 *         message is received.
 * @method currentCmd {String} - the first item in the queue / current operation
 *      for a particular server key
 * @method cmdQueue {String} - the whole list of commands
 *      for a particular server key
 */
export class ServerCmdProcessingQueue {
    constructor(onUpdate) {
        this.registry = new Map();
        this.onUpdate = onUpdate;
        this.processCmdResponses = this.processCmdResponses.bind(this);
    }

    reset() {
        Log.info('Command Queue: clearing response registry on Zap');
        this.registry.clear();
    }

    processCmdResponses(requestId, scalarResponse, cmdInfo, decoder) {
        const serverResponder = new ServerResponder(requestId, scalarResponse, cmdInfo, decoder);
        this.add(serverResponder);
        return serverResponder;
    }

    add(serverResponder) {
        this.registry.set(serverResponder.requestId, serverResponder);
        Log.info(`Added ${serverResponder.requestId} to server handler registry`);
        if (this.onUpdate) this.onUpdate();
    }

    remove(responder) {
        this.registry.delete(responder.requestId);
        Log.info(`Deleted ${responder.requestId} from server handler registry`);
        if (this.onUpdate) this.onUpdate();
    }

    getRequest(requestId) {
        return this.registry.get(requestId);
    }

    lookupBy(keyValuePair) {
        for (const responder of this.registry.values()) {
            const [[key, value]] = Object.entries(keyValuePair);
            if (responder[key] === value) {
                return responder;
            }
        }
        return null;
    }

    currentCmd(serverKey) {
        const current = this.queue(serverKey)[0];
        return current;
    }

    /**
     * Return the list of objects in the command queue for a particular connection
     * Uses the serverKey to distinguish connections
     * @param {*} serverKey - the key from exec for this connection
     * @returns [Object] - list of command info objects in the queue
     */
    queue(serverKey) {
        const ret = [];
        for (const c of this.registry.values()) {
            if (c.cmdInfo.serverKey === serverKey || serverKey === undefined) {
                ret.push(c.cmdInfo);
            }
        }
        return ret;
    }

    /**
     * @description Look up a responder for a server response object.
     * Remove the responder if the command is complete.
     * @param {*} requestId - requestId in the response object
     * @param {*} responseId - the type of the response object
     * @param {*} data - response data
     */
    handleResponse(requestId, responseId, data) {
        Log.info(`Received request response for ${requestId}: ${GetResponseLabel(responseId)}`);
        const ret = { requestId, responseId, result: null };
        const responder = this.getRequest(requestId);

        const handleWith = (handler) => {
            ret.result = handler.handleResponse(responseId, data);
            if (responseId === ResponseIds.Completed) {
                this.remove(handler);
            }
            return ret;
        };

        if (responder) {
            // Certain responses in async situations have special handling.
            // Async here means the operation is handled by a separate process
            // from bfd-server (eg mm-server energies).
            if (!this.handleAsyncResponse(responder, responseId, data)) {
                return handleWith(responder);
            }
        } else {
            // Because the responder starts listening for asyncRequestIds only after receiving
            // the Completed message for the original id, it is possible to receive server errors
            // for the new async id before the switch. This can be avoided if the server uses
            // enqueueCommand, which ensures the first request is completed before the errors are
            // sent with the new request id. mm-server and diffdock work like this.
            // Folding doesn't use enqueueCommand however (because it doesn't modify server state),
            // so a network error may be reported before the first folding request is complete.
            // Repro scenario: attempt to fold when the folding service is offline.
            const asyncResponder = this.lookupBy({ asyncRequestId: requestId });
            if (asyncResponder) {
                Log.warn(`Looked up async responder for ${requestId}`);
                return handleWith(asyncResponder);
            }
            Log.warn(`Received server response for unknown request id: ${requestId}`);
        }
        return ret;
    }

    /**
     * @description Special handling for the first Completed message in a
     * async response situation.  Instead of completing the responder promise,
     * the first Completed will change the responder object to be listening
     * for the specified async request id.  The promise will complete when the
     * the second Completed message arrives.
     *
     * Explanation: Some operations (OpenMM minimization / energies,
     * and maybe Docking someday) run on an external server.
     * Those command requests are handled in two parts.  bfd-server sends a Completed
     * message for the first request id when it hands the request off to the external
     * server.  But first, it sends an AsyncResponseInfo message with a new request id
     * for the results from the other server.
     *
     * Again, the special handling is just for the first Completed message, to switch the
     * id the responder is waiting for. All other packets should be handled normally.
     *
     * @returns true if special handling was performed for the first Completed message,
     * informing the caller not to process the message (since that would end the promise)
    */
    handleAsyncResponse(responder, responseId, data) {
        const asyncInfo = responder.responseData.asyncResponseInfo;
        const asyncId = asyncInfo && asyncInfo.asyncRequestId;

        if (responseId === ResponseIds.AsyncResponseInfo) {
            // TODO: consider switching the responder here instead of after the first
            // completed message.
            try {
                const newAsyncId = JSON.parse(data).asyncRequestId;
                if (responder.asyncRequestId) {
                    Log.warn(`Overriding ${responder.asyncRequestId} with ${newAsyncId}`);
                }
                responder.asyncRequestId = newAsyncId;
            } catch (ex) {
                Log.error(`Failed to set asyncRequestId from ${data}: ${ex}`);
            }
        }

        // Switch to async when processing the first Completed message (with the old request id)
        // in an async scenario. But don't switch to async requestId if there was an error.
        const switchingToAsync = responseId === ResponseIds.Completed
              && asyncId && asyncId !== responder.requestId
              && !responder.responseData.hasError();

        if (switchingToAsync) {
            Log.info(`handleAsyncResponseInfo: switching from ${responder.requestId} to ${asyncId}`);
            this.remove(responder);
            responder.requestId = asyncId;
            this.add(responder);
        }

        return switchingToAsync;
    }
}

export class ResponseData {
    constructor() {
        this.responses = {};
    }

    addResponse(responseId, data) {
        if (this.responses[responseId]) {
            this.responses[responseId].push(data);
        } else {
            this.responses[responseId] = [data];
        }
    }

    byId(responseIdOrArray) {
        const data = [];
        for (const [key, packets] of Object.entries(this.responses)) {
            const parsed = Number(key);
            const resId = Number.isNaN(parsed) ? key : parsed;
            if (resId === responseIdOrArray
                || (Array.isArray(responseIdOrArray) && responseIdOrArray.includes(resId))) {
                data.push(...packets);
            }
        }
        return data;
    }

    get errors() {
        const errs = this.byId(ResponseIds.Error);
        return errs;
    }

    hasError() {
        return this.byId(ResponseIds.Error).length > 0;
    }

    get asyncResponseInfo() {
        const ari = this.byId(ResponseIds.AsyncResponseInfo);
        return ari.length > 0 ? ari[0] : null;
    }

    hasAsyncResponseInfo() {
        return this.asyncResponseInfo;
    }

    get responseCount() {
        return Object.keys(this.responses).length;
    }

    // Return the first response of any response type
    get scalarResponse() {
        return Object.values(this.responses)[0]?.[0];
    }
}

const defaultHandlers = {
    [ResponseIds.TestMessage]: receiveTestMessage,
    [ResponseIds.Solute]: receiveSolute,
    [ResponseIds.Snapshot]: receiveSnapshot,
    [ResponseIds.Fragments]: receiveFragments,
    [ResponseIds.WaterMap]: receiveWaterMap,
    [ResponseIds.ClusterMap]: receiveClusterMap,
    [ResponseIds.FragmentMap]: receiveFragmentMap,
    [ResponseIds.AtomGroupInfo]: receiveAtomGroupInfo,
    [ResponseIds.Selection]: receiveSelection,
    [ResponseIds.SolvationForLigand]: receiveSolvationForLigand,
    [ResponseIds.ForcefieldParamsForLigand]: receiveForcefieldParamsForLigand,
    [ResponseIds.EnergiesForLigand]: receiveEnergiesForLigand,
    [ResponseIds.Compound]: receiveCompound,
    [ResponseIds.Compound2D]: receiveCompound2D,
    [ResponseIds.DockResults]: receiveDockResults,
    [ResponseIds.FindResults]: receiveFindResults,
    [ResponseIds.TranslateMolecule]: receiveTranslateMolecule,
    [ResponseIds.FragDataResults]: receiveFragDataResults,
    [ResponseIds.SelectResults]: receiveSelectResults,
    [ResponseIds.MapList]: receiveMapList,
    [ResponseIds.PdbInfo]: receivePdbInfo,
    [ResponseIds.ProjectCaseStatus]: receiveProjectCaseStatus,
    [ResponseIds.SetPermissions]: receivePermissions,
    [ResponseIds.AvailableFragments]: getJsonPacketReceiver(ResponseIds.AvailableFragments),
    [ResponseIds.ModificationSelections]: receiveModificationSelections,
    [ResponseIds.ExportContents]: receiveExportContents,
    [ResponseIds.MolServiceRequestResult]: receiveMolServiceRequestResult,
    [ResponseIds.GetMoleculeProperties]: receiveGetMoleculeProperties,
    [ResponseIds.Zapped]: receiveZapped,
    [ResponseIds.Error]: receiveError,
    [ResponseIds.Warning]: receiveWarning,
    [ResponseIds.Status]: receiveStatus,
    [ResponseIds.AsyncResponseInfo]: receiveAsyncResponseInfo,
    [ResponseIds.StarterAvailability]: receiveStarterAvailability,
    [ResponseIds.EnergiesForLigandText]: receiveEnergiesForLigand,
    [ResponseIds.SolvationForLigandText]: receiveSolvationForLigand,
    [ResponseIds.ProteinRotationMatrix]: receiveRotationMatrix,
    [ResponseIds.DockResultsInfo]: getJsonPacketReceiver(ResponseIds.DockResultsInfo),
    [ResponseIds.CompoundMetadata]: getProtocolPacketReceiver(CompoundMetadataPacket),
    [ResponseIds.UnboundConformations]: getProtocolPacketReceiver(UnboundConformationsPacket),
    [ResponseIds.FoldStatus]: (data) => {
        const parsed = getJsonPacketReceiver(ResponseIds.FoldStatus)(data);
        Log.info(`Folding status: ${parsed['folding-status']}`);
    },
    [ResponseIds.FoldResults]: getJsonPacketReceiver(ResponseIds.FoldResults),
};

// Respond to server commands

export function getJsonPacketReceiver(responseType) {
    return (data) => {
        Log.info(`Received ${GetResponseLabel(responseType)}.`);
        return JSON.parse(data);
    };
}

function getProtocolPacketReceiver(Class) {
    return (data) => {
        const responseType = Class.MsgType;
        Log.info(`Received ${GetResponseLabel(responseType)}.`);
        return new Class(JSON.parse(data));
    };
}

export function receiveTestMessage(data) {
    Log.info('Test message received.');
}

export function receiveSolute(data, decoder) {
    Log.info('Received solute.');
    const [
        caseID, residues, atoms, bonds,
        helices, sheets, soluteName,
    ] = decoder.decodeSolute(data);

    if (!caseID || !atoms) return null;

    const { compounds, allAtomGroups } = identifySoluteParts(residues, helices, sheets);
    console.log(`Solute for ${caseID} with ${atoms.length} atoms installed.`);
    return {
        caseID,
        soluteName,
        compounds,
        allAtomGroups,
        atomData: {
            atoms,
            bonds,
            helices,
            sheets,
        },
    };
}

export function receiveWaterMap(data, decoder) {
    return receiveFragmentPacket(data, 'watermap', 'water', decoder);
}

export function receiveClusterMap(data, decoder) {
    return receiveFragmentPacket(data, 'clustermap', 'cluster', decoder);
}

export function receiveFragmentMap(data, decoder) {
    return receiveFragmentPacket(data, 'fragmentmap', 'fragmentmap', decoder);
}

export function receiveFragments(data, decoder) {
    return receiveFragmentPacket(data, 'fragments', 'fragment', decoder);
}

export function receiveSnapshot(data, decoder) {
    const { caseArgs, fragments } = decoder.decodeFragments(data, 'snapshot');
    console.log(`${fragments.length} fragments loaded from snapshot ${caseArgs}.`);
}

export function receiveAtomGroupInfo(data, decoder) {
    Log.info('Received atom group info.');
    // Store charges, amber names, and ringOrdinals, ready for incoming compound.
    decoder.decodeAndStoreAtomGroupInfo(data);
    // Data is just stored by the decoder, so nothing to return here.
}

export function receiveSelectResults(data, decoder) {
    Log.info('Received select results.');
    return doReceiveSelectResults(data, decoder);
}

export function receiveSelection(data, decoder) {
    Log.info('Received selection.');
    return doReceiveSelection(data, decoder);
}

export function receiveSolvationForLigand(data, decoder) {
    Log.info('Received solvation-for-ligand.');
    const [compoundSpec, solvData] = decoder.decodeSolvationForLigand(data);
    return { compoundSpec, solvData };
}

export function receiveForcefieldParamsForLigand(data, decoder) {
    Log.info('Received forcefield-params-for-ligand.');
    return decoder.decodeForcefieldParamsForLigand(data);
}

export function receiveEnergiesForLigand(data, decoder) {
    Log.info('Received energies-for-ligand.');
    const [
        cmpdSpec, internalEnergies, interactionEnergies, raw,
    ] = decoder.decodeEnergiesForLigand(data);

    return {
        compoundSpec: cmpdSpec, internalEnergies, interactionEnergies, raw,
    };
}

export function receiveCompound(data, decoder) {
    Log.info('Received compound.');
    return receiveCompoundData(data, decoder);
}

export function receiveCompound2D(dataOrig) {
    let data = dataOrig;
    let boundary = data.indexOf('\n');
    const compoundSpec = data.substr(0, boundary);
    Log.info(`Received compound 2D data for ${compoundSpec}.`);
    if (compoundSpec.startsWith('HOH')) return undefined;

    let smiles = null;
    let molData = null;
    data = data.substr(boundary+1);
    if (data.search('SMILES ') === 0) {
        boundary = data.indexOf('\n');
        smiles = data.substring(7, boundary);
        molData = data.substr(boundary+1);
    }
    return { compoundSpec, smiles, molData };
}

export function receiveDockResults(data, decoder) {
    Log.info('Received dock results.');
    return receiveCompoundData(data, decoder);
}

export function receiveFindResults(data, decoder) {
    Log.info('Received find results.');
    const [atoms, bonds, fragments, caseArgs] = decoder.decodeFragments(data, 'find-fragments');
    return fragments;
}

// Text message handlers

export function receiveMapList(data) {
    Log.info(`Received map list of length ${data.length}.`);
    return MapListData.fromBfdServer(JSON.parse(data));
}

export function receiveProjectCaseStatus(data) {
    try {
        return JSON.parse(data);
    } catch (ex) {
        Log.warn('Failed to parse project-case-status.');
        return { error: 'Failed to parse project-case-status' };
    }
}

export function receivePdbInfo(data) {
    Log.info(`Received pdb info: ${data}`);
    return JSON.parse(data);
}

export function receivePermissions(data) {
    Log.info(`Received permissions: ${data}`);
    let permObj = { dock: true };
    try {
        permObj = typeof (data) === 'string' ? JSON.parse(data) : data;
    } catch (ex) {
        Log.info(`Failed to parse permissions, using default: ${JSON.stringify(permObj)}`);
    }
    return permObj;
}

export function receiveModificationSelections(data) {
    Log.info('Received modification-selections.');
    // Format: findType, selectionID; name; binding FE; interaction energy; delta
    // interaction energy (only for replace); <newline>
    const results = JSON.parse(data);
    // Format: selectionID; name; binding FE; interaction energy; delta
    // interaction energy (only for replace); <newline>
    const suggMap = new Map();
    for (const result of results) {
        const key = result.modType.concat(result.name);
        const sugg = suggMap.get(key);
        if (sugg) {
            sugg.addPoseId(result);
        } else {
            suggMap.set(key, new BfdServerFragmentSearchResult(result));
        }
    }
    const suggestions = [...suggMap.values()];
    const totalPoses = suggestions.reduce(
        (poseCount, nextSugg) => poseCount + nextSugg.selectionIDs.length, 0
    );
    console.log(`Recieved ${suggMap.size} modification suggestions with ${totalPoses} total poses.`);
    return suggestions;
}

export function receiveExportContents(data) {
    Log.info('Received export-contents.');
    // The data is a string consisting of a header line followed by the
    // file contents requested by a previous export-selection command.
    // The header line is just a send-back of the export-selection
    // command arguments, and can be anything.  The header is
    // terminated by a newline.
    //--- Presumably write this to some location requested by the user.
    // The filename could have been supplied to the export-selection
    // command, and used here to write a file.
    const marker = data.indexOf('\n'); // delimits header
    const header = data.substr(0, marker);
    const formattedData = data.substr(marker+1);
    return formattedData;
}

export function receiveMolServiceRequestResult(data) {
    Log.info('Received mol service result.');
    const resultObj = typeof (data)==='string' ? JSON.parse(data) : data;
    return resultObj;
}

export function receiveGetMoleculeProperties(data) {
    Log.info('Received mol props result.');
    const resultObj = typeof (data) === 'string' ? JSON.parse(data) : data;
    return resultObj;
}

export function receiveTranslateMolecule(data, decoder) {
    Log.info('Received translate_molecule_msg.');
    return decoder.decodeTranslateMessage(data);
}

export function receiveZapped(data) {
    Log.info('Received zapped');
    // Do anything with this?
}

export function receiveError(data) {
    Log.info(`Received error msg ${data}`);
    const [cmd, args, message] = parseInfoMsg(data);
    doReceiveError(cmd, args, message);
    return message;
}

export function receiveWarning(data) {
    Log.info(`Received warning msg ${data}`);
    const [cmd, args, message] = parseInfoMsg(data);
    doReceiveWarning(cmd, args, message);
    return message;
}

export function receiveStatus(data) {
    Log.info(`Received status msg ${data}`);
    const [cmd, args, message] = parseInfoMsg(data);
    doReceiveStatus(cmd, args, message);
    return message;
}

export function receiveCompleted(data) {
    Log.info('Received completed message.');
}

export function receiveAsyncResponseInfo(data) {
    Log.info(`Received async-response-info ${data}`);
    const obj = JSON.parse(data);
    return obj;
}

export function receiveStarterAvailability(data) {
    Log.info(`Received starter-availability: ${data}`);
    const obj = JSON.parse(data);
    return obj;
}

export function receiveRotationMatrix(data) {
    Log.info(`Received rotation-matrix: ${data}`);
    const obj = JSON.parse(data);
    return obj;
}

export function receiveFragDataResults(dataIn, decoder) {
    Log.info('Received binary frag-data-query results');
    const data = dataIn.slice(2); // remove 17, code for frag data results

    const [atoms, bonds, fragments] = decoder.decodeFragDataResults(data);
    console.log(`$dataservice results received: ${fragments.length} fragments.`);

    return {
        fragments,
        atomData: { atoms, bonds },
    };
}

// Helper functions
/**
 * Process an inbound fragment packet
 * @param {*} data binary packet data
 * @param {*} packetType packet type passed into Decoder.decodeFragment
 * @param {*} fragmentSubType packet type assigned to the decoded fragment objects
 * @param {*} decoder the decoder instance for decoding the packet
 * @returns {{ caseArgs: string, fragments: DecodedFragment[], atomData: { atoms, bonds}}}
 */
function receiveFragmentPacket(data, packetType, fragmentSubType, decoder) {
    const [atoms, bonds, fragments, caseArgs] = decoder.decodeFragments(data, packetType);
    console.log(`${packetType} fragments received: ${fragments.length} fragments loaded for ${caseArgs}.`);
    if (fragmentSubType) {
        fragments.forEach((f) => f.setSubtype(fragmentSubType));
    }
    return {
        caseArgs,
        fragments,
        atomData: { atoms, bonds },
    };
}

function receiveCompoundData(data, decoder) {
    const [decodedCompound] = decoder.decodeCompound(data);
    const compound = AtomGroupFactory.CreateAtomGroup(decodedCompound);
    return compound;
}

// Called when receiving a solute.
// Sorting the parts into the correct molecule types is done by AtomGroupFactory
function identifySoluteParts(residues, helices, sheets) {
    const newAtomGroups = AtomGroupFactory.CreateAtomGroups(residues, helices, sheets);
    const compounds = newAtomGroups[AtomGroupTypes.Compound];
    const allAtomGroups = [];

    for (const groupedByType of Object.values(newAtomGroups)) {
        for (const atomGroup of groupedByType) {
            allAtomGroups.push(atomGroup);
        }
    }

    console.log(`Compounds: ${compounds}`);
    // console.log(`Other Atom Groups: ${allAtomGroups}`);

    return {
        compounds,
        allAtomGroups,
    };
}
