/** ModalPane.jsx
 *
 * This is a component for a modal dialog.
 * It includes a close button and listeners for the escape key.
 *
 * Props:
 *   visible - whether or not the modal is visible
 *   title   - title to display in the dialog
 *   content - A react component to display in the body of the dialog
 *   close   - Close function to be invoked on escape or close button
 *   hidden  - passing true will render the content component with
 *             display: none.  visible must also be true.
 *   closeOnEscape - invoke close on escape. default: true
 *
 * It needs to be managed by another component who will assign visible
 * and provide the close function.
 *
 */
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { EventBroker } from 'BMapsSrc/eventbroker';
import styled from 'styled-components';
import Dialog from './common/Dialog';
import DialogHeader from './common/DialogHeader';
import { cssRulesForStyleObj } from './ui_utils';

const ModalPane = styled(({
    visible,
    title,
    children,
    closeOnEscape,
    close,
    titleAdditions,
    className,
    DialogProps,
    ContentProps,
    modalRef,
    // style (only used in the styled-components template)
}) => {
    const contentProps = ContentProps || {};
    const component = contentProps?.component || 'div';
    let dialogProps = DialogProps || {};

    if (modalRef?.current) {
        const positionProps = {
            sx: {
                position: 'absolute',
            },
            BackdropProps: {
                sx: {
                    position: 'absolute',
                },
            },
            container: () => modalRef.current,
            disableEnforceFocus: true,
        };
        dialogProps = _.merge(dialogProps, positionProps);
    }

    // right: 'calc((var(--mol-button-size) + 1vh + (var(--mol-button-margin) * 2)) * -1)',

    return (
        <Dialog
            open={visible}
            onEscape={closeOnEscape ? close : null}
            className={className}
            onClose={close}
            TransitionProps={{
                onExited: () => {
                    EventBroker.publish('modalClosed');
                },
            }}
            {...dialogProps}
        >
            <DialogHeader
                handleClose={close}
                title={title}
            >
                { !!titleAdditions && titleAdditions }
            </DialogHeader>
            <Typography component={component} {...contentProps} className="dialogContent">{ children }</Typography>
        </Dialog>
    );
})`
${({ style }) => cssRulesForStyleObj(style, '& .MuiPaper-root')}

/* Mobile-view ModalPane is nearly full-screen */
@media only screen and (max-width: 1000px) {
    & .MuiPaper-root.MuiDialog-paper {
        position: absolute;
        top: 1.5em;
        bottom: -1em;
        left: -1em;
        right: -1em;
        height: unset;
        width: unset;
    }
}
`;

ModalPane.defaultProps = {
    visible: false,
    closeOnEscape: true,
};

export default ModalPane;
