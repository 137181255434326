import Box from '@mui/material/Box';

export default function TabPanel({
    parentClass, selected, children, display, ...rest
}) {
    return (
        <Box
            className={parentClass ? `${parentClass}_tabpanel` : 'tabpanel'}
            role="tabpanel"
            overflow="auto"
            p={3}
            display={selected ? display || 'block' : 'none'}
            {...rest}
        >
            {children}
        </Box>
    );
}
