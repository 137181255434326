/* init_info_display.js
 * Near obscolescence: this file is has mostly been replaced by InfoDisplay.jsx
 * Remaining functions are:
 * - Keyboard event handling
 * - Handling changes to the color scheme which affect compound svgs and the #app_wrapper
 * - Importing FragmentManager to include it in the build
 *
* Note: this file uses jQuery
 */

import { EventBroker } from '../eventbroker';
import { FragmentManager } from './dialog/FragmentManager'; // Importing just to include it in the build
import { App } from '../BMapsApp';
import { subscribe } from '../redux/subscribers/stateChangeSubscriber';
import { BackgroundColors } from '../themes';

export function initInfoDisplay() {
    subscribe('prefs.ColorScheme', (scheme) => {
        const colorInfo = BackgroundColors[scheme];
        updateTextColor(colorInfo.textCss);
        if (App.Workspace) {
            for (const cmpd of App.Workspace.getLoadedCompounds()) {
                cmpd.setSvg('refresh');
            }
        }
    });
    $(document).on('keydown', onKeyDown);
    $(document).on('keyup', onKeyUp);
}

function updateTextColor(color) {
    $('#app_wrapper').css('color', color);
}

// Weird that keydown / keyup is in stylebuttons
const keysDown = [];
function onKeyDown(event) {
    const key = event.key;
    const keyCode = event.keyCode;
    const ctrlKey = event.ctrlKey;
    const shiftKey = event.shiftKey;
    const optionKey = event.optionKey;
    if (!keysDown.includes(keyCode)) {
        EventBroker.publish('keyDown', {
            key, keyCode, ctrlKey, shiftKey, optionKey,
        });
        keysDown.push(keyCode);
    }
}

function onKeyUp(event) {
    const key = event.key;
    const keyCode = event.keyCode;
    const ctrlKey = event.ctrlKey;
    const shiftKey = event.shiftKey;
    const optionKey = event.optionKey;
    EventBroker.publish('keyUp', {
        key, keyCode, ctrlKey, shiftKey, optionKey,
    });
    const idx = keysDown.indexOf(keyCode);
    if (idx > -1) keysDown.splice(idx, 1);
}
