// atomgroups.js
/**
 * @fileoverview Classes for Residue and Fragment. Maybe Compound could be moved in here, too.
 *
 * @typedef { AtomGroup | Hotspot } AtomGroupLike
 */

import { MolStyles } from '../style_manager';
import { getFullResidueId, formResidueSpec, formFragmentSpec } from '../util/mol_info_utils';

/**
 * @typedef AtomGroupTypes
 * @property {'Protein'} Protein
 * @property {'Residue'} Residue
 * @property {'Compound'} Compound
 * @property {'Ligand'} Ligand
 * @property {'PeptideLigand'} PeptideLigand
 * @property {'Cofactor'} Cofactor
 * @property {'Buffer'} Buffer
 * @property {'Ion'} Ion
 * @property {'CrystalWater'} CrystalWater
 * @property {'ComputedWater'} ComputedWater
 * @property {'Fragment'} Fragment
 * @property {'Polymer'} Polymer
 * @property {'Unknown'} Unknown
 */

/**
 * @type {AtomGroupTypes}
 */
export const AtomGroupTypes = {
    Protein: 'Protein',
    Residue: 'Residue',
    Compound: 'Compound',
    Ligand: 'Ligand',
    PeptideLigand: 'PeptideLigand',
    Cofactor: 'Cofactor',
    Buffer: 'Buffer',
    Ion: 'Ion',
    CrystalWater: 'CrystalWater',
    ComputedWater: 'ComputedWater',
    Fragment: 'Fragment',
    Polymer: 'Polymer',
    Unknown: 'Unknown',
};

export class AtomGroupCollection {
    constructor(atomGroups) {
        this.atomGroups = [];
        if (atomGroups) {
            this.addAtomGroups(atomGroups);
        }
    }

    getAtomGroups() { return [...this.atomGroups]; }
    addAtomGroup(atomGroup) { this.atomGroups.push(atomGroup); }
    addAtomGroups(atomGroups) { atomGroups.forEach((atomGroup) => this.addAtomGroup(atomGroup)); }
    isEmpty() { return this.atomGroups.length === 0; }
}

/** @description Business object base class for AtomGroups in the BMaps Client. */
export class AtomGroup {
    /**
     * Collect all the atoms from a list of atomGroups into a single array
     * @param {AtomGroup[]} atomGroups
     * @return {import('./atoms').Atom[]}
     */
    static atomsInAtomGroups(atomGroups) {
        return atomGroups.reduce((acc, nextAtomGroup) => acc.concat(nextAtomGroup.getAtoms()), []);
    }

    static atomGroupsFromAtoms(atoms) {
        const atomGroups = new Set();
        for (const atom of atoms) {
            const atomGroup = atom.getAtomGroup();
            if (atomGroup) atomGroups.add(atomGroup);
        }
        return [...atomGroups];
    }

    constructor(atoms, type=AtomGroupTypes.Unknown) {
        /** @type {AtomGroupTypes[keyof AtomGroupTypes]} */
        this.type = type;
        /** @type {import('./atoms').Atom[]} */
        this.atoms = atoms;

        const atom = atoms[0];
        if (!atom) throw new Error(`Can't make an AtomGroup (${type}) without any atoms`);
        this.resn = atom.resn; // 3-letter pdb code
        this.resname = atom.resname; // 3-letter pdb code, or longer ligand name, or fragment name.
        this.chain = atom.chain;
        this.resSpec = getFullResidueId(atom);
        this.formalCharge = (atom.residue && atom.residue.formalCharge)
            || (atom.fragment && atom.fragment.formalCharge);

        // Properties from Residue object created by decode.js (null for fragments!)
        this.residue = atom.residue;
        this.resi = atom.resi;
        this.molType = (atom.residue && atom.residue.molType);
        this.molName = (atom.residue && atom.residue.molName); // May only apply to polymers
        // Note: molType and molName are set to null for Compounds in the constructor

        /** @type {string} */
        this.key = this.resSpec; // Used for saving state

        /** @type {import('BMapsModel').CaseData} */
        this.caseData = null;

        this.atoms.forEach((at) => { if (!at.getAtomGroup()) at.setAtomGroup(this); });
    }

    getAtoms() {
        return [...this.atoms];
    }

    getSelectableAtoms() {
        return this.getAtoms().filter((atom) => atom.atom !== 'LP');
    }

    /** @returns {import('./atoms').Bond[]} */
    getBonds() {
        const bonds = new Set();
        for (const atom of this.getAtoms()) {
            atom.getBonds().forEach((bond) => { bonds.add(bond); });
        }
        return [...bonds];
    }

    hasAtom(atom) {
        return this.atoms.includes(atom);
    }

    getCaseData() { return this.caseData; }
    setCaseData(caseData) { this.caseData = caseData; }
    /** @returns {string} */
    get displayName() {
        const molOrResName = this.molName || this.resname;
        switch (this.type) {
            case AtomGroupTypes.Protein: {
                const chainName = this.molName || 'Target';
                const chainType = this.molType || 'protein';
                // Consider improving the look of molType's.
                return `${chainName} (${chainType} chain ${this.chain})`;
            }
            case AtomGroupTypes.Cofactor: {
                return `${molOrResName} (cofactor ${this.resSpec})`;
            }
            case AtomGroupTypes.Ion: {
                return `${molOrResName} (ion ${this.resSpec})`;
            }
            case AtomGroupTypes.Polymer:
            case AtomGroupTypes.PeptideLigand: {
                let polymerType = this.molType || 'peptide';
                polymerType = polymerType.replace(/_/g, ' ');
                if (polymerType === 'saccharide') polymerType = 'sugar'; // shorten for display
                const residues = this.getResidues();
                const firstRes = residues[0].residue;
                const lastRes = residues[residues.length-1].residue;
                const firstSeq = firstRes.sequenceNumber.toString();
                const lastSeq = lastRes.sequenceNumber.toString();
                return `${molOrResName} (${polymerType} ${firstSeq}-${lastSeq}:${this.chain})`;
            }
            default:
                return this.resSpec;
        }
    }

    toString() { return this.displayName; }

    isMyAtom(atom) { return this.getAtoms().includes(atom); }

    getForeignBonds() {
        const isForeignBond = (bond) => bond.getAtoms().some((atom) => !this.isMyAtom(atom));
        const foreignBonds = this.getBonds().filter(isForeignBond);
        return foreignBonds;
    }

    hasForeignBonds() {
        return this.getForeignBonds().length > 0;
    }
}

AtomGroup.PolymerTypes = [AtomGroupTypes.Polymer, AtomGroupTypes.PeptideLigand];
AtomGroup.CompoundTypes = [AtomGroupTypes.Compound, AtomGroupTypes.Ligand];
AtomGroup.WaterTypes = [AtomGroupTypes.CrystalWater, AtomGroupTypes.ComputedWater];
AtomGroup.ChainTypes = [AtomGroupTypes.Protein, ...AtomGroup.PolymerTypes];
AtomGroup.SoluteTypes = [
    ...AtomGroup.ChainTypes, AtomGroupTypes.Cofactor, AtomGroupTypes.Ion,
];

/**
 * @description Business object class for multi-residue atom groups.
 */
export class Polymer extends AtomGroup {
    static residuesInPolymers(polymers) {
        return polymers.reduce((acc, nextPolymer) => acc.concat(nextPolymer.getResidues()), []);
    }

    static polymersForResidues(residues) {
        const chains = new Set();
        residues.forEach((residue) => chains.add(residue.getChainGroup()));
        return [...chains];
    }

    constructor(atoms, residues, type=AtomGroupTypes.Polymer) {
        super(atoms, type);
        this.residues = residues;
        residues.forEach((res) => res.setChainGroup(this));
    }

    getResidues() {
        return [...this.residues];
    }
}

/**
 * @description Business object class for Fragments
 */
export class Fragment extends AtomGroup {
    constructor(decodedFragment, type=AtomGroupTypes.Fragment) {
        super(decodedFragment.atoms, type);
        this.fragmentName = decodedFragment.parentName;
        this.fragmentOrdinal = decodedFragment.parentSequenceNum;
        this.exchemPotential = decodedFragment.exchemPotential;
        this.fragmentInfo = null;
    }

    get displayName() {
        switch (this.type) {
            case AtomGroupTypes.ComputedWater:
                return `Computed Water ${this.resSpec}`;
            case AtomGroupTypes.CrystalWater:
                return `Crystal Water ${this.resSpec}`;
            case AtomGroupTypes.Fragment:
            default: // fallthrough
                return `${this.fragmentName}.${this.fragmentOrdinal}`;
        }
    }
}

/**
 * @description Business object class for Residues
 */
export class Residue extends AtomGroup {
    constructor(...args) {
        super(...args);
        this.secondaryStructureInfo = null;
        this.chainGroup = null;
    }

    getChainGroup() { return this.chainGroup; }
    setChainGroup(chainGroup) { this.chainGroup = chainGroup; }
    getSecondaryStructureInfo() { return this.secondaryStructureInfo; }
    setSecondaryStructureInfo(specs) { this.secondaryStructureInfo = specs; }
}

/**
 * Not an AtomGroup but deserving of a class
 * (But really should probably be an AtomGroup)
 */
export class Hotspot {
    constructor(groupNumber) {
        this.type = 'Hotspot';
        this.frags = [];
        this.atoms = [];
        this.solute = null;
        this.fragmentGroupNumber = groupNumber;
        this.key = `Hotspot-${groupNumber}`;
        this.exchemPotentialAvg = 0;

        /** @type {import('BMapsModel').CaseData} */
        this.caseData = null;
    }

    getCaseData() { return this.caseData; }
    setCaseData(caseData) { this.caseData = caseData; }

    addFragment(frag) {
        this.frags.push(frag);
        for (const a of frag.atoms) {
            this.atoms.push(a);
            a.setAtomGroup(this);
        }
        this.updateAvgExchemP();
    }

    getAtoms() {
        return [...this.atoms];
    }

    getFragments() {
        return [...this.frags];
    }

    getFragmentNames() {
        return this.frags.reduce((acc, f) => {
            if (!acc.includes(f.parentName)) {
                acc.push(f.parentName);
            }
            return acc;
        }, []);
    }

    hasAtom(atom) {
        return this.atoms.includes(atom);
    }

    updateAvgExchemP() {
        let total = 0;
        let count = 0;

        for (const f of this.getFragments()) {
            if (f.exchemPotential != null) {
                total += f.exchemPotential;
                count++;
            }
        }
        this.exchemPotentialAvg = total / count;
    }

    get displayName() {
        return `Hotspot ${this.fragmentGroupNumber}`;
    }
}

/* DecodedAtomGroup
 * Base class for Residue and Fragments decoded from binary data in decode.js
 * This was originally implemented thinking that this category would evolve into
 * general AtomGroup business objects to be used in the client.
 * However, now they are only used for receiving decoded data;
 * the business objects are MolAtomGroups which are created from the decoded atom groups.
 *
 * Note: Atom business objects are created in the decoder, which is the only
 * business object to be created there.
 */

export class DecodedAtomGroup {
    constructor(type, name, code, sequenceNum, inscode, altloc) {
        // TODO: consider moving some of this to setAtomProperties
        this.parentType = type;
        this.parentName = name;
        this.parentCode = code;
        this.parentSequenceNum = sequenceNum;
        this.parentInsertionCode = inscode;
        this.parentAltLocation = altloc;
        this.hetflag = true;
        this.isIon = false;
        this.atoms = null; // will be assigned by decoder
    }

    /** @description This is called in the Atom constructor to update the atom props according
     * to the parent group.
     */
    setAtomProperties() { }
    getAtoms() { return [...this.atoms]; }

    getSpec() {
        return `DecodedAtomGroup-${this.parentType}.${this.parentName}.${this.parentSequenceNum}`;
    }

    // Copied from AtomGroup
    getBonds() {
        const bonds = new Set();
        for (const atom of this.getAtoms()) {
            atom.getBonds().forEach((bond) => { bonds.add(bond); });
        }
        return [...bonds];
    }
}

/* Residue
 * JS class residues decoded from bfd-server binary data
 */

export class DecodedResidue extends DecodedAtomGroup {
    constructor(props) {
        super('residue', props.name, props.pdbCode, props.sequenceNumber, props.insertionCode, props.altLocation);
        this.name = props.name;
        this.pdbCode = props.pdbCode;
        this.sequenceNumber = props.sequenceNumber;
        this.insertionCode = props.insertionCode;
        this.altLocation = props.altLocation;
        this.rescode = props.rescode;
        this.chainID = props.chainID;
        this.modelNumber = props.modelNumber;
        this.hetflag = props.hetflag;
        this.isIon = props.isIon;
        this.updateStatus = props.updateStatus; // not used
        this.isCompound = props.isCompound;
        this.molType = props.molType;
        this.molName = props.molName;
    }

    setAtomProperties(atom) {
        atom.residue = this;
        // Properties for 3DMol
        atom.icode = this.insertionCode;
        atom.altLoc = this.altLocation;
        atom.chain = this.chainID;
        atom.modelNum = this.modelNumber;
        atom.rescode = this.rescode;
    }

    getSpec() {
        return formResidueSpec(this.pdbCode, this.sequenceNumber, this.chainID, {
            altLoc: this.altLocation,
            insCode: this.insertionCode,
            modelNum: this.modelNumber,
        });
    }
}

/* Fragment
 */

export class DecodedFragment extends DecodedAtomGroup {
    constructor(props) {
        super('fragment', props.baseFrag.name, props.baseFrag.name, props.ordinal);
        this.subtype = null; // will be overridden

        this.baseFrag = props.baseFrag;
        this.formalCharge = props.baseFrag.formalCharge;
        this.ordinal = props.ordinal;
        this.fragmentGroup = props.fragmentGroup;
        this.atomSerialNumberBase = props.atomSerialNumberBase;
        this.translation = props.translation;
        this.orientation = props.orientation;
        this.exchemPotential = props.exchemPotential;
        this.enSolute = props.enSolute;
        this.enFragments = props.enFragments;
        this.enSolv = props.enSolv;
        this.poseSerialNo = props.poseSerialNo;
        this.projectCase = undefined;
        // Note: some decoded fragments will join the system as AtomGroups (eg waters, hotspots),
        // but those from fragment searching will not. Because those fragments are still subject
        // to a transformation from protein alignment, we need to track the case data here.
        // This is only used by fragments in fragment search suggestions.
        this.caseData = undefined;
    }

    setAtomProperties(atom) {
        atom.fragment = this;
        atom.initialStyle = MolStyles.hidden;
    }

    getSpec() {
        return formFragmentSpec(this.parentCode, this.parentSequenceNum);
    }

    setSubtype(type) {
        this.subtype = type;
    }

    setProjectCase(projectCase) {
        this.projectCase = projectCase;
    }

    setCaseData(caseData) { this.caseData = caseData; }
    getCaseData() { return this.caseData; }

    get isHotspot() { return this.subtype === 'cluster'; }
    get isWater() { return this.subtype === 'water'; }
    get isFragment() { return this.subtype === 'fragment'; }
    get isFragmentMapFragment() { return this.subtype === 'fragmentmap'; }
}
