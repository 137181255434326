import TabInfo from '../../../../common/TabController/TabInfo';
import ImportProteinTab from './ImportProteinTab';
import ImportPdbTab from './ImportPdbTab';
import ImportAlphaFoldTab from './ImportAlphaFoldTab';
import SelectProteinTab from './SelectProteinTab';
import ProjectTargetsTab from './ProjectTargetsTab';
import { Loader } from '../../../../../Loader';
import SelectActiveSoluteTab from './SelectActiveSoluteTab';
import ProteinFoldingTab from './ProteinFoldingTab';

const tabs = [
    new TabInfo(
        'protein_search_tab',
        'Structure Search',
        SelectProteinTab,
        {
            TabPanelProps: {
                display: 'flex', // display checked in TabPanel.jsx; do not place in style
                style: {
                    flexDirection: 'column',
                },
            },
            viewOnlyOK: true,
        },
    ),
    new TabInfo(
        'protein_folding_tab',
        'Protein Folding',
        ProteinFoldingTab,
        {
            TabPanelProps: {
                style: {
                    padding: 0,
                },
            },
        }
    ),
    new TabInfo(
        'import_protein_tab',
        'Upload Protein Data',
        ImportProteinTab
    ),
    new TabInfo(
        'import_pdb_tab',
        'Import from PDB',
        ImportPdbTab
    ),
    new TabInfo(
        'import_alphafold_tab',
        'Import from AlphaFold',
        ImportAlphaFoldTab
    ),
];

if (Loader.AllowLabFeatures) {
    tabs.push(
        new TabInfo(
            'project_target_tab',
            'Project / Targets',
            ProjectTargetsTab,
        )
    );
}

if (Loader.AllowDevFeatures) {
    tabs.push(
        new TabInfo(
            'solute_selector_tab',
            'Solute selector',
            SelectActiveSoluteTab,
        )
    );
}

export default tabs;
