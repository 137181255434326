import styled from 'styled-components';
import MuiTypography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import CopyToClipboardButton from '../../common/Buttons/CopyToClipboardButton';

const ErrorTypography = styled(MuiTypography)`
  width: 100%;
  font-weight: bold;
`;

export function ErrorMessage({ children, ...rest }) {
    return (
        <ErrorTypography {...rest}>
            <i className="fa fa-warning" />
            {' '}
            {children}
        </ErrorTypography>
    );
}

export function CopyToClipboard({ copyTarget }) {
    return (
        <CopyToClipboardButton
            copyTarget={copyTarget}
            TooltipProps={{ placement: 'right', arrow: true }}
            IconButtonProps={{ size: 'medium' }}
        />
    );
}

export function Notice({ title, children }) {
    return (
        <>
            <Grid item component="h4">{title}</Grid>
            <Grid item>{children}</Grid>
        </>
    );
}
