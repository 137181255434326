import styled from 'styled-components';
import ControlButton from './ControlButton';
import { withColorSchemeInfoStyled } from '../../../redux/prefs/access';

export default withColorSchemeInfoStyled(styled(DrawerButton)`
    ${({ theme, $colorSchemeInfo: { name } }) => `
            border: 0.5vh solid ${ name === 'black' ? 'rgb(131, 148, 150)' : 'black'};
            margin-right: ${theme.spacing(1)};

            &:hover {
                padding: 0.5vh;
            }
    `}
`);

function DrawerButton(props) {
    return (
        <ControlButton
            TooltipProps={{ placement: 'top' }}
            {...props}
        />
    );
}
