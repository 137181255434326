import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import TooltipInfo from '../TooltipInfo';
import ConditionalWrapper from '../ConditionalWrapper';

/**
 *
 * @param {React Component} Component - React component
 * @param {Object} options - Props that can be applied to all components of type Component
 * @description - You can use ComponentProps to apply to all components of type Component. Or you
 *                pass in props normally to configure instances individually.
 *
 *                For example:
 *                const ComponentA = withTooltip(Component, { fullWidth: true });
 *
 *                All instances of ComponentA will have full width.
 *                ----------------------------------------------------------------
 *                const ComponentB = withTooltip(Component);
 *                const instance = <ComponentB fullWidth />
 *
 *                Only this one instance will have full width.
 */
export default function withTooltip(Component, {
    ComponentProps: ComponentPropsHOC = {},
    ContainerProps: ContainerPropsHOC = {},
    IconWrapperProps: IconWrapperPropsHOC = {},
    WrapperProps: WrapperPropsHOC = {},
} = {}) {
    return function TooltipWrapper({
        label, tooltip,
        ComponentProps: ComponentPropsInstance = {},
        ContainerProps: ContainerPropsInstance = {},
        IconWrapperProps: IconWrapperPropsInstance = {},
        WrapperProps: WrapperPropsInstance = {},
        ...rest
    }) {
        const tooltipWidth = '3rem';

        const ComponentProps = { ...ComponentPropsHOC, ...ComponentPropsInstance };
        const ContainerProps = { ...ContainerPropsHOC, ...ContainerPropsInstance };
        const IconWrapperProps = { ...IconWrapperPropsHOC, ...IconWrapperPropsInstance };
        const WrapperProps = { ...WrapperPropsHOC, ...WrapperPropsInstance };

        function wrapper(children) {
            return (
                <Grid
                    container
                    wrap="nowrap"
                    {...WrapperProps}
                >
                    {children}
                </Grid>
            );
        }

        return (
            // NOTE: "overflow: hidden" is needed because when the Component contains InputError,
            // it creates a scrollbar whenever the error message appears.
            <Grid container {...ContainerProps} style={{ overflow: 'hidden', ...ContainerProps.style }}>
                {label && (
                    <Grid item container>
                        <InputLabel>{label}</InputLabel>
                    </Grid>
                )}
                <ConditionalWrapper condition={!!tooltip} wrapper={wrapper}>
                    <Grid
                        item
                        component={Component}
                        {...ComponentProps}
                        {...rest}
                    />
                    {tooltip && (
                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            style={{
                                width: tooltipWidth,
                                paddingTop: '15px',
                            }}
                            {...IconWrapperProps}
                        >
                            <TooltipInfo title={tooltip} />
                        </Grid>
                    )}

                </ConditionalWrapper>
            </Grid>
        );
    };
}
