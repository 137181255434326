import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import { UserActions } from 'BMapsCmds';
import { EventBroker } from 'BMapsSrc/eventbroker';
import { App } from '../../BMapsApp';
import { withPausedRedisplay } from '../../util/display_utils';
import { WorkingIndicator } from '../ui_utils';
import TooltipInfo from '../common/TooltipInfo';
import InspectorMessage from './InspectorMessage';
import { fetchLID } from '../ProLIFApi';

export function DisplayLID({ compound }) {
    const [html, setHtml] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const fetchDiagram = useCallback(async (cmpd) => {
        const { caseData } = App.getDataParents(cmpd);
        const query = `(protein or rna or dna) and within (15 ${cmpd.resSpec}); roundup`;
        const { data: pdb, error } = await withPausedRedisplay(UserActions.ExportQueryToFormat(query, 'pdb', caseData));
        // Make sure the scene is redrawn after pausing redisplay (to avoid selection flash).
        // Redraw is necessary because if the user switches compounds with just the right timing,
        // we could end up with a blank screen.
        // May occasionally cause slight delay, but since fetch is debounced, should be fine.
        EventBroker.publish('redrawSceneRequest');
        const sdf = cmpd.getSDF({ propInclusion: 'none' });
        const noPdb = (!error && !pdb) || pdb?.indexOf('The export-selection command found nothing selected.') > 0;
        const haveError = error || pdb?.startsWith('Caught error processing text command request export-selection');

        const params = {
            width: '100%',
            height: '300px',
        };

        if (noPdb) {
            // Display a message for no pdb case:
            setErrorMessage('Load a protein to see a ligand interaction diagram.');
        } else if (haveError) {
            // Display error for error case:
            setErrorMessage('The ligand interaction diagram failed to load.');
        } else {
            // Set isLoading to true while fetching data
            setIsLoading(true);

            // Call API and set LID html
            try {
                const result = await fetchLID(pdb, sdf, params);
                if (!result.error) {
                    setHtml(result.html);
                } else {
                    console.warn(`Error from LID service: ${result.error}`);
                    setHtml('');
                    setErrorMessage('The Ligand Interaction Diagram service failed to prepare the diagram for this structure.');
                }
            } catch (errorObj) {
                console.warn(`Error communicating with LID service: ${errorObj.message}`);
                setHtml('');
                setErrorMessage('There was a problem communicating with the Ligand Interaction Diagram service.');
            } finally {
                setIsLoading(false);
            }
        }
    }, []);

    // Debounce wants a stable function input, and the result should also be a stable function.
    // So useCallback for both.
    /* eslint-disable react-hooks/exhaustive-deps */
    const fetchDiagramDebounced = useCallback(_.debounce(fetchDiagram, 1000), []);
    useEffect(() => fetchDiagramDebounced(compound), [compound]);
    /* eslint-enable react-hooks/exhaustive-deps */

    if (html) {
        return (
            <ProLIFResults html={html} />
        );
    } else if (errorMessage) {
        return <InspectorMessage>{errorMessage}</InspectorMessage>;
    } else if (isLoading) {
        return (
            <InspectorMessage>
                Loading ligand interaction diagram...
                {' '}
                <WorkingIndicator />
            </InspectorMessage>
        );
    } else {
        return <InspectorMessage>No Diagram Available</InspectorMessage>;
    }
}

function ProLIFResults({ html }) {
    return (
        <div style={{ position: 'relative' }}>
            <ProLIFInfoIcon style={{
                position: 'absolute', right: '0', backgroundColor: 'white', color: 'black',
            }}
            />
            <iframe srcDoc={html} title="ProLIF Ligand Interaction Diagram" width="100%" height="400" />
        </div>
    );
}

export function ProLIFInfoIcon(props) {
    const content = `"ProLIF" ligand interaction diagram from ChemoSim Lab.
    Try zooming, mousing over interaction lines, toggling the interactivity features, and dragging the residues.
    See https://doi.org/10.1186/s13321-021-00548-6 for more information.`;
    return (
        <TooltipInfo title={content} {...props} />
    );
}
