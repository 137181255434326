import { useEffect, useState } from 'react';
// Local
import { UserActions } from 'BMapsCmds';
import { EventBroker } from '../../../../eventbroker';
import ModalPane from '../../../ModalPane';
import TabController from '../../../common/TabController';
import { App } from '../../../../BMapsApp';
import tabs from './Tabs';
import SelectivityControl from './Tabs/SelectivityControl';
import ProteinContextProvider from '../../../common/ProteinContextProvider';
import {
    getNewStaticDataConnection, getSelectivityDataConnection, releaseUnusedConnections,
} from '../../../../server/connection_utils';
import { getStructureErrMsg } from '../../../../server/error_utils';
import { loadProteinByMapCase } from '../../../../project_data';

export default function ImportProteinPane({ ModalPaneProps }) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(tabs[0].id);
    const [initData, setInitData] = useState({});
    const [selectivity, setSelectivity] = useState('individual');
    const [target, setTarget] = useState('target');
    const [loadErrors, setLoadErrors] = useState({});
    const [connection, setConnection] = useState();
    const [connectionWarning, setConnectionWarning] = useState();

    function closeImportPane() {
        setOpen(false);
        setInitData(false);
        setSelectivity('individual');
        setTarget('target');
        releaseUnusedConnections();
        clearAllLoadErrors();
    }

    const openImportPane = (tabId, data) => {
        // prevent pane from being opened without dataconnection
        setConnection(App.PrimaryDataConnection);
        setSelected(tabId);
        if (data) {
            setInitData(data);
            if (data.options?.selectivity) {
                setSelectivity(data.options.selectivity);
            }
            if (data.cmd === 'hide') {
                setOpen(false);
                return;
            }
        }
        setOpen(true);
    };

    // Determine the DataConnection to use for loading the structure, perhaps allocating a new one.
    useEffect(() => {
        if (!open) return;
        (async () => {
            let newConn;
            switch (selectivity) {
                case 'individual':
                    newConn = App.PrimaryDataConnection;
                    setConnectionWarning(null);
                    break;
                case 'selectivity': {
                    const { dataConnection, failureReason } = await getSelectivityDataConnection();
                    if (dataConnection) {
                        newConn = dataConnection;
                        setConnectionWarning(null);
                    } else {
                        setConnectionWarning(failureReason);
                        setSelectivity('viewonly');
                        // The change will get picked up for the next render.
                        return;
                    }
                    break;
                }
                case 'viewonly': {
                    newConn = await getNewStaticDataConnection();
                    const selectedTab = tabs.find(({ id }) => id === selected);
                    if (!selectedTab.viewOnlyOK) {
                        const firstViewOnlyTab = tabs.find(({ viewOnlyOK }) => viewOnlyOK);
                        console.log(`Switching to ${firstViewOnlyTab.id} tab because ${selectedTab.id} is not view-only capable.`);
                        setSelected(firstViewOnlyTab.id);
                    }
                    break;
                }
                case 'multiProtein': {
                    newConn = App.PrimaryDataConnection;
                    setConnectionWarning(null);
                    break;
                }
                // no default
            }
            console.log(`ImportProteinPane using connection ${newConn?.connector.getLabel()} for adding ${selectivity} target`);
            setConnection(newConn);
        })();
    }, [selectivity, open]);

    useEffect(() => {
        const openPane = EventBroker.subscribe(
            'openProteinImportTab',
            (name, { tabId, args }) => openImportPane(tabId, args)
        );

        return () => {
            EventBroker.unsubscribe('openProteinImportTab', openPane);
        };
    }, []);

    async function selectProtein(mapCase, loadArgsIn={}) {
        const loadArgs = {
            ...loadArgsIn,
            keepExisting: selectivity==='selectivity' || selectivity==='viewonly' || selectivity==='multiProtein',
        };

        console.log(`Loading protein ${mapCase.uri} into connection ${connection?.connector.getLabel()}`);
        EventBroker.withIgnoredEvents('escapeKey', (async () => {
            // Zap (called when loading 'individual' structures) invokes escapeKey, which closes
            // the Pane. Ignore escapeKey event while loading to avoid closing in case of errors.
            const result = await loadProteinByMapCase(mapCase, loadArgs, connection);

            switch (true) {
                case !result:
                    // User cancelled the load
                    clearMyLoadErrors();
                    break;
                case result.errors.length > 0:
                    // Load completed with errors
                    setMyLoadErrors([getStructureErrMsg(mapCase, result.errors, result.caseData)]);
                    break;
                default:
                    // Load succeeded
                    clearMyLoadErrors();
                    if (target === 'offtarget') {
                        UserActions.SetProteinTarget(mapCase, { isTarget: false });
                    }
                    closeImportPane();
                    break;
            }
        })()); // IIFE since withIgnoredEvents needs a promise, not a function
    }

    function clearAllLoadErrors() { setLoadErrors({}); }
    function getMyLoadErrors() { return loadErrors[selected]; }
    function setMyLoadErrors(errs) { setErrorsFor(errs, selected); }
    function clearMyLoadErrors() { setMyLoadErrors(null); }
    function setErrorsFor(errs, id) { setLoadErrors((old) => ({ ...old, [id]: errs })); }

    const viewOnly = connection?.connector.staticMode;
    return (
        <ModalPane
            visible={open}
            style={{ width: '100%', height: '100%' }}
            title="Add Protein"
            closeOnEscape
            close={closeImportPane}
            {...ModalPaneProps}
        >
            <ProteinContextProvider>
                <TabController
                    tabs={tabs.map((tab) => {
                        const disabled = viewOnly && !tab.viewOnlyOK;
                        return tab.forTabController({
                            closeImportPane,
                            initData,
                            selectProtein,
                            errors: getMyLoadErrors(),
                            setErrors: (errs) => setErrorsFor(errs, tab.id),
                            connection,
                        },
                        {
                            TabProps: {
                                disabled,
                                title: disabled ? 'This import option is not available in view-only mode' : '',
                            },
                        });
                    })}
                    selected={selected}
                    setSelected={setSelected}
                    parentClass="import-protein-pane"
                >
                    {selected !== 'project_target_tab' && (
                        <SelectivityControl
                            selectivity={selectivity}
                            setSelectivity={setSelectivity}
                            viewOnly={viewOnly}
                            connectionWarning={connectionWarning}
                            target={target}
                            setTarget={setTarget}
                        />
                    )}
                </TabController>
            </ProteinContextProvider>
        </ModalPane>
    );
}
