/**
 * cmds_common.js
 * @fileoverview These functions perform operations on server response data that have model
 * side effects, and are thus beyond the scope of server_responders.
 * They are needed for various UserActions in different files, so they are collected here.
 * @typedef {import('BMapsModel').Compound} Compound
 * */
import {
    AtomGroupTypes, AtomGroupFactory, EnergyInfo, Compound, MolProps,
    applyEnergiesToCompound, applySolvationToCompound,
} from 'BMapsModel';
import { UserActions } from 'BMapsCmds/UserCmd';
import { App } from '../BMapsApp';
import { ResponseIds } from '../server/server_common';
import { CompoundColorMap } from '../themes';
import { EventBroker } from '../eventbroker';
import { ensureArray } from '../util/js_utils';
import { Loader } from '../Loader';
import { getUseAutoGiFE } from '../redux/prefs/access';

export async function setSoluteForComponent(item) {
    if (!item) {
        return { connector: App.ServerConnection };
    }
    const { connector, caseData } = App.getDataParents(item);
    if (caseData?.soluteName) await connector.cmdSetActiveSolute(caseData.soluteName);
    return { connector, caseData };
}

export function filterNonCompounds(list, label='') {
    return list.filter((cmpd) => {
        // Possible that with a server mol data bug, we get end up with a Residue
        if (cmpd instanceof Compound) {
            return true;
        } else {
            const prefix = label ? `${label}: ` : '';
            console.warn(`${prefix}Ignoring a non-compound atom group: ${cmpd} (${cmpd.constructor.name})`);
            return false;
        }
    });
}

/**
 * Necessary functions on inbound compound response data:
 * - Assigning default colors
 * - Updating 2d info and mol properties
 * @param {*} responseData
 */
export function integrateCompounds(responseData, caseData) {
    // Gather compounds and update the colors of atoms
    /** @type {Compound[]} */
    let compounds = [];
    const [solute] = responseData.byId(ResponseIds.Solute);
    if (solute?.compounds) {
        compounds.push(...solute.compounds);
    }
    compounds.push(...responseData.byId([ResponseIds.Compound, ResponseIds.DockResults]));
    compounds = filterNonCompounds(compounds, 'integrateCompounds');

    for (const compound of compounds) {
        for (const atom of compound.getAtoms()) {
            // Assign atom.defaultColorMap, used by 3dmol_interface to override default colors.
            atom.defaultColorMap = CompoundColorMap;
        }
    }
    EventBroker.publish('refreshAtomDisplay'); // update atom colors & styles
    EventBroker.publish('redisplayRequest'); // rerender the scene

    // Apply inbound compound info to workspace compounds
    const compoundMetadatas = responseData.byId(ResponseIds.CompoundMetadata);
    if (compoundMetadatas.length > 0) {
        for (const { compoundSpec, scopedProperties } of compoundMetadatas) {
            const compound = caseData.getCompoundBySpec(compoundSpec);
            if (compound) {
                compound.scopedProperties.copyFrom(scopedProperties);
                compound.setSmiles(compound.getProperty('structure2d', 'smiles'));
                compound.setMol2000(compound.getProperty('model3d', 'mol'));
                compound.updateSvg('mol');
                compound.updateMolProps();
            } else {
                console.warn(`Compound metadata received for unknown compound ${compoundSpec}`);
            }
        }
    } else {
        // Compound-2D maintained for backwards compatibility.
        const compound2Ds = responseData.byId(ResponseIds.Compound2D);
        for (const { compoundSpec, smiles, molData } of compound2Ds) {
            const compound = caseData.getCompoundBySpec(compoundSpec);
            if (compound) {
                compound.setSmiles(smiles);
                compound.setMol2000(molData);
                compound.updateSvg('mol');
                compound.updateMolProps();
            } else {
                console.warn(`Compound2D received for unknown compound ${compoundSpec}`);
            }
        }
    }
    EventBroker.publish('compoundsUpdated', { compounds });

    if (Loader.AllowLabFeatures && getUseAutoGiFE()) {
        updateGifeEnergiesForCompounds(compounds);
    }
}

/**
 * Update model after requesting forcefield params.
 * The atom info maps in the decoder have already been updated when the atomgroupinfo packet
 * was received in response to the FF param request.
 * But we need to sync any existing compounds with the updated params in the atom info map.
 * @param { import('BMapsSrc/server_responders').ResponseData } responseData
 * @param { import('BMapsModel').Compound} compound
 */
export function integrateForcefieldParams(responseData, compound) {
    const successful = [];
    const errors = [];

    const [ffCmpdSpec] = responseData.byId(ResponseIds.ForcefieldParamsForLigand);

    if (responseData.errors.length > 0) {
        for (const error of responseData.errors) {
            console.error(`Error getting FF params for ${compound.resSpec}: ${error}`);
            errors.push(error);
        }
        const msg = `Forcefield parameters failed. Technical detail: ${errors.join('; ')}`;
        compound.energyInfo.updateForcefieldRequest(false, msg);
    }

    if (ffCmpdSpec === compound.resSpec) {
        successful.push(compound);
        const { caseDataCollection } = App.getDataParents(compound);

        // If FF params changed, make sure existing compounds are updated with the new values
        const updatedCmpds = caseDataCollection.atomInfoMaps.syncCompoundsWithAtomInfo(compound);
        if (updatedCmpds.includes(compound)) {
            console.log(`Updated atom info for ${compound.resSpec} after forcefield param request`);
        }
        compound.energyInfo.updateForcefieldRequest(true);
    } else if (ffCmpdSpec) {
        console.warn(`Received forcefield parameters for unrecognized ligand ${ffCmpdSpec}`);
    }

    if (compound.energyInfo.forcefieldParamStatus === EnergyInfo.States.working) {
        console.error(`FF Params unresolved for ${compound.resSpec}`);
        const msg = 'Forcefield parameters failed to be applied, perhaps because of compound id confusion.';
        compound.energyInfo.updateForcefieldRequest(false, msg);
    }

    return { compounds: successful, errors };
}

/**
 * Incorporate inbound water map and / or cluster map data into Workspace / CaseData,
 * returning newly created AtomGroups if requested
 * @param { import('BMapsSrc/server_responders').ResponseData } responseData
 * @param { import('BMapsModel').CaseData } caseData - The CaseData associated with the request
 * @param { boolean } returnAGs - Whether or not to return newly created atomgroups
 * @returns { Array } Newly created atom groups if requested
 *
 * Note: returnAGs is false by default for the water map case; no point in building up large
 * arrays of waters if we don't need them.
 */
export function integrateFragments(responseData, caseData, returnAGs=false) {
    // The packets in these lists have all been prepared by server_responders receiveFragmentPacket
    const waterMapPackets = responseData.byId(ResponseIds.WaterMap);
    const clusterMapPackets = responseData.byId(ResponseIds.ClusterMap);
    const fragmentMapPackets = responseData.byId(ResponseIds.FragmentMap);
    const projectCase = caseData?.mapCase?.projectCase;

    const results = { water: [], cluster: [], fragmentmap: [] };
    // Fragments
    for (const [packetList, fragType] of [
        [waterMapPackets, 'water'],
        [clusterMapPackets, 'cluster'],
        [fragmentMapPackets, 'fragmentmap'],
    ]) {
        for (const packet of packetList) {
            const { caseArgs, fragments } = packet; // per receiveFragmentPacket
            if (caseArgs === projectCase) {
                const newAGs = processFragments(caseData, fragType, fragments, returnAGs);
                results[fragType].push(...newAGs);
            } else {
                console.warn(`Attempting to integrate fragments with unexpected case: ${caseArgs}. Expected: ${projectCase}`);
            }
        }
    }
    return results;
}

/**
 * @description Gather energy calculation errors into a map: compound -> err strings
 * @param {*} compounds - compounds that we operated on
 * @param {*} minimizedCmpds - compounds returned with minimized coordinates
 * @param {*} energies - energy objects returned (both energies and solvation packets)
 * @param {*} errors - error strings returned, possibly json strings
 * @param {{skipSolvation: boolean}} [minimizationOptions]
 * @returns Map from Compound object to an array of error strings
 */
export function gatherEnergyErrors(
    compounds, minimizedCmpds, energies,
    errors, minimizationOptions={}
) {
    const errMap = new Map();

    const workingErrs = errors.map((e) => {
        if (e.startsWith('{')) {
            try {
                return JSON.parse(e);
            } catch (ex) {
                // If we can't parse as JSON, just return the string as the error
            }
        }
        return e;
    });

    for (const cmpd of compounds) {
        const spec = cmpd.resSpec;
        const cmpdErrs = [];
        errMap.set(cmpd, cmpdErrs);
        const minimized = minimizedCmpds && minimizedCmpds.find((c) => c.resSpec === spec);
        const cmpdEns = energies.filter((e) => e.cmpdSpec === spec);

        // See if any errors match this compound.
        // The errors could be objects with compound fields or strings.
        for (const err of workingErrs) {
            if (typeof err === 'object' && !err.compound) {
                // Protein issue that applies to all compounds
                let { error } = err;
                if (err.errType === 'Missing hydrogens') {
                    error = 'This protein contains residues that are missing hydrogens and cannot be minimized against.';
                } else if (err.errType === 'Compound data missing after minimization' && !error) {
                    // This is what we see if mm-server crashes.
                    error = 'Perhaps energy server failure due to alt-loc?';
                }
                cmpdErrs.push(`${err.errType}: ${error}`);
            } else if (err.compound && err.compound === spec) {
                // Issue just for this compound
                cmpdErrs.push(`${err.errType}: ${err.error}`);
            } else if (err.indexOf && err.indexOf(spec) > -1) {
                // non-json error
                cmpdErrs.push(err);
            }
        }

        // Add default error for failing condition if there is no error msg.
        // Success = Have energies for both interaction and solvation; and
        // if we're minimizing, received compound with minimized coordinates.
        const expectedLength = minimizationOptions.skipSolvation ? 1 : 2;
        const success = cmpdEns.length === expectedLength && (!minimizedCmpds || minimized);
        const haveError = cmpdErrs.length > 0;
        if (!success && !haveError) {
            cmpdErrs.push(`Failed to assign energies for ${spec}`);
        }
    }

    return errMap;
}

/**
 * Process one set of fragment data and integrate into workspace:
 * - Add atoms to the 3D display
 * - Create atom groups as needed
 * - Add AtomGroups to caseData as needed
 * Return the new atom groups if requested.
 * @param {CaseData} caseData
 * @param {string} type type defined in integrateFragments
 * @param {import('BMapsModel').DecodedFragment[]} fragments fragments decoded receiveFragmentPacket
 * @param {boolean} returnAGs whether or not to return the newly created atom groups
 *
 * @todo Consider creating the atom groups in server_responders
 */
function processFragments(caseData, type, fragments, returnAGs=false) {
    const newAGs = [];
    if (type === 'cluster') {
        App.Workspace.setHotspotFragments(caseData, fragments); // creates Hotspots and adds to case
        if (returnAGs) newAGs.push(...caseData.getHotspots());
    } else {
        for (const fragment of fragments) {
            const atomGroup = AtomGroupFactory.CreateAtomGroup(fragment);
            caseData.addAtomGroup(atomGroup);
            if (returnAGs) newAGs.push(atomGroup);
        }
    }
    return newAGs;
}

/**
 * Calls the individual integration methods for any energy or solvation data
 * @param { import('BMapsSrc/server_responders').ResponseData } responseData
 * @param { import('BMapsModel').CaseData } caseData
 */
export function integrateEnergies(responseData, caseData) {
    const results = [];
    const energyPackets = responseData.byId(
        [ResponseIds.EnergiesForLigand, ResponseIds.EnergiesForLigandText]
    );
    for (const packet of energyPackets) {
        const result = integrateEnergy(packet, caseData);
        if (result) {
            results.push(result);
        }
    }
    const solvationPackets = responseData.byId(
        [ResponseIds.SolvationForLigand, ResponseIds.SolvationForLigandText]
    );

    for (const packet of solvationPackets) {
        const result = integrateSolvation(packet, caseData);
        if (result) {
            results.push(result);
        }
    }

    return results;
}

/**
 * Apply inbound energy data to compound
 * @param {*} param0
 * @param {import('BMapsModel').CaseData} caseData
 */
function integrateEnergy({
    compoundSpec, internalEnergies, interactionEnergies, raw,
}, caseData) {
    const compound = caseData.getCompoundBySpec(compoundSpec);
    if (compound) {
        const energies = { interactionEnergies, internalEnergies, raw };
        const {
            vdw, coulomb, hbonds, stress, total, worstTorsion,
        } = applyEnergiesToCompound(compound, energies, caseData);

        // Note: this call to updateEnergyEfficiency is in both doReceiveEnergiesForLigand and
        // doReceiveSolvationForLigand, since they could come in either order.
        // I considered moving this to energyMinimize in UserActions, but then it would also
        // need to be applied to other scenarios, like GetCaseFiles and GetStarterCompounds.
        compound.updateEnergyEfficiency();
        // console.log(`The worst torsion is ${worstTorsion.join(',')}.`);
        return {
            compound, cmpdSpec: compoundSpec, vdw, coulomb, hbonds, stress, total,
        };
    }
    console.warn(`integrateEnergy can't find compound ${compoundSpec}`);
    return null;
}

/**
 * Apply inbound solvation data to compound
 * @param {*} param0
 * @param {import('BMapsModel').CaseData} caseData
 */
function integrateSolvation({ compoundSpec, solvData }, caseData) {
    const cmpd = caseData.getCompoundBySpec(compoundSpec);

    if (cmpd) {
        const { ddGs } = applySolvationToCompound(cmpd, solvData, caseData);

        // Note: this call to updateEnergyEfficiency is in both doReceiveEnergiesForLigand and
        // doReceiveSolvationForLigand, since they could come in either order.
        // I considered moving this to energyMinimize in UserActions, but then it would also
        // need to be applied to other scenarios, like GetCaseFiles and GetStarterCompounds.
        cmpd.updateEnergyEfficiency();
        return { cmpdSpec: compoundSpec, ddGs };
    }
    console.warn(`integrateSolvation can't find compound ${compoundSpec}`);
    return null;
}

/**
 * updateGifeEnergiesForCompounds
 * Call GiFE for the specified compounds, using the entire solute (protein+cofactor) for
 * each compound.
 * @param {Compound[] | Compound} compoundsIn
 */
export async function updateGifeEnergiesForCompounds(compoundsIn) {
    const compounds = ensureArray(compoundsIn);
    const { dataParentsMap } = App.partitionByDataParents(compounds);

    for (const [dataParents, cmpds] of dataParentsMap.entries()) {
        const { caseData } = dataParents;
        const solute = caseData.getSolute()
            .filter((x) => x.type !== AtomGroupTypes.Ion); // ions not supported by GiFE yet
        const soluteCharge = totalFormalCharge(solute);

        const promises = compounds.map((cmpd) => (
            cmpd.fetchProperty('extra_energies', EnergyInfo.ExtraTypes.GiFE, async () => {
                const cmpdChargeBfd = cmpd.formalCharge;
                const cmpdChargeOpenBabel = cmpd.getMolProp(MolProps.Charge);
                if (cmpdChargeBfd || cmpdChargeOpenBabel || soluteCharge) {
                    const errorMsg = 'GiFE energies are not yet available for charged molecules';
                    return { error: errorMsg };
                }
                const gifeRequest = { atomGroups: [cmpd, ...solute] };
                const gifeResult = await UserActions.GetGifeEnergies(gifeRequest);
                let value;
                let error;
                const { results, error: requestErr } = gifeResult;
                if (results?.length > 0) {
                    const firstResult = results[0];
                    value = firstResult.value;
                    if (firstResult.error) {
                        error = firstResult.error;
                    }
                } else {
                    error = requestErr || 'GiFE failed to produce a result';
                }
                return { value, error };
            },
            () => EventBroker.publish('energyCalc')) // refresh energy display
        ));
        await Promise.all(promises);
    }
}

/**
 * totalFormalCharge
 * Helper function to sum up formal charges in a list of atom groups.
 * Protein and Polymer atom groups themselves have a list of atom groups (residues),
 * so this is recursively called to report their formal charges.
 * The solute decoding and loading process should be updated to sum up the residue charges
 * for polymers (decode.js, AtomGroupFactory.js)
 */
function totalFormalCharge(atomGroups) {
    return atomGroups.reduce(
        (acc, next) => {
            const myCharge = next.formalCharge || 0;
            if (!next.getResidues) {
                return acc + myCharge;
            }
            const myResiduesCharge = totalFormalCharge(next.getResidues());
            if (myCharge !== myResiduesCharge) {
                console.warn(`Formal charges not the same for ${next.resSpec} and residues`);
            }
            return acc + myResiduesCharge;
        },
        0
    );
}
