export default class TabInfo {
    constructor(id, label, Component, { TabProps = {}, TabPanelProps, ...rest } = {}) {
        // Props needed for TabController
        this.id = id;
        this.label = label;
        this.Component = Component;
        this.TabProps = TabProps; // properties assigned to the Tab itself
        this.TabPanelProps = TabPanelProps; // properties assigned to the Tab content panel
        // Props that can be added for other purposes
        for (const [key, value] of Object.entries(rest)) {
            this[key] = value;
        }
    }

    /**
     * Prepare a tab to be displayed by the TabController.
     * This is called by the host of the TabController, passing in necessary props for the logic
     * of the content component, and also allowing overrides for the Tab and TabPanel UI elements.
     * @param {object} contentPropsFromDialog props passed to the component from the host
     * @param {{TabProps, TabPanelProps}} param1 props to override UI defaults, passed from host
     */
    forTabController(contentPropsFromDialog, {
        TabProps: tabPropsFromDialog,
        TabPanelProps: tabPanelPropsFromDialog,
    }={}) {
        return {
            id: this.id,
            label: this.label,
            getContent: (contentPropsFromController) => (
                <this.Component {...contentPropsFromDialog} {...contentPropsFromController} />
            ),
            TabProps: { ...this.TabProps, ...tabPropsFromDialog },
            TabPanelProps: { ...this.TabPanelProps, ...tabPanelPropsFromDialog },
        };
    }
}
