import styled from 'styled-components';
// Material UI
import MuiTextField from '@mui/material/TextField';
// Local

export default styled(MuiTextField).attrs({
    variant: 'outlined',
    multiline: true,
})`
    box-sizing: border-box;
    resize: none;
    height: 100%;
    width: 100%;

    & textarea {
        font-family: "Courier New", Courier, monospace !important;
        font-size: .9em !important;
        ${({ maxRows }) => (maxRows ? `height: ${maxRows}em !important;` : '')}
    }
`;
