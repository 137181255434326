import { WebServices } from '../WebServices';

export async function fetchLID(pdb, sdf, params) {
    const data = await WebServices.startWsRequestJson(getLIDUrl(), {
        pdb,
        sdf,
        params,
    });
    const result = JSON.parse(data);
    return result;
}

function getLIDUrl() {
    const url = 'https://2idhwvmhqkdkx4oy3nqmuhmame0acooz.lambda-url.us-east-1.on.aws/';
    return url;
}
