// Local
import { DialogSubmitButton } from '../../../common/DialogActionButtons';
import { useExportDataFetch } from '../exportUtils';
import { ErrorMessage } from '../Components';

const config = {
    formats: ['smi'],
    allowMultiple: false,
    allowStaticMode: true,
    disallowAtoms: true,
};

export default function ExportReaxysTab({ selected }) {
    const { errors, formattedData } = useExportDataFetch(selected, config, 'Reaxys');

    let value = '';
    if (formattedData['sdf']) {
        value = `MOLFILE='${formattedData['sdf']}'`;
    } else if (formattedData['smi']) {
        value = `SMILES='${formattedData['smi']}'`;
    }

    return (
        <div>
            <div style={{ marginBottom: '1em' }}>
                Reaxys is a popular search tool for literature,
                compound properties, and chemical reaction data.
                <br />
                You need a Reaxys account to access the service.
                {' '}
                <a className="reaxys_link" href="https://www.elsevier.com/solutions/reaxys" target="_blank" rel="noreferrer">Learn more about Reaxys</a>
            </div>
            <form id="reaxys-form" method="post" action="https://www.reaxys.com/reaxys/secured/hopinto.do" target="_blank">
                <input type="hidden" name="context" value="S" />
                <input type="hidden" name="query" value={value} />
                <input type="hidden" name="origin" value="Boltzmann Maps" />
                <input type="hidden" name="ln" value="" />
                {errors.length ? <ErrorMessage>{errors[0]}</ErrorMessage>
                    : (
                        <DialogSubmitButton>Search Reaxys For Compound</DialogSubmitButton>
                    )}
            </form>
        </div>
    );
}
