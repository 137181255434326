/* LigandMod.jsx  -*- mode: javascript; indent-tab-mode:nil; fill-column: 90; -*-
 *
 * Display a list of suggested modifications and replace them.
 *
 * Important ideas:
 * - FragmentSearchResult is a model class that contains data from bfd-server/dataservice,
 *   tying together 3D fragment poses and metadata about fragment and search.
 * - AnnotatedSearchResult wraps the FragmentSearchResult with other information such as
 *   fragment properties and protein target info.
 * - Unfortunately, the word "suggestion" is used for both of these classes, but usually
 *   it refers to the annotated version.
 * - These search results will get rendered in tabular form, either csv or in a GUI table.
 * - column_definitions.js is a central place to define available columns and how they
 *   are rendered in csv or GUI. LigandMod.getColumns() decides which columns to show.
 */
import React from 'react';
import { App } from 'BMapsSrc/BMapsApp';
import {
    redisplay, makeTempMolecule, removeTempMolecule, updateTempMolecule,
} from 'BMapsSrc/MainCanvas';
import { EventBroker } from 'BMapsSrc/eventbroker';
import { showAlert, hasPreviewModeError } from 'BMapsSrc/utils';
import { Length3, Sub3_3 } from 'BMapsSrc/math';
import { updateTooltipster } from 'BMapsUI/ui_utils';
import { MolStyles } from 'BMapsSrc/style_manager';
import { FragList } from 'BMapsSrc/FragList';
import { UserActions } from 'BMapsCmds';
import LinkLikeButton from 'BMapsUI/common/LinkLikeButton';
import { SelectivityColoring } from 'BMapsPresentation/SelectivityColoring';
import { HydrogenDisplayOptions, hydrogenCheck } from 'BMapsUtil/display_utils';
import { EnergySlider } from 'BMapsUI/UIComponents';
import { AnnotatedSearchResult, ActivityThresholdInfo } from './AnnotatedSearchResult';
import { SuggestionTable } from './SuggestionTable';
import { getCsvData } from './FragmentSearchCsv';
import {
    renderDebug, sortSearchResults, LigandModUIInfo, isKinaseInProjectCases,
} from './utils';
import {
    FragmentSearchResultColumns, makePerProteinCol,
    ActiveTargetColor, ActiveOffTargetColor,
} from './column_definitions';

export default class LigandMod extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            suggestions: [],
            suggestionState: new Map(),
            sourceAtom: null,
            sortCol: 'default',
            sortAscending: true,
            showImages: true,
            uiInfo: new LigandModUIInfo('grow'),
            showOptions: false,
            addMultipleFrags: false,
            displayFragmentHydrogens: false,
            highlightActiveFrags: false,
            activityThresholdInfo: new ActivityThresholdInfo(),
            includedColumns: new Set(),
            sortPosesBy: 'position',
        };
        this.handleDisplaySuggestions = this.handleDisplaySuggestions.bind(this);
        this.hide = this.hide.bind(this);
        this.suggestionAccept = this.suggestionAccept.bind(this);
        this.suggestionEnter = this.suggestionEnter.bind(this);
        this.suggestionExit = this.suggestionExit.bind(this);
        this.cycleSuggestions = this.cycleSuggestions.bind(this);
        this.togglePin = this.togglePin.bind(this);
        this.handleMultipleFrags = this.handleMultipleFrags.bind(this);
        this.handleFragmentHydrogens = this.handleFragmentHydrogens.bind(this);
        this.scrollingRef = React.createRef(); // Used for reseting scroll after sort change
        this.getCsvString = this.getCsvString.bind(this);
        this.handleCsvDownload = this.handleCsvDownload.bind(this);
        this.toggleImages = this.toggleImages.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.getSortedSuggestions = this.getSortedSuggestions.bind(this);
        this.isPinned = this.isPinned.bind(this);
        this.handleHighlightActiveFrags = this.handleHighlightActiveFrags.bind(this);
        this.handleActivityThreshold = this.handleActivityThreshold.bind(this);
        this.handleShowOptions = this.handleShowOptions.bind(this);
        this.toggleColumns = this.toggleColumns.bind(this);
        this.handleSortPosesBy = this.handleSortPosesBy.bind(this);
    }

    componentDidMount() {
        EventBroker.subscribe('displaySuggestions', this.handleDisplaySuggestions);
        EventBroker.subscribe('escapeKey', this.hide);
        EventBroker.subscribe('zapAll', this.hide);
        this.updateTooltips();
    }

    componentDidUpdate(prevProps, prevState) {
        const { sortCol, sortAscending } = this.state;
        if (prevState.sortCol !== sortCol
            || prevState.sortAscending !== sortAscending) {
            if (this.scrollingRef.current) {
                this.scrollingRef.current.scrollTop = 0;
            }
        }
        this.updateTooltips();
    }

    componentWillUnmount() {
        EventBroker.unsubscribe('displaySuggestions', this.handleDisplaySuggestions);
        EventBroker.unsubscribe('escapeKey', this.hide);
        EventBroker.unsubscribe('zapAll', this.hide);
    }

    handleDisplaySuggestions(evtName, {
        suggestions, errors, sourceAtom, cmd, fragList,
    }) {
        if (suggestions.length > 0) {
            EventBroker.publish('modifyingCompound', true);
            this.displaySuggestions(suggestions, sourceAtom, cmd, fragList);
        } else {
            const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.substr(1)}`;
            const title = capitalize(cmd);
            if (errors.length === 0) {
                showAlert('No candidates found.', title);
            } else if (!hasPreviewModeError(errors)) {
                showAlert('Sorry, an error occurred in this search.', title);
            }
            this.hide();
        }
    }

    // Change sort after click on column header
    handleSort(column) {
        this.setState((oldState) => {
            if (oldState.sortCol === column) {
                return { sortAscending: !oldState.sortAscending };
            } else { // change column
                return { sortCol: column };
            }
        });
    }

    handleMultipleFrags() {
        this.setState((oldState) => ({ addMultipleFrags: !oldState.addMultipleFrags }));
    }

    handleHighlightActiveFrags() {
        this.setState((oldState) => ({ highlightActiveFrags: !oldState.highlightActiveFrags }));
    }

    handleActivityThreshold(activityThreshold) {
        const { suggestions } = this.state;
        const ati = new ActivityThresholdInfo({ activityField: 'bindingScore', activityThreshold });
        for (const sugg of suggestions) {
            sugg.updateSummaryInfo(ati);
        }
        this.setState({ activityThresholdInfo: ati });
    }

    handleShowOptions() {
        this.setState((oldState) => ({ showOptions: !oldState.showOptions }));
    }

    toggleColumns(...columns) { // eslint-disable-line react/sort-comp
        this.setState(({ includedColumns: oldIncludedColumns }) => {
            for (const column of columns) {
                if (oldIncludedColumns.has(column)) {
                    oldIncludedColumns.delete(column);
                } else {
                    oldIncludedColumns.add(column);
                }
            }
            return { includedColumns: new Set([...oldIncludedColumns]) };
        });
    }

    handleSortPosesBy(sortPosesBy) {
        // Changing the pose sort order may lead to confusion if the distribution is pinned.
        // If we maintain the highlighted *pose* then we'd have to adjust the slider position.
        // I decided to maintain the slider position, so we need to adjust the highlighted pose.
        // This is achieved by hiding and redisplaying all the temporary molecules, which will
        // find the newly highlighted fragment at the previous selectionIndex.
        this.setState((oldState) => {
            const { suggestions, suggestionState } = oldState;
            this.removeTempAtoms(suggestions, { skipRedisplay: true });

            for (const sugg of suggestions) {
                this.sortSuggestionFrags(sugg, sortPosesBy);
                if (suggestionState.get(sugg)?.pinned) {
                    this.suggestionEnter(sugg, { force: true, skipRedisplay: true });
                }
            }

            redisplay();

            return { sortPosesBy };
        });
    }

    /**
     * Handle changes to the "Show Hs" checkbox
     */
    handleFragmentHydrogens() {
        // refreshFrags is invoked in the setTimeout below. The molecule construction needs the
        // updated displayFragmentHydrogens state, so this runs after the state update finishes.
        const refreshFrags = (oldState) => {
            const pinnedSuggestions = oldState.suggestions.filter(
                (sugg) => oldState.suggestionState.get(sugg)?.pinned
            );
            for (const pinnedSugg of pinnedSuggestions) {
                this.suggestionExit(pinnedSugg, { force: true, skipRedisplay: true });
                this.suggestionEnter(pinnedSugg, { force: true, skipRedisplay: true });
            }
            redisplay();
        };

        this.setState((oldState) => {
            setTimeout(() => refreshFrags(oldState), 10); // Refresh after display state update
            return { displayFragmentHydrogens: !oldState.displayFragmentHydrogens };
        });
    }

    handleCsvDownload() {
        const csvString = this.getCsvString();
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = this.getDownloadName();
        document.body.appendChild(anchor);
        anchor.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
    }

    getDownloadName() {
        const { suggestions, uiInfo, sourceAtom } = this.state;
        const isFromDataService = this.fromDataService(suggestions);
        const isGrow = uiInfo.displayLinkType;
        const service = isFromDataService ? 'DataQuery' : 'Search';
        const searchType = isGrow ? 'GrowFrom' : 'Nearby';
        const { mapCase } = App.getDataParents(sourceAtom);
        const caseSuffix = mapCase?.pdbID || mapCase?.case || 'unknown case';
        return `BMaps${service}Results_${caseSuffix}_${searchType}-${sourceAtom.atom}`;
    }

    getSortedSuggestions() {
        const {
            suggestions, sortCol, sortAscending, uiInfo, showImages, includedColumns,
        } = this.state;
        const sortedSuggestions = [...suggestions];
        sortedSuggestions.sort(sortSearchResults({ sortCol, sortAscending }));
        const columns = this.getColumns(sortedSuggestions, uiInfo, showImages, includedColumns);
        return { suggestions: sortedSuggestions, columns };
    }

    /**
     * getColumns() - determine the columns to display for the table and the csv download.
     */
    getColumns(suggestions, uiInfo, showImages, includedColumns) {
        const {
            perFragColumns, perProteinColumns, linkColumns, summaryColumns,
        } = FragmentSearchResultColumns;
        const projectCases = AnnotatedSearchResult.getProjectCasesForSuggestions(suggestions);
        const displayLink = uiInfo.displayLinkType;
        const displaySummary = projectCases.length > 1;
        const displayCoral = isKinaseInProjectCases(projectCases);

        // Determine per-fragment columns (cols for csv and table are different)
        const fragColsPool = Object.keys(perFragColumns).filter(
            (col) => !perFragColumns[col].skip || includedColumns.has(col)
        );
        const fragColsCsv = fragColsPool.filter((col) => perFragColumns[col].csv);
        const fragColsTable = fragColsPool.filter(
            // name, svg are added to the table separately
            (col) => perFragColumns[col].table && col !== 'name' && col !== 'svg'
        );

        // Determine per-Protein columns
        const perProtColsPool = Object.keys(perProteinColumns).filter(
            (col) => !perProteinColumns[col].skip || includedColumns.has(col)
        );
        const perProteinColsCsv = [];
        const perProteinColsTable = [];
        for (const pc of projectCases) {
            for (const col of perProtColsPool) {
                if (pc === projectCases[0] && col === 'bindingScoreRatio') {
                    // Don't need to show the ratio of the primary target to itself (always 1.0)
                    continue;
                }
                if (perProteinColumns[col].csv) {
                    perProteinColsCsv.push(makePerProteinCol(col, pc));
                }
                if (perProteinColumns[col].table) {
                    perProteinColsTable.push(makePerProteinCol(col, pc));
                }
            }
        }

        // Determine link columns
        const linkColsPool = Object.keys(linkColumns).filter(
            (col) => !linkColumns[col].skip || includedColumns.has(col)
        );
        const linkCols = displayLink ? linkColsPool : [];

        // Determine summary columns (cols for csv and table are different)
        const summColsPool = Object.keys(summaryColumns).filter(
            (col) => !summaryColumns[col].skip || includedColumns.has(col)
        );
        const summCols = displaySummary
            ? summColsPool.filter((col) => (displayCoral || col !== 'coralMap'))
            : [];
        const summColsCsv = summCols.filter((col) => summaryColumns[col].csv);

        // Gather columns together
        const csvColumns = [
            ...fragColsCsv,
            ...linkCols,
            ...perProteinColsCsv,
            ...summColsCsv,
        ];
        const tableColumns = [
            showImages ? 'svg' : 'name',
            ...perProteinColsTable,
            ...fragColsTable,
            ...linkCols,
            ...summCols,
        ];

        return { csvColumns, tableColumns };
    }

    getCsvString() {
        const { suggestions, columns } = this.getSortedSuggestions();
        return getCsvData(suggestions, columns.csvColumns);
    }

    // TODO: remove this
    fromDataService(suggOrSuggs) {
        const sugg = Array.isArray(suggOrSuggs) ? suggOrSuggs[0] : suggOrSuggs;
        return sugg?.origin === 'dataservice';
    }

    // TODO: switch away from tooltipster
    updateTooltips() {
        updateTooltipster({
            '#ligand_mod th': { side: 'top' },
            '.suggestionRow': { side: 'bottom' },
            '.suggestionRow button': { side: 'right' },
        });
    }

    show() {
        this.setState({ visible: true });
    }

    hide() {
        const { suggestions, sourceAtom } = this.state;
        const dataParents = App.getDataParents(sourceAtom);
        this.removeTempAtoms(suggestions);
        for (const sugg of suggestions) {
            App.Workspace.removeFragments(sugg.frags, dataParents);
            // Note: sending dataParents is necessary because these frags are
            // DecodedFragments and not Fragment MolAtomGroups.
            // Unlike MolAtomGroups, DecodedFragments are not able to look up their own
            // data parents.  It would be better to get rid of DecodedFragments and
            // actually build Fragment objects when decoding.
        }

        this.setState({
            visible: false,
            suggestions: [],
            uiInfo: new LigandModUIInfo('grow'),
            suggestionState: new Map(),
        });
        // Turn off bond vectors when closing grow suggestions
        EventBroker.publish('displayBondVectors', { hideAll: true });
        EventBroker.publish('modifyingCompound', false);
    }

    sortSuggestionFrags(sugg, sortPosesBy='position') {
        if (sugg.frags.length === 0) return;

        const unsortedFrags = sugg.frags;
        let sortedFrags;
        switch (sortPosesBy) {
            case 'position': {
                sortedFrags = [unsortedFrags.shift()];
                while (unsortedFrags.length > 0) {
                    let closestIndex = 0;
                    let minDistSq = 10000;
                    const nextFrag = sortedFrags[sortedFrags.length-1];
                    for (let i = 0; i<unsortedFrags.length; i++) {
                        const testFrag = unsortedFrags[i];
                        const distSq = Length3(Sub3_3(testFrag.translation, nextFrag.translation));
                        if (distSq < minDistSq) { minDistSq = distSq; closestIndex = i; }
                        //--- consider min dist early termination
                    }
                    sortedFrags.push(unsortedFrags[closestIndex]);
                    unsortedFrags.splice(closestIndex, 1);
                }
                break;
            }
            case 'bindingScore':
                sortedFrags = [...unsortedFrags];
                sortedFrags.sort((a, b) => a.exchemPotential - b.exchemPotential);
                break;
            default:
                // We don't know how to sort this way, so do nothing.
        }

        if (sortedFrags) {
            sugg.frags = sortedFrags;
            if (sugg.origin === 'bfd-server') {
                sugg.selectionIDs = sugg.frags.map((frag) => frag.poseSerialNo);
            }
        }
    }

    /**
     * @param {FragmentSearchResult[]} suggestions
     * @param {Atom} sourceAtom
     * @param {string} cmd
     * @param {FragList} fragList
    */
    async displaySuggestions(suggestionsIn, sourceAtom, cmd, fragList) {
        const { sortPosesBy } = this.state;

        // Add derived columns
        let hasMultipleFrags = false;
        const suggestionState = new Map();

        const suggestions = [];
        for (const [suggIx, suggIn] of suggestionsIn.entries()) {
            const sugg = new AnnotatedSearchResult(suggIn);
            sugg.index = suggIx;

            this.sortSuggestionFrags(sugg, sortPosesBy);

            // In suggestions from bfd-server, the fragList is associated with the target protein.
            // In suggestions from dataservice, there is no fraglist; instead the Workspace method
            // checks the fragLists for all loaded proteins.
            // Is it guaranteed that fragments with the same name will always have the same image?
            // I think so, but just in case, the bfd-server will use the protein's FragList.
            sugg.img = sugg.origin === 'bfd-server'
                ? await fragList.getFragImage(sugg.name) // bfd-server case
                : await App.Workspace.getSvgForFragment(sugg.name); // dataservice case
            sugg.selectedIndex = 0;
            if (sugg.frags.length > 1) hasMultipleFrags = true;
            suggestionState.set(sugg, {
                pinned: false,
            });
            suggestions.push(sugg);
        }

        console.log(`Displaying ${suggestions.length} fragment search results.`);

        this.setState({
            suggestions,
            sourceAtom,
            visible: true,
            suggestionState,
            uiInfo: new LigandModUIInfo(cmd, hasMultipleFrags), // labels & widget presence
        });
    }

    makeSuggestionMolecule(suggestion, fragIdx) {
        const { sourceAtom, displayFragmentHydrogens } = this.state;

        let fragCarbonColor = 0x00FF00;
        const frag = suggestion.frags[fragIdx];
        const hydrogenDisplay = displayFragmentHydrogens ? HydrogenDisplayOptions.all
            : HydrogenDisplayOptions.polar;
        const atoms = frag.atoms.filter((atom) => hydrogenCheck(atom, hydrogenDisplay));
        const isSelected = fragIdx === suggestion.selectedIndex;
        const opacity = isSelected ? 1 : 0.7;
        if (suggestion.origin === 'dataservice'
            && SelectivityColoring.selectivityActive()
            && suggestion.projectCases.length > 1) {
            fragCarbonColor = SelectivityColoring.getBaseColor(frag.projectCase);
        }

        const Cspec = { C: fragCarbonColor };
        let atomPair = [];
        if (suggestion.modType !== 'Near' && isSelected && suggestion.fragAtomName && sourceAtom) {
            // Draw bond highlight
            const [fragAtom] = frag.atoms.filter((x) => x.atom === suggestion.fragAtomName);
            if (fragAtom) atomPair = [fragAtom, sourceAtom];
        }
        return makeTempMolecule(atoms, MolStyles.sticks, Cspec, opacity, atomPair);
    }

    updateSuggestionMolecule(suggestion, fragIdx) {
        const opacity = fragIdx === suggestion.selectedIndex ? 1 : 0.7;
        const style = MolStyles.sticks;
        const model = suggestion.tmpModels[fragIdx];
        if (model) {
            updateTempMolecule(model, { style, opacity });
        }
    }

    suggestionEnter(suggestion, { force, skipRedisplay }={}) {
        if (!force && this.isPinned(suggestion)) return; // already visible
        // Frag atoms (exclude non-polar hydrogens)
        suggestion.tmpModels = [];
        for (const [fragIdx, frag] of suggestion.frags.entries()) {
            if (!frag) continue;
            suggestion.tmpModels.push(this.makeSuggestionMolecule(suggestion, fragIdx));
        }
        if (!skipRedisplay) {
            redisplay();
        }
    }

    suggestionExit(suggestion, { force, skipRedisplay }={}) {
        if (!force && this.isPinned(suggestion)) return; // leave it visible
        this.removeTempAtoms([suggestion], { skipRedisplay });
    }

    suggestionAccept(suggestion) {
        this.suggestionExit(suggestion, { force: true });
        const { sourceAtom, addMultipleFrags } = this.state;
        UserActions.ConfirmModification(suggestion, sourceAtom);
        if (!addMultipleFrags) {
            this.hide();
        }
    }

    cycleSuggestions(suggestion, index) {
        const prevIndex = suggestion.selectedIndex;
        suggestion.selectedIndex = index;
        // removeTempMolecule(suggestion.tmpModels[prevIndex]);
        this.updateSuggestionMolecule(suggestion, prevIndex);
        this.updateSuggestionMolecule(suggestion, suggestion.selectedIndex);
        // suggestion.tmpModels[prevIndex] = this.makeSuggestionMolecule(suggestion, prevIndex);
        // removeTempMolecule(suggestion.tmpModels[index]);
        // suggestion.tmpModels[index]     = this.makeSuggestionMolecule(suggestion, index);
        redisplay();
    }

    togglePin(sugg) {
        this.setState((oldState) => {
            const suggestionState = oldState.suggestionState.get(sugg);
            suggestionState.pinned = !suggestionState.pinned;
            // Assigning the new Map object to the state field triggers
            // and update, rerendering the necessary children
            return { suggestionState: new Map(oldState.suggestionState) };
        });
    }

    isPinned(sugg) {
        const { suggestionState } = this.state;
        return suggestionState.get(sugg).pinned;
    }

    // Remove temp atoms shown when hovering on frag rows
    removeTempAtoms(suggestions, { skipRedisplay }={}) {
        if (suggestions.length === 0) return;
        for (const suggestion of suggestions) {
            if (suggestion.tmpModels && suggestion.tmpModels.length > 0) {
                for (const tmpModel of suggestion.tmpModels) {
                    removeTempMolecule(tmpModel);
                }
                suggestion.tmpModels = [];
            }
        }
        if (!skipRedisplay) {
            redisplay();
        }
    }

    toggleImages() {
        this.setState((oldState) => ({ showImages: !oldState.showImages }));
    }

    // Display the fragment table table
    render() {
        if (renderDebug) console.log('Rendering LigandMod');
        const {
            visible, sortCol, sortAscending, uiInfo, showImages, sourceAtom,
            addMultipleFrags, displayFragmentHydrogens, highlightActiveFrags, activityThresholdInfo,
            showOptions, includedColumns, sortPosesBy,
        } = this.state;
        if (!visible) return false;

        const { suggestions: sortedSuggestions, columns } = this.getSortedSuggestions();
        // Report the number of fragments that were searched for bfd-server searches
        // since not all of the fragments can be loaded at once.
        const showSearchInfo = sortedSuggestions[0]?.origin === 'bfd-server';
        const { caseData } = App.getDataParents(sourceAtom);
        const searchFrags = App.Workspace.getActiveFragments(caseData).length;
        const totalFrags = caseData.getAvailableFragmentInfo().items().length;
        const projectCases = AnnotatedSearchResult.getProjectCasesForSuggestions(sortedSuggestions);
        const isMultiProtein = projectCases.length > 1;

        return (
            <div id="ligand_mod" className="canvasPopup">
                <div className="ligand_mod_title">
                    <LinkLikeButton className="ligand_mod_close" style={{ color: 'black' }} onClick={this.hide} aria-label="Close"><i className="fa fa-close" /></LinkLikeButton>
                    <div className="ligand_mod_caption">
                        Found
                        {' '}
                        {sortedSuggestions.length}
                        {' '}
                        {uiInfo.caption}
                    </div>
                    { showSearchInfo && (
                        <div className="ligand_mod_searchinfo">
                            <span>
                                Searched poses for
                                {' '}
                                {searchFrags}
                                {' '}
                                out of
                                {' '}
                                {totalFrags}
                                {' '}
                                total fragments
                            </span>
                            <LinkLikeButton style={{ color: 'var(--marketing-blue)' }} title="Manage fragment search" onClick={() => UserActions.OpenFragmentManager()}>
                                Manage
                                {' '}
                                searchable
                                {' '}
                                frags
                            </LinkLikeButton>
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '0 1em',
                        }}
                    >
                        <LinkLikeButton
                            style={{ color: 'var(--marketing-blue)', fontSize: 'inherit' }}
                            onClick={this.handleShowOptions}
                        >
                            <i className="fa fa-cog" />
                            {' '}
                            Options
                        </LinkLikeButton>
                        <LinkLikeButton
                            title="Click this button to download the fragment search results in CSV format"
                            style={{
                                color: 'var(--marketing-blue)',
                                fontSize: 'inherit',
                                paddingTop: 0,
                                paddingBottom: 0,
                                textAlign: 'end',
                            }}
                            onClick={this.handleCsvDownload}
                        >
                            <i className="fa fa-download" />
                            {' '}
                            CSV
                        </LinkLikeButton>
                    </div>
                    { !!showOptions && (
                        <div style={{ textAlign: 'start', padding: '0 1em' }}>
                            <div>
                                <label
                                    htmlFor="ligandmod-add-multiple-frags"
                                    title="Check this box to keep the search results open after adding a fragment."
                                >
                                    <input
                                        id="ligandmod-add-multiple-frags"
                                        type="checkbox"
                                        checked={addMultipleFrags}
                                        onChange={this.handleMultipleFrags}
                                    />
                                    {' '}
                                    Add multiple fragments
                                </label>
                            </div>
                            <div>
                                <label
                                    htmlFor="ligandmod-show-hydrogens"
                                    title="Check this box to display all the hydrogens on the fragment suggestions."
                                >
                                    <input
                                        id="ligandmod-show-hydrogens"
                                        type="checkbox"
                                        checked={displayFragmentHydrogens}
                                        onChange={this.handleFragmentHydrogens}
                                    />
                                    {' '}
                                    Show fragment Hs
                                </label>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <label
                                    htmlFor="ligandmod-highlight-active-results"
                                >
                                    <input
                                        id="ligandmod-highlight-active-results"
                                        type="checkbox"
                                        checked={highlightActiveFrags}
                                        onChange={this.handleHighlightActiveFrags}
                                    />
                                    {' '}
                                    Binding score highlight
                                </label>
                                {!!highlightActiveFrags && (
                                    <>
                                        <span style={{ padding: '0 0.5em' }}>
                                            (
                                            <span style={{ backgroundColor: ActiveTargetColor }}>
                                                Target
                                            </span>
                                            {' '}
                                            <span style={{ backgroundColor: ActiveOffTargetColor }}>
                                                Off-Target
                                            </span>
                                            )
                                        </span>
                                        Threshold:
                                        <EnergySlider
                                            value={activityThresholdInfo.activityThreshold}
                                            onChange={this.handleActivityThreshold}
                                            min={-20}
                                            max={0}
                                            step={0.5}
                                            style={{ flexGrow: 1 }}
                                            disabled={!highlightActiveFrags}
                                        />
                                        <span>
                                            {activityThresholdInfo.activityThreshold.toFixed(1)}
                                        </span>
                                    </>
                                )}
                            </div>
                            { uiInfo.usingSlider && (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label
                                        htmlFor="ligandmod-sort-poses-by"
                                    >
                                        <input
                                            id="ligandmod-sort-poses-by"
                                            type="checkbox"
                                            checked={sortPosesBy === 'bindingScore'}
                                            onChange={(evt) => {
                                                const val = evt.target.checked ? 'bindingScore' : 'position';
                                                this.handleSortPosesBy(val);
                                            }}
                                        />
                                        {' '}
                                        In pose selector, sort poses by binding score
                                    </label>
                                </div>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                Columns:
                                <label
                                    htmlFor="ligandmod-include-ligEff"
                                    style={{ marginLeft: '0.5em' }}
                                >
                                    <input
                                        id="ligandmod-include-ligEff"
                                        type="checkbox"
                                        checked={includedColumns.has('ligandEfficiency')}
                                        onChange={() => this.toggleColumns('ligandEfficiency')}
                                    />
                                    {' '}
                                    Lig. Effcy.
                                </label>
                                <label
                                    htmlFor="ligandmod-include-enSolute"
                                    style={{ marginLeft: '0.5em' }}
                                >
                                    <input
                                        id="ligandmod-include-enSolute"
                                        type="checkbox"
                                        checked={includedColumns.has('enSolute')}
                                        onChange={() => this.toggleColumns('enSolute')}
                                    />
                                    {' '}
                                    Interaction energy
                                </label>
                                { isMultiProtein && (
                                    <label
                                        htmlFor="ligandmod-include-targetCounts"
                                        style={{ marginLeft: '0.5em' }}
                                    >
                                        <input
                                            id="ligandmod-include-targetCounts"
                                            type="checkbox"
                                            checked={includedColumns.has('activeTargetCount')}
                                            onChange={() => this.toggleColumns(
                                                'activeTargetCount',
                                                'activeOffTargetCount',
                                                'selectivityRatio'
                                            )}
                                        />
                                        {' '}
                                        Target counts
                                    </label>
                                )}
                                { uiInfo.displayLinkType && (
                                    <label
                                        htmlFor="ligandmod-include-fragAtom"
                                        style={{ marginLeft: '0.5em' }}
                                    >
                                        <input
                                            id="ligandmod-include-fragAtom"
                                            type="checkbox"
                                            checked={includedColumns.has('fragAtomName')}
                                            onChange={() => this.toggleColumns('fragAtomName')}
                                        />
                                        {' '}
                                        Frag. attachment atom
                                    </label>
                                )}
                                <label
                                    htmlFor="ligandmod-include-posebuckets"
                                    style={{ marginLeft: '0.5em' }}
                                    title="In the CSV download, include columns with the number of poses in each binding score bucket"
                                >
                                    {' '}
                                    <input
                                        id="ligandmod-include-posebuckets"
                                        type="checkbox"
                                        checked={includedColumns.has('poseBucket0_1')}
                                        onChange={() => this.toggleColumns(
                                            'poseBucket0_1',
                                            'poseBucket1_2',
                                            'poseBucket2_3',
                                            'poseBucket3_4',
                                            'poseBucket4_5',
                                            'poseBucket5_6',
                                            'poseBucket6_7',
                                            'poseBucket7_8',
                                            'poseBucket8_9',
                                            'poseBucket9_10',
                                            'poseBucket10_11',
                                            'poseBucket11_12',
                                            'poseBucket12_13',
                                            'poseBucket13_14',
                                            'poseBucket14_15',
                                            'poseBucket15_16',
                                            'poseBucket16_17',
                                            'poseBucket17_18',
                                            'poseBucket18_19',
                                            'poseBucket19_20',
                                            'poseBucket20_X',
                                            'poseBucketX_0',
                                        )}
                                    />
                                    {' '}
                                    CSV pose buckets
                                </label>
                                <label
                                    htmlFor="ligandmod-include-enSoluteAvg"
                                    style={{ marginLeft: '0.5em' }}
                                >
                                    <input
                                        id="ligandmod-include-enSoluteAvg"
                                        type="checkbox"
                                        checked={includedColumns.has('enSoluteAvg')}
                                        onChange={() => this.toggleColumns('enSoluteAvg')}
                                    />
                                    {' '}
                                    Avg inter. energy
                                </label>
                            </div>
                        </div>
                    )}
                </div>
                <div className="scroller" ref={this.scrollingRef}>
                    { sortedSuggestions.length > 0 ? (
                        <SuggestionTable
                            sortedSuggestions={sortedSuggestions}
                            columns={columns.tableColumns}
                            uiInfo={uiInfo}
                            sortCol={sortCol}
                            sortAscending={sortAscending}
                            handleSort={this.handleSort}
                            showImages={showImages}
                            toggleImages={this.toggleImages}
                            suggestionAccept={this.suggestionAccept}
                            suggestionEnter={this.suggestionEnter}
                            suggestionExit={this.suggestionExit}
                            cycleSuggestions={this.cycleSuggestions}
                            isPinned={this.isPinned}
                            togglePin={this.togglePin}
                            activityInfo={{ highlightActiveFrags, activityThresholdInfo }}
                        />
                    ) : (
                        <table>
                            <tbody>
                                <tr key="no-suggestions-row" className="suggestionRow"><td colSpan="4">Sorry, no fragments found.</td></tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        );
    }
}
