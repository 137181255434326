import { useSelector, connect } from 'react-redux';
import store from '../store';
import { setPreference as setPreferenceRedux, setPreferences as setPreferencesRedux } from '.';
import { BackgroundColors, DefaultBackgroundColor } from '../../themes';

// Fundamental preference functions
export function getPreferences() {
    return store.getState().prefs;
}

export function getPreference(pref) {
    const allPrefs = getPreferences();
    return allPrefs[pref];
}

export function setPreferences(prefs) {
    store.dispatch(setPreferencesRedux(prefs));
}

export function setPreference(pref, val) {
    store.dispatch(setPreferenceRedux(pref, val));
}

// Color scheme functions
export function getColorScheme() {
    return getPreference('ColorScheme');
}

/**
 * @returns {BackgroundColors[keyof BackgroundColors]}
 */
export function getColorSchemeInfo() {
    const color = getColorScheme();
    return BackgroundColors[color];
}

export function setColorScheme(val=DefaultBackgroundColor) {
    return setPreference('ColorScheme', val);
}

/**
 * Custom hook to select the current color info from the redux store.
 * Currently, just the name is in the store; this does the lookup and returns the full color info.
 * @returns { {name: string, css: string, hex: number, textCss: string,
 *             textHex: number, style: string} }
 */
export function useColorSchemeInfo() {
    const key = useSelector((state) => state.prefs.ColorScheme);
    return BackgroundColors[key];
}

/**
 * HOC (Higher-Order Component) to pass colorSchemeInfo (from Redux) into a component.
 * The colorSchemeInfo is defined in themes.js and contains these fields:
 *     name - 'black' | 'white' | 'green' | 'cyan' | 'tan'
 *     hex - background color as a hex number (eg 0x0A0A0A)
 *     css - background color as a css string (eg #0A0A0A)
 *     textHex - text color as a hex number
 *     testCss - text color as a css string
 *     style - 'dark' | 'light'
 */
export function withColorSchemeInfo(component) {
    // stateToProps function for React-Redux connection
    function stateToProps(state) {
        const backgroundColor = state.prefs['ColorScheme'];
        const colorSchemeInfo = BackgroundColors[backgroundColor];
        return { colorSchemeInfo };
    }

    return connect(stateToProps)(component);
}

export function withColorSchemeInfoStyled(component) {
    // stateToProps function for React-Redux connection
    function stateToProps(state) {
        const backgroundColor = state.prefs['ColorScheme'];
        const colorSchemeInfo = BackgroundColors[backgroundColor];
        return { $colorSchemeInfo: colorSchemeInfo };
    }
    function dispatchToProps() { return {}; }
    return connect(stateToProps, dispatchToProps)(component);
}

export function getBindingSiteDistance() {
    return getPreference('BindingSiteDistance');
}

export function setBindingSiteDistance(val) {
    return setPreference('BindingSiteDistance', val);
}

export function stepBindingSiteDistance(delta) {
    return setPreference('BindingSiteDistance', getBindingSiteDistance() + delta);
}

export function getUseAutoGiFE() {
    return getPreference('UseAutoGiFE');
}
