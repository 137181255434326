import { EventBroker } from './eventbroker';

// Class to wrap the permissions object.
// This could be extended to also store and report operation counts.
export class PermissionManager {
    constructor(dataConnection) {
        this.dataConnection = dataConnection;
        this.permissions = {};
        this.operationCounts = {
            limits: {},
            done: {},
            remaining: {},
        };
        this.periodEnd = null;
    }

    setPermissions(permissions={}) {
        this.permissions = permissions;
        EventBroker.publish('setPermissions', permissions);
    }

    setOperationCounts(counts={}) {
        this.operationCounts.limits = counts.policy_limits || {};
        this.operationCounts.activity = counts.activity || {};
        this.operationCounts.remaining = counts.remaining || {};
        this.periodEnd = new Date(counts.period_end);
    }

    isAllowed(action) {
        return this.permissions[action];
    }

    anyLeft(action) {
        return this.operationCounts.remaining[action] > 0;
    }

    getPeriodResetDate() {
        if (!this.periodEnd) return null;
        const newPeriod = new Date(this.periodEnd);
        newPeriod.setDate(this.periodEnd.getDate() + 1);
        return newPeriod;
    }

    getCounts(action) {
        const result = {};
        result.limit = this.operationCounts.limits?.[action] ?? 0;
        result.done = this.operationCounts.activity?.[action] ?? 0;
        result.remaining = this.operationCounts.remaining?.[action] ?? 0;
        result.periodEnd = this.periodEnd;
        result.nextPeriodStart = this.getPeriodResetDate();
        return result;
    }

    async refresh() {
        const connector = this.getConnector();
        const result = await connector.cmdGetPermissions();
        this.setOperationCounts(result.operation_counts);
        this.setPermissions(result.permissions);
    }

    getConnector() {
        return this.dataConnection.getConnector();
    }
}
