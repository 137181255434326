/**
 * UserCmd.js
 * Classes to encapsulate user actions in BMaps
 *
 * The following concepts are defined:
 * UserCmd: is a possible kind of action, but without specific arguments (analogous to a class)
 * UserAction: is an the "instantiation" of the UserCmd, with arguments
 * UserActions is an object containing functions for to run all the UserCmds. (dynamically built)
 *
 * Normal use is to import UserActions from UserAction.js and call the functions on it.
 *
 * Action Queue:
 * Actions are put into a queue to hopefully allow for command undo/redo.
 * EventBroker "zapAll" is subscribed, so "ActionLog" and "UndoQueue" can be cleared on zap.
 */

import { EventBroker } from '../eventbroker';

// A UserCmd is a potential user action.
// It contains the logic, but not the arguments.
export class UserCmd {
    constructor(name, doFn, options={}) {
        this.name = name;
        this.doFn = doFn;
        this.undoFn = options.undoFn;
        this.redoFn = options.redoFn;
        this.skipQueue = options.skipQueue;
    }

    do(...args) {
        return UserAction.do(this, ...args);
    }
}

// A UserAction is something the user has done.
// It is a UserCmd (a potential action) plus arguments.
// A UserAction can theoretically be undone and redone.
export class UserAction {
    static do(cmd, ...args) {
        return new UserAction(cmd, ...args).do();
    }

    static undo() {
        if (UserAction.canUndo()) {
            UserAction.PrevAction.undo();
        } else {
            console.warn('Cannot Undo');
        }
    }

    static redo() {
        if (UserAction.canRedo()) {
            UserAction.NextAction.redo();
        } else {
            console.warn('Cannot Redo');
        }
    }

    static canUndo() {
        return UserAction.PrevAction && UserAction.PrevAction.cmd.undoFn;
    }

    static canRedo() {
        return UserAction.NextAction;
    }

    constructor(cmd, ...args) {
        this.cmd = cmd;
        this.args = args;
    }

    do() {
        const cmd = this.cmd;
        const result = cmd.doFn(...this.args);
        UserAction.Enqueue(this);
        return result;
    }

    undo() {
        const cmd = this.cmd;
        if (cmd.undoFn) {
            UserAction.UndoIndex--;
            return cmd.undoFn(...this.args);
        } else {
            console.warn('Attempted to undo un-undoable action.');
            return undefined;
        }
    }

    redo() {
        const cmd = this.cmd;
        // Not exactly sure how redo will be used, but this allows a custom "redo" function,
        // or fallback to "do" function
        const verb = cmd.redoFn ? cmd.redoFn : cmd.doFn;
        if (UserAction.UndoQueue[UserAction.UndoIndex] === this) {
            UserAction.UndoIndex++;
            verb(...this.args);
        } else {
            console.warn('Attempted to redo the wrong action.');
        }
    }

    // Action queue managment
    static setupUndoQueue() {
        if (!UserAction.undoQueue) {
            UserAction.undoQueue = [];
            UserAction.undoIndex = 0;
        }
    }

    static get ActionLog() {
        if (!UserAction.actionLog) {
            UserAction.actionLog = [];
            UserAction.zapHandler = EventBroker.subscribe('zapAll', () => UserAction.ResetQueues());
        }
        return UserAction.actionLog;
    }

    static get UndoQueue() {
        UserAction.setupUndoQueue();
        return UserAction.undoQueue;
    }

    static get UndoIndex() {
        UserAction.setupUndoQueue();
        return UserAction.undoIndex;
    }

    static set UndoIndex(index) {
        UserAction.setupUndoQueue();
        UserAction.undoIndex = index;
    }

    static get PrevAction() {
        UserAction.setupUndoQueue();
        return UserAction.UndoQueue[UserAction.UndoIndex-1];
    }

    static get NextAction() {
        UserAction.setupUndoQueue();
        return UserAction.UndoQueue[UserAction.UndoIndex];
    }

    static Enqueue(action) {
        UserAction.ActionLog.push(action);
        if (!action.cmd.skipQueue) {
            UserAction.UndoQueue.splice(UserAction.UndoIndex);
            UserAction.UndoQueue.push(action);
            UserAction.UndoIndex++;
        }
    }

    static ResetQueues() {
        UserAction.actionLog = [];
        UserAction.undoQueue = [];
        UserAction.undoIndex = 0;
    }

    static DumpQueues() {
        const dumpOneQueue = (q, active) => q.map((a, i) => `${active===i?'*':''}${a.cmd.name}`).join('\n');
        console.log(`Action log: ${dumpOneQueue(UserAction.ActionLog)}`);
        console.log(`Undo queue: ${dumpOneQueue(UserAction.UndoQueue, UserAction.UndoIndex)}`);
    }
}

// CmdRegistry is an object with functions that will execute
// every possible UserCmd
export const UserActions = {

};

export const CustomActions = {
    Compound: [],
    // CompoundGroup: [],
    // CompoundView: [],
    // Fragment: [],
    // FragmentGroup: [],
    // FragmentView: [],
    // ProteinView: [],
    // HotspotGroup: [],
};

export function AddUserActions(cmdObj, childKey) {
    const host = childKey ? UserActions[childKey] : UserActions;
    for (const [cmdName, cmdFn] of Object.entries(cmdObj)) {
        if (host[cmdName]) console.warn(`${cmdName} is already defined as a user action${childKey ? `on ${childKey}` : ''}. Overriding...`);
        host[cmdName] = (...args) => UserAction.do(cmdFn, ...args);
    }
}

export function AddCustomAction(type, actions) {
    for (const action of [].concat(actions)) {
        CustomActions[type].push(action);
    }
}

const UndoActions = {
    Undo: new UserCmd('Undo', UserAction.undo, { skipQueue: true }),
    Redo: new UserCmd('Redo', UserAction.redo, { skipQueue: true }),
};

AddUserActions(UndoActions);
