import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { BackgroundColors } from 'BMapsSrc/themes';

/**
 * @param {import("@mui/material").TooltipProps} param0
 * @returns
 *
 * Applies the styles used by tooltipster to the MUI tooltip
 */
export default function BMapsTooltip({
    children, noMaxWidth, useColorScheme, ...props
}) {
    const colorScheme = useSelector((state) => state.prefs['ColorScheme']);

    // These styles are used by tooltipster in infoDisplay.css
    const popupBgColors = {
        black: '#333333',
        white: '#e5e5e5',
        green: '#1e4c30',
        cyan: '#3f7f7a',
        tan: '#d8d6cd',
    };

    let color = popupBgColors['black'];
    let fontColor = BackgroundColors['black'].textCss;
    if (useColorScheme) {
        color = popupBgColors[colorScheme];
        fontColor = BackgroundColors[colorScheme].textCss;
    }

    return (
        <Tooltip
            arrow
            componentsProps={{
                tooltip: {
                    sx: noMaxWidth
                        ? {
                            backgroundColor: color,
                            color: fontColor,
                            fontSize: '1rem',
                            opacity: 1,
                            maxWidth: 'none',
                        }
                        : {
                            backgroundColor: color,
                            color: fontColor,
                            fontSize: '1rem',
                            opacity: 1,
                        },
                },
                arrow: {
                    sx: {
                        color,
                        opacity: 1,
                    },
                },
            }}
            {...props}
        >
            {children}
        </Tooltip>
    );
}
