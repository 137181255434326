// cmds/DisplayCmds.js
/**
 * @fileoverview
 * UserActions for altering the display to use import {UserActions} from 'BMapsCmds
 *
 * @typedef {import('BMapsSrc/style_manager').MolStyles} MolStyles
 * @typedef {import('BMapsModel').AtomGroup} AtomGroup
 * @typedef {import('BMapsModel').AtomGroupStyle} AtomGroupStyle
 * @typedef {import('BMapsModel').Atom} Atom
 * @typedef {import('BMapsModel').TreeItem} TreeItem
 *
 * @typedef DisplayCmds
 * @property {dumpWorkspace} DumpWorkspace
 * @property {stepBindingSiteRadius} StepBindingSiteRadius
 * @property {removeCase} RemoveCase
 * @property {setView} SetView
 * @property {resetView} ResetView
 * @property {setDisplayStyle} SetDisplayStyle
 * @property {setHydrogenView} SetHydrogenView
 * @property {setWaterView} SetWaterView
 * @property {setHBonds} SetHBonds
 * @property {setBindingSiteSurface} SetBindingSiteSurface
 * @property {setLigandSolvation} SetLigandSolvation
 * @property {setHotspots} SetHotspots
 * @property {setFragmentEnergyFilter} SetFragmentEnergyFilter
 * @property {showBfactor} ShowBfactor
 * @property {setColorScheme} SetColorScheme
 * @property {activateCompound} ActivateCompound
 * @property {activateNextCompound} ActivateNextCompound
 * @property {toggleVisibility} ToggleVisibility
 * @property {toggleSelection} ToggleSelection
 * @property {toggleExpansion} ToggleExpansion
 * @property {moveTreeItem} MoveTreeItem
 * @property {groupCreate} GroupCreate
 * @property {groupItems} GroupItems
 * @property {groupCreate} GroupCreate
 * @property {groupRename} GroupRename
 * @property {groupUngroup} GroupUngroup
 * @property {groupDelete} GroupDelete
 * @property {groupSort} GroupSort
 * @property {changeTreeSort} ChangeTreeSort
 * @property {changeHotspotThreshold} ChangeHotspotThreshold
 * @property {setCustomAtomGroupStyle} SetCustomAtomGroupStyle
 * @property {removeCustomAtomGroupStyle} RemoveCustomAtomGroupStyle
 * @property {setCustomAtomsStyle} SetCustomAtomsStyle
 * @property {removeCustomAtomsStyle} RemoveCustomAtomsStyle
 * @property {setGlobalStyleState} SetGlobalStyleState
 * @property {redisplay} Redisplay
 * @property {redrawScene} RedrawScene
 * @property {toggleStarred} ToggleStarred
 * @property {setPiPiStacking} SetPiPiStacking
 */

import { StyleManager, DefaultMolStyleCode } from 'BMapsSrc/style_manager';
import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { UserActions, UserAction, UserCmd } from './UserCmd';
import { setColorScheme as updateColorScheme, stepBindingSiteDistance } from '../redux/prefs/access';

/** @type {DisplayCmds} */
export const DisplayCmds = {
    DumpWorkspace: new UserCmd('DumpWorkspace', dumpWorkspace),
    StepBindingSiteRadius: new UserCmd('StepBindingSiteRadius', stepBindingSiteRadius, { undoFn: undoStepBindingSiteRadius }),
    RemoveCase: new UserCmd('RemoveCase', removeCase),
    // These need undo functions that revert to the previous state.
    SetView: new UserCmd('SetView', setView),
    ResetView: new UserCmd('ResetView', resetView),
    SetDisplayStyle: new UserCmd('SetDisplayStyle', setDisplayStyle),
    SetHydrogenView: new UserCmd('SetHydrogenView', setHydrogenView),
    SetWaterView: new UserCmd('SetWaterView', setWaterView),
    SetHBonds: new UserCmd('SetHBonds', setHBonds),
    SetPiPiStacking: new UserCmd('SetPiPiStacking', setPiPiStacking),
    SetBindingSiteSurface: new UserCmd('SetBindingSiteSurface', setBindingSiteSurface),
    SetLigandSolvation: new UserCmd('SetLigandSolvation', setLigandSolvation),
    SetHotspots: new UserCmd('SetHotspots', setHotspots),
    SetFragmentEnergyFilter: new UserCmd('setFragmentEnergyFilter', setFragmentEnergyFilter),
    ShowBfactor: new UserCmd('ShowBfactor', showBfactor),

    SetColorScheme: new UserCmd('SetColorScheme', setColorScheme),
    ActivateCompound: new UserCmd('ActivateCompound', activateCompound),
    ActivateNextCompound: new UserCmd('ActivateNextCompound', activateNextCompound),
    ToggleVisibility: new UserCmd('ToggleVisibility', toggleVisibility),
    ToggleSelection: new UserCmd('ToggleSelection', toggleSelection),
    ToggleExpansion: new UserCmd('ToggleExpansion', toggleExpansion),
    ToggleStarred: new UserCmd('ToggleStarred', toggleStarred),

    MoveTreeItem: new UserCmd('MoveTreeItem', moveTreeItem),
    GroupCreate: new UserCmd('GroupCreate', groupCreate),
    GroupItems: new UserCmd('GroupItems', groupItems),
    GroupRename: new UserCmd('GroupRename', groupRename),
    GroupUngroup: new UserCmd('GroupUngroup', groupUngroup),
    GroupDelete: new UserCmd('GroupDelete', groupDelete),
    GroupSort: new UserCmd('GroupSort', groupSort),
    ChangeTreeSort: new UserCmd('ChangeTreeSort', changeTreeSort),

    ChangeHotspotThreshold: new UserCmd('ChangeHotspotThreshold', changeHotspotThreshold),
    SetCustomAtomGroupStyle: new UserCmd('SetCustomAtomGroupStyle', setCustomAtomGroupStyle),
    RemoveCustomAtomGroupStyle: new UserCmd('RemoveCustomAtomGroupStyle', removeCustomAtomGroupStyle),
    SetCustomAtomsStyle: new UserCmd('SetCustomAtomsStyle', setCustomAtomsStyle),
    RemoveCustomAtomsStyle: new UserCmd('RemoveCustomAtomssStyle', removeCustomAtomsStyle),
    SetGlobalStyleState: new UserCmd('SetGlobalStyleState', setGlobalStyleState),
    Redisplay: new UserCmd('Redisplay', redisplay),
    RedrawScene: new UserCmd('RedrawScene', redrawScene),
};

function dumpWorkspace() {
    console.log(App.Workspace.dumpState());
    UserAction.DumpQueues();
}

async function removeCase(caseData) {
    const { connector } = App.getDataParents(caseData);
    await UserActions.RemoveConnection(connector);
    // Refresh selectivity coloring for remaining molecules
    EventBroker.publish('refreshAtomDisplay');
    EventBroker.publish('redisplayRequest');
}

function stepBindingSiteRadius(delta) {
    stepBindingSiteDistance(delta);
}

function undoStepBindingSiteRadius(delta) {
    stepBindingSiteRadius(-1 * delta);
}

// UserActions for updating view state
// Consider moving these to another file
function setView(view) {
    App.Workspace.displayStateController.setView(view);
}

function resetView() {
    App.Workspace.displayStateController.reset();
    // Right now a change in protein style only won't reset the display,
    // so need to force it here.
    EventBroker.publish('redrawSceneRequest');
}

function setDisplayStyle(style, selectorFn) {
    EventBroker.publish('setDisplayStyle', { style, selectorFn });
}

function setHydrogenView(hydrogens) {
    App.Workspace.displayStateController.setHydrogens(hydrogens);
}

function setWaterView(waters) {
    App.Workspace.displayStateController.setWaters(waters);
}

function setHBonds(hbondMode) {
    App.Workspace.displayStateController.setHBonds(hbondMode);
}

function setPiPiStacking(show) {
    App.Workspace.displayStateController.setPiPiStacking(show);
}

function setBindingSiteSurface(surfaceMode) {
    App.Workspace.displayStateController.setBindingSiteSurface(surfaceMode);
}

function setLigandSolvation(show) {
    App.Workspace.displayStateController.setLigandSolvation(show);
}

function setHotspots(show) {
    App.Workspace.displayStateController.setHotspots(show);
}

function showBfactor(show) {
    App.Workspace.displayStateController.setBfactor(show);
}

function setFragmentEnergyFilter(fragmentInfo, value) {
    App.Workspace.updateAtomGroupState(fragmentInfo, { energyFilterValue: value });
}

function setColorScheme(color) {
    updateColorScheme(color);
}

function activateCompound(compound) {
    App.Workspace.activateCompound(compound);
}

function activateNextCompound(step) {
    App.Workspace.activateNextCompound(step);
}

function toggleVisibility(component, forceValue, evtName) {
    App.Workspace.toggleVisibility(component, forceValue, evtName);
}

function toggleStarred(component, forceValue, evtName) {
    App.Workspace.toggleStarred(component, forceValue, evtName);
}

function toggleSelection(component, forceValue, evtName) {
    App.Workspace.toggleSelection(component, forceValue, evtName);
}

function toggleExpansion(groupItem, forceValue, evtName) {
    App.Workspace.toggleExpansion(groupItem, forceValue, evtName);
}

/**
 * @param {string} treeName
 * @param {TreeItem} movingItem
 * @param {{
 *     fromIndexPath?: number[],
 *     toIndexPath?: number[],
 *     addAtItem?: TreeItem,
 *     addAfterItem?: TreeItem,
 *     addInItem?: TreeItem,
 * }} pathOptions
 */
function moveTreeItem(treeName, movingItem, pathOptions) {
    App.Workspace.moveTreeItem(treeName, movingItem, pathOptions);
}

/**
 * @param {string} groupName
 * @param {string} treeName
 * @param {{
 *     toItem?: TreeItem
 *     toIndexPath?: number[],
 * }} pathOptions
 */
function groupCreate(groupName, treeName, pathOptions) {
    return App.Workspace.groupCreate(groupName, treeName, pathOptions);
}

function groupItems(items, treeName, path, groupInfo) {
    return App.Workspace.groupItems(items, treeName, path, groupInfo);
}

function groupRename(group, newName) {
    App.Workspace.groupRename(group, newName);
}

function groupUngroup(group, treeName) {
    App.Workspace.groupUngroup(group, treeName);
}

/**
 * @param {{ treeItem, indexPath }|[{ treeItem, indexPath }]} groupOrGroups
 * @param {string} treeName
 */
function groupDelete(groupOrGroups, treeName) {
    App.Workspace.groupDelete(groupOrGroups, treeName);
}

/**
 * groupSort() - Sort a group in the tree control
 * @param {TreeGroup} group
 * @param {{ sortType: "Alphabetical" | "EnergyScore" }} sortOptions
 */
function groupSort(group, sortOptions) {
    App.Workspace.groupSort(group, sortOptions);
}

function changeTreeSort(treeName, sortType) {
    App.Workspace.changeTreeSort(treeName, sortType);
}

function changeHotspotThreshold(threshold) {
    App.Workspace.changeHotspotThreshold(threshold);
}

/**
 * @param {AtomGroup} atomGroup
 * @param {AtomGroupStyle} style
 */
function setCustomAtomGroupStyle(atomGroup, style={}) {
    if (style.molStyle) {
        // If it's the default style, change to 'default' so it will be cleared instead of stored
        const defaultStyle = App.Workspace.getDefaultAtomGroupStyle(atomGroup);
        if (style.molStyle === defaultStyle) style.molStyle = DefaultMolStyleCode;
    }

    StyleManager.setCustomAtomGroupStyle(atomGroup, style);
}

/**
 * @param {AtomGroup} atomGroup
 * @param {keyof AtomGroupStyle} styleType
 * styleType is an optional argument. If you want to remove all styles just pass in atomGroup
 */
function removeCustomAtomGroupStyle(atomGroup, styleType) {
    StyleManager.removeCustomAtomGroupStyle(atomGroup, styleType);
}

/**
 * @param {Atom[]} atoms
 * @param {MolStyles[keyof MolStyles]} style
 */
function setCustomAtomsStyle(atoms, style) {
    StyleManager.setCustomAtomsStyle(atoms, style);
}

/** @param {Atom[]} atoms */
function removeCustomAtomsStyle(atoms) {
    StyleManager.removeCustomAtomsStyle(atoms);
}

/**
 * @param {{
 *  ProteinViewProteinStyle?: MolStyles[keyof MolStyles]
 *  LigandViewProteinStyle?: MolStyles[keyof MolStyles]
 *  LabelStyle?: import('BMapsSrc/style_manager').labelSpec
 * }} obj
 */
function setGlobalStyleState(obj) {
    StyleManager.setGlobalStyleState(obj);
}

/**
 * Redraw existing 3D atoms.
 */
function redisplay() {
    EventBroker.publish('redisplayRequest');
}

/**
 * Redraw the whole scene according to current settings.
 * @param {{ forceProteinView: boolean, recenter: boolean }} options
 */
function redrawScene(options) {
    EventBroker.publish('redrawSceneRequest', options);
}
