import { WebSocketConn } from './WebSocketConn';

export class BrowserWebSocketConn extends WebSocketConn {
    wsCreate(url) {
        console.log(`BrowserWebSocketConn: creating new instance for ${url} at ${new Date()}`);
        let ws;
        if ('WebSocket' in window) {
            ws = new WebSocket(url);
        } else if ('MozWebSocket' in window) {
            ws = new MozWebSocket(url);
        } else {
            console.log('BrowserWebSocketConn: This Browser does not support WebSockets!');
            return null;
        }
        ws.binaryType = 'arraybuffer';
        ws.createdAt = Date.now();
        console.log(`BrowserWebSocketConn: websocketURL ${ws.url}`);
        return ws;
    }

    /* eslint-disable no-param-reassign */
    wsSubscribe(ws, callbacks) {
        ws.onopen = callbacks.onOpen;
        ws.onerror = callbacks.onError;
        ws.onclose = callbacks.onClose;
        ws.onmessage = (evt) => callbacks.onMessage(evt.data);
    }

    wsUnsubscribe(ws, callbacks) {
        ws.onopen = undefined;
        ws.onerror = undefined;
        ws.onclose = undefined;
        ws.onmessage = undefined;
    }
    /* eslint-enable no-param-reassign */

    wsSend(ws, msg) {
        ws.send(msg);
    }

    wsUrl(ws) {
        return ws && ws.url;
    }

    wsConnectionInfo(ws) {
        return {
            agent: this.connectionProperties?.agent,
            key: this.connectionProperties?.key,
            port: this.connectionProperties?.port,
            createdAt: ws.createdAt,
            isOpen: this.wsIsOpen(ws),
        };
    }

    wsIsClosed(ws) {
        return ws && ws.readyState === WebSocket.CLOSED;
    }

    wsIsOpen(ws) {
        return ws && ws.readyState === WebSocket.OPEN;
    }

    wsClose(ws) {
        ws.close();
    }
}
