/**
 * TheManual.jsx
 * A React Component to show instructions on how to use BMaps
 *
 */

import React from 'react';
import { ModalManager } from '../ModalManager';
import { CookieManager } from '../../CookieManager';
import {
    MainPage, Cheatsheet, ChooseProteinPage, AddCompoundPage,
    ModifyCompoundPage, DockCompoundPage, FragmentDataPage,
    EvaluatePage,
} from './pages';
import { MainTour } from '../Tour';
import LinkLikeButton from '../common/LinkLikeButton';

export class TheManual extends React.Component {
    static Show(manPageArgs) {
        TheManual.ShowPage(TheManual.Pages.Main, manPageArgs);
    }

    static ShowPage(page, manPageArgs, modalPaneOptions) {
        ModalManager.Show(TheManual.ModalArgs(page, manPageArgs, modalPaneOptions));
    }

    static Toggle(manPageArgs) {
        TheManual.TogglePage(TheManual.Pages.Main, manPageArgs);
    }

    static TogglePage(page, manPageArgs, modalPaneOptions) {
        ModalManager.Toggle(TheManual.ModalArgs(page, manPageArgs, modalPaneOptions));
    }

    static ModalArgs(page, manPageArgs, modalPaneOptions) {
        return {
            title: page.paneTitle || 'Guide',
            content: <TheManual page={page} args={manPageArgs} />,
            style: { width: '90vw', maxWidth: 'unset', height: '80%' },
            options: modalPaneOptions,
        };
    }

    static ShowOnStartup() {
        const cookieName = TheManual.StartupCookieName;
        return !CookieManager.exists(cookieName) || !CookieManager.get(cookieName);
    }

    static get StartupCookieName() {
        return 'nomanual';
    }

    constructor(props) {
        super(props);
        this.keypressHandler = this.keypressHandler.bind(this);
        this.handleShowManualChange = this.handleShowManualChange.bind(this);
    }

    componentDidMount() {
        document.body.addEventListener('keyup', this.keypressHandler);
    }

    componentWillUnmount() {
        document.body.removeEventListener('keyup', this.keypressHandler);
    }

    handleShowManualChange(evt) {
        this.toggleShowOnStartup(!evt.target.checked);
    }

    keypressHandler(evt) {
        switch (evt.key) {
            case 'ArrowRight':
                this.showNextPage();
                break;
            case 'ArrowLeft':
                this.showPrevPage();
                break;
            // no default; ignore other keystrokes
        }
    }

    toggleShowOnStartup(showOnStartup) {
        // The cookie means "don't show on startup," so cookie actions are reversed
        if (showOnStartup) {
            CookieManager.clear(TheManual.StartupCookieName);
        } else {
            CookieManager.set(TheManual.StartupCookieName, 'set', -1);
        }
        this.forceUpdate();
    }

    showNextPage() {
        const pages = Object.values(TheManual.Pages);
        const pagesCount = pages.length;
        const { page } = this.props;
        const curIndex = page.index;
        const nextIndex = (curIndex + 1) % pagesCount;
        const nextPage = pages.find((x) => x.index === nextIndex);
        TheManual.ShowPage(nextPage, undefined, { alreadyOpen: true });
    }

    showPrevPage() {
        const pages = Object.values(TheManual.Pages);
        const pagesCount = pages.length;
        const { page } = this.props;
        const curIndex = page.index;
        const prevIndex = (curIndex + pagesCount - 1) % pagesCount;
        const prevPage = pages.find((x) => x.index === prevIndex);
        TheManual.ShowPage(prevPage, undefined, { alreadyOpen: true });
    }

    render() {
        const mainPage = TheManual.Pages.Main;
        const { page, args } = this.props;
        const checkbox = page === mainPage;
        const Page = page.component;
        const pages = Object.values(TheManual.Pages);

        return (
            <div id="theManual">
                <NavBar pages={pages} curIndex={page.index} />
                <div id="theManual_content">
                    <button type="button" className="theManual_navArrows" onClick={() => this.showPrevPage()}>
                        <i className="fa fa-caret-left" />
                    </button>
                    <div id="theManual_page">
                        <Page manual={TheManual} title={page.title} args={args} />
                        {checkbox
                        && (
                        <div id="theManual_checkbox">
                            <label htmlFor="theManual_checkbox_ctrl">
                                <i>Don&apos;t show this screen when BMaps starts</i>
                                <input
                                    id="theManual_checkbox_ctrl"
                                    type="checkbox"
                                    style={{ marginLeft: '10px', verticalAlign: 'middle' }}
                                    checked={!TheManual.ShowOnStartup()}
                                    onChange={this.handleShowManualChange}
                                />
                            </label>
                            <input
                                type="button"
                                value="View Tour"
                                className="startTourButton"
                                onClick={() => {
                                    ModalManager.Hide();
                                    MainTour.start();
                                }}
                            />
                        </div>
                        )}
                    </div>
                    <button type="button" className="theManual_navArrows" onClick={() => this.showNextPage()}>
                        <i className="fa fa-caret-right" />
                    </button>
                </div>
            </div>
        );
    }
}

function NavBar({ pages: pagesIn, curIndex }) {
    let pages = pagesIn;
    pages.sort((first, second) => first.index - second.index);
    const currentPage = pages[curIndex];

    const firstIndex = 0;
    const lastIndex = pages.length - 1;
    let showLeftDots = (curIndex - 2) > firstIndex;
    let showRightDots = (curIndex + 2) < lastIndex;
    const onFirstPage = curIndex === firstIndex;
    const onLastPage = curIndex === lastIndex;

    const pageIndicesToShow = new Set();
    if (!onFirstPage && !onLastPage) {
        pageIndicesToShow.add(curIndex);
    }

    // Special treatment for Welcome and Cheatsheet:
    // don't show indiviual manual pages in the nav bar, just "Manual"
    if (onFirstPage || onLastPage) {
        pages = [
            pages[firstIndex], // Main page
            {
                ...pages[firstIndex+1],
                menuTitle: 'Manual',
            },
            pages[lastIndex], // Cheatsheet
        ];
        showLeftDots = false;
        showRightDots = false;
        pageIndicesToShow.add(1);
    } else {
        // We're on an manual page, so show the sibling manual links.
        for (let i = 1; pageIndicesToShow.size < 3 && i < lastIndex; i++) {
            const left = curIndex - i;
            const right = curIndex + i;
            if (left > firstIndex) pageIndicesToShow.add(left);
            if (right < lastIndex) pageIndicesToShow.add(right);
        }
    }
    const pageIndices = [...pageIndicesToShow.values()].sort((first, second) => first - second);

    const MaybeLink = ({ page: pageIn, index, addSeparator }) => {
        const page = pageIn || pages[index];
        const title = page.menuTitle || page.title;
        return (
            <span>
                <NavLink page={page} isCurrent={page === currentPage}>
                    {title}
                </NavLink>
                {addSeparator && ' | '}
            </span>
        );
    };

    return (
        <div id="theManual_navBar">
            <div style={{
                // This style is for when the Guide navigation is in the dark green title bar
                backgroundColor: 'rgba(255,255,255,0.8)', display: 'inline-block', borderRadius: '0.5em',
            }}
            >
                <MaybeLink index={firstIndex} />
                {showLeftDots ? ' ... ' : ' | '}
                {pageIndices.map((pgIx, arrIx, arr) => (
                    <MaybeLink
                        index={pgIx}
                        addSeparator={arrIx+1 !== arr.length}
                        key={pages[pgIx].index}
                    />
                ))}
                {showRightDots ? ' ... ' : ' | '}
                <MaybeLink page={pages[pages.length-1]} />
            </div>
        </div>
    );
}

function NavLink({ page, children, isCurrent }) {
    const style = {
        color: 'var(--marketing-blue)',
        whiteSpace: 'nowrap',
    };
    if (isCurrent) {
        style.color = 'black';
        style.cursor = 'default';
    }
    return (
        <LinkLikeButton
            onClick={() => TheManual.ShowPage(page)}
            style={style}
        >
            {children}
        </LinkLikeButton>
    );
}

TheManual.Pages = {
    Main: {
        title: 'Welcome Page',
        component: MainPage,
        index: 0,
    },
    ChooseProtein: {
        title: 'Viewing proteins',
        component: ChooseProteinPage,
        index: 1,
    },
    AddCompound: {
        title: 'Adding compounds',
        component: AddCompoundPage,
        index: 2,
    },
    DockCompound: {
        title: 'Docking compounds',
        component: DockCompoundPage,
        index: 3,
    },
    ModifyCompound: {
        title: 'Modifying compounds',
        component: ModifyCompoundPage,
        index: 4,
    },
    Evaluate: {
        title: 'Evaluating compounds',
        component: EvaluatePage,
        index: 5,
    },
    FragmentData: {
        title: 'Creating water maps',
        component: FragmentDataPage,
        index: 6,
    },
    Cheatsheet: {
        title: 'Cheatsheet',
        component: Cheatsheet,
        index: 7,
    },
};
