import Grid from '@mui/material/Grid';
import { useExportDataFetch } from '../exportUtils';
import { DialogSubmitButton } from '../../../common/DialogActionButtons';
import { ErrorMessage, Notice } from '../Components';

const configs = {
    formats: ['sdf', 'smiNoName'],
    allowMultiple: false,
    allowStaticMode: true,
    allowMultipleProteins: false,
    disallowAtoms: true,
};

export default function ExportNPDBTab({ selected }) {
    const { errors, formattedData, context } = useExportDataFetch(selected, configs, 'Natural Products DB');
    const { exportTarget, setLoading, isMobile } = context;
    const exportSmiData = formattedData['smiNoName'];

    let errMsg;
    if (errors.length > 0) {
        errMsg = errors[0];
    } else if (!exportSmiData) {
        errMsg = 'Failed to prepare SMILES for Natural Products DB query';
    }

    return (
        <Grid container spacing={0} direction="column" alignItems="left">
            <Grid item>
                <div style={{ marginBottom: '1em' }}>
                    Natural Product Database is an annotated collection of natural products,
                    compiling target activity, biosynthetic information, and other properties.
                </div>
            </Grid>
            <Grid item>
                <div>
                    {errMsg ? (
                        <Notice title="Problem with Natural Products DB integration">
                            <ErrorMessage>{errMsg}</ErrorMessage>
                        </Notice>
                    ) : (
                        <div>
                            <p>
                                <DialogSubmitButton $isMobile={isMobile} className="npdb_link" href={`http://npdb-search.boltzmannmaps.com/?structure=${encodeURIComponent(exportSmiData)}`} target="_blank" rel="noreferrer">Natural Product Substructure Search</DialogSubmitButton>
                            </p>
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
}
