import Link from '@mui/material/Link';

export default function ExternalLink({
    children, link, style: styleIn={}, skipIcon,
}) {
    const style = { color: 'blue', ...styleIn };
    return (
        <Link href={link} style={style} target="_blank" rel="noreferrer">
            {children}
            {!skipIcon && (
                <sup><i style={{ marginLeft: '.25em' }} className="fa fa-external-link" /></sup>
            )}
        </Link>
    );
}
