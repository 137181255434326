// bfm_viewer.js

// Manage the molecular viewer.
import { viewerMaker, resizeCanvas } from './MainCanvas';

// Paste and Drag and Drop
import { initDropHandling } from './paste_drag';

import { EventBroker } from './eventbroker';

export function makeBFMViewer(viewerOptions={}) {
    const canvasParentId = 'canvas_wrapper';
    const options = { parentEltId: canvasParentId, ...viewerOptions };
    viewerMaker(options);
    // Initialize the Tour first so that it gets keyboard events before
    // the rest of bmaps (EventBroker publishing is set up in info_display (not InfoDisplay.jsx)).
    // This lets the tour ignore keyboard evts based on various elt visibility.
    // Tour wouldn't be able to ignore Escape if Escape had already closed the
    // pane it was checking the visibility of!
    resizeViewer(canvasParentId); // first time sizing
    $(window).resize(() => { resizeViewer(canvasParentId); });
    EventBroker.subscribe('resize', () => { resizeViewer(canvasParentId); });
    initDropHandling();
    console.log('BFM viewer initialized.');
}

function resizeViewer(viewer) {
    // Use all the viewport except for the title bar.
    const bfv = $(`#${viewer}`);
    const tb = $('#topbar');
    const tlbHeight = tb.outerHeight(true); // title bar plus margin
    const winHeight = $(window).innerHeight(); // viewport height
    const width = $(window).innerWidth(); // viewport width
    const height = winHeight - tlbHeight;
    // Update the bfm viewer container.
    const leftSidePanelWidth = $('#leftsidepanel').innerWidth() || 0;
    const rightSidePanelWidth = $('#rightsidepanel').innerWidth() || 0;
    bfv.width(width - leftSidePanelWidth - rightSidePanelWidth);
    bfv.height(height);
    // We could resize the canvas ourselves, the but the underlying visualization packages
    // need to know about it too, to update the gl.viewport and such.
    resizeCanvas(width, height);
}
