// Material UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function InputError({ error }) {
    return (
        // NOTE: padding of InputError's Grid is based on TextField's padding so the text is aligned
        <Grid item container style={{ padding: '0 14px', height: '2ex' }}>
            {error && (
                <Typography
                    color="error"
                    variant="caption"
                    style={{
                        fontSize: '0.65em',
                    }}
                >
                    {error}
                </Typography>
            )}
        </Grid>
    );
}
