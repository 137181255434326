import { App } from 'BMapsSrc/BMapsApp';
import { MoleculeLoadOptions } from 'BMapsSrc/utils';
import { is2dFormat, isBinaryFormat } from 'BMapsUtil/mol_format_utils';

export function fileFilterAcceptedTypes(allowedTypes, includeBmaps=true) {
    const types = [...allowedTypes];
    if (includeBmaps) types.push('bmaps');

    // "accepted" attribute needs to have dots for extension names
    return types
        .map((x) => (x.startsWith('.') ? x : `.${x}`))
        .join(',');
}

/**
 * Return a list of compound placement options for the given format and caseData (loaded protein).
 *
 * Return format: [
 *     {
 *         label: The text for the select option
 *         placementType:
 *                  - Align  - Align to selected atoms or a compound
 *                  - Retain - Retain existing coordinates
 *                  - Place  - Place outside the protein (or default if no protein)
 *         value: The unique value for a non-disabled select option:
 *                "Retain" and "Place" types are already unique, so the value is the placementType.
 *                For "Align" types, the value must be qualified.
 *                If it is to align to a valid compound, value is `Align:<cmpd spec>`
 *                If it is to align to selected atoms, value is `Align-atoms`
 *                Disabled options don't have a value
 *         disabled: whether the option is disabled
 *         title: Tooltip text for enabled options
 *         disabledMsg: tooltip text for disabled options
 *     }, ...
 * ]
 * @param {string} dataFormat
 * @param {CaseData} caseData
 */
export function getPlacementOptions(dataFormat, caseData) {
    const hasProtein = !!caseData.mapCase;

    // Placement options about atoms
    const allSelectedAtoms = App.Workspace.getSelectedAtoms();
    const mySelectedAtoms = allSelectedAtoms.filter(
        (atom) => App.getDataParents(atom).caseData === caseData
    );
    const atomsDisabled = allSelectedAtoms.length === 0
        || mySelectedAtoms.length !== allSelectedAtoms.length;
    const atomOptions = [{
        label: (allSelectedAtoms.length > 1)
            ? 'Center between selected atoms'
            : 'Center on selected atom',
        placementType: PlacementTypes.Align,
        value: !atomsDisabled ? PlacementTypes.alignAtomsValue() : '',
        disabled: atomsDisabled,
        disabledMsg: mySelectedAtoms.length !== allSelectedAtoms.length
            ? 'Select atoms from only this protein to use as a center for compound placement'
            : 'Select some atoms in the 3D workspace to use them as a center for compound placement.',
    }];

    // Placement options about compounds - ligands
    const compoundOptions = [];
    // const ligands = App.Workspace.getLigands();
    const ligands = caseData.getLigands();
    if (ligands.length > 0) {
        for (const ligand of ligands) {
            compoundOptions.push({
                label: `Align to co-crystal ligand ${ligand.resSpec}`,
                ligandspec: ligand.resSpec,
                placementType: PlacementTypes.Align,
                value: PlacementTypes.alignCompoundValue(ligand),
            });
        }
    } else {
        compoundOptions.push({
            label: 'Align to co-crystal ligand',
            placementType: PlacementTypes.Align,
            disabled: true,
            disabledMsg: hasProtein
                ? "This protein doesn't have a crystal ligand to use a reference for compound placement"
                : 'Load a protein to use a crystal ligand as a reference for compound placement.',
        });
    }

    // Placement options about compounds - active compound
    let activeCompound = App.Workspace.getActiveCompound();
    let activeCompoundLabel = 'Align to focus compound';
    let activeCompoundDisabledMsg = 'No compounds available yet. Add a compound to use it as a reference for compound placement.';
    if (activeCompound) {
        if (!caseData.hasAtomGroup(activeCompound)) {
            activeCompoundDisabledMsg = `The focus compound (${activeCompound.resSpec}) is on a different protein and can't be used for alignment.`;
            activeCompound = null;
        } else {
            activeCompoundLabel += ` (${activeCompound.resSpec})`;
        }
    }
    compoundOptions.push({
        label: activeCompoundLabel,
        placementType: PlacementTypes.Align,
        value: activeCompound ? PlacementTypes.alignCompoundValue(activeCompound) : '',
        ligandspec: activeCompound?.resSpec || '',
        disabled: !activeCompound,
        disabledMsg: activeCompoundDisabledMsg,
    });

    // Placement options about the protein
    const proteinOptions = [{
        label: hasProtein ? 'Place outside protein for now' : 'Default placement without protein',
        placementType: PlacementTypes.PlaceOutside,
        value: PlacementTypes.PlaceOutside,
    }];

    // Other placement options
    const retainDisabled = is2dFormat(dataFormat);
    const otherOptions = [
        {
            label: 'Keep existing coordinates',
            placementType: PlacementTypes.KeepExisting,
            value: !retainDisabled ? PlacementTypes.KeepExisting : '',
            disabled: retainDisabled,
            title: 'Use the 3D coordinates specified in the molecule data',
            disabledMsg: `This format (${dataFormat.toUpperCase()}) doesn't use 3D coordinates`,
        },
        // Consider options for when no protein is loaded:
        //       * center at (0,0,0)
        //       * position in 2D grid
    ];

    const allOptions = [...atomOptions, ...compoundOptions, ...proteinOptions, ...otherOptions];
    const availableOptions = allOptions.filter((x) => !x.disabled);
    const disabledOptions = allOptions.filter((x) => x.disabled);

    return { availableOptions, disabledOptions };
}

/**
 * @description Return values for text and format Import pane fields when
 * staging file MolSources in the import tool.
 * @param Array(MolDataSource) molSources
 */
export function importFieldsForMolSources(molSources, molDataText) {
    let text = '';
    let format = '';
    let textDisabled=false;
    let formatDisabled=false;

    if (molSources.length > 1) {
        text = 'Multiple files ready for import:\n    ';
        text += molSources.map(({ sourceId }) => sourceId).join('\n    ');
        format = molSources[0].molFormat;
        // Show blank format selector if not all the same
        for (const ms of molSources) {
            if (ms.molFormat !== format) {
                format = 'multiple';
                break;
            }
        }

        // For multiple files, disable format and data textarea
        formatDisabled = true;
        textDisabled = true;
    } else if (molSources.length === 1) {
        const molSource = molSources[0];
        format = molSource.molFormat;
        if (isBinaryFormat(format)) {
            const filename = molSource['sourceId'];
            text = filename
                ? `Binary file ready for import:\n    ${filename}`
                : 'Binary data ready for import.';
            // For binary files, disable format and data textarea
            formatDisabled = true;
            textDisabled = true;
        } else {
            // One text molSource, put in the text area
            text = molSource.molData;

            // If the form text matches the file upload text, the file extension will be used
            // instead of the form format input, so disable the form input.
            if (text === molDataText) {
                formatDisabled = true;
            }
        }
    }
    return {
        text, format, textDisabled, formatDisabled,
    };
}

/**
 * @param {string} positioning
 * @param {boolean} assignBondOrders
 * @param {string} refSpec
 * @param {boolean} [alreadyMinimized]
 * @returns
 */
export function getMoleculeOptions(positioning, assignBondOrders, refSpec, alreadyMinimized=false) {
    const options = {};
    switch (positioning) {
        case PlacementTypes.Align:
            options.alignAction = 'align';
            options.refSpec = refSpec;
            break;
        case PlacementTypes.KeepExisting:
            options.retainCoords = true;
            break;
        // no default
    }

    options.keepBonds = !assignBondOrders;
    options.alreadyMinimized = alreadyMinimized;
    return new MoleculeLoadOptions(options);
}

export const PlacementTypes = Object.freeze({
    Specify: 'Specify',
    Align: 'Align',
    KeepExisting: 'Retain',
    PlaceOutside: 'Place',
    alignAtomsValue() { return 'Align-atoms'; },
    alignCompoundValue(compound) { return `Align:${compound.resSpec}`; },
    isValidPlacement(placement) { return placement && placement !== PlacementTypes.Specify; },
});
