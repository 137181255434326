/* eslint-disable */

export const Parker = {
    name: '4zae_parker',
    mol2:
`@<TRIPOS>MOLECULE
4zae_parker
57   61    1
SMALL
USER_CHARGES


@<TRIPOS>ATOM
      1 C21       -14.6830  -32.5710  -16.1460 C.ar      1 4M1         0.0000 
      2 C22       -14.2980  -33.8210  -16.5770 C.ar      1 4M1         0.0000 
      3 C20       -15.0160  -32.3800  -14.8210 C.ar      1 4M1         0.0000 
      4 C23       -14.2420  -34.8820  -15.6860 C.ar      1 4M1         0.0000 
      5 C19       -14.9840  -33.4440  -13.9310 C.ar      1 4M1         0.0000 
      6 C2        -11.6620  -34.8970   -9.3320 C.ar      1 4M1         0.0000 
      7 C5        -14.2380  -34.1200   -8.6720 C.ar      1 4M1         0.0000 
      8 C12       -14.2300  -41.4300  -10.3090 C.ar      1 4M1         0.0000 
      9 C14       -16.3870  -42.1720  -11.0800 C.ar      1 4M1         0.0000 
     10 C17       -14.6950  -43.6200   -8.1840 C.ar      1 4M1         0.0000 
     11 C16       -15.6090  -44.7930   -9.7320 C.ar      1 4M1         0.0000 
     12 C10       -15.4460  -40.1100  -11.9490 C.ar      1 4M1         0.0000 
     13 C18       -14.5810  -34.7070  -14.3500 C.ar      1 4M1         0.0000 
     14 C1        -11.9110  -34.3660   -8.0700 C.ar      1 4M1         0.0000 
     15 C         -13.2230  -33.9720   -7.7320 C.ar      1 4M1         0.0000 
     16 C3        -12.6660  -35.0530  -10.2760 C.ar      1 4M1         0.0000 
     17 C13       -15.2600  -42.3690  -10.2840 C.ar      1 4M1         0.0000 
     18 C4        -13.9540  -34.6620   -9.9230 C.ar      1 4M1         0.0000 
     19 C11       -14.3300  -40.3280  -11.1400 C.ar      1 4M1         0.0000 
     20 C15       -16.4750  -41.0450  -11.8750 C.ar      1 4M1         0.0000 
     21 C6        -14.0300  -35.4560  -12.0210 C.ar      1 4M1         0.0000 
     22 C9        -15.4930  -38.9760  -12.9360 C.2       1 4M1         0.0000 
     23 C24       -10.7970  -34.3050   -7.0600 C.3       1 4M1         0.0000 
     24 C25       -13.5650  -33.5360   -6.3290 C.3       1 4M1         0.0000 
     25 C8        -15.8950  -36.5880  -13.2760 C.3       1 4M1         0.0000 
     26 C7        -14.5110  -35.8860  -13.3850 C.3       1 4M1         0.0000 
     27 N5        -14.8180  -44.8290   -7.7190 N.2       1 4M1         0.0000 
     28 N4        -15.3960  -45.5910   -8.7270 N.2       1 4M1         0.0000 
     29 N1        -12.7450  -35.5250  -11.5820 N.pl3     1 4M1         0.0000 
     30 N3        -15.1880  -43.5310   -9.4470 N.pl3     1 4M1         0.0000 
     31 N         -14.7580  -34.9290  -11.0130 N.2       1 4M1         0.0000 
     32 N2        -15.7590  -37.7670  -12.4370 N.am      1 4M1         0.0000 
     33 O         -15.3250  -39.1800  -14.1370 O.2       1 4M1         0.0000 
     34 CL1       -12.9960  -39.2290  -11.2150 Cl        1 4M1         0.0000 
     35 CL        -17.9450  -40.7660  -12.7510 Cl        1 4M1         0.0000 
     36 H21       -14.7228  -31.7477  -16.8439 H         1 4M1         0.0000 
     37 H22       -14.0379  -33.9785  -17.6133 H         1 4M1         0.0000 
     38 H20       -15.3032  -31.3990  -14.4724 H         1 4M1         0.0000 
     39 H23       -13.9321  -35.8585  -16.0279 H         1 4M1         0.0000 
     40 H19       -15.2739  -33.2956  -12.9013 H         1 4M1         0.0000 
     41 H2        -10.6618  -35.2024   -9.6016 H         1 4M1         0.0000 
     42 H5        -15.2456  -33.8146   -8.4314 H         1 4M1         0.0000 
     43 H12       -13.3608  -41.5650   -9.6823 H         1 4M1         0.0000 
     44 H14       -17.1863  -42.8983  -11.0736 H         1 4M1         0.0000 
     45 H17       -14.2464  -42.8499   -7.5740 H         1 4M1         0.0000 
     46 H16       -16.0656  -45.1714  -10.6346 H         1 4M1         0.0000 
     47 H241      -11.1682  -33.8635   -6.1351 H         1 4M1         0.0000 
     48 H242      -10.4315  -35.3123   -6.8601 H         1 4M1         0.0000 
     49 H243       -9.9833  -33.6950   -7.4522 H         1 4M1         0.0000 
     50 H251      -12.6533  -33.4738   -5.7349 H         1 4M1         0.0000 
     51 H252      -14.0460  -32.5583   -6.3589 H         1 4M1         0.0000 
     52 H253      -14.2432  -34.2604   -5.8780 H         1 4M1         0.0000 
     53 H81       -16.2301  -36.8861  -14.2695 H         1 4M1         0.0000 
     54 H82       -16.6148  -35.9038  -12.8267 H         1 4M1         0.0000 
     55 H7        -13.7970  -36.6068  -13.7834 H         1 4M1         0.0000 
     56 HN1       -11.9127  -35.8507  -12.0525 H         1 4M1         0.0000 
     57 HN2       -15.8737  -37.6459  -11.4409 H         1 4M1         0.0000 
@<TRIPOS>BOND
     1    1    2 ar   
     2    1    3 ar   
     3    1   36 1    
     4    2    4 ar   
     5    2   37 1    
     6    3    5 ar   
     7    3   38 1    
     8    4   13 ar   
     9    4   39 1    
    10    5   13 ar   
    11    5   40 1    
    12    6   14 ar   
    13    6   16 ar   
    14    6   41 1    
    15    7   15 ar   
    16    7   18 ar   
    17    7   42 1    
    18    8   17 ar   
    19    8   19 ar   
    20    8   43 1    
    21    9   17 ar   
    22    9   20 ar   
    23    9   44 1    
    24   10   27 ar   
    25   10   30 ar   
    26   10   45 1    
    27   11   28 ar   
    28   11   30 ar   
    29   11   46 1    
    30   12   19 ar   
    31   12   20 ar   
    32   12   22 1    
    33   13   26 1    
    34   14   15 ar   
    35   14   23 1    
    36   15   24 1    
    37   16   18 ar   
    38   16   29 ar   
    39   17   30 1    
    40   18   31 ar   
    41   19   34 1    
    42   20   35 1    
    43   21   26 1    
    44   21   29 ar   
    45   21   31 ar   
    46   22   32 am   BACKBONE|DICT|INTERRES
    47   22   33 2    
    48   23   47 1    
    49   23   48 1    
    50   23   49 1    
    51   24   50 1    
    52   24   51 1    
    53   24   52 1    
    54   25   26 1    
    55   25   32 1    
    56   25   53 1    
    57   25   54 1    
    58   26   55 1    
    59   27   28 ar   
    60   29   56 1    
    61   32   57 1    
@<TRIPOS>SUBSTRUCTURE
     1 4M1         1 GROUP             0 A     ****    0 ROOT    
`,
};

export const Benzene = {
    smi: 'c1ccccc1',
};

export const Acetamide = {
    name: 'acetamide',
    pdb:
`REMARK   4      COMPLIES WITH FORMAT V. 3.0, 1-DEC-2006
REMARK 888
REMARK 888 WRITTEN BY MAESTRO (A PRODUCT OF SCHRODINGER, LLC)
TITLE     acetamide
MODEL        1
HETATM    1  N1  UNK   900       0.990  -0.871   0.000  1.00  0.00           N  
HETATM    2  C1  UNK   900       0.080   0.153   0.000  1.00  0.00           C  
HETATM    3  O1  UNK   900       0.422   1.326   0.000  1.00  0.00           O  
HETATM    4  C2  UNK   900      -1.377  -0.291   0.000  1.00  0.00           C  
HETATM    5  H1  UNK   900       0.722  -1.843   0.000  1.00  0.00           H  
HETATM    6  H2  UNK   900       1.973  -0.639   0.001  1.00  0.00           H  
HETATM    7  H3  UNK   900      -2.012   0.596  -0.003  1.00  0.00           H  
HETATM    8  H4  UNK   900      -1.604  -0.894   0.888  1.00  0.00           H  
HETATM    9  H5  UNK   900      -1.603  -0.900  -0.884  1.00  0.00           H  
CONECT    1    2    5    6
CONECT    2    1    4    3
CONECT    2    3
CONECT    3    2
CONECT    3    2
CONECT    5    1
CONECT    6    1
CONECT    4    2    7    8    9
CONECT    7    4
CONECT    8    4
CONECT    9    4
ENDMDL
END   `,
};

export const Xylo = {
    name: 'xylo',
    sdf:
`cmpd_1
  BFDSrvr 1008240109
Written by the BMaps Web Application (bfd-server)[Conifer Point Consulting, LLC]
 20 20  0  0  0  0  0  0  0  0999 V2000
    0.5450    1.6877   -0.1451 C   0  0  0  0  0  0  0  0  0  1  0  0
    1.4034    0.4257   -0.1744 C   0  0  0  0  0  0  0  0  0  2  0  0
    0.5800   -0.7701    0.3011 C   0  0  0  0  0  0  0  0  0  3  0  0
   -0.7106   -0.8819   -0.5113 C   0  0  0  0  0  0  0  0  0  4  0  0
   -1.4662    0.4590   -0.5014 C   0  0  0  0  0  0  0  0  0  5  0  0
    2.5550    0.5785    0.6564 O   0  0  0  0  0  0  0  0  0  6  0  0
    1.3640   -1.9672    0.1563 O   0  0  0  0  0  0  0  0  0  7  0  0
   -1.5121   -1.9369    0.0460 O   0  0  0  0  0  0  0  0  0  8  0  0
   -1.9736    0.7004    0.8065 O   0  0  0  0  0  0  0  0  0  9  0  0
   -0.6496    1.5428   -0.9192 O   0  0  0  0  0  0  0  0  0 10  0  0
    0.2908    1.9732    0.8832 H   0  0  0  0  0  0  0  0  0 11  0  0
    1.1125    2.5289   -0.5584 H   0  0  0  0  0  0  0  0  0 12  0  0
    1.7607    0.2410   -1.1949 H   0  0  0  0  0  0  0  0  0 13  0  0
    0.3647   -0.6883    1.3737 H   0  0  0  0  0  0  0  0  0 14  0  0
   -0.4649   -1.1685   -1.5405 H   0  0  0  0  0  0  0  0  0 15  0  0
   -2.3278    0.3895   -1.1743 H   0  0  0  0  0  0  0  0  0 16  0  0
    2.9434   -0.3174    0.7198 H   0  0  0  0  0  0  0  0  0 17  0  0
    0.7298   -2.7058    0.2555 H   0  0  0  0  0  0  0  0  0 18  0  0
   -1.9603   -1.5416    0.8221 H   0  0  0  0  0  0  0  0  0 19  0  0
   -2.5844    1.4513    0.6988 H   0  0  0  0  0  0  0  0  0 20  0  0
  1  2  1  0  0  0  0
  1 10  1  0  0  0  0
  1 11  1  0  0  0  0
  1 12  1  0  0  0  0
  2  3  1  0  0  0  0
  2  6  1  0  0  0  0
  2 13  1  0  0  0  0
  3  4  1  0  0  0  0
  3  7  1  0  0  0  0
  3 14  1  0  0  0  0
  4  5  1  0  0  0  0
  4  8  1  0  0  0  0
  4 15  1  0  0  0  0
  5  9  1  0  0  0  0
  5 10  1  0  0  0  0
  5 16  1  0  0  0  0
  6 17  1  0  0  0  0
  7 18  1  0  0  0  0
  8 19  1  0  0  0  0
  9 20  1  0  0  0  0
M  END
$$$$
`,
    smi: 'O1[C@@H]([C@@H]([C@H]([C@@H](C1)O)O)O)O',
    mol2:
`#	Name:			unknown
#	Creation time:		Tue Oct  8, 2024 at  1:10:24
#	Creation user:		
#	Creation host:		dev-server
@<TRIPOS>MOLECULE
cmpd_1
   20    20     1     0     0
SMALL
USER_CHARGES


@<TRIPOS>ATOM
      1 C1           0.5450    1.6877   -0.1451 C.3       1 UNL          0.1304
      2 C2           1.4034    0.4257   -0.1744 C.3       1 UNL          0.1251
      3 C3           0.5800   -0.7701    0.3011 C.3       1 UNL          0.0721
      4 C4          -0.7106   -0.8819   -0.5113 C.3       1 UNL          0.0931
      5 C5          -1.4662    0.4590   -0.5014 C.3       1 UNL          0.2949
      6 O6           2.5550    0.5785    0.6564 O.3       1 UNL         -0.5968
      7 O7           1.3640   -1.9672    0.1563 O.3       1 UNL         -0.6148
      8 O8          -1.5121   -1.9369    0.0460 O.3       1 UNL         -0.5988
      9 O9          -1.9736    0.7004    0.8065 O.3       1 UNL         -0.6278
     10 O10         -0.6496    1.5428   -0.9192 O.3       1 UNL         -0.4186
     11 H11          0.2908    1.9732    0.8832 H         1 UNL          0.0687
     12 H12          1.1125    2.5289   -0.5584 H         1 UNL          0.0687
     13 H13          1.7607    0.2410   -1.1949 H         1 UNL          0.0547
     14 H14          0.3647   -0.6883    1.3737 H         1 UNL          0.0657
     15 H15         -0.4649   -1.1685   -1.5405 H         1 UNL          0.1007
     16 H16         -2.3278    0.3895   -1.1743 H         1 UNL          0.0787
     17 H17          2.9434   -0.3174    0.7198 H         1 UNL          0.4290
     18 H18          0.7298   -2.7058    0.2555 H         1 UNL          0.4270
     19 H19         -1.9603   -1.5416    0.8221 H         1 UNL          0.4250
     20 H20         -2.5844    1.4513    0.6988 H         1 UNL          0.4250
@<TRIPOS>BOND
    1      1      2 1
    2      1     10 1
    3      1     11 1
    4      1     12 1
    5      2      3 1
    6      2      6 1
    7      2     13 1
    8      3      4 1
    9      3      7 1
   10      3     14 1
   11      4      5 1
   12      4      8 1
   13      4     15 1
   14      5      9 1
   15      5     10 1
   16      5     16 1
   17      6     17 1
   18      7     18 1
   19      8     19 1
   20      9     20 1
`,
    pdb:
`REMARK 77 Written by the BMaps Web Application.
HETATM    1  C1  UNL Z   4       0.545   1.688  -0.145  1.00  0.00           C  
HETATM    2  C2  UNL Z   4       1.403   0.426  -0.174  1.00  0.00           C  
HETATM    3  C3  UNL Z   4       0.580  -0.770   0.301  1.00  0.00           C  
HETATM    4  C4  UNL Z   4      -0.711  -0.882  -0.511  1.00  0.00           C  
HETATM    5  C5  UNL Z   4      -1.466   0.459  -0.501  1.00  0.00           C  
HETATM    6  O6  UNL Z   4       2.555   0.578   0.656  1.00  0.00           O  
HETATM    7  O7  UNL Z   4       1.364  -1.967   0.156  1.00  0.00           O  
HETATM    8  O8  UNL Z   4      -1.512  -1.937   0.046  1.00  0.00           O  
HETATM    9  O9  UNL Z   4      -1.974   0.700   0.807  1.00  0.00           O  
HETATM   10  O10 UNL Z   4      -0.650   1.543  -0.919  1.00  0.00           O  
HETATM   11  H11 UNL Z   4       0.291   1.973   0.883  1.00  0.00           H  
HETATM   12  H12 UNL Z   4       1.112   2.529  -0.558  1.00  0.00           H  
HETATM   13  H13 UNL Z   4       1.761   0.241  -1.195  1.00  0.00           H  
HETATM   14  H14 UNL Z   4       0.365  -0.688   1.374  1.00  0.00           H  
HETATM   15  H15 UNL Z   4      -0.465  -1.168  -1.541  1.00  0.00           H  
HETATM   16  H16 UNL Z   4      -2.328   0.389  -1.174  1.00  0.00           H  
HETATM   17  H17 UNL Z   4       2.943  -0.317   0.720  1.00  0.00           H  
HETATM   18  H18 UNL Z   4       0.730  -2.706   0.255  1.00  0.00           H  
HETATM   19  H19 UNL Z   4      -1.960  -1.542   0.822  1.00  0.00           H  
HETATM   20  H20 UNL Z   4      -2.584   1.451   0.699  1.00  0.00           H  
TER      21      UNL Z   4                                                      
CONECT    1    2   10   11   12
CONECT    2    1    3    6   13
CONECT    3    2    4    7   14
CONECT    4    3    5    8   15
CONECT    5    4    9   10   16
CONECT    6    2   17          
CONECT    7    3   18          
CONECT    8    4   19          
CONECT    9    5   20          
CONECT   10    1    5          
CONECT   11    1               
CONECT   12    1               
CONECT   13    2               
CONECT   14    3               
CONECT   15    4               
CONECT   16    5               
CONECT   17    6               
CONECT   18    7               
CONECT   19    8               
CONECT   20    9               
END
`,
    cdxml:
`<fragment>
 <n id="21" p="2219.422860 869.422860"/>
 <n id="22" p="1440.000000 1319.422860"/>
 <n id="23" p="1440.000000 2219.422860"/>
 <n id="24" p="2219.422860 2669.422860"/>
 <n id="25" p="2998.845720 2219.422860"/>
 <n id="26" p="540.000000 1319.422860" Element="8"/>
 <n id="27" p="990.000000 2998.845720" Element="8"/>
 <n id="28" p="2669.422860 3448.845720" Element="8"/>
 <n id="29" p="3898.845720 2219.422860" Element="8"/>
 <n id="30" p="2998.845720 1319.422860" Element="8"/>
 <n id="31" p="1769.422860 90.000000" Element="1"/>
 <n id="32" p="2669.422860 90.000000" Element="1"/>
 <n id="33" p="990.000000 540.000000" Element="1"/>
 <n id="34" p="540.000000 2219.422860" Element="1"/>
 <n id="35" p="1769.422860 3448.845720" Element="1"/>
 <n id="36" p="3448.845720 2998.845720" Element="1"/>
 <n id="37" p="90.000000 540.000000" Element="1"/>
 <n id="38" p="1440.000000 3778.268580" Element="1"/>
 <n id="39" p="2219.422860 4228.268580" Element="1"/>
 <n id="40" p="4348.845720 1440.000000" Element="1"/>
 <b B="21" E="22"/>
 <b B="21" E="30"/>
 <b B="21" E="31"/>
 <b B="21" E="32"/>
 <b B="22" E="23"/>
 <b B="22" E="26"/>
 <b B="22" E="33"/>
 <b B="23" E="24"/>
 <b B="23" E="27"/>
 <b B="23" E="34"/>
 <b B="24" E="25"/>
 <b B="24" E="28"/>
 <b B="24" E="35"/>
 <b B="25" E="29"/>
 <b B="25" E="30"/>
 <b B="25" E="36"/>
 <b B="26" E="37"/>
 <b B="27" E="38"/>
 <b B="28" E="39"/>
 <b B="29" E="40"/>
</fragment>
`,
};

export const OneXJ = {
    name: '1XJ',
    smi: 'c1c(C2=C(CC(CC2)(C)C)CN2CCN(CC2)c2ccc(C(=O)NS(=O)(=O)c3cc(S(=O)(=O)C(F)(F)F)c(N[C@@H](CSc4ccccc4)CCN4CCOCC4)cc3)cc2)ccc(Cl)c1',
};

export const JAK3Ligand = {
    name: '4ST',
    smi: 'CO[C@H]1[C@@]2(O[C@@H](n3c4CCCCc4c4c5C(=O)NCc5c5c(c34)n2c2c5cccc2)C[C@H]1NC)C',
};

export const FourK6_no_H = {
    name: '4K6_no_H',
    mol:
`4K6
Ketcher 12 41822592D 1   1.00000     0.00000     0

 27 30  0     0  0            999 V2000
   -1.5000   -2.5981    0.0000 O   0  0  0  0  0  0  0  0  0  1  0  0
   -2.0000   -1.7321    0.0000 C   0  0  0  0  0  0  0  0  0  2  0  0
   -3.0000   -1.7321    0.0000 C   0  0  0  0  0  0  0  0  0  3  0  0
   -3.5000   -0.8660    0.0000 C   0  0  0  0  0  0  0  0  0  4  0  0
   -4.5000   -0.8660    0.0000 C   0  0  0  0  0  0  0  0  0  5  0  0
   -5.0000   -1.7321    0.0000 C   0  0  0  0  0  0  0  0  0  6  0  0
   -6.0000   -1.7321    0.0000 N   0  0  0  0  0  0  0  0  0  7  0  0
   -6.5878   -0.9231    0.0000 C   0  0  0  0  0  0  0  0  0  8  0  0
   -7.5388   -1.2321    0.0000 N   0  0  0  0  0  0  0  0  0  9  0  0
   -7.5388   -2.2321    0.0000 N   0  0  0  0  0  0  0  0  0 10  0  0
   -6.5878   -2.5411    0.0000 C   0  0  0  0  0  0  0  0  0 11  0  0
   -4.5000   -2.5981    0.0000 C   0  0  0  0  0  0  0  0  0 12  0  0
   -3.5000   -2.5981    0.0000 C   0  0  0  0  0  0  0  0  0 13  0  0
   -1.5000   -0.8660    0.0000 N   0  0  0  0  0  0  0  0  0 14  0  0
   -0.5000   -0.8660    0.0000 C   0  0  0  0  0  0  0  0  0 15  0  0
    0.0000    0.0000    0.0000 C   0  0  0  0  0  0  0  0  0 16  0  0
    1.0000    0.0000    0.0000 C   0  0  0  0  0  0  0  0  0 17  0  0
    1.5878    0.8090    0.0000 N   0  0  0  0  0  0  0  0  0 18  0  0
    2.5388    0.5000    0.0000 C   0  0  0  0  0  0  0  0  0 19  0  0
    3.4049    1.0000    0.0000 C   0  0  0  0  0  0  0  0  0 20  0  0
    1.5878   -0.8090    0.0000 N   0  0  0  0  0  0  0  0  0 21  0  0
    2.5388   -0.5000    0.0000 C   0  0  0  0  0  0  0  0  0 22  0  0
    3.4049   -1.0000    0.0000 C   0  0  0  0  0  0  0  0  0 23  0  0
    4.2709   -0.5000    0.0000 C   0  0  0  0  0  0  0  0  0 24  0  0
    5.1370   -1.0000    0.0000 C   0  0  0  0  0  0  0  0  0 25  0  0
    4.2709    0.5000    0.0000 C   0  0  0  0  0  0  0  0  0 26  0  0
    5.1370    1.0000    0.0000 C   0  0  0  0  0  0  0  0  0 27  0  0
 27 26  1  0  0  0  0
 26 20  1  0  0  0  0
 20 19  2  0  0  0  0
 19 22  1  0  0  0  0
 22 23  2  0  0  0  0
 23 24  1  0  0  0  0
 26 24  2  0  0  0  0
 24 25  1  0  0  0  0
 17 21  2  0  0  0  0
 17 18  1  0  0  0  0
 17 16  1  0  0  0  0
 16 15  1  0  0  0  0
  2 14  1  0  0  0  0
  2  3  1  0  0  0  0
  3 13  1  0  0  0  0
  3  4  2  0  0  0  0
 13 12  2  0  0  0  0
  6  7  1  0  0  0  0
 12  6  1  0  0  0  0
  6  5  2  0  0  0  0
  5  4  1  0  0  0  0
 11  7  1  0  0  0  0
 22 21  1  0  0  0  0
 19 18  1  0  0  0  0
 15 14  1  0  0  0  0
  8  7  1  0  0  0  0
 11 10  2  0  0  0  0
  8  9  2  0  0  0  0
 10  9  1  0  0  0  0
  2  1  2  0  0  0  0
M  END
`,
};

export const Case4YZU = {
    uri: 'factorIXa/4YZU_frags',
};

export const CaseA2ar = {
    uri: 'a2ar/4UHR_pr_op_min_frags',
};

export const CaseEBV = {
    uri: 'EBV/2c9n_pr_op_min_frags',
};

export const Case4RMG = {
    uri: 'sirt2/4RMG_pr_op_min_frags',
};

export const Case1G0Y = {
    uri: 'IL1R1/1G0Y_pr_op_min_frags',
};

export const Case2C9N = {
    uri: 'EBV/2c9n_pr_op_min_frags',
};

export const CaseESR1 = {
    uri: 'ESR1/6IAR_pr_op_min_frags',
};

export const Case6HXS = {
    uri: 'CA2/6HX5_pr_op_min_frags',
}

export const Case5R82 = {
    uri: 'nCov_Protease/5R82_pr_op_min_frags',
};

export const BoltzCif = `data_model
_entry.id model
_struct.entry_id model
_struct.pdbx_model_details .
_struct.pdbx_structure_determination_methodology computational
_struct.title .
_audit_conform.dict_location https://raw.githubusercontent.com/ihmwg/ModelCIF/d18ba38/base/mmcif_ma-core.dic
_audit_conform.dict_name mmcif_ma.dic
_audit_conform.dict_version 1.4.6
#
loop_
_chem_comp.id
_chem_comp.type
_chem_comp.name
_chem_comp.formula
_chem_comp.formula_weight
_chem_comp.ma_provenance
ALA 'L-peptide linking' . . . 'CCD Core'
ARG 'L-peptide linking' . . . 'CCD Core'
ASN 'L-peptide linking' . . . 'CCD Core'
ASP 'L-peptide linking' . . . 'CCD Core'
CYS 'L-peptide linking' . . . 'CCD Core'
GLN 'L-peptide linking' . . . 'CCD Core'
GLU 'L-peptide linking' . . . 'CCD Core'
GLY 'L-peptide linking' . . . 'CCD Core'
HIS 'L-peptide linking' . . . 'CCD Core'
ILE 'L-peptide linking' . . . 'CCD Core'
LEU 'L-peptide linking' . . . 'CCD Core'
LIG non-polymer . . . 'CCD Core'
LYS 'L-peptide linking' . . . 'CCD Core'
MET 'L-peptide linking' . . . 'CCD Core'
PHE 'L-peptide linking' . . . 'CCD Core'
PRO 'L-peptide linking' . . . 'CCD Core'
SER 'L-peptide linking' . . . 'CCD Core'
THR 'L-peptide linking' . . . 'CCD Core'
TRP 'L-peptide linking' . . . 'CCD Core'
TYR 'L-peptide linking' . . . 'CCD Core'
VAL 'L-peptide linking' . . . 'CCD Core'
#
#
loop_
_entity.id
_entity.type
_entity.src_method
_entity.pdbx_description
_entity.formula_weight
_entity.pdbx_number_of_molecules
_entity.details
1 polymer man . . 1 .
2 non-polymer man . . 1 .
#
#
loop_
_entity_poly.entity_id
_entity_poly.type
_entity_poly.nstd_linkage
_entity_poly.nstd_monomer
_entity_poly.pdbx_strand_id
_entity_poly.pdbx_seq_one_letter_code
_entity_poly.pdbx_seq_one_letter_code_can
1 polypeptide(L) no no A
;(MET)(THR)(SER)(SER)(ALA)(ASP)(LEU)(THR)(ASN)(LEU)(LYS)(GLU)(LEU)(LEU)
(SER)(LEU)(TYR)(LYS)(SER)(LEU)(ARG)(PHE)(SER)(ASP)(SER)(VAL)(ALA)(ILE)
(GLU)(LYS)(TYR)(ASN)(SER)(LEU)(VAL)(GLU)(TRP)(GLY)(THR)(SER)(THR)(TYR)
(TRP)(LYS)(ILE)(GLY)(VAL)(GLN)(LYS)(VAL)(THR)(ASN)(VAL)(GLU)(THR)(SER)
(ILE)(SER)(ASP)(TYR)(TYR)(ASP)(GLU)(VAL)(LYS)(ASN)(LYS)(PRO)(PHE)(ASN)
(ILE)(ASP)(PRO)(GLY)(TYR)(TYR)(ILE)(PHE)(LEU)(PRO)(VAL)(TYR)(PHE)(GLY)
(SER)(VAL)(PHE)(ILE)(TYR)(SER)(LYS)(GLY)(LYS)(ASN)(MET)(VAL)(GLU)(LEU)
(GLY)(SER)(GLY)(ASN)(SER)(PHE)(GLN)(ILE)(PRO)(ASP)(GLU)(ILE)(ARG)(SER)
(ALA)(CYS)(ASN)(LYS)(VAL)(LEU)(ASP)(SER)(ASP)(ASN)(GLY)(ILE)(ASP)(PHE)
(LEU)(ARG)(PHE)(VAL)(LEU)(LEU)(ASN)(ASN)(ARG)(TRP)(ILE)(MET)(GLU)(ASP)
(ALA)(ILE)(SER)(LYS)(TYR)(GLN)(SER)(PRO)(VAL)(ASN)(ILE)(PHE)(LYS)(LEU)
(ALA)(SER)(GLU)(TYR)(GLY)(LEU)(ASN)(ILE)(PRO)(ASN)(TYR)(LEU)(GLU)(ILE)
(GLU)(ILE)(GLU)(GLU)(ASP)(THR)(LEU)(PHE)(ASP)(ASP)(GLU)(LEU)(TYR)(SER)
(ILE)(MET)(GLU)(ARG)(SER)(PHE)(ASP)(ASP)(THR)(PHE)(PRO)(LYS)(ILE)(SER)
(ILE)(SER)(TYR)(ILE)(LYS)(LEU)(GLY)(GLU)(LEU)(LYS)(ARG)(GLN)(VAL)(VAL)
(ASP)(PHE)(PHE)(LYS)(PHE)(SER)(PHE)(MET)(TYR)(ILE)(GLU)(SER)(ILE)(LYS)
(VAL)(ASP)(ARG)(ILE)(GLY)(ASP)(ASN)(ILE)(PHE)(ILE)(PRO)(SER)(VAL)(ILE)
(THR)(LYS)(SER)(GLY)(LYS)(LYS)(ILE)(LEU)(VAL)(LYS)(ASP)(VAL)(ASP)(HIS)
(LEU)(ILE)(ARG)(SER)(LYS)(VAL)(ARG)(GLU)(HIS)(THR)(PHE)(VAL)(LYS)(VAL)
(LYS)(LYS)(LYS)(ASN)(THR)(PHE)(SER)(ILE)(LEU)(TYR)(ASP)(TYR)(ASP)(GLY)
(ASN)(GLY)(THR)(GLU)(THR)(ARG)(GLY)(GLU)(VAL)(ILE)(LYS)(ARG)(ILE)(ILE)
(ASP)(THR)(ILE)(GLY)(ARG)(ASP)(TYR)(TYR)(VAL)(ASN)(GLY)(LYS)(TYR)(PHE)
(SER)(LYS)(VAL)(GLY)(ILE)(ALA)(GLY)(LEU)(LYS)(GLN)(LEU)(THR)(ASN)(LYS)
(LEU)(ASP)(ILE)(ASN)(GLU)(CYS)(ALA)(THR)(VAL)(ASP)(GLU)(LEU)(VAL)(ASP)
(GLU)(ILE)(ASN)(LYS)(SER)(GLY)(THR)(VAL)(LYS)(ARG)(LYS)(ILE)(LYS)(ASN)
(GLN)(SER)(VAL)(PHE)(ASP)(LEU)(SER)(ARG)(GLU)(CYS)(LEU)(GLY)(TYR)(PRO)
(GLU)(ALA)(ASP)(PHE)(ILE)(THR)(LEU)(VAL)(ASN)(ASN)(MET)(ARG)(PHE)(LYS)
(ILE)(GLU)(ASN)(CYS)(LYS)(VAL)(VAL)(ASN)(PHE)(ASN)(ILE)(GLU)(ASN)(THR)
(ASN)(CYS)(LEU)(ASN)(ASN)(PRO)(SER)(ILE)(GLU)(THR)(ILE)(TYR)(GLY)(ASN)
(PHE)(ASN)(GLN)(PHE)(VAL)(SER)(ILE)(PHE)(ASN)(THR)(VAL)(THR)(ASP)(VAL)
(LYS)(LYS)(ARG)(LEU)(PHE)(GLU)
;

;XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXX
;

#
#
loop_
_pdbx_entity_nonpoly.entity_id
_pdbx_entity_nonpoly.name
_pdbx_entity_nonpoly.comp_id
_pdbx_entity_nonpoly.ma_model_mode
2 . LIG .
#
#
loop_
_entity_poly_seq.entity_id
_entity_poly_seq.num
_entity_poly_seq.mon_id
_entity_poly_seq.hetero
1 1 MET .
1 2 THR .
1 3 SER .
1 4 SER .
1 5 ALA .
1 6 ASP .
1 7 LEU .
1 8 THR .
1 9 ASN .
1 10 LEU .
1 11 LYS .
1 12 GLU .
1 13 LEU .
1 14 LEU .
1 15 SER .
1 16 LEU .
1 17 TYR .
1 18 LYS .
1 19 SER .
1 20 LEU .
1 21 ARG .
1 22 PHE .
1 23 SER .
1 24 ASP .
1 25 SER .
1 26 VAL .
1 27 ALA .
1 28 ILE .
1 29 GLU .
1 30 LYS .
1 31 TYR .
1 32 ASN .
1 33 SER .
1 34 LEU .
1 35 VAL .
1 36 GLU .
1 37 TRP .
1 38 GLY .
1 39 THR .
1 40 SER .
1 41 THR .
1 42 TYR .
1 43 TRP .
1 44 LYS .
1 45 ILE .
1 46 GLY .
1 47 VAL .
1 48 GLN .
1 49 LYS .
1 50 VAL .
1 51 THR .
1 52 ASN .
1 53 VAL .
1 54 GLU .
1 55 THR .
1 56 SER .
1 57 ILE .
1 58 SER .
1 59 ASP .
1 60 TYR .
1 61 TYR .
1 62 ASP .
1 63 GLU .
1 64 VAL .
1 65 LYS .
1 66 ASN .
1 67 LYS .
1 68 PRO .
1 69 PHE .
1 70 ASN .
1 71 ILE .
1 72 ASP .
1 73 PRO .
1 74 GLY .
1 75 TYR .
1 76 TYR .
1 77 ILE .
1 78 PHE .
1 79 LEU .
1 80 PRO .
1 81 VAL .
1 82 TYR .
1 83 PHE .
1 84 GLY .
1 85 SER .
1 86 VAL .
1 87 PHE .
1 88 ILE .
1 89 TYR .
1 90 SER .
1 91 LYS .
1 92 GLY .
1 93 LYS .
1 94 ASN .
1 95 MET .
1 96 VAL .
1 97 GLU .
1 98 LEU .
1 99 GLY .
1 100 SER .
1 101 GLY .
1 102 ASN .
1 103 SER .
1 104 PHE .
1 105 GLN .
1 106 ILE .
1 107 PRO .
1 108 ASP .
1 109 GLU .
1 110 ILE .
1 111 ARG .
1 112 SER .
1 113 ALA .
1 114 CYS .
1 115 ASN .
1 116 LYS .
1 117 VAL .
1 118 LEU .
1 119 ASP .
1 120 SER .
1 121 ASP .
1 122 ASN .
1 123 GLY .
1 124 ILE .
1 125 ASP .
1 126 PHE .
1 127 LEU .
1 128 ARG .
1 129 PHE .
1 130 VAL .
1 131 LEU .
1 132 LEU .
1 133 ASN .
1 134 ASN .
1 135 ARG .
1 136 TRP .
1 137 ILE .
1 138 MET .
1 139 GLU .
1 140 ASP .
1 141 ALA .
1 142 ILE .
1 143 SER .
1 144 LYS .
1 145 TYR .
1 146 GLN .
1 147 SER .
1 148 PRO .
1 149 VAL .
1 150 ASN .
1 151 ILE .
1 152 PHE .
1 153 LYS .
1 154 LEU .
1 155 ALA .
1 156 SER .
1 157 GLU .
1 158 TYR .
1 159 GLY .
1 160 LEU .
1 161 ASN .
1 162 ILE .
1 163 PRO .
1 164 ASN .
1 165 TYR .
1 166 LEU .
1 167 GLU .
1 168 ILE .
1 169 GLU .
1 170 ILE .
1 171 GLU .
1 172 GLU .
1 173 ASP .
1 174 THR .
1 175 LEU .
1 176 PHE .
1 177 ASP .
1 178 ASP .
1 179 GLU .
1 180 LEU .
1 181 TYR .
1 182 SER .
1 183 ILE .
1 184 MET .
1 185 GLU .
1 186 ARG .
1 187 SER .
1 188 PHE .
1 189 ASP .
1 190 ASP .
1 191 THR .
1 192 PHE .
1 193 PRO .
1 194 LYS .
1 195 ILE .
1 196 SER .
1 197 ILE .
1 198 SER .
1 199 TYR .
1 200 ILE .
1 201 LYS .
1 202 LEU .
1 203 GLY .
1 204 GLU .
1 205 LEU .
1 206 LYS .
1 207 ARG .
1 208 GLN .
1 209 VAL .
1 210 VAL .
1 211 ASP .
1 212 PHE .
1 213 PHE .
1 214 LYS .
1 215 PHE .
1 216 SER .
1 217 PHE .
1 218 MET .
1 219 TYR .
1 220 ILE .
1 221 GLU .
1 222 SER .
1 223 ILE .
1 224 LYS .
1 225 VAL .
1 226 ASP .
1 227 ARG .
1 228 ILE .
1 229 GLY .
1 230 ASP .
1 231 ASN .
1 232 ILE .
1 233 PHE .
1 234 ILE .
1 235 PRO .
1 236 SER .
1 237 VAL .
1 238 ILE .
1 239 THR .
1 240 LYS .
1 241 SER .
1 242 GLY .
1 243 LYS .
1 244 LYS .
1 245 ILE .
1 246 LEU .
1 247 VAL .
1 248 LYS .
1 249 ASP .
1 250 VAL .
1 251 ASP .
1 252 HIS .
1 253 LEU .
1 254 ILE .
1 255 ARG .
1 256 SER .
1 257 LYS .
1 258 VAL .
1 259 ARG .
1 260 GLU .
1 261 HIS .
1 262 THR .
1 263 PHE .
1 264 VAL .
1 265 LYS .
1 266 VAL .
1 267 LYS .
1 268 LYS .
1 269 LYS .
1 270 ASN .
1 271 THR .
1 272 PHE .
1 273 SER .
1 274 ILE .
1 275 LEU .
1 276 TYR .
1 277 ASP .
1 278 TYR .
1 279 ASP .
1 280 GLY .
1 281 ASN .
1 282 GLY .
1 283 THR .
1 284 GLU .
1 285 THR .
1 286 ARG .
1 287 GLY .
1 288 GLU .
1 289 VAL .
1 290 ILE .
1 291 LYS .
1 292 ARG .
1 293 ILE .
1 294 ILE .
1 295 ASP .
1 296 THR .
1 297 ILE .
1 298 GLY .
1 299 ARG .
1 300 ASP .
1 301 TYR .
1 302 TYR .
1 303 VAL .
1 304 ASN .
1 305 GLY .
1 306 LYS .
1 307 TYR .
1 308 PHE .
1 309 SER .
1 310 LYS .
1 311 VAL .
1 312 GLY .
1 313 ILE .
1 314 ALA .
1 315 GLY .
1 316 LEU .
1 317 LYS .
1 318 GLN .
1 319 LEU .
1 320 THR .
1 321 ASN .
1 322 LYS .
1 323 LEU .
1 324 ASP .
1 325 ILE .
1 326 ASN .
1 327 GLU .
1 328 CYS .
1 329 ALA .
1 330 THR .
1 331 VAL .
1 332 ASP .
1 333 GLU .
1 334 LEU .
1 335 VAL .
1 336 ASP .
1 337 GLU .
1 338 ILE .
1 339 ASN .
1 340 LYS .
1 341 SER .
1 342 GLY .
1 343 THR .
1 344 VAL .
1 345 LYS .
1 346 ARG .
1 347 LYS .
1 348 ILE .
1 349 LYS .
1 350 ASN .
1 351 GLN .
1 352 SER .
1 353 VAL .
1 354 PHE .
1 355 ASP .
1 356 LEU .
1 357 SER .
1 358 ARG .
1 359 GLU .
1 360 CYS .
1 361 LEU .
1 362 GLY .
1 363 TYR .
1 364 PRO .
1 365 GLU .
1 366 ALA .
1 367 ASP .
1 368 PHE .
1 369 ILE .
1 370 THR .
1 371 LEU .
1 372 VAL .
1 373 ASN .
1 374 ASN .
1 375 MET .
1 376 ARG .
1 377 PHE .
1 378 LYS .
1 379 ILE .
1 380 GLU .
1 381 ASN .
1 382 CYS .
1 383 LYS .
1 384 VAL .
1 385 VAL .
1 386 ASN .
1 387 PHE .
1 388 ASN .
1 389 ILE .
1 390 GLU .
1 391 ASN .
1 392 THR .
1 393 ASN .
1 394 CYS .
1 395 LEU .
1 396 ASN .
1 397 ASN .
1 398 PRO .
1 399 SER .
1 400 ILE .
1 401 GLU .
1 402 THR .
1 403 ILE .
1 404 TYR .
1 405 GLY .
1 406 ASN .
1 407 PHE .
1 408 ASN .
1 409 GLN .
1 410 PHE .
1 411 VAL .
1 412 SER .
1 413 ILE .
1 414 PHE .
1 415 ASN .
1 416 THR .
1 417 VAL .
1 418 THR .
1 419 ASP .
1 420 VAL .
1 421 LYS .
1 422 LYS .
1 423 ARG .
1 424 LEU .
1 425 PHE .
1 426 GLU .
#
#
loop_
_struct_asym.id
_struct_asym.entity_id
_struct_asym.details
A 1 'Model subunit A'
B 2 'Model subunit B'
#
#
loop_
_pdbx_poly_seq_scheme.asym_id
_pdbx_poly_seq_scheme.entity_id
_pdbx_poly_seq_scheme.seq_id
_pdbx_poly_seq_scheme.mon_id
_pdbx_poly_seq_scheme.pdb_seq_num
_pdbx_poly_seq_scheme.auth_seq_num
_pdbx_poly_seq_scheme.pdb_mon_id
_pdbx_poly_seq_scheme.auth_mon_id
_pdbx_poly_seq_scheme.pdb_strand_id
_pdbx_poly_seq_scheme.pdb_ins_code
A 1 1 MET 1 1 MET MET A .
A 1 2 THR 2 2 THR THR A .
A 1 3 SER 3 3 SER SER A .
A 1 4 SER 4 4 SER SER A .
A 1 5 ALA 5 5 ALA ALA A .
A 1 6 ASP 6 6 ASP ASP A .
A 1 7 LEU 7 7 LEU LEU A .
A 1 8 THR 8 8 THR THR A .
A 1 9 ASN 9 9 ASN ASN A .
A 1 10 LEU 10 10 LEU LEU A .
A 1 11 LYS 11 11 LYS LYS A .
A 1 12 GLU 12 12 GLU GLU A .
A 1 13 LEU 13 13 LEU LEU A .
A 1 14 LEU 14 14 LEU LEU A .
A 1 15 SER 15 15 SER SER A .
A 1 16 LEU 16 16 LEU LEU A .
A 1 17 TYR 17 17 TYR TYR A .
A 1 18 LYS 18 18 LYS LYS A .
A 1 19 SER 19 19 SER SER A .
A 1 20 LEU 20 20 LEU LEU A .
A 1 21 ARG 21 21 ARG ARG A .
A 1 22 PHE 22 22 PHE PHE A .
A 1 23 SER 23 23 SER SER A .
A 1 24 ASP 24 24 ASP ASP A .
A 1 25 SER 25 25 SER SER A .
A 1 26 VAL 26 26 VAL VAL A .
A 1 27 ALA 27 27 ALA ALA A .
A 1 28 ILE 28 28 ILE ILE A .
A 1 29 GLU 29 29 GLU GLU A .
A 1 30 LYS 30 30 LYS LYS A .
A 1 31 TYR 31 31 TYR TYR A .
A 1 32 ASN 32 32 ASN ASN A .
A 1 33 SER 33 33 SER SER A .
A 1 34 LEU 34 34 LEU LEU A .
A 1 35 VAL 35 35 VAL VAL A .
A 1 36 GLU 36 36 GLU GLU A .
A 1 37 TRP 37 37 TRP TRP A .
A 1 38 GLY 38 38 GLY GLY A .
A 1 39 THR 39 39 THR THR A .
A 1 40 SER 40 40 SER SER A .
A 1 41 THR 41 41 THR THR A .
A 1 42 TYR 42 42 TYR TYR A .
A 1 43 TRP 43 43 TRP TRP A .
A 1 44 LYS 44 44 LYS LYS A .
A 1 45 ILE 45 45 ILE ILE A .
A 1 46 GLY 46 46 GLY GLY A .
A 1 47 VAL 47 47 VAL VAL A .
A 1 48 GLN 48 48 GLN GLN A .
A 1 49 LYS 49 49 LYS LYS A .
A 1 50 VAL 50 50 VAL VAL A .
A 1 51 THR 51 51 THR THR A .
A 1 52 ASN 52 52 ASN ASN A .
A 1 53 VAL 53 53 VAL VAL A .
A 1 54 GLU 54 54 GLU GLU A .
A 1 55 THR 55 55 THR THR A .
A 1 56 SER 56 56 SER SER A .
A 1 57 ILE 57 57 ILE ILE A .
A 1 58 SER 58 58 SER SER A .
A 1 59 ASP 59 59 ASP ASP A .
A 1 60 TYR 60 60 TYR TYR A .
A 1 61 TYR 61 61 TYR TYR A .
A 1 62 ASP 62 62 ASP ASP A .
A 1 63 GLU 63 63 GLU GLU A .
A 1 64 VAL 64 64 VAL VAL A .
A 1 65 LYS 65 65 LYS LYS A .
A 1 66 ASN 66 66 ASN ASN A .
A 1 67 LYS 67 67 LYS LYS A .
A 1 68 PRO 68 68 PRO PRO A .
A 1 69 PHE 69 69 PHE PHE A .
A 1 70 ASN 70 70 ASN ASN A .
A 1 71 ILE 71 71 ILE ILE A .
A 1 72 ASP 72 72 ASP ASP A .
A 1 73 PRO 73 73 PRO PRO A .
A 1 74 GLY 74 74 GLY GLY A .
A 1 75 TYR 75 75 TYR TYR A .
A 1 76 TYR 76 76 TYR TYR A .
A 1 77 ILE 77 77 ILE ILE A .
A 1 78 PHE 78 78 PHE PHE A .
A 1 79 LEU 79 79 LEU LEU A .
A 1 80 PRO 80 80 PRO PRO A .
A 1 81 VAL 81 81 VAL VAL A .
A 1 82 TYR 82 82 TYR TYR A .
A 1 83 PHE 83 83 PHE PHE A .
A 1 84 GLY 84 84 GLY GLY A .
A 1 85 SER 85 85 SER SER A .
A 1 86 VAL 86 86 VAL VAL A .
A 1 87 PHE 87 87 PHE PHE A .
A 1 88 ILE 88 88 ILE ILE A .
A 1 89 TYR 89 89 TYR TYR A .
A 1 90 SER 90 90 SER SER A .
A 1 91 LYS 91 91 LYS LYS A .
A 1 92 GLY 92 92 GLY GLY A .
A 1 93 LYS 93 93 LYS LYS A .
A 1 94 ASN 94 94 ASN ASN A .
A 1 95 MET 95 95 MET MET A .
A 1 96 VAL 96 96 VAL VAL A .
A 1 97 GLU 97 97 GLU GLU A .
A 1 98 LEU 98 98 LEU LEU A .
A 1 99 GLY 99 99 GLY GLY A .
A 1 100 SER 100 100 SER SER A .
A 1 101 GLY 101 101 GLY GLY A .
A 1 102 ASN 102 102 ASN ASN A .
A 1 103 SER 103 103 SER SER A .
A 1 104 PHE 104 104 PHE PHE A .
A 1 105 GLN 105 105 GLN GLN A .
A 1 106 ILE 106 106 ILE ILE A .
A 1 107 PRO 107 107 PRO PRO A .
A 1 108 ASP 108 108 ASP ASP A .
A 1 109 GLU 109 109 GLU GLU A .
A 1 110 ILE 110 110 ILE ILE A .
A 1 111 ARG 111 111 ARG ARG A .
A 1 112 SER 112 112 SER SER A .
A 1 113 ALA 113 113 ALA ALA A .
A 1 114 CYS 114 114 CYS CYS A .
A 1 115 ASN 115 115 ASN ASN A .
A 1 116 LYS 116 116 LYS LYS A .
A 1 117 VAL 117 117 VAL VAL A .
A 1 118 LEU 118 118 LEU LEU A .
A 1 119 ASP 119 119 ASP ASP A .
A 1 120 SER 120 120 SER SER A .
A 1 121 ASP 121 121 ASP ASP A .
A 1 122 ASN 122 122 ASN ASN A .
A 1 123 GLY 123 123 GLY GLY A .
A 1 124 ILE 124 124 ILE ILE A .
A 1 125 ASP 125 125 ASP ASP A .
A 1 126 PHE 126 126 PHE PHE A .
A 1 127 LEU 127 127 LEU LEU A .
A 1 128 ARG 128 128 ARG ARG A .
A 1 129 PHE 129 129 PHE PHE A .
A 1 130 VAL 130 130 VAL VAL A .
A 1 131 LEU 131 131 LEU LEU A .
A 1 132 LEU 132 132 LEU LEU A .
A 1 133 ASN 133 133 ASN ASN A .
A 1 134 ASN 134 134 ASN ASN A .
A 1 135 ARG 135 135 ARG ARG A .
A 1 136 TRP 136 136 TRP TRP A .
A 1 137 ILE 137 137 ILE ILE A .
A 1 138 MET 138 138 MET MET A .
A 1 139 GLU 139 139 GLU GLU A .
A 1 140 ASP 140 140 ASP ASP A .
A 1 141 ALA 141 141 ALA ALA A .
A 1 142 ILE 142 142 ILE ILE A .
A 1 143 SER 143 143 SER SER A .
A 1 144 LYS 144 144 LYS LYS A .
A 1 145 TYR 145 145 TYR TYR A .
A 1 146 GLN 146 146 GLN GLN A .
A 1 147 SER 147 147 SER SER A .
A 1 148 PRO 148 148 PRO PRO A .
A 1 149 VAL 149 149 VAL VAL A .
A 1 150 ASN 150 150 ASN ASN A .
A 1 151 ILE 151 151 ILE ILE A .
A 1 152 PHE 152 152 PHE PHE A .
A 1 153 LYS 153 153 LYS LYS A .
A 1 154 LEU 154 154 LEU LEU A .
A 1 155 ALA 155 155 ALA ALA A .
A 1 156 SER 156 156 SER SER A .
A 1 157 GLU 157 157 GLU GLU A .
A 1 158 TYR 158 158 TYR TYR A .
A 1 159 GLY 159 159 GLY GLY A .
A 1 160 LEU 160 160 LEU LEU A .
A 1 161 ASN 161 161 ASN ASN A .
A 1 162 ILE 162 162 ILE ILE A .
A 1 163 PRO 163 163 PRO PRO A .
A 1 164 ASN 164 164 ASN ASN A .
A 1 165 TYR 165 165 TYR TYR A .
A 1 166 LEU 166 166 LEU LEU A .
A 1 167 GLU 167 167 GLU GLU A .
A 1 168 ILE 168 168 ILE ILE A .
A 1 169 GLU 169 169 GLU GLU A .
A 1 170 ILE 170 170 ILE ILE A .
A 1 171 GLU 171 171 GLU GLU A .
A 1 172 GLU 172 172 GLU GLU A .
A 1 173 ASP 173 173 ASP ASP A .
A 1 174 THR 174 174 THR THR A .
A 1 175 LEU 175 175 LEU LEU A .
A 1 176 PHE 176 176 PHE PHE A .
A 1 177 ASP 177 177 ASP ASP A .
A 1 178 ASP 178 178 ASP ASP A .
A 1 179 GLU 179 179 GLU GLU A .
A 1 180 LEU 180 180 LEU LEU A .
A 1 181 TYR 181 181 TYR TYR A .
A 1 182 SER 182 182 SER SER A .
A 1 183 ILE 183 183 ILE ILE A .
A 1 184 MET 184 184 MET MET A .
A 1 185 GLU 185 185 GLU GLU A .
A 1 186 ARG 186 186 ARG ARG A .
A 1 187 SER 187 187 SER SER A .
A 1 188 PHE 188 188 PHE PHE A .
A 1 189 ASP 189 189 ASP ASP A .
A 1 190 ASP 190 190 ASP ASP A .
A 1 191 THR 191 191 THR THR A .
A 1 192 PHE 192 192 PHE PHE A .
A 1 193 PRO 193 193 PRO PRO A .
A 1 194 LYS 194 194 LYS LYS A .
A 1 195 ILE 195 195 ILE ILE A .
A 1 196 SER 196 196 SER SER A .
A 1 197 ILE 197 197 ILE ILE A .
A 1 198 SER 198 198 SER SER A .
A 1 199 TYR 199 199 TYR TYR A .
A 1 200 ILE 200 200 ILE ILE A .
A 1 201 LYS 201 201 LYS LYS A .
A 1 202 LEU 202 202 LEU LEU A .
A 1 203 GLY 203 203 GLY GLY A .
A 1 204 GLU 204 204 GLU GLU A .
A 1 205 LEU 205 205 LEU LEU A .
A 1 206 LYS 206 206 LYS LYS A .
A 1 207 ARG 207 207 ARG ARG A .
A 1 208 GLN 208 208 GLN GLN A .
A 1 209 VAL 209 209 VAL VAL A .
A 1 210 VAL 210 210 VAL VAL A .
A 1 211 ASP 211 211 ASP ASP A .
A 1 212 PHE 212 212 PHE PHE A .
A 1 213 PHE 213 213 PHE PHE A .
A 1 214 LYS 214 214 LYS LYS A .
A 1 215 PHE 215 215 PHE PHE A .
A 1 216 SER 216 216 SER SER A .
A 1 217 PHE 217 217 PHE PHE A .
A 1 218 MET 218 218 MET MET A .
A 1 219 TYR 219 219 TYR TYR A .
A 1 220 ILE 220 220 ILE ILE A .
A 1 221 GLU 221 221 GLU GLU A .
A 1 222 SER 222 222 SER SER A .
A 1 223 ILE 223 223 ILE ILE A .
A 1 224 LYS 224 224 LYS LYS A .
A 1 225 VAL 225 225 VAL VAL A .
A 1 226 ASP 226 226 ASP ASP A .
A 1 227 ARG 227 227 ARG ARG A .
A 1 228 ILE 228 228 ILE ILE A .
A 1 229 GLY 229 229 GLY GLY A .
A 1 230 ASP 230 230 ASP ASP A .
A 1 231 ASN 231 231 ASN ASN A .
A 1 232 ILE 232 232 ILE ILE A .
A 1 233 PHE 233 233 PHE PHE A .
A 1 234 ILE 234 234 ILE ILE A .
A 1 235 PRO 235 235 PRO PRO A .
A 1 236 SER 236 236 SER SER A .
A 1 237 VAL 237 237 VAL VAL A .
A 1 238 ILE 238 238 ILE ILE A .
A 1 239 THR 239 239 THR THR A .
A 1 240 LYS 240 240 LYS LYS A .
A 1 241 SER 241 241 SER SER A .
A 1 242 GLY 242 242 GLY GLY A .
A 1 243 LYS 243 243 LYS LYS A .
A 1 244 LYS 244 244 LYS LYS A .
A 1 245 ILE 245 245 ILE ILE A .
A 1 246 LEU 246 246 LEU LEU A .
A 1 247 VAL 247 247 VAL VAL A .
A 1 248 LYS 248 248 LYS LYS A .
A 1 249 ASP 249 249 ASP ASP A .
A 1 250 VAL 250 250 VAL VAL A .
A 1 251 ASP 251 251 ASP ASP A .
A 1 252 HIS 252 252 HIS HIS A .
A 1 253 LEU 253 253 LEU LEU A .
A 1 254 ILE 254 254 ILE ILE A .
A 1 255 ARG 255 255 ARG ARG A .
A 1 256 SER 256 256 SER SER A .
A 1 257 LYS 257 257 LYS LYS A .
A 1 258 VAL 258 258 VAL VAL A .
A 1 259 ARG 259 259 ARG ARG A .
A 1 260 GLU 260 260 GLU GLU A .
A 1 261 HIS 261 261 HIS HIS A .
A 1 262 THR 262 262 THR THR A .
A 1 263 PHE 263 263 PHE PHE A .
A 1 264 VAL 264 264 VAL VAL A .
A 1 265 LYS 265 265 LYS LYS A .
A 1 266 VAL 266 266 VAL VAL A .
A 1 267 LYS 267 267 LYS LYS A .
A 1 268 LYS 268 268 LYS LYS A .
A 1 269 LYS 269 269 LYS LYS A .
A 1 270 ASN 270 270 ASN ASN A .
A 1 271 THR 271 271 THR THR A .
A 1 272 PHE 272 272 PHE PHE A .
A 1 273 SER 273 273 SER SER A .
A 1 274 ILE 274 274 ILE ILE A .
A 1 275 LEU 275 275 LEU LEU A .
A 1 276 TYR 276 276 TYR TYR A .
A 1 277 ASP 277 277 ASP ASP A .
A 1 278 TYR 278 278 TYR TYR A .
A 1 279 ASP 279 279 ASP ASP A .
A 1 280 GLY 280 280 GLY GLY A .
A 1 281 ASN 281 281 ASN ASN A .
A 1 282 GLY 282 282 GLY GLY A .
A 1 283 THR 283 283 THR THR A .
A 1 284 GLU 284 284 GLU GLU A .
A 1 285 THR 285 285 THR THR A .
A 1 286 ARG 286 286 ARG ARG A .
A 1 287 GLY 287 287 GLY GLY A .
A 1 288 GLU 288 288 GLU GLU A .
A 1 289 VAL 289 289 VAL VAL A .
A 1 290 ILE 290 290 ILE ILE A .
A 1 291 LYS 291 291 LYS LYS A .
A 1 292 ARG 292 292 ARG ARG A .
A 1 293 ILE 293 293 ILE ILE A .
A 1 294 ILE 294 294 ILE ILE A .
A 1 295 ASP 295 295 ASP ASP A .
A 1 296 THR 296 296 THR THR A .
A 1 297 ILE 297 297 ILE ILE A .
A 1 298 GLY 298 298 GLY GLY A .
A 1 299 ARG 299 299 ARG ARG A .
A 1 300 ASP 300 300 ASP ASP A .
A 1 301 TYR 301 301 TYR TYR A .
A 1 302 TYR 302 302 TYR TYR A .
A 1 303 VAL 303 303 VAL VAL A .
A 1 304 ASN 304 304 ASN ASN A .
A 1 305 GLY 305 305 GLY GLY A .
A 1 306 LYS 306 306 LYS LYS A .
A 1 307 TYR 307 307 TYR TYR A .
A 1 308 PHE 308 308 PHE PHE A .
A 1 309 SER 309 309 SER SER A .
A 1 310 LYS 310 310 LYS LYS A .
A 1 311 VAL 311 311 VAL VAL A .
A 1 312 GLY 312 312 GLY GLY A .
A 1 313 ILE 313 313 ILE ILE A .
A 1 314 ALA 314 314 ALA ALA A .
A 1 315 GLY 315 315 GLY GLY A .
A 1 316 LEU 316 316 LEU LEU A .
A 1 317 LYS 317 317 LYS LYS A .
A 1 318 GLN 318 318 GLN GLN A .
A 1 319 LEU 319 319 LEU LEU A .
A 1 320 THR 320 320 THR THR A .
A 1 321 ASN 321 321 ASN ASN A .
A 1 322 LYS 322 322 LYS LYS A .
A 1 323 LEU 323 323 LEU LEU A .
A 1 324 ASP 324 324 ASP ASP A .
A 1 325 ILE 325 325 ILE ILE A .
A 1 326 ASN 326 326 ASN ASN A .
A 1 327 GLU 327 327 GLU GLU A .
A 1 328 CYS 328 328 CYS CYS A .
A 1 329 ALA 329 329 ALA ALA A .
A 1 330 THR 330 330 THR THR A .
A 1 331 VAL 331 331 VAL VAL A .
A 1 332 ASP 332 332 ASP ASP A .
A 1 333 GLU 333 333 GLU GLU A .
A 1 334 LEU 334 334 LEU LEU A .
A 1 335 VAL 335 335 VAL VAL A .
A 1 336 ASP 336 336 ASP ASP A .
A 1 337 GLU 337 337 GLU GLU A .
A 1 338 ILE 338 338 ILE ILE A .
A 1 339 ASN 339 339 ASN ASN A .
A 1 340 LYS 340 340 LYS LYS A .
A 1 341 SER 341 341 SER SER A .
A 1 342 GLY 342 342 GLY GLY A .
A 1 343 THR 343 343 THR THR A .
A 1 344 VAL 344 344 VAL VAL A .
A 1 345 LYS 345 345 LYS LYS A .
A 1 346 ARG 346 346 ARG ARG A .
A 1 347 LYS 347 347 LYS LYS A .
A 1 348 ILE 348 348 ILE ILE A .
A 1 349 LYS 349 349 LYS LYS A .
A 1 350 ASN 350 350 ASN ASN A .
A 1 351 GLN 351 351 GLN GLN A .
A 1 352 SER 352 352 SER SER A .
A 1 353 VAL 353 353 VAL VAL A .
A 1 354 PHE 354 354 PHE PHE A .
A 1 355 ASP 355 355 ASP ASP A .
A 1 356 LEU 356 356 LEU LEU A .
A 1 357 SER 357 357 SER SER A .
A 1 358 ARG 358 358 ARG ARG A .
A 1 359 GLU 359 359 GLU GLU A .
A 1 360 CYS 360 360 CYS CYS A .
A 1 361 LEU 361 361 LEU LEU A .
A 1 362 GLY 362 362 GLY GLY A .
A 1 363 TYR 363 363 TYR TYR A .
A 1 364 PRO 364 364 PRO PRO A .
A 1 365 GLU 365 365 GLU GLU A .
A 1 366 ALA 366 366 ALA ALA A .
A 1 367 ASP 367 367 ASP ASP A .
A 1 368 PHE 368 368 PHE PHE A .
A 1 369 ILE 369 369 ILE ILE A .
A 1 370 THR 370 370 THR THR A .
A 1 371 LEU 371 371 LEU LEU A .
A 1 372 VAL 372 372 VAL VAL A .
A 1 373 ASN 373 373 ASN ASN A .
A 1 374 ASN 374 374 ASN ASN A .
A 1 375 MET 375 375 MET MET A .
A 1 376 ARG 376 376 ARG ARG A .
A 1 377 PHE 377 377 PHE PHE A .
A 1 378 LYS 378 378 LYS LYS A .
A 1 379 ILE 379 379 ILE ILE A .
A 1 380 GLU 380 380 GLU GLU A .
A 1 381 ASN 381 381 ASN ASN A .
A 1 382 CYS 382 382 CYS CYS A .
A 1 383 LYS 383 383 LYS LYS A .
A 1 384 VAL 384 384 VAL VAL A .
A 1 385 VAL 385 385 VAL VAL A .
A 1 386 ASN 386 386 ASN ASN A .
A 1 387 PHE 387 387 PHE PHE A .
A 1 388 ASN 388 388 ASN ASN A .
A 1 389 ILE 389 389 ILE ILE A .
A 1 390 GLU 390 390 GLU GLU A .
A 1 391 ASN 391 391 ASN ASN A .
A 1 392 THR 392 392 THR THR A .
A 1 393 ASN 393 393 ASN ASN A .
A 1 394 CYS 394 394 CYS CYS A .
A 1 395 LEU 395 395 LEU LEU A .
A 1 396 ASN 396 396 ASN ASN A .
A 1 397 ASN 397 397 ASN ASN A .
A 1 398 PRO 398 398 PRO PRO A .
A 1 399 SER 399 399 SER SER A .
A 1 400 ILE 400 400 ILE ILE A .
A 1 401 GLU 401 401 GLU GLU A .
A 1 402 THR 402 402 THR THR A .
A 1 403 ILE 403 403 ILE ILE A .
A 1 404 TYR 404 404 TYR TYR A .
A 1 405 GLY 405 405 GLY GLY A .
A 1 406 ASN 406 406 ASN ASN A .
A 1 407 PHE 407 407 PHE PHE A .
A 1 408 ASN 408 408 ASN ASN A .
A 1 409 GLN 409 409 GLN GLN A .
A 1 410 PHE 410 410 PHE PHE A .
A 1 411 VAL 411 411 VAL VAL A .
A 1 412 SER 412 412 SER SER A .
A 1 413 ILE 413 413 ILE ILE A .
A 1 414 PHE 414 414 PHE PHE A .
A 1 415 ASN 415 415 ASN ASN A .
A 1 416 THR 416 416 THR THR A .
A 1 417 VAL 417 417 VAL VAL A .
A 1 418 THR 418 418 THR THR A .
A 1 419 ASP 419 419 ASP ASP A .
A 1 420 VAL 420 420 VAL VAL A .
A 1 421 LYS 421 421 LYS LYS A .
A 1 422 LYS 422 422 LYS LYS A .
A 1 423 ARG 423 423 ARG ARG A .
A 1 424 LEU 424 424 LEU LEU A .
A 1 425 PHE 425 425 PHE PHE A .
A 1 426 GLU 426 426 GLU GLU A .
#
#
loop_
_pdbx_nonpoly_scheme.asym_id
_pdbx_nonpoly_scheme.entity_id
_pdbx_nonpoly_scheme.mon_id
_pdbx_nonpoly_scheme.ndb_seq_num
_pdbx_nonpoly_scheme.pdb_seq_num
_pdbx_nonpoly_scheme.auth_seq_num
_pdbx_nonpoly_scheme.auth_mon_id
_pdbx_nonpoly_scheme.pdb_strand_id
_pdbx_nonpoly_scheme.pdb_ins_code
B 2 LIG 1 1 1 LIG B .
#
#
loop_
_ma_data.id
_ma_data.name
_ma_data.content_type
_ma_data.content_type_other_details
1 . target .
2 . target .
3 Model 'model coordinates' .
#
#
loop_
_ma_target_entity.entity_id
_ma_target_entity.data_id
_ma_target_entity.origin
1 1 designed
2 2 designed
#
#
loop_
_ma_target_entity_instance.asym_id
_ma_target_entity_instance.entity_id
_ma_target_entity_instance.details
A 1 'Model subunit A'
B 2 'Model subunit B'
#
#
loop_
_ma_model_list.ordinal_id
_ma_model_list.model_id
_ma_model_list.model_group_id
_ma_model_list.model_name
_ma_model_list.model_group_name
_ma_model_list.data_id
_ma_model_list.model_type
_ma_model_list.model_type_other_details
1 1 1 Model 'All models' 3 'Ab initio model' .
#
#
loop_
_atom_site.group_PDB
_atom_site.id
_atom_site.type_symbol
_atom_site.label_atom_id
_atom_site.label_alt_id
_atom_site.label_comp_id
_atom_site.label_seq_id
_atom_site.auth_seq_id
_atom_site.pdbx_PDB_ins_code
_atom_site.label_asym_id
_atom_site.Cartn_x
_atom_site.Cartn_y
_atom_site.Cartn_z
_atom_site.occupancy
_atom_site.label_entity_id
_atom_site.auth_asym_id
_atom_site.auth_comp_id
_atom_site.B_iso_or_equiv
_atom_site.pdbx_PDB_model_num
ATOM 1 N N . MET 1 1 ? A -37.37448 -29.39474 0.17249 1 1 A MET 1 1
ATOM 2 C CA . MET 1 1 ? A -36.89384 -29.52679 1.54543 1 1 A MET 1 1
ATOM 3 C C . MET 1 1 ? A -35.58850 -28.76128 1.75017 1 1 A MET 1 1
ATOM 4 O O . MET 1 1 ? A -34.64374 -28.91824 0.95858 1 1 A MET 1 1
ATOM 5 C CB . MET 1 1 ? A -36.72036 -30.98660 1.94483 1 1 A MET 1 1
ATOM 6 C CG . MET 1 1 ? A -38.02640 -31.76474 1.97088 1 1 A MET 1 1
ATOM 7 S SD . MET 1 1 ? A -39.07993 -31.35040 3.40147 1 1 A MET 1 1
ATOM 8 C CE . MET 1 1 ? A -40.21847 -30.12180 2.66604 1 1 A MET 1 1
ATOM 9 N N . THR 2 2 ? A -35.52046 -27.94802 2.80177 1 1 A THR 1 1
ATOM 10 C CA . THR 2 2 ? A -34.36105 -27.15406 3.14776 1 1 A THR 1 1
ATOM 11 C C . THR 2 2 ? A -33.97467 -27.44052 4.59843 1 1 A THR 1 1
ATOM 12 O O . THR 2 2 ? A -34.81237 -27.37490 5.49700 1 1 A THR 1 1
ATOM 13 C CB . THR 2 2 ? A -34.65243 -25.65617 2.96296 1 1 A THR 1 1
ATOM 14 O OG1 . THR 2 2 ? A -35.10232 -25.40967 1.62958 1 1 A THR 1 1
ATOM 15 C CG2 . THR 2 2 ? A -33.38445 -24.83749 3.20327 1 1 A THR 1 1
ATOM 16 N N . SER 3 3 ? A -32.69416 -27.74275 4.83609 1 1 A SER 1 1
ATOM 17 C CA . SER 3 3 ? A -32.20352 -27.94731 6.17899 1 1 A SER 1 1
ATOM 18 C C . SER 3 3 ? A -31.92435 -26.60438 6.85033 1 1 A SER 1 1
ATOM 19 O O . SER 3 3 ? A -31.87762 -25.56296 6.18440 1 1 A SER 1 1
ATOM 20 C CB . SER 3 3 ? A -30.94316 -28.81173 6.16343 1 1 A SER 1 1
ATOM 21 O OG . SER 3 3 ? A -29.86211 -28.10054 5.57882 1 1 A SER 1 1
ATOM 22 N N . SER 4 4 ? A -31.73073 -26.62003 8.19052 1 1 A SER 1 1
ATOM 23 C CA . SER 4 4 ? A -31.46930 -25.39200 8.92463 1 1 A SER 1 1
ATOM 24 C C . SER 4 4 ? A -30.14469 -24.75081 8.53337 1 1 A SER 1 1
ATOM 25 O O . SER 4 4 ? A -30.03799 -23.52586 8.51124 1 1 A SER 1 1
ATOM 26 C CB . SER 4 4 ? A -31.53006 -25.64952 10.43299 1 1 A SER 1 1
ATOM 27 O OG . SER 4 4 ? A -30.49107 -26.50404 10.83541 1 1 A SER 1 1
ATOM 28 N N . ALA 5 5 ? A -29.13639 -25.53788 8.21803 1 1 A ALA 1 1
ATOM 29 C CA . ALA 5 5 ? A -27.87596 -24.99234 7.74898 1 1 A ALA 1 1
ATOM 30 C C . ALA 5 5 ? A -28.05050 -24.30346 6.40434 1 1 A ALA 1 1
ATOM 31 O O . ALA 5 5 ? A -27.43266 -23.25834 6.15474 1 1 A ALA 1 1
ATOM 32 C CB . ALA 5 5 ? A -26.83063 -26.09307 7.64927 1 1 A ALA 1 1
ATOM 33 N N . ASP 6 6 ? A -28.88643 -24.85117 5.54427 1 1 A ASP 1 1
ATOM 34 C CA . ASP 6 6 ? A -29.17958 -24.22774 4.25847 1 1 A ASP 1 1
ATOM 35 C C . ASP 6 6 ? A -29.86607 -22.88585 4.43272 1 1 A ASP 1 1
ATOM 36 O O . ASP 6 6 ? A -29.53356 -21.91708 3.74047 1 1 A ASP 1 1
ATOM 37 C CB . ASP 6 6 ? A -30.04377 -25.14204 3.39253 1 1 A ASP 1 1
ATOM 38 C CG . ASP 6 6 ? A -29.28497 -26.34067 2.85225 1 1 A ASP 1 1
ATOM 39 O OD1 . ASP 6 6 ? A -28.04581 -26.33873 2.86213 1 1 A ASP 1 1
ATOM 40 O OD2 . ASP 6 6 ? A -29.94658 -27.30161 2.41623 1 1 A ASP 1 1
ATOM 41 N N . LEU 7 7 ? A -30.81598 -22.78011 5.36289 1 1 A LEU 1 1
ATOM 42 C CA . LEU 7 7 ? A -31.48694 -21.51850 5.61730 1 1 A LEU 1 1
ATOM 43 C C . LEU 7 7 ? A -30.50634 -20.46963 6.12408 1 1 A LEU 1 1
ATOM 44 O O . LEU 7 7 ? A -30.58519 -19.30855 5.73143 1 1 A LEU 1 1
ATOM 45 C CB . LEU 7 7 ? A -32.63279 -21.69605 6.61797 1 1 A LEU 1 1
ATOM 46 C CG . LEU 7 7 ? A -33.84729 -22.45285 6.11514 1 1 A LEU 1 1
ATOM 47 C CD1 . LEU 7 7 ? A -34.84114 -22.68677 7.21817 1 1 A LEU 1 1
ATOM 48 C CD2 . LEU 7 7 ? A -34.51091 -21.70626 4.96827 1 1 A LEU 1 1
ATOM 49 N N . THR 8 8 ? A -29.56920 -20.85973 6.99196 1 1 A THR 1 1
ATOM 50 C CA . THR 8 8 ? A -28.55580 -19.95105 7.48541 1 1 A THR 1 1
ATOM 51 C C . THR 8 8 ? A -27.69456 -19.44503 6.34057 1 1 A THR 1 1
ATOM 52 O O . THR 8 8 ? A -27.39550 -18.24561 6.26620 1 1 A THR 1 1
ATOM 53 C CB . THR 8 8 ? A -27.68839 -20.62430 8.55991 1 1 A THR 1 1
ATOM 54 O OG1 . THR 8 8 ? A -28.51283 -21.00535 9.65699 1 1 A THR 1 1
ATOM 55 C CG2 . THR 8 8 ? A -26.58995 -19.69972 9.04830 1 1 A THR 1 1
ATOM 56 N N . ASN 9 9 ? A -27.29992 -20.32415 5.43453 1 1 A ASN 1 1
ATOM 57 C CA . ASN 9 9 ? A -26.51386 -19.93322 4.27155 1 1 A ASN 1 1
ATOM 58 C C . ASN 9 9 ? A -27.27623 -18.97948 3.36781 1 1 A ASN 1 1
ATOM 59 O O . ASN 9 9 ? A -26.70454 -17.98766 2.89482 1 1 A ASN 1 1
ATOM 60 C CB . ASN 9 9 ? A -26.07037 -21.15444 3.48120 1 1 A ASN 1 1
ATOM 61 C CG . ASN 9 9 ? A -24.95661 -21.92209 4.16884 1 1 A ASN 1 1
ATOM 62 O OD1 . ASN 9 9 ? A -24.19422 -21.36861 4.95357 1 1 A ASN 1 1
ATOM 63 N ND2 . ASN 9 9 ? A -24.85453 -23.20148 3.87663 1 1 A ASN 1 1
ATOM 64 N N . LEU 10 10 ? A -28.53802 -19.21930 3.12781 1 1 A LEU 1 1
ATOM 65 C CA . LEU 10 10 ? A -29.34325 -18.34472 2.28218 1 1 A LEU 1 1
ATOM 66 C C . LEU 10 10 ? A -29.51918 -16.97716 2.91502 1 1 A LEU 1 1
ATOM 67 O O . LEU 10 10 ? A -29.44392 -15.95825 2.21861 1 1 A LEU 1 1
ATOM 68 C CB . LEU 10 10 ? A -30.70003 -18.97496 1.98488 1 1 A LEU 1 1
ATOM 69 C CG . LEU 10 10 ? A -30.67752 -20.18443 1.05809 1 1 A LEU 1 1
ATOM 70 C CD1 . LEU 10 10 ? A -32.03323 -20.82977 0.97305 1 1 A LEU 1 1
ATOM 71 C CD2 . LEU 10 10 ? A -30.19762 -19.78427 -0.32857 1 1 A LEU 1 1
ATOM 72 N N . LYS 11 11 ? A -29.72695 -16.90469 4.22883 1 1 A LYS 1 1
ATOM 73 C CA . LYS 11 11 ? A -29.85271 -15.63221 4.92400 1 1 A LYS 1 1
ATOM 74 C C . LYS 11 11 ? A -28.55533 -14.84162 4.86712 1 1 A LYS 1 1
ATOM 75 O O . LYS 11 11 ? A -28.57359 -13.62572 4.64719 1 1 A LYS 1 1
ATOM 76 C CB . LYS 11 11 ? A -30.28221 -15.84742 6.37499 1 1 A LYS 1 1
ATOM 77 C CG . LYS 11 11 ? A -31.72726 -16.28606 6.52929 1 1 A LYS 1 1
ATOM 78 C CD . LYS 11 11 ? A -32.08739 -16.57049 7.97317 1 1 A LYS 1 1
ATOM 79 C CE . LYS 11 11 ? A -33.51403 -17.05195 8.11411 1 1 A LYS 1 1
ATOM 80 N NZ . LYS 11 11 ? A -33.84977 -17.39280 9.50244 1 1 A LYS 1 1
ATOM 81 N N . GLU 12 12 ? A -27.42566 -15.50555 5.04999 1 1 A GLU 1 1
ATOM 82 C CA . GLU 12 12 ? A -26.14091 -14.83105 4.95690 1 1 A GLU 1 1
ATOM 83 C C . GLU 12 12 ? A -25.89025 -14.33140 3.53882 1 1 A GLU 1 1
ATOM 84 O O . GLU 12 12 ? A -25.35477 -13.22587 3.35266 1 1 A GLU 1 1
ATOM 85 C CB . GLU 12 12 ? A -25.00365 -15.75163 5.42236 1 1 A GLU 1 1
ATOM 86 C CG . GLU 12 12 ? A -23.66808 -15.02409 5.50694 1 1 A GLU 1 1
ATOM 87 C CD . GLU 12 12 ? A -22.55383 -15.86524 6.10683 1 1 A GLU 1 1
ATOM 88 O OE1 . GLU 12 12 ? A -22.75124 -17.06192 6.33132 1 1 A GLU 1 1
ATOM 89 O OE2 . GLU 12 12 ? A -21.46559 -15.30875 6.32572 1 1 A GLU 1 1
ATOM 90 N N . LEU 13 13 ? A -26.27338 -15.10871 2.54849 1 1 A LEU 1 1
ATOM 91 C CA . LEU 13 13 ? A -26.13171 -14.70118 1.15389 1 1 A LEU 1 1
ATOM 92 C C . LEU 13 13 ? A -26.89850 -13.41458 0.89722 1 1 A LEU 1 1
ATOM 93 O O . LEU 13 13 ? A -26.36481 -12.47390 0.28689 1 1 A LEU 1 1
ATOM 94 C CB . LEU 13 13 ? A -26.61783 -15.82181 0.23114 1 1 A LEU 1 1
ATOM 95 C CG . LEU 13 13 ? A -26.63075 -15.52066 -1.25982 1 1 A LEU 1 1
ATOM 96 C CD1 . LEU 13 13 ? A -25.21445 -15.25358 -1.75948 1 1 A LEU 1 1
ATOM 97 C CD2 . LEU 13 13 ? A -27.22590 -16.69150 -2.01912 1 1 A LEU 1 1
ATOM 98 N N . LEU 14 14 ? A -28.11703 -13.30826 1.34451 1 1 A LEU 1 1
ATOM 99 C CA . LEU 14 14 ? A -28.91889 -12.11120 1.15054 1 1 A LEU 1 1
ATOM 100 C C . LEU 14 14 ? A -28.33771 -10.91728 1.90179 1 1 A LEU 1 1
ATOM 101 O O . LEU 14 14 ? A -28.35829 -9.79754 1.38421 1 1 A LEU 1 1
ATOM 102 C CB . LEU 14 14 ? A -30.36413 -12.34950 1.57334 1 1 A LEU 1 1
ATOM 103 C CG . LEU 14 14 ? A -31.14679 -13.31861 0.69272 1 1 A LEU 1 1
ATOM 104 C CD1 . LEU 14 14 ? A -32.53899 -13.54402 1.22736 1 1 A LEU 1 1
ATOM 105 C CD2 . LEU 14 14 ? A -31.21922 -12.80190 -0.73631 1 1 A LEU 1 1
ATOM 106 N N . SER 15 15 ? A -27.81642 -11.12793 3.11071 1 1 A SER 1 1
ATOM 107 C CA . SER 15 15 ? A -27.18039 -10.05847 3.86961 1 1 A SER 1 1
ATOM 108 C C . SER 15 15 ? A -25.97125 -9.50767 3.13858 1 1 A SER 1 1
ATOM 109 O O . SER 15 15 ? A -25.80364 -8.29074 3.03761 1 1 A SER 1 1
ATOM 110 C CB . SER 15 15 ? A -26.77367 -10.56044 5.25599 1 1 A SER 1 1
ATOM 111 O OG . SER 15 15 ? A -27.91499 -10.82817 6.04319 1 1 A SER 1 1
ATOM 112 N N . LEU 16 16 ? A -25.13801 -10.38408 2.61360 1 1 A LEU 1 1
ATOM 113 C CA . LEU 16 16 ? A -23.96234 -9.96049 1.87336 1 1 A LEU 1 1
ATOM 114 C C . LEU 16 16 ? A -24.34836 -9.24150 0.59298 1 1 A LEU 1 1
ATOM 115 O O . LEU 16 16 ? A -23.70560 -8.24844 0.21801 1 1 A LEU 1 1
ATOM 116 C CB . LEU 16 16 ? A -23.05791 -11.15130 1.55631 1 1 A LEU 1 1
ATOM 117 C CG . LEU 16 16 ? A -22.31370 -11.75510 2.74606 1 1 A LEU 1 1
ATOM 118 C CD1 . LEU 16 16 ? A -21.64334 -13.05150 2.35333 1 1 A LEU 1 1
ATOM 119 C CD2 . LEU 16 16 ? A -21.28139 -10.77274 3.28134 1 1 A LEU 1 1
ATOM 120 N N . TYR 17 17 ? A -25.38725 -9.70897 -0.09391 1 1 A TYR 1 1
ATOM 121 C CA . TYR 17 17 ? A -25.85412 -9.06415 -1.31358 1 1 A TYR 1 1
ATOM 122 C C . TYR 17 17 ? A -26.23038 -7.60486 -1.05451 1 1 A TYR 1 1
ATOM 123 O O . TYR 17 17 ? A -25.86862 -6.72002 -1.84049 1 1 A TYR 1 1
ATOM 124 C CB . TYR 17 17 ? A -27.04652 -9.82210 -1.89523 1 1 A TYR 1 1
ATOM 125 C CG . TYR 17 17 ? A -27.68878 -9.13837 -3.08342 1 1 A TYR 1 1
ATOM 126 C CD1 . TYR 17 17 ? A -27.11915 -9.23584 -4.34209 1 1 A TYR 1 1
ATOM 127 C CD2 . TYR 17 17 ? A -28.85293 -8.39776 -2.93767 1 1 A TYR 1 1
ATOM 128 C CE1 . TYR 17 17 ? A -27.69112 -8.60170 -5.44057 1 1 A TYR 1 1
ATOM 129 C CE2 . TYR 17 17 ? A -29.43547 -7.75254 -4.02508 1 1 A TYR 1 1
ATOM 130 C CZ . TYR 17 17 ? A -28.84394 -7.86642 -5.27697 1 1 A TYR 1 1
ATOM 131 O OH . TYR 17 17 ? A -29.42085 -7.23127 -6.35584 1 1 A TYR 1 1
ATOM 132 N N . LYS 18 18 ? A -26.89609 -7.32794 0.05651 1 1 A LYS 1 1
ATOM 133 C CA . LYS 18 18 ? A -27.33005 -5.98363 0.38836 1 1 A LYS 1 1
ATOM 134 C C . LYS 18 18 ? A -26.18654 -5.02455 0.66873 1 1 A LYS 1 1
ATOM 135 O O . LYS 18 18 ? A -26.31763 -3.82744 0.48909 1 1 A LYS 1 1
ATOM 136 C CB . LYS 18 18 ? A -28.26513 -6.01913 1.59478 1 1 A LYS 1 1
ATOM 137 C CG . LYS 18 18 ? A -29.60767 -6.67319 1.30639 1 1 A LYS 1 1
ATOM 138 C CD . LYS 18 18 ? A -30.46618 -6.75036 2.55754 1 1 A LYS 1 1
ATOM 139 C CE . LYS 18 18 ? A -31.78276 -7.43046 2.29945 1 1 A LYS 1 1
ATOM 140 N NZ . LYS 18 18 ? A -32.60016 -7.53510 3.51098 1 1 A LYS 1 1
ATOM 141 N N . SER 19 19 ? A -25.05110 -5.54758 1.14908 1 1 A SER 1 1
ATOM 142 C CA . SER 19 19 ? A -23.92999 -4.68445 1.52367 1 1 A SER 1 1
ATOM 143 C C . SER 19 19 ? A -22.76480 -4.73511 0.54657 1 1 A SER 1 1
ATOM 144 O O . SER 19 19 ? A -21.65974 -4.29795 0.88803 1 1 A SER 1 1
ATOM 145 C CB . SER 19 19 ? A -23.43934 -5.04883 2.93016 1 1 A SER 1 1
ATOM 146 O OG . SER 19 19 ? A -22.88893 -6.35420 2.94795 1 1 A SER 1 1
ATOM 147 N N . LEU 20 20 ? A -22.96729 -5.26123 -0.65538 1 1 A LEU 1 1
ATOM 148 C CA . LEU 20 20 ? A -21.88646 -5.43957 -1.61241 1 1 A LEU 1 1
ATOM 149 C C . LEU 20 20 ? A -21.17868 -4.16190 -2.02372 1 1 A LEU 1 1
ATOM 150 O O . LEU 20 20 ? A -19.98834 -4.19348 -2.34314 1 1 A LEU 1 1
ATOM 151 C CB . LEU 20 20 ? A -22.37579 -6.16335 -2.86591 1 1 A LEU 1 1
ATOM 152 C CG . LEU 20 20 ? A -22.57381 -7.65697 -2.74366 1 1 A LEU 1 1
ATOM 153 C CD1 . LEU 20 20 ? A -23.13276 -8.21195 -4.03901 1 1 A LEU 1 1
ATOM 154 C CD2 . LEU 20 20 ? A -21.26285 -8.34422 -2.41022 1 1 A LEU 1 1
ATOM 155 N N . ARG 21 21 ? A -21.90677 -3.04178 -2.03382 1 1 A ARG 1 1
ATOM 156 C CA . ARG 21 21 ? A -21.31998 -1.78595 -2.50967 1 1 A ARG 1 1
ATOM 157 C C . ARG 21 21 ? A -19.94987 -1.49227 -1.90894 1 1 A ARG 1 1
ATOM 158 O O . ARG 21 21 ? A -19.06937 -0.99608 -2.60169 1 1 A ARG 1 1
ATOM 159 C CB . ARG 21 21 ? A -22.23693 -0.60877 -2.18801 1 1 A ARG 1 1
ATOM 160 C CG . ARG 21 21 ? A -23.50415 -0.55244 -3.00880 1 1 A ARG 1 1
ATOM 161 C CD . ARG 21 21 ? A -24.32859 0.66113 -2.60382 1 1 A ARG 1 1
ATOM 162 N NE . ARG 21 21 ? A -25.51234 0.77785 -3.40831 1 1 A ARG 1 1
ATOM 163 C CZ . ARG 21 21 ? A -25.57958 1.44076 -4.54449 1 1 A ARG 1 1
ATOM 164 N NH1 . ARG 21 21 ? A -24.53385 2.06921 -5.01450 1 1 A ARG 1 1
ATOM 165 N NH2 . ARG 21 21 ? A -26.72229 1.48330 -5.22085 1 1 A ARG 1 1
ATOM 166 N N . PHE 22 22 ? A -19.78055 -1.78106 -0.63411 1 1 A PHE 1 1
ATOM 167 C CA . PHE 22 22 ? A -18.56813 -1.38853 0.05987 1 1 A PHE 1 1
ATOM 168 C C . PHE 22 22 ? A -17.82449 -2.56988 0.68705 1 1 A PHE 1 1
ATOM 169 O O . PHE 22 22 ? A -16.99875 -2.39089 1.58036 1 1 A PHE 1 1
ATOM 170 C CB . PHE 22 22 ? A -18.88073 -0.34396 1.13366 1 1 A PHE 1 1
ATOM 171 C CG . PHE 22 22 ? A -19.66085 0.80322 0.58987 1 1 A PHE 1 1
ATOM 172 C CD1 . PHE 22 22 ? A -19.08841 1.67108 -0.31230 1 1 A PHE 1 1
ATOM 173 C CD2 . PHE 22 22 ? A -20.97358 1.00961 0.95629 1 1 A PHE 1 1
ATOM 174 C CE1 . PHE 22 22 ? A -19.80603 2.72862 -0.84122 1 1 A PHE 1 1
ATOM 175 C CE2 . PHE 22 22 ? A -21.70423 2.05890 0.43652 1 1 A PHE 1 1
ATOM 176 C CZ . PHE 22 22 ? A -21.11049 2.92604 -0.47034 1 1 A PHE 1 1
ATOM 177 N N . SER 23 23 ? A -18.07602 -3.78548 0.20663 1 1 A SER 1 1
ATOM 178 C CA . SER 23 23 ? A -17.47850 -4.98369 0.75791 1 1 A SER 1 1
ATOM 179 C C . SER 23 23 ? A -16.05453 -5.18091 0.27652 1 1 A SER 1 1
ATOM 180 O O . SER 23 23 ? A -15.70812 -4.82453 -0.85084 1 1 A SER 1 1
ATOM 181 C CB . SER 23 23 ? A -18.30438 -6.19895 0.39277 1 1 A SER 1 1
ATOM 182 O OG . SER 23 23 ? A -19.58968 -6.12549 0.97227 1 1 A SER 1 1
ATOM 183 N N . ASP 24 24 ? A -15.20452 -5.76496 1.15227 1 1 A ASP 1 1
ATOM 184 C CA . ASP 24 24 ? A -13.83995 -6.09900 0.77546 1 1 A ASP 1 1
ATOM 185 C C . ASP 24 24 ? A -13.80859 -7.44305 0.05241 1 1 A ASP 1 1
ATOM 186 O O . ASP 24 24 ? A -14.83882 -8.11360 -0.07975 1 1 A ASP 1 1
ATOM 187 C CB . ASP 24 24 ? A -12.91328 -6.07969 1.99092 1 1 A ASP 1 1
ATOM 188 C CG . ASP 24 24 ? A -13.27686 -7.07683 3.05934 1 1 A ASP 1 1
ATOM 189 O OD1 . ASP 24 24 ? A -13.91622 -8.10174 2.77479 1 1 A ASP 1 1
ATOM 190 O OD2 . ASP 24 24 ? A -12.88885 -6.86190 4.23500 1 1 A ASP 1 1
ATOM 191 N N . SER 25 25 ? A -12.62323 -7.85213 -0.40654 1 1 A SER 1 1
ATOM 192 C CA . SER 25 25 ? A -12.49233 -9.07703 -1.19339 1 1 A SER 1 1
ATOM 193 C C . SER 25 25 ? A -12.89480 -10.32828 -0.41671 1 1 A SER 1 1
ATOM 194 O O . SER 25 25 ? A -13.39873 -11.28019 -1.00601 1 1 A SER 1 1
ATOM 195 C CB . SER 25 25 ? A -11.07064 -9.21374 -1.73375 1 1 A SER 1 1
ATOM 196 O OG . SER 25 25 ? A -10.14631 -9.33716 -0.67995 1 1 A SER 1 1
ATOM 197 N N . VAL 26 26 ? A -12.69091 -10.34072 0.90620 1 1 A VAL 1 1
ATOM 198 C CA . VAL 26 26 ? A -13.08344 -11.48287 1.71394 1 1 A VAL 1 1
ATOM 199 C C . VAL 26 26 ? A -14.59998 -11.64223 1.71775 1 1 A VAL 1 1
ATOM 200 O O . VAL 26 26 ? A -15.11125 -12.75414 1.55485 1 1 A VAL 1 1
ATOM 201 C CB . VAL 26 26 ? A -12.54534 -11.35036 3.15683 1 1 A VAL 1 1
ATOM 202 C CG1 . VAL 26 26 ? A -13.11613 -12.43344 4.05399 1 1 A VAL 1 1
ATOM 203 C CG2 . VAL 26 26 ? A -11.03059 -11.41190 3.15519 1 1 A VAL 1 1
ATOM 204 N N . ALA 27 27 ? A -15.31797 -10.55084 1.91214 1 1 A ALA 1 1
ATOM 205 C CA . ALA 27 27 ? A -16.76784 -10.59675 1.87738 1 1 A ALA 1 1
ATOM 206 C C . ALA 27 27 ? A -17.27612 -10.98600 0.49758 1 1 A ALA 1 1
ATOM 207 O O . ALA 27 27 ? A -18.23602 -11.75531 0.37506 1 1 A ALA 1 1
ATOM 208 C CB . ALA 27 27 ? A -17.34772 -9.26186 2.31385 1 1 A ALA 1 1
ATOM 209 N N . ILE 28 28 ? A -16.62566 -10.46811 -0.54370 1 1 A ILE 1 1
ATOM 210 C CA . ILE 28 28 ? A -17.01220 -10.81617 -1.90871 1 1 A ILE 1 1
ATOM 211 C C . ILE 28 28 ? A -16.75511 -12.29042 -2.18490 1 1 A ILE 1 1
ATOM 212 O O . ILE 28 28 ? A -17.57590 -12.96172 -2.82001 1 1 A ILE 1 1
ATOM 213 C CB . ILE 28 28 ? A -16.29840 -9.91747 -2.92713 1 1 A ILE 1 1
ATOM 214 C CG1 . ILE 28 28 ? A -16.85338 -8.48182 -2.82557 1 1 A ILE 1 1
ATOM 215 C CG2 . ILE 28 28 ? A -16.46679 -10.44603 -4.34305 1 1 A ILE 1 1
ATOM 216 C CD1 . ILE 28 28 ? A -16.07878 -7.45618 -3.62634 1 1 A ILE 1 1
ATOM 217 N N . GLU 29 29 ? A -15.60291 -12.81358 -1.70094 1 1 A GLU 1 1
ATOM 218 C CA . GLU 29 29 ? A -15.32012 -14.24326 -1.84620 1 1 A GLU 1 1
ATOM 219 C C . GLU 29 29 ? A -16.36928 -15.08583 -1.14024 1 1 A GLU 1 1
ATOM 220 O O . GLU 29 29 ? A -16.83401 -16.09513 -1.68771 1 1 A GLU 1 1
ATOM 221 C CB . GLU 29 29 ? A -13.92994 -14.58698 -1.30003 1 1 A GLU 1 1
ATOM 222 C CG . GLU 29 29 ? A -12.79805 -14.09777 -2.17677 1 1 A GLU 1 1
ATOM 223 C CD . GLU 29 29 ? A -11.42736 -14.48618 -1.63358 1 1 A GLU 1 1
ATOM 224 O OE1 . GLU 29 29 ? A -11.31128 -14.82469 -0.44346 1 1 A GLU 1 1
ATOM 225 O OE2 . GLU 29 29 ? A -10.46780 -14.46025 -2.41505 1 1 A GLU 1 1
ATOM 226 N N . LYS 30 30 ? A -16.73147 -14.68054 0.09733 1 1 A LYS 1 1
ATOM 227 C CA . LYS 30 30 ? A -17.77331 -15.38987 0.82901 1 1 A LYS 1 1
ATOM 228 C C . LYS 30 30 ? A -19.09298 -15.33089 0.07238 1 1 A LYS 1 1
ATOM 229 O O . LYS 30 30 ? A -19.79316 -16.34249 -0.04059 1 1 A LYS 1 1
ATOM 230 C CB . LYS 30 30 ? A -17.91228 -14.81594 2.24088 1 1 A LYS 1 1
ATOM 231 C CG . LYS 30 30 ? A -18.96045 -15.49311 3.09835 1 1 A LYS 1 1
ATOM 232 C CD . LYS 30 30 ? A -18.57953 -16.91662 3.41792 1 1 A LYS 1 1
ATOM 233 C CE . LYS 30 30 ? A -19.57369 -17.55251 4.37497 1 1 A LYS 1 1
ATOM 234 N NZ . LYS 30 30 ? A -19.25249 -18.97343 4.61392 1 1 A LYS 1 1
ATOM 235 N N . TYR 31 31 ? A -19.42506 -14.14786 -0.42986 1 1 A TYR 1 1
ATOM 236 C CA . TYR 31 31 ? A -20.63737 -13.99050 -1.22687 1 1 A TYR 1 1
ATOM 237 C C . TYR 31 31 ? A -20.62355 -14.92853 -2.43049 1 1 A TYR 1 1
ATOM 238 O O . TYR 31 31 ? A -21.59383 -15.66552 -2.66990 1 1 A TYR 1 1
ATOM 239 C CB . TYR 31 31 ? A -20.79030 -12.53195 -1.67644 1 1 A TYR 1 1
ATOM 240 C CG . TYR 31 31 ? A -21.89509 -12.30174 -2.67955 1 1 A TYR 1 1
ATOM 241 C CD1 . TYR 31 31 ? A -23.21654 -12.15869 -2.26825 1 1 A TYR 1 1
ATOM 242 C CD2 . TYR 31 31 ? A -21.60910 -12.23380 -4.04075 1 1 A TYR 1 1
ATOM 243 C CE1 . TYR 31 31 ? A -24.23322 -11.95164 -3.18248 1 1 A TYR 1 1
ATOM 244 C CE2 . TYR 31 31 ? A -22.61862 -12.03021 -4.96930 1 1 A TYR 1 1
ATOM 245 C CZ . TYR 31 31 ? A -23.93207 -11.88994 -4.52461 1 1 A TYR 1 1
ATOM 246 O OH . TYR 31 31 ? A -24.93831 -11.69162 -5.44800 1 1 A TYR 1 1
ATOM 247 N N . ASN 32 32 ? A -19.52989 -14.90721 -3.19756 1 1 A ASN 1 1
ATOM 248 C CA . ASN 32 32 ? A -19.43468 -15.75193 -4.38878 1 1 A ASN 1 1
ATOM 249 C C . ASN 32 32 ? A -19.47258 -17.23757 -4.05246 1 1 A ASN 1 1
ATOM 250 O O . ASN 32 32 ? A -20.06920 -18.02165 -4.79955 1 1 A ASN 1 1
ATOM 251 C CB . ASN 32 32 ? A -18.17818 -15.41187 -5.19244 1 1 A ASN 1 1
ATOM 252 C CG . ASN 32 32 ? A -18.27797 -14.07218 -5.89654 1 1 A ASN 1 1
ATOM 253 O OD1 . ASN 32 32 ? A -19.37521 -13.56409 -6.13060 1 1 A ASN 1 1
ATOM 254 N ND2 . ASN 32 32 ? A -17.13672 -13.48341 -6.23111 1 1 A ASN 1 1
ATOM 255 N N . SER 33 33 ? A -18.86295 -17.64595 -2.91950 1 1 A SER 1 1
ATOM 256 C CA . SER 33 33 ? A -18.94755 -19.03862 -2.47947 1 1 A SER 1 1
ATOM 257 C C . SER 33 33 ? A -20.37494 -19.43917 -2.18427 1 1 A SER 1 1
ATOM 258 O O . SER 33 33 ? A -20.80621 -20.53416 -2.55838 1 1 A SER 1 1
ATOM 259 C CB . SER 33 33 ? A -18.08609 -19.25950 -1.23024 1 1 A SER 1 1
ATOM 260 O OG . SER 33 33 ? A -16.71737 -19.11370 -1.53249 1 1 A SER 1 1
ATOM 261 N N . LEU 34 34 ? A -21.11502 -18.54899 -1.52562 1 1 A LEU 1 1
ATOM 262 C CA . LEU 34 34 ? A -22.50888 -18.83385 -1.22422 1 1 A LEU 1 1
ATOM 263 C C . LEU 34 34 ? A -23.36733 -18.85189 -2.47533 1 1 A LEU 1 1
ATOM 264 O O . LEU 34 34 ? A -24.30066 -19.65920 -2.57446 1 1 A LEU 1 1
ATOM 265 C CB . LEU 34 34 ? A -23.05358 -17.82943 -0.20747 1 1 A LEU 1 1
ATOM 266 C CG . LEU 34 34 ? A -22.48220 -17.96565 1.21294 1 1 A LEU 1 1
ATOM 267 C CD1 . LEU 34 34 ? A -22.97081 -16.83682 2.10098 1 1 A LEU 1 1
ATOM 268 C CD2 . LEU 34 34 ? A -22.87963 -19.30626 1.82578 1 1 A LEU 1 1
ATOM 269 N N . VAL 35 35 ? A -23.05591 -17.98206 -3.46288 1 1 A VAL 1 1
ATOM 270 C CA . VAL 35 35 ? A -23.77794 -18.01184 -4.73334 1 1 A VAL 1 1
ATOM 271 C C . VAL 35 35 ? A -23.56181 -19.35103 -5.42940 1 1 A VAL 1 1
ATOM 272 O O . VAL 35 35 ? A -24.51356 -19.95958 -5.93362 1 1 A VAL 1 1
ATOM 273 C CB . VAL 35 35 ? A -23.36036 -16.83807 -5.64045 1 1 A VAL 1 1
ATOM 274 C CG1 . VAL 35 35 ? A -23.89527 -17.02511 -7.05362 1 1 A VAL 1 1
ATOM 275 C CG2 . VAL 35 35 ? A -23.84910 -15.51904 -5.06319 1 1 A VAL 1 1
ATOM 276 N N . GLU 36 36 ? A -22.27785 -19.78857 -5.46496 1 1 A GLU 1 1
ATOM 277 C CA . GLU 36 36 ? A -21.97096 -21.06985 -6.09032 1 1 A GLU 1 1
ATOM 278 C C . GLU 36 36 ? A -22.68516 -22.21937 -5.38648 1 1 A GLU 1 1
ATOM 279 O O . GLU 36 36 ? A -23.26299 -23.09668 -6.03963 1 1 A GLU 1 1
ATOM 280 C CB . GLU 36 36 ? A -20.46111 -21.29996 -6.10616 1 1 A GLU 1 1
ATOM 281 C CG . GLU 36 36 ? A -20.06138 -22.58734 -6.84085 1 1 A GLU 1 1
ATOM 282 C CD . GLU 36 36 ? A -18.54963 -22.77159 -6.93053 1 1 A GLU 1 1
ATOM 283 O OE1 . GLU 36 36 ? A -17.83143 -22.20788 -6.10069 1 1 A GLU 1 1
ATOM 284 O OE2 . GLU 36 36 ? A -18.10489 -23.49773 -7.82811 1 1 A GLU 1 1
ATOM 285 N N . TRP 37 37 ? A -22.63827 -22.19715 -4.00042 1 1 A TRP 1 1
ATOM 286 C CA . TRP 37 37 ? A -23.36241 -23.20125 -3.22990 1 1 A TRP 1 1
ATOM 287 C C . TRP 37 37 ? A -24.84395 -23.17554 -3.56296 1 1 A TRP 1 1
ATOM 288 O O . TRP 37 37 ? A -25.45268 -24.22190 -3.79842 1 1 A TRP 1 1
ATOM 289 C CB . TRP 37 37 ? A -23.15860 -22.95862 -1.72314 1 1 A TRP 1 1
ATOM 290 C CG . TRP 37 37 ? A -24.08879 -23.75247 -0.88077 1 1 A TRP 1 1
ATOM 291 C CD1 . TRP 37 37 ? A -23.94013 -25.03913 -0.45232 1 1 A TRP 1 1
ATOM 292 C CD2 . TRP 37 37 ? A -25.36836 -23.30610 -0.38015 1 1 A TRP 1 1
ATOM 293 N NE1 . TRP 37 37 ? A -25.03318 -25.41574 0.30547 1 1 A TRP 1 1
ATOM 294 C CE2 . TRP 37 37 ? A -25.91601 -24.38687 0.35858 1 1 A TRP 1 1
ATOM 295 C CE3 . TRP 37 37 ? A -26.09212 -22.10341 -0.49172 1 1 A TRP 1 1
ATOM 296 C CZ2 . TRP 37 37 ? A -27.15525 -24.29531 0.98806 1 1 A TRP 1 1
ATOM 297 C CZ3 . TRP 37 37 ? A -27.32538 -22.01573 0.13241 1 1 A TRP 1 1
ATOM 298 C CH2 . TRP 37 37 ? A -27.83890 -23.10738 0.85603 1 1 A TRP 1 1
ATOM 299 N N . GLY 38 38 ? A -25.45892 -21.99965 -3.58412 1 1 A GLY 1 1
ATOM 300 C CA . GLY 38 38 ? A -26.87580 -21.84389 -3.84729 1 1 A GLY 1 1
ATOM 301 C C . GLY 38 38 ? A -27.29969 -22.32292 -5.21784 1 1 A GLY 1 1
ATOM 302 O O . GLY 38 38 ? A -28.30573 -23.01609 -5.36007 1 1 A GLY 1 1
ATOM 303 N N . THR 39 39 ? A -26.50242 -21.96244 -6.24816 1 1 A THR 1 1
ATOM 304 C CA . THR 39 39 ? A -26.88537 -22.35572 -7.59765 1 1 A THR 1 1
ATOM 305 C C . THR 39 39 ? A -26.74117 -23.85553 -7.83325 1 1 A THR 1 1
ATOM 306 O O . THR 39 39 ? A -27.47117 -24.43208 -8.63132 1 1 A THR 1 1
ATOM 307 C CB . THR 39 39 ? A -26.11646 -21.57088 -8.66770 1 1 A THR 1 1
ATOM 308 O OG1 . THR 39 39 ? A -24.70837 -21.80640 -8.51871 1 1 A THR 1 1
ATOM 309 C CG2 . THR 39 39 ? A -26.39017 -20.08679 -8.56005 1 1 A THR 1 1
ATOM 310 N N . SER 40 40 ? A -25.77626 -24.48910 -7.13028 1 1 A SER 1 1
ATOM 311 C CA . SER 40 40 ? A -25.63106 -25.93493 -7.27563 1 1 A SER 1 1
ATOM 312 C C . SER 40 40 ? A -26.67208 -26.70544 -6.47210 1 1 A SER 1 1
ATOM 313 O O . SER 40 40 ? A -26.99153 -27.84368 -6.79873 1 1 A SER 1 1
ATOM 314 C CB . SER 40 40 ? A -24.22477 -26.37793 -6.88526 1 1 A SER 1 1
ATOM 315 O OG . SER 40 40 ? A -24.04772 -26.35981 -5.49272 1 1 A SER 1 1
ATOM 316 N N . THR 41 41 ? A -27.20698 -26.09198 -5.40689 1 1 A THR 1 1
ATOM 317 C CA . THR 41 41 ? A -28.21762 -26.71661 -4.56317 1 1 A THR 1 1
ATOM 318 C C . THR 41 41 ? A -29.63231 -26.39479 -5.01909 1 1 A THR 1 1
ATOM 319 O O . THR 41 41 ? A -30.50931 -27.25622 -5.01998 1 1 A THR 1 1
ATOM 320 C CB . THR 41 41 ? A -28.04499 -26.27098 -3.10269 1 1 A THR 1 1
ATOM 321 O OG1 . THR 41 41 ? A -26.72566 -26.60266 -2.66667 1 1 A THR 1 1
ATOM 322 C CG2 . THR 41 41 ? A -29.05742 -26.94983 -2.19800 1 1 A THR 1 1
ATOM 323 N N . TYR 42 42 ? A -29.83188 -25.11159 -5.39754 1 1 A TYR 1 1
ATOM 324 C CA . TYR 42 42 ? A -31.13951 -24.63794 -5.83368 1 1 A TYR 1 1
ATOM 325 C C . TYR 42 42 ? A -31.06933 -24.07618 -7.23840 1 1 A TYR 1 1
ATOM 326 O O . TYR 42 42 ? A -30.12273 -23.37899 -7.58919 1 1 A TYR 1 1
ATOM 327 C CB . TYR 42 42 ? A -31.65569 -23.55961 -4.87686 1 1 A TYR 1 1
ATOM 328 C CG . TYR 42 42 ? A -31.71066 -23.99163 -3.44633 1 1 A TYR 1 1
ATOM 329 C CD1 . TYR 42 42 ? A -32.75041 -24.78730 -2.98017 1 1 A TYR 1 1
ATOM 330 C CD2 . TYR 42 42 ? A -30.70429 -23.62751 -2.55865 1 1 A TYR 1 1
ATOM 331 C CE1 . TYR 42 42 ? A -32.79105 -25.20820 -1.65473 1 1 A TYR 1 1
ATOM 332 C CE2 . TYR 42 42 ? A -30.73492 -24.04505 -1.23612 1 1 A TYR 1 1
ATOM 333 C CZ . TYR 42 42 ? A -31.78967 -24.83763 -0.79631 1 1 A TYR 1 1
ATOM 334 O OH . TYR 42 42 ? A -31.83775 -25.25330 0.51122 1 1 A TYR 1 1
ATOM 335 N N . TRP 43 43 ? A -32.06444 -24.26723 -8.03874 1 1 A TRP 1 1
ATOM 336 C CA . TRP 43 43 ? A -32.15860 -23.76983 -9.39898 1 1 A TRP 1 1
ATOM 337 C C . TRP 43 43 ? A -32.79673 -22.38875 -9.46933 1 1 A TRP 1 1
ATOM 338 O O . TRP 43 43 ? A -32.71171 -21.71649 -10.49747 1 1 A TRP 1 1
ATOM 339 C CB . TRP 43 43 ? A -32.93575 -24.72838 -10.29637 1 1 A TRP 1 1
ATOM 340 C CG . TRP 43 43 ? A -34.25931 -25.06146 -9.77271 1 1 A TRP 1 1
ATOM 341 C CD1 . TRP 43 43 ? A -35.45067 -24.44110 -10.01765 1 1 A TRP 1 1
ATOM 342 C CD2 . TRP 43 43 ? A -34.52542 -26.10289 -8.81290 1 1 A TRP 1 1
ATOM 343 N NE1 . TRP 43 43 ? A -36.46705 -25.03045 -9.30492 1 1 A TRP 1 1
ATOM 344 C CE2 . TRP 43 43 ? A -35.91933 -26.05784 -8.54339 1 1 A TRP 1 1
ATOM 345 C CE3 . TRP 43 43 ? A -33.73299 -27.06742 -8.14623 1 1 A TRP 1 1
ATOM 346 C CZ2 . TRP 43 43 ? A -36.54060 -26.92855 -7.64962 1 1 A TRP 1 1
ATOM 347 C CZ3 . TRP 43 43 ? A -34.32746 -27.93956 -7.26343 1 1 A TRP 1 1
ATOM 348 C CH2 . TRP 43 43 ? A -35.71069 -27.87672 -7.02650 1 1 A TRP 1 1
ATOM 349 N N . LYS 44 44 ? A -33.41954 -21.94745 -8.40527 1 1 A LYS 1 1
ATOM 350 C CA . LYS 44 44 ? A -34.19115 -20.72383 -8.41137 1 1 A LYS 1 1
ATOM 351 C C . LYS 44 44 ? A -33.51058 -19.51682 -7.75730 1 1 A LYS 1 1
ATOM 352 O O . LYS 44 44 ? A -34.15249 -18.50045 -7.53401 1 1 A LYS 1 1
ATOM 353 C CB . LYS 44 44 ? A -35.53391 -20.96314 -7.72268 1 1 A LYS 1 1
ATOM 354 C CG . LYS 44 44 ? A -35.42033 -21.50739 -6.30668 1 1 A LYS 1 1
ATOM 355 C CD . LYS 44 44 ? A -36.79399 -21.91908 -5.75498 1 1 A LYS 1 1
ATOM 356 C CE . LYS 44 44 ? A -36.69688 -22.54795 -4.35792 1 1 A LYS 1 1
ATOM 357 N NZ . LYS 44 44 ? A -38.03846 -22.91132 -3.84127 1 1 A LYS 1 1
ATOM 358 N N . ILE 45 45 ? A -32.20105 -19.60647 -7.46010 1 1 A ILE 1 1
ATOM 359 C CA . ILE 45 45 ? A -31.50259 -18.48512 -6.86451 1 1 A ILE 1 1
ATOM 360 C C . ILE 45 45 ? A -31.35163 -17.36650 -7.88061 1 1 A ILE 1 1
ATOM 361 O O . ILE 45 45 ? A -30.88452 -17.57350 -8.99354 1 1 A ILE 1 1
ATOM 362 C CB . ILE 45 45 ? A -30.12480 -18.89931 -6.33561 1 1 A ILE 1 1
ATOM 363 C CG1 . ILE 45 45 ? A -30.28189 -19.76559 -5.08602 1 1 A ILE 1 1
ATOM 364 C CG2 . ILE 45 45 ? A -29.28069 -17.68742 -6.00130 1 1 A ILE 1 1
ATOM 365 C CD1 . ILE 45 45 ? A -28.99862 -20.27673 -4.51873 1 1 A ILE 1 1
ATOM 366 N N . GLY 46 46 ? A -31.74134 -16.15966 -7.49315 1 1 A GLY 1 1
ATOM 367 C CA . GLY 46 46 ? A -31.74857 -15.02751 -8.39264 1 1 A GLY 1 1
ATOM 368 C C . GLY 46 46 ? A -30.59277 -14.06113 -8.23994 1 1 A GLY 1 1
ATOM 369 O O . GLY 46 46 ? A -30.71857 -12.89673 -8.64012 1 1 A GLY 1 1
ATOM 370 N N . VAL 47 47 ? A -29.46562 -14.49026 -7.66931 1 1 A VAL 1 1
ATOM 371 C CA . VAL 47 47 ? A -28.31936 -13.62217 -7.52763 1 1 A VAL 1 1
ATOM 372 C C . VAL 47 47 ? A -27.15892 -14.15530 -8.34428 1 1 A VAL 1 1
ATOM 373 O O . VAL 47 47 ? A -27.07239 -15.35249 -8.61437 1 1 A VAL 1 1
ATOM 374 C CB . VAL 47 47 ? A -27.90153 -13.47697 -6.05998 1 1 A VAL 1 1
ATOM 375 C CG1 . VAL 47 47 ? A -28.99093 -12.75943 -5.28268 1 1 A VAL 1 1
ATOM 376 C CG2 . VAL 47 47 ? A -27.60037 -14.82646 -5.43484 1 1 A VAL 1 1
ATOM 377 N N . GLN 48 48 ? A -26.23386 -13.24952 -8.71434 1 1 A GLN 1 1
ATOM 378 C CA . GLN 48 48 ? A -25.09539 -13.61946 -9.53909 1 1 A GLN 1 1
ATOM 379 C C . GLN 48 48 ? A -23.79485 -13.26798 -8.85022 1 1 A GLN 1 1
ATOM 380 O O . GLN 48 48 ? A -23.76504 -12.41121 -7.95871 1 1 A GLN 1 1
ATOM 381 C CB . GLN 48 48 ? A -25.16002 -12.91145 -10.88225 1 1 A GLN 1 1
ATOM 382 C CG . GLN 48 48 ? A -26.39002 -13.28408 -11.68389 1 1 A GLN 1 1
ATOM 383 C CD . GLN 48 48 ? A -26.47909 -12.51810 -12.98787 1 1 A GLN 1 1
ATOM 384 O OE1 . GLN 48 48 ? A -25.54168 -11.87308 -13.41307 1 1 A GLN 1 1
ATOM 385 N NE2 . GLN 48 48 ? A -27.64343 -12.60845 -13.62505 1 1 A GLN 1 1
ATOM 386 N N . LYS 49 49 ? A -22.72609 -13.89320 -9.24532 1 1 A LYS 1 1
ATOM 387 C CA . LYS 49 49 ? A -21.41508 -13.61776 -8.70048 1 1 A LYS 1 1
ATOM 388 C C . LYS 49 49 ? A -20.97337 -12.19502 -9.00149 1 1 A LYS 1 1
ATOM 389 O O . LYS 49 49 ? A -21.37375 -11.61778 -10.00121 1 1 A LYS 1 1
ATOM 390 C CB . LYS 49 49 ? A -20.39340 -14.58209 -9.27886 1 1 A LYS 1 1
ATOM 391 C CG . LYS 49 49 ? A -20.52694 -16.00384 -8.79744 1 1 A LYS 1 1
ATOM 392 C CD . LYS 49 49 ? A -19.37888 -16.84064 -9.33156 1 1 A LYS 1 1
ATOM 393 C CE . LYS 49 49 ? A -19.28124 -18.17510 -8.63567 1 1 A LYS 1 1
ATOM 394 N NZ . LYS 49 49 ? A -18.07887 -18.93674 -9.07304 1 1 A LYS 1 1
ATOM 395 N N . VAL 50 50 ? A -20.12087 -11.66382 -8.12331 1 1 A VAL 1 1
ATOM 396 C CA . VAL 50 50 ? A -19.56553 -10.33218 -8.26691 1 1 A VAL 1 1
ATOM 397 C C . VAL 50 50 ? A -18.06927 -10.43073 -8.51025 1 1 A VAL 1 1
ATOM 398 O O . VAL 50 50 ? A -17.40207 -11.30197 -7.93955 1 1 A VAL 1 1
ATOM 399 C CB . VAL 50 50 ? A -19.84070 -9.49114 -7.00267 1 1 A VAL 1 1
ATOM 400 C CG1 . VAL 50 50 ? A -19.12378 -8.15653 -7.07385 1 1 A VAL 1 1
ATOM 401 C CG2 . VAL 50 50 ? A -21.33179 -9.26861 -6.84126 1 1 A VAL 1 1
ATOM 402 N N . THR 51 51 ? A -17.48921 -9.53540 -9.30647 1 1 A THR 1 1
ATOM 403 C CA . THR 51 51 ? A -16.08856 -9.61586 -9.65734 1 1 A THR 1 1
ATOM 404 C C . THR 51 51 ? A -15.21400 -8.47725 -9.15076 1 1 A THR 1 1
ATOM 405 O O . THR 51 51 ? A -14.01049 -8.65158 -8.97565 1 1 A THR 1 1
ATOM 406 C CB . THR 51 51 ? A -15.90593 -9.73086 -11.16938 1 1 A THR 1 1
ATOM 407 O OG1 . THR 51 51 ? A -16.52293 -8.60795 -11.79617 1 1 A THR 1 1
ATOM 408 C CG2 . THR 51 51 ? A -16.53934 -11.01032 -11.67705 1 1 A THR 1 1
ATOM 409 N N . ASN 52 52 ? A -15.77835 -7.27452 -8.93070 1 1 A ASN 1 1
ATOM 410 C CA . ASN 52 52 ? A -14.94709 -6.15784 -8.50408 1 1 A ASN 1 1
ATOM 411 C C . ASN 52 52 ? A -15.69874 -5.17666 -7.61585 1 1 A ASN 1 1
ATOM 412 O O . ASN 52 52 ? A -16.91393 -5.05385 -7.68959 1 1 A ASN 1 1
ATOM 413 C CB . ASN 52 52 ? A -14.37344 -5.43624 -9.70885 1 1 A ASN 1 1
ATOM 414 C CG . ASN 52 52 ? A -15.42752 -4.83658 -10.59755 1 1 A ASN 1 1
ATOM 415 O OD1 . ASN 52 52 ? A -16.60141 -5.18297 -10.56401 1 1 A ASN 1 1
ATOM 416 N ND2 . ASN 52 52 ? A -15.02899 -3.89520 -11.43279 1 1 A ASN 1 1
ATOM 417 N N . VAL 53 53 ? A -14.92786 -4.45244 -6.76628 1 1 A VAL 1 1
ATOM 418 C CA . VAL 53 53 ? A -15.46590 -3.50166 -5.81925 1 1 A VAL 1 1
ATOM 419 C C . VAL 53 53 ? A -15.59063 -2.10140 -6.40552 1 1 A VAL 1 1
ATOM 420 O O . VAL 53 53 ? A -16.43690 -1.32549 -5.99458 1 1 A VAL 1 1
ATOM 421 C CB . VAL 53 53 ? A -14.57708 -3.45896 -4.55149 1 1 A VAL 1 1
ATOM 422 C CG1 . VAL 53 53 ? A -15.03297 -2.37605 -3.59152 1 1 A VAL 1 1
ATOM 423 C CG2 . VAL 53 53 ? A -14.58186 -4.80417 -3.85516 1 1 A VAL 1 1
ATOM 424 N N . GLU 54 54 ? A -14.73558 -1.74328 -7.34208 1 1 A GLU 1 1
ATOM 425 C CA . GLU 54 54 ? A -14.71000 -0.41816 -7.93010 1 1 A GLU 1 1
ATOM 426 C C . GLU 54 54 ? A -16.05851 0.00509 -8.47475 1 1 A GLU 1 1
ATOM 427 O O . GLU 54 54 ? A -16.46117 1.16536 -8.36932 1 1 A GLU 1 1
ATOM 428 C CB . GLU 54 54 ? A -13.65906 -0.34905 -9.02932 1 1 A GLU 1 1
ATOM 429 C CG . GLU 54 54 ? A -13.47016 1.04608 -9.61773 1 1 A GLU 1 1
ATOM 430 C CD . GLU 54 54 ? A -12.34501 1.09441 -10.63191 1 1 A GLU 1 1
ATOM 431 O OE1 . GLU 54 54 ? A -12.02505 0.05631 -11.21486 1 1 A GLU 1 1
ATOM 432 O OE2 . GLU 54 54 ? A -11.79280 2.17701 -10.85281 1 1 A GLU 1 1
ATOM 433 N N . THR 55 55 ? A -16.77249 -0.90838 -9.06370 1 1 A THR 1 1
ATOM 434 C CA . THR 55 55 ? A -18.09274 -0.62420 -9.59418 1 1 A THR 1 1
ATOM 435 C C . THR 55 55 ? A -19.01531 -0.04649 -8.53058 1 1 A THR 1 1
ATOM 436 O O . THR 55 55 ? A -19.82703 0.82638 -8.81046 1 1 A THR 1 1
ATOM 437 C CB . THR 55 55 ? A -18.71691 -1.88123 -10.19759 1 1 A THR 1 1
ATOM 438 O OG1 . THR 55 55 ? A -17.86959 -2.36897 -11.23956 1 1 A THR 1 1
ATOM 439 C CG2 . THR 55 55 ? A -20.08647 -1.59613 -10.77453 1 1 A THR 1 1
ATOM 440 N N . SER 56 56 ? A -18.89349 -0.48828 -7.35015 1 1 A SER 1 1
ATOM 441 C CA . SER 56 56 ? A -19.71990 -0.06399 -6.24596 1 1 A SER 1 1
ATOM 442 C C . SER 56 56 ? A -19.64789 1.43382 -5.98479 1 1 A SER 1 1
ATOM 443 O O . SER 56 56 ? A -20.65494 2.07273 -5.70775 1 1 A SER 1 1
ATOM 444 C CB . SER 56 56 ? A -19.30635 -0.82057 -4.99237 1 1 A SER 1 1
ATOM 445 O OG . SER 56 56 ? A -20.07038 -0.42548 -3.87125 1 1 A SER 1 1
ATOM 446 N N . ILE 57 57 ? A -18.46400 2.03247 -6.07783 1 1 A ILE 1 1
ATOM 447 C CA . ILE 57 57 ? A -18.27603 3.44749 -5.76369 1 1 A ILE 1 1
ATOM 448 C C . ILE 57 57 ? A -18.54944 4.36238 -6.94845 1 1 A ILE 1 1
ATOM 449 O O . ILE 57 57 ? A -18.80243 5.55215 -6.76794 1 1 A ILE 1 1
ATOM 450 C CB . ILE 57 57 ? A -16.84611 3.68137 -5.22996 1 1 A ILE 1 1
ATOM 451 C CG1 . ILE 57 57 ? A -16.83072 4.90505 -4.34560 1 1 A ILE 1 1
ATOM 452 C CG2 . ILE 57 57 ? A -15.84534 3.83408 -6.35529 1 1 A ILE 1 1
ATOM 453 C CD1 . ILE 57 57 ? A -15.52929 5.16169 -3.65735 1 1 A ILE 1 1
ATOM 454 N N . SER 58 58 ? A -18.54140 3.83251 -8.15557 1 1 A SER 1 1
ATOM 455 C CA . SER 58 58 ? A -18.69522 4.66208 -9.34705 1 1 A SER 1 1
ATOM 456 C C . SER 58 58 ? A -20.06522 5.29362 -9.48531 1 1 A SER 1 1
ATOM 457 O O . SER 58 58 ? A -20.22330 6.26937 -10.22398 1 1 A SER 1 1
ATOM 458 C CB . SER 58 58 ? A -18.34196 3.87427 -10.60017 1 1 A SER 1 1
ATOM 459 O OG . SER 58 58 ? A -19.22949 2.80632 -10.80195 1 1 A SER 1 1
ATOM 460 N N . ASP 59 59 ? A -21.06807 4.80172 -8.76991 1 1 A ASP 1 1
ATOM 461 C CA . ASP 59 59 ? A -22.38369 5.41628 -8.77610 1 1 A ASP 1 1
ATOM 462 C C . ASP 59 59 ? A -22.38581 6.81039 -8.18184 1 1 A ASP 1 1
ATOM 463 O O . ASP 59 59 ? A -23.31777 7.58197 -8.41497 1 1 A ASP 1 1
ATOM 464 C CB . ASP 59 59 ? A -23.38083 4.54671 -8.01478 1 1 A ASP 1 1
ATOM 465 C CG . ASP 59 59 ? A -23.70722 3.24905 -8.72826 1 1 A ASP 1 1
ATOM 466 O OD1 . ASP 59 59 ? A -23.45739 3.15153 -9.93137 1 1 A ASP 1 1
ATOM 467 O OD2 . ASP 59 59 ? A -24.21893 2.32587 -8.06616 1 1 A ASP 1 1
ATOM 468 N N . TYR 60 60 ? A -21.37099 7.14390 -7.41101 1 1 A TYR 1 1
ATOM 469 C CA . TYR 60 60 ? A -21.25288 8.43595 -6.75659 1 1 A TYR 1 1
ATOM 470 C C . TYR 60 60 ? A -20.46950 9.45788 -7.55411 1 1 A TYR 1 1
ATOM 471 O O . TYR 60 60 ? A -20.40443 10.62420 -7.16380 1 1 A TYR 1 1
ATOM 472 C CB . TYR 60 60 ? A -20.62139 8.26390 -5.38186 1 1 A TYR 1 1
ATOM 473 C CG . TYR 60 60 ? A -21.43834 7.41261 -4.45915 1 1 A TYR 1 1
ATOM 474 C CD1 . TYR 60 60 ? A -22.41370 7.98221 -3.66430 1 1 A TYR 1 1
ATOM 475 C CD2 . TYR 60 60 ? A -21.26545 6.04412 -4.41148 1 1 A TYR 1 1
ATOM 476 C CE1 . TYR 60 60 ? A -23.19311 7.20117 -2.82260 1 1 A TYR 1 1
ATOM 477 C CE2 . TYR 60 60 ? A -22.03499 5.24688 -3.57891 1 1 A TYR 1 1
ATOM 478 C CZ . TYR 60 60 ? A -23.00241 5.83856 -2.78606 1 1 A TYR 1 1
ATOM 479 O OH . TYR 60 60 ? A -23.77035 5.06474 -1.96469 1 1 A TYR 1 1
ATOM 480 N N . TYR 61 61 ? A -19.86247 9.05626 -8.66055 1 1 A TYR 1 1
ATOM 481 C CA . TYR 61 61 ? A -19.02470 9.92553 -9.46509 1 1 A TYR 1 1
ATOM 482 C C . TYR 61 61 ? A -19.44801 9.90868 -10.91788 1 1 A TYR 1 1
ATOM 483 O O . TYR 61 61 ? A -19.73527 8.85382 -11.47700 1 1 A TYR 1 1
ATOM 484 C CB . TYR 61 61 ? A -17.56343 9.50722 -9.34916 1 1 A TYR 1 1
ATOM 485 C CG . TYR 61 61 ? A -17.01173 9.64701 -7.95629 1 1 A TYR 1 1
ATOM 486 C CD1 . TYR 61 61 ? A -16.40636 10.82228 -7.55656 1 1 A TYR 1 1
ATOM 487 C CD2 . TYR 61 61 ? A -17.12419 8.62396 -7.03253 1 1 A TYR 1 1
ATOM 488 C CE1 . TYR 61 61 ? A -15.92263 10.98265 -6.26581 1 1 A TYR 1 1
ATOM 489 C CE2 . TYR 61 61 ? A -16.64600 8.76678 -5.74154 1 1 A TYR 1 1
ATOM 490 C CZ . TYR 61 61 ? A -16.04799 9.94930 -5.36646 1 1 A TYR 1 1
ATOM 491 O OH . TYR 61 61 ? A -15.56854 10.10638 -4.08860 1 1 A TYR 1 1
ATOM 492 N N . ASP 62 62 ? A -19.47368 11.06867 -11.52852 1 1 A ASP 1 1
ATOM 493 C CA . ASP 62 62 ? A -19.83683 11.23061 -12.93018 1 1 A ASP 1 1
ATOM 494 C C . ASP 62 62 ? A -18.63901 10.95145 -13.82559 1 1 A ASP 1 1
ATOM 495 O O . ASP 62 62 ? A -17.49510 10.84623 -13.35763 1 1 A ASP 1 1
ATOM 496 C CB . ASP 62 62 ? A -20.33657 12.64887 -13.17702 1 1 A ASP 1 1
ATOM 497 C CG . ASP 62 62 ? A -21.44286 12.71358 -14.20537 1 1 A ASP 1 1
ATOM 498 O OD1 . ASP 62 62 ? A -21.68635 11.71239 -14.89452 1 1 A ASP 1 1
ATOM 499 O OD2 . ASP 62 62 ? A -22.06636 13.78481 -14.32085 1 1 A ASP 1 1
ATOM 500 N N . GLU 63 63 ? A -18.83625 10.86904 -15.11475 1 1 A GLU 1 1
ATOM 501 C CA . GLU 63 63 ? A -17.80009 10.67403 -16.09521 1 1 A GLU 1 1
ATOM 502 C C . GLU 63 63 ? A -16.75058 11.77709 -15.96972 1 1 A GLU 1 1
ATOM 503 O O . GLU 63 63 ? A -17.09192 12.94513 -15.77198 1 1 A GLU 1 1
ATOM 504 C CB . GLU 63 63 ? A -18.40285 10.69567 -17.49951 1 1 A GLU 1 1
ATOM 505 C CG . GLU 63 63 ? A -17.39898 10.47763 -18.62224 1 1 A GLU 1 1
ATOM 506 C CD . GLU 63 63 ? A -18.03407 10.63262 -19.99275 1 1 A GLU 1 1
ATOM 507 O OE1 . GLU 63 63 ? A -19.25990 10.53412 -20.10116 1 1 A GLU 1 1
ATOM 508 O OE2 . GLU 63 63 ? A -17.29104 10.86022 -20.96208 1 1 A GLU 1 1
ATOM 509 N N . VAL 64 64 ? A -15.48329 11.42825 -16.11255 1 1 A VAL 1 1
ATOM 510 C CA . VAL 64 64 ? A -14.39861 12.38193 -15.99178 1 1 A VAL 1 1
ATOM 511 C C . VAL 64 64 ? A -14.42196 13.36346 -17.15168 1 1 A VAL 1 1
ATOM 512 O O . VAL 64 64 ? A -14.51151 12.95845 -18.30450 1 1 A VAL 1 1
ATOM 513 C CB . VAL 64 64 ? A -13.03289 11.65500 -15.94935 1 1 A VAL 1 1
ATOM 514 C CG1 . VAL 64 64 ? A -11.89274 12.65771 -15.90795 1 1 A VAL 1 1
ATOM 515 C CG2 . VAL 64 64 ? A -12.96653 10.72543 -14.74625 1 1 A VAL 1 1
ATOM 516 N N . LYS 65 65 ? A -14.34262 14.64939 -16.82571 1 1 A LYS 1 1
ATOM 517 C CA . LYS 65 65 ? A -14.28809 15.68361 -17.83977 1 1 A LYS 1 1
ATOM 518 C C . LYS 65 65 ? A -12.84159 16.01534 -18.14439 1 1 A LYS 1 1
ATOM 519 O O . LYS 65 65 ? A -12.09415 16.39008 -17.25334 1 1 A LYS 1 1
ATOM 520 C CB . LYS 65 65 ? A -15.02610 16.91835 -17.38132 1 1 A LYS 1 1
ATOM 521 C CG . LYS 65 65 ? A -16.53316 16.69357 -17.21968 1 1 A LYS 1 1
ATOM 522 C CD . LYS 65 65 ? A -17.25223 17.93496 -16.77307 1 1 A LYS 1 1
ATOM 523 C CE . LYS 65 65 ? A -18.74455 17.80452 -16.82347 1 1 A LYS 1 1
ATOM 524 N NZ . LYS 65 65 ? A -19.24229 16.71323 -15.99015 1 1 A LYS 1 1
ATOM 525 N N . ASN 66 66 ? A -12.45088 15.84922 -19.41061 1 1 A ASN 1 1
ATOM 526 C CA . ASN 66 66 ? A -11.07023 16.09115 -19.79933 1 1 A ASN 1 1
ATOM 527 C C . ASN 66 66 ? A -10.91169 17.24209 -20.78292 1 1 A ASN 1 1
ATOM 528 O O . ASN 66 66 ? A -9.82473 17.47092 -21.29976 1 1 A ASN 1 1
ATOM 529 C CB . ASN 66 66 ? A -10.44934 14.81071 -20.35571 1 1 A ASN 1 1
ATOM 530 C CG . ASN 66 66 ? A -11.26604 14.20606 -21.46815 1 1 A ASN 1 1
ATOM 531 O OD1 . ASN 66 66 ? A -11.83461 14.90376 -22.28466 1 1 A ASN 1 1
ATOM 532 N ND2 . ASN 66 66 ? A -11.33747 12.87779 -21.49721 1 1 A ASN 1 1
ATOM 533 N N . LYS 67 67 ? A -12.01354 17.97015 -21.02732 1 1 A LYS 1 1
ATOM 534 C CA . LYS 67 67 ? A -12.01383 19.15169 -21.87824 1 1 A LYS 1 1
ATOM 535 C C . LYS 67 67 ? A -12.78850 20.26477 -21.19309 1 1 A LYS 1 1
ATOM 536 O O . LYS 67 67 ? A -13.64801 19.98234 -20.34928 1 1 A LYS 1 1
ATOM 537 C CB . LYS 67 67 ? A -12.66588 18.85146 -23.21798 1 1 A LYS 1 1
ATOM 538 C CG . LYS 67 67 ? A -11.88081 17.92479 -24.09989 1 1 A LYS 1 1
ATOM 539 C CD . LYS 67 67 ? A -12.59194 17.76717 -25.43906 1 1 A LYS 1 1
ATOM 540 C CE . LYS 67 67 ? A -11.79954 16.90518 -26.41304 1 1 A LYS 1 1
ATOM 541 N NZ . LYS 67 67 ? A -12.49518 16.77028 -27.71557 1 1 A LYS 1 1
ATOM 542 N N . PRO 68 68 ? A -12.54751 21.52467 -21.51151 1 1 A PRO 1 1
ATOM 543 C CA . PRO 68 68 ? A -13.29226 22.62713 -20.91077 1 1 A PRO 1 1
ATOM 544 C C . PRO 68 68 ? A -14.80129 22.46563 -21.05899 1 1 A PRO 1 1
ATOM 545 O O . PRO 68 68 ? A -15.27182 21.90527 -22.04420 1 1 A PRO 1 1
ATOM 546 C CB . PRO 68 68 ? A -12.78992 23.84931 -21.67128 1 1 A PRO 1 1
ATOM 547 C CG . PRO 68 68 ? A -11.39923 23.45878 -22.05877 1 1 A PRO 1 1
ATOM 548 C CD . PRO 68 68 ? A -11.50242 22.00640 -22.40220 1 1 A PRO 1 1
ATOM 549 N N . PHE 69 69 ? A -15.56324 22.97671 -20.08530 1 1 A PHE 1 1
ATOM 550 C CA . PHE 69 69 ? A -17.00960 22.86124 -20.04578 1 1 A PHE 1 1
ATOM 551 C C . PHE 69 69 ? A -17.59668 24.00996 -19.23688 1 1 A PHE 1 1
ATOM 552 O O . PHE 69 69 ? A -16.87026 24.74784 -18.57219 1 1 A PHE 1 1
ATOM 553 C CB . PHE 69 69 ? A -17.42009 21.51709 -19.44001 1 1 A PHE 1 1
ATOM 554 C CG . PHE 69 69 ? A -16.93734 21.32873 -18.03853 1 1 A PHE 1 1
ATOM 555 C CD1 . PHE 69 69 ? A -15.65420 20.87043 -17.78662 1 1 A PHE 1 1
ATOM 556 C CD2 . PHE 69 69 ? A -17.76357 21.62811 -16.96385 1 1 A PHE 1 1
ATOM 557 C CE1 . PHE 69 69 ? A -15.18151 20.71488 -16.49372 1 1 A PHE 1 1
ATOM 558 C CE2 . PHE 69 69 ? A -17.29181 21.47323 -15.65834 1 1 A PHE 1 1
ATOM 559 C CZ . PHE 69 69 ? A -16.00480 21.01348 -15.43337 1 1 A PHE 1 1
ATOM 560 N N . ASN 70 70 ? A -18.90591 24.14380 -19.25638 1 1 A ASN 1 1
ATOM 561 C CA . ASN 70 70 ? A -19.58736 25.19954 -18.52850 1 1 A ASN 1 1
ATOM 562 C C . ASN 70 70 ? A -20.16241 24.68004 -17.21141 1 1 A ASN 1 1
ATOM 563 O O . ASN 70 70 ? A -20.70912 23.58350 -17.17179 1 1 A ASN 1 1
ATOM 564 C CB . ASN 70 70 ? A -20.69921 25.81737 -19.37057 1 1 A ASN 1 1
ATOM 565 C CG . ASN 70 70 ? A -20.16226 26.66787 -20.49583 1 1 A ASN 1 1
ATOM 566 O OD1 . ASN 70 70 ? A -19.38266 27.58640 -20.25667 1 1 A ASN 1 1
ATOM 567 N ND2 . ASN 70 70 ? A -20.56425 26.37235 -21.71805 1 1 A ASN 1 1
ATOM 568 N N . ILE 71 71 ? A -20.03298 25.49403 -16.16168 1 1 A ILE 1 1
ATOM 569 C CA . ILE 71 71 ? A -20.63367 25.21540 -14.87645 1 1 A ILE 1 1
ATOM 570 C C . ILE 71 71 ? A -21.82922 26.13050 -14.67147 1 1 A ILE 1 1
ATOM 571 O O . ILE 71 71 ? A -21.72900 27.34177 -14.87641 1 1 A ILE 1 1
ATOM 572 C CB . ILE 71 71 ? A -19.64237 25.41722 -13.72594 1 1 A ILE 1 1
ATOM 573 C CG1 . ILE 71 71 ? A -18.48079 24.43273 -13.83592 1 1 A ILE 1 1
ATOM 574 C CG2 . ILE 71 71 ? A -20.34479 25.23933 -12.37944 1 1 A ILE 1 1
ATOM 575 C CD1 . ILE 71 71 ? A -17.45581 24.58160 -12.73697 1 1 A ILE 1 1
ATOM 576 N N . ASP 72 72 ? A -22.96054 25.57393 -14.26879 1 1 A ASP 1 1
ATOM 577 C CA . ASP 72 72 ? A -24.18604 26.32087 -14.01491 1 1 A ASP 1 1
ATOM 578 C C . ASP 72 72 ? A -24.08564 27.10001 -12.71173 1 1 A ASP 1 1
ATOM 579 O O . ASP 72 72 ? A -23.28118 26.75529 -11.83107 1 1 A ASP 1 1
ATOM 580 C CB . ASP 72 72 ? A -25.38784 25.36547 -13.93974 1 1 A ASP 1 1
ATOM 581 C CG . ASP 72 72 ? A -25.67384 24.65886 -15.24956 1 1 A ASP 1 1
ATOM 582 O OD1 . ASP 72 72 ? A -25.43148 25.25267 -16.30734 1 1 A ASP 1 1
ATOM 583 O OD2 . ASP 72 72 ? A -26.13221 23.50499 -15.20587 1 1 A ASP 1 1
ATOM 584 N N . PRO 73 73 ? A -24.90283 28.13638 -12.56852 1 1 A PRO 1 1
ATOM 585 C CA . PRO 73 73 ? A -24.92825 28.88405 -11.30432 1 1 A PRO 1 1
ATOM 586 C C . PRO 73 73 ? A -25.29640 27.98894 -10.12047 1 1 A PRO 1 1
ATOM 587 O O . PRO 73 73 ? A -26.06298 27.03967 -10.27525 1 1 A PRO 1 1
ATOM 588 C CB . PRO 73 73 ? A -26.01452 29.93525 -11.53631 1 1 A PRO 1 1
ATOM 589 C CG . PRO 73 73 ? A -26.03577 30.10860 -13.02431 1 1 A PRO 1 1
ATOM 590 C CD . PRO 73 73 ? A -25.79551 28.73737 -13.57101 1 1 A PRO 1 1
ATOM 591 N N . GLY 74 74 ? A -24.74809 28.28524 -8.96884 1 1 A GLY 1 1
ATOM 592 C CA . GLY 74 74 ? A -25.03517 27.50238 -7.78456 1 1 A GLY 1 1
ATOM 593 C C . GLY 74 74 ? A -23.90542 27.57427 -6.77883 1 1 A GLY 1 1
ATOM 594 O O . GLY 74 74 ? A -22.87676 28.23125 -7.02606 1 1 A GLY 1 1
ATOM 595 N N . TYR 75 75 ? A -24.08012 26.92788 -5.68162 1 1 A TYR 1 1
ATOM 596 C CA . TYR 75 75 ? A -23.06895 26.83739 -4.64977 1 1 A TYR 1 1
ATOM 597 C C . TYR 75 75 ? A -22.33027 25.51074 -4.79705 1 1 A TYR 1 1
ATOM 598 O O . TYR 75 75 ? A -22.96198 24.45064 -4.85602 1 1 A TYR 1 1
ATOM 599 C CB . TYR 75 75 ? A -23.68910 26.92882 -3.25276 1 1 A TYR 1 1
ATOM 600 C CG . TYR 75 75 ? A -24.25342 28.29462 -2.93465 1 1 A TYR 1 1
ATOM 601 C CD1 . TYR 75 75 ? A -25.53694 28.64189 -3.32940 1 1 A TYR 1 1
ATOM 602 C CD2 . TYR 75 75 ? A -23.48160 29.25105 -2.23627 1 1 A TYR 1 1
ATOM 603 C CE1 . TYR 75 75 ? A -26.06050 29.88988 -3.06008 1 1 A TYR 1 1
ATOM 604 C CE2 . TYR 75 75 ? A -23.99912 30.49704 -1.95287 1 1 A TYR 1 1
ATOM 605 C CZ . TYR 75 75 ? A -25.28531 30.80624 -2.37793 1 1 A TYR 1 1
ATOM 606 O OH . TYR 75 75 ? A -25.79742 32.04744 -2.09787 1 1 A TYR 1 1
ATOM 607 N N . TYR 76 76 ? A -21.02007 25.58054 -4.85963 1 1 A TYR 1 1
ATOM 608 C CA . TYR 76 76 ? A -20.20655 24.40092 -5.11175 1 1 A TYR 1 1
ATOM 609 C C . TYR 76 76 ? A -19.12875 24.20903 -4.06933 1 1 A TYR 1 1
ATOM 610 O O . TYR 76 76 ? A -18.64379 25.17881 -3.47366 1 1 A TYR 1 1
ATOM 611 C CB . TYR 76 76 ? A -19.54522 24.49537 -6.49065 1 1 A TYR 1 1
ATOM 612 C CG . TYR 76 76 ? A -20.48554 24.39007 -7.65745 1 1 A TYR 1 1
ATOM 613 C CD1 . TYR 76 76 ? A -21.18007 25.50878 -8.10497 1 1 A TYR 1 1
ATOM 614 C CD2 . TYR 76 76 ? A -20.68541 23.18109 -8.30822 1 1 A TYR 1 1
ATOM 615 C CE1 . TYR 76 76 ? A -22.04966 25.42773 -9.17806 1 1 A TYR 1 1
ATOM 616 C CE2 . TYR 76 76 ? A -21.54595 23.08796 -9.38338 1 1 A TYR 1 1
ATOM 617 C CZ . TYR 76 76 ? A -22.23066 24.21531 -9.82037 1 1 A TYR 1 1
ATOM 618 O OH . TYR 76 76 ? A -23.08221 24.12977 -10.89288 1 1 A TYR 1 1
ATOM 619 N N . ILE 77 77 ? A -18.74930 22.95595 -3.88144 1 1 A ILE 1 1
ATOM 620 C CA . ILE 77 77 ? A -17.58311 22.57627 -3.09722 1 1 A ILE 1 1
ATOM 621 C C . ILE 77 77 ? A -16.58343 21.92396 -4.04621 1 1 A ILE 1 1
ATOM 622 O O . ILE 77 77 ? A -16.96059 21.02848 -4.80429 1 1 A ILE 1 1
ATOM 623 C CB . ILE 77 77 ? A -17.96997 21.59554 -1.97643 1 1 A ILE 1 1
ATOM 624 C CG1 . ILE 77 77 ? A -18.98406 22.26361 -1.03339 1 1 A ILE 1 1
ATOM 625 C CG2 . ILE 77 77 ? A -16.73430 21.12157 -1.21694 1 1 A ILE 1 1
ATOM 626 C CD1 . ILE 77 77 ? A -19.66782 21.30353 -0.09476 1 1 A ILE 1 1
ATOM 627 N N . PHE 78 78 ? A -15.34455 22.37091 -4.01471 1 1 A PHE 1 1
ATOM 628 C CA . PHE 78 78 ? A -14.29159 21.81878 -4.85543 1 1 A PHE 1 1
ATOM 629 C C . PHE 78 78 ? A -13.24439 21.13000 -4.00352 1 1 A PHE 1 1
ATOM 630 O O . PHE 78 78 ? A -12.78517 21.69834 -3.00433 1 1 A PHE 1 1
ATOM 631 C CB . PHE 78 78 ? A -13.64622 22.92441 -5.69957 1 1 A PHE 1 1
ATOM 632 C CG . PHE 78 78 ? A -14.56867 23.51271 -6.72349 1 1 A PHE 1 1
ATOM 633 C CD1 . PHE 78 78 ? A -15.44001 24.52956 -6.37030 1 1 A PHE 1 1
ATOM 634 C CD2 . PHE 78 78 ? A -14.58700 23.02831 -8.02476 1 1 A PHE 1 1
ATOM 635 C CE1 . PHE 78 78 ? A -16.31732 25.06420 -7.30074 1 1 A PHE 1 1
ATOM 636 C CE2 . PHE 78 78 ? A -15.45642 23.56243 -8.95718 1 1 A PHE 1 1
ATOM 637 C CZ . PHE 78 78 ? A -16.32271 24.58797 -8.58824 1 1 A PHE 1 1
ATOM 638 N N . LEU 79 79 ? A -12.86906 19.91089 -4.36826 1 1 A LEU 1 1
ATOM 639 C CA . LEU 79 79 ? A -11.93039 19.08163 -3.62997 1 1 A LEU 1 1
ATOM 640 C C . LEU 79 79 ? A -10.78525 18.63326 -4.52537 1 1 A LEU 1 1
ATOM 641 O O . LEU 79 79 ? A -11.01235 18.26830 -5.68428 1 1 A LEU 1 1
ATOM 642 C CB . LEU 79 79 ? A -12.63496 17.82973 -3.08943 1 1 A LEU 1 1
ATOM 643 C CG . LEU 79 79 ? A -13.86106 18.03873 -2.23441 1 1 A LEU 1 1
ATOM 644 C CD1 . LEU 79 79 ? A -14.56322 16.72032 -1.98527 1 1 A LEU 1 1
ATOM 645 C CD2 . LEU 79 79 ? A -13.48201 18.70051 -0.92734 1 1 A LEU 1 1
ATOM 646 N N . PRO 80 80 ? A -9.56330 18.59542 -3.97571 1 1 A PRO 1 1
ATOM 647 C CA . PRO 80 80 ? A -8.47466 18.00789 -4.74005 1 1 A PRO 1 1
ATOM 648 C C . PRO 80 80 ? A -8.58327 16.48476 -4.74675 1 1 A PRO 1 1
ATOM 649 O O . PRO 80 80 ? A -9.06151 15.89343 -3.77347 1 1 A PRO 1 1
ATOM 650 C CB . PRO 80 80 ? A -7.22763 18.46048 -3.97766 1 1 A PRO 1 1
ATOM 651 C CG . PRO 80 80 ? A -7.70360 18.55188 -2.55617 1 1 A PRO 1 1
ATOM 652 C CD . PRO 80 80 ? A -9.13831 19.04156 -2.64925 1 1 A PRO 1 1
ATOM 653 N N . VAL 81 81 ? A -8.12483 15.87271 -5.81329 1 1 A VAL 1 1
ATOM 654 C CA . VAL 81 81 ? A -7.97727 14.43844 -5.87386 1 1 A VAL 1 1
ATOM 655 C C . VAL 81 81 ? A -6.48643 14.14570 -5.95382 1 1 A VAL 1 1
ATOM 656 O O . VAL 81 81 ? A -5.82977 14.54745 -6.91612 1 1 A VAL 1 1
ATOM 657 C CB . VAL 81 81 ? A -8.71774 13.82828 -7.07960 1 1 A VAL 1 1
ATOM 658 C CG1 . VAL 81 81 ? A -8.48176 12.33237 -7.14174 1 1 A VAL 1 1
ATOM 659 C CG2 . VAL 81 81 ? A -10.20023 14.14538 -6.99059 1 1 A VAL 1 1
ATOM 660 N N . TYR 82 82 ? A -5.97119 13.47978 -4.95495 1 1 A TYR 1 1
ATOM 661 C CA . TYR 82 82 ? A -4.53022 13.30531 -4.82046 1 1 A TYR 1 1
ATOM 662 C C . TYR 82 82 ? A -4.00559 12.05678 -5.50009 1 1 A TYR 1 1
ATOM 663 O O . TYR 82 82 ? A -4.74062 11.08967 -5.71303 1 1 A TYR 1 1
ATOM 664 C CB . TYR 82 82 ? A -4.13999 13.25295 -3.33213 1 1 A TYR 1 1
ATOM 665 C CG . TYR 82 82 ? A -4.42445 14.51844 -2.58336 1 1 A TYR 1 1
ATOM 666 C CD1 . TYR 82 82 ? A -3.75051 15.69003 -2.86673 1 1 A TYR 1 1
ATOM 667 C CD2 . TYR 82 82 ? A -5.37580 14.54035 -1.56087 1 1 A TYR 1 1
ATOM 668 C CE1 . TYR 82 82 ? A -4.00657 16.85787 -2.17304 1 1 A TYR 1 1
ATOM 669 C CE2 . TYR 82 82 ? A -5.63898 15.70602 -0.86086 1 1 A TYR 1 1
ATOM 670 C CZ . TYR 82 82 ? A -4.94394 16.85566 -1.17310 1 1 A TYR 1 1
ATOM 671 O OH . TYR 82 82 ? A -5.19616 18.00624 -0.47726 1 1 A TYR 1 1
ATOM 672 N N . PHE 83 83 ? A -2.75219 12.06909 -5.82053 1 1 A PHE 1 1
ATOM 673 C CA . PHE 83 83 ? A -2.02148 10.91740 -6.30351 1 1 A PHE 1 1
ATOM 674 C C . PHE 83 83 ? A -1.74132 9.99821 -5.11769 1 1 A PHE 1 1
ATOM 675 O O . PHE 83 83 ? A -1.31193 10.45476 -4.06069 1 1 A PHE 1 1
ATOM 676 C CB . PHE 83 83 ? A -0.71230 11.37049 -6.95202 1 1 A PHE 1 1
ATOM 677 C CG . PHE 83 83 ? A 0.15468 10.23374 -7.41442 1 1 A PHE 1 1
ATOM 678 C CD1 . PHE 83 83 ? A -0.15211 9.52494 -8.54977 1 1 A PHE 1 1
ATOM 679 C CD2 . PHE 83 83 ? A 1.26390 9.86218 -6.67990 1 1 A PHE 1 1
ATOM 680 C CE1 . PHE 83 83 ? A 0.63881 8.48003 -8.96244 1 1 A PHE 1 1
ATOM 681 C CE2 . PHE 83 83 ? A 2.06004 8.81395 -7.08412 1 1 A PHE 1 1
ATOM 682 C CZ . PHE 83 83 ? A 1.74916 8.12462 -8.22966 1 1 A PHE 1 1
ATOM 683 N N . GLY 84 84 ? A -1.95476 8.71068 -5.28959 1 1 A GLY 1 1
ATOM 684 C CA . GLY 84 84 ? A -1.69534 7.73004 -4.25219 1 1 A GLY 1 1
ATOM 685 C C . GLY 84 84 ? A -2.67151 6.57758 -4.33182 1 1 A GLY 1 1
ATOM 686 O O . GLY 84 84 ? A -3.53386 6.53642 -5.21998 1 1 A GLY 1 1
ATOM 687 N N . SER 85 85 ? A -2.54091 5.64667 -3.39728 1 1 A SER 1 1
ATOM 688 C CA . SER 85 85 ? A -3.44562 4.50942 -3.33622 1 1 A SER 1 1
ATOM 689 C C . SER 85 85 ? A -4.75405 4.95206 -2.70021 1 1 A SER 1 1
ATOM 690 O O . SER 85 85 ? A -4.74268 5.70456 -1.71843 1 1 A SER 1 1
ATOM 691 C CB . SER 85 85 ? A -2.84565 3.38026 -2.51849 1 1 A SER 1 1
ATOM 692 O OG . SER 85 85 ? A -1.62920 2.94455 -3.10201 1 1 A SER 1 1
ATOM 693 N N . VAL 86 86 ? A -5.86529 4.49951 -3.20919 1 1 A VAL 1 1
ATOM 694 C CA . VAL 86 86 ? A -7.17607 4.94835 -2.79043 1 1 A VAL 1 1
ATOM 695 C C . VAL 86 86 ? A -7.88575 3.86312 -1.98993 1 1 A VAL 1 1
ATOM 696 O O . VAL 86 86 ? A -7.90366 2.70027 -2.39372 1 1 A VAL 1 1
ATOM 697 C CB . VAL 86 86 ? A -8.02889 5.35724 -4.00003 1 1 A VAL 1 1
ATOM 698 C CG1 . VAL 86 86 ? A -9.39606 5.85318 -3.54170 1 1 A VAL 1 1
ATOM 699 C CG2 . VAL 86 86 ? A -7.32089 6.41808 -4.80372 1 1 A VAL 1 1
ATOM 700 N N . PHE 87 87 ? A -8.50242 4.25250 -0.87216 1 1 A PHE 1 1
ATOM 701 C CA . PHE 87 87 ? A -9.19961 3.35153 0.02798 1 1 A PHE 1 1
ATOM 702 C C . PHE 87 87 ? A -10.55937 3.89843 0.41765 1 1 A PHE 1 1
ATOM 703 O O . PHE 87 87 ? A -10.77975 5.10721 0.38175 1 1 A PHE 1 1
ATOM 704 C CB . PHE 87 87 ? A -8.37577 3.13823 1.29983 1 1 A PHE 1 1
ATOM 705 C CG . PHE 87 87 ? A -7.05238 2.49172 1.07625 1 1 A PHE 1 1
ATOM 706 C CD1 . PHE 87 87 ? A -5.95713 3.24114 0.65578 1 1 A PHE 1 1
ATOM 707 C CD2 . PHE 87 87 ? A -6.89063 1.14082 1.27206 1 1 A PHE 1 1
ATOM 708 C CE1 . PHE 87 87 ? A -4.73392 2.64772 0.44522 1 1 A PHE 1 1
ATOM 709 C CE2 . PHE 87 87 ? A -5.65812 0.54732 1.07111 1 1 A PHE 1 1
ATOM 710 C CZ . PHE 87 87 ? A -4.58418 1.30488 0.65982 1 1 A PHE 1 1
ATOM 711 N N . ILE 88 88 ? A -11.43360 3.01724 0.84196 1 1 A ILE 1 1
ATOM 712 C CA . ILE 88 88 ? A -12.75432 3.37441 1.32359 1 1 A ILE 1 1
ATOM 713 C C . ILE 88 88 ? A -12.96249 2.78438 2.70866 1 1 A ILE 1 1
ATOM 714 O O . ILE 88 88 ? A -12.57718 1.64745 2.96879 1 1 A ILE 1 1
ATOM 715 C CB . ILE 88 88 ? A -13.83908 2.86138 0.35699 1 1 A ILE 1 1
ATOM 716 C CG1 . ILE 88 88 ? A -13.82698 3.69081 -0.90604 1 1 A ILE 1 1
ATOM 717 C CG2 . ILE 88 88 ? A -15.21088 2.87734 1.02455 1 1 A ILE 1 1
ATOM 718 C CD1 . ILE 88 88 ? A -14.73814 3.15756 -1.97142 1 1 A ILE 1 1
ATOM 719 N N . TYR 89 89 ? A -13.59509 3.57515 3.59335 1 1 A TYR 1 1
ATOM 720 C CA . TYR 89 89 ? A -13.91363 3.10597 4.91862 1 1 A TYR 1 1
ATOM 721 C C . TYR 89 89 ? A -15.37843 3.33747 5.20103 1 1 A TYR 1 1
ATOM 722 O O . TYR 89 89 ? A -15.87310 4.44566 5.03862 1 1 A TYR 1 1
ATOM 723 C CB . TYR 89 89 ? A -13.04869 3.83535 5.95672 1 1 A TYR 1 1
ATOM 724 C CG . TYR 89 89 ? A -13.12804 3.24336 7.34804 1 1 A TYR 1 1
ATOM 725 C CD1 . TYR 89 89 ? A -14.19877 3.53389 8.19206 1 1 A TYR 1 1
ATOM 726 C CD2 . TYR 89 89 ? A -12.12956 2.38172 7.80786 1 1 A TYR 1 1
ATOM 727 C CE1 . TYR 89 89 ? A -14.28606 2.97824 9.45999 1 1 A TYR 1 1
ATOM 728 C CE2 . TYR 89 89 ? A -12.20259 1.83120 9.07077 1 1 A TYR 1 1
ATOM 729 C CZ . TYR 89 89 ? A -13.27563 2.13636 9.89729 1 1 A TYR 1 1
ATOM 730 O OH . TYR 89 89 ? A -13.34708 1.58455 11.16249 1 1 A TYR 1 1
ATOM 731 N N . SER 90 90 ? A -16.12507 2.32248 5.60051 1 1 A SER 1 1
ATOM 732 C CA . SER 90 90 ? A -17.51231 2.42730 6.00316 1 1 A SER 1 1
ATOM 733 C C . SER 90 90 ? A -17.70346 1.68490 7.32162 1 1 A SER 1 1
ATOM 734 O O . SER 90 90 ? A -16.95459 0.75281 7.63487 1 1 A SER 1 1
ATOM 735 C CB . SER 90 90 ? A -18.43990 1.86430 4.92719 1 1 A SER 1 1
ATOM 736 O OG . SER 90 90 ? A -18.15335 0.50295 4.67362 1 1 A SER 1 1
ATOM 737 N N . LYS 91 91 ? A -18.69156 2.10176 8.09833 1 1 A LYS 1 1
ATOM 738 C CA . LYS 91 91 ? A -18.90587 1.53108 9.41683 1 1 A LYS 1 1
ATOM 739 C C . LYS 91 91 ? A -19.12956 0.03609 9.32704 1 1 A LYS 1 1
ATOM 740 O O . LYS 91 91 ? A -19.91956 -0.43538 8.50632 1 1 A LYS 1 1
ATOM 741 C CB . LYS 91 91 ? A -20.09361 2.19660 10.09429 1 1 A LYS 1 1
ATOM 742 C CG . LYS 91 91 ? A -20.18267 1.88375 11.57763 1 1 A LYS 1 1
ATOM 743 C CD . LYS 91 91 ? A -21.22637 2.73865 12.26537 1 1 A LYS 1 1
ATOM 744 C CE . LYS 91 91 ? A -21.27749 2.45647 13.75805 1 1 A LYS 1 1
ATOM 745 N NZ . LYS 91 91 ? A -22.30339 3.28099 14.42678 1 1 A LYS 1 1
ATOM 746 N N . GLY 92 92 ? A -18.45479 -0.68444 10.16411 1 1 A GLY 1 1
ATOM 747 C CA . GLY 92 92 ? A -18.54136 -2.12904 10.17340 1 1 A GLY 1 1
ATOM 748 C C . GLY 92 92 ? A -17.69308 -2.79401 9.11580 1 1 A GLY 1 1
ATOM 749 O O . GLY 92 92 ? A -17.66251 -4.01586 9.01930 1 1 A GLY 1 1
ATOM 750 N N . LYS 93 93 ? A -16.96229 -2.00546 8.31708 1 1 A LYS 1 1
ATOM 751 C CA . LYS 93 93 ? A -16.09274 -2.51256 7.26759 1 1 A LYS 1 1
ATOM 752 C C . LYS 93 93 ? A -14.64517 -2.19329 7.57945 1 1 A LYS 1 1
ATOM 753 O O . LYS 93 93 ? A -14.33315 -1.31592 8.38985 1 1 A LYS 1 1
ATOM 754 C CB . LYS 93 93 ? A -16.45827 -1.89879 5.92623 1 1 A LYS 1 1
ATOM 755 C CG . LYS 93 93 ? A -17.90276 -2.10673 5.50017 1 1 A LYS 1 1
ATOM 756 C CD . LYS 93 93 ? A -18.15557 -3.52413 5.06171 1 1 A LYS 1 1
ATOM 757 C CE . LYS 93 93 ? A -19.57039 -3.71821 4.49889 1 1 A LYS 1 1
ATOM 758 N NZ . LYS 93 93 ? A -19.80226 -5.14217 4.11988 1 1 A LYS 1 1
ATOM 759 N N . ASN 94 94 ? A -13.75075 -2.89556 6.89806 1 1 A ASN 1 1
ATOM 760 C CA . ASN 94 94 ? A -12.33263 -2.60157 6.98748 1 1 A ASN 1 1
ATOM 761 C C . ASN 94 94 ? A -11.98914 -1.48168 6.01937 1 1 A ASN 1 1
ATOM 762 O O . ASN 94 94 ? A -12.80802 -1.08934 5.18636 1 1 A ASN 1 1
ATOM 763 C CB . ASN 94 94 ? A -11.50878 -3.83854 6.65444 1 1 A ASN 1 1
ATOM 764 C CG . ASN 94 94 ? A -11.74343 -4.97529 7.61820 1 1 A ASN 1 1
ATOM 765 O OD1 . ASN 94 94 ? A -11.80855 -4.76226 8.82560 1 1 A ASN 1 1
ATOM 766 N ND2 . ASN 94 94 ? A -11.89657 -6.18447 7.08766 1 1 A ASN 1 1
ATOM 767 N N . MET 95 95 ? A -10.79043 -0.93288 6.12723 1 1 A MET 1 1
ATOM 768 C CA . MET 95 95 ? A -10.27392 -0.02224 5.12094 1 1 A MET 1 1
ATOM 769 C C . MET 95 95 ? A -9.99987 -0.84623 3.87755 1 1 A MET 1 1
ATOM 770 O O . MET 95 95 ? A -9.19031 -1.75944 3.91257 1 1 A MET 1 1
ATOM 771 C CB . MET 95 95 ? A -9.00668 0.67365 5.62452 1 1 A MET 1 1
ATOM 772 C CG . MET 95 95 ? A -8.49932 1.77930 4.74324 1 1 A MET 1 1
ATOM 773 S SD . MET 95 95 ? A -9.54293 3.24787 4.80542 1 1 A MET 1 1
ATOM 774 C CE . MET 95 95 ? A -8.86829 4.05172 6.27923 1 1 A MET 1 1
ATOM 775 N N . VAL 96 96 ? A -10.71886 -0.56071 2.79506 1 1 A VAL 1 1
ATOM 776 C CA . VAL 96 96 ? A -10.66308 -1.37869 1.59315 1 1 A VAL 1 1
ATOM 777 C C . VAL 96 96 ? A -10.01300 -0.60324 0.46135 1 1 A VAL 1 1
ATOM 778 O O . VAL 96 96 ? A -10.41463 0.52638 0.17275 1 1 A VAL 1 1
ATOM 779 C CB . VAL 96 96 ? A -12.06592 -1.82922 1.16700 1 1 A VAL 1 1
ATOM 780 C CG1 . VAL 96 96 ? A -11.99682 -2.67518 -0.09208 1 1 A VAL 1 1
ATOM 781 C CG2 . VAL 96 96 ? A -12.73658 -2.60565 2.28257 1 1 A VAL 1 1
ATOM 782 N N . GLU 97 97 ? A -9.03084 -1.19953 -0.18463 1 1 A GLU 1 1
ATOM 783 C CA . GLU 97 97 ? A -8.34937 -0.57930 -1.29637 1 1 A GLU 1 1
ATOM 784 C C . GLU 97 97 ? A -9.17994 -0.71704 -2.55959 1 1 A GLU 1 1
ATOM 785 O O . GLU 97 97 ? A -9.59408 -1.82091 -2.92151 1 1 A GLU 1 1
ATOM 786 C CB . GLU 97 97 ? A -6.96970 -1.22017 -1.50158 1 1 A GLU 1 1
ATOM 787 C CG . GLU 97 97 ? A -6.14577 -0.54049 -2.57910 1 1 A GLU 1 1
ATOM 788 C CD . GLU 97 97 ? A -4.74005 -1.11389 -2.70916 1 1 A GLU 1 1
ATOM 789 O OE1 . GLU 97 97 ? A -4.45885 -2.15505 -2.12821 1 1 A GLU 1 1
ATOM 790 O OE2 . GLU 97 97 ? A -3.93239 -0.49986 -3.42903 1 1 A GLU 1 1
ATOM 791 N N . LEU 98 98 ? A -9.44125 0.40143 -3.24726 1 1 A LEU 1 1
ATOM 792 C CA . LEU 98 98 ? A -10.13916 0.35891 -4.52120 1 1 A LEU 1 1
ATOM 793 C C . LEU 98 98 ? A -9.24134 -0.27128 -5.56599 1 1 A LEU 1 1
ATOM 794 O O . LEU 98 98 ? A -8.03747 -0.04284 -5.59276 1 1 A LEU 1 1
ATOM 795 C CB . LEU 98 98 ? A -10.55701 1.75245 -4.96805 1 1 A LEU 1 1
ATOM 796 C CG . LEU 98 98 ? A -11.68952 2.38027 -4.18150 1 1 A LEU 1 1
ATOM 797 C CD1 . LEU 98 98 ? A -12.02869 3.74403 -4.75128 1 1 A LEU 1 1
ATOM 798 C CD2 . LEU 98 98 ? A -12.92269 1.49986 -4.19849 1 1 A LEU 1 1
ATOM 799 N N . GLY 99 99 ? A -9.82171 -1.06139 -6.41652 1 1 A GLY 1 1
ATOM 800 C CA . GLY 99 99 ? A -9.07013 -1.77318 -7.43953 1 1 A GLY 1 1
ATOM 801 C C . GLY 99 99 ? A -8.85869 -3.23108 -7.05897 1 1 A GLY 1 1
ATOM 802 O O . GLY 99 99 ? A -9.40831 -4.12540 -7.70797 1 1 A GLY 1 1
ATOM 803 N N . SER 100 100 ? A -8.12743 -3.47970 -5.98776 1 1 A SER 1 1
ATOM 804 C CA . SER 100 100 ? A -7.85135 -4.84144 -5.55245 1 1 A SER 1 1
ATOM 805 C C . SER 100 100 ? A -8.92667 -5.41074 -4.63808 1 1 A SER 1 1
ATOM 806 O O . SER 100 100 ? A -9.08760 -6.62409 -4.58152 1 1 A SER 1 1
ATOM 807 C CB . SER 100 100 ? A -6.49983 -4.90746 -4.84034 1 1 A SER 1 1
ATOM 808 O OG . SER 100 100 ? A -6.55292 -4.22533 -3.59946 1 1 A SER 1 1
ATOM 809 N N . GLY 101 101 ? A -9.64401 -4.57339 -3.89918 1 1 A GLY 1 1
ATOM 810 C CA . GLY 101 101 ? A -10.64466 -5.03171 -2.95576 1 1 A GLY 1 1
ATOM 811 C C . GLY 101 101 ? A -10.08406 -5.57803 -1.65935 1 1 A GLY 1 1
ATOM 812 O O . GLY 101 101 ? A -10.83316 -6.06294 -0.81888 1 1 A GLY 1 1
ATOM 813 N N . ASN 102 102 ? A -8.76275 -5.46893 -1.47697 1 1 A ASN 1 1
ATOM 814 C CA . ASN 102 102 ? A -8.11811 -6.01518 -0.29705 1 1 A ASN 1 1
ATOM 815 C C . ASN 102 102 ? A -8.38974 -5.17875 0.94607 1 1 A ASN 1 1
ATOM 816 O O . ASN 102 102 ? A -8.44260 -3.94431 0.87652 1 1 A ASN 1 1
ATOM 817 C CB . ASN 102 102 ? A -6.61118 -6.11455 -0.51600 1 1 A ASN 1 1
ATOM 818 C CG . ASN 102 102 ? A -6.23492 -7.11816 -1.57805 1 1 A ASN 1 1
ATOM 819 O OD1 . ASN 102 102 ? A -6.94191 -8.09487 -1.78317 1 1 A ASN 1 1
ATOM 820 N ND2 . ASN 102 102 ? A -5.12833 -6.87873 -2.27182 1 1 A ASN 1 1
ATOM 821 N N . SER 103 103 ? A -8.54456 -5.84880 2.05878 1 1 A SER 1 1
ATOM 822 C CA . SER 103 103 ? A -8.63600 -5.21868 3.35142 1 1 A SER 1 1
ATOM 823 C C . SER 103 103 ? A -7.24617 -4.73783 3.75376 1 1 A SER 1 1
ATOM 824 O O . SER 103 103 ? A -6.25378 -5.40816 3.49249 1 1 A SER 1 1
ATOM 825 C CB . SER 103 103 ? A -9.17344 -6.21007 4.38045 1 1 A SER 1 1
ATOM 826 O OG . SER 103 103 ? A -9.34553 -5.61677 5.63627 1 1 A SER 1 1
ATOM 827 N N . PHE 104 104 ? A -7.18905 -3.58163 4.41230 1 1 A PHE 1 1
ATOM 828 C CA . PHE 104 104 ? A -5.90877 -2.99927 4.77481 1 1 A PHE 1 1
ATOM 829 C C . PHE 104 104 ? A -5.89632 -2.64797 6.24933 1 1 A PHE 1 1
ATOM 830 O O . PHE 104 104 ? A -6.77630 -1.94759 6.72571 1 1 A PHE 1 1
ATOM 831 C CB . PHE 104 104 ? A -5.64909 -1.74560 3.91907 1 1 A PHE 1 1
ATOM 832 C CG . PHE 104 104 ? A -4.22946 -1.25665 3.94639 1 1 A PHE 1 1
ATOM 833 C CD1 . PHE 104 104 ? A -3.27124 -1.85981 3.16146 1 1 A PHE 1 1
ATOM 834 C CD2 . PHE 104 104 ? A -3.86275 -0.20151 4.76440 1 1 A PHE 1 1
ATOM 835 C CE1 . PHE 104 104 ? A -1.96059 -1.41423 3.16004 1 1 A PHE 1 1
ATOM 836 C CE2 . PHE 104 104 ? A -2.54830 0.24497 4.78138 1 1 A PHE 1 1
ATOM 837 C CZ . PHE 104 104 ? A -1.59643 -0.36307 3.97228 1 1 A PHE 1 1
ATOM 838 N N . GLN 105 105 ? A -4.88551 -3.10662 6.96839 1 1 A GLN 1 1
ATOM 839 C CA . GLN 105 105 ? A -4.77087 -2.83170 8.37776 1 1 A GLN 1 1
ATOM 840 C C . GLN 105 105 ? A -4.24629 -1.41146 8.57893 1 1 A GLN 1 1
ATOM 841 O O . GLN 105 105 ? A -3.18715 -1.06423 8.07966 1 1 A GLN 1 1
ATOM 842 C CB . GLN 105 105 ? A -3.84590 -3.83263 9.05010 1 1 A GLN 1 1
ATOM 843 C CG . GLN 105 105 ? A -3.89794 -3.76986 10.57180 1 1 A GLN 1 1
ATOM 844 C CD . GLN 105 105 ? A -3.14368 -4.92621 11.21814 1 1 A GLN 1 1
ATOM 845 O OE1 . GLN 105 105 ? A -2.79145 -5.89162 10.57587 1 1 A GLN 1 1
ATOM 846 N NE2 . GLN 105 105 ? A -2.90161 -4.82398 12.50219 1 1 A GLN 1 1
ATOM 847 N N . ILE 106 106 ? A -5.00227 -0.60158 9.33991 1 1 A ILE 1 1
ATOM 848 C CA . ILE 106 106 ? A -4.65886 0.77883 9.61541 1 1 A ILE 1 1
ATOM 849 C C . ILE 106 106 ? A -4.43493 0.98110 11.10691 1 1 A ILE 1 1
ATOM 850 O O . ILE 106 106 ? A -4.89969 0.17963 11.91464 1 1 A ILE 1 1
ATOM 851 C CB . ILE 106 106 ? A -5.73141 1.75418 9.08954 1 1 A ILE 1 1
ATOM 852 C CG1 . ILE 106 106 ? A -7.07792 1.46474 9.77170 1 1 A ILE 1 1
ATOM 853 C CG2 . ILE 106 106 ? A -5.85109 1.65422 7.58365 1 1 A ILE 1 1
ATOM 854 C CD1 . ILE 106 106 ? A -8.14731 2.48352 9.40757 1 1 A ILE 1 1
ATOM 855 N N . PRO 107 107 ? A -3.74555 2.05586 11.47306 1 1 A PRO 1 1
ATOM 856 C CA . PRO 107 107 ? A -3.53529 2.31103 12.89918 1 1 A PRO 1 1
ATOM 857 C C . PRO 107 107 ? A -4.85080 2.46785 13.64773 1 1 A PRO 1 1
ATOM 858 O O . PRO 107 107 ? A -5.82809 2.97230 13.10161 1 1 A PRO 1 1
ATOM 859 C CB . PRO 107 107 ? A -2.74247 3.62265 12.90372 1 1 A PRO 1 1
ATOM 860 C CG . PRO 107 107 ? A -2.06760 3.65310 11.57371 1 1 A PRO 1 1
ATOM 861 C CD . PRO 107 107 ? A -3.04898 3.01929 10.62708 1 1 A PRO 1 1
ATOM 862 N N . ASP 108 108 ? A -4.87296 2.04398 14.93255 1 1 A ASP 1 1
ATOM 863 C CA . ASP 108 108 ? A -6.06991 2.13165 15.74562 1 1 A ASP 1 1
ATOM 864 C C . ASP 108 108 ? A -6.56561 3.56165 15.90980 1 1 A ASP 1 1
ATOM 865 O O . ASP 108 108 ? A -7.77356 3.78445 16.03152 1 1 A ASP 1 1
ATOM 866 C CB . ASP 108 108 ? A -5.83605 1.49617 17.11946 1 1 A ASP 1 1
ATOM 867 C CG . ASP 108 108 ? A -5.75210 -0.02165 17.06711 1 1 A ASP 1 1
ATOM 868 O OD1 . ASP 108 108 ? A -6.18637 -0.61832 16.06261 1 1 A ASP 1 1
ATOM 869 O OD2 . ASP 108 108 ? A -5.26059 -0.62385 18.04019 1 1 A ASP 1 1
ATOM 870 N N . GLU 109 109 ? A -5.64336 4.55019 15.92180 1 1 A GLU 1 1
ATOM 871 C CA . GLU 109 109 ? A -6.02797 5.94923 16.04630 1 1 A GLU 1 1
ATOM 872 C C . GLU 109 109 ? A -6.87262 6.39041 14.86189 1 1 A GLU 1 1
ATOM 873 O O . GLU 109 109 ? A -7.88072 7.08637 15.03690 1 1 A GLU 1 1
ATOM 874 C CB . GLU 109 109 ? A -4.78226 6.84276 16.14292 1 1 A GLU 1 1
ATOM 875 C CG . GLU 109 109 ? A -3.87491 6.54271 17.30846 1 1 A GLU 1 1
ATOM 876 C CD . GLU 109 109 ? A -2.80979 5.48582 16.99620 1 1 A GLU 1 1
ATOM 877 O OE1 . GLU 109 109 ? A -2.74840 5.03981 15.84529 1 1 A GLU 1 1
ATOM 878 O OE2 . GLU 109 109 ? A -2.06064 5.13414 17.90770 1 1 A GLU 1 1
ATOM 879 N N . ILE 110 110 ? A -6.51216 5.96508 13.67993 1 1 A ILE 1 1
ATOM 880 C CA . ILE 110 110 ? A -7.27140 6.30691 12.47952 1 1 A ILE 1 1
ATOM 881 C C . ILE 110 110 ? A -8.60305 5.56867 12.47019 1 1 A ILE 1 1
ATOM 882 O O . ILE 110 110 ? A -9.64776 6.15508 12.16033 1 1 A ILE 1 1
ATOM 883 C CB . ILE 110 110 ? A -6.46055 6.01272 11.21257 1 1 A ILE 1 1
ATOM 884 C CG1 . ILE 110 110 ? A -5.25322 6.96264 11.14605 1 1 A ILE 1 1
ATOM 885 C CG2 . ILE 110 110 ? A -7.33260 6.16423 9.97070 1 1 A ILE 1 1
ATOM 886 C CD1 . ILE 110 110 ? A -4.33183 6.70631 9.96403 1 1 A ILE 1 1
ATOM 887 N N . ARG 111 111 ? A -8.58436 4.27830 12.80592 1 1 A ARG 1 1
ATOM 888 C CA . ARG 111 111 ? A -9.81407 3.51503 12.87590 1 1 A ARG 1 1
ATOM 889 C C . ARG 111 111 ? A -10.79281 4.15337 13.85601 1 1 A ARG 1 1
ATOM 890 O O . ARG 111 111 ? A -11.97976 4.29058 13.56412 1 1 A ARG 1 1
ATOM 891 C CB . ARG 111 111 ? A -9.52001 2.07237 13.27673 1 1 A ARG 1 1
ATOM 892 C CG . ARG 111 111 ? A -10.75342 1.18479 13.28848 1 1 A ARG 1 1
ATOM 893 C CD . ARG 111 111 ? A -10.39914 -0.24690 13.63823 1 1 A ARG 1 1
ATOM 894 N NE . ARG 111 111 ? A -9.49908 -0.80934 12.65659 1 1 A ARG 1 1
ATOM 895 C CZ . ARG 111 111 ? A -9.89439 -1.26002 11.46142 1 1 A ARG 1 1
ATOM 896 N NH1 . ARG 111 111 ? A -11.13162 -1.24561 11.10621 1 1 A ARG 1 1
ATOM 897 N NH2 . ARG 111 111 ? A -8.98610 -1.73738 10.61581 1 1 A ARG 1 1
ATOM 898 N N . SER 112 112 ? A -10.30561 4.52706 15.04721 1 1 A SER 1 1
ATOM 899 C CA . SER 112 112 ? A -11.14736 5.14932 16.05166 1 1 A SER 1 1
ATOM 900 C C . SER 112 112 ? A -11.72824 6.46466 15.56066 1 1 A SER 1 1
ATOM 901 O O . SER 112 112 ? A -12.92707 6.72793 15.74557 1 1 A SER 1 1
ATOM 902 C CB . SER 112 112 ? A -10.35564 5.37138 17.33703 1 1 A SER 1 1
ATOM 903 O OG . SER 112 112 ? A -11.11508 6.06979 18.28929 1 1 A SER 1 1
ATOM 904 N N . ALA 113 113 ? A -10.91189 7.29361 14.94279 1 1 A ALA 1 1
ATOM 905 C CA . ALA 113 113 ? A -11.38585 8.56214 14.41728 1 1 A ALA 1 1
ATOM 906 C C . ALA 113 113 ? A -12.44264 8.35853 13.34169 1 1 A ALA 1 1
ATOM 907 O O . ALA 113 113 ? A -13.45968 9.06963 13.32072 1 1 A ALA 1 1
ATOM 908 C CB . ALA 113 113 ? A -10.21960 9.37664 13.87737 1 1 A ALA 1 1
ATOM 909 N N . CYS 114 114 ? A -12.22382 7.40604 12.44722 1 1 A CYS 1 1
ATOM 910 C CA . CYS 114 114 ? A -13.19939 7.10813 11.40337 1 1 A CYS 1 1
ATOM 911 C C . CYS 114 114 ? A -14.53264 6.67703 11.98927 1 1 A CYS 1 1
ATOM 912 O O . CYS 114 114 ? A -15.58862 7.13317 11.53567 1 1 A CYS 1 1
ATOM 913 C CB . CYS 114 114 ? A -12.67242 6.01795 10.47447 1 1 A CYS 1 1
ATOM 914 S SG . CYS 114 114 ? A -11.31738 6.54153 9.41291 1 1 A CYS 1 1
ATOM 915 N N . ASN 115 115 ? A -14.47326 5.81550 12.99225 1 1 A ASN 1 1
ATOM 916 C CA . ASN 115 115 ? A -15.70310 5.35795 13.62299 1 1 A ASN 1 1
ATOM 917 C C . ASN 115 115 ? A -16.45460 6.49208 14.30740 1 1 A ASN 1 1
ATOM 918 O O . ASN 115 115 ? A -17.69078 6.54050 14.24169 1 1 A ASN 1 1
ATOM 919 C CB . ASN 115 115 ? A -15.41644 4.22843 14.61120 1 1 A ASN 1 1
ATOM 920 C CG . ASN 115 115 ? A -15.05823 2.93879 13.91489 1 1 A ASN 1 1
ATOM 921 O OD1 . ASN 115 115 ? A -15.50048 2.68910 12.80504 1 1 A ASN 1 1
ATOM 922 N ND2 . ASN 115 115 ? A -14.27310 2.10793 14.57323 1 1 A ASN 1 1
ATOM 923 N N . LYS 116 116 ? A -15.75484 7.41965 14.93248 1 1 A LYS 1 1
ATOM 924 C CA . LYS 116 116 ? A -16.39258 8.57714 15.54098 1 1 A LYS 1 1
ATOM 925 C C . LYS 116 116 ? A -17.09365 9.42659 14.49914 1 1 A LYS 1 1
ATOM 926 O O . LYS 116 116 ? A -18.21903 9.89675 14.72927 1 1 A LYS 1 1
ATOM 927 C CB . LYS 116 116 ? A -15.38346 9.42375 16.30197 1 1 A LYS 1 1
ATOM 928 C CG . LYS 116 116 ? A -14.87115 8.79629 17.58305 1 1 A LYS 1 1
ATOM 929 C CD . LYS 116 116 ? A -13.83787 9.68288 18.26410 1 1 A LYS 1 1
ATOM 930 C CE . LYS 116 116 ? A -13.28895 9.03889 19.51929 1 1 A LYS 1 1
ATOM 931 N NZ . LYS 116 116 ? A -12.18622 9.82541 20.10354 1 1 A LYS 1 1
ATOM 932 N N . VAL 117 117 ? A -16.45150 9.63266 13.35701 1 1 A VAL 1 1
ATOM 933 C CA . VAL 117 117 ? A -17.06851 10.39257 12.28101 1 1 A VAL 1 1
ATOM 934 C C . VAL 117 117 ? A -18.32492 9.68537 11.78581 1 1 A VAL 1 1
ATOM 935 O O . VAL 117 117 ? A -19.37981 10.31582 11.64112 1 1 A VAL 1 1
ATOM 936 C CB . VAL 117 117 ? A -16.08006 10.62219 11.11881 1 1 A VAL 1 1
ATOM 937 C CG1 . VAL 117 117 ? A -16.78628 11.26255 9.93409 1 1 A VAL 1 1
ATOM 938 C CG2 . VAL 117 117 ? A -14.92006 11.48632 11.58601 1 1 A VAL 1 1
ATOM 939 N N . LEU 118 118 ? A -18.22727 8.39937 11.51615 1 1 A LEU 1 1
ATOM 940 C CA . LEU 118 118 ? A -19.37430 7.64944 11.02579 1 1 A LEU 1 1
ATOM 941 C C . LEU 118 118 ? A -20.52490 7.64681 12.02390 1 1 A LEU 1 1
ATOM 942 O O . LEU 118 118 ? A -21.69071 7.70611 11.62368 1 1 A LEU 1 1
ATOM 943 C CB . LEU 118 118 ? A -18.97602 6.21368 10.68153 1 1 A LEU 1 1
ATOM 944 C CG . LEU 118 118 ? A -18.06895 6.05775 9.45881 1 1 A LEU 1 1
ATOM 945 C CD1 . LEU 118 118 ? A -17.60637 4.61476 9.32007 1 1 A LEU 1 1
ATOM 946 C CD2 . LEU 118 118 ? A -18.80613 6.49226 8.19272 1 1 A LEU 1 1
ATOM 947 N N . ASP 119 119 ? A -20.20716 7.58105 13.32597 1 1 A ASP 1 1
ATOM 948 C CA . ASP 119 119 ? A -21.23640 7.56164 14.35775 1 1 A ASP 1 1
ATOM 949 C C . ASP 119 119 ? A -21.95479 8.89074 14.51729 1 1 A ASP 1 1
ATOM 950 O O . ASP 119 119 ? A -23.08016 8.93015 15.00928 1 1 A ASP 1 1
ATOM 951 C CB . ASP 119 119 ? A -20.63826 7.14454 15.70793 1 1 A ASP 1 1
ATOM 952 C CG . ASP 119 119 ? A -20.27816 5.66738 15.77714 1 1 A ASP 1 1
ATOM 953 O OD1 . ASP 119 119 ? A -20.77561 4.89155 14.93675 1 1 A ASP 1 1
ATOM 954 O OD2 . ASP 119 119 ? A -19.50945 5.28972 16.66858 1 1 A ASP 1 1
ATOM 955 N N . SER 120 120 ? A -21.32565 9.98687 14.11050 1 1 A SER 1 1
ATOM 956 C CA . SER 120 120 ? A -21.91717 11.31207 14.26817 1 1 A SER 1 1
ATOM 957 C C . SER 120 120 ? A -22.95893 11.65160 13.21186 1 1 A SER 1 1
ATOM 958 O O . SER 120 120 ? A -23.66611 12.64507 13.36457 1 1 A SER 1 1
ATOM 959 C CB . SER 120 120 ? A -20.81948 12.37354 14.26245 1 1 A SER 1 1
ATOM 960 O OG . SER 120 120 ? A -20.29115 12.51809 12.95374 1 1 A SER 1 1
ATOM 961 N N . ASP 121 121 ? A -23.05018 10.88609 12.14558 1 1 A ASP 1 1
ATOM 962 C CA . ASP 121 121 ? A -23.99368 11.16859 11.06569 1 1 A ASP 1 1
ATOM 963 C C . ASP 121 121 ? A -24.44681 9.85166 10.45573 1 1 A ASP 1 1
ATOM 964 O O . ASP 121 121 ? A -23.66361 9.15569 9.81058 1 1 A ASP 1 1
ATOM 965 C CB . ASP 121 121 ? A -23.33299 12.04976 10.01562 1 1 A ASP 1 1
ATOM 966 C CG . ASP 121 121 ? A -24.19752 12.29351 8.79317 1 1 A ASP 1 1
ATOM 967 O OD1 . ASP 121 121 ? A -25.43198 12.35806 8.93630 1 1 A ASP 1 1
ATOM 968 O OD2 . ASP 121 121 ? A -23.66912 12.42796 7.68719 1 1 A ASP 1 1
ATOM 969 N N . ASN 122 122 ? A -25.73705 9.52744 10.62690 1 1 A ASN 1 1
ATOM 970 C CA . ASN 122 122 ? A -26.27142 8.25648 10.16000 1 1 A ASN 1 1
ATOM 971 C C . ASN 122 122 ? A -26.43557 8.17227 8.64766 1 1 A ASN 1 1
ATOM 972 O O . ASN 122 122 ? A -26.79343 7.11960 8.12100 1 1 A ASN 1 1
ATOM 973 C CB . ASN 122 122 ? A -27.59264 7.93974 10.86465 1 1 A ASN 1 1
ATOM 974 C CG . ASN 122 122 ? A -28.66051 9.00701 10.62570 1 1 A ASN 1 1
ATOM 975 O OD1 . ASN 122 122 ? A -28.54534 9.82577 9.72611 1 1 A ASN 1 1
ATOM 976 N ND2 . ASN 122 122 ? A -29.69725 8.98541 11.44002 1 1 A ASN 1 1
ATOM 977 N N . GLY 123 123 ? A -26.15774 9.28144 7.93156 1 1 A GLY 1 1
ATOM 978 C CA . GLY 123 123 ? A -26.28284 9.29574 6.48728 1 1 A GLY 1 1
ATOM 979 C C . GLY 123 123 ? A -24.99146 9.01879 5.73886 1 1 A GLY 1 1
ATOM 980 O O . GLY 123 123 ? A -25.01010 8.96021 4.50321 1 1 A GLY 1 1
ATOM 981 N N . ILE 124 124 ? A -23.87762 8.83604 6.42150 1 1 A ILE 1 1
ATOM 982 C CA . ILE 124 124 ? A -22.60999 8.63243 5.74430 1 1 A ILE 1 1
ATOM 983 C C . ILE 124 124 ? A -22.55714 7.25419 5.11013 1 1 A ILE 1 1
ATOM 984 O O . ILE 124 124 ? A -22.72373 6.24507 5.78861 1 1 A ILE 1 1
ATOM 985 C CB . ILE 124 124 ? A -21.42549 8.80910 6.71224 1 1 A ILE 1 1
ATOM 986 C CG1 . ILE 124 124 ? A -21.39889 10.24721 7.24166 1 1 A ILE 1 1
ATOM 987 C CG2 . ILE 124 124 ? A -20.10736 8.47465 6.02386 1 1 A ILE 1 1
ATOM 988 C CD1 . ILE 124 124 ? A -20.38573 10.47515 8.34665 1 1 A ILE 1 1
ATOM 989 N N . ASP 125 125 ? A -22.29918 7.21399 3.78548 1 1 A ASP 1 1
ATOM 990 C CA . ASP 125 125 ? A -22.11815 5.94791 3.08143 1 1 A ASP 1 1
ATOM 991 C C . ASP 125 125 ? A -20.68906 5.44641 3.20825 1 1 A ASP 1 1
ATOM 992 O O . ASP 125 125 ? A -20.47555 4.25844 3.46305 1 1 A ASP 1 1
ATOM 993 C CB . ASP 125 125 ? A -22.48044 6.07873 1.60196 1 1 A ASP 1 1
ATOM 994 C CG . ASP 125 125 ? A -23.96690 6.23446 1.36390 1 1 A ASP 1 1
ATOM 995 O OD1 . ASP 125 125 ? A -24.75364 5.62556 2.10403 1 1 A ASP 1 1
ATOM 996 O OD2 . ASP 125 125 ? A -24.33959 6.96898 0.42233 1 1 A ASP 1 1
ATOM 997 N N . PHE 126 126 ? A -19.70940 6.33736 3.01837 1 1 A PHE 1 1
ATOM 998 C CA . PHE 126 126 ? A -18.32658 5.94827 3.17519 1 1 A PHE 1 1
ATOM 999 C C . PHE 126 126 ? A -17.43232 7.18274 3.24478 1 1 A PHE 1 1
ATOM 1000 O O . PHE 126 126 ? A -17.84968 8.27572 2.85312 1 1 A PHE 1 1
ATOM 1001 C CB . PHE 126 126 ? A -17.86152 5.00803 2.05385 1 1 A PHE 1 1
ATOM 1002 C CG . PHE 126 126 ? A -17.89667 5.61472 0.68348 1 1 A PHE 1 1
ATOM 1003 C CD1 . PHE 126 126 ? A -16.84025 6.36923 0.21689 1 1 A PHE 1 1
ATOM 1004 C CD2 . PHE 126 126 ? A -18.99792 5.40106 -0.13594 1 1 A PHE 1 1
ATOM 1005 C CE1 . PHE 126 126 ? A -16.86697 6.92489 -1.06815 1 1 A PHE 1 1
ATOM 1006 C CE2 . PHE 126 126 ? A -19.03613 5.94568 -1.41646 1 1 A PHE 1 1
ATOM 1007 C CZ . PHE 126 126 ? A -17.95691 6.70716 -1.88518 1 1 A PHE 1 1
ATOM 1008 N N . LEU 127 127 ? A -16.22919 6.97013 3.74955 1 1 A LEU 1 1
ATOM 1009 C CA . LEU 127 127 ? A -15.17164 7.95858 3.73890 1 1 A LEU 1 1
ATOM 1010 C C . LEU 127 127 ? A -14.11477 7.50263 2.74845 1 1 A LEU 1 1
ATOM 1011 O O . LEU 127 127 ? A -13.74653 6.32034 2.74274 1 1 A LEU 1 1
ATOM 1012 C CB . LEU 127 127 ? A -14.54666 8.10932 5.12883 1 1 A LEU 1 1
ATOM 1013 C CG . LEU 127 127 ? A -15.51598 8.31479 6.29203 1 1 A LEU 1 1
ATOM 1014 C CD1 . LEU 127 127 ? A -14.74988 8.38590 7.60608 1 1 A LEU 1 1
ATOM 1015 C CD2 . LEU 127 127 ? A -16.34117 9.57530 6.08181 1 1 A LEU 1 1
ATOM 1016 N N . ARG 128 128 ? A -13.62086 8.39221 1.93403 1 1 A ARG 1 1
ATOM 1017 C CA . ARG 128 128 ? A -12.62997 8.06679 0.94014 1 1 A ARG 1 1
ATOM 1018 C C . ARG 128 128 ? A -11.27177 8.60236 1.38274 1 1 A ARG 1 1
ATOM 1019 O O . ARG 128 128 ? A -11.15127 9.78117 1.73241 1 1 A ARG 1 1
ATOM 1020 C CB . ARG 128 128 ? A -13.01807 8.67326 -0.39953 1 1 A ARG 1 1
ATOM 1021 C CG . ARG 128 128 ? A -12.08124 8.29217 -1.53142 1 1 A ARG 1 1
ATOM 1022 C CD . ARG 128 128 ? A -12.50828 8.94019 -2.83194 1 1 A ARG 1 1
ATOM 1023 N NE . ARG 128 128 ? A -11.39763 9.00865 -3.73379 1 1 A ARG 1 1
ATOM 1024 C CZ . ARG 128 128 ? A -11.48480 8.84059 -5.05520 1 1 A ARG 1 1
ATOM 1025 N NH1 . ARG 128 128 ? A -12.63903 8.59463 -5.63045 1 1 A ARG 1 1
ATOM 1026 N NH2 . ARG 128 128 ? A -10.38871 8.91664 -5.80291 1 1 A ARG 1 1
ATOM 1027 N N . PHE 129 129 ? A -10.25612 7.73947 1.37770 1 1 A PHE 1 1
ATOM 1028 C CA . PHE 129 129 ? A -8.90872 8.08566 1.76218 1 1 A PHE 1 1
ATOM 1029 C C . PHE 129 129 ? A -7.93063 7.85339 0.63263 1 1 A PHE 1 1
ATOM 1030 O O . PHE 129 129 ? A -8.17834 7.03094 -0.23797 1 1 A PHE 1 1
ATOM 1031 C CB . PHE 129 129 ? A -8.45553 7.24108 2.96755 1 1 A PHE 1 1
ATOM 1032 C CG . PHE 129 129 ? A -9.13693 7.58024 4.25127 1 1 A PHE 1 1
ATOM 1033 C CD1 . PHE 129 129 ? A -10.36718 7.02341 4.56033 1 1 A PHE 1 1
ATOM 1034 C CD2 . PHE 129 129 ? A -8.54418 8.45080 5.14420 1 1 A PHE 1 1
ATOM 1035 C CE1 . PHE 129 129 ? A -11.00764 7.33574 5.74561 1 1 A PHE 1 1
ATOM 1036 C CE2 . PHE 129 129 ? A -9.17502 8.76264 6.34205 1 1 A PHE 1 1
ATOM 1037 C CZ . PHE 129 129 ? A -10.40018 8.19807 6.63821 1 1 A PHE 1 1
ATOM 1038 N N . VAL 130 130 ? A -6.83153 8.54528 0.69905 1 1 A VAL 1 1
ATOM 1039 C CA . VAL 130 130 ? A -5.70317 8.28884 -0.17122 1 1 A VAL 1 1
ATOM 1040 C C . VAL 130 130 ? A -4.46901 8.09361 0.69941 1 1 A VAL 1 1
ATOM 1041 O O . VAL 130 130 ? A -4.28044 8.79245 1.70000 1 1 A VAL 1 1
ATOM 1042 C CB . VAL 130 130 ? A -5.51926 9.42872 -1.20958 1 1 A VAL 1 1
ATOM 1043 C CG1 . VAL 130 130 ? A -5.10389 10.72403 -0.52386 1 1 A VAL 1 1
ATOM 1044 C CG2 . VAL 130 130 ? A -4.51161 9.00851 -2.25085 1 1 A VAL 1 1
ATOM 1045 N N . LEU 131 131 ? A -3.66909 7.12146 0.38266 1 1 A LEU 1 1
ATOM 1046 C CA . LEU 131 131 ? A -2.40438 6.87808 1.04438 1 1 A LEU 1 1
ATOM 1047 C C . LEU 131 131 ? A -1.29270 7.31125 0.11399 1 1 A LEU 1 1
ATOM 1048 O O . LEU 131 131 ? A -1.14342 6.76058 -0.97066 1 1 A LEU 1 1
ATOM 1049 C CB . LEU 131 131 ? A -2.27030 5.39396 1.41604 1 1 A LEU 1 1
ATOM 1050 C CG . LEU 131 131 ? A -0.96734 4.97207 2.10853 1 1 A LEU 1 1
ATOM 1051 C CD1 . LEU 131 131 ? A -0.89501 5.57591 3.49703 1 1 A LEU 1 1
ATOM 1052 C CD2 . LEU 131 131 ? A -0.89153 3.45505 2.20422 1 1 A LEU 1 1
ATOM 1053 N N . LEU 132 132 ? A -0.52264 8.29328 0.54027 1 1 A LEU 1 1
ATOM 1054 C CA . LEU 132 132 ? A 0.54075 8.83201 -0.28486 1 1 A LEU 1 1
ATOM 1055 C C . LEU 132 132 ? A 1.77686 9.02987 0.57068 1 1 A LEU 1 1
ATOM 1056 O O . LEU 132 132 ? A 1.72889 9.75640 1.56569 1 1 A LEU 1 1
ATOM 1057 C CB . LEU 132 132 ? A 0.10522 10.17270 -0.90788 1 1 A LEU 1 1
ATOM 1058 C CG . LEU 132 132 ? A 1.18577 10.94640 -1.68204 1 1 A LEU 1 1
ATOM 1059 C CD1 . LEU 132 132 ? A 1.59558 10.11511 -2.91883 1 1 A LEU 1 1
ATOM 1060 C CD2 . LEU 132 132 ? A 0.65054 12.32140 -2.07343 1 1 A LEU 1 1
ATOM 1061 N N . ASN 133 133 ? A 2.87523 8.38122 0.20243 1 1 A ASN 1 1
ATOM 1062 C CA . ASN 133 133 ? A 4.10870 8.46946 0.96649 1 1 A ASN 1 1
ATOM 1063 C C . ASN 133 133 ? A 3.86930 8.16088 2.44112 1 1 A ASN 1 1
ATOM 1064 O O . ASN 133 133 ? A 4.35760 8.85670 3.32924 1 1 A ASN 1 1
ATOM 1065 C CB . ASN 133 133 ? A 4.76026 9.85086 0.81622 1 1 A ASN 1 1
ATOM 1066 C CG . ASN 133 133 ? A 5.19805 10.11846 -0.60366 1 1 A ASN 1 1
ATOM 1067 O OD1 . ASN 133 133 ? A 5.52048 9.19358 -1.33322 1 1 A ASN 1 1
ATOM 1068 N ND2 . ASN 133 133 ? A 5.20124 11.38082 -0.99613 1 1 A ASN 1 1
ATOM 1069 N N . ASN 134 134 ? A 3.09376 7.10823 2.68185 1 1 A ASN 1 1
ATOM 1070 C CA . ASN 134 134 ? A 2.77678 6.62031 4.01342 1 1 A ASN 1 1
ATOM 1071 C C . ASN 134 134 ? A 1.99997 7.63372 4.85917 1 1 A ASN 1 1
ATOM 1072 O O . ASN 134 134 ? A 2.02109 7.57540 6.08745 1 1 A ASN 1 1
ATOM 1073 C CB . ASN 134 134 ? A 4.05683 6.18440 4.73203 1 1 A ASN 1 1
ATOM 1074 C CG . ASN 134 134 ? A 3.79947 5.14852 5.80862 1 1 A ASN 1 1
ATOM 1075 O OD1 . ASN 134 134 ? A 3.01276 4.23350 5.60817 1 1 A ASN 1 1
ATOM 1076 N ND2 . ASN 134 134 ? A 4.44392 5.29134 6.94563 1 1 A ASN 1 1
ATOM 1077 N N . ARG 135 135 ? A 1.31217 8.55777 4.23021 1 1 A ARG 1 1
ATOM 1078 C CA . ARG 135 135 ? A 0.43245 9.50258 4.90617 1 1 A ARG 1 1
ATOM 1079 C C . ARG 135 135 ? A -1.00282 9.27295 4.46285 1 1 A ARG 1 1
ATOM 1080 O O . ARG 135 135 ? A -1.26459 9.08272 3.27119 1 1 A ARG 1 1
ATOM 1081 C CB . ARG 135 135 ? A 0.83861 10.94563 4.59304 1 1 A ARG 1 1
ATOM 1082 C CG . ARG 135 135 ? A 2.17493 11.34775 5.19096 1 1 A ARG 1 1
ATOM 1083 C CD . ARG 135 135 ? A 2.45465 12.83036 4.90689 1 1 A ARG 1 1
ATOM 1084 N NE . ARG 135 135 ? A 1.48277 13.71086 5.55298 1 1 A ARG 1 1
ATOM 1085 C CZ . ARG 135 135 ? A 1.30447 15.00099 5.22935 1 1 A ARG 1 1
ATOM 1086 N NH1 . ARG 135 135 ? A 2.02354 15.56904 4.27545 1 1 A ARG 1 1
ATOM 1087 N NH2 . ARG 135 135 ? A 0.40452 15.71946 5.88573 1 1 A ARG 1 1
ATOM 1088 N N . TRP 136 136 ? A -1.93191 9.30466 5.39609 1 1 A TRP 1 1
ATOM 1089 C CA . TRP 136 136 ? A -3.35294 9.13394 5.12020 1 1 A TRP 1 1
ATOM 1090 C C . TRP 136 136 ? A -4.03435 10.48510 5.00626 1 1 A TRP 1 1
ATOM 1091 O O . TRP 136 136 ? A -3.91215 11.32709 5.90813 1 1 A TRP 1 1
ATOM 1092 C CB . TRP 136 136 ? A -4.02895 8.33804 6.23882 1 1 A TRP 1 1
ATOM 1093 C CG . TRP 136 136 ? A -3.64362 6.89954 6.26698 1 1 A TRP 1 1
ATOM 1094 C CD1 . TRP 136 136 ? A -2.69597 6.31773 7.05400 1 1 A TRP 1 1
ATOM 1095 C CD2 . TRP 136 136 ? A -4.20109 5.87099 5.45728 1 1 A TRP 1 1
ATOM 1096 N NE1 . TRP 136 136 ? A -2.62759 4.97077 6.78026 1 1 A TRP 1 1
ATOM 1097 C CE2 . TRP 136 136 ? A -3.53494 4.67437 5.81406 1 1 A TRP 1 1
ATOM 1098 C CE3 . TRP 136 136 ? A -5.18584 5.83580 4.48347 1 1 A TRP 1 1
ATOM 1099 C CZ2 . TRP 136 136 ? A -3.84633 3.45265 5.21967 1 1 A TRP 1 1
ATOM 1100 C CZ3 . TRP 136 136 ? A -5.49200 4.62514 3.89227 1 1 A TRP 1 1
ATOM 1101 C CH2 . TRP 136 136 ? A -4.81598 3.45117 4.26334 1 1 A TRP 1 1
ATOM 1102 N N . ILE 137 137 ? A -4.75368 10.70844 3.91214 1 1 A ILE 1 1
ATOM 1103 C CA . ILE 137 137 ? A -5.54265 11.91645 3.73255 1 1 A ILE 1 1
ATOM 1104 C C . ILE 137 137 ? A -6.97747 11.50344 3.42013 1 1 A ILE 1 1
ATOM 1105 O O . ILE 137 137 ? A -7.19701 10.73264 2.48007 1 1 A ILE 1 1
ATOM 1106 C CB . ILE 137 137 ? A -4.98741 12.78349 2.59152 1 1 A ILE 1 1
ATOM 1107 C CG1 . ILE 137 137 ? A -3.51659 13.12221 2.82820 1 1 A ILE 1 1
ATOM 1108 C CG2 . ILE 137 137 ? A -5.81793 14.05685 2.42977 1 1 A ILE 1 1
ATOM 1109 C CD1 . ILE 137 137 ? A -2.83451 13.76268 1.64846 1 1 A ILE 1 1
ATOM 1110 N N . MET 138 138 ? A -7.93080 11.98885 4.20508 1 1 A MET 1 1
ATOM 1111 C CA . MET 138 138 ? A -9.32854 11.76708 3.88423 1 1 A MET 1 1
ATOM 1112 C C . MET 138 138 ? A -9.73194 12.79369 2.84479 1 1 A MET 1 1
ATOM 1113 O O . MET 138 138 ? A -9.86530 13.96550 3.15269 1 1 A MET 1 1
ATOM 1114 C CB . MET 138 138 ? A -10.20847 11.87860 5.12925 1 1 A MET 1 1
ATOM 1115 C CG . MET 138 138 ? A -11.65622 11.51533 4.85872 1 1 A MET 1 1
ATOM 1116 S SD . MET 138 138 ? A -12.67393 11.58567 6.34601 1 1 A MET 1 1
ATOM 1117 C CE . MET 138 138 ? A -13.07773 13.32680 6.35345 1 1 A MET 1 1
ATOM 1118 N N . GLU 139 139 ? A -9.87096 12.37367 1.57034 1 1 A GLU 1 1
ATOM 1119 C CA . GLU 139 139 ? A -10.10223 13.33805 0.50918 1 1 A GLU 1 1
ATOM 1120 C C . GLU 139 139 ? A -11.56579 13.55248 0.17032 1 1 A GLU 1 1
ATOM 1121 O O . GLU 139 139 ? A -11.89110 14.49944 -0.54606 1 1 A GLU 1 1
ATOM 1122 C CB . GLU 139 139 ? A -9.30160 12.97810 -0.72759 1 1 A GLU 1 1
ATOM 1123 C CG . GLU 139 139 ? A -9.76727 11.74826 -1.46093 1 1 A GLU 1 1
ATOM 1124 C CD . GLU 139 139 ? A -8.96992 11.47614 -2.73093 1 1 A GLU 1 1
ATOM 1125 O OE1 . GLU 139 139 ? A -7.99199 12.18474 -3.00064 1 1 A GLU 1 1
ATOM 1126 O OE2 . GLU 139 139 ? A -9.33444 10.52786 -3.47773 1 1 A GLU 1 1
ATOM 1127 N N . ASP 140 140 ? A -12.50255 12.67971 0.70863 1 1 A ASP 1 1
ATOM 1128 C CA . ASP 140 140 ? A -13.90586 12.87991 0.41663 1 1 A ASP 1 1
ATOM 1129 C C . ASP 140 140 ? A -14.75307 12.17957 1.47602 1 1 A ASP 1 1
ATOM 1130 O O . ASP 140 140 ? A -14.27198 11.25966 2.15587 1 1 A ASP 1 1
ATOM 1131 C CB . ASP 140 140 ? A -14.24507 12.34897 -0.96941 1 1 A ASP 1 1
ATOM 1132 C CG . ASP 140 140 ? A -15.48032 12.96867 -1.58428 1 1 A ASP 1 1
ATOM 1133 O OD1 . ASP 140 140 ? A -16.20980 13.69713 -0.88567 1 1 A ASP 1 1
ATOM 1134 O OD2 . ASP 140 140 ? A -15.72642 12.74286 -2.77271 1 1 A ASP 1 1
ATOM 1135 N N . ALA 141 141 ? A -15.95708 12.61854 1.61500 1 1 A ALA 1 1
ATOM 1136 C CA . ALA 141 141 ? A -16.93678 11.98865 2.47709 1 1 A ALA 1 1
ATOM 1137 C C . ALA 141 141 ? A -18.27781 12.00173 1.76989 1 1 A ALA 1 1
ATOM 1138 O O . ALA 141 141 ? A -18.72904 13.05510 1.32984 1 1 A ALA 1 1
ATOM 1139 C CB . ALA 141 141 ? A -17.03420 12.71048 3.81482 1 1 A ALA 1 1
ATOM 1140 N N . ILE 142 142 ? A -18.89515 10.85607 1.66646 1 1 A ILE 1 1
ATOM 1141 C CA . ILE 142 142 ? A -20.16563 10.73114 0.96991 1 1 A ILE 1 1
ATOM 1142 C C . ILE 142 142 ? A -21.28585 10.54884 1.99629 1 1 A ILE 1 1
ATOM 1143 O O . ILE 142 142 ? A -21.28083 9.57697 2.74981 1 1 A ILE 1 1
ATOM 1144 C CB . ILE 142 142 ? A -20.14817 9.54919 -0.01226 1 1 A ILE 1 1
ATOM 1145 C CG1 . ILE 142 142 ? A -18.97515 9.64224 -0.98070 1 1 A ILE 1 1
ATOM 1146 C CG2 . ILE 142 142 ? A -21.46775 9.45905 -0.75525 1 1 A ILE 1 1
ATOM 1147 C CD1 . ILE 142 142 ? A -18.94436 10.89075 -1.83679 1 1 A ILE 1 1
ATOM 1148 N N . SER 143 143 ? A -22.22468 11.48071 2.05429 1 1 A SER 1 1
ATOM 1149 C CA . SER 143 143 ? A -23.33900 11.42332 2.98002 1 1 A SER 1 1
ATOM 1150 C C . SER 143 143 ? A -24.61569 11.89089 2.31324 1 1 A SER 1 1
ATOM 1151 O O . SER 143 143 ? A -24.57735 12.79254 1.47494 1 1 A SER 1 1
ATOM 1152 C CB . SER 143 143 ? A -23.07128 12.29783 4.20357 1 1 A SER 1 1
ATOM 1153 O OG . SER 143 143 ? A -24.20924 12.36187 5.04474 1 1 A SER 1 1
ATOM 1154 N N . LYS 144 144 ? A -25.73603 11.27815 2.69560 1 1 A LYS 1 1
ATOM 1155 C CA . LYS 144 144 ? A -27.04225 11.66236 2.18283 1 1 A LYS 1 1
ATOM 1156 C C . LYS 144 144 ? A -27.54116 12.96087 2.77938 1 1 A LYS 1 1
ATOM 1157 O O . LYS 144 144 ? A -28.36423 13.63808 2.16853 1 1 A LYS 1 1
ATOM 1158 C CB . LYS 144 144 ? A -28.05698 10.55838 2.44537 1 1 A LYS 1 1
ATOM 1159 C CG . LYS 144 144 ? A -27.77888 9.27813 1.68513 1 1 A LYS 1 1
ATOM 1160 C CD . LYS 144 144 ? A -28.77393 8.18751 2.04477 1 1 A LYS 1 1
ATOM 1161 C CE . LYS 144 144 ? A -28.50775 6.90972 1.27304 1 1 A LYS 1 1
ATOM 1162 N NZ . LYS 144 144 ? A -27.22790 6.26976 1.66162 1 1 A LYS 1 1
ATOM 1163 N N . TYR 145 145 ? A -27.04604 13.31241 3.98004 1 1 A TYR 1 1
ATOM 1164 C CA . TYR 145 145 ? A -27.60287 14.43739 4.70450 1 1 A TYR 1 1
ATOM 1165 C C . TYR 145 145 ? A -26.71981 15.67544 4.81329 1 1 A TYR 1 1
ATOM 1166 O O . TYR 145 145 ? A -27.22725 16.77150 5.01021 1 1 A TYR 1 1
ATOM 1167 C CB . TYR 145 145 ? A -28.02486 14.00816 6.11103 1 1 A TYR 1 1
ATOM 1168 C CG . TYR 145 145 ? A -28.99151 12.86561 6.13927 1 1 A TYR 1 1
ATOM 1169 C CD1 . TYR 145 145 ? A -30.24708 12.99012 5.55474 1 1 A TYR 1 1
ATOM 1170 C CD2 . TYR 145 145 ? A -28.65081 11.66086 6.72675 1 1 A TYR 1 1
ATOM 1171 C CE1 . TYR 145 145 ? A -31.14108 11.94807 5.55619 1 1 A TYR 1 1
ATOM 1172 C CE2 . TYR 145 145 ? A -29.53435 10.59936 6.73806 1 1 A TYR 1 1
ATOM 1173 C CZ . TYR 145 145 ? A -30.79362 10.74598 6.14838 1 1 A TYR 1 1
ATOM 1174 O OH . TYR 145 145 ? A -31.68050 9.70852 6.16331 1 1 A TYR 1 1
ATOM 1175 N N . GLN 146 146 ? A -25.38063 15.50369 4.73204 1 1 A GLN 1 1
ATOM 1176 C CA . GLN 146 146 ? A -24.47439 16.61534 4.94540 1 1 A GLN 1 1
ATOM 1177 C C . GLN 146 146 ? A -23.47750 16.74272 3.81087 1 1 A GLN 1 1
ATOM 1178 O O . GLN 146 146 ? A -23.13437 15.75306 3.15865 1 1 A GLN 1 1
ATOM 1179 C CB . GLN 146 146 ? A -23.70058 16.42799 6.24104 1 1 A GLN 1 1
ATOM 1180 C CG . GLN 146 146 ? A -24.55236 16.45155 7.48354 1 1 A GLN 1 1
ATOM 1181 C CD . GLN 146 146 ? A -23.72088 16.58517 8.74110 1 1 A GLN 1 1
ATOM 1182 O OE1 . GLN 146 146 ? A -22.60700 17.10052 8.70977 1 1 A GLN 1 1
ATOM 1183 N NE2 . GLN 146 146 ? A -24.25733 16.12580 9.85451 1 1 A GLN 1 1
ATOM 1184 N N . SER 147 147 ? A -22.98185 17.95119 3.61573 1 1 A SER 1 1
ATOM 1185 C CA . SER 147 147 ? A -21.91058 18.18475 2.66353 1 1 A SER 1 1
ATOM 1186 C C . SER 147 147 ? A -20.60447 17.62260 3.20995 1 1 A SER 1 1
ATOM 1187 O O . SER 147 147 ? A -20.44408 17.51070 4.43180 1 1 A SER 1 1
ATOM 1188 C CB . SER 147 147 ? A -21.76328 19.68869 2.40349 1 1 A SER 1 1
ATOM 1189 O OG . SER 147 147 ? A -21.16894 20.33734 3.50945 1 1 A SER 1 1
ATOM 1190 N N . PRO 148 148 ? A -19.67452 17.28476 2.32747 1 1 A PRO 1 1
ATOM 1191 C CA . PRO 148 148 ? A -18.39917 16.79650 2.82841 1 1 A PRO 1 1
ATOM 1192 C C . PRO 148 148 ? A -17.69398 17.79481 3.74141 1 1 A PRO 1 1
ATOM 1193 O O . PRO 148 148 ? A -16.97187 17.37664 4.64979 1 1 A PRO 1 1
ATOM 1194 C CB . PRO 148 148 ? A -17.58778 16.51289 1.57567 1 1 A PRO 1 1
ATOM 1195 C CG . PRO 148 148 ? A -18.29611 17.23418 0.49451 1 1 A PRO 1 1
ATOM 1196 C CD . PRO 148 148 ? A -19.72814 17.33422 0.88945 1 1 A PRO 1 1
ATOM 1197 N N . VAL 149 149 ? A -17.89863 19.08313 3.56302 1 1 A VAL 1 1
ATOM 1198 C CA . VAL 149 149 ? A -17.23572 20.08791 4.37418 1 1 A VAL 1 1
ATOM 1199 C C . VAL 149 149 ? A -17.62060 19.96189 5.84996 1 1 A VAL 1 1
ATOM 1200 O O . VAL 149 149 ? A -16.75539 20.08515 6.73031 1 1 A VAL 1 1
ATOM 1201 C CB . VAL 149 149 ? A -17.53932 21.50364 3.84793 1 1 A VAL 1 1
ATOM 1202 C CG1 . VAL 149 149 ? A -17.00440 22.56787 4.79222 1 1 A VAL 1 1
ATOM 1203 C CG2 . VAL 149 149 ? A -16.95678 21.68345 2.47089 1 1 A VAL 1 1
ATOM 1204 N N . ASN 150 150 ? A -18.88687 19.74375 6.14080 1 1 A ASN 1 1
ATOM 1205 C CA . ASN 150 150 ? A -19.30631 19.54543 7.52053 1 1 A ASN 1 1
ATOM 1206 C C . ASN 150 150 ? A -18.64454 18.32056 8.13188 1 1 A ASN 1 1
ATOM 1207 O O . ASN 150 150 ? A -18.21029 18.35756 9.28959 1 1 A ASN 1 1
ATOM 1208 C CB . ASN 150 150 ? A -20.82126 19.41336 7.61028 1 1 A ASN 1 1
ATOM 1209 C CG . ASN 150 150 ? A -21.54538 20.70336 7.30899 1 1 A ASN 1 1
ATOM 1210 O OD1 . ASN 150 150 ? A -20.99613 21.78766 7.46338 1 1 A ASN 1 1
ATOM 1211 N ND2 . ASN 150 150 ? A -22.79015 20.58467 6.86371 1 1 A ASN 1 1
ATOM 1212 N N . ILE 151 151 ? A -18.53391 17.27574 7.34838 1 1 A ILE 1 1
ATOM 1213 C CA . ILE 151 151 ? A -17.89819 16.06112 7.82822 1 1 A ILE 1 1
ATOM 1214 C C . ILE 151 151 ? A -16.40231 16.27726 7.99748 1 1 A ILE 1 1
ATOM 1215 O O . ILE 151 151 ? A -15.80069 15.78415 8.95381 1 1 A ILE 1 1
ATOM 1216 C CB . ILE 151 151 ? A -18.21504 14.87917 6.90335 1 1 A ILE 1 1
ATOM 1217 C CG1 . ILE 151 151 ? A -19.72949 14.61469 6.92906 1 1 A ILE 1 1
ATOM 1218 C CG2 . ILE 151 151 ? A -17.44233 13.63995 7.30281 1 1 A ILE 1 1
ATOM 1219 C CD1 . ILE 151 151 ? A -20.21241 13.66245 5.87474 1 1 A ILE 1 1
ATOM 1220 N N . PHE 152 152 ? A -15.76186 17.00597 7.07648 1 1 A PHE 1 1
ATOM 1221 C CA . PHE 152 152 ? A -14.34548 17.32615 7.18518 1 1 A PHE 1 1
ATOM 1222 C C . PHE 152 152 ? A -14.02443 18.07367 8.47568 1 1 A PHE 1 1
ATOM 1223 O O . PHE 152 152 ? A -13.02177 17.77545 9.14072 1 1 A PHE 1 1
ATOM 1224 C CB . PHE 152 152 ? A -13.87316 18.16918 5.99612 1 1 A PHE 1 1
ATOM 1225 C CG . PHE 152 152 ? A -13.71707 17.42921 4.70114 1 1 A PHE 1 1
ATOM 1226 C CD1 . PHE 152 152 ? A -13.67210 16.05283 4.65814 1 1 A PHE 1 1
ATOM 1227 C CD2 . PHE 152 152 ? A -13.59888 18.15248 3.51522 1 1 A PHE 1 1
ATOM 1228 C CE1 . PHE 152 152 ? A -13.51595 15.39015 3.44245 1 1 A PHE 1 1
ATOM 1229 C CE2 . PHE 152 152 ? A -13.44483 17.50332 2.30140 1 1 A PHE 1 1
ATOM 1230 C CZ . PHE 152 152 ? A -13.40667 16.11460 2.26997 1 1 A PHE 1 1
ATOM 1231 N N . LYS 153 153 ? A -14.84709 19.03773 8.84573 1 1 A LYS 1 1
ATOM 1232 C CA . LYS 153 153 ? A -14.61749 19.79928 10.06524 1 1 A LYS 1 1
ATOM 1233 C C . LYS 153 153 ? A -14.63616 18.88545 11.28107 1 1 A LYS 1 1
ATOM 1234 O O . LYS 153 153 ? A -13.77108 18.99755 12.16040 1 1 A LYS 1 1
ATOM 1235 C CB . LYS 153 153 ? A -15.64617 20.91445 10.21019 1 1 A LYS 1 1
ATOM 1236 C CG . LYS 153 153 ? A -15.45188 22.03009 9.17865 1 1 A LYS 1 1
ATOM 1237 C CD . LYS 153 153 ? A -16.53312 23.09495 9.26008 1 1 A LYS 1 1
ATOM 1238 C CE . LYS 153 153 ? A -16.34161 24.14546 8.19235 1 1 A LYS 1 1
ATOM 1239 N NZ . LYS 153 153 ? A -17.40612 25.17182 8.22089 1 1 A LYS 1 1
ATOM 1240 N N . LEU 154 154 ? A -15.57195 17.97932 11.31450 1 1 A LEU 1 1
ATOM 1241 C CA . LEU 154 154 ? A -15.63495 17.00958 12.39366 1 1 A LEU 1 1
ATOM 1242 C C . LEU 154 154 ? A -14.43983 16.06778 12.35305 1 1 A LEU 1 1
ATOM 1243 O O . LEU 154 154 ? A -13.81908 15.79655 13.39445 1 1 A LEU 1 1
ATOM 1244 C CB . LEU 154 154 ? A -16.95248 16.22448 12.30802 1 1 A LEU 1 1
ATOM 1245 C CG . LEU 154 154 ? A -17.21875 15.23007 13.43334 1 1 A LEU 1 1
ATOM 1246 C CD1 . LEU 154 154 ? A -17.35224 15.94700 14.76353 1 1 A LEU 1 1
ATOM 1247 C CD2 . LEU 154 154 ? A -18.48477 14.44149 13.12305 1 1 A LEU 1 1
ATOM 1248 N N . ALA 155 155 ? A -14.11392 15.54524 11.18070 1 1 A ALA 1 1
ATOM 1249 C CA . ALA 155 155 ? A -12.99122 14.64093 11.02488 1 1 A ALA 1 1
ATOM 1250 C C . ALA 155 155 ? A -11.69086 15.28432 11.47857 1 1 A ALA 1 1
ATOM 1251 O O . ALA 155 155 ? A -10.85082 14.62304 12.10081 1 1 A ALA 1 1
ATOM 1252 C CB . ALA 155 155 ? A -12.88945 14.17614 9.58031 1 1 A ALA 1 1
ATOM 1253 N N . SER 156 156 ? A -11.51676 16.55535 11.16701 1 1 A SER 1 1
ATOM 1254 C CA . SER 156 156 ? A -10.33384 17.28859 11.58971 1 1 A SER 1 1
ATOM 1255 C C . SER 156 156 ? A -10.24641 17.34702 13.11094 1 1 A SER 1 1
ATOM 1256 O O . SER 156 156 ? A -9.16192 17.20446 13.68914 1 1 A SER 1 1
ATOM 1257 C CB . SER 156 156 ? A -10.34501 18.68778 10.98881 1 1 A SER 1 1
ATOM 1258 O OG . SER 156 156 ? A -9.21895 19.42768 11.40357 1 1 A SER 1 1
ATOM 1259 N N . GLU 157 157 ? A -11.36270 17.55956 13.79039 1 1 A GLU 1 1
ATOM 1260 C CA . GLU 157 157 ? A -11.38760 17.58546 15.24724 1 1 A GLU 1 1
ATOM 1261 C C . GLU 157 157 ? A -10.95924 16.25525 15.84809 1 1 A GLU 1 1
ATOM 1262 O O . GLU 157 157 ? A -10.39619 16.21736 16.94274 1 1 A GLU 1 1
ATOM 1263 C CB . GLU 157 157 ? A -12.78135 17.95660 15.75383 1 1 A GLU 1 1
ATOM 1264 C CG . GLU 157 157 ? A -13.16205 19.39500 15.45190 1 1 A GLU 1 1
ATOM 1265 C CD . GLU 157 157 ? A -14.61469 19.71169 15.79632 1 1 A GLU 1 1
ATOM 1266 O OE1 . GLU 157 157 ? A -15.31014 18.84728 16.36769 1 1 A GLU 1 1
ATOM 1267 O OE2 . GLU 157 157 ? A -15.06409 20.83222 15.49188 1 1 A GLU 1 1
ATOM 1268 N N . TYR 158 158 ? A -11.22465 15.15669 15.13502 1 1 A TYR 1 1
ATOM 1269 C CA . TYR 158 158 ? A -10.83222 13.83439 15.61109 1 1 A TYR 1 1
ATOM 1270 C C . TYR 158 158 ? A -9.42409 13.43369 15.17248 1 1 A TYR 1 1
ATOM 1271 O O . TYR 158 158 ? A -8.99985 12.31036 15.41152 1 1 A TYR 1 1
ATOM 1272 C CB . TYR 158 158 ? A -11.83272 12.77821 15.13733 1 1 A TYR 1 1
ATOM 1273 C CG . TYR 158 158 ? A -13.22920 12.95741 15.69350 1 1 A TYR 1 1
ATOM 1274 C CD1 . TYR 158 158 ? A -13.41627 13.35002 17.00844 1 1 A TYR 1 1
ATOM 1275 C CD2 . TYR 158 158 ? A -14.33888 12.72936 14.89231 1 1 A TYR 1 1
ATOM 1276 C CE1 . TYR 158 158 ? A -14.68430 13.51364 17.52258 1 1 A TYR 1 1
ATOM 1277 C CE2 . TYR 158 158 ? A -15.61928 12.89524 15.40356 1 1 A TYR 1 1
ATOM 1278 C CZ . TYR 158 158 ? A -15.78909 13.28901 16.72477 1 1 A TYR 1 1
ATOM 1279 O OH . TYR 158 158 ? A -17.04988 13.44770 17.22711 1 1 A TYR 1 1
ATOM 1280 N N . GLY 159 159 ? A -8.68797 14.37706 14.53921 1 1 A GLY 1 1
ATOM 1281 C CA . GLY 159 159 ? A -7.29994 14.13405 14.21357 1 1 A GLY 1 1
ATOM 1282 C C . GLY 159 159 ? A -7.02753 13.60070 12.81952 1 1 A GLY 1 1
ATOM 1283 O O . GLY 159 159 ? A -5.86591 13.28804 12.52097 1 1 A GLY 1 1
ATOM 1284 N N . LEU 160 160 ? A -8.01575 13.47837 11.96427 1 1 A LEU 1 1
ATOM 1285 C CA . LEU 160 160 ? A -7.77814 13.01549 10.61051 1 1 A LEU 1 1
ATOM 1286 C C . LEU 160 160 ? A -7.26740 14.15660 9.74552 1 1 A LEU 1 1
ATOM 1287 O O . LEU 160 160 ? A -7.63064 15.31865 9.96421 1 1 A LEU 1 1
ATOM 1288 C CB . LEU 160 160 ? A -9.05588 12.44278 9.99928 1 1 A LEU 1 1
ATOM 1289 C CG . LEU 160 160 ? A -9.59194 11.17099 10.64242 1 1 A LEU 1 1
ATOM 1290 C CD1 . LEU 160 160 ? A -10.90062 10.75749 9.99211 1 1 A LEU 1 1
ATOM 1291 C CD2 . LEU 160 160 ? A -8.58334 10.04720 10.54088 1 1 A LEU 1 1
ATOM 1292 N N . ASN 161 161 ? A -6.38676 13.84093 8.79219 1 1 A ASN 1 1
ATOM 1293 C CA . ASN 161 161 ? A -5.90957 14.80863 7.82324 1 1 A ASN 1 1
ATOM 1294 C C . ASN 161 161 ? A -6.95291 15.00243 6.74494 1 1 A ASN 1 1
ATOM 1295 O O . ASN 161 161 ? A -7.38457 14.03333 6.11831 1 1 A ASN 1 1
ATOM 1296 C CB . ASN 161 161 ? A -4.60227 14.35833 7.17395 1 1 A ASN 1 1
ATOM 1297 C CG . ASN 161 161 ? A -3.43999 14.39298 8.11961 1 1 A ASN 1 1
ATOM 1298 O OD1 . ASN 161 161 ? A -3.39689 15.21278 9.03360 1 1 A ASN 1 1
ATOM 1299 N ND2 . ASN 161 161 ? A -2.48337 13.49858 7.92561 1 1 A ASN 1 1
ATOM 1300 N N . ILE 162 162 ? A -7.39454 16.21860 6.54393 1 1 A ILE 1 1
ATOM 1301 C CA . ILE 162 162 ? A -8.35128 16.52443 5.49810 1 1 A ILE 1 1
ATOM 1302 C C . ILE 162 162 ? A -7.72431 17.45948 4.47918 1 1 A ILE 1 1
ATOM 1303 O O . ILE 162 162 ? A -6.75521 18.15258 4.79327 1 1 A ILE 1 1
ATOM 1304 C CB . ILE 162 162 ? A -9.64836 17.13947 6.06707 1 1 A ILE 1 1
ATOM 1305 C CG1 . ILE 162 162 ? A -9.34504 18.47771 6.74632 1 1 A ILE 1 1
ATOM 1306 C CG2 . ILE 162 162 ? A -10.31783 16.16536 7.03335 1 1 A ILE 1 1
ATOM 1307 C CD1 . ILE 162 162 ? A -10.58232 19.29475 7.05170 1 1 A ILE 1 1
ATOM 1308 N N . PRO 163 163 ? A -8.27505 17.51187 3.27830 1 1 A PRO 1 1
ATOM 1309 C CA . PRO 163 163 ? A -7.71417 18.40059 2.27162 1 1 A PRO 1 1
ATOM 1310 C C . PRO 163 163 ? A -8.17151 19.84307 2.47847 1 1 A PRO 1 1
ATOM 1311 O O . PRO 163 163 ? A -9.19097 20.09465 3.13034 1 1 A PRO 1 1
ATOM 1312 C CB . PRO 163 163 ? A -8.28999 17.85568 0.95951 1 1 A PRO 1 1
ATOM 1313 C CG . PRO 163 163 ? A -9.63387 17.33484 1.35966 1 1 A PRO 1 1
ATOM 1314 C CD . PRO 163 163 ? A -9.42481 16.75009 2.74796 1 1 A PRO 1 1
ATOM 1315 N N . ASN 164 164 ? A -7.42315 20.79239 1.93665 1 1 A ASN 1 1
ATOM 1316 C CA . ASN 164 164 ? A -7.96877 22.11149 1.72926 1 1 A ASN 1 1
ATOM 1317 C C . ASN 164 164 ? A -9.00897 22.00314 0.62853 1 1 A ASN 1 1
ATOM 1318 O O . ASN 164 164 ? A -8.83544 21.24553 -0.32102 1 1 A ASN 1 1
ATOM 1319 C CB . ASN 164 164 ? A -6.89859 23.11166 1.32565 1 1 A ASN 1 1
ATOM 1320 C CG . ASN 164 164 ? A -5.91534 23.39233 2.43886 1 1 A ASN 1 1
ATOM 1321 O OD1 . ASN 164 164 ? A -6.24196 23.25583 3.61066 1 1 A ASN 1 1
ATOM 1322 N ND2 . ASN 164 164 ? A -4.69597 23.77009 2.07441 1 1 A ASN 1 1
ATOM 1323 N N . TYR 165 165 ? A -10.11464 22.71601 0.78279 1 1 A TYR 1 1
ATOM 1324 C CA . TYR 165 165 ? A -11.20409 22.67496 -0.16537 1 1 A TYR 1 1
ATOM 1325 C C . TYR 165 165 ? A -11.65998 24.09151 -0.45751 1 1 A TYR 1 1
ATOM 1326 O O . TYR 165 165 ? A -11.25025 25.03829 0.23017 1 1 A TYR 1 1
ATOM 1327 C CB . TYR 165 165 ? A -12.36793 21.83669 0.37215 1 1 A TYR 1 1
ATOM 1328 C CG . TYR 165 165 ? A -12.86317 22.29626 1.71860 1 1 A TYR 1 1
ATOM 1329 C CD1 . TYR 165 165 ? A -12.27047 21.80515 2.89379 1 1 A TYR 1 1
ATOM 1330 C CD2 . TYR 165 165 ? A -13.87764 23.24723 1.84083 1 1 A TYR 1 1
ATOM 1331 C CE1 . TYR 165 165 ? A -12.68970 22.23190 4.14381 1 1 A TYR 1 1
ATOM 1332 C CE2 . TYR 165 165 ? A -14.30377 23.67920 3.09310 1 1 A TYR 1 1
ATOM 1333 C CZ . TYR 165 165 ? A -13.70161 23.17002 4.23569 1 1 A TYR 1 1
ATOM 1334 O OH . TYR 165 165 ? A -14.12009 23.60707 5.46812 1 1 A TYR 1 1
ATOM 1335 N N . LEU 166 166 ? A -12.46809 24.27885 -1.44521 1 1 A LEU 1 1
ATOM 1336 C CA . LEU 166 166 ? A -12.99535 25.57816 -1.81487 1 1 A LEU 1 1
ATOM 1337 C C . LEU 166 166 ? A -14.51110 25.52659 -1.81351 1 1 A LEU 1 1
ATOM 1338 O O . LEU 166 166 ? A -15.09926 24.58621 -2.35561 1 1 A LEU 1 1
ATOM 1339 C CB . LEU 166 166 ? A -12.50815 25.99230 -3.20515 1 1 A LEU 1 1
ATOM 1340 C CG . LEU 166 166 ? A -10.99862 25.99943 -3.41648 1 1 A LEU 1 1
ATOM 1341 C CD1 . LEU 166 166 ? A -10.68071 26.25765 -4.87535 1 1 A LEU 1 1
ATOM 1342 C CD2 . LEU 166 166 ? A -10.34228 27.05728 -2.52710 1 1 A LEU 1 1
ATOM 1343 N N . GLU 167 167 ? A -15.15572 26.52099 -1.22596 1 1 A GLU 1 1
ATOM 1344 C CA . GLU 167 167 ? A -16.58974 26.72498 -1.31717 1 1 A GLU 1 1
ATOM 1345 C C . GLU 167 167 ? A -16.81185 27.95752 -2.16191 1 1 A GLU 1 1
ATOM 1346 O O . GLU 167 167 ? A -16.39426 29.05155 -1.77956 1 1 A GLU 1 1
ATOM 1347 C CB . GLU 167 167 ? A -17.21959 26.90917 0.06360 1 1 A GLU 1 1
ATOM 1348 C CG . GLU 167 167 ? A -17.09675 25.68340 0.96008 1 1 A GLU 1 1
ATOM 1349 C CD . GLU 167 167 ? A -17.82936 25.86263 2.29046 1 1 A GLU 1 1
ATOM 1350 O OE1 . GLU 167 167 ? A -19.00119 26.26880 2.28258 1 1 A GLU 1 1
ATOM 1351 O OE2 . GLU 167 167 ? A -17.22747 25.60369 3.32955 1 1 A GLU 1 1
ATOM 1352 N N . ILE 168 168 ? A -17.42187 27.81156 -3.30098 1 1 A ILE 1 1
ATOM 1353 C CA . ILE 168 168 ? A -17.55151 28.91420 -4.23925 1 1 A ILE 1 1
ATOM 1354 C C . ILE 168 168 ? A -18.99458 29.03392 -4.69428 1 1 A ILE 1 1
ATOM 1355 O O . ILE 168 168 ? A -19.60095 28.04333 -5.11628 1 1 A ILE 1 1
ATOM 1356 C CB . ILE 168 168 ? A -16.64935 28.71178 -5.46967 1 1 A ILE 1 1
ATOM 1357 C CG1 . ILE 168 168 ? A -15.19159 28.58229 -5.04664 1 1 A ILE 1 1
ATOM 1358 C CG2 . ILE 168 168 ? A -16.82828 29.86516 -6.44908 1 1 A ILE 1 1
ATOM 1359 C CD1 . ILE 168 168 ? A -14.25127 28.21148 -6.17393 1 1 A ILE 1 1
ATOM 1360 N N . GLU 169 169 ? A -19.53272 30.24580 -4.62968 1 1 A GLU 1 1
ATOM 1361 C CA . GLU 169 169 ? A -20.82503 30.52077 -5.22697 1 1 A GLU 1 1
ATOM 1362 C C . GLU 169 169 ? A -20.59067 31.01877 -6.64931 1 1 A GLU 1 1
ATOM 1363 O O . GLU 169 169 ? A -19.93563 32.04103 -6.84485 1 1 A GLU 1 1
ATOM 1364 C CB . GLU 169 169 ? A -21.60230 31.56226 -4.42388 1 1 A GLU 1 1
ATOM 1365 C CG . GLU 169 169 ? A -22.98099 31.84745 -5.02125 1 1 A GLU 1 1
ATOM 1366 C CD . GLU 169 169 ? A -23.77729 32.85321 -4.20130 1 1 A GLU 1 1
ATOM 1367 O OE1 . GLU 169 169 ? A -23.21323 33.49324 -3.30602 1 1 A GLU 1 1
ATOM 1368 O OE2 . GLU 169 169 ? A -24.96650 33.01205 -4.49053 1 1 A GLU 1 1
ATOM 1369 N N . ILE 170 170 ? A -21.08823 30.29411 -7.60027 1 1 A ILE 1 1
ATOM 1370 C CA . ILE 170 170 ? A -21.00253 30.69646 -8.99738 1 1 A ILE 1 1
ATOM 1371 C C . ILE 170 170 ? A -22.29187 31.41129 -9.35650 1 1 A ILE 1 1
ATOM 1372 O O . ILE 170 170 ? A -23.37256 30.81863 -9.27404 1 1 A ILE 1 1
ATOM 1373 C CB . ILE 170 170 ? A -20.74382 29.48377 -9.91018 1 1 A ILE 1 1
ATOM 1374 C CG1 . ILE 170 170 ? A -19.38588 28.87496 -9.55787 1 1 A ILE 1 1
ATOM 1375 C CG2 . ILE 170 170 ? A -20.81479 29.89657 -11.36823 1 1 A ILE 1 1
ATOM 1376 C CD1 . ILE 170 170 ? A -19.07833 27.58327 -10.26100 1 1 A ILE 1 1
ATOM 1377 N N . GLU 171 171 ? A -22.18024 32.69969 -9.71327 1 1 A GLU 1 1
ATOM 1378 C CA . GLU 171 171 ? A -23.35723 33.53754 -9.90379 1 1 A GLU 1 1
ATOM 1379 C C . GLU 171 171 ? A -23.93134 33.45633 -11.30354 1 1 A GLU 1 1
ATOM 1380 O O . GLU 171 171 ? A -25.11584 33.72546 -11.49053 1 1 A GLU 1 1
ATOM 1381 C CB . GLU 171 171 ? A -23.03060 34.98536 -9.54992 1 1 A GLU 1 1
ATOM 1382 C CG . GLU 171 171 ? A -22.62412 35.15789 -8.08051 1 1 A GLU 1 1
ATOM 1383 C CD . GLU 171 171 ? A -22.32459 36.58755 -7.69498 1 1 A GLU 1 1
ATOM 1384 O OE1 . GLU 171 171 ? A -22.71600 37.51734 -8.42393 1 1 A GLU 1 1
ATOM 1385 O OE2 . GLU 171 171 ? A -21.69753 36.78551 -6.64977 1 1 A GLU 1 1
ATOM 1386 N N . GLU 172 172 ? A -23.11804 33.09670 -12.29011 1 1 A GLU 1 1
ATOM 1387 C CA . GLU 172 172 ? A -23.58491 32.95353 -13.66157 1 1 A GLU 1 1
ATOM 1388 C C . GLU 172 172 ? A -22.83544 31.82073 -14.34877 1 1 A GLU 1 1
ATOM 1389 O O . GLU 172 172 ? A -21.79753 31.36370 -13.82597 1 1 A GLU 1 1
ATOM 1390 C CB . GLU 172 172 ? A -23.41085 34.26152 -14.43874 1 1 A GLU 1 1
ATOM 1391 C CG . GLU 172 172 ? A -21.94688 34.71177 -14.55022 1 1 A GLU 1 1
ATOM 1392 C CD . GLU 172 172 ? A -21.81767 36.08428 -15.18838 1 1 A GLU 1 1
ATOM 1393 O OE1 . GLU 172 172 ? A -22.82895 36.76697 -15.35977 1 1 A GLU 1 1
ATOM 1394 O OE2 . GLU 172 172 ? A -20.67159 36.47802 -15.50477 1 1 A GLU 1 1
ATOM 1395 N N . ASP 173 173 ? A -23.34521 31.35590 -15.46873 1 1 A ASP 1 1
ATOM 1396 C CA . ASP 173 173 ? A -22.66569 30.30145 -16.20798 1 1 A ASP 1 1
ATOM 1397 C C . ASP 173 173 ? A -21.19445 30.66878 -16.37100 1 1 A ASP 1 1
ATOM 1398 O O . ASP 173 173 ? A -20.87574 31.78194 -16.77017 1 1 A ASP 1 1
ATOM 1399 C CB . ASP 173 173 ? A -23.29389 30.09378 -17.58915 1 1 A ASP 1 1
ATOM 1400 C CG . ASP 173 173 ? A -24.63481 29.39680 -17.54064 1 1 A ASP 1 1
ATOM 1401 O OD1 . ASP 173 173 ? A -25.08766 29.03495 -16.44032 1 1 A ASP 1 1
ATOM 1402 O OD2 . ASP 173 173 ? A -25.23869 29.22579 -18.60598 1 1 A ASP 1 1
ATOM 1403 N N . THR 174 174 ? A -20.30145 29.75505 -16.02091 1 1 A THR 1 1
ATOM 1404 C CA . THR 174 174 ? A -18.87399 29.99949 -16.02558 1 1 A THR 1 1
ATOM 1405 C C . THR 174 174 ? A -18.14095 28.90273 -16.77322 1 1 A THR 1 1
ATOM 1406 O O . THR 174 174 ? A -18.41660 27.71564 -16.56927 1 1 A THR 1 1
ATOM 1407 C CB . THR 174 174 ? A -18.33260 30.09109 -14.58857 1 1 A THR 1 1
ATOM 1408 O OG1 . THR 174 174 ? A -19.04584 31.10608 -13.87218 1 1 A THR 1 1
ATOM 1409 C CG2 . THR 174 174 ? A -16.85776 30.43739 -14.56790 1 1 A THR 1 1
ATOM 1410 N N . LEU 175 175 ? A -17.18563 29.29487 -17.62597 1 1 A LEU 1 1
ATOM 1411 C CA . LEU 175 175 ? A -16.38026 28.33143 -18.35747 1 1 A LEU 1 1
ATOM 1412 C C . LEU 175 175 ? A -15.31420 27.75200 -17.43431 1 1 A LEU 1 1
ATOM 1413 O O . LEU 175 175 ? A -14.51782 28.49340 -16.84892 1 1 A LEU 1 1
ATOM 1414 C CB . LEU 175 175 ? A -15.71473 29.00310 -19.57443 1 1 A LEU 1 1
ATOM 1415 C CG . LEU 175 175 ? A -14.78245 28.10846 -20.40665 1 1 A LEU 1 1
ATOM 1416 C CD1 . LEU 175 175 ? A -15.57550 27.01192 -21.08464 1 1 A LEU 1 1
ATOM 1417 C CD2 . LEU 175 175 ? A -14.03453 28.93485 -21.43787 1 1 A LEU 1 1
ATOM 1418 N N . PHE 176 176 ? A -15.30081 26.43243 -17.29454 1 1 A PHE 1 1
ATOM 1419 C CA . PHE 176 176 ? A -14.27348 25.75356 -16.51369 1 1 A PHE 1 1
ATOM 1420 C C . PHE 176 176 ? A -13.20494 25.27866 -17.49678 1 1 A PHE 1 1
ATOM 1421 O O . PHE 176 176 ? A -13.34404 24.22356 -18.10658 1 1 A PHE 1 1
ATOM 1422 C CB . PHE 176 176 ? A -14.87227 24.56980 -15.74846 1 1 A PHE 1 1
ATOM 1423 C CG . PHE 176 176 ? A -14.00932 24.09016 -14.58245 1 1 A PHE 1 1
ATOM 1424 C CD1 . PHE 176 176 ? A -12.63260 24.03342 -14.67202 1 1 A PHE 1 1
ATOM 1425 C CD2 . PHE 176 176 ? A -14.63134 23.68488 -13.40210 1 1 A PHE 1 1
ATOM 1426 C CE1 . PHE 176 176 ? A -11.87020 23.58140 -13.60582 1 1 A PHE 1 1
ATOM 1427 C CE2 . PHE 176 176 ? A -13.86339 23.23731 -12.34158 1 1 A PHE 1 1
ATOM 1428 C CZ . PHE 176 176 ? A -12.48880 23.17258 -12.43997 1 1 A PHE 1 1
ATOM 1429 N N . ASP 177 177 ? A -12.18277 26.07424 -17.65967 1 1 A ASP 1 1
ATOM 1430 C CA . ASP 177 177 ? A -11.10956 25.80659 -18.60542 1 1 A ASP 1 1
ATOM 1431 C C . ASP 177 177 ? A -9.79114 25.56933 -17.87829 1 1 A ASP 1 1
ATOM 1432 O O . ASP 177 177 ? A -9.76350 25.52505 -16.63677 1 1 A ASP 1 1
ATOM 1433 C CB . ASP 177 177 ? A -10.98445 26.95338 -19.62401 1 1 A ASP 1 1
ATOM 1434 C CG . ASP 177 177 ? A -10.73267 28.30180 -18.97374 1 1 A ASP 1 1
ATOM 1435 O OD1 . ASP 177 177 ? A -10.37757 28.37276 -17.78802 1 1 A ASP 1 1
ATOM 1436 O OD2 . ASP 177 177 ? A -10.90265 29.32385 -19.66162 1 1 A ASP 1 1
ATOM 1437 N N . ASP 178 178 ? A -8.70690 25.42643 -18.62300 1 1 A ASP 1 1
ATOM 1438 C CA . ASP 178 178 ? A -7.39963 25.17793 -18.04320 1 1 A ASP 1 1
ATOM 1439 C C . ASP 178 178 ? A -6.97791 26.26927 -17.06942 1 1 A ASP 1 1
ATOM 1440 O O . ASP 178 178 ? A -6.33875 25.98227 -16.05172 1 1 A ASP 1 1
ATOM 1441 C CB . ASP 178 178 ? A -6.33844 25.02233 -19.13204 1 1 A ASP 1 1
ATOM 1442 C CG . ASP 178 178 ? A -6.50147 23.74726 -19.94285 1 1 A ASP 1 1
ATOM 1443 O OD1 . ASP 178 178 ? A -7.00790 22.75177 -19.39513 1 1 A ASP 1 1
ATOM 1444 O OD2 . ASP 178 178 ? A -6.11498 23.74486 -21.12245 1 1 A ASP 1 1
ATOM 1445 N N . GLU 179 179 ? A -7.32293 27.53388 -17.37882 1 1 A GLU 1 1
ATOM 1446 C CA . GLU 179 179 ? A -6.95167 28.63975 -16.51184 1 1 A GLU 1 1
ATOM 1447 C C . GLU 179 179 ? A -7.65532 28.54781 -15.16187 1 1 A GLU 1 1
ATOM 1448 O O . GLU 179 179 ? A -7.01215 28.69153 -14.10777 1 1 A GLU 1 1
ATOM 1449 C CB . GLU 179 179 ? A -7.24701 29.97305 -17.19406 1 1 A GLU 1 1
ATOM 1450 C CG . GLU 179 179 ? A -6.78239 31.18881 -16.38474 1 1 A GLU 1 1
ATOM 1451 C CD . GLU 179 179 ? A -7.00937 32.50329 -17.10537 1 1 A GLU 1 1
ATOM 1452 O OE1 . GLU 179 179 ? A -7.79932 32.53935 -18.06949 1 1 A GLU 1 1
ATOM 1453 O OE2 . GLU 179 179 ? A -6.40655 33.50868 -16.70886 1 1 A GLU 1 1
ATOM 1454 N N . LEU 180 180 ? A -8.96753 28.33717 -15.16217 1 1 A LEU 1 1
ATOM 1455 C CA . LEU 180 180 ? A -9.70169 28.19450 -13.90808 1 1 A LEU 1 1
ATOM 1456 C C . LEU 180 180 ? A -9.20722 26.97654 -13.14150 1 1 A LEU 1 1
ATOM 1457 O O . LEU 180 180 ? A -9.06250 27.03089 -11.91222 1 1 A LEU 1 1
ATOM 1458 C CB . LEU 180 180 ? A -11.20841 28.09755 -14.18044 1 1 A LEU 1 1
ATOM 1459 C CG . LEU 180 180 ? A -12.09880 27.96515 -12.94108 1 1 A LEU 1 1
ATOM 1460 C CD1 . LEU 180 180 ? A -11.87604 29.12956 -11.98311 1 1 A LEU 1 1
ATOM 1461 C CD2 . LEU 180 180 ? A -13.56530 27.91833 -13.35105 1 1 A LEU 1 1
ATOM 1462 N N . TYR 181 181 ? A -8.96388 25.86442 -13.84892 1 1 A TYR 1 1
ATOM 1463 C CA . TYR 181 181 ? A -8.41724 24.66944 -13.21670 1 1 A TYR 1 1
ATOM 1464 C C . TYR 181 181 ? A -7.11511 24.99312 -12.49696 1 1 A TYR 1 1
ATOM 1465 O O . TYR 181 181 ? A -6.92736 24.62509 -11.33160 1 1 A TYR 1 1
ATOM 1466 C CB . TYR 181 181 ? A -8.21510 23.56756 -14.25956 1 1 A TYR 1 1
ATOM 1467 C CG . TYR 181 181 ? A -7.73647 22.24079 -13.69381 1 1 A TYR 1 1
ATOM 1468 C CD1 . TYR 181 181 ? A -6.40594 22.04483 -13.33571 1 1 A TYR 1 1
ATOM 1469 C CD2 . TYR 181 181 ? A -8.61729 21.17801 -13.53284 1 1 A TYR 1 1
ATOM 1470 C CE1 . TYR 181 181 ? A -5.97612 20.81901 -12.81612 1 1 A TYR 1 1
ATOM 1471 C CE2 . TYR 181 181 ? A -8.18277 19.96080 -13.02983 1 1 A TYR 1 1
ATOM 1472 C CZ . TYR 181 181 ? A -6.87471 19.78667 -12.67671 1 1 A TYR 1 1
ATOM 1473 O OH . TYR 181 181 ? A -6.45409 18.56991 -12.17011 1 1 A TYR 1 1
ATOM 1474 N N . SER 182 182 ? A -6.21909 25.67541 -13.16431 1 1 A SER 1 1
ATOM 1475 C CA . SER 182 182 ? A -4.92552 26.03546 -12.59829 1 1 A SER 1 1
ATOM 1476 C C . SER 182 182 ? A -5.07108 26.93431 -11.37693 1 1 A SER 1 1
ATOM 1477 O O . SER 182 182 ? A -4.37620 26.74312 -10.36698 1 1 A SER 1 1
ATOM 1478 C CB . SER 182 182 ? A -4.05742 26.70962 -13.65729 1 1 A SER 1 1
ATOM 1479 O OG . SER 182 182 ? A -2.82128 27.10220 -13.11518 1 1 A SER 1 1
ATOM 1480 N N . ILE 183 183 ? A -5.95393 27.90792 -11.43880 1 1 A ILE 1 1
ATOM 1481 C CA . ILE 183 183 ? A -6.20557 28.80721 -10.31995 1 1 A ILE 1 1
ATOM 1482 C C . ILE 183 183 ? A -6.70885 28.01986 -9.11410 1 1 A ILE 1 1
ATOM 1483 O O . ILE 183 183 ? A -6.21867 28.21219 -7.99096 1 1 A ILE 1 1
ATOM 1484 C CB . ILE 183 183 ? A -7.21224 29.90605 -10.71016 1 1 A ILE 1 1
ATOM 1485 C CG1 . ILE 183 183 ? A -6.57000 30.88643 -11.69340 1 1 A ILE 1 1
ATOM 1486 C CG2 . ILE 183 183 ? A -7.71665 30.64592 -9.47785 1 1 A ILE 1 1
ATOM 1487 C CD1 . ILE 183 183 ? A -7.57181 31.83744 -12.33471 1 1 A ILE 1 1
ATOM 1488 N N . MET 184 184 ? A -7.64211 27.14647 -9.32933 1 1 A MET 1 1
ATOM 1489 C CA . MET 184 184 ? A -8.19191 26.35726 -8.23529 1 1 A MET 1 1
ATOM 1490 C C . MET 184 184 ? A -7.16010 25.40972 -7.66340 1 1 A MET 1 1
ATOM 1491 O O . MET 184 184 ? A -7.04850 25.28852 -6.43423 1 1 A MET 1 1
ATOM 1492 C CB . MET 184 184 ? A -9.43415 25.60564 -8.69615 1 1 A MET 1 1
ATOM 1493 C CG . MET 184 184 ? A -10.56874 26.55991 -9.04185 1 1 A MET 1 1
ATOM 1494 S SD . MET 184 184 ? A -12.03270 25.70125 -9.65741 1 1 A MET 1 1
ATOM 1495 C CE . MET 184 184 ? A -12.58797 24.92728 -8.15586 1 1 A MET 1 1
ATOM 1496 N N . GLU 185 185 ? A -6.37144 24.76380 -8.51780 1 1 A GLU 1 1
ATOM 1497 C CA . GLU 185 185 ? A -5.33538 23.85358 -8.06634 1 1 A GLU 1 1
ATOM 1498 C C . GLU 185 185 ? A -4.34118 24.56691 -7.15417 1 1 A GLU 1 1
ATOM 1499 O O . GLU 185 185 ? A -3.95592 24.04892 -6.09800 1 1 A GLU 1 1
ATOM 1500 C CB . GLU 185 185 ? A -4.63149 23.23375 -9.26392 1 1 A GLU 1 1
ATOM 1501 C CG . GLU 185 185 ? A -3.65499 22.11444 -8.90104 1 1 A GLU 1 1
ATOM 1502 C CD . GLU 185 185 ? A -3.11590 21.40990 -10.13986 1 1 A GLU 1 1
ATOM 1503 O OE1 . GLU 185 185 ? A -2.59713 22.11870 -11.02206 1 1 A GLU 1 1
ATOM 1504 O OE2 . GLU 185 185 ? A -3.22171 20.18618 -10.21626 1 1 A GLU 1 1
ATOM 1505 N N . ARG 186 186 ? A -3.89363 25.78778 -7.53870 1 1 A ARG 1 1
ATOM 1506 C CA . ARG 186 186 ? A -2.98383 26.57202 -6.71695 1 1 A ARG 1 1
ATOM 1507 C C . ARG 186 186 ? A -3.62943 26.98833 -5.40330 1 1 A ARG 1 1
ATOM 1508 O O . ARG 186 186 ? A -2.94910 27.13766 -4.38498 1 1 A ARG 1 1
ATOM 1509 C CB . ARG 186 186 ? A -2.50951 27.82273 -7.45452 1 1 A ARG 1 1
ATOM 1510 C CG . ARG 186 186 ? A -1.55440 27.56043 -8.59016 1 1 A ARG 1 1
ATOM 1511 C CD . ARG 186 186 ? A -0.94020 28.85847 -9.09169 1 1 A ARG 1 1
ATOM 1512 N NE . ARG 186 186 ? A -1.90841 29.84110 -9.54624 1 1 A ARG 1 1
ATOM 1513 C CZ . ARG 186 186 ? A -2.40570 29.91365 -10.77305 1 1 A ARG 1 1
ATOM 1514 N NH1 . ARG 186 186 ? A -2.00403 29.04210 -11.69215 1 1 A ARG 1 1
ATOM 1515 N NH2 . ARG 186 186 ? A -3.30203 30.85358 -11.07795 1 1 A ARG 1 1
ATOM 1516 N N . SER 187 187 ? A -4.94950 27.21708 -5.40208 1 1 A SER 1 1
ATOM 1517 C CA . SER 187 187 ? A -5.65711 27.67432 -4.21490 1 1 A SER 1 1
ATOM 1518 C C . SER 187 187 ? A -5.73608 26.61667 -3.12516 1 1 A SER 1 1
ATOM 1519 O O . SER 187 187 ? A -5.93229 26.95460 -1.95640 1 1 A SER 1 1
ATOM 1520 C CB . SER 187 187 ? A -7.05991 28.12799 -4.58614 1 1 A SER 1 1
ATOM 1521 O OG . SER 187 187 ? A -6.99637 29.22274 -5.47665 1 1 A SER 1 1
ATOM 1522 N N . PHE 188 188 ? A -5.59291 25.35045 -3.46650 1 1 A PHE 1 1
ATOM 1523 C CA . PHE 188 188 ? A -5.58031 24.32100 -2.44036 1 1 A PHE 1 1
ATOM 1524 C C . PHE 188 188 ? A -4.35900 24.43072 -1.53132 1 1 A PHE 1 1
ATOM 1525 O O . PHE 188 188 ? A -4.36519 23.91113 -0.42732 1 1 A PHE 1 1
ATOM 1526 C CB . PHE 188 188 ? A -5.66797 22.92164 -3.06653 1 1 A PHE 1 1
ATOM 1527 C CG . PHE 188 188 ? A -6.97455 22.64185 -3.77222 1 1 A PHE 1 1
ATOM 1528 C CD1 . PHE 188 188 ? A -8.16809 22.73056 -3.09459 1 1 A PHE 1 1
ATOM 1529 C CD2 . PHE 188 188 ? A -6.97730 22.27806 -5.11244 1 1 A PHE 1 1
ATOM 1530 C CE1 . PHE 188 188 ? A -9.36953 22.47359 -3.73450 1 1 A PHE 1 1
ATOM 1531 C CE2 . PHE 188 188 ? A -8.17737 22.01425 -5.75778 1 1 A PHE 1 1
ATOM 1532 C CZ . PHE 188 188 ? A -9.37636 22.11003 -5.07039 1 1 A PHE 1 1
ATOM 1533 N N . ASP 189 189 ? A -3.30200 25.12868 -1.99474 1 1 A ASP 1 1
ATOM 1534 C CA . ASP 189 189 ? A -2.10702 25.43359 -1.21266 1 1 A ASP 1 1
ATOM 1535 C C . ASP 189 189 ? A -1.58673 24.20713 -0.47010 1 1 A ASP 1 1
ATOM 1536 O O . ASP 189 189 ? A -1.28702 24.23799 0.72211 1 1 A ASP 1 1
ATOM 1537 C CB . ASP 189 189 ? A -2.39446 26.57331 -0.22559 1 1 A ASP 1 1
ATOM 1538 C CG . ASP 189 189 ? A -1.13928 27.13484 0.42277 1 1 A ASP 1 1
ATOM 1539 O OD1 . ASP 189 189 ? A -0.05682 26.99835 -0.16859 1 1 A ASP 1 1
ATOM 1540 O OD2 . ASP 189 189 ? A -1.24234 27.70585 1.52237 1 1 A ASP 1 1
ATOM 1541 N N . ASP 190 190 ? A -1.45293 23.14366 -1.18234 1 1 A ASP 1 1
ATOM 1542 C CA . ASP 190 190 ? A -1.01914 21.85831 -0.67390 1 1 A ASP 1 1
ATOM 1543 C C . ASP 190 190 ? A 0.20639 21.41132 -1.44639 1 1 A ASP 1 1
ATOM 1544 O O . ASP 190 190 ? A 0.34921 21.72067 -2.63563 1 1 A ASP 1 1
ATOM 1545 C CB . ASP 190 190 ? A -2.17059 20.85858 -0.89236 1 1 A ASP 1 1
ATOM 1546 C CG . ASP 190 190 ? A -2.00882 19.56955 -0.12563 1 1 A ASP 1 1
ATOM 1547 O OD1 . ASP 190 190 ? A -0.87395 19.16729 0.17308 1 1 A ASP 1 1
ATOM 1548 O OD2 . ASP 190 190 ? A -3.04464 18.92990 0.14774 1 1 A ASP 1 1
ATOM 1549 N N . THR 191 191 ? A 1.10240 20.69633 -0.80543 1 1 A THR 1 1
ATOM 1550 C CA . THR 191 191 ? A 2.31249 20.24204 -1.45923 1 1 A THR 1 1
ATOM 1551 C C . THR 191 191 ? A 2.15469 18.90352 -2.16172 1 1 A THR 1 1
ATOM 1552 O O . THR 191 191 ? A 3.03715 18.49138 -2.91320 1 1 A THR 1 1
ATOM 1553 C CB . THR 191 191 ? A 3.46954 20.15735 -0.48156 1 1 A THR 1 1
ATOM 1554 O OG1 . THR 191 191 ? A 3.16932 19.22174 0.55202 1 1 A THR 1 1
ATOM 1555 C CG2 . THR 191 191 ? A 3.75364 21.51701 0.13572 1 1 A THR 1 1
ATOM 1556 N N . PHE 192 192 ? A 1.03745 18.21513 -1.93241 1 1 A PHE 1 1
ATOM 1557 C CA . PHE 192 192 ? A 0.82003 16.90077 -2.52946 1 1 A PHE 1 1
ATOM 1558 C C . PHE 192 192 ? A 0.35439 17.01044 -3.96127 1 1 A PHE 1 1
ATOM 1559 O O . PHE 192 192 ? A -0.40977 17.91250 -4.30087 1 1 A PHE 1 1
ATOM 1560 C CB . PHE 192 192 ? A -0.19821 16.09814 -1.72671 1 1 A PHE 1 1
ATOM 1561 C CG . PHE 192 192 ? A 0.29508 15.69570 -0.37469 1 1 A PHE 1 1
ATOM 1562 C CD1 . PHE 192 192 ? A 1.51916 15.08469 -0.22674 1 1 A PHE 1 1
ATOM 1563 C CD2 . PHE 192 192 ? A -0.47207 15.93611 0.75779 1 1 A PHE 1 1
ATOM 1564 C CE1 . PHE 192 192 ? A 1.98240 14.72434 1.02354 1 1 A PHE 1 1
ATOM 1565 C CE2 . PHE 192 192 ? A -0.01528 15.57258 2.01182 1 1 A PHE 1 1
ATOM 1566 C CZ . PHE 192 192 ? A 1.21196 14.95967 2.13386 1 1 A PHE 1 1
ATOM 1567 N N . PRO 193 193 ? A 0.78560 16.06429 -4.82383 1 1 A PRO 1 1
ATOM 1568 C CA . PRO 193 193 ? A 0.39044 16.10119 -6.23280 1 1 A PRO 1 1
ATOM 1569 C C . PRO 193 193 ? A -1.11269 15.88009 -6.39500 1 1 A PRO 1 1
ATOM 1570 O O . PRO 193 193 ? A -1.66025 14.91723 -5.86022 1 1 A PRO 1 1
ATOM 1571 C CB . PRO 193 193 ? A 1.16324 14.94654 -6.85455 1 1 A PRO 1 1
ATOM 1572 C CG . PRO 193 193 ? A 2.28178 14.68041 -5.89343 1 1 A PRO 1 1
ATOM 1573 C CD . PRO 193 193 ? A 1.71728 14.98803 -4.54124 1 1 A PRO 1 1
ATOM 1574 N N . LYS 194 194 ? A -1.72906 16.75458 -7.16249 1 1 A LYS 1 1
ATOM 1575 C CA . LYS 194 194 ? A -3.15005 16.64094 -7.42002 1 1 A LYS 1 1
ATOM 1576 C C . LYS 194 194 ? A -3.35995 16.15321 -8.82766 1 1 A LYS 1 1
ATOM 1577 O O . LYS 194 194 ? A -2.91630 16.80152 -9.76694 1 1 A LYS 1 1
ATOM 1578 C CB . LYS 194 194 ? A -3.83741 17.97088 -7.19352 1 1 A LYS 1 1
ATOM 1579 C CG . LYS 194 194 ? A -3.61591 18.46386 -5.77057 1 1 A LYS 1 1
ATOM 1580 C CD . LYS 194 194 ? A -3.92698 19.91567 -5.57283 1 1 A LYS 1 1
ATOM 1581 C CE . LYS 194 194 ? A -3.37993 20.42106 -4.26203 1 1 A LYS 1 1
ATOM 1582 N NZ . LYS 194 194 ? A -1.92821 20.61314 -4.32968 1 1 A LYS 1 1
ATOM 1583 N N . ILE 195 195 ? A -4.02595 15.01321 -8.97683 1 1 A ILE 1 1
ATOM 1584 C CA . ILE 195 195 ? A -4.21383 14.42766 -10.29251 1 1 A ILE 1 1
ATOM 1585 C C . ILE 195 195 ? A -5.50086 14.88384 -10.96230 1 1 A ILE 1 1
ATOM 1586 O O . ILE 195 195 ? A -5.64677 14.73107 -12.16712 1 1 A ILE 1 1
ATOM 1587 C CB . ILE 195 195 ? A -4.15778 12.89397 -10.23888 1 1 A ILE 1 1
ATOM 1588 C CG1 . ILE 195 195 ? A -5.29812 12.32084 -9.38718 1 1 A ILE 1 1
ATOM 1589 C CG2 . ILE 195 195 ? A -2.80328 12.44636 -9.69480 1 1 A ILE 1 1
ATOM 1590 C CD1 . ILE 195 195 ? A -5.38190 10.81553 -9.43764 1 1 A ILE 1 1
ATOM 1591 N N . SER 196 196 ? A -6.41483 15.43316 -10.18083 1 1 A SER 1 1
ATOM 1592 C CA . SER 196 196 ? A -7.64712 15.95490 -10.71931 1 1 A SER 1 1
ATOM 1593 C C . SER 196 196 ? A -8.35553 16.79102 -9.66028 1 1 A SER 1 1
ATOM 1594 O O . SER 196 196 ? A -7.85913 16.92365 -8.53760 1 1 A SER 1 1
ATOM 1595 C CB . SER 196 196 ? A -8.55513 14.83315 -11.21769 1 1 A SER 1 1
ATOM 1596 O OG . SER 196 196 ? A -8.93924 13.97914 -10.17019 1 1 A SER 1 1
ATOM 1597 N N . ILE 197 197 ? A -9.47349 17.38988 -10.01027 1 1 A ILE 1 1
ATOM 1598 C CA . ILE 197 197 ? A -10.30949 18.13757 -9.09883 1 1 A ILE 1 1
ATOM 1599 C C . ILE 197 197 ? A -11.70712 17.53971 -9.11649 1 1 A ILE 1 1
ATOM 1600 O O . ILE 197 197 ? A -12.26687 17.31529 -10.18916 1 1 A ILE 1 1
ATOM 1601 C CB . ILE 197 197 ? A -10.35889 19.62400 -9.47711 1 1 A ILE 1 1
ATOM 1602 C CG1 . ILE 197 197 ? A -8.98641 20.26241 -9.29185 1 1 A ILE 1 1
ATOM 1603 C CG2 . ILE 197 197 ? A -11.40172 20.35878 -8.64568 1 1 A ILE 1 1
ATOM 1604 C CD1 . ILE 197 197 ? A -8.87869 21.66866 -9.82503 1 1 A ILE 1 1
ATOM 1605 N N . SER 198 198 ? A -12.24991 17.28198 -7.94818 1 1 A SER 1 1
ATOM 1606 C CA . SER 198 198 ? A -13.60631 16.80846 -7.81478 1 1 A SER 1 1
ATOM 1607 C C . SER 198 198 ? A -14.46734 17.95155 -7.30174 1 1 A SER 1 1
ATOM 1608 O O . SER 198 198 ? A -13.99839 18.77882 -6.50847 1 1 A SER 1 1
ATOM 1609 C CB . SER 198 198 ? A -13.65593 15.63463 -6.83552 1 1 A SER 1 1
ATOM 1610 O OG . SER 198 198 ? A -14.97670 15.23748 -6.57699 1 1 A SER 1 1
ATOM 1611 N N . TYR 199 199 ? A -15.71600 18.04166 -7.75590 1 1 A TYR 1 1
ATOM 1612 C CA . TYR 199 199 ? A -16.58789 19.06020 -7.21530 1 1 A TYR 1 1
ATOM 1613 C C . TYR 199 199 ? A -18.00316 18.53596 -7.10319 1 1 A TYR 1 1
ATOM 1614 O O . TYR 199 199 ? A -18.39318 17.59687 -7.79142 1 1 A TYR 1 1
ATOM 1615 C CB . TYR 199 199 ? A -16.53215 20.34274 -8.04528 1 1 A TYR 1 1
ATOM 1616 C CG . TYR 199 199 ? A -17.15135 20.28236 -9.41422 1 1 A TYR 1 1
ATOM 1617 C CD1 . TYR 199 199 ? A -16.43350 19.78369 -10.49643 1 1 A TYR 1 1
ATOM 1618 C CD2 . TYR 199 199 ? A -18.45668 20.71995 -9.62971 1 1 A TYR 1 1
ATOM 1619 C CE1 . TYR 199 199 ? A -17.00066 19.73126 -11.76686 1 1 A TYR 1 1
ATOM 1620 C CE2 . TYR 199 199 ? A -19.02742 20.66729 -10.90085 1 1 A TYR 1 1
ATOM 1621 C CZ . TYR 199 199 ? A -18.29927 20.17443 -11.96198 1 1 A TYR 1 1
ATOM 1622 O OH . TYR 199 199 ? A -18.86182 20.13195 -13.21154 1 1 A TYR 1 1
ATOM 1623 N N . ILE 200 200 ? A -18.75758 19.16144 -6.23812 1 1 A ILE 1 1
ATOM 1624 C CA . ILE 200 200 ? A -20.14647 18.79365 -6.02823 1 1 A ILE 1 1
ATOM 1625 C C . ILE 200 200 ? A -20.96835 20.05836 -5.86797 1 1 A ILE 1 1
ATOM 1626 O O . ILE 200 200 ? A -20.55307 20.99564 -5.18112 1 1 A ILE 1 1
ATOM 1627 C CB . ILE 200 200 ? A -20.31749 17.86542 -4.81257 1 1 A ILE 1 1
ATOM 1628 C CG1 . ILE 200 200 ? A -21.75747 17.44212 -4.64247 1 1 A ILE 1 1
ATOM 1629 C CG2 . ILE 200 200 ? A -19.78133 18.51056 -3.52461 1 1 A ILE 1 1
ATOM 1630 C CD1 . ILE 200 200 ? A -21.97258 16.37897 -3.57989 1 1 A ILE 1 1
ATOM 1631 N N . LYS 201 201 ? A -22.10392 20.12417 -6.49236 1 1 A LYS 1 1
ATOM 1632 C CA . LYS 201 201 ? A -23.03974 21.20650 -6.30805 1 1 A LYS 1 1
ATOM 1633 C C . LYS 201 201 ? A -23.80826 20.95020 -5.01688 1 1 A LYS 1 1
ATOM 1634 O O . LYS 201 201 ? A -24.28381 19.83810 -4.78408 1 1 A LYS 1 1
ATOM 1635 C CB . LYS 201 201 ? A -23.99866 21.28831 -7.49603 1 1 A LYS 1 1
ATOM 1636 C CG . LYS 201 201 ? A -24.91988 22.48360 -7.47799 1 1 A LYS 1 1
ATOM 1637 C CD . LYS 201 201 ? A -25.75329 22.55776 -8.74419 1 1 A LYS 1 1
ATOM 1638 C CE . LYS 201 201 ? A -26.62753 23.79581 -8.77039 1 1 A LYS 1 1
ATOM 1639 N NZ . LYS 201 201 ? A -27.46459 23.84256 -10.00392 1 1 A LYS 1 1
ATOM 1640 N N . LEU 202 202 ? A -23.91627 21.94812 -4.16227 1 1 A LEU 1 1
ATOM 1641 C CA . LEU 202 202 ? A -24.57603 21.79686 -2.87674 1 1 A LEU 1 1
ATOM 1642 C C . LEU 202 202 ? A -25.97778 21.24969 -3.06492 1 1 A LEU 1 1
ATOM 1643 O O . LEU 202 202 ? A -26.72688 21.72362 -3.92315 1 1 A LEU 1 1
ATOM 1644 C CB . LEU 202 202 ? A -24.61469 23.14102 -2.15043 1 1 A LEU 1 1
ATOM 1645 C CG . LEU 202 202 ? A -25.05577 23.09846 -0.69819 1 1 A LEU 1 1
ATOM 1646 C CD1 . LEU 202 202 ? A -24.08171 22.27992 0.13257 1 1 A LEU 1 1
ATOM 1647 C CD2 . LEU 202 202 ? A -25.15444 24.50082 -0.12596 1 1 A LEU 1 1
ATOM 1648 N N . GLY 203 203 ? A -26.32729 20.24686 -2.25727 1 1 A GLY 1 1
ATOM 1649 C CA . GLY 203 203 ? A -27.62195 19.59864 -2.35671 1 1 A GLY 1 1
ATOM 1650 C C . GLY 203 203 ? A -27.64224 18.36608 -3.24111 1 1 A GLY 1 1
ATOM 1651 O O . GLY 203 203 ? A -28.60060 17.60129 -3.20339 1 1 A GLY 1 1
ATOM 1652 N N . GLU 204 204 ? A -26.59659 18.17782 -4.02114 1 1 A GLU 1 1
ATOM 1653 C CA . GLU 204 204 ? A -26.50229 17.00032 -4.87514 1 1 A GLU 1 1
ATOM 1654 C C . GLU 204 204 ? A -25.55427 15.99084 -4.26501 1 1 A GLU 1 1
ATOM 1655 O O . GLU 204 204 ? A -24.67119 16.34014 -3.47694 1 1 A GLU 1 1
ATOM 1656 C CB . GLU 204 204 ? A -26.04575 17.38795 -6.27648 1 1 A GLU 1 1
ATOM 1657 C CG . GLU 204 204 ? A -27.06620 18.23707 -7.01893 1 1 A GLU 1 1
ATOM 1658 C CD . GLU 204 204 ? A -26.68607 18.49753 -8.46325 1 1 A GLU 1 1
ATOM 1659 O OE1 . GLU 204 204 ? A -25.70715 17.90138 -8.95024 1 1 A GLU 1 1
ATOM 1660 O OE2 . GLU 204 204 ? A -27.37762 19.30268 -9.11706 1 1 A GLU 1 1
ATOM 1661 N N . LEU 205 205 ? A -25.70886 14.74723 -4.58880 1 1 A LEU 1 1
ATOM 1662 C CA . LEU 205 205 ? A -24.87563 13.68194 -4.06097 1 1 A LEU 1 1
ATOM 1663 C C . LEU 205 205 ? A -23.74596 13.31805 -5.01392 1 1 A LEU 1 1
ATOM 1664 O O . LEU 205 205 ? A -22.63805 13.02559 -4.57618 1 1 A LEU 1 1
ATOM 1665 C CB . LEU 205 205 ? A -25.73839 12.44254 -3.77334 1 1 A LEU 1 1
ATOM 1666 C CG . LEU 205 205 ? A -25.04396 11.26149 -3.11207 1 1 A LEU 1 1
ATOM 1667 C CD1 . LEU 205 205 ? A -24.48158 11.66098 -1.74461 1 1 A LEU 1 1
ATOM 1668 C CD2 . LEU 205 205 ? A -26.01758 10.09675 -2.97952 1 1 A LEU 1 1
ATOM 1669 N N . LYS 206 206 ? A -23.98206 13.38226 -6.27905 1 1 A LYS 1 1
ATOM 1670 C CA . LYS 206 206 ? A -23.04374 12.91510 -7.27241 1 1 A LYS 1 1
ATOM 1671 C C . LYS 206 206 ? A -21.92445 13.92356 -7.51942 1 1 A LYS 1 1
ATOM 1672 O O . LYS 206 206 ? A -22.17481 15.11085 -7.69294 1 1 A LYS 1 1
ATOM 1673 C CB . LYS 206 206 ? A -23.77620 12.62427 -8.57422 1 1 A LYS 1 1
ATOM 1674 C CG . LYS 206 206 ? A -23.04277 11.73805 -9.53558 1 1 A LYS 1 1
ATOM 1675 C CD . LYS 206 206 ? A -23.98149 11.15696 -10.57456 1 1 A LYS 1 1
ATOM 1676 C CE . LYS 206 206 ? A -23.27034 10.15324 -11.44631 1 1 A LYS 1 1
ATOM 1677 N NZ . LYS 206 206 ? A -24.23582 9.32529 -12.21634 1 1 A LYS 1 1
ATOM 1678 N N . ARG 207 207 ? A -20.65180 13.43939 -7.53338 1 1 A ARG 1 1
ATOM 1679 C CA . ARG 207 207 ? A -19.47366 14.25677 -7.78480 1 1 A ARG 1 1
ATOM 1680 C C . ARG 207 207 ? A -19.18376 14.35379 -9.26563 1 1 A ARG 1 1
ATOM 1681 O O . ARG 207 207 ? A -19.47336 13.42982 -10.02481 1 1 A ARG 1 1
ATOM 1682 C CB . ARG 207 207 ? A -18.25332 13.66898 -7.09116 1 1 A ARG 1 1
ATOM 1683 C CG . ARG 207 207 ? A -17.99839 14.17465 -5.69487 1 1 A ARG 1 1
ATOM 1684 C CD . ARG 207 207 ? A -18.95577 13.63878 -4.67827 1 1 A ARG 1 1
ATOM 1685 N NE . ARG 207 207 ? A -18.55125 13.92027 -3.33262 1 1 A ARG 1 1
ATOM 1686 C CZ . ARG 207 207 ? A -19.32693 13.93705 -2.30810 1 1 A ARG 1 1
ATOM 1687 N NH1 . ARG 207 207 ? A -20.60520 13.66685 -2.43021 1 1 A ARG 1 1
ATOM 1688 N NH2 . ARG 207 207 ? A -18.82771 14.21408 -1.10623 1 1 A ARG 1 1
ATOM 1689 N N . GLN 208 208 ? A -18.58671 15.44349 -9.64009 1 1 A GLN 1 1
ATOM 1690 C CA . GLN 208 208 ? A -18.02781 15.63363 -10.96359 1 1 A GLN 1 1
ATOM 1691 C C . GLN 208 208 ? A -16.50584 15.62144 -10.83414 1 1 A GLN 1 1
ATOM 1692 O O . GLN 208 208 ? A -15.97562 16.12319 -9.84324 1 1 A GLN 1 1
ATOM 1693 C CB . GLN 208 208 ? A -18.47488 16.95158 -11.57958 1 1 A GLN 1 1
ATOM 1694 C CG . GLN 208 208 ? A -19.96817 17.24058 -11.47294 1 1 A GLN 1 1
ATOM 1695 C CD . GLN 208 208 ? A -20.79139 16.32585 -12.34837 1 1 A GLN 1 1
ATOM 1696 O OE1 . GLN 208 208 ? A -20.35971 15.92583 -13.42765 1 1 A GLN 1 1
ATOM 1697 N NE2 . GLN 208 208 ? A -21.99257 15.99241 -11.89627 1 1 A GLN 1 1
ATOM 1698 N N . VAL 209 209 ? A -15.79685 15.06860 -11.80309 1 1 A VAL 1 1
ATOM 1699 C CA . VAL 209 209 ? A -14.34796 14.97768 -11.74720 1 1 A VAL 1 1
ATOM 1700 C C . VAL 209 209 ? A -13.75268 15.63478 -12.98167 1 1 A VAL 1 1
ATOM 1701 O O . VAL 209 209 ? A -14.16421 15.32985 -14.10580 1 1 A VAL 1 1
ATOM 1702 C CB . VAL 209 209 ? A -13.87281 13.51616 -11.64827 1 1 A VAL 1 1
ATOM 1703 C CG1 . VAL 209 209 ? A -12.36354 13.45627 -11.58289 1 1 A VAL 1 1
ATOM 1704 C CG2 . VAL 209 209 ? A -14.48456 12.84384 -10.41503 1 1 A VAL 1 1
ATOM 1705 N N . VAL 210 210 ? A -12.78731 16.52541 -12.77364 1 1 A VAL 1 1
ATOM 1706 C CA . VAL 210 210 ? A -12.11725 17.22957 -13.85260 1 1 A VAL 1 1
ATOM 1707 C C . VAL 210 210 ? A -10.66190 16.79387 -13.88665 1 1 A VAL 1 1
ATOM 1708 O O . VAL 210 210 ? A -9.96401 16.89392 -12.87127 1 1 A VAL 1 1
ATOM 1709 C CB . VAL 210 210 ? A -12.18793 18.76259 -13.64504 1 1 A VAL 1 1
ATOM 1710 C CG1 . VAL 210 210 ? A -11.59616 19.49345 -14.82562 1 1 A VAL 1 1
ATOM 1711 C CG2 . VAL 210 210 ? A -13.61144 19.20160 -13.38259 1 1 A VAL 1 1
ATOM 1712 N N . ASP 211 211 ? A -10.17963 16.36317 -15.02747 1 1 A ASP 1 1
ATOM 1713 C CA . ASP 211 211 ? A -8.80256 15.92482 -15.15593 1 1 A ASP 1 1
ATOM 1714 C C . ASP 211 211 ? A -8.27528 16.33064 -16.52453 1 1 A ASP 1 1
ATOM 1715 O O . ASP 211 211 ? A -8.64776 15.73159 -17.53373 1 1 A ASP 1 1
ATOM 1716 C CB . ASP 211 211 ? A -8.72206 14.40429 -14.99398 1 1 A ASP 1 1
ATOM 1717 C CG . ASP 211 211 ? A -7.29224 13.85878 -14.97563 1 1 A ASP 1 1
ATOM 1718 O OD1 . ASP 211 211 ? A -6.34028 14.61930 -15.18326 1 1 A ASP 1 1
ATOM 1719 O OD2 . ASP 211 211 ? A -7.14601 12.64771 -14.74100 1 1 A ASP 1 1
ATOM 1720 N N . PHE 212 212 ? A -7.42910 17.36356 -16.56664 1 1 A PHE 1 1
ATOM 1721 C CA . PHE 212 212 ? A -6.86842 17.86234 -17.81441 1 1 A PHE 1 1
ATOM 1722 C C . PHE 212 212 ? A -5.42591 17.37184 -18.02124 1 1 A PHE 1 1
ATOM 1723 O O . PHE 212 212 ? A -4.49578 18.15641 -18.11710 1 1 A PHE 1 1
ATOM 1724 C CB . PHE 212 212 ? A -6.89433 19.40121 -17.83037 1 1 A PHE 1 1
ATOM 1725 C CG . PHE 212 212 ? A -8.26901 20.02141 -17.85740 1 1 A PHE 1 1
ATOM 1726 C CD1 . PHE 212 212 ? A -9.40292 19.24943 -18.07273 1 1 A PHE 1 1
ATOM 1727 C CD2 . PHE 212 212 ? A -8.41733 21.39796 -17.67302 1 1 A PHE 1 1
ATOM 1728 C CE1 . PHE 212 212 ? A -10.66351 19.82251 -18.10641 1 1 A PHE 1 1
ATOM 1729 C CE2 . PHE 212 212 ? A -9.67252 21.98163 -17.70773 1 1 A PHE 1 1
ATOM 1730 C CZ . PHE 212 212 ? A -10.79672 21.19696 -17.91813 1 1 A PHE 1 1
ATOM 1731 N N . PHE 213 213 ? A -5.23576 16.07313 -18.05594 1 1 A PHE 1 1
ATOM 1732 C CA . PHE 213 213 ? A -3.90937 15.49308 -18.22180 1 1 A PHE 1 1
ATOM 1733 C C . PHE 213 213 ? A -3.66507 15.13260 -19.67543 1 1 A PHE 1 1
ATOM 1734 O O . PHE 213 213 ? A -4.57278 15.10403 -20.49024 1 1 A PHE 1 1
ATOM 1735 C CB . PHE 213 213 ? A -3.75059 14.25121 -17.33549 1 1 A PHE 1 1
ATOM 1736 C CG . PHE 213 213 ? A -4.51001 13.06092 -17.82135 1 1 A PHE 1 1
ATOM 1737 C CD1 . PHE 213 213 ? A -5.85142 12.89428 -17.49674 1 1 A PHE 1 1
ATOM 1738 C CD2 . PHE 213 213 ? A -3.88682 12.09912 -18.62377 1 1 A PHE 1 1
ATOM 1739 C CE1 . PHE 213 213 ? A -6.57233 11.80425 -17.95837 1 1 A PHE 1 1
ATOM 1740 C CE2 . PHE 213 213 ? A -4.60673 10.99651 -19.09073 1 1 A PHE 1 1
ATOM 1741 C CZ . PHE 213 213 ? A -5.94220 10.85004 -18.75508 1 1 A PHE 1 1
ATOM 1742 N N . LYS 214 214 ? A -2.38865 14.85204 -20.03142 1 1 A LYS 1 1
ATOM 1743 C CA . LYS 214 214 ? A -1.95554 14.41486 -21.34972 1 1 A LYS 1 1
ATOM 1744 C C . LYS 214 214 ? A -1.15731 13.12284 -21.21818 1 1 A LYS 1 1
ATOM 1745 O O . LYS 214 214 ? A -0.43468 12.93686 -20.23632 1 1 A LYS 1 1
ATOM 1746 C CB . LYS 214 214 ? A -1.08426 15.47337 -22.02905 1 1 A LYS 1 1
ATOM 1747 C CG . LYS 214 214 ? A -1.64164 16.89655 -22.00669 1 1 A LYS 1 1
ATOM 1748 C CD . LYS 214 214 ? A -2.81444 17.06946 -22.93118 1 1 A LYS 1 1
ATOM 1749 C CE . LYS 214 214 ? A -3.18338 18.53018 -23.14705 1 1 A LYS 1 1
ATOM 1750 N NZ . LYS 214 214 ? A -3.75486 19.17037 -21.95290 1 1 A LYS 1 1
ATOM 1751 N N . PHE 215 215 ? A -1.24580 12.22257 -22.18293 1 1 A PHE 1 1
ATOM 1752 C CA . PHE 215 215 ? A -0.40195 11.04552 -22.25050 1 1 A PHE 1 1
ATOM 1753 C C . PHE 215 215 ? A 0.86285 11.36132 -23.03219 1 1 A PHE 1 1
ATOM 1754 O O . PHE 215 215 ? A 0.82964 12.14593 -23.97199 1 1 A PHE 1 1
ATOM 1755 C CB . PHE 215 215 ? A -1.11853 9.88142 -22.92803 1 1 A PHE 1 1
ATOM 1756 C CG . PHE 215 215 ? A -2.16418 9.22730 -22.07827 1 1 A PHE 1 1
ATOM 1757 C CD1 . PHE 215 215 ? A -1.80394 8.39842 -21.02740 1 1 A PHE 1 1
ATOM 1758 C CD2 . PHE 215 215 ? A -3.51216 9.42891 -22.33869 1 1 A PHE 1 1
ATOM 1759 C CE1 . PHE 215 215 ? A -2.77062 7.78408 -20.24967 1 1 A PHE 1 1
ATOM 1760 C CE2 . PHE 215 215 ? A -4.48390 8.81814 -21.56431 1 1 A PHE 1 1
ATOM 1761 C CZ . PHE 215 215 ? A -4.10854 7.99493 -20.51606 1 1 A PHE 1 1
ATOM 1762 N N . SER 216 216 ? A 1.98588 10.77360 -22.61565 1 1 A SER 1 1
ATOM 1763 C CA . SER 216 216 ? A 3.22757 10.86283 -23.36381 1 1 A SER 1 1
ATOM 1764 C C . SER 216 216 ? A 4.12558 9.69157 -22.99934 1 1 A SER 1 1
ATOM 1765 O O . SER 216 216 ? A 3.77623 8.87231 -22.13569 1 1 A SER 1 1
ATOM 1766 C CB . SER 216 216 ? A 3.93998 12.19638 -23.10696 1 1 A SER 1 1
ATOM 1767 O OG . SER 216 216 ? A 4.37375 12.28962 -21.76714 1 1 A SER 1 1
ATOM 1768 N N . PHE 217 217 ? A 5.26489 9.57595 -23.65648 1 1 A PHE 1 1
ATOM 1769 C CA . PHE 217 217 ? A 6.23540 8.53150 -23.41308 1 1 A PHE 1 1
ATOM 1770 C C . PHE 217 217 ? A 7.55203 9.15322 -22.97461 1 1 A PHE 1 1
ATOM 1771 O O . PHE 217 217 ? A 7.98431 10.15968 -23.53303 1 1 A PHE 1 1
ATOM 1772 C CB . PHE 217 217 ? A 6.44500 7.67354 -24.65963 1 1 A PHE 1 1
ATOM 1773 C CG . PHE 217 217 ? A 5.24790 6.83277 -25.00574 1 1 A PHE 1 1
ATOM 1774 C CD1 . PHE 217 217 ? A 5.11115 5.56472 -24.45299 1 1 A PHE 1 1
ATOM 1775 C CD2 . PHE 217 217 ? A 4.26417 7.30699 -25.84854 1 1 A PHE 1 1
ATOM 1776 C CE1 . PHE 217 217 ? A 4.00306 4.78556 -24.75542 1 1 A PHE 1 1
ATOM 1777 C CE2 . PHE 217 217 ? A 3.16437 6.53875 -26.15169 1 1 A PHE 1 1
ATOM 1778 C CZ . PHE 217 217 ? A 3.03263 5.27746 -25.60515 1 1 A PHE 1 1
ATOM 1779 N N . MET 218 218 ? A 8.18890 8.53682 -21.98314 1 1 A MET 1 1
ATOM 1780 C CA . MET 218 218 ? A 9.46717 9.01632 -21.49146 1 1 A MET 1 1
ATOM 1781 C C . MET 218 218 ? A 10.44393 7.86522 -21.38077 1 1 A MET 1 1
ATOM 1782 O O . MET 218 218 ? A 10.05571 6.73840 -21.08742 1 1 A MET 1 1
ATOM 1783 C CB . MET 218 218 ? A 9.30063 9.68446 -20.13066 1 1 A MET 1 1
ATOM 1784 C CG . MET 218 218 ? A 8.46769 10.96469 -20.19644 1 1 A MET 1 1
ATOM 1785 S SD . MET 218 218 ? A 8.32685 11.77469 -18.59765 1 1 A MET 1 1
ATOM 1786 C CE . MET 218 218 ? A 7.54003 13.33774 -19.06372 1 1 A MET 1 1
ATOM 1787 N N . TYR 219 219 ? A 11.72255 8.17500 -21.59270 1 1 A TYR 1 1
ATOM 1788 C CA . TYR 219 219 ? A 12.76184 7.15809 -21.51729 1 1 A TYR 1 1
ATOM 1789 C C . TYR 219 219 ? A 13.24621 7.04591 -20.07834 1 1 A TYR 1 1
ATOM 1790 O O . TYR 219 219 ? A 13.57955 8.05054 -19.44109 1 1 A TYR 1 1
ATOM 1791 C CB . TYR 219 219 ? A 13.93541 7.52768 -22.42656 1 1 A TYR 1 1
ATOM 1792 C CG . TYR 219 219 ? A 14.99337 6.45375 -22.51405 1 1 A TYR 1 1
ATOM 1793 C CD1 . TYR 219 219 ? A 14.86676 5.41313 -23.42837 1 1 A TYR 1 1
ATOM 1794 C CD2 . TYR 219 219 ? A 16.09958 6.47878 -21.66749 1 1 A TYR 1 1
ATOM 1795 C CE1 . TYR 219 219 ? A 15.82489 4.42127 -23.52294 1 1 A TYR 1 1
ATOM 1796 C CE2 . TYR 219 219 ? A 17.06382 5.48799 -21.73993 1 1 A TYR 1 1
ATOM 1797 C CZ . TYR 219 219 ? A 16.91999 4.46451 -22.67746 1 1 A TYR 1 1
ATOM 1798 O OH . TYR 219 219 ? A 17.88471 3.47820 -22.75770 1 1 A TYR 1 1
ATOM 1799 N N . ILE 220 220 ? A 13.27828 5.81387 -19.56754 1 1 A ILE 1 1
ATOM 1800 C CA . ILE 220 220 ? A 13.66373 5.56705 -18.18524 1 1 A ILE 1 1
ATOM 1801 C C . ILE 220 220 ? A 15.15623 5.28166 -18.09531 1 1 A ILE 1 1
ATOM 1802 O O . ILE 220 220 ? A 15.65219 4.35460 -18.72329 1 1 A ILE 1 1
ATOM 1803 C CB . ILE 220 220 ? A 12.87632 4.39128 -17.58755 1 1 A ILE 1 1
ATOM 1804 C CG1 . ILE 220 220 ? A 11.38380 4.66544 -17.66157 1 1 A ILE 1 1
ATOM 1805 C CG2 . ILE 220 220 ? A 13.31469 4.12324 -16.14992 1 1 A ILE 1 1
ATOM 1806 C CD1 . ILE 220 220 ? A 10.94993 5.96612 -17.04518 1 1 A ILE 1 1
ATOM 1807 N N . GLU 221 221 ? A 15.83405 6.08887 -17.28256 1 1 A GLU 1 1
ATOM 1808 C CA . GLU 221 221 ? A 17.25393 5.87386 -17.07231 1 1 A GLU 1 1
ATOM 1809 C C . GLU 221 221 ? A 17.52276 4.95339 -15.88593 1 1 A GLU 1 1
ATOM 1810 O O . GLU 221 221 ? A 18.39943 4.09218 -15.96770 1 1 A GLU 1 1
ATOM 1811 C CB . GLU 221 221 ? A 17.96836 7.21416 -16.86749 1 1 A GLU 1 1
ATOM 1812 C CG . GLU 221 221 ? A 19.47502 7.11168 -16.78544 1 1 A GLU 1 1
ATOM 1813 C CD . GLU 221 221 ? A 20.08389 6.65900 -18.11939 1 1 A GLU 1 1
ATOM 1814 O OE1 . GLU 221 221 ? A 19.62679 7.14626 -19.17089 1 1 A GLU 1 1
ATOM 1815 O OE2 . GLU 221 221 ? A 21.00015 5.82013 -18.10063 1 1 A GLU 1 1
ATOM 1816 N N . SER 222 222 ? A 16.76520 5.11464 -14.78872 1 1 A SER 1 1
ATOM 1817 C CA . SER 222 222 ? A 17.01409 4.26850 -13.62948 1 1 A SER 1 1
ATOM 1818 C C . SER 222 222 ? A 15.79518 4.19662 -12.71860 1 1 A SER 1 1
ATOM 1819 O O . SER 222 222 ? A 14.89015 5.03592 -12.80746 1 1 A SER 1 1
ATOM 1820 C CB . SER 222 222 ? A 18.20740 4.78081 -12.82830 1 1 A SER 1 1
ATOM 1821 O OG . SER 222 222 ? A 17.93369 6.05864 -12.27392 1 1 A SER 1 1
ATOM 1822 N N . ILE 223 223 ? A 15.80797 3.19312 -11.85891 1 1 A ILE 1 1
ATOM 1823 C CA . ILE 223 223 ? A 14.83111 3.02127 -10.80157 1 1 A ILE 1 1
ATOM 1824 C C . ILE 223 223 ? A 15.56516 3.16293 -9.47909 1 1 A ILE 1 1
ATOM 1825 O O . ILE 223 223 ? A 16.59609 2.51825 -9.27288 1 1 A ILE 1 1
ATOM 1826 C CB . ILE 223 223 ? A 14.13888 1.64962 -10.88894 1 1 A ILE 1 1
ATOM 1827 C CG1 . ILE 223 223 ? A 13.45399 1.49267 -12.24598 1 1 A ILE 1 1
ATOM 1828 C CG2 . ILE 223 223 ? A 13.14607 1.47608 -9.74371 1 1 A ILE 1 1
ATOM 1829 C CD1 . ILE 223 223 ? A 12.93194 0.08869 -12.51119 1 1 A ILE 1 1
ATOM 1830 N N . LYS 224 224 ? A 15.02888 3.98633 -8.58592 1 1 A LYS 1 1
ATOM 1831 C CA . LYS 224 224 ? A 15.65378 4.22741 -7.29175 1 1 A LYS 1 1
ATOM 1832 C C . LYS 224 224 ? A 14.61555 4.17361 -6.18863 1 1 A LYS 1 1
ATOM 1833 O O . LYS 224 224 ? A 13.45006 3.88886 -6.44174 1 1 A LYS 1 1
ATOM 1834 C CB . LYS 224 224 ? A 16.33921 5.58558 -7.29049 1 1 A LYS 1 1
ATOM 1835 C CG . LYS 224 224 ? A 17.48877 5.66438 -8.27694 1 1 A LYS 1 1
ATOM 1836 C CD . LYS 224 224 ? A 18.20755 6.98245 -8.19582 1 1 A LYS 1 1
ATOM 1837 C CE . LYS 224 224 ? A 19.35595 7.04254 -9.18068 1 1 A LYS 1 1
ATOM 1838 N NZ . LYS 224 224 ? A 20.07380 8.32284 -9.11729 1 1 A LYS 1 1
ATOM 1839 N N . VAL 225 225 ? A 15.01785 4.39672 -4.94664 1 1 A VAL 1 1
ATOM 1840 C CA . VAL 225 225 ? A 14.10115 4.48516 -3.83590 1 1 A VAL 1 1
ATOM 1841 C C . VAL 225 225 ? A 14.32885 5.77990 -3.07938 1 1 A VAL 1 1
ATOM 1842 O O . VAL 225 225 ? A 15.45166 6.27468 -3.01500 1 1 A VAL 1 1
ATOM 1843 C CB . VAL 225 225 ? A 14.21466 3.29062 -2.86195 1 1 A VAL 1 1
ATOM 1844 C CG1 . VAL 225 225 ? A 13.78193 2.01121 -3.54496 1 1 A VAL 1 1
ATOM 1845 C CG2 . VAL 225 225 ? A 15.62564 3.15813 -2.31973 1 1 A VAL 1 1
ATOM 1846 N N . ASP 226 226 ? A 13.27039 6.34873 -2.49143 1 1 A ASP 1 1
ATOM 1847 C CA . ASP 226 226 ? A 13.31544 7.57977 -1.72840 1 1 A ASP 1 1
ATOM 1848 C C . ASP 226 226 ? A 12.80299 7.32637 -0.32279 1 1 A ASP 1 1
ATOM 1849 O O . ASP 226 226 ? A 11.75469 6.70826 -0.15148 1 1 A ASP 1 1
ATOM 1850 C CB . ASP 226 226 ? A 12.46286 8.67573 -2.38048 1 1 A ASP 1 1
ATOM 1851 C CG . ASP 226 226 ? A 12.91912 9.04402 -3.76685 1 1 A ASP 1 1
ATOM 1852 O OD1 . ASP 226 226 ? A 14.14016 9.15493 -3.96937 1 1 A ASP 1 1
ATOM 1853 O OD2 . ASP 226 226 ? A 12.06222 9.21203 -4.65107 1 1 A ASP 1 1
ATOM 1854 N N . ARG 227 227 ? A 13.51876 7.78896 0.68774 1 1 A ARG 1 1
ATOM 1855 C CA . ARG 227 227 ? A 13.12017 7.63377 2.06194 1 1 A ARG 1 1
ATOM 1856 C C . ARG 227 227 ? A 11.90064 8.49556 2.35401 1 1 A ARG 1 1
ATOM 1857 O O . ARG 227 227 ? A 11.89353 9.67437 2.04723 1 1 A ARG 1 1
ATOM 1858 C CB . ARG 227 227 ? A 14.26267 8.02276 2.99299 1 1 A ARG 1 1
ATOM 1859 C CG . ARG 227 227 ? A 14.05929 7.59743 4.42968 1 1 A ARG 1 1
ATOM 1860 C CD . ARG 227 227 ? A 15.26335 7.93758 5.29515 1 1 A ARG 1 1
ATOM 1861 N NE . ARG 227 227 ? A 15.08221 7.50865 6.64682 1 1 A ARG 1 1
ATOM 1862 C CZ . ARG 227 227 ? A 14.37370 8.14704 7.55292 1 1 A ARG 1 1
ATOM 1863 N NH1 . ARG 227 227 ? A 13.76692 9.26758 7.25913 1 1 A ARG 1 1
ATOM 1864 N NH2 . ARG 227 227 ? A 14.26934 7.64954 8.77888 1 1 A ARG 1 1
ATOM 1865 N N . ILE 228 228 ? A 10.85588 7.88963 2.95071 1 1 A ILE 1 1
ATOM 1866 C CA . ILE 228 228 ? A 9.66138 8.62784 3.31955 1 1 A ILE 1 1
ATOM 1867 C C . ILE 228 228 ? A 9.29397 8.44187 4.78714 1 1 A ILE 1 1
ATOM 1868 O O . ILE 228 228 ? A 8.33333 9.03015 5.24984 1 1 A ILE 1 1
ATOM 1869 C CB . ILE 228 228 ? A 8.44425 8.26494 2.42884 1 1 A ILE 1 1
ATOM 1870 C CG1 . ILE 228 228 ? A 8.05933 6.79594 2.58976 1 1 A ILE 1 1
ATOM 1871 C CG2 . ILE 228 228 ? A 8.72727 8.62040 0.98115 1 1 A ILE 1 1
ATOM 1872 C CD1 . ILE 228 228 ? A 6.75114 6.45274 1.90850 1 1 A ILE 1 1
ATOM 1873 N N . GLY 229 229 ? A 10.04587 7.58734 5.50304 1 1 A GLY 1 1
ATOM 1874 C CA . GLY 229 229 ? A 9.77599 7.37003 6.90864 1 1 A GLY 1 1
ATOM 1875 C C . GLY 229 229 ? A 10.89372 6.56612 7.54148 1 1 A GLY 1 1
ATOM 1876 O O . GLY 229 229 ? A 11.92618 6.28055 6.90840 1 1 A GLY 1 1
ATOM 1877 N N . ASP 230 230 ? A 10.73050 6.16379 8.77903 1 1 A ASP 1 1
ATOM 1878 C CA . ASP 230 230 ? A 11.72157 5.37989 9.50621 1 1 A ASP 1 1
ATOM 1879 C C . ASP 230 230 ? A 11.84018 3.99912 8.88297 1 1 A ASP 1 1
ATOM 1880 O O . ASP 230 230 ? A 10.99862 3.12740 9.10838 1 1 A ASP 1 1
ATOM 1881 C CB . ASP 230 230 ? A 11.31867 5.29280 10.98924 1 1 A ASP 1 1
ATOM 1882 C CG . ASP 230 230 ? A 12.36219 4.60094 11.84139 1 1 A ASP 1 1
ATOM 1883 O OD1 . ASP 230 230 ? A 13.54596 4.60752 11.45759 1 1 A ASP 1 1
ATOM 1884 O OD2 . ASP 230 230 ? A 12.00065 4.05705 12.90510 1 1 A ASP 1 1
ATOM 1885 N N . ASN 231 231 ? A 12.88334 3.80539 8.09877 1 1 A ASN 1 1
ATOM 1886 C CA . ASN 231 231 ? A 13.13832 2.58594 7.33659 1 1 A ASN 1 1
ATOM 1887 C C . ASN 231 231 ? A 11.97044 2.24328 6.41812 1 1 A ASN 1 1
ATOM 1888 O O . ASN 231 231 ? A 11.56183 1.09604 6.30224 1 1 A ASN 1 1
ATOM 1889 C CB . ASN 231 231 ? A 13.48748 1.41986 8.26118 1 1 A ASN 1 1
ATOM 1890 C CG . ASN 231 231 ? A 14.17693 0.28406 7.51245 1 1 A ASN 1 1
ATOM 1891 O OD1 . ASN 231 231 ? A 14.97767 0.52372 6.60852 1 1 A ASN 1 1
ATOM 1892 N ND2 . ASN 231 231 ? A 13.86759 -0.94595 7.85449 1 1 A ASN 1 1
ATOM 1893 N N . ILE 232 232 ? A 11.37105 3.25780 5.80311 1 1 A ILE 1 1
ATOM 1894 C CA . ILE 232 232 ? A 10.30672 3.12689 4.81425 1 1 A ILE 1 1
ATOM 1895 C C . ILE 232 232 ? A 10.72612 3.87405 3.55483 1 1 A ILE 1 1
ATOM 1896 O O . ILE 232 232 ? A 11.03058 5.06787 3.61093 1 1 A ILE 1 1
ATOM 1897 C CB . ILE 232 232 ? A 8.97288 3.69500 5.32704 1 1 A ILE 1 1
ATOM 1898 C CG1 . ILE 232 232 ? A 8.53731 3.00657 6.63348 1 1 A ILE 1 1
ATOM 1899 C CG2 . ILE 232 232 ? A 7.88077 3.57877 4.28882 1 1 A ILE 1 1
ATOM 1900 C CD1 . ILE 232 232 ? A 8.19004 1.55219 6.46784 1 1 A ILE 1 1
ATOM 1901 N N . PHE 233 233 ? A 10.75045 3.19089 2.42896 1 1 A PHE 1 1
ATOM 1902 C CA . PHE 233 233 ? A 11.21232 3.74307 1.16638 1 1 A PHE 1 1
ATOM 1903 C C . PHE 233 233 ? A 10.19291 3.51228 0.06671 1 1 A PHE 1 1
ATOM 1904 O O . PHE 233 233 ? A 9.63698 2.42495 -0.03647 1 1 A PHE 1 1
ATOM 1905 C CB . PHE 233 233 ? A 12.54731 3.12179 0.75885 1 1 A PHE 1 1
ATOM 1906 C CG . PHE 233 233 ? A 13.63706 3.30579 1.76353 1 1 A PHE 1 1
ATOM 1907 C CD1 . PHE 233 233 ? A 14.48543 4.39914 1.68050 1 1 A PHE 1 1
ATOM 1908 C CD2 . PHE 233 233 ? A 13.82447 2.41743 2.81237 1 1 A PHE 1 1
ATOM 1909 C CE1 . PHE 233 233 ? A 15.49383 4.60289 2.61434 1 1 A PHE 1 1
ATOM 1910 C CE2 . PHE 233 233 ? A 14.82798 2.60966 3.74678 1 1 A PHE 1 1
ATOM 1911 C CZ . PHE 233 233 ? A 15.66128 3.70371 3.64788 1 1 A PHE 1 1
ATOM 1912 N N . ILE 234 234 ? A 9.95089 4.52425 -0.74880 1 1 A ILE 1 1
ATOM 1913 C CA . ILE 234 234 ? A 9.05939 4.42787 -1.89727 1 1 A ILE 1 1
ATOM 1914 C C . ILE 234 234 ? A 9.89588 4.36814 -3.16977 1 1 A ILE 1 1
ATOM 1915 O O . ILE 234 234 ? A 10.92979 5.03643 -3.27307 1 1 A ILE 1 1
ATOM 1916 C CB . ILE 234 234 ? A 8.06492 5.61032 -1.91806 1 1 A ILE 1 1
ATOM 1917 C CG1 . ILE 234 234 ? A 6.94795 5.34150 -2.93583 1 1 A ILE 1 1
ATOM 1918 C CG2 . ILE 234 234 ? A 8.77026 6.93852 -2.20291 1 1 A ILE 1 1
ATOM 1919 C CD1 . ILE 234 234 ? A 5.76478 6.26178 -2.78965 1 1 A ILE 1 1
ATOM 1920 N N . PRO 235 235 ? A 9.47418 3.56082 -4.15557 1 1 A PRO 1 1
ATOM 1921 C CA . PRO 235 235 ? A 10.23429 3.52978 -5.39477 1 1 A PRO 1 1
ATOM 1922 C C . PRO 235 235 ? A 10.01269 4.79300 -6.21971 1 1 A PRO 1 1
ATOM 1923 O O . PRO 235 235 ? A 8.95829 5.42180 -6.12014 1 1 A PRO 1 1
ATOM 1924 C CB . PRO 235 235 ? A 9.69067 2.30105 -6.11415 1 1 A PRO 1 1
ATOM 1925 C CG . PRO 235 235 ? A 8.29973 2.15301 -5.58632 1 1 A PRO 1 1
ATOM 1926 C CD . PRO 235 235 ? A 8.35349 2.61711 -4.16125 1 1 A PRO 1 1
ATOM 1927 N N . SER 236 236 ? A 11.00075 5.17565 -7.01480 1 1 A SER 1 1
ATOM 1928 C CA . SER 236 236 ? A 10.87823 6.28197 -7.93515 1 1 A SER 1 1
ATOM 1929 C C . SER 236 236 ? A 11.54753 5.92847 -9.25132 1 1 A SER 1 1
ATOM 1930 O O . SER 236 236 ? A 12.46909 5.10439 -9.29661 1 1 A SER 1 1
ATOM 1931 C CB . SER 236 236 ? A 11.49340 7.55553 -7.34894 1 1 A SER 1 1
ATOM 1932 O OG . SER 236 236 ? A 12.86977 7.38311 -7.06790 1 1 A SER 1 1
ATOM 1933 N N . VAL 237 237 ? A 11.09973 6.54944 -10.31182 1 1 A VAL 1 1
ATOM 1934 C CA . VAL 237 237 ? A 11.59824 6.30824 -11.64835 1 1 A VAL 1 1
ATOM 1935 C C . VAL 237 237 ? A 12.19318 7.60812 -12.17598 1 1 A VAL 1 1
ATOM 1936 O O . VAL 237 237 ? A 11.55487 8.66327 -12.07514 1 1 A VAL 1 1
ATOM 1937 C CB . VAL 237 237 ? A 10.47209 5.79624 -12.57119 1 1 A VAL 1 1
ATOM 1938 C CG1 . VAL 237 237 ? A 10.91843 5.75040 -14.01053 1 1 A VAL 1 1
ATOM 1939 C CG2 . VAL 237 237 ? A 10.00896 4.42707 -12.08940 1 1 A VAL 1 1
ATOM 1940 N N . ILE 238 238 ? A 13.42222 7.54263 -12.70856 1 1 A ILE 1 1
ATOM 1941 C CA . ILE 238 238 ? A 14.12790 8.71387 -13.18924 1 1 A ILE 1 1
ATOM 1942 C C . ILE 238 238 ? A 14.26012 8.65047 -14.69707 1 1 A ILE 1 1
ATOM 1943 O O . ILE 238 238 ? A 14.67915 7.62157 -15.24214 1 1 A ILE 1 1
ATOM 1944 C CB . ILE 238 238 ? A 15.52462 8.81001 -12.55534 1 1 A ILE 1 1
ATOM 1945 C CG1 . ILE 238 238 ? A 15.45189 8.69192 -11.03411 1 1 A ILE 1 1
ATOM 1946 C CG2 . ILE 238 238 ? A 16.21279 10.10342 -12.96743 1 1 A ILE 1 1
ATOM 1947 C CD1 . ILE 238 238 ? A 14.55245 9.69190 -10.34715 1 1 A ILE 1 1
ATOM 1948 N N . THR 239 239 ? A 13.91696 9.73837 -15.37082 1 1 A THR 1 1
ATOM 1949 C CA . THR 239 239 ? A 13.99217 9.78746 -16.82304 1 1 A THR 1 1
ATOM 1950 C C . THR 239 239 ? A 15.39547 10.12961 -17.28292 1 1 A THR 1 1
ATOM 1951 O O . THR 239 239 ? A 16.23847 10.56759 -16.48377 1 1 A THR 1 1
ATOM 1952 C CB . THR 239 239 ? A 13.01467 10.81110 -17.39890 1 1 A THR 1 1
ATOM 1953 O OG1 . THR 239 239 ? A 13.46829 12.13199 -17.09760 1 1 A THR 1 1
ATOM 1954 C CG2 . THR 239 239 ? A 11.61657 10.60016 -16.85361 1 1 A THR 1 1
ATOM 1955 N N . LYS 240 240 ? A 15.63672 9.98442 -18.56343 1 1 A LYS 1 1
ATOM 1956 C CA . LYS 240 240 ? A 16.91205 10.33151 -19.15443 1 1 A LYS 1 1
ATOM 1957 C C . LYS 240 240 ? A 17.23444 11.80467 -18.94992 1 1 A LYS 1 1
ATOM 1958 O O . LYS 240 240 ? A 18.39599 12.16687 -18.76266 1 1 A LYS 1 1
ATOM 1959 C CB . LYS 240 240 ? A 16.91727 9.99364 -20.63642 1 1 A LYS 1 1
ATOM 1960 C CG . LYS 240 240 ? A 18.25046 10.18493 -21.32139 1 1 A LYS 1 1
ATOM 1961 C CD . LYS 240 240 ? A 18.16749 9.84307 -22.79304 1 1 A LYS 1 1
ATOM 1962 C CE . LYS 240 240 ? A 19.50324 10.04359 -23.48948 1 1 A LYS 1 1
ATOM 1963 N NZ . LYS 240 240 ? A 19.39909 9.81920 -24.95391 1 1 A LYS 1 1
ATOM 1964 N N . SER 241 241 ? A 16.21366 12.65296 -18.93772 1 1 A SER 1 1
ATOM 1965 C CA . SER 241 241 ? A 16.40964 14.07584 -18.73231 1 1 A SER 1 1
ATOM 1966 C C . SER 241 241 ? A 16.57495 14.45185 -17.26535 1 1 A SER 1 1
ATOM 1967 O O . SER 241 241 ? A 16.74212 15.62759 -16.94308 1 1 A SER 1 1
ATOM 1968 C CB . SER 241 241 ? A 15.23512 14.85723 -19.32306 1 1 A SER 1 1
ATOM 1969 O OG . SER 241 241 ? A 14.03467 14.58740 -18.60040 1 1 A SER 1 1
ATOM 1970 N N . GLY 242 242 ? A 16.50702 13.46503 -16.36052 1 1 A GLY 1 1
ATOM 1971 C CA . GLY 242 242 ? A 16.70427 13.71589 -14.95037 1 1 A GLY 1 1
ATOM 1972 C C . GLY 242 242 ? A 15.43899 13.97042 -14.15841 1 1 A GLY 1 1
ATOM 1973 O O . GLY 242 242 ? A 15.50864 14.15836 -12.94117 1 1 A GLY 1 1
ATOM 1974 N N . LYS 243 243 ? A 14.27132 13.93943 -14.78789 1 1 A LYS 1 1
ATOM 1975 C CA . LYS 243 243 ? A 13.03232 14.18120 -14.08195 1 1 A LYS 1 1
ATOM 1976 C C . LYS 243 243 ? A 12.54990 12.91654 -13.39000 1 1 A LYS 1 1
ATOM 1977 O O . LYS 243 243 ? A 12.81558 11.80405 -13.83569 1 1 A LYS 1 1
ATOM 1978 C CB . LYS 243 243 ? A 11.96284 14.68429 -15.04403 1 1 A LYS 1 1
ATOM 1979 C CG . LYS 243 243 ? A 12.25073 16.07590 -15.59199 1 1 A LYS 1 1
ATOM 1980 C CD . LYS 243 243 ? A 11.12321 16.56845 -16.48406 1 1 A LYS 1 1
ATOM 1981 C CE . LYS 243 243 ? A 11.44603 17.89734 -17.09350 1 1 A LYS 1 1
ATOM 1982 N NZ . LYS 243 243 ? A 11.48759 18.97559 -16.06252 1 1 A LYS 1 1
ATOM 1983 N N . LYS 244 244 ? A 11.83173 13.09192 -12.28549 1 1 A LYS 1 1
ATOM 1984 C CA . LYS 244 244 ? A 11.28508 12.00101 -11.51663 1 1 A LYS 1 1
ATOM 1985 C C . LYS 244 244 ? A 9.84111 11.74271 -11.92066 1 1 A LYS 1 1
ATOM 1986 O O . LYS 244 244 ? A 9.04887 12.67496 -11.99005 1 1 A LYS 1 1
ATOM 1987 C CB . LYS 244 244 ? A 11.37007 12.32045 -10.02411 1 1 A LYS 1 1
ATOM 1988 C CG . LYS 244 244 ? A 11.00951 11.17492 -9.10210 1 1 A LYS 1 1
ATOM 1989 C CD . LYS 244 244 ? A 11.18536 11.56632 -7.64172 1 1 A LYS 1 1
ATOM 1990 C CE . LYS 244 244 ? A 12.65020 11.74134 -7.28737 1 1 A LYS 1 1
ATOM 1991 N NZ . LYS 244 244 ? A 12.85481 12.02436 -5.83853 1 1 A LYS 1 1
ATOM 1992 N N . ILE 245 245 ? A 9.50578 10.48218 -12.15868 1 1 A ILE 1 1
ATOM 1993 C CA . ILE 245 245 ? A 8.13914 10.08254 -12.43476 1 1 A ILE 1 1
ATOM 1994 C C . ILE 245 245 ? A 7.54666 9.51685 -11.16047 1 1 A ILE 1 1
ATOM 1995 O O . ILE 245 245 ? A 8.18892 8.69619 -10.49236 1 1 A ILE 1 1
ATOM 1996 C CB . ILE 245 245 ? A 8.07457 9.03060 -13.55696 1 1 A ILE 1 1
ATOM 1997 C CG1 . ILE 245 245 ? A 8.61913 9.60414 -14.85769 1 1 A ILE 1 1
ATOM 1998 C CG2 . ILE 245 245 ? A 6.65566 8.51535 -13.74835 1 1 A ILE 1 1
ATOM 1999 C CD1 . ILE 245 245 ? A 8.81189 8.57134 -15.94486 1 1 A ILE 1 1
ATOM 2000 N N . LEU 246 246 ? A 6.35002 9.94838 -10.81495 1 1 A LEU 1 1
ATOM 2001 C CA . LEU 246 246 ? A 5.72756 9.54663 -9.56800 1 1 A LEU 1 1
ATOM 2002 C C . LEU 246 246 ? A 5.22098 8.11867 -9.63695 1 1 A LEU 1 1
ATOM 2003 O O . LEU 246 246 ? A 4.60631 7.70466 -10.62199 1 1 A LEU 1 1
ATOM 2004 C CB . LEU 246 246 ? A 4.57139 10.47890 -9.21955 1 1 A LEU 1 1
ATOM 2005 C CG . LEU 246 246 ? A 4.91839 11.94897 -9.05988 1 1 A LEU 1 1
ATOM 2006 C CD1 . LEU 246 246 ? A 3.68079 12.74399 -8.69155 1 1 A LEU 1 1
ATOM 2007 C CD2 . LEU 246 246 ? A 6.00256 12.15860 -8.02544 1 1 A LEU 1 1
ATOM 2008 N N . VAL 247 247 ? A 5.46624 7.37115 -8.54818 1 1 A VAL 1 1
ATOM 2009 C CA . VAL 247 247 ? A 5.03383 6.00782 -8.36181 1 1 A VAL 1 1
ATOM 2010 C C . VAL 247 247 ? A 4.28111 5.92301 -7.04168 1 1 A VAL 1 1
ATOM 2011 O O . VAL 247 247 ? A 4.76504 6.45870 -6.02922 1 1 A VAL 1 1
ATOM 2012 C CB . VAL 247 247 ? A 6.22563 5.02863 -8.33473 1 1 A VAL 1 1
ATOM 2013 C CG1 . VAL 247 247 ? A 5.76087 3.59836 -8.15107 1 1 A VAL 1 1
ATOM 2014 C CG2 . VAL 247 247 ? A 7.05435 5.17393 -9.60594 1 1 A VAL 1 1
ATOM 2015 N N . LYS 248 248 ? A 3.10478 5.25613 -7.01518 1 1 A LYS 1 1
ATOM 2016 C CA . LYS 248 248 ? A 2.30842 5.17318 -5.80568 1 1 A LYS 1 1
ATOM 2017 C C . LYS 248 248 ? A 2.96333 4.34272 -4.72893 1 1 A LYS 1 1
ATOM 2018 O O . LYS 248 248 ? A 3.00152 4.75764 -3.56387 1 1 A LYS 1 1
ATOM 2019 C CB . LYS 248 248 ? A 0.94010 4.55935 -6.09157 1 1 A LYS 1 1
ATOM 2020 C CG . LYS 248 248 ? A 0.00207 5.40934 -6.88604 1 1 A LYS 1 1
ATOM 2021 C CD . LYS 248 248 ? A -1.30391 4.66904 -7.10314 1 1 A LYS 1 1
ATOM 2022 C CE . LYS 248 248 ? A -2.25003 5.43225 -7.98501 1 1 A LYS 1 1
ATOM 2023 N NZ . LYS 248 248 ? A -3.46472 4.65870 -8.29862 1 1 A LYS 1 1
ATOM 2024 N N . ASP 249 249 ? A 3.42913 3.20138 -5.11229 1 1 A ASP 1 1
ATOM 2025 C CA . ASP 249 249 ? A 3.95031 2.23389 -4.17432 1 1 A ASP 1 1
ATOM 2026 C C . ASP 249 249 ? A 4.59830 1.08460 -4.92483 1 1 A ASP 1 1
ATOM 2027 O O . ASP 249 249 ? A 4.71328 1.13592 -6.16175 1 1 A ASP 1 1
ATOM 2028 C CB . ASP 249 249 ? A 2.84650 1.72869 -3.23345 1 1 A ASP 1 1
ATOM 2029 C CG . ASP 249 249 ? A 1.65468 1.19435 -3.96698 1 1 A ASP 1 1
ATOM 2030 O OD1 . ASP 249 249 ? A 1.75935 0.76790 -5.13376 1 1 A ASP 1 1
ATOM 2031 O OD2 . ASP 249 249 ? A 0.55399 1.18106 -3.37038 1 1 A ASP 1 1
ATOM 2032 N N . VAL 250 250 ? A 5.02113 0.04841 -4.20411 1 1 A VAL 1 1
ATOM 2033 C CA . VAL 250 250 ? A 5.65743 -1.10137 -4.83046 1 1 A VAL 1 1
ATOM 2034 C C . VAL 250 250 ? A 4.69942 -1.77710 -5.80456 1 1 A VAL 1 1
ATOM 2035 O O . VAL 250 250 ? A 5.10356 -2.18919 -6.89489 1 1 A VAL 1 1
ATOM 2036 C CB . VAL 250 250 ? A 6.15723 -2.09811 -3.77072 1 1 A VAL 1 1
ATOM 2037 C CG1 . VAL 250 250 ? A 6.66770 -3.36706 -4.40747 1 1 A VAL 1 1
ATOM 2038 C CG2 . VAL 250 250 ? A 7.21806 -1.46481 -2.90435 1 1 A VAL 1 1
ATOM 2039 N N . ASP 251 251 ? A 3.43796 -1.90633 -5.41374 1 1 A ASP 1 1
ATOM 2040 C CA . ASP 251 251 ? A 2.46113 -2.56251 -6.26840 1 1 A ASP 1 1
ATOM 2041 C C . ASP 251 251 ? A 2.29539 -1.83716 -7.59654 1 1 A ASP 1 1
ATOM 2042 O O . ASP 251 251 ? A 2.18951 -2.47661 -8.65228 1 1 A ASP 1 1
ATOM 2043 C CB . ASP 251 251 ? A 1.11317 -2.67908 -5.55513 1 1 A ASP 1 1
ATOM 2044 C CG . ASP 251 251 ? A 0.13958 -3.56215 -6.29672 1 1 A ASP 1 1
ATOM 2045 O OD1 . ASP 251 251 ? A 0.40293 -4.77186 -6.39154 1 1 A ASP 1 1
ATOM 2046 O OD2 . ASP 251 251 ? A -0.88011 -3.04677 -6.78850 1 1 A ASP 1 1
ATOM 2047 N N . HIS 252 252 ? A 2.26620 -0.51329 -7.57788 1 1 A HIS 1 1
ATOM 2048 C CA . HIS 252 252 ? A 2.17548 0.28135 -8.79587 1 1 A HIS 1 1
ATOM 2049 C C . HIS 252 252 ? A 3.36533 0.01810 -9.69255 1 1 A HIS 1 1
ATOM 2050 O O . HIS 252 252 ? A 3.21319 -0.15384 -10.90468 1 1 A HIS 1 1
ATOM 2051 C CB . HIS 252 252 ? A 2.07796 1.76569 -8.42191 1 1 A HIS 1 1
ATOM 2052 C CG . HIS 252 252 ? A 1.82715 2.67712 -9.56101 1 1 A HIS 1 1
ATOM 2053 N ND1 . HIS 252 252 ? A 1.89744 4.06335 -9.43159 1 1 A HIS 1 1
ATOM 2054 C CD2 . HIS 252 252 ? A 1.51272 2.46143 -10.85516 1 1 A HIS 1 1
ATOM 2055 C CE1 . HIS 252 252 ? A 1.64520 4.62260 -10.58392 1 1 A HIS 1 1
ATOM 2056 N NE2 . HIS 252 252 ? A 1.41668 3.66941 -11.47440 1 1 A HIS 1 1
ATOM 2057 N N . LEU 253 253 ? A 4.55242 -0.02359 -9.12000 1 1 A LEU 1 1
ATOM 2058 C CA . LEU 253 253 ? A 5.76330 -0.30353 -9.87660 1 1 A LEU 1 1
ATOM 2059 C C . LEU 253 253 ? A 5.70161 -1.68284 -10.50949 1 1 A LEU 1 1
ATOM 2060 O O . LEU 253 253 ? A 6.09487 -1.86610 -11.66557 1 1 A LEU 1 1
ATOM 2061 C CB . LEU 253 253 ? A 6.98739 -0.16802 -8.97366 1 1 A LEU 1 1
ATOM 2062 C CG . LEU 253 253 ? A 8.35107 -0.25161 -9.63601 1 1 A LEU 1 1
ATOM 2063 C CD1 . LEU 253 253 ? A 8.54265 0.90445 -10.59794 1 1 A LEU 1 1
ATOM 2064 C CD2 . LEU 253 253 ? A 9.44410 -0.23790 -8.57416 1 1 A LEU 1 1
ATOM 2065 N N . ILE 254 254 ? A 5.20302 -2.67198 -9.74357 1 1 A ILE 1 1
ATOM 2066 C CA . ILE 254 254 ? A 5.07110 -4.03031 -10.26457 1 1 A ILE 1 1
ATOM 2067 C C . ILE 254 254 ? A 4.09016 -4.07909 -11.42541 1 1 A ILE 1 1
ATOM 2068 O O . ILE 254 254 ? A 4.38282 -4.68786 -12.46579 1 1 A ILE 1 1
ATOM 2069 C CB . ILE 254 254 ? A 4.64673 -5.01082 -9.15712 1 1 A ILE 1 1
ATOM 2070 C CG1 . ILE 254 254 ? A 5.75111 -5.18377 -8.13363 1 1 A ILE 1 1
ATOM 2071 C CG2 . ILE 254 254 ? A 4.24272 -6.35222 -9.73383 1 1 A ILE 1 1
ATOM 2072 C CD1 . ILE 254 254 ? A 5.32047 -5.88792 -6.86451 1 1 A ILE 1 1
ATOM 2073 N N . ARG 255 255 ? A 2.88641 -3.44219 -11.26929 1 1 A ARG 1 1
ATOM 2074 C CA . ARG 255 255 ? A 1.88367 -3.45134 -12.32596 1 1 A ARG 1 1
ATOM 2075 C C . ARG 255 255 ? A 2.39044 -2.79010 -13.59285 1 1 A ARG 1 1
ATOM 2076 O O . ARG 255 255 ? A 2.03947 -3.22764 -14.69510 1 1 A ARG 1 1
ATOM 2077 C CB . ARG 255 255 ? A 0.59835 -2.75117 -11.87965 1 1 A ARG 1 1
ATOM 2078 C CG . ARG 255 255 ? A -0.16839 -3.50067 -10.81124 1 1 A ARG 1 1
ATOM 2079 C CD . ARG 255 255 ? A -1.57670 -2.96460 -10.67284 1 1 A ARG 1 1
ATOM 2080 N NE . ARG 255 255 ? A -1.64317 -1.51670 -10.56144 1 1 A ARG 1 1
ATOM 2081 C CZ . ARG 255 255 ? A -1.61839 -0.85312 -9.41513 1 1 A ARG 1 1
ATOM 2082 N NH1 . ARG 255 255 ? A -1.50185 -1.51433 -8.27931 1 1 A ARG 1 1
ATOM 2083 N NH2 . ARG 255 255 ? A -1.68452 0.47884 -9.42279 1 1 A ARG 1 1
ATOM 2084 N N . SER 256 256 ? A 3.22112 -1.77723 -13.48726 1 1 A SER 1 1
ATOM 2085 C CA . SER 256 256 ? A 3.72022 -1.05173 -14.64769 1 1 A SER 1 1
ATOM 2086 C C . SER 256 256 ? A 4.73814 -1.85387 -15.44847 1 1 A SER 1 1
ATOM 2087 O O . SER 256 256 ? A 4.96221 -1.55924 -16.61757 1 1 A SER 1 1
ATOM 2088 C CB . SER 256 256 ? A 4.33528 0.26882 -14.21391 1 1 A SER 1 1
ATOM 2089 O OG . SER 256 256 ? A 5.56536 0.06918 -13.54911 1 1 A SER 1 1
ATOM 2090 N N . LYS 257 257 ? A 5.38008 -2.82465 -14.81398 1 1 A LYS 1 1
ATOM 2091 C CA . LYS 257 257 ? A 6.42511 -3.63534 -15.42610 1 1 A LYS 1 1
ATOM 2092 C C . LYS 257 257 ? A 7.56040 -2.81010 -16.01619 1 1 A LYS 1 1
ATOM 2093 O O . LYS 257 257 ? A 8.26973 -3.25837 -16.89905 1 1 A LYS 1 1
ATOM 2094 C CB . LYS 257 257 ? A 5.84915 -4.56195 -16.47020 1 1 A LYS 1 1
ATOM 2095 C CG . LYS 257 257 ? A 4.87989 -5.56663 -15.86987 1 1 A LYS 1 1
ATOM 2096 C CD . LYS 257 257 ? A 4.34082 -6.54597 -16.86836 1 1 A LYS 1 1
ATOM 2097 C CE . LYS 257 257 ? A 3.35936 -7.47460 -16.20747 1 1 A LYS 1 1
ATOM 2098 N NZ . LYS 257 257 ? A 2.88514 -8.53900 -17.06793 1 1 A LYS 1 1
ATOM 2099 N N . VAL 258 258 ? A 7.78358 -1.63560 -15.48313 1 1 A VAL 1 1
ATOM 2100 C CA . VAL 258 258 ? A 8.81924 -0.72820 -15.97410 1 1 A VAL 1 1
ATOM 2101 C C . VAL 258 258 ? A 10.19870 -1.36205 -15.86766 1 1 A VAL 1 1
ATOM 2102 O O . VAL 258 258 ? A 10.50871 -2.10056 -14.92061 1 1 A VAL 1 1
ATOM 2103 C CB . VAL 258 258 ? A 8.76131 0.61089 -15.20343 1 1 A VAL 1 1
ATOM 2104 C CG1 . VAL 258 258 ? A 9.16067 0.40992 -13.74227 1 1 A VAL 1 1
ATOM 2105 C CG2 . VAL 258 258 ? A 9.65232 1.64462 -15.87921 1 1 A VAL 1 1
ATOM 2106 N N . ARG 259 259 ? A 11.04792 -1.08138 -16.85301 1 1 A ARG 1 1
ATOM 2107 C CA . ARG 259 259 ? A 12.43118 -1.51703 -16.93206 1 1 A ARG 1 1
ATOM 2108 C C . ARG 259 259 ? A 13.32425 -0.31702 -17.15570 1 1 A ARG 1 1
ATOM 2109 O O . ARG 259 259 ? A 12.91778 0.65470 -17.80691 1 1 A ARG 1 1
ATOM 2110 C CB . ARG 259 259 ? A 12.64029 -2.48329 -18.08254 1 1 A ARG 1 1
ATOM 2111 C CG . ARG 259 259 ? A 11.94648 -3.80513 -17.95085 1 1 A ARG 1 1
ATOM 2112 C CD . ARG 259 259 ? A 12.12086 -4.62957 -19.21974 1 1 A ARG 1 1
ATOM 2113 N NE . ARG 259 259 ? A 11.54684 -5.92620 -19.08422 1 1 A ARG 1 1
ATOM 2114 C CZ . ARG 259 259 ? A 12.21330 -7.02749 -18.79110 1 1 A ARG 1 1
ATOM 2115 N NH1 . ARG 259 259 ? A 13.52171 -6.98707 -18.59388 1 1 A ARG 1 1
ATOM 2116 N NH2 . ARG 259 259 ? A 11.56992 -8.18026 -18.69276 1 1 A ARG 1 1
ATOM 2117 N N . GLU 260 260 ? A 14.55246 -0.40743 -16.63617 1 1 A GLU 1 1
ATOM 2118 C CA . GLU 260 260 ? A 15.53043 0.61720 -16.96851 1 1 A GLU 1 1
ATOM 2119 C C . GLU 260 260 ? A 15.85265 0.56872 -18.44258 1 1 A GLU 1 1
ATOM 2120 O O . GLU 260 260 ? A 15.79610 -0.49585 -19.06417 1 1 A GLU 1 1
ATOM 2121 C CB . GLU 260 260 ? A 16.80437 0.41391 -16.15717 1 1 A GLU 1 1
ATOM 2122 C CG . GLU 260 260 ? A 16.62295 0.68792 -14.68051 1 1 A GLU 1 1
ATOM 2123 C CD . GLU 260 260 ? A 17.91642 0.54919 -13.89773 1 1 A GLU 1 1
ATOM 2124 O OE1 . GLU 260 260 ? A 18.88346 -0.03448 -14.41237 1 1 A GLU 1 1
ATOM 2125 O OE2 . GLU 260 260 ? A 17.95106 1.02543 -12.75822 1 1 A GLU 1 1
ATOM 2126 N N . HIS 261 261 ? A 16.17721 1.74241 -18.98918 1 1 A HIS 1 1
ATOM 2127 C CA . HIS 261 261 ? A 16.62691 1.87053 -20.37365 1 1 A HIS 1 1
ATOM 2128 C C . HIS 261 261 ? A 15.55086 1.47936 -21.38716 1 1 A HIS 1 1
ATOM 2129 O O . HIS 261 261 ? A 15.83701 0.89766 -22.42501 1 1 A HIS 1 1
ATOM 2130 C CB . HIS 261 261 ? A 17.90761 1.06705 -20.60396 1 1 A HIS 1 1
ATOM 2131 C CG . HIS 261 261 ? A 18.95063 1.32279 -19.57058 1 1 A HIS 1 1
ATOM 2132 N ND1 . HIS 261 261 ? A 19.51752 2.55449 -19.38493 1 1 A HIS 1 1
ATOM 2133 C CD2 . HIS 261 261 ? A 19.49455 0.51079 -18.63546 1 1 A HIS 1 1
ATOM 2134 C CE1 . HIS 261 261 ? A 20.37687 2.49369 -18.39430 1 1 A HIS 1 1
ATOM 2135 N NE2 . HIS 261 261 ? A 20.38449 1.26370 -17.91453 1 1 A HIS 1 1
ATOM 2136 N N . THR 262 262 ? A 14.27899 1.77183 -21.05240 1 1 A THR 1 1
ATOM 2137 C CA . THR 262 262 ? A 13.15950 1.58756 -21.96318 1 1 A THR 1 1
ATOM 2138 C C . THR 262 262 ? A 12.22710 2.78240 -21.86263 1 1 A THR 1 1
ATOM 2139 O O . THR 262 262 ? A 12.32363 3.58530 -20.92499 1 1 A THR 1 1
ATOM 2140 C CB . THR 262 262 ? A 12.37258 0.31039 -21.65865 1 1 A THR 1 1
ATOM 2141 O OG1 . THR 262 262 ? A 11.79032 0.39085 -20.36548 1 1 A THR 1 1
ATOM 2142 C CG2 . THR 262 262 ? A 13.25683 -0.91543 -21.73500 1 1 A THR 1 1
ATOM 2143 N N . PHE 263 263 ? A 11.29433 2.90465 -22.78664 1 1 A PHE 1 1
ATOM 2144 C CA . PHE 263 263 ? A 10.26335 3.91894 -22.75460 1 1 A PHE 1 1
ATOM 2145 C C . PHE 263 263 ? A 9.06625 3.43849 -21.96243 1 1 A PHE 1 1
ATOM 2146 O O . PHE 263 263 ? A 8.71473 2.26476 -22.02000 1 1 A PHE 1 1
ATOM 2147 C CB . PHE 263 263 ? A 9.81848 4.29816 -24.16559 1 1 A PHE 1 1
ATOM 2148 C CG . PHE 263 263 ? A 10.78057 5.19616 -24.88242 1 1 A PHE 1 1
ATOM 2149 C CD1 . PHE 263 263 ? A 11.85670 4.66987 -25.56928 1 1 A PHE 1 1
ATOM 2150 C CD2 . PHE 263 263 ? A 10.61481 6.58383 -24.85338 1 1 A PHE 1 1
ATOM 2151 C CE1 . PHE 263 263 ? A 12.76077 5.48937 -26.22086 1 1 A PHE 1 1
ATOM 2152 C CE2 . PHE 263 263 ? A 11.50969 7.41028 -25.50848 1 1 A PHE 1 1
ATOM 2153 C CZ . PHE 263 263 ? A 12.58422 6.85856 -26.18785 1 1 A PHE 1 1
ATOM 2154 N N . VAL 264 264 ? A 8.42616 4.36431 -21.22828 1 1 A VAL 1 1
ATOM 2155 C CA . VAL 264 264 ? A 7.18988 4.06855 -20.52967 1 1 A VAL 1 1
ATOM 2156 C C . VAL 264 264 ? A 6.16216 5.13642 -20.82850 1 1 A VAL 1 1
ATOM 2157 O O . VAL 264 264 ? A 6.50726 6.28436 -21.14909 1 1 A VAL 1 1
ATOM 2158 C CB . VAL 264 264 ? A 7.37496 3.95220 -19.00597 1 1 A VAL 1 1
ATOM 2159 C CG1 . VAL 264 264 ? A 8.31631 2.81805 -18.65769 1 1 A VAL 1 1
ATOM 2160 C CG2 . VAL 264 264 ? A 7.85582 5.25924 -18.40287 1 1 A VAL 1 1
ATOM 2161 N N . LYS 265 265 ? A 4.92104 4.76971 -20.70190 1 1 A LYS 1 1
ATOM 2162 C CA . LYS 265 265 ? A 3.82514 5.69165 -20.86263 1 1 A LYS 1 1
ATOM 2163 C C . LYS 265 265 ? A 3.56126 6.39515 -19.54372 1 1 A LYS 1 1
ATOM 2164 O O . LYS 265 265 ? A 3.60828 5.76389 -18.47820 1 1 A LYS 1 1
ATOM 2165 C CB . LYS 265 265 ? A 2.57969 4.93492 -21.31983 1 1 A LYS 1 1
ATOM 2166 C CG . LYS 265 265 ? A 1.41651 5.79626 -21.72805 1 1 A LYS 1 1
ATOM 2167 C CD . LYS 265 265 ? A 0.28968 4.92813 -22.28351 1 1 A LYS 1 1
ATOM 2168 C CE . LYS 265 265 ? A -0.87349 5.73707 -22.77563 1 1 A LYS 1 1
ATOM 2169 N NZ . LYS 265 265 ? A -1.97462 4.86478 -23.25331 1 1 A LYS 1 1
ATOM 2170 N N . VAL 266 266 ? A 3.28722 7.70407 -19.57469 1 1 A VAL 1 1
ATOM 2171 C CA . VAL 266 266 ? A 2.99113 8.46288 -18.37633 1 1 A VAL 1 1
ATOM 2172 C C . VAL 266 266 ? A 1.77992 9.34894 -18.58946 1 1 A VAL 1 1
ATOM 2173 O O . VAL 266 266 ? A 1.42918 9.66869 -19.73090 1 1 A VAL 1 1
ATOM 2174 C CB . VAL 266 266 ? A 4.17395 9.33877 -17.92242 1 1 A VAL 1 1
ATOM 2175 C CG1 . VAL 266 266 ? A 5.40963 8.49346 -17.66587 1 1 A VAL 1 1
ATOM 2176 C CG2 . VAL 266 266 ? A 4.47377 10.43336 -18.93138 1 1 A VAL 1 1
ATOM 2177 N N . LYS 267 267 ? A 1.15203 9.74283 -17.51340 1 1 A LYS 1 1
ATOM 2178 C CA . LYS 267 267 ? A 0.15625 10.79408 -17.49731 1 1 A LYS 1 1
ATOM 2179 C C . LYS 267 267 ? A 0.87055 12.06801 -17.10788 1 1 A LYS 1 1
ATOM 2180 O O . LYS 267 267 ? A 1.44929 12.15003 -16.02475 1 1 A LYS 1 1
ATOM 2181 C CB . LYS 267 267 ? A -0.95276 10.49682 -16.49762 1 1 A LYS 1 1
ATOM 2182 C CG . LYS 267 267 ? A -1.83796 9.34024 -16.89088 1 1 A LYS 1 1
ATOM 2183 C CD . LYS 267 267 ? A -2.95541 9.16281 -15.86297 1 1 A LYS 1 1
ATOM 2184 C CE . LYS 267 267 ? A -3.94837 8.10353 -16.26986 1 1 A LYS 1 1
ATOM 2185 N NZ . LYS 267 267 ? A -5.04937 7.99794 -15.28486 1 1 A LYS 1 1
ATOM 2186 N N . LYS 268 268 ? A 0.84275 13.04344 -17.96877 1 1 A LYS 1 1
ATOM 2187 C CA . LYS 268 268 ? A 1.57059 14.26158 -17.73317 1 1 A LYS 1 1
ATOM 2188 C C . LYS 268 268 ? A 0.63182 15.37014 -17.27855 1 1 A LYS 1 1
ATOM 2189 O O . LYS 268 268 ? A -0.35053 15.66439 -17.94856 1 1 A LYS 1 1
ATOM 2190 C CB . LYS 268 268 ? A 2.30950 14.68305 -18.99034 1 1 A LYS 1 1
ATOM 2191 C CG . LYS 268 268 ? A 3.29433 15.80119 -18.78273 1 1 A LYS 1 1
ATOM 2192 C CD . LYS 268 268 ? A 4.15358 16.01377 -20.01613 1 1 A LYS 1 1
ATOM 2193 C CE . LYS 268 268 ? A 5.14575 17.14259 -19.82407 1 1 A LYS 1 1
ATOM 2194 N NZ . LYS 268 268 ? A 5.92027 17.40633 -21.05436 1 1 A LYS 1 1
ATOM 2195 N N . LYS 269 269 ? A 0.94203 15.98120 -16.11708 1 1 A LYS 1 1
ATOM 2196 C CA . LYS 269 269 ? A 0.18923 17.09735 -15.58219 1 1 A LYS 1 1
ATOM 2197 C C . LYS 269 269 ? A 0.98566 18.37353 -15.76396 1 1 A LYS 1 1
ATOM 2198 O O . LYS 269 269 ? A 2.02215 18.38976 -16.45010 1 1 A LYS 1 1
ATOM 2199 C CB . LYS 269 269 ? A -0.13219 16.88300 -14.10666 1 1 A LYS 1 1
ATOM 2200 C CG . LYS 269 269 ? A -0.86697 15.57524 -13.81651 1 1 A LYS 1 1
ATOM 2201 C CD . LYS 269 269 ? A -2.27507 15.60796 -14.33915 1 1 A LYS 1 1
ATOM 2202 C CE . LYS 269 269 ? A -3.12237 16.56256 -13.50488 1 1 A LYS 1 1
ATOM 2203 N NZ . LYS 269 269 ? A -4.51525 16.65651 -14.03705 1 1 A LYS 1 1
ATOM 2204 N N . ASN 270 270 ? A 0.55319 19.44765 -15.11651 1 1 A ASN 1 1
ATOM 2205 C CA . ASN 270 270 ? A 1.25371 20.71567 -15.25658 1 1 A ASN 1 1
ATOM 2206 C C . ASN 270 270 ? A 2.67300 20.67305 -14.70942 1 1 A ASN 1 1
ATOM 2207 O O . ASN 270 270 ? A 3.58208 21.23339 -15.31686 1 1 A ASN 1 1
ATOM 2208 C CB . ASN 270 270 ? A 0.48822 21.83802 -14.54897 1 1 A ASN 1 1
ATOM 2209 C CG . ASN 270 270 ? A -0.83683 22.14291 -15.20728 1 1 A ASN 1 1
ATOM 2210 O OD1 . ASN 270 270 ? A -0.96442 22.06049 -16.42017 1 1 A ASN 1 1
ATOM 2211 N ND2 . ASN 270 270 ? A -1.82635 22.48234 -14.38881 1 1 A ASN 1 1
ATOM 2212 N N . THR 271 271 ? A 2.85551 20.03588 -13.56497 1 1 A THR 1 1
ATOM 2213 C CA . THR 271 271 ? A 4.14837 20.09641 -12.90667 1 1 A THR 1 1
ATOM 2214 C C . THR 271 271 ? A 4.79076 18.74030 -12.63932 1 1 A THR 1 1
ATOM 2215 O O . THR 271 271 ? A 5.87749 18.68750 -12.06016 1 1 A THR 1 1
ATOM 2216 C CB . THR 271 271 ? A 4.05041 20.85580 -11.57528 1 1 A THR 1 1
ATOM 2217 O OG1 . THR 271 271 ? A 3.11734 20.18146 -10.71551 1 1 A THR 1 1
ATOM 2218 C CG2 . THR 271 271 ? A 3.58071 22.28013 -11.80587 1 1 A THR 1 1
ATOM 2219 N N . PHE 272 272 ? A 4.10865 17.64672 -13.01863 1 1 A PHE 1 1
ATOM 2220 C CA . PHE 272 272 ? A 4.66719 16.33224 -12.74030 1 1 A PHE 1 1
ATOM 2221 C C . PHE 272 272 ? A 4.10453 15.29389 -13.69214 1 1 A PHE 1 1
ATOM 2222 O O . PHE 272 272 ? A 3.11960 15.53686 -14.38884 1 1 A PHE 1 1
ATOM 2223 C CB . PHE 272 272 ? A 4.39919 15.92988 -11.28077 1 1 A PHE 1 1
ATOM 2224 C CG . PHE 272 272 ? A 2.94639 15.81006 -10.94189 1 1 A PHE 1 1
ATOM 2225 C CD1 . PHE 272 272 ? A 2.21694 16.91869 -10.52552 1 1 A PHE 1 1
ATOM 2226 C CD2 . PHE 272 272 ? A 2.28725 14.58938 -11.02250 1 1 A PHE 1 1
ATOM 2227 C CE1 . PHE 272 272 ? A 0.87259 16.81311 -10.20552 1 1 A PHE 1 1
ATOM 2228 C CE2 . PHE 272 272 ? A 0.93700 14.48484 -10.69995 1 1 A PHE 1 1
ATOM 2229 C CZ . PHE 272 272 ? A 0.23572 15.60134 -10.29445 1 1 A PHE 1 1
ATOM 2230 N N . SER 273 273 ? A 4.72823 14.12845 -13.69146 1 1 A SER 1 1
ATOM 2231 C CA . SER 273 273 ? A 4.28373 12.99911 -14.48768 1 1 A SER 1 1
ATOM 2232 C C . SER 273 273 ? A 4.01778 11.80056 -13.59692 1 1 A SER 1 1
ATOM 2233 O O . SER 273 273 ? A 4.70555 11.61327 -12.58073 1 1 A SER 1 1
ATOM 2234 C CB . SER 273 273 ? A 5.31619 12.63562 -15.53427 1 1 A SER 1 1
ATOM 2235 O OG . SER 273 273 ? A 5.54654 13.72411 -16.41347 1 1 A SER 1 1
ATOM 2236 N N . ILE 274 274 ? A 3.05840 10.97042 -14.00289 1 1 A ILE 1 1
ATOM 2237 C CA . ILE 274 274 ? A 2.66772 9.79693 -13.25002 1 1 A ILE 1 1
ATOM 2238 C C . ILE 274 274 ? A 2.90349 8.54885 -14.07953 1 1 A ILE 1 1
ATOM 2239 O O . ILE 274 274 ? A 2.52365 8.51270 -15.25050 1 1 A ILE 1 1
ATOM 2240 C CB . ILE 274 274 ? A 1.17888 9.88291 -12.86857 1 1 A ILE 1 1
ATOM 2241 C CG1 . ILE 274 274 ? A 0.92018 11.08554 -11.96216 1 1 A ILE 1 1
ATOM 2242 C CG2 . ILE 274 274 ? A 0.70764 8.59076 -12.19609 1 1 A ILE 1 1
ATOM 2243 C CD1 . ILE 274 274 ? A -0.54907 11.41734 -11.80815 1 1 A ILE 1 1
ATOM 2244 N N . LEU 275 275 ? A 3.50105 7.53765 -13.48297 1 1 A LEU 1 1
ATOM 2245 C CA . LEU 275 275 ? A 3.73444 6.27825 -14.17247 1 1 A LEU 1 1
ATOM 2246 C C . LEU 275 275 ? A 2.40646 5.60659 -14.48583 1 1 A LEU 1 1
ATOM 2247 O O . LEU 275 275 ? A 1.58624 5.37294 -13.59802 1 1 A LEU 1 1
ATOM 2248 C CB . LEU 275 275 ? A 4.59806 5.35701 -13.29548 1 1 A LEU 1 1
ATOM 2249 C CG . LEU 275 275 ? A 5.02405 4.03178 -13.91318 1 1 A LEU 1 1
ATOM 2250 C CD1 . LEU 275 275 ? A 5.89436 4.25470 -15.12876 1 1 A LEU 1 1
ATOM 2251 C CD2 . LEU 275 275 ? A 5.76712 3.19302 -12.89258 1 1 A LEU 1 1
ATOM 2252 N N . TYR 276 276 ? A 2.20393 5.28964 -15.75100 1 1 A TYR 1 1
ATOM 2253 C CA . TYR 276 276 ? A 0.98130 4.64344 -16.19222 1 1 A TYR 1 1
ATOM 2254 C C . TYR 276 276 ? A 1.14789 3.13152 -16.13461 1 1 A TYR 1 1
ATOM 2255 O O . TYR 276 276 ? A 2.24361 2.61773 -16.40946 1 1 A TYR 1 1
ATOM 2256 C CB . TYR 276 276 ? A 0.63217 5.09381 -17.61062 1 1 A TYR 1 1
ATOM 2257 C CG . TYR 276 276 ? A -0.74965 4.70029 -18.07591 1 1 A TYR 1 1
ATOM 2258 C CD1 . TYR 276 276 ? A -1.87736 5.17316 -17.42916 1 1 A TYR 1 1
ATOM 2259 C CD2 . TYR 276 276 ? A -0.91653 3.84961 -19.14506 1 1 A TYR 1 1
ATOM 2260 C CE1 . TYR 276 276 ? A -3.14700 4.81399 -17.84565 1 1 A TYR 1 1
ATOM 2261 C CE2 . TYR 276 276 ? A -2.18636 3.48790 -19.56571 1 1 A TYR 1 1
ATOM 2262 C CZ . TYR 276 276 ? A -3.29557 3.97456 -18.91611 1 1 A TYR 1 1
ATOM 2263 O OH . TYR 276 276 ? A -4.54676 3.61472 -19.34796 1 1 A TYR 1 1
ATOM 2264 N N . ASP 277 277 ? A 0.08385 2.40300 -15.73793 1 1 A ASP 1 1
ATOM 2265 C CA . ASP 277 277 ? A 0.11004 0.95273 -15.69938 1 1 A ASP 1 1
ATOM 2266 C C . ASP 277 277 ? A 0.15913 0.42423 -17.11643 1 1 A ASP 1 1
ATOM 2267 O O . ASP 277 277 ? A -0.79806 0.57582 -17.87698 1 1 A ASP 1 1
ATOM 2268 C CB . ASP 277 277 ? A -1.12099 0.40058 -14.98587 1 1 A ASP 1 1
ATOM 2269 C CG . ASP 277 277 ? A -1.18257 0.79408 -13.51764 1 1 A ASP 1 1
ATOM 2270 O OD1 . ASP 277 277 ? A -0.13274 0.69779 -12.84354 1 1 A ASP 1 1
ATOM 2271 O OD2 . ASP 277 277 ? A -2.26898 1.18546 -13.04312 1 1 A ASP 1 1
ATOM 2272 N N . TYR 278 278 ? A 1.27864 -0.14644 -17.49356 1 1 A TYR 1 1
ATOM 2273 C CA . TYR 278 278 ? A 1.52097 -0.58866 -18.85589 1 1 A TYR 1 1
ATOM 2274 C C . TYR 278 278 ? A 1.76633 -2.08564 -18.91131 1 1 A TYR 1 1
ATOM 2275 O O . TYR 278 278 ? A 2.83562 -2.55368 -18.53349 1 1 A TYR 1 1
ATOM 2276 C CB . TYR 278 278 ? A 2.71445 0.18633 -19.42076 1 1 A TYR 1 1
ATOM 2277 C CG . TYR 278 278 ? A 3.02587 -0.04791 -20.88019 1 1 A TYR 1 1
ATOM 2278 C CD1 . TYR 278 278 ? A 2.17314 0.41233 -21.86808 1 1 A TYR 1 1
ATOM 2279 C CD2 . TYR 278 278 ? A 4.16775 -0.71402 -21.26227 1 1 A TYR 1 1
ATOM 2280 C CE1 . TYR 278 278 ? A 2.44650 0.21481 -23.20613 1 1 A TYR 1 1
ATOM 2281 C CE2 . TYR 278 278 ? A 4.45628 -0.92608 -22.60218 1 1 A TYR 1 1
ATOM 2282 C CZ . TYR 278 278 ? A 3.58773 -0.45661 -23.57177 1 1 A TYR 1 1
ATOM 2283 O OH . TYR 278 278 ? A 3.86739 -0.64985 -24.88876 1 1 A TYR 1 1
ATOM 2284 N N . ASP 279 279 ? A 0.75850 -2.85661 -19.36852 1 1 A ASP 1 1
ATOM 2285 C CA . ASP 279 279 ? A 0.83164 -4.30286 -19.38309 1 1 A ASP 1 1
ATOM 2286 C C . ASP 279 279 ? A 0.88478 -4.89648 -20.77271 1 1 A ASP 1 1
ATOM 2287 O O . ASP 279 279 ? A 1.15100 -6.08352 -20.92447 1 1 A ASP 1 1
ATOM 2288 C CB . ASP 279 279 ? A -0.34749 -4.91597 -18.63029 1 1 A ASP 1 1
ATOM 2289 C CG . ASP 279 279 ? A -0.31121 -4.64925 -17.14442 1 1 A ASP 1 1
ATOM 2290 O OD1 . ASP 279 279 ? A 0.77577 -4.37270 -16.60617 1 1 A ASP 1 1
ATOM 2291 O OD2 . ASP 279 279 ? A -1.38111 -4.71008 -16.50981 1 1 A ASP 1 1
ATOM 2292 N N . GLY 280 280 ? A 0.65736 -4.12217 -21.79476 1 1 A GLY 1 1
ATOM 2293 C CA . GLY 280 280 ? A 0.58249 -4.61105 -23.15621 1 1 A GLY 1 1
ATOM 2294 C C . GLY 280 280 ? A 1.87937 -5.15610 -23.71034 1 1 A GLY 1 1
ATOM 2295 O O . GLY 280 280 ? A 1.86308 -6.08425 -24.51669 1 1 A GLY 1 1
ATOM 2296 N N . ASN 281 281 ? A 3.00728 -4.61620 -23.30046 1 1 A ASN 1 1
ATOM 2297 C CA . ASN 281 281 ? A 4.29481 -5.02958 -23.82792 1 1 A ASN 1 1
ATOM 2298 C C . ASN 281 281 ? A 4.98981 -6.09780 -22.99853 1 1 A ASN 1 1
ATOM 2299 O O . ASN 281 281 ? A 5.89350 -6.76508 -23.46576 1 1 A ASN 1 1
ATOM 2300 C CB . ASN 281 281 ? A 5.23321 -3.83033 -23.96804 1 1 A ASN 1 1
ATOM 2301 C CG . ASN 281 281 ? A 4.80567 -2.87005 -25.04256 1 1 A ASN 1 1
ATOM 2302 O OD1 . ASN 281 281 ? A 4.97741 -1.67318 -24.89790 1 1 A ASN 1 1
ATOM 2303 N ND2 . ASN 281 281 ? A 4.25627 -3.37685 -26.12259 1 1 A ASN 1 1
ATOM 2304 N N . GLY 282 282 ? A 4.60396 -6.26558 -21.76307 1 1 A GLY 1 1
ATOM 2305 C CA . GLY 282 282 ? A 5.26146 -7.21543 -20.88471 1 1 A GLY 1 1
ATOM 2306 C C . GLY 282 282 ? A 4.44500 -8.46633 -20.64492 1 1 A GLY 1 1
ATOM 2307 O O . GLY 282 282 ? A 3.22130 -8.41445 -20.54623 1 1 A GLY 1 1
ATOM 2308 N N . THR 283 283 ? A 5.11986 -9.57493 -20.55509 1 1 A THR 1 1
ATOM 2309 C CA . THR 283 283 ? A 4.50727 -10.83980 -20.22555 1 1 A THR 1 1
ATOM 2310 C C . THR 283 283 ? A 4.99629 -11.38467 -18.89581 1 1 A THR 1 1
ATOM 2311 O O . THR 283 283 ? A 4.61835 -12.47073 -18.48144 1 1 A THR 1 1
ATOM 2312 C CB . THR 283 283 ? A 4.73834 -11.89697 -21.31532 1 1 A THR 1 1
ATOM 2313 O OG1 . THR 283 283 ? A 6.13885 -12.13913 -21.44960 1 1 A THR 1 1
ATOM 2314 C CG2 . THR 283 283 ? A 4.17532 -11.43820 -22.65208 1 1 A THR 1 1
ATOM 2315 N N . GLU 284 284 ? A 5.88736 -10.61595 -18.20513 1 1 A GLU 1 1
ATOM 2316 C CA . GLU 284 284 ? A 6.42396 -11.05152 -16.92807 1 1 A GLU 1 1
ATOM 2317 C C . GLU 284 284 ? A 5.32657 -11.24983 -15.90681 1 1 A GLU 1 1
ATOM 2318 O O . GLU 284 284 ? A 4.35030 -10.48654 -15.86874 1 1 A GLU 1 1
ATOM 2319 C CB . GLU 284 284 ? A 7.38922 -10.02181 -16.36238 1 1 A GLU 1 1
ATOM 2320 C CG . GLU 284 284 ? A 8.64795 -9.80635 -17.15171 1 1 A GLU 1 1
ATOM 2321 C CD . GLU 284 284 ? A 9.50607 -8.71517 -16.53254 1 1 A GLU 1 1
ATOM 2322 O OE1 . GLU 284 284 ? A 9.04455 -7.57241 -16.45342 1 1 A GLU 1 1
ATOM 2323 O OE2 . GLU 284 284 ? A 10.61180 -9.02755 -16.08316 1 1 A GLU 1 1
ATOM 2324 N N . THR 285 285 ? A 5.47412 -12.22027 -15.06057 1 1 A THR 1 1
ATOM 2325 C CA . THR 285 285 ? A 4.59688 -12.39447 -13.92745 1 1 A THR 1 1
ATOM 2326 C C . THR 285 285 ? A 4.95257 -11.37452 -12.85865 1 1 A THR 1 1
ATOM 2327 O O . THR 285 285 ? A 6.04794 -10.80539 -12.87650 1 1 A THR 1 1
ATOM 2328 C CB . THR 285 285 ? A 4.70660 -13.80725 -13.33449 1 1 A THR 1 1
ATOM 2329 O OG1 . THR 285 285 ? A 6.00234 -13.97465 -12.75229 1 1 A THR 1 1
ATOM 2330 C CG2 . THR 285 285 ? A 4.47362 -14.86112 -14.40212 1 1 A THR 1 1
ATOM 2331 N N . ARG 286 286 ? A 4.07467 -11.15015 -11.91378 1 1 A ARG 1 1
ATOM 2332 C CA . ARG 286 286 ? A 4.34410 -10.23083 -10.82419 1 1 A ARG 1 1
ATOM 2333 C C . ARG 286 286 ? A 5.59387 -10.63605 -10.05260 1 1 A ARG 1 1
ATOM 2334 O O . ARG 286 286 ? A 6.38475 -9.78732 -9.63987 1 1 A ARG 1 1
ATOM 2335 C CB . ARG 286 286 ? A 3.14416 -10.13369 -9.89181 1 1 A ARG 1 1
ATOM 2336 C CG . ARG 286 286 ? A 1.96975 -9.38751 -10.49746 1 1 A ARG 1 1
ATOM 2337 C CD . ARG 286 286 ? A 0.77102 -9.36205 -9.54127 1 1 A ARG 1 1
ATOM 2338 N NE . ARG 286 286 ? A 1.07243 -8.70809 -8.28887 1 1 A ARG 1 1
ATOM 2339 C CZ . ARG 286 286 ? A 0.92925 -7.39900 -8.06785 1 1 A ARG 1 1
ATOM 2340 N NH1 . ARG 286 286 ? A 0.52197 -6.59183 -9.03748 1 1 A ARG 1 1
ATOM 2341 N NH2 . ARG 286 286 ? A 1.22951 -6.92454 -6.86951 1 1 A ARG 1 1
ATOM 2342 N N . GLY 287 287 ? A 5.78523 -11.95178 -9.86426 1 1 A GLY 1 1
ATOM 2343 C CA . GLY 287 287 ? A 6.95405 -12.44420 -9.16336 1 1 A GLY 1 1
ATOM 2344 C C . GLY 287 287 ? A 8.24324 -12.12052 -9.87362 1 1 A GLY 1 1
ATOM 2345 O O . GLY 287 287 ? A 9.23045 -11.71742 -9.26250 1 1 A GLY 1 1
ATOM 2346 N N . GLU 288 288 ? A 8.23046 -12.28885 -11.19674 1 1 A GLU 1 1
ATOM 2347 C CA . GLU 288 288 ? A 9.41192 -11.98109 -11.99847 1 1 A GLU 1 1
ATOM 2348 C C . GLU 288 288 ? A 9.73021 -10.49864 -11.96149 1 1 A GLU 1 1
ATOM 2349 O O . GLU 288 288 ? A 10.89822 -10.10901 -11.90798 1 1 A GLU 1 1
ATOM 2350 C CB . GLU 288 288 ? A 9.23008 -12.45327 -13.43895 1 1 A GLU 1 1
ATOM 2351 C CG . GLU 288 288 ? A 9.18919 -13.96530 -13.58384 1 1 A GLU 1 1
ATOM 2352 C CD . GLU 288 288 ? A 8.67742 -14.42403 -14.95089 1 1 A GLU 1 1
ATOM 2353 O OE1 . GLU 288 288 ? A 8.14917 -13.61199 -15.72710 1 1 A GLU 1 1
ATOM 2354 O OE2 . GLU 288 288 ? A 8.80002 -15.64004 -15.22229 1 1 A GLU 1 1
ATOM 2355 N N . VAL 289 289 ? A 8.68401 -9.69272 -11.95709 1 1 A VAL 1 1
ATOM 2356 C CA . VAL 289 289 ? A 8.89107 -8.25547 -11.92308 1 1 A VAL 1 1
ATOM 2357 C C . VAL 289 289 ? A 9.53125 -7.83975 -10.60427 1 1 A VAL 1 1
ATOM 2358 O O . VAL 289 289 ? A 10.50276 -7.07110 -10.58786 1 1 A VAL 1 1
ATOM 2359 C CB . VAL 289 289 ? A 7.56967 -7.50656 -12.14827 1 1 A VAL 1 1
ATOM 2360 C CG1 . VAL 289 289 ? A 7.76383 -6.00851 -11.94873 1 1 A VAL 1 1
ATOM 2361 C CG2 . VAL 289 289 ? A 7.03361 -7.78599 -13.53586 1 1 A VAL 1 1
ATOM 2362 N N . ILE 290 290 ? A 8.97671 -8.31528 -9.46693 1 1 A ILE 1 1
ATOM 2363 C CA . ILE 290 290 ? A 9.55239 -7.90219 -8.19061 1 1 A ILE 1 1
ATOM 2364 C C . ILE 290 290 ? A 10.98573 -8.39426 -8.04041 1 1 A ILE 1 1
ATOM 2365 O O . ILE 290 290 ? A 11.83315 -7.68774 -7.48365 1 1 A ILE 1 1
ATOM 2366 C CB . ILE 290 290 ? A 8.66851 -8.32393 -7.00336 1 1 A ILE 1 1
ATOM 2367 C CG1 . ILE 290 290 ? A 9.15578 -7.66098 -5.70994 1 1 A ILE 1 1
ATOM 2368 C CG2 . ILE 290 290 ? A 8.69774 -9.82735 -6.78652 1 1 A ILE 1 1
ATOM 2369 C CD1 . ILE 290 290 ? A 9.02444 -6.15192 -5.73925 1 1 A ILE 1 1
ATOM 2370 N N . LYS 291 291 ? A 11.28519 -9.59542 -8.53172 1 1 A LYS 1 1
ATOM 2371 C CA . LYS 291 291 ? A 12.65779 -10.07672 -8.50100 1 1 A LYS 1 1
ATOM 2372 C C . LYS 291 291 ? A 13.57057 -9.13476 -9.27363 1 1 A LYS 1 1
ATOM 2373 O O . LYS 291 291 ? A 14.63953 -8.75197 -8.77695 1 1 A LYS 1 1
ATOM 2374 C CB . LYS 291 291 ? A 12.75272 -11.48374 -9.08227 1 1 A LYS 1 1
ATOM 2375 C CG . LYS 291 291 ? A 14.18739 -12.01556 -9.15466 1 1 A LYS 1 1
ATOM 2376 C CD . LYS 291 291 ? A 14.27039 -13.34729 -9.88051 1 1 A LYS 1 1
ATOM 2377 C CE . LYS 291 291 ? A 15.66699 -13.92426 -9.85121 1 1 A LYS 1 1
ATOM 2378 N NZ . LYS 291 291 ? A 16.62237 -13.08169 -10.60833 1 1 A LYS 1 1
ATOM 2379 N N . ARG 292 292 ? A 13.16628 -8.78795 -10.48417 1 1 A ARG 1 1
ATOM 2380 C CA . ARG 292 292 ? A 13.96283 -7.89402 -11.32127 1 1 A ARG 1 1
ATOM 2381 C C . ARG 292 292 ? A 14.13295 -6.53463 -10.65305 1 1 A ARG 1 1
ATOM 2382 O O . ARG 292 292 ? A 15.23353 -5.96193 -10.66635 1 1 A ARG 1 1
ATOM 2383 C CB . ARG 292 292 ? A 13.29877 -7.73797 -12.69263 1 1 A ARG 1 1
ATOM 2384 C CG . ARG 292 292 ? A 13.97721 -6.73560 -13.57040 1 1 A ARG 1 1
ATOM 2385 C CD . ARG 292 292 ? A 13.44366 -6.79091 -14.98542 1 1 A ARG 1 1
ATOM 2386 N NE . ARG 292 292 ? A 11.99980 -6.66414 -15.06642 1 1 A ARG 1 1
ATOM 2387 C CZ . ARG 292 292 ? A 11.31936 -5.53313 -15.00096 1 1 A ARG 1 1
ATOM 2388 N NH1 . ARG 292 292 ? A 11.94377 -4.37818 -14.81154 1 1 A ARG 1 1
ATOM 2389 N NH2 . ARG 292 292 ? A 10.02131 -5.53789 -15.10700 1 1 A ARG 1 1
ATOM 2390 N N . ILE 293 293 ? A 13.08516 -6.00567 -10.05581 1 1 A ILE 1 1
ATOM 2391 C CA . ILE 293 293 ? A 13.14898 -4.70106 -9.41830 1 1 A ILE 1 1
ATOM 2392 C C . ILE 293 293 ? A 14.08165 -4.74266 -8.20611 1 1 A ILE 1 1
ATOM 2393 O O . ILE 293 293 ? A 14.89806 -3.82771 -8.02153 1 1 A ILE 1 1
ATOM 2394 C CB . ILE 293 293 ? A 11.75975 -4.20161 -9.00912 1 1 A ILE 1 1
ATOM 2395 C CG1 . ILE 293 293 ? A 10.87094 -3.99686 -10.23174 1 1 A ILE 1 1
ATOM 2396 C CG2 . ILE 293 293 ? A 11.84993 -2.95527 -8.14795 1 1 A ILE 1 1
ATOM 2397 C CD1 . ILE 293 293 ? A 11.35079 -2.95586 -11.17422 1 1 A ILE 1 1
ATOM 2398 N N . ILE 294 294 ? A 13.99307 -5.80005 -7.36945 1 1 A ILE 1 1
ATOM 2399 C CA . ILE 294 294 ? A 14.88126 -5.93648 -6.22381 1 1 A ILE 1 1
ATOM 2400 C C . ILE 294 294 ? A 16.33524 -6.02217 -6.68051 1 1 A ILE 1 1
ATOM 2401 O O . ILE 294 294 ? A 17.22130 -5.39900 -6.08029 1 1 A ILE 1 1
ATOM 2402 C CB . ILE 294 294 ? A 14.51482 -7.15787 -5.35418 1 1 A ILE 1 1
ATOM 2403 C CG1 . ILE 294 294 ? A 13.12690 -6.99338 -4.73039 1 1 A ILE 1 1
ATOM 2404 C CG2 . ILE 294 294 ? A 15.56697 -7.43264 -4.30109 1 1 A ILE 1 1
ATOM 2405 C CD1 . ILE 294 294 ? A 13.05746 -5.89754 -3.67380 1 1 A ILE 1 1
ATOM 2406 N N . ASP 295 295 ? A 16.58323 -6.78820 -7.74396 1 1 A ASP 1 1
ATOM 2407 C CA . ASP 295 295 ? A 17.92968 -6.87687 -8.28639 1 1 A ASP 1 1
ATOM 2408 C C . ASP 295 295 ? A 18.43826 -5.52225 -8.75870 1 1 A ASP 1 1
ATOM 2409 O O . ASP 295 295 ? A 19.62793 -5.22950 -8.66782 1 1 A ASP 1 1
ATOM 2410 C CB . ASP 295 295 ? A 17.99637 -7.88386 -9.43670 1 1 A ASP 1 1
ATOM 2411 C CG . ASP 295 295 ? A 17.87446 -9.33409 -8.98299 1 1 A ASP 1 1
ATOM 2412 O OD1 . ASP 295 295 ? A 17.99631 -9.59558 -7.77234 1 1 A ASP 1 1
ATOM 2413 O OD2 . ASP 295 295 ? A 17.66034 -10.21174 -9.84942 1 1 A ASP 1 1
ATOM 2414 N N . THR 296 296 ? A 17.53464 -4.67413 -9.28483 1 1 A THR 1 1
ATOM 2415 C CA . THR 296 296 ? A 17.88959 -3.36055 -9.80334 1 1 A THR 1 1
ATOM 2416 C C . THR 296 296 ? A 18.23119 -2.37555 -8.68848 1 1 A THR 1 1
ATOM 2417 O O . THR 296 296 ? A 19.24835 -1.67456 -8.75706 1 1 A THR 1 1
ATOM 2418 C CB . THR 296 296 ? A 16.75485 -2.80040 -10.66814 1 1 A THR 1 1
ATOM 2419 O OG1 . THR 296 296 ? A 16.57813 -3.63446 -11.80334 1 1 A THR 1 1
ATOM 2420 C CG2 . THR 296 296 ? A 17.04566 -1.36934 -11.11001 1 1 A THR 1 1
ATOM 2421 N N . ILE 297 297 ? A 17.38997 -2.29093 -7.62912 1 1 A ILE 1 1
ATOM 2422 C CA . ILE 297 297 ? A 17.60525 -1.29063 -6.59355 1 1 A ILE 1 1
ATOM 2423 C C . ILE 297 297 ? A 18.61865 -1.72570 -5.53884 1 1 A ILE 1 1
ATOM 2424 O O . ILE 297 297 ? A 19.10761 -0.88933 -4.77312 1 1 A ILE 1 1
ATOM 2425 C CB . ILE 297 297 ? A 16.28855 -0.88581 -5.89736 1 1 A ILE 1 1
ATOM 2426 C CG1 . ILE 297 297 ? A 15.68884 -2.04658 -5.09944 1 1 A ILE 1 1
ATOM 2427 C CG2 . ILE 297 297 ? A 15.29531 -0.35712 -6.92026 1 1 A ILE 1 1
ATOM 2428 C CD1 . ILE 297 297 ? A 14.46974 -1.67051 -4.29843 1 1 A ILE 1 1
ATOM 2429 N N . GLY 298 298 ? A 18.94090 -3.00166 -5.50224 1 1 A GLY 1 1
ATOM 2430 C CA . GLY 298 298 ? A 19.94139 -3.49182 -4.57571 1 1 A GLY 1 1
ATOM 2431 C C . GLY 298 298 ? A 19.39656 -4.52039 -3.60300 1 1 A GLY 1 1
ATOM 2432 O O . GLY 298 298 ? A 18.23870 -4.45940 -3.19153 1 1 A GLY 1 1
ATOM 2433 N N . ARG 299 299 ? A 20.26431 -5.45525 -3.25042 1 1 A ARG 1 1
ATOM 2434 C CA . ARG 299 299 ? A 19.88519 -6.58705 -2.43041 1 1 A ARG 1 1
ATOM 2435 C C . ARG 299 299 ? A 19.64995 -6.23195 -0.96008 1 1 A ARG 1 1
ATOM 2436 O O . ARG 299 299 ? A 19.20652 -7.08998 -0.19492 1 1 A ARG 1 1
ATOM 2437 C CB . ARG 299 299 ? A 20.91619 -7.70562 -2.53429 1 1 A ARG 1 1
ATOM 2438 C CG . ARG 299 299 ? A 21.19657 -8.15642 -3.96191 1 1 A ARG 1 1
ATOM 2439 C CD . ARG 299 299 ? A 20.00049 -8.80163 -4.61358 1 1 A ARG 1 1
ATOM 2440 N NE . ARG 299 299 ? A 19.72502 -10.07874 -3.94099 1 1 A ARG 1 1
ATOM 2441 C CZ . ARG 299 299 ? A 19.05260 -11.04759 -4.46318 1 1 A ARG 1 1
ATOM 2442 N NH1 . ARG 299 299 ? A 18.53677 -10.96082 -5.68483 1 1 A ARG 1 1
ATOM 2443 N NH2 . ARG 299 299 ? A 18.89195 -12.14034 -3.75350 1 1 A ARG 1 1
ATOM 2444 N N . ASP 300 300 ? A 19.92231 -4.99125 -0.56319 1 1 A ASP 1 1
ATOM 2445 C CA . ASP 300 300 ? A 19.68300 -4.61240 0.81622 1 1 A ASP 1 1
ATOM 2446 C C . ASP 300 300 ? A 18.25275 -4.14330 1.07024 1 1 A ASP 1 1
ATOM 2447 O O . ASP 300 300 ? A 17.94246 -3.72054 2.18709 1 1 A ASP 1 1
ATOM 2448 C CB . ASP 300 300 ? A 20.68685 -3.55804 1.27274 1 1 A ASP 1 1
ATOM 2449 C CG . ASP 300 300 ? A 20.62247 -2.25806 0.50538 1 1 A ASP 1 1
ATOM 2450 O OD1 . ASP 300 300 ? A 20.17013 -2.26933 -0.63841 1 1 A ASP 1 1
ATOM 2451 O OD2 . ASP 300 300 ? A 21.03183 -1.21539 1.05468 1 1 A ASP 1 1
ATOM 2452 N N . TYR 301 301 ? A 17.35585 -4.24381 0.09286 1 1 A TYR 1 1
ATOM 2453 C CA . TYR 301 301 ? A 15.96332 -3.87071 0.23760 1 1 A TYR 1 1
ATOM 2454 C C . TYR 301 301 ? A 15.05535 -5.07535 0.17712 1 1 A TYR 1 1
ATOM 2455 O O . TYR 301 301 ? A 15.38351 -6.06898 -0.47313 1 1 A TYR 1 1
ATOM 2456 C CB . TYR 301 301 ? A 15.54479 -2.87039 -0.84152 1 1 A TYR 1 1
ATOM 2457 C CG . TYR 301 301 ? A 16.20098 -1.51452 -0.72333 1 1 A TYR 1 1
ATOM 2458 C CD1 . TYR 301 301 ? A 15.73369 -0.57902 0.17735 1 1 A TYR 1 1
ATOM 2459 C CD2 . TYR 301 301 ? A 17.29371 -1.17968 -1.51559 1 1 A TYR 1 1
ATOM 2460 C CE1 . TYR 301 301 ? A 16.33375 0.66248 0.30059 1 1 A TYR 1 1
ATOM 2461 C CE2 . TYR 301 301 ? A 17.90276 0.05748 -1.40201 1 1 A TYR 1 1
ATOM 2462 C CZ . TYR 301 301 ? A 17.41841 0.97298 -0.48993 1 1 A TYR 1 1
ATOM 2463 O OH . TYR 301 301 ? A 18.01946 2.20077 -0.37523 1 1 A TYR 1 1
ATOM 2464 N N . TYR 302 302 ? A 13.94018 -5.01636 0.86842 1 1 A TYR 1 1
ATOM 2465 C CA . TYR 302 302 ? A 12.88714 -6.01897 0.77967 1 1 A TYR 1 1
ATOM 2466 C C . TYR 302 302 ? A 11.54193 -5.30614 0.79756 1 1 A TYR 1 1
ATOM 2467 O O . TYR 302 302 ? A 11.44967 -4.13908 1.19397 1 1 A TYR 1 1
ATOM 2468 C CB . TYR 302 302 ? A 13.00013 -7.05208 1.90113 1 1 A TYR 1 1
ATOM 2469 C CG . TYR 302 302 ? A 12.68927 -6.55094 3.29212 1 1 A TYR 1 1
ATOM 2470 C CD1 . TYR 302 302 ? A 13.65187 -5.87183 4.04719 1 1 A TYR 1 1
ATOM 2471 C CD2 . TYR 302 302 ? A 11.43972 -6.75517 3.86872 1 1 A TYR 1 1
ATOM 2472 C CE1 . TYR 302 302 ? A 13.37519 -5.41475 5.32816 1 1 A TYR 1 1
ATOM 2473 C CE2 . TYR 302 302 ? A 11.16405 -6.29179 5.16230 1 1 A TYR 1 1
ATOM 2474 C CZ . TYR 302 302 ? A 12.12777 -5.62304 5.88147 1 1 A TYR 1 1
ATOM 2475 O OH . TYR 302 302 ? A 11.82652 -5.18296 7.14982 1 1 A TYR 1 1
ATOM 2476 N N . VAL 303 303 ? A 10.48509 -6.00749 0.32496 1 1 A VAL 1 1
ATOM 2477 C CA . VAL 303 303 ? A 9.15710 -5.43702 0.30532 1 1 A VAL 1 1
ATOM 2478 C C . VAL 303 303 ? A 8.58034 -5.44949 1.71873 1 1 A VAL 1 1
ATOM 2479 O O . VAL 303 303 ? A 8.59937 -6.47554 2.39553 1 1 A VAL 1 1
ATOM 2480 C CB . VAL 303 303 ? A 8.22858 -6.20674 -0.66263 1 1 A VAL 1 1
ATOM 2481 C CG1 . VAL 303 303 ? A 6.79635 -5.70198 -0.54474 1 1 A VAL 1 1
ATOM 2482 C CG2 . VAL 303 303 ? A 8.72030 -6.06375 -2.08433 1 1 A VAL 1 1
ATOM 2483 N N . ASN 304 304 ? A 8.07382 -4.33952 2.12129 1 1 A ASN 1 1
ATOM 2484 C CA . ASN 304 304 ? A 7.41932 -4.17211 3.40523 1 1 A ASN 1 1
ATOM 2485 C C . ASN 304 304 ? A 6.08102 -3.48625 3.18121 1 1 A ASN 1 1
ATOM 2486 O O . ASN 304 304 ? A 6.00806 -2.26244 3.09429 1 1 A ASN 1 1
ATOM 2487 C CB . ASN 304 304 ? A 8.32160 -3.34999 4.33153 1 1 A ASN 1 1
ATOM 2488 C CG . ASN 304 304 ? A 7.73896 -3.16081 5.72246 1 1 A ASN 1 1
ATOM 2489 O OD1 . ASN 304 304 ? A 6.86626 -3.91190 6.13922 1 1 A ASN 1 1
ATOM 2490 N ND2 . ASN 304 304 ? A 8.22293 -2.15419 6.42529 1 1 A ASN 1 1
ATOM 2491 N N . GLY 305 305 ? A 5.01663 -4.26119 3.02031 1 1 A GLY 1 1
ATOM 2492 C CA . GLY 305 305 ? A 3.71416 -3.72380 2.67624 1 1 A GLY 1 1
ATOM 2493 C C . GLY 305 305 ? A 3.71336 -3.08646 1.30547 1 1 A GLY 1 1
ATOM 2494 O O . GLY 305 305 ? A 4.08858 -3.73853 0.31860 1 1 A GLY 1 1
ATOM 2495 N N . LYS 306 306 ? A 3.35980 -1.81372 1.24254 1 1 A LYS 1 1
ATOM 2496 C CA . LYS 306 306 ? A 3.34196 -1.09796 -0.02651 1 1 A LYS 1 1
ATOM 2497 C C . LYS 306 306 ? A 4.66265 -0.42905 -0.35416 1 1 A LYS 1 1
ATOM 2498 O O . LYS 306 306 ? A 4.79412 0.17942 -1.41608 1 1 A LYS 1 1
ATOM 2499 C CB . LYS 306 306 ? A 2.24070 -0.05288 -0.01071 1 1 A LYS 1 1
ATOM 2500 C CG . LYS 306 306 ? A 0.84179 -0.64607 -0.08527 1 1 A LYS 1 1
ATOM 2501 C CD . LYS 306 306 ? A -0.19540 0.40316 -0.38467 1 1 A LYS 1 1
ATOM 2502 C CE . LYS 306 306 ? A -1.53760 -0.19706 -0.69455 1 1 A LYS 1 1
ATOM 2503 N NZ . LYS 306 306 ? A -1.60905 -0.58637 -2.13147 1 1 A LYS 1 1
ATOM 2504 N N . TYR 307 307 ? A 5.62022 -0.60546 0.55242 1 1 A TYR 1 1
ATOM 2505 C CA . TYR 307 307 ? A 6.88387 0.09398 0.37946 1 1 A TYR 1 1
ATOM 2506 C C . TYR 307 307 ? A 8.07183 -0.84982 0.46976 1 1 A TYR 1 1
ATOM 2507 O O . TYR 307 307 ? A 7.89848 -2.07349 0.46038 1 1 A TYR 1 1
ATOM 2508 C CB . TYR 307 307 ? A 6.99156 1.21425 1.41936 1 1 A TYR 1 1
ATOM 2509 C CG . TYR 307 307 ? A 5.80677 2.15967 1.38179 1 1 A TYR 1 1
ATOM 2510 C CD1 . TYR 307 307 ? A 5.56087 2.94989 0.27634 1 1 A TYR 1 1
ATOM 2511 C CD2 . TYR 307 307 ? A 4.93820 2.25680 2.45625 1 1 A TYR 1 1
ATOM 2512 C CE1 . TYR 307 307 ? A 4.48203 3.80982 0.22801 1 1 A TYR 1 1
ATOM 2513 C CE2 . TYR 307 307 ? A 3.85739 3.11861 2.41863 1 1 A TYR 1 1
ATOM 2514 C CZ . TYR 307 307 ? A 3.63528 3.89541 1.30106 1 1 A TYR 1 1
ATOM 2515 O OH . TYR 307 307 ? A 2.56347 4.74727 1.25745 1 1 A TYR 1 1
ATOM 2516 N N . PHE 308 308 ? A 9.25864 -0.34372 0.48836 1 1 A PHE 1 1
ATOM 2517 C CA . PHE 308 308 ? A 10.47196 -1.09646 0.72732 1 1 A PHE 1 1
ATOM 2518 C C . PHE 308 308 ? A 11.03316 -0.73467 2.10034 1 1 A PHE 1 1
ATOM 2519 O O . PHE 308 308 ? A 10.74997 0.34470 2.62609 1 1 A PHE 1 1
ATOM 2520 C CB . PHE 308 308 ? A 11.52650 -0.78416 -0.33424 1 1 A PHE 1 1
ATOM 2521 C CG . PHE 308 308 ? A 11.20606 -1.27494 -1.71383 1 1 A PHE 1 1
ATOM 2522 C CD1 . PHE 308 308 ? A 11.14079 -2.62441 -1.98794 1 1 A PHE 1 1
ATOM 2523 C CD2 . PHE 308 308 ? A 10.98373 -0.37776 -2.74874 1 1 A PHE 1 1
ATOM 2524 C CE1 . PHE 308 308 ? A 10.86054 -3.07754 -3.26566 1 1 A PHE 1 1
ATOM 2525 C CE2 . PHE 308 308 ? A 10.70255 -0.82177 -4.02918 1 1 A PHE 1 1
ATOM 2526 C CZ . PHE 308 308 ? A 10.64918 -2.17674 -4.28643 1 1 A PHE 1 1
ATOM 2527 N N . SER 309 309 ? A 11.81058 -1.64387 2.66796 1 1 A SER 1 1
ATOM 2528 C CA . SER 309 309 ? A 12.61051 -1.42969 3.85218 1 1 A SER 1 1
ATOM 2529 C C . SER 309 309 ? A 14.01555 -1.94154 3.60616 1 1 A SER 1 1
ATOM 2530 O O . SER 309 309 ? A 14.22992 -2.75147 2.70553 1 1 A SER 1 1
ATOM 2531 C CB . SER 309 309 ? A 12.01576 -2.12942 5.06254 1 1 A SER 1 1
ATOM 2532 O OG . SER 309 309 ? A 10.80815 -1.50290 5.45902 1 1 A SER 1 1
ATOM 2533 N N . LYS 310 310 ? A 14.99634 -1.48789 4.40197 1 1 A LYS 1 1
ATOM 2534 C CA . LYS 310 310 ? A 16.35001 -1.99148 4.31245 1 1 A LYS 1 1
ATOM 2535 C C . LYS 310 310 ? A 16.56720 -3.13340 5.27679 1 1 A LYS 1 1
ATOM 2536 O O . LYS 310 310 ? A 16.07079 -3.10924 6.39194 1 1 A LYS 1 1
ATOM 2537 C CB . LYS 310 310 ? A 17.35414 -0.88542 4.61492 1 1 A LYS 1 1
ATOM 2538 C CG . LYS 310 310 ? A 17.59871 0.08421 3.48170 1 1 A LYS 1 1
ATOM 2539 C CD . LYS 310 310 ? A 18.74199 1.02174 3.81365 1 1 A LYS 1 1
ATOM 2540 C CE . LYS 310 310 ? A 19.19827 1.83062 2.63062 1 1 A LYS 1 1
ATOM 2541 N NZ . LYS 310 310 ? A 20.30252 2.75285 2.97725 1 1 A LYS 1 1
ATOM 2542 N N . VAL 311 311 ? A 17.31798 -4.15609 4.84086 1 1 A VAL 1 1
ATOM 2543 C CA . VAL 311 311 ? A 17.75210 -5.20329 5.73622 1 1 A VAL 1 1
ATOM 2544 C C . VAL 311 311 ? A 18.90382 -4.67113 6.58497 1 1 A VAL 1 1
ATOM 2545 O O . VAL 311 311 ? A 19.38364 -3.56895 6.35264 1 1 A VAL 1 1
ATOM 2546 C CB . VAL 311 311 ? A 18.18691 -6.47237 4.97928 1 1 A VAL 1 1
ATOM 2547 C CG1 . VAL 311 311 ? A 16.99874 -7.06438 4.24208 1 1 A VAL 1 1
ATOM 2548 C CG2 . VAL 311 311 ? A 19.34039 -6.20772 4.02760 1 1 A VAL 1 1
ATOM 2549 N N . GLY 312 312 ? A 19.34737 -5.39965 7.52505 1 1 A GLY 1 1
ATOM 2550 C CA . GLY 312 312 ? A 20.45825 -5.01408 8.36980 1 1 A GLY 1 1
ATOM 2551 C C . GLY 312 312 ? A 20.03596 -4.82613 9.81777 1 1 A GLY 1 1
ATOM 2552 O O . GLY 312 312 ? A 19.95296 -5.80398 10.57568 1 1 A GLY 1 1
ATOM 2553 N N . ILE 313 313 ? A 19.69394 -3.64254 10.21523 1 1 A ILE 1 1
ATOM 2554 C CA . ILE 313 313 ? A 19.26951 -3.35632 11.57321 1 1 A ILE 1 1
ATOM 2555 C C . ILE 313 313 ? A 18.08189 -4.22891 11.96282 1 1 A ILE 1 1
ATOM 2556 O O . ILE 313 313 ? A 18.03631 -4.78250 13.05765 1 1 A ILE 1 1
ATOM 2557 C CB . ILE 313 313 ? A 18.91826 -1.87022 11.74888 1 1 A ILE 1 1
ATOM 2558 C CG1 . ILE 313 313 ? A 20.16851 -1.00903 11.49449 1 1 A ILE 1 1
ATOM 2559 C CG2 . ILE 313 313 ? A 18.37036 -1.60098 13.13940 1 1 A ILE 1 1
ATOM 2560 C CD1 . ILE 313 313 ? A 19.87706 0.47323 11.44514 1 1 A ILE 1 1
ATOM 2561 N N . ALA 314 314 ? A 17.11034 -4.35503 11.10933 1 1 A ALA 1 1
ATOM 2562 C CA . ALA 314 314 ? A 15.95220 -5.18625 11.38215 1 1 A ALA 1 1
ATOM 2563 C C . ALA 314 314 ? A 16.31496 -6.65061 11.55789 1 1 A ALA 1 1
ATOM 2564 O O . ALA 314 314 ? A 15.73968 -7.33823 12.41150 1 1 A ALA 1 1
ATOM 2565 C CB . ALA 314 314 ? A 14.91993 -5.02471 10.27676 1 1 A ALA 1 1
ATOM 2566 N N . GLY 315 315 ? A 17.26433 -7.15411 10.77449 1 1 A GLY 1 1
ATOM 2567 C CA . GLY 315 315 ? A 17.70852 -8.52894 10.89824 1 1 A GLY 1 1
ATOM 2568 C C . GLY 315 315 ? A 18.37781 -8.82009 12.21998 1 1 A GLY 1 1
ATOM 2569 O O . GLY 315 315 ? A 18.13708 -9.86206 12.83303 1 1 A GLY 1 1
ATOM 2570 N N . LEU 316 316 ? A 19.21963 -7.90679 12.70414 1 1 A LEU 1 1
ATOM 2571 C CA . LEU 316 316 ? A 19.85668 -8.08004 14.00338 1 1 A LEU 1 1
ATOM 2572 C C . LEU 316 316 ? A 18.83436 -8.11653 15.12297 1 1 A LEU 1 1
ATOM 2573 O O . LEU 316 316 ? A 18.96748 -8.91260 16.05574 1 1 A LEU 1 1
ATOM 2574 C CB . LEU 316 316 ? A 20.88676 -6.98010 14.25983 1 1 A LEU 1 1
ATOM 2575 C CG . LEU 316 316 ? A 22.16981 -7.04723 13.45284 1 1 A LEU 1 1
ATOM 2576 C CD1 . LEU 316 316 ? A 23.08130 -5.89132 13.80512 1 1 A LEU 1 1
ATOM 2577 C CD2 . LEU 316 316 ? A 22.88770 -8.36639 13.67356 1 1 A LEU 1 1
ATOM 2578 N N . LYS 317 317 ? A 17.80925 -7.27539 15.04426 1 1 A LYS 1 1
ATOM 2579 C CA . LYS 317 317 ? A 16.77848 -7.26418 16.06958 1 1 A LYS 1 1
ATOM 2580 C C . LYS 317 317 ? A 16.04895 -8.59957 16.11621 1 1 A LYS 1 1
ATOM 2581 O O . LYS 317 317 ? A 15.78525 -9.12986 17.20124 1 1 A LYS 1 1
ATOM 2582 C CB . LYS 317 317 ? A 15.79060 -6.12903 15.83976 1 1 A LYS 1 1
ATOM 2583 C CG . LYS 317 317 ? A 14.73186 -6.02549 16.93527 1 1 A LYS 1 1
ATOM 2584 C CD . LYS 317 317 ? A 13.77542 -4.87645 16.70304 1 1 A LYS 1 1
ATOM 2585 C CE . LYS 317 317 ? A 12.70698 -4.82642 17.77801 1 1 A LYS 1 1
ATOM 2586 N NZ . LYS 317 317 ? A 11.67655 -3.79350 17.49805 1 1 A LYS 1 1
ATOM 2587 N N . GLN 318 318 ? A 15.73204 -9.15576 14.95833 1 1 A GLN 1 1
ATOM 2588 C CA . GLN 318 318 ? A 15.07520 -10.46036 14.90321 1 1 A GLN 1 1
ATOM 2589 C C . GLN 318 318 ? A 15.94099 -11.54173 15.51336 1 1 A GLN 1 1
ATOM 2590 O O . GLN 318 318 ? A 15.43449 -12.40763 16.24928 1 1 A GLN 1 1
ATOM 2591 C CB . GLN 318 318 ? A 14.69006 -10.82559 13.46907 1 1 A GLN 1 1
ATOM 2592 C CG . GLN 318 318 ? A 13.59038 -9.94776 12.90106 1 1 A GLN 1 1
ATOM 2593 C CD . GLN 318 318 ? A 13.25930 -10.33244 11.47286 1 1 A GLN 1 1
ATOM 2594 O OE1 . GLN 318 318 ? A 13.96768 -9.95919 10.55785 1 1 A GLN 1 1
ATOM 2595 N NE2 . GLN 318 318 ? A 12.19067 -11.08625 11.30620 1 1 A GLN 1 1
ATOM 2596 N N . LEU 319 319 ? A 17.23295 -11.53921 15.22278 1 1 A LEU 1 1
ATOM 2597 C CA . LEU 319 319 ? A 18.13675 -12.52017 15.80493 1 1 A LEU 1 1
ATOM 2598 C C . LEU 319 319 ? A 18.20913 -12.39051 17.31288 1 1 A LEU 1 1
ATOM 2599 O O . LEU 319 319 ? A 18.13344 -13.39920 18.02640 1 1 A LEU 1 1
ATOM 2600 C CB . LEU 319 319 ? A 19.54383 -12.38459 15.22031 1 1 A LEU 1 1
ATOM 2601 C CG . LEU 319 319 ? A 19.79725 -13.05063 13.88965 1 1 A LEU 1 1
ATOM 2602 C CD1 . LEU 319 319 ? A 21.18154 -12.70693 13.38729 1 1 A LEU 1 1
ATOM 2603 C CD2 . LEU 319 319 ? A 19.64309 -14.56044 14.01023 1 1 A LEU 1 1
ATOM 2604 N N . THR 320 320 ? A 18.37748 -11.16541 17.82538 1 1 A THR 1 1
ATOM 2605 C CA . THR 320 320 ? A 18.46761 -10.99039 19.26407 1 1 A THR 1 1
ATOM 2606 C C . THR 320 320 ? A 17.19213 -11.42810 19.96883 1 1 A THR 1 1
ATOM 2607 O O . THR 320 320 ? A 17.25350 -12.02166 21.05263 1 1 A THR 1 1
ATOM 2608 C CB . THR 320 320 ? A 18.82171 -9.55113 19.65984 1 1 A THR 1 1
ATOM 2609 O OG1 . THR 320 320 ? A 17.83157 -8.64878 19.18508 1 1 A THR 1 1
ATOM 2610 C CG2 . THR 320 320 ? A 20.17289 -9.14930 19.11186 1 1 A THR 1 1
ATOM 2611 N N . ASN 321 321 ? A 16.03495 -11.16247 19.36016 1 1 A ASN 1 1
ATOM 2612 C CA . ASN 321 321 ? A 14.76559 -11.60656 19.92605 1 1 A ASN 1 1
ATOM 2613 C C . ASN 321 321 ? A 14.66781 -13.11946 19.94786 1 1 A ASN 1 1
ATOM 2614 O O . ASN 321 321 ? A 14.30301 -13.70572 20.97837 1 1 A ASN 1 1
ATOM 2615 C CB . ASN 321 321 ? A 13.58827 -11.02128 19.15420 1 1 A ASN 1 1
ATOM 2616 C CG . ASN 321 321 ? A 13.39430 -9.53882 19.41197 1 1 A ASN 1 1
ATOM 2617 O OD1 . ASN 321 321 ? A 13.89322 -8.98973 20.40176 1 1 A ASN 1 1
ATOM 2618 N ND2 . ASN 321 321 ? A 12.66434 -8.87008 18.52813 1 1 A ASN 1 1
ATOM 2619 N N . LYS 322 322 ? A 14.99420 -13.77870 18.82300 1 1 A LYS 1 1
ATOM 2620 C CA . LYS 322 322 ? A 14.92452 -15.23088 18.74292 1 1 A LYS 1 1
ATOM 2621 C C . LYS 322 322 ? A 15.90175 -15.89647 19.69618 1 1 A LYS 1 1
ATOM 2622 O O . LYS 322 322 ? A 15.58210 -16.93543 20.28106 1 1 A LYS 1 1
ATOM 2623 C CB . LYS 322 322 ? A 15.16992 -15.68814 17.31348 1 1 A LYS 1 1
ATOM 2624 C CG . LYS 322 322 ? A 15.02771 -17.18694 17.07953 1 1 A LYS 1 1
ATOM 2625 C CD . LYS 322 322 ? A 13.67880 -17.73635 17.53184 1 1 A LYS 1 1
ATOM 2626 C CE . LYS 322 322 ? A 12.62078 -17.70731 16.47498 1 1 A LYS 1 1
ATOM 2627 N NZ . LYS 322 322 ? A 11.39475 -18.42616 16.88742 1 1 A LYS 1 1
ATOM 2628 N N . LEU 323 323 ? A 17.08778 -15.31442 19.89323 1 1 A LEU 1 1
ATOM 2629 C CA . LEU 323 323 ? A 18.10050 -15.87279 20.77997 1 1 A LEU 1 1
ATOM 2630 C C . LEU 323 323 ? A 17.90788 -15.45463 22.23494 1 1 A LEU 1 1
ATOM 2631 O O . LEU 323 323 ? A 18.59784 -15.96458 23.12202 1 1 A LEU 1 1
ATOM 2632 C CB . LEU 323 323 ? A 19.50315 -15.45043 20.31523 1 1 A LEU 1 1
ATOM 2633 C CG . LEU 323 323 ? A 19.92754 -15.95593 18.94163 1 1 A LEU 1 1
ATOM 2634 C CD1 . LEU 323 323 ? A 21.25343 -15.35229 18.53567 1 1 A LEU 1 1
ATOM 2635 C CD2 . LEU 323 323 ? A 20.01184 -17.47224 18.92231 1 1 A LEU 1 1
ATOM 2636 N N . ASP 324 324 ? A 16.97041 -14.55747 22.47863 1 1 A ASP 1 1
ATOM 2637 C CA . ASP 324 324 ? A 16.66185 -14.05341 23.80813 1 1 A ASP 1 1
ATOM 2638 C C . ASP 324 324 ? A 17.89462 -13.44413 24.48311 1 1 A ASP 1 1
ATOM 2639 O O . ASP 324 324 ? A 18.24873 -13.77742 25.61094 1 1 A ASP 1 1
ATOM 2640 C CB . ASP 324 324 ? A 16.05585 -15.15139 24.68472 1 1 A ASP 1 1
ATOM 2641 C CG . ASP 324 324 ? A 15.37715 -14.61812 25.93760 1 1 A ASP 1 1
ATOM 2642 O OD1 . ASP 324 324 ? A 14.97581 -13.43720 25.94326 1 1 A ASP 1 1
ATOM 2643 O OD2 . ASP 324 324 ? A 15.24444 -15.37347 26.92366 1 1 A ASP 1 1
ATOM 2644 N N . ILE 325 325 ? A 18.56622 -12.55292 23.78931 1 1 A ILE 1 1
ATOM 2645 C CA . ILE 325 325 ? A 19.69970 -11.79561 24.30801 1 1 A ILE 1 1
ATOM 2646 C C . ILE 325 325 ? A 19.42820 -10.30235 24.14465 1 1 A ILE 1 1
ATOM 2647 O O . ILE 325 325 ? A 18.41470 -9.92739 23.53906 1 1 A ILE 1 1
ATOM 2648 C CB . ILE 325 325 ? A 21.02084 -12.19392 23.62681 1 1 A ILE 1 1
ATOM 2649 C CG1 . ILE 325 325 ? A 20.95281 -11.92298 22.12654 1 1 A ILE 1 1
ATOM 2650 C CG2 . ILE 325 325 ? A 21.35991 -13.64328 23.90810 1 1 A ILE 1 1
ATOM 2651 C CD1 . ILE 325 325 ? A 22.26098 -12.17719 21.40441 1 1 A ILE 1 1
ATOM 2652 N N . ASN 326 326 ? A 20.31281 -9.45899 24.66382 1 1 A ASN 1 1
ATOM 2653 C CA . ASN 326 326 ? A 20.14194 -8.01613 24.54389 1 1 A ASN 1 1
ATOM 2654 C C . ASN 326 326 ? A 20.22359 -7.57306 23.09512 1 1 A ASN 1 1
ATOM 2655 O O . ASN 326 326 ? A 20.97606 -8.14542 22.29796 1 1 A ASN 1 1
ATOM 2656 C CB . ASN 326 326 ? A 21.21356 -7.27388 25.34902 1 1 A ASN 1 1
ATOM 2657 C CG . ASN 326 326 ? A 21.07380 -7.47802 26.83719 1 1 A ASN 1 1
ATOM 2658 O OD1 . ASN 326 326 ? A 19.95907 -7.64272 27.34949 1 1 A ASN 1 1
ATOM 2659 N ND2 . ASN 326 326 ? A 22.18365 -7.48629 27.55696 1 1 A ASN 1 1
ATOM 2660 N N . GLU 327 327 ? A 19.44999 -6.56276 22.73592 1 1 A GLU 1 1
ATOM 2661 C CA . GLU 327 327 ? A 19.42390 -6.04266 21.38310 1 1 A GLU 1 1
ATOM 2662 C C . GLU 327 327 ? A 20.79852 -5.49582 21.00199 1 1 A GLU 1 1
ATOM 2663 O O . GLU 327 327 ? A 21.43510 -4.79064 21.79436 1 1 A GLU 1 1
ATOM 2664 C CB . GLU 327 327 ? A 18.37365 -4.95023 21.25395 1 1 A GLU 1 1
ATOM 2665 C CG . GLU 327 327 ? A 18.18089 -4.47247 19.80970 1 1 A GLU 1 1
ATOM 2666 C CD . GLU 327 327 ? A 17.01087 -3.51428 19.67690 1 1 A GLU 1 1
ATOM 2667 O OE1 . GLU 327 327 ? A 16.13935 -3.48089 20.54951 1 1 A GLU 1 1
ATOM 2668 O OE2 . GLU 327 327 ? A 16.98274 -2.78453 18.67126 1 1 A GLU 1 1
ATOM 2669 N N . CYS 328 328 ? A 21.24396 -5.79906 19.79591 1 1 A CYS 1 1
ATOM 2670 C CA . CYS 328 328 ? A 22.54222 -5.40115 19.30468 1 1 A CYS 1 1
ATOM 2671 C C . CYS 328 328 ? A 22.42149 -4.56052 18.05243 1 1 A CYS 1 1
ATOM 2672 O O . CYS 328 328 ? A 21.52075 -4.79192 17.24268 1 1 A CYS 1 1
ATOM 2673 C CB . CYS 328 328 ? A 23.40096 -6.62283 19.02416 1 1 A CYS 1 1
ATOM 2674 S SG . CYS 328 328 ? A 23.58170 -7.71487 20.44663 1 1 A CYS 1 1
ATOM 2675 N N . ALA 329 329 ? A 23.33451 -3.62503 17.86776 1 1 A ALA 1 1
ATOM 2676 C CA . ALA 329 329 ? A 23.30990 -2.75840 16.70953 1 1 A ALA 1 1
ATOM 2677 C C . ALA 329 329 ? A 24.35264 -3.13333 15.66474 1 1 A ALA 1 1
ATOM 2678 O O . ALA 329 329 ? A 24.28330 -2.65178 14.53180 1 1 A ALA 1 1
ATOM 2679 C CB . ALA 329 329 ? A 23.51413 -1.30740 17.14172 1 1 A ALA 1 1
ATOM 2680 N N . THR 330 330 ? A 25.31683 -3.94971 16.03004 1 1 A THR 1 1
ATOM 2681 C CA . THR 330 330 ? A 26.36700 -4.38475 15.11885 1 1 A THR 1 1
ATOM 2682 C C . THR 330 330 ? A 26.65097 -5.86415 15.30711 1 1 A THR 1 1
ATOM 2683 O O . THR 330 330 ? A 26.28061 -6.45256 16.32686 1 1 A THR 1 1
ATOM 2684 C CB . THR 330 330 ? A 27.67185 -3.60617 15.33897 1 1 A THR 1 1
ATOM 2685 O OG1 . THR 330 330 ? A 28.22856 -3.94275 16.60970 1 1 A THR 1 1
ATOM 2686 C CG2 . THR 330 330 ? A 27.44421 -2.10301 15.26608 1 1 A THR 1 1
ATOM 2687 N N . VAL 331 331 ? A 27.33261 -6.47752 14.34561 1 1 A VAL 1 1
ATOM 2688 C CA . VAL 331 331 ? A 27.74759 -7.86538 14.47123 1 1 A VAL 1 1
ATOM 2689 C C . VAL 331 331 ? A 28.73411 -8.02439 15.62233 1 1 A VAL 1 1
ATOM 2690 O O . VAL 331 331 ? A 28.70851 -9.03308 16.33555 1 1 A VAL 1 1
ATOM 2691 C CB . VAL 331 331 ? A 28.33844 -8.38546 13.14612 1 1 A VAL 1 1
ATOM 2692 C CG1 . VAL 331 331 ? A 28.93666 -9.77416 13.32164 1 1 A VAL 1 1
ATOM 2693 C CG2 . VAL 331 331 ? A 27.27788 -8.40714 12.06283 1 1 A VAL 1 1
ATOM 2694 N N . ASP 332 332 ? A 29.60864 -7.04999 15.84847 1 1 A ASP 1 1
ATOM 2695 C CA . ASP 332 332 ? A 30.52640 -7.09268 16.97856 1 1 A ASP 1 1
ATOM 2696 C C . ASP 332 332 ? A 29.78827 -7.18876 18.30597 1 1 A ASP 1 1
ATOM 2697 O O . ASP 332 332 ? A 30.15485 -8.00047 19.16708 1 1 A ASP 1 1
ATOM 2698 C CB . ASP 332 332 ? A 31.44551 -5.86707 16.98641 1 1 A ASP 1 1
ATOM 2699 C CG . ASP 332 332 ? A 32.48177 -5.88463 15.88002 1 1 A ASP 1 1
ATOM 2700 O OD1 . ASP 332 332 ? A 32.78716 -6.97063 15.37122 1 1 A ASP 1 1
ATOM 2701 O OD2 . ASP 332 332 ? A 32.98899 -4.80667 15.52783 1 1 A ASP 1 1
ATOM 2702 N N . GLU 333 333 ? A 28.74606 -6.39039 18.47242 1 1 A GLU 1 1
ATOM 2703 C CA . GLU 333 333 ? A 27.93426 -6.44783 19.68381 1 1 A GLU 1 1
ATOM 2704 C C . GLU 333 333 ? A 27.24007 -7.79015 19.81985 1 1 A GLU 1 1
ATOM 2705 O O . GLU 333 333 ? A 27.14432 -8.34125 20.92822 1 1 A GLU 1 1
ATOM 2706 C CB . GLU 333 333 ? A 26.90472 -5.31412 19.71599 1 1 A GLU 1 1
ATOM 2707 C CG . GLU 333 333 ? A 27.51625 -3.94023 19.91337 1 1 A GLU 1 1
ATOM 2708 C CD . GLU 333 333 ? A 26.48938 -2.81031 19.83278 1 1 A GLU 1 1
ATOM 2709 O OE1 . GLU 333 333 ? A 25.28895 -3.08563 19.66188 1 1 A GLU 1 1
ATOM 2710 O OE2 . GLU 333 333 ? A 26.90292 -1.64137 19.94402 1 1 A GLU 1 1
ATOM 2711 N N . LEU 334 334 ? A 26.74751 -8.32730 18.69799 1 1 A LEU 1 1
ATOM 2712 C CA . LEU 334 334 ? A 26.08683 -9.62745 18.70229 1 1 A LEU 1 1
ATOM 2713 C C . LEU 334 334 ? A 27.04876 -10.71138 19.17451 1 1 A LEU 1 1
ATOM 2714 O O . LEU 334 334 ? A 26.67822 -11.55869 20.00975 1 1 A LEU 1 1
ATOM 2715 C CB . LEU 334 334 ? A 25.53811 -9.93908 17.31063 1 1 A LEU 1 1
ATOM 2716 C CG . LEU 334 334 ? A 24.74018 -11.22998 17.15730 1 1 A LEU 1 1
ATOM 2717 C CD1 . LEU 334 334 ? A 23.53018 -11.23873 18.08028 1 1 A LEU 1 1
ATOM 2718 C CD2 . LEU 334 334 ? A 24.29820 -11.39348 15.70623 1 1 A LEU 1 1
ATOM 2719 N N . VAL 335 335 ? A 28.26649 -10.73654 18.65927 1 1 A VAL 1 1
ATOM 2720 C CA . VAL 335 335 ? A 29.26875 -11.70735 19.07447 1 1 A VAL 1 1
ATOM 2721 C C . VAL 335 335 ? A 29.55740 -11.58180 20.56417 1 1 A VAL 1 1
ATOM 2722 O O . VAL 335 335 ? A 29.63975 -12.59130 21.27656 1 1 A VAL 1 1
ATOM 2723 C CB . VAL 335 335 ? A 30.55986 -11.55961 18.24464 1 1 A VAL 1 1
ATOM 2724 C CG1 . VAL 335 335 ? A 31.67963 -12.41452 18.81843 1 1 A VAL 1 1
ATOM 2725 C CG2 . VAL 335 335 ? A 30.30322 -11.92330 16.80187 1 1 A VAL 1 1
ATOM 2726 N N . ASP 336 336 ? A 29.71414 -10.34742 21.06227 1 1 A ASP 1 1
ATOM 2727 C CA . ASP 336 336 ? A 29.96082 -10.13170 22.48289 1 1 A ASP 1 1
ATOM 2728 C C . ASP 336 336 ? A 28.83095 -10.67023 23.34571 1 1 A ASP 1 1
ATOM 2729 O O . ASP 336 336 ? A 29.08441 -11.31661 24.36791 1 1 A ASP 1 1
ATOM 2730 C CB . ASP 336 336 ? A 30.18570 -8.64820 22.77399 1 1 A ASP 1 1
ATOM 2731 C CG . ASP 336 336 ? A 31.50183 -8.12494 22.22062 1 1 A ASP 1 1
ATOM 2732 O OD1 . ASP 336 336 ? A 32.38805 -8.94482 21.91088 1 1 A ASP 1 1
ATOM 2733 O OD2 . ASP 336 336 ? A 31.64892 -6.89088 22.10630 1 1 A ASP 1 1
ATOM 2734 N N . GLU 337 337 ? A 27.57547 -10.41746 22.95651 1 1 A GLU 1 1
ATOM 2735 C CA . GLU 337 337 ? A 26.43006 -10.89749 23.71917 1 1 A GLU 1 1
ATOM 2736 C C . GLU 337 337 ? A 26.34252 -12.41684 23.70981 1 1 A GLU 1 1
ATOM 2737 O O . GLU 337 337 ? A 26.00410 -13.02561 24.73420 1 1 A GLU 1 1
ATOM 2738 C CB . GLU 337 337 ? A 25.13220 -10.29591 23.21028 1 1 A GLU 1 1
ATOM 2739 C CG . GLU 337 337 ? A 24.92037 -8.86899 23.62350 1 1 A GLU 1 1
ATOM 2740 C CD . GLU 337 337 ? A 24.81454 -8.72986 25.14624 1 1 A GLU 1 1
ATOM 2741 O OE1 . GLU 337 337 ? A 23.98198 -9.44061 25.72866 1 1 A GLU 1 1
ATOM 2742 O OE2 . GLU 337 337 ? A 25.56997 -7.93060 25.71600 1 1 A GLU 1 1
ATOM 2743 N N . ILE 338 338 ? A 26.63456 -13.05337 22.59036 1 1 A ILE 1 1
ATOM 2744 C CA . ILE 338 338 ? A 26.60267 -14.50651 22.50710 1 1 A ILE 1 1
ATOM 2745 C C . ILE 338 338 ? A 27.67783 -15.11261 23.39232 1 1 A ILE 1 1
ATOM 2746 O O . ILE 338 338 ? A 27.45140 -16.14419 24.04412 1 1 A ILE 1 1
ATOM 2747 C CB . ILE 338 338 ? A 26.72959 -14.97609 21.05259 1 1 A ILE 1 1
ATOM 2748 C CG1 . ILE 338 338 ? A 25.48263 -14.56212 20.26794 1 1 A ILE 1 1
ATOM 2749 C CG2 . ILE 338 338 ? A 26.94608 -16.47386 20.97894 1 1 A ILE 1 1
ATOM 2750 C CD1 . ILE 338 338 ? A 25.61060 -14.74141 18.76707 1 1 A ILE 1 1
ATOM 2751 N N . ASN 339 339 ? A 28.86120 -14.49029 23.43664 1 1 A ASN 1 1
ATOM 2752 C CA . ASN 339 339 ? A 29.93998 -14.97865 24.28205 1 1 A ASN 1 1
ATOM 2753 C C . ASN 339 339 ? A 29.59177 -14.94930 25.76910 1 1 A ASN 1 1
ATOM 2754 O O . ASN 339 339 ? A 30.17525 -15.69243 26.55682 1 1 A ASN 1 1
ATOM 2755 C CB . ASN 339 339 ? A 31.22351 -14.18303 24.04698 1 1 A ASN 1 1
ATOM 2756 C CG . ASN 339 339 ? A 32.03046 -14.70358 22.87362 1 1 A ASN 1 1
ATOM 2757 O OD1 . ASN 339 339 ? A 32.04290 -15.90371 22.60060 1 1 A ASN 1 1
ATOM 2758 N ND2 . ASN 339 339 ? A 32.73621 -13.82113 22.18904 1 1 A ASN 1 1
ATOM 2759 N N . LYS 340 340 ? A 28.61328 -14.13318 26.17099 1 1 A LYS 1 1
ATOM 2760 C CA . LYS 340 340 ? A 28.19004 -14.05636 27.56103 1 1 A LYS 1 1
ATOM 2761 C C . LYS 340 340 ? A 27.29332 -15.20772 27.98133 1 1 A LYS 1 1
ATOM 2762 O O . LYS 340 340 ? A 27.08665 -15.42429 29.17155 1 1 A LYS 1 1
ATOM 2763 C CB . LYS 340 340 ? A 27.46902 -12.73344 27.82750 1 1 A LYS 1 1
ATOM 2764 C CG . LYS 340 340 ? A 28.35697 -11.51164 27.71000 1 1 A LYS 1 1
ATOM 2765 C CD . LYS 340 340 ? A 27.54800 -10.22933 27.90139 1 1 A LYS 1 1
ATOM 2766 C CE . LYS 340 340 ? A 28.38592 -9.00417 27.66788 1 1 A LYS 1 1
ATOM 2767 N NZ . LYS 340 340 ? A 27.59205 -7.75688 27.76299 1 1 A LYS 1 1
ATOM 2768 N N . SER 341 341 ? A 26.72355 -15.96919 27.02841 1 1 A SER 1 1
ATOM 2769 C CA . SER 341 341 ? A 25.81731 -17.06243 27.35091 1 1 A SER 1 1
ATOM 2770 C C . SER 341 341 ? A 26.28619 -18.34546 26.68752 1 1 A SER 1 1
ATOM 2771 O O . SER 341 341 ? A 26.26612 -18.44614 25.45984 1 1 A SER 1 1
ATOM 2772 C CB . SER 341 341 ? A 24.39869 -16.74143 26.90327 1 1 A SER 1 1
ATOM 2773 O OG . SER 341 341 ? A 23.57311 -17.88545 27.06341 1 1 A SER 1 1
ATOM 2774 N N . GLY 342 342 ? A 26.67486 -19.31821 27.48852 1 1 A GLY 1 1
ATOM 2775 C CA . GLY 342 342 ? A 27.08011 -20.61458 26.97821 1 1 A GLY 1 1
ATOM 2776 C C . GLY 342 342 ? A 25.96960 -21.33467 26.26005 1 1 A GLY 1 1
ATOM 2777 O O . GLY 342 342 ? A 26.19573 -22.01904 25.26132 1 1 A GLY 1 1
ATOM 2778 N N . THR 343 343 ? A 24.74683 -21.19937 26.76104 1 1 A THR 1 1
ATOM 2779 C CA . THR 343 343 ? A 23.58144 -21.83678 26.16678 1 1 A THR 1 1
ATOM 2780 C C . THR 343 343 ? A 23.33198 -21.33409 24.75669 1 1 A THR 1 1
ATOM 2781 O O . THR 343 343 ? A 23.11064 -22.12522 23.83491 1 1 A THR 1 1
ATOM 2782 C CB . THR 343 343 ? A 22.33569 -21.59179 27.03415 1 1 A THR 1 1
ATOM 2783 O OG1 . THR 343 343 ? A 22.56752 -22.10934 28.34005 1 1 A THR 1 1
ATOM 2784 C CG2 . THR 343 343 ? A 21.10605 -22.24666 26.44092 1 1 A THR 1 1
ATOM 2785 N N . VAL 344 344 ? A 23.36987 -20.01867 24.57685 1 1 A VAL 1 1
ATOM 2786 C CA . VAL 344 344 ? A 23.15463 -19.43512 23.26387 1 1 A VAL 1 1
ATOM 2787 C C . VAL 344 344 ? A 24.29793 -19.78921 22.32910 1 1 A VAL 1 1
ATOM 2788 O O . VAL 344 344 ? A 24.07691 -20.10904 21.15849 1 1 A VAL 1 1
ATOM 2789 C CB . VAL 344 344 ? A 22.99114 -17.90354 23.36348 1 1 A VAL 1 1
ATOM 2790 C CG1 . VAL 344 344 ? A 22.90729 -17.27594 21.98559 1 1 A VAL 1 1
ATOM 2791 C CG2 . VAL 344 344 ? A 21.76172 -17.55821 24.18804 1 1 A VAL 1 1
ATOM 2792 N N . LYS 345 345 ? A 25.51368 -19.75164 22.83556 1 1 A LYS 1 1
ATOM 2793 C CA . LYS 345 345 ? A 26.67656 -20.08753 22.03255 1 1 A LYS 1 1
ATOM 2794 C C . LYS 345 345 ? A 26.62156 -21.51949 21.52706 1 1 A LYS 1 1
ATOM 2795 O O . LYS 345 345 ? A 26.97381 -21.79857 20.37368 1 1 A LYS 1 1
ATOM 2796 C CB . LYS 345 345 ? A 27.94744 -19.83564 22.82697 1 1 A LYS 1 1
ATOM 2797 C CG . LYS 345 345 ? A 29.20855 -19.84359 21.98673 1 1 A LYS 1 1
ATOM 2798 C CD . LYS 345 345 ? A 30.42169 -19.39581 22.79091 1 1 A LYS 1 1
ATOM 2799 C CE . LYS 345 345 ? A 31.67037 -19.36185 21.94621 1 1 A LYS 1 1
ATOM 2800 N NZ . LYS 345 345 ? A 32.86448 -19.00921 22.73078 1 1 A LYS 1 1
ATOM 2801 N N . ARG 346 346 ? A 26.16802 -22.45975 22.39158 1 1 A ARG 1 1
ATOM 2802 C CA . ARG 346 346 ? A 26.01161 -23.85500 21.97401 1 1 A ARG 1 1
ATOM 2803 C C . ARG 346 346 ? A 24.95035 -23.99209 20.89661 1 1 A ARG 1 1
ATOM 2804 O O . ARG 346 346 ? A 25.11348 -24.76601 19.95591 1 1 A ARG 1 1
ATOM 2805 C CB . ARG 346 346 ? A 25.66635 -24.75501 23.16786 1 1 A ARG 1 1
ATOM 2806 C CG . ARG 346 346 ? A 26.82125 -25.00134 24.10096 1 1 A ARG 1 1
ATOM 2807 C CD . ARG 346 346 ? A 26.49276 -26.11876 25.08636 1 1 A ARG 1 1
ATOM 2808 N NE . ARG 346 346 ? A 25.32725 -25.80414 25.90499 1 1 A ARG 1 1
ATOM 2809 C CZ . ARG 346 346 ? A 25.36473 -25.13438 27.04336 1 1 A ARG 1 1
ATOM 2810 N NH1 . ARG 346 346 ? A 26.53209 -24.69164 27.51504 1 1 A ARG 1 1
ATOM 2811 N NH2 . ARG 346 346 ? A 24.22966 -24.88386 27.72087 1 1 A ARG 1 1
ATOM 2812 N N . LYS 347 347 ? A 23.84280 -23.27673 21.02385 1 1 A LYS 1 1
ATOM 2813 C CA . LYS 347 347 ? A 22.80112 -23.30565 20.01784 1 1 A LYS 1 1
ATOM 2814 C C . LYS 347 347 ? A 23.33785 -22.84890 18.67350 1 1 A LYS 1 1
ATOM 2815 O O . LYS 347 347 ? A 23.07297 -23.48090 17.64572 1 1 A LYS 1 1
ATOM 2816 C CB . LYS 347 347 ? A 21.61928 -22.44740 20.44043 1 1 A LYS 1 1
ATOM 2817 C CG . LYS 347 347 ? A 20.44069 -22.52225 19.47581 1 1 A LYS 1 1
ATOM 2818 C CD . LYS 347 347 ? A 19.22302 -21.80115 20.01664 1 1 A LYS 1 1
ATOM 2819 C CE . LYS 347 347 ? A 18.03902 -21.95525 19.09449 1 1 A LYS 1 1
ATOM 2820 N NZ . LYS 347 347 ? A 16.80327 -21.34556 19.64102 1 1 A LYS 1 1
ATOM 2821 N N . ILE 348 348 ? A 24.10318 -21.76467 18.65699 1 1 A ILE 1 1
ATOM 2822 C CA . ILE 348 348 ? A 24.68550 -21.25766 17.41805 1 1 A ILE 1 1
ATOM 2823 C C . ILE 348 348 ? A 25.61049 -22.29785 16.79931 1 1 A ILE 1 1
ATOM 2824 O O . ILE 348 348 ? A 25.61383 -22.49951 15.58493 1 1 A ILE 1 1
ATOM 2825 C CB . ILE 348 348 ? A 25.45250 -19.94304 17.65571 1 1 A ILE 1 1
ATOM 2826 C CG1 . ILE 348 348 ? A 24.52189 -18.85071 18.17308 1 1 A ILE 1 1
ATOM 2827 C CG2 . ILE 348 348 ? A 26.20841 -19.50724 16.42243 1 1 A ILE 1 1
ATOM 2828 C CD1 . ILE 348 348 ? A 23.47761 -18.41755 17.17779 1 1 A ILE 1 1
ATOM 2829 N N . LYS 349 349 ? A 26.40374 -22.98277 17.63737 1 1 A LYS 1 1
ATOM 2830 C CA . LYS 349 349 ? A 27.33564 -23.98903 17.15653 1 1 A LYS 1 1
ATOM 2831 C C . LYS 349 349 ? A 26.63525 -25.20353 16.57129 1 1 A LYS 1 1
ATOM 2832 O O . LYS 349 349 ? A 27.04008 -25.71545 15.51904 1 1 A LYS 1 1
ATOM 2833 C CB . LYS 349 349 ? A 28.26353 -24.42275 18.27880 1 1 A LYS 1 1
ATOM 2834 C CG . LYS 349 349 ? A 29.37222 -25.36362 17.83430 1 1 A LYS 1 1
ATOM 2835 C CD . LYS 349 349 ? A 30.32105 -25.70953 18.95596 1 1 A LYS 1 1
ATOM 2836 C CE . LYS 349 349 ? A 31.43103 -26.61807 18.48177 1 1 A LYS 1 1
ATOM 2837 N NZ . LYS 349 349 ? A 32.43386 -26.87736 19.53402 1 1 A LYS 1 1
ATOM 2838 N N . ASN 350 350 ? A 25.55268 -25.68345 17.24130 1 1 A ASN 1 1
ATOM 2839 C CA . ASN 350 350 ? A 24.92916 -26.95665 16.88497 1 1 A ASN 1 1
ATOM 2840 C C . ASN 350 350 ? A 23.90562 -26.89058 15.77127 1 1 A ASN 1 1
ATOM 2841 O O . ASN 350 350 ? A 23.66341 -27.89260 15.10428 1 1 A ASN 1 1
ATOM 2842 C CB . ASN 350 350 ? A 24.30007 -27.59148 18.12407 1 1 A ASN 1 1
ATOM 2843 C CG . ASN 350 350 ? A 25.33513 -27.99572 19.15062 1 1 A ASN 1 1
ATOM 2844 O OD1 . ASN 350 350 ? A 26.47106 -28.33695 18.80608 1 1 A ASN 1 1
ATOM 2845 N ND2 . ASN 350 350 ? A 24.96950 -27.94554 20.42035 1 1 A ASN 1 1
ATOM 2846 N N . GLN 351 351 ? A 23.25707 -25.75948 15.53530 1 1 A GLN 1 1
ATOM 2847 C CA . GLN 351 351 ? A 22.25141 -25.65047 14.48924 1 1 A GLN 1 1
ATOM 2848 C C . GLN 351 351 ? A 22.85734 -25.13846 13.20565 1 1 A GLN 1 1
ATOM 2849 O O . GLN 351 351 ? A 23.79067 -24.32974 13.22333 1 1 A GLN 1 1
ATOM 2850 C CB . GLN 351 351 ? A 21.12619 -24.72261 14.91901 1 1 A GLN 1 1
ATOM 2851 C CG . GLN 351 351 ? A 20.18303 -25.33286 15.92914 1 1 A GLN 1 1
ATOM 2852 C CD . GLN 351 351 ? A 18.93785 -24.48749 16.09211 1 1 A GLN 1 1
ATOM 2853 O OE1 . GLN 351 351 ? A 18.84676 -23.71123 17.02279 1 1 A GLN 1 1
ATOM 2854 N NE2 . GLN 351 351 ? A 18.00971 -24.65282 15.16859 1 1 A GLN 1 1
ATOM 2855 N N . SER 352 352 ? A 22.31619 -25.60099 12.08454 1 1 A SER 1 1
ATOM 2856 C CA . SER 352 352 ? A 22.76601 -25.12982 10.78595 1 1 A SER 1 1
ATOM 2857 C C . SER 352 352 ? A 22.34297 -23.68040 10.57767 1 1 A SER 1 1
ATOM 2858 O O . SER 352 352 ? A 21.36426 -23.21845 11.17298 1 1 A SER 1 1
ATOM 2859 C CB . SER 352 352 ? A 22.20502 -26.00698 9.67194 1 1 A SER 1 1
ATOM 2860 O OG . SER 352 352 ? A 20.82108 -25.72894 9.47571 1 1 A SER 1 1
ATOM 2861 N N . VAL 353 353 ? A 23.09435 -22.97247 9.74721 1 1 A VAL 1 1
ATOM 2862 C CA . VAL 353 353 ? A 22.78066 -21.59308 9.42225 1 1 A VAL 1 1
ATOM 2863 C C . VAL 353 353 ? A 21.38689 -21.48358 8.82343 1 1 A VAL 1 1
ATOM 2864 O O . VAL 353 353 ? A 20.63178 -20.56047 9.15354 1 1 A VAL 1 1
ATOM 2865 C CB . VAL 353 353 ? A 23.83349 -21.00423 8.47434 1 1 A VAL 1 1
ATOM 2866 C CG1 . VAL 353 353 ? A 23.47475 -19.58262 8.06412 1 1 A VAL 1 1
ATOM 2867 C CG2 . VAL 353 353 ? A 25.20514 -21.03501 9.12046 1 1 A VAL 1 1
ATOM 2868 N N . PHE 354 354 ? A 21.00592 -22.41869 7.94309 1 1 A PHE 1 1
ATOM 2869 C CA . PHE 354 354 ? A 19.68031 -22.43505 7.35923 1 1 A PHE 1 1
ATOM 2870 C C . PHE 354 354 ? A 18.59168 -22.55160 8.41088 1 1 A PHE 1 1
ATOM 2871 O O . PHE 354 354 ? A 17.60057 -21.80678 8.38053 1 1 A PHE 1 1
ATOM 2872 C CB . PHE 354 354 ? A 19.52966 -23.56990 6.34403 1 1 A PHE 1 1
ATOM 2873 C CG . PHE 354 354 ? A 19.86803 -23.18472 4.93972 1 1 A PHE 1 1
ATOM 2874 C CD1 . PHE 354 354 ? A 18.94728 -22.48935 4.16485 1 1 A PHE 1 1
ATOM 2875 C CD2 . PHE 354 354 ? A 21.09538 -23.50022 4.38859 1 1 A PHE 1 1
ATOM 2876 C CE1 . PHE 354 354 ? A 19.24815 -22.11744 2.86149 1 1 A PHE 1 1
ATOM 2877 C CE2 . PHE 354 354 ? A 21.40513 -23.13819 3.09728 1 1 A PHE 1 1
ATOM 2878 C CZ . PHE 354 354 ? A 20.48225 -22.44869 2.33630 1 1 A PHE 1 1
ATOM 2879 N N . ASP 355 355 ? A 18.74734 -23.49086 9.35162 1 1 A ASP 1 1
ATOM 2880 C CA . ASP 355 355 ? A 17.73493 -23.67629 10.37582 1 1 A ASP 1 1
ATOM 2881 C C . ASP 355 355 ? A 17.60730 -22.46260 11.28114 1 1 A ASP 1 1
ATOM 2882 O O . ASP 355 355 ? A 16.48656 -22.03831 11.60050 1 1 A ASP 1 1
ATOM 2883 C CB . ASP 355 355 ? A 18.02354 -24.92707 11.20567 1 1 A ASP 1 1
ATOM 2884 C CG . ASP 355 355 ? A 17.66636 -26.20550 10.47754 1 1 A ASP 1 1
ATOM 2885 O OD1 . ASP 355 355 ? A 16.79309 -26.17088 9.59174 1 1 A ASP 1 1
ATOM 2886 O OD2 . ASP 355 355 ? A 18.24838 -27.25847 10.81045 1 1 A ASP 1 1
ATOM 2887 N N . LEU 356 356 ? A 18.71472 -21.88594 11.70964 1 1 A LEU 1 1
ATOM 2888 C CA . LEU 356 356 ? A 18.65568 -20.69284 12.53501 1 1 A LEU 1 1
ATOM 2889 C C . LEU 356 356 ? A 17.97142 -19.55880 11.80601 1 1 A LEU 1 1
ATOM 2890 O O . LEU 356 356 ? A 17.12762 -18.85619 12.39489 1 1 A LEU 1 1
ATOM 2891 C CB . LEU 356 356 ? A 20.05151 -20.26629 12.96507 1 1 A LEU 1 1
ATOM 2892 C CG . LEU 356 356 ? A 20.66586 -21.03797 14.11310 1 1 A LEU 1 1
ATOM 2893 C CD1 . LEU 356 356 ? A 22.11482 -20.63212 14.30516 1 1 A LEU 1 1
ATOM 2894 C CD2 . LEU 356 356 ? A 19.89071 -20.80629 15.40019 1 1 A LEU 1 1
ATOM 2895 N N . SER 357 357 ? A 18.30331 -19.35927 10.55646 1 1 A SER 1 1
ATOM 2896 C CA . SER 357 357 ? A 17.71107 -18.29184 9.76284 1 1 A SER 1 1
ATOM 2897 C C . SER 357 357 ? A 16.23297 -18.53596 9.54725 1 1 A SER 1 1
ATOM 2898 O O . SER 357 357 ? A 15.42430 -17.61218 9.71162 1 1 A SER 1 1
ATOM 2899 C CB . SER 357 357 ? A 18.41439 -18.16607 8.42630 1 1 A SER 1 1
ATOM 2900 O OG . SER 357 357 ? A 19.80632 -17.89213 8.61487 1 1 A SER 1 1
ATOM 2901 N N . ARG 358 358 ? A 15.85951 -19.77028 9.19359 1 1 A ARG 1 1
ATOM 2902 C CA . ARG 358 358 ? A 14.45720 -20.12279 8.98661 1 1 A ARG 1 1
ATOM 2903 C C . ARG 358 358 ? A 13.65541 -19.89301 10.26460 1 1 A ARG 1 1
ATOM 2904 O O . ARG 358 358 ? A 12.58956 -19.26972 10.23437 1 1 A ARG 1 1
ATOM 2905 C CB . ARG 358 358 ? A 14.32718 -21.58096 8.54670 1 1 A ARG 1 1
ATOM 2906 C CG . ARG 358 358 ? A 12.88986 -22.04146 8.39593 1 1 A ARG 1 1
ATOM 2907 C CD . ARG 358 358 ? A 12.77760 -23.53859 8.20824 1 1 A ARG 1 1
ATOM 2908 N NE . ARG 358 358 ? A 13.30787 -24.27029 9.35664 1 1 A ARG 1 1
ATOM 2909 C CZ . ARG 358 358 ? A 12.62047 -24.49163 10.49122 1 1 A ARG 1 1
ATOM 2910 N NH1 . ARG 358 358 ? A 11.39915 -24.05905 10.62913 1 1 A ARG 1 1
ATOM 2911 N NH2 . ARG 358 358 ? A 13.22166 -25.15820 11.45997 1 1 A ARG 1 1
ATOM 2912 N N . GLU 359 359 ? A 14.16279 -20.39060 11.38404 1 1 A GLU 1 1
ATOM 2913 C CA . GLU 359 359 ? A 13.46063 -20.24768 12.64859 1 1 A GLU 1 1
ATOM 2914 C C . GLU 359 359 ? A 13.33495 -18.79133 13.06491 1 1 A GLU 1 1
ATOM 2915 O O . GLU 359 359 ? A 12.29766 -18.38583 13.61296 1 1 A GLU 1 1
ATOM 2916 C CB . GLU 359 359 ? A 14.14617 -21.05588 13.74510 1 1 A GLU 1 1
ATOM 2917 C CG . GLU 359 359 ? A 14.00094 -22.55540 13.55181 1 1 A GLU 1 1
ATOM 2918 C CD . GLU 359 359 ? A 14.72095 -23.36234 14.62894 1 1 A GLU 1 1
ATOM 2919 O OE1 . GLU 359 359 ? A 15.25797 -22.78242 15.58720 1 1 A GLU 1 1
ATOM 2920 O OE2 . GLU 359 359 ? A 14.73095 -24.61301 14.50841 1 1 A GLU 1 1
ATOM 2921 N N . CYS 360 360 ? A 14.37144 -18.00781 12.83961 1 1 A CYS 1 1
ATOM 2922 C CA . CYS 360 360 ? A 14.33484 -16.59389 13.15325 1 1 A CYS 1 1
ATOM 2923 C C . CYS 360 360 ? A 13.18328 -15.90642 12.44722 1 1 A CYS 1 1
ATOM 2924 O O . CYS 360 360 ? A 12.51525 -15.04022 13.03758 1 1 A CYS 1 1
ATOM 2925 C CB . CYS 360 360 ? A 15.65598 -15.94159 12.76832 1 1 A CYS 1 1
ATOM 2926 S SG . CYS 360 360 ? A 15.75252 -14.18874 13.13563 1 1 A CYS 1 1
ATOM 2927 N N . LEU 361 361 ? A 12.91014 -16.27262 11.21558 1 1 A LEU 1 1
ATOM 2928 C CA . LEU 361 361 ? A 11.84705 -15.66318 10.43060 1 1 A LEU 1 1
ATOM 2929 C C . LEU 361 361 ? A 10.50282 -16.36088 10.59264 1 1 A LEU 1 1
ATOM 2930 O O . LEU 361 361 ? A 9.48437 -15.85600 10.12163 1 1 A LEU 1 1
ATOM 2931 C CB . LEU 361 361 ? A 12.23784 -15.63269 8.95733 1 1 A LEU 1 1
ATOM 2932 C CG . LEU 361 361 ? A 13.44259 -14.75239 8.64143 1 1 A LEU 1 1
ATOM 2933 C CD1 . LEU 361 361 ? A 13.86303 -14.90826 7.19014 1 1 A LEU 1 1
ATOM 2934 C CD2 . LEU 361 361 ? A 13.11398 -13.28921 8.93108 1 1 A LEU 1 1
ATOM 2935 N N . GLY 362 362 ? A 10.47834 -17.51025 11.26079 1 1 A GLY 1 1
ATOM 2936 C CA . GLY 362 362 ? A 9.23625 -18.22468 11.49280 1 1 A GLY 1 1
ATOM 2937 C C . GLY 362 362 ? A 8.66631 -18.93881 10.28857 1 1 A GLY 1 1
ATOM 2938 O O . GLY 362 362 ? A 7.45949 -19.19020 10.24633 1 1 A GLY 1 1
ATOM 2939 N N . TYR 363 363 ? A 9.50253 -19.24871 9.30750 1 1 A TYR 1 1
ATOM 2940 C CA . TYR 363 363 ? A 9.02008 -19.92655 8.11256 1 1 A TYR 1 1
ATOM 2941 C C . TYR 363 363 ? A 8.67796 -21.38034 8.40725 1 1 A TYR 1 1
ATOM 2942 O O . TYR 363 363 ? A 9.40819 -22.05141 9.15005 1 1 A TYR 1 1
ATOM 2943 C CB . TYR 363 363 ? A 10.06705 -19.88311 7.00465 1 1 A TYR 1 1
ATOM 2944 C CG . TYR 363 363 ? A 10.40040 -18.49068 6.49703 1 1 A TYR 1 1
ATOM 2945 C CD1 . TYR 363 363 ? A 9.58615 -17.39805 6.78867 1 1 A TYR 1 1
ATOM 2946 C CD2 . TYR 363 363 ? A 11.54462 -18.28973 5.73067 1 1 A TYR 1 1
ATOM 2947 C CE1 . TYR 363 363 ? A 9.90298 -16.13367 6.31700 1 1 A TYR 1 1
ATOM 2948 C CE2 . TYR 363 363 ? A 11.86498 -17.01631 5.25695 1 1 A TYR 1 1
ATOM 2949 C CZ . TYR 363 363 ? A 11.03946 -15.94717 5.55253 1 1 A TYR 1 1
ATOM 2950 O OH . TYR 363 363 ? A 11.35510 -14.70683 5.08208 1 1 A TYR 1 1
ATOM 2951 N N . PRO 364 364 ? A 7.58565 -21.92855 7.83628 1 1 A PRO 1 1
ATOM 2952 C CA . PRO 364 364 ? A 7.25299 -23.33483 8.02388 1 1 A PRO 1 1
ATOM 2953 C C . PRO 364 364 ? A 8.32355 -24.23657 7.41677 1 1 A PRO 1 1
ATOM 2954 O O . PRO 364 364 ? A 8.79079 -23.97741 6.31426 1 1 A PRO 1 1
ATOM 2955 C CB . PRO 364 364 ? A 5.93360 -23.49778 7.28325 1 1 A PRO 1 1
ATOM 2956 C CG . PRO 364 364 ? A 5.37218 -22.12232 7.20501 1 1 A PRO 1 1
ATOM 2957 C CD . PRO 364 364 ? A 6.56908 -21.21958 7.07579 1 1 A PRO 1 1
ATOM 2958 N N . GLU 365 365 ? A 8.69367 -25.31544 8.12936 1 1 A GLU 1 1
ATOM 2959 C CA . GLU 365 365 ? A 9.79347 -26.16269 7.70922 1 1 A GLU 1 1
ATOM 2960 C C . GLU 365 365 ? A 9.54925 -26.85649 6.38070 1 1 A GLU 1 1
ATOM 2961 O O . GLU 365 365 ? A 10.42644 -26.84125 5.50006 1 1 A GLU 1 1
ATOM 2962 C CB . GLU 365 365 ? A 10.11496 -27.18321 8.78987 1 1 A GLU 1 1
ATOM 2963 C CG . GLU 365 365 ? A 11.36422 -28.01735 8.48374 1 1 A GLU 1 1
ATOM 2964 C CD . GLU 365 365 ? A 11.79086 -28.89248 9.64619 1 1 A GLU 1 1
ATOM 2965 O OE1 . GLU 365 365 ? A 11.25737 -28.74493 10.75306 1 1 A GLU 1 1
ATOM 2966 O OE2 . GLU 365 365 ? A 12.66841 -29.74115 9.44585 1 1 A GLU 1 1
ATOM 2967 N N . ALA 366 366 ? A 8.39164 -27.52374 6.22427 1 1 A ALA 1 1
ATOM 2968 C CA . ALA 366 366 ? A 8.14224 -28.25981 4.99397 1 1 A ALA 1 1
ATOM 2969 C C . ALA 366 366 ? A 8.13223 -27.35744 3.77396 1 1 A ALA 1 1
ATOM 2970 O O . ALA 366 366 ? A 8.75827 -27.68656 2.75399 1 1 A ALA 1 1
ATOM 2971 C CB . ALA 366 366 ? A 6.83698 -29.03055 5.09883 1 1 A ALA 1 1
ATOM 2972 N N . ASP 367 367 ? A 7.43873 -26.23661 3.83282 1 1 A ASP 1 1
ATOM 2973 C CA . ASP 367 367 ? A 7.40630 -25.28478 2.73033 1 1 A ASP 1 1
ATOM 2974 C C . ASP 367 367 ? A 8.78233 -24.71563 2.44455 1 1 A ASP 1 1
ATOM 2975 O O . ASP 367 367 ? A 9.16211 -24.54003 1.28130 1 1 A ASP 1 1
ATOM 2976 C CB . ASP 367 367 ? A 6.43113 -24.15106 3.02502 1 1 A ASP 1 1
ATOM 2977 C CG . ASP 367 367 ? A 4.97982 -24.60395 3.05234 1 1 A ASP 1 1
ATOM 2978 O OD1 . ASP 367 367 ? A 4.70772 -25.78035 2.74659 1 1 A ASP 1 1
ATOM 2979 O OD2 . ASP 367 367 ? A 4.10896 -23.78050 3.39909 1 1 A ASP 1 1
ATOM 2980 N N . PHE 368 368 ? A 9.53741 -24.44916 3.49952 1 1 A PHE 1 1
ATOM 2981 C CA . PHE 368 368 ? A 10.87882 -23.93001 3.33811 1 1 A PHE 1 1
ATOM 2982 C C . PHE 368 368 ? A 11.77644 -24.91243 2.60506 1 1 A PHE 1 1
ATOM 2983 O O . PHE 368 368 ? A 12.51759 -24.53445 1.68938 1 1 A PHE 1 1
ATOM 2984 C CB . PHE 368 368 ? A 11.47749 -23.57848 4.70835 1 1 A PHE 1 1
ATOM 2985 C CG . PHE 368 368 ? A 12.85854 -23.01147 4.64215 1 1 A PHE 1 1
ATOM 2986 C CD1 . PHE 368 368 ? A 13.05556 -21.68629 4.30290 1 1 A PHE 1 1
ATOM 2987 C CD2 . PHE 368 368 ? A 13.95520 -23.80454 4.90618 1 1 A PHE 1 1
ATOM 2988 C CE1 . PHE 368 368 ? A 14.32668 -21.15682 4.22809 1 1 A PHE 1 1
ATOM 2989 C CE2 . PHE 368 368 ? A 15.24580 -23.27957 4.82940 1 1 A PHE 1 1
ATOM 2990 C CZ . PHE 368 368 ? A 15.42255 -21.96121 4.49208 1 1 A PHE 1 1
ATOM 2991 N N . ILE 369 369 ? A 11.72298 -26.18374 2.99874 1 1 A ILE 1 1
ATOM 2992 C CA . ILE 369 369 ? A 12.51934 -27.20885 2.33036 1 1 A ILE 1 1
ATOM 2993 C C . ILE 369 369 ? A 12.11267 -27.33689 0.87227 1 1 A ILE 1 1
ATOM 2994 O O . ILE 369 369 ? A 12.97303 -27.45027 -0.00956 1 1 A ILE 1 1
ATOM 2995 C CB . ILE 369 369 ? A 12.41691 -28.55296 3.06484 1 1 A ILE 1 1
ATOM 2996 C CG1 . ILE 369 369 ? A 13.15269 -28.45965 4.40460 1 1 A ILE 1 1
ATOM 2997 C CG2 . ILE 369 369 ? A 12.99594 -29.68043 2.22628 1 1 A ILE 1 1
ATOM 2998 C CD1 . ILE 369 369 ? A 12.91354 -29.63650 5.32177 1 1 A ILE 1 1
ATOM 2999 N N . THR 370 370 ? A 10.79610 -27.34214 0.60349 1 1 A THR 1 1
ATOM 3000 C CA . THR 370 370 ? A 10.30573 -27.39066 -0.77043 1 1 A THR 1 1
ATOM 3001 C C . THR 370 370 ? A 10.85510 -26.22714 -1.58201 1 1 A THR 1 1
ATOM 3002 O O . THR 370 370 ? A 11.33496 -26.41590 -2.70426 1 1 A THR 1 1
ATOM 3003 C CB . THR 370 370 ? A 8.77536 -27.38587 -0.80554 1 1 A THR 1 1
ATOM 3004 O OG1 . THR 370 370 ? A 8.28973 -28.56026 -0.15897 1 1 A THR 1 1
ATOM 3005 C CG2 . THR 370 370 ? A 8.25718 -27.34441 -2.23008 1 1 A THR 1 1
ATOM 3006 N N . LEU 371 371 ? A 10.78100 -25.03066 -1.02646 1 1 A LEU 1 1
ATOM 3007 C CA . LEU 371 371 ? A 11.25685 -23.85625 -1.73609 1 1 A LEU 1 1
ATOM 3008 C C . LEU 371 371 ? A 12.75283 -23.93048 -2.00070 1 1 A LEU 1 1
ATOM 3009 O O . LEU 371 371 ? A 13.19703 -23.70238 -3.13066 1 1 A LEU 1 1
ATOM 3010 C CB . LEU 371 371 ? A 10.91737 -22.58595 -0.94752 1 1 A LEU 1 1
ATOM 3011 C CG . LEU 371 371 ? A 11.35740 -21.28828 -1.59027 1 1 A LEU 1 1
ATOM 3012 C CD1 . LEU 371 371 ? A 10.73095 -21.12703 -2.96076 1 1 A LEU 1 1
ATOM 3013 C CD2 . LEU 371 371 ? A 10.97361 -20.10809 -0.70637 1 1 A LEU 1 1
ATOM 3014 N N . VAL 372 372 ? A 13.53843 -24.25103 -0.97667 1 1 A VAL 1 1
ATOM 3015 C CA . VAL 372 372 ? A 14.98032 -24.32291 -1.13406 1 1 A VAL 1 1
ATOM 3016 C C . VAL 372 372 ? A 15.38195 -25.34353 -2.18559 1 1 A VAL 1 1
ATOM 3017 O O . VAL 372 372 ? A 16.32695 -25.12101 -2.94614 1 1 A VAL 1 1
ATOM 3018 C CB . VAL 372 372 ? A 15.65832 -24.61589 0.21714 1 1 A VAL 1 1
ATOM 3019 C CG1 . VAL 372 372 ? A 17.11814 -24.94356 0.04403 1 1 A VAL 1 1
ATOM 3020 C CG2 . VAL 372 372 ? A 15.50277 -23.42842 1.13776 1 1 A VAL 1 1
ATOM 3021 N N . ASN 373 373 ? A 14.64974 -26.46874 -2.24706 1 1 A ASN 1 1
ATOM 3022 C CA . ASN 373 373 ? A 14.94331 -27.48611 -3.25203 1 1 A ASN 1 1
ATOM 3023 C C . ASN 373 373 ? A 14.64888 -27.02635 -4.66479 1 1 A ASN 1 1
ATOM 3024 O O . ASN 373 373 ? A 15.14803 -27.60597 -5.61974 1 1 A ASN 1 1
ATOM 3025 C CB . ASN 373 373 ? A 14.17591 -28.76766 -2.94933 1 1 A ASN 1 1
ATOM 3026 C CG . ASN 373 373 ? A 14.76618 -29.54701 -1.79362 1 1 A ASN 1 1
ATOM 3027 O OD1 . ASN 373 373 ? A 15.93678 -29.38646 -1.46002 1 1 A ASN 1 1
ATOM 3028 N ND2 . ASN 373 373 ? A 13.95673 -30.39118 -1.16982 1 1 A ASN 1 1
ATOM 3029 N N . ASN 374 374 ? A 13.84304 -25.97729 -4.80873 1 1 A ASN 1 1
ATOM 3030 C CA . ASN 374 374 ? A 13.51091 -25.43152 -6.11594 1 1 A ASN 1 1
ATOM 3031 C C . ASN 374 374 ? A 14.19801 -24.10392 -6.39120 1 1 A ASN 1 1
ATOM 3032 O O . ASN 374 374 ? A 13.84224 -23.40908 -7.33553 1 1 A ASN 1 1
ATOM 3033 C CB . ASN 374 374 ? A 12.00437 -25.27386 -6.24767 1 1 A ASN 1 1
ATOM 3034 C CG . ASN 374 374 ? A 11.32046 -26.60136 -6.44266 1 1 A ASN 1 1
ATOM 3035 O OD1 . ASN 374 374 ? A 11.22196 -27.09022 -7.55633 1 1 A ASN 1 1
ATOM 3036 N ND2 . ASN 374 374 ? A 10.87381 -27.21909 -5.35315 1 1 A ASN 1 1
ATOM 3037 N N . MET 375 375 ? A 15.18818 -23.76660 -5.57183 1 1 A MET 1 1
ATOM 3038 C CA . MET 375 375 ? A 15.97177 -22.55325 -5.75373 1 1 A MET 1 1
ATOM 3039 C C . MET 375 375 ? A 17.40816 -22.89677 -6.05029 1 1 A MET 1 1
ATOM 3040 O O . MET 375 375 ? A 17.89731 -23.97018 -5.70102 1 1 A MET 1 1
ATOM 3041 C CB . MET 375 375 ? A 15.93133 -21.70026 -4.48849 1 1 A MET 1 1
ATOM 3042 C CG . MET 375 375 ? A 14.62530 -21.01755 -4.22704 1 1 A MET 1 1
ATOM 3043 S SD . MET 375 375 ? A 14.71627 -19.92226 -2.78107 1 1 A MET 1 1
ATOM 3044 C CE . MET 375 375 ? A 15.53603 -18.53024 -3.49633 1 1 A MET 1 1
ATOM 3045 N N . ARG 376 376 ? A 18.08467 -22.02312 -6.70453 1 1 A ARG 1 1
ATOM 3046 C CA . ARG 376 376 ? A 19.52429 -22.12318 -6.82381 1 1 A ARG 1 1
ATOM 3047 C C . ARG 376 376 ? A 20.13994 -20.87800 -6.18695 1 1 A ARG 1 1
ATOM 3048 O O . ARG 376 376 ? A 19.61622 -19.77227 -6.33421 1 1 A ARG 1 1
ATOM 3049 C CB . ARG 376 376 ? A 19.98527 -22.29367 -8.25731 1 1 A ARG 1 1
ATOM 3050 C CG . ARG 376 376 ? A 19.72019 -21.13337 -9.14335 1 1 A ARG 1 1
ATOM 3051 C CD . ARG 376 376 ? A 20.36555 -21.24940 -10.49493 1 1 A ARG 1 1
ATOM 3052 N NE . ARG 376 376 ? A 20.46658 -19.93809 -11.08015 1 1 A ARG 1 1
ATOM 3053 C CZ . ARG 376 376 ? A 19.91283 -19.58492 -12.21661 1 1 A ARG 1 1
ATOM 3054 N NH1 . ARG 376 376 ? A 19.22821 -20.45679 -12.92184 1 1 A ARG 1 1
ATOM 3055 N NH2 . ARG 376 376 ? A 20.05754 -18.34191 -12.64796 1 1 A ARG 1 1
ATOM 3056 N N . PHE 377 377 ? A 21.20786 -21.07053 -5.47124 1 1 A PHE 1 1
ATOM 3057 C CA . PHE 377 377 ? A 21.89310 -20.01398 -4.77248 1 1 A PHE 1 1
ATOM 3058 C C . PHE 377 377 ? A 23.26439 -19.77385 -5.36546 1 1 A PHE 1 1
ATOM 3059 O O . PHE 377 377 ? A 23.93324 -20.71654 -5.77556 1 1 A PHE 1 1
ATOM 3060 C CB . PHE 377 377 ? A 22.04993 -20.36752 -3.28471 1 1 A PHE 1 1
ATOM 3061 C CG . PHE 377 377 ? A 20.76800 -20.51644 -2.53907 1 1 A PHE 1 1
ATOM 3062 C CD1 . PHE 377 377 ? A 20.10724 -19.41355 -2.06100 1 1 A PHE 1 1
ATOM 3063 C CD2 . PHE 377 377 ? A 20.23382 -21.77330 -2.33533 1 1 A PHE 1 1
ATOM 3064 C CE1 . PHE 377 377 ? A 18.91103 -19.55084 -1.37529 1 1 A PHE 1 1
ATOM 3065 C CE2 . PHE 377 377 ? A 19.03941 -21.92515 -1.65421 1 1 A PHE 1 1
ATOM 3066 C CZ . PHE 377 377 ? A 18.38244 -20.80499 -1.16966 1 1 A PHE 1 1
ATOM 3067 N N . LYS 378 378 ? A 23.68042 -18.55371 -5.35424 1 1 A LYS 1 1
ATOM 3068 C CA . LYS 378 378 ? A 25.03373 -18.20825 -5.69928 1 1 A LYS 1 1
ATOM 3069 C C . LYS 378 378 ? A 25.73322 -17.78630 -4.41361 1 1 A LYS 1 1
ATOM 3070 O O . LYS 378 378 ? A 25.28853 -16.85034 -3.74586 1 1 A LYS 1 1
ATOM 3071 C CB . LYS 378 378 ? A 25.06561 -17.08473 -6.72344 1 1 A LYS 1 1
ATOM 3072 C CG . LYS 378 378 ? A 26.43041 -16.84405 -7.33014 1 1 A LYS 1 1
ATOM 3073 C CD . LYS 378 378 ? A 26.32317 -15.90650 -8.52346 1 1 A LYS 1 1
ATOM 3074 C CE . LYS 378 378 ? A 27.62379 -15.77519 -9.26581 1 1 A LYS 1 1
ATOM 3075 N NZ . LYS 378 378 ? A 27.45742 -14.93705 -10.48796 1 1 A LYS 1 1
ATOM 3076 N N . ILE 379 379 ? A 26.76819 -18.50006 -4.05199 1 1 A ILE 1 1
ATOM 3077 C CA . ILE 379 379 ? A 27.46437 -18.27443 -2.80639 1 1 A ILE 1 1
ATOM 3078 C C . ILE 379 379 ? A 28.89872 -17.85698 -3.07731 1 1 A ILE 1 1
ATOM 3079 O O . ILE 379 379 ? A 29.59292 -18.50702 -3.85030 1 1 A ILE 1 1
ATOM 3080 C CB . ILE 379 379 ? A 27.42139 -19.53597 -1.93098 1 1 A ILE 1 1
ATOM 3081 C CG1 . ILE 379 379 ? A 25.97347 -19.88020 -1.58315 1 1 A ILE 1 1
ATOM 3082 C CG2 . ILE 379 379 ? A 28.25831 -19.34019 -0.66358 1 1 A ILE 1 1
ATOM 3083 C CD1 . ILE 379 379 ? A 25.79025 -21.17280 -0.82849 1 1 A ILE 1 1
ATOM 3084 N N . GLU 380 380 ? A 29.33864 -16.79129 -2.42353 1 1 A GLU 1 1
ATOM 3085 C CA . GLU 380 380 ? A 30.71152 -16.31940 -2.53499 1 1 A GLU 1 1
ATOM 3086 C C . GLU 380 380 ? A 31.23719 -16.01676 -1.14392 1 1 A GLU 1 1
ATOM 3087 O O . GLU 380 380 ? A 30.57058 -15.33004 -0.36149 1 1 A GLU 1 1
ATOM 3088 C CB . GLU 380 380 ? A 30.78538 -15.07566 -3.41675 1 1 A GLU 1 1
ATOM 3089 C CG . GLU 380 380 ? A 30.36925 -15.33707 -4.84857 1 1 A GLU 1 1
ATOM 3090 C CD . GLU 380 380 ? A 30.44789 -14.09090 -5.72129 1 1 A GLU 1 1
ATOM 3091 O OE1 . GLU 380 380 ? A 30.78164 -13.00895 -5.21057 1 1 A GLU 1 1
ATOM 3092 O OE2 . GLU 380 380 ? A 30.15445 -14.20623 -6.92081 1 1 A GLU 1 1
ATOM 3093 N N . ASN 381 381 ? A 32.39050 -16.53522 -0.83520 1 1 A ASN 1 1
ATOM 3094 C CA . ASN 381 381 ? A 32.99459 -16.35120 0.47632 1 1 A ASN 1 1
ATOM 3095 C C . ASN 381 381 ? A 32.04226 -16.74338 1.59894 1 1 A ASN 1 1
ATOM 3096 O O . ASN 381 381 ? A 31.90395 -16.04672 2.60281 1 1 A ASN 1 1
ATOM 3097 C CB . ASN 381 381 ? A 33.47301 -14.90587 0.66821 1 1 A ASN 1 1
ATOM 3098 C CG . ASN 381 381 ? A 34.49964 -14.50643 -0.36682 1 1 A ASN 1 1
ATOM 3099 O OD1 . ASN 381 381 ? A 35.42686 -15.26096 -0.63750 1 1 A ASN 1 1
ATOM 3100 N ND2 . ASN 381 381 ? A 34.32902 -13.32906 -0.95386 1 1 A ASN 1 1
ATOM 3101 N N . CYS 382 382 ? A 31.36742 -17.88371 1.41924 1 1 A CYS 1 1
ATOM 3102 C CA . CYS 382 382 ? A 30.44081 -18.46713 2.38315 1 1 A CYS 1 1
ATOM 3103 C C . CYS 382 382 ? A 29.19913 -17.61599 2.64295 1 1 A CYS 1 1
ATOM 3104 O O . CYS 382 382 ? A 28.51167 -17.80999 3.64431 1 1 A CYS 1 1
ATOM 3105 C CB . CYS 382 382 ? A 31.15423 -18.74767 3.70666 1 1 A CYS 1 1
ATOM 3106 S SG . CYS 382 382 ? A 32.48931 -19.93796 3.54737 1 1 A CYS 1 1
ATOM 3107 N N . LYS 383 383 ? A 28.90926 -16.70935 1.72435 1 1 A LYS 1 1
ATOM 3108 C CA . LYS 383 383 ? A 27.72756 -15.86660 1.86503 1 1 A LYS 1 1
ATOM 3109 C C . LYS 383 383 ? A 26.87497 -15.91736 0.61299 1 1 A LYS 1 1
ATOM 3110 O O . LYS 383 383 ? A 27.40476 -15.99841 -0.49291 1 1 A LYS 1 1
ATOM 3111 C CB . LYS 383 383 ? A 28.14017 -14.43597 2.16927 1 1 A LYS 1 1
ATOM 3112 C CG . LYS 383 383 ? A 28.81117 -14.29522 3.52183 1 1 A LYS 1 1
ATOM 3113 C CD . LYS 383 383 ? A 29.08719 -12.87213 3.90473 1 1 A LYS 1 1
ATOM 3114 C CE . LYS 383 383 ? A 29.65647 -12.78052 5.30738 1 1 A LYS 1 1
ATOM 3115 N NZ . LYS 383 383 ? A 30.00014 -11.38564 5.67564 1 1 A LYS 1 1
ATOM 3116 N N . VAL 384 384 ? A 25.57961 -15.89353 0.79689 1 1 A VAL 1 1
ATOM 3117 C CA . VAL 384 384 ? A 24.66019 -15.87430 -0.33027 1 1 A VAL 1 1
ATOM 3118 C C . VAL 384 384 ? A 24.66357 -14.48808 -0.95095 1 1 A VAL 1 1
ATOM 3119 O O . VAL 384 384 ? A 24.45294 -13.49675 -0.24842 1 1 A VAL 1 1
ATOM 3120 C CB . VAL 384 384 ? A 23.23465 -16.25312 0.10140 1 1 A VAL 1 1
ATOM 3121 C CG1 . VAL 384 384 ? A 22.26859 -16.12458 -1.06899 1 1 A VAL 1 1
ATOM 3122 C CG2 . VAL 384 384 ? A 23.20569 -17.66120 0.65936 1 1 A VAL 1 1
ATOM 3123 N N . VAL 385 385 ? A 24.91272 -14.41434 -2.26100 1 1 A VAL 1 1
ATOM 3124 C CA . VAL 385 385 ? A 24.91680 -13.13499 -2.94274 1 1 A VAL 1 1
ATOM 3125 C C . VAL 385 385 ? A 23.80729 -13.00308 -3.97433 1 1 A VAL 1 1
ATOM 3126 O O . VAL 385 385 ? A 23.52037 -11.89954 -4.42774 1 1 A VAL 1 1
ATOM 3127 C CB . VAL 385 385 ? A 26.27110 -12.85411 -3.61433 1 1 A VAL 1 1
ATOM 3128 C CG1 . VAL 385 385 ? A 27.36634 -12.76195 -2.57722 1 1 A VAL 1 1
ATOM 3129 C CG2 . VAL 385 385 ? A 26.60959 -13.89496 -4.65560 1 1 A VAL 1 1
ATOM 3130 N N . ASN 386 386 ? A 23.14888 -14.11432 -4.34251 1 1 A ASN 1 1
ATOM 3131 C CA . ASN 386 386 ? A 22.05094 -14.07312 -5.28558 1 1 A ASN 1 1
ATOM 3132 C C . ASN 386 386 ? A 21.23106 -15.34970 -5.18903 1 1 A ASN 1 1
ATOM 3133 O O . ASN 386 386 ? A 21.70789 -16.35495 -4.66202 1 1 A ASN 1 1
ATOM 3134 C CB . ASN 386 386 ? A 22.56567 -13.88437 -6.70311 1 1 A ASN 1 1
ATOM 3135 C CG . ASN 386 386 ? A 21.48265 -13.48723 -7.66695 1 1 A ASN 1 1
ATOM 3136 O OD1 . ASN 386 386 ? A 20.81638 -14.32933 -8.26421 1 1 A ASN 1 1
ATOM 3137 N ND2 . ASN 386 386 ? A 21.28444 -12.19755 -7.82654 1 1 A ASN 1 1
ATOM 3138 N N . PHE 387 387 ? A 19.99155 -15.31387 -5.68265 1 1 A PHE 1 1
ATOM 3139 C CA . PHE 387 387 ? A 19.10753 -16.46145 -5.67227 1 1 A PHE 1 1
ATOM 3140 C C . PHE 387 387 ? A 18.12716 -16.38246 -6.82812 1 1 A PHE 1 1
ATOM 3141 O O . PHE 387 387 ? A 17.79080 -15.29619 -7.28621 1 1 A PHE 1 1
ATOM 3142 C CB . PHE 387 387 ? A 18.36365 -16.55977 -4.33726 1 1 A PHE 1 1
ATOM 3143 C CG . PHE 387 387 ? A 17.44754 -15.40084 -4.09638 1 1 A PHE 1 1
ATOM 3144 C CD1 . PHE 387 387 ? A 16.15904 -15.39286 -4.58901 1 1 A PHE 1 1
ATOM 3145 C CD2 . PHE 387 387 ? A 17.91022 -14.29768 -3.38380 1 1 A PHE 1 1
ATOM 3146 C CE1 . PHE 387 387 ? A 15.32234 -14.29147 -4.36591 1 1 A PHE 1 1
ATOM 3147 C CE2 . PHE 387 387 ? A 17.08132 -13.20135 -3.16750 1 1 A PHE 1 1
ATOM 3148 C CZ . PHE 387 387 ? A 15.78918 -13.20303 -3.66634 1 1 A PHE 1 1
ATOM 3149 N N . ASN 388 388 ? A 17.67138 -17.55203 -7.27991 1 1 A ASN 1 1
ATOM 3150 C CA . ASN 388 388 ? A 16.65101 -17.68175 -8.30789 1 1 A ASN 1 1
ATOM 3151 C C . ASN 388 388 ? A 15.77858 -18.88671 -8.02191 1 1 A ASN 1 1
ATOM 3152 O O . ASN 388 388 ? A 16.27832 -19.91894 -7.57321 1 1 A ASN 1 1
ATOM 3153 C CB . ASN 388 388 ? A 17.27438 -17.84882 -9.68665 1 1 A ASN 1 1
ATOM 3154 C CG . ASN 388 388 ? A 17.96085 -16.60399 -10.17707 1 1 A ASN 1 1
ATOM 3155 O OD1 . ASN 388 388 ? A 17.30869 -15.56250 -10.35349 1 1 A ASN 1 1
ATOM 3156 N ND2 . ASN 388 388 ? A 19.27408 -16.69762 -10.38698 1 1 A ASN 1 1
ATOM 3157 N N . ILE 389 389 ? A 14.49156 -18.78892 -8.30651 1 1 A ILE 1 1
ATOM 3158 C CA . ILE 389 389 ? A 13.56077 -19.89994 -8.18346 1 1 A ILE 1 1
ATOM 3159 C C . ILE 389 389 ? A 13.47192 -20.60475 -9.53149 1 1 A ILE 1 1
ATOM 3160 O O . ILE 389 389 ? A 13.08914 -19.98873 -10.52188 1 1 A ILE 1 1
ATOM 3161 C CB . ILE 389 389 ? A 12.17803 -19.42408 -7.73594 1 1 A ILE 1 1
ATOM 3162 C CG1 . ILE 389 389 ? A 12.27854 -18.72999 -6.36944 1 1 A ILE 1 1
ATOM 3163 C CG2 . ILE 389 389 ? A 11.21264 -20.59750 -7.67971 1 1 A ILE 1 1
ATOM 3164 C CD1 . ILE 389 389 ? A 10.98242 -18.06478 -5.93159 1 1 A ILE 1 1
ATOM 3165 N N . GLU 390 390 ? A 13.84121 -21.87302 -9.55514 1 1 A GLU 1 1
ATOM 3166 C CA . GLU 390 390 ? A 13.83237 -22.63215 -10.80507 1 1 A GLU 1 1
ATOM 3167 C C . GLU 390 390 ? A 12.45518 -23.13945 -11.18061 1 1 A GLU 1 1
ATOM 3168 O O . GLU 390 390 ? A 12.13913 -23.26997 -12.35142 1 1 A GLU 1 1
ATOM 3169 C CB . GLU 390 390 ? A 14.81472 -23.79909 -10.71882 1 1 A GLU 1 1
ATOM 3170 C CG . GLU 390 390 ? A 16.25834 -23.34420 -10.60547 1 1 A GLU 1 1
ATOM 3171 C CD . GLU 390 390 ? A 17.23064 -24.50317 -10.45805 1 1 A GLU 1 1
ATOM 3172 O OE1 . GLU 390 390 ? A 16.79301 -25.64564 -10.20405 1 1 A GLU 1 1
ATOM 3173 O OE2 . GLU 390 390 ? A 18.43960 -24.26691 -10.59800 1 1 A GLU 1 1
ATOM 3174 N N . ASN 391 391 ? A 11.62110 -23.43075 -10.17156 1 1 A ASN 1 1
ATOM 3175 C CA . ASN 391 391 ? A 10.25568 -23.87170 -10.40515 1 1 A ASN 1 1
ATOM 3176 C C . ASN 391 391 ? A 9.30056 -22.94727 -9.66121 1 1 A ASN 1 1
ATOM 3177 O O . ASN 391 391 ? A 9.12545 -23.07374 -8.44060 1 1 A ASN 1 1
ATOM 3178 C CB . ASN 391 391 ? A 10.07657 -25.31910 -9.93381 1 1 A ASN 1 1
ATOM 3179 C CG . ASN 391 391 ? A 8.67085 -25.84780 -10.16927 1 1 A ASN 1 1
ATOM 3180 O OD1 . ASN 391 391 ? A 7.79670 -25.13046 -10.64866 1 1 A ASN 1 1
ATOM 3181 N ND2 . ASN 391 391 ? A 8.45790 -27.11337 -9.82384 1 1 A ASN 1 1
ATOM 3182 N N . THR 392 392 ? A 8.69055 -22.02756 -10.37264 1 1 A THR 1 1
ATOM 3183 C CA . THR 392 392 ? A 7.83586 -21.02748 -9.75891 1 1 A THR 1 1
ATOM 3184 C C . THR 392 392 ? A 6.49536 -21.56666 -9.26584 1 1 A THR 1 1
ATOM 3185 O O . THR 392 392 ? A 5.77686 -20.87394 -8.54525 1 1 A THR 1 1
ATOM 3186 C CB . THR 392 392 ? A 7.59126 -19.84722 -10.70290 1 1 A THR 1 1
ATOM 3187 O OG1 . THR 392 392 ? A 6.98457 -20.31192 -11.91088 1 1 A THR 1 1
ATOM 3188 C CG2 . THR 392 392 ? A 8.90178 -19.15041 -11.03092 1 1 A THR 1 1
ATOM 3189 N N . ASN 393 393 ? A 6.16794 -22.80768 -9.66064 1 1 A ASN 1 1
ATOM 3190 C CA . ASN 393 393 ? A 4.92734 -23.43283 -9.25279 1 1 A ASN 1 1
ATOM 3191 C C . ASN 393 393 ? A 5.07686 -24.34442 -8.03644 1 1 A ASN 1 1
ATOM 3192 O O . ASN 393 393 ? A 4.14540 -25.06099 -7.67838 1 1 A ASN 1 1
ATOM 3193 C CB . ASN 393 393 ? A 4.32068 -24.23483 -10.40498 1 1 A ASN 1 1
ATOM 3194 C CG . ASN 393 393 ? A 4.01610 -23.36616 -11.60563 1 1 A ASN 1 1
ATOM 3195 O OD1 . ASN 393 393 ? A 3.44769 -22.28856 -11.45740 1 1 A ASN 1 1
ATOM 3196 N ND2 . ASN 393 393 ? A 4.42015 -23.82044 -12.78397 1 1 A ASN 1 1
ATOM 3197 N N . CYS 394 394 ? A 6.22318 -24.32775 -7.38408 1 1 A CYS 1 1
ATOM 3198 C CA . CYS 394 394 ? A 6.52019 -25.24737 -6.30030 1 1 A CYS 1 1
ATOM 3199 C C . CYS 394 394 ? A 5.69734 -25.00926 -5.04240 1 1 A CYS 1 1
ATOM 3200 O O . CYS 394 394 ? A 5.54630 -25.93296 -4.23067 1 1 A CYS 1 1
ATOM 3201 C CB . CYS 394 394 ? A 8.00657 -25.19406 -5.95462 1 1 A CYS 1 1
ATOM 3202 S SG . CYS 394 394 ? A 8.59520 -23.61941 -5.32944 1 1 A CYS 1 1
ATOM 3203 N N . LEU 395 395 ? A 5.19205 -23.80215 -4.82313 1 1 A LEU 1 1
ATOM 3204 C CA . LEU 395 395 ? A 4.40795 -23.48718 -3.63681 1 1 A LEU 1 1
ATOM 3205 C C . LEU 395 395 ? A 3.11656 -22.77401 -4.00232 1 1 A LEU 1 1
ATOM 3206 O O . LEU 395 395 ? A 3.08311 -21.99927 -4.95637 1 1 A LEU 1 1
ATOM 3207 C CB . LEU 395 395 ? A 5.20454 -22.61445 -2.67413 1 1 A LEU 1 1
ATOM 3208 C CG . LEU 395 395 ? A 6.39804 -23.25846 -1.99215 1 1 A LEU 1 1
ATOM 3209 C CD1 . LEU 395 395 ? A 7.15574 -22.22665 -1.16248 1 1 A LEU 1 1
ATOM 3210 C CD2 . LEU 395 395 ? A 5.95879 -24.41245 -1.10867 1 1 A LEU 1 1
ATOM 3211 N N . ASN 396 396 ? A 2.06551 -23.05585 -3.23591 1 1 A ASN 1 1
ATOM 3212 C CA . ASN 396 396 ? A 0.81859 -22.32898 -3.37863 1 1 A ASN 1 1
ATOM 3213 C C . ASN 396 396 ? A 0.79695 -21.09236 -2.49611 1 1 A ASN 1 1
ATOM 3214 O O . ASN 396 396 ? A 0.22466 -20.07692 -2.86516 1 1 A ASN 1 1
ATOM 3215 C CB . ASN 396 396 ? A -0.38064 -23.22699 -3.04673 1 1 A ASN 1 1
ATOM 3216 C CG . ASN 396 396 ? A -0.53819 -24.37279 -4.03411 1 1 A ASN 1 1
ATOM 3217 O OD1 . ASN 396 396 ? A -0.28693 -24.20573 -5.22653 1 1 A ASN 1 1
ATOM 3218 N ND2 . ASN 396 396 ? A -0.94008 -25.53292 -3.53678 1 1 A ASN 1 1
ATOM 3219 N N . ASN 397 397 ? A 1.38349 -21.15293 -1.33580 1 1 A ASN 1 1
ATOM 3220 C CA . ASN 397 397 ? A 1.47928 -20.06832 -0.38723 1 1 A ASN 1 1
ATOM 3221 C C . ASN 397 397 ? A 2.76491 -20.19603 0.39103 1 1 A ASN 1 1
ATOM 3222 O O . ASN 397 397 ? A 3.05485 -21.29452 0.90771 1 1 A ASN 1 1
ATOM 3223 C CB . ASN 397 397 ? A 0.28139 -20.07658 0.56420 1 1 A ASN 1 1
ATOM 3224 C CG . ASN 397 397 ? A 0.30403 -18.94522 1.56065 1 1 A ASN 1 1
ATOM 3225 O OD1 . ASN 397 397 ? A 0.48430 -17.79028 1.17246 1 1 A ASN 1 1
ATOM 3226 N ND2 . ASN 397 397 ? A 0.14318 -19.25844 2.82899 1 1 A ASN 1 1
ATOM 3227 N N . PRO 398 398 ? A 3.58851 -19.21056 0.46520 1 1 A PRO 1 1
ATOM 3228 C CA . PRO 398 398 ? A 3.39866 -17.85784 -0.00769 1 1 A PRO 1 1
ATOM 3229 C C . PRO 398 398 ? A 3.52847 -17.77829 -1.52240 1 1 A PRO 1 1
ATOM 3230 O O . PRO 398 398 ? A 4.08448 -18.68617 -2.15547 1 1 A PRO 1 1
ATOM 3231 C CB . PRO 398 398 ? A 4.52853 -17.09239 0.67752 1 1 A PRO 1 1
ATOM 3232 C CG . PRO 398 398 ? A 5.59492 -18.10234 0.85581 1 1 A PRO 1 1
ATOM 3233 C CD . PRO 398 398 ? A 4.88045 -19.40762 1.10664 1 1 A PRO 1 1
ATOM 3234 N N . SER 399 399 ? A 3.08151 -16.70910 -2.08876 1 1 A SER 1 1
ATOM 3235 C CA . SER 399 399 ? A 3.19127 -16.51264 -3.51614 1 1 A SER 1 1
ATOM 3236 C C . SER 399 399 ? A 4.64042 -16.29768 -3.90998 1 1 A SER 1 1
ATOM 3237 O O . SER 399 399 ? A 5.46692 -15.88836 -3.08697 1 1 A SER 1 1
ATOM 3238 C CB . SER 399 399 ? A 2.34855 -15.31619 -3.95282 1 1 A SER 1 1
ATOM 3239 O OG . SER 399 399 ? A 2.92096 -14.10697 -3.47092 1 1 A SER 1 1
ATOM 3240 N N . ILE 400 400 ? A 4.95253 -16.57920 -5.14062 1 1 A ILE 1 1
ATOM 3241 C CA . ILE 400 400 ? A 6.29315 -16.34941 -5.66133 1 1 A ILE 1 1
ATOM 3242 C C . ILE 400 400 ? A 6.68267 -14.88038 -5.50027 1 1 A ILE 1 1
ATOM 3243 O O . ILE 400 400 ? A 7.83336 -14.56424 -5.19202 1 1 A ILE 1 1
ATOM 3244 C CB . ILE 400 400 ? A 6.40117 -16.79520 -7.12188 1 1 A ILE 1 1
ATOM 3245 C CG1 . ILE 400 400 ? A 6.14760 -18.30196 -7.22440 1 1 A ILE 1 1
ATOM 3246 C CG2 . ILE 400 400 ? A 7.75494 -16.44211 -7.71563 1 1 A ILE 1 1
ATOM 3247 C CD1 . ILE 400 400 ? A 7.06514 -19.15532 -6.37137 1 1 A ILE 1 1
ATOM 3248 N N . GLU 401 401 ? A 5.74149 -14.00179 -5.70006 1 1 A GLU 1 1
ATOM 3249 C CA . GLU 401 401 ? A 5.97089 -12.58373 -5.51526 1 1 A GLU 1 1
ATOM 3250 C C . GLU 401 401 ? A 6.42726 -12.28795 -4.08774 1 1 A GLU 1 1
ATOM 3251 O O . GLU 401 401 ? A 7.37250 -11.52582 -3.87098 1 1 A GLU 1 1
ATOM 3252 C CB . GLU 401 401 ? A 4.70624 -11.80584 -5.84762 1 1 A GLU 1 1
ATOM 3253 C CG . GLU 401 401 ? A 4.83068 -10.29859 -5.63057 1 1 A GLU 1 1
ATOM 3254 C CD . GLU 401 401 ? A 3.51195 -9.57987 -5.86979 1 1 A GLU 1 1
ATOM 3255 O OE1 . GLU 401 401 ? A 2.47812 -10.23803 -6.00882 1 1 A GLU 1 1
ATOM 3256 O OE2 . GLU 401 401 ? A 3.53207 -8.32808 -5.92993 1 1 A GLU 1 1
ATOM 3257 N N . THR 402 402 ? A 5.76578 -12.89597 -3.10793 1 1 A THR 1 1
ATOM 3258 C CA . THR 402 402 ? A 6.12047 -12.71218 -1.71305 1 1 A THR 1 1
ATOM 3259 C C . THR 402 402 ? A 7.51832 -13.24971 -1.42788 1 1 A THR 1 1
ATOM 3260 O O . THR 402 402 ? A 8.29965 -12.62275 -0.70107 1 1 A THR 1 1
ATOM 3261 C CB . THR 402 402 ? A 5.09481 -13.38861 -0.79327 1 1 A THR 1 1
ATOM 3262 O OG1 . THR 402 402 ? A 3.82422 -12.76434 -0.97836 1 1 A THR 1 1
ATOM 3263 C CG2 . THR 402 402 ? A 5.50211 -13.26630 0.66759 1 1 A THR 1 1
ATOM 3264 N N . ILE 403 403 ? A 7.83744 -14.37566 -1.97898 1 1 A ILE 1 1
ATOM 3265 C CA . ILE 403 403 ? A 9.14419 -14.97971 -1.77166 1 1 A ILE 1 1
ATOM 3266 C C . ILE 403 403 ? A 10.24645 -14.07454 -2.30159 1 1 A ILE 1 1
ATOM 3267 O O . ILE 403 403 ? A 11.22259 -13.78537 -1.58503 1 1 A ILE 1 1
ATOM 3268 C CB . ILE 403 403 ? A 9.21202 -16.36877 -2.39995 1 1 A ILE 1 1
ATOM 3269 C CG1 . ILE 403 403 ? A 8.23381 -17.30161 -1.67795 1 1 A ILE 1 1
ATOM 3270 C CG2 . ILE 403 403 ? A 10.62304 -16.92150 -2.32918 1 1 A ILE 1 1
ATOM 3271 C CD1 . ILE 403 403 ? A 7.97644 -18.60233 -2.40662 1 1 A ILE 1 1
ATOM 3272 N N . TYR 404 404 ? A 10.13896 -13.64672 -3.53926 1 1 A TYR 1 1
ATOM 3273 C CA . TYR 404 404 ? A 11.13836 -12.76958 -4.10768 1 1 A TYR 1 1
ATOM 3274 C C . TYR 404 404 ? A 11.26112 -11.45180 -3.34227 1 1 A TYR 1 1
ATOM 3275 O O . TYR 404 404 ? A 12.35822 -10.94558 -3.14949 1 1 A TYR 1 1
ATOM 3276 C CB . TYR 404 404 ? A 10.84804 -12.46435 -5.57867 1 1 A TYR 1 1
ATOM 3277 C CG . TYR 404 404 ? A 11.30772 -13.54118 -6.54240 1 1 A TYR 1 1
ATOM 3278 C CD1 . TYR 404 404 ? A 12.60050 -14.02529 -6.50285 1 1 A TYR 1 1
ATOM 3279 C CD2 . TYR 404 404 ? A 10.43531 -14.05351 -7.49885 1 1 A TYR 1 1
ATOM 3280 C CE1 . TYR 404 404 ? A 13.02961 -14.99363 -7.38733 1 1 A TYR 1 1
ATOM 3281 C CE2 . TYR 404 404 ? A 10.85178 -15.02520 -8.39642 1 1 A TYR 1 1
ATOM 3282 C CZ . TYR 404 404 ? A 12.15323 -15.50169 -8.33821 1 1 A TYR 1 1
ATOM 3283 O OH . TYR 404 404 ? A 12.56246 -16.46127 -9.23594 1 1 A TYR 1 1
ATOM 3284 N N . GLY 405 405 ? A 10.12322 -10.93702 -2.87413 1 1 A GLY 1 1
ATOM 3285 C CA . GLY 405 405 ? A 10.12443 -9.68220 -2.15257 1 1 A GLY 1 1
ATOM 3286 C C . GLY 405 405 ? A 10.69433 -9.77743 -0.75815 1 1 A GLY 1 1
ATOM 3287 O O . GLY 405 405 ? A 11.24843 -8.81156 -0.23158 1 1 A GLY 1 1
ATOM 3288 N N . ASN 406 406 ? A 10.59339 -10.95922 -0.13657 1 1 A ASN 1 1
ATOM 3289 C CA . ASN 406 406 ? A 10.96771 -11.13783 1.25812 1 1 A ASN 1 1
ATOM 3290 C C . ASN 406 406 ? A 12.31290 -11.83317 1.44825 1 1 A ASN 1 1
ATOM 3291 O O . ASN 406 406 ? A 12.90558 -11.75781 2.52965 1 1 A ASN 1 1
ATOM 3292 C CB . ASN 406 406 ? A 9.87482 -11.93995 1.97039 1 1 A ASN 1 1
ATOM 3293 C CG . ASN 406 406 ? A 10.04315 -11.97141 3.46990 1 1 A ASN 1 1
ATOM 3294 O OD1 . ASN 406 406 ? A 10.55696 -11.04458 4.06844 1 1 A ASN 1 1
ATOM 3295 N ND2 . ASN 406 406 ? A 9.57239 -13.05533 4.07709 1 1 A ASN 1 1
ATOM 3296 N N . PHE 407 407 ? A 12.83141 -12.51555 0.45071 1 1 A PHE 1 1
ATOM 3297 C CA . PHE 407 407 ? A 14.00294 -13.34925 0.60145 1 1 A PHE 1 1
ATOM 3298 C C . PHE 407 407 ? A 15.26199 -12.61979 1.05944 1 1 A PHE 1 1
ATOM 3299 O O . PHE 407 407 ? A 16.11728 -13.21463 1.71577 1 1 A PHE 1 1
ATOM 3300 C CB . PHE 407 407 ? A 14.26961 -14.09729 -0.71105 1 1 A PHE 1 1
ATOM 3301 C CG . PHE 407 407 ? A 15.33175 -15.15835 -0.57873 1 1 A PHE 1 1
ATOM 3302 C CD1 . PHE 407 407 ? A 15.06013 -16.35261 0.05221 1 1 A PHE 1 1
ATOM 3303 C CD2 . PHE 407 407 ? A 16.61019 -14.93505 -1.08552 1 1 A PHE 1 1
ATOM 3304 C CE1 . PHE 407 407 ? A 16.04064 -17.32337 0.17562 1 1 A PHE 1 1
ATOM 3305 C CE2 . PHE 407 407 ? A 17.59179 -15.90889 -0.95791 1 1 A PHE 1 1
ATOM 3306 C CZ . PHE 407 407 ? A 17.29715 -17.10546 -0.32839 1 1 A PHE 1 1
ATOM 3307 N N . ASN 408 408 ? A 15.40447 -11.36483 0.75397 1 1 A ASN 1 1
ATOM 3308 C CA . ASN 408 408 ? A 16.57819 -10.61693 1.18338 1 1 A ASN 1 1
ATOM 3309 C C . ASN 408 408 ? A 16.69401 -10.54210 2.70010 1 1 A ASN 1 1
ATOM 3310 O O . ASN 408 408 ? A 17.80297 -10.44566 3.22503 1 1 A ASN 1 1
ATOM 3311 C CB . ASN 408 408 ? A 16.58739 -9.21107 0.57277 1 1 A ASN 1 1
ATOM 3312 C CG . ASN 408 408 ? A 16.93279 -9.22686 -0.89894 1 1 A ASN 1 1
ATOM 3313 O OD1 . ASN 408 408 ? A 17.79143 -10.00187 -1.32847 1 1 A ASN 1 1
ATOM 3314 N ND2 . ASN 408 408 ? A 16.27431 -8.38155 -1.67268 1 1 A ASN 1 1
ATOM 3315 N N . GLN 409 409 ? A 15.55168 -10.59564 3.39643 1 1 A GLN 1 1
ATOM 3316 C CA . GLN 409 409 ? A 15.61377 -10.68395 4.85039 1 1 A GLN 1 1
ATOM 3317 C C . GLN 409 409 ? A 16.32307 -11.96046 5.26474 1 1 A GLN 1 1
ATOM 3318 O O . GLN 409 409 ? A 17.18717 -11.94784 6.14613 1 1 A GLN 1 1
ATOM 3319 C CB . GLN 409 409 ? A 14.22014 -10.66468 5.46399 1 1 A GLN 1 1
ATOM 3320 C CG . GLN 409 409 ? A 13.49827 -9.34191 5.33221 1 1 A GLN 1 1
ATOM 3321 C CD . GLN 409 409 ? A 12.16684 -9.38751 6.07269 1 1 A GLN 1 1
ATOM 3322 O OE1 . GLN 409 409 ? A 12.12275 -9.14210 7.27401 1 1 A GLN 1 1
ATOM 3323 N NE2 . GLN 409 409 ? A 11.12068 -9.74274 5.36594 1 1 A GLN 1 1
ATOM 3324 N N . PHE 410 410 ? A 15.93833 -13.06179 4.61182 1 1 A PHE 1 1
ATOM 3325 C CA . PHE 410 410 ? A 16.57831 -14.33583 4.89344 1 1 A PHE 1 1
ATOM 3326 C C . PHE 410 410 ? A 18.06882 -14.27777 4.58992 1 1 A PHE 1 1
ATOM 3327 O O . PHE 410 410 ? A 18.89808 -14.70340 5.40690 1 1 A PHE 1 1
ATOM 3328 C CB . PHE 410 410 ? A 15.90651 -15.45721 4.09153 1 1 A PHE 1 1
ATOM 3329 C CG . PHE 410 410 ? A 16.60714 -16.78037 4.24873 1 1 A PHE 1 1
ATOM 3330 C CD1 . PHE 410 410 ? A 16.35583 -17.58821 5.33588 1 1 A PHE 1 1
ATOM 3331 C CD2 . PHE 410 410 ? A 17.53138 -17.19394 3.29013 1 1 A PHE 1 1
ATOM 3332 C CE1 . PHE 410 410 ? A 17.01583 -18.79257 5.48554 1 1 A PHE 1 1
ATOM 3333 C CE2 . PHE 410 410 ? A 18.20680 -18.40736 3.43857 1 1 A PHE 1 1
ATOM 3334 C CZ . PHE 410 410 ? A 17.94055 -19.20082 4.53766 1 1 A PHE 1 1
ATOM 3335 N N . VAL 411 411 ? A 18.41740 -13.78390 3.42499 1 1 A VAL 1 1
ATOM 3336 C CA . VAL 411 411 ? A 19.81203 -13.68475 3.01867 1 1 A VAL 1 1
ATOM 3337 C C . VAL 411 411 ? A 20.61339 -12.86678 4.02332 1 1 A VAL 1 1
ATOM 3338 O O . VAL 411 411 ? A 21.72094 -13.26313 4.41093 1 1 A VAL 1 1
ATOM 3339 C CB . VAL 411 411 ? A 19.93025 -13.09336 1.59619 1 1 A VAL 1 1
ATOM 3340 C CG1 . VAL 411 411 ? A 21.37638 -12.77347 1.25786 1 1 A VAL 1 1
ATOM 3341 C CG2 . VAL 411 411 ? A 19.34622 -14.06219 0.58326 1 1 A VAL 1 1
ATOM 3342 N N . SER 412 412 ? A 20.08385 -11.76688 4.46568 1 1 A SER 1 1
ATOM 3343 C CA . SER 412 412 ? A 20.76724 -10.91704 5.43585 1 1 A SER 1 1
ATOM 3344 C C . SER 412 412 ? A 20.99737 -11.65200 6.74779 1 1 A SER 1 1
ATOM 3345 O O . SER 412 412 ? A 22.10897 -11.61118 7.29688 1 1 A SER 1 1
ATOM 3346 C CB . SER 412 412 ? A 19.96851 -9.63807 5.67596 1 1 A SER 1 1
ATOM 3347 O OG . SER 412 412 ? A 20.59289 -8.83615 6.65727 1 1 A SER 1 1
ATOM 3348 N N . ILE 413 413 ? A 19.97902 -12.31451 7.24578 1 1 A ILE 1 1
ATOM 3349 C CA . ILE 413 413 ? A 20.11449 -13.06566 8.48901 1 1 A ILE 1 1
ATOM 3350 C C . ILE 413 413 ? A 21.06896 -14.23560 8.28550 1 1 A ILE 1 1
ATOM 3351 O O . ILE 413 413 ? A 21.94284 -14.47709 9.13393 1 1 A ILE 1 1
ATOM 3352 C CB . ILE 413 413 ? A 18.73695 -13.54641 8.99033 1 1 A ILE 1 1
ATOM 3353 C CG1 . ILE 413 413 ? A 17.90554 -12.32572 9.43012 1 1 A ILE 1 1
ATOM 3354 C CG2 . ILE 413 413 ? A 18.89650 -14.53060 10.13789 1 1 A ILE 1 1
ATOM 3355 C CD1 . ILE 413 413 ? A 16.45202 -12.62928 9.69610 1 1 A ILE 1 1
ATOM 3356 N N . PHE 414 414 ? A 20.92997 -14.95417 7.19092 1 1 A PHE 1 1
ATOM 3357 C CA . PHE 414 414 ? A 21.82284 -16.05919 6.85995 1 1 A PHE 1 1
ATOM 3358 C C . PHE 414 414 ? A 23.27645 -15.59692 6.85362 1 1 A PHE 1 1
ATOM 3359 O O . PHE 414 414 ? A 24.14571 -16.23163 7.46623 1 1 A PHE 1 1
ATOM 3360 C CB . PHE 414 414 ? A 21.42570 -16.65480 5.50449 1 1 A PHE 1 1
ATOM 3361 C CG . PHE 414 414 ? A 22.29187 -17.80622 5.04805 1 1 A PHE 1 1
ATOM 3362 C CD1 . PHE 414 414 ? A 23.50373 -17.55701 4.41509 1 1 A PHE 1 1
ATOM 3363 C CD2 . PHE 414 414 ? A 21.89042 -19.12277 5.24125 1 1 A PHE 1 1
ATOM 3364 C CE1 . PHE 414 414 ? A 24.31324 -18.61548 3.99160 1 1 A PHE 1 1
ATOM 3365 C CE2 . PHE 414 414 ? A 22.69705 -20.16639 4.81406 1 1 A PHE 1 1
ATOM 3366 C CZ . PHE 414 414 ? A 23.90993 -19.92618 4.19070 1 1 A PHE 1 1
ATOM 3367 N N . ASN 415 415 ? A 23.55000 -14.51666 6.17118 1 1 A ASN 1 1
ATOM 3368 C CA . ASN 415 415 ? A 24.91302 -14.00342 6.08936 1 1 A ASN 1 1
ATOM 3369 C C . ASN 415 415 ? A 25.42867 -13.50627 7.43207 1 1 A ASN 1 1
ATOM 3370 O O . ASN 415 415 ? A 26.61013 -13.68257 7.75486 1 1 A ASN 1 1
ATOM 3371 C CB . ASN 415 415 ? A 25.01320 -12.90013 5.02898 1 1 A ASN 1 1
ATOM 3372 C CG . ASN 415 415 ? A 24.84348 -13.43308 3.61666 1 1 A ASN 1 1
ATOM 3373 O OD1 . ASN 415 415 ? A 24.98007 -14.63784 3.38095 1 1 A ASN 1 1
ATOM 3374 N ND2 . ASN 415 415 ? A 24.53344 -12.54491 2.68433 1 1 A ASN 1 1
ATOM 3375 N N . THR 416 416 ? A 24.55186 -12.92284 8.24554 1 1 A THR 1 1
ATOM 3376 C CA . THR 416 416 ? A 24.93273 -12.48119 9.58245 1 1 A THR 1 1
ATOM 3377 C C . THR 416 416 ? A 25.30112 -13.67419 10.45431 1 1 A THR 1 1
ATOM 3378 O O . THR 416 416 ? A 26.33730 -13.64989 11.14140 1 1 A THR 1 1
ATOM 3379 C CB . THR 416 416 ? A 23.80279 -11.66334 10.23054 1 1 A THR 1 1
ATOM 3380 O OG1 . THR 416 416 ? A 23.56563 -10.49498 9.44113 1 1 A THR 1 1
ATOM 3381 C CG2 . THR 416 416 ? A 24.16928 -11.25022 11.64995 1 1 A THR 1 1
ATOM 3382 N N . VAL 417 417 ? A 24.47041 -14.72209 10.44339 1 1 A VAL 1 1
ATOM 3383 C CA . VAL 417 417 ? A 24.74906 -15.91193 11.23483 1 1 A VAL 1 1
ATOM 3384 C C . VAL 417 417 ? A 26.04157 -16.56927 10.77436 1 1 A VAL 1 1
ATOM 3385 O O . VAL 417 417 ? A 26.84486 -17.02492 11.60348 1 1 A VAL 1 1
ATOM 3386 C CB . VAL 417 417 ? A 23.56627 -16.90930 11.17989 1 1 A VAL 1 1
ATOM 3387 C CG1 . VAL 417 417 ? A 23.92824 -18.21337 11.86340 1 1 A VAL 1 1
ATOM 3388 C CG2 . VAL 417 417 ? A 22.34004 -16.28867 11.83793 1 1 A VAL 1 1
ATOM 3389 N N . THR 418 418 ? A 26.27002 -16.62192 9.46477 1 1 A THR 1 1
ATOM 3390 C CA . THR 418 418 ? A 27.51568 -17.16196 8.93531 1 1 A THR 1 1
ATOM 3391 C C . THR 418 418 ? A 28.71338 -16.39901 9.49134 1 1 A THR 1 1
ATOM 3392 O O . THR 418 418 ? A 29.69697 -17.00471 9.94134 1 1 A THR 1 1
ATOM 3393 C CB . THR 418 418 ? A 27.52066 -17.12216 7.39848 1 1 A THR 1 1
ATOM 3394 O OG1 . THR 418 418 ? A 26.46056 -17.94140 6.90967 1 1 A THR 1 1
ATOM 3395 C CG2 . THR 418 418 ? A 28.85141 -17.59583 6.83963 1 1 A THR 1 1
ATOM 3396 N N . ASP 419 419 ? A 28.63688 -15.08671 9.47379 1 1 A ASP 1 1
ATOM 3397 C CA . ASP 419 419 ? A 29.71077 -14.24854 9.99231 1 1 A ASP 1 1
ATOM 3398 C C . ASP 419 419 ? A 29.92874 -14.47663 11.48466 1 1 A ASP 1 1
ATOM 3399 O O . ASP 419 419 ? A 31.06893 -14.56536 11.95573 1 1 A ASP 1 1
ATOM 3400 C CB . ASP 419 419 ? A 29.41424 -12.77341 9.70595 1 1 A ASP 1 1
ATOM 3401 C CG . ASP 419 419 ? A 30.57741 -11.84975 9.99098 1 1 A ASP 1 1
ATOM 3402 O OD1 . ASP 419 419 ? A 31.71899 -12.22822 9.67235 1 1 A ASP 1 1
ATOM 3403 O OD2 . ASP 419 419 ? A 30.35458 -10.75107 10.52158 1 1 A ASP 1 1
ATOM 3404 N N . VAL 420 420 ? A 28.84431 -14.56833 12.24817 1 1 A VAL 1 1
ATOM 3405 C CA . VAL 420 420 ? A 28.92924 -14.78887 13.68538 1 1 A VAL 1 1
ATOM 3406 C C . VAL 420 420 ? A 29.60637 -16.12113 13.99334 1 1 A VAL 1 1
ATOM 3407 O O . VAL 420 420 ? A 30.46829 -16.19027 14.88845 1 1 A VAL 1 1
ATOM 3408 C CB . VAL 420 420 ? A 27.52606 -14.71600 14.33124 1 1 A VAL 1 1
ATOM 3409 C CG1 . VAL 420 420 ? A 27.54358 -15.26849 15.75215 1 1 A VAL 1 1
ATOM 3410 C CG2 . VAL 420 420 ? A 27.03367 -13.27771 14.32152 1 1 A VAL 1 1
ATOM 3411 N N . LYS 421 421 ? A 29.20945 -17.20696 13.28584 1 1 A LYS 1 1
ATOM 3412 C CA . LYS 421 421 ? A 29.84765 -18.50544 13.49056 1 1 A LYS 1 1
ATOM 3413 C C . LYS 421 421 ? A 31.34641 -18.44444 13.24273 1 1 A LYS 1 1
ATOM 3414 O O . LYS 421 421 ? A 32.12925 -19.02959 14.00404 1 1 A LYS 1 1
ATOM 3415 C CB . LYS 421 421 ? A 29.21077 -19.57166 12.60770 1 1 A LYS 1 1
ATOM 3416 C CG . LYS 421 421 ? A 27.88230 -20.11473 13.11331 1 1 A LYS 1 1
ATOM 3417 C CD . LYS 421 421 ? A 27.37579 -21.24339 12.22559 1 1 A LYS 1 1
ATOM 3418 C CE . LYS 421 421 ? A 26.14982 -21.90820 12.81927 1 1 A LYS 1 1
ATOM 3419 N NZ . LYS 421 421 ? A 26.49489 -23.06073 13.64441 1 1 A LYS 1 1
ATOM 3420 N N . LYS 422 422 ? A 31.77498 -17.76163 12.16681 1 1 A LYS 1 1
ATOM 3421 C CA . LYS 422 422 ? A 33.20225 -17.62611 11.88421 1 1 A LYS 1 1
ATOM 3422 C C . LYS 422 422 ? A 33.93533 -16.94577 13.02883 1 1 A LYS 1 1
ATOM 3423 O O . LYS 422 422 ? A 34.99387 -17.40942 13.46913 1 1 A LYS 1 1
ATOM 3424 C CB . LYS 422 422 ? A 33.42996 -16.84122 10.59753 1 1 A LYS 1 1
ATOM 3425 C CG . LYS 422 422 ? A 33.16356 -17.60036 9.32500 1 1 A LYS 1 1
ATOM 3426 C CD . LYS 422 422 ? A 33.41978 -16.70878 8.11737 1 1 A LYS 1 1
ATOM 3427 C CE . LYS 422 422 ? A 33.11634 -17.42499 6.82899 1 1 A LYS 1 1
ATOM 3428 N NZ . LYS 422 422 ? A 34.20698 -18.32708 6.48828 1 1 A LYS 1 1
ATOM 3429 N N . ARG 423 423 ? A 33.38844 -15.86181 13.54897 1 1 A ARG 1 1
ATOM 3430 C CA . ARG 423 423 ? A 34.02158 -15.10004 14.62181 1 1 A ARG 1 1
ATOM 3431 C C . ARG 423 423 ? A 34.07666 -15.87686 15.92621 1 1 A ARG 1 1
ATOM 3432 O O . ARG 423 423 ? A 35.02266 -15.72412 16.70341 1 1 A ARG 1 1
ATOM 3433 C CB . ARG 423 423 ? A 33.30219 -13.78174 14.83266 1 1 A ARG 1 1
ATOM 3434 C CG . ARG 423 423 ? A 33.44507 -12.81227 13.67917 1 1 A ARG 1 1
ATOM 3435 C CD . ARG 423 423 ? A 32.83636 -11.47797 14.01629 1 1 A ARG 1 1
ATOM 3436 N NE . ARG 423 423 ? A 32.77508 -10.59220 12.86220 1 1 A ARG 1 1
ATOM 3437 C CZ . ARG 423 423 ? A 32.59324 -9.28398 12.95316 1 1 A ARG 1 1
ATOM 3438 N NH1 . ARG 423 423 ? A 32.44315 -8.68494 14.11465 1 1 A ARG 1 1
ATOM 3439 N NH2 . ARG 423 423 ? A 32.56102 -8.56048 11.84417 1 1 A ARG 1 1
ATOM 3440 N N . LEU 424 424 ? A 33.08598 -16.69799 16.19357 1 1 A LEU 1 1
ATOM 3441 C CA . LEU 424 424 ? A 33.02700 -17.44979 17.44773 1 1 A LEU 1 1
ATOM 3442 C C . LEU 424 424 ? A 33.85471 -18.72389 17.42310 1 1 A LEU 1 1
ATOM 3443 O O . LEU 424 424 ? A 34.45427 -19.09922 18.44434 1 1 A LEU 1 1
ATOM 3444 C CB . LEU 424 424 ? A 31.58159 -17.80470 17.79645 1 1 A LEU 1 1
ATOM 3445 C CG . LEU 424 424 ? A 30.66914 -16.66069 18.18186 1 1 A LEU 1 1
ATOM 3446 C CD1 . LEU 424 424 ? A 29.23835 -17.14260 18.33649 1 1 A LEU 1 1
ATOM 3447 C CD2 . LEU 424 424 ? A 31.13544 -16.00954 19.47761 1 1 A LEU 1 1
ATOM 3448 N N . PHE 425 425 ? A 33.90449 -19.41323 16.27434 1 1 A PHE 1 1
ATOM 3449 C CA . PHE 425 425 ? A 34.45277 -20.76439 16.25844 1 1 A PHE 1 1
ATOM 3450 C C . PHE 425 425 ? A 35.68864 -20.94815 15.37724 1 1 A PHE 1 1
ATOM 3451 O O . PHE 425 425 ? A 36.33159 -21.99940 15.43164 1 1 A PHE 1 1
ATOM 3452 C CB . PHE 425 425 ? A 33.36977 -21.76356 15.85848 1 1 A PHE 1 1
ATOM 3453 C CG . PHE 425 425 ? A 32.16338 -21.68088 16.75006 1 1 A PHE 1 1
ATOM 3454 C CD1 . PHE 425 425 ? A 32.21626 -22.15619 18.04754 1 1 A PHE 1 1
ATOM 3455 C CD2 . PHE 425 425 ? A 30.99248 -21.10670 16.29497 1 1 A PHE 1 1
ATOM 3456 C CE1 . PHE 425 425 ? A 31.11488 -22.07750 18.88157 1 1 A PHE 1 1
ATOM 3457 C CE2 . PHE 425 425 ? A 29.88576 -21.02305 17.11993 1 1 A PHE 1 1
ATOM 3458 C CZ . PHE 425 425 ? A 29.95190 -21.51077 18.42606 1 1 A PHE 1 1
ATOM 3459 N N . GLU 426 426 ? A 36.06227 -19.96292 14.56030 1 1 A GLU 1 1
ATOM 3460 C CA . GLU 426 426 ? A 37.25222 -20.10121 13.70229 1 1 A GLU 1 1
ATOM 3461 C C . GLU 426 426 ? A 38.43143 -19.23984 14.19052 1 1 A GLU 1 1
ATOM 3462 O O . GLU 426 426 ? A 38.19394 -18.17363 14.74280 1 1 A GLU 1 1
ATOM 3463 C CB . GLU 426 426 ? A 36.91142 -19.77801 12.24726 1 1 A GLU 1 1
ATOM 3464 C CG . GLU 426 426 ? A 35.94174 -20.78002 11.63636 1 1 A GLU 1 1
ATOM 3465 C CD . GLU 426 426 ? A 35.58391 -20.43724 10.20757 1 1 A GLU 1 1
ATOM 3466 O OE1 . GLU 426 426 ? A 35.97660 -19.35139 9.70521 1 1 A GLU 1 1
ATOM 3467 O OE2 . GLU 426 426 ? A 34.89222 -21.24712 9.56564 1 1 A GLU 1 1
HETATM 3468 C C27 . LIG . 1 ? B 8.82037 -17.39499 2.93932 1 2 B LIG 1 1
HETATM 3469 N N21 . LIG . 1 ? B 9.05690 -18.65873 2.88579 1 2 B LIG 1 1
HETATM 3470 N N22 . LIG . 1 ? B 10.19926 -18.94460 2.25950 1 2 B LIG 1 1
HETATM 3471 C C28 . LIG . 1 ? B 10.82702 -17.88361 1.83203 1 2 B LIG 1 1
HETATM 3472 S S24 . LIG . 1 ? B 9.98125 -16.53375 2.19327 1 2 B LIG 1 1
HETATM 3473 C C43 . LIG . 1 ? B 12.15099 -17.91981 1.13855 1 2 B LIG 1 1
HETATM 3474 C C44 . LIG . 1 ? B 13.14036 -18.60080 2.00805 1 2 B LIG 1 1
HETATM 3475 C C42 . LIG . 1 ? B 14.28368 -19.22483 1.32644 1 2 B LIG 1 1
HETATM 3476 C C25 . LIG . 1 ? B 7.63920 -16.80898 3.59351 1 2 B LIG 1 1
HETATM 3477 O O19 . LIG . 1 ? B 7.42881 -15.61381 3.52174 1 2 B LIG 1 1
HETATM 3478 N N39 . LIG . 1 ? B 6.89607 -17.69962 4.24463 1 2 B LIG 1 1
HETATM 3479 C C38 . LIG . 1 ? B 5.73443 -17.44977 4.96345 1 2 B LIG 1 1
HETATM 3480 C C35 . LIG . 1 ? B 5.33367 -16.23619 5.40446 1 2 B LIG 1 1
HETATM 3481 C C33 . LIG . 1 ? B 4.19958 -16.13434 6.19985 1 2 B LIG 1 1
HETATM 3482 C C37 . LIG . 1 ? B 3.46196 -17.25894 6.50641 1 2 B LIG 1 1
HETATM 3483 C C26 . LIG . 1 ? B 2.27831 -17.24819 7.37511 1 2 B LIG 1 1
HETATM 3484 O O20 . LIG . 1 ? B 2.14030 -18.08419 8.23265 1 2 B LIG 1 1
HETATM 3485 N N40 . LIG . 1 ? B 1.32119 -16.40902 7.20115 1 2 B LIG 1 1
HETATM 3486 C C41 . LIG . 1 ? B 0.16307 -16.37511 8.11992 1 2 B LIG 1 1
HETATM 3487 C C30 . LIG . 1 ? B -1.03048 -15.94839 7.50788 1 2 B LIG 1 1
HETATM 3488 O O23 . LIG . 1 ? B -1.90781 -16.76532 6.96061 1 2 B LIG 1 1
HETATM 3489 C C29 . LIG . 1 ? B -2.86526 -16.00052 6.46827 1 2 B LIG 1 1
HETATM 3490 C C31 . LIG . 1 ? B -2.65206 -14.66199 6.77562 1 2 B LIG 1 1
HETATM 3491 C C32 . LIG . 1 ? B -1.43763 -14.61995 7.47999 1 2 B LIG 1 1
HETATM 3492 C C34 . LIG . 1 ? B 3.87916 -18.45812 6.03973 1 2 B LIG 1 1
HETATM 3493 C C36 . LIG . 1 ? B 4.99311 -18.56194 5.27845 1 2 B LIG 1 1
#
#
loop_
_atom_type.symbol
C
N
O
S
#
#
loop_
_ma_qa_metric.id
_ma_qa_metric.name
_ma_qa_metric.description
_ma_qa_metric.type
_ma_qa_metric.mode
_ma_qa_metric.type_other_details
_ma_qa_metric.software_group_id
1 pLDDT 'Predicted lddt' pLDDT local . .
#
#
loop_
_ma_qa_metric_local.ordinal_id
_ma_qa_metric_local.model_id
_ma_qa_metric_local.label_asym_id
_ma_qa_metric_local.label_seq_id
_ma_qa_metric_local.label_comp_id
_ma_qa_metric_local.metric_id
_ma_qa_metric_local.metric_value
1 1 A 1 MET 1 0.491
2 1 A 2 THR 1 0.597
3 1 A 3 SER 1 0.735
4 1 A 4 SER 1 0.813
5 1 A 5 ALA 1 0.835
6 1 A 6 ASP 1 0.842
7 1 A 7 LEU 1 0.857
8 1 A 8 THR 1 0.904
9 1 A 9 ASN 1 0.887
10 1 A 10 LEU 1 0.844
11 1 A 11 LYS 1 0.894
12 1 A 12 GLU 1 0.912
13 1 A 13 LEU 1 0.887
14 1 A 14 LEU 1 0.863
15 1 A 15 SER 1 0.877
16 1 A 16 LEU 1 0.874
17 1 A 17 TYR 1 0.852
18 1 A 18 LYS 1 0.801
19 1 A 19 SER 1 0.755
20 1 A 20 LEU 1 0.776
21 1 A 21 ARG 1 0.710
22 1 A 22 PHE 1 0.773
23 1 A 23 SER 1 0.816
24 1 A 24 ASP 1 0.850
25 1 A 25 SER 1 0.849
26 1 A 26 VAL 1 0.839
27 1 A 27 ALA 1 0.873
28 1 A 28 ILE 1 0.863
29 1 A 29 GLU 1 0.879
30 1 A 30 LYS 1 0.908
31 1 A 31 TYR 1 0.900
32 1 A 32 ASN 1 0.890
33 1 A 33 SER 1 0.922
34 1 A 34 LEU 1 0.917
35 1 A 35 VAL 1 0.893
36 1 A 36 GLU 1 0.899
37 1 A 37 TRP 1 0.903
38 1 A 38 GLY 1 0.856
39 1 A 39 THR 1 0.847
40 1 A 40 SER 1 0.848
41 1 A 41 THR 1 0.810
42 1 A 42 TYR 1 0.761
43 1 A 43 TRP 1 0.617
44 1 A 44 LYS 1 0.639
45 1 A 45 ILE 1 0.716
46 1 A 46 GLY 1 0.744
47 1 A 47 VAL 1 0.778
48 1 A 48 GLN 1 0.781
49 1 A 49 LYS 1 0.790
50 1 A 50 VAL 1 0.747
51 1 A 51 THR 1 0.675
52 1 A 52 ASN 1 0.614
53 1 A 53 VAL 1 0.580
54 1 A 54 GLU 1 0.612
55 1 A 55 THR 1 0.521
56 1 A 56 SER 1 0.610
57 1 A 57 ILE 1 0.764
58 1 A 58 SER 1 0.786
59 1 A 59 ASP 1 0.791
60 1 A 60 TYR 1 0.895
61 1 A 61 TYR 1 0.893
62 1 A 62 ASP 1 0.867
63 1 A 63 GLU 1 0.834
64 1 A 64 VAL 1 0.906
65 1 A 65 LYS 1 0.944
66 1 A 66 ASN 1 0.899
67 1 A 67 LYS 1 0.935
68 1 A 68 PRO 1 0.955
69 1 A 69 PHE 1 0.960
70 1 A 70 ASN 1 0.961
71 1 A 71 ILE 1 0.940
72 1 A 72 ASP 1 0.921
73 1 A 73 PRO 1 0.956
74 1 A 74 GLY 1 0.963
75 1 A 75 TYR 1 0.953
76 1 A 76 TYR 1 0.967
77 1 A 77 ILE 1 0.962
78 1 A 78 PHE 1 0.972
79 1 A 79 LEU 1 0.967
80 1 A 80 PRO 1 0.961
81 1 A 81 VAL 1 0.956
82 1 A 82 TYR 1 0.962
83 1 A 83 PHE 1 0.943
84 1 A 84 GLY 1 0.948
85 1 A 85 SER 1 0.937
86 1 A 86 VAL 1 0.927
87 1 A 87 PHE 1 0.940
88 1 A 88 ILE 1 0.900
89 1 A 89 TYR 1 0.938
90 1 A 90 SER 1 0.845
91 1 A 91 LYS 1 0.694
92 1 A 92 GLY 1 0.732
93 1 A 93 LYS 1 0.751
94 1 A 94 ASN 1 0.790
95 1 A 95 MET 1 0.925
96 1 A 96 VAL 1 0.895
97 1 A 97 GLU 1 0.911
98 1 A 98 LEU 1 0.879
99 1 A 99 GLY 1 0.808
100 1 A 100 SER 1 0.836
101 1 A 101 GLY 1 0.856
102 1 A 102 ASN 1 0.868
103 1 A 103 SER 1 0.873
104 1 A 104 PHE 1 0.916
105 1 A 105 GLN 1 0.898
106 1 A 106 ILE 1 0.933
107 1 A 107 PRO 1 0.914
108 1 A 108 ASP 1 0.933
109 1 A 109 GLU 1 0.941
110 1 A 110 ILE 1 0.952
111 1 A 111 ARG 1 0.943
112 1 A 112 SER 1 0.946
113 1 A 113 ALA 1 0.965
114 1 A 114 CYS 1 0.963
115 1 A 115 ASN 1 0.948
116 1 A 116 LYS 1 0.959
117 1 A 117 VAL 1 0.967
118 1 A 118 LEU 1 0.946
119 1 A 119 ASP 1 0.938
120 1 A 120 SER 1 0.898
121 1 A 121 ASP 1 0.884
122 1 A 122 ASN 1 0.863
123 1 A 123 GLY 1 0.922
124 1 A 124 ILE 1 0.944
125 1 A 125 ASP 1 0.888
126 1 A 126 PHE 1 0.908
127 1 A 127 LEU 1 0.964
128 1 A 128 ARG 1 0.947
129 1 A 129 PHE 1 0.968
130 1 A 130 VAL 1 0.960
131 1 A 131 LEU 1 0.957
132 1 A 132 LEU 1 0.900
133 1 A 133 ASN 1 0.914
134 1 A 134 ASN 1 0.918
135 1 A 135 ARG 1 0.928
136 1 A 136 TRP 1 0.963
137 1 A 137 ILE 1 0.964
138 1 A 138 MET 1 0.972
139 1 A 139 GLU 1 0.936
140 1 A 140 ASP 1 0.960
141 1 A 141 ALA 1 0.959
142 1 A 142 ILE 1 0.923
143 1 A 143 SER 1 0.940
144 1 A 144 LYS 1 0.890
145 1 A 145 TYR 1 0.902
146 1 A 146 GLN 1 0.891
147 1 A 147 SER 1 0.908
148 1 A 148 PRO 1 0.946
149 1 A 149 VAL 1 0.933
150 1 A 150 ASN 1 0.939
151 1 A 151 ILE 1 0.964
152 1 A 152 PHE 1 0.969
153 1 A 153 LYS 1 0.963
154 1 A 154 LEU 1 0.956
155 1 A 155 ALA 1 0.973
156 1 A 156 SER 1 0.966
157 1 A 157 GLU 1 0.965
158 1 A 158 TYR 1 0.967
159 1 A 159 GLY 1 0.967
160 1 A 160 LEU 1 0.977
161 1 A 161 ASN 1 0.957
162 1 A 162 ILE 1 0.960
163 1 A 163 PRO 1 0.949
164 1 A 164 ASN 1 0.949
165 1 A 165 TYR 1 0.958
166 1 A 166 LEU 1 0.965
167 1 A 167 GLU 1 0.951
168 1 A 168 ILE 1 0.953
169 1 A 169 GLU 1 0.962
170 1 A 170 ILE 1 0.953
171 1 A 171 GLU 1 0.953
172 1 A 172 GLU 1 0.945
173 1 A 173 ASP 1 0.951
174 1 A 174 THR 1 0.949
175 1 A 175 LEU 1 0.965
176 1 A 176 PHE 1 0.958
177 1 A 177 ASP 1 0.955
178 1 A 178 ASP 1 0.925
179 1 A 179 GLU 1 0.948
180 1 A 180 LEU 1 0.947
181 1 A 181 TYR 1 0.948
182 1 A 182 SER 1 0.930
183 1 A 183 ILE 1 0.946
184 1 A 184 MET 1 0.942
185 1 A 185 GLU 1 0.927
186 1 A 186 ARG 1 0.927
187 1 A 187 SER 1 0.947
188 1 A 188 PHE 1 0.947
189 1 A 189 ASP 1 0.869
190 1 A 190 ASP 1 0.773
191 1 A 191 THR 1 0.855
192 1 A 192 PHE 1 0.909
193 1 A 193 PRO 1 0.928
194 1 A 194 LYS 1 0.939
195 1 A 195 ILE 1 0.933
196 1 A 196 SER 1 0.943
197 1 A 197 ILE 1 0.962
198 1 A 198 SER 1 0.953
199 1 A 199 TYR 1 0.963
200 1 A 200 ILE 1 0.947
201 1 A 201 LYS 1 0.946
202 1 A 202 LEU 1 0.890
203 1 A 203 GLY 1 0.871
204 1 A 204 GLU 1 0.908
205 1 A 205 LEU 1 0.872
206 1 A 206 LYS 1 0.918
207 1 A 207 ARG 1 0.944
208 1 A 208 GLN 1 0.950
209 1 A 209 VAL 1 0.949
210 1 A 210 VAL 1 0.959
211 1 A 211 ASP 1 0.929
212 1 A 212 PHE 1 0.922
213 1 A 213 PHE 1 0.879
214 1 A 214 LYS 1 0.884
215 1 A 215 PHE 1 0.917
216 1 A 216 SER 1 0.904
217 1 A 217 PHE 1 0.940
218 1 A 218 MET 1 0.949
219 1 A 219 TYR 1 0.972
220 1 A 220 ILE 1 0.954
221 1 A 221 GLU 1 0.969
222 1 A 222 SER 1 0.964
223 1 A 223 ILE 1 0.967
224 1 A 224 LYS 1 0.941
225 1 A 225 VAL 1 0.962
226 1 A 226 ASP 1 0.952
227 1 A 227 ARG 1 0.961
228 1 A 228 ILE 1 0.949
229 1 A 229 GLY 1 0.947
230 1 A 230 ASP 1 0.923
231 1 A 231 ASN 1 0.925
232 1 A 232 ILE 1 0.952
233 1 A 233 PHE 1 0.973
234 1 A 234 ILE 1 0.965
235 1 A 235 PRO 1 0.952
236 1 A 236 SER 1 0.907
237 1 A 237 VAL 1 0.958
238 1 A 238 ILE 1 0.970
239 1 A 239 THR 1 0.962
240 1 A 240 LYS 1 0.950
241 1 A 241 SER 1 0.910
242 1 A 242 GLY 1 0.959
243 1 A 243 LYS 1 0.927
244 1 A 244 LYS 1 0.939
245 1 A 245 ILE 1 0.959
246 1 A 246 LEU 1 0.944
247 1 A 247 VAL 1 0.955
248 1 A 248 LYS 1 0.931
249 1 A 249 ASP 1 0.946
250 1 A 250 VAL 1 0.959
251 1 A 251 ASP 1 0.922
252 1 A 252 HIS 1 0.944
253 1 A 253 LEU 1 0.952
254 1 A 254 ILE 1 0.932
255 1 A 255 ARG 1 0.905
256 1 A 256 SER 1 0.927
257 1 A 257 LYS 1 0.908
258 1 A 258 VAL 1 0.925
259 1 A 259 ARG 1 0.914
260 1 A 260 GLU 1 0.955
261 1 A 261 HIS 1 0.966
262 1 A 262 THR 1 0.906
263 1 A 263 PHE 1 0.934
264 1 A 264 VAL 1 0.923
265 1 A 265 LYS 1 0.923
266 1 A 266 VAL 1 0.944
267 1 A 267 LYS 1 0.917
268 1 A 268 LYS 1 0.915
269 1 A 269 LYS 1 0.895
270 1 A 270 ASN 1 0.805
271 1 A 271 THR 1 0.840
272 1 A 272 PHE 1 0.931
273 1 A 273 SER 1 0.950
274 1 A 274 ILE 1 0.938
275 1 A 275 LEU 1 0.950
276 1 A 276 TYR 1 0.889
277 1 A 277 ASP 1 0.822
278 1 A 278 TYR 1 0.592
279 1 A 279 ASP 1 0.530
280 1 A 280 GLY 1 0.561
281 1 A 281 ASN 1 0.513
282 1 A 282 GLY 1 0.655
283 1 A 283 THR 1 0.776
284 1 A 284 GLU 1 0.866
285 1 A 285 THR 1 0.875
286 1 A 286 ARG 1 0.885
287 1 A 287 GLY 1 0.903
288 1 A 288 GLU 1 0.887
289 1 A 289 VAL 1 0.932
290 1 A 290 ILE 1 0.934
291 1 A 291 LYS 1 0.904
292 1 A 292 ARG 1 0.928
293 1 A 293 ILE 1 0.951
294 1 A 294 ILE 1 0.934
295 1 A 295 ASP 1 0.912
296 1 A 296 THR 1 0.946
297 1 A 297 ILE 1 0.958
298 1 A 298 GLY 1 0.934
299 1 A 299 ARG 1 0.867
300 1 A 300 ASP 1 0.888
301 1 A 301 TYR 1 0.966
302 1 A 302 TYR 1 0.953
303 1 A 303 VAL 1 0.960
304 1 A 304 ASN 1 0.938
305 1 A 305 GLY 1 0.917
306 1 A 306 LYS 1 0.919
307 1 A 307 TYR 1 0.963
308 1 A 308 PHE 1 0.974
309 1 A 309 SER 1 0.949
310 1 A 310 LYS 1 0.933
311 1 A 311 VAL 1 0.904
312 1 A 312 GLY 1 0.829
313 1 A 313 ILE 1 0.741
314 1 A 314 ALA 1 0.742
315 1 A 315 GLY 1 0.848
316 1 A 316 LEU 1 0.883
317 1 A 317 LYS 1 0.873
318 1 A 318 GLN 1 0.855
319 1 A 319 LEU 1 0.904
320 1 A 320 THR 1 0.932
321 1 A 321 ASN 1 0.913
322 1 A 322 LYS 1 0.903
323 1 A 323 LEU 1 0.927
324 1 A 324 ASP 1 0.919
325 1 A 325 ILE 1 0.939
326 1 A 326 ASN 1 0.935
327 1 A 327 GLU 1 0.909
328 1 A 328 CYS 1 0.931
329 1 A 329 ALA 1 0.884
330 1 A 330 THR 1 0.924
331 1 A 331 VAL 1 0.919
332 1 A 332 ASP 1 0.946
333 1 A 333 GLU 1 0.943
334 1 A 334 LEU 1 0.946
335 1 A 335 VAL 1 0.961
336 1 A 336 ASP 1 0.960
337 1 A 337 GLU 1 0.955
338 1 A 338 ILE 1 0.958
339 1 A 339 ASN 1 0.951
340 1 A 340 LYS 1 0.939
341 1 A 341 SER 1 0.929
342 1 A 342 GLY 1 0.949
343 1 A 343 THR 1 0.931
344 1 A 344 VAL 1 0.947
345 1 A 345 LYS 1 0.955
346 1 A 346 ARG 1 0.952
347 1 A 347 LYS 1 0.923
348 1 A 348 ILE 1 0.939
349 1 A 349 LYS 1 0.949
350 1 A 350 ASN 1 0.943
351 1 A 351 GLN 1 0.911
352 1 A 352 SER 1 0.893
353 1 A 353 VAL 1 0.896
354 1 A 354 PHE 1 0.864
355 1 A 355 ASP 1 0.905
356 1 A 356 LEU 1 0.917
357 1 A 357 SER 1 0.916
358 1 A 358 ARG 1 0.918
359 1 A 359 GLU 1 0.906
360 1 A 360 CYS 1 0.903
361 1 A 361 LEU 1 0.901
362 1 A 362 GLY 1 0.906
363 1 A 363 TYR 1 0.894
364 1 A 364 PRO 1 0.885
365 1 A 365 GLU 1 0.902
366 1 A 366 ALA 1 0.899
367 1 A 367 ASP 1 0.867
368 1 A 368 PHE 1 0.887
369 1 A 369 ILE 1 0.889
370 1 A 370 THR 1 0.896
371 1 A 371 LEU 1 0.884
372 1 A 372 VAL 1 0.850
373 1 A 373 ASN 1 0.892
374 1 A 374 ASN 1 0.895
375 1 A 375 MET 1 0.868
376 1 A 376 ARG 1 0.881
377 1 A 377 PHE 1 0.854
378 1 A 378 LYS 1 0.890
379 1 A 379 ILE 1 0.844
380 1 A 380 GLU 1 0.890
381 1 A 381 ASN 1 0.837
382 1 A 382 CYS 1 0.827
383 1 A 383 LYS 1 0.846
384 1 A 384 VAL 1 0.879
385 1 A 385 VAL 1 0.871
386 1 A 386 ASN 1 0.861
387 1 A 387 PHE 1 0.860
388 1 A 388 ASN 1 0.841
389 1 A 389 ILE 1 0.848
390 1 A 390 GLU 1 0.862
391 1 A 391 ASN 1 0.839
392 1 A 392 THR 1 0.797
393 1 A 393 ASN 1 0.810
394 1 A 394 CYS 1 0.822
395 1 A 395 LEU 1 0.831
396 1 A 396 ASN 1 0.780
397 1 A 397 ASN 1 0.810
398 1 A 398 PRO 1 0.832
399 1 A 399 SER 1 0.872
400 1 A 400 ILE 1 0.869
401 1 A 401 GLU 1 0.898
402 1 A 402 THR 1 0.908
403 1 A 403 ILE 1 0.900
404 1 A 404 TYR 1 0.916
405 1 A 405 GLY 1 0.945
406 1 A 406 ASN 1 0.890
407 1 A 407 PHE 1 0.881
408 1 A 408 ASN 1 0.927
409 1 A 409 GLN 1 0.921
410 1 A 410 PHE 1 0.914
411 1 A 411 VAL 1 0.919
412 1 A 412 SER 1 0.919
413 1 A 413 ILE 1 0.927
414 1 A 414 PHE 1 0.919
415 1 A 415 ASN 1 0.921
416 1 A 416 THR 1 0.925
417 1 A 417 VAL 1 0.933
418 1 A 418 THR 1 0.924
419 1 A 419 ASP 1 0.926
420 1 A 420 VAL 1 0.942
421 1 A 421 LYS 1 0.927
422 1 A 422 LYS 1 0.923
423 1 A 423 ARG 1 0.948
424 1 A 424 LEU 1 0.948
425 1 A 425 PHE 1 0.924
426 1 A 426 GLU 1 0.815
427 1 B 1 LIG 1 0.482
#`;