import { UserCmd, UserActions, AddCustomAction } from './UserCmd';
import { actionableAtoms, TerminalGroups, allBondVectorPairs } from '../utils';
import { App } from '../BMapsApp';

const debug = false;

/**
 * @typedef DesignCmds
 * @type {object}
 * @property {replaceAllGroups} ReplaceAllGroups
 * @property {growFromAllAtoms} GrowFromAllAtoms
 */

/** @type {DesignCmds} */
export const DesignCmds = {
    ReplaceAllGroups: new UserCmd('ReplaceAllGroups', replaceAllGroups),
    GrowFromAllAtoms: new UserCmd('GrowFromAllAtoms', growFromAllAtoms),
};

// Add menu items to the compound selector menu
// Add this to Loader (in feature_loaders) to enable.
// Loader invokes Init() at startup
export const DesignCustomActions = {
    Init() {
        AddCustomAction('Compound', DesignCustomActions.Compound);
    },
    Compound: [
        {
            title: 'Replace all R-groups',
            icon: 'fa fa-expand',
            action: (compound) => replaceAllGroups(compound),
        },
        {
            title: 'Grow from all atoms',
            icon: 'fa fa-expand',
            action: (compound) => growFromAllAtoms(compound, undefined, { minimize: true }),
        },
    ],
};

/**
 *
 * @param {*} compounds
 * @param {*} atomFilterFn
 * @param {*} replacements
 * @returns {Array} objects with fields:
 * - compound -- original compound
 * - atom -- the atom triggering the replacement
 * - replacement -- the name of the replacement
 * - newCompound -- the resulting compound from the replacement
 * - errors -- a list of errors
 */
async function replaceAllGroups(
    compoundsIn,
    atomFilterFn=(x) => true,
    replacements=TerminalGroups.slice(0, 8)
) {
    const compounds = [].concat(compoundsIn);
    const results = [];
    for (const [compoundI, compound] of compounds.entries()) {
        const { canReplace } = actionableAtoms(compound, atomFilterFn);
        for (const [atomI, atom] of canReplace.entries()) {
            for (const [repI, rep] of replacements.entries()) {
                // console.log(`Running (cmpd,atom,rep) (${compoundI},${atomI},${repI}) out of
                //     (${compounds.length},${canReplace.length},${replacements.length}`);
                const { compounds: [newCompound], errors } = (
                    await UserActions.ReplaceGroup(atom, rep.name)
                );
                if (errors.length > 0) {
                    console.log(`Error in replace all, ${compound.resSpec}.${atom.atom} -> ${rep.name}: ${errors}`);
                }
                results.push({
                    compound,
                    atom,
                    replacement: rep,
                    newCompound,
                    errors,
                });
            }
        }
    }
    return results;
}

/**
 *
 * @param {*} compounds
 * @param {*} atomFilterFn
 * @param {*} specs
 * - allowedVectors
 * - vectorFn
 * - maxSuggestions
 * - fragments
 * - fragmentSets
 * - fragmentFn
 * - minimize
 * @returns {Promise<Array>} objects with fields:
 * - compound -- the original compound
 * - atom -- the atom triggering the grow
 * - bondVector -- the grow vector [fromAtom, toAtom]
 * - newCompound -- the resulting compound, or null
 * - errors -- errors
 */
async function growFromAllAtoms(
    compoundsIn,
    atomFilterFn=(x) => true,
    specs={},
) {
    const results = [];

    const { dataParentsMap } = App.partitionByDataParents([].concat(compoundsIn)); // force to array

    for (const [{ caseData }, compounds] of dataParentsMap.entries()) {
        results.push(...await growOneCaseCompounds(caseData, compounds, atomFilterFn, specs));
    }
    return results;
}

async function growOneCaseCompounds(caseData, compounds, atomFilterFn, specs) {
    const results = [];

    const savedFrags = App.Workspace.getActiveFragments(caseData);
    const fragList = caseData.getAvailableFragmentInfo();
    const fragsOfInterest = getFragments(fragList, specs);
    if (fragsOfInterest) {
        await UserActions.LoadFragments(fragsOfInterest);
        if (debug) console.log(`GrowFromAllAtoms: saved fragments: ${savedFrags.map((f) => f.name)}`);
        if (debug) console.log(`GrowFromAllAtoms: loading fragments: ${fragsOfInterest.map((f) => f.name)}`);
    }

    // Grow logic: loop through compounds, atoms, bondVectors to run search,
    // then loop through grow suggestions

    for (const [compoundI, compound] of compounds.entries()) {
        const finishedVectors = []; // finishedVectors is per-compound
        const { canGrow } = actionableAtoms(compound, atomFilterFn);
        for (const [atomI, atom] of canGrow.entries()) {
            const bondVectors = allBondVectorPairs(atom);
            for (const [bondVectorI, bondVector] of bondVectors.entries()) {
                if (!isAllowedVector(compound, bondVector, finishedVectors, specs)) {
                    continue;
                }

                const { suggestions, errors: searchErrors } = (
                    await UserActions.GrowFromAtom(bondVector, 'Bond')
                );
                if (searchErrors.length > 0) {
                    console.log(`Error in grow all, ${compound.resSpec}.${atom.atom} -> ${bondVector}: ${searchErrors}`);
                }
                if (suggestions.length > 0) {
                    const suggestionsToUse = filterSuggestions(fragList, suggestions, specs);
                    for (const [suggI, sugg] of suggestionsToUse.entries()) {
                        const { compounds: newCompounds, errors: growErrors } = (
                            await UserActions.ConfirmModification(sugg, atom)
                        );
                        let desc = `${compound.resSpec};${atom.atom};${bondVector.map((x) => x.atom)};${sugg.name}`;
                        if (debug) {
                            desc = `${compoundI}.${atomI}.${bondVectorI}.${suggI} (${desc})`;
                        }
                        if (debug) console.log(`GrowFromAllAtoms: received grow result for ${desc}: ${newCompounds.map((x) => x.resSpec)}`);
                        for (const cmpd of newCompounds) {
                            results.push({
                                compound,
                                atom,
                                bondVector,
                                newCompound: cmpd,
                                errors: [...searchErrors, ...growErrors],
                            });
                        }
                    }
                } else if (searchErrors.length > 0) {
                    results.push({
                        compound,
                        atom,
                        bondVector,
                        newCompound: null,
                        errors: searchErrors,
                    });
                }
                finishedVectors.push(bondVector);
            }
        }
    }

    // Restore originally loaded frags before auto-grow
    if (fragsOfInterest) {
        await UserActions.LoadFragments(savedFrags);
        if (debug) console.log(`GrowFromAllAtoms: reloading saved frags: ${savedFrags.map((f) => f.name)}`);
    }

    // Minimize if desired
    if (specs.minimize) {
        let minimizeErrs;
        for (const r of results) {
            if (r.newCompound) {
                const { compounds: [minimized] } = await UserActions.EnergyMinimize(r.newCompound);
                if (minimized) {
                    r.newCompound = minimized;
                }
            }
        }
    }

    return results;
}

/**
 * @description Should we attempt to grow on this vector, based on the
 * following considerations:
 * 1) Have we already grown on this vector?
 * 2) Has it been included in an "allowedVectors" list?
 * 3) Does it pass a "vectorFn" function to determine eligibility?
 * @param {*} compound Compound object
 * @param {*} vector 2-array of Atom objects
 * @param {*} alreadyFinished array of completed bond vectors
 * @param {*} specs object possibly including:
 *       allowedVectors: {
 *           <cmpd spec>: [ ... [A, B], ... ],
 *           ...
 *       }
 *       allowedVectors maps compound resSpecs to lists of bondvectors,
 *          where a bondvector is a 2-array of either atom objects or atom names
 *       vectorFn: (bondvector, compound) => boolean:
 *              a function to run to determine if a vector is eligible
 */
function isAllowedVector(compound, vector, alreadyFinished, specs) {
    const { allowedVectors, vectorFn } = specs;
    const vectorName = `${vector[0].atom},${vector[1].atom}`;

    // Disallow if already finished
    if (findVector(alreadyFinished, vector)) {
        if (debug) console.log(`Skipping ${vectorName} because already finished`);
        return false;
    }
    // Disallow if not in allowedVectors
    const avs = allowedVectors && allowedVectors[compound.resSpec];
    if (avs) {
        if (findVector(avs, vector)) {
            if (debug) console.log(`Passing ${vectorName} because in allow list.`);
        } else {
            if (debug) console.log(`Skipping ${vectorName} because not in allow list.`);
            return false;
        }
    } else {
        if (debug) console.log(`Passing ${vectorName} because no allow list`);
    }

    // Disallow if not passing vectorFn
    if (vectorFn) {
        if (vectorFn(vector, compound)) {
            if (debug) console.log(`Passing ${vectorName} because passes vectorFn`);
        } else {
            if (debug) console.log(`Skipping ${vectorName} because fails vectorFn`);
            return false;
        }
    }

    console.log(`GrowFromAllAtoms.isAllowedVector(): Allowing ${compound.resSpec} - ${vectorName}`);
    return true;
}

/**
 * @description Find a bondvector 2-array in a list of bondvector 2-arrays.
 * Bond vectors can be either 2-arrays of atom objects or atom name strings
 * @param {*} haystack
 * @param {*} needlePair
 */
function findVector(haystack, needlePair) {
    // input to aName could be atom object or atom name string
    const aName = (atom) => (typeof (atom) === 'object' ? atom.atom : atom);

    const [needleA, needleB] = needlePair.map((np) => aName(np));
    return haystack.find((haystackPair) => {
        const [haystackA, haystackB] = haystackPair.map((hp) => aName(hp));
        return haystackA === needleA && haystackB === needleB;
    });
}

// Filter by max suggestions and fragments
function filterSuggestions(fragList, suggestions, specs) {
    let filteredSuggestions = suggestions;

    const fragments = getFragments(fragList, specs);
    if (fragments) {
        const filterByFragment = (sugg) => fragments.find((f) => f.name === sugg.name);
        filteredSuggestions = filteredSuggestions.filter(filterByFragment);
    }

    const maxSuggs = (specs.maxSuggestions !== undefined) ? specs.maxSuggestions : 10;
    if (maxSuggs !== -1 && maxSuggs !== null) {
        filteredSuggestions = filteredSuggestions.slice(0, maxSuggs);
    }
    if (debug) console.log(`Suggestion filter: ${suggestions.length} -> ${filteredSuggestions.length} (max: ${maxSuggs})`);
    return filteredSuggestions;
}

/**
 * @param {{
 *      fragments: string[] | FragInfo[],
 *      fragmentSets: string[] | FragservFragset[],
 *      fragmentFn: Function
 * }}
 *
 * fragments: frag names or FragInfo objects (from available fragments, may or may not be loaded)
 * fragmentSets: fragment set names or FragservFragset objects
 * fragmentFn: filter function taking FragInfo (from available fragments, may or may not be loaded)
 * @returns {FragInfo[]?}
 */
function getFragments(fragList, { fragments, fragmentSets, fragmentFn }) {
    const fragData = App.Workspace.fragmentData;
    const fragservData = fragData.fragservData;
    if (!(fragments || fragmentSets || fragmentFn)) {
        return null;
    }

    const result = [];
    if (fragments) {
        for (let f of fragments) {
            if (typeof (f) === 'string') {
                f = fragList.findByName(f);
            }
            if (f) {
                if (debug) console.log(`GrowFromAllAtoms.getFragments(): Including fragment ${f.name}`);
                result.push(f);
            }
        }
    }
    if (fragmentSets) {
        for (let fs of fragmentSets) {
            if (typeof (fs) === 'string') {
                if (debug) console.log(`GrowFromAllAtoms.getFragments(): Looking up fragment set: ${fs}`);
                fs = fragservData.fragsetByName(fs);
            }
            if (fs) {
                if (debug) console.log(`GrowFromAllAtoms.getFragments(): Including fragset ${fs.name}`);
                for (const f of fragData.availableFragmentsInFragset(fragList, fs)) {
                    if (debug) console.log(`GrowFromAllAtoms.getFragments(): Including fragset fragment ${fs.name}:${f.name}`);
                    result.push(f);
                }
            }
        }
    }

    if (fragmentFn) {
        for (const f of fragList.items().filter(fragmentFn)) {
            if (debug) console.log(`GrowFromAllAtoms.getFragments(): Including fragment after filter ${f.name}`);
            result.push(f);
        }
    }
    return result;
}
