import {
    useState, useRef, useMemo, useContext,
} from 'react';
import {
    Formik, Form,
} from 'formik';
// Material UI
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
// Local
import DialogContentSection from 'BMapsSrc/ui/common/DialogContentSection';
import { Compound } from 'BMapsSrc/model';
import { DialogSubmitButton } from '../../../../common/DialogActionButtons';
import { ProteinContext } from '../../../../common/ProteinContextProvider';
import TextField from '../../../../common/TextFields';
import { SelectWithFormik } from '../../../../common/Select';
import { ProteinSelectWithFormik } from '../../../../common/ProteinSelect';
import { MolDataSource } from '../../../../../utils';
import {
    fileFilterAcceptedTypes,
    getMoleculeOptions,
    getPlacementOptions,
    importFieldsForMolSources,
    PlacementTypes,
} from '../../tab_utils';
import { loadMolFiles, loadSessionFiles } from '../../../../../paste_drag';
import { EventBroker } from '../../../../../eventbroker';
import FormControlContainer from '../../base/FormControlContainer';
import FileDroppableTextAreaWithFormik from '../../base/TextAreaWithFormik';
import {
    InputBox, PlaceHolder, InputFile, UploadButton,
} from '../../import_components';
import {
    getSupportedMoltypes, isBinaryFormat, guessFormat,
} from '../../../../../util/mol_format_utils';

const Data = EventBroker.getModule('project_data.js');

// Note: since initData is used as the condition for useMemo, we can't use a default
// parameter value, since that will construct a new object every time.
const emptyInitData = Object.freeze({}); // Make sure we don't modify the empty copy

export default function EnterMolDataTab({ closeImportPane, initData=emptyInitData }) {
    const uploadBtnRef = useRef(null);
    const [stagedData, setStagedData] = useState();
    const {
        selectedCaseData, selectedProtein, loadedProteins,
    } = useContext(ProteinContext);
    const initialFormValues = useMemo(() => {
        const {
            molData, compounds, molSource, molSources: molSourcesIn,
        } = initData;

        const initValues = {
            molDataText: '',
            dataFormat: '',
            placement: PlacementTypes.Specify,
            molName: '',
            assignBondOrders: true,
            alreadyMinimized: false,
            selectedProtein: selectedProtein?.uri || '',
        };

        const molSources = molSourcesIn ? [...molSourcesIn] : [];
        if (molSource) {
            molSources.push(molSource);
        }
        setStagedData(molSources);

        if (molSources.length === 1) {
            const { compoundName } = molSources[0];
            if (compoundName) initValues.molName = compoundName;
        }

        if (molSources.length > 0) {
            const { text, format } = importFieldsForMolSources(molSources);
            if (text) initValues.molDataText = text;
            if (format) initValues.dataFormat = format;
        }

        if (molData) {
            initValues.molDataText = molData;
            initValues.dataFormat = guessFormat(molData);
        }

        if (compounds) {
            initValues.molDataText = Compound.makeSDF(compounds, {
                refCaseData: selectedCaseData, propInclusion: 'none',
            });
            initValues.dataFormat = 'sdf';
        }

        const {
            availableOptions,
        } = getPlacementOptions(initValues.dataFormat, selectedCaseData);

        const { placement: requestedPlacement } = initData;

        // Set initial placement if necessary
        // 1. Enable a single available option
        // 2. Enable a specifically requested placement from the caller
        if (availableOptions.length === 1) {
            initValues.placement = availableOptions[0].value;
        } else if (requestedPlacement && findPlacement(requestedPlacement, availableOptions)) {
            initValues.placement = requestedPlacement;
        }

        return initValues;
    }, [initData]);

    const [placementOptions, setPlacementOptions] = useState(() => {
        const {
            availableOptions, disabledOptions,
        } = getPlacementOptions(initialFormValues.dataFormat, selectedCaseData);

        return [...availableOptions, ...disabledOptions];
    });

    const placementUIOptions = [
        <MenuItem key="specify compound placement" value={PlacementTypes.Specify}>Specify compound placement...</MenuItem>,
    ].concat(
        placementOptions.map((option) => {
            const {
                label, disabled, value, disabledMsg='', title='',
            } = option;
            const titleText = disabled ? disabledMsg : title;

            return (
                <MenuItem
                    key={label}
                    value={value}
                    disabled={disabled}
                    title={titleText}
                >
                    {label}
                </MenuItem>
            );
        })
    );

    function getMoltypeSelectComponents() {
        const moltypes = getSupportedMoltypes();
        return (
            moltypes.map((moltype) => (
                <MenuItem
                    key={moltype}
                    value={moltype}
                >
                    {moltype.toUpperCase()}
                </MenuItem>
            ))
        );
    }

    function handleUploadBtnClicked() {
        uploadBtnRef.current.children[0].click();
    }

    function validateMolDataText(text) {
        return text === '' ? 'Please add molecule data to import!' : undefined;
    }

    function validateProteinSelect(proteinSelect) {
        return loadedProteins.length > 1 && proteinSelect === '' ? 'Please select a protein!' : undefined;
    }

    function validateDataFormat(format) {
        return format === '' && !usingStagedData(format) ? 'Please specify the data format!' : undefined;
    }

    function validatePlacement(placement) {
        return !PlacementTypes.isValidPlacement(placement)
            ? 'Please choose an option for compound placement!'
            : undefined;
    }

    function formValidate(formValues) {
        const errors = {};
        let val;

        val = validateMolDataText(formValues.molDataText);
        if (val) {
            errors.molDataText = val;
        }

        val = validateProteinSelect(formValues.selectedProtein);
        if (val) {
            errors.selectedProtein = val;
        }

        // Allow format to be empty when there are multiple files loaded (formats could differ)
        // stagedData is in component state
        val = validateDataFormat(formValues.dataFormat);
        if (val) {
            errors.dataFormat = val;
        }

        val = validatePlacement(formValues.placement);
        if (val) {
            errors.placement = val;
        }

        return errors;
    }

    /**
     * Return whether or not we can send uploaded file data, ignoring form mol data and format.
     *
     * The data sent to the server will be either file upload data or form text data.
     * We will send the file upload data (including the format derived from the extension) when:
     * - There are multiple files
     * - OR there is binary data
     * - OR the text data from an uploaded file hasn't changed in the form
     *
     * If there is exactly one text data file upload and the mol data text has been changed,
     * we'll send the mol data and format from the form inputs instead.
     * @returns
     */
    function usingStagedData(dataText) {
        return stagedData.length > 0 && (
            stagedData.length > 1
            || isBinaryFormat(stagedData[0].molFormat)
            || stagedData[0].molData === dataText
        );
    }

    function handleSubmit(formValues) {
        const {
            molDataText, dataFormat, molName, placement, assignBondOrders, alreadyMinimized,
        } = formValues;

        const placementOption = findPlacement(placement, placementOptions);
        const { placementType, ligandspec: refSpec } = placementOption;
        const moleculeLoadOptions = getMoleculeOptions(placementType, assignBondOrders, refSpec || '', alreadyMinimized);

        if (usingStagedData(molDataText)) {
            for (const molSource of stagedData) {
                // Allow renaming of compounds
                if (molName) molSource.compoundName = molName;
                Data.loadCompound(molSource, moleculeLoadOptions, selectedCaseData);
            }
        } else {
            const molSource = initData.compounds
                ? MolDataSource.FromProteinCopy(
                    molName, dataFormat, molDataText, initData.compounds
                )
                : MolDataSource.FromImport(molName, dataFormat, molDataText);
            Data.loadCompound(molSource, moleculeLoadOptions, selectedCaseData);
        }
        closeImportPane();
    }

    /**
     * Determine if the placement index should be automatically updated after a format change.
     * In general, the placement index should be what the user has chosen, but it is automatically
     * updated in three scenarios:
     * 1. If the user's selected placement option becomes disabled, then automatically clear the
     *    selection and show the "Specify placement" option
     * 2. If the user hasn't chosen a placement option (it's in the "Specify" state), and there is
     *    only one non-disabled placement option available, then auto-select that one option.
     * 3. If the previous format had only one option available, and the updated format has more than
     *    one option available, then clear the selection and show the "Specify placement" option.
     *
     * @param {number} previousIndex The old placement index
     * @param {*} prevOptions The option list before this current update
     * @param {*} currOptions The updated option list based on a new format selection
     * @returns {number} updated index: 0 ("Specify"), 1 (first available option) or -1 (no change)
     */
    function autoPlacement(previousVal, prevOptions, currOptions) {
        const justOneEnabledPrevious = prevOptions.filter(({ disabled }) => !disabled).length === 1;
        const justOneEnabledNow = currOptions.filter(({ disabled }) => !disabled).length === 1;
        const previousSelection = PlacementTypes.isValidPlacement(previousVal)
            && findPlacement(previousVal, prevOptions);
        const currVersion = previousSelection && findPlacement(previousVal, currOptions);

        // Scenario 1 - choose "Specify" if my previously selected option has become disabled
        if (previousSelection && (!currVersion || currVersion.disabled)) {
            return PlacementTypes.Specify;
        }

        // Scenario 2 - if only one available option, choose it
        if (justOneEnabledNow && !previousSelection) {
            return currOptions[0].value;
        }

        // Scenario 3 - choose "Specify" when going from only 1 available to multiple available
        if (justOneEnabledPrevious && !justOneEnabledNow) {
            return PlacementTypes.Specify;
        }

        // Otherwise, don't update the placement selection
        return '';
    }

    /**
     * Look in the provided placement option list for an option with a value that matches
     * the provided value
     */
    function findPlacement(value, placementOptionList) {
        return placementOptionList.find((option) => option.value === value);
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            validate={formValidate}
            onSubmit={handleSubmit}
        >
            {/* Formik child render function with parameters from the formik "bag" */}
            {({
                values, getFieldProps, setFieldValue,
            }) => {
                const {
                    molDataText, dataFormat, placement, assignBondOrders, alreadyMinimized,
                } = values;

                // Data and functions that depend on form values
                const {
                    textDisabled, formatDisabled,
                } = importFieldsForMolSources(stagedData, molDataText);

                function handleProteinSelect(e, { caseData: newCaseData }) {
                    const { availableOptions, disabledOptions } = getPlacementOptions(
                        dataFormat, newCaseData
                    );
                    const prevOptions = placementOptions;
                    const currOptions = [...availableOptions, ...disabledOptions];
                    let newPlacement = PlacementTypes.Specify;

                    // If the current format matches the initial format
                    // AND the current placement matches the (existing) requested placement option,
                    // then after a protein change, we need to choose the requested option again.
                    const useRequestedPlacement = (
                        initialFormValues.dataFormat && initialFormValues.dataFormat === dataFormat
                        && PlacementTypes.isValidPlacement(initialFormValues.placement)
                        && placement === initialFormValues.placement
                        && findPlacement(placement, availableOptions) // make sure it's available
                    );

                    if (useRequestedPlacement) {
                        newPlacement = placement;
                    } else if (placement === PlacementTypes.Specify) { // currently unspecified
                        // Always reset to unspecified placement when changing proteins UNLESS
                        // it's currently in an unspecified state and the new protein has
                        // only one option.

                        const autoVal = autoPlacement(placement, prevOptions, currOptions);
                        if (PlacementTypes.isValidPlacement(autoVal)) {
                            newPlacement = autoVal;
                        }
                    }
                    setPlacementOptions(currOptions);
                    setFieldValue('placement', newPlacement);
                    if (initData.compounds) {
                        setFieldValue('molDataText', Compound.makeSDF(initData.compounds, {
                            refCaseData: newCaseData, propInclusion: 'none',
                        }));
                    }
                }

                function setDataFormat(format) {
                    if (format !== dataFormat) {
                        const prevOptions = placementOptions;
                        setFieldValue('dataFormat', format);
                        const {
                            availableOptions, disabledOptions,
                        } = getPlacementOptions(format, selectedCaseData);
                        const currOptions = [...availableOptions, ...disabledOptions];
                        const autoVal = autoPlacement(placement, prevOptions, currOptions);
                        setPlacementOptions(currOptions);
                        if (autoVal) {
                            setFieldValue('placement', autoVal);
                        }
                    }
                }

                async function stageImportFiles(files) {
                    await loadSessionFiles(files);
                    // If any session files were loaded, the window will close so
                    // the following will be discarded.
                    // But in principle, we could load the session and then stage the others here.
                    const molSources = await loadMolFiles(files);
                    if (molSources?.length > 0) {
                        setStagedData(molSources);
                        const { text, format } = importFieldsForMolSources(molSources);
                        setFieldValue('molDataText', text);
                        setDataFormat(format);
                    }
                }

                function updateFormatPicker(formatIn, text) {
                    let format = formatIn;
                    if (text.length > 0) {
                        format = guessFormat(text);
                        setDataFormat(format);
                    } else {
                        setDataFormat('');
                    }
                }

                function handleChangeFormat(e) {
                    const format = e.target.value;
                    setDataFormat(format);
                }

                function handleChangeImportMol(e) {
                    updateFormatPicker(dataFormat, e.target.value);
                }

                function handleImportFileChange(e) {
                    stageImportFiles(e.target.files);
                }

                // Actual form JSX
                return (
                    <Form id="importForm">
                        <PlaceHolder show={molDataText === ''}>
                            Drag & Drop or
                            {' '}
                            <UploadButton
                                disableRipple
                                onClick={handleUploadBtnClicked}
                            >
                                upload
                            </UploadButton>
                            {' '}
                            a molecule or BMaps session file,
                            {' '}
                            <br />
                            Or paste molecule data and click &quot;Import Molecule&quot;
                            <br />
                            <br />
                            <i>Drop files or paste data into the main workspace any time.</i>
                        </PlaceHolder>
                        <FileDroppableTextAreaWithFormik
                            name="molDataText"
                            id="importMolText"
                            rows={1}
                            autoFocus
                            disabled={textDisabled}
                            validate={validateMolDataText}
                            onChange={handleChangeImportMol}
                            onDrop={stageImportFiles}
                            shouldUpdate={
                                (nextProps, prevProps) => (
                                    prevProps.formik.values.selectedProtein
                                        !== nextProps.formik.values.selectedProtein
                                    || prevProps.formik.values.placement
                                        !== nextProps.formik.values.placement
                                )
                            }
                            sx={{
                                '& textarea': {
                                    resize: 'vertical',
                                    minHeight: '7rem',
                                },
                            }}
                        />
                        <InputBox>
                            <ProteinSelectWithFormik name="selectedProtein" validate={validateProteinSelect} onChange={handleProteinSelect} />
                            <SelectWithFormik
                                name="dataFormat"
                                id="importMolFormat"
                                label="Data Format"
                                disabled={formatDisabled}
                                validate={validateDataFormat}
                                onChange={handleChangeFormat}
                                SelectProps={{
                                    native: false,
                                }}
                            >
                                <MenuItem value=""><em>Select Format</em></MenuItem>
                                {getMoltypeSelectComponents()}
                            </SelectWithFormik>
                            <TextField
                                fullWidth
                                name="molName"
                                id="importMolName"
                                label="Molecule Name (optional)"
                                variant="outlined"
                                optimize={false}
                            />
                            <SelectWithFormik
                                name="placement"
                                id="importMolPositioning"
                                label="Compound Placement"
                                validate={validatePlacement}
                                SelectProps={{
                                    native: false,
                                }}
                            >
                                {placementUIOptions}
                            </SelectWithFormik>
                            <FormControlContainer>
                                <InputFile
                                    ref={uploadBtnRef}
                                    id="importFileSelect"
                                    type="file"
                                    onChange={handleImportFileChange}
                                    inputProps={{
                                        accept: fileFilterAcceptedTypes(getSupportedMoltypes()),
                                        multiple: true,
                                    }}
                                />
                            </FormControlContainer>
                            <DialogContentSection
                                title="Advanced Import Options"
                                collapsible
                                disablePaddingTop
                                collapsedMarginBottom="0.5em"
                            >
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            color="primary"
                                            {...getFieldProps('assignBondOrders')}
                                            checked={assignBondOrders}
                                        />
                                    )}
                                    label="Assign bond orders"
                                />
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            color="primary"
                                            {...getFieldProps('alreadyMinimized')}
                                            checked={alreadyMinimized}
                                        />
                                    )}
                                    label="This pose is the minimized, unbound conformation"
                                />
                            </DialogContentSection>
                        </InputBox>
                        <DialogSubmitButton style={{ width: '100%' }}>
                            Import Molecule
                        </DialogSubmitButton>
                    </Form>
                );
            }}
            { /* end of formik render function */ }
        </Formik>
    );
}
