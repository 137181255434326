/** MainCanvas.js
 *
 * Eventually, this might be a React component which manages the main canvas of BMaps.
 * For now, it just manages a reference to the visualizer and contains functions which
 * pass through to the visualizer.
 * This is a first step which allows the previous interface to generally be unchanged.
 *
 * @typedef {import('3dmol-bmaps/src').LabelSpec} LabelSpec
 *
 * @typedef {{x: number, y: number, z: number }} Point
 * @typedef {{
 *     opacity: number?, description: string?, onClick: function?, object: object
 * }} ShapeOptions
 * @typedef CanvasShape
 * @typedef {{
 * text: string,
 * options: LabelSpec,
 * centerAtomGroup: import('./model').AtomGroup | import('./model').Hotspot,
 * }} labelOptions
 * @typedef CanvasLabel
 */
import { VisualizerInterface } from './VisualizerInterface';
import { Visualizer3Dmol } from './Visualizer3Dmol';
import { EventBroker } from './eventbroker';

let TheVisualizer = new VisualizerInterface();

export function viewerMaker(viewerOptions) {
    TheVisualizer = new Visualizer3Dmol(viewerOptions);
    TheVisualizer.RegisterMouseHandler(HandleMouseEvent);
    EventBroker.subscribe('zapAll', TheVisualizer.Reset);
    EventBroker.subscribe('addAtoms',
        (evtName, {
            atoms, bonds, helices, sheets,
        }) => TheVisualizer.AddAtoms(atoms, bonds, sheets, helices));
    EventBroker.subscribe('removeAtoms',
        (evtName, atoms) => TheVisualizer.RemoveAtoms(atoms));
    EventBroker.subscribe('setAtomSelected',
        (evtName, { atom, selected }) => TheVisualizer.setAtomSelected(atom, selected));
    EventBroker.subscribe('setAtomColor',
        (evtName, { atom, color }) => TheVisualizer.setAtomColor(atom, color));
    EventBroker.subscribe('pauseRedisplay', (evtName, paused) => TheVisualizer.PauseRedisplay(paused));

    return TheVisualizer;
}

// Helpers
/* HandleMouseEvent(): Mouse handler registered to the visualizer.
 * Signature:
 *   - targetObject - { background: true } | { atom: <targetAtom> } | { shape: <targetShape> }
 *   - mouseEvent   - Javascript mouse event
 */
function HandleMouseEvent(targetObject, mouseEvent) {
    if (targetObject.background) {
        EventBroker.publish('backgroundMouse', mouseEvent);
    } else if (targetObject.atom) {
        EventBroker.publish('atomMouse', { atom: getBMapsAtom(targetObject.atom), mouseEvent });
    } else if (targetObject.shape) {
        EventBroker.publish('shapeMouse', { selected: targetObject.shape, mouseEvent });
    } else {
        console.warn(`Unknown mouse target for ${mouseEvent.type}: ${JSON.stringify(targetObject)}`);
    }
}

// Pass-throughs which preserve the current interface

export function resizeCanvas(width, height) {
    TheVisualizer.ResizeViewer(width, height);
}

export function getBMapsAtom(atom) {
    return TheVisualizer.AppAtom(atom);
}

export function getCanvasAtom(atom) {
    return TheVisualizer.VisAtom(atom);
}

export function addAtoms(atoms, bonds, helices, sheets) {
    TheVisualizer.AddAtoms(atoms, bonds, helices, sheets);
}

export function setAtomStyle(atom, style) {
    TheVisualizer.StyleAtom(atom, style);
}

export function setMoleculeDisplayStyle(style, selectorFn) {
    TheVisualizer.StyleAtoms(style, selectorFn);
}

export function setBackgroundColor(colorInfo) {
    TheVisualizer.SetBackgroundColor(colorInfo);
}

/**
 * Refresh the display of existing items in the canvas, including atom colors and bonds.
 * This does not add or remove atoms.
 */
export function redisplay() {
    TheVisualizer.Redisplay();
}

export function zoomToAtoms(selectorFn) {
    TheVisualizer.ZoomToAtoms(selectorFn);
}

export function drawSurface(surfaceArgs) {
    return TheVisualizer.DrawSurface(surfaceArgs);
}

export function removeSurface(surface) {
    TheVisualizer.RemoveSurface(surface);
}

/**
 * Draw a sphere on the canvas
 * @param {Point} center
 * @param {number} radius
 * @param {string|number} color
 * @param {ShapeOptions} shapeOptions
 * @returns {CanvasShape}
 */
export function drawSphere(center, radius, color, shapeOptions) {
    return TheVisualizer.drawSphere(center, radius, color, shapeOptions);
}

export function removeSphere(sphere) {
    TheVisualizer.removeSphere(sphere);
}

/**
 * Draw a cylinder on the canvas
 * @param {Point} start
 * @param {Point} end
 * @param {number} radius
 * @param {string|number} color
 * @param {boolean} dashed
 * @param {ShapeOptions} shapeOptions
 * @returns {CanvasShape}
 */
export function drawCylinder(start, end, radius, color, dashed, shapeOptions) {
    return TheVisualizer.drawCylinder(start, end, radius, color, dashed, shapeOptions);
}

export function removeCylinder(cylinder) {
    TheVisualizer.removeCylinder(cylinder);
}

/**
 * Draw an arrow on the canvas
 * @param {Point} start
 * @param {Point} end
 * @param {number} radius
 * @param {string|number} color
 * @param {number?} radiusRatio
 * @param {ShapeOptions} shapeOptions
 * @returns {CanvasShape}
 */
export function drawArrow(start, end, radius, color, radiusRatio, shapeOptions) {
    return TheVisualizer.drawArrow(start, end, radius, color, radiusRatio, shapeOptions);
}

export function removeArrow(arrow) {
    TheVisualizer.removeArrow(arrow);
}

export function removeShape(shape) {
    TheVisualizer.RemoveShape(shape);
}

export function setAtomSelected(atom, selected) {
    TheVisualizer.setAtomSelected(atom, selected);
}

export function setAtomColor(atom, color) {
    TheVisualizer.setAtomColor(atom, color);
}

export function atomIsVisible(atom) {
    return TheVisualizer.atomIsVisible(atom);
}

export function makeTempMolecule(atoms, style, colorMap, opacity, atomPair) {
    return TheVisualizer.MakeTempMolecule(atoms, style, colorMap, opacity, atomPair);
}

export function removeTempMolecule(model) {
    return TheVisualizer.RemoveTempMolecule(model);
}

export function updateTempMolecule(model, arg) {
    return TheVisualizer.UpdateTempMolecule(model, arg);
}

export function removeAtoms(atoms) {
    return TheVisualizer.RemoveAtoms(atoms);
}

/**
 * @param {labelOptions} labelOptions
 */
export function drawLabel(labelOptions) {
    return TheVisualizer.drawLabel(labelOptions);
}

export function removeLabel(label) {
    return TheVisualizer.removeLabel(label);
}

export function resetMainCanvas() {
    return TheVisualizer.Reset();
}
