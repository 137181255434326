/* WebSocketConn.js */
export class WebSocketConn {
    constructor(url, connectionProperties={}) {
        console.log(`WebSocketConn: creating new instance for ${url} at ${new Date()}`);

        this.url = url;
        this.connectionProperties = connectionProperties;

        this.onConnectionChange = () => {}; // nop
        this.onMessageHandler = () => {}; // nop
        this.onErrorHandler = () => {}; // nop

        this.wsCmdQueue = [];
        this.wsServerBinaryMsgHandlers = [];
        this.wsServerTextMsgHandlers = {};
        this.onOpen = this.onOpen.bind(this);
        this.onMessage = this.onMessage.bind(this);
        this.onClose = this.onClose.bind(this);
        this.initializeWs(this.url);
    }

    initializeWs(url) {
        this.ws = this.wsCreate(url);
        this.wsSubscribe(this.ws, {
            onOpen: this.onOpen,
            onError: this.onError,
            onClose: this.onClose,
            onMessage: this.onMessage,
        });
    }

    closeWs() {
        this.wsUnsubscribe(this.ws, {
            onOpen: this.onOpen,
            onError: this.onError,
            onClose: this.onClose,
            onMessage: this.onMessage,
        });
        this.wsClose(this.ws);
        this.onConnectionChange = () => {};
        this.onMessageHandler = () => {};
        this.onErrorHandler = () => {};
    }

    addHandlers(onConnectionChange, onMessage, onError) {
        this.onConnectionChange = (conn, connected) => onConnectionChange(conn, connected);
        this.onMessageHandler = (data) => onMessage(data);
        if (onError) {
            this.onErrorHandler = (error) => onError(error);
        }
    }

    sendCmd(cmd) {
        const ws = this.ws;
        const wsCmdQueue = this.wsCmdQueue;

        if (ws === undefined || this.wsUrl(ws) !== this.url || this.wsIsClosed(ws)) {
            this.initializeWs(this.url);
        }
        if (this.wsIsOpen(ws)) {
            this.wsSend(ws, cmd);
        } else {
            wsCmdQueue.push(cmd);
        }
    }

    keepAlive() {
        const ws = this.ws;
        if (this.isConnected()) {
            this.wsSend(ws, '#keep-alive0 keep-alive');
        }
    }

    onOpen() {
        console.log(`WebSocketConn: A connection to ${this.getUrl()} has been opened.`);
        while (this.wsCmdQueue.length > 0) {
            const cmd = this.wsCmdQueue.shift(); // fifo
            // console.log(`Sending queued command ${cmd}`);
            this.wsSend(this.ws, cmd);
        }
        this.onConnectionChange(this, true);
    }

    onError(error) {
        console.log(error);
        if (this.onErrorHandler) this.onErrorHandler(error);
    }

    onClose() {
        console.log(`WebSocketConn: A connection to ${this.getUrl()} has been closed.`);
        this.wsCmdQueue = [];
        this.onConnectionChange(this, false);
    }

    onMessage(data) {
        this.onMessageHandler(data);
    }

    isConnected() {
        return this.wsIsOpen(this.ws);
    }

    isDisconnected() {
        return this.wsIsClosed(this.ws);
    }

    getUrl() {
        return this.wsUrl(this.ws);
    }

    getConnectionInfo() {
        return this.wsConnectionInfo(this.ws);
    }

    disconnect() {
        this.wsClose(this.ws);
    }

    // abstract methods
    wsCreate(url) { abstractWarn('wsCreate'); }
    wsSubscribe(ws, callbacks) { abstractWarn('wsSubscribe'); }
    wsUnsubscribe(ws, callbacks) { abstractWarn('wsUnsubscribe'); }
    wsSend(ws, msg) { abstractWarn('wsSend'); }
    wsUrl(ws) { abstractWarn('wsUrl'); }
    wsConnectionInfo(ws) { abstractWarn('wsConnectionInfo'); }
    wsIsClosed(ws) { abstractWarn('wsIsClosed'); }
    wsIsOpen(ws) { abstractWarn('wsIsOpen'); }
    wsClose(ws) { abstractWarn('wsClose'); }
}

function abstractWarn(methodName) {
    console.error(`WebSocketConn: Abstract method call: ${methodName}.  Make sure you are instantiating a child class.`);
}
