import { isCarbon } from 'BMapsSrc/util/chem_utils';
import { aminoRings } from 'BMapsSrc/atomnames';
import { Sub3_3, Dot3 } from '../math';
import { usalignTransform } from '../util/transform_utils';
import { getFullResidueId } from '../util/mol_info_utils';
import { getPreferences } from '../redux/prefs/access';

/* Atom.js
 * JS class for Boltzmann Maps atoms
 */

export class Atom {
    constructor(
        parent, uniqueID, atomName, pos, atomSequenceNumber,
        amberName, atomElement, atomNumber, last
    ) {
        this.atomGroup = null;
        /** @type {number} */
        this.uniqueID = uniqueID;
        this.rawX = pos[0];
        this.rawY = pos[1];
        this.rawZ = pos[2];
        this.atom = atomName; // 'atom' is 3Dmol nomenclature
        this.amber = amberName;
        // serial and index should be moved into visualizer; only one sequence number needs to be
        // maintained here
        this.index = atomSequenceNumber;
        this.serial = atomSequenceNumber;

        this.bonds = [];
        // this.elem = Atom.getElementFromName(atomName, parent.isIon);
        // Note: 'elem' is 3Dmol nomenclature
        this.elem = atomElement;
        this.resn = parent.parentCode;
        this.resname = parent.parentName;
        this.resi = parent.parentSequenceNum;
        this.hetflag = parent.hetflag; // 'hetflag' is 3Dmol nomenclature
        this.Bfactor = undefined;

        parent.setAtomProperties(this);

        // Should these stay here or move to visualizer?
        this.properties = {};
        this.b = 1;
        this.pdbline = '';

        // Needed for visualization
        this.forVisualizer = null;
    }

    toString() {
        return `${getFullResidueId(this)}-${this.atom}`;
    }

    get x() { console.warn('Calling Atom.x'); return this.getX(); }
    get y() { console.warn('Calling Atom.y'); return this.getY(); }
    get z() { console.warn('Calling Atom.z'); return this.getZ(); }
    get origX() { return this.rawX; }
    get origY() { return this.rawY; }
    get origZ() { return this.rawZ; }

    getCaseData() {
        // Note: fragments resulting from fragment searching are fragment objects (DecodedFragment),
        // but are not part of the workspace as MolAtomGroups. They are still subject to transform.
        const parent = this.getAtomGroup() || this.fragment;
        return parent?.getCaseData();
    }

    getAtomTransform() {
        const caseData = this.getCaseData();
        return caseData?.getReferenceCaseData() ? caseData.getWholeProteinTransform() : null;
    }

    getX(options={}) { const pos = this.getPosition(options); return options.as === 'object' ? pos.x : pos[0]; }
    getY(options={}) { const pos = this.getPosition(options); return options.as === 'object' ? pos.y : pos[1]; }
    getZ(options={}) { const pos = this.getPosition(options); return options.as === 'object' ? pos.z : pos[2]; }

    getPosition(options={}) {
        let [x, y, z] = [this.origX, this.origY, this.origZ];

        const { UseAlignment } = getPreferences();
        // null options.transform is not overridden - will return original coordinates
        const transform = options.transform !== undefined
            ? options.transform
            : this.getAtomTransform();
        if (UseAlignment && transform && !options.useOriginal) {
            [x, y, z] = usalignTransform([x, y, z], transform);
        }
        return options.as === 'object' ? { x, y, z } : [x, y, z];
    }

    getAtomGroup() { return this.atomGroup; }
    setAtomGroup(atomGroup) { this.atomGroup = atomGroup; }

    /** Get only the bonds between two heavy atoms.
     * Note: by this definition, Hs have 0 heavy bonds.
     */
    get heavyBonds() {
        return this.bonds.filter((x) => x.atom1.elem !== 'H' && x.atom2.elem !== 'H');
    }

    // Get the list of this atom's neighbors
    get bondedAtoms() {
        return this.bonds.map((b) => b.otherAtom(this));
    }

    // Get the list of bondOrders for this atom
    get bondOrder() {
        return this.bonds.map((b) => b.order);
    }

    /** @returns {Bond[]} */
    getBonds() {
        return [...this.bonds];
    }

    getRingOrdinals() {
        if (this.ringOrdinals) return this.ringOrdinals;
        // resn: standard name (HIS), resname: possible variation (HIE)
        const ringInfo = aminoRings[this.resn];
        if (!ringInfo) return [];
        const ringOrdinals = [];
        for (const [ringOrdinal, atomNames] of Object.entries(ringInfo)) {
            if (atomNames.includes(this.atom)) {
                ringOrdinals.push(ringOrdinal);
            }
        }
        return ringOrdinals;
    }

    /**
     *
     * @param {Atom[]} atoms
     * @returns {{[number]: Atom[]}} - mapping from ring ordinal to atoms in the ring
     */
    static getRingsForAtoms(atoms) {
        const rings = {};
        for (const atom of atoms) {
            for (const ringOrdinal of atom.getRingOrdinals()) {
                let ringAtoms = rings[ringOrdinal];
                if (!ringAtoms) {
                    ringAtoms = [];
                    rings[ringOrdinal] = ringAtoms;
                }
                if (!ringAtoms.includes(atom)) ringAtoms.push(atom);
            }
        }
        return rings;
    }

    isTerminalAtom() {
        return this.elem === 'H' || this.heavyBonds.length === 1;
    }

    hasTrueDoubleBond() {
        return this.bonds.find((b) => b.orderOriginal === 2);
    }

    bondTo(otherAtom) {
        return this.bonds.find((b) => b.otherAtom(this) === otherAtom);
    }

    isSp3Carbon() {
        return isCarbon(this)
            && this.bonds.length === 4
            && this.bondOrder.every((x) => x === 1);
    }

    static getElementFromName(atomName, isIon) {
        const elem = atomName.substr(0, 2);
        if (isIon) {
            return elem[0].toUpperCase() + elem.substr(1).toLowerCase();
        } else {
            if (elem.length <= 1) return elem; // C, N, O, etc.
            //--- why test for ions here?
            // Split out elements that can be single letters or two letters.
            // Some atoms can be both ions and part of complexes (e.g. iron (FE), cobalt,
            // magnesium, etc.).
            switch (elem[0]) {
                case 'C':
                // Note, CA is a common atom name (alpha carbon) so only
                // consider Ca with explicit lower case as calcium.
                // However, ion calcium ions should be handled above, unless complexed.
                    if (elem[1] === '0' || atomName[1] === 'a') return 'Ca';
                    if (elem[1] === 'O') return 'Co'; // good guess (see 5OGP)
                    return (/d|e|l|m|n|o|r|s|u/.test(elem[1])) ? elem : 'C';
                case 'B':
                    return (/a|e|h|r|i|k/.test(elem[1])) ? elem : 'B';
                case 'F':
                    if (elem[1] === 'E') return 'Fe';
                    return (/e|l|r|m/.test(elem[1])) ? elem : 'F';
                case 'H':
                    return (/e|f|g|s|o/.test(elem[1])) ? elem : 'H';
                case 'I':
                    return (/n|r/.test(elem[1])) ? elem : 'I';
                case 'K':
                    return (/r/.test(elem[1])) ? elem : 'K';
                case 'L':
                    return elem === 'Lp' ? 'O' : elem; // LP
                case 'N':
                    return (/a|b|d|e|h|i|o|p/.test(elem[1])) ? elem : 'N';
                case 'O':
                    return (/s|g/.test(elem[1])) ? elem : 'O';
                case 'P':
                    return (/a|b|d|g|m|o|r|t|u/.test(elem[1])) ? elem : 'P';
                case 'S':
                    return (/r|c|g|i|e|n|b|m/.test(elem[1])) ? elem : 'S';
                case 'Y':
                    return (/b/.test(elem[1])) ? elem : 'Y';
                default:
                    // There are fewer single letter elements, the rest are double.
                    return (/U|W/.test(elem)) ? elem[0] : elem;
            }
        }
    }
}

/* Bond class
 * JS class for Boltzmann Maps bonds
 */

export class Bond {
    constructor(atom1, atom2, bondOrder, kekuleOrder) {
        this.atom1 = atom1;
        this.atom2 = atom2;
        this.orderOriginal = bondOrder;
        this.kekuleOrderOriginal = kekuleOrder;

        this.order = this.updateBondOrder(bondOrder, kekuleOrder);

        atom1.bonds.push(this);
        atom2.bonds.push(this);
    }

    getAtoms() { return [this.atom1, this.atom2]; }

    otherAtom(atom) {
        return atom === this.atom1 ? this.atom2 : this.atom1;
    }

    updateBondOrder(bondOrderIn, kekuleOrder) {
        let bondOrder = bondOrderIn;
        if (bondOrder && bondOrder <= 3) {
            // Regular bonds pass through bondOrder for regular bonds
        } else if (Bond.useKekuleBonds && kekuleOrder && kekuleOrder !== 0) {
            // kekuleOrder 0 is bt_indeterminate
            bondOrder = kekuleOrder;
        } else if (Bond.isConjugated(bondOrder)) {
            bondOrder = 2;
        } else if (Bond.isDelocalized(bondOrder) || Bond.isAromatic(bondOrder)) {
            // Pass delocalized and aromatic through to visualizer
        } else {
            // Unexpected.
            console.warn(`Unexpected bond order : ${bondOrder}`);
            bondOrder = 1; // default to single bond
        }

        return bondOrder;
    }

    isRotatable({ includeAmideBonds }={}) {
        return (this.order === 1 || this.order === 0)
            && !this.isTerminal()
            && (includeAmideBonds || !this.isAmide())
            && !this.isInRing()
            && !this.isCollinearToTerminal();
    }

    isInRing() {
        const ringOrdinals1 = this.atom1.ringOrdinals;
        const ringOrdinals2 = this.atom2.ringOrdinals;
        // if both atoms are in a ring and both atoms are in the same ring, the bond is part of that
        // ring
        return ringOrdinals1 && ringOrdinals1.length > 0 // Atom 1 is in a ring
            && ringOrdinals2 && ringOrdinals2.length > 0 // Atom 2 is in a ring
            // Both atoms are in the same ring
            && ringOrdinals1.filter((x) => ringOrdinals2.includes(x)).length > 0;
    }

    isTerminal() {
        // Note: this definition of isTerminalAtom is only used to calculate rotateable bonds
        // bonds, so it excludes Hs
        const isTerminalAtom = (atom) => atom.heavyBonds.length === 1;

        const isTerminalGroup = (fromAtom, toAtom) => {
            const ba = toAtom.bondedAtoms.filter((x) => x.elem !== 'H' && x !== fromAtom);
            const allTerminals = ba.reduce((acc, cur) => acc && isTerminalAtom(cur), true);
            const isTriFluoro = () => toAtom.elem === 'C' && ba.filter((x) => x.elem === 'F').length === 3;
            const isTriChloro = () => toAtom.elem === 'C' && ba.filter((x) => x.elem === 'Cl').length === 3;
            const isPhosphiteOrSulfite = () => ['P', 'S'].includes(toAtom.elem) && ba.filter((x) => x.elem === 'O').length === 3;

            return allTerminals && (isTriFluoro() || isTriChloro() || isPhosphiteOrSulfite());
        };

        return isTerminalAtom(this.atom1)
            || isTerminalAtom(this.atom2)
            || isTerminalGroup(this.atom1, this.atom2)
            || isTerminalGroup(this.atom2, this.atom1);
    }

    isAmide() {
        const amber1 = this.atom1.amber.toLowerCase();
        const amber2 = this.atom2.amber.toLowerCase();
        return (amber1 === 'n' && amber2 === 'c')
            || (amber1 === 'c' && amber2 === 'n');
    }

    isCollinearToTerminal() {
        // we have to examine the bond in both directions
        const vectorIsCollinear = (atfrom, atto) => {
            // atto must have exactly two bonds, and one is to atfrom. Find the other one.
            if (atto.heavyBonds.length !== 2) return false;
            const b1 = atto.heavyBonds[0];
            const b2 = atto.heavyBonds[1];
            const other = b1 === this ? b2 : b1;
            // Check if it's terminal
            if (!other.isTerminal()) return false;
            // check if it's collinear:
            // if the dot product of the bond vectors is nearly unity (1 or -1)
            // Actually, avoid sqrt by checking the square of (A.B)/(|A||B|)
            // or (A.B)^2 / ((A.A)(B.B))
            const ax = other.atom1;
            const ay = other.atom2;
            const at3 = ax === atto ? ay : ax;
            const p1 = atfrom.getPosition();
            const p2 = atto.getPosition();
            const p3 = at3.getPosition();
            const v12 = Sub3_3(p1, p2);
            const v32 = Sub3_3(p3, p2);
            const dot = Dot3(v12, v32);
            const mag1sq = Dot3(v12, v12);
            const mag2sq = Dot3(v32, v32);
            const quot = (dot**2)/(mag1sq * mag2sq);
            // accept 5% non-collinearity, atan(0.05) = 2.9 degrees.
            // square(0.95) ~= 0.9
            return quot > 0.9;
        };

        return vectorIsCollinear(this.atom1, this.atom2)
            || vectorIsCollinear(this.atom2, this.atom1);
    }

    equals(otherBond) {
        return this === otherBond
            || (this.atom1 === otherBond.atom1 && this.atom2 === otherBond.atom2)
            || (this.atom1 === otherBond.atom2 && this.atom2 === otherBond.atom1);
    }

    static isConjugated(bondOrder) {
        return bondOrder === 6;
    }

    static isDelocalized(bondOrder) {
        return bondOrder === 7;
    }

    static isAromatic(bondOrder) {
        return bondOrder === 8 || bondOrder === 9;
    }
}

Bond.useKekuleBonds = true; //--- Move to settings
