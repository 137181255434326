import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import _ from 'lodash';
import { UserActions } from 'BMapsSrc/cmds';
import { Loader } from 'BMapsSrc/Loader';
import { setScoringTableState, setSidePanelWidth } from 'BMapsSrc/redux/projectState/access';
import { useColorSchemeInfo } from 'BMapsSrc/redux/prefs/access';
import { EventBroker } from 'BMapsSrc/eventbroker';
import { App } from 'BMapsSrc/BMapsApp';
import { CompoundTable } from './CompoundTable';
import { SidePanelContainer } from '../info_display/SidePanel';

export function ScoringTableSidePanel({ open, handleClose }) {
    const {
        columnVisibilityModel, scoring,
    } = useSelector((state) => state.projectState.scoringTableState);
    const sidePanelWidth = useSelector((state) => state.projectState.sidePanelWidth);
    const loadedProteins = App.Workspace.getLoadedProteins();
    const [checkedCompounds, setCheckedCompounds] = useState([]);
    const [scoringErrors, setScoringErrors] = useState([]);

    /**
     * @param {Compound[]} scoringCompounds
     * @param {MapCase[]} proteins
     */
    const scoreCompounds = async (scoringCompounds, proteins, quick) => {
        setScoringTableState({ scoring: true });
        const newScoringErrors = [];
        try {
            const scoreResults = await UserActions.ScoreCompounds({
                compounds: scoringCompounds,
                proteins,
                scoringParams: { cleanup: true, quick },
            });
            newScoringErrors.push(...scoreResults.errors.map((error) => {
                console.error(error);
                return { type: 'Error', message: error };
            }));
        } catch (error) {
            console.error(error);
            newScoringErrors.push({ type: 'Exception', message: error.message || error });
        }
        setScoringErrors(newScoringErrors);
        setScoringTableState({ scoring: false });
    };

    const { textCss } = useColorSchemeInfo();

    const [width, setWidth] = useState(sidePanelWidth);
    const [resizing, setResizing] = useState(false);

    function onMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();
        setResizing(true);
    }

    function onMouseMove(e) {
        if (!resizing) return;
        e.preventDefault();
        e.stopPropagation();
        const newWidth = e.clientX - 10;
        if (newWidth > 20) {
            setWidth(newWidth);
        }
    }

    useEffect(() => {
        function onMouseUp() {
            setResizing(false);
            EventBroker.publish('resize');
        }
        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, []);

    useEffect(() => {
        if (resizing) return;
        setSidePanelWidth(width);
        EventBroker.publish('resize');
    }, [resizing]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <SidePanelContainer
                open={open}
                handleClose={handleClose}
                title="Scoring Table"
                width={width}
                contentStyle={{
                    padding: '0.5rem',
                }}
            >
                <>
                    <CompoundTable
                        useProteinProperties
                        useUniqueCompounds
                        checkboxSelection
                        toolBarId="ScoringTableSideTable"
                        setCheckedCompounds={setCheckedCompounds}
                        setColumnVisibilityModel={(visibilityModel) => {
                            setScoringTableState({ columnVisibilityModel: visibilityModel });
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        disableTable={scoring}
                        itemSelector
                    />
                    {scoringErrors.length !== 0 && (
                        <ul style={{
                            border: '1px solid black',
                            margin: '.5rem',
                            padding: '.5rem',
                            borderRadius: '.5rem',
                        }}
                        >
                            <p>Errors while attempting to score compound:</p>
                            {_.uniqBy(scoringErrors, (err) => err.message).map((error) => (
                                <li
                                    style={{ color: 'red', margin: '1rem' }}
                                    key={error.message}
                                >
                                    {error.message}
                                </li>
                            ))}
                        </ul>
                    )}
                    <Button
                        type="button"
                        variant="contained"
                        style={{
                            margin: '.5rem',
                            alignSelf: 'flex-start',
                        }}
                        onClick={() => {
                            scoreCompounds(checkedCompounds, loadedProteins);
                            setCheckedCompounds([]);
                        }}
                        disabled={checkedCompounds.length === 0}
                    >
                        Score
                    </Button>
                    {!!Loader.AllowDevFeatures && (
                        <Button
                            type="button"
                            variant="contained"
                            style={{
                                margin: '.5rem',
                                alignSelf: 'flex-start',
                            }}
                            onClick={() => {
                                scoreCompounds(checkedCompounds, loadedProteins, true);
                                setCheckedCompounds([]);
                            }}
                            disabled={checkedCompounds.length === 0}
                        >
                            Score (quick)
                        </Button>
                    )}
                </>
            </SidePanelContainer>
            <div
                role="toolbar"
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseMove}
                style={{
                    cursor: 'ew-resize',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginRight: '.5rem',
                }}
            >
                <i className="fa fa-arrows-h" style={{ color: textCss }} />
            </div>
        </div>
    );
}
