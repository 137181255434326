/**
 * SimSpec.js - a class to manage data for a fragment simulation run
 */

import { Polymer } from './atomgroups';
import { MapCase } from './MapCase';

export class SimSpec {
    constructor(obj) {
        this.protein = obj.protein || null;
        this.proteinChains = obj.proteinChains || null;
        this.compound = obj.compound || null;
        this.ions = obj.ions || [];
        this.cofactors = obj.cofactors || [];
        this.polymers = obj.polymers || [];
        this.ignoredObjects = obj.ignoredObjects || [];
        this.project = obj.project || SimSpec.defaultProjectName(this);
        this.case = obj.case || SimSpec.defaultCaseName(this);
        this.bindingSiteSpecs = obj.bindingSiteSpecs || '';
        this.fragments = obj.fragments || '';
    }

    get selectQuery() {
        const chains = [...this.proteinChains, ...this.polymers];
        let including = `${chains.map((x) => `chain ${x.chain}`).join(' and not ligand or ')} and not ligand`;
        if (this.compound) including += ` or ${this.compound.selectQuery}`;
        this.ions.forEach((x) => { including += ` or ${x.resSpec}`; });
        this.cofactors.forEach((x) => { including += ` or ${x.resSpec}`; });

        let excluding = [];
        for (const ignored of this.ignoredObjects) {
            if (ignored !== this.compound) {
                const spec = ignored instanceof Polymer
                    ? `chain ${ignored.chain}`
                    : ignored.resSpec;
                excluding.push(spec);
            }
        }
        const selectHack = 'chain impossible or '; // Workaround for select issue
        excluding = excluding.length > 0 ? `and not (${selectHack}${excluding.join(' or ')})` : '';
        const query = `(${including}) ${excluding}`;
        console.log(`Select query for prepare simulation data:\n${query}`);
        return query;
    }

    get forServer() {
        return {
            project: this.project,
            case: this.case,
            bindingSiteSpecs: this.bindingSiteSpecs,
        };
    }

    get forFragserv() {
        const protein = this.protein;
        const structureLabel = protein.pdbID?`Specified ${protein.pdbID}`:'Custom';
        let description = `${structureLabel} structure`;
        if (this.compound) {
            description += ` with ${this.compound.isLigand() ? 'ligand' : 'compound'} ${this.compound.resSpec}`;
        }

        // Fragserv will use the sourceDesc field to know where to find the structures.
        // If the protein case and the simulation case are different, then we'll writing to
        // the personal directory.  Change the sourceDesc so fragserv doesn't look in the default
        // place for that protein.
        let sourceDesc = '';
        if (protein) {
            sourceDesc = protein.sourceDesc;
            if (protein.case !== this.case && !protein.sourceDesc.endsWith('_modified')) {
                sourceDesc += '_modified';
            }
        }

        return {
            project: this.project || '',
            case: this.case || '',
            fragments: this.fragments || '',
            gene_name: protein ? protein.gene_name : '',
            molecule_name: protein ? protein.molecule_name : '',
            pdbid: protein ? protein.pdbID : '',
            description,
            sourceDesc,
        };
    }

    /**
     * POST arguments that will be sent to fragserv
     */
    static fragservArgsFromProtein(protein, fragments='') {
        return {
            project: protein.project || '',
            case: protein.case || '',
            fragments,
            gene_name: protein ? protein.gene_name : '',
            molecule_name: protein ? protein.molecule_name : '',
            pdbid: protein ? protein.pdbID : '',
            description: protein ? protein.description : '',
            sourceDesc: protein ? protein.sourceDesc : '',
        };
    }

    static defaultProjectName({ protein }) {
        if (!protein) return '';
        const name = protein.project || protein.gene_name || protein.molecule_name || '';
        return name.trim().replace(/[^\w]/g, '-');
    }

    // This default case will populate the "structure name" field when creating a
    // new simulation.
    static defaultCaseName({ protein, compound }) {
        if (!protein) return '';
        let caseName = '';
        let cmpdName = '';
        if (compound) {
            cmpdName = compound.isLigand() ? compound.resname : compound.resSpec;
        }

        // The default case name has a protein part and a compound part.
        // Protein part could be pdbid or derived from the case name, depending on source.
        // The logic for when to include the compound part changes by source.

        // Most scenarios use pdbID for default case name. If empty, the user must specify.
        caseName = protein.pdbID;

        switch (protein.sourceDesc) {
            case MapCase.Sources.Public:
                // If there ever is an installed bmaps case without a pdbid,
                // use the first portion of the case name
                if (!caseName) caseName = protein.case.split('_')[0];
                break;
            case MapCase.Sources.Fragserv:
                caseName = protein.case;
                // For fragserv and other cases, keep the existing case name, adding the
                // compound if it's not already present.
                // This may result in case names like "PDBID+cmpd_1+cmpd_2" if you run a
                // water simulation on one compound and then another on the results of the first.
                // Not going to try to be clever at parsing case components from the name.
                // Don't allow characters except separators after the cmpdName
                if (cmpdName && !caseName.match(`\\+${cmpdName}([-_\\.\\+]|$)`)) {
                    caseName += `+${cmpdName}`;
                    cmpdName = ''; // Prevent appending cmpdName a 2nd time below.
                }
                break;
            // No default - handled above (pdbID)
        }

        // Don't add compound to empty caseName, which would make, eg. "+4K6"
        if (caseName && cmpdName) {
            caseName += `+${cmpdName}`;
        }

        return caseName;
    }

    /**
     * Derive a default SimSpec from a CaseData and workspace.
     * The caseData is the source of the data.
     * The workspace contains the active compound as well as (in)visibility information that
     * may be used (not currently) to uncheck some of the protein chains by default.
     *
     * @param {Workspace} workspace The Workspace containing active / visible metadata
     * @param {CaseData} caseData The CaseData containing structure data for the simulation
     * @param {boolean} includeActiveCompound Whether to include Workspace active cmpd in the sim.
     * @param {string} fragments
     * @returns {SimSpec} The default SimSpec for this Workspace and CaseData
     *
     * Note: I went back and forth about how to handle the compound argument.
     * Should it become a compound object reference and the caller has to decide which one to send?
     * If the caseData doesn't have the currently active compound, should we try a ligand instead?
     * I decided to keep to keep the behavior the same, using a flag for whether or not to include
     * the active compound, and I decided to only apply it to the active compound.
     * So if the user has an active compound from one protein and they attempt a simulation on a
     * different protein, by default the derived SimSpec will not have a cmpd;
     * the user will have to specify.
     *
     */
    static GetFromWorkspace(workspace, caseData, includeActiveCompound=false, fragments='') {
        if (!caseData) return new SimSpec();

        let compound;
        if (includeActiveCompound) {
            const activeCmpd = workspace.getActiveCompound();
            if (activeCmpd && caseData.hasAtomGroup(activeCmpd)) {
                compound = activeCmpd;
            }
        }

        const polymerChains = caseData.getPolymers();
        let ions = caseData.getIons();
        let cofactors = caseData.getCofactors();
        // Filter out polymer ions and cofactors
        if (polymerChains.length > 0) {
            const polymerChainIds = polymerChains.map((i) => i.chain);
            ions = ions.filter((i) => !polymerChainIds.includes(i.chain));
            cofactors = cofactors.filter((i) => !polymerChainIds.includes(i.chain));
        }

        // First filter out any invisible ions, cofactors, and chains
        ions = ions.filter((i) => workspace.isVisible(i));
        cofactors = cofactors.filter((c) => workspace.isVisible(c));
        const proteinChains = caseData.getProteinChains();
        const proteinChainIds = proteinChains.map((c) => c.chain);
        const visibleChains = proteinChains.filter((p) => workspace.isVisible(p));
        const visibleChainIds = visibleChains.map((c) => c.chain);
        // Include cofactors and ions that are on visible chains or independent
        const independentIons = ions.filter((i) => !proteinChainIds.includes(i.chain));
        ions = ions.filter((i) => visibleChainIds.includes(i.chain));
        ions = [...ions, ...independentIons];
        const independentCofactors = cofactors.filter((c) => !proteinChainIds.includes(c.chain));
        cofactors = cofactors.filter((c) => visibleChainIds.includes(c.chain));
        cofactors = [...cofactors, ...independentCofactors];

        return new SimSpec({
            protein: caseData.mapCase,
            proteinChains: visibleChains,
            compound: compound || null,
            ions,
            cofactors,
            fragments,
        });
    }
}

SimSpec.CaseStatus = {
    NEW: 'NEW',
    EXISTS_OK: 'EXISTS_OK',
    EXISTS_CONFLICT: 'EXISTS_CONFLICT',
};

SimSpec.Status = {
    Ready: 'Ready',
    Conflict: 'Conflict',
    StorageUpdatedError: 'StorageUpdatedError',
    PersonalStorageError: 'PersonalStorageError',
    NoHydrogensError: 'NoHydrogensError',
    FdbFileError: 'FdbFileError',
    PartialChargesError: 'PartialChargesError',
    UnknownError: 'UnknownError',
    TooManyChainsError: 'TooManyChainsError',
    BindingSiteSpecError: 'BindingSiteSpecError',
};
