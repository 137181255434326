// GiFEHelpers.js
/**
 * @fileoverview This file contains functions that are used to interact with the GiFE API.
 *
 * Public interface:
 * - prepareGiFERequest - process atoms / atomGroups into a logical BMapsGiFERequest
 * - sendGiFERequest - place the webservice call and process the results
 *
 * Important helper functions:
 * - prepareGiFERequestUnits - convert atomGroups and atoms into GiFERequestUnits
 * - createGiFERequest - format the request for the GiFE API
 * - getDisplayData - process the results from the GiFE API for potential display in a table
 *
 * @typedef {import('BMapsModel').Compound} Compound
 * @typedef {import('BMapsModel').Atom} Atom
 *
 * @typedef {{
 *     name: string,
 *     unboundName: string,
 *     bound: GiFEAtomList,
 *     unbound: GiFEAtomList,
 * }} GiFERequestUnit
 *
 * @typedef {{
 *     soluteUnits: { [name: string]: GiFERequestUnit },
 *     compoundUnits: { [name: string]: GiFERequestUnit }
 * }} BMapsGiFERequest
 *
 * @typedef {{
 *     compound: string, protein: string, value: number, warning: string, error: string
 * }} GiFEResultUnit
 *
 * @typedef {{
 *     kind: string,
 *     name: string,
 *     coords: number[],
 *     elements: string[],
 * }} GiFEAtomList
 *
 * @typedef {{
 *    kind: string,
 *    name: string,
 *    parts: string[],
 * }} GiFEAtomSet
 *
 * @typedef {{
 *    kind: string,
 *    version: number,
 *    parts: (GiFEAtomList | GiFEAtomSet)[],
 * }} GiFERequestObj
 */

import { AtomGroup } from 'BMapsModel';
import { hasLogin } from 'BMapsSrc/server/session_request';
import { getFullResidueId, getAltLocMap } from 'BMapsSrc/util/mol_info_utils';
import { countHeavyAtoms } from 'BMapsSrc/util/chem_utils';
import { WebServices } from '../WebServices';
import { ensureArray } from '../util/js_utils';
import { getMolAtomLines } from '../util/mol_format_utils';
import { App } from '../BMapsApp';

/**
 *
 * @param {{ atoms: Atom[], atomGroups: AtomGroup[]}} param0
 * @returns {Promise<BMapsGiFERequest>}
 * prepareGiFEGroups takes 1 argument either atomsIn or atomGroupsIn, never both.
 */
export async function prepareGiFERequest({ atoms: atomsIn, atomGroups: atomGroupsIn }) {
    if ((atomsIn && atomGroupsIn) || (!atomsIn && !atomGroupsIn)) {
        throw new Error('getGifeEnergies needs exactly one of atoms or atomGroups');
    }

    const gifeGroups = []; // { atoms, atomGroup }

    if (atomsIn) {
        const atoms = filterAltLocs(ensureArray(atomsIn));
        const {
            primaryGroups, // Whole atom groups that may contain whole subgroups
            extraAtomsMap, // Map for atoms not in any whole group
        } = App.Workspace.partitionAtomsIntoGroups(atoms);
        // For Gife, we'll send:
        // Primary groups with all their atoms
        // Extra atom groups with their atoms
        // We're not dealing with "subsumed groups" which are child groups of whole primary groups
        for (const primaryGroup of primaryGroups) {
            gifeGroups.push({ atoms: primaryGroup.getAtoms(), atomGroup: primaryGroup });
        }
        for (const [atomGroup, extraAtoms] of extraAtomsMap.entries()) {
            gifeGroups.push({ atoms: extraAtoms, atomGroup });
        }
    } else {
        const atomGroups = ensureArray(atomGroupsIn);
        const allAtoms = AtomGroup.atomsInAtomGroups(atomGroups);
        const includedAltLocSpecs = getIncludedAltLocSpecs(allAtoms);
        for (const atomGroup of atomGroups) {
            const atoms = filterAltLocs(atomGroup.getAtoms(), includedAltLocSpecs);
            if (atoms.length > 0) {
                gifeGroups.push({ atoms, atomGroup });
            }
        }
    }

    const { soluteUnits, compoundUnits } = await prepareGiFERequestUnits(gifeGroups);
    return { soluteUnits, compoundUnits };
}

/**
 * sendGiFERequest takes a logical BMapsGiFERequest, converts it to the GiFE API format,
 * calls the API, and processes the results.
 * errors in the results are from the API for that request.
 * Top level error is for cases in which the API was unable to be called correctly.
 * @param {BMapsGiFERequest} gifeRequest
 * @returns {Promise<{
 *     results: GiFEResultUnit[],
 *     error: string
 * }>}
 */
export async function sendGiFERequest({ soluteUnits, compoundUnits }) {
    const results = [];
    let error = '';
    const gifeReq = createGiFERequest(soluteUnits, compoundUnits);
    console.log('GiFE Req:', gifeReq);
    if (await hasLogin()) {
        try {
            const url = WebServices.GIFE_URL;
            const data = await WebServices.startWsRequestJson(url, gifeReq);
            const responseData = JSON.parse(data);
            if (responseData.kind === 'GiFE-response') {
                const response = responseData.results;
                console.log('Response from GiFE:', response);
                for (const pair of getSoluteCompoundPairs(soluteUnits, compoundUnits)) {
                    const [soluteUnit, cmpdUnit] = pair;
                    const displayObj = getDisplayData(response, soluteUnit, cmpdUnit);
                    const resultUnit = {
                        compound: cmpdUnit?.name,
                        protein: soluteUnit?.name,
                        value: displayObj.result,
                        warning: displayObj.warning,
                        error: displayObj.error,
                    };
                    results.push(resultUnit);
                }
            } else {
                error = `The GiFE API reported an error: ${responseData.error}`;
            }
        } catch (errorCaught) {
            error = errorCaught;
        }
    } else {
        error = 'GiFE requires a BMaps login.';
    }
    return { results, error };
}

/**
 * Convert { atomGroup, atoms } request units into logical GiFE request units,
 * which combine related solute atomGroups, and account for bound/unbound states.
 * @param {[{ atoms: Atom[], atomGroup: AtomGroup[] }]} gifeGroups
 * @returns {Promise<{
 *     soluteUnits: { [name: string]: GiFERequestUnit },
 *     compoundUnits: { [name: string]: GiFERequestUnit }
 * }>}
 */
async function prepareGiFERequestUnits(gifeGroups) {
    const compoundUnits = {};
    const soluteUnits = {};

    for (const { atoms, atomGroup } of gifeGroups) {
        console.log(`One GiFE group with ${atoms.length} atoms from ${atomGroup.displayName}`);
        const isCompound = atoms.some((atom) => App.Workspace.isCompoundAtom(atom));
        if (isCompound) {
            const unitName = atomGroup.displayName;
            const gifeUnit = compoundUnits[unitName];
            if (!gifeUnit) {
                const unboundName = `${unitName}-unbound`;
                const bound = atomsToGiFEAtomList(unitName, atoms);
                const { mol: unboundMol, error } = await compoundUnboundConf(atomGroup);
                let unbound;
                if (unboundMol && atoms.length === atomGroup.getAtoms().length) {
                    unbound = molStringToGiFEAtomList(unboundName, unboundMol);
                } else {
                    // Primary use case here is that the user selects a subset of the compound
                    // to send to GiFE. The unbound conformation that we have is for the whole
                    // compound, not the subset. We would need a way to get the unbound conformation
                    // for just the subset.
                    unbound = atomsToGiFEAtomList(unboundName, atoms);
                    const reason = unboundMol ? 'partial atom selection' : error;
                    console.warn(`GiFE prep couldn't get proper unbound conformaton ${unboundName} because of ${reason}`);
                }
                compoundUnits[unitName] = {
                    name: unitName,
                    unboundName,
                    bound,
                    unbound,
                };
            } else {
                console.warn(`GiFE prep adding new atoms to existing compound unit ${unitName}, but could not apply unbound conformation for the new atoms`);
                addToAtomList(gifeUnit.bound, atoms);
                addToAtomList(gifeUnit.unbound, atoms);
            }
        } else { // solute
            const unitName = App.getDataParents(atomGroup).caseData.mapCase.pdbID;
            const unboundName = `${unitName}-unbound`;
            const gifeUnit = soluteUnits[unitName];
            if (!gifeUnit) {
                const bound = atomsToGiFEAtomList(unitName, atoms);
                const unboundAtoms = atoms; // Can't currently handle unbound proteins
                const unbound = atomsToGiFEAtomList(unboundName, unboundAtoms);
                soluteUnits[unitName] = {
                    name: unitName,
                    unboundName,
                    bound,
                    unbound,
                };
            } else {
                addToAtomList(gifeUnit.bound, atoms);
                const unboundAtoms = atoms; // Can't currently handle unbound proteins
                addToAtomList(gifeUnit.unbound, unboundAtoms);
            }
        }
    }

    return { soluteUnits, compoundUnits };
}

/**
 *
 * @param {GiFERequestUnit[]} soluteUnits
 * @param {GiFERequestUnit[]} compoundUnits
 * @returns { GiFERequestObj }
 */
function createGiFERequest(soluteUnits, compoundUnits) {
    const parts = [];
    Object.values(soluteUnits).forEach(({ bound, unbound }) => parts.push(bound, unbound));
    Object.values(compoundUnits).forEach(({ bound, unbound }) => parts.push(bound, unbound));
    for (const [soluteUnit, cmpdUnit] of getSoluteCompoundPairs(soluteUnits, compoundUnits)) {
        if (!soluteUnit || !cmpdUnit) continue; // If single unit, there is no system to add

        /** @type {GiFEAtomSet} */
        const atomSet = {
            kind: 'atom-set',
            name: getSystemName(soluteUnit, cmpdUnit),
            parts: [soluteUnit.name, cmpdUnit.name],
        };
        parts.push(atomSet);
    }

    /** @type {GiFERequestObj} */
    const requestObj = {
        kind: 'GiFE',
        version: 1,
        parts,
    };
    return requestObj;
}

/**
 *
 * @param {{ [name: string]: { value: number, error: string }}} resDict
 * @param {GiFERequestUnit} soluteUnit
 * @param {GiFERequestUnit} cmpdUnit
 * @returns {{ result: number, warning: string, error: string }}
 * getDisplayData uses the arguments given to determine if interactions
 * need to be calculated and creates a display object for each result
 * returned in the GiFE response stored in resDict.
 */
function getDisplayData(resDict, soluteUnit, cmpdUnit) {
    let error = '';
    let result = null;
    let warning = '';
    const sanityCheckLevel = 150;

    if (!cmpdUnit) {
        // singleStructure
        const { name, unboundName } = soluteUnit;
        const { value: valueUnbound, error: errorUnbound } = resDict[unboundName];
        const { value: valueBound, error: errorBound } = resDict[name];
        if (errorUnbound || errorBound) {
            error = `Error: ${errorUnbound || errorBound}`;
        } else {
            result = valueBound - valueUnbound;
            console.log('Single Structure Equation: Structure(bound) - Structure(unbound) = Result');
            console.log(`${valueBound} - ${valueUnbound} = ${result}`);
        }
    } else {
        const { unboundName: soluteUnboundName } = soluteUnit;
        const { unboundName: cmpdUnboundName } = cmpdUnit;
        const systemName = getSystemName(soluteUnit, cmpdUnit);

        const cError = resDict[cmpdUnboundName].error;
        const pError = resDict[soluteUnboundName].error;
        const sError = resDict[systemName].error;

        if (cError) {
            error = `Error computing compound ${cError}`;
        } else if (pError) {
            error = `Error protein compound ${pError}`;
        } else if (sError) {
            error = `Error computing system ${sError}`;
        }
        if (!error) {
            const compound = resDict[cmpdUnboundName].value;
            const protein = resDict[soluteUnboundName].value;
            const system = resDict[systemName].value;
            result = system - (compound + protein);
            if (result > sanityCheckLevel) {
                warning = 'This GiFE interaction value is unexpected. You may wish to check the chemistry geometry or contact support.';
            }
            console.log('Interaction Equation: System Energy - (Compound Energy + Protein Energy) = Result');
            console.log(`${system} - (${compound} + ${protein}) = ${result}`);
        }
    }
    return { result, warning, error };
}

/**
 * Return atoms as GiFE atom-list
 * @param {string} name
 * @param {Atom[]} atoms
 * @returns {GiFEAtomList}
 */
function atomsToGiFEAtomList(name, atoms) {
    const coords = atoms.map((atom) => atom.getPosition());
    const atomNames = atoms.flatMap(gifeAtomName);

    const list = {
        kind: 'atom-list',
        name,
        coords,
        // GiFE API accepts elements or atom names but key must be elements
        elements: atomNames, // (may want to use both in the future)
    };
    return list;
}

/**
 * Parse coordinates and elements from molstring and return as a GiFE atom-list.
 * @param {string} name
 * @param {string} molString
 * @returns {GiFEAtomList}
 */
function molStringToGiFEAtomList(name, molString) {
    const coords = [];
    const elements = [];

    const atomLines = getMolAtomLines(molString);

    for (const atom of atomLines) {
        coords.push([atom.x, atom.y, atom.z]);
        elements.push(atom.element);
    }
    return {
        kind: 'atom-list',
        name,
        coords,
        elements,
    };
}

/**
 *
 * @param {GiFEAtomList} atomList
 * @param {Atom[]} atoms
 */
function addToAtomList(atomList, atoms) {
    const coords = atoms.map((atom) => atom.getPosition());
    const atomNames = atoms.flatMap(gifeAtomName);
    coords.forEach((coord) => atomList.coords.push(coord));
    // GiFE API accepts elements or atom names but key must be elements
    atomNames.forEach((name) => atomList.elements.push(name));
}

/**
 *
 * @param {GiFERequestUnit[]} soluteUnits
 * @param {GiFERequestUnit[]} compoundUnits
 * @returns {Array<[GiFERequestUnit, GiFERequestUnit]>}
 */
function getSoluteCompoundPairs(soluteUnits, compoundUnits) {
    const pairs = [];
    if (Object.keys(soluteUnits).length === 0 || Object.keys(compoundUnits).length === 0) {
        const listToUse = Object.keys(soluteUnits).length === 0 ? compoundUnits : soluteUnits;
        Object.values(listToUse).forEach((unit) => {
            pairs.push([unit]);
        });
    } else {
        Object.values(soluteUnits).forEach((soluteUnit) => {
            Object.values(compoundUnits).forEach((cmpdUnit) => {
                pairs.push([soluteUnit, cmpdUnit]);
            });
        });
    }
    return pairs;
}

/**
 * Fetch, store, and return the unbound conformation for a compound, in mol format.
 * @param {Compound} cmpd
 * @returns {Promise<string?>}
 */
export async function compoundUnboundConf(cmpd) {
    const unboundConf = cmpd.molUnbound;
    if (unboundConf) {
        return { mol: unboundConf };
    }

    if (countHeavyAtoms(cmpd.getAtoms()) > 50) {
        return { error: 'Too many heavy atoms to calculate unbound conformation' };
    }

    const { connector } = App.getDataParents(cmpd);
    const spec = cmpd.resSpec;
    const unboundConformations = await connector.cmdGetUnboundConformations([spec]);
    const unboundResult = unboundConformations[spec];
    const { value, error } = unboundResult || {};
    if (value) {
        cmpd.molUnbound = value;
        return { mol: value };
    } else {
        console.warn(`Failed to get unbound conformation for ${spec}. Result packet: ${JSON.stringify(unboundConformations)}. Error: ${error}`);
        return { error: 'Failed to parse unbound conformation result packet.' };
    }
}

function getSystemName(soluteUnit, cmpdUnit) {
    return `${soluteUnit.name}-${cmpdUnit.name}-system`;
}

/**
 * @description GiFE will reject multiple alt locs for the same residue, so we need to filter.
 * This function collects any altLocs and chooses a specific resSpec to include for each residue.
 * @param {Atom[]} atoms
 * @returns {Set<string>} includedAltLocSpecs
 */
function getIncludedAltLocSpecs(atoms) {
    const altLocMap = getAltLocMap(atoms);
    if (Object.keys(altLocMap).length > 0) console.log('GiFE AltLocs:', altLocMap);
    const includedAltLocSpecs = new Set();
    // For each residue that has alt locs, use the atoms of the first we find
    for (const [altLocKey, specToAtomsMap] of Object.entries(altLocMap)) {
        const resSpec = Object.keys(specToAtomsMap)[0];
        includedAltLocSpecs.add(resSpec);
        console.log(`For alt loc ${altLocKey}, using ${resSpec}`);
    }
    return includedAltLocSpecs;
}

/**
 * Filter atoms based on altLoc. If includedSpecs is provided, only include those residues,
 * otherwise scan the atoms to identify the residues to include.
 * @param {Atom[]} atoms
 * @param {Set<string>?} includedSpecs
 * @returns {Atom[]}
 */
function filterAltLocs(atoms, includedSpecs) {
    const includedAltLocSpecs = includedSpecs || getIncludedAltLocSpecs(atoms);
    return atoms.filter((atom) => !atom.altLoc || includedAltLocSpecs.has(getFullResidueId(atom)));
}

function gifeAtomName(atom) {
    const name = atom.atom;
    const elem = atom.elem;
    if (elem.length === 1) {
        return atom.atom;
    }

    // For multi-character elements, always send the lowercase version to GiFE, eg Cl, not CL.
    // Sometimes the atomname could be eg CL2, which is ambiguous between C and Cl, so send Cl2.
    const nameStart = name.slice(0, elem.length);
    if (nameStart.toUpperCase() === elem.toUpperCase() && nameStart !== elem) {
        return `${elem}${name.slice(elem.length)}`;
    }
    return name;
}
