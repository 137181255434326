import initRDKitModule from './RDKit_minimal';
import RDKitModuleWasm from './RDKit_minimal.wasm';

const USE_COORDGEN = true;
let RDKitModule;
initRDKitModule({
    locateFile(path) {
        if (path.endsWith('.wasm')) return RDKitModuleWasm;
        return path;
    },
    onRuntimeInitialized() { // TODO make loading screen wait for this
        console.log('RDKit runtime initialized');
    },
}).then(
    (instance) => { RDKitModule = instance; },
    (error) => { console.error(`RDKit runtime failed to initialize: ${error}`); },
);

export class RDKitExport {
    static isInitialized() {
        return Boolean(RDKitModule);
    }

    static getMol(data) {
        if (!RDKitModule) throw new Error('RDKit not initialized');
        return RDKitModule.get_mol(data);
    }

    // I'm not clear what this is for'
    static getQMol(data) {
        if (!RDKitModule) throw new Error('RDKit not initialized');
        return RDKitModule.get_qmol(data);
    }

    // getCleanMol takes molblock input and returns a mol object flattened and without hydrogens
    static getCleanMol(data) {
        if (!RDKitModule) throw new Error('RDKit not initialized');
        let molA = RDKitExport.getMol(data);
        let molB = RDKitExport.getMol(molA.get_new_coords(USE_COORDGEN));
        molA.delete();
        molA = RDKitExport.getMol(molB.remove_hs());
        molB.delete();
        molB = RDKitExport.getMol(molA.get_kekule_form());
        molA.delete();
        return molB;
    }

    /** This functions checks the orientation of the given molblock molecule.
     * If it is more "tall" than "wide", rotate it 90 degrees
     */
    static molSpin(molBlock) {
        const x = [];
        const y = [];
        let index = molBlock.indexOf('.');

        // Each '.' in a mol corresponds to a coordinate. Pull x and y coordinates into the arrays.
        while (index > 0) {
            x.push(parseFloat(molBlock.substr(index - 5, 10), 10));
            index += 10;
            y.push(parseFloat(molBlock.substr(index - 5, 10), 10));
            index = molBlock.indexOf('.', index + 11);
        }

        // if the arrays are empty, we don't need to rotate
        if (x.length === 0 || y.length === 0) return 0;
        const xdelta = x.reduce((a, b) => Math.max(a, b)) - x.reduce((a, b) => Math.min(a, b));
        const ydelta = y.reduce((a, b) => Math.max(a, b)) - y.reduce((a, b) => Math.min(a, b));
        return (ydelta > xdelta ? 90 : 0);
    }
}
