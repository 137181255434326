/* Candidate for MapSelector removal */

import { useEffect } from 'react';
import { initMapSelector } from '../map_selector';

/**
 * React wrapper for existing jquery protein selector pane; initializes jquery and prepares element
 * Relies on jquery reference to bfm_viewer for height and drag/drop event handling
 * @returns component for the map selector
 */
export default function MapSelector() {
    useEffect(() => {
        initMapSelector();
    }, []);
    return (
        <div id="mapSelectorGroup" style={{ maxWidth: '75%', maxHeight: '75%' }} hidden />
    );
}
