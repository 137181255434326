import _ from 'lodash';
import React, { useEffect } from 'react';
import { MoleculeTypes } from '../molecule_types';

/* UI Utilities */

// Generally don't want to use jQuery in React code, but tooltipster needs it.
// jQuery is isolated to this function.
// Pass in an object mapping from css classes to tooltipster params.
// The given classes will have their tooltipster tooltips reset to their "title" attribute.
// at the specified location.
// tooltipInfo = { class1: params1, class2: params2 ...
export function updateTooltipster(tooltipInfo) {
    if (!$.tooltipster) return; // Not initialized yet

    for (const [tClass, props] of Object.entries(tooltipInfo)) {
        const query = (tClass.startsWith('.') || tClass.startsWith('#')) ? tClass : `.${tClass}`;

        // Update existing tooltips, by going backwards from
        // tooltipster instance to the DOM node.
        const toUpdate = $.tooltipster.instances(query);
        for (const t of toUpdate) {
            const existingContent = t.content();
            const origin = t.elementOrigin();
            if (origin) {
                // htmlContent is parsed into a DOM node, if present
                const htmlContent = origin.dataset['tooltipContent'] && $(origin.dataset['tooltipContent']);
                // arbitrarily giving priority to html content
                const newContent = htmlContent || origin.title;
                if (newContent && newContent !== existingContent) {
                    // Update to a new tooltip content
                    t.content(newContent);
                    origin.title = '';
                    delete (origin.dataset['tooltipContent']);
                }
                // This change was an attempt to automatically clean up the tooltip content
                // however it lead to unresponsive tooltips that wouldn't load on initial render.
                //
                // else if (!newContent) {
                //    // Remove tooltip content altogether
                //     t.content(null);
                //     delete origin.title;
                //     delete (origin.dataset['tooltipContent']);
                // }

                // In react, we may have added a new node after an update, but it is still
                // known by tooltipster.  In this case, we need to add the tooltipstered class.
                if (!origin.classList.contains('tooltipstered')) {
                    origin.classList.add('tooltipstered');
                }
            } else {
                t.destroy();
            }
        }

        // Add tooltips.
        // Don't redo existing tooltipster tooltips.
        const remaining = $(query).not('.tooltipstered');
        if (props) {
            remaining.tooltipster({
                ...props,
                functionBefore(instance, helper) {
                    // Handling situations with tooltips on both parent and child nodes.
                    // Examples:
                    //   1. In Energy Table, there's a tooltip on the energy score row,
                    //      and on the energy error icon.
                    //   2. In the fragment grow search results (LigandMod.jsx), there is
                    //      a tooltip on a result row, and on the icons (pin, plus sign, etc)
                    //
                    // We don't want to show the parent tooltip if the child tooltip is being shown.
                    // So, we need to:
                    // 1. Prevent opening the parent tooltip if the child tooltip should be seen
                    // 2. Close any parent tooltips if a child tooltip is opening
                    //
                    // Issue:
                    //    Sometimes, if you mouse from the child back into the parent,
                    //    the parent tooltip won't show.  This is because it has been closed.
                    //    It will show up again if you leave and reenter just the parent.

                    // 1. Prevent opening parent when child tooltip should be used.
                    const descendents = $(helper.origin).find('*');
                    const descendentTooltips = $.tooltipster.instances(descendents);
                    // eslint-disable-next-line no-underscore-dangle
                    if (descendentTooltips.some((tooltip) => tooltip.__pointerIsOverOrigin)) {
                        return false; // Stop tooltip from being opened
                    }

                    // 2. Close parent before opening child tooltip
                    const parents = $(helper.origin).parents();
                    $.tooltipster.instances(parents).forEach((p) => p.close());

                    return undefined;
                },
            });
        } else {
            remaining.tooltipster();
        }
    }
}

export function imageSrc(img) {
    const imageDir = 'images';
    return `${imageDir}/${img}`;
}

export function svgImgSrc(svgData) {
    let imgData = svgData.replace(/#/g, '%23'); // replace #s, per warning
    imgData = imgData.replace(/\s/g, '%20'); // URLify
    imgData = imgData.replace(/'/g, '%27'); // make quotes guaranteed safe
    imgData = imgData.replace(/"/g, '%22');
    imgData = `data:image/svg+xml;charset=utf-8,${imgData}`; // convert to src data value
    return imgData;
}

export function workingIndicator() {
    return " <i class='fa fa-circle-o-notch fa-spin'> </i>";
}

export function WorkingIndicator() {
    return React.createElement('i', { className: 'fa fa-circle-o-notch fa-spin' }, ' ');
}

export function addTabInfo(hostId, tabInfo) {
    $(`#${hostId} ul`).append(`<li><a href="#${tabInfo.tabId}">${tabInfo.label}</a></li>`);
    $(`#${hostId}`).append(`<div id="${tabInfo.tabId}">${tabInfo.tabHtml}</div>`);
    if (tabInfo.onActive) {
        $(`#${tabInfo.tabId}`).on('onActive', tabInfo.onActive);
    }
    if (tabInfo.onCreate) {
        $(`#${tabInfo.tabId}`).on('onCreate', tabInfo.onCreate);
    }
}

export function tabify(hostId) {
    // First check if we've already tabified.
    if ($(`#${hostId}.ui-tabs`).length > 0) {
        return;
    }

    // jQuery UI tab mechanism
    $(`#${hostId}`).tabs({
        active: 0,
        // Tab panels are created with display: block. Need to force grid.
        create(evt, ui) {
            ui.panel.css('display', 'grid');
            ui.panel.trigger('onCreate');
        },
        activate(evt, ui) {
            ui.newPanel.css('display', 'grid');
            ui.newPanel.trigger('onActive');
        },
    });
}

// MDN considers these URL functions 'experimental',
// so we wrap them in case behavior changes in the future
export function createBlobURL(blob) {
    return URL.createObjectURL(blob);
}

export function freeBlobURL(url) {
    URL.revokeObjectURL(url);
}

export function textToBlobURL(text) {
    const blob = new Blob([text], { type: 'text/plain' });
    return createBlobURL(blob);
}

// Maps a molecule type specified by the server to a user-readable name
export function molTypeToString(molType) {
    return MoleculeTypes.stringify(molType);
}

/**
 * Translate a mouse event into a kind of gesture.  Used to unify mouse and touch events.
 * @param {*} mouseEvent
 * @returns { {gesture: string, fnKeys: { ctrlKey: Boolean, shiftKey: Boolean, altKey: Boolean}}}
 */
export function getMouseGesture(mouseEvent) {
    const type = mouseEvent.type;
    const button = mouseEvent.button;

    switch (type) {
        case 'touchend':
        case 'mouseup':
            return 'click';
        case 'contextmenu':
            return 'context-menu';
        case 'mouseenter':
            return 'enter';
        case 'mouseleave':
            return 'leave';
        case 'mousemove':
            return 'move';
        // no default; handled below
    }
    return 'unspecified';
}

/** Output a CSS rule for a style object, optionally wrapping in a provided selector
 * @param { Object } styleObj
 * @param { string } selector
 */
export function cssRulesForStyleObj(styleObj, selector='') {
    if (!styleObj) return '';

    return `
        ${selector && `${selector} {`}
        ${styleObjToCss(styleObj)}
        ${selector && '}'}
    `;
}

/** Return a css string for a React style object */
export function styleObjToCss(styleObj) {
    let ret = '';

    for (const [field, value] of Object.entries(styleObj)) {
        const cssName = reactStyleNameToCssName(field);
        ret += `${cssName}: ${value};\n`;
    }
    return ret;
}

/** Convert a camelCase React style propertyName to a css property-name */
export function reactStyleNameToCssName(name) {
    let ret = '';
    for (const letter of name) {
        if (letter >= 'a' && letter <= 'z') {
            ret += letter;
        } else if (letter >= 'A' && letter <= 'Z') {
            ret += `-${letter.toLowerCase()}`;
        }
    }
    return ret;
}

export function cssNameToReactStyleName(name) {
    let ret = '';
    let capitalizeNext = false;
    for (const letter of name) {
        if (letter === '-') {
            capitalizeNext = true;
        } else {
            ret += capitalizeNext ? letter.toUpperCase() : letter;
            capitalizeNext = false;
        }
    }
    return ret;
}

export function useJQueryHandlers(target, eventMap, dependencies=[]) {
    const deps = [...new Set([target, ...Object.values(eventMap), ...dependencies])];

    useEffect(() => {
        for (const [eventName, eventHandler] of Object.entries(eventMap)) {
            $(target).on(eventName, eventHandler);
        }
        return () => {
            for (const [eventName, eventHandler] of Object.entries(eventMap)) {
                $(target).off(eventName, eventHandler);
            }
        };
    }, deps); /* eslint-disable-line react-hooks/exhaustive-deps */
}

/**
 * @param {number} [hexColor]
 * @returns {string?} CSS color string
 */
export function convertHexToCSS(hexColor) {
    if (typeof hexColor === 'number') return `#${hexColor.toString(16).padStart(6, '0')}`;
    return null;
}

/**
 * Ensure a string is <= a specified length, maybe preserving characters at the end.
 * For non-string input: nullish values return empty string; others are returned as-is,
 * for the caller to deal with.
 * @param {string} str
 * @param {number} length The total limit of the string length.
 * @param {number} endChars The number of characters to preserve from the end.
 * @returns {string}
 */
export function truncateString(str, length=15, endChars=3) {
    // Coerce nullish to empty string, but return other non-strings, for caller to handle
    if (typeof str !== 'string') return str ?? '';
    if (str.length <= length) return str;
    return _.truncate(str, { length: length - endChars }) + str.slice(str.length-endChars);
}

export function downloadData(filename, data, contentType='text/plain') {
    const blob = new Blob([data], { type: contentType });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
}
