import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import useEventBrokerSubscription from '../common/helpers/useEventBrokerSubscription';
import { EventBroker } from '../../eventbroker';
import { ensureArray } from '../../util/js_utils';
import { withColorSchemeInfoStyled } from '../../redux/prefs/access';

/**
 * SidePanel - class to manage side panels
 * Content panels can be temporary or long-lived. Temporary panels are unmounted when closed.
 * Long-lived panels are cloned when displayed, but preserve their state variables across clones.
 *
 * side: `left` | `Info`
 * The "default" page is optional - what will be displayed when the panel is "closed"
 * For example, the Selector in InfoDisplay
 *
 * @param {{
 *      side: string,
 *      defaultPage: { pageId: string, component: JSX.Element, props: object }?,
 *      defaultPageId: string?,
 *      children: JSX.Element[]?,
 * }} props
 *
 * @example `UserActions.OpenSidePanel('left', { pageId: 'docking' });`
 * @example `Eventbroker.publish(setInfoPanel, <jsx />)
*/
export default function SidePanel({
    side, defaultPage, defaultPageId, children,
}) {
    // pageInfoList - currently mounted pages
    // This can be initially populated with JSX children or a default page object.
    const [pageInfoList, setPageInfoList] = useState(() => {
        const pages = [];
        if (defaultPage) {
            pages.push({
                pageId: defaultPage.pageId,
                component: defaultPage.component,
                props: { ...defaultPage.props, open: true },
                longLived: true,
            });
        }
        if (children) {
            for (const child of ensureArray(children)) {
                pages.push({
                    pageId: child.props.pageId,
                    component: child,
                    props: { ...child.props, open: false },
                    longLived: true,
                });
            }
        }
        return pages;
    });

    // Resize needs to run after rendering, so needs to be in useEffect
    useEffect(() => {
        EventBroker.publish('resize');
    }, [pageInfoList]);

    const handleClose = useCallback(() => { EventBroker.publish(`set${side}Panel`); }, [side]);
    // TODO: consider adding an 'op' field to the data: open, toggle, close, zapAll, etc...
    const handleZapAll = useCallback(() => { EventBroker.publish(`set${side}Panel`, { zapAll: true }); }, [side]);
    const handleSetEvents = useCallback((_, data) => {
        // There's a request to update the SidePanel
        // Possibilities:
        // 1. Close / reset to default, by passing no arguments
        // 2. Display an existing page, by passing a pageId, perhaps with optional props to update
        // 3. Add and display a new page, by passing a pageId, component, and optional props
        // 4. Toggle an existing page, by passing a pageId and toggle: true
        // The `longLived` option controls whether to keep the page mounted when it's not visible.

        // Remove temporary pages
        let updatedPages = pageInfoList.filter((page) => page.longLived);
        // Add new page if it doesn't exist
        const existingPage = updatedPages.find((page) => page.pageId === data?.pageId);
        if (data?.pageId && !existingPage) {
            updatedPages.push(data);
        }

        // Toggle page.
        let requestedPageId = data?.pageId;
        if (data?.toggle && existingPage?.props.open) {
            requestedPageId = null;
        }

        const visiblePageId = requestedPageId || defaultPageId || defaultPage?.pageId;

        // Inject props into page components:
        // * open, setopen, handleClose, longLived
        // * and maybe other incoming props for the target page
        updatedPages = updatedPages.map((pageInfo) => ({
            ...pageInfo,
            props: {
                ...pageInfo.props,
                ...((pageInfo.pageId === visiblePageId) ? (data?.props) : {}),
                open: (() => {
                    if (data?.zapAll) {
                        // Zap case does not supply visiblePageId, so just update the open page.
                        return pageInfo.props.open && pageInfo.component.props.keepOpenOnZap;
                    }
                    return pageInfo.pageId === visiblePageId && visiblePageId != null;
                })(),
                setOpen(open, props) {
                    if (!open) {
                        handleClose();
                    } else {
                        EventBroker.publish(`set${side}Panel`, { pageId: pageInfo.pageId, props: { ...props } });
                    }
                },
                handleClose,
                longLived: pageInfo.longLived,
            },
        }));
        setPageInfoList(updatedPages);
    }, [pageInfoList, defaultPageId, defaultPage?.pageId, side, handleClose]);

    useEventBrokerSubscription(`set${side}Panel`, handleSetEvents);
    useEventBrokerSubscription('zapAll', handleZapAll);

    return (
        <>
            {pageInfoList.map((pageInfo) => (
                // cloneElement is used because we need to pass `open` prop into the component.
                // Note: useState variables are maintained in the clone.
                // For example, the DockingPane has a dockingState useState. The state is
                // preserved across several calls to setSidePanel, because the useState hook
                // implementation returns consistent values and setter functions in the clones.
                <React.Fragment key={pageInfo.pageId}>
                    {React.cloneElement(pageInfo.component, pageInfo.props)}
                </React.Fragment>
            ))}
        </>
    );
}

export const SidePanelContainer = withColorSchemeInfoStyled(styled(({
    title, children, className, width='18rem', containerStyle,
    // The following are inserted by SidePanel, and passed through by parent Component.
    open, handleClose, longLived, contentStyle,
}) => (
    !!(open || longLived) && (
        <div className={className} style={{ display: open ? 'flex' : 'none' }}>
            <div className="sidepanel-container" style={{ width, ...containerStyle }}>
                <div className="sidepanel-container-title">
                    <div>{title}</div>
                    <button type="button" onClick={() => handleClose()}>
                        <i className="fa fa-close" />
                    </button>
                </div>
                <div className="sidepanel-container-content" style={contentStyle}>
                    {children}
                </div>
            </div>
        </div>
    )
))`
${({ $colorSchemeInfo: { css: color, textCss: textColor } }) => `
position: relative;
height: 100%;
& .sidepanel-container {
    background: ${color};
    display: flex;
    flex-direction: column;
    z-index: 1;
}
& .sidepanel-container-content {
    margin: .2rem .5rem;
    overflow: auto;
    border-radius: 0 0 .8rem .8rem;
    flex: 1;
    background: white;
    border: 1px solid black;
    color: black;
    box-shadow: inset 0 -2px 4px black;
    display: flex;
    flex-direction: column;
}
& .sidepanel-container-title {
    margin: .5rem .5rem 0 .5rem;
    display: flex;
    justify-content: center;
    padding: .5rem;
    border-radius: .8rem .8rem 0 0;
    font-size: 2.5vmin;
    font-family: sans-serif;
    font-weight: bold;
    background: white;
    border: 1px solid black;
    color: black;
    box-shadow: inset 0 1px 4px black;
}
& .sidepanel-container-title button {
    fontSize: inherit;
    background-color: transparent;
    color: inherit;
    border: none;
    margin: 0;
    margin-left: auto;
    text-align: inherit;
    outline: none;
}
& .sidepanel-container-title div {
    margin-left: auto;
}
@media only screen and (max-width: 800px) {
    & .sidepanel-container-content{
        position: absolute;
        bottom: 0;
        top: calc(var(--base-font-size) + 1rem);
        left:0;
        right: 0;
        margin: 0rem;
        border-radius: 0;
    }
    & .sidepanel-container-title{
        position: absolute;
        top:0;
        left:0;
        right: 0;
        margin: 0rem;
        border-radius: 0;
    }
}
`}
// Close two template literals: inner for CSS with colors added; outer for styled-component input
`);

export function SidePanelSection({ children }) {
    return (
        <div style={{
            background: 'white',
            color: 'black',
            borderRadius: '.2rem',
            padding: '.5rem',
        }}
        >
            {children}
        </div>
    );
}
