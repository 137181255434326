// ServerMonitor.js

import { Log } from '../Log';
import { EventBroker } from '../eventbroker';

export default class ServerMonitor {
    constructor(getConnectionsFn, removeAllFn) {
        this.getConnections = getConnectionsFn;
        this.removeAllImpl = removeAllFn;
        // Check connection (every 2sec) and attempt reconnects if disconnected
        EventBroker.subscribe('reconnect', (_, { connector }) => connector.monitorConnection());
        this.startLoop();
        /** @type {boolean} Flag marking if we should remove connections on the serverloop thread */
        this.removeAllScheduled = false;
        /** @type {boolean} Flag marking if the server loop is currently removing connections */
        this.removingAll = false;
        this.getRunningParams = this.getRunningParams.bind(this);
    }

    startLoop() {
        this.loopID = setInterval(() => this.step(), 2000);
    }

    stopLoop() {
        clearInterval(this.loopID);
    }

    getRunningParams() {
        return {
            removeAllScheduled: this.removeAllScheduled,
            removingAll: this.removingAll,
        };
    }

    /**
     * Set flag to remove all connections on the server loop thread.
     * @returns {Promise} Promise resolves when all connections have been removed
     */
    removeAll() {
        Log.info('ServerMonitor: scheduling removeAll');

        this.removeAllScheduled = true;
        const getConnections = this.getConnections;
        const getRunningParams = this.getRunningParams;

        return new Promise((resolve) => {
            // We want to resolve when the number of connections is 0
            // This function waits a second, checks, and calls itself again

            function check() {
                Log.info('ServerMonitor: checking connections after removeAll request');

                const { removeAllScheduled, removingAll } = getRunningParams();
                setTimeout(
                    () => {
                        if (getConnections().length === 0
                            && !removingAll
                            && !removeAllScheduled) {
                            Log.info('ServerMonitor: finished removeAll');
                            resolve();
                        } else {
                            Log.info('ServerMonitor: waiting to finish removeAll');
                            check();
                        }
                    },
                    1000
                );
            }
            check();
        });
    }

    step() {
        if (this.removeAllScheduled) {
            Log.info('ServerMonitor: executing removeAll');
            this.removingAll = true;
            this.removeAllScheduled = false;
            this.removeAllImpl().then(() => {
                Log.info('ServerMonitor: removeAll execution finished');
                this.removingAll = false;
            }).catch((ex) => {
                Log.error(`ServerMonitor: exception during removeAll execution: ${ex}`);
                this.removingAll = false;
            });
            return;
        } else if (this.removingAll) {
            return;
        }

        const connectors = this.getConnections().map((dc) => dc.getConnector());
        for (const connector of connectors) {
            connector.monitorConnection();
        }
    }
}
