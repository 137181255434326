import { App } from '../BMapsApp';
import { EventBroker } from '../eventbroker';
import { UserActions, UserCmd } from './UserCmd';

/**
 * @typedef UICmds
 * @type {object}
 * @property {openImport} OpenImport
 * @property {openProteinImport} OpenProteinImport
 * @property {stageMoleculeImport} StageMoleculeImport
 * @property {stageProteinImport} StageProteinImport
 * @property {openExport} OpenExport
 * @property {openDock} OpenDock
 * @property {openSketcher} OpenSketcher
 * @property {openFragmentManager} OpenFragmentManager
 * @property {showInspectorTab} ShowInspectorTab
 * @property {showFragmentPane} ShowFragmentPane
 * @property {openMapSelector} OpenMapSelector
 * @property {openSelectBox} OpenSelectBox
 * @property {openSidePanel} OpenSidePanel
 * @property {closeSidePanel} CloseSidePanel
 * @property {requestMinimize} RequestMinimize
 */

/** @type {UICmds} */
export const UICmds = {
    OpenImport: new UserCmd('OpenImport', openImport),
    OpenProteinImport: new UserCmd('OpenProteinImport', openProteinImport),
    StageMoleculeImport: new UserCmd('StageMoleculeImport', stageMoleculeImport),
    StageProteinImport: new UserCmd('StageProteinImport', stageProteinImport),
    OpenExport: new UserCmd('OpenExport', openExport),
    OpenDock: new UserCmd('OpenDock', openDock),
    OpenSketcher: new UserCmd('OpenSketcher', openSketcher),
    OpenFragmentManager: new UserCmd('OpenFragmentManager', openFragmentManager),
    ShowInspectorTab: new UserCmd('ShowInspectorTab', showInspectorTab),
    ShowFragmentPane: new UserCmd('showFragmentPane', showFragmentPane),
    OpenMapSelector: new UserCmd('OpenMapSelector', openMapSelector),
    OpenSelectBox: new UserCmd('OpenSelectBox', openSelectBox),
    OpenSidePanel: new UserCmd('OpenSidePanel', openSidePanel),
    CloseSidePanel: new UserCmd('CloseSidePanel', closeSidePanel),
    RequestMinimize: new UserCmd('RequestMinimize', requestMinimize),
};

function openImport(tabId, args) {
    EventBroker.publish('openImportTab', { tabId, args });
}

function openProteinImport(tabId, args) {
    EventBroker.publish('openProteinImportTab', { tabId, args });
}

function stageMoleculeImport(stageArgs={}) {
    // undefined = Use default import tab
    openImport(undefined, stageArgs);
}

function stageProteinImport(stageArgs={}) {
    openProteinImport('import_protein_tab', stageArgs);
}

function openExport({ compounds: compoundsIn=[], atoms: atomsIn=[] }={}, args) {
    let compounds = compoundsIn;
    let atoms = atomsIn;
    // Use defaults if nothing specified
    if (compounds.length === 0 && atoms.length === 0) {
        compounds = App.Workspace.getSelectedOrActiveCompounds();
        atoms = App.Workspace.getSelectedAtoms();
        if (atoms.length === 1) {
            // Selected atoms currently take priority over compounds.
            // In the case of a single selected atom, don't send it,
            // to avoid stray forgotten selections interfering with exporting
            // compounds.
            // Better: give a selector on the export screen to choose between
            // selected atoms and selected compounds.
            atoms = [];
        }
    }
    EventBroker.publish('askExport', [{ compounds, atoms }, args]);
}

function openDock(compoundsIn=[]) {
    let compounds = [].concat(compoundsIn); // guarantee array
    if (compounds.length === 0) {
        compounds = compounds.concat(App.Workspace.getSelectedOrActiveCompounds());
    }
    if (compounds.length > 0) {
        EventBroker.publish('askDock', { compounds });
    }
}

function openSketcher(compound) {
    EventBroker.publish('askSketcher', { compound });
}

function openFragmentManager(initialMode) {
    EventBroker.publish('openFragmentManager', { initialMode });
}

function showInspectorTab(tab) {
    App.Workspace.displayStateController.setInspectorTab(tab);
}

function showFragmentPane(which) {
    EventBroker.publish('showFragmentPane', { type: which });
}

/**
 * @param {'show' | 'hide'} cmd
 *
 * @param {{
 *      selectivity?: 'individual' | 'selectivity' | 'viewonly' | 'multiProtein'
 * }} options
 */

function openMapSelector(cmd, options={}) {
    openProteinImport('protein_search_tab', { cmd, options });
}

function openSelectBox(arg) {
    EventBroker.publish('openSelectBox', arg);
}

function openSidePanel(positionId, args) {
    EventBroker.publish(`set${positionId}Panel`, args);
}

function closeSidePanel(positionId) {
    EventBroker.publish(`set${positionId}Panel`);
}

function requestMinimize() {
    UserActions.ShowInspectorTab('energies');
    UserActions.EnergyMinimize(App.Workspace.getSelectedOrActiveCompounds());
}
