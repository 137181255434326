import {
    AtomGroupTypes, DecodedResidue, AtomGroup, Polymer, Fragment, Residue,
} from './atomgroups';
import { Compound, Ligand } from './compound';
import { getFullResidueId } from '../util/mol_info_utils';
import {
    ionAtomNamesMap, residueAtomNamesMap, cofactorResidueNames,
    bufferResidueNames, waterResidueNames,
} from '../atomnames';
import { BfdMolTypeCodes, MoleculeTypes } from '../molecule_types';

/** @description A static class to create AtomGroup business objects */
export class AtomGroupFactory {
    /** @description Convert a diverse list of decoded residues to categorized AtomGroup
     *  business objects.
     *  @returns an object: { Type1: [...residues of type Type1...],
     *  Type2: [...residues of type Type2...], ... }
     */

    static CreateAtomGroups(decodedList, helices, sheets) {
        const result = {};
        // initialize all known types to empty list
        for (const key of Object.keys(AtomGroupTypes)) { result[key] = []; }

        decodedList.forEach((decoded) => {
            try {
                const newAtomGroup = AtomGroupFactory.CreateAtomGroup(decoded);
                result[newAtomGroup.type].push(newAtomGroup);
            } catch (ex) {
                const residueLabel = decoded.atoms.length > 0
                    ? getFullResidueId(decoded.atoms[0])
                    : decoded.getSpec();
                console.error(`Exception decoding ${residueLabel}: ${ex}`);
            }
        });

        const polymers = AtomGroupFactory.CreatePolymers(
            result[AtomGroupTypes.Residue], helices, sheets
        );
        polymers.forEach((x) => result[x.type].push(x));
        return result;
    }

    /** @description Create a AtomGroup business object according to the type derived from
     *  the residue.
     */
    static CreateAtomGroup(decoded) {
        let molAtomGroup;
        const type = decoded instanceof DecodedResidue
            ? AtomGroupFactory.ClassifyDecodedResidue(decoded)
            : AtomGroupFactory.ClassifyDecodedFragment(decoded);
        const { Class, ctorTakesObject } = AtomGroupTypeInfo[type];
        const ctorArg = ctorTakesObject ? decoded : decoded.atoms;
        if (Class) {
            molAtomGroup = new Class(ctorArg, type);
        } else {
            molAtomGroup = new AtomGroup(ctorArg, type);
        }
        return molAtomGroup;
    }

    /** @description Compare a residue name against known values to classify an atom group. */
    static ClassifyDecodedResidue(decoded) {
        // It occurs that modified aminos or nucleotides that are standalone molecules,
        // instead of polymers, do not have the hetflag but are labeled 'small_molecule'.
        if (decoded.isCompound) return AtomGroupTypes.Compound;
        if (decoded.molType === BfdMolTypeCodes.small_molecule) return AtomGroupTypes.Ligand;
        if (decoded.molType === BfdMolTypeCodes.cofactor) return AtomGroupTypes.Cofactor;
        // if (decoded.molType === BfdMolTypeCodes.ion) return AtomGroupTypes.Ion;
        if (!decoded.hetflag
            || decoded.molType === BfdMolTypeCodes.saccharide) return AtomGroupTypes.Residue;

        // Look for co-factors, ions, etc.
        const ret = AtomGroupTypes.Ligand;
        for (const type of Object.keys(AtomGroupTypes)) {
            const typeInfo = AtomGroupTypeInfo[type];
            const resNames = typeInfo && typeInfo.KnownResNames;
            const regexFn = typeInfo && typeInfo.modRegex;
            if (resNames) {
                if (regexFn) {
                    if (resNames.has) console.error('Warning: using modRegex with a Map has not been tested.');
                    const rs = resNames.includes
                        ? resNames.map(regexFn) // resNames is an Array
                        : [...resNames.keys()].map(regexFn); // resNames is a Map
                    const found = rs.find((r) => decoded.pdbCode.match(r));
                    if (found) {
                        return type;
                    }
                } else if (
                    (resNames.includes && resNames.includes(decoded.pdbCode)) // resNames is Array
                    || (resNames.has && resNames.has(decoded.pdbCode)) // resNames is a Map
                ) {
                    return type;
                }
            }
        }

        return ret;
    }

    /**
     *
     * @param {DecodedFragment} decoded
     */
    static ClassifyDecodedFragment(decoded) {
        if (decoded.isWater) return AtomGroupTypes.ComputedWater;
        if (decoded.isFragment || decoded.isFragmentMapFragment) return AtomGroupTypes.Fragment;
        if (decoded.isHotspot) {
            // We may need to treat Hotspot fragments differently
            return AtomGroupTypes.Fragment;
        }
        return AtomGroupTypes.Unknown;
    }

    /**
     * @description Create Polymers based on Residue AtomGroup objects made by CreateAtomGroup
     */
    static CreatePolymers(residues, helices=[], sheets=[]) {
        // Assume the residues are in the order originally delivered (in-chain order).  A
        // chain can be unlabeled (assumed to be the main protein), labeled with a name
        // and type, or there can be multiple labeled polymers with the same chain ID.
        // When a residue has a molName/molType, all subsequent residues with the same
        // chain ID are included into a polymer with that name until either a different
        // molName or a new chain is encountered. In the simple case, a chain is just a
        // protein.

        const polymerNames = new Set(); // just used to group residues
        const atomsByPolymer = {};
        const residuesByPolymer = {};
        const molTypeInfoByPolymer = {};

        // Helix and sheet specs:
        //     [modelNumber, chainID, seqnumStart, inscodeStart, seqnumEnd, inscodeEnd]
        const helicesByChain = helices.reduce((acc, helix) => {
            const chain = helix[1];
            if (!acc[chain]) acc[chain] = [];
            acc[chain].push(helix);
            return acc;
        }, {});
        const sheetsByChain = sheets.reduce((acc, sheet) => {
            const chain = sheet[1];
            if (!acc[chain]) acc[chain] = [];
            acc[chain].push(sheet);
            return acc;
        }, {});

        // Populate the collections of polymer data
        // This is in a block to scope polymerName
        {
            let polymerChain;
            let polymerName;
            for (const residue of residues) {
                const chain = residue.chain;
                if (chain) {
                    const molName = residue.molName;
                    if (polymerChain !== chain || (molName && polymerName !== molName)) {
                        polymerChain = chain;
                        polymerName = molName || 'main';
                        polymerName += chain;
                        polymerNames.add(polymerName);
                        molTypeInfoByPolymer[polymerName] = (
                            MoleculeTypes.getTypeInfo(residue.molType)
                        );
                    }
                    if (!atomsByPolymer[polymerName]) atomsByPolymer[polymerName] = [];
                    if (!residuesByPolymer[polymerName]) residuesByPolymer[polymerName] = new Set();
                    atomsByPolymer[polymerName].push(...residue.atoms);
                    residue.molType = molTypeInfoByPolymer[polymerName].molType;
                    residuesByPolymer[polymerName].add(residue);
                } else {
                    console.warn(`Residue ${residue.resSpec} does not have an associated chain`);
                }

                // Process secondary structure information
                const helicesForChain = helicesByChain[chain];
                const sheetsForChain = sheetsByChain[chain];
                if (helicesForChain) {
                    for (const helixSpec of helicesForChain) {
                        const specs = this.calculateSecondaryStructure(
                            residue, helixSpec, 'helix'
                        );
                        if (specs) {
                            residue.setSecondaryStructureInfo(specs);
                            break;
                        }
                    }
                }
                if (sheetsForChain) {
                    for (const sheetSpec of sheetsForChain) {
                        const specs = this.calculateSecondaryStructure(
                            residue, sheetSpec, 'sheet'
                        );
                        if (specs) {
                            residue.setSecondaryStructureInfo(specs);
                            break;
                        }
                    }
                }
            }
        }

        const polymers = [];
        const refPolymers = [];
        for (const polymerName of polymerNames) {
            const atomGroupType = molTypeInfoByPolymer[polymerName].atomGroupType;
            const newMol = new Polymer(
                atomsByPolymer[polymerName], residuesByPolymer[polymerName], atomGroupType
            );
            const res0 = newMol.getResidues()[0];
            newMol.molName = res0.molName; // note, molType is Polymer
            // Collect all the ref structures at the head of the list.
            if (res0.molType && res0.molType.substring(0, 3) === 'ref') {
                refPolymers.push(newMol);
            } else {
                polymers.push(newMol);
            }
        }

        return [...refPolymers, ...polymers];
    }

    static calculateSecondaryStructure(residue, spec, type) {
        const [modelNumber, chainID, seqnumStart, inscodeStart, seqnumEnd, inscodeEnd] = spec;

        const residueInStructure = ({ chain, resi, icode }) => {
            const isInBetweenSeq = (resi > seqnumStart && resi < seqnumEnd);
            const atStartOrEndCheck = (
                (resi === seqnumStart && (!icode || icode >= inscodeStart))
                || (resi === seqnumEnd && (!icode || icode <= inscodeEnd))
            );

            return chain === chainID && (isInBetweenSeq || atStartOrEndCheck);
        };
        const atStart = ({ resi, icode }) => (
            resi === seqnumStart && (!icode || icode === inscodeStart)
        );
        const atEnd = ({ resi, icode }) => (
            resi === seqnumEnd && (!icode || icode === inscodeEnd)
        );

        if (residueInStructure(residue)) {
            return {
                type,
                isStart: atStart(residue),
                isEnd: atEnd(residue),
            };
        }
        return null;
    }

    static PrintSummary(atomGroupsByType) {
        let totalResidues = 0;
        for (const [groupType, agList] of Object.entries(atomGroupsByType)) {
            if (agList.length === 0) continue;
            console.log(`  ${agList.length} ${groupType}(s)`);
            // Summarize Residue counts by residue name
            // This clause about Residues isn't used, since the
            // residue objects aren't included in atomGroupsByType
            if (groupType === AtomGroupTypes.Residue) {
                const byResname = {};
                for (const r of agList) {
                    if (!byResname[r.resn]) {
                        byResname[r.resn] = [r];
                    } else {
                        byResname[r.resn].push(r);
                    }
                }
                for (const [rName, resList] of Object.entries(byResname)) {
                    console.log(`    ${rName}: ${resList.length} residues`);
                }
            // Print a line for ions, compounds, buffers and cofactors,
            // but skip crystal waters.
            } else if (groupType !== AtomGroupTypes.CrystalWater) {
                for (const ag of agList) {
                    // If it's a polymer, print the chain ID, molType, molName, #atoms,
                    // and #residues
                    if (ag.residues) {
                        const molType = ag.molType || 'target';
                        const molName = ag.molName || '';
                        const molDesc = molName ? `${molName} (${molType})` : molType;
                        console.log(`    chain ${ag.chain}:\n      ${molDesc}\n      ${ag.atoms.length} atoms\n      ${ag.residues.size} residues`);
                        totalResidues += ag.residues.size;
                    } else {
                        const ligandMarker = (ag.type === AtomGroupTypes.Compound && ag.isLigand())
                            ? ' (co-crystal ligand)'
                            : '';
                        console.log(`    ${ag.resSpec}: ${ag.atoms.length} atoms${ligandMarker}`);
                    }
                }
            }
        }
        console.log(`Total residue count: ${totalResidues}`);
    }
}

const AtomGroupTypeInfo = {
    [AtomGroupTypes.Protein]: { id: AtomGroupTypes.Protein },
    [AtomGroupTypes.Residue]: {
        id: AtomGroupTypes.Residue,
        KnownResNames: residueAtomNamesMap,
        Class: Residue,
    },
    [AtomGroupTypes.Compound]: {
        id: AtomGroupTypes.Compound,
        Class: Compound,
    },
    [AtomGroupTypes.Ligand]: {
        id: AtomGroupTypes.Ligand,
        Class: Ligand,
    },
    [AtomGroupTypes.PeptideLigand]: {
        id: AtomGroupTypes.PeptideLigand,
        Class: Residue,
    },
    [AtomGroupTypes.Cofactor]: {
        id: AtomGroupTypes.Cofactor, KnownResNames: cofactorResidueNames,
    },
    [AtomGroupTypes.Buffer]: {
        id: AtomGroupTypes.Buffer, KnownResNames: bufferResidueNames,
    },
    [AtomGroupTypes.Ion]: {
        id: AtomGroupTypes.Ion, KnownResNames: ionAtomNamesMap,
    },
    [AtomGroupTypes.CrystalWater]: {
        id: AtomGroupTypes.CrystalWater,
        KnownResNames: waterResidueNames,
        modRegex: (name) => `${name}(_[A-Za-z0-9]+)?`,
    },
    [AtomGroupTypes.ComputedWater]: {
        id: AtomGroupTypes.ComputedWater,
        Class: Fragment,
        ctorTakesObject: true,
    },
    [AtomGroupTypes.Fragment]: {
        id: AtomGroupTypes.Fragment,
        Class: Fragment,
        ctorTakesObject: true,
    },
    [AtomGroupTypes.Unknown]: { id: AtomGroupTypes.Unknown },
};
