import styled from 'styled-components';
// Material UI
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MuiPopper from '@mui/material/Popper';
import BMapsTooltip from 'BMapsSrc/ui/BMapsTooltip';

const Popper = styled(MuiPopper)`
  & .MuiTooltip-tooltip {
    font-size: smaller;
  }
`;

export default function TooltipInfo({ ...rest }) {
    return (
        <BMapsTooltip
            tabIndex="0"
            focusable
            PopperComponent={Popper}
            {...rest}
        >
            <InfoOutlinedIcon />
        </BMapsTooltip>
    );
}
