import { WebServices } from 'BMapsSrc/WebServices';
import { ensureArray, sleep } from 'BMapsUtil/js_utils';

/**
 * Update MapCase properties with the associated UniProt IDs.
 * @param {import('BMapsModel').MapCase} mapCase
 * @param {function} trigger
 */
export async function updateMapCaseWithUniprotId(mapCase, trigger) {
    const { value, error } = await mapCase.fetchProperty('uniprot', 'uniprot_ids', async () => {
        const pdbId = mapCase.pdbID;
        if (!isValidPdbId(pdbId)) {
            const msg = pdbId
                ? `'${pdbId}' is not a valid PDB ID for UniProt lookup`
                : 'No PDB ID defined, needed for UniProt lookup';
            return { error: msg };
        }
        const { uniprotIds, error: uniprotErr } = await uniprotLookupOne(pdbId);
        return {
            value: uniprotIds,
            error: uniprotErr,
        };
    }, trigger);
    return { value, error };
}

/**
 * Lookup UniProt IDs for a list of PDBs. Return an object mapping from each PDB ID to UniProt IDs.
 * The keys in the result object are forced to lowercase.
 * @param {string|string[]} pdbIds
 * @returns {{ results: { [pdbId: string]: string[] } } | { error: string }}
 */
export async function uniprotLookup(pdbIds) {
    const requestIds = ensureArray(pdbIds);
    const startUrl = 'https://rest.uniprot.org/idmapping/run';
    const resultsUrlBase = 'https://rest.uniprot.org/idmapping/results/';
    const postData = `from=PDB&to=UniProtKB&ids=${requestIds.join(',')}`;
    let responseStr = await WebServices.startWsRequest(startUrl, { postData, contentType: 'application/x-www-form-urlencoded' });
    let response;
    try {
        response = JSON.parse(responseStr);
    } catch (ex) {
        return { error: `Failed to parse uniprot request response: ${ex}` };
    }
    if (response.jobId) {
        const resultsUrl = `${resultsUrlBase}${response.jobId}`;
        let resultResponse;
        const maxTries = 10;
        for (let i = 0; i < maxTries; i++) {
            await sleep(1000);
            try {
                responseStr = await WebServices.startWsRequest(resultsUrl);
            } catch (ex) {
                continue;
            }
            try {
                response = JSON.parse(responseStr);
            } catch (ex) {
                return { error: `Failed to parse uniprot results response: ${ex}` };
            }

            if (response.results) {
                console.log(`UniProt: processing ${JSON.stringify(response.results)}`);
                const results = response.results.reduce(
                    (acc, { from, to }) => {
                        const key = from.toLowerCase();
                        if (!acc[key]) acc[key] = [];
                        acc[key].push(to);
                        return acc;
                    }, {}
                );
                return { results };
            }
        }
        return { error: `UniProt lookup gave up after ${maxTries} seconds. Last result response: ${JSON.stringify(resultResponse)}` };
    } else if (response.messages) {
        return { error: `UniProt lookup failed with messages: ${JSON.stringify(response.messages)}` };
    } else {
        return { error: `UniProt failed. Request response: ${JSON.stringify(response)}` };
    }
}

/**
 * Lookup UniProt IDs for a single PDB.
 * @param {string} pdbId
 * @returns {{ uniprotIds: string[], error: string }}
 */
export async function uniprotLookupOne(pdbId) {
    const { results, error } = await uniprotLookup(pdbId);
    if (error) return { error };
    const key = pdbId.toLowerCase();
    const uniprotIds = results[key];
    return { uniprotIds, error };
}

// Copied from PdbImportCase in MapCase.js. Could go into mol_format_utils?
// Number followed by 3 alphanumerics, with at least one letter
function isValidPdbId(id) {
    const pidFormat = /^[0-9][A-Za-z0-9]{3}$/;
    const alphaChar = /[A-Za-z]/;
    return pidFormat.test(id) && alphaChar.test(id);
}
