/**
 * @class ResidueAtomInfo
 * @description Class to maintain atom information for ligands and user compounds.
 * @member atomNames Map of ligand res name / compound name to atom names
 * @member amberNames Map of ligand res name / compound name to atom amber names
 * @member charges Map of ligand res name / compound name to atom charges
 * @member ringOrdinals Map of ligand res name / compound name to atom ring ordinals
 */
export default class AtomInfoMap {
    constructor() {
        /** @type { Map<string, {
         *      atomNames: string[],
         *      amberNames: string[],
         *      charges: number[]?,
         *      ringOrdinals: number[]?,
         *      formalCharge: number?
         * }}
         */
        this.map = new Map();
    }

    get size() { return this.map.size; }

    has(resName) { return this.map.has(resName); }

    setAtomInfo(resName, {
        atomNames, amberNames, charges, ringOrdinals, formalCharge,
    }={}) {
        if (!resName) {
            console.error("Can't add residue atom info without residue name.");
            return;
        }

        if (!atomNames || atomNames.length === 0) {
            console.error(`Can't add residue atom info for residue ${resName} without atom names`);
            return;
        }

        this.map.set(resName, {
            atomNames,
            amberNames: this.checkArray(amberNames, 'amberNames', resName, atomNames),
            charges: this.checkArray(charges, 'charges', resName, atomNames),
            ringOrdinals: this.checkArray(ringOrdinals, 'ringOrdinals', resName, atomNames, true),
            formalCharge,
        });
    }

    updateAtomInfo(resName, newAtomInfo) {
        const existing = this.map.get(resName);
        if (!existing) {
            console.warn(`Attempted to update non-existent atom info for ${resName}`);
            return;
        }

        for (const key of ['amberNames', 'charges', 'ringOrdinals']) {
            const allowEmpty = key === 'ringOrdinals';
            if (this.checkArray(newAtomInfo[key], key, existing.atomNames, resName, allowEmpty)) {
                existing[key] = newAtomInfo[key];
                console.log(`Updated ${key} for ${resName}`);
            }
        }
    }

    getAtomInfo(resName) {
        const found = this.map.get(resName);
        return found && { ...found };
    }

    lookupFormalCharge(resName) {
        return this.getAtomInfo(resName)?.formalCharge;
    }

    // Internal functions
    checkArray(array, arrayName, resName, atomNames, allowEmpty=true) {
        if (!array || (array.length === 0 && allowEmpty)) {
            // Return false to avoid adding, but don't need to log error
            return null;
        } else if (array.length !== atomNames.length) {
            console.error(`Can't add ${arrayName} for residue ${resName} because ${arrayName} length differs from atomNames.`);
            console.error(`atomNames (${atomNames.length}): ${atomNames.join(', ')}`);
            console.error(`${arrayName} (${array.length}): ${array.join(', ')}`);
            return null;
        }
        return array;
    }
}
