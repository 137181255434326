export const CookieManager = {
    /**
     * @param {string} name
     * @param {string} value
     * @param {number?} maxAgeSec Lifetime in seconds. -1 is one year, 0 clears the cookie.
     */
    set(name, value, maxAgeSec) {
        if (value == null) {
            CookieManager.clear(name);
        } else {
            setSecureCookie(name, value, maxAgeSec);
        }
    },
    clear(name) {
        setSecureCookie(name, '', 0);
    },
    get(name) {
        return getCookie(name);
    },
    exists(name) {
        return cookieExists(name);
    },
};

// General cookie functions
function setSecureCookie(name, value, maxAgeSec) {
    const maxAge = (maxAgeSec && maxAgeSec < 0) ? /* one year */ 60*60*24*365 : maxAgeSec;
    const options = {
        name,
        value,
        secure: true,
        sameSite: 'Strict',
        maxAge,
    };
    setCookie(options);
}

function cookieExists(cookieName) {
    const cookie = document.cookie.split(';').find((item) => item.trim().startsWith(`${cookieName}=`));
    return cookie !== undefined;
}

function getCookie(cookieName) {
    const cookie = document.cookie.split(';').find((item) => item.trim().startsWith(`${cookieName}=`));
    const cookieValue = cookie.split('=')[1];
    return cookieValue && cookieValue.trim();
}

function setCookie({
    name, value, secure, sameSite, maxAge, expires, domain, path,
} = {}) {
    const securePart = secure ? ';secure' : '';
    const sameSitePart = sameSite ? `;samesite=${sameSite}` : '';
    const maxAgePart = maxAge != null ? `;max-age=${maxAge}` : '';
    const expiresPart = expires ? `;expires=${expires}` : '';
    const domainPart = domain ? `;domain=${domain}` : '';
    const pathPart = path ? `;path=${path}` : '';
    document.cookie = `${name}=${value}${securePart}${sameSitePart}${maxAgePart}${expiresPart}${domainPart}${pathPart}`;
}
